import styled from 'styled-components/macro';

export const MainWrapperSmartview = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  min-height: 30px;
  height: 30px;
  cursor: pointer;
  .to_be_hide {
    display: none;
  }
  &:hover {
    background: #ffeebf;
    border-color: #ece2e2;
    .to_be_hide {
      display: block;
    }
  }
  ${({ isActive }) => (isActive
    ? `
      background: #FFEEBF;
      border-color: #ECE2E2;
    `
    : '')}
`;

export const DDTitleWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d3c8c8;
  margin-left: 16px;
  margin-bottom: 12px;
`;
export const SVGWrapperTagsAndSmartviews = styled.div`
  position: relative;

  ${({ isSecond }) => (isSecond
    ? `
    position: absolute;
    right: 11px;
    top: 3px;
    padding-left: 3px;
    width:24px;
    height:24px;
 
    border-radius: 4px;
    &:hover{
     background: #FFDF85;
    }
    svg {
    width:17px;
    }
    `
    : `
    top: 5px;
    margin-right: 6px;
    `)}

  ${({ isSecond, isActive }) => (isSecond && isActive
    ? `
    background:#FFC72C;
    display:block !important;
    `
    : '')}
  svg path {
    ${({ color }) => (color ? `stroke:#${color};` : '')}
  }
  ${({ forTagsThreeDots }) => (forTagsThreeDots
    ? `
      display:block;
    position: absolute;
    right: 10px;
    top: -1.5px;
    padding-left: 4px;
    svg{
    width: 17px;
    }
  `
    : '')}
`;
export const DDInputWrapper = styled.input`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border: none;
  margin-left: 16px;
  margin-bottom: 2px;
  position: relative;
  &:focus {
    ::placeholder,
    ::-webkit-input-placeholder {
      color: transparent;
    }

    :-ms-input-placeholder {
      color: transparent;
    }
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(122, 105, 105, 0.5);
    position: absolute;
    left: -1px;
    top: 3px;
  }

  :-ms-input-placeholder {
    color: rgba(122, 105, 105, 0.5);
  }
`;
export const BtnWrapper = styled.div`
  padding-left: 16px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  &:hover {
    background-color: #ffeebf;
  }
`;
export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 32px;
  margin-top: 14px;
  margin-bottom: 5px;
  max-width: 652px;
`;
export const BTNGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  z-index: 103;
`;
export const Divider = styled.div`
  background: rgba(29, 29, 29, 0.08);
  width: 1px;
  height: 22px;
  position: relative;
  top: 5px;
`;
