import { EditPage } from './types';

export const actionChangeEditableBlock = ({
  id,
  newState,
  innerHtml,
  playlistField,
  dur,
  blockType,
}) => {
  return {
    type: EditPage.ChangeEditableBlock,
    payload: { id, newState, innerHtml, playlistField, dur, blockType },
  };
};

export const actionChangeEditableBlockRedux = (id, newState, innerHtml) => {
  return {
    type: EditPage.ChangeEditableBlockRedux,
    payload: { id, newState, innerHtml },
  };
};

export const actionAddManyEditableBlock = (
  id,
  blocks,
  pageId,
  firstEmpty,
  playlistField,
  isLibraryCreate,
) => {
  return {
    type: EditPage.AddManyEditableBlock,
    payload: { id, blocks, pageId, firstEmpty, playlistField, isLibraryCreate },
  };
};

export const actionAddEditableBlockRedux = (
  id,
  type,
  pageId,
  isClone,
  contentText,
  eraseContent,
) => {
  return {
    type: EditPage.AddEditableBlockRedux,
    payload: { id, type, pageId, isClone, contentText, eraseContent },
  };
};

export const actionAddEditableBlock = (
  id,
  type,
  pageId,
  isClone,
  isWidget,
  eraseContent,
  playlistField,
  title,
  text,
) => {
  return {
    type: EditPage.AddEditableBlock,
    payload: {
      id,
      type,
      pageId,
      isClone,
      isWidget,
      eraseContent,
      playlistField,
      title,
      text,
    },
  };
};

export const actionDeleteEditableBlock = (id) => {
  return {
    type: EditPage.DeleteEditableBlock,
    payload: { id },
  };
};
export const actionDeleteEditableBlockRedux = (id, type, pageId) => {
  return {
    type: EditPage.DeleteEditableBlockRedux,
    payload: { id, type, pageId },
  };
};

export const actionDeleteManyBlocks = (ids, playlistField) => {
  return {
    type: EditPage.DeleteManyBlocks,
    payload: { ids, playlistField },
  };
};

export const actionDragManyBlock = (dragHandlerId, draggableBlocks) => {
  return {
    type: EditPage.DragManyBlock,
    payload: { dragHandlerId, draggableBlocks },
  };
};
export const actionDragManyBlockRedux = (dragHandlerId) => {
  return {
    type: EditPage.DragManyBlockRedux,
    payload: { dragHandlerId },
  };
};
