import React from 'react';
import styled from 'styled-components/macro';
import { GlobalFlexRowWrapper } from '../../Maker/MakerCommon/UiComponents/styled';


const NameWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #343434;
  padding-top: 4px;
`;

const NameAndAva = ({ text }) => {
  return (
    <GlobalFlexRowWrapper style={{ overflowY: 'hidden', paddingLeft: 1 }}>
      <NameWrapper>{text.replaceAll('undefined', '')}</NameWrapper>
    </GlobalFlexRowWrapper>
  );
};

export default NameAndAva;
