import React, { useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import Close from '../SimpleComponents/Close/Close';
import { actionAddSubscribe } from '../../redux/channels/action';
import validateEmail from '../../utils/validators/validateEmail';
import UI from '../../UIComponents';
import InputWithSelectUser from '../Inputs/InputUsersWithSelector/InputWithSelectUser';
import { actionCreator } from '../../shared/redux/actionHelper';
import Contacts from '../../redux/contacts/types';
import { empty } from '../../utils/constants';

const cx = classNames.bind(styles);

const AddMemberModal = () => {
  const dispatch = useDispatch();
  const [inputEmail, setInputEmail] = useState('');
  const [emailsAndUsersToInvite, setEmailsAndUsersToInvite] = useState([]);
  const [validateError, setValidateError] = useState('');
  const { t } = useTranslation();

  const currentPage = useSelector((state) => state.currentPage);
  const { id: folderId } = currentPage;
  const channelsMembers = useSelector((state) => state.channels.myChannels[folderId]) || empty;

  const close = () => {
    dispatch(actionCloseModal());
  };

  const sendClickHandler = () => {
    if (!emailsAndUsersToInvite.length) return;
    if (inputEmail.trim() && validateEmail(inputEmail)) {
      dispatch(
        actionAddSubscribe(
          [...emailsAndUsersToInvite, inputEmail.trim()],
          folderId,
        ),
      );
      dispatch(actionCloseModal());
    } else if (!inputEmail.trim() && emailsAndUsersToInvite.length) {
      dispatch(actionAddSubscribe(emailsAndUsersToInvite, folderId));
      dispatch(actionCloseModal());
    } else if (!inputEmail.trim()) {
      setValidateError(t('pleaseEnterValidEmailT'));
    }
  };

  const deleteEmail = (indexToDel) => {
    setEmailsAndUsersToInvite(
      emailsAndUsersToInvite.filter((_, index) => index !== indexToDel),
    );
  };

  const handleUserOrEmailState = useCallback((e, user, email) => {
    if (!validateEmail(email) && user) {
      setEmailsAndUsersToInvite([
        ...emailsAndUsersToInvite,
        { ...user, type: 'user' },
      ]);
    } else if (email && !validateEmail(email) && !user) {
      const name = email.slice(0, 40);
      setValidateError(
        `${name}${
          name.length > 39 ? '...' : ''
        } ${t('notFoundInContactsT')}`,
      );
    } else if (email && validateEmail(email)) {
      setEmailsAndUsersToInvite([...emailsAndUsersToInvite, email]);
      dispatch(
        actionCreator(Contacts.CreateAndAddSingleToContactMap, { email }),
      );
    } else {
      setValidateError(t('enterEmailOrUsernameT'));
      e.preventDefault();
    }
    setInputEmail('');
  }, [emailsAndUsersToInvite]);

  return (
    <div onClick={close} className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'AddMemberModal')}
      >
        <div className={cx('title_wrapper')}>
          <div className={cx('title')}> {t('addMemberT')}</div>
        </div>

        <InputWithSelectUser
          inputEmail={inputEmail}
          setInputEmail={setInputEmail}
          emailsAndUsersToInvite={emailsAndUsersToInvite}
          setEmailsAndUsersToInvite={setEmailsAndUsersToInvite}
          setValidateError={setValidateError}
          validateError={validateError}
          deleteEmail={deleteEmail}
          handleUserOrEmailState={handleUserOrEmailState}
          excludedUsers={channelsMembers}
          placeholder={t('emailOrUsernameT')}
          labelText=""
        />

        <UI.Error error={validateError} />

        <div className={cx('group_buttons')}>
          <div onClick={close} className={cx('group_buttons_cancel')}>
            {t('cancelT')}
          </div>
          <div
            onClick={sendClickHandler}
            className={cx('group_buttons_send_invite', {
              disabled: !emailsAndUsersToInvite.length,
            })}
          >
            {t('addMemberT')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMemberModal;
