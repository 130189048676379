import { useState, useEffect, useRef, MutableRefObject, SetStateAction, Dispatch } from 'react';

type ReturnType = [
  MutableRefObject<HTMLElement | null>,
  boolean,
  Dispatch<SetStateAction<boolean>>
];

export default function useComponentVisibleNoEsc(
  initialIsVisible: boolean,
  data?: string,
): ReturnType {
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(initialIsVisible);

  const ref = useRef<HTMLElement | null>(null);

  const handleClickOutside = (event: MouseEvent): void => {
    if (data) {
      if (
        ref.current
          && !ref.current.contains(event.target as Node)
          && !(
            (event.target as HTMLElement)?.dataset.parent === data
              || (event.target as HTMLElement)?.parentElement?.dataset.parent === data
          )
      ) {
        setIsComponentVisible(false);
      }
      return;
    }
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [data]);

  return [ref, isComponentVisible, setIsComponentVisible];
}
