export const historyEvents = [];
// eslint-disable-next-line
export let historyStep = 0;

export const incrementHistoryStep = () => {
  historyStep++;
};

export const decrementHistoryStep = () => {
  historyStep--;
};

export const clearHistory = () => {
  historyStep = 0;
  historyEvents.length = 0;
};

export const CLEAR_HISTORY = 'CLEAR_HISTORY';

export const actionClearHistoryUNDO = () => ({
  type: CLEAR_HISTORY,
});
