import { useEffect, useRef } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isParagraphNode, $isRangeSelection } from 'lexical';
import { $isHeadingNode } from '@lexical/rich-text';


export const ParagraphPlaceholderPlugin = (props: { placeHolderClass: string }) => {
  const [editor] = useLexicalComposerContext();
  const paragraphRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(({ editorState }) => {
      const nativeSelection = window.getSelection();

      editorState.read(() => {
        // Cleanup
        if (paragraphRef?.current) {
          paragraphRef.current.classList.remove(props.placeHolderClass);
          paragraphRef.current = null;
        }

        const selection = $getSelection();
        if (!nativeSelection || !selection || !$isRangeSelection(selection)) return;

        const parentNode = selection.anchor.getNode();
        const isValidNode = $isHeadingNode(parentNode) || $isParagraphNode(parentNode);
        if (!isValidNode || !parentNode.isEmpty()) return;

        const paragraphDOMElement = nativeSelection.anchorNode;

        if (!paragraphDOMElement) return;

        if (paragraphDOMElement instanceof HTMLElement) {
          paragraphRef.current = paragraphDOMElement;
          paragraphRef.current.classList.add(props.placeHolderClass);
        }
      });
    });

    return () => {
      removeUpdateListener();
    };
  }, [editor]);

  return null;
};
