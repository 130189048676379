import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import styles from './index.module.scss';
import { copyInBuffer } from '../../../utils/helpers';
import { ReactComponent as Copy } from '../../../images/icons/icon_20/copy.svg';
import { SHARED_PLAYLIST_URL } from '../../../utils/constants';


const LinkShareToWeb = React.memo(function LinkShareToWeb({ isPage, dataParent = '', link, wrapperId }) {
  const dispatch = useDispatch();
  const copyRef = useRef(null);

  const itemType = isPage
    ? 'page'
    : 'playlist';

  const copyHandler = (e) => {
    e.preventDefault();
    copyRef.current.select();
    copyInBuffer(copyRef, dispatch);
  };
  const shareLink = itemType === 'page'
    ? `${document.location.origin}/sharedwebpage/${link}`
    : `${document.location.origin}/${SHARED_PLAYLIST_URL}/${link || wrapperId}`;

  return (
    <div
      data-parent={dataParent}
      className={styles.button_group_copy}
    >
      <div className={`${styles.share_menu__access_code} ${styles.project_link}`}>
        Project Link
      </div>
      <input
        data-parent={dataParent}
        data-cy="copy-link-input"
        onClick={copyHandler}
        ref={copyRef}
        readOnly
        className={`${styles.copyInput} ${styles.input_user_name}`}
        value={shareLink}
      />
      <button
        data-parent={dataParent}
        data-cy="copy-link-btn"
        onClick={copyHandler}
        className={`${styles.copy_button}`}
      >
        <Copy data-parent={dataParent} />
        <div data-parent={dataParent}>
          Copy
        </div>
      </button>
    </div>
  );
});

export default LinkShareToWeb;
