import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DATA_PARENTS, openModalType } from '../../../utils/constants';
import TagsDropDownItem from './TagsDropDownItem';
import useThrottle from '../../../utils/hooks/useThrottle';
import { filterItems, sortTagsByLastModified } from '../../../utils/helpers';
import {
  BtnWrapper,
  DDInputWrapper,
  DDTitleWrapper,
} from '../../SmartView/2023/styled';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import DropDownDownItemTags from './2023/DropDown';
import { actionOpenModal } from '../../../redux/user/action';
import {
  HiddenScrollBarArea,
  TagsDropDownContent,
  TagsDropDownEmptyMsg,
  TagsDropDownSeparator,
  TagsDropDownShell,
  TagsDropDownWrap,
} from './styled';

const buttonWidth = 32;

const TagsDropDown = ({
  tagsRef,
  tags,
  dragPageStart,
  endDrop,
  activeSieveOption,
  iconsToShow,
  toggleSideBars,
  parentRef,
  isLibrary,
  defaultPosition = { top: 24, left: 0 },
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dropDDPos, setDropDDPos] = useState({ x: -15, y: 0 });
  const [search, setSearch] = useState('');
  const [localTags, setLocalTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState({});
  const [exOptionRef, isExOpened, setIsExOpened] = useComponentVisible(
    false,
    DATA_PARENTS.tagList,
  );

  const filterWithDebounce = () => setLocalTags(
    sortTagsByLastModified(Object.values(tags)).filter((tag) => filterItems(tag, search),
    ),
  );
  const [throttle] = useThrottle(300);

  useEffect(() => {
    if (!search.trim()) setLocalTags(sortTagsByLastModified(Object.values(tags)));
    else {
      throttle(() => filterWithDebounce);
    }
  }, [tags, search]);

  const calculateHorizontalPosition = () => {
    if (parentRef && parentRef?.current) {
      const width = document.documentElement.clientWidth;
      const positionX = defaultPosition.left;
      const refAbsoluteX = parentRef?.current.getBoundingClientRect().x;
      const widthElement = 253 - buttonWidth;
      const isOpenInRight = width > refAbsoluteX + widthElement + positionX;
      if (isOpenInRight) return positionX;
      return positionX - widthElement;
    }
    return defaultPosition.left;
  };

  const styleDiv = isLibrary
    ? { left: -261, top: 36 }
    : { left: calculateHorizontalPosition() };

  const onInputChange = (event) => {
    setSearch(event.target.value.trim());
  };
  const onInputKeyDown = (event) => {
    if (event.key === 'Backspace' && !search) {
      event.currentTarget.blur();
    }
  };
  const onAddNewTagClickHandler = () => {
    dispatch(actionOpenModal(openModalType.TagModal2023));
  };

  const setPosition = (rect, tag) => {
    const { y } = rect;
    setIsExOpened(true);
    setDropDDPos((oldState) => ({ ...oldState, y: y - 120 }));
    setSelectedTag(tag);
  };

  return (
    <TagsDropDownShell
      data-cy="tags-wrapper"
      data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
    >
      {isExOpened && (
        <DropDownDownItemTags
          refMenu={exOptionRef}
          clickedTag={selectedTag}
          top={dropDDPos.y}
          left={dropDDPos.x}
        />
      )}
      <TagsDropDownWrap
        ref={tagsRef}
        data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
        style={styleDiv}
      >
        <DDTitleWrapper data-parent={DATA_PARENTS.PlaylistBuilderLibrary}>
          {t('manageTagsT')}
        </DDTitleWrapper>
        <DDInputWrapper
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          value={search}
          onChange={onInputChange}
          onBlur={onInputKeyDown}
          placeholder={t('searchATagT')}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        />
        <BtnWrapper
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          data-cy="add-tag"
          onClick={onAddNewTagClickHandler}
        >
          {t('addNewTagT')}
        </BtnWrapper>

        <TagsDropDownSeparator />

        {!!localTags.length && (
          <HiddenScrollBarArea
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            onScroll={() => {
              setIsExOpened(false);
              setSelectedTag({});
            }}
          >
            <TagsDropDownContent
              data-cy="tags-list"
              data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            >
              {localTags.map((tag, index) => (
                <TagsDropDownItem
                  data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
                  key={tag.id}
                  setPosition={setPosition}
                  isExOpened={isExOpened}
                  dragPageStart={dragPageStart}
                  endDrop={endDrop}
                  index={index}
                  tag={tag}
                  activeSieveOption={activeSieveOption}
                  iconsToShow={iconsToShow}
                  toggleSideBars={toggleSideBars}
                  selectedTagID={selectedTag?.id}
                />
              ))}
            </TagsDropDownContent>
          </HiddenScrollBarArea>
        )}

        {!localTags.length && !search && (
          <TagsDropDownEmptyMsg>{t('noTagsT')}</TagsDropDownEmptyMsg>
        )}
        {!localTags.length && !!search && (
          <TagsDropDownEmptyMsg>{t('noTagsFoundT')}</TagsDropDownEmptyMsg>
        )}
      </TagsDropDownWrap>
    </TagsDropDownShell>
  );
};

export default TagsDropDown;
