import styled from 'styled-components/macro';

export const LeftNav = styled.div`
  display: flex;
`;
//
export const Controls = styled.div`
  display: flex;
  align-items: center;
`;
export const AvatarPlaceHolder = styled.div`
 height: 1px;
 width: 140px;
`;

export const ActiveContent = styled.div`
  display: flex;
  max-width: 1036px;
  &, & > p{
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  width: calc(100vw - 360px);
  justify-content: space-between;
  
  @media (min-width: 1640px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -30px;
  }
`;

export const RoleDISPLAYOwner = styled.div`
  position: absolute;

  background-color: red;
`;
export const RoleDISPLAYCoedit = styled.div`
  background-color: blue;
  position: absolute;
`;
export const RoleDISPLAYView = styled.div`
  background-color: grey;
  position: absolute;
`;
export const ArrowWrapper = styled.div`
  margin-right: 17px;
  svg path {
    fill: #484343;
    fill-opacity: 1;
  }
  cursor: pointer;
  svg rect {
    fill: #484343;
    fill-opacity: 1;
  }

  ${({ isDisabled }) => (isDisabled
    ? `
  cursor:default;
  svg path {
 fill:rgba(52, 52, 52, 0.1);
}
  svg rect {
 fill:rgba(52, 52, 52, 0.1);
  }
  `
    : `
  `)}
  ${({ mT }) => (mT ? `margin-top:${mT}px;` : '')}
`;
export const PlayButton = styled.div`
  cursor: pointer;
  svg {
    width: 50px;
    height: 50px;
  }

  ${({ isDisabled }) => (isDisabled
    ? `
    cursor: no-drop;
    svg circle{
      fill: lightgrey;
    }
    &:hover svg circle{
      fill: lightgrey;
    }
  `
    : '')}
    ${({ isMaker }) => (isMaker
    ? `
      margin-left: 8px;

      svg {
        position: relative;
        top: 1px;
        width: 38px;
        height: 38px;
      }
      svg circle{
        fill: #fff;
      }
      &:hover svg circle{
        fill: #FFEEBF;
        stroke-width: 0.6px;
        stroke: #FFC72C;
      }
    `
    : '')}
`;
export const SmallVerticalSeparator = styled.div`
  width: 1px;
  height: 30px;
  background: rgba(29, 29, 29, 0.08);
  margin-left: 2px;
  margin-right: 20px;
  position: relative;
  top: -4px;
  ${({ theSecond }) => (theSecond
    ? `
    height: 22px;
    margin-left: 0px;
    margin-right: 10px;
    top: 0;
  `
    : '')}
  ${({ theThird }) => (theThird
    ? `
    height: 22px;
    margin-left: 10px;
    margin-right: 0px;
    top: 6px;
  `
    : '')}
  ${({ mT }) => (mT ? `margin-top:${mT}px;` : '')}
`;

export const SmallVerticalSeparatorInCuteHeader = styled.div`
  width: 1px;
  height: 22px;
  background: rgba(29, 29, 29, 0.08);
  margin: 0 3px 0 10px;
`;
export const ViewModeSwitcherWrap = styled.div`
  display: flex;
  align-items: center;
  ${({ forCutHeader }) => (forCutHeader
    ? `
  `
    : '')}
  ${({ forCutHeader, moved }) => (moved && forCutHeader
    ? `
    right: 9.2%;
  `
    : '')}
`;
export const ViewOptionShell = styled.div`
  display: flex;
  filter: drop-shadow(0px 1px 4px rgba(122, 105, 105, 0.1));
`;
export const SvgWrap = styled.div`
  background: #fff;
  border: 0.6px solid #ece2e2;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isLeft }) => (isLeft
    ? `border-radius: 6px 0px 0px 6px;
  border-right: 0;`
    : '')}
  ${({ isRight }) => (isRight
    ? `border-radius: 0px 6px 6px 0px;
  border-left: 0;`
    : '')}
  &:hover {
    background: #ffeebf;
  }
  ${({ isActive }) => (isActive ? 'background: #FFEEBF; border:0.6px solid #FFC72C;' : '')}

  &:active {
      background: #FFE9AD;
  }
`;
export const TOCSvgShell = styled.div`
  position: absolute;
  left: 27px;
  top: calc(50% + 33px);
  transform: translateY(-50%);
  z-index: 8;
  cursor: pointer;
`;

export const ViewModeWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const ChevronWrapper = styled.div`
  width: 18px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 0.6px solid #ECE2E2;
  background: #fff;
  cursor: pointer;
  :hover {
    background: rgb(255, 238, 191);
  }
`;

export const Popup = styled.div`
  position: absolute;
  z-index: 10012;
  ${({ top }) => (top ? `top:${top}px;` : '')}
  ${({ left }) => (left ? `left:${left}px;` : '')}
  height: 84px;
  border-radius: 12px;
  border: 0.6px solid #ECE2E2;
  background: #FFF;
  box-shadow: 1px 1px 6px 0px rgba(122, 105, 105, 0.25);
  padding: 12px 0;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 0.6px solid #ECE2E2;
    border-left: 0.6px solid #ECE2E2;
    padding: 5px 16px;
    cursor: pointer;
    height: 30px;
    gap: 10px;

    p {
      margin: 0; 
    }
    
    :hover {
      background: #FFEEBF;
    }
  }

  svg path {
    stroke: #484343;
  }
`;
