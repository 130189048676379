import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import rawStyles from './index.module.scss';
import { ReactComponent as ChevronSvg } from '../../images/icons/chevron_up_20.svg';
import { ReactComponent as CloseSvg } from '../../images/icons/close_12.svg';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { filterTitles } from '../../utils/helpers';

const cn = classNames.bind(rawStyles);
const languages = [
  { id: 1, title: 'English' },
  { id: 2, title: 'French' },
  { id: 3, title: 'Dutch' },
  { id: 4, title: 'German' },
  { id: 5, title: 'Spanish' },
];

const MINIMUM_HEIGHT = 54;
const EMPTY_HEIGHT = 70;
const MAXIMUM_HEIGHT = 180;
const CLOSED_HEIGHT = 0;
const ONE_ITEM_HEIGHT = 24;

const LanguageSelectorComponent = () => {
  const [chevyRef, isChevyActive, setIsChevyActive] = useComponentVisible(false);
  const disabled = true;
  const [activeItem] = useState(languages[0]);
  const [filter, setFilter] = useState('');
  const [height, setHeight] = useState(CLOSED_HEIGHT);
  const [displayItems, setDisplayItems] = useState([]);
  const inputRef = useRef();
  const { t } = useTranslation();

  const toggleChevy = () => {
    if (!disabled) {
      setIsChevyActive((prev) => !prev);
    }
  };

  const onInputChange = (e) => setFilter(e.target.value);

  const onCloseFilterClick = () => setFilter('');

  const pickAnItem = () => {
    // TBD
    // setActiveItem(displayItems[index]);
    // setIsChevyActive(false);
  };

  useEffect(() => {
    setDisplayItems(languages.filter((item) => filterTitles(item, filter)));
  }, [filter]);

  useEffect(() => {
    if (!isChevyActive) {
      setHeight(CLOSED_HEIGHT);
      setFilter('');
    } else {
      const itemsNumber = displayItems.length;
      let calcHeight;
      if (!itemsNumber) {
        calcHeight = EMPTY_HEIGHT;
      } else {
        calcHeight = MINIMUM_HEIGHT + itemsNumber * ONE_ITEM_HEIGHT;
      }
      setHeight(calcHeight >= MAXIMUM_HEIGHT ? MAXIMUM_HEIGHT : calcHeight);
      if (inputRef?.current) inputRef.current.focus();
    }
  }, [displayItems, filter, isChevyActive]);

  return (
    <div className={cn('wrapper')} ref={chevyRef}>
      <div className={cn('container', { disabled })} onClick={toggleChevy}>
        <div className={cn('display_item')}>{activeItem.title}</div>
        <div className={cn('chevron_container', { active: isChevyActive })}>
          <ChevronSvg />
        </div>
      </div>

      <div
        className={cn('drop_down_wrapper', { active: isChevyActive })}
        style={{ height }}
      >
        <div className={cn('search_bar')}>
          <div className={cn('input_wrapper')}>
            <input
              ref={inputRef}
              type="text"
              onChange={onInputChange}
              value={filter}
              placeholder={t('typeHereToSearchPlaceholderT')}
            />
            {!!filter && (
              <div className={cn('close_icon')} onClick={onCloseFilterClick}>
                <CloseSvg />
              </div>
            )}
          </div>
        </div>
        <PerfectScrollbar className={cn('scroll')}>
          <div className={cn('content')}>
            {displayItems.map((item, index) => (
              <div
                key={item.id}
                className={cn('item', 'disabled', {
                  active: item.id === activeItem.id,
                })}
                onClick={(e) => pickAnItem(e, index)}
              >
                {item.title}
              </div>
            ))}
            {!displayItems.length && (
              <div className={cn('item')}>{t('noResultsT')}</div>
            )}
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default LanguageSelectorComponent;
