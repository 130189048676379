import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';

function NewCardForm() {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error(error);
    } else {
      dispatch(actionCreator(Settings.AttachPaymentMethod, { methodId: paymentMethod.id, paymentMethod }));
    }
  };

  return (
    <form style={{ display: 'flex', gap: '20px' }} onSubmit={handleSubmit}>
      <div style={{ width: '80%' }}>
        <CardElement options={{ hidePostalCode: true }} />
      </div>
      <button type="submit" style={{}} disabled={!stripe}>
        Save Card
      </button>
    </form>
  );
}

export default NewCardForm;
