import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { chevronArrowType } from '../../../../utils/tableHelpers/tableUi';
import { sortedFunctions } from '../../../../utils/dateConvert';
import { emptyArr } from '../../../../utils/constants';
import EmojiListItem from './EmojiListItem';
import {
  EmptyMessage,
  Header,
  Item,
  TableShell,
} from '../../CommonComponents/Table';

const EmojisListTable = () => {
  const {
    tables: { emojis },
  } = useSelector((state) => state.settings);
  const [sortType, setSort] = useState(Object.keys(sortedFunctions)[2]);
  const [displayItems, setDisplayItems] = useState(emptyArr);
  const arraySortedKey = Object.keys(sortedFunctions);
  const { t } = useTranslation();

  useEffect(() => {
    setDisplayItems(sortedFunctions[sortType](Object.values(emojis)));
  }, [sortType, emojis]);

  const handlerClick = (type) => {
    if (arraySortedKey[type] === sortType) setSort(arraySortedKey[type + 1]);
    else setSort(arraySortedKey[type]);
  };

  return (
    <TableShell>
      <Header isEmojis>
        <Item onClick={() => handlerClick(0)}>
          {t('nameT')}
          {chevronArrowType(0, sortType)}
        </Item>
        <Item marginLeft>{t('emojiT')}</Item>
        <Item center>{t('actionsT')}</Item>
      </Header>
      {!!displayItems.length
        && displayItems.map((emoji) => (
          <EmojiListItem key={emoji.id} emoji={emoji} />
        ))}
      {!displayItems.length && (
        <EmptyMessage>{t('noEmojisT')}</EmptyMessage>
      )}
    </TableShell>
  );
};

export default EmojisListTable;
