import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GreyButton } from '../../CommonComponents/Buttons';
import FormInformer from '../../FormInformer';
// eslint-disable-next-line max-len
import AddFilePopUpClickOutsideWithUnderlay from '../../../../components/LibraryHeaderMenu/AddFilePopUpClickOutsideWithUnderlay';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';
import ValidationResultsPrinter from '../../CommonComponents/ValidationResultsPrinter';

const ColumnUpload = styled.div`
  position: relative;
  width: 415px;
  margin-bottom: ${({ isNoNotifications }) => (isNoNotifications ? 25 : 8)}px;
  display: flex;
  margin-top: 4px;
`;

const EmojiUploadInput = () => {
  const dispatch = useDispatch();
  const { inputs } = useSelector((state) => state.settings);
  const {
    forms: { emojiFileUpload },
  } = useSelector((state) => state.settings);
  const [isAddFile, setIsAddFile] = useState(false);
  const addFileRef = useRef(null);
  const inputFile = useRef(null);
  const isShowError = !!inputs?.emojiFile?.errors?.length && !inputs?.emojiFile.tmpImageBlob;
  const { t } = useTranslation();

  const submitForm = (e) => {
    dispatch(
      actionCreator(Settings.HandleUploadFileS, {
        file: e.target.files[0],
        inputType: 'emojiFile',
      }),
    );
    setIsAddFile(false);
    inputFile.current.value = '';
  };

  const onChooseFileButtonClick = (event) => {
    event.stopPropagation();
    inputFile?.current.click();
  };

  const onUploadButtonClick = () => {
    setIsAddFile(true);
  };

  return (
    <ColumnUpload
      isNoNotifications={
        !emojiFileUpload.errors.length || !emojiFileUpload.errors.length
      }
    >
      <GreyButton
        text={t('uploadEmojiT')}
        customStyles={{ height: 32, padding: '' }}
        onClick={onUploadButtonClick}
        parentRef={addFileRef}
        primaryGrey
      />
      {isAddFile && (
        <AddFilePopUpClickOutsideWithUnderlay
          parentRef={addFileRef}
          setIsAddFileActive={setIsAddFile}
          onButtonClick={onChooseFileButtonClick}
        />
      )}
      <input
        type="file"
        name="file"
        accept="image/*"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={(e) => {
          submitForm(e);
        }}
      />

      <FormInformer formName="emojiFileUpload" />

      <ValidationResultsPrinter
        isShowError={isShowError}
        errorMessage={inputs?.emojiFile?.errors[0]?.message}
        isEmojiUploadInput
      />
    </ColumnUpload>
  );
};

export default EmojiUploadInput;
