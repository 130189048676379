export const ApprovalBTNColorEnum = {
  green: '#1E9001',
  red: '#D33F49',
  orange: '#E56E00',
  purple: '#9F4C9B',
  blue: '#204EC3',
  black: '#484343',
} as const;

export const ApprovalBTNBackgroundColorEnum = {
  green: '#DBF5D5',
  red: '#FFE7E8',
  orange: '#FFEDDD',
  purple: '#FCE2FA',
  blue: '#E0E9FF',
  black: '#E7E7E7',
} as const;


export const ApprovalBTNHoverColorEnum = {
  green: 'rgba(219, 245, 213, 0.6)',
  red: 'rgba(255, 231, 232, 0.6)',
  orange: 'rgba(255, 237, 221, 0.6)',
  purple: 'rgba(252, 226, 250, 0.6)',
  blue: 'rgba(224, 233, 255, 0.6)',
  black: 'rgba(231, 231, 231, 0.6)',
} as const;

export const ApprovalBTNClickedColorEnum = {
  green: 'rgba(219, 245, 213, 1.4)',
  red: 'rgba(255, 231, 232, 1.4)',
  orange: 'rgba(255, 237, 221,1.4)',
  purple: 'rgba(252, 226, 250,1.4)',
  blue: 'rgba(224, 233, 255, 1.4)',
  black: 'rgba(231, 231, 231, 1.4)',
} as const;
