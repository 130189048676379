import styled from 'styled-components/macro';

export const ExpandWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 0.6px solid #ece2e2;
  box-shadow: 0 1px 4px rgba(122, 105, 105, 0.1);
  border-radius: 6px;
  cursor: pointer;
`;
export const CloseWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  cursor: pointer;
  &:hover {
    background-color: #ffe9ad;
  }
`;
export const TextWrap = styled.div`
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #343434;
  width: 70%;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px 4px 15px;
  font-family: "RobotoFlex";
  position: sticky;
  background: #fff;
  z-index: 5;
  top: 0;
  width: 100%;
}
`;
export const TOCShell = styled.div`
  margin-top: 67px;
  height: fit-content;
  transition: all ease-in-out 0.4s;
  box-sizing: content-box;
  position: absolute;
  left: 30px;
  max-height: 80vh;
  align-self: center;
  background: #fff;
  z-index: 10;
  border-radius: 8px;
  ${({ open }) => (open
    ? `
    width: 230px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
  `
    : `width:0;
  `)}
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    ${({ open }) => (open
    ? `width: 4px;
  `
    : `width:0;
  `)}
  }

  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track-piece {
    display: none;
    background-color: #fafaff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  flex-shrink: 0;
  ${({ open, shouldScroll }) => (!shouldScroll && open
    ? 'overflow: visible;' : '')}
`;
export const TOCShell2 = styled.div`
  width: 230px;
  min-width: 225px;
  box-sizing: border-box;
  background-color: white;
  // a likely solution for opening a dropdown
  direction: ltr;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(236, 226, 226, 0.50);
  max-height: 80vh;
`;

export const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 20px 16px 15px;
`;

export const EmptyStateText = styled.div`
  color: #D3C8C8;
  font-family: RobotoFlex, sans-serif;
  font-size: 14px;
  font-weight: 400;
`;

export const TrashZone = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 17px;
  left: 157px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TOCContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 22px;
  width: 100%;
`;

export const LastItem = styled.div`
  width: 100%;
  height: 25px;
`;
export const DNDElementWrap = styled.div`
  position: relative;
`;
export const DragLineWrap = styled.div`
  opacity: 0;
  position: absolute;
  transition-property:   opacity, top, left;
  left: 30px;
  top: 1px;
  transition: ease-out 0.25s;
  ${({ dragHover }) => (dragHover
    ? `
   opacity:1;
   top: 7px;
    left: 30px;

`
    : '')}}
`;
export const ThreeDots = styled.div`
  position: absolute;
  height: 28px;
  width: 28px;
  display: none;

  justify-content: center;
  align-items: center;
  border-radius: 6px;
  right: 10px;
  cursor: pointer;
  &:hover {
    background: #ffc72c;
  }
`;
export const SixDots = styled.div`
  display: none;
  cursor: grab;
  svg {
    position: relative;
    top: 1px;
    width: 20px;
    height: 20px;
  }
  // position: absolute;
`;

export const NoDrop = styled.div`
  cursor: no-drop;
`;

export const Box = styled.div`
  background:  ${({ isActive }) => (isActive ? '#FFC72C' : '#ffe9ad')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2px;
  height: 100%;
  min-height: 14px;
  margin: 0 11px;
  position: absolute;
`;

export const Counter = styled.div`
  width: 24px;
  height: 24px;
  background: #ffe9ad;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 28px;
  gap: 8px;
`;
export const NameText = styled.div`
  ${({ isRichText }) => (isRichText ? 'color: #939393; font-style: italic; font-weight: 300;' : '')}
  width: 155px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const Name = styled.div`
  font-weight: 400;
  font-size: 12px;
  padding-left: 30px;
  color: #484343;
  display: flex;
  gap: 7px;
  ${({ shifted }) => (shifted ? 'margin-left:18px ' : '')};
  ${({ isPlayer }) => (isPlayer ? 'padding-right: 30px;' : '')}
  ${({ isTitle }) => (isTitle ? 'font-size: 14px; font-weight: 600; font-family: "Roboto", sans-serif;' : '')}
`;

export const SvgWrap = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  ${({ isCardView }) => (isCardView ? `
    top: 11px;
    background: #fff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    display: flex;
    ` : '')};
    ${({ isViewed }) => (isViewed ? `
      svg path {
        stroke: #1FAE09
      }
    ` : '')};
`;

export const DragWrap = styled.div`
  width: 100%;
  transition-property: padding;
  transition: ease-out 0.25s;
  ${({ dragHover }) => (dragHover
    ? `
     padding-top: 36px;
  `
    : '')}
`;
export const TOCShellItem = styled.div`
  padding-left: 5px;
  position: relative;
  display: flex;
  padding: ${({ isTitle }) => (isTitle ? '7px 0;' : '3px 0;')};
  align-items: center;
  cursor: pointer;
  height: fit-content;
  width: 100%;
  .actions_fluent {
    display: none;
    .svg_wrapper {
      right: 12px;
      top: 2px;
      svg {
        position: relative;
        top: -1px;
      }
    }
  }

  &:hover {
    .actions_fluent {
      display: block;
    }
    background: #ffe9ad;

    ${Box} {
      width: 24px;
      margin: 0;
    }
    ${SixDots} {
      display: block;
    }
    ${Counter} {
      display: none;
    }
    ${Name} {
      width: 234px;
    }
    ${ThreeDots} {
      display: flex;
    }
  }
  ${({ isActive }) => (isActive
    ? `
      .actions_fluent{
      display:block;
      background:#FFC72C;
    }
    background: #FFE9AD;
    
    ${SixDots}{
      display: block;
    }
    ${Counter}{
      display: none;
    }
    ${Name}{
      width: 234px;

    }
    ${ThreeDots}{
     display: flex;

    }
  `
    : '')}
`;

export const DownLine = styled.div`
  position: absolute;
  background: #ffe9ad;
  height: 12px;
  top: 28px;
  left: 44px;
  transition-property: height;
  transition: ease-out 0.25s;
  ${({ dragHover }) => (dragHover
    ? `
    height: 47px;
  `
    : '')}
`;
