import React from 'react';

const FolderSvgFrontPart = React.memo(function FolderSvgFrontPart({ color, id }) {
  const key = id || '#single';
  const gradientFillLink = `url(#${key})`;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="140" height="115" viewBox="0 0 140 115" fill="none">
      <path d="M-0.00012298 10.4026L-0.000115957 104.616C-4.01173e-05 109.808 3.49996 115 7.56051 115L133 115C135.656 115 137.957 112.805 139.142 109.569C139.678 108.102 139.979 106.423 139.979 104.642L139.54 18.3719C139.517 13.9657 135.936 10.4071 131.53 10.4127L63.4764 10.4988C62.8357 10.4996 62.2051 10.3392 61.6426 10.0323C60.7262 9.53222 60.0753 8.67045 59.711 7.69208C57.9665 3.00722 54.4494 -7.47728e-06 50.5568 -7.81758e-06L7.56052 -1.15764e-05C6.67014 -1.16543e-05 5.82504 0.212746 5.03276 0.593466C2.09755 2.01557 -0.000122584 5.87876 -0.00012298 10.4026Z" fill={gradientFillLink} />
      <defs>
        <linearGradient id={key} x1="38.5" y1="-65.9437" x2="38.5" y2="103" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" />
          <stop offset="1" stopColor={color?.dark ?? '#FFC72C'} />
        </linearGradient>
      </defs>
    </svg>
  );
});

export default FolderSvgFrontPart;
