import React from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { ReactComponent as CheckboxOff16Svg } from '../../images/icons/checkbox_off_16.svg';
import { ReactComponent as CheckboxOn16Svg } from '../../images/icons/checkbox_on_16.svg';

const cn = classNames.bind(styles);

const DynamicCollectionDropListOption = (props) => {
  const clickHandler = (e, item) => {
    props.setFilter((prev) => {
      const newState = { ...prev.fileType };

      if (newState[item]) {
        delete newState[item];
      } else {
        newState[item] = props.option[item];
      }
      return { ...prev, fileType: { ...newState } };
    });

    e.stopPropagation();
  };

  return (
    <div
      ref={props.refParent}
      className={cn('drop_list_wrapper', {
        active: props.active,
        is_types: props.type === 'File Types',
      })}
      data-parent={props.data}
    >
      <div className={cn('drop_list_container')}>
        <div className={cn('drop_list_title')}>
          {props.title || `All ${props.type}`}
        </div>
        <div className={cn('drop_list_main')}>
          {Object.keys(props.option).map((item) => (
            <div
              key={item}
              className={cn('list_item_container')}
              onMouseDown={(e) => clickHandler(e, item)}
            >
              <div className={cn('list_item_checkbox')}>
                {!!props.filter && props.filter[item] && <CheckboxOn16Svg />}
                {!(props.filter && props.filter[item]) && <CheckboxOff16Svg />}
              </div>
              <div className={cn('list_item_title')}>{props.option[item]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DynamicCollectionDropListOption;
