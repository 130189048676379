import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Column, ColumnDropDownItemShell } from './StyledComponents';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import SupportAction from '../../../../redux/support/types';

const ColumnDropDownItem = ({
  item,
  setSelectedColumn,
  dataParent,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectHandler = (cl, event) => {
    event.stopPropagation();
    setSelectedColumn(cl);
    props.setIsMenuVisible(false);
    dispatch(
      actionCreator(SupportAction.SetActiveMenuItemId, { id: item?.id }),
    );
  };

  const sameColors = !!item?.color && item?.backgroundColor === item?.color;

  const name = item.customType === 'close' ? t('completedT') : item.name;
  return (
    <ColumnDropDownItemShell
      onClick={(event) => selectHandler(item, event)}
      data-parent={dataParent}
    >
      <Column background={item.backgroundColor} text={item.color} sameColors={sameColors}>
        <p>{name}</p>
      </Column>
    </ColumnDropDownItemShell>
  );
};

export default ColumnDropDownItem;
