import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import searchIcon from '../../images/icons/search.svg';
import clearSearch from '../../images/icons/clear_search.svg';
import { ReactComponent as PlaylistSvg } from '../../images/icons/replace_icons/Playlist.svg';
import { actionCreator } from '../../shared/redux/actionHelper';
import EditPlaylist from '../../redux/playlists/types';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { DEFAULT_TITLE } from '../../utils/constants';

const HEIGHT_OFFSET = 136;
const MENU_WIDTH = 240;
const LEFT_SIDE_BAR_WIDTH = 241;

const AddPlaylistOption = ({
  addPlaylistRef,
  itemId,
  linkPageId,
  isWholePlaylistOperation,
}) => {
  const playlists = useSelector((state) => state.playlists);
  const currentPage = useSelector((state) => state.currentPage);
  const menuRef = useRef(null);
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const [displayItems, setDisplayItems] = useState(
    Object.values(playlists).filter(
      (item) => !item.movedToTrash && !!item?.title?.includes(search),
    ),
  );
  const history = useHistory();
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const calculateHorizontalPosition = () => {
    if (addPlaylistRef && addPlaylistRef.current) {
      const refAbsoluteX = addPlaylistRef.current.clientLeft;
      const refClientX = addPlaylistRef.current.getBoundingClientRect().x;
      if (refClientX + 2 * MENU_WIDTH > width) return 30;
      if (refAbsoluteX - MENU_WIDTH - LEFT_SIDE_BAR_WIDTH < 0) return refAbsoluteX + MENU_WIDTH;
      if (refAbsoluteX - MENU_WIDTH < LEFT_SIDE_BAR_WIDTH) return MENU_WIDTH / 2;
      return refAbsoluteX - MENU_WIDTH;
    }
    return 0;
  };
  const calculateVerticalPosition = () => {
    if (addPlaylistRef && addPlaylistRef.current && !!menuRef.current) {
      const refClientY = addPlaylistRef.current.getBoundingClientRect().y;
      const refAbsoluteY = addPlaylistRef.current.clientTop;
      const refClientX = addPlaylistRef.current.getBoundingClientRect().x;
      const menuHeight = menuRef.current.offsetHeight - HEIGHT_OFFSET / 2;

      if (refClientX + 2 * MENU_WIDTH > width) return 30;
      if (refClientY - menuHeight - HEIGHT_OFFSET < 0) return 0;
      if (refClientY + menuHeight > height) return 0;
      return refAbsoluteY - menuHeight;
    }
    return 0;
  };

  const [divStyle, setDivStyle] = useState({
    top: calculateVerticalPosition(),
    left: calculateHorizontalPosition(),
  });

  useEffect(() => {
    dispatch(
      actionCreator(EditPlaylist.RequestUserPlaylists, { parentId: itemId }),
    );
  }, []);

  useEffect(() => {
    setDisplayItems(
      Object.values(playlists).filter(
        (item) => !item.movedToTrash && !!item?.title?.includes(search),
      ),
    );
  }, [playlists, search]);

  useEffect(() => {
    setDivStyle({
      top: calculateVerticalPosition(),
      left: calculateHorizontalPosition(),
    });
  }, [width]);

  const movePageToPlaylist = (playlistId, title) => {
    if (isWholePlaylistOperation) {
      dispatch(
        actionCreator(EditPlaylist.AddAllDownloadableItemsIntoPlaylist, {
          fromPlaylist: currentPage.id,
          history,
          toPlaylist: playlistId,
          title,
        }),
      );
      return;
    }
    dispatch(
      actionCreator(EditPlaylist.AddPageToPlaylist, {
        playlistId,
        currentPageId: itemId || currentPage.id,
        history,
        isItemId: !!itemId,
        linkPageId,
      }),
    );
  };
  return (
    <div
      data-parent="miniPage"
      className={`${styles.move_page} ${search && styles.move_page_active}`}
      style={divStyle}
      onClick={(e) => e.stopPropagation()}
      ref={menuRef}
    >
      <div
        data-parent="miniPage"
        className={`${styles.editMenu__input} ${styles.editMenu__item_first}`}
      >
        <div data-parent="miniPage" className={styles.search_wrapper_input}>
          <img
            data-parent="miniPage"
            src={searchIcon}
            alt="search"
            className={styles.search_wrapper_input_search_icon}
          />
          <input
            data-parent="miniPage"
            placeholder={t('addToSmartFilePlaceholderT')}
            value={search}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setSearch(e.target.value)}
            className={`${styles.search_input}`}
          />
          {search && (
            <img
              data-parent="miniPage"
              className={styles.clear_search}
              src={clearSearch}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSearch('');
              }}
              alt=""
            />
          )}
        </div>
      </div>

      {!!displayItems.length && (
        <div data-parent="miniPage">
          <PerfectScrollbar
            option={{
              wheelPropagation: false,
            }}
            className={styles.ps_menu_height}
          >
            {displayItems.map((res) => (
              <div
                data-parent="miniPage"
                onClick={(e) => {
                  e.stopPropagation();
                  movePageToPlaylist(res.id, res.title);
                }}
                key={res.id}
                className={`${styles.editMenu__item}`}
              >
                <div className={styles.image_wrapper}>
                  <PlaylistSvg />
                </div>
                <div
                  data-parent="miniPage"
                  className={`${styles.editMenu__text} ${styles.playlists}`}
                >
                  {res.title ? res.title : t('defaultSmartFileTitleT')}
                </div>
              </div>
            ))}
            <div data-parent="miniPage" className={styles.end} />
          </PerfectScrollbar>
        </div>
      )}

      {!displayItems.length && (
        <div className={styles.no_found}>
          {!search && t('noSmartFilesT')}
          {!!search && t('noSmartFilesFoundT')}
        </div>
      )}
    </div>
  );
};

export default AddPlaylistOption;
