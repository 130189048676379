import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import {
  SvgWrap,
  ViewModeSwitcherWrap,
  ViewOptionShell,
  ViewModeWrapper,
  ChevronWrapper,
  Popup,
} from './sharedStyled';
import { NewUPVViewModes } from '../../utils/constants';
import { ReactComponent as ListSvg } from '../../images/icons/icon_18/view_list.svg';
import { ReactComponent as GridSvg } from '../../images/icons/icon_18/view_grid.svg';
import { ReactComponent as ExpandedSvg } from '../../images/icons/icon_18/expand.svg';
import { ReactComponent as CheckSvg } from '../../images/icons/upload_check_20.svg';
import { ReactComponent as ChevronSVG } from '../../images/2023/svg/small/complete/chevron_10_6.svg';
import Tooltip from '../../shared/Tooltips/Tooltip';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { actionToggleSettingPreviewRS } from '../../redux/currentPage/action';

const PreviewSettingsPopup = ({ popupBtnRef, popupDotRef }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const position = popupBtnRef.current?.getBoundingClientRect();
  const top = position?.top + 40;
  const left = position?.left - 105;
  const { manager, wrapperId } = useSelector((state) => state.currentPage);
  const onClick = (type) => {
    const state = type === 'userHideApprovalButton' ? (!manager?.userHideApprovalButton)
      : (!manager?.userHideDateAvatar);
    dispatch(actionToggleSettingPreviewRS(manager?.id ?? wrapperId, type, state));
  };
  return (
    <Popup top={top} left={left} ref={popupDotRef}>
      <div onClick={() => onClick('userHideDateAvatar')}>
        <p>{t('AvatarsDatesT')}</p>
        {!manager?.userHideDateAvatar && <CheckSvg />}
      </div>
      <div onClick={() => onClick('userHideApprovalButton')}>
        <p>{t('ApprovalButtonsT')}</p>
        {!manager?.userHideApprovalButton && <CheckSvg />}
      </div>
    </Popup>
  );
};

const SwitchModeAndPublishBlock = ({
  viewMode,
  setViewMode,
  forCutHeader,
  isLargeRightBar,
  isShowLibraryWidget,
  moveLeft,
}) => {
  const { t } = useTranslation();
  const [popupDotRef, isPopupActive, setPopupActive] = useComponentVisible(false, 'miniPage');
  const moved = moveLeft || isShowLibraryWidget;
  const popupBtnRef = useRef(null);

  const PreviewSettingsPopupWrapper = () => (
    <PreviewSettingsPopup popupBtnRef={popupBtnRef} popupDotRef={popupDotRef} />
  );

  return (
    <ViewModeSwitcherWrap
      forCutHeader={forCutHeader}
      moved={moved}
    >
      {!isLargeRightBar && (
        <ViewModeWrapper>
          <ViewOptionShell>
            <SvgWrap
              isActive={viewMode === NewUPVViewModes.compressed}
              isLeft
              onClick={() => setViewMode(NewUPVViewModes.compressed)}
            >
              {' '}
              <Tooltip
                text={t('listViewT')}
                direction={forCutHeader ? 'up' : 'down'}
              >
                <ListSvg />
              </Tooltip>
            </SvgWrap>

            <SvgWrap
              isActive={viewMode === NewUPVViewModes.card}
              onClick={() => setViewMode(NewUPVViewModes.card)}
            >
              {' '}
              <Tooltip
                text={t('cardViewT')}
                direction={forCutHeader ? 'up' : 'down'}
              >
                <GridSvg />
              </Tooltip>
            </SvgWrap>
            <SvgWrap
              isActive={viewMode === NewUPVViewModes.expanded}
              onClick={() => setViewMode(NewUPVViewModes.expanded)}
              isRight
            >
              {' '}
              <Tooltip
                text={t('expandViewT')}
                direction={forCutHeader ? 'up' : 'down'}
              >
                <ExpandedSvg />
              </Tooltip>
            </SvgWrap>
          </ViewOptionShell>
          <ChevronWrapper onClick={() => setPopupActive(!isPopupActive)} ref={popupBtnRef}>
            <ChevronSVG />
          </ChevronWrapper>
        </ViewModeWrapper>
      )}
      {isPopupActive && createPortal(PreviewSettingsPopupWrapper(), document.body)}
    </ViewModeSwitcherWrap>
  );
};

export default SwitchModeAndPublishBlock;
