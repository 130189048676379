import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PlaySVG } from '../../images/icons/player/Play.svg';
import { ReactComponent as PauseSVG } from '../../images/icons/player/pause_3.svg';
import { ReactComponent as GoBackSVG } from '../../images/2023/svg/upv/control_arrow_left_17_18.svg';
import { ReactComponent as GoForwardSVG } from '../../images/2023/svg/upv/control_arrow_right_17_18.svg';
import { CPSVGWrapper, PlayBTNWrapper } from './styled';
import { traceStackPush } from '../../utils/helpers';

const radius = 31; // Radius of the circular progress bar
const circumference = 2 * Math.PI * radius; // Circumference of the circle
const strokeDashoffset = (percent) => circumference * (1 - percent / 100);
let interval;
const PLAYER = {
  PAUSED: 'PAUSED',
  RESUMED: 'RESUMED',
};

export const CircularProgressBar = React.memo(function CircularProgressBar({ setIsEnd, currentItem }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const isForSharedToWeb = history.location.pathname.includes('shared_player');

  const { id: playlistId, channelId, itemNumber } = useParams();
  const currentItemNumber = parseInt(itemNumber);

  const {
    linkPages = [],
  } = useSelector((state) => state.currentPage);

  const [currentIndexEx, setCurrentIndexEx] = useState(currentItemNumber);
  const [currentRelativeSecondsTime, setCurrentRelativeSecondsTime] = useState(0);
  const [dashoffset, setDashoffset] = useState(0);
  const [playerStatus, setPlayerStatus] = useState(PLAYER.PAUSED);


  const stopResumeRelativeClocks = () => {
    if (currentRelativeSecondsTime >= currentItem?.duration) return;
    setPlayerStatus((prevState) => (prevState === PLAYER.PAUSED ? PLAYER.RESUMED : PLAYER.PAUSED),
    );
  };

  const goToLeftSection = () => {
    setCurrentRelativeSecondsTime(0);
    setCurrentIndexEx((prev) => {
      if (prev - 1 > 0) {
        return prev - 1;
      }
      return 1;
    });
  };

  const gotToRightSection = () => {
    setCurrentRelativeSecondsTime(0);
    setCurrentIndexEx((prev) => {
      if (prev + 1 > linkPages.length) {
        setIsEnd(true);
        return prev;
      }
      return prev + 1;
    });
  }

  const btnKeyDownHandler = (e) => {
    if (e.code === 'Space' || e.keyCode === 32) {
      stopResumeRelativeClocks();
    }
    if (e.key === 'ArrowRight') {
      gotToRightSection();
    }
    if (e.key === 'ArrowLeft') {
      goToLeftSection();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', btnKeyDownHandler);
    return () => {
      document.removeEventListener('keydown', btnKeyDownHandler);
    };
  }, [linkPages.length]);

  useEffect(() => {
    isForSharedToWeb
      ? traceStackPush(
        `/shared_player/shared/${playlistId}/${currentIndexEx}`,
        history,
        { type: 'page' },
        dispatch,
      )
      : traceStackPush(
        `/player/${channelId}/${playlistId}/${currentIndexEx}`,
        history,
        { type: 'page' },
        dispatch,
      );
  }, [currentIndexEx]);

  useEffect(() => {
    setDashoffset(strokeDashoffset(((90 / (currentItem?.duration)) * currentRelativeSecondsTime)) || 0);
    if (
      currentRelativeSecondsTime >= currentItem?.duration
      && !currentItem
    ) {
      setPlayerStatus(PLAYER.PAUSED);
      return;
    }
    if (currentRelativeSecondsTime >= currentItem?.duration) {
      gotToRightSection();
    }
  }, [currentRelativeSecondsTime]);

  useEffect(() => {
    if (playerStatus === PLAYER.RESUMED) {
      interval = setInterval(() => {
        setCurrentRelativeSecondsTime((prev) => prev + 0.05);
      }, 50);
    } else if (interval) clearInterval(interval);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [playerStatus, itemNumber]);

  useEffect(() => {
    return () => {
      if (interval) {
        setCurrentRelativeSecondsTime(0);
      }
    };
  }, [itemNumber]);

  useEffect(() => {
    setCurrentIndexEx(currentItemNumber);
  }, [itemNumber]);


  return (
    <PlayBTNWrapper>
      <GoBackSVG className="arrows" onClick={goToLeftSection} />
      <svg width={2 * radius} height={2 * radius}>
        <circle
          cx={radius}
          cy={radius}
          r={radius - 4} // Adjust for the stroke width
          stroke="#FFEEBF"
          strokeWidth="4"
          fill="transparent"
        />
        <circle
          cx={radius}
          cy={radius}
          r={radius - 4}
          stroke="#FFC72C"
          strokeWidth="4"
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={dashoffset}
          transform={`rotate(-90 ${radius} ${radius})`} // Rotate by -90 degrees to start from 12 o'clock
        />
      </svg>
      <CPSVGWrapper onClick={stopResumeRelativeClocks} isPlay={playerStatus === PLAYER.RESUMED}>
        { playerStatus === PLAYER.RESUMED ? <PauseSVG /> : <PlaySVG />}
      </CPSVGWrapper>
      <GoForwardSVG className="arrows" onClick={gotToRightSection} />
    </PlayBTNWrapper>
  );
});
