import { MiniPages } from './types';
import downloadStatus from '../../utils/dataUtil';
import { ServiceUser } from '../user/types';

const defaultState = {};

export const miniPagesReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case MiniPages.getMiniPage: {
      state[payload.id] = { status: downloadStatus.pending };
      return { ...state };
    }
    // case MiniPages.getMiniPagesClear: {
    //   if (payload.notDeleted?.length) {
    //     const newState = {};
    //     payload.notDeleted.forEach(i => newState[i] = state[i]);
    //     return newState;
    //   }
    //   return {};
    // }
    case MiniPages.saveMiniPage: {
      state[payload.id] = payload;
      state[payload.id].status = downloadStatus.success;
      return { ...state };
    }
    case MiniPages.togglePublishMiniPage: {
      return state;
    }
    case ServiceUser.Logout: {
      return { ...defaultState };
    }
    default:
      return state;
  }
};
