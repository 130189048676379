import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as More16Svg } from '../../../../images/icons/more_16.svg';
import useComponentVisible from '../../../../utils/hooks/useComponentVisible';
import MENU_OPTIONS from '../../../../components/DropDownOption/MenuOptions/PlaylistMenuOptions';
import PlaylistOptionWithX from '../../../../components/DropDownOption/MenuOptions/PlaylistOptionWithX';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';
import ValidationResultsPrinter from '../../CommonComponents/ValidationResultsPrinter';
import { Item, Row } from '../../CommonComponents/Table';

const RenameInput = styled.input`
  border: none;
  width: 100%;
`;

const More = styled.div`
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 6px;

  &:hover {
    background: #d7ddf7;
  }

  ${({ isActive }) => (isActive ? 'background: #d7ddf7;' : '')}
`;

const Notifications = styled.div`
  position: absolute;
  width: 0;
  left: 101%;
`;

const EmojiListItem = ({ emoji }) => {
  const DATA_PARENT = `emoji_${emoji.id}`;
  const {
    inputs: { currentEmojiRename },
    tables: { emojisErrors },
  } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const [moreRef, isShowMore, setIsShowMore] = useComponentVisible(
    false,
    DATA_PARENT,
  );
  const [renameRef, isShowRename, setIsShowRename] = useComponentVisible(
    false,
    DATA_PARENT,
  );
  const renameInputRef = useRef(null);
  const isShowError = !!emojisErrors[emoji.id]?.length;
  const emojiAltName = (name) => `emoji_${name}`;

  const onRenameClick = () => {
    if (isShowMore) setIsShowMore(false);
    dispatch(
      actionCreator(Settings.Preferences.SetRenameEmojiNameR, {
        inputType: 'currentEmojiRename',
        inputValue: emoji.name,
      }),
    );
    setIsShowRename(true);
    setTimeout(() => {
      renameInputRef.current.focus();
    }, 0);
  };
  const allErrors = emojisErrors[emoji.id] ?? [];

  const onRemoveClick = () => {
    dispatch(
      actionCreator(Settings.Preferences.RemoveUserEmoji, {
        emojiId: emoji.id,
        emojiName: emoji.name,
      }),
    );
  };

  const onMoreClick = () => {
    setIsShowMore(true);
  };

  const onRenameChange = (event) => {
    dispatch(
      actionCreator(Settings.Profile.ChangeValue, {
        inputType: 'currentEmojiRename',
        inputValue: event.target.value,
      }),
    );
  };

  const onBlur = () => {
    dispatch(
      actionCreator(Settings.Preferences.EmojiNameRenameCheckAndSave, {
        inputType: 'currentEmojiRename',
        emojiId: emoji.id,
      }),
    );
    setIsShowRename(false);
  };

  const onRenameKeyDown = (event) => {
    if (event.key === 'Enter' && isShowRename) {
      onBlur();
    }
  };

  return (
    <Row key={emoji.id} isActive={isShowMore}>
      <Item ref={renameRef} onDoubleClick={onRenameClick}>
        :
        {isShowRename ? (
          <RenameInput
            ref={renameInputRef}
            type="text"
            value={currentEmojiRename.value}
            onChange={onRenameChange}
            onBlur={onBlur}
            onKeyDown={onRenameKeyDown}
          />
        ) : (
          emoji.name
        )}
        :
      </Item>
      <Item marginLeft>
        <img src={emoji.url} alt={emojiAltName(emoji.name)} />
      </Item>
      <Item center>
        <More ref={moreRef} onClick={onMoreClick} isActive={isShowMore}>
          <More16Svg />
        </More>
        {isShowMore && (
          <PlaylistOptionWithX
            parentRef={moreRef}
            menuOptions={[
              MENU_OPTIONS.renameEmoji(onRenameClick, DATA_PARENT),
              MENU_OPTIONS.removeEmoji(onRemoveClick, DATA_PARENT),
            ]}
            isEmojiRow
          />
        )}
      </Item>
      <Notifications>
        <ValidationResultsPrinter
          isShowError={isShowError}
          errorMessage={allErrors[0]?.message}
        />
      </Notifications>
    </Row>
  );
};

export default EmojiListItem;
