import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../../components/PlaylistContentSelector/index.module.css';
import manyPagesStyles from '../ManyPages/index.module.css';
import tableStyles from '../../components/LibraryMiniPage/index.module.css';
import { ReactComponent as PlaylistIconSvg } from '../../images/icons/replace_icons/Playlist.svg';
import { ReactComponent as PlusSvg } from '../../images/icons/add_component.svg';
import HelpDragHandler from './HelpDragHandler';
import RecentFilterGroupInHelp from '../../components/RecentComponents/InHelp/RecentFilterGroup';
import NewPlaylist2023 from '../../components/MiniPlaylist/NewPlaylist2023/NewPlaylist2023';
import { FluentTableView } from '../../components/MiniPlaylist/NewPlaylist2023/table/FluentTableView';
import { PlaylistGap } from '../../components/MiniPlaylist/NewPlaylist2023/styled';

const HelpContentSelector = ({
  sortedPages,
  isSimplifiedMultiselect,
  setSimplifiedMultiselect,
  counterSelectedPage,
  needToDelete,
  selectorType,
  isCardsView,
  setSort,
  sortType,
  isFilter,
  isFavoriteEmpty,
  filter,
  displayedPages,
  refSelectedBlocks,
  createPage,
  totalFavorites,
  loading,
  channelId,
  isChannelMiniPlaylist,
  permissions,
  isDescriptionOpen,
  ...props
}) => {
  const { t } = useTranslation();
  const { isCardView } = useSelector((state) => state.currentPage);
  const { isAdmin } = useSelector((state) => state.user);
  const getTableStyles = () => {
    return !isCardsView
      ? `${styles.recent_list_view_block} ${tableStyles.outerTableWrapper}`
      : '';
  };

  const isShowCreateNewPlaylist = !!isAdmin && isCardsView;
  return (
    <>
      {!!sortedPages?.length && (
        <div className={`${!isCardsView ? styles.row_list_spacing : ''}`}>
          <div
            className={`${styles.is_help} ${isCardsView ? styles.recent_grid_row_container : ''}
            ${getTableStyles()}`}
          >
            {isShowCreateNewPlaylist && (
              <div
                onClick={createPage}
                className={`${manyPagesStyles.create_new_playlist}
                  ${!isCardsView && manyPagesStyles.create_new_page_list}`}
              >
                <PlaylistIconSvg
                  className={`${manyPagesStyles.icon_page_40} ${manyPagesStyles.new_playlist_icon}`}
                />
                <div className={manyPagesStyles.add_new_page}>
                  <PlusSvg className={`${manyPagesStyles.icon_page_20}`} />
                  <div className={manyPagesStyles.new_page_text}>{t('smartFileUpT')}</div>
                </div>
              </div>
            )}
            {sortType === 'recent' && (
              <RecentFilterGroupInHelp
                isCardsView={isCardsView}
                props={props}
                sortedPages={sortedPages}
                refSelectedBlocks={refSelectedBlocks}
                isAdmin={isAdmin}
                isSimplifiedMultiselect={isSimplifiedMultiselect}
                setSimplifiedMultiselect={setSimplifiedMultiselect}
                counterSelectedPage={counterSelectedPage}
                needToDelete={needToDelete}
                channelId={channelId}
                isChannelMiniPlaylist={isChannelMiniPlaylist}
                permissions={permissions}
                isHelp
                cannotBeDeleted={!isAdmin}
                isDescriptionOpen={isDescriptionOpen}
              />
            )}
            {sortType !== 'recent'
              && isCardView
              && !!sortedPages.length
              && sortedPages.map((page, index, arr) => (
                <PlaylistGap
                  key={page.id}
                  className={`${
                    !isCardsView ? styles.fullParentWidth : styles.relative
                  }`}
                  ref={(ref) => refSelectedBlocks.current.push({
                    ref,
                    pageId: page.id,
                    folderId: page.folderId,
                  })
                  }
                >
                  {isAdmin && (
                    <HelpDragHandler
                      previousItemId={arr[index - 1]?.id}
                      currentItemId={page?.id}
                      nextItemId={arr[index + 1]?.id}
                    />
                  )}
                  <NewPlaylist2023
                    item={page}
                    isSimplifiedMultiselect={isSimplifiedMultiselect}
                    setSimplifiedMultiselect={setSimplifiedMultiselect}
                    isDescriptionOpen={isDescriptionOpen}
                  />
                </PlaylistGap>
              ))}
            {!isCardView && sortType !== 'recent' && (
              <FluentTableView
                setSort={setSort}
                isSimplifiedMultiselect={isSimplifiedMultiselect}
                setSimplifiedMultiselect={setSimplifiedMultiselect}
                isDescriptionOpen={isDescriptionOpen}
                data={sortedPages}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default HelpContentSelector;
