import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { ReactComponent as LibrarySvg } from '../../../images/icons/icon_50/Library.svg';

import { placeSelector } from '../../../utils/helpers';
import {
  calcIsDynamicCollection,
  DATA_PARENTS,
} from '../../../utils/constants';
import { actionShowLibraryWidget } from '../../../redux/currentPage/action';
import { actionChangeSieveValue } from '../../../redux/library/actions';
import Tooltip from '../../../shared/Tooltips/Tooltip';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { ContentActionType } from '../../../redux/content/contentTypes';
import { sortedFunctions } from '../../../utils/dateConvert';
import { isRoleInPlaylist } from '../../../utils/permissions';

const cn = classNames.bind(styles);
const defColor = {
  svgActive: '#242633',
  svg: '#596080',
  background: '#FFEEBF',
};
const Tab = styled.div`
  position: ${({ isPage }) => (isPage ? 'absolute' : 'relative')};
  //width: 32px;
  //height: 32px;
  border: 1px solid transparent;
  border-radius: 8px;
  margin: 0 auto;
  ${({ isLast }) => (isLast ? 'margin-bottom: 0;' : '')}
  ${({ isLibrary }) => (isLibrary ? 'margin-right: 8px;' : 'margin-right: 0;')}

  ${({ isHiddenItem }) => (isHiddenItem ? 'margin: 0;' : '')}
  ${({ isHiddenItem }) => (isHiddenItem ? 'margin: 0;' : '')}

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive, color, isHiddenItem }) => {
    if (isHiddenItem) return isActive ? '#E8EBFA' : 'transparent';
    return isActive ? color.background : 'transparent';
  }};

  ${({ forSecondaryMenu }) => (forSecondaryMenu
    ? `
    display: flex;
    justify-content: center;
    background: white;
    border: 0.6px solid #ECE2E2;
    border-radius: 6px;
  `
    : '')}

  svg g {
    rect:first-child {
      ${({ activeStyles }) => (activeStyles ? 'fill: #FFEEBF;' : 'fill: white')}
    }
    rect:nth-child(2) {
      ${({ activeStyles }) => activeStyles && 'stroke: #343434'}
    }
    rect:last-child {
      ${({ activeStyles }) => activeStyles && 'stroke: #FFC72C'}
    }
  }
`;
const Placeholder = styled.div`
  position: relative;
  border: 1px solid transparent;
  border-radius: 8px;
  margin: 0 0 12px auto;
  width: 58px;
  height: 58px;
  background-color: transparent;
`;
const CommentsTooltipWrapper = styled.div`
  position: absolute;
  bottom: -143px;
`;

const defaultSortType = Object.keys(sortedFunctions)[2];

const PlaylistSideBarTabs = ({
  isLibrary,
  forSecondaryMenu,
  currentRole,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const windowDimensions = useWindowDimensions();
  const { isShowLibraryWidget } = useSelector((state) => state.currentPage);
  const { activeSieveOption } = useSelector((state) => state.library);
  const [tooltipHover, setTooltipHover] = useState(true);
  // const [hiddenIcons, setHiddenIcons] = useState([]);
  // const [tabsRef, isMoreOpened, setIsMoreOpened] = useComponentVisible(false,
  //   DATA_PARENTS.PlaylistBuilderLibrary);
  const [refMenu, isActive, setIsActive] = useComponentVisible(
    false,
    DATA_PARENTS.PlaylistBuilderLibrary,
  );
  const [tagsRef, isTagsOpened, setIsTagOpened] = useComponentVisible(
    false,
    DATA_PARENTS.tagList,
  );
  const smartViewModal = useComponentVisible(
    false,
    DATA_PARENTS.smartview,
    true,
  );
  const history = useHistory();
  const { isOwner, isCoEdit, isViewer } = isRoleInPlaylist;
  const { sortType = defaultSortType } = useSelector((state) => state.content);
  const setPreviewContentQuery = ({
    newActiveNavSlider,
    newSelectorType,
    newSortType,
  }) => {
    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        {
          activeNavSlider: newActiveNavSlider,
          selectorType: newSelectorType,
          sortType: newSortType || sortType,
          isNeedUpdate: true,
        },
        'SideBarTabs',
      ),
    );
  };

  // useEffect(() => {
  // const dynamicCollections = Object.values(dynamicCollection || {}).sort((a, b) => b.position - a.position);
  // if (!dynamicCollections.length) return;
  // const availableSpace = windowDimensions.height - TABS_MENU_TOP_SHIFT - TABS_MENU_BOTTOM_SHIFT;
  // const numberOfVisibleItems = Math.floor(availableSpace / ONE_ICON_HEIGHT);
  // const hiddenItems = dynamicCollections
  //   .splice(numberOfVisibleItems, dynamicCollections.length - numberOfVisibleItems);
  // setIconsToShow(dynamicCollections);
  // setHiddenIcons(hiddenItems);
  // }, [dynamicCollection, windowDimensions.height]);

  useEffect(() => {
    if (isActive) {
      setTooltipHover(false);
    } else {
      setTooltipHover(true);
    }
  }, [isActive]);

  const toggleSideBars = (e, place, id) => {
    e.stopPropagation();
    e.preventDefault();
    if (props.isShowFilter) props.setIsShowFilter(false);
    if (isTagsOpened) setIsTagOpened(false);
    if (smartViewModal.isComponentVisible) smartViewModal.setIsComponentVisible(false);

    if (place === 'text' || place === 'library' || place === 'pages') {
      setPreviewContentQuery({
        newActiveNavSlider: 'pages',
        newSelectorType: 'all',
      });

      dispatch(actionChangeSieveValue(placeSelector(place), 'library'));
    } else if (place === 'tag') {
      if (isLibrary) {
        setPreviewContentQuery({
          newActiveNavSlider: 'tag',
          newSelectorType: id.id,
        });
        dispatch(
          actionChangeSieveValue(
            { name: id.title, id: id.id, mode: 'tag' },
            'library',
          ),
        );
        history.push(`/content/library/tag_${id.id}`);
      } else {
        setPreviewContentQuery({
          newActiveNavSlider: 'tag',
          newSelectorType: id,
        });
        dispatch(actionChangeSieveValue(place, 'library'));
      }
    } else if (place === 'comments') {
      // do smth
    } else {
      setPreviewContentQuery({
        newActiveNavSlider: 'dynamicCollectionNew',
        newSelectorType: place,
      });
      dispatch(
        actionChangeSieveValue(
          { name: place.name, id: place.id, mode: 'dynamic' },
          'library',
        ),
      );
    }

    if (!isShowLibraryWidget) {
      dispatch(actionShowLibraryWidget(!isShowLibraryWidget, place));
    }
  };

  return (
    <div
      data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
      className={cn({
        tab_bar: !isLibrary,
        forUPV: props.forUPV,
        // for_upv_viewer: isViewer[currentRole]
      })}
    >
      <>
        <div
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          // className={cn('inner_radius', { active: props.showLibrary })}
          ref={props.wrapperRef}
        />
        {!(
          isShowLibraryWidget && !calcIsDynamicCollection(activeSieveOption)
        ) && (
          <>
            {isViewer[currentRole] && (
              <>
                <Placeholder />
                <Placeholder />

                <Placeholder />
              </>
            )}
            {(isOwner[currentRole] || isCoEdit[currentRole]) && (
              <Tooltip
                isShow={tooltipHover}
                data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
                text={t('libraryT')}
                direction="right"
                place="MakerPlaylistTabs"
                moveTop
                forUPV={props.forUPV}
              >
                <Tab
                  data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
                  data-place="library"
                  color={defColor}
                  isActive={
                    isShowLibraryWidget
                    && !calcIsDynamicCollection(activeSieveOption)
                  }
                  onClick={(e) => toggleSideBars(e, 'library')}
                >
                  <LibrarySvg
                    data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
                    data-place="library"
                  />
                </Tab>
              </Tooltip>
            )}

            {isCoEdit[currentRole] && <Placeholder />}
            {/* <Tooltip */}
            {/*  data-parent={DATA_PARENTS.PlaylistBuilderLibrary} */}
            {/*  text={t('libraryT')} */}
            {/*  direction="right" */}
            {/*  place="MakerPlaylistTabs" */}
            {/*  moveTop */}
            {/* > */}
            {/*  <Tab */}
            {/*    data-parent={DATA_PARENTS.PlaylistBuilderLibrary} */}
            {/*    data-place="library" */}
            {/*    isActive={isShowLibraryWidget && !calcIsDynamicCollection(activeSieveOption)} */}
            {/*    onClick={(e) => toggleSideBars(e, 'library')} */}
            {/*  > */}
            {/*    <SettingsSvg data-parent={DATA_PARENTS.PlaylistBuilderLibrary} data-place="library" /> */}
            {/*  </Tab> */}
            {/* </Tooltip> */}
          </>
        )}
      </>
    </div>
  );
};

export default PlaylistSideBarTabs;
