import { MiniPlaylists } from './types';

export const actionGetMiniPlaylist = (id, folderId) => {
  return {
    type: MiniPlaylists.getMiniPlaylist,
    payload: { id, folderId },
  };
};
export const actionGetMiniPlaylistForce = (id, folderId) => {
  return {
    type: MiniPlaylists.getMiniPlaylistForce,
    payload: { id, folderId },
  };
};

export const actionGetMiniPlaylistsClear = (playlistsArray) => {
  return {
    type: MiniPlaylists.getMiniPlaylistsClear,
    payload: { playlistsArray },
  };
};
export const actionGetMiniPlaylists = (playlistsArray) => {
  return {
    type: MiniPlaylists.getMiniPlaylists,
    payload: { playlistsArray },
  };
};

export const actionSaveMiniPlaylist = (miniPage) => {
  return {
    type: MiniPlaylists.saveMiniPlaylist,
    payload: miniPage,
  };
};
export const actionTogglePublishMiniPlaylist = (id, isPublish) => {
  return {
    type: MiniPlaylists.togglePublishMiniPlaylist,
    payload: { id, isPublish, state: isPublish, field: 'isPublish' },
  };
};
