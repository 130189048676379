import * as React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import UpVcompleteProgressBar from '../../../../components/ViewersProgressBars/UPVcompleteProgressBar';

const UploadForExpandWrapper = styled.div`
  width: 100%;
  padding: 15px;
  height: 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const PercentageWrapper = styled.div`
  font-size: 18px;
  font-family: "RobotoFlex", sans-serif;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  ${({ isError }) => (isError ? 'color:red;' : 'color: #484343;')}
`;
const PercentageInnerWrapper = styled.div`
  position: absolute;
  top: 40px;
`;
export const UploadForExpanded = ({ value, error }) => {
  const { t } = useTranslation();
  return (
    <UploadForExpandWrapper>
      {!error && (
        <>
          <UpVcompleteProgressBar
            widthInPercent={Math.round(value * 100)}
            fullLength
          />
          <PercentageWrapper>
            <PercentageInnerWrapper>
              {Math.round(value * 100)}%{' '}
            </PercentageInnerWrapper>
          </PercentageWrapper>
        </>
      )}
      {error && <PercentageWrapper isError>{t('uploadErrorT')}</PercentageWrapper>}
    </UploadForExpandWrapper>
  );
};
