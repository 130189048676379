import { shortcut } from './types';

export const actionWriteCombination = (combination) => ({
  type: shortcut.WriteCombination,
  payload: combination,
});

export const actionClearCombinations = (combination) => ({
  type: shortcut.ClearCombinations,
  payload: combination,
});
export const actionPressModKey = (key) => ({
  type: shortcut.PressModKey,
  payload: key,
});
export const actionReleaseModKey = (key) => ({
  type: shortcut.ReleaseModKey,
  payload: key,
});
