export const FILTER_CARD = {
  ALL: 'all',
  READ: 'read',
  UNREAD: 'unread',
};

export const FILTER_CARD_READABLE = {
  [FILTER_CARD.READ]: 'Read',
  [FILTER_CARD.UNREAD]: 'Unread',
  [FILTER_CARD.ALL]: 'Read and Unread',
};
