import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { empty } from '../../../../utils/constants';
import useThrottle from '../../../../utils/hooks/useThrottle';
import InformerSpinner from '../../../Informer/InformerSpinner';
import { actionSaveComponentDescriptionData } from '../../../../redux/support/action';

const SelectedWrapperShell = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  justify-content: space-between;
  ${({ maxWidth }) => (maxWidth ? `max-width="${maxWidth}px"` : '')}
`;
const SelectedWrapper = ({ children, foo, itemID, maxWidth, ...props }) => {
  const [cursorLoader, setCursorLoader] = useState(false);
  const { folderId } = useSelector((state) => state.user) || empty;
  const selectedPage = useSelector((state) => state.selectedPage) || empty;
  const { id: metaSideBarId } = useSelector(
    (reduxState) => reduxState.support.componentDescriptionData || empty,
  );
  const isShown = useSelector((state) => state.support.componentDescription);
  const {
    componentDescription,
    unseenPlaylistMapInChannel = {},
    unseenPlaylistManagerMapInAll = {},
  } = useSelector((state) => state.support);
  const isMetaAffected = !!metaSideBarId && metaSideBarId === itemID;
  const isSelected = folderId
    ? selectedPage[folderId] && selectedPage[folderId][itemID]
    : selectedPage[itemID];
  const dispatch = useDispatch();
  const [throttleSaveValue] = useThrottle(1000);
  const showPlaylistInfo = () => {
    dispatch(actionSaveComponentDescriptionData(itemID, '', 'playlist'));
  };
  //

  const isTheSame = metaSideBarId === itemID;
  return (
    <SelectedWrapperShell
      maxWidth={maxWidth}
      onClick={foo}
      className={`
      ${isSelected ? 'disrupt_opacity' : ''}
      ${
        cursorLoader || (isMetaAffected && componentDescription)
          ? 'hovered_by_meta'
          : ''
      }
      `}
      onMouseEnter={(event) => {
        if (props.type !== 'playlist') {
          return;
        }
        if (isTheSame) {
          return;
        }
        if (!metaSideBarId || isMetaAffected || !isShown) return;
        setCursorLoader(true);
        throttleSaveValue(() => () => {
          showPlaylistInfo(event);
          setCursorLoader(false);
        });
      }}
      onMouseLeave={() => {
        if (props.type !== 'playlist') {
          return;
        }
        if (isTheSame) {
          return;
        }
        if (!metaSideBarId) return;
        if (cursorLoader) setCursorLoader(false);
      }}
    >
      {cursorLoader && (
        <div>
          <InformerSpinner forTable />
        </div>
      )}
      {!cursorLoader && children}
    </SelectedWrapperShell>
  );
};

export default SelectedWrapper;
