import { FileUpload } from './types';
import { ServiceUser } from '../user/types';

const defaultState = {
  components: {},
  collections: {},
  globalStatus: { ready: true },
};

export const uploadReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case FileUpload.CreateUpload: {
      const { newId, errorMsg, workerTask, collectionId } = payload;
      if (!errorMsg) {
        return {
          ...state,
          components: {
            ...state.components,
            [newId]: {
              ...payload,
              id: newId,
              status: 'UPLOADING',
              workerTask,
              collectionId,
              progress: 0,
            },
          },
        };
      }
      return {
        ...state,
        components: {
          ...state.components,
          [newId]: { ...payload, id: newId, status: 'ERROR' },
        },
      };
    }
    case FileUpload.CreateUploadR: {
      const { newId, errorMsg, workerTask, collectionId } = payload;
      if (!errorMsg) {
        return {
          ...state,
          components: {
            ...state.components,
            [newId]: {
              ...payload,
              id: newId,
              status: 'UPLOADING',
              workerTask,
              collectionId,
              progress: 0,
            },
          },
        };
      }
      return {
        ...state,
        components: {
          ...state.components,
          [newId]: { ...payload, id: newId, status: 'ERROR' },
        },
      };
    }
    case FileUpload.UpdateUpload: {
      return {
        ...state,
        components: {
          ...state.components,
          [payload.id]: {
            ...state.components[payload.id],
            ...payload,
          },
        },
      };
    }
    case FileUpload.DeleteUpload: {
      const oldState = { ...state };
      delete oldState.components[payload.id];
      // return { ...oldState };
      return { ...oldState, components: { ...oldState.components } };
    }
    case FileUpload.WipeAll: {
      return { ...state, components: {}, globalStatus: { ready: true } };
    }
    case ServiceUser.Logout: {
      return { ...defaultState };
    }
    default:
      return state;
  }
};
