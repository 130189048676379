import { generateHistoryFreezePath } from './historyFreezePath';

export const cantGoForward = (historyTrace) => {
  return historyTrace.activeIndex + 1 >= historyTrace.trace.length;
};

export const needToClearHistory = (history) => {
  const path = history.location.pathname;
  return path.includes('/channel/') || path.includes('/content/');
};

export const hardGoBack = (customTrace, history) => {
  if (customTrace && customTrace[customTrace.length - 2]) {
    history.push(customTrace[customTrace.length - 2]);
    return;
  }
  if (history.location.pathname) {
    history.push(generateHistoryFreezePath(history.location.pathname));
  }
};
