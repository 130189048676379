import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import ButtonComponent from '../Buttons/ButtonComponent';
import { actionAddDynamicCollection } from '../../redux/dynamicCollection/action';
import { actionChangeSieveValue } from '../../redux/library/actions';
import {
  DEFAULT_POSITION_STEP,
  PLACE,
  svgDynamicCollection,
} from '../../utils/constants';
import { actionSwitchFolder } from '../../redux/user/action';
import ColorPalette from '../ColorPicker/ColorPalette';

const cn = classNames.bind(styles);

const CreatorDynamicCollectionModal = ({
  dref,
  filter,
  libFilterSearch,
  setShowSaveOption,
  isFullScreen,
  isLibrary,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [refColor, showColors, setShowColors] = useComponentVisible(false);
  const [refIcon, showIcon, setShowIcon] = useComponentVisible(false);
  const dynamicCollection = useSelector((state) => state.dynamicCollection);

  const [name, setName] = useState('');
  const [iconKey, setIconKey] = useState('actions');
  const [selectedColor, setColorKey] = useState('A3AACC');

  const handlerConfirm = () => {
    const id = uuidv4();
    const createDate = Math.floor(Date.now() / 1000);
    const position = Math.max(
      ...Object.values(dynamicCollection || {}).map(
        (d) => d.position || DEFAULT_POSITION_STEP,
      ),
      0,
    ) + DEFAULT_POSITION_STEP;
    const callback = isLibrary
      ? () => history.push(`/content/dynamicCollection/${id}`)
      : null;
    dispatch(
      actionAddDynamicCollection(
        {
          name,
          id,
          position,
          iconKey,
          createDate,
          color: selectedColor,
          filter,
          libFilterSearch,
        },
        callback,
      ),
    );
    setShowSaveOption(false);
    if (isLibrary) return;
    if (isFullScreen) {
      dispatch(actionSwitchFolder('smartView', id));
    } else dispatch(actionChangeSieveValue(id, PLACE.Library));
  };

  const changeItemColor = (event, color) => {
    setColorKey(color.slice(1));
  };
  return (
    <div
      data-cy="CreatorDynamicCollectionModal"
      ref={dref}
      className={cn('save_option', { isLibrary })}
    >
      <div className={cn('save_option_changes')}>
        <div className={cn('save_option_icon')}>
          <div className={cn('save_option_text')}>{t('iconT')}</div>
          <div className={cn('save_option_icon_selectors')}>
            <div
              onClick={setShowIcon}
              className={cn('item', { active: showIcon })}
            >
              {svgDynamicCollection[iconKey]()}
            </div>

            <div className={cn('separator')} />

            <div
              onClick={setShowColors}
              className={cn('item', { active: showColors })}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  // eslint-disable-next-line max-len
                  d="M9.00033 17.3327C13.6027 17.3327 17.3337 13.6017 17.3337 8.99935C17.3337 4.39698 13.6027 0.666016 9.00033 0.666016C4.39795 0.666016 0.666992 4.39698 0.666992 8.99935C0.666992 13.6017 4.39795 17.3327 9.00033 17.3327Z"
                  fill={`#${selectedColor}`}
                  stroke="#A2A7B2"
                  strokeOpacity="0.2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {showColors && (
              <div
                ref={refColor}
                className={cn('save_option_select_option', 'colors')}
              >
                <ColorPalette
                  isPositionRelative
                  selectedColor={selectedColor}
                  selectedBackgroundColor={selectedColor}
                  refColorPickerParent={refColor}
                  onChangeColor={changeItemColor}
                  isPaletteActive
                  defaultPosition={{ top: 0, left: 0 }}
                  buttonWidth={0}
                />
              </div>
            )}
            {showIcon && (
              <div ref={refIcon} className={cn('save_option_select_option')}>
                {Object.keys(svgDynamicCollection).map((svgKey) => (
                  <div
                    onClick={() => setIconKey(svgKey)}
                    className={cn('select_item', {
                      active: svgKey === iconKey,
                    })}
                  >
                    {svgDynamicCollection[svgKey]()}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={cn('save_option_name')}>
          <div className={cn('save_option_text')}>{t('searchNameT')}</div>
          <input
            maxLength={55}
            onChange={(event) => setName(event.target.value)}
            value={name}
            className={cn('save_option_input')}
          />
        </div>
      </div>
      <div className={cn('save_option_confirm')}>
        <div
          onClick={() => setShowSaveOption(false)}
          className={cn('save_option_confirm_cancel')}
        >
          {t('cancelT')}
        </div>
        <ButtonComponent text={t('createT')} clickCb={handlerConfirm} />
      </div>
    </div>
  );
};

export default CreatorDynamicCollectionModal;
