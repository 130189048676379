import { INBOX_CARD_TYPE } from '../../utils/constants';

export const deleteInboxNotification = (state, { type, inChannel, id }) => {
  if (type === INBOX_CARD_TYPE.sharedPlaylist || type === INBOX_CARD_TYPE.channelPlaylist) {
    const unseenInbox = {
      ...state.unseenInbox,
    };
    delete unseenInbox[id];

    const unseenPlaylistManagerMapInAll = {
      ...state.unseenPlaylistManagerMapInAll,
    };
    delete unseenPlaylistManagerMapInAll[id];

    const unseenPlaylistManagerMapInAllNotification = {
      ...state.unseenPlaylistManagerMapInAllNotification,
    };
    delete unseenPlaylistManagerMapInAllNotification[id];
    const newUnseenInChannel = {};

    if (inChannel) {
      inChannel?.forEach(i => {
        newUnseenInChannel[i.channel?.id] = { ...state.unseenInChannel[i.channel?.id] };
        delete newUnseenInChannel[i.channel?.id][id];
      });
    } else {
      Object.keys(state.unseenInChannel).forEach(channelId => {
        newUnseenInChannel[channelId] = { ...state.unseenInChannel[channelId] };
        if (newUnseenInChannel[channelId][id]) delete newUnseenInChannel[channelId][id];
      });
    }
    const unseenPlaylistMapInChannel = { ...state.unseenPlaylistMapInChannel };
    delete unseenPlaylistMapInChannel[id];
    const unseenPlaylistMapInChannelNotification = { ...state.unseenPlaylistMapInChannelNotification };
    delete unseenPlaylistMapInChannelNotification[id];

    return {
      ...state,
      unseenPlaylistManagerMapInAll,
      unseenPlaylistManagerMapInAllNotification,
      unseenPlaylistMapInChannel,
      unseenInbox,
      unseenPlaylistMapInChannelNotification,
      unseenInChannel: { ...state.unseenInChannel, ...newUnseenInChannel },
    };
  }
  if (type === INBOX_CARD_TYPE.invite) {
    const unseenInvite = {
      ...state.unseenInvite,
    };
    delete unseenInvite[id];

    return {
      ...state,
      unseenInvite,
    };
  }
  if (type === INBOX_CARD_TYPE.page) {
    const unseenPagesManagerMap = {
      ...state.unseenPagesManagerMap,
    };
    delete unseenPagesManagerMap[id];
    const unseenPagesManagerMapNotification = { ...state.unseenPagesManagerMapNotification };
    delete unseenPagesManagerMapNotification[id];

    return {
      ...state,
      unseenPagesManagerMap,
      unseenPagesManagerMapNotification,
    };
  }

  return state;
};
export const updateUnreadState = (state, { type, inChannel, id }, isRead) => {
  if (!isRead) {
    if (type === INBOX_CARD_TYPE.sharedPlaylist || type === INBOX_CARD_TYPE.channelPlaylist) {
      const unseenPlaylistManagerMapInAll = {
        ...state.unseenPlaylistManagerMapInAll,
      };
      delete unseenPlaylistManagerMapInAll[id];
      const unseenPlaylistManagerMapInAllNotification = {
        ...state.unseenPlaylistManagerMapInAllNotification,
      };
      const unseenInbox = {
        ...state.unseenInbox,
      };
      delete unseenInbox[id];
      delete unseenPlaylistManagerMapInAllNotification[id];


      const newUnseenInChannel = {};
      if (inChannel) {
        inChannel?.forEach(i => {
          newUnseenInChannel[i.channel?.id] = { ...state.unseenInChannel[i.channel?.id] };
          delete newUnseenInChannel[i.channel?.id][id];
        });
      } else {
        Object.keys(state.unseenInChannel).forEach(channelId => {
          newUnseenInChannel[channelId] = { ...state.unseenInChannel[channelId] };
          if (newUnseenInChannel[channelId][id]) delete newUnseenInChannel[channelId][id];
        });
      }
      const unseenPlaylistMapInChannel = { ...state.unseenPlaylistMapInChannel };
      delete unseenPlaylistMapInChannel[id];
      const unseenPlaylistMapInChannelNotification = { ...state.unseenPlaylistMapInChannelNotification };
      delete unseenPlaylistMapInChannelNotification[id];

      return {
        ...state,
        unseenPlaylistManagerMapInAll,
        unseenInbox,
        unseenPlaylistManagerMapInAllNotification,
        unseenPlaylistMapInChannel,
        unseenPlaylistMapInChannelNotification,
        unseenInChannel: { ...state.unseenInChannel, ...newUnseenInChannel },
      };
    }
    if (type === INBOX_CARD_TYPE.invite) {
      const unseenInvite = {
        ...state.unseenInvite,
      };
      delete unseenInvite[id];

      return {
        ...state,
        unseenInvite,
      };
    }
    if (type === INBOX_CARD_TYPE.page) {
      const unseenPagesManagerMap = {
        ...state.unseenPagesManagerMap,
      };
      delete unseenPagesManagerMap[id];
      const unseenPagesManagerMapNotification = { ...state.unseenPagesManagerMapNotification };
      delete unseenPagesManagerMapNotification[id];

      return {
        ...state,
        unseenPagesManagerMapNotification,
        unseenPagesManagerMap,
      };
    }
  }

  if (type === INBOX_CARD_TYPE.invite) {
    return { ...state, unseenInvite: { ...state.unseenInvite, [id]: true } };
  }
  if (type === INBOX_CARD_TYPE.sharedPlaylist || type === INBOX_CARD_TYPE.channelPlaylist) {
    const unseenPlaylistManagerMapInAll = {
      ...state.unseenPlaylistManagerMapInAll,
    };
    unseenPlaylistManagerMapInAll[id] = {
      type: 'sharing',
      playlistId: id,
    };
    const unseenPlaylistManagerMapInAllNotification = {
      ...state.unseenPlaylistManagerMapInAllNotification,
    };
    unseenPlaylistManagerMapInAllNotification[id] = {
      type: 'sharing',
      playlistId: id,
    };

    const unseenInbox = {
      ...state.unseenInbox,
    };
    unseenInbox[id] = true;

    const unseenPlaylistMapInChannelNotification = {
      ...state.unseenPlaylistMapInChannelNotification,
    };

    unseenPlaylistMapInChannelNotification[id] = true;
    const unseenPlaylistMapInChannel = {
      ...state.unseenPlaylistMapInChannel,
    };
    unseenPlaylistMapInChannel[id] = true;
    const unseenInChannel = { ...state.unseenInChannel };

    if (inChannel) {
      inChannel?.forEach(i => {
        unseenInChannel[i.channel?.id] = { ...state.unseenInChannel[i.channel?.id] };
        unseenInChannel[i.channel?.id][id] = { playlistId: id };
      });
    }

    return {
      ...state,
      unseenInbox,
      unseenInChannel,
      unseenPlaylistMapInChannel,
      unseenPlaylistMapInChannelNotification,
      unseenPlaylistManagerMapInAll,
      unseenPlaylistManagerMapInAllNotification,
    };
  }
  if (type === INBOX_CARD_TYPE.page) {
    const unseenPagesManagerMap = {
      ...state.unseenPagesManagerMap,
    };
    unseenPagesManagerMap[id] = true;
    const unseenPagesManagerMapNotification = { ...state.unseenPagesManagerMapNotification };
    unseenPagesManagerMapNotification[id] = true;
    return {
      ...state,
      unseenPagesManagerMap,
      unseenPagesManagerMapNotification,
    };
  }
  return state;
};
