import React from 'react';
import classnames from 'classnames/bind';
import { UiComponentTypes } from '../../utils/constants';
import { ReactComponent as LibComponentUnderlaySvg } from '../../images/icons/lib_component_underlay_104_122.svg';
import rawStyles from './index.module.scss';

const styles = classnames.bind(rawStyles);

export default ({ width = 20, height = 24, chWidth, chHeight, ...props }) => {
  return (
    <div className={styles('typeIcon')} style={{ width, height }}>
      <div
        className={styles('children_wrapper')}
        style={{ width: chWidth, height: chHeight }}
      >
        {props.children}
      </div>
    </div>
  );
};
