import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { keyframes } from 'styled-components';
import { PdfContent } from '../../../pages/LibraryComponent/contentGenerators/PDFContent';
import { pageControls } from '../../../pages/LibraryComponent/helpers';

const PDFPreviewImage = styled.img`
  width: 100%;
`;

const PDFPreviewWrapper = styled.div`
  min-width: 600px;
  height: calc(100vh - 200px);
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  ${({ isExpandView }) => (isExpandView ? 'height:auto; min-height:200px' : '')}
  ${({ customStyle }) => (customStyle ?? '')};
  ${({ transformed }) => (transformed ? `
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80vh;
    transition: top 0.2s;
  ` : `
    transform: none;
    max-height: none;
    transition: top 0.2s;
  `)}
  ${({ isWhiteGallery }) => (isWhiteGallery
    ? `height:auto; 
       min-height:200px;
       width:auto;
       max-height: calc(100vh - 200px);`
    : '')}

`;

const PDFPreview = ({ item, isExpandView, customStyle, isGalleryOpen, ...props }) => {
  const pdfRef = useRef(null);
  const imageRef = useRef(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [siblingComponentIsLoading, setSiblingComponentIsLoading] = useState(false);
  const [isShowPDF, setShowPDF] = useState(false);

  const clickHandler = (e) => {
    e.stopPropagation();
    setShowPDF(true);
  };

  const changePage = (operation) => (e) => {
    e.stopPropagation();
    if (operation) setPage((prev) => pageControls[operation](prev, totalPages));
  };
  if (item.urlSmallImage && !isGalleryOpen && !isShowPDF) {
    return <PDFPreviewImage onClick={clickHandler} src={item.urlSmallImage} />;
  }
  return (
    <PDFPreviewWrapper
      isExpandView={isExpandView}
      isWhiteGallery={props.isWhiteGallery}
    >
      <PdfContent
        zoomRef={props.zoomRef}
        zoomWrapRef={props.zoomWrapRef}
        forUPV
        isExpandView={isExpandView}
        item={item}
        updateAfterLoadPdf={props.updateAfterLoadPdf}
        pdfRef={pdfRef}
        changePage={changePage}
        setTotalPages={setTotalPages}
        pages={{ current: page, max: totalPages }}
        scale={isExpandView ? null : 99}
        isPDFPreview
        imageRef={imageRef}
        setSiblingComponentIsLoading={setSiblingComponentIsLoading}
        isWhiteGallery={props.isWhiteGallery}
      />
    </PDFPreviewWrapper>
  );
};

export default PDFPreview;
