import React from 'react';
import CreateLinkComponentPopUp from '../../CreateLinkComponentPopUp';
import SideBarTabs from '../../../../pages/Playlist/PlaylistTabs/SideBarTabs';
import SortAndView from '../SortAndView';
import useComponentVisible from '../../../../utils/hooks/useComponentVisible';
import { Divider, GroupIcon, Wrapper } from './StyledComponents';
import ButtonUpload from '../../../Buttons/ButtonUpload';

const SecondaryFile = (props) => {
  const [addLinkRef, isCreateLinkVisible, setIsCreateLinkVisible] = useComponentVisible(false, 'dataParentCreateLink');

  return (
    <GroupIcon newUPVMode={props.newUPVMode}>
      {isCreateLinkVisible && (
        <CreateLinkComponentPopUp
          wrapperRef={addLinkRef}
          setIsCreateLinkVisible={setIsCreateLinkVisible}
        />
      )}
      <Wrapper>
        <ButtonUpload
          dataParent={props.dataParent}
          newUPVMode={props.newUPVMode}
        />
        <Divider newUPVMode={props.newUPVMode} />
      </Wrapper>
      <SideBarTabs
        newUPVMode={props.newUPVMode}
        forSecondaryMenu
        isLibrary
        setIsShowFilter={() => {}}
        setShowNewSmartView={props.setShowNewSmartView}
        resetSmartview={props.resetSmartview}
        setSmartViewForEdit={props.setSmartViewForEdit}
        setChosenSmartView={props.setChosenSmartView}
        setChosenTag={props.setChosenTag}
      />
      <Divider ms newUPVMode={props.newUPVMode} />
      <SortAndView {...props} />
    </GroupIcon>
  );
};

export default SecondaryFile;
