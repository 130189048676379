import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoSvg } from '../../../images/2023/svg/Logo_black_text.svg';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import UI from '../../../UIComponents';
import { ReactComponent as SpinnerSvg } from '../../../images/icons/spinner.svg';
import styles from '../Login.module.scss';

export const ForgotWrapper = styled.div`
  margin: auto;
  background: transparent;
  z-index: 2;

  @media screen and (max-width: 1180px) {
    margin-left: 90px;
    width: 49.25%;
  }

  @media screen and (max-width: 992.1px) {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 520px;
  height: 457px;
  background: #ffffff;
  border-radius: 32px;
  border: 0.6px solid #ECE2E2;
  box-sizing: border-box;
  padding: 0 67px;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);

  ${({ isResetPassword }) => (isResetPassword ? `
    height: 520px;
  ` : '')}
`;

export const ContainerFit = styled.div`
  position: relative;
  width: 520px;
  height: fit-content;
  background: #ffffff;
  border-radius: 32px;
  border: 0.6px solid #ECE2E2;
  box-sizing: border-box;
  padding: 0 67px 70px 67px;
 
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);

  ${({ isResetPassword }) => (isResetPassword ? `
    height: 520px;
  ` : '')}
`;

export const EmailBoldSpan = styled.span`
  font-weight: 700;
  font-family: Roboto, sans-serif;
`;

export const ConfirmedContainer = styled.div`
  width: 520px;
  height: 380px;
  border-radius: 32px;
  background: #ffffff;
  border: 1px solid #d7ddf7;
  box-sizing: border-box;
  padding: 0 67px;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  ${({ isLoginPageSignIn }) => (isLoginPageSignIn ? `
    svg {
      width: 157px;
      height: auto;
      margin-top: 20px;
    }
  ` : '')};

  ${({ isNotClickable }) => (isNotClickable ? `
    &:hover {
      cursor: default;
    }
  ` : '')};

  ${({ isForgotPassword }) => (isForgotPassword ? `
      height: 45px;
      margin-top: 40px;
  ` : '')}

    ${({ isLoginPageText }) => (isLoginPageText ? `
    justify-content: flex-start;
   
    svg {
      width: 125px;
      height: auto;
    }
  ` : '')};
   ${({ isResetPassword }) => (isResetPassword ? `
      height: 125px;
  ` : '')}
`;
export const Title = styled.div`
  margin-top: 46px;
  font-style: normal;
  display: flex;
  align-items: center;
  color: #484343;
  font-size: 36px;
  font-weight: 500;
  line-height: 48px; 

   ${({ isResetPassword }) => (isResetPassword ? `
      margin-top: 5px;
  ` : '')}

  & svg {
    margin-right: 4px;
  }
`;
export const SubTitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #484343;
  margin-top: 14px;
  margin-bottom: 23px;

  ${({ isForgotPassword }) => (isForgotPassword ? `
      line-height: 17px;
  ` : '')}

  ${({ isResetPassword }) => (isResetPassword ? `
    margin-bottom: 0;
  ` : '')}
`;
export const EmailInput = styled.div`
  margin-bottom: ${({ isResetPassword }) => (isResetPassword ? 0 : '9px')};
`;
export const Error = styled.div`
  visibility: ${({ hasError }) => (hasError ? 'visible' : 'hidden')};
  width: 100%;
  height: ${({ hasError }) => (hasError ? '32' : '0')}px;
  opacity: ${({ hasError }) => (hasError ? '1' : '0')};
  transition: ease-in-out all 0.3s;
  border-radius: 6px;
  font-size: 14px;
  line-height: 21px;
  color: #c21c44;
  background-color: #ffeef2;
  display: flex;
  align-items: center;
  padding-left: 8px;
  & svg {
    margin: 8px 8px 8px 0;
  }
`;
export const InputTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  margin-bottom: 4px;
`;
export const InputField = styled.div`
  width: 400px;
  height: 40px;
  left: 600px;
  top: 279px;
  background: ${({ hasError }) => (hasError ? '#FFE8E8' : '#ffffff')};
  &:focus-within {
    border: 1px solid #FFC72C;
  }
  border: 1px solid ${({ hasError }) => (hasError ? '#C21C44' : '#D7DDF7')};
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 8px;

  ${({ isForgotPassword }) => (isForgotPassword ? `
  width: 100%;
  height: 48px;
  ` : '')}

  & input {
    margin: 0 5px;
    border: none;
    height: 100%;
    width: 98%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    background: ${({ hasError }) => (hasError ? '#FFE8E8' : '#ffffff')};
    &::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export const PasswordField = styled.div`
  width: 400px;
  height: 40px;
  left: 600px;
  top: 279px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid ${({ hasError }) => (hasError ? '#C21C44' : '#D7DDF7')};
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 8px;

  &:focus-within {
    border: 1px solid #8697f0;
  }
  & input {
    margin: 0 5px;
    border: none;
    height: 100%;
    width: 350px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    &::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }
  }
  ${({ isResetPassword }) => (isResetPassword ? `
    width: 385px;
    height: 48px;
    margin-bottom: 0;
    
    input {
      padding: 8px;
    }
  ` : '')}
`;

export const EyeContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;

  &:hover {
    background-color: #ffc72c;
  }
  
  & svg {
    width: 20px;
    height: 20px;
  }
  & svg path {
    stroke: #596080;
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: ${({ isResetPassword }) => (isResetPassword ? '20px' : '25px')};
`;
export const SignInReturn = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #0026e5;
  cursor: pointer;
  width: fit-content;
  height: auto;

 ${({ isForgotPassword }) => (isForgotPassword ? `
  color: #4744A0;
  font-size: 14px;
  font-weight: 500;
  ` : '')}

  &:hover {
    text-decoration: underline;
  }
`;

const spinTheSpinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  position: absolute;
  bottom: 21px;
  left: 50%;
  transform: translate(-50%, -60px);
  & svg {
    animation: linear ${spinTheSpinner} 1.2s infinite;
  }
`;

const ForgotView = ({
  value,
  onValueChange,
  onSubmit,
  onSignIn,
  onInputBlur,
  onInputFocus,
  onInputKeyPress,
  errorMsg,
  requestSpinner,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.scroll_wrapper}>
      <div className={styles.scroll_balancer}>
        <ForgotWrapper>
          <Container>
            <Logo isNotClickable isForgotPassword>
              <LogoSvg />
            </Logo>
            <Title>{t('forgotYourPasswordQuestionT')}</Title>
            <SubTitle>
              {t('noWorriesSendResetInstructionsT')}
            </SubTitle>
            <EmailInput>
              <InputField hasError={!!errorMsg} isForgotPassword>
                <input
                  type="text"
                  placeholder={t('emailAddressPlaceholerT')}
                  value={value}
                  onChange={onValueChange}
                  onBlur={onInputBlur}
                  onFocus={onInputFocus}
                  onKeyDown={onInputKeyPress}
                />
              </InputField>
              <UI.Error error={errorMsg} isLogin />
            </EmailInput>
            <ButtonWrapper onClick={onSubmit}>
              <ButtonComponent text={t('resetPasswordT')} fullWidth height={48} isSignIn />
            </ButtonWrapper>
            <SignInReturn onClick={onSignIn} isForgotPassword>{t('backToSignInT')}</SignInReturn>
            {!!requestSpinner && (
              <Spinner>
                <SpinnerSvg />
              </Spinner>
            )}
          </Container>
        </ForgotWrapper>
      </div>
    </div>
  );
};

export default ForgotView;
