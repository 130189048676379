import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createPage } from '../../../../utils/helpers';
import Button2023 from '../../../Buttons/Button2023';
import SideBarTabs from '../../../../pages/Playlist/PlaylistTabs/SideBarTabs';
import SortAndView from '../SortAndView';
import { Divider, GroupIcon, Wrapper } from './StyledComponents';
import ButtonUpload from '../../../Buttons/ButtonUpload';

const SecondaryPage = (props) => {
  const { selector: selectorType = 'recent' } = useParams();
  const { tags } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id: folderId } = useParams();
  const createNewPage = (e) => {
    e.stopPropagation();
    createPage({ selectorType, dispatch, tag: tags[folderId], history });
  };

  return (
    <GroupIcon
      data-parent={props.dataParent}
      newUPVMode={props.newUPVMode}
    >
      <Wrapper>
        <Button2023
          derivedParentData={props.dataParent}
          text={t('plusPageUpT')}
          width={64}
          height={props.lowMode ? 28 : 32}
          variant="secondary"
          handleButtonClick={createNewPage}
        />
        <ButtonUpload
          dataParent={props.dataParent}
          newUPVMode={props.newUPVMode}
        />
        <Divider
          data-parent={props.dataParent}
          newUPVMode={props.newUPVMode}
        />
      </Wrapper>
      <SideBarTabs
        newUPVMode={props.newUPVMode}
        forSecondaryMenu
        isLibrary
        setIsShowFilter={() => {}}
        setShowNewSmartView={props.setShowNewSmartView}
        resetSmartview={props.resetSmartview}
        setSmartViewForEdit={props.setSmartViewForEdit}
        setChosenSmartView={props.setChosenSmartView}
        setChosenTag={props.setChosenTag}
      />
      <Divider dataParent={props.dataParent} ms newUPVMode={props.newUPVMode} />
      <SortAndView {...props} />
    </GroupIcon>
  );
};

export default SecondaryPage;
