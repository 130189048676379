import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moduleStyles from './index.module.scss';
import { actionDetachComponent } from '../../redux/library/actions';
import useComponentVisible from '../../utils/hooks/useComponentVisible';

const styles = classNames.bind(moduleStyles);

const DetachWarning = ({ setOpenMenuDetach, createComponentAfterDetach }) => {
  const { draggableBlocks } = useSelector((state) => state.currentPage);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [refOption, isOptionVisible] = useComponentVisible(true);

  const onDetachComponent = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      actionDetachComponent(draggableBlocks, createComponentAfterDetach),
    );
    setOpenMenuDetach();
  };
  useEffect(() => {
    if (!isOptionVisible) {
      setOpenMenuDetach();
    }
  }, [isOptionVisible]);
  return (
    <div ref={refOption} className={styles('warning')}>
      <div className={styles('title')}>{t('createComponentT')}</div>
      <div className={styles('text')}>
        {t('unableToCreateNewComponentT')}
      </div>
      <div className={styles('button_group')}>
        <div className={styles('cancel')} onClick={setOpenMenuDetach}>
          {t('cancelT')}
        </div>
        <div className={styles('detach')} onClick={onDetachComponent}>
          {t('detachCreateComponentT')}
        </div>
      </div>
    </div>
  );
};

export default DetachWarning;
