import styled from 'styled-components/macro';
import { css, keyframes } from 'styled-components';

export const Bottom = styled.div`
  height: 58px;
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 260px;
  z-index: 100;
  background-color: white;
  padding: 19px 37px 18px 40px;
`;


export const TrashWrapper = styled.div``;

export const LoaderWrapper = styled.div`
  position: relative;
  width: 300px;
  overflow: hidden;
  zoom: 0.8;
`;

export const ImageWrap = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: ${({ active }) => (active ? '#FFC72C' : 'inherit')};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #ffeebf;
  }
`;

export const MainLeftSideBarShell = styled.div`
  background: #ffffff;
  width: 260px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
`;

export const SelectorBoard = styled.div`
  width: 260px;
  padding-top: 1px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(-175px + 100vh);
  &::-webkit-scrollbar {
    width: 4px;
    position: absolute;
    right: -4px;
  }
  &::-webkit-scrollbar-thumb {
    position: absolute;
    display: block;
    border-radius: 4px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.13);
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
`;

export const NewSFButton = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  font-family: RobotoFlex, sans-serif;
  line-height: 12px;
  letter-spacing: 0.05em;
  cursor: pointer;
  text-transform: uppercase;
  color: #FFF;
  width: 117px;
  height: 38px;
  border-radius: 22px;
  border: none;
  background: linear-gradient(317deg, #1FAE09 -2.91%, #5BDA48 138.5%);
  box-shadow: 0 1px 4px 0 rgba(217, 0, 0, 0.15);
  margin: 18px 0 9px 22px;
  padding-left: 12px;

  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%,
     rgba(255, 255, 255, 0.15) 100%),
     linear-gradient(317deg, #1FAE09 -2.91%, #5BDA48 138.5%);
  }

  svg path {
    stroke: #fff;
  }

  div {
    margin: 0; 
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
`;

export const Counter = styled.div`
  font-size: 14px;
  color: ${({ active }) => (active ? '#484343' : '#939393')};
  font-weight: ${({ active }) => (active ? '500' : '400')};
  width: 26px;
  line-height: 32px;
  height: 32px;
  display: flex;
  justify-content: flex-end;
  ${({ isHidden }) => (isHidden ? ' visibility: hidden;' : '')};
`;


export const ContactName = styled.div`
  color: #585858;
  font-family: RobotoFlex, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-left: 8px;
  width:  ${({ isHeader }) => (isHeader ? 'calc(32vw - 212px)' : '137px')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: none;
`;

export const PlusWrapper = styled.div`
  width: ${({ hidden }) => (hidden ? '23px' : '29px')};
  height: ${({ hidden }) => (hidden ? '23px' : '29px')};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg path {
    stroke: #585858;
  }
  &:hover {
    background: ${({ hidden }) => (hidden ? '#F5F6FF' : '#FFEEBF')};
  }
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'inherit')};
  cursor: pointer;
`;

export const PlusWrapperCircle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    svg {
      stroke: #FFC72C;

      rect {
        fill: #FFEEBF;
      }
    }
  }
`;

export const SelectorPlus = styled.div`
  position: absolute;
  right: 10px;

  width: ${({ hidden }) => (hidden ? '23px' : '29px')};
  height: ${({ hidden }) => (hidden ? '23px' : '29px')};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: ${({ hidden }) => (hidden ? '#FFDF85' : '#E4E7F7')};
  }
  & svg path {
    stroke: #484343;
  }
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'inherit')};
  cursor: pointer;
`;

export const TogglerShowHide = styled.div`
  position: absolute;
  right: 141px;

  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;

  height: 22px;
  & svg {
    transition: transform;
    transition-duration: 0.4s;
    ${({ isHide }) => (isHide ? ' transform: rotate(-90deg);' : ' transform: rotate(0deg);')}
  }
  &:hover {
    background: #ffdf85;
    & svg path {
      stroke: #484343;
      //fill: none !important;
    }
  }

  cursor: pointer;
`;

export const ThreeDotsMenuShell = styled.div`
  cursor: pointer;
  visibility: hidden;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -7px;
  border-radius: 6px;

  &:hover{
    background-color: #FFDF85;
  }
  ${({ isOpenMenu }) => (isOpenMenu ? 'background-color: #FFC72C;' : '')}

  & svg{
    width: 17px;
    height: 17px;
  }
  
`;

export const Info = styled.div`
  display: flex;
  font-family: "RobotoFlex", sans-serif;
  align-items: center;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ active }) => (active ? '#484343' : '#585858')};
  font-weight: ${({ active }) => (active ? '500' : '400')};
  text-transform: capitalize;
  position: relative;
  ${({ isLib }) => (isLib
    ? `
    position: relative;
    top: 0px;
    left: 2px;
`
    : '')}
  & svg path {
    stroke: ${({ active }) => (active ? '#484343' : '#585858')};
    fill: ${({ active }) => (active ? 'transparent' : 'transparent')};
  }

  ${({ isMediumBold }) => (isMediumBold
    ? `
    font-weight: 500; 
  `
    : '')}
`;

export const New = styled.div`
  background: #f5b400;
  border-radius: 12px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 6px;
  top: 3px;
  z-index: 100;
  font-family: RobotoFlex, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: rgb(255, 255, 255);
  padding: 4px 6px;
`;

export const Selector = styled.div`
  position: relative;  
  height: 28px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;
  ${({ active }) => (active
    ? ` 
   background-color: #FFC72C;
   `
    : '')};
  cursor: pointer;
  color: #484343;
  margin-left: 23px;
  margin-right: 16px;
  padding-left: 11px;
  padding-right: 12px;
  width: 216px;
  margin-left: 23px;
  ${({ isDisabled }) => (isDisabled ? 'cursor: not-allowed; opacity: 0.2;' : '')};
  ${({ isHasOpenMenu }) => (isHasOpenMenu ? `
    ${ThreeDotsMenuShell} {
      visibility: visible;
      background-color: #FFDF85;
    }
    ${Counter} {
      visibility: hidden;
    }
  ` : '')};
  &:hover {
    ${({ isDisabled }) => (isDisabled ? '' : 'background-color: #ffeebf;')};
    
    ${PlusWrapper} {
      visibility: ${({ isPlusDisabled }) => (isPlusDisabled ? 'hidden' : 'visible')};
    }

    ${SelectorPlus} {
      visibility: ${({ isPlusDisabled }) => (isPlusDisabled ? 'hidden' : 'visible')};
    }

    ${Counter} {
      visibility: ${({ isPlusDisabled }) => (isPlusDisabled ? 'visible' : 'hidden')};
    }
    ${New} {
      visibility: hidden;
    }
    ${ThreeDotsMenuShell} {
      visibility: visible;
    }
  }
  ${({ isDragOver, isNotAllowed }) => (isDragOver
    ? `background: #FFEEBF;
    ${isNotAllowed ? '' : 'border: 1px solid #F5B400;'}
  `
    : '')}
`;
export const SelectorWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const SectionChevronIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  
  ${({ isCollapsed }) => (isCollapsed ? 'transform: rotate(-90deg);' : '')}

  &:hover {
    background: #FFEEBF;
  }
`;

export const MajorSelector = styled.div`
  height: 32px;
  margin-left: 30px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 207px;
   ${({ isSecond }) => (isSecond ? 'margin-top: 10px;' : '')}
`;
const hidingWithDelay = keyframes`
  from {
    clip-path: view-box;

  }

  to {

  }
`;
export const CollapsingSectionWrapper = styled.div`
    height: fit-content;
    position: relative;
    ${({ time }) => (time ? `transition: max-height ${time}s ease-in-out;` : '')}
    ${({ height }) => (height ? `max-height: ${height};` : '')}
    ${({ isCollapsed, time }) => !isCollapsed && css`animation: ${hidingWithDelay} ${time}s forwards;`}
    ${({ isCollapsed }) => (isCollapsed && 'overflow: hidden; max-height: 0;')}
`;

export const MajorText = styled.div`
  display: flex;
  align-items: center;
  font-family: RobotoFlex, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1.2px;
  text-align: left;
  text-transform: uppercase;
  color: #939393;
`;

export const IconPlace = styled.div`
  //height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    padding-bottom: 1px;
    margin-right: 11px;
    width: 16px;
    height: 16px;
  }
  ${({ isPdf }) => (isPdf
    ? `
      & svg path:nth-last-child(-n+3) {
     fill: rgb(88, 88, 88);
     stroke:transparent;
    } 
  `
    : '')}
`;

export const ContactWrapper = styled.div`
  width: 100%;
  position: relative;
`;
