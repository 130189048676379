import { UiComponentTypes } from '../../constants';
import { getFileExt, readableSizeConverter } from '../../helpers';
import { smartFileItemTypeCheck } from '../../../shared/smartFile/constant';

const isBlockElement = (type) => !!(
  UiComponentTypes.pdf[type]
    || UiComponentTypes.page[type]
    || UiComponentTypes.ms[type]
    || UiComponentTypes.component[type]
);

const isMedia = (type) => !!(UiComponentTypes.media[type] || UiComponentTypes.youtube[type]);

export const PlaylistHelper = {
  isUiComponentBig: (type) => isBlockElement(type),

  hintSelector: (item = {}) => {
    if (item?.successUpload && UiComponentTypes.image[item.type]) return getFileExt(item.type);
    if (item.isUpload) return readableSizeConverter(item.size || 'unknown');
    if (isBlockElement(item.type)) return item.duration;
    if (isMedia(item.type)) return item.time;
    if (UiComponentTypes.image[item.type]) return getFileExt(item.type);
    if (UiComponentTypes.link[item.type]) return item.linkUrl;
    return item.description;
  },
};

export const PlaylistElementTypeDisplayResolver = (
  type,
  libraryType,
  extention,
) => {
  if (type === 'elementText') return 'Text';
  if (smartFileItemTypeCheck.isRichText[type]) return 'Text';
  if (type === null) return 'Placeholder';
  if (type === 'elementComponent' && UiComponentTypes.pdf[libraryType]) return 'PDF';
  if (type === 'elementComponent' && UiComponentTypes.page[libraryType]) return 'Page';
  if (type === 'elementComponent' && UiComponentTypes.image[libraryType]) return 'Image';
  if (type === 'elementComponent' && UiComponentTypes.imageLike[libraryType]) return 'Image';
  if (type === 'elementComponent' && UiComponentTypes.video[libraryType]) return 'Video';
  if (type === 'elementComponent' && UiComponentTypes.audio[libraryType]) return 'Audio';
  if (
    type === 'elementComponent'
    && extention
    && (UiComponentTypes.xls[libraryType]
      || UiComponentTypes.ms[libraryType]
      || UiComponentTypes.pp[libraryType])
  ) return extention.toUpperCase();
  if (
    type === 'elementComponent'
    && (UiComponentTypes.xls[libraryType]
      || UiComponentTypes.ms[libraryType]
      || UiComponentTypes.pp[libraryType])
  ) return 'Document';
  if (
    type === 'elementComponent'
    && UiComponentTypes.google_embed_component[libraryType]
  ) return 'Google';
  if (
    type === 'elementComponent'
    && UiComponentTypes.dropbox_embed_component[libraryType]
  ) return 'Dropbox';
  if (type === 'elementComponent' && UiComponentTypes.link[libraryType]) return 'Link';
  if (type === 'elementComponent' && UiComponentTypes.archive[libraryType]) return 'Archive';
  if (type === 'elementComponent' && UiComponentTypes.youtube[libraryType]) return 'Youtube';
  return 'FILE';
};

export const GetLibComponentType = (type, extention) => {
  if (UiComponentTypes.pdf[type]) return 'PDF';
  if (UiComponentTypes.page[type]) return 'Page';
  if (UiComponentTypes.image[type]) return 'Image';
  if (UiComponentTypes.video[type]) return 'Video';
  if (UiComponentTypes.audio[type]) return 'Audio';
  if (
    extention
    && (UiComponentTypes.xls[type]
      || UiComponentTypes.ms[type]
      || UiComponentTypes.pp[type])
  ) return extention.toUpperCase();
  if (
    UiComponentTypes.xls[type]
    || UiComponentTypes.ms[type]
    || UiComponentTypes.pp[type]
  ) return 'Document';
  if (UiComponentTypes.google_embed_component[type]) return 'Google';
  if (UiComponentTypes.dropbox_embed_component[type]) return 'Dropbox';
  if (UiComponentTypes.link[type]) return 'Link';
  if (UiComponentTypes.archive[type]) return 'Archive';
  if (UiComponentTypes.youtube[type]) return 'Youtube';
  if (UiComponentTypes.component[type]) return 'Component';
  return 'FILE';
};
