import React from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import rawStyles from './index.module.scss';
import { ReactComponent as MoreSvg } from '../../../../images/icons/more_redesigned.svg';
import MENU_OPTIONS from '../../../../components/DropDownOption/MenuOptions/PlaylistMenuOptions';
import { DATA_PARENTS, openModalType } from '../../../../utils/constants';
import PlaylistOptionWithX from '../../../../components/DropDownOption/MenuOptions/PlaylistOptionWithX';
import useComponentVisible from '../../../../utils/hooks/useComponentVisible';
import { UserRole } from '../../../../utils/permissions';
import { actionOpenModal } from '../../../../redux/user/action';
import { actionSetBlinkId } from '../../../../redux/support/action';
import { Channels } from '../../../../redux/channels/types';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { ReactComponent as PinSvg } from '../../../../images/icons/pin_icon.svg';
import { ReactComponent as UnpinSvg } from '../../../../images/icons/unpin_icon.svg';
import { calcNextItemToRedirect } from '../../../../utils/helpers';

const cn = classNames.bind(rawStyles);

const ActivityTableRow = ({
  channelName,
  userRole,
  authorName,
  id,
  isUserOwner,
  isUnpin,
  activeChannelId,
  sidebarChannels,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const DATA_PARENT = DATA_PARENTS.ActivityMore + id;
  const [refMenu, isMenuVisible, setIsMenuVisible] = useComponentVisible(
    false,
    DATA_PARENT,
  );

  const onLeaveChannelClick = () => {
    const nextItem = calcNextItemToRedirect(sidebarChannels, activeChannelId, true);
    dispatch(actionOpenModal(openModalType.LeaveChannel, { channelId: id, channelName, nextItem }));
  };
  const onDeleteItemClick = () => {
    const nextItem = calcNextItemToRedirect(sidebarChannels, activeChannelId, true);
    dispatch(actionOpenModal(openModalType.DeleteChannel, { id, nextItem, history }));
  };

  const menuOptions = (isUserOwner || userRole === 'admin')
    ? [MENU_OPTIONS.deleteFromTheList(onDeleteItemClick, DATA_PARENT)]
    : [MENU_OPTIONS.leaveChannel(onLeaveChannelClick, DATA_PARENT)];

  const setBlinkId = () => {
    if (isUnpin) {
      dispatch(actionSetBlinkId([id]));
    }
  };
  const unpinHandler = (e) => {
    e.stopPropagation();
    dispatch(
      actionCreator(Channels.UpdatePinState, {
        id,
        isUnpin,
      }),
    );
    if (isUnpin) {
      setBlinkId();
    }
  };
  const toggleIsShowMore = () => setIsMenuVisible((prev) => !prev);
  return (
    <div className={cn('row_wrapper', { active: isMenuVisible })}>
      <div className={cn('row')}>
        <div className={cn('cell', 'channelName', 'first')}>{channelName}</div>
        <div className={cn('cell', 'userRole')}>{UserRole[userRole]}</div>
        <div className={cn('cell', 'authorName')}>{authorName}</div>
        <div className={cn('cell', 'actions', 'last')} ref={refMenu}>
          <div
            className={cn('more', 'pin')}
            data-parent={DATA_PARENT}
            onClick={unpinHandler}
          >
            {isUnpin ? <UnpinSvg /> : <PinSvg />}
          </div>
          <div
            className={cn('more', { active: isMenuVisible })}
            onClick={toggleIsShowMore}
            data-parent={DATA_PARENT}
          >
            <MoreSvg />
          </div>
          {isMenuVisible && (
            <PlaylistOptionWithX
              parentRef={refMenu}
              menuOptions={menuOptions}
              isActivityRow
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityTableRow;
