import styled from 'styled-components/macro';

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 2px;
  align-items: center;
`;
export const ChevronButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #ffeebf;
  }

  &:active {
    background-color: #ffc72c;
  }

  ${({ isRight }) => (isRight
    ? `
    margin-left: 0px;
    margin-right: 6px;
    svg {
      transform: rotate(180deg);
    }
  `
    : '')}
  ${({ isDisabled }) => (isDisabled
    ? `
    background-color: initial;
    &:hover {
      background-color: initial;
    }
    svg path {
      stroke: transparent;
      fill: lightgrey;
    }
    cursor: default;
  `
    : '')}
`;

export const Arrow = styled.div`
  width: 9px;
  height: 14px;
  margin-right: 3px;

  ${({ isRight }) => isRight
  && `
    margin-right: -2px;
  `}
`;
