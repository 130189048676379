import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { ReactComponent as RadioButton } from '../../../images/icons/icon_20/radioButtonBlack.svg';

import {
  checkShareType, shareType,
} from './helpers';


const ShareRadioButtonGroup = React.memo(function ShareRadioButtonGroup({
  dataParent,
  selectedShareType,
  setSelectedShareType,
  isMultiple,
  isPage,
}) {
  const { t } = useTranslation();
  const isActiveShareToWeb = checkShareType.isShareToWeb[selectedShareType];
  const isActiveShareToUser = checkShareType.isShareToUser[selectedShareType];
  const isActiveShareToChannel = checkShareType.isShareToChannel[selectedShareType];

  const selectHandlerCB = (option) => () => {
    if (isMultiple && checkShareType.isShareToWeb[option]) return;
    if (checkShareType.isShareToChannel[option] && isPage) return;
    setSelectedShareType(option);
  };

  return (
    <div
      data-parent={dataParent}
      className={styles.radio_selector}
    >
      <div
        className={styles.option}
        data-parent={dataParent}
        onClick={selectHandlerCB(shareType.SHARE_TO_USER)}
      >
        <RadioButton
          data-parent={dataParent}
          data-cy="isShareToUsers"
          className={`${styles.radioButton} ${
            isActiveShareToUser ? styles.selected : ''
          } `}
        />
        <div data-parent={dataParent} className={styles.optional_text}>
          {t('shareWithT')}
        </div>
      </div>
      {!isPage && (
        <div
          data-parent={dataParent}
          className={styles.option}
          onClick={selectHandlerCB(shareType.SHARE_TO_CHANNEL)}
        >
          <RadioButton
            data-parent={dataParent}
            data-cy="isShareToChannel"
            className={`${styles.radioButton} ${
              isActiveShareToChannel ? styles.selected : ''
            }`}
          />
          <div data-parent={dataParent} className={styles.optional_text}>
            {t('shareInChannelsT')}
          </div>
        </div>
      )}
      <div
        data-parent={dataParent}
        className={styles.option}
        onClick={selectHandlerCB(shareType.SHARE_TO_WEB)}
      >
        <RadioButton
          data-parent={dataParent}
          data-cy="isShareToWeb"
          className={`${styles.radioButton} ${
            isActiveShareToWeb ? styles.selected : ''
          } ${isMultiple ? styles.disabled : ''}`}
        />
        <div
          data-parent={dataParent}
          className={`${styles.optional_text} ${
            isMultiple ? styles.disabled : ''
          }`}
        >
          {t('shareToWebT')}
        </div>
      </div>
    </div>
  );
});

export default ShareRadioButtonGroup;
