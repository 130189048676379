import { $getRoot, BLUR_COMMAND, FOCUS_COMMAND, COMMAND_PRIORITY_EDITOR, COMMAND_PRIORITY_LOW } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect, useState } from 'react';

export default function PlaceholderPlugin({ placeholder, placeholderClass = 'placeholder-title' }) {
  const [editor] = useLexicalComposerContext();
  const [isEditorEmpty, setIsEditorEmpty] = useState(false);

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.update(() => {
        const rootElement = $getRoot();
        const textContent = rootElement.getTextContent();
        setIsEditorEmpty(textContent === '');
      });
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          const rootElement = $getRoot();
          const textContent = rootElement.getTextContent();
          setIsEditorEmpty(textContent === '');
        },
        COMMAND_PRIORITY_EDITOR,
      );
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          setIsEditorEmpty(false);
        },
        COMMAND_PRIORITY_LOW,
      );
    });
  }, []);
  if (!isEditorEmpty) return <></>;

  return (
    <div className={`placeholder-without-focus ${placeholderClass}`}>
      {placeholder}
    </div>
  );
}
