import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ColumnButton } from '../StyledComponents';
import { ReactComponent as ChevronDownSmallSvg } from '../../../../../images/icons/chevron_down_12.svg';
import useComponentVisible from '../../../../../utils/hooks/useComponentVisible';
import { DATA_PARENTS } from '../../../../../utils/constants';
import ColumnsDropDown from '../ColumnsDropDown';

const column = { name: 'Board' };

const calcCurrentColumn = (columns = {}, closeColumn = {}, inColumn = {}) => {
  if (columns[inColumn]) return columns[inColumn];
  if (inColumn === closeColumn.id) return closeColumn;
  return column;
};

const ColumnSelector = ({
  item,
  isInboxCard,
  setActive,
  setSelectedColumn,
  close,
  tableView,
  isCard,
}) => {
  const { columns, closeColumn } = useSelector(
    (state) => state.dashboardColumns,
  );

  const [selectedColumn, setSelectedColumn2] = useState(
    calcCurrentColumn(columns, closeColumn, item.inColumn),
  );
  const dataParent = `${DATA_PARENTS.PlaylistBuilderLibrary} ${item?.id} miniPage`;
  const [refMenu, isMenuVisible, setIsMenuVisible] = useComponentVisible(
    null,
    dataParent,
  );

  const calcDropdownPosition = useCallback(() => {
    if (isCard) return { top: 135, right: 0 };
    if (isInboxCard) return { top: 25, right: '-100%' };
    return null;
  }, [isCard, isInboxCard]);

  useEffect(() => {
    if (!isMenuVisible && isMenuVisible !== null && close) {
      close();
    }
  }, [isMenuVisible]);

  const onColumnClickHandler = (e) => {
    e.stopPropagation();
    setIsMenuVisible(!isMenuVisible);
    if (setActive) setActive(item?.id);
    // dispatch(actionCreator(SupportAction.SetActiveMenuItemId, { id: item?.id }));
  };

  const setSelectedColumnHandler = (newColumn) => {
    setSelectedColumn2(newColumn);
    setSelectedColumn(newColumn);
  };

  const sameColors = !!selectedColumn?.color && selectedColumn?.backgroundColor === selectedColumn?.color;

  return (
    <>
      <ColumnButton
        isCard={isCard}
        isRead={item.isRead}
        tableView={tableView}
        isInboxCard={isInboxCard}
        onClick={onColumnClickHandler}
        active={isMenuVisible}
        background={selectedColumn?.backgroundColor ?? '#FFDE80'}
        text={selectedColumn?.color ?? '#484343'}
        sameColors={sameColors}
      >
        <p>{selectedColumn?.name}</p>
        <ChevronDownSmallSvg />
      </ColumnButton>

      {isMenuVisible && (
        <ColumnsDropDown
          refMenu={refMenu}
          defaultPosition={calcDropdownPosition()}
          item={item}
          setSelectedColumn={setSelectedColumnHandler}
          selectedColumn={selectedColumn}
          dataParent={dataParent}
          setIsMenuVisible={setIsMenuVisible}
        />
      )}
    </>
  );
};

export default ColumnSelector;
