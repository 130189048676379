export const MiniPlaylists = {
  getMiniPlaylist: '[MiniPlaylists] get mini Playlist',
  getMiniPlaylistForce: '[MiniPlaylists] get mini Playlist Force',
  saveMiniPlaylist: '[MiniPlaylists] save mini Playlist',
  getMiniPlaylistsClear: '[MiniPlaylists] get mini Playlist whit init state',
  getMiniPlaylists: '[MiniPlaylists] get many mini Playlists ',
  togglePublishMiniPlaylist: '[MiniPlaylists] toggle publish Playlist',
  togglePublishMiniPage: '[MiniPlaylists] toggle publish Page',
  GetLinkPagesForPlaylistS: '[MiniPlaylists] get link pages for playlist SAGAS',

  ToggleHide: '[EditPlaylist] toggle playlist to hide/show',
};
