import styled from 'styled-components/macro';

export const PBWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ isCardView }) => (isCardView ? `
  width: 130px;
  position: relative;
  justify-content: center;
  bottom: -5px;
  left: 5px;
  ` : '')}
  ${({ isListView }) => (isListView ? `
  overflow: hidden;
  height: 20px;
  width: 152px;
  ` : '')}
  ${({ isPlayer }) => (isPlayer ? `
    margin-top: 5px;
    margin-bottom: 12px;
  ` : '')}
  ${({ isMaker }) => (isMaker ? `
    height: 30px;
  ` : '')}
`;
export const PBCardViewInnerContainer = styled.div`
  display: flex;
   ${({ isCardView }) => (isCardView ? `
    flex-direction: column;
    align-items: center;
    gap: 6px;
   `
    : '')};
`;

export const PBCounterContainer = styled.div`
  color: #979797;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  ${({ isListView }) => (isListView ? `
  position: relative;
  ` : '')}
  ${({ isCardView }) => (isCardView ? `
    margin-right: 3px;
    order: 2;
  ` : '')}
  ${({ isMaker }) => (isMaker ? `
  position: relative;
  ` : '')}
  ${({ isMaker, forCutHeader }) => (isMaker && forCutHeader ? `
      display:none;
  ` : '')}
`;

export const PBEmptyStripe = styled.div`
  height: 6px;
  border-radius: 4px;
  background: rgba(52, 52, 52, 0.10);

  display: flex;
  align-items: center;
  ${({ isCardView }) => (isCardView ? `
  width: 130px;
  order: 1;
  ` : '')}
  ${({ isListView }) => (isListView ? `
  margin-left: 6px;
  margin-right: 8px;
  width: 90px;
  position: relative;
  ` : '')}
  ${({ isMaker }) => (isMaker ? `
  margin-left: 8px;
  margin-right: 8px;
  width: 180px;
  position: relative;
  ` : '')}
   ${({ isMaker, isCut }) => (isMaker && isCut ? `
    width: 104px;
  ` : '')}
  ${({ isMaker, forCutHeader }) => (isMaker && forCutHeader ? `
      display:none;
  ` : '')}
  ${({ isPlayer }) => (isPlayer ? `
  border-radius: 24px;
  margin-left: 8px;
  margin-right: 8px;
  width: 304px;
  height: 12px;
  ` : '')}
`;
export const PBFilledStripe = styled.div`
  ${({ width }) => (width
    ? `width:${width}px;`
    : 'width:0;')}
  border-radius: 4px;
  background: #29BF12;

  ${({ isPlayer }) => (isPlayer ? `
    top: 13px;
  height: 12px;
  ` : '  height: 6px;')}
`;
export const PBSVGWrapperPlayer = styled.div`
  position: absolute;
  right: -3px;
  top: -13px;
  svg{
    width: 38px;
    height: 38px;
  }
`;
export const PBSVGWrapper = styled.div`
  position: absolute;
  right:-2px;
  top: -7px;
`;
