import React from 'react';
import { useTranslation } from 'react-i18next';


const InvoiceSingle = ({ item }) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', gap: '30px' }}>
      <div>${item?.total && (item.total / 100).toFixed(2)}</div>
      <div>{item.lines.data[0].description}</div>
      <div>
        {item?.status_transitions?.paid_at && new Date(item.status_transitions.paid_at * 1000).toDateString()}
      </div>
      <a
        href={item.hosted_invoice_url}
        target="_blank"
        rel="noreferrer"
      >
        {t('linkT')}
      </a>
    </div>
  );
};

export default InvoiceSingle;
