import { useState, useEffect, Dispatch, SetStateAction } from 'react';

type UseMiniPageClickOutsideReturnType = [boolean, Dispatch<SetStateAction<boolean>>];

const useMiniPageClickOutside = (initialIsVisible: boolean): UseMiniPageClickOutsideReturnType => {
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(initialIsVisible);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      isComponentVisible
        && !(
          (event.target as HTMLElement)?.dataset?.parent?.includes('miniPage')
            || (event.target as HTMLElement)?.parentElement?.dataset?.parent?.includes('miniPage')
        )
    ) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isComponentVisible]);

  return [isComponentVisible, setIsComponentVisible];
};

export default useMiniPageClickOutside;
