import React, { ChangeEvent, KeyboardEvent, RefObject } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { colorsApprovedButton } from '../../utils/constants';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

type ColorsApprovedButton = typeof colorsApprovedButton[number];


interface CreateApprovePopUpProps {
  isOpenMenu: boolean;
  exRef?: RefObject<HTMLDivElement> | null;
  buttonId: string;
  setButtonId: (value: string) => void;
  defaultName: string;
  setDefaultName: (value: string) => void;
  clickedName: string;
  setClickedName: (value: string) => void;
  setColor: (color: ColorsApprovedButton) => void;
  color: ColorsApprovedButton;
}

const CreateApprovePopUp: React.FC<CreateApprovePopUpProps> = ({
  isOpenMenu,
  exRef,
  buttonId,
  setButtonId,
  defaultName,
  setDefaultName,
  clickedName,
  setClickedName,
  setColor,
  color,
}) => {
  const { t } = useTranslation();
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
    if (e.key === 'Backspace') {
      e.stopPropagation();
    }
  };

  if (!isOpenMenu) return <></>;

  return (
    <div
      data-cy="approve_settings_menu"
      ref={exRef}
      className={cx('settings_menu')}
    >
      <div className={cx('item_wrapper')}>
        <div className={cx('text', { active: !buttonId.length })}>
          {t('buttonNameT')}
        </div>
        <div className={cx('input_wrapper')}>
          <input
            data-cy="btn-id"
            maxLength={25}
            value={buttonId}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setButtonId(event.target.value)}
            placeholder={t('approveButtonT')}
            onKeyDown={handleKeyDown}
            onBlur={() => {
              if (!buttonId) setButtonId(t('approveButtonT'));
            }}
          />
        </div>
      </div>
      <div className={cx('item_wrapper')}>
        <div className={cx('text', { active: !defaultName.length })}>
          {t('choiceAffirmativeT')}
        </div>
        <div className={cx('input_wrapper')}>
          <input
            data-cy="default-name"
            maxLength={25}
            value={defaultName}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setDefaultName(event.target.value)}
            placeholder={t('approveT')}
            onKeyDown={handleKeyDown}
            onBlur={() => {
              if (!defaultName) setDefaultName(t('approveT'));
            }}
          />
        </div>
      </div>
      <div className={cx('item_wrapper')}>
        <div className={cx('text', { active: !clickedName.length })}>
          {t('choiceNegativeT')}
        </div>
        <div className={cx('input_wrapper')}>
          <input
            data-cy="clicked-name"
            maxLength={25}
            value={clickedName}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setClickedName(event.target.value)}
            placeholder={t('dissaproveT')}
            onKeyDown={handleKeyDown}
            onBlur={() => {
              if (!clickedName) setClickedName(t('dissaproveT'));
            }}
          />
        </div>
      </div>
      <div className={cx('item_wrapper')}>
        <div className={cx('text')}>{t('buttonColorT')}</div>
        <div data-cy="btn-color" className={cx('select_color')}>
          {colorsApprovedButton.map((selectableColor) => (
            <div
              key={selectableColor}
              onClick={() => setColor(selectableColor)}
              className={cx('picker_wrapper', {
                active: selectableColor === color,
              })}
            >
              <div className={cx('picker', `${selectableColor}`)} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CreateApprovePopUp;
