import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { BLUR_COMMAND, COMMAND_PRIORITY_EDITOR } from 'lexical';
import { DATA_PARENTS } from '../../../utils/constants';

export const EditorBlurPlugin = ({ onBlurHandler, isLinkEditMode, setIsLinkEditMode, isOpenOptionsRef, idKey = '' }) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (!onBlurHandler) return;
    editor.registerCommand(
      BLUR_COMMAND,
      (e) => {
        if (isOpenOptionsRef?.current) {
          e.stopPropagation();
          e.preventDefault();
          return;
        }
        const element = e.relatedTarget;
        const parentElement = element?.parentElement;
        const parentParentElement = parentElement?.parentElement;
        if (element?.role === 'checkbox') {
          if (parentParentElement.parentElement.dataset?.parent?.includes(idKey)) {
            setTimeout(() => {
              editor.focus();
            }, 100);
            return;
          }
        }
        const clickOnConfirmLink = !!element?.dataset.confirmlink;

        if (clickOnConfirmLink) {
          setIsLinkEditMode(false);
          editor.focus();
          return;
        }

        const clickOnEditLink = element?.dataset.parent2 === DATA_PARENTS.TextFormatFloatingLink
          || parentElement?.dataset.parent2 === DATA_PARENTS.TextFormatFloatingLink;

        if (clickOnEditLink) {
          return;
        }

        const clickOnFloatingBar = element?.dataset.parent === DATA_PARENTS.TextFormatToolbar
            || parentElement?.dataset.parent === DATA_PARENTS.TextFormatToolbar
            || parentParentElement?.dataset.parent === DATA_PARENTS.TextFormatToolbar;

        if (clickOnFloatingBar) {
          editor.focus();
          return;
        }
        if (isLinkEditMode?.current) {
          setIsLinkEditMode(false);
          editor.focus();
          return;
        }
        onBlurHandler(editor.getEditorState().toJSON());
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, []);

  return null;
};
