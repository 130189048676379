import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CloseCrossSvg } from '../../images/2023/svg/small/component-description/close_16_16.svg';

const CloseBtnWrapper = styled.div`
  box-shadow: 0px 1px 4px 0px #7a696933;
  position: absolute;
  border-width: 1px;
  height: 32px;
  width: 32px;
  border-style: solid;
  border-color: #ece2e2;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  padding-top: 2%;
  cursor: pointer;

  ${({ moveTop }) => (moveTop ? `padding-top:${moveTop}px;` : '')}
  ${({ top }) => (top ? `top:${top}px;` : '')}
  ${({ right }) => (right ? `right:${right}px;` : '')}
  ${({ left }) => (left ? `left:${left}px;` : '')}
  ${({ position }) => (position ? `position:${position};` : '')}
  &:hover {
    background-color: #ffeebf;
    border-color: #FFC72C;
  }
`;

const CloseRoundedBtnWithCross = ({ ...props }) => {
  return (
    <CloseBtnWrapper
      top={props.top}
      right={props.right}
      onClick={props.onClick}
      moveTop={props.moveTop}
      position={props.position}
      left={props.left}
    >
      <CloseCrossSvg />
    </CloseBtnWrapper>
  );
};

export default CloseRoundedBtnWithCross;
