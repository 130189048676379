import { convertFromRaw } from 'draft-js';
import React from 'react';

const EditorState = require('draft-js/lib/EditorState');
const DraftModifier = require('draft-js/lib/DraftModifier');

export const toggleAllBlocksType = (editorState, blockType) => {
  const selection = editorState.getSelection();
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const content = editorState.getCurrentContent();
  let target = selection; // Triple-click can lead to a selection that includes offset 0 of the
  // following block. The `SelectionState` for this case is accurate, but
  // we should avoid toggling block type for the trailing block because it
  // is a confusing interaction.
  const contentState = editorState.getCurrentContent();

  const arrayOBlocks = contentState.getBlocksAsArray();
  const arrayOBlocksKeys = arrayOBlocks.map((block) => block.getKey());
  // if (startKey !== endKey && selection.getEndOffset() === 0) {
  //   endKey = blockBefore.getKey();
  target = target.merge({
    anchorKey: arrayOBlocksKeys[0],
    anchorOffset: 0,
    focusKey: arrayOBlocksKeys[arrayOBlocksKeys.length - 1],
    focusOffset: arrayOBlocks[arrayOBlocks.length - 1].getLength(),
    isBackward: false,
  });
  // }

  const hasAtomicBlock = content
    .getBlockMap()
    .skipWhile((_, k) => k !== startKey)
    .reverse()
    .skipWhile((_, k) => k !== endKey)
    .some((v) => v.getType() === 'atomic');

  if (hasAtomicBlock) {
    return editorState;
  }

  const typeToSet = content.getBlockForKey(startKey).getType() === blockType
    ? 'unstyled'
    : blockType;

  const a = EditorState.push(
    editorState,
    DraftModifier.setBlockType(content, target, typeToSet),
    'change-block-type',
  );
  return EditorState.create({
    currentContent: a.getCurrentContent(),
    selection,
  });
};

export const getNameFromState = (item) => {
  const parsed = item?.textComponent?.state?.getCurrentContent
    && item?.textComponent?.state?.getCurrentContent().getPlainText('');

  if (typeof parsed === 'string') return parsed;
  try {
    const content = EditorState.createWithContent(
      convertFromRaw(
        JSON.parse(
          item?.textComponent?.state
            .replaceAll('"', '\\"')
            .replaceAll("'", '"')
            .replaceAll(/(\r\n|\n|\r)/gm, '\\n')
            .replaceAll('$@quote', "'"),
        ),
      ),
    );
    return content.getCurrentContent().getPlainText('');
  } catch (e) {
    console.log(e);
    return '';
  }
};


export const LinkDecorator = (props) => {
  const { contentState, entityKey, children } = props;
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};
