import { call, put, takeEvery } from 'redux-saga/effects';
import { all, select } from '@redux-saga/core/effects';
import { v4 as uuidv4 } from 'uuid';
import DashboardColumns from '../redux/dashboardColumns/types';
import { getUser, showErrorMessage } from './sagasUtils';
import { actionCreator } from '../shared/redux/actionHelper';
import { actionShowMessage } from '../redux/support/action';
import {
  COLUMN_CUSTOM_TYPE,
  DEFAULT_POSITION_STEP,
  DEFAULT_TITLE,
  MessageType,
} from '../utils/constants';
import {
  calculateIndex,
  sanitizeToLoad,
  sanitizeToSave,
} from '../utils/helpers';
import EditPlaylist from '../redux/playlists/types';
import {
  isPlaylistAvailableOnBoard,
  isSharesPlaylistAvailableOnBoard,
} from '../utils/dateConvert';
import {
  requestCreateCard,
  requestCreateColumns,
  requestDeleteCard,
  requestDeleteColumns,
  requestGetSomeColumnsContent,
  requestGetSomePlaylistContent,
  requestUpdateCard,
  requestUpdateColumns,
  requestUpdatePlaylistManagerSeen,
} from '../utils/request';
import i18n from '../i18n';

export const calcReadPercentage = (playlist) => {
  if (!playlist?.linkPages) return 0;
  return (
    (playlist.linkPages.filter(
      (lp) => lp.mainUserInterface?.userView.length,
    ).length
      / (playlist.linkPages.length || 1))
    * 100
  );
};

const calcNewState = (data) => {
  const cards = {};
  const columns = {};
  const structure = [];

  Object.keys(data).forEach((itKey) => {
    columns[itKey] = {};
    columns[itKey].name = sanitizeToLoad(data[itKey].name);
    columns[itKey].color = data[itKey].color;
    columns[itKey].backgroundColor = data[itKey].backgroundColor;
    columns[itKey].id = data[itKey].id;
    columns[itKey].position = data[itKey].position;
    const items = Object.values(data[itKey].items || {}).sort(
      (i, b) => i.position - b.position,
    );
    columns[itKey].items = {};

    structure.push({
      id: itKey,
      position: data[itKey].position,
      name: sanitizeToLoad(data[itKey].name),
      items: items.map((i) => i.id),
    });

    Object.keys(data[itKey].items).forEach((cardKei) => {
      columns[itKey].items[cardKei] = {
        ...data[itKey].items[cardKei],
        // channel: card.playlist.inChannel.map(i => ({ ...i, name: sanitizeToLoad(i.name) })),
        parent: itKey,
        name: sanitizeToLoad(data[itKey].items[cardKei].name),
      };
      cards[cardKei] = {
        ...data[itKey].items[cardKei],
        // channel: card.playlist.inChannel.map(i => ({ ...i, name: sanitizeToLoad(i.name) })),
        parent: itKey,
        name: sanitizeToLoad(data[itKey].items[cardKei].name),
      };
    });
  });

  return {
    cards,
    columns,
    structure: structure.sort((i, b) => b.position - i.position),
  };
};

const updateState = (columns) => {
  const structure = [];
  Object.keys(columns).forEach((itKey) => {
    structure.push({
      id: itKey,
      position: columns[itKey].position,
      name: columns[itKey].name,
      items: Object.values(columns[itKey].items || {})
        .sort((i, b) => i.position - b.position)
        .map((i) => i.id),
    });
  });
  return structure.sort((i, b) => b.position - i.position);
};
const calcCardStateClose = (cards, userId) => {
  return cards
    .map((card) => {
      if (!card.playlist || !card.playlist[0]) return;
      const playlist = card.playlist[0];
      const isOwner = !!playlist.users.filter(elem => elem.id === userId).length;
      const isCanReadDraft = isOwner
        || (!!playlist.canReadDraft?.length
          && isSharesPlaylistAvailableOnBoard(playlist));
      const isCanReadPublish = !!playlist.canReadPublish?.length
        && isPlaylistAvailableOnBoard(playlist);
      let title = 'Unavailable';
      if (isCanReadPublish) title = playlist?.publishPlaylis?.title;
      if (isCanReadDraft) title = playlist?.editPlaylist?.title;
      const cropUrl = playlist.editPlaylist?.cropUrl;
      const user = {
        fullName: playlist.users?.[0]?.showUsername
          ? playlist.users?.[0]?.username : `${playlist.users?.[0]?.first_name} ${playlist?.users?.[0]?.last_name}`,
        avatar: playlist.users?.[0]?.avatarUrlVerySmall,
        displayImage: playlist.users?.[0]?.displayImage === 'AvatarImage',
      };
      return {
        id: card.id,
        position: card.position,
        name: sanitizeToLoad(title),
        lastModifiedDate: playlist?.editPlaylist?.lastModifiedDate,
        availableTo: playlist?.editPlaylist?.availableTo,
        availableFrom: playlist?.editPlaylist?.availableFrom,
        sharedAvailableFrom: playlist.sharedAvailableTo,
        sharedAvailableTo: playlist.sharedAvailableTo,
        playlistId: playlist?.editPlaylist?.id,
        wrapperId: playlist?.id,
        channel: playlist?.inChannel?.map((i) => ({
          ...i,
          name: sanitizeToLoad(i.name),
        })),
        channelValidId:
          !!playlist.canReadPublish?.length && playlist.canReadPublish[0].id,
        isOwner,
        readPercentage: calcReadPercentage(playlist?.editPlaylist),
        isCanReadPublish,
        isCanReadDraft,
        cropUrl,
        user,
      };
    })
    .filter((i) => i)
    .sort((i, b) => i.position - b.position);
};
// const calcCardStateInColumn = (cards, existed) => {
//   const cardInbox = {};
//   const notSeenPlaylist = {};
//
//   cards.forEach(card => {
//     card.editManager.inChannel.forEach((channel) => {
//       if (existed[card.id + channel.id]) return;
//       if (!card.editManager.seenByUsers.length) {
//         notSeenPlaylist[card.editManager.id] = !card.editManager.seenByUsers.length;
//       }
//       cardInbox[card.id + channel.id] = ({
//         id: card.id + channel.id,
//         position: card.position,
//         name: sanitizeToLoad(card?.title || 'Untitled'),
//         lastModifiedDate: card?.lastModifiedDate,
//         availableTo: card?.availableTo,
//         playlistId: card?.id,
//         wrapperId: card.editManager?.id,
//         channel: { id: channel.id, name: sanitizeToLoad(channel.name) },
//         isOwner: card.editManager.users?.length,
//         readPercentage: calcReadPercentage(card) || 0,
//       });
//     });
//   },
//   );
//
//   return { calcItemsInbox: Object.values(cardInbox || {}), notSeenPlaylist };
// };
const calcCardState = (cards, userId) => {
  const cardMap = {};
  cards.forEach((card) => {
    if (!card.playlist || !card.playlist[0]) return;
    const playlist = card.playlist && card.playlist[0];
    const cropUrl = playlist.editPlaylist?.cropUrl;
    const user = {
      fullName: playlist.users?.[0]?.showUsername
        ? playlist.users?.[0]?.username : `${playlist.users?.[0]?.first_name} ${playlist?.users?.[0]?.last_name}`,
      avatar: playlist.users?.[0]?.avatarUrlVerySmall,
      displayImage: playlist.users?.[0]?.displayImage === 'AvatarImage',
    };
    const isOwner = !!playlist.users.filter(elem => elem.id === userId).length;
    const isCanReadDraft = isOwner
      || (!!playlist.canReadDraft?.length
        && isSharesPlaylistAvailableOnBoard(playlist));
    const isCanReadPublish = !!playlist.canReadPublish?.length && isPlaylistAvailableOnBoard(playlist);

    cardMap[card.id] = {
      playlist,
      id: card.id,
      position: card.position,
      name: sanitizeToLoad(playlist?.editPlaylist?.title) || i18n.t(DEFAULT_TITLE.Playlist),
      lastModifiedDate: playlist?.editPlaylist?.lastModifiedDate,
      availableTo: playlist?.editPlaylist?.availableTo,
      availableFrom: playlist?.editPlaylist?.availableFrom,
      sharedAvailableFrom: playlist.sharedAvailableTo,
      sharedAvailableTo: playlist.sharedAvailableTo,
      playlistId: playlist?.editPlaylist?.id,
      wrapperId: playlist?.id,
      isCoEdit: playlist?.isCoEdit,
      // channel: card.inChannel,
      channelValidId:
        !!playlist.canReadPublish?.length && playlist.canReadPublish[0].id,
      channel: playlist?.inChannel?.map((i) => ({
        ...i,
        name: sanitizeToLoad(i.name),
      })),
      isOwner,
      readPercentage: calcReadPercentage(playlist?.editPlaylist) || 0,
      isCanReadPublish,
      isCanReadDraft,
      cropUrl,
      user,
    };
  });
  return cardMap;
};

function* moveToColumn(action) {
  try {
    const { columnId, moveOverCard, dragCard } = action.payload;
    const moveToEnd = !moveOverCard;

    const { cards, columns, structure, closeColumn } = yield select(
      (state) => state.dashboardColumns,
    );
    const oldColumnsId = cards[dragCard]?.parent;
    if (!oldColumnsId) {
      let newPosition;
      const moveOutCloseColumn = closeColumn.items.find(
        (i) => i.id === dragCard,
      );
      if (moveOutCloseColumn) {
        let newCards;
        if (!moveToEnd) {
          const currentColumns = columns[columnId];

          const orderedItemsInCurrentColumns = Object.values(
            currentColumns.items || {},
          )
            .sort((i, b) => i.position - b.position)
            .map((i) => i.id);

          const positionOver = cards[moveOverCard]?.position || DEFAULT_POSITION_STEP;
          const nextItemIndexIndex = orderedItemsInCurrentColumns.findIndex(
            (i) => i === moveOverCard,
          );
          let positionUnder = 0;
          if (nextItemIndexIndex) {
            const nextItem = orderedItemsInCurrentColumns[nextItemIndexIndex - 1];
            positionUnder = cards[nextItem]?.position || positionOver + DEFAULT_POSITION_STEP;
          }
          newPosition = (positionOver + positionUnder) / 2;
          newCards = {
            ...cards,
            [dragCard]: {
              ...moveOutCloseColumn,
              position: newPosition,
              parent: columnId,
            },
          };
        } else {
          const calcMaxPosition = Math.max(
            ...Object.values(columns[columnId].items || {}).map(
              (i) => i.position || 0,
            ),
            0,
          );
          newPosition = calcMaxPosition + DEFAULT_POSITION_STEP;
          newCards = {
            ...cards,
            [dragCard]: {
              ...moveOutCloseColumn,
              position: newPosition,
              parent: columnId,
            },
          };
        }

        const newColumnsItem = closeColumn.items.filter(
          (i) => dragCard !== i.id,
        );
        const newColumns = { ...columns };
        newColumns[columnId].items[dragCard] = {
          ...moveOutCloseColumn,
          position: newPosition,
          parent: columnId,
        };

        yield put(
          actionCreator(DashboardColumns.uploadColumnsRedux, {
            columns: newColumns,
            cards: newCards,
            structure: updateState(newColumns, newCards),
            closeColumn: { ...closeColumn, items: newColumnsItem },
          }),
        );
        yield put(
          actionCreator(EditPlaylist.UpdateCardStateR, {
            ...moveOutCloseColumn,
            channelId: moveOutCloseColumn.channel.id,
            cardId: moveOutCloseColumn.id,
            playlistId: moveOutCloseColumn.playlistId,
            addToColumn: columnId,
            removeToColumn: closeColumn.id,
            inColumn: {
              id: columns[columnId].id,
              name: columns[columnId].name,
              color: columns[columnId].color,
            },
          }),
        );
        // yield wrapperRequestNeo4j(queryMoveToNewColumn(
        //   { ...moveOutCloseColumn, position: newPosition }, closeColumn.id, columnId));
        yield call(requestUpdateCard, {
          id: moveOutCloseColumn.id,
          fieldsUpdateObg: { position: newPosition },
          connect: { column: [columnId] },
          disconnect: { column: [closeColumn.id] },
        });
        return;
      }
    }
    const currentColumns = columns[columnId];
    let newPosition;
    let newCards;

    if (!moveToEnd) {
      const orderedItemsInCurrentColumns = Object.values(
        currentColumns.items || {},
      )
        .sort((i, b) => i.position - b.position)
        .map((i) => i.id);

      const positionOver = cards[moveOverCard]?.position || DEFAULT_POSITION_STEP;
      const nextItemIndexIndex = orderedItemsInCurrentColumns.findIndex(
        (i) => i === moveOverCard,
      );
      let positionUnder = 0;
      if (nextItemIndexIndex) {
        const nextItem = orderedItemsInCurrentColumns[nextItemIndexIndex - 1];
        positionUnder = cards[nextItem]?.position || positionOver + DEFAULT_POSITION_STEP;
      }
      newPosition = (positionOver + positionUnder) / 2;
      newCards = {
        ...cards,
        [dragCard]: {
          ...cards[dragCard],
          position: newPosition,
          parent: columnId,
        },
      };
    } else {
      const calcMaxPosition = Math.max(
        ...Object.values(currentColumns.items || {}).map(
          (i) => i.position || 0,
        ),
        0,
      );
      newPosition = calcMaxPosition + DEFAULT_POSITION_STEP;
      newCards = {
        ...cards,
        [dragCard]: {
          ...cards[dragCard],
          position: newPosition,
          parent: columnId,
        },
      };
    }

    const moveToNewColumn = columnId !== oldColumnsId;
    let newColumns;

    if (moveToNewColumn) {
      newColumns = {
        ...columns,
        [columnId]: { ...columns[columnId] },
        [oldColumnsId]: { ...columns[oldColumnsId] },
      };
      delete newColumns[oldColumnsId]?.items[dragCard];
    } else {
      newColumns = { ...columns };
    }
    if (!newColumns[columnId].items) newColumns[columnId].items = {};
    newColumns[columnId].items[dragCard] = {
      ...cards[dragCard],
      position: newPosition,
      parent: columnId,
    };

    yield put(
      actionCreator(DashboardColumns.uploadColumnsRedux, {
        columns: newColumns,
        cards: newCards,
        structure: updateState(newColumns, newCards),
        closeColumn,
      }),
    );
    yield put(
      actionCreator(EditPlaylist.UpdateCardStateR, {
        ...cards[dragCard],
        channelId: cards[dragCard].channel.id,
        cardId: cards[dragCard].id,
        playlistId: cards[dragCard].playlistId,
        addToColumn: columnId,
        removeToColumn: oldColumnsId,
        inColumn: {
          id: currentColumns.id,
          name: currentColumns.name,
          color: currentColumns.color,
        },
      }),
    );
    try {
      if (moveToNewColumn) {
        // yield wrapperRequestNeo4j(queryMoveToNewColumn(newColumns[columnId].items[dragCard], oldColumnsId, columnId));
        yield call(requestUpdateCard, {
          id: newColumns[columnId].items[dragCard].id,
          fieldsUpdateObg: {
            position: newColumns[columnId].items[dragCard].position,
          },
          connect: { column: [columnId] },
          disconnect: { column: [oldColumnsId] },
        });
      } else {
        yield call(requestUpdateCard, {
          id: newColumns[columnId].items[dragCard].id,
          fieldsUpdateObg: {
            position: newColumns[columnId].items[dragCard].position,
          },
        });
        // yield wrapperRequestNeo4j(queryChangePositionCard(newColumns[columnId].items[dragCard]));
      }
    } catch (err) {
      yield put(
        actionCreator(DashboardColumns.uploadColumnsRedux, {
          cards,
          columns,
          structure,
          closeColumn,
        }),
      );
      yield showErrorMessage(err, action);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

// function* moveToINBoxColumn(action) {
//   try {
//     const { dragCard } = action.payload;
//     const { cards, closeColumn } = yield select((state) => state.dashboardColumns);
//     const oldColumnsId = cards[dragCard]?.parent;
//     if (!oldColumnsId) {
//       const moveOutCloseColumn = closeColumn.items.find(i => i.id === dragCard);
//       if (moveOutCloseColumn) {
//         yield put(actionCreator(DashboardColumns.card.remove, { cardId: dragCard, isMoveFromClose: true }));
//         yield put(actionCreator(EditPlaylist.UpdateCardStateR, {
//           ...moveOutCloseColumn,
//           channelId: moveOutCloseColumn.channel?.id,
//           cardId: moveOutCloseColumn.id,
//           playlistId: moveOutCloseColumn.playlistId,
//           addToColumn: 'in',
//           removeToColumn: closeColumn.id,
//           inColumn: { id: closeColumn.id,
//             name: closeColumn.name,
//             color: closeColumn.color } }),
//         );
//         return;
//       }
//     }
//     yield put(actionCreator(DashboardColumns.card.remove, { cardId: dragCard, isMoveToIn: true }));
//   } catch (err) {
//     yield showErrorMessage(err, action);
//   }
// }

function* moveToCLoseColumn(action) {
  try {
    const { moveOverCard, dragCard } = action.payload;
    const moveToEnd = !moveOverCard;

    const { cards, columns, structure, closeColumn } = yield select(
      (state) => state.dashboardColumns,
    );
    const oldColumnsId = cards[dragCard]?.parent;

    const currentColumns = closeColumn;
    let newPosition;
    const newCards = { ...cards };

    if (!moveToEnd) {
      const orderedItemsInCurrentColumns = closeColumn.items.sort(
        (i, b) => i.position - b.position,
      );
      const nextItemIndexIndex = orderedItemsInCurrentColumns.findIndex(
        (i) => i.id === moveOverCard,
      );
      const positionOver = orderedItemsInCurrentColumns[nextItemIndexIndex]?.position
        || DEFAULT_POSITION_STEP;

      let positionUnder = 0;
      if (nextItemIndexIndex) {
        positionUnder = orderedItemsInCurrentColumns[nextItemIndexIndex - 1]?.position
          || positionOver + DEFAULT_POSITION_STEP;
      }
      newPosition = (positionOver + positionUnder) / 2;
    } else {
      const calcMaxPosition = Math.max(
        ...currentColumns.items.map((i) => i.position || 0),
        0,
      );
      newPosition = calcMaxPosition + DEFAULT_POSITION_STEP;
    }

    const moveToNewColumn = !!oldColumnsId;
    const newColumns = { ...columns };
    const newCloseColumns = { ...closeColumn };
    let newCard;
    let indexCard;
    const newItemsInCloseColumns = [...closeColumn.items];
    if (moveToNewColumn) {
      newCard = { ...cards[dragCard], position: newPosition };
      newItemsInCloseColumns.push({
        ...cards[dragCard],
        position: newPosition,
      });
      delete newCards[dragCard];
      delete newColumns[oldColumnsId].items[dragCard];
    } else {
      indexCard = newItemsInCloseColumns.findIndex((i) => i.id === dragCard);
      newItemsInCloseColumns[indexCard].position = newPosition;
      newCard = { ...newItemsInCloseColumns[indexCard], position: newPosition };
    }
    newCloseColumns.items = newItemsInCloseColumns.sort(
      (i, b) => i.position - b.position,
    );
    yield put(
      actionCreator(DashboardColumns.uploadColumnsRedux, {
        columns: newColumns,
        cards: newCards,
        structure: updateState(newColumns, newCards),

        closeColumn: newCloseColumns,
      }),
    );
    yield put(
      actionCreator(EditPlaylist.UpdateCardStateR, {
        ...newCard,
        cardId: newCard.id,
        playlistId: newCard.playlistId,
        addToColumn: closeColumn.id,
        removeToColumn: oldColumnsId,
        inColumn: {
          id: closeColumn.id,
          name: closeColumn.name,
          color: closeColumn.color,
        },
      }),
    );
    try {
      if (moveToNewColumn) {
        // yield wrapperRequestNeo4j(queryMoveToNewColumn(newCard, oldColumnsId, closeColumn.id));
        yield call(requestUpdateCard, {
          id: newCard.id,
          fieldsUpdateObg: { position: newCard.position },
          connect: { column: [closeColumn.id] },
          disconnect: { column: [oldColumnsId] },
        });
      } else {
        // yield wrapperRequestNeo4j(queryChangePositionCard(newItemsInCloseColumns[indexCard]));
        yield call(requestUpdateCard, {
          id: newItemsInCloseColumns[indexCard].id,
          fieldsUpdateObg: {
            position: newItemsInCloseColumns[indexCard].position,
          },
        });
      }
    } catch (err) {
      yield put(
        actionCreator(DashboardColumns.uploadColumnsRedux, {
          cards,
          columns,
          structure,
          closeColumn,
        }),
      );
      yield showErrorMessage(err, action);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* uploadColumns(action) {
  try {
    const { id: userId } = yield select(getUser);

    const {
      data: {
        User: [data],
      },
    } = yield requestGetSomeColumnsContent([]);
    let closeColumn = {};
    const testItems = {};
    data?.columns?.forEach((i) => {
      if (!i.customType) testItems[i.id] = { ...i, items: calcCardState(i.card, userId) };
      else if (i.customType === COLUMN_CUSTOM_TYPE.CLOSE) closeColumn = { ...i, name: 'Completed', items: calcCardStateClose(i.card, userId) };
    });

    if (!Object.keys(closeColumn).length) {
      closeColumn = {
        id: uuidv4(),
        name: 'Completed',
        customType: COLUMN_CUSTOM_TYPE.CLOSE,
        position: 0,
        items: [],
      };
      yield call(requestCreateColumns, {
        id: closeColumn.id,
        position: closeColumn.position,
        name: closeColumn.name,
        customType: closeColumn.customType,
        userId,
      });
    }
    const { cards, columns, structure } = calcNewState(testItems);
    const existCardInOtherColumn = {};
    Object.values(cards || {}).forEach((i) => {
      existCardInOtherColumn[i.playlistId + i.channel?.id] = true;
    });
    closeColumn.items.forEach((i) => {
      existCardInOtherColumn[i.playlistId + i.channel?.id] = true;
    });

    yield put(
      actionCreator(DashboardColumns.uploadColumnsRedux, {
        cards,
        columns,
        structure,
        closeColumn,
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* createColumns(action) {
  try {
    const { putAfterItemIndex } = action.payload;

    const { cards, columns, structure, closeColumn } = yield select(
      (state) => state.dashboardColumns,
    );
    const { id: userId } = yield select(getUser);
    const id = uuidv4();

    let position;
    if (
      (putAfterItemIndex || putAfterItemIndex === 0)
      && putAfterItemIndex !== 'first'
    ) {
      const putAfterItemId = structure[putAfterItemIndex]?.id;
      const putBeforeItemId = structure[putAfterItemIndex + 1]?.id;
      if (columns[putBeforeItemId]?.position) {
        position = calculateIndex(
          columns[putAfterItemId]?.position,
          columns[putBeforeItemId]?.position,
        );
      } else {
        position = columns[putAfterItemId]?.position / 2;
      }
    } else {
      const lastItemId = structure[0]?.id;
      position = (columns[lastItemId]?.position || 0) + DEFAULT_POSITION_STEP;
    }

    const newColumn = { id, position, name: 'Untitled', backgroundColor: '#FFDE80' };
    const newColumns = { ...columns, [newColumn.id]: newColumn };
    yield put(
      actionCreator(DashboardColumns.uploadColumnsRedux, {
        columns: newColumns,
        cards,
        structure: updateState(newColumns, cards),
        closeColumn,
      }),
    );

    try {
      yield call(requestCreateColumns, {
        id: newColumn.id,
        position: newColumn.position,
        name: newColumn.name,
        customType: newColumn.customType,
        userId,
      });
    } catch (err) {
      yield put(
        actionCreator(DashboardColumns.uploadColumnsRedux, {
          cards,
          columns,
          structure,
        }),
      );
      yield showErrorMessage(err, action);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updatePositionColumns(action) {
  try {
    const { columnId, dragColumn } = action.payload;
    const { columns, structure, cards, closeColumn } = yield select(
      (state) => state.dashboardColumns,
    );
    let newPosition;
    if (columnId === COLUMN_CUSTOM_TYPE.CLOSE) {
      const endPosition = structure[structure.length - 1].position;
      newPosition = endPosition / 2;
    } else if (columnId === COLUMN_CUSTOM_TYPE.INBOX) {
      const endPosition = structure[0].position;
      newPosition = endPosition + DEFAULT_POSITION_STEP;
    } else {
      const moveTOIndex = structure.findIndex((i) => i.id === columnId);
      const startPositionColumn = columns[columnId]?.position;
      const endColumnID = structure[moveTOIndex - 1]?.id;
      const endPositionColumn = columns[endColumnID]?.position
        || startPositionColumn + DEFAULT_POSITION_STEP;
      newPosition = (startPositionColumn + endPositionColumn) / 2;
    }
    const updColumn = { ...columns[dragColumn], position: newPosition };
    const newColumns = { ...columns, [dragColumn]: updColumn };
    yield put(
      actionCreator(DashboardColumns.uploadColumnsRedux, {
        columns: newColumns,
        cards,
        structure: updateState(newColumns, cards),
        closeColumn,
      }),
    );
    try {
      yield call(requestUpdateColumns, {
        id: updColumn.id,
        fieldsUpdateObg: { position: updColumn.position },
      });
    } catch (err) {
      yield put(
        actionCreator(DashboardColumns.uploadColumnsRedux, {
          cards,
          columns,
          structure,
          closeColumn,
        }),
      );
      yield showErrorMessage(err, action);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* removeColumn(action) {
  try {
    const { columnId } = action.payload;
    const dashboardColumns = yield select((state) => state.dashboardColumns);
    const newColumns = {
      ...dashboardColumns.columns,
      [columnId]: {
        ...dashboardColumns.columns[columnId],
        items: { ...dashboardColumns.columns[columnId].items },
      },
    };

    const newCard = { ...dashboardColumns.cards };
    Object.keys(dashboardColumns.columns[columnId].items || {}).forEach(
      (cardId) => {
        delete newColumns[newCard[cardId].parent].items[cardId];
        delete newCard[cardId];
      },
    );

    delete newColumns[columnId];

    yield put(
      actionCreator(DashboardColumns.uploadColumnsRedux, {
        ...dashboardColumns,
        columns: newColumns,
        structure: updateState(newColumns, dashboardColumns.cards),
      }),
    );
    try {
      yield call(requestDeleteColumns, {
        id: columnId,
        nestedCardId: Object.values(
          dashboardColumns.columns[columnId].items || {},
        ).map((i) => i.id),
      });
      yield put(
        actionShowMessage({
          type: MessageType.SuccessfullyDeletedBoardColumn,
          message: i18n.t('successfullyDeletedBoardColumnT'),
        }),
      );
    } catch (err) {
      yield put(
        actionCreator(DashboardColumns.uploadColumnsRedux, dashboardColumns),
      );
      yield showErrorMessage(err, action);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* addToColumn(action) {
  try {
    const { card, columnId } = action.payload;
    const dashboardColumns = yield select((state) => state.dashboardColumns);
    const newColumns = { ...dashboardColumns.columns };
    const newCard = { ...dashboardColumns.cards };
    if (!newColumns[columnId]?.items) newColumns[columnId].items = {};
    newColumns[columnId].items[card.id] = card;
    newCard[card.id] = card;

    yield put(
      actionCreator(DashboardColumns.uploadColumnsRedux, {
        ...dashboardColumns,
        columns: newColumns,
        cards: newCard,
        structure: updateState(newColumns, newCard),
      }),
    );
    try {
      // yield wrapperRequestNeo4j(queryAddPlaylistToDashboard(card));
      yield call(requestCreateCard, {
        id: card.id,
        position: card.position,
        playlistId: card.wrapperId,
        columnId: card.parent,
      });
    } catch (err) {
      yield put(
        actionCreator(DashboardColumns.uploadColumnsRedux, dashboardColumns),
      );
      yield showErrorMessage(err, action);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

// function* addToColumn(action) {
//   try {
//     const { card, columnId, isMoveOutInboxColumn } = action.payload;
//     const dashboardColumns = yield select((state) => state.dashboardColumns);
//     const newColumns = { ...dashboardColumns.columns };
//     const newCard = { ...dashboardColumns.cards };
//     const updInBoxColumn = { ...dashboardColumns.inBoxColumn };
//     if (!newColumns[columnId].items) newColumns[columnId].items = {};
//     newColumns[columnId].items[card.id] = card;
//     newCard[card.id] = card;
//     if (isMoveOutInboxColumn) {
//       updInBoxColumn.items = updInBoxColumn.items
//         .filter(i => !((i.wrapperId === card.itemId) && (i.channel?.id === card.channel?.id)));
//     }
//     yield put(actionCreator(DashboardColumns.uploadColumnsRedux,
//       { ...dashboardColumns,
//         inBoxColumn: updInBoxColumn,
//         columns: newColumns,
//         cards: newCard,
//         structure: updateState(newColumns, newCard) },
//     ));
//     try {
//       yield  wrapperRequestNeo4j(queryAddPlaylistToDashboard(card));
//     } catch (err) {
//       yield put(actionCreator(DashboardColumns.uploadColumnsRedux, dashboardColumns));
//       yield showErrorMessage(err, action);
//     }
//   } catch (err) {
//     yield showErrorMessage(err, action);
//   }
// }
function* addToCloseColumn(action) {
  try {
    const { card } = action.payload;
    const dashboardColumns = yield select((state) => state.dashboardColumns);
    const newColumns = { ...dashboardColumns.columns };
    const newCard = { ...dashboardColumns.cards };
    const newItemsCloseColumn = [...dashboardColumns.closeColumn.items] || [];

    newItemsCloseColumn.push(card);
    newCard[card.id] = card;

    yield put(
      actionCreator(DashboardColumns.uploadColumnsRedux, {
        ...dashboardColumns,
        closeColumn: {
          ...dashboardColumns.closeColumn,
          items: newItemsCloseColumn.sort((i, b) => i.position - b.position),
        },
        structure: updateState(newColumns, newCard),
      }),
    );
    try {
      // yield wrapperRequestNeo4j(queryAddPlaylistToDashboard({ ...card, parent: dashboardColumns.closeColumn.id }));
      yield call(requestCreateCard, {
        id: card.id,
        position: card.position,
        playlistId: card.wrapperId,
        columnId: dashboardColumns.closeColumn.id,
      });
    } catch (err) {
      yield put(
        actionCreator(DashboardColumns.uploadColumnsRedux, dashboardColumns),
      );
      yield showErrorMessage(err, action);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* renameColumn(action) {
  try {
    const { columnId, newName } = action.payload;
    const dashboardColumns = yield select((state) => state.dashboardColumns);
    const newColumns = {
      ...dashboardColumns.columns,
      [columnId]: { ...dashboardColumns.columns[columnId] },
    };
    const newCard = { ...dashboardColumns.cards };
    newColumns[columnId].name = newName;
    yield put(
      actionCreator(DashboardColumns.uploadColumnsRedux, {
        ...dashboardColumns,
        columns: newColumns,
        cards: newCard,
        structure: updateState(newColumns, newCard),
      }),
    );
    try {
      yield call(requestUpdateColumns, {
        id: columnId,
        fieldsUpdateObg: { name: `"${sanitizeToSave(newName)}"` },
      });
      yield put(
        actionShowMessage({
          type: MessageType.SuccessfullyUpdatedBoardColumnHeader,
          message: i18n.t('successfullyUpdatedBoardColumnHeaderT'),
        }),
      );
    } catch (err) {
      yield put(
        actionCreator(DashboardColumns.uploadColumnsRedux, dashboardColumns),
      );
      yield showErrorMessage(err, action);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updateColorsOrTitle(action) {
  try {
    const { columnId, newColor, newBackgroundColor, newName } = action.payload;
    const dashboardColumns = yield select((state) => state.dashboardColumns);
    yield put(
      actionCreator(DashboardColumns.column.updateColorsOrTitleR, {
        columnId,
        newColor,
        newBackgroundColor,
        newName,
      }),
    );
    let editColumnId = columnId;
    let oldColor;
    let oldBGColor;
    let oldName;
    if (columnId === COLUMN_CUSTOM_TYPE.CLOSE) {
      editColumnId = dashboardColumns.closeColumn.id;
      oldColor = dashboardColumns.closeColumn.color;
      oldBGColor = dashboardColumns.closeColumn.backgroundColor;
      oldName = dashboardColumns.closeColumn.name;
    } else {
      oldColor = dashboardColumns.columns[columnId].color;
      oldBGColor = dashboardColumns.columns[columnId].backgroundColor;
      oldName = dashboardColumns.columns[columnId].name;
      const newColumns = {
        ...dashboardColumns.columns,
        [columnId]: { ...dashboardColumns.columns[columnId] },
      };
      if (newColor) newColumns[columnId].color = newColor;
      if (newBackgroundColor) newColumns[columnId].backgroundColor = newBackgroundColor;
      if (newName) newColumns[columnId].name = newName;
      yield put(
        actionCreator(DashboardColumns.uploadColumnsRedux, {
          ...dashboardColumns,
          columns: newColumns,
          cards: dashboardColumns.cards,
          structure: updateState(newColumns, dashboardColumns.cards),
        }),
      );
    }

    try {
      const fieldsUpdateObg = {};
      if (newColor) fieldsUpdateObg.color = `"${newColor}"`;
      if (newBackgroundColor) fieldsUpdateObg.backgroundColor = `"${newBackgroundColor}"`;
      if (newName) fieldsUpdateObg.name = `"${newName}"`;
      const updatePayload = {
        id: editColumnId,
        fieldsUpdateObg,
      };
      yield call(requestUpdateColumns, updatePayload);
      yield put(
        actionShowMessage({
          type: MessageType.SuccessfullyUpdatedBoardColumnHeader,
          message: i18n.t('successfullyUpdatedBoardColumnHeaderT'),
        }),
      );
    } catch (err) {
      yield put(
        actionCreator(DashboardColumns.column.updateColorsOrTitleR, {
          columnId,
          newColor: oldColor,
          newBackgroundColor: oldBGColor,
          newName: oldName,
        }),
      );
      yield showErrorMessage(err, action);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* toggleShowHide(action) {
  try {
    const { columnType, isHide } = action.payload;
    const dashboardColumns = yield select((state) => state.dashboardColumns);
    yield put(
      actionCreator(DashboardColumns.column.toggleShowHideRedux, {
        columnType,
        isHide,
      }),
    );
    let column;
    // TODO ALL TOGGLE HIDE
    if (columnType === COLUMN_CUSTOM_TYPE.CLOSE) {
      column = dashboardColumns.closeColumn;
    } else {
      column = dashboardColumns.columns[columnType];
    }

    try {
      yield call(requestUpdateColumns, {
        id: column.id,
        fieldsUpdateObg: { isHide },
      });
    } catch (err) {
      yield put(
        actionCreator(DashboardColumns.column.toggleShowHideRedux, {
          columnType,
          isHide: !isHide,
        }),
      );
      yield showErrorMessage(err, action);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* removeCard(action) {
  try {
    const { cardId, isMoveFromClose } = action.payload;
    const dashboardColumns = yield select((state) => state.dashboardColumns);
    const newColumns = { ...dashboardColumns.columns };
    const newCloseColumn = { ...dashboardColumns.closeColumn };
    const newCard = { ...dashboardColumns.cards };
    // if (isMoveToIn) {

    // }
    if (isMoveFromClose) {
      const moveOutInboxColumn = newCloseColumn.items.find(
        (i) => i.id === cardId,
      );
      newCloseColumn.items = newCloseColumn.items.filter(
        (i) => i.id !== cardId,
      );
      yield put(
        actionCreator(EditPlaylist.UpdateCardStateR, {
          ...moveOutInboxColumn,
          channelId: moveOutInboxColumn.channel?.id,
          cardId: moveOutInboxColumn.id,
          playlistId: moveOutInboxColumn.playlistId,
          addToColumn: 'in',
          removeToColumn: newCloseColumn.id,
          inColumn: {
            id: newCloseColumn.id,
            name: newCloseColumn.name,
            color: newCloseColumn.color,
          },
        }),
      );
    } else {
      if (!newColumns[newCard[cardId]?.parent]) return;

      yield put(
        actionCreator(EditPlaylist.UpdateCardStateR, {
          ...newCard[cardId],
          channelId: newCard[cardId].channel?.id,
          cardId: newCard[cardId].id,
          playlistId: newCard[cardId].playlistId,
          addToColumn: 'in',
          removeToColumn: newCard[cardId].parent,
        }),
      );
      delete newColumns[newCard[cardId].parent].items[cardId];
      delete newCard[cardId];
    }

    yield put(
      actionCreator(DashboardColumns.uploadColumnsRedux, {
        closeColumn: newCloseColumn,
        columns: newColumns,
        cards: newCard,
        structure: updateState(newColumns, newCard),
      }),
    );
    try {
      // yield wrapperRequestNeo4j(queryDeleteCard(cardId));
      yield call(requestDeleteCard, { id: cardId });
    } catch (err) {
      yield put(
        actionCreator(DashboardColumns.uploadColumnsRedux, dashboardColumns),
      );
      yield showErrorMessage(err, action);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updateCardsForPlaylist(action) {
  try {
    const { selectedColumn, playlistId, usedInChannels } = action.payload;
    if (!Object.values(selectedColumn).length) return;
    const { data } = yield requestGetSomePlaylistContent(['card', playlistId]);
    const playlist = data.Playlist[0];
    const wrapper = playlist?.editManager;
    const card = wrapper.card[0];
    if (card?.column?.id && card.column?.id === selectedColumn?.id) return;

    const { columns, closeColumn } = yield select(
      (state) => state.dashboardColumns,
    );

    const isOwner = !!wrapper.users.length;
    const isCanReadDraft = isOwner
      || (!!wrapper.canReadDraft?.length
        && isSharesPlaylistAvailableOnBoard(wrapper));
    const isCanReadPublish = !!wrapper.canReadPublish?.length && isPlaylistAvailableOnBoard(playlist);
    let title = 'Unavailable';
    let canOpenId = playlistId;
    const cropUrl = wrapper?.editPlaylist?.cropUrl;
    const user = {
      fullName: wrapper?.users?.[0]?.showUsername
        ? wrapper?.users?.[0]?.username : `${wrapper?.users?.[0]?.first_name} ${wrapper?.users?.[0]?.last_name}`,
      avatar: wrapper?.users?.[0]?.avatarUrlVerySmall,
      displayImage: wrapper?.users?.[0]?.displayImage === 'AvatarImage',
    };
    const editPlaylist = playlist;
    if (isCanReadPublish) {
      canOpenId = playlistId;
      title = playlist?.title;
    }
    if (isCanReadDraft) {
      canOpenId = editPlaylist?.id || playlistId;
      title = editPlaylist?.title;
    }

    if (!selectedColumn.customType && selectedColumn.id) {
      const maxPosition = Math.max(
        ...Object.values(columns[selectedColumn.id].items || {}).map(
          (item) => item?.position || 0,
        ),
        0,
      );
      if (wrapper.card.length) {
        yield all(
          wrapper.card.map((card) => {
            return removeCard({
              payload: {
                cardId: card.id,
                isMoveFromClose:
                  card.column?.customType === COLUMN_CUSTOM_TYPE.CLOSE,
              },
            });
          }),
        );
      }
      const isShared = !!playlist?.editManager?.usersToSharing?.length;
      const newCard = {
        isShared,
        isOwner:
          !!playlist.editManager?.users.length,
        isCoEdit:
          playlist.editManager?.isCoEdit,
        position: maxPosition + DEFAULT_POSITION_STEP,
        id: uuidv4(),
        parent: selectedColumn.id,
        name: sanitizeToLoad(title),

        itemId: wrapper.id,
        playlistId: canOpenId,
        wrapperId: wrapper.id,
        channel: Object.values(usedInChannels || {}),
        availableTo: playlist?.availableTo,
        availableFrom: playlist?.availableFrom,
        sharedAvailableFrom: wrapper.sharedAvailableTo,
        sharedAvailableTo: wrapper.sharedAvailableTo,
        cropUrl,
        user,
        channelValidId:
          !!wrapper.canReadPublish?.length && wrapper.canReadPublish[0].id,
        isCanReadPublish,
        isCanReadDraft,
      };
      yield addToColumn({
        payload: {
          card: newCard,
          columnId: selectedColumn.id,
          isMoveOutInboxColumn: true,
        },
      });
      yield put(
        actionCreator(EditPlaylist.UpdateCardStateR, {
          ...newCard,
          cardId: newCard.id,
          playlistId: newCard.playlistId,
          addToColumn: selectedColumn.id,
          removeToColumn: 'in',
          inColumn: {
            id: selectedColumn.id,
            name: selectedColumn.name,
            color: selectedColumn.color,
          },
        }),
      );
      // const existInColumn = {};
      // for (const card of wrapper.card) {
      //   if (card.column?.id !== selectedColumn.id) {
      //
      //   } else {
      //     existInColumn[card.inChannel.id] = true;
      //   }
      // }
      // for (const channelId in usedInChannels) {
      //   if (!existInColumn[channelId]) {
      //     maxPosition += DEFAULT_POSITION_STEP;
      //
      // }
    } else if (selectedColumn.customType === COLUMN_CUSTOM_TYPE.CLOSE) {
      const existInColumn = {};

      for (const card of wrapper.card) {
        if (card.column.id !== selectedColumn.id) {
          yield removeCard({
            payload: {
              cardId: card.id,
              isMoveFromClose:
                card.column?.customType === COLUMN_CUSTOM_TYPE.CLOSE,
            },
          });
        } else {
          existInColumn[card.inChannel?.id] = true;
        }
      }

      const alreadyExists = selectedColumn.items.filter(e => e.playlistId === canOpenId);
      if (alreadyExists.length > 0) return;

      const maxPosition = Math.max(
        ...closeColumn.items.map((item) => item?.position || 0),
        0,
      );

      const newCard = {
        position: maxPosition + DEFAULT_POSITION_STEP,
        id: uuidv4(),
        parent: selectedColumn.id,
        name: sanitizeToLoad(title),
        itemId: wrapper.id,
        channel: Object.values(usedInChannels || {}),
        wrapperId: wrapper.id,
        isOwner:
          !!playlist.editManager?.users.length,
        availableTo: playlist?.availableTo,
        availableFrom: playlist?.availableFrom,
        sharedAvailableFrom: wrapper.sharedAvailableTo,
        sharedAvailableTo: wrapper.sharedAvailableTo,
        channelValidId:
          !!wrapper.canReadPublish?.length && wrapper.canReadPublish[0].id,
        isCanReadPublish,
        isCanReadDraft,
        playlistId: canOpenId,
        user,
        cropUrl,
      };
      yield addToCloseColumn(
        {
          payload: {
            card: newCard,
            columnId: selectedColumn.id,
            isMoveOutInboxColumn: true,
          },
        }, // TODO
      );
      yield put(
        actionCreator(EditPlaylist.UpdateCardStateR, {
          isOwner: true,
          cardId: newCard.id,
          playlistId: newCard.playlistId,
          addToColumn: closeColumn.id,
          removeToColumn: 'in',
          inColumn: {
            id: closeColumn.id,
            name: closeColumn.name,
            color: closeColumn.color,
          },
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* addViewedPlaylistManager(action) {
  try {
    const { playlistWrapperId } = action.payload;
    const { id: userId } = yield select(getUser);
    if (!userId) {
      return;
    }
    yield call(requestUpdatePlaylistManagerSeen, {
      playlistManagerId: playlistWrapperId,
      userId,
    });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}


// function* updateStateColumnInbox(action) {
//   try {
//     const { id: userId } = yield select(getUser);
//
//     const { data: { User: [data] } } = yield wrapperRequestNeo4j(queryGetColumnInboxData(userId));
//     const existCardInOtherColumn = {};
//     const dashboardColumns = yield select((state) => state.dashboardColumns);
//
//     Object.values(dashboardColumns.cards || {}).forEach(i => {
//       existCardInOtherColumn[i.playlistId + i.channel?.id] = true;
//     });
//     dashboardColumns.closeColumn.items.forEach(i => {
//       existCardInOtherColumn[i.playlistId + i.channel?.id] = true;
//     });
//     const { calcItemsInbox, notSeenPlaylist } = calcCardStateInColumn(data.playlistsIN, existCardInOtherColumn);
//     const inBoxColumnItems = calcItemsInbox.filter(i => !existCardInOtherColumn[i.id]);
//     yield put(actionCreator(DashboardColumns.column.updateInboxColumnRedux, { inBoxColumnItems, notSeenPlaylist }));
//   } catch (err) {
//     yield showErrorMessage(err, action);
//   }
// }

export default function* dashboardSaga() {
  yield takeEvery(DashboardColumns.uploadColumns, uploadColumns);
  yield takeEvery(DashboardColumns.column.create, createColumns);
  yield takeEvery(
    DashboardColumns.column.updatePosition,
    updatePositionColumns,
  );
  yield takeEvery(DashboardColumns.column.remove, removeColumn);
  yield takeEvery(DashboardColumns.column.rename, renameColumn);
  yield takeEvery(DashboardColumns.column.updateColorsOrTitle, updateColorsOrTitle);
  yield takeEvery(DashboardColumns.column.toggleShowHide, toggleShowHide);

  yield takeEvery(DashboardColumns.card.moveToColumn, moveToColumn);
  yield takeEvery(DashboardColumns.card.moveToCLoseColumn, moveToCLoseColumn);
  // yield takeEvery(DashboardColumns.card.moveToINBoxColumn, moveToINBoxColumn);
  yield takeEvery(DashboardColumns.card.addToColumn, addToColumn);
  yield takeEvery(DashboardColumns.card.addToCloseColumn, addToCloseColumn);
  yield takeEvery(DashboardColumns.card.remove, removeCard);

  yield takeEvery(
    DashboardColumns.card.updateCardsForPlaylist,
    updateCardsForPlaylist,
  );
  yield takeEvery(
    DashboardColumns.card.addViewedPlaylistManagerSR,
    addViewedPlaylistManager,
  );
  // yield takeEvery(DashboardColumns.column.updateInboxColumnSaga, updateStateColumnInbox);
}
