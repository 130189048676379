import React from 'react';
import { Avatar } from '../../MainHeader/User';
import AvatarPlaceholder from '../../Avatar';
import { AvatarContainer, Info, Username } from './styled';

export const SignatureCardView = ({ item }) => {
  const user = item.owner || {};
  const firstName = user.first_name && user.first_name[0] ? user.first_name[0] : '';
  const lastName = user.last_name && user.last_name[0] ? user.last_name[0] : '';
  const fNameExtended = user.first_name || '';
  const lNameExtended = user.last_name || '';
  const username = fNameExtended || lNameExtended
    ? `${fNameExtended} ${lNameExtended}`
    : "User doesn't exist";
  return (
    <Info>
      <div>
        <Username>
          {user?.showUsername && user?.username
            ? `${user?.username}`
            : username}
        </Username>
      </div>
    </Info>
  );
};
