

export const openDatabase = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('myDatabase', 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore('tokens', { keyPath: 'id' });
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const addTokenToIndexedDB = async (token, BASEURL) => {
  const db = await openDatabase();
  const transaction = db.transaction('tokens', 'readwrite');
  const objectStore = transaction.objectStore('tokens');
  const data = { id: 1, token, BASEURL };
  objectStore.put(data);
};
