import React from 'react';
import classNames from 'classnames/bind';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import moduleStyles from '../../pages/Library/LibraryHeader.module.scss';
import DynamicCollectionCreator from '../DynamicCollectionCreator/DynamicCollectionCreator';
import { ReactComponent as CloseSvg } from '../../images/icons/close_28.svg';

const SmartViewMenuInLibraryShell = styled.div`
  //width: 120px;
  margin-left: 30px;
  display: flex;
  align-items: center;
`;

const FiltersAndSearchWrapper = styled.div`
  border: 1px solid #e8ebfa;
  border-radius: 8px;
  width: 695px;
`;

// const defaultSortType = Object.keys(sortedFunctions)[2];
const CloseWrapper = styled.div`
  margin-left: 20px;
  cursor: pointer;
`;
const NewSmartViewOrEdit = (props) => {
  //
  // const [libFilterSearch, setLibFilterSearch] = useState('');
  // const [libFilter, setLibFilter] = useState({});
  //
  // useEffect(() => {
  //   const params = { libFilter, libFilterSearch };
  //   setDynamicCollectionNewParams(params);
  // }, [libFilterSearch, libFilter]);
  const history = useHistory();

  const closeHandler = () => history.push('/content/library/all');

  return (
    <SmartViewMenuInLibraryShell data-cy="SmartViewMenuInLibraryShell">
      <FiltersAndSearchWrapper>
        <DynamicCollectionCreator
          isDynamicCollection
          isLibrary
          {...props}
          filter={props.libFilter || {}}
          setFilter={props.setLibFilter}
        />
      </FiltersAndSearchWrapper>

      {/* <DynamicCollectionCreator */}
      {/*  isDynamicCollection */}
      {/*  isEdit={props.isEdit} */}
      {/*  filter={props.filter} */}
      {/*  libFilterSearch={props.libFilterSearch} */}
      {/*  clearAllFilters={props.clearAllFilters} */}
      {/*  setFilter={props.setFilter} */}
      {/*  setLibFilterSearch={props.setLibFilterSearch} */}
      {/* /> */}
      <CloseWrapper onClick={closeHandler}>
        <CloseSvg />
      </CloseWrapper>
    </SmartViewMenuInLibraryShell>
  );
};

export default NewSmartViewOrEdit;
