import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { COMMAND_PRIORITY_HIGH, REDO_COMMAND, UNDO_COMMAND } from 'lexical';
import { actionWriteCombination } from '../../../redux/shortcuts/action';

export const EditorUndoRedoPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const dispatch = useDispatch();

  useEffect(() => {
    editor.registerCommand(
      REDO_COMMAND,
      () => {
        dispatch(actionWriteCombination('mod+shift+z'));
        return false;
      },
      COMMAND_PRIORITY_HIGH,
    );
    editor.registerCommand(
      UNDO_COMMAND,
      () => {
        dispatch(actionWriteCombination('mod+z'));
        return false;
      },
      COMMAND_PRIORITY_HIGH,
    );
  }, []);

  return null;
};
