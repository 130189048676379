import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../Inbox/index.module.scss';
import { ReactComponent as Copy } from '../../images/icons/icon_20/copy.svg';
import { ReactComponent as LinkToWeb } from '../../images/icons/icon_12/link.svg';
import { copyInBuffer } from '../../utils/helpers';
import { SHARED_PLAYLIST_URL } from '../../utils/constants';


const CopyButton = ({
  id,
  isPage,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const copyRef = useRef();
  const copyHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    copyRef.current.select();
    copyInBuffer(copyRef, dispatch);
  };
  const shareLink = isPage
    ? `${document.location.origin}/sharedwebpage/${id}`
    : `${document.location.origin}/${SHARED_PLAYLIST_URL}/${id}`;

  return (

    <div className={styles.wrapper_copy_button}>
      <LinkToWeb />
      <span>{t('projectLinkT')} </span>
      <input
        readOnly
        ref={copyRef}
        value={shareLink}
        className={styles.hiddenInput}
      />
      <button
        data-cy="copy-link-btn"
        onClick={copyHandler}
        className={styles.copy_button}
      >
        <Copy />
        <div>{t('copyUpT')}</div>
      </button>
    </div>

  );
};

export default CopyButton;
