import React, { useEffect, useState } from 'react';
import {
  BlockTypes,
  DATA_PARENTS,
  LibraryComponentTypes,
  UiComponentTypes,
} from '../../utils/constants';
import styles from '../LibraryMiniPage/index.module.css';
import { ReactComponent as EmbedSvg } from '../../images/icons/embed option_16.svg';
import { ReactComponent as LinkSvg } from '../../images/icons/link option_16.svg';
import PdfAsPreview from '../MomentBlock/CustomBlocks/pdfBlock/PdfAsPreview';
import { ReactComponent as WordBigSvg } from '../../images/icons/elements_word_no_preview_43-52.svg';
import { ReactComponent as BrokenImageSvg } from '../../images/icons/broken_img_32.svg';
import { ReactComponent as ArchiveSvg } from '../../images/icons/mini_page_types_icons/archive_20_24_white.svg';
import { ReactComponent as UnknownFileSvg } from '../../images/icons/mini_page_types_icons/file_20_24_white.svg';
import { ReactComponent as VideoFileSvg } from '../../images/icons/mini_page_types_icons/video_20_24_white.svg';
import { ReactComponent as AudioFileSvg } from '../../images/2023/svg/fileTypes/audio_44_50.svg';
import { SvgWrapper } from './styled';

const FallbackImage = (props) => {
  const [error, setError] = useState(false);
  const [secondChanceError, setSecondChanceError] = useState(false);
  const [secondChanceUrl, setSecondChanceUrl] = useState('');
  const isEmbed = props.type === LibraryComponentTypes.embed_component;
  const hasPdfPreview = !!props.item?.pdfPreviewUrl;
  useEffect(() => {
    // update image url when download is finished (for link components)
    setError(false);
  }, [props.src]);
  const onError = (e) => {
    if (!secondChanceUrl && props?.item?.urlFile) {
      setSecondChanceUrl(props.item.urlFile);
    }

    e.target.onError = null;
    setError(true);
  };
  const onSecondError = () => {
    setSecondChanceError(true);
  };

  const drawDefaultIcon = () => {
    if (UiComponentTypes.link[props.type]) {
      return props.type.includes('embed') ? <EmbedSvg /> : <LinkSvg />;
    }
    if (UiComponentTypes.image[props.type]) {
      return <BrokenImageSvg />;
    }
    if (props?.item?.urlFile) {
      return <UnknownFileSvg />;
    }
  };

  const imageStileWrapper = () => {
    if (props.isListViewLibrary) return styles.image_container_ListViewLibrary;
    if (props.isListView) return styles.image_container_ListView;
    if (props.isListViewPreview) return styles.image_container_ListViewPreview;
    if (props.isModal && props.forAddImageToPlaylist) return styles.image_container_170;
    if (props.isModal) return styles.image_container_140;
    if (props.isDescription && !props.isExpanded) return styles.image_container_450;
    if (props.isDescription && props.isExpanded) return styles.image_container_parent_width;
    if (
      props.isPlaylistBuilder
      && props.type === BlockTypes.pdf
      && !hasPdfPreview
    ) return styles.image_container_161_pdf;
    if (props.isPlaylistBuilder && !hasPdfPreview) return styles.image_container_161;
    if (props.isBuilderCanvas) return styles.image_container_canvas;
    if (props.isBuilderCanvasImage) return styles.image_container_canvas_image;
    if (props.isHoverPreview) return styles.image_container_isHoverPreview;
    if (
      (props.isPagePdf || props.isPdfPreview)
      && props.forComponentDescription
    ) return styles.image_container_components_pdfAsPage_for_description;
    if (props.isPagePdf || props.isPdfPreview) return styles.image_container_components_pdfAsPage;
    return styles.image_container_components;
  };

  if (isEmbed) {
    return (
      <div
        className={`${styles.embed_component}
        ${props.isDescription ? styles.embed_meta : ''}
      `}
      >
        <EmbedSvg />
      </div>
    );
  }
  if (!error) {
    return (
      <div>
        {!props.isResizeImage && (
          <div
            className={`${imageStileWrapper()} ${props.isSelected ? styles.become_tiny : ''}`}
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          >
            {(props.type === BlockTypes.pdf || props.isPdfPreview) && (
              <PdfAsPreview
                previewRef={null}
                state={{ data: { urlFile: props.src } }}
                height={props.item?.height}
                item={props.item}
                setHeight={() => {}}
                relationId="none"
                save={() => {}}
                width={props.item?.width}
                isReadOnly
                isChannelMiniPage={props.isChannelMiniPage}
                isListView={props.isListView}
                isListViewPreview={props.isListViewPreview}
                loader={props.loader}
                isLibDescription={props.isLibDescription}
                isWhiteGallery={props.isWhiteGallery}
              />
            )}
            {props.type !== BlockTypes.pdf
              && !props.isPdfPreview
              && !isEmbed && (
                <img
                  className={`
                  ${!props.isDescription ? styles.prev_image : ''}
                  ${
                    props.isDescription && !props.isExpanded
                      ? styles.image_450
                      : ''
                  }
                  ${
                    props.isDescription && props.isExpanded
                      ? styles.image_centered
                      : ''
                  }
                  ${props.isBuilderCanvas ? styles.prev_image_canvas : ''}
                  ${
                    props.isBuilderCanvasImage
                      ? styles.prev_image_canvas_element
                      : ''
                  }
                  ${props.isListViewLibrary ? styles.image_ListViewLibrary : ''}
                  ${props.isHoverPreview ? styles.isHoverPreview : ''}
                  ${props.isImgPreview ? styles.isImgPreview : ''}
                  ${props.isLibDescription ? styles.isLibDescription : ''}
                `}
                  src={secondChanceUrl || props.src}
                  onError={onError}
                  onLoad={() => setError(false)}
                  alt={props.alt}
                />
            )}
          </div>
        )}
      </div>
    );
  }
  if (
    !(
      UiComponentTypes.page[props.type]
      || UiComponentTypes.image[props.type]
      || UiComponentTypes.media[props.type]
      || UiComponentTypes.audio[props.type]
      || UiComponentTypes.video[props.type]
      || UiComponentTypes.pdf[props.type]
      || UiComponentTypes.text[props.type]
      || UiComponentTypes.xls[props.type]
      || UiComponentTypes.ms[props.type]
      || UiComponentTypes.component[props.type]
      || UiComponentTypes.presentation[props.type]
      || UiComponentTypes.link[props.type]
      || UiComponentTypes.archive[props.type]
      || UiComponentTypes.youtube[props.type]
      || UiComponentTypes.google_embed_component[props.type]
      || UiComponentTypes.dropbox_embed_component[props.type]
    )
  ) {
    return (
      <div
        data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
        className={`${styles.broken_image_container}
          ${
            props.isPlaylistBuilder
              ? styles.broken_image_container_sideBar_playlist
              : ''
          }
          ${
            props.isDescription
              ? styles.broken_image_container_metaData_sideBar
              : ''
          }
          ${
            props.isListViewLibrary
              ? styles.broken_image_container_listView
              : ''
          }
          ${
            props.isHoverPreview
              ? styles.broken_image_container_isHoverPreview
              : ''
          }
          ${
            props.isPagePdf || props.isPdfPreview
              ? styles.broken_image_container_isPagePdf
              : ''
          }
        `}
      >
        <UnknownFileSvg />
        <div className={styles.icon_background} />
      </div>
    );
  }
  if (UiComponentTypes.archive[props.type]) {
    return (
      <div
        data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
        className={`${styles.broken_image_container}
          ${
            props.isPlaylistBuilder
              ? styles.broken_image_container_sideBar_playlist
              : ''
          }
          ${
            props.isDescription
              ? styles.broken_image_container_metaData_sideBar
              : ''
          }
          ${
            props.isListViewLibrary
              ? styles.broken_image_container_listView
              : ''
          }
          ${
            props.isHoverPreview
              ? styles.broken_image_container_isHoverPreview
              : ''
          }
          ${
            props.isPagePdf || props.isPdfPreview
              ? styles.broken_image_container_isPagePdf
              : ''
          }
        `}
      >
        <ArchiveSvg />
        <div className={styles.icon_background} />
      </div>
    );
  }
  if (error && UiComponentTypes.ms[props.type]) {
    return (
      <div
        data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
        className={`${styles.broken_image_container}
          ${
            props.isPlaylistBuilder
              ? styles.broken_image_container_sideBar_playlist
              : ''
          }
          ${
            props.isDescription
              ? styles.broken_image_container_metaData_sideBar
              : ''
          }
          ${
            props.isListViewLibrary
              ? styles.broken_image_container_listView
              : ''
          }
          ${
            props.isHoverPreview
              ? styles.broken_image_container_isHoverPreview
              : ''
          }
        `}
      >
        <WordBigSvg />
      </div>
    );
  }

  if (UiComponentTypes.video[props.type]) {
    return (
      <div
        data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
        className={`${styles.broken_image_container}
          ${
            props.isPlaylistBuilder
              ? styles.broken_image_container_sideBar_playlist
              : ''
          }
          ${
            props.isDescription
              ? styles.broken_image_container_metaData_sideBar
              : ''
          }
          ${
            props.isListViewLibrary
              ? styles.broken_image_container_listView
              : ''
          }
          ${
            props.isHoverPreview
              ? styles.broken_image_container_isHoverPreview
              : ''
          }
          ${
            props.isPagePdf || props.isPdfPreview
              ? styles.broken_image_container_isPagePdf
              : ''
          }
        `}
      >
        <VideoFileSvg />
      </div>
    );
  }
  if (UiComponentTypes.audio[props.type]) {
    return (
      <div
        data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
        className={`${styles.broken_image_container}
          ${
            props.isPlaylistBuilder
              ? styles.broken_image_container_sideBar_playlist
              : ''
          }
          ${
            props.isDescription
              ? styles.broken_image_container_metaData_sideBar
              : ''
          }
          ${
            props.isListViewLibrary
              ? styles.broken_image_container_listView
              : ''
          }
          ${
            props.isHoverPreview
              ? styles.broken_image_container_isHoverPreview
              : ''
          }
          ${
            props.isPagePdf || props.isPdfPreview
              ? styles.broken_image_container_isPagePdf
              : ''
          }
        `}
      >
        <AudioFileSvg />
        {/* <div className={styles.icon_background} /> */}
      </div>
    );
  }
  if (error && !secondChanceError) {
    return (
      <>
        {!props.isResizeImage && (
          <div
            className={`${imageStileWrapper()} ${props.isSelected ? styles.become_tiny : ''}`}
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          >
            {(props.type === BlockTypes.pdf || props.isPdfPreview) && (
              <PdfAsPreview
                previewRef={null}
                state={{ data: { urlFile: props.src } }}
                height={props.item?.height}
                setHeight={() => {}}
                relationId="none"
                save={() => {}}
                width={props.item?.width}
                isReadOnly
                isChannelMiniPage={props.isChannelMiniPage}
                isListView={props.isListView}
                isListViewPreview={props.isListViewPreview}
                loader={props.loader}
                isWhiteGallery={props.isWhiteGallery}
              />
            )}
            {props.type !== BlockTypes.pdf
              && !props.isPdfPreview
              && !isEmbed && (
                <img
                  className={`
                  ${!props.isDescription ? styles.prev_image : ''}
                  ${
                    props.isDescription && !props.isExpanded
                      ? styles.image_450
                      : ''
                  }
                  ${
                    props.isDescription && props.isExpanded
                      ? styles.image_centered
                      : ''
                  }
                  ${props.isBuilderCanvas ? styles.prev_image_canvas : ''}
                  ${
                    props.isBuilderCanvasImage
                      ? styles.prev_image_canvas_element
                      : ''
                  }
                  ${props.isListViewLibrary ? styles.image_ListViewLibrary : ''}
                  ${props.isHoverPreview ? styles.isHoverPreview : ''}
                `}
                  src={secondChanceUrl}
                  onError={onSecondError}
                  onLoad={() => setError(false)}
                  alt={props.alt}
                />
            )}
          </div>
        )}
      </>
    );
  }
  if (error) {
    if (props.isListView || props.isListViewPreview) {
      return (
        <SvgWrapper isListView={props.isListView}>
          <BrokenImageSvg />
        </SvgWrapper>
      );
    }

    return (
      <div
        data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
        className={`${styles.broken_image_container}
          ${
            props.isPlaylistBuilder
              ? styles.broken_image_container_sideBar_playlist
              : ''
          }
          ${
            props.isDescription
              ? styles.broken_image_container_metaData_sideBar
              : ''
          }
          ${
            props.isListViewLibrary
              ? styles.broken_image_container_listView
              : ''
          }
          ${
            props.isHoverPreview
              ? styles.broken_image_container_isHoverPreview
              : ''
          }
          ${
            props.isPagePdf || props.isPdfPreview
              ? styles.broken_image_container_isPagePdf
              : ''
          }
        `}
      >
        {drawDefaultIcon()}
      </div>
    );
  }
  return null;
};

export default FallbackImage;
