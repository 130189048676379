import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloudSvg } from '../../images/icons/cloud_13x9.svg';
import { actionLogout, actionOpenModal } from '../../redux/user/action';
import AvatarPlaceholder from '../Avatar';
import { Avatar } from '../MainHeader/User';
import Button2023 from '../Buttons/Button2023';
import { openModalType } from '../../utils/constants';

const Menu = styled.div`
  position: absolute;
  width: 270px;
  right: 34px;
  top: 60px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 108;
  font-family: RobotoFlex, sans-serif;
`;

const Info = styled.div`
  display: flex;
  padding: 14px 16px;
`;

const Separator = styled.div`
  border-bottom: 1px solid rgba(29, 29, 29, 0.08);
  margin: 0 16px;
`;

const Username = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #111111;
  line-height: 23px;
`;

const Email = styled.div`
  font-style: normal;
  font-size: 12px;
  color: #939393;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  line-height: 16px;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${({ isSignout }) => (isSignout ? '#D32F2F' : '#242633')};
`;
const Item = styled.div`
  display: flex;
  padding: 0 16px;
  cursor: pointer;
  height: 30px;
  align-items: center;
  &:hover {
    background: #ffeebf;
  }

  ${({ disabled }) => (disabled
    ? `
    cursor: no-drop;
    
    ${Text} {
      color: lightgrey;
    }
    
    &:hover {
      background: initial;
    }
    & svg path {
      stroke: lightgrey;
    }
  `
    : '')}
`;

const Section = styled.div`
  padding: ${({ isSettings }) => (isSettings ? ' 7px 0' : '10px 0')};
  ${({ isSignout }) => (isSignout ? 'padding: 4px 0 13px' : '')};
  font-size: 14px;
`;

const StorageSection = styled.div`
  height: 100px;
  padding: 0 16px;
`;

const StorageLine = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  & svg {
    margin-right: 8px;
  }
`;
const ProgressBar = styled.div`
  width: 100%;
  height: 1px;
  background: #e8ebfa;
  margin: 6px 0;
`;
const InfillLine = styled.div`
  background: #FFC72C;
  width: 45%;
  height: 1px;
  transition: width ease-in-out 0.3s;
`;

const ProgressBarHint = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #939393;
`;

const ButtonContainer = styled.div`
  margin: 13px 0;
`;

const AvatarContainer = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const UserMenu = ({ refUserMenu, setIsComponentVisible }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();

  const onLogoutClick = () => {
    dispatch(
      actionOpenModal(openModalType.ConfirmModal, {
        subject: t('leavingT'),
        description: t('signOutQuestionT'),
        confirm: () => {
          dispatch(actionLogout());
          history.push('/logout');
        },
        cancelText: t('cancelUpT'),
        okText: t('signOutUpT'),
        isSignOut: true,
      }),
    );
    setIsComponentVisible(false);
  };

  const onHelpClick = () => {
    setIsComponentVisible(false);
    history.push('/help');
  };

  const onSettingsClick = () => {
    dispatch(actionOpenModal(openModalType.ApplicationSettings));
    setIsComponentVisible(false);
  };

  return (
    <Menu data-cy="user-menu" ref={refUserMenu}>
      <Info>
        <AvatarContainer>
          {!!user?.avatarUrlVerySmall
            && user?.displayImage === 'AvatarImage' && (
              <Avatar width={40} src={user?.avatarUrlVerySmall} alt="avatar" />
          )}
          {(!user?.avatarUrlVerySmall
            || user?.displayImage === 'GeneratedAvatar') && (
            <AvatarPlaceholder
              width={40}
              name={user?.first_name}
              abbreviation={`${user?.first_name[0]}${user?.last_name[0]}`}
            />
          )}
        </AvatarContainer>

        <div>
          <Username>
            {user?.showUsername && user?.username
              ? `${user?.username}`
              : `${user?.first_name} ${user?.last_name}`}
          </Username>
          <Email>{user?.email}</Email>
        </div>
      </Info>

      <Separator />

      <Section>
        <StorageSection>
          <StorageLine>
            <CloudSvg />
            <Text>{t('storageT')}</Text>
          </StorageLine>

          <ProgressBar>
            <InfillLine />
          </ProgressBar>

          <ProgressBarHint>{t('progressBarT')}</ProgressBarHint>

          <ButtonContainer>
            <Button2023
              variant="secondaryWhiteAuto"
              height={32}
              text={t('upgradePlanT')}
              noMargins
            />
          </ButtonContainer>
        </StorageSection>
      </Section>

      <Separator />

      <Section isSettings>
        <Item onClick={onSettingsClick}>
          <Text>{t('settingsT')}</Text>
        </Item>

        <Item onClick={onHelpClick}>
          <Text>{t('helpT')}</Text>
        </Item>
      </Section>

      <Separator />

      <Section isSignout>
        <Item onClick={onLogoutClick}>
          <Text data-cy="logout-btn" isSignout>{t('signOutT')}</Text>
        </Item>
      </Section>
    </Menu>
  );
};

export default UserMenu;
