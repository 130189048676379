import { Settings } from './types';

export const actionChangeOneSortKeyInRedux = (key, value) => ({
  type: Settings.Sort.changeOneSortKeyInRedux,
  payload: { key, value },
});

export const actionSortSettingsFromDB = (history) => ({
  type: Settings.Sort.getSortFromDB,
  payload: history,
});

export const actionChangeSortRedux = (sortOptions) => ({
  type: Settings.Sort.changeSortRedux,
  payload: sortOptions,
});

export const actionChangeSortSagas = (value) => ({
  type: Settings.Sort.changeSortSagas,
  payload: { value },
});
