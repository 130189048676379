import {
  CLOSE_COPY_MESSAGE,
  CLOSE_ERROR,
  CLOSE_TRASH_MESSAGE,
  IS_DOWNLOAD_USER,
  SAVE_USER,
  ServiceUser,
  SHOW_COPY_MESSAGE,
  SHOW_TRASH_MESSAGE,
} from './types';
import { Tags } from '../tags/types';
import { MiniPages } from '../miniPages/types';
import { MiniPlaylists } from '../miniPlaylists/types';
import { Library, LibraryComponents } from '../library/types';
import { Channels } from '../channels/types';
import SupportAction from '../support/types';
import { DynamicCollection } from '../dynamicCollection/types';
import { Settings } from '../settings/types';
import { PLAYER_SETTINGS } from '../../utils/constants';

const defaultState = {
  id: '',
  isDownload: true,
  isHasErrorServer: false,
  copyMessage: false,
  isShowOnlyNotNestedPages: false,
  message: [],
  selectorType: 'all',
  isUnloadingBlocked: false,
  lazyLoading: false,
  isAdmin: false,
  tags: {},
  linkedAccounts: [],
  playerSettings: {
    contentWidthMode: PLAYER_SETTINGS.widthMode.fullheight,
    soundVolumePercent: PLAYER_SETTINGS.soundVolumePercent,
    zoomPercentage: PLAYER_SETTINGS.zoomPercentage,
    isFullScreenMode: PLAYER_SETTINGS.isFullScreenMode,
    isCaptionsAndApproveButtonsHidden:
      PLAYER_SETTINGS.isCaptionsAndApproveButtonsHidden,
    isCaptionsHidden: PLAYER_SETTINGS.isCaptionsHidden,
    isApproveButtonsHidden: PLAYER_SETTINGS.isApproveButtonsHidden,
  },
};

export const userReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SAVE_USER: {
      return { ...state, ...payload };
    }
    case ServiceUser.Player.SetContentWidthMode: {
      return {
        ...state,
        playerSettings: {
          ...state.playerSettings,
          contentWidthMode: payload.value,
        },
      };
    }
    case ServiceUser.Player.ChangeZoom: {
      return {
        ...state,
        playerSettings: {
          ...state.playerSettings,
          zoomPercentage: payload.value,
          contentWidthMode: PLAYER_SETTINGS.widthMode.default,
        },
      };
    }
    case ServiceUser.Player.ChangeZoomAuto: {
      return {
        ...state,
        playerSettings: {
          ...state.playerSettings,
          zoomPercentage: payload.value,
        },
      };
    }
    case ServiceUser.Player.SoundVolume: {
      return {
        ...state,
        playerSettings: {
          ...state.playerSettings,
          soundVolumePercent: payload.value,
        },
      };
    }
    case ServiceUser.Player.ChangeFullscreen: {
      return {
        ...state,
        playerSettings: {
          ...state.playerSettings,
          isFullScreenMode: payload.value,
        },
      };
    }
    case ServiceUser.Player.IsCaptionsAndApproveButtonsHidden: {
      return {
        ...state,
        playerSettings: {
          ...state.playerSettings,
          isCaptionsAndApproveButtonsHidden: payload.value,
        },
      };
    }
    case ServiceUser.Player.isCaptionsHidden: {
      return {
        ...state,
        playerSettings: {
          ...state.playerSettings,
          isCaptionsHidden: payload.value,
        },
      };
    }
    case ServiceUser.Player.isApproveButtonsHidden: {
      return {
        ...state,
        playerSettings: {
          ...state.playerSettings,
          isApproveButtonsHidden: payload.value,
        },
      };
    }
    case SupportAction.InitialDataFetch: {
      return { ...state, ...payload.user };
    }
    case ServiceUser.DeleteLinkedAccount: {
      return {
        ...state,
        linkedAccounts: [
          ...state.linkedAccounts.filter(
            (account) => account.id !== payload.id,
          ),
        ],
      };
    }
    case IS_DOWNLOAD_USER: {
      const newState = { ...state };
      if (payload) newState.hasModification = false;
      return { ...newState, isDownload: payload };
    }
    case ServiceUser.SetResponseError: {
      const newState = {
        ...state,
        isHasErrorServer: true,
        serverErrorMessage: payload,
        requestSent: false,
      };
      if (!payload) newState.isHasServerError = false;
      return newState;
    }
    case ServiceUser.Request: {
      return { ...state, requestSent: true };
    }
    case CLOSE_ERROR: {
      return { ...state, isHasErrorServer: false };
    }
    case SHOW_TRASH_MESSAGE: {
      return { ...state, trashMessage: payload.text };
    }
    case CLOSE_TRASH_MESSAGE: {
      return { ...state, trashMessage: '' };
    }
    case SHOW_COPY_MESSAGE: {
      return { ...state, copyMessage: true };
    }
    case CLOSE_COPY_MESSAGE: {
      return { ...state, copyMessage: false };
    }
    case ServiceUser.switchFolder: {
      return { ...state, ...payload };
    }
    case Settings.ChangePaymentPlan: {
      const { newPlan } = payload;
      return { ...state, currentPlan: newPlan };
    }
    case Settings.AttachPaymentMethod: {
      const { methodId, paymentMethod } = payload;
      const { paymentMethods } = state;
      return { ...state, activePaymentMethod: methodId, paymentMethods: [...paymentMethods, paymentMethod] };
    }
    case Settings.ChangePaymentMethod: {
      const { methodId } = payload;
      return { ...state, activePaymentMethod: methodId };
    }
    case ServiceUser.BlockUnloading: {
      return { ...state, isUnloadingBlocked: true };
    }
    case ServiceUser.UnblockUnloading: {
      if (state.isUnloadingBlocked) {
        return { ...state, isUnloadingBlocked: false };
      }
      return state;
    }
    case ServiceUser.OpenModal: {
      return {
        ...state,
        openModal: payload.modalName,
        dataPayload: payload.data,
      };
    }
    case ServiceUser.OpenAIModal: {
      return {
        ...state,
        openAIModal: payload.modalName,
        dataPayloadAI: payload.data,
      };
    }
    case ServiceUser.OpenModalOverlayModal: {
      return {
        ...state,
        openModalOverlayModal: payload.modalName,
        overlayDataPayload: payload.data,
      };
    }
    // case Channels.AddChannelR: {
    //  return { ...state, folderId: payload.channelId, selectorType: 'folder' };
    // }
    case ServiceUser.CloseModal: {
      return { ...state, openModal: '', dataPayload: null };
    }
    case ServiceUser.CloseAIModal: {
      return { ...state, openAIModal: '', dataPayloadAI: null };
    }
    case ServiceUser.CloseModalOverlayModal: {
      return { ...state, openModalOverlayModal: '', overlayDataPayload: null };
    }
    case Channels.SetCurrentChannel: {
      return { ...state, folderId: payload.id, selectorType: 'folder' };
    }
    case Tags.DeleteTagFromSystem: {
      const { tagId } = payload;
      const newState = { ...state, tags: { ...state.tags } };
      if (tagId === state.folderId) {
        newState.selectorType = 'all';
        newState.folderId = '';
      }

      delete newState.tags[tagId];
      return newState;
    }
    case Tags.AddTagEmptyR: {
      const { tag } = payload;
      return { ...state, tags: { ...state.tags, [tag.id]: tag } };
    }
    case Tags.updateTagCounterR: {
      const { updateTagsData } = payload;
      const newTagState = {};
      updateTagsData.forEach((tagData) => {
        newTagState[tagData.id] = {
          ...state.tags[tagData.id],
          ...tagData,
          itemsCounter: tagData.libraryComponentsCounter,
        };
      });
      return { ...state, tags: { ...state.tags, ...newTagState } };
    }

    case Tags.ChangeTagColor: {
      const { id, newColor, newBackgroundColor } = payload;
      const newTag = {};
      newTag[id] = { ...state.tags[id] };
      if (newColor) newTag[id].color = newColor;
      if (newBackgroundColor) newTag[id].backgroundColor = newBackgroundColor;
      return { ...state, tags: { ...state.tags, ...newTag } };
    }
    case Tags.ChangeTagTitle: {
      const { id, newTitle } = payload;
      const newTag = {};
      newTag[id] = { ...state.tags[id], title: newTitle };
      return { ...state, tags: { ...state.tags, ...newTag } };
    }
    case Tags.CreateTag: {
      const { tag } = payload;
      return { ...state, tags: { ...state.tags, [tag.id]: { ...tag } } };
    }
    case Tags.UpdateTagRedux: {
      const tag = payload;
      return {
        ...state,
        tags: { ...state.tags, [tag.id]: { ...state.tags[tag.id], ...tag } },
      };
    }
    case Tags._RefreshUserTags: {
      const { tags } = payload;
      return {
        ...state,
        tags: tags.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {}),
      };
    }
    case ServiceUser.LazyLoading: {
      const { isActive } = payload;
      if (state.isActive !== isActive) {
        return { ...state, lazyLoading: isActive };
      }
      return state;
    }
    case MiniPages.getMiniPages: {
      if (!state.lazyLoading) {
        return {
          ...state,
          lazyLoading: true,
        };
      }
      return state;
    }
    case LibraryComponents.GetComponents: {
      if (!state.lazyLoading) {
        return {
          ...state,
          lazyLoading: true,
        };
      }
      return state;
    }

    // case MiniPages.getMiniPagesClear: {
    //   if (!state.lazyLoading) {
    //     return {
    //       ...state,
    //       lazyLoading: true,
    //     };
    //   } return state;
    // }
    case MiniPlaylists.getMiniPlaylists: {
      if (!state.lazyLoading) {
        return {
          ...state,
          lazyLoading: true,
        };
      }
      return state;
    }
    case MiniPlaylists.getMiniPlaylistsClear: {
      if (!state.lazyLoading) {
        return {
          ...state,
          lazyLoading: true,
        };
      }
      return state;
    }
    case ServiceUser.Logout: {
      return { ...defaultState };
    }
    case DynamicCollection.deleteDC: {
      if (payload.dynamicCollectionId === state.folderId) {
        return { ...state, selectorType: 'all', folderId: '' };
      }
      return state;
    }
    case ServiceUser.SetUpIsAdmin: {
      return { ...state, isAdmin: payload.state };
    }
    case Settings.Profile.UpdateAvatarInput: {
      const {
        avatarUrlSmall,
        avatarUrlVerySmall,
        displayImage = 'GeneratedAvatar',
      } = payload;
      return { ...state, avatarUrlSmall, avatarUrlVerySmall, displayImage };
    }
    case Settings.Profile.DeleteAvatar: {
      return { ...state, avatarUrlSmall: null, avatarUrlVerySmall: null };
    }
    case ServiceUser.goToLink: {
      return { ...state, ...payload };
    }
    case ServiceUser.UpdateGoogleAccessToken: {
      return { ...state, googleAccessToken: payload.access_token };
    }
    case Library.AddLinkedAccountR: {
      const { linkedAccount } = payload;
      const newLinkedAccounts = [...state.linkedAccounts, linkedAccount];
      return { ...state, linkedAccounts: newLinkedAccounts };
    }
    case Settings.Profile.UpdateDisplayImageR: {
      const { newValue } = payload;
      return { ...state, displayImage: newValue };
    }
    case Settings.Profile.UpdateUserInfoAfterSettingsChangeR: {
      const { requestedUpdates } = payload;
      const updatedState = {};

      Object.entries(requestedUpdates).forEach(([key, value]) => {
        if (key === 'email' || key === 'newEmail') return;
        if (key === 'firstName') {
          updatedState.firstName = value;
          updatedState.first_name = value;
          return;
        }
        if (key === 'lastName') {
          updatedState.lastName = value;
          updatedState.last_name = value;
          return;
        }
        updatedState[key] = value;
      });

      return { ...state, ...updatedState };
    }
    case ServiceUser.GetPaymentIntent: {
      const { clientSecret, payAmount } = payload;
      return { ...state, clientSecret, payAmount };
    }
    case ServiceUser.GetStripeData: {
      const { invoices, paymentMethods } = payload;
      return { ...state, invoices, paymentMethods };
    }
    case ServiceUser.SetSubscriptionCancelDate: {
      const { subscriptionCanceledAt } = payload;
      return { ...state, subscriptionCanceledAt };
    }
    default:
      return state;
  }
};
