import styled from 'styled-components/macro';

export const TableOfContentMainWrapper = styled.div`
  position: absolute;
  height: max-content;
  width: 300px;
  border-radius: 12px;
  box-shadow: 1px 1px 6px 0 #7a696940;
  border: 0.6px solid #ece2e2;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 16px 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  right: 0;
  bottom: -152px;
  max-height: 319px;
  z-index: 10;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background: #ece2e2;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: pink;
  }
`;

export const TableOfContentTitleWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-left: 16px;
  padding-right: 16px;
  color: #d3c8c8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const TableOfContentRowWrapper = styled.div`
  height: 30px;
  width: 100%;
  border-radius: 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  padding-left: 16px;
  padding-right: 14px;
  color: #484343;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:hover {
    background-color: #ffeebf;
  }
`;

export const TableOfContentRowText = styled.p`
  height: 30px;
  width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
`;
export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  width: 100%;
  background: white;
  padding: 34px 30px 30px;
  box-shadow: -4px 0 12px 0 #ece2e280;
  z-index: 103;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background: #ece2e2;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: pink;
  }
`;

export const DetailsWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;
  width: max-content;
  color: #343434;
  margin-bottom: 18px;
`;

export const GlobalOneLineTextWrapped = styled.div`
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ImageWrapper = styled.div`
  height: 162px;
  width: 300px;
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: center;

  svg {
    position: absolute;
  }

  img {
    height: 140px;
    width: 140px;
    object-fit: cover;
    border-radius: 8px;
    position: absolute;
    box-shadow: 0px 2px 2px rgba(120, 120, 120, 0.25);
  }

  ${({ isLibComponent }) => (isLibComponent
    ? `
 width:170px;
 height:170px;
   img {
    height: 170px;
    width: 170px;
    }
 `
    : '')}
`;

export const AudioSvgWrapper = styled.div`
  width: 100%;
  height: 168px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

export const GreyTitleWrapper = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #939393;
  ${({ stepBot }) => (stepBot ? 'margin-bottom:5px;' : '')}
  ${({ first }) => (first ? 'margin-top:20px;' : '')}
`;
export const TitleBlockWrapper = styled.div`
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 19px;
  position: relative;
  color: #484343;
  margin-top: 3px;
  margin-bottom: 17px;
`;

export const UserWrapper = styled.div`
  display: flex;
  margin-top: -2px;
`;

export const DataBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`;

export const DataBlockColumns = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
`;

export const Divider = styled.div`
  width: 300px;
  border: 1px solid #e7e7e9;
  margin-bottom: 15px;
`;

export const ContentTextWrapper = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: #484343;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ forChannelList }) => (forChannelList ? '  margin-bottom: 3px;' : '  margin-bottom: 15.5px;')}
  ${({ isUserName }) => (isUserName
          ?`
            margin-top: 3px;
            margin-left: 5px;
            margin-bottom: 12.5px;
            `
          : '')}
  a {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const ChannelListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
  position: relative;
`;

export const PlayWrapperForMeta = styled.div`
  position: absolute;
  right: 12px;
  bottom: 11px;
`;
