import React from 'react';
import { OptionContent, OptionTitle, OptionWrapper } from '../styled';


const DefaultOption = ({ type, ...props }) => {
  return (
    <OptionWrapper data-parent={props.dataParent}>
      <OptionTitle>{type.title}</OptionTitle>
      <OptionContent row data-parent={props.dataParent}>
        {props.children}
      </OptionContent>
    </OptionWrapper>
  );
};

export default DefaultOption;
