import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import {
  actionBulkToggleIsBlockHidden,
  actionDragManyPage,
  actionDragPage,
  actionToggleIsBlockHidden,
  actionToggleLibraryDrag,
} from '../../redux/currentPage/action';
import { emptyCallback } from '../../utils/constants';
import SharedComponent from '../SharedComponent';
import addComponentIcon from '../../images/icons/add_component.svg';
import { ReactComponent as DragDotsSvg } from '../../images/icons/drag_dots.svg';
import BlockOption from '../DropDownOption/BlockOption';
import ActionDraggable from '../../redux/dragable/action';
import { ReactComponent as EyeOffSvg } from '../../images/icons/eye_off.svg';
import useComponentVisibleMouseDown from '../../utils/hooks/useComponentVisibleMouseDown';
import Tooltip from '../../shared/Tooltips/Tooltip';

const ComponentWrap = styled.div`
  border-radius: 3px;
  margin-left: -3px;
  border: 1px dashed #596080;
   &:after {
    content: "";
    top: -5px;
    overflow: hidden;
    position: absolute;
    background: #0A9531;
    border-radius: 13px;
    right: 13px;
    max-width: 14px;
    min-width: 14px;
    height: 14px;
    transition-property: all;
    transition-duration: 1s;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: white;
  }
}
 &:hover{
    ${({ isPdf, name }) => (isPdf
    ? ''
    : `
   &:after {
      top: -10px;
      content: "${name}";
      max-width: 150px;
      padding: 3px 8px;
      transition-property: all;
      transition-duration: 1s;
    }
  `)}
  }
  ${({ isLargeRightBar }) => isLargeRightBar
    && `
  margin-left: -6px;`}
`;

const ComponentInPage = ({
  isBlockHidden,
  editableBlocks,
  selectedIds,
  onChangeBlocksWithData,
  handlerClick,
  addEditableBlock,
  deleteEditableBlock,
  componentId,
  isLibraryCreate = 'isLibraryCreate',
  setOpenMenuDetach,
  addDuplicateBlock,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentPageId, isLargeRightBar } = useSelector(state => state.currentPage);
  const inputArrayEl = { current: [] };
  const refSelectedBlocks = { current: [] };
  const { blocks } = useSelector(reduxState => reduxState.currentPage);
  const { draggableBlocks } = useSelector(reduxState => reduxState.draggable);
  const [blocksToCreateLComponent, setBlocksToCreateLComponent] = useState({});
  const [idOpenMenuBlock, setIdOpenMenuBlock] = useState('');
  const [selectedIndex, setSelectedIndex] = useState([]);

  const [refMenu, isOpenMenu, setIsOpenMenu] = useComponentVisibleMouseDown(false);
  const [hoverBlock, setHoverBlock] = useState(false);

  const close = useCallback(() => {
    if (isOpenMenu) setTimeout(() => setIsOpenMenu(false), 0);
  }, [isOpenMenu, setIsOpenMenu]);

  const toggleIsHidden = useCallback((e, id, value) => {
    if (draggableBlocks?.length > 1) {
      const processedBlocks = [];
      blocks.forEach((item) => {
        if (draggableBlocks.includes(item.id)) processedBlocks.push({ id: item.id, isHidden: !item.isHidden });
      });
      dispatch(actionBulkToggleIsBlockHidden(processedBlocks));
      e.preventDefault();
      e.stopPropagation();
    } else {
      dispatch(actionToggleIsBlockHidden(id, value, true));
      e.preventDefault();
      e.stopPropagation();
    }
  }, [blocks, dispatch, draggableBlocks]);

  useEffect(() => {
    if (Object.keys(blocksToCreateLComponent).length) {
      refSelectedBlocks.current.forEach((item) => {
        if (item && item.className) {
          item.removeAttribute('class');
          item.setAttribute('class', 'selected_block_create_block');
        }
      });

      dispatch(actionToggleLibraryDrag(false));
    }
  }, [blocksToCreateLComponent]);

  const setOpenMenuDetachCb = useCallback(() => {
    setOpenMenuDetach();
    close();
  }, [setOpenMenuDetach, close]);

  const addCloneBlockCb = useCallback(() => {
    addDuplicateBlock();
    close();
  }, [addDuplicateBlock, close]);

  const addItemRef = useCallback((ref) => {
    inputArrayEl.current.push(ref);
  }, [inputArrayEl]);

  const dragEndHandler = () => {
    const div = document.getElementById('draggable_page');
    dispatch(actionDragPage(null, null));
    dispatch(actionDragManyPage(false));
    dispatch(ActionDraggable.BlocksOnPage([]));
    const parent = document.getElementById('root');
    if (div) parent.removeChild(div);
  };

  const dragStartHandler = (e) => {
    setIsOpenMenu(false);
    const draggableId = draggableBlocks.filter((i) => i);
    let handledBlocks;
    if (draggableId.includes(componentId)) {
      handledBlocks = draggableId;
    } else {
      handledBlocks = [componentId];
    }
    props.selectBlock(handledBlocks);

    dispatch(ActionDraggable.BlocksOnPage(handledBlocks));
    const div = document.createElement('div');
    div.className = 'draggable_page';
    div.id = 'draggable_page';
    if (handledBlocks.length > 1) {
      div.textContent = `${handledBlocks.length} BLOCKS`;
    } else {
      div.textContent = '1 BLOCK';
    }
    if (!isLibraryCreate) dispatch(actionDragManyPage(true));
    const parent = document.getElementById('root');
    parent.appendChild(div);
    e.dataTransfer.setDragImage(div, 117, 20);
  };

  const onDotsMouseDownHandler = (event) => {
    event.stopPropagation();
    if (!props.blocksIds.length || !props.blocksIds.includes(componentId)) {
      props.selectBlock([componentId]);
      dispatch(ActionDraggable.BlocksOnPage([componentId]));
    }
    if (props.blocksIds.length) dispatch(ActionDraggable.BlocksOnPage(props.blocksIds));
  };

  return (
    <div className={`${styles.wrapper_component2}`}>
      <div>
        <div className={styles.wrapper_component_balanser}>
          <div
            className={`
            ${
              !isLibraryCreate
                ? styles.wrapper_component_in_library
                : styles.wrapper_component
            }
            ${isLargeRightBar ? styles.wrapper_component_small : ''}`}
          >
            <div
              className={`${styles.add_line}  ${
                !isLibraryCreate ? styles.add_line_in_library : ''
              }`}
            >
              <div className={` ${styles.img_wrapper} `}>
                <img
                  draggable={false}
                  src={addComponentIcon}
                  onClick={() => props.addNewEmptyEditableBlock('new_before')}
                  alt="add component"
                />
              </div>

              <div className={` ${styles.separating_line} `} />
              <div className={` ${styles.img_wrapper} `}>
                <img
                  draggable={false}
                  src={addComponentIcon}
                  onClick={() => props.addNewEmptyEditableBlock('new')}
                  alt="add component"
                />
              </div>
            </div>

            <div
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              // className={`height_wrapper_component_${type} height_wrapper_component`}
              className={`component_${'type'} component ${styles.component}`}
            >
              <div
                ref={refMenu}
                data-parent="data-parent"
                draggable
                onDragEnd={dragEndHandler}
                onMouseDown={onDotsMouseDownHandler}
                onDragStart={dragStartHandler}
                onClick={(event) => {
                  handlerClick(event, componentId);
                  setIsOpenMenu(true);
                  event.stopPropagation();
                }}
                onMouseUp={() => {
                  dispatch(ActionDraggable.BlocksOnPage([]));
                  dispatch(ActionDraggable.SetHasDraggable(false));
                }}
                className={`${styles.rectangle} ${
                  isOpenMenu && styles.rectangle_active
                }`}
              >
                <DragDotsSvg
                  className={`${styles.dots} ${
                    isOpenMenu && styles.dots_active
                  }`}
                />

                {isOpenMenu && (
                  <>
                    <BlockOption
                      blocksToCreateLComponent={props.blocksToCreateLComponent}
                      setBlocksToCreateLComponent={props.setBlocksToCreateLComponent}
                      blocksIds={props.blocksIds}
                      blocksIndices={props.blocksIndices}
                      mouseup={props.mouseup}
                      isOpenMenu={isOpenMenu}
                      canDetachComponent
                      setIsOpenMenu={setIsOpenMenu}
                      isCreateComponentMenuVisible={props.isCreateComponentMenuVisible}
                      // setIsCreateComponentMenuVisible={props.setIsCreateComponentMenuVisible}
                      onEditComponentClickHandler={props.onEditComponentClickHandler}
                      parentRef={refMenu}
                      setOpenMenuDetach={setOpenMenuDetachCb}
                      addCloneBlock={addCloneBlockCb}
                      deleteEditableBlock={deleteEditableBlock}
                      type="libComponent"
                      isBlockHidden={isBlockHidden}
                      toggleIsHidden={toggleIsHidden}
                      index={props.index}
                      componentId={componentId}
                    />
                  </>
                )}
              </div>
            </div>
            <ComponentWrap
              isLargeRightBar={props.isLargeRightBar}
              name={props.title}
              className="opacityWrapper"
            >
              <div
                className={`${hoverBlock ? styles.hiddenBlockWrapperBg : ''} ${
                  styles.hiddenWrapper
                }`}
              >
                <div
                  className={`${styles.hiddenContainer} ${
                    isBlockHidden ? styles.visible : ''
                  }`}
                  onMouseDown={e => toggleIsHidden(e, componentId, false)}
                  onMouseEnter={() => setHoverBlock(true)}
                  onMouseLeave={() => setHoverBlock(false)}
                >
                  <Tooltip text={t('showBlockT')} direction="down">
                    <div
                      className={`${styles.imageContainer} ${styles.visible}`}
                    >
                      <EyeOffSvg />
                    </div>
                  </Tooltip>
                </div>
                <div className={`${isBlockHidden ? styles.addOpacity : ''} `}>
                  {editableBlocks
                    .sort((a, b) => a.position - b.position)
                    .map(
                      (item, index) => {
                        return (
                          <div key={`${item.inComponent}_${item.id}`}>
                            <div className={styles.relative}>
                              <div
                                id={item.id}
                                ref={ref => refSelectedBlocks.current.push(ref)}
                              >
                                {/* <div */}
                                {/*  className={`${styles.component_sele} */}
                                {/*                    `} */}
                                {/* /> */}
                                <div>
                                  <SharedComponent
                                    setBlocksToCreateLComponent={setBlocksToCreateLComponent}
                                    blocksToCreateLComponent={blocksToCreateLComponent}
                                    onChangeBlocksWithData={onChangeBlocksWithData}
                                    urlFile={item.urlFile}
                                    // blocksIds={selectedIds}
                                    blocksIndices={selectedIndex}
                                    // setBlocksIds={setSelectedIds}
                                    setBlocksIndices={setSelectedIndex}
                                    // mouseup={mouseup}x
                                    // inComponent={inComponent}
                                    setIdOpenMenuBlock={setIdOpenMenuBlock}
                                    idOpenMenuBlock={idOpenMenuBlock}
                                    // handleDashedFrameMouseOver={handleDashedFrameMouseOver}
                                    //       setIsCreateComponentMenuVisible={setIsCreateComponentMenuVisible}
                                    stopPropagation={emptyCallback}
                                    clear={emptyCallback}
                                    handlerClick={handlerClick}
                                    parentIndex={props.index}
                                    type1={item.type}
                                    state={item.state}
                                    componentId={item.id}
                                    relationId={componentId}
                                    innerHtml={item.innerHtml}
                                    position={item.position}
                                    currentPageId={currentPageId}
                                    index={index}
                                    refI={addItemRef}
                                    width={item.width}
                                    page={item}
                                    isViewMode={props.isViewMode}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </ComponentWrap>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentInPage;
