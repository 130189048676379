import React from 'react';

const FolderIconLayout = React.memo(function FolderIconLayout({ isOverCoverImage, isSelected, isSmall = false }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="140" height="140" viewBox="0 0 140 140" fill="none">
      <g clipPath="url(#clip0_3259_18949)">
        <mask
          id="mask0_3259_18949"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="140"
          height="140"
        >
          <path d="M140 0H0V140H140V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_3259_18949)">
          <g filter="url(#filter0_d_3259_18949)">
            <path d="M50.6 24C54.9 24 58.8 27.3 60.3 32.3C60.5272 33.0789 61.0009 33.7632 61.65 34.25C62.2991 34.7368 63.0886 35 63.9 35H128V20C128 17.8783 127.157 15.8434 125.657 14.3431C124.157 12.8429 122.122 12 120 12H20C17.8783 12 15.8434 12.8429 14.3431 14.3431C12.8429 15.8434 12 17.8783 12 20V24H50.6Z" fill="white" fillOpacity={isOverCoverImage ? 0.6 : 1} />
          </g>
          {!isSmall && <path fillRule="evenodd" clipRule="evenodd" d="M139 56V9C139 6.99829 138.217 4.96943 136.965 3.44761C135.711 1.92241 134.072 1 132.4 1H7.00028C4.81522 1 2.82261 2.54163 1.83103 5.06565L1.82269 5.08689L1.81338 5.10773C1.26773 6.3297 0.99045 7.65446 1.00025 8.99268L1.00031 9L1.00029 56H0.000277863V9C-0.01057 7.51881 0.296329 6.05251 0.900277 4.7C2.00028 1.9 4.30028 0 7.00028 0H132.4C136.5 0 140 4.5 140 9V56H139Z" fill="white" fillOpacity="0.2" />}
          {!isSelected && !isSmall && (
            <g filter="url(#filter1_d_3259_18949)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M138.999 130.7V43.0061C138.999 41.149 138.261 39.3679 136.948 38.0548C135.634 36.7416 133.852 36.0039 131.994 36.0039H63.8368L63.8281 36.0037C62.8092 35.9859 61.8219 35.6473 61.0066 35.0361C60.1914 34.4249 59.5897 33.5722 59.2874 32.5995L59.2845 32.5902C57.8753 27.8947 54.3862 25.0004 50.5361 25.0004H7.51277C6.79165 25.0115 6.0785 25.1524 5.40748 25.4162C2.92194 26.6336 1.00071 29.7352 1.00071 33.403V130.697C1.00071 132.811 1.79224 134.918 3.04609 136.487C4.30396 138.063 5.94203 139 7.60543 139H133.095C135.254 139 137.26 137.391 138.264 134.744C138.754 133.453 139.003 132.082 138.999 130.7ZM5.00357 24.5002C5.79984 24.1818 6.64783 24.0123 7.50536 24H50.5361C54.9392 24 58.7419 27.3011 60.243 32.3027C60.4829 33.0745 60.9602 33.751 61.6071 34.2359C62.2539 34.7208 63.0372 34.9895 63.8456 35.0036H131.994C134.117 35.0036 136.154 35.8467 137.655 37.3474C139.157 38.8482 140 40.8837 140 43.0061V130.697C140.004 132.201 139.733 133.693 139.199 135.099C138.099 137.999 135.797 140 133.095 140H7.60543C3.5025 140 0 135.399 0 130.697V33.403C0 29.4018 2.1015 25.9006 5.00357 24.5002Z"
                fill="white"
              />
            </g>
          )}
        </g>
      </g>
      <defs>
        <filter id="filter0_d_3259_18949" x="10" y="9" width="120" height="27" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.345098 0 0 0 0 0.345098 0 0 0 0 0.345098 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3259_18949" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3259_18949" result="shape" />
        </filter>
        <filter
          id="filter1_d_3259_18949"
          x="-2"
          y="21"
          width="144"
          height="120"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.470588 0 0 0 0 0.470588 0 0 0 0 0.470588 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3259_18949" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3259_18949" result="shape" />
        </filter>
        <clipPath id="clip0_3259_18949">
          <rect width="140" height="140" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

export default FolderIconLayout;
