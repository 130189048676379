import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Page32Svg } from '../../../images/icons/replace_icons/Page.svg';

import rawStyles from './index.module.scss';
import { actionAddPayloadUnifyHistory } from '../../../redux/history/actions';

const cn = classNames.bind(rawStyles);

const PageLink = ({ page, header, isLibraryCreate }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isPublishedPage = history.location.pathname.includes('/publish_page');
  const isViewedPlaylist = history.location.pathname.includes('/view_playlist')
    || history.location.pathname.includes('/shared_playlist');
  const isSharedPage = history.location.pathname.includes('/shared_page');
  const isPlayer = history.location.pathname.includes('/player');
  const isChannelEditPage = history.location.pathname.includes('/edit_page');
  const { id, title } = useSelector((state) => state.currentPage);
  const customHistory = useSelector((state) => state.historyTrace);
  const clickHandler = (e) => {
    e.stopPropagation();
    if (isLibraryCreate || isSharedPage) return;
    if (id === page.nestedItemId) return;
    const lastStep = customHistory.somePayload;
    const newStep = { step: {} };
    newStep.step.type = 'page';
    newStep.step.id = id;
    newStep.step.title = title;
    newStep.step.playlistTitle = lastStep?.step?.playlistTitle;

    newStep.departureUrl = history?.location?.pathname;

    if (isChannelEditPage) {
      dispatch(actionAddPayloadUnifyHistory(newStep));
      history.push(`/edit_page/${page.nestedItemId}`);
    } else if (isPublishedPage) {
      dispatch(actionAddPayloadUnifyHistory(newStep));
      history.push(`/publish_page/${page.nestedItemId}`);
    } else if (isViewedPlaylist) {
      newStep.step.type = '';
      newStep.step.playlistTitle = title;
      dispatch(actionAddPayloadUnifyHistory(newStep));
      history.push(`/edit_page/${page.nestedItemId}`);
    } else if (isPlayer) {
      newStep.step.type = '';
      newStep.step.playlistTitle = title;
      dispatch(actionAddPayloadUnifyHistory(newStep));
      history.push(`/edit_page/${page.nestedItemId}`);
    } else {
      dispatch(actionAddPayloadUnifyHistory(newStep));
      history.push(`/libraryPage/${page.nestedItemId}`);
    }
  };

  return (
    <div onClick={clickHandler} className={cn('page_link_wrapper')}>
      <div className={cn('page_link_container')}>
        <Page32Svg className={cn('icon')} />
        <div className={cn('page_name')}>{header}</div>
        <div className={cn('hint')}>{t('linkToPageT')}</div>
      </div>
    </div>
  );
};

export default PageLink;
