import { Channels } from './types';

export const actionGetChannels = () => ({
  type: Channels.GetUserChannels,
});
export const actionChangeChannelUserStatus = (id, status, channelId) => ({
  type: Channels.ChangeChannelUserStatus,
  payload: { userId: id, newStatus: status, channelId },
});
export const actionSetNewFilterValue = (place, value) => ({
  type: Channels.SetNewFilterValue,
  payload: { place, newValue: value },
});

export const actionCreateChannel = (title, channelID, callback) => ({
  type: Channels.CreateChannel,
  payload: { channelID, title, callback },
});

export const actionAddChannelR = (
  channelId,
  user,
  isNew,
  newPosition,
  title,
) => ({
  type: Channels.AddChannelR,
  payload: { channelId, user, isNew, newPosition, title },
});

export const actionSaveChannels = (channels) => ({
  type: Channels.SaveChannelsR,
  payload: channels,
});
export const actionSaveChannel = (channel, id) => ({
  type: Channels.SaveChannelR,
  payload: { channel, id },
});

export const actionClearChannelData = (id) => ({
  type: Channels.clearChannelData,
  payload: { id },
});

export const actionSetCurrentChannel = (channel) => ({
  type: Channels.SetCurrentChannel,
  payload: channel,
});

export const actionUpdateChannelR = (channel) => ({
  type: Channels.UpdateChannelR,
  payload: channel,
});

export const actionConfirmSubscribe = (key, token, history, confirmUrl) => ({
  type: Channels.ConfirmSubscribe,
  payload: { key, token, history, confirmUrl },
});
export const actionDeleteChannel = (channelId, nextChannel, history) => ({
  type: Channels.DeleteChannel,
  payload: { channelId, nextChannel, history },
});
export const actionDeleteChannelR = (channelId) => ({
  type: Channels.DeleteChannelR,
  payload: { channelId },
});

export const actionAddSubscribe = (emailsToInvite, channelId) => ({
  type: Channels.AddSubscribers,
  payload: { emailsToInvite, channelId },
});

export const actionAddSubscribeR = (users, channelId) => ({
  type: Channels.AddSubscribersR,
  payload: { users, channelId },
});

export const actionTogglePlaylistPublishInChannel = (channel, playlist) => ({
  type: Channels.TogglePlaylistPublishInChannelR,
  payload: { channel, playlist },
});

export const actionGetChannelData = (channelId) => ({
  type: Channels.getChannelData,
  payload: { channelId },
});

export const actionRenameChannel = (channelId, name) => ({
  type: Channels.RenameChannel,
  payload: { channelId, name },
});
