import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import TextElement from './TextElement';
import LibraryElement from './LibraryElement';
import { actionCreator } from '../../../shared/redux/actionHelper';
import GoogleUploadElement from './GoogleUploadElement';
import { PLAYLIST_ELEMENTS } from '../../../utils/constants';
import { CurrentPage } from '../../../redux/currentPage/types';
import TitleElement from './TitleElement';
import { smartFileItemTypeCheck } from '../../../shared/smartFile/constant';
import LibraryElementExpanded from '../MakerExpanded/LibraryElement';
import GoogleUploadElementExpanded from '../MakerExpanded/GoogleUploadElement';
import PlaceHolderAll from '../MakerCommon/Table/PlaceHolderAll';
import ShellOptionSfElement from './ShellOptionSfElement';
import rawElemStyles from './elements.module.scss';
import LexTextElement from './prevTextElement';

const cx = classNames.bind(rawElemStyles);

const PlaylistsBuilderContent = ({
  item,
  playlistId,
  itemStylesObject,
  onClick,
  canUpdForRedoUndo,
  setCanUpdForRedo,
  playlistType,
  isExpanded,
  isCompressView,
  ...props
}) => {
  const dispatch = useDispatch();
  const renameRef = useRef(false);

  const createApproveButton = () => {
    dispatch(
      actionCreator(CurrentPage.CreateApproveButtonS, { linkPageId: item.id }),
    );
  };
  const { id: userId } = useSelector((state) => state.user);

  const addCaptionToLinkPage = () => {
    dispatch(
      actionCreator(CurrentPage.UpdateIsShowCaptionState, {
        playlistId,
        linkPageId: item.id,
        value: true,
        isNew: true,
      }),
    );
  };
  const removeCaptionFromLinkPage = () => {
    dispatch(
      actionCreator(CurrentPage.UpdateIsShowCaptionState, {
        playlistId,
        linkPageId: item.id,
        value: false,
      }),
    );
  };

  const deleteApproveButton = () => {
    dispatch(
      actionCreator(CurrentPage.DeleteApproveButtonS, { linkPageId: item.id }),
    );
  };

  if (!item?.type || item?.type === 'placeholder') {
    return (
      <ShellOptionSfElement
        blinkIdsArray={props.blinkIdsArray}
        clear={props.clear}
        createHandler={props.createHandler}
        onDragEndHandler={props.onDragEndHandler}
        playlistId={playlistId}
        item={item}
        isDragHover={props.isDragHover}
        dragStartHandler={props.dragStartHandler}
        builderSelectedIds={props.selectedIds}
        itemStylesObject={itemStylesObject}
        currentRole={props.currentRole}
        itemIndex={props.itemIndex}
        isExpanded={isExpanded}
        isCompressView={isCompressView}
        handleShowPannel={props.handleShowPannel}
      >
        <div
          className={cx('empty_menu', {
            notEdit: userId !== item?.owner?.id,
          })}
        >
          <div className={cx('center_menu')}>
            <PlaceHolderAll
              item={item}
              index={props.itemIndex}
              dragHover={props.isHoverByDndOnEmpty}
              onDragEnter={props.onDragEnterOnEmpty}
              onDragLeave={(e) => {
                e.stopPropagation();
                props.onDragLeave(e);
              }}
              onDrop={props.onDropOnEmpty}
            />
          </div>
        </div>
      </ShellOptionSfElement>

    );
  }

  if (smartFileItemTypeCheck.isRichText[item.type]) {
    return (
      <LexTextElement
        item={item}
        playlistId={playlistId}
        isDragHover={props.isDragHover}
        itemStylesObject={itemStylesObject}
        isExpandedView
        blinkId={props.blinkId}
        clear={props.clear}
        createHandler={props.createHandler}
        onDragEndHandler={props.onDragEndHandler}
        linkPageId={item.id}
        canUpdForRedoUndo={canUpdForRedoUndo}
        setCanUpdForRedo={setCanUpdForRedo}
        builderSelectedIds={props.selectedIds}
        selectedIds={props.selectedIds}
        selectedIndex={props.selectedIndex}
        setSelectedIndex={props.setSelectedIndex}
        itemIndex={props.itemIndex}
        setSelectedIds={props.setSelectedIds}
        selectionBox={props.selectionBox}
        refSelectedBlocks={props.refSelectedBlocks}
        dragStartHandler={props.dragStartHandler}
        onPasteFile={props.onPasteFile}
        currentRole={props.currentRole}
        duplicateHandler={props.duplicateHandler}
        removeCaptionFromLinkPage={removeCaptionFromLinkPage}
        createApproveButton={createApproveButton}
        addCaptionToLinkPage={addCaptionToLinkPage}
        deleteApproveButton={deleteApproveButton}
        setIsDNDInTextBlock={props.setIsDNDInTextBlock}
        playlistType={playlistType}
        handleShowPannel={props.handleShowPannel}
        isCompressView={isCompressView}
      />
    );
  }
  if (smartFileItemTypeCheck.isTitle[item?.type]) {
    return (
      <TitleElement
        blinkId={props.blinkId}
        clear={props.clear}
        createHandler={props.createHandler}
        onDragEndHandler={props.onDragEndHandler}
        playlistId={playlistId}
        linkPageId={item.id}
        item={item}
        canUpdForRedoUndo={canUpdForRedoUndo}
        setCanUpdForRedo={setCanUpdForRedo}
        isDragHover={props.isDragHover}
        builderSelectedIds={props.selectedIds}
        selectedIds={props.selectedIds}
        selectedIndex={props.selectedIndex}
        setSelectedIndex={props.setSelectedIndex}
        itemIndex={props.itemIndex}
        setSelectedIds={props.setSelectedIds}
        selectionBox={props.selectionBox}
        refSelectedBlocks={props.refSelectedBlocks}
        dragStartHandler={props.dragStartHandler}
        itemStylesObject={itemStylesObject}
        onPasteFile={props.onPasteFile}
        currentRole={props.currentRole}
        duplicateHandler={props.duplicateHandler}
        removeCaptionFromLinkPage={removeCaptionFromLinkPage}
        createApproveButton={createApproveButton}
        addCaptionToLinkPage={addCaptionToLinkPage}
        deleteApproveButton={deleteApproveButton}
        playlistType={playlistType}
        handleShowPannel={props.handleShowPannel}
        isCompressView={isCompressView}
      />
    );
  }
  if (item.type === 'elementText') {
    return (
      <TextElement
        blinkIdsArray={props.blinkIdsArray}
        clear={props.clear}
        createHandler={props.createHandler}
        onDragEndHandler={props.onDragEndHandler}
        playlistId={playlistId}
        linkPageId={item.id}
        item={item}
        canUpdForRedoUndo={canUpdForRedoUndo}
        setCanUpdForRedo={setCanUpdForRedo}
        isDragHover={props.isDragHover}
        builderSelectedIds={props.selectedIds}
        selectedIds={props.selectedIds}
        selectedIndex={props.selectedIndex}
        setSelectedIndex={props.setSelectedIndex}
        itemIndex={props.itemIndex}
        setSelectedIds={props.setSelectedIds}
        selectionBox={props.selectionBox}
        refSelectedBlocks={props.refSelectedBlocks}
        dragStartHandler={props.dragStartHandler}
        itemStylesObject={itemStylesObject}
        onPasteFile={props.onPasteFile}
        currentRole={props.currentRole}
        duplicateHandler={props.duplicateHandler}
        removeCaptionFromLinkPage={removeCaptionFromLinkPage}
        createApproveButton={createApproveButton}
        addCaptionToLinkPage={addCaptionToLinkPage}
        deleteApproveButton={deleteApproveButton}
        playlistType={playlistType}
        handleShowPannel={props.handleShowPannel}
        isCompressView={isCompressView}
      />
    );
  }
  if (!isExpanded) {
    if (item.type === PLAYLIST_ELEMENTS.IntegrationsUpload) {
      return (
        <GoogleUploadElement
          blinkIdsArray={props.blinkIdsArray}
          clear={props.clear}
          createHandler={props.createHandler}
          onDragEndHandler={props.onDragEndHandler}
          playlistId={playlistId}
          item={item}
          isDragHover={props.isDragHover}
          dragStartHandler={props.dragStartHandler}
          itemStylesObject={itemStylesObject}
          itemIndex={props.itemIndex}
          createLinkPage={props.createLinkPage}
          currentRole={props.currentRole}
          isCompressView={isCompressView}
        />
      );
    }
    if (item.type === PLAYLIST_ELEMENTS.ElementComponent) {
      return (
        <LibraryElement
          isFinished={props.isFinished}
          playlistType={playlistType}
          blinkIdsArray={props.blinkIdsArray}
          clear={props.clear}
          createHandler={props.createHandler}
          onDragEndHandler={props.onDragEndHandler}
          playlistId={playlistId}
          item={item}
          isDragHover={props.isDragHover}
          builderSelectedIds={props.selectedIds}
          refSelectedBlocks={props.refSelectedBlocks}
          itemIndex={props.itemIndex}
          dragStartHandler={props.dragStartHandler}
          itemStylesObject={itemStylesObject}
          goToFullScreenView={onClick}
          renameRef={renameRef}
          currentRole={props.currentRole}
          duplicateHandler={props.duplicateHandler}
          createApproveButton={createApproveButton}
          addCaptionToLinkPage={addCaptionToLinkPage}
          removeCaptionFromLinkPage={removeCaptionFromLinkPage}
          deleteApproveButton={deleteApproveButton}
          handleShowPannel={props.handleShowPannel}
          isCompressView={isCompressView}
        />
      );
    }
  }
  if (item.type === PLAYLIST_ELEMENTS.IntegrationsUpload) {
    return (
      <GoogleUploadElementExpanded
        blinkIdsArray={props.blinkIdsArray}
        clear={props.clear}
        createHandler={props.createHandler}
        onDragEndHandler={props.onDragEndHandler}
        playlistId={playlistId}
        item={item}
        isDragHover={props.isDragHover}
        dragStartHandler={props.dragStartHandler}
        itemStylesObject={itemStylesObject}
        itemIndex={props.itemIndex}
        createLinkPage={props.createLinkPage}
        currentRole={props.currentRole}
        isCompressView={isCompressView}
      />
    );
  }
  if (item.type === PLAYLIST_ELEMENTS.ElementComponent) {
    return (
      <LibraryElementExpanded
        isFinished={props.isFinished}
        playlistType={playlistType}
        blinkIdsArray={props.blinkIdsArray}
        clear={props.clear}
        createHandler={props.createHandler}
        onDragEndHandler={props.onDragEndHandler}
        playlistId={playlistId}
        item={item}
        isDragHover={props.isDragHover}
        builderSelectedIds={props.selectedIds}
        refSelectedBlocks={props.refSelectedBlocks}
        itemIndex={props.itemIndex}
        dragStartHandler={props.dragStartHandler}
        itemStylesObject={itemStylesObject}
        goToFullScreenView={onClick}
        renameRef={renameRef}
        currentRole={props.currentRole}
        duplicateHandler={props.duplicateHandler}
        createApproveButton={createApproveButton}
        addCaptionToLinkPage={addCaptionToLinkPage}
        removeCaptionFromLinkPage={removeCaptionFromLinkPage}
        deleteApproveButton={deleteApproveButton}
        handleShowPannel={props.handleShowPannel}
        isCompressView={isCompressView}
      />
    );
  }
  if (item.type === 'slide') {
    return <div />;
  }
  return <div />;
};

export default PlaylistsBuilderContent;
