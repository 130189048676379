export const FileUpload = {
  CreateUpload: '[Upload] Create upload instance',
  CreateUploadR: '[Upload] Create upload instance REDUX',
  DeleteUpload: '[Upload] Delete upload instance',
  UpdateUpload: '[Upload] Update upload instance',
  ErrorUpload: '[Upload] Uploading stopped with error',
  CancelAll: '[Upload] Cancel all uploads',
  CloseUploads: '[Upload] Cancel all finished uploads',
  Cancel: '[Upload] Cancel upload',
  UploadGlobalStatus: '[Upload] Update Global Upload Status',
  WipeAll: '[Upload] Wipe All uploads',
  CreateImageUploadByLink: '[Upload] Create Image Upload By Link',
};
