import { ADD_PAGE, EditPage, GET_PAGE } from './types';
import { CurrentPage } from '../currentPage/types';
import { ServiceUser } from '../user/types';

const defaultState = {};

export const pagesReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_PAGE: {
      return payload;
    }
    case ADD_PAGE: {
      return { ...payload, ...state };
    }
    case EditPage.ClearIsNewFolder: {
      const { id, isNewOnButton } = payload;
      if (isNewOnButton) {
        return {
          ...state,
          [id]: {
            ...state[id],
            isNewOnButton: false,
          },
        };
      }
      return {
        ...state,
        [id]: {
          ...state[id],
          isNew: false,
        },
      };
    }
    case EditPage.MovedToTrashPage: {
      if (
        payload.folderId
        && state[payload.folderId]?.nestedPage[payload.idPageMovedToTrash]
      ) {
        state[payload.idPageMovedToTrash] = {
          ...state[payload.folderId].nestedPage[payload.idPageMovedToTrash],
          movedToTrash: true,
        };

        delete state[payload.folderId].nestedPage[payload.idPageMovedToTrash];
      } else if (state[payload.idPageMovedToTrash]) {
        state[payload.idPageMovedToTrash].movedToTrash = true;
      }
      return { ...state };
    }
    case CurrentPage.handleFolderTitleChangeREDUX: {
      return {
        ...state,
        [payload.id]: { ...state[payload.id], title: payload.title },
      };
    }
    case ServiceUser.Logout: {
      return { ...defaultState };
    }
    default:
      return state;
  }
};
