import { useDispatch, useSelector } from 'react-redux';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { actionChangeChannelUserStatus } from '../../../redux/channels/action';
import { actionShowMessage } from '../../../redux/support/action';
import { MessageType, openModalType } from '../../../utils/constants';
import { actionOpenModal } from '../../../redux/user/action';
import MemberRowUI from './MemberRowUI';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';

const MemberRow = ({ item, ...props }) => {
  const dispatch = useDispatch();
  const { myChannels } = useSelector((state) => state.channels);
  const { id: folderId } = useParams();
  const [optionsRef, isOptionsOpened, setIsOptionsOpened] = useComponentVisible(
    false,
    `usersTable${item.id}`,
  );
  const cellRef = useRef(null);

  const rowClickHandle = () => {};

  const changeMemberStatus = (userid, name, status) => {
    if (!myChannels[folderId]?.permissions.canChangeRolesTo[item.role]) {
      dispatch(
        actionShowMessage({
          type: MessageType.ForbiddenStatusChange,
          userRole: myChannels[folderId].role,
        }),
      );
      return;
    }
    if (status === 'Cancel') {
      dispatch(
        actionChangeChannelUserStatus(userid, 'Deleted', props.channel.id),
      );
      dispatch(
        actionShowMessage({
          type: MessageType.CancelInvSubscriber,
          userName: name,
        }),
      );
    } else if (status === 'Deleted') {
      dispatch(
        actionOpenModal(openModalType.DeleteUser, {
          userid,
          channelId: props.channel.id,
          name,
        }),
      );
    } else {
      dispatch(actionChangeChannelUserStatus(userid, status, props.channel.id));

      dispatch(
        actionShowMessage({
          type: MessageType.ChangeChannelSubscriberStatus,
          userName: name,
          newStatus: status,
        }),
      );
    }

    setIsOptionsOpened(false);
  };

  return (
    <MemberRowUI
      item={item}
      rowClickHandle={rowClickHandle}
      isOptionsOpened={isOptionsOpened}
      setIsOptionsOpened={setIsOptionsOpened}
      changeMemberStatus={changeMemberStatus}
      cellRef={cellRef}
      optionsRef={optionsRef}
      isDisabled={props.isDisabled}
    />
  );
};

export default MemberRow;
