interface Action<T = any> {
    type: string;
    payload?: T;
    place?: string;
}


export const actionCreator = <T = any>(type: string, value?: T, place?: string): Action<T> => ({
  type,
  payload: value,
  place,
});
