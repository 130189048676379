import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SharedPlaylistUser from '../SharedPlaylistUser';
import {
  ReactComponent as CloseCrossSvg,
} from '../../../../../../images/2023/svg/small/component-description/close_16_16.svg';
import Toggler from '../../../../../../components/Togglers/Toggler';
import Tooltip from '../../../../../../shared/Tooltips/Tooltip';
import { getUserAvatarComponent } from '../../../LogicComponents/getUserAvatarComponent';
import Availability from '../Availability';
import {
  ContainerWithPointer,
  OptionDivider,
  OptionHeader,
  OptionItem,
} from '../styled';
import ShareSingleUser from '../../../../../../components/DropDownOption/Share/ShareSingleUser';
import { DATA_PARENTS } from '../../../../../../utils/constants';
import { calcCoEditorColor } from '../../../../../../utils/helpers';
import { actionCreator } from '../../../../../../shared/redux/actionHelper';
import { ActionTypeRemoveUserToSharing } from '../../../../../../components/DropDownOption/Share/helpers';
import useComponentVisible from '../../../../../../utils/hooks/useComponentVisible';

const ShareUserItem = ({
  singleUserShareState,
  playlistId,
  itemType,
  user,
  dataParent,
  coEditToggler,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isPage = itemType === 'page';
  const [refDropMenu, isDropMenu, setIsSingleShareOpened] = useComponentVisible(
    false,
    DATA_PARENTS.AddMember,
  );

  const removeUserToSharing = (user) => {
    dispatch(
      actionCreator(ActionTypeRemoveUserToSharing[itemType], {
        pageId: playlistId,
        user,
      }),
    );
  };

  const coEditTogglerHandler = useCallback(() => {
    coEditToggler(!singleUserShareState[user.email]?.isCoEdit, 'isCoEdit', user.email);
  }, [coEditToggler, user, singleUserShareState]);

  const color = useMemo(() => {
    return calcCoEditorColor(user.coEditorIndex);
  }, [user.coEditorIndex]);

  return (
    <OptionItem key={user.id} data-parent={dataParent}>
      <OptionHeader>
        <SharedPlaylistUser
          AvatarComponent={getUserAvatarComponent(user, 20, false, color)}
          name={
            user.first_name && user.last_name
              ? `${user?.first_name} ${user.last_name}`
              : user.username || user.email
          }
          dataParent={dataParent}
          itemIndex={user.coEditorIndex}
        >
          {!isPage && (
            <Tooltip
              data-parent={dataParent}
              text={t('allowCoEditingT')}
              direction="down"
              place="SettingsRightSideBar"
              moveTop
            >
              <Toggler
                relative
                isActive={singleUserShareState[user.email]?.isCoEdit}
                callback={coEditTogglerHandler}
                dataParent={dataParent}
              />
            </Tooltip>
          )}
        </SharedPlaylistUser>
        <ContainerWithPointer>
          <CloseCrossSvg data-parent={dataParent} onClick={() => removeUserToSharing(user)} />
        </ContainerWithPointer>
      </OptionHeader>
      <Availability
        isShared
        handleClick={() => {
          setIsSingleShareOpened(true);
        }}
        user={singleUserShareState[user.email]}
        dataParent={dataParent}
      />
      {isDropMenu
        && (
          <ShareSingleUser
            refMenu={refDropMenu}
            close={() => setIsSingleShareOpened(false)}
            userEmail={user.email}
            dataParent={DATA_PARENTS.PlaylistBuilderLibrary}
          />
        )}
      <OptionDivider />
    </OptionItem>
  );
};

export default ShareUserItem;
