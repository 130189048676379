import React from 'react';
import styled from 'styled-components/macro';
import { CompositeDecorator, DefaultDraftBlockRenderMap } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import { styleMap } from '../../../utils/richTextBar';
import { emptyCallback } from '../../../utils/constants';
import { blockRenderMap, findLinkEntities, myBlockStyleFn } from '../../../utils/helpersDragt';
import 'draft-js/dist/Draft.css';
import LexicalViewer from '../../../features/LexicalViewer/LexicalViewer';


const TextScrollWrapper = styled.div`
  max-height: calc(100vh - 200px);
  overflow: auto;
  flex-shrink:0;
`;

const TextWrapper = styled.div`
  zoom: 1.5;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 25px;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  max-width: 961px;
`;


const LexicalPreview = ({ item }) => {
  return (
    <TextScrollWrapper>
      <TextWrapper>
        <LexicalViewer state={item.textComponent?.state || ''} />
      </TextWrapper>
    </TextScrollWrapper>
  );
};

export default LexicalPreview;
