export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function getImgSize(imgSrc, setter) {
  const newImg = new Image();
  newImg.onload = function f() {
    const height = newImg.height;
    const width = newImg.width;
    setter(`${width} × ${height}`);
  };

  newImg.src = imgSrc;
}

export const savedValue = (value) => (typeof value === 'string' ? `"${value}"` : value);
