import { FileUpload } from './types';

export const actionCreateUpload = (uploadObj) => ({
  type: FileUpload.CreateUpload,
  payload: uploadObj,
});

export const actionDeleteUpload = (id) => ({
  type: FileUpload.DeleteUpload,
  payload: { id },
});
export const actionCancelUpload = (id, folderId) => ({
  type: FileUpload.Cancel,
  payload: { id, folderId },
});
export const actionCloseUploadsOnFolderSwitch = (
  folderId,
  hideNotification,
) => ({
  type: FileUpload.CloseUploads,
  payload: { folderId, hideNotification },
});
export const actionCancelAllUploads = (folderId, hideNotification) => ({
  type: FileUpload.CancelAll,
  payload: { folderId, hideNotification },
});
export const actionUpdateUpload = (uploadObj) => ({
  type: FileUpload.UpdateUpload,
  payload: uploadObj,
});
export const actionUpdateUploadGlobalStatus = (string) => ({
  type: FileUpload.UploadGlobalStatus,
  payload: string,
});
export const actionWipeAllUploads = () => ({
  type: FileUpload.WipeAll,
});
