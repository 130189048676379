import React, { useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { ReactComponent as Library16Svg } from '../../../images/icons/black_dynamic_collection16.svg';
import { ReactComponent as TagsGroupSvg } from '../../../images/icons/tagsGroup.svg';
import { placeSelector } from '../../../utils/helpers';
import {
  calcIsDynamicCollection,
  DATA_PARENTS,
  dragType,
} from '../../../utils/constants';
import { actionShowLibraryWidget } from '../../../redux/currentPage/action';
import { actionChangeSieveValue } from '../../../redux/library/actions';
import Tooltip from '../../../shared/Tooltips/Tooltip';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import { actionMoveDynamicCollection } from '../../../redux/dynamicCollection/action';
import ActionDraggable from '../../../redux/dragable/action';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { ContentActionType } from '../../../redux/content/contentTypes';
import { sortedFunctions } from '../../../utils/dateConvert';
import TagsDropDown from '../../../components/Tag/TagsDropDown/TagsDropDown';
import SmartViewTab from '../../../components/SmartView';
import { ReactComponent as SettingsSvg } from '../../../images/icons/settings.svg';
import { SideBarSeparator, SideBarTab } from './styled';

const cn = classNames.bind(styles);


const defColor = {
  svgActive: '#242633',
  svg: '#596080',
  background: '#FFEEBF',
};


const defaultSortType = Object.keys(sortedFunctions)[2];

const SideBarTabs = ({ isLibrary, forSecondaryMenu, ...props }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const { isShowLibraryWidget, sideBarType } = useSelector((state) => state.currentPage);
  const dynamicCollection = useSelector((state) => state.dynamicCollection);
  const { activeSieveOption } = useSelector((state) => state.library);
  const [iconsToShow, setIconsToShow] = useState([]);
  const parentRef = useRef(null);
  const { tags } = useSelector((state) => state.user);

  const isSettings = sideBarType === 'settings';
  const isPage = location.pathname.startsWith('/libraryPage');

  const onSettingsClick = () => {
    dispatch(actionShowLibraryWidget(true, 'settings'));
  };


  const [tagsRef, isTagsOpened, setIsTagOpened] = useComponentVisible(
    false,
    DATA_PARENTS.tagList,
  );
  const smartViewModal = useComponentVisible(
    false,
    DATA_PARENTS.smartview,
    true,
  );
  const history = useHistory();

  const { sortType = defaultSortType } = useSelector((state) => state.content);
  const setPreviewContentQuery = ({
    newActiveNavSlider,
    newSelectorType,
    newSortType,
  }) => {
    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        {
          activeNavSlider: newActiveNavSlider,
          selectorType: newSelectorType,
          sortType: newSortType || sortType,
          isNeedUpdate: true,
        },
        'SideBarTabs',
      ),
    );
  };

  const toggleSideBars = (e, place, id) => {
    e.stopPropagation();
    e.preventDefault();
    if (props.newUPVMode) {
      props.setChosenTag(id);
      setPreviewContentQuery({
        newActiveNavSlider: 'tag',
        newSelectorType: id.id,
      });
      setIsTagOpened(false);
      return;
    }
    if (props.isShowFilter) props.setIsShowFilter(false);
    if (isTagsOpened) setIsTagOpened(false);
    if (smartViewModal.isComponentVisible) smartViewModal.setIsComponentVisible(false);

    if (place === 'text' || place === 'library' || place === 'pages') {
      setPreviewContentQuery({
        newActiveNavSlider: 'pages',
        newSelectorType: 'all',
      });

      dispatch(actionChangeSieveValue(placeSelector(place), 'library'));
    } else if (place === 'tag') {
      if (isLibrary) {
        setPreviewContentQuery({
          newActiveNavSlider: 'tag',
          newSelectorType: id.id,
        });
        dispatch(
          actionChangeSieveValue(
            { name: id.title, id: id.id, mode: 'tag' },
            'library',
          ),
        );
        history.push(`/content/library/tag_${id.id}`);
      } else {
        setPreviewContentQuery({
          newActiveNavSlider: 'tag',
          newSelectorType: id.id,
        });
        dispatch(actionChangeSieveValue(place, 'library'));
      }
    } else {
      setPreviewContentQuery({
        newActiveNavSlider: 'dynamicCollectionNew',
        newSelectorType: place,
      });
      dispatch(
        actionChangeSieveValue(
          { name: place.name, id: place.id, mode: 'dynamic' },
          'library',
        ),
      );
    }
    if (isSettings) dispatch(actionShowLibraryWidget(true, ''));
    if (!isShowLibraryWidget || !isSettings) dispatch(actionShowLibraryWidget(!isShowLibraryWidget, ''));
  };

  const [dragItem, setDragItem] = useState(null);

  const dragPageStart = (item, index) => (e) => {
    const id = document.getElementById(item.id);
    const div2 = id.cloneNode('deep');
    // https://stackoverflow.com/questions/14203734/dragend-dragenter-and-dragleave-firing-off-immediately-when-i-drag
    setTimeout(() => {
      setDragItem({ ...item, index });
      dispatch(ActionDraggable.DragSmartView(item.id));
    }, 10);
    const div = document.createElement('div');
    div.className = dragType.smartView;
    div.id = dragType.smartView;
    div.appendChild(div2);
    document.body.appendChild(div);

    const rect = id.getBoundingClientRect();

    e.dataTransfer.setDragImage(
      div,
      e.clientX - rect.left,
      e.clientY - rect.top,
    );
  };

  const endDrop = () => {
    setDragItem(false);
    const div = document.getElementById(dragType.smartView);
    if (div) document.body.removeChild(div);
    dispatch(ActionDraggable.Clear());
  };

  const onDrop = (e, newIndex) => {
    if (newIndex || newIndex === 0) {
      if (newIndex !== dragItem.index) {
        dispatch(actionMoveDynamicCollection(dragItem, newIndex));
      }
      setDragItem(null);
    }
    endDrop();
  };

  return (
    <div
      data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
      className={cn({
        tab_bar: !isLibrary,
        isLibrary,
        active: props.showLibrary,
      })}
    >
      {!isLibrary && (
        <>
          <div
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            className={cn('inner_radius', { active: props.showLibrary })}
            ref={props.wrapperRef}
          />
          <Tooltip
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            text={t('libraryT')}
            direction="right"
            place="PlaylistTabs"
            moveTop
          >
            <SideBarTab
              data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
              data-place="library"
              color={defColor}
              isActive={
                isShowLibraryWidget
                && !calcIsDynamicCollection(activeSieveOption)
                && !isSettings
              }
              onClick={(e) => toggleSideBars(e, 'library')}
            >
              <Library16Svg
                data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
                data-place="library"
              />
            </SideBarTab>
          </Tooltip>
          {!!Object.values(dynamicCollection || {}).length && <SideBarSeparator />}
        </>
      )}

      <Tooltip
        text={t('tagsT')}
        moveTop
        direction={isLibrary ? 'up' : 'right'}
        place={isLibrary ? 'LibraryTabsTag' : 'PlaylistTabs'}
      >
        <SideBarTab
          forSecondaryMenu
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          data-place="library"
          isLibrary={isLibrary}
          color={defColor}
          isActive={isTagsOpened}
          onClick={() => setIsTagOpened(true)}
          ref={parentRef}
          newUPVMode={props.newUPVMode}
        >
          <TagsGroupSvg
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            data-place="library"
          />
        </SideBarTab>
      </Tooltip>

      {isTagsOpened && (
        <TagsDropDown
          isLibrary={isLibrary}
          tagsRef={tagsRef}
          tags={tags}
          dragPageStart={dragPageStart}
          endDrop={endDrop}
          activeSieveOption={activeSieveOption}
          iconsToShow={iconsToShow}
          toggleSideBars={toggleSideBars}
          parentRef={parentRef}
          newUPVMode={props.newUPVMode}
        />
      )}
      <SmartViewTab
        moveTop
        forSecondaryMenu={forSecondaryMenu}
        isLibrary={isLibrary}
        setIsShowFilter={props.setIsShowFilter}
        smartViewModal={smartViewModal}
        toggleSideBars={toggleSideBars}
        setShowNewSmartView={props.setShowNewSmartView}
        resetSmartview={props.resetSmartview}
        setSmartViewForEdit={props.setSmartViewForEdit}
        smartViewForEdit={props.smartViewForEdit}
        newUPVMode={props.newUPVMode}
        setChosenSmartView={props.setChosenSmartView}
      />
      {isPage && !forSecondaryMenu && (
      <Tooltip
        text={t('settingsT')}
        moveTop
        direction={isLibrary ? 'up' : 'right'}
        place={isLibrary ? 'LibraryTabsTag' : 'PlaylistTabs'}
      >
        <SideBarTab
          forSecondaryMenu
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          data-place="library"
          isLibrary={isLibrary}
          color={defColor}
          onClick={onSettingsClick}
          isActive={
            isShowLibraryWidget
            && isSettings
          }
          ref={parentRef}
          newUPVMode={props.newUPVMode}
        >

          <SettingsSvg
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            data-place="library"
          />
        </SideBarTab>
      </Tooltip>
      )}

      <div
        className={cn('inner_radius', 'bottom', { active: props.showLibrary })}
      />
    </div>
  );
};

export default SideBarTabs;
