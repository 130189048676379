import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import moduleStyles from '../../pages/Library/LibraryHeader.module.scss';
import { Tag } from '../Tag';
import { DATA_PARENTS, empty } from '../../utils/constants';
import { ReactComponent as CloseSvg } from '../../images/icons/close_28.svg';
import { ReactComponent as MoreSvg } from '../../images/icons/icon_20/more_20.svg';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import TagListDropOptionsColorPalette from '../../pages/Playlist/TagListDropOptionsColorPalette';
import { checkTagExists } from '../../utils/helpers';
import { actionCreator } from '../../shared/redux/actionHelper';
import { Tags } from '../../redux/tags/types';
import { actionChangeTagColor } from '../../redux/tags/action';
import ColorPalette from '../ColorPicker/ColorPalette';

const styles = classNames.bind(moduleStyles);
const CloseWrapper = styled.div`
  margin-left: 20px;
  cursor: pointer;
`;
const Border = styled.div`
  border: 1px solid #e8ebfa;
  border-radius: 8px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 7px;
`;
const ShellTagMenuInLibrary = styled.div`
  margin-left: 30px;

  display: flex;
  align-items: center;
`;
const TagMenuInLibrary = () => {
  const { selector: tagId } = useParams();
  const history = useHistory();
  const [refMenu, isComponentVisible, setIsComponentVisible] = useComponentVisible(false);

  const { tags } = useSelector((state) => state.user);
  const tag = tags[tagId] || empty;
  const closeHandler = () => history.push('/content/library/all');

  const dispatch = useDispatch();
  const [refColorPickerParent, isPaletteActive, setIsPaletteActive] = useComponentVisible(false, DATA_PARENTS.PlaylistBuilderLibrary);
  const [hasError, setHasError] = useState(false);
  const { tags: userTags } = useSelector((redux) => redux.user) || empty;

  const [localTitle, setLocalTitle] = useState(tag.title || '');

  useEffect(() => {
    setLocalTitle(tag.title);
  }, [tag.title]);

  useEffect(() => {
    if (!tags[tagId]) history.push('/content/library/all');
  }, [tags]);

  const saveNewTitle = (e) => {
    e.currentTarget.blur();
    if (!localTitle.trim()) {
      setHasError('Please type a value');
    } else if (checkTagExists(userTags, localTitle.trim())) {
      setHasError('This tag already exists');
    } else {
      dispatch(
        actionCreator(Tags.ChangeTagTitle, {
          id: tag.id,
          newTitle: localTitle.trim(),
        }),
      );
    }
  };

  const onTitleKeyDown = (event) => {
    if (event.key === 'Enter') {
      saveNewTitle(event, tag.id);
    }
  };
  const changeItemColor = (e, newColor, mode) => {
    if (mode === 'Text') {
      dispatch(
        actionChangeTagColor({
          id: tag.id,
          newColor,
        }),
      );
    } else {
      dispatch(
        actionChangeTagColor({
          id: tag.id,
          newBackgroundColor: newColor,
        }),
      );
    }
    e.stopPropagation();
  };

  return (
    <ShellTagMenuInLibrary>
      <Border>
        <div className={styles('sideBarLib__header_tabs_container')}>
          <div className={styles('item_tag')}>
            <Tag
              color={tag.color}
              backgroundColor={tag.backgroundColor}
              id={tag.id}
            >
              <div
                className={`text ${
                  tag.color === tag.backgroundColor ? 'stroke' : ''
                }`}
              >
                {tag.title}
              </div>
            </Tag>
          </div>
        </div>
        <div className={styles('options_wrapper')}>
          <ColorPalette
            selectedColor={tag.color}
            selectedBackgroundColor={tag.backgroundColor}
            refColorPickerParent={refColorPickerParent}
            onChangeColor={changeItemColor}
            isPaletteActive={isPaletteActive}
            setIsPaletteActive={setIsPaletteActive}
            dataParent={DATA_PARENTS.PlaylistBuilderLibrary}
            element={(
              <TagListDropOptionsColorPalette
                setIsOptionVisible={setIsPaletteActive}
                title={localTitle}
                saveTitle={saveNewTitle}
                onTitleKeyDown={onTitleKeyDown}
                onTitleChange={setLocalTitle}
                hasError={hasError && tag.title !== localTitle}
                errorText={hasError}
                setHasError={setHasError}
                itemId={tag.id}
                dataParent={DATA_PARENTS.PlaylistBuilderLibrary}
                isLibrarySideBarTags
              />
            )}
          >
            <div
              data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
              ref={refMenu}
              className={styles('icon_wrapper', { active: isPaletteActive })}
            >
              <MoreSvg />
            </div>
          </ColorPalette>
        </div>
      </Border>
      <CloseWrapper onClick={closeHandler}>
        <CloseSvg />
      </CloseWrapper>
    </ShellTagMenuInLibrary>
  );
};

export default TagMenuInLibrary;
