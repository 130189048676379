import React, { useState, useEffect } from 'react';
import { Knob, TogglerContainer, TopWrapper } from './styled';


const Toggler = ({
  callback,
  isActive,
  togglerWidth = 22,
  togglerHeight = 11,
  top = 'initial',
  relative,
  disabled,
  ...props
}) => {
  const [knobStyle, setKnobStyle] = useState({
    width: togglerHeight + 2,
    height: togglerHeight + 2,
  });

  useEffect(() => {
    if (!isActive) setKnobStyle({ ...knobStyle, left: -1 });
    else setKnobStyle({ ...knobStyle, left: togglerWidth - togglerHeight - 1 });
  }, [isActive]);

  return (
    <TopWrapper
      relative={relative}
      style={{ top }}
      onClick={() => callback(!isActive)}
      data-parent={props.dataParent}
    >
      <TogglerContainer
        isActive={isActive}
        disabled={disabled}
        style={{
          width: togglerWidth + 2,
          height: togglerHeight + 2,
          borderRadius: togglerHeight + 2,
        }}
        data-parent={props.dataParent}
      >
        <Knob isActive={isActive} style={knobStyle} />
      </TogglerContainer>
    </TopWrapper>
  );
};

export default Toggler;
