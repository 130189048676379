export const calculateSelectorAndSlider = (
  activeNavSlider,
  selectorType,
  params,
  isEdit,
  ignoreSelectorType,
) => {
  let calcSelectorType;
  let calcActiveNavSlider;
  if (selectorType.includes('tag_')) {
    calcSelectorType = selectorType.split('_')[1];
    calcActiveNavSlider = 'tag';
  } else {
    calcSelectorType = activeNavSlider === 'dynamicCollectionNew' || isEdit
      ? params
      : selectorType;
    calcActiveNavSlider = isEdit ? 'dynamicCollectionNew' : activeNavSlider;
    if (ignoreSelectorType) {
      calcSelectorType = 'all';
    }
  }
  return { calcActiveNavSlider, calcSelectorType };
};
