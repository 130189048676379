import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { extractIFrameSrc } from '../../../utils/DOM';

const LinkPreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 180px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  cursor: pointer;
  
  ${({ isExpandedView }) => (isExpandedView ? '' : '  width: 600px;')}
  ${({ isIframe }) => (isIframe
    ? `
  border:none;
  display:block;
    iframe{
    border:none;
    width: 960px;
    height: 100vh;
    zoom: 0.6;
    position: relative;

  }
  `
    : '')}
  ${({ height }) => (height
    ? `
        iframe{
   height: ${height / 0.6}px;
  }
  `
    : '')};
  ${({ customStyle }) => (customStyle ?? '')};
  ${({ transformed }) => (transformed ? `
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.25);
    max-height: calc(80vh - 200px);
    transition: top 0.2s;
  ` : `
    transform: none;
    max-height: none;
    transition: top 0.2s;
  `)}
`;

const LinkImg = styled.img`
  width: 140px;
  height: 140px;
  border: 3px solid #ffffff;
  border-radius: 4px;
  object-fit: contain;
  margin-right: 20px;
`;

const LinkText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  overflow-wrap: break-word;
`;

const Title = styled.div`
  font-weight: 700;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #484343;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #343434;
  margin-top: 10px;
`;

const Url = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #939393;
  margin-top: 8px;
`;

const LinkPreview = ({ item, isExpandedView = false, customStyle, isGalleryOpen }) => {
  const itemIFrameSrc = extractIFrameSrc(item.linkUrl);
  const [height, setHeight] = useState(null);
  const customStyleForLink = {
    top: customStyle?.top,
    left: customStyle?.left,
    height: customStyle?.height,
    position: customStyle?.position,
  };
  useEffect(() => {
    if (item.linkUrl) {
      const iframeString = item.linkUrl;
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = iframeString;
      if (tempDiv.firstChild?.getAttribute) {
        const heightValue = tempDiv.firstChild?.getAttribute('height');
        const heightNumber = parseInt(heightValue, 10);
        tempDiv.remove();
        setHeight(heightNumber);
      }
    }
  }, [item]);

  const goToLink = (e, isEmbed = false) => {
    e.preventDefault();
    e.stopPropagation();

    if (item.linkUrl) {
      const linkToGo = itemIFrameSrc || item.linkUrl;
      const newWindow = window.open(linkToGo, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  };
  if (item.urlFile && item.urlFile !== 'undefined') {
    return (
      <LinkPreviewWrapper
        height={height}
        onClick={goToLink}
        isExpandedView={isExpandedView}
      >
        <LinkImg src={item.urlFile} />
        <LinkText>
          <Title>{item.title}</Title>
          {!!item.description && <Description>{item.description}</Description>}
          <Url>{item.linkUrl}</Url>
        </LinkText>
      </LinkPreviewWrapper>
    );
  }

  if (itemIFrameSrc) {
    return (
      <LinkPreviewWrapper
        height={height}
        isExpandedView={isExpandedView}
        isIframe
      >
        <iframe src={itemIFrameSrc} scrolling="no" />
        <LinkText onClick={goToLink}>
          <Title>{item.title}</Title>
          {!!item.description && <Description>{item.description}</Description>}
        </LinkText>
      </LinkPreviewWrapper>
    );
  }
  return (
    <LinkPreviewWrapper
      height={height}
      onClick={goToLink}
      isExpandedView={isExpandedView}
      style={customStyle?.top ? customStyleForLink : {}}
      transformed={isGalleryOpen && !!customStyle?.top}
    >
      <LinkImg src={item.urlFile} />
      <LinkText>
        <Title>{item.title}</Title>
        {!!item.description && <Description>{item.description}</Description>}
        <Url>{item.linkUrl}</Url>
      </LinkText>
    </LinkPreviewWrapper>
  );
};

export default LinkPreview;
