import { put, select } from "@redux-saga/core/effects";

import { call } from "redux-saga/effects";
import { actionShowMessage } from "../redux/support/action";
import {
  APIGetPlaylistSharing,
  errorType,
  MessageType,
  SOCKET_EVENT_TYPE,
} from '../utils/constants';
import { requestErrorReport, requestPlaylist } from "../utils/request";
import axios from "axios";
import { actionSwitchPage } from "../redux/currentPage/action";
import { actionCreator } from "../shared/redux/actionHelper";
import SupportAction from "../redux/support/types";
import downloadStatus from "../utils/dataUtil";
import { RolesForPlaylist } from "../utils/permissions";
import draftDataConvertToLoad from "../utils/draftDataConvert";
import { sanitizeToLoad } from "../utils/helpers";
import { parseLibraryComponent } from "./helpers";
import { ServiceUser } from "../redux/user/types";
import { sendMessage } from './SocketCluster/action';
import convertDraftStateToLexicalState from '../utils/convertDraftStateToLexicalState';
import {smartFileItemTypeCheck} from "../shared/smartFile/constant";

export function* showErrorMessage(e, action) {
  try {
    console.error(e);
    console.error(action);
    // eslint-disable-next-line no-debugger
    if (localStorage.getItem("developModDebugger")) {
      debugger;
    }
    yield call(requestErrorReport, {
      "Error message": e,
      description: {
        type: errorType.parseError,
        ...e,
        path: window.location?.href,
        message: e.message,
        name: e.name,
        stack: e.stack,
      },
      actionType: action?.type,
      payload: action?.payload,
    });
  } catch (err) {
    console.error(err);
  }
  yield put(actionShowMessage({ type: MessageType.Error }));
}

export function* calculateUpdatePlaylistDurationTime() {
  // let durationTime = 0;
  // const currentPlaylist = yield select(getCurrentPage);
  // durationTime += calculateInfoDurationTime(currentPlaylist);
  // if (currentPlaylist.durationTimePage) {
  //   durationTime += Object.values(currentPlaylist.durationTimePage).reduce((acc, i) => acc + i, 0);
  // }
  // yield put(actionUpdateTime(durationTime));
}

export const getUser = (state) => state.user;
export const getSettings = (state) => state.settings;
export const getDynamicCollections = (state) => state.dynamicCollection;
export const currentPage = (state) => state.currentPage;
export const getHelp = (state) => state.help;
export const getLessons = (state) => state.lessons;
export const getCurrentPage = (state) => state.currentPage;
export const getDashboardColumns = (state) => state.dashboardColumns;
export const getLibrary = (state) => state.library;
export const getLibraryComponent = (state) => state.content.contentData;
export const getLibraryComponentByIDsArr = (ids) => (state) =>
  ids.map((id) => state.content.contentData[id]);
export const getContentData = (state) => state.content.contentData;
export const getAllSortOptions = (state) => state.settings.sortOptions;
export const getActiveNavSliderAndSelectorType = (state) => ({
  selectorType: state.content.selectorType,
  activeNavSlider: state.content.activeNavSlider,
});
export const getTags = (state) => state.currentPage.tags;
export const getUserTags = (state) => state.user.tags;
export const getLocalStateTags = (state) => state.support.tagsToUpdate;
export const getSidebarContacts = (state) => state.support.contactNicknameMap;
export const getSupportPlaylists = (state) => state.playlists;
export const getMyChannels = (state) => state.channels.myChannels;
export const getChannels = (state) => state.channels;

export const getBlocks = (state) => state.currentPage.blocks || [];
export const getSupportBlocks = (state) => state.support.blocks || [];
export const getBlocksPlaylist = (state) =>
  state.currentPage.additionalInformation || [];
export const getDraggableBlocks = (state) =>
  state.draggable.draggableBlocks || [];
export const getPages = (state) => state.pages;
export const getMiniPages = (state) => state.miniPages;
export const getCurrentPageLinkPages = (state) =>
  state.currentPage?.linkPages || [];
export const getLibraryComponents = getLibraryComponent;
export const getLibraryCollections = (state) => state.library.collections;
export const getSelectedPage = (state) => state.selectedPage;
export const getSupport = (state) => state.support;
export const getUploads = (state) => state.uploads.components;
export const getHistoryTrace = (state) => state.historyTrace;

export const parseLinkPages = (arr, isViewMode, users) =>
  arr.reduce((acc, item) => {
    if (isViewMode && (item?.type === "placeholder" || item.type === null))
      return acc;
    if (item?.type === "text") return acc;
    if (item?.textComponent && item?.textComponent[0]) {
      const [textComponent] = item.textComponent;
      if (!textComponent) return acc;
      const state = draftDataConvertToLoad(textComponent.content);
      const { id, position, type } = item;
      acc[item.id] = {
        ...item,
        id,
        title: sanitizeToLoad(item.title),
        caption: sanitizeToLoad(item.caption),
        isShowCaption: item.isShowCaption === true,
        isRemixLocked: item.isRemixLocked === true,
        position,
        type,
        interactiveItemData:
          item.interactiveItemData &&
          JSON.parse(sanitizeToLoad(item.interactiveItemData)),

        textComponent: {
          ...textComponent,
          state,
          innerHtml: sanitizeToLoad(textComponent?.innerHtml),
          type: textComponent.type,
        },
      };
      if (isViewMode)
        acc[item.id].isRead = !!item?.mainUserInterface?.userView?.length;
      if (isViewMode && acc[item.id].interactiveItemData) {
        acc[item.id].interactiveItemData.isApprove =
          !!item?.mainUserInterface?.userAnswer?.length;
      }
    } else if (item.libraryComponent.length) {
      const libraryComponent = parseLibraryComponent(item.libraryComponent);
      acc[item.id] = {
        ...item,
        title: sanitizeToLoad(item.title),
        caption: sanitizeToLoad(item.caption),
        isShowCaption: item.isShowCaption === true,
        isRemixLocked: item.isRemixLocked === true,
        type: item.type,
        interactiveItemData:
          item.interactiveItemData &&
          JSON.parse(sanitizeToLoad(item.interactiveItemData)),
        libraryComponent: {
          ...libraryComponent,
          owner: {
            id: users.id,
            email: users.email,
          },
        },
      };
      if (isViewMode)
        acc[item.id].isRead = !!item?.mainUserInterface?.userView?.length;
      if (isViewMode && acc[item.id].interactiveItemData) {
        acc[item.id].interactiveItemData.isApprove =
          !!item?.mainUserInterface?.userAnswer?.length;
      }
    } else if (item.libraryComponent) {
      const libraryComponent = parseLibraryComponent([item.libraryComponent]);
      acc[item.id] = {
        ...item,
        title: sanitizeToLoad(item.title),
        caption: sanitizeToLoad(item.caption),
        isShowCaption: item.isShowCaption === true,
        isRemixLocked: item.isRemixLocked === true,
        type: item.type,
        interactiveItemData:
          item.interactiveItemData &&
          JSON.parse(sanitizeToLoad(item.interactiveItemData)),
        libraryComponent: {
          ...libraryComponent,
          owner: {
            id: users.id,
            email: users.email,
          },
        },
      };
      if (isViewMode)
        acc[item.id].isRead = !!item?.mainUserInterface?.userView?.length;
      if (isViewMode && acc[item.id].interactiveItemData) {
        acc[item.id].interactiveItemData.isApprove =
          !!item?.mainUserInterface?.userAnswer?.length;
      }
    } else {
      acc[item.id] = {
        ...item,
        title: sanitizeToLoad(item.title),
        caption: sanitizeToLoad(item.caption),
        isShowCaption: item.isShowCaption === true,
        isRemixLocked: item.isRemixLocked === true,
        interactiveItemData:
          item.interactiveItemData &&
          JSON.parse(sanitizeToLoad(item.interactiveItemData)),
      };
      if (isViewMode)
        acc[item.id].isRead = !!item?.mainUserInterface?.userView?.length;
      if (isViewMode && acc[item.id].interactiveItemData) {
        acc[item.id].interactiveItemData.isApprove =
          !!item?.mainUserInterface?.userAnswer?.length;
      }
    }
    return acc;
  }, {});

export const mapLinkPages = (arr) =>
  arr.map((lp) => {
    const owner = lp.owner ? lp.owner : {}
    let toBeReturned;
    if (lp?.type === "placeholder" || lp.type === null) return lp;
    if (lp?.type === "text") return lp;
    if (lp?.textComponent && lp?.textComponent[0]) {
      const [textComponent] = lp.textComponent;
      if (!textComponent) return lp;
      let state

      try {
        state = textComponent && (!smartFileItemTypeCheck.isLexicalText[lp?.type]
          ? convertDraftStateToLexicalState(draftDataConvertToLoad(textComponent?.content))
          : JSON.parse(sanitizeToLoad(textComponent?.content)));
      } catch (e) {
        state = '';
      }
      const { id, position, type } = lp;

      toBeReturned = {
        ...lp,
        id,
        title: sanitizeToLoad(lp.title),
        caption: sanitizeToLoad(lp.caption),
        isShowCaption: lp.isShowCaption === true,
        isRemixLocked: lp.isRemixLocked === true,
        position,
        type,
        // type:'elementTitle',
        isViewed:!!lp.mainUserInterface?.userView?.length,
        interactiveItemData:
          lp.interactiveItemData &&
          JSON.parse(sanitizeToLoad(lp.interactiveItemData)),

        textComponent: {
          ...textComponent,
          state,
          innerHtml: sanitizeToLoad(textComponent?.innerHtml),
          type: textComponent.type,
        },
      };
      if (toBeReturned.interactiveItemData) {
        toBeReturned.interactiveItemData.isApprove =
          !!lp?.mainUserInterface?.userAnswer?.length;
      }
    } else if (lp.libraryComponent.length) {
      const libraryComponent = parseLibraryComponent(lp.libraryComponent);
      toBeReturned = {
        ...lp,
        title: sanitizeToLoad(lp.title),
        caption: sanitizeToLoad(lp.caption),
        isShowCaption: lp.isShowCaption === true,
        isRemixLocked: lp.isRemixLocked === true,
        type: lp.type,
        isViewed:!!lp.mainUserInterface?.userView?.length,
        interactiveItemData:
          lp.interactiveItemData &&
          JSON.parse(sanitizeToLoad(lp.interactiveItemData)),
        libraryComponent: {
          ...libraryComponent,
          owner: {
            id: owner.id,
            email: owner.email,
          },
        },
      };
      if (toBeReturned.interactiveItemData) {
        toBeReturned.interactiveItemData.isApprove =
          !!lp?.mainUserInterface?.userAnswer?.length;
      }
    } else if (lp.libraryComponent) {
      const libraryComponent = parseLibraryComponent([lp.libraryComponent]);
      toBeReturned = {
        ...lp,
        isViewed:!!lp.mainUserInterface?.userView?.length,
        title: sanitizeToLoad(lp.title),
        caption: sanitizeToLoad(lp.caption),
        isShowCaption: lp.isShowCaption === true,
        isRemixLocked: lp.isRemixLocked === true,
        type: lp.type,
        interactiveItemData:
          lp.interactiveItemData &&
          JSON.parse(sanitizeToLoad(lp.interactiveItemData)),
        libraryComponent: {
          ...libraryComponent,
          owner: {
            id: owner.id,
            email: owner.email,
          },
        },
      };
      if (toBeReturned.interactiveItemData) {
        toBeReturned.interactiveItemData.isApprove =
          !!lp?.mainUserInterface?.userAnswer?.length;
      }
    } else {
      toBeReturned = {
        ...lp,
        title: sanitizeToLoad(lp.title),
        caption: sanitizeToLoad(lp.caption),
        isViewed:!!lp.mainUserInterface?.userView?.length,
        isShowCaption: lp.isShowCaption === true,
        isRemixLocked: lp.isRemixLocked === true,
        interactiveItemData:
          lp.interactiveItemData &&
          JSON.parse(sanitizeToLoad(lp.interactiveItemData)),
      };
      if (toBeReturned.interactiveItemData) {
        toBeReturned.interactiveItemData.isApprove =
          !!lp?.mainUserInterface?.userAnswer?.length;
      }
    }
    return toBeReturned;
  });


export function* getPlaylistResolver({
  id,
  type = "edit",
  accessCode,
  channelID,
  isViewAsCoEdit,
}) {
  const token = sessionStorage.getItem("jwt") || localStorage.getItem("jwt");
  let role = RolesForPlaylist.viewer;
  let shareState;
  let singleUserShareState = {};
  let data;
  let playlist;
  let sharedAvailableTo;
  let sharedAvailableFrom;
  let extenededManager;
  let owner;
  let isFavorite;
  let linkPages;
  let wrapperId;
  let isContentEditable;
  let isCanCoEdit;
  let idForCoEdit;
  let isFinished;
  const { id: userId } = yield select(getUser);

  let replacedType = type;
  if (type === "draft") {
    replacedType = "edit";
  }
  if (type === "channel") {
    replacedType = "edit";
  }
  if (replacedType === "shared") {
    try {
      const response = yield call(axios, {
        method: "get",
        url: `${APIGetPlaylistSharing}/${id}`,
        headers: {
          accessCode: accessCode ? `${accessCode}` : "",
          token: token ? `${token}` : "",
        },
      });
      isContentEditable = response.data.isCoEdit;
      role = response.data.role;
      data = response.data.data;

      sharedAvailableTo = response.data.sharedAvailableTo;
      sharedAvailableFrom = response.data.sharedAvailableFrom;
      isCanCoEdit = response.data.isCoEdit;
      idForCoEdit = response.data.data?.Playlist[0]?.id;
      isFinished = response.data.isFinished;
      // loadSharedAvailableTo = response.data.sharedAvailableTo;
    } catch (e) {
      if (e.response?.status === 403) {
        if (e.response?.data?.message === "need accessCode") {
          yield put(actionSwitchPage({ shareState: { needAccessCode: true } }));
          yield put({
            type: "PAGE_IS_DOWNLOAD",
            payload: { isDownload: false },
          });
          return;
        }
        if (e.response?.data?.message === "wrong accessCode") {
          yield put(
            actionSwitchPage({
              shareState: { needAccessCode: true, wrongAccessCode: true },
            }),
          );
          yield put({
            type: "PAGE_IS_DOWNLOAD",
            payload: { isDownload: false },
          });
          return;
        }
        if (e.response?.data?.message === "need login") {
          yield put(actionSwitchPage({ shareState: { needLogin: true } }));
          yield put({
            type: "PAGE_IS_DOWNLOAD",
            payload: { isDownload: false },
          });
          return;
        }
        if (e.response?.data?.message === "wrong user") {
          yield put(actionSwitchPage({ shareState: { wrongUser: true } }));
          yield put({
            type: "PAGE_IS_DOWNLOAD",
            payload: { isDownload: false },
          });
          yield put(
            actionCreator(ServiceUser.goToLink, {
              goToLink: "/playlists",
              paramsGoToLink: {
                isGoOut: true,
                ...data,
              },
            }),
          );
          yield put(
            actionShowMessage({
              type: MessageType.RemoveAccess,
              itemName: "playlist",
            }),
          );

          return;
        }

        if (e.response?.data?.message === "wrong time") {
          const {
            sharedAvailableTo,
            sharedAvailableFrom,
            sharedIsAvailableRange,
            readId,
          } = e.response?.data;
          const shareState = {
            availableTo: sharedAvailableTo,
            availableFrom: sharedAvailableFrom,
            isAvailableRange: sharedIsAvailableRange,
          };
          yield put(
            actionCreator(ServiceUser.goToLink, {
              goToLink: "/playlists",
              paramsGoToLink: {
                isGoOut: true,
                ...data,
              },
            }),
          );
          yield put(
            actionShowMessage({
              type: MessageType.RemoveAccess,
              itemName: "playlist",
            }),
          );
          yield put(
            actionCreator(SupportAction.ReadOneUnseenPlaylist, { id: readId }),
          );

          return;
        }
      } else {
        throw e;
      }
      return;
    }
    playlist = data.Playlist[0];
    owner = playlist.editManager.users[0];
    isFavorite = !!playlist.editManager.favorites?.length;
    linkPages = data.Playlist[0].linkPages;
    wrapperId = playlist.editManager.id;
    const usersToSharing = playlist.editManager?.usersToSharing
    const usersInviteToSharing = playlist.editManager?.usersInviteToSharing

    const usersInviteToSharingNode = usersInviteToSharing.edges?.map(i => ({
      ...i.node, isCoEdit: i.isCoEdit, coEditorIndex: i.coEditorIndex,
    })) || usersInviteToSharing;

    const usersToSharingNode = usersToSharing.edges?.map(i => ({
      ...i.node, isCoEdit: i.isCoEdit, coEditorIndex: i.coEditorIndex,
    })) || usersToSharing;

    usersInviteToSharingNode?.forEach(i => singleUserShareState[i.email] = i);
    usersToSharingNode?.forEach(i => singleUserShareState[i.email] = i);

  } else {
    try {
      const response = yield call(requestPlaylist(replacedType, id), [], {
        channelID,
        isViewAsCoEdit,
      });
      // let dataLink;
      // try {
      //   const { data: link } = yield call(axios, {
      //     method: "get",
      //     url: `${APIGetSharedPlaylistUrl}/${id}`,
      //     headers: {
      //       token: token ? `${token}` : "",
      //     },
      //   });
      //   dataLink = link;
      // } catch (e) {
      //   dataLink = {};
      // }
      playlist = (response.data.data.EditPlaylist || response.data.data.editPlaylist || response.data.data.JustCreatedEdit)[0];
      role = response.data.role || RolesForPlaylist.viewer;
      extenededManager = playlist.manager || playlist.manager2;

      owner = extenededManager.users[0];
      isFavorite = !!extenededManager.favorites?.length;
      linkPages = playlist.linkPages;
      wrapperId = extenededManager.id;
      isContentEditable = owner.id === userId;
      isCanCoEdit = !!extenededManager.usersToSharing?.length;
      idForCoEdit = extenededManager?.editPlaylist?.id;
      isFinished = response.data.isFinished || response.isFinished;
      // need fix
      // console.log( 'saga ->', isCanCoEdit);
      // console.log( 'saga ->', extenededManager.usersToSharing);

      const {
        sharedAvailableFrom,
        usersToSharing,
        usersInviteToSharing,
        sharedAvailableTo,
        dateRangeMark,
        sharedIsAvailableRange,
        isShareToWeb,
        isNeedAccess,
        isCoEdit,
        accessCode,
      } = extenededManager;
      const {
        isAnyCanComment,
        isAnyCanEdit,
        isMakeCollaborate,
        isReused,
        isAvailableRange,
      } = playlist;

      const usersToSharingNode = usersToSharing.edges?.map(i => ({
        ...i.node, isCoEdit: i.isCoEdit, coEditorIndex: i.coEditorIndex,
      })) || usersToSharing;

      const usersInviteToSharingNode = usersInviteToSharing.edges?.map(i => ({
        ...i.node, isCoEdit: i.isCoEdit, coEditorIndex: i.coEditorIndex,
      })) || usersInviteToSharing;

      shareState = {
        dateRangeMark,
        isAnyCanComment,
        isAnyCanEdit,
        isMakeCollaborate,
        isReused,
        link: wrapperId,
        isShareToWeb,
        isNeedAccess,
        accessCode,
        isCoEdit,
        isAvailableRange,
        sharedAvailableFrom,
        sharedAvailableTo,
        usersToSharing: [
          ...(usersToSharingNode?.map((i) => ({
            ...i,
            send: i.send ? downloadStatus.success : "",
          })) || []),
          ...(usersInviteToSharingNode?.map((i) => ({
            ...i,
            send: i.send ? downloadStatus.success : "",
          })) || []),
        ],
        sharedIsAvailableRange,
      };
      usersInviteToSharingNode.forEach(i=>singleUserShareState[i.email]=i)
      usersToSharingNode.forEach(i=>singleUserShareState[i.email]=i)
    } catch (e) {
      if (e.response?.status === 403) {
        if (e.response?.data?.message === "need accessCode") {
          yield put(actionSwitchPage({ shareState: { needAccessCode: true } }));
          yield put({
            type: "PAGE_IS_DOWNLOAD",
            payload: { isDownload: false },
          });
          return;
        }
        if (e.response?.data?.message === "wrong accessCode") {
          yield put(
            actionSwitchPage({
              shareState: { needAccessCode: true, wrongAccessCode: true },
            }),
          );
          yield put({
            type: "PAGE_IS_DOWNLOAD",
            payload: { isDownload: false },
          });
          return;
        }
        if (e.response?.data?.message === "need login") {
          yield put(actionSwitchPage({ shareState: { needLogin: true } }));
          yield put({
            type: "PAGE_IS_DOWNLOAD",
            payload: { isDownload: false },
          });
          return;
        }
        if (e.response?.data?.message === "wrong user") {
          yield put(actionSwitchPage({ shareState: { wrongUser: true } }));
          yield put({
            type: "PAGE_IS_DOWNLOAD",
            payload: { isDownload: false },
          });
          return;
        }

        if (e.response?.data?.message === "wrong time") {
          const {
            sharedAvailableTo,
            sharedAvailableFrom,
            sharedIsAvailableRange,
            readId,
          } = e.response?.data;
          const shareState = {
            availableTo: sharedAvailableTo,
            availableFrom: sharedAvailableFrom,
            isAvailableRange: sharedIsAvailableRange,
          };
          yield put(
            actionSwitchPage({
              id,
              shareState,
              notAvailable: true,
            }),
          );
          yield put({
            type: "PAGE_IS_DOWNLOAD",
            payload: { isDownload: false },
          });
          yield put(
            actionCreator(SupportAction.ReadOneUnseenPlaylist, { id: readId }),
          );

          return;
        }
      } else {
        throw e;
      }
      return;
    }
  }

  return {
    data,
    playlist,
    shareState,
    singleUserShareState,
    extenededManager,
    wrapperId,
    owner,
    isFavorite,
    isContentEditable,
    isCanCoEdit,
    idForCoEdit,
    linkPages,
    sharedAvailableTo,
    sharedAvailableFrom,
    role,
    isFinished
  };
}

export function* sendEventUpdate({type="smartfile", users:providedUsers}) {

  const currentPageData = yield select(currentPage);
  const channels = Object.values(currentPageData?.usedInChannels || {}).map(i=>i.id) || []
  const users = providedUsers || Object.values(currentPageData?.singleUserShareState ||{}).map(i=>i.id) || []
  yield put(
    sendMessage({
      dataSend: {
        eventType: SOCKET_EVENT_TYPE.UPDATE_NOTIFICATION,
        itemId:currentPageData.id,
        typeUpdatedItem:type,
        wrapperId: currentPageData.wrapperId,
        channels,
        users,
      },
      isNotificationUpdate:true,
      channels,
      users,
    }),
  );

}
export function findCommonNotification(newNotificationObj, existNotificationArr) {
  const idMap = {};
  existNotificationArr.forEach(item=>{
    idMap[item.id]=true
  })
  const idsForUpdateNotification = {};
  const notExist = {};

  Object.values(newNotificationObj).forEach(obj=> {
    if (idMap[obj.id] || idMap[obj.itemId]|| idMap[obj.wrapperId] || idMap[obj.playlist?.id]) {
      idsForUpdateNotification[obj.id] = true;
    } else {
      const id = obj.id || obj.wrapperId
      notExist[id] = true
    }
  })

  return { idsForUpdateNotification, notExist };
}
