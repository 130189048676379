import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_TIME_ZONES } from '../../../../utils/SettingsHelpers/helpers';
import { ReactComponent as ChevronDownSvg } from '../../../../images/icons/chevron_down_12.svg';
import { ReactComponent as SearchSvg } from '../../../../images/icons/search.svg';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';
import useComponentVisible from '../../../../utils/hooks/useComponentVisible';
import { DATA_PARENTS } from '../../../../utils/constants';
import ChevronContainer from './chevronContainer';

const SelectorShell = styled.div`
  position: relative;
  ${({ isActive }) => (isActive ? 'z-index: 5;' : '')}
  width:276px;
  padding: 3px 3px 3px 10px;
  border-radius: 6px;
  border: 0.6px solid #ECE2E2;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(122, 105, 105, 0.10);
  display: flex;
  align-items: center;
  :hover {
    border-radius: 6px;
    border: 0.6px solid #FFC72C;
    background: #FFEEBF;
    box-shadow: 0px 1px 4px 0px rgba(122, 105, 105, 0.10);
    cursor: pointer;
  }
`;

const Selector = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #596080;
  z-index: 4;
`;

const DropDown = styled.div`
  top: 35px;
  left: -179px;
  position: absolute;
  overflow: auto;
  width: 442px;
  height: 330px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding-bottom: 8px;
  padding-top: 15px;
  z-index: 1;
  overflow: hidden;
`;

const DropDownHeader = styled.div`
  padding: 0 16px;
  color: #D3C8C8;
  font-size: 12px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 12px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

const DropDownSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  svg {
    position: absolute;
    right: 22px;
    top: 47px;
  }
`;

const Input = styled.input`
  padding: 3px 8px;
  top: 12px;
  left: 16px;
  position: relative;
  background: none;
  width: 412px;
  border: 0;
  color: #484343;
  font-family: RobotoFlex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.006em;
  text-align: left;
  border-radius: 6px;
  border: 1px solid #ECE2E2;

  &:focus {
    border: 1px solid #ffc72c;
  }

  &::placeholder {
    color: rgba(122, 105, 105, 0.50);
    font-size: 16px;
    letter-spacing: -0.006em;
  }
`;

const DropListItem = styled.div`
  height: 32px;
  line-height: 24px;
  padding: 5px 16px;
  margin-bottom: -2px;
  &:hover {
    background: #FFEEBF;
  }
  ${({ isActive }) => (isActive ? 'background: #FFC72C;' : '')}
`;

const TimeZoneSelector = () => {
  const { t } = useTranslation();
  const [menuRef, isDropMenuActive, setIsDropMenuActive] = useComponentVisible(
    false,
    DATA_PARENTS.TimeZoneSelector,
  );
  const {
    inputs: { timeZone },
  } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const parentRef = useRef(null);
  const [citiesTimeZone, setCitiesTimeZone] = useState('');
  const [filter, setFilter] = useState('');
  const [filteredZones, setFilteredZones] = useState('');

  const getCitiesForTimezone = (zoneOffset, count) => {
    const allCities = SUPPORTED_TIME_ZONES[zoneOffset];
    const cities = allCities.slice(0, count).filter((item) => !!item);
    return `(${cities.join(', ')}${allCities.length > count ? ' ... ' : ''})`;
  };

  useEffect(() => {
    if (timeZone.value) setCitiesTimeZone(getCitiesForTimezone(timeZone.value, 3));
  }, [timeZone.value]);

  useEffect(() => {
    const newZonesList = Object.keys(SUPPORTED_TIME_ZONES).filter((zone) => `
      ${zone} ${getCitiesForTimezone(zone, 4)}
      `.toLowerCase().includes(filter.toLowerCase()));
    setFilteredZones(newZonesList);
  }, [filter]);

  const pickNewTimeZone = (event, newValue) => {
    dispatch(
      actionCreator(Settings.Preferences.ChangeTimeZoneFormat, { newValue }),
    );
    setIsDropMenuActive(false);
    event.stopPropagation();
  };

  const openDropMenu = (e) => {
    e.preventDefault();
    setIsDropMenuActive(!isDropMenuActive);
  };

  return (
    <SelectorShell isActive={isDropMenuActive} onClick={openDropMenu}>
      <Selector ref={parentRef}>
        {`${timeZone.value} ${citiesTimeZone}`}
        <ChevronContainer
          data-parent={DATA_PARENTS.TimeZoneSelector}
          isLanguageTab
          isActive={isDropMenuActive}
          onMouseDown={(e) => e.preventDefault()}
        >
          <ChevronDownSvg data-parent={DATA_PARENTS.TimeZoneSelector} />
        </ChevronContainer>
        {isDropMenuActive && (
          <DropDown ref={menuRef}>
            <DropDownHeader>{t('timeZoneT')}</DropDownHeader>
            <DropDownSearchWrapper>
              <Input
                data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
                onClick={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
                placeholder={t('searchDotsPlaceholderT')}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              />
              <SearchSvg />
            </DropDownSearchWrapper>
            <PerfectScrollbar>
              {filteredZones.map((zone) => (
                <DropListItem
                  key={zone}
                  isActive={zone === timeZone.value}
                  onClick={(event) => pickNewTimeZone(event, zone)}
                >
                  {`${zone} ${getCitiesForTimezone(zone, 4)}`}
                </DropListItem>
              ))}
            </PerfectScrollbar>
          </DropDown>
        )}
      </Selector>
    </SelectorShell>
  );
};

export default TimeZoneSelector;
