import React, { useMemo } from 'react';
import { ReactComponent as CompletedSVG } from '../../images/2023/svg/small/complete/checked_20_20.svg';
import { PBEmptyStripe, PBFilledStripe, PBSVGWrapper, PBSVGWrapperPlayer, PBWrapper } from './styled';
import useViewersProgressMaker from '../../utils/hooks/useViewersProgressMaker';


const Player = ({ isFinished }) => {
  const { viewed = 0, total = 0 } = useViewersProgressMaker();
  const filledWidth = useMemo(() => (isFinished ? 304 : Math.round(viewed * 304 / total)), [viewed, total, isFinished]);
  if (total === 0) {
    return <></>;
  }
  const isCompleted = total === viewed || isFinished;

  return (
    <PBWrapper isPlayer>
      <PBEmptyStripe isPlayer>
        <PBFilledStripe width={filledWidth} isPlayer />
        {isCompleted && (
          <PBSVGWrapperPlayer>
            <CompletedSVG />
          </PBSVGWrapperPlayer>
        )}
      </PBEmptyStripe>
    </PBWrapper>
  );
};

export default Player;
