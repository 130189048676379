export const extractIFrameSrc = (url?: string): string | null => {
  if (!url) return null;
  const pattern = /src="([^"]*)"/;
  const match = url.match(pattern);

  if (match && match[1]) {
    return match[1];
  }
  return null;
};

export const setButtonsParentAttribute = (nodeId: string, dataParent: string) => {
  const componentNode = document.getElementById(nodeId);

  if (!componentNode) {
    return;
  }
  const allNodes = componentNode.querySelectorAll<HTMLElement>('*');

  allNodes.forEach(node => {
    node.setAttribute('data-parent', dataParent);
  });
};
