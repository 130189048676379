import React, { useRef } from 'react';
import MsContent from '../../../pages/LibraryComponent/MsContent';

const MSdocPreview = ({ item }) => {
  const imageRef = useRef(null);

  const goToLink = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (item.linkUrl) {
      const newWindow = window.open(
        item.linkUrl,
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  return (
    <div>
      <MsContent item={item} goToLink={goToLink} imageRef={imageRef} isPlayer />
    </div>
  );
};

export default MSdocPreview;
