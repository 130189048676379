export const DefaultDraftBlockRenderMap1 = {
  'header-one': 'title',
  'header-two': 'title2',
  'header-three': 'title3',
  'header-four': 'title3',
  'header-five': 'title3',
  'header-six': 'title3',
  'unordered-list-item': 'unordered-list-item',
  'ordered-list-item': 'ordered-list-item',
} as const;

export const limitNumberChapner = {
  text: 4000,
  title: 75,
  title2: 75,
  title3: 75,
  callout: 1000,
} as const;
