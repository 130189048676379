import React from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { ReactComponent as CloseSmallSvg } from '../../images/icons/close_7.svg';
import rawStyles from './index.module.scss';
import { Tag } from '../../components/Tag';
import { empty } from '../../utils/constants';

const styles = classnames.bind(rawStyles);

const TagItem = ({
  id,
  backgroundColor,
  title,
  deleteCb,
  index,
  isView,
  itemIndex,
  dataParent,
}) => {
  const allTags = useSelector((reduxState) => reduxState.user.tags || empty);
  const deleteTagItem = (e, deleteValue) => {
    deleteCb(e, deleteValue, index || itemIndex);
  };

  return (
    <Tag
      color={allTags[id]?.color}
      backgroundColor={backgroundColor || allTags[id]?.backgroundColor}
      data-parent={dataParent}
    >
      <div
        className={`text ${
          allTags[id] && allTags[id]?.backgroundColor === allTags[id]?.color
            ? 'stroke'
            : ''
        }`}
      >
        {title}
      </div>
      {!isView && (
        <div
          data-parent={dataParent}
          className={styles('icon')}
          onClick={(e) => deleteTagItem(e, id)}
        >
          <CloseSmallSvg data-parent={dataParent} />
        </div>
      )}
    </Tag>
  );
};

export default TagItem;
