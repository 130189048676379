import styled from 'styled-components';

const IconColored = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background-color: ${({ color }) => color?.background};
  }

  ${({ isActive, color }) => (isActive ? `background-color: ${color?.background};` : '')}

  & svg path {
    stroke: ${({ color }) => color?.color};
    fill: transparent;
  }
`;

export default IconColored;
