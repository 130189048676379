
export const ChannelPagesSchema = {
  tableName: 'ChannelPagesSchema',
  rowHeight: 48,
  hoverColor: '#E8EBFA',
  borderRadius: 4,
  activeColor: '#D7DDF7',
  fontStyles: `font-family: RobotoFlex;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #242633;
      `,
  backgroundColor: 'rgba(245, 246, 255, 0.5)',
  transition: 'transition: background-color 0.1s ease-in-out;',
  columns: [
    {
      order: 1,
      type: '_checker',
      weight: { min: 0.5, max: 0.5 },
      position: 'center',
      title: '',
      disableHover: true,
    },
    { order: 2, hoverFirst: true, type: 'title', weight: { min: 3, max: 3 } },
    { order: 3, type: 'folderTitle', weight: { min: 2, max: 2 } },
    {
      order: 4,
      type: 'status',
      weight: { min: 2, max: 2 },
      position: 'center',
    },
    { order: 5, type: 'tags', weight: { min: 3, max: 3 }, position: 'center' },
    { order: 6, type: 'lastModifiedDate', weight: { min: 2, max: 2 } },
    { order: 7, type: 'createDate', weight: { min: 2, max: 2 } },
    {
      order: 8,
      hoverLast: true,
      type: '_actions',
      weight: { min: 1.5, max: 1.5 },
      position: 'center',
      title: '',
    },
  ],
  noHeader: false,
};

export const ChannelUsersSchema = {
  tableName: 'ChannelUsersSchema',
  transition: 'transition: background-color 0.1s ease-in-out;',
  columns: [
    {
      type: '_checker',
      title: 'checker',
      weight: { min: 1, max: 1 },
      position: 'center',
    },
    {
      type: 'name',
      title: 'name',
      weight: { min: 5, max: 5 },
      hoverFirst: true,
    },
    { type: 'email', title: 'email address', weight: { min: 5, max: 5 } },
    {
      type: 'role',
      title: 'role',
      weight: { min: 4, max: 4 },
      position: 'center',
    },
    {
      type: 'status',
      title: 'status',
      weight: { min: 4, max: 4 },
      position: 'center',
    },
    {
      type: '_actions',
      title: 'actions',
      weight: { min: 3, max: 3 },
      position: 'right',
      hoverLast: true,
    },
  ],
};
//
// export const PagesTableSchema = {
//   tableName: 'PagesTableSchema',
//   rowHeight: 48,
//   hoverColor: '#E8EBFA',
//   borderRadius: 4,
//   activeColor: '#D7DDF7',
//   fontStyles:
//     `font-family: RobotoFlex;
//       font-style: normal;
//       font-weight: normal;
//       font-size: 14px;
//       line-height: 21px;
//       color: #242633;
//       `,
//   backgroundColor: 'rgba(245, 246, 255, 0.5)',
//   transition: 'transition: background-color 0.1s ease-in-out;',
//   columns: [
//     { order: 1, type: '_checker', weight: { min: 0.5, max: 0.5 },
//      position: 'center', title: '', disableHover: true },
//     { order: 2, hoverFirst: true, type: 'title', weight: { min: 3, max: 3 } },
//     { order: 3, type: 'folderTitle', weight: { min: 2, max: 2 } },
//     { order: 4, type: 'status', weight: { min: 2, max: 2 }, position: 'center' },
//     { order: 5, type: 'tags', weight: { min: 3, max: 3 }, position: 'center' },
//     { order: 6, type: 'lastModifiedDate', weight: { min: 2, max: 2 } },
//     { order: 7, type: 'createDate', weight: { min: 2, max: 2 } },
//     {
//       order: 8,
//       hoverLast: true,
//       type: '_actions',
//       weight: { min: 1.5, max: 1.5 },
//       position: 'center',
//       title: '',
//     },
//   ],
//   noHeader: false,
// };

export const LibraryTableSchema = {
  tableName: 'LibraryTableSchema',
  rowHeight: 48,
  hoverColor: '#E8EBFA',
  borderRadius: 4,
  activeColor: '#D7DDF7',
  fontStyles: `font-family: RobotoFlex;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #242633;
      `,
  backgroundColor: 'rgba(245, 246, 255, 0.5)',
  transition: 'transition: background-color 0.1s ease-in-out;',
  columns: [
    {
      order: 1,
      type: '_checker',
      weight: { min: 0.5, max: 0.5 },
      position: 'center',
      title: '',
      disableHover: true,
    },
    { order: 2, hoverFirst: true, type: 'name', weight: { min: 4, max: 4 } },
    { order: 3, type: 'type', weight: { min: 2, max: 2 } },
    { order: 4, type: 'collection', weight: { min: 3, max: 3 } },
    { order: 5, type: 'tags', weight: { min: 4, max: 4 }, position: 'center' },
    { order: 6, type: 'lastModifiedDate', weight: { min: 2, max: 2 } },
    { order: 7, type: 'size', weight: { min: 2, max: 2 }, position: 'center' },
    {
      order: 8,
      hoverLast: true,
      type: '_actions',
      weight: { min: 1.5, max: 1.5 },
      position: 'center',
      title: '',
    },
  ],
  noHeader: false,
};

export const TableControls = {
  getTableTemplate: (tableSchema, gapPercentage) => {
    const totalWeight = Object.values(tableSchema || {}).reduce((acc, item) => {
      acc += item.weight.max;
      return acc;
    }, 0);
    return {
      cols: Object.values(tableSchema || {}).length,
      netWeight: totalWeight,
      unit: (100 - gapPercentage) / totalWeight,
      gapSize: gapPercentage / Object.values(tableSchema || {}).length,
    };
  },
};
