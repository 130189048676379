import { ServiceUser } from '../user/types';

const defaultState = {};

export const downloadsReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case ServiceUser.Logout: {
      return { ...defaultState };
    }
    default:
      return state;
  }
};
