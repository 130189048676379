import styled from 'styled-components/macro';

const ChevronContainer = styled.div`
  border-radius: 4px;
  margin-left: 7px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ isActive }) => (isActive ? 'background:  #E8EBFA;' : '')}
  &:hover {
    background: #e8ebfa;
  }

   ${({ isLanguageTab }) => (isLanguageTab ? `
   background: transparent;
    &:hover {
    background: transparent;
  }
   ` : '')}
 
`;
export default ChevronContainer;
