import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionSavePdfBlock,
  actionUpdatePdfRelation,
} from '../../../../redux/library/actions';
import { actionDownloadFile } from '../../../../redux/downloads/action';
import PdfAsAttachment from './PdfBlockAsAttachment';
import PdfAsPreview from './PdfAsPreview';
import PdfOptions from './PdfOptions';
import useComponentVisibleMouseDown from '../../../../utils/hooks/useComponentVisibleMouseDown';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { LibraryComponents } from '../../../../redux/library/types';
import ResizeImage from '../../../ResizeImage';
import './pdfBlock.scss';

const PdfBlock = ({ state, relationId, innerHtml, isSharePage = false }) => {
  const { id: currentPageId } = useSelector((state) => state.currentPage);
  const [representationState, setRepresentationState] = useState(
    state?.data?.representationState === 'preview' ? 'preview' : 'attachment',
  );
  const [refOption, isOptionVisible, setIsOptionVisible] = useComponentVisibleMouseDown(state?.data?.isNew);
  const [height, setHeight] = useState(state?.data?.height || 42);
  const [isInitRender, setIsInitRender] = useState(true);
  const attachmentRef = useRef(null);
  const previewRef = useRef(null);
  const dispatch = useDispatch();

  const downloadPdf = () => {
    if (representationState === 'attachment') {
      dispatch(
        actionDownloadFile({
          id: state?.data?.nestedItemId,
          url: state?.data?.urlFile,
        }),
      );
    }
  };
  useEffect(() => {
    if (state?.data?.content && state.data.content !== representationState) setRepresentationState(state.data.content);
    if (state?.data?.isNew && !isOptionVisible) setIsOptionVisible(true);
  }, [state]);
  const savePdf = (libComponentId, newInnerHtml, resizeWidth, newHeight) => {
    if (isInitRender) {
      setIsInitRender(false);
      return;
    }
    dispatch(
      actionSavePdfBlock(
        currentPageId,
        relationId,
        libComponentId,
        newInnerHtml,
        resizeWidth,
        newHeight,
      ),
    );
  };
  const updatePdfBlock = (newState) => {
    setRepresentationState(newState);
    // dispatch(actionUpdatePdfRelation(
    //   relationId,
    //   ['content'],
    //   [newState],
    //   null,
    //   null,
    //   currentPageId,
    // ));

    dispatch(
      actionCreator(LibraryComponents.UpdatePdfPreview, {
        relationId,
        newState,
        currentPageId,
      }),
    );
  };
  const updatePdfBlockWidth = (width) => {
    let newHtml;
    if (representationState === 'attachment') newHtml = attachmentRef?.current?.innerHTML;
    else if (representationState === 'preview') newHtml = previewRef?.current?.innerHTML;

    dispatch(
      actionCreator(LibraryComponents.UpdatePdfWidth, {
        relationId,
        width,
        nestedItemId: state?.data?.nestedItemId,
        newInnerHtml: newHtml,
        currentPageId,
      }),
    );
    dispatch(
      actionUpdatePdfRelation(
        relationId,
        ['width'],
        [width],
        state?.data?.nestedItemId,
        newHtml,
        currentPageId,
      ),
    );
  };
  if (representationState === 'preview') {
    return (
      <div>
        <ResizeImage
          save={updatePdfBlockWidth}
          startWith={state?.data?.width}
          type="application/pdf"
          innerHtml={innerHtml}
          localState={state}
          isPdf
          isSharePage={isSharePage}
        >
          <PdfAsPreview
            previewRef={previewRef}
            state={state}
            height={height}
            setHeight={setHeight}
            relationId={relationId}
            save={savePdf}
          />
        </ResizeImage>
      </div>
    );
  }

  return (
    <div ref={attachmentRef}>
      <PdfAsAttachment
        title={state?.data?.title}
        size={state?.data?.size}
        refOption={refOption}
        downloadPdf={downloadPdf}
      />
      {isOptionVisible && (
        <PdfOptions
          setIsOptionVisible={setIsOptionVisible}
          update={updatePdfBlock}
          parentRef={attachmentRef}
          refOption={refOption}
        />
      )}
    </div>
  );
};

export default PdfBlock;
