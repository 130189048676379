import './index.css';

import { $isCodeHighlightNode } from '@lexical/code';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  $isParagraphNode,
  $isRangeSelection, $isRootOrShadowRoot,
  $isTextNode,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { createPortal } from 'react-dom';

import { $getSelectionStyleValueForProperty, $patchStyleText, $setBlocksType } from '@lexical/selection';
import { $createHeadingNode, $isHeadingNode } from '@lexical/rich-text';

import { useTranslation } from 'react-i18next';
import { getDOMRangeRect } from '../../../../shared/lexical/utils/getDOMRangeRect';
import { setFloatingElemPosition } from '../../../../shared/lexical/utils/setFloatingElemPosition';
import { getSelectedNode } from '../../../../shared/lexical/utils/getSelectedNode';
import DropDown, { DropDownItem } from '../../../../shared/lexical/ui/DropDown';
import DropdownColorPicker from '../../../../shared/lexical/ui/DropdownColorPicker';
import { DATA_PARENTS } from '../../../../utils/constants';
import { FontDropDown } from '../../../../shared/lexical/DropDown/FontDropDown';

function dropDownActiveClass(active) {
  if (active) {
    return 'active dropdown-item-active';
  }
  return '';
}
function Divider() {
  return <div className="divider" />;
}
const blockTypeToBlockName = {
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
};


function BlockFormatDropDown({
  editor,
  blockType,
  disabled = false,
}) {
  const { t } = useTranslation();
  const formatHeading = (headingSize) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      });
    }
  };

  return (
    <DropDown
      disabled={disabled}
      buttonClassName="toolbar-item block-controls"
      buttonIconClassName={`icon  block-type ${blockType}`}
      buttonAriaLabel="Formatting options for text style"
      dropdownClassName="dropdown-item-title"
    >
      <div className="annotation">{t('sizeFilterT')}</div>

      <DropDownItem
        className={`item item-title ${dropDownActiveClass(blockType === 'h3')}`}
        onClick={() => formatHeading('h3')}
      >
        <span className="text title3 title">{t('title3T')}</span>
      </DropDownItem>
      <DropDownItem
        className={`item item-title ${dropDownActiveClass(blockType === 'h2')}`}
        onClick={() => formatHeading('h2')}
      >
        <span className="text title2 title">{t('title2T')}</span>
      </DropDownItem>

      <DropDownItem
        className={`item item-title ${dropDownActiveClass(blockType === 'h1')}`}
        onClick={() => formatHeading('h1')}
      >
        <span className="text title1 title">{t('title1T')}</span>
      </DropDownItem>
    </DropDown>
  );
}


function TextFormatFloatingToolbar({
  editor,
  anchorElem,
  isBold,
  isItalic,
  fontFamily,
  isUnderline,
  isStrikethrough,
  isEditable,
  blockType,
  fontColor,
}) {
  const popupCharStylesEditorRef = useRef(null);
  const { t } = useTranslation();
  function mouseMoveListener(e) {
    if (
      popupCharStylesEditorRef?.current
      && (e.buttons === 1 || e.buttons === 3)
    ) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'none') {
        const x = e.clientX;
        const y = e.clientY;
        const elementUnderMouse = document.elementFromPoint(x, y);

        if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
          // Mouse is not over the target element => not a normal click, but probably a drag
          popupCharStylesEditorRef.current.style.pointerEvents = 'none';
        }
      }
    }
  }
  function mouseUpListener() {
    if (popupCharStylesEditorRef?.current) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'auto') {
        popupCharStylesEditorRef.current.style.pointerEvents = 'auto';
      }
    }
  }

  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      document.addEventListener('mousemove', mouseMoveListener);
      document.addEventListener('mouseup', mouseUpListener);

      return () => {
        document.removeEventListener('mousemove', mouseMoveListener);
        document.removeEventListener('mouseup', mouseUpListener);
      };
    }
  }, [popupCharStylesEditorRef]);

  const updateTextFormatFloatingToolbar = useCallback(() => {
    const selection = $getSelection();

    const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
    const nativeSelection = window.getSelection();

    if (popupCharStylesEditorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null
      && nativeSelection !== null
      && !nativeSelection.isCollapsed
      && rootElement !== null
      && rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement);

      setFloatingElemPosition(
        rangeRect,
        popupCharStylesEditorElem,
        anchorElem,
      );
    }
  }, [editor, anchorElem]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        updateTextFormatFloatingToolbar();
      });
    };

    window.addEventListener('resize', update);
    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);
      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [editor, updateTextFormatFloatingToolbar, anchorElem]);

  const applyStyleText = useCallback(
    (styles, skipHistoryStack) => {
      editor.update(
        () => {
          const selection = $getSelection();
          if (selection !== null) {
            $patchStyleText(selection, styles);
          }
        },
        skipHistoryStack ? { tag: 'historic' } : {},
      );
    },
    [editor],
  );

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar();
    });
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateTextFormatFloatingToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, updateTextFormatFloatingToolbar]);

  const onFontColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ color: value }, skipHistoryStack);
    },
    [applyStyleText],
  );


  return (
    <div
      // tabIndex="0" - fix for safari in onBlur
      tabIndex="0"
      data-parent={DATA_PARENTS.TextFormatToolbar}
      ref={popupCharStylesEditorRef}
      className="floating-text-format-popup"
    >
      {editor.isEditable() && (
        <>
          <BlockFormatDropDown
            disabled={!isEditable}
            blockType={blockType}
            editor={editor}
          />
          <FontDropDown
            disabled={!isEditable}
            styles="font-family"
            value={fontFamily}
            editor={editor}
            annotation="Sans serif"
            annotationTwo="Serif"
          />
          <Divider />
          <button
            data-parent={DATA_PARENTS.TextFormatToolbar}
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
            }}
            className={`popup-item spaced ${isBold ? 'active' : ''}`}
            aria-label="Format text as bold"
          >
            <i className="format bold" />
          </button>
          <button
            type="button"
            data-parent={DATA_PARENTS.TextFormatToolbar}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
            }}
            className={`popup-item spaced ${isItalic ? 'active' : ''}`}
            aria-label="Format text as italics"
          >
            <i className="format italic" />
          </button>
          <button
            type="button"
            data-parent={DATA_PARENTS.TextFormatToolbar}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
            }}
            className={`popup-item spaced ${isUnderline ? 'active' : ''}`}
            aria-label="Format text to underlined"
          >
            <i className="format underline" />
          </button>
          <button
            type="button"
            data-parent={DATA_PARENTS.TextFormatToolbar}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
            }}
            className={`popup-item spaced ${isStrikethrough ? 'active' : ''}`}
            aria-label="Format text with a strikethrough"
          >
            <i className="format strikethrough" />
          </button>

          <DropdownColorPicker
            disabled={!isEditable}
            isSubOption
            buttonClassName="toolbar-item color-picker"
            dropdownClassName="full_size"
            buttonAriaLabel="Formatting text color"
            buttonIconClassName="icon font-color"
            color={fontColor}
            onChange={onFontColorSelect}
            title={t('textColorLowT')}
          />
        </>
      )}

    </div>
  );
}

function useFloatingTitleFormatToolbar(
  editor,
  anchorElem,
) {
  const [isText, setIsText] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isSubscript, setIsSubscript] = useState(false);
  const [isSuperscript, setIsSuperscript] = useState(false);
  const [blockType, setBlockType] = useState('h1');
  const [fontColor, setFontColor] = useState('#000');
  const [fontFamily, setFontFamily] = useState('Roboto');


  const updatePopup = useCallback(() => {
    editor.getEditorState().read(() => {
      // Should not to pop up the floating toolbar when using IME input
      if (editor.isComposing()) {
        return;
      }
      const selection = $getSelection();
      const nativeSelection = window.getSelection();
      const rootElement = editor.getRootElement();

      if (
        nativeSelection !== null
        && (!$isRangeSelection(selection)
          || rootElement === null
          || !rootElement.contains(nativeSelection.anchorNode))
      ) {
        setIsText(false);
        return;
      }

      if (!$isRangeSelection(selection)) {
        return;
      }

      const node = getSelectedNode(selection);

      setIsBold(!selection.hasFormat('bold')); // BUG-340 A title without bold is more bold
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsSubscript(selection.hasFormat('subscript'));
      setIsSuperscript(selection.hasFormat('superscript'));
      setFontFamily(
        $getSelectionStyleValueForProperty(selection, 'font-family', 'Arial'),
      );
      const anchorNode = selection.anchor.getNode();
      const elementKey = anchorNode.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      const element = anchorNode.getKey() === 'root'
        ? anchorNode
        : $findMatchingParent(anchorNode, (e) => {
          const parent = e.getParent();
          return parent !== null && $isRootOrShadowRoot(parent);
        });
      if (elementDOM !== null) {
        const type = $isHeadingNode(element)
          ? element.getTag()
          : element.getType();
        if (type in blockTypeToBlockName) {
          setBlockType(type);
        }
      }
      setFontColor(
        $getSelectionStyleValueForProperty(selection, 'color', '#000'),
      );

      if (
        !$isCodeHighlightNode(selection.anchor.getNode())
        && selection.getTextContent() !== ''
      ) {
        setIsText($isTextNode(node) || $isParagraphNode(node));
      } else {
        setIsText(false);
      }

      const rawTextContent = selection.getTextContent().replace(/\n/g, '');
      if (!selection.isCollapsed() && rawTextContent === '') {
        setIsText(false);
      }
    });
  }, [editor]);

  useEffect(() => {
    document.addEventListener('selectionchange', updatePopup);
    return () => {
      document.removeEventListener('selectionchange', updatePopup);
    };
  }, [updatePopup]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup();
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsText(false);
        }
      }),
    );
  }, [editor, updatePopup]);

  if (!isText) {
    return null;
  }

  return createPortal(
    <TextFormatFloatingToolbar
      editor={editor}
      anchorElem={anchorElem}
      isBold={isBold}
      isItalic={isItalic}
      isStrikethrough={isStrikethrough}
      isSubscript={isSubscript}
      isSuperscript={isSuperscript}
      isUnderline={isUnderline}
      blockType={blockType}
      fontColor={fontColor}
      fontFamily={fontFamily}
      isEditable
    />,
    anchorElem,
  );
}

export default function FloatingTitleFormatToolbarPlugin({
  anchorElem = document.body,
}) {
  const [editor] = useLexicalComposerContext();
  return useFloatingTitleFormatToolbar(editor, anchorElem);
}
