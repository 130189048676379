import i18n from "../../../i18n";

export const DateRangeMark4SharedPlaylist = {
  OPEN_TO_READ: 'OPEN_TO_READ',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  CUSTOM_TIME_RANGE: 'CUSTOM_TIME_RANGE',
};

export const DateRangeMark4SharedPlaylistText = {
  OPEN_TO_READ: 'openToReadT',
  DAILY: 'only24HoursT',
  WEEKLY: 'oneWeekT',
  CUSTOM_TIME_RANGE: 'selectADateRangeT',
};
