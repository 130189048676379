import { HelpActions } from './types';

const defaultState = {
  playlists: {},
};

export default (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case HelpActions.UpdateHelp: {
      const { playlists, isDownload } = payload;
      return {
        ...state,
        playlists,
        isDownload,
      };
    }
    case HelpActions.RearrangeHelpPlaylistR: {
      return {
        ...state,
        playlists: {
          ...state.playlists,
          [payload.id]: {
            ...state.playlists[payload.id],
            playlistPosition: payload.newPlaylistPosition,
          },
        },
      };
    }
    case HelpActions.UpdateHelpPlaylistsR: {
      return {
        ...state,
        playlists: {
          ...payload.playlists,
        },
      };
    }
    default: {
      return state;
    }
  }
};
