import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { ReactComponent as ApproveButtonSettingsSvg } from '../../../images/icons/icon_20/ApproveButtoSettings.svg';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import { stopPropagation } from '../../../utils/constants';

const cx = classNames.bind(styles);

const ShortText = ({ state, saveState, isView }) => {
  const data = 'ActionButton';
  const { t } = useTranslation();
  const [ref, isOpenMenu, setOpenMenu] = useComponentVisible(false, data);
  const [questionName, setQuestionName] = useState(state?.questionName || '');
  const [prevQuestionName, setPrevQuestionName] = useState(
    state?.questionName || '',
  );
  const [hintText, setHintText] = useState(state?.hintText || '');
  const [placeholderText, setPlaceholderText] = useState(
    state?.placeholderText || '',
  );
  const [isRequired, setIsRequired] = useState(state?.isRequired || false);
  const [isHasLimit, setIsHasLimit] = useState(state?.isHasLimit || false);
  const refQuestion = useRef(null);
  const [answer, setAnswer] = useState('');
  const [isRename, setIsRename] = useState(false);
  const [limit, setLimit] = useState(state?.limit || 50);
  const [width, setWidth] = useState(50);
  const [isPlaceHolderFocused, setIsPlaceHolderFocused] = useState(false);
  const [isHintFocused, setIsHintFocused] = useState(false);
  // TODO thi is disabler for 2 togglers.
  const disabled = true;
  const saveRef = useRef();

  useEffect(() => {
    if (state?.questionName !== questionName) {
      setQuestionName(state?.questionName);
      setPrevQuestionName(state?.questionName);
    }
    if (state?.hintText !== hintText) setHintText(state?.hintText);
    if (state?.placeholderText !== placeholderText) {
      setPlaceholderText(state?.placeholderText);
    }
    if (state?.isRequired !== isRequired) setIsRequired(state?.isRequired);
    if (state?.isHasLimit !== isHasLimit) setIsHasLimit(state?.isHasLimit);
    if (state?.limit !== limit) setLimit(state?.limit);
  }, [state]);

  const inputOnlyNumber = (e) => {
    const parsed = parseInt(e.target.value);
    if (isNaN(parsed)) {
      setLimit('');
    } else {
      const a = parsed < 1 ? 1 : parsed > 99 ? 100 : parsed;
      setLimit(a);
    }
  };

  const handlerOpenMenu = (e) => {
    e.stopPropagation();
    setOpenMenu((v) => !v);
  };

  const inputKeyHandler = (e) => {
    if (e.key === 'Backspace') {
      e.stopPropagation();
    }
  };
  const keyHandler = (e) => {
    if (e.key === 'Escape') {
      setQuestionName(prevQuestionName);
      setIsRename(false);
    }
    if (e.key === 'Enter') {
      setIsRename(false);
    }
    if (e.key === 'Backspace') {
      e.stopPropagation();
    }
    // prevQuestionName
  };

  useEffect(() => {
    if (isRename) {
      setPrevQuestionName(questionName);
    }
  }, [isRename]);
  useEffect(() => {
    if (
      !isOpenMenu
      && !isRename
      && (questionName !== state?.questionName
        || hintText !== state?.hintText
        || placeholderText !== state?.placeholderText
        || isRequired !== state?.isRequired
        || isHasLimit !== state?.isHasLimit
        || limit !== state?.limit)
    ) {
      const outerHtml = saveRef?.current?.outerHTML;
      saveState(
        {
          questionName,
          hintText,
          isRequired,
          isHasLimit,
          limit,
          placeholderText,
        },
        outerHtml,
      );
    }
  }, [isOpenMenu, isRename]);

  useEffect(() => {
    setWidth(refQuestion?.current?.getBoundingClientRect().width + 5);
  }, [questionName, isRename]);

  return (
    <>
      {/* <div className={cx('outer_hover_wrapper')} /> */}
      <div className={cx('button_wrapper')}>
        <div ref={saveRef} className={cx('short_text')}>
          <div
            onClick={() => !isView && setIsRename(true)}
            className={cx('question', { rename: isRename, hoverable: !isView })}
          >
            {isRename && (
              <input
                value={questionName}
                onKeyDown={keyHandler}
                autoFocus
                onBlur={() => setIsRename(false)}
                style={{ width }}
                onChange={(e) => setQuestionName(e.target.value)}
                maxLength={120}
              />
            )}
            <div
              className={cx('question_text', { hidden: isRename })}
              ref={refQuestion}
            >
              {questionName || t('questionNameT')}
            </div>
            {isRequired && <div className={cx('required')}>*</div>}
          </div>
          <div className={cx('answer')}>
            {isHasLimit && !!limit && (
              <div className={cx('limit')}>{`${answer.length}/${limit}`}</div>
            )}

            <input
              onChange={(e) => setAnswer(e.target.value)}
              value={answer}
              placeholder={placeholderText}
              maxLength={(isHasLimit && limit) || null}
              onKeyDown={inputKeyHandler}
            />
          </div>
          <div className={cx('hint')}>{hintText}</div>
        </div>
        {!isView && (
          <div className={cx('button_settings')}>
            {t('shortTextT')}
            <div className={cx('svg_wrapper', { active_item: isOpenMenu })}>
              <ApproveButtonSettingsSvg
                data-parent={data}
                onClick={handlerOpenMenu}
              />
            </div>
            {isOpenMenu && (
              <div ref={ref} className={cx('settings_menu')}>
                <div className={cx('item_wrapper')}>
                  <div
                    className={cx('text', {
                      active: !placeholderText?.length && isPlaceHolderFocused,
                    })}
                  >
                    {t('placeholderTextT')}
                  </div>
                  <div className={cx('input_wrapper')}>
                    <input
                      onFocus={() => setIsPlaceHolderFocused(true)}
                      onBlur={() => setIsPlaceHolderFocused(false)}
                      maxLength={38}
                      value={placeholderText}
                      onChange={(event) => setPlaceholderText(event.target.value)
                      }
                      onKeyDown={inputKeyHandler}
                    />
                  </div>
                </div>
                <div className={cx('item_wrapper')}>
                  <div
                    className={cx('text', {
                      active: !hintText.length && isHintFocused,
                    })}
                  >
                    {t('hintTextT')}
                  </div>

                  <div className={cx('input_wrapper')}>
                    <input
                      onFocus={() => setIsHintFocused(true)}
                      onBlur={() => setIsHintFocused(false)}
                      maxLength={100}
                      value={hintText}
                      onChange={(event) => setHintText(event.target.value)}
                      onKeyDown={inputKeyHandler}
                    />
                  </div>
                </div>
                <div className={cx('item_wrapper')}>
                  <div className={cx('field', { disabled })}>
                    <div
                      className={styles.share_menu_toggler}
                      onClick={
                        disabled
                          ? stopPropagation
                          : () => setIsRequired((v) => !v)
                      }
                    >
                      <div
                        className={`
                  ${styles.in_folder_toggler_img} 
                  ${isRequired && styles.in_folder_toggler_img_right}`}
                      >
                        <div
                          className={`
                    ${styles.in_folder_toggler_knob} 
                    ${
                      !isRequired
                        ? styles.in_folder_knob_left
                        : styles.in_folder_knob_right
                    }
                  `}
                        />
                      </div>
                    </div>
                    {t('requiredFieldT')}
                  </div>
                </div>
                <div className={cx('item_wrapper', 'item_wrapper_limit')}>
                  <div className={cx('field', { disabled })}>
                    <div
                      className={styles.share_menu_toggler}
                      onClick={
                        disabled
                          ? stopPropagation
                          : () => setIsHasLimit((v) => !v)
                      }
                    >
                      <div
                        className={`
                  ${styles.in_folder_toggler_img} 
                  ${isHasLimit && styles.in_folder_toggler_img_right}`}
                      >
                        <div
                          className={`
                    ${styles.in_folder_toggler_knob} 
                    ${
                      !isHasLimit
                        ? styles.in_folder_knob_left
                        : styles.in_folder_knob_right
                    }
                  `}
                        />
                      </div>
                    </div>
                    {t('limitMaxCharactersT')}
                    {isHasLimit && (
                      <div
                        className={cx('input_wrapper_small', 'input_wrapper')}
                      >
                        <input
                          value={limit}
                          onChange={inputOnlyNumber}
                          onKeyDown={inputKeyHandler}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className={cx('submit')}>{t('submitFormUpT')}</div>
      {/* {isView && <div className={cx('submit')}>SUBMIT FORM</div> } */}
    </>
  );
};

export default ShortText;
