import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useComponentVisibleNoEsc from '../../utils/hooks/useComponentVisibleNoEsc';
import { actionRenameChannel } from '../../redux/channels/action';
import { IconRole, RawUserRoleMap, UserRole } from '../../utils/permissions';
import dropSmartfileToEntity from '../../utils/dnd/dropSmartfileToEntity';
import Tooltip from '../../shared/Tooltips/Tooltip';
import playlistRawStyles from '../../pages/Playlist/index.module.scss';
import rawStyles from './LeftSideBar.module.scss';
import {
  actionDragPage,
  actionSwitchPage,
} from '../../redux/currentPage/action';
import ActionDraggable from '../../redux/dragable/action';
import { actionCreator } from '../../shared/redux/actionHelper';
import { SUBSCRIBE_SOCKET } from '../../sagas/SocketClusterHelper';
import ThreeDotsMenu from '../MainLeftSideBar/sidebar-sections/ThreeDotsMenu';
import useComponentVisible from '../../utils/hooks/useComponentVisible';

const cn = classNames.bind(rawStyles);
const cnPlaylist = classNames.bind(playlistRawStyles);

const ListItemChannel = ({ channel, switchFolder, activeFolderId, blinkIdsArray, navAmongFolders }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { unseenInChannel = {} } = useSelector((state) => state.support);
  const { isDragFolder, idDragPage } = useSelector((state) => state.currentPage);

  const selectedPage = useSelector((state) => state.selectedPage);
  const currentUserId = useSelector((state) => state.user.id);
  const contentData = useSelector((state) => state.content.contentData);
  const draggableItemsExtraParams = useSelector((state) => state.library.draggableItemsExtraParams);

  const [refItem, , setIsComponentRename] = useComponentVisibleNoEsc(
    channel.isNew || false,
  );
  const [name, setName] = useState('');
  const [isDragOver, setIsDragOver] = useState(false);

  const hasUnseen = !!Object.values(unseenInChannel[channel.id] || {}).length;

  const [refShowMenu, isOpenMenu, setIsOpenMenu] = useComponentVisible(false);
  const onContextMenuHandler = (e) => {
    e.preventDefault();
    setIsOpenMenu(true);
  };
  useEffect(() => {
    dispatch(
      actionCreator(SUBSCRIBE_SOCKET, {
        channelName: 'channelName',
        id: channel.id,
        dispatch,
        history,
      }),
    );
  }, []);

  const dragPageStart = (e) => {
    e.stopPropagation();
    const div = e.currentTarget.cloneNode('deep');
    div.style.position = 'relative';
    div.style.opacity = 0.999;
    div.style.backgroundColor = '#ffeebf';
    div.id = 'draggable_page_placeholder';

    dispatch(
      ActionDraggable.DragChannel({
        id: channel.id,
        isForeign: channel.role !== RawUserRoleMap.Admin,
      }),
    );

    dispatch(actionSwitchPage({ idDragPage: null }));
    document.body.appendChild(div);
    setTimeout(() => {
      dispatch(actionDragPage(channel.id, null, true));
    }, 0);
    e.dataTransfer.setDragImage(div, 117, 20);
  };

  const dragPageEnd = () => {
    const div = document.getElementById('draggable_page_placeholder');
    if (div) document.body.removeChild(div);
    dispatch(actionDragPage(null, null));
  };

  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isDragFolder) return;
    if (!isDragOver) setIsDragOver(true);
  };

  const onDragEnd = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(ActionDraggable.Clear());
    setIsDragOver(false);
  };

  const onDrop = (e) => {
    dropSmartfileToEntity(
      e,
      dispatch,
      setIsDragOver,
      idDragPage,
      contentData,
      currentUserId,
      channel,
      draggableItemsExtraParams,
      selectedPage,
      'channel',
    );
  };

  const enterHandler = (e) => {
    if (e.key === 'Enter' && channel.isNew) {
      setIsComponentRename(false);
      dispatch(actionRenameChannel(channel.id, name));
    }
    if (e.key === 'Escape' && channel.isNew) {
      e.stopPropagation();
      e.preventDefault();
      dispatch(actionRenameChannel(channel.id, channel.name));
      setIsComponentRename(false);
    }
  };

  const handleInputChange = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    if (channel.isNew) {
      if (refItem && refItem.current) refItem.current.focus();
    }
  }, []);

  const isChannelOwner = channel.role === 'admin';

  return (
    <div
      onDragOver={onDragOver}
      onDragLeave={onDragEnd}
      onDrop={onDrop}
      onDragStart={dragPageStart}
      onDragEnd={dragPageEnd}
      draggable
      onClick={() => switchFolder('folder', channel.id)}
      onContextMenu={onContextMenuHandler}
      className={cn('folders_item', {
        folder_has_input: channel.isNew,
        folder_has_drag: isDragOver,
        folders_item__active: activeFolderId === channel.id,
        blink_it: blinkIdsArray?.includes(channel.id),
      })}
    >
      <div className={cn('item')}>
        {!channel.isNew && (
          <Tooltip
            text={UserRole[channel.role]}
            direction="up"
            place="ChannelListItemInNew"
            forChannels
          >
            <div className={cnPlaylist('badge_container')}>
              {IconRole[channel.role]}
            </div>
          </Tooltip>
        )}

        {channel.isNew && (
          <input
            ref={refItem}
            placeholder={t('channelTitlePlaceholderT')}
            maxLength="55"
            onKeyDown={enterHandler}
            onBlur={() => {
              dispatch(actionRenameChannel(channel.id, name));
              setIsComponentRename(false);
            }}
            value={name}
            onClick={(e) => e.stopPropagation()}
            onChange={handleInputChange}
            className={`${cn('input')}`}
          />
        )}
        {hasUnseen && (
          <div className={cn('new')}>
            {Object.values(unseenInChannel[channel.id] || {}).length}{' '}
          </div>
        )}
        {!channel.isNew && (
          <>
            <div
              className={cn('folder_name', 'channel_item', {
                active: activeFolderId === channel.id,
              })}
            >
              {channel.name || t('unnamedChannelT')}
            </div>
          </>
        )}
      </div>
      <div
        className={cn('count_channel', {
          active: activeFolderId === channel.id,
          hide: hasUnseen,
        })}
      >
        {channel.playlistsCounter}
      </div>
      <div className={cn('three_dots-wrapper')}>
        <ThreeDotsMenu
          refShowMenu={refShowMenu}
          setIsOpenMenu={setIsOpenMenu}
          isOpenMenu={isOpenMenu}
          userId={channel.id}
          itemId={channel.id}
          isChannel
          isChannelOwner={isChannelOwner}
          items={navAmongFolders}
          activeItemId={activeFolderId}
        />
      </div>
    </div>
  );
};

export default ListItemChannel;
