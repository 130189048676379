import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIco } from '../../images/2023/svg/Search Icon.svg';
import clearSearch from '../../images/icons/clear_search.svg';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { DATA_PARENTS } from '../../utils/constants';

const Main = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  ${({ forPlace }) => (forPlace === 'smart'
    ? `
      max-width: 157px;
      margin-right:20px;

      input {
        width: 122px;
      }
      `
    : forPlace === 'playlist'
      ? `
      input {
        width: 250px;
      }
    `
    : `
      border-radius: 30px;
      border: 0.6px solid #ECE2E2;
      background: #FFF;
      width: 400px;
      height: 38px;
      padding: 9px 10px;

      @media (max-width: 1280.1px) {
        width: 250px;
      }

      @media (max-width: 1024.1px) {
        width: 150px;
      }
    `)}

  & svg {
    margin-right: 5px;
    margin-top: 1px;
  }

  & svg path {
    stroke: #484343;
  }

  &:has(input:focus) {
    .cross_wrapper {
      visibility: visible;
    }
  }
`;
// const SearchIcon = styled.img`
//   padding: 8px 7px 8px 8px;
// `;
const ClearIcon = styled.img`
  position: absolute;
  padding: 2px;
  right: 0px;
  cursor: pointer;
  border-radius: 50%;
  width: 18px;
  height: 18px;

  &:hover {
    background: #ffeebf;
  }
`;

const Input = styled.input`
  top: 2px;
  position: relative;
  background: none;
  width: 460px;
  border: 0;
  color: #484343;
  font-family: RobotoFlex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.006em;
  text-align: left;
  border-bottom: 2px solid transparent;
  &:disabled {
    cursor: pointer;
  }

  &:focus {
    border-bottom: 2px solid #ffc72c;
  }

  &::placeholder {
    color: #484343;
    font-size: 16px;
    letter-spacing: -0.006em;
  }
`;

// const CrossWrapper = styled.div`
//   position: absolute;
//   //visibility: hidden;
// `;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ isDisabled }) => (isDisabled
    ? `
    cursor: pointer;
    opacity: 0.4;
    input {
      width: 350px;
    }
  }
  `
    : '')}
`;

const SearchInHeader = ({ forPlace, ...props }) => {
  const [filter, setFilter] = useState('');
  const { t } = useTranslation();
  const isDisabled = true;
  if (forPlace === 'smart') {
    const clear = () => props.setLibFilterSearch('');
    return (
      <Main
        forPlace={forPlace}
        data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
      >
        <SearchIco />
        <Input
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
          placeholder={t('searchDotsPlaceholderT')}
          value={props.libFilterSearch}
          onChange={(e) => {
            props.setLibFilterSearch(e.target.value);
            setFilter(e.target.value);
          }}
        />
        {/* <CrossWrapper className="cross_wrapper"><CrossCloseSVG /></CrossWrapper> */}
        {!!props.libFilterSearch && (
          <ClearIcon src={clearSearch} onClick={clear} alt="" />
        )}
      </Main>
    );
  }
  if (forPlace === 'playlist') {
    const clear = () => props.setPlaylistFilter('');
    return (
      <Main forPlace={forPlace}>
        <SearchIco />
        <Input
          placeholder={t('searchDotsPlaceholderT')}
          value={props.playlistFilter}
          onChange={(e) => {
            props.setPlaylistFilter(e.target.value);
          }}
        />
        {!!props.playlistFilter && (
          <ClearIcon src={clearSearch} onClick={clear} alt="" />
        )}
      </Main>
    );
  }
  return (
    <Main forPlace={forPlace}>
      <Tooltip
        text={t('soonAvailableT')}
        direction="up"
        isShow={isDisabled}
        place="SearchInHeader"
      >
        <InputWrapper isDisabled={isDisabled}>
          <SearchIco />
          <Input
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder={t('searchDotsPlaceholderT')}
            disabled={isDisabled}
          />
          {/* <CrossWrapper className="cross_wrapper"><CrossCloseSVG /></CrossWrapper> */}
          {!!filter && (
            <ClearIcon src={clearSearch} onClick={() => setFilter('')} alt="" />
          )}
        </InputWrapper>
      </Tooltip>
    </Main>
  );
};

export default SearchInHeader;
