import React, { useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { actionCreator } from '../../shared/redux/actionHelper';
import { Settings } from '../../redux/settings/types';

export const CheckoutForm = ({ plan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { clientSecret, payAmount } = useSelector((state) => state.user);
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [paySuccessful, setPaySuccessful] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null || stripe == null) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError?.message) {
      setErrorMessage(submitError.message);
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/`,
      },
      redirect: 'if_required',
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      setPaySuccessful(true);
      dispatch(actionCreator(Settings.ChangePaymentPlan, { newPlan: plan }));
    }
  };

  return (
    <div className={styles.paymentWrapper}>
      {!paySuccessful && (
        <form id="payment-form" onSubmit={handleSubmit}>
          <div className={styles.price}>{t('purchasingPlanT')} {plan}</div>
          <div className={styles.price}>{t('totalT')}: ${payAmount && (payAmount / 100).toFixed(2)}</div>
          <div className={styles.payElement}>
            <PaymentElement id="payment-element" />
          </div>
          <button className={styles.submitPay} type="submit" disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner" /> : 'Pay now'}
            </span>
          </button>
        </form>
      )}
      {paySuccessful && (
        <div className={styles.paymentSuccessfulMessage}>
          <div>{t('successfullySubscribedToT')} {plan}!</div>
        </div>
      )}
    </div>
  );
};
