import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SpinnerSvg } from '../../images/icons/spinner.svg';
import { actionCreator } from '../../shared/redux/actionHelper';
import { LibraryComponents } from '../../redux/library/types';
import { SpinnerGoogleEmbed } from '../../pages/Channels/MemberRow/RolesCell/Spinner';
import { convertDate } from '../../utils/dateConvert';
import useImageCheck from '../../utils/hooks/useImageCheck';
import { ReactComponent as GoogleDrive16Svg } from '../../images/icons/google_drive_16.svg';
import { DropboxPlaceholder } from './DropboxEmbedPreviewBlock';

const GoogleEmbedPreviewBlock = ({
  data,
  editorWR,
  index,
  isSharedComponent,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState('');
  const calcInitState = () => !(!!data?.foreignLastModifiedDate && !!data?.foreignLastModifiedUserName);
  const [isLastModifiedInit, setIsLastModifiedInit] = useState(calcInitState());
  const [error, loading] = useImageCheck(imageUrl);

  useEffect(() => {
    if (data.isUpdated) {
      setIsLastModifiedInit(false);
    }
  }, [data]);

  const updateGoogle = async() => {
    if (isSharedComponent) return;
    dispatch(
      actionCreator(LibraryComponents.UpdateGoogleEmbedModifiedTime, {
        currentPageBlockIndex: index,
      }),
    );
  };

  useEffect(() => {
    updateGoogle();
  }, []);

  const openInNewTab = (e) => {
    e.stopPropagation();

    let url = data?.url;
    if (url) {
      if (!url.startsWith('https://') && !url.startsWith('http://')) url = `http://${url}`;
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  };

  useEffect(() => {
    if (data?.images && Array.isArray(data?.images)) {
      const test = data?.images.find((i) => i.endsWith('.jpg'));
      setImageUrl(test || data?.images[0]);
    } else if (typeof data?.images === 'string') setImageUrl(data?.images);
  }, [data]);

  if (!data?.title) {
    return (
      <>
        <div onClick={openInNewTab} className="google_embed_website">
          <div className="google_loader_image_wrapper">
            <SpinnerSvg className="google_loader_image" />
          </div>
          <div className="google_embed_website_text">
            <div className="google_embed_website_loading">{t('loadingPreviewNoDotsT')}</div>
            <div className="google_embed_website_url">{data?.url || ''}</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        ref={editorWR}
        onClick={openInNewTab}
        className="google_embed_website"
      >
        <div className="google_preview_image_wrapper">
          {data?.images.length && (
            <>
              {loading && (
                <div className="cornerSpinner">
                  <SpinnerGoogleEmbed>
                    <SpinnerSvg />
                  </SpinnerGoogleEmbed>
                </div>
              )}
              {!error && !loading && (
                <img
                  className="google_preview_image"
                  src={imageUrl}
                  alt="preview"
                />
              )}
              {(!!error || loading) && (
                <DropboxPlaceholder>
                  <GoogleDrive16Svg style={{ width: 200, height: 200 }} />
                </DropboxPlaceholder>
              )}
            </>
          )}
        </div>
        <div className="google_embed_website_text">
          <div className="google_embed_website_h1">{data?.title}</div>
          {data?.description && (
            <div className="google_embed_website_description">
              {data?.description}
            </div>
          )}
          <div className="google_embed_website_url">{data?.url || ''}</div>
          {isLastModifiedInit && !isSharedComponent && (
            <div className="google_embed_website_modified minHeight">
              <SpinnerGoogleEmbed>
                <SpinnerSvg />
              </SpinnerGoogleEmbed>
            </div>
          )}
          {!isLastModifiedInit
            && !!data?.foreignLastModifiedDate
            && !!data?.foreignLastModifiedUserName && (
            <>
              <div className="google_embed_website_modified">
                {t('lastUpdatedT')}:{' '}
                {convertDate(data?.foreignLastModifiedDate) || ''}
              </div>
              <div className="google_embed_website_modified">
                {t('byT')}:{' '}
                {data?.foreignLastModifiedUserName || isSharedComponent ? (
                  data?.foreignLastModifiedUserName
                ) : (
                  <SpinnerGoogleEmbed>
                    <SpinnerSvg />
                  </SpinnerGoogleEmbed>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GoogleEmbedPreviewBlock;
