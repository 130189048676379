import React from 'react';
import { useDispatch } from 'react-redux';
import { COLUMN_CUSTOM_TYPE } from '../../../../utils/constants';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import SupportAction from '../../../../redux/support/types';
import DashboardColumns from '../../../../redux/dashboardColumns/types';
import ColumnSelector from './ColumnSelector/ColumnSelector';

const BoardColumnLabelLayer = ({ item, tableView, isInboxCard, isCard }) => {
  const dispatch = useDispatch();
  const isChannel = false;

  const close = () => {
    dispatch(actionCreator(SupportAction.SetActiveMenuItemId, { id: null }));
  };

  const onColumnClickHandler = (id) => {
    dispatch(actionCreator(SupportAction.SetActiveMenuItemId, { id }));
  };

  const setSelectedColumn = (newColumn) => {
    if (isChannel) {
      const cardId = item.cardId ? item.cardId : item.id;

      if (COLUMN_CUSTOM_TYPE.CLOSE === newColumn.customType) {
        dispatch(
          actionCreator(DashboardColumns.card.moveToCLoseColumn, {
            dragCard: cardId,
          }),
        );
      } else {
        dispatch(
          actionCreator(DashboardColumns.card.moveToColumn, {
            columnId: newColumn.id,
            dragCard: cardId,
          }),
        );
      }
    } else {
      dispatch(
        actionCreator(DashboardColumns.card.updateCardsForPlaylist, {
          selectedColumn: newColumn,
          playlistId: item.sharedID || item.id,
          usedInChannels: item.usedInChannels,
        }),
      );
    }
  };

  return (
    <ColumnSelector
      isCard={isCard}
      tableView={tableView}
      isInboxCard={isInboxCard}
      setSelectedColumn={setSelectedColumn}
      item={item}
      close={close}
      setActive={onColumnClickHandler}
    />
  );
};

export default BoardColumnLabelLayer;
