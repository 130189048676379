import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../../pages/Playlist/index.module.scss';
import { ReactComponent as CheckboxOnSvg } from '../../images/icons/checkbox_on.svg';
import { ReactComponent as CheckboxOffSvg } from '../../images/icons/checkbox_off.svg';
import { ShortRole, UserRole, IconRole } from '../../utils/permissions';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { actionRenameChannel } from '../../redux/channels/action';
import useComponentVisibleNoEsc from '../../utils/hooks/useComponentVisibleNoEsc';

const cx = classNames.bind(styles);

const Input = styled.input`
  padding-left: 6px;
  height: 32px;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 232px;
  z-index: 18;
  border: 1px solid #0026e5;
  border-radius: 6px;
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  height: 32px;
`;
const dataParent = 'dropDown';

const ChannelInChannelsSelector = ({
  item,
  used,
  usedOld,
  changeLocalUsedValue,
  isPublishPlaylist,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [refItem, , setIsComponentRename] = useComponentVisibleNoEsc(
    item.isNew || false,
  );
  const [name, setName] = useState('');

  const enterHandler = (e) => {
    if (e.key === 'Enter' && item.isNew) {
      setIsComponentRename(false);
      dispatch(actionRenameChannel(item.id, name));
      setName('');
    }
    if (e.key === 'Escape' && item.isNew) {
      e.stopPropagation();
      e.preventDefault();
      dispatch(actionRenameChannel(item.id, item.name));
      setName('');
      setIsComponentRename(false);
    }
  };
  const handleInputChange = (e) => {
    setName(e.target.value);
  };
  return (
    <div key={item?.id}>
      {item.isNew && (
        <InputWrapper>
          <Input
            ref={refItem}
            placeholder={t('channelTitlePlaceholderT')}
            maxLength="55"
            onKeyDown={enterHandler}
            autoFocus
            onBlur={() => {
              dispatch(actionRenameChannel(item.id, name));
              setIsComponentRename(false);
            }}
            value={name}
            onClick={(e) => e.stopPropagation()}
            onChange={handleInputChange}
          />
        </InputWrapper>
      )}
      {!item.isNew && (
        <div
          className={cx('dropdown_row_wrapper', { active: used[item?.id], isDisabled: usedOld[item?.id] })}
          onClick={() => changeLocalUsedValue(item)}
        >
          <div className={cx('dropdown_icon')}>
            {(used[item?.id] || usedOld[item?.id]) ? <CheckboxOnSvg /> : <CheckboxOffSvg />}
          </div>
          <div
            key={item?.id}
            className={cx('dropdown_row')}
            data-parent={dataParent}
          >
            {item?.name || t('unnamedChannelT')}
          </div>
          {item.isShared && item.role !== UserRole.Viewer && (
            <Tooltip
              text={UserRole[item.role]}
              direction="right"
              place={`newChannelItemInSelector_${ShortRole[item.role]}`}
            >
              <div className={cx('badge_container')}>
                <div
                  className={cx('item', {
                    coAdmin:
                      item.role.toLowerCase() === 'co_admin'
                      && !isPublishPlaylist,
                    contributor:
                      item.role === 'contributor' && !isPublishPlaylist,
                    admin:
                      item.role.toLowerCase() === 'admin' && !isPublishPlaylist,
                    viewer:
                      item.role.toLowerCase() === 'viewer'
                      && !isPublishPlaylist,
                  })}
                >
                  {isPublishPlaylist
                    ? IconRole[item.role]
                    : ShortRole[item.role]}
                </div>
              </div>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default ChannelInChannelsSelector;
