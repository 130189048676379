import React, { useState } from 'react';
import { MinorCardImgWrapper, MinorFailedVideoWrapper } from '../styled';
import { MUX_IMAGE_PATH } from '../../../utils/constants';
import { ReactComponent as VideoFileSvg } from '../../../images/icons/mini_page_types_icons/video_20_24_white.svg';

const VideoMinorPreview = ({
  isLibDescription,
  isImage,
  item,
  onImageError,
  isError,
  isVideo,
}) => {
  const [isSecondChanceError, setIsSecondChanceError] = useState(false);

  if (
    (isSecondChanceError && isVideo && isLibDescription)
    || (!item.playbackID && isVideo)
  ) {
    return (
      <MinorFailedVideoWrapper>
        <VideoFileSvg />
      </MinorFailedVideoWrapper>
    );
  }
  if (isError && isLibDescription) {
    return (
      <MinorCardImgWrapper
        onError={() => setIsSecondChanceError(true)}
        isLibDescription={isLibDescription}
        src={
          isImage
            ? item.urlSmallImage || item.urlFile
            : MUX_IMAGE_PATH(item.playbackID, 170, 170)
        }
      />
    );
  }
  return (
    <MinorCardImgWrapper
      onError={onImageError}
      isLibDescription={isLibDescription}
      src={
        isImage
          ? item.urlSmallImage || item.urlFile
          : MUX_IMAGE_PATH(
            item.playbackID,
            isLibDescription ? 170 : 90,
            isLibDescription ? 170 : 90,
          )
      }
    />
  );
};

export default VideoMinorPreview;
