import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import { actionCreator } from '../../shared/redux/actionHelper';
import { InboxAction } from '../../redux/inbox/types';
import InboxCard from './InboxCard';
import { OutboxAction } from '../../redux/outbox/types';

const CardInfiniteScrollShell = styled.div`
`;

const CardInfiniteScroll = ({
  scrollParentRef,
  currentFilter,
  channels,
  selectedCards,
  pickCard,
  isOutbox,
  Card,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [hasMore, setHasMore] = useState(true);

  const { cards, hasItem } = useSelector(state => (isOutbox ? state.outbox : state.inbox));

  useEffect(() => {
    setHasMore(hasItem);
  }, [cards.length]);

  const next = () => {
    setHasMore(false);
    if (isOutbox) {
      dispatch(actionCreator(OutboxAction.UploadOutboxCardSaga, { filter: currentFilter, isNext: true }));
      return;
    }
    dispatch(actionCreator(InboxAction.UploadInboxCardSaga, { filter: currentFilter, isNext: true }));
  };

  return (
    <CardInfiniteScrollShell>
      <InfiniteScroll
        pageStart={3}
        loadMore={next}
        useWindow={false}
        hasMore={hasMore}
        loader={<div key={0}>{t('loadingT')}</div>}
        getScrollParent={() => scrollParentRef.current}
      >
        <div>
          {!!cards.length && cards?.sort((a, b) => b.date - a.date).map((item) => (
            <Card
              key={(item.id || item.channelId) + item.type}
              item={item}
              channels={channels}
              isCardSelectedByDefault={selectedCards.some(card => card.id === item.id)}
              pickCard={pickCard}
            />
          ))}
        </div>
      </InfiniteScroll>
    </CardInfiniteScrollShell>
  );
};

export default CardInfiniteScroll;
