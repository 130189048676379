
export const calcItemName = (linkPage, t) => {
  if (linkPage.name) return linkPage.name;
  if (linkPage.title) return linkPage.title;
  if (linkPage.libraryComponent?.title) return linkPage.libraryComponent.title;
  return t('textComponentT');
};

export const calcModalTitle = (aiType, t) => {
  const naming = {};
  switch (aiType) {
    case 'ai_task':
      naming.title = t('assignTaskForAlanAIT');
      naming.button = t('sendT');
      break;
    case 'ai_send':
      naming.title = t('aiProcessingT');
      naming.button = t('sendT');
      break;
    case 'ai_search':
      naming.title = `${t('aISearchT')}:`;
      naming.button = t('searchT');
      break;
    case 'ai_summarize_single':
      naming.title = t('alanAIResultsT');
      naming.button = t('Save as text');
      break;
    case 'ai_summarize_all':
      naming.title = t('alanAIResultsAcrossTheSmartfileT');
      naming.button = t('addT');
      break;
    case 'ai_queue':
      naming.title = t('sendToAIT');
      break;
    default:
      break;
  }
  return naming;
};
