import styled from 'styled-components/macro';

const iconStrokeColor = '#242633';
const iconFillColor = '#FFFFFF';

export const PlayerShell = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%; // todo
  overflow-y: scroll;
  overflow-x: auto;
  transition-property: height, padding, background-color;
  transition: ease-out 0.25s;

  ${({ isFullScreenMode }) => (isFullScreenMode
    ? `
      background: #242633;
    `
    : 'background: rgb(250,250,255);' + ' margin-top: 56px;' + 'margin-bottom: 61px;')}
  ${({ isFullScreenMode, isMouseMoveShowControls }) => (isFullScreenMode && !isMouseMoveShowControls
    ? `
      height: 100%;
    `
    : '')}
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    ${({ isFullScreenMode }) => (isFullScreenMode
    ? `
          background-color: #26293d;
    `
    : 'background-color: #FAFAFF;')}
  }

  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;

    ${({ isFullScreenMode }) => (isFullScreenMode
    ? `
          background: #242633;
    `
    : '    background-color: #e2e1e1;')}
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track-piece {
    display: none;
    background-color: #fafaff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
`;
export const PlayerHeaderShell = styled.div`
  background: transparent;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 56px;
  width: 100%;
  padding: 11px 27px 11px 21px;
  transition: all ease-out 0.25s;
  position: absolute;
  top: 0;
  z-index: 11; // approveButton conflict in PlayerItemContent component

  ${({ isFullScreenMode, isMouseMoveShowControls }) => (isFullScreenMode && !isMouseMoveShowControls
    ? `
      top: -56px;
      position: absolute;
  `
    : '')}
`;

export const MiddleSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    path {
      stroke: ${iconStrokeColor};
      fill: ${iconFillColor};
      stroke-width: 2.5px;
    }
  }

  ${({ isFullScreenMode, isMouseMoveShowControls }) => (isFullScreenMode && !isMouseMoveShowControls
    ? `
      height: 0;
      padding: 0;
      overflow: hidden;
    `
    : '')}
`;

export const ActionsSection = styled.div`
  position: absolute;
  right: 26px;
  display: flex;
  z-index: 110;
`;

const Text = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const ChannelTitle = styled(Text)`
  font-size: 14px;
  color: #242633;
`;

export const PlaylistTitle = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  color: #121b25;
`;

export const Button = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: relative;
  margin-left: 19px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  & svg path {
    stroke: ${iconStrokeColor};
    fill: ${iconFillColor};
  }

  svg path {
    stroke: ${iconStrokeColor};
    fill: ${iconFillColor};
  }

  .item.library.active {
    background: #e8ebfa;
  }

  ${({ isActive }) => (isActive
    ? `
    background: #E8EBFA;
    
    svg path {
      stroke: ${iconStrokeColor};
      fill: ${iconFillColor};
    }
  `
    : '')}
  ${({ isDisabled }) => (isDisabled
    ? `
    cursor: not-allowed;
    pointer-events: none;
    & svg path {
      fill: none;
      stroke: #A2A7B2;
    }
  
  `
    : '')}

  &:hover {
    background: #e8ebfa;
    & svg path {
      stroke: ${iconStrokeColor};
      fill: ${iconFillColor};
    }
  }
  &:hover svg path {
    fill: #e8ebfa;
  }
`;

export const FavoriteButton = styled(Button)`
  ${({ isActive }) => (isActive
    ? `
    background: initial;
    
    svg path {
      stroke: #596080;
      fill: #596080;
    }
  `
    : '')}

  &:hover svg path {
    stroke: #596080;
    fill: #e8ebfa;
  }
`;

export const PlayerFooterShell = styled.div`
  position: fixed;
  height: 60px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #f9fafc;
  box-shadow: 0 0 60px rgba(62, 85, 103, 0.1);
  padding-left: 10px;
  padding-right: 20px;
  transition-property: height, padding;
  transition: ease-out 0.25s;
  z-index: 10;

  ${({ isFullScreenMode, isMouseMoveShowControls }) => (isFullScreenMode && !isMouseMoveShowControls
    ? `
      bottom: -60px;
      overflow: hidden;
    `
    : '')}
`;

export const FootterListItemShell = styled.div`
  display: flex;
`;

export const TitleWrapper = styled.div`
  font-family: "RobotoFlex", sans-serif;
`;

export const SeparatorLineHorizontal = styled.div`
  width: 100%;
  background-color: #e4e7f7;
  height: 1px;
  margin: 20px auto;
  max-width: 194px;
`;

export const SeparatorLine = styled.div`
  width: 1px;
  background-color: #e4e7f7;
  height: 50%;
`;

export const TitleLinkPage = styled.div`
  font-weight: 400;
  font-size: 15px;
  color: #596675;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 142px;
  width: 142px;
`;

export const PlayerButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 165px;
`;

export const ProgressText = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: #242633;
  display: inline-flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 4px;
  max-width: 63px;
`;

export const NamePlate = styled.div`
  width: 204px; //todo
  display: flex;
`;

export const PlaylistPlayerControlsShell = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
`;

export const ContentWidthControlShell = styled.div`
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentWidthControlButton = styled.div`
  border-radius: 6px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: fit-content;
  &:hover {
    background-color: #e8ebfa;
  }
  ${({ isActive }) => (isActive
    ? `
     background-color: #E8EBFA;
  `
    : '')}
`;

export const TimeLineComponentShell = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
`;

export const TimeData = styled.div`
  width: 60px;
  display: flex;
  justify-content: center;

  ${({ floatLeft }) => (floatLeft
    ? `
    justify-content: flex-start;
  `
    : '')}
  ${({ floatRight }) => (floatRight
    ? `
    justify-content: flex-end;
  `
    : '')}
`;

export const TimeText = styled.div`
  font-size: 14px;
  color: #121b25;
  white-space: nowrap;
  display: inline-flex;
`;

export const LineSegmentShell = styled.div`
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const CompositeLine = styled.div`
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
`;

export const Circle = styled.div`
  position: relative;
  top: 0;
  left: -4px;
  width: 8px;
  height: 8px;
  background: #596080;
  border-radius: 50%;
  cursor: pointer;
`;

export const PlayerButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(153.43deg, #ffffff 0%, #f9fafc 83.33%);
  cursor: pointer;
  border: 1px solid transparent;

  & svg path,
  & svg rect {
    stroke: #596080;
  }
  & svg rect {
    fill: #596080;
  }
  & svg rect {
    stroke-width: 0.5px;
  }

  &:hover {
    background: #e8ebfa;
    border-color: #0026e5;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    & svg path,
    & svg rect {
      stroke: #0026e5;
    }
    & svg rect {
      fill: #0026e5;
    }
  }
`;

export const PlayButton = styled(PlayerButton)`
  ${({ isPauseIcon }) => (isPauseIcon
    ? `
    & svg rect {
      fill: #596080;
    }
  
  `
    : `
    &:hover svg path {
      fill: #E8EBFA;
    }
  
  `)}
`;

export const DigitItem = styled.div`
  min-width: 8px;
  max-width: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Dots = styled(DigitItem)`
  min-width: 2px;
  max-width: 2px;
  margin-bottom: 2px;
  margin-right: 2px;
  margin-left: 2px;
`;

export const VolumeControlShell = styled.div`
  position: relative;
`;
export const VolumeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background-color: #e8ebfa;
  }
  ${({ isActive }) => (isActive ? 'background-color: #E8EBFA;' : '')}
`;

export const VolumeSliderShell = styled.div`
  left: -78px;
  top: -32px;
  position: absolute;
  width: 170px;
  height: 30px;
  display: flex;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
`;

export const VolumeSliderInput = styled.div`
  position: relative;
  width: 130px;
  height: 10px;
  display: flex;
  align-items: center;
`;

export const ZoomControlShell = styled.div`
  width: 109px;
  display: flex;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background-color: #e8ebfa;
  }
`;
export const ZoomText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #596080;
  margin-left: 4px;
  margin-right: 4px;
  cursor: default;
`;

export const FullscreenModeControlShell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background-color: #e8ebfa;
  }
`;

export const HideControlsInFullscreenModeHelpers = styled.div`
  width: 4px;
  height: 100%;
  position: fixed;
  top: 0;

  ${({ left, right }) => {
    if (left) return ' left: 0; ';
    if (right) return ' right: 0; ';
    return ' display: none ';
  }};
`;

export const PlayerItemContentShell = styled.div`
  position: relative;
  //width: 100%;
  //height: 100%;
  height: 100vh;
  ${({ isSimple }) => (!isSimple ? '' : ' width: 100%;  height: 100%;')}
  ${({ isPage }) => (!isPage
    ? ''
    : '    justify-content: center;'
        + '    display: flex;'
        + '    min-height: calc(100vh - 117px);')}
  ${({ isFullScreenMode }) => (isFullScreenMode
    ? 'background-color: #242633;' : '')}

  ${({ isHasScroll }) => (!isHasScroll ? '' : 'height: auto;' + 'min-height: auto;')}
  
  

  ${({ isImageHasScroll }) => (!isImageHasScroll ? '' : 'margin: auto; display: inline-table;')}

  ${({ isNeedToCenterText }) => (isNeedToCenterText ? `

  ` : '')}
 ${({ isText }) => (isText ? `
 span{
 font-size:26px !important;
 }
 ` : '')}
`;

export const PlayerItem = styled.div`
  position: relative;
  display: flex;

  justify-content: center;
  //top: 0;
  align-items: center;

  height: 100%;
  ${({ isSimple }) => (!isSimple ? '' : ' width: 100%;  height: 100%;')}
`;

export const CommentIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  transition: all ease-in-out 0.25s;

  transform: translate(0%, -50%);

  &:hover {
    background: #d7ddf7;
  }

  ${({ active }) => (active ? 'background: #D7DDF7;' : '')}

  background: #FFFFFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  position: absolute;
  right: 26px;
  top: 50%;

  & svg {
    width: 20px;
    height: 20px;
  }

  ${({ isFullScreenMode, isMouseMoveShowControls }) => (isFullScreenMode && !isMouseMoveShowControls
    ? `
    right: -60px;
  `
    : `
    
  `)}
`;

export const ButtonsPlace = styled.div`
  position: absolute;
  width: 0;
  right: -26px;
  top: 26px;

  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const HideCaptionsAndButtons = styled.div`
  position: absolute;
  transform: translate(50%, 50%);
  left: 100%;
  top: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;

  & svg {
    width: 8px;
    height: 8px;
  }

  ${({ isFullScreenMode }) => (isFullScreenMode
    ? `
    & svg path {stroke: #FFFFFF;}
  `
    : `
    & svg path {stroke: #596080;}
  `)}

  &:hover svg path {
    stroke: #0d26e5;
  }
`;

export const PreviewLabel = styled.div`
  position: absolute;
  color: white;
  background-color: #596080;
  font-size: 11px;
  border-radius: 4px;
  width: 50px;
  top: 4px;
  right: -60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
`;
