import { sym } from './utils';

export const EMIT = sym('EMIT');
export const REQUEST = sym('REQUEST');
export const SUBSCRIBE_SOCKET = sym('SUBSCRIBE_SOCKET');

export const socketEmit = (
  payload,
  event = 'dispatch',
  autoReconnectOptions,
) => ({
  type: EMIT,
  event,
  autoReconnectOptions,
  payload,
});
