import { v4 as uuidv4 } from 'uuid';
import { actionCreator } from '../../shared/redux/actionHelper';
import EditPlaylist from '../../redux/playlists/types';
import {
  actionDragPage,
} from '../../redux/currentPage/action';
import { actionPublishPlaylistS } from '../../redux/playlists/action';
import { actionShowMessage } from '../../redux/support/action';
import { MessageType } from '../constants';

const handleErrorsMessages = (
  dispatch,
  isMultiple,
  isSharedToMe,
  isAlreadySharedToUser,
) => {
  if (isSharedToMe) {
    dispatch(
      actionShowMessage({
        type: MessageType.sharedToContactError,
      }),
    );
    return;
  }
  if (isAlreadySharedToUser) {
    dispatch(
      actionShowMessage({
        type: MessageType.alreadySharedToContact,
        isMultiple,
      }),
    );
  }
};

export default function dropSmartfileToEntity(
  e,
  dispatch,
  setIsDragOver,
  idDragPage,
  contentData,
  currentUserId,
  entity,
  draggableItemsExtraParams,
  selectedPage,
  place,
) {
  e.stopPropagation();
  e.preventDefault();
  setIsDragOver(false);
  if (idDragPage && contentData[idDragPage]?.itemType === 'playlist') {
    const isSharedToMe = currentUserId !== contentData[idDragPage].owner.id;
    const isAlreadySharedToUser = place === 'contact'
      ? contentData[idDragPage].sharedToUsers[entity.id]
      : contentData[idDragPage].usedInChannels[entity.id];
    handleErrorsMessages(dispatch, false, isSharedToMe, isAlreadySharedToUser);
    if (isSharedToMe || isAlreadySharedToUser) return;
    if (place === 'contact') {
      dispatch(actionCreator(EditPlaylist.updateShareToUserPlaylistSR,
        {
          update: { isCoEdit: false },
          users: [entity],
          wrapperIdFromOptions: Object.keys(draggableItemsExtraParams)[0],
          idFromOptions: idDragPage,
          isSharedOnDrop: true,
        }));
    }
    if (place === 'channel') {
      dispatch(actionDragPage(null, null));
      const channelForSend = { [entity.id]: entity };
      const playlist = {
        id: idDragPage,
        title: contentData[idDragPage].title,
      };
      dispatch(actionPublishPlaylistS(
        channelForSend,
        {},
        playlist,
        {},
        true,
      ));
    }
  }
  if (Object.keys(selectedPage).length > 1) {
    const droppedSmartfiles = Object.values(contentData)
      .filter((val) => selectedPage[val.id]
        && val.itemType === 'playlist',
      );
    const smartfilesToShare = droppedSmartfiles.filter((item) => currentUserId === item.owner.id);
    const notSharedSmartfiles = droppedSmartfiles.reduce((acc, cur) => {
      if (!cur.sharedToUsers[entity.id] && place === 'contact') acc.push(cur.id);
      if (!cur.usedInChannels[entity.id] && place === 'channel') acc.push(cur.id);
      return acc;
    }, []);
    const isUnableToShare = smartfilesToShare.length !== Object.keys(selectedPage).length;
    const isNoSmartfilesToShare = notSharedSmartfiles.length === 0;
    handleErrorsMessages(dispatch, true, isUnableToShare, isNoSmartfilesToShare);
    if (isUnableToShare || isNoSmartfilesToShare) return;
    if (place === 'contact') {
      dispatch(actionCreator(EditPlaylist.ShareMultiplePlaylistsS, {
        update: { isCoEdit: false },
        users: [entity],
        dragToContactParams: {
          notSharedSmartfiles,
        },
      }));
    }
    if (place === 'channel') {
      const newId = uuidv4();
      dispatch(actionCreator(EditPlaylist.multiplePublish, {
        newId,
        addedNewChannel: [{ id: entity.id }],
        playlistsIds: Object.keys(selectedPage),
        dateState: {},
        dragToContactParams: {
          channelName: entity.name,
          notSharedSmartfiles,
        },
      }));
    }
  }
  const div = document.getElementById('draggable_page');
  if (div) document.body.removeChild(div);
}
