import { useSelector } from 'react-redux';
import React from 'react';
import ReadableTimeLeft from './ReadableTimeLeft';

const ReadableTimeLeftSharedPage = (props) => {
  const currentPage = useSelector((state) => state.currentPage);
  return (
    <ReadableTimeLeft
      {...props}
      availableTo={currentPage?.shareState?.availableTo}
      availableFrom={currentPage?.shareState?.availableFrom}
    />
  );
};

const ReadableTimeLeftSharedPlaylist = (props) => {
  const currentPage = useSelector((state) => state.currentPage);
  return (
    <ReadableTimeLeft
      {...props}
      availableTo={currentPage?.shareState?.sharedAvailableTo}
      availableFrom={currentPage?.shareState?.sharedAvailableFrom}
    />
  );
};

const ReadableTimeLeftDefault = (props) => {
  const currentPage = useSelector((state) => state.currentPage);
  return (
    <ReadableTimeLeft
      {...props}
      availableTo={currentPage?.inputs?.availableTo}
      availableFrom={currentPage?.shareState?.availableFrom}
    />
  );
};
const ReadableTimeLeftDataLayer = (props) => {
  if (props.type === 'sharedPage') return <ReadableTimeLeftSharedPage {...props} />;
  if (props.type === 'sharedPlaylist') return <ReadableTimeLeftSharedPlaylist {...props} />;

  return <ReadableTimeLeftDefault {...props} />;
};

export default ReadableTimeLeftDataLayer;
