// import React from "react";
// eslint-disable no-underscore-dangle
// import {
//   RichUtils,
//   KeyBindingUtil,
//   EditorState,
//   CompositeDecorator
// } from "draft-js";
//
// export const linkStrategy = (contentBlock, callback, contentState) => {
//   contentBlock.findEntityRanges(character => {
//     const entityKey = character.getEntity();
//     return (
//       entityKey !== null &&
//       contentState.getEntity(entityKey).getType() === "LINK"
//     );
//   }, callback);
// };
//
// export const Link = props => {
//   const { contentState, entityKey } = props;
//   const { url } = contentState.getEntity(entityKey).getData();
//   return (
//     <a
//       className="link"
//       href={url}
//       rel="noopener noreferrer"
//       target="_blank"
//       aria-label={url}
//     >
//       {props.children}
//     </a>
//   );
// };
//
// const addLinkPluginPlugin = {
//   keyBindingFn(event, { getEditorState }) {
//     const editorState = getEditorState();
//     const selection = editorState.getSelection();
//     if (selection.isCollapsed()) {
//       return;
//     }
//     if (KeyBindingUtil.hasCommandModifier(event) && event.which === 75) {
//       return "add-link";
//     }
//   },
//
//   handleKeyCommand(command, editorState,eventTimeStamp,  { getEditorState, setEditorState }) {
//     if (command !== "add-link") {
//       return "not-handled";
//     }
//     const link = 'Paste the link -'
//
//     const selection = editorState.getSelection();
//     if (!link) {
//       setEditorState(RichUtils.toggleLink(editorState, selection, null));
//       return "handled";
//     }
//     const content = editorState.getCurrentContent();
//     const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
//       url: link
//     });
//     const newEditorState = EditorState.push(
//       editorState,
//       contentWithEntity,
//       "create-entity"
//     );
//     const entityKey = contentWithEntity.getLastCreatedEntityKey();
//     setEditorState(RichUtils.toggleLink(newEditorState, selection, entityKey));
//     return "handled";
//   },
//
//   decorators: [
//     {
//       strategy: linkStrategy,
//       component: Link
//     }
//   ]
// };
//
// export default addLinkPluginPlugin;

import React from 'react';
import { createStore } from '@draft-js-plugins/utils';
import { getVisibleSelectionRect } from 'draft-js';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
} from '@draft-js-plugins/buttons';

function _extends() {
  _extends = Object.assign
    || function (target) {
      for (let i = 1; i < arguments.length; i++) {
        const source = arguments[i];

        for (const key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }

      return target;
    };

  return _extends.apply(this, arguments);
}

function _inheritsLoose(subClass, superClass) {
  subClass.prototype = Object.create(superClass.prototype);
  subClass.prototype.constructor = subClass;

  _setPrototypeOf(subClass, superClass);
}

function _setPrototypeOf(o, p) {
  _setPrototypeOf = Object.setPrototypeOf
    || function _setPrototypeOf(o, p) {
      o.__proto__ = p;
      return o;
    };

  return _setPrototypeOf(o, p);
}

const Toolbar = /* #__PURE__ */ (function (_React$Component) {
  function Toolbar() {
    let _this;

    for (
      var _len = arguments.length, args = new Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }

    _this = _React$Component.call.apply(_React$Component, [this].concat(args))
      || this;
    _this.state = {
      isVisible: false,
      position: undefined,

      /**
       * If this is set, the toolbar will render this instead of the children
       * prop and will also be shown when the editor loses focus.
       * @type {Component}
       */
      overrideContent: undefined,
    };
    _this.toolbar = null;

    _this.onOverrideContent = function (overrideContent) {
      _this.setState({
        overrideContent,
      });
    };

    _this.onSelectionChanged = function () {
      // need to wait a tick for window.getSelection() to be accurate
      // when focusing editor with already present selection

      setTimeout(() => {
        try {
          if (!_this.toolbar) return; // The editor root should be two levels above the node from
          // `getEditorRef`. In case this changes in the future, we
          // attempt to find the node dynamically by traversing upwards.

          const editorRef = _this.props?.store?.getItem('getEditorRef')();

          if (!editorRef) return; // This keeps backwards compatibility with React 15

          let editorRoot = editorRef.refs && editorRef.refs.editor
            ? editorRef.refs.editor
            : editorRef.editor;

          while (editorRoot.className.indexOf('DraftEditor-root') === -1) {
            editorRoot = editorRoot.parentNode;
          }

          const editorRootRect = editorRoot.getBoundingClientRect();
          const parentWindow = editorRoot.ownerDocument && editorRoot.ownerDocument.defaultView;
          const selectionRect = getVisibleSelectionRect(parentWindow || window);
          if (!selectionRect) return; // The toolbar shouldn't be positioned directly on top of the selected text,
          // but rather with a small offset so the caret doesn't overlap with the text.
          const left = editorRoot.offsetLeft
            + (selectionRect.left - editorRootRect.left)
            + selectionRect.width / 2;
          const extraTopOffset = -5;
          const position = {
            top:
              editorRoot.offsetTop
              - _this.toolbar.offsetHeight
              + (selectionRect.top - editorRootRect.top)
              + extraTopOffset,
            left: _this.props?.mod && left < 140 ? 140 : left,
          };

          _this.setState({
            position,
          });
        } catch (e) {
          _this.setState({
            position: {
              // top: 0,
              // left: 0
            },
          });
        }
      });
    };

    return _this;
  }

  _inheritsLoose(Toolbar, _React$Component);

  const _proto = Toolbar.prototype;

  // eslint-disable-next-line camelcase
  _proto.UNSAFE_componentWillMount = function UNSAFE_componentWillMount() {
    this.props.store.subscribeToItem('selection', this.onSelectionChanged);
  };

  _proto.componentWillUnmount = function componentWillUnmount() {
    this.props.store.unsubscribeFromItem('selection', this.onSelectionChanged);
  };
  /**
   * This can be called by a child in order to render custom content instead
   * of the children prop. It's the responsibility of the callee to call
   * this function again with `undefined` in order to reset `overrideContent`.
   * @param {Component} overrideContent
   */

  _proto.getStyle = function getStyle() {
    const store = this.props.store;
    const mod = this.props.mod;
    const _this$state = this.state;
    const overrideContent = _this$state.overrideContent;
    const position = _this$state.position;
    try {
      // overrideContent could for example contain a text input, hence we always show overrideContent
      const selection = store.getItem('getEditorState')().getSelection();
      const isVisible = (!selection.isCollapsed() && selection.getHasFocus())
        || overrideContent
        || this.props.isLinkVisible;
      const style = _extends({}, position);
      if (mod && position.left < 140) position.left = 140;
      if (isVisible) {
        style.visibility = 'visible';
        style.transform = 'translate(-50%) scale(1)';
        style.transition = 'transform 0.15s cubic-bezier(.3,1.2,.2,1)';
      } else {
        style.transform = 'translate(-50%) scale(0)';
        style.visibility = 'hidden';
      }

      return style;
    } catch (e) {
      return { transform: 'translate(-50%) scale(0)', visibility: 'hidden' };
    }
  };

  _proto.render = function render() {
    const _this2 = this;

    const _this$props = this.props;
    const theme = _this$props.theme;
    const store = _this$props.store;
    const OverrideContent = this.state.overrideContent;
    const childrenProps = {
      theme: theme.buttonStyles,
      getEditorState: store.getItem('getEditorState'),
      setEditorState: store.getItem('setEditorState'),
      onOverrideContent: this.onOverrideContent,
    };

    return /* #__PURE__ */ React.createElement(
      'div',
      {
        className: theme.toolbarStyles.toolbar,
        style: this.getStyle(),
        ref: function ref(element) {
          _this2.toolbar = element;
        },
      },
      OverrideContent
        ? /* #__PURE__ */ React.createElement(OverrideContent, childrenProps)
        : this.props.children(childrenProps),
    );
  };

  return Toolbar;
}(React.Component));

Toolbar.defaultProps = {
  children: function children(externalProps) {
    return (
      /* #__PURE__ */
      // may be use React.Fragment instead of div to improve perfomance after React 16
      React.createElement(
        'div',
        null,
        /* #__PURE__ */ React.createElement(ItalicButton, externalProps),
        /* #__PURE__ */ React.createElement(BoldButton, externalProps),
        /* #__PURE__ */ React.createElement(UnderlineButton, externalProps),
        /* #__PURE__ */ React.createElement(CodeButton, externalProps),
      )
    );
  },
};

const separator = 's1o2cezu';
function Seperator(_ref) {
  const _ref$className = _ref.className;
  const className = _ref$className === void 0 ? separator : _ref$className;
  return /* #__PURE__ */ React.createElement('div', {
    className,
  });
}

const buttonStyles = {
  buttonWrapper: 'bpsgbes',
  button: 'b181v2oy',
  active: 'a9immln',
};
const toolbarStyles = {
  toolbar: 'tukdd6b',
};
const defaultTheme = {
  buttonStyles,
  toolbarStyles,
};

const index = function (config) {
  if (config === void 0) {
    config = {};
  }

  const store = createStore({
    isVisible: false,
  });
  const mod = config.isLibraryCreate;
  const _config = config;
  const _config$theme = _config.theme;
  const theme = _config$theme === void 0 ? defaultTheme : _config$theme;

  const InlineToolbar = function InlineToolbar(props) {
    return /* #__PURE__ */ React.createElement(
      Toolbar,
      _extends({}, props, {
        mod,
        store,
        theme,
      }),
    );
  };

  return {
    initialize: function initialize(_ref) {
      const getEditorState = _ref.getEditorState;
      const setEditorState = _ref.setEditorState;
      const getEditorRef = _ref.getEditorRef;
      store.updateItem('getEditorState', getEditorState);
      store.updateItem('setEditorState', setEditorState);
      store.updateItem('getEditorRef', getEditorRef);
    },
    // Re-Render the text-toolbar on selection change
    onChange: function onChange(editorState) {
      store.updateItem('selection', editorState.getSelection());
      return editorState;
    },
    InlineToolbar,
  };
};

export default index;
