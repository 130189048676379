import * as React from 'react';
import styled from 'styled-components/macro';
import { UiComponentTypes, MUX_IMAGE_PATH } from '../../utils/constants';
import { ReactComponent as ArchiveSvg } from '../../images/icons/libComponentTypeArchivePlaceholder.svg';
import { ReactComponent as AudioSvg } from '../../images/2023/svg/fileTypes/audio_44_50.svg';
import { ReactComponent as AudioSvgMaker } from '../../images/2023/svg/fileTypes/audio_black.svg';
import { ReactComponent as BrokenImageSvgMaker } from '../../images/2023/svg/fileTypes/link_icon.svg';
import { ReactComponent as EmbedSvgMaker } from '../../images/2023/svg/fileTypes/embed_icon.svg';
import { ReactComponent as TextSvgMaker } from '../../images/2023/svg/fileTypes/text.svg';
import { ReactComponent as VideoSvg } from '../../images/icons/playlist_item_types/video_17_24.svg';
import { ReactComponent as BrokenImageSvg } from '../../images/icons/broken_img_32.svg';
import BlockCreatorComponent from '../LibraryMiniPage/BlockCreatorComponent';
import FallBackImage from '../FallBackImage/FallBackImage';
import { smartFileItemTypeCheck } from '../../shared/smartFile/constant';

const Image = styled.img`
  height: 30px;
  width: 30px;
  object-fit: cover;
  position: relative;
  border-radius: 6px;
`;

const Wrapper = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 6px;
  background-color: white;
  ${({ isPresentation }) => (isPresentation
    ? `
    display: flex;
    align-items: center;`
    : '')}
    
  > div {
    overflow: hidden;
  }
`;

const SvgWrapper = styled.div`
  height: 30px;
  width: 30px;
  min-width: 30px;
  overflow: hidden;
  ${({ error }) => (error
    ? `
    svg {
      height: 30px;
      width: 30px;
    }
  `
    : `
    padding: 4px;
    svg {
      height: 22px;
      width: 22px;
    }
  `)}
   ${({ isMaker }) => (isMaker
    ? `
    border-radius: 4px;
    background: #FFF;
    padding: 4px;
    svg {
      height: 22px;
      width: 22px;
    }`
    : '')}
`;

const ImageContainer = ({ item, isMaker }) => {
  const [error, setError] = React.useState(false);

  const onError = (e) => {
    e.target.onError = null;
    setError(true);
  };

  const isMakerText = isMaker
    && (item?.type === 'callout' || item?.type === 'text' || item?.type?.includes('title'));

  if (!item) return <></>;

  if (UiComponentTypes.video[item?.type]) {
    if (!item.playbackID) {
      return (
        <SvgWrapper isMaker={isMaker}>
          <VideoSvg />
        </SvgWrapper>
      );
    }
    return <Image src={MUX_IMAGE_PATH(item.playbackID, 30, 30)} alt="" />;
  }
  if (UiComponentTypes.image[item?.type]) return <Image src={item.urlFile} alt="" />;
  if (UiComponentTypes.imageLike[item?.type]) return <Image src={item.urlSmallImage} alt="" />;
  if (UiComponentTypes.audio[item?.type]) {
    return (
      <SvgWrapper isMaker={isMaker}>
        {isMaker ? <AudioSvgMaker /> : <AudioSvg />}
      </SvgWrapper>
    );
  }
  if (
    UiComponentTypes.page[item?.type]
    || UiComponentTypes.component[item?.type]
  ) {
    return (
      <Wrapper>
        <BlockCreatorComponent item={item} place="ViewList" isPreview />
      </Wrapper>
    );
  }
  if (UiComponentTypes.link[item?.type]) {
    const isEmbed = item?.type !== 'text/html' && item?.type !== 'link';
    if (error) {
      if (isMaker) {
        return (
          <SvgWrapper error isMaker={isMaker}>
            {isEmbed ? <EmbedSvgMaker /> : <BrokenImageSvgMaker />}
          </SvgWrapper>
        );
      }
      return (
        <SvgWrapper error isMaker={isMaker}>
          <BrokenImageSvg />
        </SvgWrapper>
      );
    }
    return (
      <Image
        src={item.urlFile ? item.urlFile : ''}
        alt=""
        onError={onError}
        onLoad={() => setError(false)}
      />
    );
  }
  if (
    UiComponentTypes.pdf[item?.type]
    || UiComponentTypes.ms[item?.type]
    || UiComponentTypes.presentation[item?.type]
    || UiComponentTypes.xls[item?.type]
    || UiComponentTypes.text[item?.type]
    || !!item?.pdfPreviewUrl
  ) {
    if (!item.pdfPreviewUrl && !item.urlFile) {
      return (
        <SvgWrapper error isMaker={isMaker}>
          <BrokenImageSvg />
        </SvgWrapper>
      );
    }
    return (
      <Wrapper isPresentation={UiComponentTypes.presentation[item?.type]}>
        <FallBackImage
          src={item.pdfPreviewUrl || item.urlFile || ''}
          alt=""
          type={item.pdfPreviewUrl ? 'application/pdf' : item.type}
          item={item}
          isListView
        />
      </Wrapper>
    );
  }
  if (isMakerText || smartFileItemTypeCheck.isRichText[item?.type]) {
    return (
      <SvgWrapper isMaker={isMaker}>
        <TextSvgMaker />
      </SvgWrapper>
    );
  }
  return (
    <SvgWrapper isMaker={isMaker}>
      <ArchiveSvg />
    </SvgWrapper>
  );
};

export default ImageContainer;
