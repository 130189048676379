import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import AvatarPlaceholder from '../Avatar';
import { DATA_PARENTS } from '../../utils/constants';
import InformerSpinner from '../Informer/InformerSpinner';

export const Text = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1b2336;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 254px;
`;
export const Avatar = styled.img`
  ${({ width }) => `width: ${width ?? 0}px;`}
  ${({ width }) => `height: ${width ?? 0}px;`}
  border-radius: 50%;
  flex: none;
  margin-right: ${({ noMargins }) => (noMargins ? 0 : '8px')};  
  ${({ borderColor }) => (borderColor ? `
    border: 1px solid ${borderColor};
    outline: 1px solid ${borderColor};
  ` : '')}
`;

export const AvatarPlace = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  //margin-right: 8px;
`;

const Main = styled.div`
  cursor: pointer;
  //display: flex;
  //align-items: center;
  //justify-content: end;
  border-radius: 6px;

  ${({ stylesForIsNew }) => (stylesForIsNew
    ? `
    margin-top: 16px; 
    margin-right: 20px;
    `
    : `
    position:absolute;
    right:34px;
    top:16px;
    `)}
`;

const User = ({ isNew, toggleUserMenu, isActive, isMaker }) => {
  const { firstName, lastName, avatarUrlVerySmall, displayImage } = useSelector(
    (state) => state.user,
  );
  const { isLoading = false } = useSelector(
    (state) => state?.settings?.inputs?.avatar,
  );
  const [innerIsLoading, setInnerIsLoading] = useState(false);
  const [oldAva, setOldAva] = useState(avatarUrlVerySmall);

  useEffect(() => {
    if (isLoading) {
      setInnerIsLoading(isLoading);
    }
  }, [isLoading]);
  useEffect(() => {
    if (avatarUrlVerySmall && avatarUrlVerySmall !== oldAva) {
      setOldAva(avatarUrlVerySmall);
      setInnerIsLoading(false);
    }
  }, [avatarUrlVerySmall]);
  return (
    <Main
      stylesForIsNew={isNew}
      data-cy="user-menu"
      data-parent={DATA_PARENTS.UserMenu}
      onClick={toggleUserMenu}
      active={isActive}
    >
      {(isLoading || innerIsLoading) && (
        <InformerSpinner isAvatar isMaker={isMaker} />
      )}
      {!(isLoading || innerIsLoading) && (
        <>
          {!!avatarUrlVerySmall && displayImage === 'AvatarImage' && (
            <Avatar
              data-parent={DATA_PARENTS.UserMenu}
              width={34}
              src={avatarUrlVerySmall}
              alt="avatar"
              noMargins
            />
          )}
          {(!avatarUrlVerySmall || displayImage === 'GeneratedAvatar') && (
            <div data-parent={DATA_PARENTS.UserMenu}>
              <AvatarPlaceholder
                width={34}
                name={firstName}
                abbreviation={`${firstName[0]}${lastName[0]}`}
              />
            </div>
          )}
        </>
      )}
    </Main>
  );
};

export default User;
