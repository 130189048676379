import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { select } from '@redux-saga/core/effects';
import SupportAction from '../redux/support/types';
import { getSupport, getUser, showErrorMessage } from './sagasUtils';
import { actionCreator } from '../shared/redux/actionHelper';
import {
  requestCreateCustomColor,
  requestDeleteCustomColor,
  requestUpdateCustomColor,
} from '../utils/request';

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function* updateCustomColor(action) {
  const { id, value } = action.payload;
  try {
    yield put(
      actionCreator(SupportAction.ColorPalette.UpdateCustomColorR, {
        id,
        value,
      }),
    );
    yield call(requestUpdateCustomColor, {
      id,
      fieldsUpdateObg: { hexColor: `"${value}"` },
    });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* addCustomColor(action) {
  const { id, value, position } = action.payload;
  try {
    const { id: userId } = yield select(getUser);
    yield put(
      actionCreator(SupportAction.ColorPalette.AddCustomColorR, {
        id,
        value,
        position,
      }),
    );
    // yield wrapperRequestNeo4j(queryAddCustomColor(id, value, userId, position));
    yield call(requestCreateCustomColor, {
      id,
      hexColor: value,
      ownerId: userId,
      position,
    });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* deleteCustomColor(action) {
  const { id } = action.payload;
  try {
    // const { data } = yield wrapperRequestNeo4j(queryDeleteCustomColor(id));
    const { data } = yield call(requestDeleteCustomColor, { ids: [id] });
    if (data.DeleteColor.id === id) {
      yield put(
        actionCreator(SupportAction.ColorPalette.DeleteCustomColorR, {
          id,
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* resetCustomColors(action) {
  try {
    const { colorsIds } = yield select(getSupport);
    // const { data } = yield wrapperRequestNeo4j(queryDeleteManyColors(colorsIds));
    const { data } = yield call(requestDeleteCustomColor, { ids: colorsIds });
    if (!data.errors) {
      yield put(
        actionCreator(SupportAction.ColorPalette.ResetAllCustomColorsR),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

export default function* colorsSaga() {
  yield takeLatest(
    SupportAction.ColorPalette.UpdateCustomColorS,
    updateCustomColor,
  );
  yield takeEvery(SupportAction.ColorPalette.AddCustomColorS, addCustomColor);
  yield takeEvery(
    SupportAction.ColorPalette.DeleteCustomColorS,
    deleteCustomColor,
  );
  yield takeEvery(
    SupportAction.ColorPalette.ResetAllCustomColorsS,
    resetCustomColors,
  );
}
