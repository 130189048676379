import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { DATA_PARENTS } from '../../utils/constants';
import { ReactComponent as TOC20Svg } from '../../images/icons/icon_50/TOCMaker_lines.svg';
import { ReactComponent as Close13Svg } from '../../images/icons/player/cross_close_13.svg';
import { ListGridButtonWithTooltip } from '../../components/LibraryHeaderMenu/ListGridButton';
import { TOCPlayerItemUI } from '../../utils/UIHelpers/TableOfContent/TOCHelpersUI';
import { actionCreator } from '../../shared/redux/actionHelper';
import Playlists from '../../redux/playlists/types';
import CloseSection from './CloseSection';
import useComponentVisible from '../../utils/hooks/useComponentVisible';

const PlayerTOCShell = styled.div`
  left: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  transition: all ease-in-out 0.25s;
  position: absolute;
  z-index: 11;
  ${({ isExpanded }) => (isExpanded
    ? `
    width: 327px;
    box-shadow: 2px 0 8px 1px rgba(0, 0, 0, 0.05);
    background: #FCFDFF;
    left: 0;
    justify-content: flex-start;
    padding-top: 26px;
    padding-left: 32px;
    height: 100vh;
  `
    : `
    width: 50px;
    height: 50px;
    top:50%;
    transform: translateY(-50%)
  `)}

  ${({ isFullScreenMode, isMouseMoveShowControls }) => (isFullScreenMode && !isMouseMoveShowControls
    ? `
    left: -60px;
  `
    : `
    
  `)}
`;
const TOCHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
 
`;

const TOCWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 0.6px solid #ECE2E2;
  box-shadow: 0px 1px 4px 0px rgba(122, 105, 105, 0.10);
  cursor: pointer;
  background-color: #fff;

  svg {
    width: 24px;
    height: 24px;  
  }
`;

const Icon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: #d7ddf7;
  }

  ${({ active }) => (active ? 'background: #D7DDF7;' : '')}

  background: #FFFFFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  right: 29px;
  top: -1px;
  transition: all ease-in-out 0.25s;
  border-radius: 18px;
  ${({ isExpanded }) => (isExpanded
    ? `
    opacity: 1;
  `
    : `
    opacity: 0;
    right: 100px;
  `)}
  &:hover{
    background:#ffeebf;
  }
`;

const TOCTitle = styled.div`
  font-size: 16px;
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #343434;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const TOCContent = styled.div`
  overflow: auto;
  overflow-x: hidden;

  ${({ isExpanded }) => (isExpanded
    ? `
    height: 92.5vh;
    padding-top: 6px;
  `
    : `
    height: 0px;
  `)}

  &::-webkit-scrollbar {
    width: 4px;
    @if $width {
      width: 4px;
    }
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    @if $width {
      border-radius: 4px;
    }
    background-color: #dbdbdb;
    -webkit-box-shadow: inset 0 0 6px #dbdbdb;
    margin-right: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    @if $width {
      border-radius: 4px;
    }
    position: absolute;
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background: #dbdbdb;
  }
`;
const TOCBottomPlaceholder = styled.div`
  height: 32px;
  width: 100%;
`;

const SeparatorLine = styled.div`
  height: 1px;
  background-color: #e8ebfa;
  width: 262px;
  margin-right: 16px;

  margin-top: 5px;
  margin-bottom: 5px;
`;

const PlayerTableOfContents = ({ ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams();
  const {
    playerSettings: { isFullScreenMode },
  } = useSelector((state) => state.user);
  const [itemRef, isExpanded, setIsExpanded] = useComponentVisible(
    false,
    DATA_PARENTS.TableOfContents,
  );
  const isFromPlaylist = history.location.pathname.includes('/playlist/')
    || history.location.pathname.includes('/view_playlist/')
    || history.location.pathname.includes('/shared_playlist/');
  const [isCardView, setIsCardView] = useState(true);

  const [animationClass, setAnimationClass] = useState('init');
  const currentPage = useSelector((state) => state.currentPage);
  const user = useSelector((state) => state.user);
  const isOwner = user.id && (user.id === currentPage.owner?.id);

  // const isShared = history.location.pathname.startsWith('/shared_playlist/')
  //   || history.location.pathname.startsWith('/shared_playlist_co_edit/');

  useEffect(() => {
    if (animationClass === 'init') {
      setAnimationClass('');
      return;
    }

    if (isExpanded) {
      setAnimationClass({ class: 'animate_in' });
    } else {
      setAnimationClass({ class: 'animate_out' });
    }
  }, [isExpanded]);
  const {
    isShowLibraryWidget,
    // id: playlistId,
    isFinished,
    wrapperId,
    // isOwnContent
  } = useSelector((state) => state.currentPage);

  useEffect(() => {
    if (isFromPlaylist) {
      setTimeout(() => setIsExpanded(true), 0);
    }
  }, []);

  useEffect(() => {
    if (props.refTOC) {
      props.setIsTOCOpened(false);
    }
    setIsExpanded(false);
  }, [isShowLibraryWidget]);

  const expandToggler = () => {
    if (props.refTOC) {
      props.setIsTOCOpened((prev) => !prev);
      return;
    }
    setIsExpanded((prev) => !prev);
  };

  const onClickClosePlaylist = (event) => {
    event.stopPropagation();

    dispatch(
      actionCreator(Playlists.MarkViewedS, {
        value: !isFinished,
        playlistId: params.id,
        channelId: params.channelId,
        wrapperId,
      }),
    );
  };

  return (
    <PlayerTOCShell
      data-parent={props.dataParent || DATA_PARENTS.TableOfContents}
      ref={itemRef}
      isExpanded={isExpanded}
      isFullScreenMode={isFullScreenMode}
      isMouseMoveShowControls={props.isMouseMoveShowControls}
    >
      <TOCHeader>
        {isExpanded && (
          <ListGridButtonWithTooltip
            direction="down"
            isLocalState={isCardView}
            setIsLocalState={setIsCardView}
          />
        )}
        {!isExpanded && (
          <TOCWrapper>
            <TOC20Svg onClick={expandToggler} />
          </TOCWrapper>
        )}

        {isExpanded && <TOCTitle>{t('TOCTitleT')}</TOCTitle>}

        <CloseIcon onClick={expandToggler} isExpanded={isExpanded}>
          <Close13Svg />
        </CloseIcon>
      </TOCHeader>

      <TOCContent
        data-parent={props.dataParent || DATA_PARENTS.TableOfContents}
        isExpanded={isExpanded}
        ref={props?.scrollRef}
      >
        {props.rawPages.map((item, index) => (
          <TOCPlayerItemUI
            key={`TableOfContent_${item.id}`}
            item={item}
            index={index}
            isSelected={props.selectedIds.includes(item.id)}
            onClickHandler={props.scrollToItem}
            itemStylesObject={props.lineStylesObject[item.id]}
            downloadFile={props.downloadFile}
            isCardView={isCardView}
          />
        ))}
        {!props.isForSharedToWeb && !isOwner && (
          <>
            <SeparatorLine />
            <CloseSection
              currentRole={props.currentRole}
              wrapperId={wrapperId}
              isFinished={isFinished}
              onClickClosePlaylist={onClickClosePlaylist}
            />
          </>
        )}
        <TOCBottomPlaceholder />
      </TOCContent>
    </PlayerTOCShell>
  );
};

export default PlayerTableOfContents;
