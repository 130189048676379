import { buffers, END, eventChannel } from 'redux-saga';

export function createUploadFileChannel(endpoint, file, id) {
  const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');

  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();
    //       token: token ? `${token}` : '',
    //       'content-type': 'multipart/form-data',

    const onProgress = (e) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({
          progress,
          abort: () => {
            xhr.abort();
          },
        });
      }
    };

    const onLoad = (e) => {
      // if (e.lengthComputable) {
      //   const progress = e.loaded / e.total;
      //   emitter({ progress });
      // }
    };
    const onFailure = (e) => {
      emitter({ err: new Error('Upload failed') });
      emitter(END);
    };
    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);
    xhr.upload.addEventListener('onload', onLoad);
    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === XMLHttpRequest.DONE) {
        if (status === 200) {
          emitter({ success: true, res: JSON.parse(xhr.response) });
          emitter(END);
        } else {
          onFailure(null);
        }
      }
    };
    xhr.open('POST', endpoint, true);
    // xhr.setRequestHeader('content-type', 'multipart/form-data');
    xhr.setRequestHeader('token', token);
    if (id) {
      xhr.setRequestHeader('id', id);
    }
    try {
      xhr.send(file);
    } catch (err) {
      onFailure(null);
    }
    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}
