export const DeletedActionType = {
  getDeleted: '[Content] get deleted content',
  deletePlaylist: '[Content] deletePlaylist',
  deleteLibItem: '[Content] delete Library item',
  deleteInboxCard: '[Content] deleteInboxCard',
  restorePlaylistSR: '[Content] restore Playlist Saga Redux',
  restoreLibItemSR: '[Content] restore Library item Saga Redux',
  restorePlaylistR: '[Content] restore Playlist Redux',
  restoreLibItemR: '[Content] restore Library item Redux',
  finishUpload: '[Content] finish upload deleted content',
};
