import mimeTypes from './utils/mimeTypes';

interface IMaxFileSize {
  bytes: number,
  text: string,
}

export const maxFileSize:IMaxFileSize = {
  bytes: 26_214_400,
  text: '25 MB',
};

interface IMaxAvatarSize {
  bytes: number,
  text: string,
  formats: { [key: string]: boolean }
}
export const maxAvatarSize:IMaxAvatarSize = {
  bytes: 2_097_152,
  text: '2 MB',
  formats: mimeTypes.image,
};

interface ImaxEmojiSize {
  bytes: number,
  text: string,
  formats: {
    'image/gif': boolean,
    'image/jpeg': boolean,
    'image/png': boolean,
  },
  dimensionsPixels: {
    width: number,
    height: number,
  },
}

export const maxEmojiSize: ImaxEmojiSize = {
  bytes: 128_000,
  text: '128 kB',
  formats: {
    'image/gif': true, // .gif  Graphics Interchange Format
    'image/jpeg': true, // .jpeg, .jpg  JPEG Image
    'image/png': true, // .png  Portable Network Graphics (PNG)
  },
  dimensionsPixels: {
    width: 128,
    height: 128,
  },
};
