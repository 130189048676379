import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Alert20Svg } from '../images/icons/alert_circle_20.svg';
import { ReactComponent as AlertFilledSvg } from '../images/icons/alert_circle_filled_red.svg';

const ErrorDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 0;
  opacity: 0;
  transition: ease-in-out all 0.3s;
  border: 1px solid transparent;
  border-radius: 6px;
  color: #c21c44;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 8px;
  line-height: 14px;
  & svg {
    margin-right: 8px;
  }
  
  ${(props) => (props.active
    ? 'background: #FFEEF2;'
        + 'height: 32px;'
        + 'opacity: 1;'
        + 'margin-top: 8px;'
        + 'margin-bottom: 8px;'
    : '')}
  ${(isLogin) => (isLogin
    ? `
      background: transparent;
      margin: 0;
      height: 24px;`
    : '')}
  ${(isSignUp) => (isSignUp
    ? `
      height: 21px;`
    : '')}
  ${(isShare) => (isShare
    ? `
      height: 27px;
      padding-bottom: 10px;
      padding-left: 0;
      `
    : '')}
`;
const Icon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 6px;
  ${(isLogin) => (isLogin
    ? `
      width: 15px;
      height: 15px;`
    : '')}
  ${({ isShare }) => (isShare
    ? `
  width: 20px;
  height: 20px;`
    : '')}
`;

const Error = ({ error, isShare, isLogin, isSignUp }) => (
  <ErrorDiv active={error} isShare={isShare} isLogin={isLogin} isSignUp={isSignUp}>
    {!!error && (
      <>
        <Icon isShare={isShare}>
          {isLogin ? <AlertFilledSvg /> : <Alert20Svg />}
        </Icon>{' '}
        {error}
      </>
    )}
  </ErrorDiv>
);

export default Error;
