import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import styles from './MediaAdd.module.scss';
import { ReactComponent as CheckSvg } from '../../images/icons/upload_check_20.svg';
import { ReactComponent as CloseSvg } from '../../images/icons/close32.svg';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { Circle } from '../../utils/UIHelpers/PlaylistBuilderElements/libraryComponent';
import { empty } from '../../utils/constants';

const cx = classNames.bind(styles);

const PrevImage = styled.img`
  top: 0;
  left: 0;
  max-height: 120px;
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
`;
const WrapPrevImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 120px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BlockImageUpload = ({ cancel, step }) => {
  const uploadData = useSelector((state) => state.uploads.components[step.newId]) || empty;
  const [url, setUrl] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (uploadData.file && !url) {
      const src = URL.createObjectURL(uploadData.file);
      setUrl(src);
    }
  }, [uploadData]);

  const progressBarStatus = (progress) => {
    if (progress === 1) {
      if (uploadData.status === 'READY') {
        return <CheckSvg />;
      }
      return (
        <div className={styles.circle_loader_wrapper}>
          <div className={styles.circle_loader} />
          <div className={styles.uploading_text}>{t('processingT')}</div>
        </div>
      );
    }
    return (
      <>
        <Circle progress={(uploadData.progress || 0) * 100} color="#0026E5" />
        <div className={styles.uploading_text}>
          {' '}
          {`${parseInt((uploadData.progress || 0) * 100)}%`}{' '}
        </div>
      </>
    );
  };
  return (
    <>
      <div className={cx('addImage')}>
        <div className={cx('imageWrapper')}>
          {step.status !== 'googlePickerPrecessing' && (
            <>
              {progressBarStatus(uploadData.progress, uploadData.status)}
              {url && (
                <WrapPrevImage>
                  {' '}
                  <PrevImage src={url} />{' '}
                </WrapPrevImage>
              )}
            </>
          )}
          {step.status === 'googlePickerPrecessing' && (
            <>{progressBarStatus(1)}</>
          )}
        </div>
        <div className={cx('menu_option')}>
          <div className={cx('info')}>
            <div className={cx('name')}>{step.title || t('unnamedT')}</div>
            {step.size && (
              <div className={cx('description')}>
                <div>{(step.size / 1024).toFixed(1)}KB</div>
              </div>
            )}
          </div>
          <div onClick={cancel} className={cx('cancel')}>
            <Tooltip text={t('cancelUploadingT')} direction="down">
              <div className={cx('cancel_image_wrapper')}>
                <CloseSvg />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockImageUpload;
