import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MomentBlock.module.css';

const AddLink = ({
  refLink,
  confirmLink,
  close,
  toggleSelect,
  toolbarRef,
  editorWrapperRef,
  initialUrl,
}) => {
  const { t } = useTranslation();
  const [style, setStyle] = useState({});
  const refLinkInput = useRef(null);
  const [url, serUrl] = useState(initialUrl);

  useEffect(() => {
    const editorWrapperLeft = editorWrapperRef?.current?.getBoundingClientRect().left;
    const editorWrapperTop = editorWrapperRef?.current?.getBoundingClientRect().top;
    const { top, left } = toolbarRef.current.getBoundingClientRect();

    setStyle({
      top: top - editorWrapperTop + 80,
      left: left - editorWrapperLeft + 100,
      visibility: 'visible',
    });
    //
    setTimeout(() => {
      refLinkInput?.current?.select();
    }, 0);
    refLinkInput?.current?.select();
    return () => toggleSelect();
  }, [refLinkInput.current]);

  const confirm = (e) => {
    if (url.trim()) {
      confirmLink(e, url.trim());
    }
    close();
  };

  const enterHandler = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') confirm(e);
  };

  const prevent = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <div
        onMouseDown={prevent}
        onMouseUp={prevent}
        onClick={prevent}
        ref={refLink}
        style={style}
        className={`${styles.link1} `}
      >
        <input
          placeholder={t('pasteALinkT')}
          onKeyDown={enterHandler}
          value={url}
          autoFocus
          onChange={(e) => serUrl(e.target.value)}
          ref={refLinkInput}
          className={styles.link_input}
        />
        <div onClick={confirm} className={styles.link_apply}>
          {t('applyUpT')}
        </div>
      </div>
    </>
  );
};

export default AddLink;
