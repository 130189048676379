import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React, { useEffect } from 'react';

export const UpdatePlugin = ({ state, isActive, preventUpdateOutside }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!state) return;
    if (!Object.values(state).length) return;
    if (preventUpdateOutside?.current) {
      // case
      preventUpdateOutside.current = false;
      return;
    }

    const oldState = JSON.stringify(editor.getEditorState().toJSON());
    const newState = JSON.stringify(state);
    if (newState === oldState) return;
    const editorState = editor.parseEditorState(
      state,
    );
    editor.setEditorState(editorState);
  }, [state]);

  useEffect(() => {
    if (!isActive) return;
    editor.focus();
  }, [isActive]);

  return <></>;
};
