import { LOCAL_SORT_MODE, LOCAL_SORT_TYPE } from './SortOptionENUM';

export const sortByType = (key, sortMode, sortAction = () => {}) => {
  if (sortMode === LOCAL_SORT_MODE.ASCENDING) {
    switch (key) {
      case LOCAL_SORT_TYPE.NAME:
        sortAction('nameAtoZ');
        return 'nameAtoZ';
      case LOCAL_SORT_TYPE.MODIFIED:
        sortAction('firstModified');
        return 'firstModified';
      case LOCAL_SORT_TYPE.CREATED:
        sortAction('firstAdded');
        return 'firstAdded';
      case LOCAL_SORT_TYPE.TYPE:
        sortAction('typeAtoZ');
        return 'typeAtoZ';
      case LOCAL_SORT_TYPE.SIZE:
        break;
      case LOCAL_SORT_TYPE.AVAILABILITY:
        break;
      case LOCAL_SORT_TYPE.BOARD:
        break;
      case LOCAL_SORT_TYPE.PROGRESS:
        break;
    }
  } else {
    switch (key) {
      case LOCAL_SORT_TYPE.NAME:
        sortAction('nameZtoA');
        return 'nameZtoA';
      case LOCAL_SORT_TYPE.MODIFIED:
        sortAction('lastModified');
        return 'lastModified';
      case LOCAL_SORT_TYPE.CREATED:
        sortAction('lastAdded');
        return 'lastAdded';
      case LOCAL_SORT_TYPE.TYPE:
        sortAction('typeZtoA');
        return 'typeZtoA';
      case LOCAL_SORT_TYPE.SIZE:
        break;
      case LOCAL_SORT_TYPE.AVAILABILITY:
        break;
      case LOCAL_SORT_TYPE.BOARD:
        break;
      case LOCAL_SORT_TYPE.PROGRESS:
        break;
    }
  }
};

export const sortByMode = (key, sortType, sortAction) => {
  if (key === LOCAL_SORT_MODE.ASCENDING) {
    switch (sortType) {
      case LOCAL_SORT_TYPE.NAME:
        sortAction('nameAtoZ');
        return 'nameAtoZ';
      case LOCAL_SORT_TYPE.MODIFIED:
        sortAction('firstModified');
        return 'firstModified';
      case LOCAL_SORT_TYPE.CREATED:
        sortAction('firstAdded');
        return 'firstAdded';
      case LOCAL_SORT_TYPE.TYPE:
        sortAction('typeAtoZ');
        return 'typeAtoZ';
      case LOCAL_SORT_TYPE.SIZE:
        break;
      case LOCAL_SORT_TYPE.AVAILABILITY:
        break;
      case LOCAL_SORT_TYPE.BOARD:
        break;
      case LOCAL_SORT_TYPE.PROGRESS:
        break;
    }
  } else {
    switch (sortType) {
      case LOCAL_SORT_TYPE.NAME:
        sortAction('nameZtoA');
        return 'nameZtoA';
      case LOCAL_SORT_TYPE.MODIFIED:
        sortAction('lastModified');
        return 'lastModified';
      case LOCAL_SORT_TYPE.CREATED:
        sortAction('lastAdded');
        return 'lastAdded';
      case LOCAL_SORT_TYPE.TYPE:
        sortAction('typeZtoA');
        return 'typeZtoA';
      case LOCAL_SORT_TYPE.SIZE:
        break;
      case LOCAL_SORT_TYPE.AVAILABILITY:
        break;
      case LOCAL_SORT_TYPE.BOARD:
        break;
      case LOCAL_SORT_TYPE.PROGRESS:
        break;
    }
  }
};
/**
 * Filter for sorting options.
 * for library - size and type
 * for playlist/channels - availability/board/progress
 */
export const filterByPlacement = (place) => {
  // case for library
  if (!(place === 'smartfiles' || place.includes('Channels'))) {
    return Object.keys(LOCAL_SORT_TYPE).filter(
      (it) => it !== LOCAL_SORT_TYPE.BOARD
        && it !== LOCAL_SORT_TYPE.PROGRESS
        && it !== LOCAL_SORT_TYPE.AVAILABILITY,
    );
  }
  // case playlist or channels
  return Object.keys(LOCAL_SORT_TYPE).filter(
    (it) => it !== LOCAL_SORT_TYPE.SIZE && it !== LOCAL_SORT_TYPE.TYPE,
  );
};
