import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import styles from './MediaAdd.module.scss';
import { ReactComponent as ErrorMoreSvg } from '../../images/icons/error_upload_32.svg';
import { ReactComponent as ErrorDamageSvg } from '../../images/icons/image_damage_32.svg';
import { ReactComponent as CloseSvg } from '../../images/icons/close32.svg';
import Tooltip from '../../shared/Tooltips/Tooltip';

const cx = classNames.bind(styles);

const BlockImageError = ({ step, cancel }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={cx('imageWrapper')}>
        {step.info === 'isMore' && <ErrorMoreSvg />}
        {step.info === 'isDamage' && <ErrorDamageSvg />}
      </div>
      <div className={cx('menu_option')}>
        <div className={cx('info')}>
          <div className={cx('name')}>{step.title}</div>
          <div className={cx('description')}>
            {step.info === 'isMore' && <div>{t('fileSizeMoreThan10mbT')}</div>}
            {step.info === 'isDamage' && <div>{t('uploadImagesOnlyT')}</div>}
          </div>
        </div>
        <div onClick={cancel} className={cx('cancel')}>
          <Tooltip text={t('cancelUploadingT')} direction="down">
            <div className={cx('cancel_image_wrapper')}>
              <CloseSvg />
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default BlockImageError;
