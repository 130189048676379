import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import FlagsApi from 'country-flag-icons/react/3x2';
import { hasFlag } from 'country-flag-icons';
import rawStyles from './index.module.scss';
import { ReactComponent as ChevronDown12Svg } from '../../../../images/icons/ic_down.svg';
import CountryPickerDropDown from './CountryPickerDropDown';
import { ReactComponent as UnknownFlagSvg } from '../../../../images/icons/UNKNOWN_FLAG.svg';

const cn = classNames.bind(rawStyles);

const CountryPicker = ({ selectedCountry, onChangeCountry, ...props }) => {
  const [isDropDownActive, setIsDropDownActive] = useState(false);
  const pickerRef = useRef(null);

  const clickOutsideHandler = () => {
    setIsDropDownActive(false);
  };

  return (
    <div className={cn('wrapper')} ref={pickerRef}>
      <div
        className={cn('selected_country')}
        onClick={() => setIsDropDownActive((p) => !p)}
      >
        <div className={cn('flag')}>
          {hasFlag(selectedCountry?.code) && (
            <div className={cn('flag_container')}>
              <div className={cn('flag_icon_border')} />
              {React.createElement(FlagsApi[selectedCountry?.code], {
                className: cn('flag_icon'),
              })}
            </div>
          )}
          {!hasFlag(selectedCountry?.code) && (
            <div className={cn('flag_container')}>
              <div className={cn('flag_icon_border')} />
              <div className={cn('unknown_flag_icon')}>
                <UnknownFlagSvg />
                <div className={cn('question')}>?</div>
              </div>
            </div>
          )}
          <ChevronDown12Svg className={cn('arrow')} />
        </div>
      </div>
      <div className={cn('prefix')}>{selectedCountry?.prefix}</div>

      {props.children}

      {isDropDownActive && (
        <div className={cn('underlay')} onClick={clickOutsideHandler} />
      )}

      {isDropDownActive && (
        <CountryPickerDropDown
          parentRef={pickerRef}
          onChangeCountry={onChangeCountry}
          selectedCountry={selectedCountry}
          setIsDropDownActive={setIsDropDownActive}
        />
      )}
    </div>
  );
};

export default CountryPicker;
