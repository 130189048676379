import { MuxContentStatus } from './types';

const defaultState = {
  preparing: [],
};

export default (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case MuxContentStatus.addNew: {
      return {
        ...state,
        preparing: [...state.preparing, payload],
      };
    }
    case MuxContentStatus.remove: {
      return {
        ...state,
        preparing: [...state.preparing.filter((it) => it.id !== payload)],
      };
    }

    default: {
      return state;
    }
  }
};
