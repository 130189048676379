import * as React from 'react';
import SortComponent from '../childComponents/SortComponent';
import i18n from '../../../../i18n';

export const generateColumns = (createTableColumn, setSort, isSharedToMe) => {
  return [
    createTableColumn({
      columnId: 'checkbox',
      renderHeaderCell: () => <></>,
    }),
    createTableColumn({
      columnId: 'image',
      renderHeaderCell: () => <></>,
    }),
    createTableColumn({
      columnId: 'name',
      renderHeaderCell: () => <SortComponent isName setSort={setSort} />,
    }),
    createTableColumn({
      columnId: 'sender',
      renderHeaderCell: () => <SortComponent isSender setSort={setSort} />,
    }),
    createTableColumn({
      columnId: 'board',
      renderHeaderCell: () => <div className="custom_header">{i18n.t('boardT')}</div>,
    }),
    createTableColumn({
      columnId: 'availability',
      renderHeaderCell: () => <div className="custom_header">{i18n.t('availabilityT')}</div>,
    }),
    createTableColumn({
      columnId: 'created',
      renderHeaderCell: () => <SortComponent isCreated setSort={setSort} />,
    }),
    createTableColumn({
      columnId: 'modified',
      renderHeaderCell: () => <SortComponent isModified setSort={setSort} />,
    }),
    createTableColumn({
      columnId: 'size',
      renderHeaderCell: () => <SortComponent isSize setSort={setSort} />,
    }),
    createTableColumn({
      columnId: 'progress',
      renderHeaderCell: () => isSharedToMe ? <div className="custom_header">{i18n.t('progressT')}</div> : <></>,
    }),
    createTableColumn({
      columnId: 'actions1',
      renderHeaderCell: () => (
        <div style={{ textAlign: 'right', width: '100%', paddingRight: 19 }}>
          {i18n.t('actionsT')}
        </div>
      ),
    }),
  ];
};
const getWidth = (k) => ((window.innerWidth - 300) * k) / 100;
export const generateColumnSizing = (isSharedToMe) => {
  return {
    checkbox: {
      idealWidth: 40,
      minWidth: 40,
      maxWidth: 40,
    },
    image: {
      idealWidth: 40,
      minWidth: 40,
      maxWidth: 40,
    },
    name: {
      minWidth: 100,
      defaultWidth: getWidth(12.96), // 17.6
    },
    sender: {
      minWidth: 66,
      maxWidth: 100,
      defaultWidth: getWidth(9.96), // 15.46
    },
    board: {
      minWidth: 66,
      maxWidth: 100,
      defaultWidth: getWidth(9.96), // 15.46
    },
    availability: {
      minWidth: 120,
      defaultWidth: getWidth(14), // 16.15
    },
    created: {
      minWidth: 120,
      defaultWidth: getWidth(14), // 10.75
    },
    modified: {
      minWidth: 80,
      defaultWidth: getWidth(10), // 10.6
    },
    size: {
      minWidth: 66,
      maxWidth: 100,
      defaultWidth: getWidth(9.96), // 15.46
    },
    progress: {
      minWidth: isSharedToMe ? 140 : 0,
      defaultWidth: getWidth(isSharedToMe ? 140 : 0), // 12.3
    },
    actions1: {
      minWidth: 100,
      defaultWidth: getWidth(4), // 3.07
    },
  };
};
