import styled from 'styled-components/macro';
import {
  CardBodyInfo,
  CardInfoChannel,
  CardInfoSize,
  HeaderWrapper,
  InfoBlock,
  SelectCheckBoxWrapper,
} from '../Inbox/styled';


const hiddenBySize = {
  firstStep: 1400,
  twoStep: 1200,
  lastStep: 900,
};

export const CardBodyInfoOutbox = styled(CardBodyInfo)`
  width: 28%;
  font-weight: 500;
  margin: 0;
  @media (max-width: ${hiddenBySize.firstStep}px) {
    width: 28%;
  }
  @media (max-width: ${hiddenBySize.twoStep}px) {
    width: 40%;
  }
  @media (max-width: ${hiddenBySize.lastStep}px) {
    order: 3;
    width: 95%;
  }
`;


export const CardInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ isHasUpdate }) => (isHasUpdate
    ? 'width: calc(100% - 40px);'
    : 'width: 100%;'
  )}
}

`;

export const CardInfoWrapperHelpWr = styled.div`
  position: relative;
  width: 100%;
`;


export const SelectCheckBoxWrapperOutBox = styled(SelectCheckBoxWrapper)`
  @media (max-width: ${hiddenBySize.lastStep}px) {
    top: 2px;
    left: -5px;
  }
`;

export const CardInfoChannelOutbox = styled(CardInfoChannel)`
  width: 23%;
  display: inherit;
  flex-direction: column;
  @media (max-width: ${hiddenBySize.firstStep}px) {
    width: 30%;
  }
  @media (max-width: ${hiddenBySize.twoStep}px) {
    width: 40%;
  }
  @media (max-width: ${hiddenBySize.lastStep}px) {
    width: 70%;
    padding-left: 25px;
  }

`;
export const CardInfoSizeOutbox = styled(CardInfoSize)`
  @media (max-width: ${hiddenBySize.firstStep}px) {
    display: none;
  }
`;
export const InfoBlockOutbox = styled(InfoBlock)`
  width: 8%;

  @media (max-width: ${hiddenBySize.twoStep}px) {
    display: none;
  }
`;

export const AvailableBlockOutbox = styled(CardInfoSize)`
  @media (max-width: ${hiddenBySize.firstStep}px) {
    display: none;
  }
`;

export const CardInfoHeaderInOutbox = styled(CardBodyInfoOutbox)`
  padding-left: 60px;
  @media (max-width: ${hiddenBySize.lastStep}px) {
    display: none;
  }
`;
export const HeaderWrapperOutbox = styled(HeaderWrapper)`
  padding-left: 16px;
  padding-right: 40px;
`;
