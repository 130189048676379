import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import { ReactComponent as DuplicateSvg } from '../../images/icons/duplicate.svg';
import { ReactComponent as TurnIntoSvg } from '../../images/icons/turninto.svg';
import { ReactComponent as CommentSvg } from '../../images/icons/comment.svg';
import { ReactComponent as CreateComponentSvg } from '../../images/icons/create_component.svg';
import { ReactComponent as EyeOffSvg } from '../../images/icons/eye_off.svg';
import { ReactComponent as EyeOnSvg } from '../../images/icons/eye_on.svg';
import { ReactComponent as DeleteRedSvg } from '../../images/icons/delete_red.svg';
import chevronRightSmall from '../../images/icons/chevron_right_small.svg';
import TurnIntoBlock from './TurnIntoBlock';
import { actionDetachComponent } from '../../redux/library/actions';
import { BlockTypes } from '../../utils/constants';

const BlockOption = ({
  isOpenMenu,
  setBlocksToCreateLComponent,
  blocksIds,
  blocksIndices,
  setIsOpenMenu,
  deleteEditableBlock,
  canTurnInto,
  selectAddEditableBlock,
  addCloneBlock,
  isHideCreateComponentOption,
  playlistField,
  parentRef,
  canDetachComponent,
  ...props
}) => {
  const { t } = useTranslation();
  const turnInfoRef = useRef(null);
  const refMenu = useRef(null);
  const [canTurnIntoMultiSelect, setCanTurnIntoMultiSelect] = useState(canTurnInto);
  const [canDetachComponentMultiSelect, setCanDetachComponentMultiSelect] = useState(canDetachComponent);
  const [canCreateComponentMultiSelect, setCanCreateComponentMultiSelect] = useState(false);
  const { isOpenCreateComponent } = useSelector((state) => state.library);
  const { draggableBlocks, additionalInformation } = useSelector(
    (state) => state.currentPage,
  );
  const { blocks } = useSelector((state) => (isOpenCreateComponent ? state.support : state.currentPage),
  );
  const dispatch = useDispatch();

  const calculatePosition = () => {
    if (parentRef.current) {
      const height = document.documentElement.clientHeight;
      const positionY = parentRef.current.getBoundingClientRect().y;
      const heightElement = refMenu?.current?.getBoundingClientRect().height;
      const isOpenInUp = height > heightElement + positionY + 30;
      if (isOpenInUp) return 30;
      return -heightElement;
    }
    return 0;
  };
  const divStyle = {
    top: calculatePosition(),
  };

  const canTurnSelectBlock = (type) => type
    && type !== BlockTypes.image
    && type !== BlockTypes.video
    && type !== BlockTypes.component
    && type !== BlockTypes.page
    && type !== BlockTypes.webSite
    && type !== BlockTypes.shortText
    && type !== BlockTypes.approveButton
    && type !== BlockTypes.actionButton
    && type !== BlockTypes.lineSeparator;

  useEffect(() => {
    if (draggableBlocks.length) {
      let selectedBlock;
      if (!playlistField) {
        selectedBlock = blocks.filter((block) => draggableBlocks.includes(block.id),
        );
      } else {
        selectedBlock = additionalInformation[playlistField].filter((block) => draggableBlocks.includes(block.id),
        );
      }
      setCanDetachComponentMultiSelect(
        selectedBlock.every((block) => block?.type === BlockTypes.component),
      );
      setCanTurnIntoMultiSelect(
        selectedBlock.every((block) => canTurnSelectBlock(block?.type)),
      );
      setCanCreateComponentMultiSelect(
        selectedBlock.some((block) => block?.type === BlockTypes.component),
      );
    } else setCanTurnIntoMultiSelect(canTurnInto);
  }, [draggableBlocks]);

  const onCreateClickHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isHideCreateComponentOption) return;
    if (canCreateComponentMultiSelect) {
      setIsOpenMenu(!isOpenMenu);
      props.setOpenMenuDetach();
      return;
    }
    const newObjects = blocksIds.reduce((acc, item) => {
      return {
        ...acc,
        [item]: { indices: blocksIndices, status: 'pending', id: item },
      };
    }, {});
    setBlocksToCreateLComponent({ ...newObjects });
    setIsOpenMenu(!isOpenMenu);
    props.setIsCreateComponentMenuVisible
      && props.setIsCreateComponentMenuVisible(true);
  };
  const onDetachComponent = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isHideCreateComponentOption) return;
    dispatch(actionDetachComponent(draggableBlocks));
  };

  const hideMessageCalculator = (isBlockHidden, draggingBlocks) => {
    if (draggingBlocks.length > 1) return t('toggleIsHiddenT');
    return isBlockHidden ? t('showBlockT') : t('hideBlockT');
  };

  const hideIconCalculator = (isBlockHidden, draggingBlocks) => {
    if (draggingBlocks.length > 1) return <TurnIntoSvg />;
    return isBlockHidden ? <EyeOnSvg /> : <EyeOffSvg />;
  };

  const onHideToggleMouseDown = (e) => {
    props.toggleIsHidden(e, props.componentId, !props.isBlockHidden);
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <div ref={refMenu} style={divStyle} className={styles.editMenu}>
      <div
        onMouseDown={addCloneBlock}
        className={`${styles.editMenu__item} ${styles.editMenu__item_first}`}
      >
        <DuplicateSvg />
        <div className={`${styles.editMenu__text}`}>{t('duplicateT')}</div>
        <div className={`${styles.hot_key}`}>⌘ D</div>
      </div>

      {canTurnIntoMultiSelect && (
        <div
          ref={turnInfoRef}
          className={`${styles.editMenu__item}  
        ${styles.editMenu__turn_info} 
        ${!canTurnSelectBlock(props.type) ? styles.editMenu__item_disable : ''}
        `}
        >
          <TurnIntoSvg />
          <div className={`${styles.editMenu__text}`}>{t('turnIntoT')}</div>
          <img src={chevronRightSmall} alt="" className={`${styles.icon}`} />
          <div className={styles.editMenu__turn_block}>
            <TurnIntoBlock
              setIsOpenMenu={setIsOpenMenu}
              selectAddEditableBlock={selectAddEditableBlock}
              parentRef={turnInfoRef}
              clear={props.clear}
            />
          </div>
        </div>
      )}

      <div
        className={`${styles.editMenu__item} ${styles.editMenu__item_last}`}
        onMouseDown={onHideToggleMouseDown}
      >
        <div className={styles.image_wrapper}>
          {hideIconCalculator(props.isBlockHidden, draggableBlocks)}
        </div>
        <div className={`${styles.editMenu__text}`}>
          {hideMessageCalculator(props.isBlockHidden, draggableBlocks)}
        </div>
        <div className={`${styles.hot_key}`}>
          {props.isBlockHidden ? '⌘ ⇧ +' : '⌘ ⇧ -'}
        </div>
      </div>

      <div className={styles.editMenu__separator} />

      <div
        className={`${styles.editMenu__item} ${styles.editMenu__item_first}`}
      >
        <CommentSvg />
        <div className={`${styles.editMenu__text}`}>{t('commentT')}</div>
        <div className={`${styles.hot_key}`}>⇧ +</div>
      </div>
      {!props.isLibraryCreate
        && !canDetachComponentMultiSelect
        && !playlistField && (
          <div
            className={`${styles.editMenu__item}  
          ${styles.editMenu__item_last} `}
            onMouseDown={onCreateClickHandler}
          >
            <div className={`${styles.image_wrapper}`}>
              <CreateComponentSvg />
            </div>
            <div className={`${styles.editMenu__text}`}>{t('createComponentT')}</div>
            <div className={`${styles.hot_key}`}>⌘ ⇧ +</div>
          </div>
      )}
      {!props.isLibraryCreate
        && props.type === 'libComponent'
        && !playlistField && (
          <div
            className={`${styles.editMenu__item} `}
            onMouseDown={props.onEditComponentClickHandler}
          >
            <div className={`${styles.image_wrapper}`}>
              <CreateComponentSvg />
            </div>
            <div className={`${styles.editMenu__text}`}>{t('editComponentT')}</div>
          </div>
      )}

      {!props.isLibraryCreate && canDetachComponentMultiSelect && (
        <div
          className={`${styles.editMenu__item}  
          ${styles.editMenu__item_last}`}
          onMouseDown={onDetachComponent}
        >
          <CreateComponentSvg />
          <div className={`${styles.editMenu__text}`}>{t('detachComponentT')}</div>
          <div className={`${styles.hot_key}`}>⌘ ⇧ +</div>
        </div>
      )}

      <div className={styles.editMenu__separator} />

      <div
        className={`${styles.editMenu__item_delete} ${styles.editMenu__item_last} ${styles.editMenu__item_first}`}
        onMouseDown={(e) => {
          setIsOpenMenu(false);
          deleteEditableBlock(e);
        }}
      >
        <DeleteRedSvg />
        <div className={`${styles.editMenu__text} ${styles.editMenu__delete}`}>
          {t('deleteT')}
        </div>
      </div>
    </div>
  );
};

export default BlockOption;
