import React from 'react';
import { useTranslation } from 'react-i18next';
import listStyles from '../../../DropDownOption/index.module.css';
import { ReactComponent as LinkSvg } from '../../../../images/icons/link.svg';
import { ReactComponent as ImageSvg } from '../../../../images/icons/image.svg';

const PdfOptions = ({ setIsOptionVisible, update, parentRef, refOption }) => {
  const { t } = useTranslation();
  const calculatePosition = () => {
    if (parentRef.current) {
      return 65;
    }
    return 0;
  };
  const divStyle = {
    top: calculatePosition(),
  };

  return (
    <div
      style={divStyle}
      className={listStyles.dropdown_parse_option}
      ref={refOption}
    >
      <div
        className={listStyles.dropdown__item}
        onClick={() => {
          setIsOptionVisible(false);
          update('attachment');
        }}
      >
        <LinkSvg />
        <div> {t('keepAsAttachmentT')}</div>
      </div>

      <div
        className={listStyles.dropdown__item}
        onClick={() => {
          setIsOptionVisible(false);
          update('preview');
        }}
      >
        <ImageSvg />

        <div>{t('createPdfPreviewT')}</div>
      </div>
    </div>
  );
};

export default PdfOptions;
