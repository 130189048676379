import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './index.module.scss';
import { actionCreator } from '../../shared/redux/actionHelper';
import { Settings } from '../../redux/settings/types';

const LanguageSwitcher = ({ isLogin }) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const dispatch = useDispatch();

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'fr' : 'en';
    i18n.changeLanguage(newLang);
    dispatch(actionCreator(Settings.Preferences.ChangeLanguage, { newLang }));
    localStorage.setItem('language', newLang);
  };

  return (
    <div className={`${styles.langSwitcher} ${isLogin && styles.langSwitcherLogin}`}>
      <span className={styles.langName}>EN</span>
      <div className={styles.switch} onClick={toggleLanguage}>
        <input type="checkbox" onChange={toggleLanguage} checked={currentLang === 'fr'} />
        <span className={`${styles.slider} ${styles.round}`} />
      </div>
      <span className={styles.langName}>FR</span>
    </div>
  );
};

export default LanguageSwitcher;
