import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { actionCloseModal } from '../../redux/user/action';
import Button2023 from '../Buttons/Button2023';

const TitleWrapper = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #d3c8c8;
  margin-bottom: 10px;
`;

const ButtonBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 24px;
  padding-top: 12px;
  width: 100%;
`;
const ModalShell = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(147, 147, 147, 0.6);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Modal = styled.div`
  background: #fff;
  ${({ type }) => (type === 'default' ? `
    width: 325px;
    padding: 15px 16px 20px 17px;
  ` : `
    width: 335px;
    padding: 15px 16px 20px 15px;
  `)};
  ${({ type }) => (type === 'unpin' ? `
    width: 361px;
  ` : '')}
  padding: 20px;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
`;

const ModalText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #484343;
  margin-bottom: 7px;
`;

const ModalDescriptionText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #484343;
`;
const ConfirmModalUniversal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    dataPayload: {
      title = t('deleteUpT'),
      subject = t('areYouSureT'),
      description = t('permanentActionQuestionT'),
      confirm = () => {},
      cancelText = t('cancelT'),
      okText = t('deleteT'),
      type = 'default',
    },
  } = useSelector((redux) => redux.user);
  const close = () => {
    dispatch(actionCloseModal());
  };
  const confirmHandler = () => {
    confirm();
    close();
  };
  return (
    <ModalShell data-cy="modal-alert" onClick={close}>
      <Modal onClick={(e) => e.stopPropagation()} type={type}>
        <TitleWrapper>{title}</TitleWrapper>
        <ModalText>{subject}</ModalText>
        <ModalDescriptionText>{description}</ModalDescriptionText>
        <ButtonBlockWrapper>
          <Button2023
            variant="primary"
            width={69}
            height={32}
            text={cancelText}
            noMargins
            handleButtonClick={close}
          />
          <Button2023
            variant="secondary"
            height={32}
            text={okText}
            noMargins
            handleButtonClick={confirmHandler}
          />
        </ButtonBlockWrapper>
      </Modal>
    </ModalShell>
  );
};

export default ConfirmModalUniversal;
