import React from 'react';
import HeaderControls from './HeaderControls';
import {
  HeaderCounterWrapper,
  HeaderTitleWrapper,
  HeaderWrapper,
} from './styled';

const Header = ({ ...props }) => {
  const isNotTextBlock = props.item.type !== 'elementText';
  return (
    <HeaderWrapper className="fade_menu">
      {isNotTextBlock && <HeaderCounterWrapper>{props.counter}</HeaderCounterWrapper>}
      {isNotTextBlock && <HeaderTitleWrapper>{props.title}</HeaderTitleWrapper>}
      <HeaderControls
        goNext={props.goNext}
        isForLib={props.isForLib}
        activeN={props.activeN}
        isLocked={props.isLocked}
        item={props.item}
        beforeCloseHandler={props.beforeCloseHandler}
        {...props}
      />
    </HeaderWrapper>
  );
};

export default Header;
