import React, { useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import {
  calcIsDynamicCollection,
  LibCompSieveTypesSideBar,
  empty,
  emptyCallback,
  DEFAULT_TITLE,
  isTypeShowLikePdf,
} from '../../utils/constants';
import {
  actionAddComponentToDragState,
  actionAddLinkPagesToDragState,
  actionBulkAddComponentsToDragState,
  actionClearDragState,
} from '../../redux/library/actions';
import ActionDraggable from '../../redux/dragable/action';
import { actionRemoveSelectedPage } from '../../redux/selectedPage/action';
import {
  actionDragManyPage,
  actionDragPage,
} from '../../redux/currentPage/action';
import { singleClickHandlerWrapper } from '../../utils/tableHelpers/singleClickHandler';
import { toggleSelectedWrapper } from '../../utils/tableHelpers/toggleSelected';

import NewPlaylist2023 from '../MiniPlaylist/NewPlaylist2023/NewPlaylist2023';
import { FluentTableView } from '../MiniPlaylist/NewPlaylist2023/table/FluentTableView';
import { FluentTableViewForLib } from '../LibraryTableView';
import { PlaylistGap } from '../MiniPlaylist/NewPlaylist2023/styled';

import LibraryMiniPage from '../LibraryMiniPage';

import styles from './index.module.css';

const initItem2 = {
  playlists: '[LibComponent]Playlists',
  users_smartfiles: '[LibComponent]Playlists',
  users_smartfiles_favorite: '[LibComponent]Playlists',
  smartfiles: '[LibComponent]Playlists',
  pages: '[LibComponent]Pages',
  components: '[LibComponent]Components',
  media: '[LibComponent]Media',
  links: '[LibComponent]Links',
  pdf: '[LibComponent]PDF',
  files: '[LibComponent]Files',
  library: '[LibComponent]Library',
};

const LibraryContent = React.memo(function LibraryContent({
  isSimplifiedMultiselect,
  setSimplifiedMultiselect,
  counterSelectedPage,
  needToDelete,
  selectorType,
  activeNavSlider,
  scrollDelta,
  permissions,
  isChannelMiniPage,
  contentData,
  ...props
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { activeSieveOption: activeSieveOptionOld } = useSelector(state => state.library);
  const isCardView = useSelector(state => state.currentPage.isCardView) || props.isUPV;
  const { activeMenuItemId } = useSelector(state => state.support);
  const selectedPage = useSelector(state => state.selectedPage);
  const isDescriptionOpen = useSelector(state => state.support?.componentDescription);

  const isSimplifiedMultiselectRef = useRef(isSimplifiedMultiselect);
  const counterSelectedPageRef = useRef(counterSelectedPage);
  const selectedPageRef = useRef(selectedPage);

  counterSelectedPageRef.current = counterSelectedPage;
  selectedPageRef.current = selectedPage;
  isSimplifiedMultiselectRef.current = isSimplifiedMultiselect;

  const activeSieveOption = initItem2[activeNavSlider] || activeSieveOptionOld || '[LibComponent]Pages';

  const isPlaylist = history.location.pathname.includes('smartfile');


  const isMixed = !!((selectorType === 'smartView'
    || props.isFilter
    || calcIsDynamicCollection(activeSieveOption)
    || props.isShowFilter
    || activeSieveOption === LibCompSieveTypesSideBar.Usage)
    && activeSieveOption !== initItem2.library);

  const isPlaylistComponent = activeSieveOption === '[LibComponent]Playlists';

  const showContent = isCardView;

  const libraryDragState = useSelector(state => state.library.dragState || empty);
  const { id: currentPageId } = useSelector(state => state.currentPage || empty);
  const unseenPagesManagerMap = useSelector(state => state.support.unseenPagesManagerMap);

  const dragStartHandler = useCallback((e, item) => {
    const selectedItems = contentData.filter(elem => selectedPageRef.current[elem.id]);
    const divWrapper = document.createElement('div');
    const div = document.createElement('div');
    div.className = 'draggable_page';
    divWrapper.id = 'draggable_page';
    divWrapper.appendChild(div);
    if (!counterSelectedPageRef.current) {
      const testDiv = document.createElement('div');
      testDiv.textContent = `${item.title || t('defaultSmartPageTitleT')}`;
      testDiv.style.width = 'fit-content';
      testDiv.id = 'testDiv';
      document.body.appendChild(testDiv);
      let trimTitle = item.title || t('defaultSmartPageTitleT');
      let counter = trimTitle.length;

      while (testDiv.getBoundingClientRect().width > 130) {
        trimTitle = `${trimTitle.slice(0, counter)}...`;
        testDiv.textContent = trimTitle;
        counter--;
      }

      document.body.removeChild(testDiv);
      div.textContent = `${trimTitle || t('defaultSmartPageTitleT')}`;

      dispatch(actionAddComponentToDragState(item.id, item.folderId, item.wrapperId));
      dispatch(actionDragPage(item.id, item.folderId, null, item.type));
      dispatch(actionDragManyPage(true));
      dispatch(ActionDraggable.BlocksOnPage(Object.keys(selectedPageRef.current)));
    } else {
      const text = counterSelectedPageRef.current === 1 ? 'item' : 'items';
      div.textContent = `${counterSelectedPageRef.current} ${text}`;
      div.classList.add('draggable_multiple');
      const counter = document.createElement('div');
      counter.className = 'draggable_multiple_counter';
      counter.textContent = counterSelectedPageRef.current;
      divWrapper.appendChild(counter);
      dispatch(actionDragManyPage(true));
      dispatch(ActionDraggable.SetHasDraggable(true));
      dispatch(ActionDraggable.BlocksOnPage(Object.keys(selectedPageRef.current)));
      dispatch(actionBulkAddComponentsToDragState(selectedPageRef.current));
    }
    dispatch(actionAddLinkPagesToDragState(counterSelectedPageRef.current ? selectedItems : [item]));
    document.body.appendChild(divWrapper);
    e.dataTransfer.setDragImage(divWrapper, 117, 20);
  }, [dispatch, contentData]);

  const dragEndHandler = useCallback((item) => {
    const div = document.getElementById('draggable_page');
    if (!Object.keys(libraryDragState).length) dispatch(actionRemoveSelectedPage(item.id, item.folderId));

    dispatch(actionClearDragState());
    dispatch(actionDragPage(null, null));
    dispatch(actionDragManyPage(false));
    dispatch(ActionDraggable.SetHasDraggable(false));
    dispatch(ActionDraggable.BlocksOnPage([]));

    if (div) document.body.removeChild(div);
  }, [dispatch, libraryDragState]);

  const toggleSelected = useCallback((event, item, isSelected) => {
    toggleSelectedWrapper({
      event,
      props: {
        isSimplifiedMultiselect: isSimplifiedMultiselectRef.current,
        setSimplifiedMultiselect,
      },
      item,
      dispatch,
      isSelected,
    });
  }, [
    setSimplifiedMultiselect,
    dispatch,
  ]);

  const singleClickHandler = useCallback((event, item, index) => {
    const isPDF = isTypeShowLikePdf[item?.type];
    const isPage = item?.type === 'page' || item?.type === 'sharedPage';
    const isNeedZoom = isPDF || isPage;
    singleClickHandlerWrapper({
      event,
      props: {
        isSimplifiedMultiselect: isSimplifiedMultiselectRef.current,
        index,
        isChannelMiniPage,
      },
      currentPageId,
      item,
      toggleSelected,
      dispatch,
      history,
      unseenPagesManagerMap,
      noUPV: false,
      isNeedZoom,
    });
  }, [
    isChannelMiniPage,
    currentPageId,
    toggleSelected,
    unseenPagesManagerMap,
    dispatch,
    history,
  ]);

  const onCompositeClickHandler = useCallback((event, item, index, isSelected) => {
    event.stopPropagation();

    if (isSimplifiedMultiselectRef.current) {
      toggleSelected(event, item, isSelected);
    } else if (event.detail === 1) {
      singleClickHandler(event, item, index);
    }
  }, [toggleSelected, singleClickHandler]);

  return (
    <>
      {!isCardView && (
        <>
          {isPlaylist && (
            <FluentTableView
              isRecent
              noHeader={props.noHeader}
              isSimplifiedMultiselect={isSimplifiedMultiselect}
              setSimplifiedMultiselect={setSimplifiedMultiselect}
              isDescriptionOpen={isDescriptionOpen}
              data={contentData}
            />
          )}
          {!isPlaylist && (
            <FluentTableViewForLib
              isRecent
              data={contentData}
              noHeader={props.noHeader}
              contentIds={[]}
              isSimplifiedMultiselect={isSimplifiedMultiselect}
              setSimplifiedMultiselect={setSimplifiedMultiselect}
              isDescriptionOpen={isDescriptionOpen}
              setSort={emptyCallback}
            />
          )}
        </>
      )}

      {showContent && contentData.map((content, index) => {
        if (!content || content.moveToTrash) {
          return null;
        }

        const isSelected = !!(content?.folderId && selectedPage[content?.folderId]
          ? selectedPage[content?.folderId][content.id]
            || selectedPage[content?.folderId][`${content.id}_${content.status}`]
          : selectedPage[content.id] || selectedPage[`${content.id}_${content.status}`]);

        return (
          <React.Fragment
            key={`${content?.status}_${content?.wrapperId}_${content.id}`}
          >
            {!isPlaylistComponent && (
              <div
                data-cy="library-card-item"
                data-intersect={content.id}
                className={!isCardView ? styles.fullParentWidth : ''}
              >
                <LibraryMiniPage
                  needToDelete={needToDelete}
                  item={content}
                  isSelected={isSelected}
                  isMixed={isMixed}
                  isFavorite={selectorType === 'favorites'}
                  index={index}
                  scrollDelta={scrollDelta}
                  isChannelMiniPage={isChannelMiniPage}
                  permissions={permissions}
                  dragStartHandler={dragStartHandler}
                  dragEndHandler={dragEndHandler}
                  onCompositeClickHandler={onCompositeClickHandler}
                  singleClickHandler={singleClickHandler}
                  toggleSelected={toggleSelected}
                  isMenuActive={activeMenuItemId === content.id}
                  isUPV={props.isUPV}
                />
              </div>
            )}

            {isPlaylistComponent && (
              <PlaylistGap data-cy="playlist-card-item" data-intersect={content.id}>
                <NewPlaylist2023
                  item={content}
                  isSimplifiedMultiselect={isSimplifiedMultiselect}
                  setSimplifiedMultiselect={setSimplifiedMultiselect}
                  isDescriptionOpen={isDescriptionOpen}
                  dragStartHandler={dragStartHandler}
                  dragEndHandler={dragEndHandler}
                  libraryDragState={libraryDragState}
                />
              </PlaylistGap>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
});

export default LibraryContent;
