import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { actionDownloadFile } from '../../redux/downloads/action';
import {
  empty, emptyCallback,
  LibraryComponentTypes,
  UiComponentTypes,
} from '../../utils/constants';
import MsContent from '../LibraryComponent/MsContent';
import SimplePdfPreview from '../../components/MomentBlock/CustomBlocks/SimplePdfPreview/SimplePdfPreview';
import {
  otherType,
  otherTypeForDisplayPreview,
  pageControls,
  scaleControls,
} from '../LibraryComponent/helpers';
import { PdfContent } from '../LibraryComponent/contentGenerators/PDFContent';
import { LowControls } from '../LibraryComponent/childComponents/LowControls';
import { ImageContent } from '../LibraryComponent/contentGenerators/ImageContent';
import { ContentWithBlocks } from '../LibraryComponent/contentGenerators/ContentWithBlocks';
import { EmbedContent } from '../LibraryComponent/contentGenerators/EmbedContent';
import { LinkContent } from '../LibraryComponent/contentGenerators/LinkContent';
import { ArchiveContent } from '../LibraryComponent/contentGenerators/ArchiveContent';
import { MuxAudioVideoPlayer } from './childComponents/MuxPlayer';
import { AudioVideoContent } from '../LibraryComponent/contentGenerators/AudioVideo';
import { ElementTextContent } from './childComponents/TextComponent';
import PdfAsPreview from '../../components/MomentBlock/CustomBlocks/pdfBlock/PdfAsPreview';
import LexicalViewer from '../../features/LexicalViewer/LexicalViewer';
import { smartFileItemTypeCheck } from '../../shared/smartFile/constant';

const DisplayPreviewShell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  position: relative;
  ${({ isPlayerPreview }) => (isPlayerPreview
    ? `
        transform: scale(0.3);
  `
    : '')}
  ${({ isPagePreview }) => (isPagePreview
    ? `
        transform: scale(0.6) translate(0%,9%);
        height: 800px;
        width: 700px;
        top: 0;
  `
    : '')}

   ${({ isPagePreview }) => (isPagePreview
    ? `
        width: 100%;
        height: 100%;
}
  `
    : '')}
   ${({ isPlayerTOCPreview, isCardView }) => {
    if (isPlayerTOCPreview && isCardView) {
      return `
        width: 100%;
        height: 100%;
      `;
    }
    if (isPlayerTOCPreview && !isCardView) {
      return `
        width: 100%;
        height: 100%;
      `;
    }
    return '';
  }}
`;
// ${({ isArchive }) => (isArchive ? `
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         margin-top: 25%;
//         transform: translate(0, -50%);
//   ` : '')}

export const DisplayPreview = ({
  libraryComponent: item = empty,
  imageRef,
  calcStyle,
  isPlayer,
  isPlayerPreview,
  isPlayerTOCPreview,
  isCardView,
  isMouseMoveShowControls,
  isForSharedToWeb,
  ...props
}) => {
  const pdfRef = useRef(null);
  const dispatch = useDispatch();
  const [scalePercentage, setScalePercentage] = useState(100);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(20);

  const goToLink = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (item.linkUrl) {
      const newWindow = window.open(
        item.linkUrl,
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  const changeStep = (operation) => (e) => {
    e.stopPropagation();
    if (operation) setScalePercentage((prev) => scaleControls[operation](prev, 10));
  };

  const changePage = (operation) => (e) => {
    e.stopPropagation();
    if (operation) setPage((prev) => pageControls[operation](prev, totalPages));
  };

  const downloadFile = () => {
    dispatch(
      actionDownloadFile({
        id: item.id,
        folderId: item.inCollectionId,
        url: item.urlFile,
      }),
    );
  };

  if (props?.linkPage?.type === 'elementText') {
    return (
      <DisplayPreviewShell
        style={calcStyle}
        isPlayerPreview={isPlayerPreview}
        isPlayerTOCPreview={isPlayerTOCPreview}
        isCardView={isCardView}
      >
        <ElementTextContent
          item={props?.linkPage}
          imageRef={imageRef}
          isPlayerPreview={isPlayerPreview}
          isPlayerTOCPreview={isPlayerTOCPreview}
          isCardView={isCardView}
        />
      </DisplayPreviewShell>
    );
  }
  if (smartFileItemTypeCheck.isLexicalText[props?.linkPage?.type]) {
    return (
      <DisplayPreviewShell
        style={calcStyle}
        isPlayerPreview={isPlayerPreview}
        isPlayerTOCPreview={isPlayerTOCPreview}
        isCardView={isCardView}
      >
        <LexicalViewer state={props?.linkPage.textComponent?.state || ''} />
      </DisplayPreviewShell>
    );
  }
  return (
    <DisplayPreviewShell
      id="player-content"
      style={calcStyle}
      isPagePreview={isPlayerPreview && UiComponentTypes.page[item?.type]}
      isArchive={otherType(item.type)}
    >
      {(UiComponentTypes.video[item?.type]
        || UiComponentTypes.audio[item?.type]) && (
        <AudioVideoContent disableHeightMargin>
          <MuxAudioVideoPlayer item={item} />
        </AudioVideoContent>
      )}
      {(UiComponentTypes.pdf[item?.type] || item?.pdfPreviewUrl) && (
        <>
          {(isPlayerTOCPreview || isPlayerPreview) && (
          <PdfAsPreview state={item} />
          )}
          {!isPlayerTOCPreview && !isPlayerPreview && (
            <PdfContent
              item={item}
              pdfRef={pdfRef}
              changePage={changePage}
              setTotalPages={setTotalPages}
              pages={{ current: page, max: totalPages }}
              scale={99}
              isMouseMoveShowControls={isMouseMoveShowControls}
              isPlayer={isPlayer}
              imageRef={imageRef}
              setSiblingComponentIsLoading={props.setSiblingComponentIsLoading}
            />
          )}

          {!isPlayerTOCPreview && !isPlayerPreview && !isPlayer && (
            <LowControls
              isPdf
              changeStep={changeStep}
              scalePercentage={100}
              changePage={changePage}
              pages={{ current: page, max: totalPages }}
              isPlayer={isPlayer}
              isPlayerPreview={isPlayerPreview}
            />
          )}
        </>
      )}
      {UiComponentTypes.image[item.type] && (
        <>
          <ImageContent
            setSiblingComponentIsLoading={props.setSiblingComponentIsLoading}
            item={item}
            pages={{ current: page, max: totalPages }}
            scale={scalePercentage}
            setScalePercentage={setScalePercentage}
            isPlayer={isPlayer}
            isPlayerPreview={isPlayerPreview}
            imageRef={imageRef}
            isPlayerTOCPreview={isPlayerTOCPreview}
            isCardView={isCardView}
          />
        </>
      )}
      {UiComponentTypes.component[item.type] && (
        <ContentWithBlocks
          imageRef={imageRef}
          item={item}
          isPlayer={isPlayer}
          isPlayerPreview={isPlayerPreview}
        />
      )}
      {UiComponentTypes.link[item.type]
        && item.type === LibraryComponentTypes.embed_component && (
          <EmbedContent
            item={item}
            isPlayer={isPlayer}
            isPlayerPreview={isPlayerPreview}
            imageRef={imageRef}
            isPlayerTOCPreview={isPlayerTOCPreview}
            isCardView={isCardView}
          />
      )}
      {UiComponentTypes.link[item.type]
        && item.type !== LibraryComponentTypes.embed_component && (
          <LinkContent
            item={item}
            goToLink={goToLink}
            isPlayer={isPlayer}
            isPlayerPreview={isPlayerPreview}
            imageRef={imageRef}
            isPlayerTOCPreview={isPlayerTOCPreview}
            isCardView={isCardView}
          />
      )}
      {UiComponentTypes.ms[item.type] && !item.pdfPreviewUrl && (
        <>
          <MsContent
            item={item}
            goToLink={goToLink}
            imageRef={imageRef}
            isPlayer={isPlayer}
            isPlayerPreview={isPlayerPreview}
            isPlayerTOCPreview={isPlayerTOCPreview}
          />
        </>
      )}
      {UiComponentTypes.page[item.type] && (
        <ContentWithBlocks
          item={item}
          isPlayer={isPlayer}
          isPlayerPreview={isPlayerPreview}
          isPlayerTOCPreview={isPlayerTOCPreview}
          imageRef={imageRef}
          isCardView={isCardView}
        />
      )}
      {otherTypeForDisplayPreview(item.type) && !item?.pdfPreviewUrl && (
        <>
          <ArchiveContent
            isForSharedToWeb={isForSharedToWeb}
            item={item}
            imageRef={imageRef}
            downloadFile={downloadFile}
            isPlayer={isPlayer}
            isCardView={isCardView}
            isPlayerPreview={isPlayerPreview}
            isPlayerTOCPreview={isPlayerTOCPreview}
          />
        </>
      )}
    </DisplayPreviewShell>
  );
};
