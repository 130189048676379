import classNames from 'classnames/bind';
import React from 'react';
import styles from '../../GlobalModals/index.module.scss';
import downloadStatus from '../../../utils/dataUtil';
import { ReactComponent as AvatarPlaceHolder24Svg } from '../../../images/icons/avatar_placeholder_24.svg';
import { ReactComponent as CloseSvg } from '../../../images/icons/close_12.svg';
import { AvatarInInput } from './AvatarInInput';

const cx = classNames.bind(styles);

export const ListItem = ({ item, index, deleteEmail, dataParent }) => {
  if (item?.type === 'user') {
    return (
      <div
        key={`${item.id}`}
        className={cx('emailToAdd_wrapper', {
          pending: item.send === downloadStatus.pending,
          success: item.send === downloadStatus.success,
          failed: item.send === downloadStatus.failed,
        })}
      >
        <AvatarInInput item={item} />
        {item.username && item.username !== item.email && (
          <>
            <div className={cx('email')}>{item.username}</div>
            <div className={cx('email')}>({item.email})</div>
          </>
        )}
        {(!item.username || item.username === item.email) && (
          <div className={cx('email')}>{item.email}</div>
        )}
        <div className={cx('close_wrapper')} data-parent={dataParent}>
          <CloseSvg
            onClick={() => deleteEmail(index)}
            data-parent={dataParent}
          />
        </div>
      </div>
    );
  }
  if (item?.type === 'newUser') {
    return (
      <div
        key={`${item.id}`}
        className={cx('emailToAdd_wrapper', {
          pending: item.send === downloadStatus.pending,
          success: item.send === downloadStatus.success,
          failed: item.send === downloadStatus.failed,
        })}
      >
        <AvatarInInput item={item} />
        <div className={cx('email')}>{item.email}</div>
        <div className={cx('close_wrapper')} data-parent={dataParent}>
          <CloseSvg
            onClick={() => deleteEmail(index)}
            data-parent={dataParent}
          />
        </div>
      </div>
    );
  }

  return (
    <div key={`${item}${index}`} className={cx('emailToAdd_wrapper')}>
      <AvatarInInput item={item} />
      <div className={cx('email')}>{item}</div>
      <div className={cx('close_wrapper')}>
        <CloseSvg onClick={() => deleteEmail(index)} />
      </div>
    </div>
  );
};
