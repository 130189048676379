import styled from 'styled-components/macro';

export const MainShell = styled.div`
  min-height: fit-content;
  background: #ffffff;
`;
export const Navigation = styled.div`
  padding-top: 6px;
  margin-left: 20px;
  margin-bottom: 36px;
`;

export const LoadingScreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 15;
  top: 0;
  left: 0;
  overflow: hidden;
`;

export const SectionShell = styled.div`
  height: 100%;
  width: 100%;
  background: #F6F6F5;
  padding-left: 20px;
  padding-bottom: 40px;
  ${({ withTop }) => (withTop ? 'padding-top: 20px;' : '')}
  ${({ paddingForSetting }) => (paddingForSetting ? 'padding: 28px 36px 28px 42px;' : '')}
`;

export const Line = styled.div`
  max-width: 670px;
  min-width: 280px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  gap: 26px;

  ${({ General }) => (General
    ? `
      align-items: start;
      margin: 0;
      gap: 0;
    `
    : '')}
  ${({ Language }) => (Language
    ? `
      min-width: 350px;
      width: 100%;
      max-width: initial;
    ` : '')}
  ${({ Language, secondSection }) => (Language && secondSection
    ? `
      margin-top: 11px;
    ` : '')}
  ${({ Info }) => (Info
    ? `
      max-width: none;
      min-width: 320px;
      width: 100%;
      margin: 6px 0;
      gap: 0;
    `
    : '')}
    ${({ formButtons }) => (formButtons ? 'justify-content: flex-end;' : '')}
    ${({ isDisable }) => (isDisable ? 'opacity: 0.3;' : '')}
`;

export const Column = styled.div`
  position: relative;
  width: 315px;
  margin-bottom: 18px;
  ${({ Language }) => (Language ? 'width: fit-content; margin-bottom: 7px;' : '')}
  ${({ General }) => (General ? 'width: 320px; margin: 0;' : '')}
  ${({ Info }) => (Info ? 'width: 100%;' : '')}
  ${({ Double }) => (Double ? 'display: flex;' : '')}
  ${({ Selector }) => (Selector ? 'margin-left: 150px; width: 120px' : '')}
  ${({ Upgrade }) => (Upgrade ? 'margin-bottom: 28px;' : '')}
`;

export const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #242633;
  margin-bottom: 23px;
  ${({ marginTop }) => (marginTop
    ? `
      margin-top: 22px;
    `
    : '')}
     ${({ isWorkplans }) => (isWorkplans
    ? `
      font-size: 16px;
      font-weight: 600;
      font-family: "Roboto", sans-serif;
      margin-top: 42px;
      color: #484343;
    `
    : '')}
`;

export const Text = styled.div`
  font-size: 12px;
  color: #596080;
`;

export const HeaderTitle = styled.div`
  font-size: 36px;
  color: #484343;
  line-height: 46px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  ${({ marginBottom32 }) => (marginBottom32
    ? `
      margin-bottom: 32px;
    `
    : '')}
`;
