import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { DATA_PARENTS } from '../constants';

export default function useMiniPageClickOutside(
  initialIsVisible: boolean,
): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(initialIsVisible);

  const handleHideDropdown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event: MouseEvent): void => {
    const target = event.target as HTMLElement;
    if (
      !(
        target?.dataset?.parent?.includes(DATA_PARENTS.createLibrary)
        || target?.parentElement?.dataset?.parent?.includes(DATA_PARENTS.createLibrary)
        || target?.parentElement?.parentElement?.dataset?.parent?.includes(DATA_PARENTS.createLibrary)
        || target?.parentElement?.parentElement?.parentElement?.dataset?.parent?.includes(DATA_PARENTS.createLibrary)
        || target?.parentElement?.parentElement?.parentElement?.parentElement?.dataset?.parent?.includes(DATA_PARENTS.createLibrary)
        || target?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.dataset?.parent?.includes(DATA_PARENTS.createLibrary)
      )
    ) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return [isComponentVisible, setIsComponentVisible];
}
