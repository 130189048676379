import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import {
  emptyCallback,
  getMessageIcon,
  Group,
  MessageGroup,
  MessageType,
} from '../../utils/constants';
import { ReactComponent as CloseCross } from '../../images/2023/svg/small/informer/white_cross_11_11.svg';
import './styles.scss';
import { calculateMessage } from './helpers';
import {
  ActionRemoveFromProceedQueue,
  ActionRequestProceedAddNew,
} from '../../redux/requestProceed/actions';
import { transformedDATA_PARENTS } from '../../utils/dataUtil';
import { actionCreator } from '../../shared/redux/actionHelper';
import { InboxAction } from '../../redux/inbox/types';

const SnackBar2023 = () => {
  const { message } = useSelector((state) => state.support);
  const { deleteQueue } = useSelector((state) => state.requestProceed);
  const alertQueue = useRef({});
  const dispatch = useDispatch();

  const closeMessageEx = (e) => {
    e.stopPropagation();
    closeSnackbar(alertQueue.current[e.currentTarget.dataset.key]);
  };

  const closeMessageByKey = (key) => {
    closeSnackbar(key);
    dispatch(ActionRemoveFromProceedQueue(key));
  };

  const deleteFromRef = (key) => {
    delete alertQueue.current[key];
  };

  const moveProcessToRequestProceed = (id, key) => {
    dispatch(ActionRequestProceedAddNew({ id, key }));
  };
  const showMessageEx = (message, autoHideDuration = 4000, dispatch) => {
    const alertKey = uuidv4();

    const IconClick = () => {
      if (message.type === MessageType.Refresh) return dispatch(actionCreator(InboxAction.Clear));
      return emptyCallback;
    };
    alertQueue.current[alertKey] = enqueueSnackbar(
      <>
        <div data-parent={transformedDATA_PARENTS}>
          {message.type === MessageType.Refresh && (
          <div className="informer__icon clickable" onClick={IconClick} data-parent={transformedDATA_PARENTS}>
            {getMessageIcon(message.type, message.mode, message.id)}
          </div>
          )}
          {message.type !== MessageType.Refresh && (
          <div className="informer__icon" data-parent={transformedDATA_PARENTS}>
            {getMessageIcon(message.type, message.mode, message.id)}
          </div>
          )}
          <div style={{ width: 192 }} data-parent={transformedDATA_PARENTS}>
            {calculateMessage(message)}
          </div>

          <div
            data-key={alertKey}
            className="informer__close"
            onClick={closeMessageEx}
            data-parent={transformedDATA_PARENTS}
          >
            <CloseCross data-parent={transformedDATA_PARENTS} />
          </div>
        </div>
      </>,

      {
        autoHideDuration,
        hideIconVariant: true,
        onClose: () => deleteFromRef(alertKey),
        variant: MessageGroup[message.type],
        TransitionProps: { direction: 'left' },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
    );
    if ((message?.mode === Group.processing || message?.mode === Group.processingWithoutCancellation) && message.id) {
      moveProcessToRequestProceed(message.id, alertQueue.current[alertKey]);
    }
  };

  useEffect(() => {
    if (message?.type) {
      showMessageEx(
        message,
        (message?.mode === Group.processing || message?.mode === Group.processingWithoutCancellation)
          ? 4000000
          : '',
        dispatch);
    }
  }, [message]);

  useEffect(() => {
    if (deleteQueue.length) {
      deleteQueue.forEach((key) => closeMessageByKey(key));
    }
  }, [deleteQueue.length]);
  return <></>;
};
export default SnackBar2023;
