import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as MinusSVG } from '../../images/2023/svg/small/player/zoom_minus.svg';
import { ReactComponent as PlusSVG } from '../../images/2023/svg/small/player/zoom_plus.svg';
import { InnerZoomContainer, PlayerZoomWrapper } from './styled';
import { actionCreator } from '../../shared/redux/actionHelper';
import { ServiceUser } from '../../redux/user/types';
import { scaleControls } from '../LibraryComponent/helpers';
import FitContent from './FitContent';


const ZOOM_STEP = 10;

const Zoom = ({ scrollTo }) => {
  const dispatch = useDispatch();
  const [isControlPressed, setIsControlPressed] = useState(false);

  const {
    playerSettings: { zoomPercentage = 100 },
  } = useSelector((state) => state.user);

  const dispatchZoom = (operation) => {
    dispatch(
      actionCreator(ServiceUser.Player.ChangeZoom, {
        value:
          operation === 'default'
            ? scaleControls[operation]
            : scaleControls[operation](zoomPercentage, ZOOM_STEP),
      }),
    );
  };

  const btnKeyDownHandler = (e) => {
    if (e.key === 'Control' || e.key === 'Alt') {
      setIsControlPressed(true);
    }
    if (e.key === 'ArrowDown') {
      scrollTo('down');
    }
    if (e.key === 'ArrowUp') {
      scrollTo('up');
    }
  };
  const btnKeyUpHandler = (e) => {
    if (e.key === 'Control' || e.key === 'Alt') {
      setIsControlPressed(false);
    }
  };

  function onWheel(e) {
    if (!isControlPressed) {
      return;
    }
    e.preventDefault();
    if (e.wheelDelta > 0) {
      dispatchZoom('increase');
    } else {
      dispatchZoom('decrease');
    }
  }
  const changeStep = (operation) => (e) => {
    e.stopPropagation();
    dispatchZoom(operation);
  };


  useEffect(() => {
    document.addEventListener('wheel', onWheel, { passive: false });
    document.addEventListener('keydown', btnKeyDownHandler);
    document.addEventListener('keyup', btnKeyUpHandler);
    return () => {
      document.removeEventListener('wheel', onWheel);
      document.removeEventListener('keydown', btnKeyDownHandler);
      document.removeEventListener('keyup', btnKeyUpHandler);
    };
  }, [isControlPressed, zoomPercentage]);

  return (
    <PlayerZoomWrapper>
      <InnerZoomContainer>
        <MinusSVG onClick={changeStep('decrease')} />
        <div onDoubleClick={changeStep('default')}>{zoomPercentage}%</div>
        <PlusSVG onClick={changeStep('increase')} />
      </InnerZoomContainer>
      <FitContent />
    </PlayerZoomWrapper>
  );
};

export default Zoom;
