import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_LANGUAGES } from '../../../../utils/SettingsHelpers/constants';
import { ReactComponent as ChevronDownSvg } from '../../../../images/icons/chevron_down_12.svg';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';
import useComponentVisible from '../../../../utils/hooks/useComponentVisible';
import { DATA_PARENTS } from '../../../../utils/constants';
import ChevronContainer from './chevronContainer';

const SelectorShell = styled.div`
  position: relative;
  ${({ isActive }) => (isActive ? 'z-index: 5;' : '')}
  width: 117px;
  padding: 3px 3px 3px 10px;
  border-radius: 6px;
  border: 0.6px solid #ECE2E2;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(122, 105, 105, 0.10);
  display: flex;
  align-items: center;
  :hover {

    ${({ isDisable }) => (isDisable
    ? 'cursor: no-drop;'
    : 'background: #FFEEBF;    '
          + '  box-shadow: 0px 1px 4px 0px rgba(122, 105, 105, 0.10);'
          + '   border: 0.6px solid #FFC72C; '
          + ' cursor: pointer;')}

  }
`;

const Selector = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #596080;
  z-index: 4;
  justify-content: space-between;
  width: 100%;
`;

const DropDown = styled.div`
  top: 39px;
  left: 0;
  position: absolute;
  overflow: auto;
  width: 109px;
  height: fit-content;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
`;

const DropListItem = styled.div`
  height: 32px;
  line-height: 24px;
  padding: 5px 16px;
  &:hover {
    background: #FFEEBF;
  }
  ${({ isActive }) => (isActive ? 'background: #FFC72C;' : '')}
`;

const LanguageSelector = () => {
  const [menuRef, isDropMenuActive, setIsDropMenuActive] = useComponentVisible(
    false,
    DATA_PARENTS.LanguageSelector,
  );
  const { i18n } = useTranslation();
  const parentRef = useRef(null);
  const dispatch = useDispatch();
  const { inputs } = useSelector((state) => state.settings);

  const pickNewLanguage = (event, newValue) => {
    i18n.changeLanguage(newValue);
    dispatch(actionCreator(Settings.Preferences.ChangeLanguage, { newValue }));
    localStorage.setItem('language', newValue);
    setIsDropMenuActive(false);
    event.stopPropagation();
  };

  const openDropMenu = () => {
    setIsDropMenuActive(!isDropMenuActive);
  };

  return (
    <SelectorShell isActive={isDropMenuActive} onClick={openDropMenu}>
      <Selector ref={parentRef}>
        {SUPPORTED_LANGUAGES[i18n.language]?.title}

        <ChevronContainer
          data-parent={DATA_PARENTS.LanguageSelector}
          isActive={isDropMenuActive}
          isLanguageTab
          onMouseDown={(e) => e.preventDefault()}
        >
          <ChevronDownSvg data-parent={DATA_PARENTS.LanguageSelector} />
        </ChevronContainer>

        {isDropMenuActive && (
          <DropDown ref={menuRef}>
            <PerfectScrollbar>
              {Object.values(SUPPORTED_LANGUAGES).map((language) => (
                <DropListItem
                  key={language.code}
                  isActive={language.code === inputs.preferredLanguage.value}
                  onClick={(event) => pickNewLanguage(event, language.code)}
                >
                  {language.title}
                </DropListItem>
              ))}
            </PerfectScrollbar>
          </DropDown>
        )}
      </Selector>
    </SelectorShell>
  );
};

export default LanguageSelector;
