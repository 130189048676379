import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SixDotsSvg } from '../../../images/icons/drag_black_lines.svg';
import { ReactComponent as None } from '../../../images/icons/icon-colour-none.svg';
import { ReactComponent as CompletedSvg } from '../../../images/icons/completed_checkmark.svg';

import { actionPageWillDownload } from '../../../redux/currentPage/action';
import { actionCreator } from '../../../shared/redux/actionHelper';
import SupportAction from '../../../redux/support/types';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import { actionJumpToWithConfirmation } from '../../../redux/support/action';
import {
  Box,
  DownLine,
  DragWrap,
  Name, NoDrop,
  SixDots, NameText,
  TOCShellItem,
  ImageWrapper,
  SvgWrap,
} from './styled';
import ThreeDotsDropDownMaker from '../ThreeDotsDropDownForMaker';
import { isRoleInPlaylist } from '../../../utils/permissions';
import { CurrentPage } from '../../../redux/currentPage/types';
import EditPlaylist from '../../../redux/playlists/types';
import { empty } from '../../../utils/constants';
import { PlaylistUI } from '../../../utils/UIHelpers/PlaylistBuilderElements/componentPickers';

const InputWrapper = styled.input`
  width: 234px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #ffc72c;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  padding-left: 12px;
  color: #484343;
  margin-top: 4px;
  margin-left: 11px;
`;

const MakerTOCItem = ({
  name,
  index,
  dragHoverNext,
  isLast,
  item,
  ...props
}) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [currentNameValue, setCurrentNameValue] = useState(
    name || t('UnnamedComponentT'),
  );
  const user = useSelector((state) => state.user || empty);

  const { isViewer: exViewer, isOwner: isOwnerEx } = isRoleInPlaylist;
  const currentPage = useSelector((state) => state.currentPage);
  const dispatch = useDispatch();
  const { unseenInChannel, unseenPlaylistManagerMapInAll } = useSelector(
    (state) => state.support,
  );
  const { channelId = null } = useParams();
  const inputRef = useRef(null);

  const isChannels = !!channelId;
  const history = useHistory();
  const [refMenu, isActive, setIsActive] = useComponentVisible(
    false,
    'miniPage',
  );
  const isOwner = isOwnerEx[props.currentRole];

  const { isContentEditable, id: playlistId, socketId } = currentPage;
  const onRenameItemHandler = (e) => {
    setCurrentNameValue(e.target.value);
  };

  const toggleDropDown = (e) => {
    e.preventDefault();
    setIsActive(!isActive);
  };
  const isOtherOwner = user.id !== item?.owner?.id;

  const onInputBlurFoo = () => {
    setEditMode(false);
    if (currentNameValue !== name) {
      dispatch(
        actionCreator(CurrentPage.RenameLinkedPage, {
          playlistId,
          linkPageId: item.id,
          newTitle: currentNameValue,
        }),
      );
      dispatch(actionCreator(EditPlaylist.removeNewInLinkPage, {}));
    }
  };

  const newGoToPlaylist = (event, index) => {
    event.stopPropagation();
    dispatch(actionPageWillDownload());
    const isShared = currentPage.status === 'shared';
    if (!isChannels && unseenPlaylistManagerMapInAll[currentPage.wrapperId]) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPlaylistR, {
          playlistManagerId: currentPage.wrapperId,
        }),
      );
    }
    if (
      isChannels
      && unseenInChannel[channelId]
      && unseenInChannel[channelId][currentPage.playlistManagerId]
    ) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPlaylistInChannel, {
          channelId,
          playlistId: currentPage.id,
          playlistManagerId: currentPage.playlistManagerId,
        }),
      );
    }

    // if (specifiedAction === GO_TO_PLAYER_VIEW) {
    if (isShared) {
      history.push(`/player/shared/${currentPage.id}/${index}`);
      return;
    }
    const firstChannelId = channelId
      || (isChannels
        && currentPage?.usedInChannels
        && Object.values(currentPage?.usedInChannels)[0]?.id)
      || 'preview';

    history.push(`/player/${firstChannelId}/${currentPage.id}/${index}`);
    // }
  };

  const onEnterFoo = (e) => {
    if (e.keyCode !== 13) return;
    setEditMode(false);
    if (currentNameValue !== name) {
      dispatch(
        actionCreator(CurrentPage.RenameLinkedPage, {
          playlistId,
          linkPageId: item.id,
          newTitle: currentNameValue,
        }),
      );
      dispatch(actionCreator(EditPlaylist.removeNewInLinkPage, {}));
    }
  };

  const goToItem = (e) => {
    e.stopPropagation();
    dispatch(actionJumpToWithConfirmation(item.id));
    dispatch(actionPageWillDownload());
    history.push(`/maker/${playlistId}/edit`);
  };

  const isTextBlock = item.type === 'elementText';
  const isTitle = item.type === 'elementTitle';
  const isRichText = item.type === 'richText';

  const isShifted = useMemo(() => {
    if (item.type === 'elementTitle') return false;
    const currentIndex = props.items.findIndex(elem => elem.id === item.id);
    return props.items.reduce((acc, cur, i) => {
      if (cur.type === 'elementTitle' && currentIndex > i) return true;
      return acc;
    }, false);
  }, [props.items, item]);

  const image = PlaylistUI.createPreviewComponent(
    item.libraryComponent?.type,
    item.libraryComponent || {},
    item?.id,
    false,
    false,
    true,
  );
  return (
    <DragWrap
      {...props}
      draggable={!isOtherOwner}
      onContextMenu={toggleDropDown}
      isHidden={props.draggablePage.id === item.id}
      onClick={() => props.setLastTouchedItemId(item.id)}
    >
      <TOCShellItem isActive={isActive} isTitle={isTitle}>
        <Box isActive={props.lastTouchedItemId === item.id}>
          <SixDots>
            {' '}
            {!isOtherOwner && <SixDotsSvg />}
            {isOtherOwner && <NoDrop> <None /></NoDrop>}
          </SixDots>
        </Box>
        {item.libraryComponent?.type && props.viewMode === 'card' ? (
          <ImageWrapper id={`image-${item.id}`}>
            {item.customIndex && `${item.customIndex}. `}
            {image}
          </ImageWrapper>
        ) : (
          !editMode && (
            <Name
              isTextBlock={isTextBlock}
              shifted={isShifted}
              isPlayer={props.isPlayer}
              isTitle={isTitle}
            >
              {item.customIndex && `${item.customIndex}. `}
              <NameText isRichText={isRichText}>{name}</NameText>
            </Name>
          )
        )
        }
        {editMode && (
          <InputWrapper
            value={currentNameValue}
            onBlur={onInputBlurFoo}
            onChange={onRenameItemHandler}
            onKeyDown={onEnterFoo}
            ref={inputRef}
          />
        )}
        {!exViewer[props.currentRole] && !isLast && (
          <DownLine dragHover={dragHoverNext} />
        )}
        {props.isPlayer && (
          <SvgWrap
            isViewed={item.isViewed || props.isFinished}
            isCardView={item.libraryComponent?.type && props.viewMode === 'card'}
          >
            <CompletedSvg />
          </SvgWrap>
        )}
        {isActive && (
          <ThreeDotsDropDownMaker
            isTOC
            positionDown={index === 0 || index === 1}
            show={isActive}
            refMenu={refMenu}
            isActive={isActive}
            setIsActive={setIsActive}
            playlistId={currentPage.id}
            isContentEditable={isContentEditable}
            socketId={socketId}
            isOwner={isOwner}
            item={item}
            goToItem={goToItem}
            currentPage={currentPage}
            currentRole={props.currentRole}
            play={(e) => newGoToPlaylist(e, index + 1)}
            isPlaceholder={!item.type && !item.textComponent?.id}
            onRename={() => setEditMode(true)}
            duplicateHandler={() => props.duplicateHandler(item, index + 1)}
          />
        )}
      </TOCShellItem>
    </DragWrap>
  );
};

export default MakerTOCItem;
