import React from 'react';
import SideBarTabs from '../../../../pages/Playlist/PlaylistTabs/SideBarTabs';
import SortAndView from '../SortAndView';
import { Divider, GroupIcon, Wrapper } from './StyledComponents';
import ButtonUpload from '../../../Buttons/ButtonUpload';

const SecondaryPdf = (props) => {
  return (
    <GroupIcon newUPVMode={props.newUPVMode}>
      <Wrapper>
        <ButtonUpload
          dataParent={props.dataParent}
          newUPVMode={props.newUPVMode}
        />
        <Divider newUPVMode={props.newUPVMode} />
      </Wrapper>
      <SideBarTabs
        newUPVMode={props.newUPVMode}
        forSecondaryMenu
        isLibrary
        setIsShowFilter={() => {}}
        setShowNewSmartView={props.setShowNewSmartView}
        resetSmartview={props.resetSmartview}
        setSmartViewForEdit={props.setSmartViewForEdit}
        setChosenSmartView={props.setChosenSmartView}
        setChosenTag={props.setChosenTag}
      />
      <Divider newUPVMode={props.newUPVMode} ms />
      <SortAndView {...props} />
    </GroupIcon>
  );
};

export default SecondaryPdf;
