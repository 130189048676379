import React from 'react';
import styled from 'styled-components/macro';
import { CompositeDecorator, DefaultDraftBlockRenderMap } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import { styleMap } from '../../../utils/richTextBar';
import { emptyCallback } from '../../../utils/constants';
import { blockRenderMap, findLinkEntities, myBlockStyleFn } from '../../../utils/helpersDragt';
import 'draft-js/dist/Draft.css';
import { LinkDecorator } from '../../../utils/draftJsHelpers';


const TextScrollWrapper = styled.div`
  max-height: calc(100vh - 200px);
  width: 100%;
  overflow: auto;
`;

const TextWrapper = styled.div`
  zoom: 1.5;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 25px;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

`;

// TODO update/rework this logic
// ${({ customStyle }) => (customStyle ?? '')};
//  ${({ transformed }) => (transformed ? `
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%) scale(1.75);
//   max-height: 45vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   transition: top 0.2s;
// ` : `
//   transform: none;
//   max-height: none;
//   transition: top 0.2s;
// `)}

const TextPreview = ({ item, customStyle, isGalleryOpen }) => {
  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: LinkDecorator,
    },
  ]);
  const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

  return (
    <TextScrollWrapper>
      <TextWrapper>
        <Editor
          customStyleMap={styleMap()}
          readOnly
          editorState={item?.textComponent?.state}
          onChange={emptyCallback}
          blockStyleFn={myBlockStyleFn}
          blockRenderMap={extendedBlockRenderMap}
          decorators={[decorator]}
        />
      </TextWrapper>
    </TextScrollWrapper>
  );
};

export default TextPreview;
