import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import styles from './MediaAdd.module.scss';
import { actionCancelUpload } from '../../redux/filesUpload/action';
import BlockImageUpload from './BlockImageUpload';
import { actionCreator } from '../../shared/redux/actionHelper';
import { CurrentPage } from '../../redux/currentPage/types';

const cx = classNames.bind(styles);

const MediaAddPlaceholder = ({ index, data, id }) => {
  const [step, setStep] = useState({
    type: 'loading',
    ...data,
    newId: id,
    status: 'googlePickerPrecessing',
  });
  const dispatch = useDispatch();

  const cancel = () => {
    if (step.type === 'loading') {
      dispatch(actionCreator(CurrentPage.RemoveBlockR, { id, index }));
      dispatch(actionCancelUpload(step.newId));
    }
  };

  return (
    <div className={cx('addImage')}>
      {step.type === 'loading' && (
        <BlockImageUpload
          setStep={setStep}
          index={index}
          step={step}
          cancel={cancel}
        />
      )}
    </div>
  );
};

export default MediaAddPlaceholder;
