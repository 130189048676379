import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DATA_PARENTS } from '../../../utils/constants';
import DropDownDownItem from '../../SmartView/2023/DropDownDownItem';
import {
  CloseSVGWrapper,
  DynamicCounterWrapper,
  DynamicTextWrapper,
  Text,
  ThreeDotsSVGWrapper,
} from './styled';
import { ReactComponent as CloseCrossSVG } from '../../../images/icons/cross_20.svg';
import { ReactComponent as ThreeDotsIcon } from '../../../images/2023/svg/upv/three_dots_22_6.svg';
import rawStyles from './index.module.scss';

const styles = classnames.bind(rawStyles);
const DynamicTabForMainNav = ({
  isExOpened,
  setIsExOpened,
  clickDynamicTab,
  exOptionRef,
  dynamicCollection,
  activeSieveOption,
  setSmartViewForEdit,
  setShowNewSmartView,
  resetSmartview,
  isTagMode,
  selector,
  calculateDisplayedSelector,
  closeDynamicTab,
  tagCounter,
  counters,
  toggleDynamicDD,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [currentPathName, setCurrentPathName] = useState(
    history.location.pathname,
  );

  useEffect(() => {
    const isToggleToAnother = history.location.pathname.startsWith('/content/library/tag_')
      || history.location.pathname.startsWith('/content/library/dynamic_');
    if (currentPathName !== history.location.pathname && !isToggleToAnother) {
      closeDynamicTab(false, false);
    }
    setCurrentPathName(history.location.pathname);
  }, [history.location.pathname]);
  return (
    <>
      <div
        data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} ${DATA_PARENTS.HeaderInput}`}
        className={styles('nav_item', { first: false, is_passive: isExOpened })}
        onClick={clickDynamicTab}
      >
        {isExOpened && (
          <DropDownDownItem
            top={42}
            right={-92}
            isLibCallBack
            refMenu={exOptionRef}
            close={() => setIsExOpened(false)}
            clickedView={dynamicCollection[activeSieveOption.id]}
            applyFilters={setSmartViewForEdit}
            setShowNewSmartView={setShowNewSmartView}
            resetSmartview={resetSmartview}
            closeDynamicTab={closeDynamicTab}
            isTag={isTagMode}
            forTab
          />
        )}
        <Text
          active={
            selector?.split('_')?.length
            && selector?.split('_')[1]
            && activeSieveOption.id === selector?.split('_')[1].toLowerCase()
          }
          isDynamic
          isPassive={isExOpened}
        >
          <DynamicTextWrapper>
            {calculateDisplayedSelector(activeSieveOption.name)
              || t('unnamedSmartViewUpT')}
          </DynamicTextWrapper>
        </Text>
        <CloseSVGWrapper onClick={closeDynamicTab}>
          <CloseCrossSVG />
        </CloseSVGWrapper>
        <DynamicCounterWrapper
          isActive={
            activeSieveOption?.id && selector.includes(activeSieveOption.id)
          }
          className={styles('to_be_hide_on_hover')}
        >
          {(isTagMode ? tagCounter : counters.dynamic) || 0}
        </DynamicCounterWrapper>
        <ThreeDotsSVGWrapper
          onClick={(e) => toggleDynamicDD(e, dynamicCollection[activeSieveOption.id])
          }
          className={styles('to_be_shown_on_hover')}
          isActive={isExOpened}
        >
          <ThreeDotsIcon />
        </ThreeDotsSVGWrapper>
      </div>
    </>
  );
};

export default DynamicTabForMainNav;
