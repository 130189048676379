import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TitleSvg } from '../../../../images/icons/icon_36/title.svg';
import { ReactComponent as TextSvg } from '../../../../images/icons/icon_36/text.svg';
import { ReactComponent as LibrarySvg } from '../../../../images/icons/icon_36/library.svg';
import { ReactComponent as UploadSvg } from '../../../../images/icons/icon_36/import.svg';
import { actionChangeSieveValue } from '../../../../redux/library/actions';
import { placeSelector } from '../../../../utils/helpers';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { ContentActionType } from '../../../../redux/content/contentTypes';
import { sortedFunctions } from '../../../../utils/dateConvert';
import {
  actionChangeLinkPageType,
  actionRemoveLinkPageFromPlaylist,
  actionShowLibraryWidget,
} from '../../../../redux/currentPage/action';
import { actionOpenModal } from '../../../../redux/user/action';
import { svgCustomBorder, svgCustomBorderHovered } from '../../../../utils/UIHelpers/customBorder';
import { openModalType } from '../../../../utils/constants';
import { smartFileItemType } from '../../../../shared/smartFile/constant';

const Item = styled.div`
  position: relative;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #FFFBF0;
  background-image: ${svgCustomBorder};
  gap: 16px;
  &:hover {
    background: #ffeebf;
    background-image: ${svgCustomBorderHovered};
  }
  ${({ isNotActive }) => (isNotActive
    ? `
    pointer-events: none;
  `
    : '')}
  ${({ dragHover }) => (dragHover
    ? `
    background: #FFEEBF;
  `
    : '')}
  border-radius: 8px;
   ${({ isForEmpty }) => (isForEmpty
    ? `
    max-width: 1100px;
    width: calc(100vw - 580px);
    min-width: 800px;

    @media (max-width: 1400px) {
      width: calc(100vw - 337px);
    }

    @media (max-width: 1050px) {
      min-width: 836px;
    }
    ` : '')}
`;

const Block = styled.div`
  font-size: 14px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0;

  padding: 0 12px;
  ${({ isBlockType, isFirst }) => (isBlockType
    ? `
      flex-direction: column;
      padding: 5px;
      height: auto;
      text-align: center;
      width: 114px;
      height: 114px;
      border-radius: 12px;
      ${isFirst ? '     padding: 5px 14px;' : ' '}
        & svg {
          margin-bottom: 5px;
        }
  `
    : `
      border-radius: 6px;
      & svg {
        height: 18px;
        width: 18px;
        margin-right: 8px;
      }
    `)}
  &:hover {
    background: #ffdf85;
  }

  cursor: pointer;
`;

const defaultSortType = Object.keys(sortedFunctions)[2];

const PlaceHolderAll = ({ index, item, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sortType = defaultSortType } = useSelector((state) => state.content);
  const currentPage = useSelector((state) => state.currentPage);

  const openLibraryHandler = () => {
    const createdItem = props?.create ? props.create(0) : item;
    dispatch(actionRemoveLinkPageFromPlaylist(currentPage.id, createdItem.id));

    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        {
          activeNavSlider: 'pages',
          selectorType: 'all',
          sortType,
          // isNeedUpdate: true,
        },
        'PlaceHolderAll',
      ),
    );
    dispatch(actionChangeSieveValue(placeSelector('library'), 'library'));
    dispatch(actionShowLibraryWidget(true, 'library'));
  };

  const setText = () => {
    const createdItem = props?.create ? props.create(0) : item;
    dispatch(actionChangeLinkPageType(createdItem.id, smartFileItemType.richText, currentPage.id));
  };

  const setTitle = () => {
    const createdItem = props?.create ? props.create(0) : item;
    dispatch(actionChangeLinkPageType(createdItem?.id, smartFileItemType.title, currentPage?.id));
  };

  const openUploadModal = () => {
    dispatch(actionOpenModal(openModalType.UploadModal2023, { item, index }));
  };

  return (
    <Item {...props}>
      <Block onClick={setText} isFirst {...props}>
        <TextSvg />
        {t('addRichTextT')}
      </Block>
      <Block onClick={setTitle} isFirst {...props}>
        <TitleSvg />
        {t('addTitleT')}
      </Block>
      <Block onClick={openLibraryHandler} {...props}>
        <LibrarySvg />
        {t('addFromLibraryT')}
      </Block>
      <Block onClick={openUploadModal} {...props}>
        <UploadSvg />
        {t('importFromT')}
      </Block>
    </Item>
  );
};

export default PlaceHolderAll;
