import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';

import { useTranslation } from 'react-i18next';
import splitArrayToRecentArrays from '../../utils/splitArrayToRecentArrays';
import { sortedFunctions } from '../../utils/dateConvert';
import { DEFAULT_TITLE, MessageType } from '../../utils/constants';
import downloadStatus from '../../utils/dataUtil';
import filterFilesByMaxSize from '../../utils/validators/filterFilesByMaxSize';
import { actionCreateUpload } from '../../redux/filesUpload/action';
import {
  actionCreateAndUploadLinkPage,
} from '../../redux/currentPage/action';
import { actionCreator } from '../../shared/redux/actionHelper';
import SupportAction from '../../redux/support/types';
import { actionShowMessage } from '../../redux/support/action';

import NewEmptyState from '../MainContent';
import ManyPagesTableHeader from '../ManyPagesTableHeader';
import RecentFilterGroup from '../RecentComponents/InLibrary/RecentFilterGroup';
import { ItemPlaceHolder } from '../MiniPlaylist/NewPlaylist/CardView/ItemPlaceHolder';

import styles from './index.module.css';
import LibraryContent from './LibraryContent';

const LibraryContentSelector = React.memo(function LibraryContentSelector({
  sortedPages,
  isSimplifiedMultiselect,
  setSimplifiedMultiselect,
  needToDelete,
  selectorType,
  activeNavSlider,
  setSort,
  sortType,
  createPage,
  scrollDelta,
  permissions,
  installationIndex,
  counterSelectedPage,
  ...props
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isCardView = useSelector(state => state.currentPage.isCardView);
  const { folderId, tags } = useSelector(state => state.user);
  const { loadStatus, contentIds, contentData, existMore } = useSelector(state => state.content);

  const [today, setToday] = useState([]);
  const [yesterday, setYesterday] = useState([]);
  const [thisWeek, setThisWeek] = useState([]);
  const [thisMont, setThisMont] = useState([]);
  const [thisYear, setThisYear] = useState([]);
  const [thisPrevYear, setThisPrevYear] = useState([]);

  const addFileInputRef = useRef();

  const isLibrary = history.location.pathname === '/library'
    || history.location.pathname === '/pages';
  const isPlaylist = history.location.pathname.includes('playlist') || history.location.pathname.includes('smartfile');

  useEffect(() => {
    if (!props.setTableIsVisible) return;
    if (installationIndex) {
      props.setTableIsVisible(true);
    } else if (installationIndex !== null) {
      props.setTableIsVisible(false);
    }
  }, [installationIndex]);

  useEffect(() => {
    if (!props.setTableIsVisible) return;
    if (!props.isTableIsVisible && props.isTableIsVisible !== null) {
      dispatch(
        actionCreator(SupportAction.SetActiveTableItemId, {
          id: null,
          activeTableItemIdWithStatus: null,
        }),
      );
    }
  }, [props.isTableIsVisible]);

  const submitForm = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.target.files);
    const filteredFilesArr = filterFilesByMaxSize([...e.target.files]);
    if (files.length !== filteredFilesArr.length) {
      dispatch(
        actionShowMessage({
          type: MessageType.CannotBeUploaded,
          itemName: t('defaultSmartPageTitleT'),
        }),
      );
    }
    filteredFilesArr.forEach((item, index) => {
      const localTags = [];
      if (selectorType === 'tag' && folderId) {
        localTags.push({ ...tags[folderId] });
      }
      if (isPlaylist) {
        dispatch(
          actionCreateAndUploadLinkPage(
            item,
            index,
            history,
            null,
            null,
            null,
            selectorType === 'favorites',
            localTags,
          ),
        );
      } else {
        dispatch(
          actionCreateUpload({
            newId: v4(),
            file: item,
            title: item.name,
            type: item.type,
            history,
            isFavorite: selectorType === 'favorites',
            tags: localTags,
          }),
        );
      }
    });
    addFileInputRef.current.value = '';
  }, [
    dispatch,
    isPlaylist,
    selectorType,
    folderId,
    history,
    tags,
  ]);

  const onUploadClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    addFileInputRef?.current?.click();
  }, []);

  useEffect(() => {
    if (selectorType === 'recent' && sortedFunctions[sortType]) {
      const {
        todayPages,
        yesterdayPages,
        thisWeekPages,
        thisMonthPages,
        thisYearPages,
        previousYearsPages,
      } = splitArrayToRecentArrays(sortedPages);
      setToday(sortedFunctions[sortType](todayPages) || []);
      setYesterday(sortedFunctions[sortType](yesterdayPages) || []);
      setThisWeek(sortedFunctions[sortType](thisWeekPages) || []);
      setThisMont(sortedFunctions[sortType](thisMonthPages) || []);
      setThisYear(sortedFunctions[sortType](thisYearPages) || []);
      setThisPrevYear(sortedFunctions[sortType](previousYearsPages) || []);
    } else {
      setToday(sortedPages || []);
      setYesterday([]);
      setThisWeek([]);
      setThisMont([]);
      setThisYear([]);
      setThisPrevYear([]);
    }
  }, [sortedPages]);

  const isShowHeader = !isCardView && !!sortedPages?.length;
  const isRecentSelector = selectorType === 'recent';
  const isUsed = selectorType === 'used';

  const isNotEmpty = !!contentIds.length;

  const isLoading = loadStatus === downloadStatus.init || loadStatus === downloadStatus.pending;

  const showItemPlaceholder = existMore || (!isNotEmpty && isLoading);

  const isEmptyState = !isNotEmpty && !isLoading;

  const libraryContent = useCallback((contentIdsValue, noHeader) => {
    const content = contentIdsValue.map(id => contentData[id]);

    return (
      <LibraryContent
        contentData={content}
        isUsed={isUsed}
        permissions={permissions}
        isSimplifiedMultiselect={isSimplifiedMultiselect}
        setSimplifiedMultiselect={setSimplifiedMultiselect}
        needToDelete={needToDelete}
        selectorType={selectorType}
        scrollDelta={scrollDelta}
        installationIndex={installationIndex}
        activeNavSlider={activeNavSlider}
        counterSelectedPage={counterSelectedPage}
        noHeader={noHeader || false}
      />
    );
  }, [
    contentData,
    isUsed,
    permissions,
    isSimplifiedMultiselect,
    setSimplifiedMultiselect,
    needToDelete,
    selectorType,
    scrollDelta,
    installationIndex,
    activeNavSlider,
    counterSelectedPage,
  ]);

  return (
    <>
      {isShowHeader && (
        <ManyPagesTableHeader
          setSort={setSort}
          sortType={sortType}
          isLibrary={isLibrary}
          tableSchema={props.tableSchema}
        />
      )}

      {isEmptyState && (
        <NewEmptyState
          place={activeNavSlider}
          isLoading={isLoading}
          selector={selectorType}
          onUploadClick={onUploadClick}
          createPage={createPage}
          showNewSmartView={props.showNewSmartView}
        />
      )}
      <div className={`${!isCardView ? styles.row_list_spacing : ''}`}>
        {isRecentSelector && !!today?.length && (
          <div
            className={`${styles.recent_title_wrapper}
            ${styles.sideBarRecentFirst}
            ${!isCardView ? styles.title_list_spacing : styles.title_grid_spacing}`}
          >
            {t('todayUpT')}
          </div>
        )}
        <div className={`${isCardView ? styles.recent_grid_row_container : ''} 
          ${activeNavSlider.includes('smartfiles') ? styles.smartfiles_tab : ''}`}
        >
          {sortType === 'recent' && (
            <RecentFilterGroup
              contentData={contentData}
              isCardView={isCardView}
            >
              {(rowContentIds, noHeader) => libraryContent(rowContentIds, noHeader)}
            </RecentFilterGroup>
          ) || libraryContent(contentIds)}

          { showItemPlaceholder && (
            <ItemPlaceHolder
              isFlex={sortType === 'recent' && isCardView}
              type={isCardView ? activeNavSlider : 'row'}
            />
          )}
        </div>
      </div>
      <input
        type="file"
        name="file"
        multiple
        ref={addFileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          submitForm(e);
        }}
      />
    </>
  );
});

export default LibraryContentSelector;
