import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../utils/hooks/useQuery';

const EmailLinkRedirect = () => {
  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    const place = query.get('place');
    const isOpenSettings = !!query.get('isOpenSettings');

    history.push(
      `content/${place}/drafts${
        isOpenSettings ? '?isOpenSettings=true' : ''
      }`,
    );
  }, []);

  return null;
};

export default EmailLinkRedirect;
