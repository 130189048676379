import { DynamicCollection } from './types';
import SupportAction from '../support/types';
import { dynamicCollectionColorCalc } from '../../utils/constants';

const defaultState = {};

export const dynamicCollectionReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case DynamicCollection.uploadDC: {
      return { ...payload.dynamicCollections };
    }
    case SupportAction.InitialDataFetch: {
      return { ...payload.dynamicCollections };
    }
    case DynamicCollection.editDC: {
      return {
        ...state,
        [payload.dynamicCollectionId]: {
          ...state[payload.dynamicCollectionId],
          ...payload,
        },
      };
    }
    case DynamicCollection.renameDC: {
      return {
        ...state,
        [payload.dynamicCollectionId]: {
          ...state[payload.dynamicCollectionId],
          name: payload.name,
        },
      };
    }
    case DynamicCollection.UpdateCounterSmartView: {
      return {
        ...state,
        [payload.id]: { ...state[payload.id], counter: payload.counter },
      };
    }
    case DynamicCollection.addDC: {
      const newDc = payload.dynamicCollection;
      const newDerivedColors = dynamicCollectionColorCalc(newDc.color);
      return {
        ...state,
        [newDc.id]: { ...newDc, derivedColors: newDerivedColors },
      };
    }
    case DynamicCollection.changeDC: {
      const newDerivedColors = dynamicCollectionColorCalc(payload.color);
      return {
        ...state,
        [payload.id]: { ...payload, derivedColors: newDerivedColors },
      };
    }
    case DynamicCollection.moveDC_R: {
      const newDerivedColors = dynamicCollectionColorCalc(payload.color);
      return {
        ...state,
        [payload.id]: { ...payload, derivedColors: newDerivedColors },
      };
    }
    case DynamicCollection.deleteDC: {
      const newState = { ...state };
      delete newState[payload.dynamicCollectionId];
      return newState;
    }

    default:
      return state;
  }
};
