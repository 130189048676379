import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { actionCreator } from '../../shared/redux/actionHelper';
import { Settings } from '../../redux/settings/types';

const ConfirmEmailChange = () => {
  const history = useHistory();
  const { jwt } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actionCreator(Settings.Profile.ChangeEmailCheckJwt, { jwt, history }),
    );
  }, []);

  return null;
};

export default ConfirmEmailChange;
