import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { MainNavigate } from '../../../../../navigate/navigate';
import { DATA_PARENTS, emptyCallback } from '../../../../../utils/constants';

const UPVLibTabGroupWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  padding-left: 29px;
`;
const TabWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  border-radius: 6px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: max-content;
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;
  margin-right: 6px;
  ${({ isActive }) => (isActive
    ? `
  color: #484343;
  background: #FFC72C;
  `
    : 'color: #585858;')}

  ${({ disabled }) => (disabled
    ? `
    &:hover {
    cursor: not-allowed;
 
  }
  opacity:0.5;`
    : `  
  &:hover {
    cursor: pointer;
    background: #FFEEBF;
  }`)}
`;

const Divider = styled.div`
  background: rgba(29, 29, 29, 0.08);
  height: 18px;
  width: 1px;
  margin-right: 5px;
  position: relative;
  top: 5px;
`;
const TabGroup = ({ selector, setSelectorType, dataP }) => {
  const [options, setOptions] = useState([]);
  const [currentChoice, setCurrentChoice] = useState('');
  const { t, i18n } = useTranslation();

  const makeChoice = (tab) => {
    setCurrentChoice(tab);
    setSelectorType(tab);
  };

  useEffect(() => {
    MainNavigate[selector]
      && setOptions(MainNavigate[selector].selectorsOrderInSideBar);
    MainNavigate[selector]
      && setCurrentChoice(MainNavigate[selector]?.selectorsOrderInSideBar[0]);
  }, [selector]);

  useEffect(() => {
    MainNavigate[selector]
      && setSelectorType(MainNavigate[selector]?.selectorsOrderInSideBar[0]);
  }, []);

  return (
    <UPVLibTabGroupWrapper data-parent={dataP}>
      {options.map((it, index) => (
        <React.Fragment key={`${it}_${index}`}>
          {index === options.length - 1 && <Divider />}
          <TabWrapper
            disabled={it === 'shared'}
            isActive={currentChoice === it}
            onClick={
              it === 'shared' ? emptyCallback : () => makeChoice(it)
            }
            data-parent={dataP}
          >
            {it === 'shared' && t('sharedToMeT')
              || it === 'sharedByMe' && t('sharedT')
              || i18n.exists(`${it}T`) && t(`${it}T`)
              || it
            }
          </TabWrapper>
        </React.Fragment>
      ))}
    </UPVLibTabGroupWrapper>
  );
};

export default TabGroup;
