import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { actionDownloadFile } from '../../../redux/downloads/action';
import { actionShowMessage } from '../../../redux/support/action';
import {
  DEFAULT_PAGE_PATH,
  LibraryComponentTypes,
  MessageType,
  UiComponentTypes,
} from '../../../utils/constants';
import {
  actionDuplicateComponent,
  actionDuplicateSharedComponent,
  actionToggleComponentFavorite,
  actionUpdateLibraryComponent,
} from '../../../redux/library/actions';
import { actionUpdateCurrentPageTitle } from '../../../redux/currentPage/action';
import { actionCreator } from '../../../shared/redux/actionHelper';
import SupportAction from '../../../redux/support/types';
import { PdfContent } from '../contentGenerators/PDFContent';
import { ImageContent } from '../contentGenerators/ImageContent';
import { ContentWithBlocks } from '../contentGenerators/ContentWithBlocks';
import { EmbedContent } from '../contentGenerators/EmbedContent';
import { LinkContent } from '../contentGenerators/LinkContent';
import MsContent from '../MsContent';
import { ArchiveContent } from '../contentGenerators/ArchiveContent';
import {
  MAX_ZOOM_VALUE,
  MIN_ZOOM_VALUE,
  otherType,
  pageControls,
  scaleControls,
} from '../helpers';
import Header from './Header';
import rawStyles from '../index.module.scss';
import { LowControls } from './LowControls';
import { MuxAudioVideoPlayer } from '../../Player/childComponents/MuxPlayer';
import { AudioVideoContent } from '../contentGenerators/AudioVideo';

const styles = classNames.bind(rawStyles);

export const ViewLibraryComponent = ({
  item,
  isAnyCanEdit,
  isOwner: isOwnerProps,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [scalePercentage, setScalePercentage] = useState(100);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(20);
  const pdfRef = useRef(null);
  const { isOwner = isOwnerProps } = item;
  // const getOperation = (e) => e.target?.dataset.operation || e.target?.parentElement?.dataset.operation;

  const changeStep = (operation) => (e) => {
    e.stopPropagation();
    if (operation) setScalePercentage((prev) => scaleControls[operation](prev, 10));
  };

  function zoomHandler(event) {
    if (event.ctrlKey) {
      event.preventDefault();
      const isZoomIn = !!(Math.sign(event.deltaY) - 1);
      if (isZoomIn) {
        setScalePercentage((prev) => Math.min(prev + event.deltaY * -0.4, MAX_ZOOM_VALUE),
        );
      } else {
        setScalePercentage((prev) => Math.max(prev + event.deltaY * -0.3, MIN_ZOOM_VALUE),
        );
      }
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', zoomHandler, { passive: false });
    return () => document.removeEventListener('wheel', zoomHandler);
  }, []);

  useEffect(() => {
    pdfRef.current?.addEventListener('wheel', zoomHandler, { passive: false });
    return () => pdfRef.current?.removeEventListener('wheel', zoomHandler);
  }, [pdfRef]);

  const changePage = (operation) => (e) => {
    e.stopPropagation();
    if (operation) setPage((prev) => pageControls[operation](prev, totalPages));
  };

  const downloadFile = () => {
    dispatch(
      actionDownloadFile({
        id: item.id,
        folderId: item.inCollectionId,
        url: item.urlFile,
      }),
    );
  };

  const goToLink = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (item.linkUrl) {
      const newWindow = window.open(
        item.linkUrl,
        '_blank',
        'noopener,noreferrer',
      );
      if (newWindow) newWindow.opener = null;
    }
  };

  const toggleFavorite = () => {
    if (!item.isFavorite) {
      dispatch(
        actionShowMessage({
          type: MessageType.PlaylistToFavorite,
          itemName: item?.title || t('UnnamedComponentT'),
          effects: otherType(item.type) ? '' : 'moveUp',
        }),
      );
    }
    dispatch(
      actionToggleComponentFavorite(
        item.id,
        item.inCollectionId || item.folderId,
        !item.isFavorite,
        true,
      ),
    );
  };

  const goBack = () => {
    history.goBack();
  };

  const renameItem = (newTitle) => {
    if (isOwner) {
      dispatch(
        actionUpdateLibraryComponent(
          { ...item, field: 'title', value: newTitle },
          item.inCollectionId || item.folderId,
        ),
      );
      dispatch(actionUpdateCurrentPageTitle(newTitle));
    }
  };

  const duplicateItem = () => {
    dispatch(
      actionShowMessage({
        type: MessageType.ItemToCopy,
        itemName: item?.title || t('UnnamedComponentT'),
        effects: otherType(item.type) ? '' : 'moveUp',
      }),
    );
    dispatch(
      actionDuplicateComponent(item.inCollectionId || item.folderId, item.id),
    );
  };

  const saveInStudio = () => {
    dispatch(actionDuplicateSharedComponent(item.id));
  };

  const deleteItem = () => {
    dispatch(
      actionCreator(SupportAction.CheckIsInUse, {
        itemTitle: item?.title,
        itemId: item.id,
      }),
    );

    history.push(DEFAULT_PAGE_PATH);
  };
  return (
    <div className={styles('wrapper')}>
      <Header
        downloadFile={downloadFile}
        toggleFavorite={toggleFavorite}
        goBack={goBack}
        item={item}
        renameItem={renameItem}
        duplicateItem={duplicateItem}
        deleteItem={deleteItem}
        isOwner={isOwner}
        saveInStudio={saveInStudio}
        isAnyCanEdit={isAnyCanEdit}
        goToItem={goToLink}
      />

      {(UiComponentTypes.audio[item.type]
        || UiComponentTypes.video[item.type]) && (
        <AudioVideoContent>
          <MuxAudioVideoPlayer item={item} />
        </AudioVideoContent>
      )}
      {(UiComponentTypes.pdf[item.type] || item.pdfPreviewUrl) && (
        <>
          <PdfContent
            item={item}
            pdfRef={pdfRef}
            changePage={changePage}
            setTotalPages={setTotalPages}
            pages={{ current: page, max: totalPages }}
            scale={scalePercentage}
          />

          <LowControls
            isPdf
            changeStep={changeStep}
            scalePercentage={scalePercentage}
            changePage={changePage}
            pages={{ current: page, max: totalPages }}
          />
        </>
      )}
      {UiComponentTypes.image[item.type] && (
        <>
          <ImageContent
            item={item}
            pages={{ current: page, max: totalPages }}
            scale={scalePercentage}
            setScalePercentage={setScalePercentage}
          />

          <LowControls
            changeStep={changeStep}
            scalePercentage={scalePercentage}
            changePage={changePage}
            pages={{ current: page, max: totalPages }}
          />
        </>
      )}
      {UiComponentTypes.component[item.type] && (
        <ContentWithBlocks item={item} isPlayer />
      )}
      {UiComponentTypes.link[item.type]
        && item.type === LibraryComponentTypes.embed_component && (
          <EmbedContent item={item} />
      )}
      {UiComponentTypes.link[item.type]
        && item.type !== LibraryComponentTypes.embed_component && (
          <LinkContent item={item} goToLink={goToLink} />
      )}
      {UiComponentTypes.ms[item.type] && !item.pdfPreviewUrl && (
        <>
          <MsContent item={item} goToLink={goToLink} />
        </>
      )}
      {item.id && otherType(item.type) && (
        <>
          <ArchiveContent item={item} downloadFile={downloadFile} />
        </>
      )}
    </div>
  );
};
