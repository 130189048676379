import React from 'react';
import DashboardLeftMenuRow from './DashboardLeftMenuRow';
import { COLUMN_CUSTOM_TYPE } from '../../../utils/constants';

const KanbanLeftMenu = ({
  highlightedColumn,
  dragColumnOverId,
  onDropCard,
  closeColumn,
  filteredItem,
  onDragEnterRow,
  moveHandler,
}) => {
  return (
    <>
      {!!filteredItem?.length
        && filteredItem.map((column) => (
          <DashboardLeftMenuRow
            highlightedColumn={highlightedColumn}
            dragColumnOverId={dragColumnOverId}
            onDragEnter={onDragEnterRow(column.id)}
            onDropCard={onDropCard}
            moveHandler={moveHandler(column.id)}
            key={column.id}
            column={column}
          />
        ))}
      <DashboardLeftMenuRow
        highlightedColumn={highlightedColumn}
        dragColumnOverId={dragColumnOverId}
        onDragEnter={onDragEnterRow(COLUMN_CUSTOM_TYPE.CLOSE)}
        onDropCard={onDropCard}
        moveHandler={moveHandler(COLUMN_CUSTOM_TYPE.CLOSE)}
        column={{
          ...closeColumn,
          name: 'Completed',
          id: COLUMN_CUSTOM_TYPE.CLOSE,
        }}
        isStatic
      />
    </>
  );
};

export default KanbanLeftMenu;
