import { v4 as uuidv4 } from 'uuid';
import Contacts from './types';
import { Statuses } from '../../utils/permissions';
import { ServiceUser } from '../user/types';

const defaultState = {};

export default (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case Contacts.AddContactR: {
      const { item } = payload;
      return { ...state, [item.id]: { ...item } };
    }
    case Contacts.ChangeContactR: {
      const { item } = payload;
      return { ...state, [item.id]: { ...item } };
    }
    case Contacts.ChangeContactStatus: {
      const { id, status } = payload;
      const newItem = state[id];
      newItem.status = status;
      return { ...state, [id]: newItem };
    }
    case Contacts.RemoveContact: {
      const { contactId } = payload;
      const newState = { ...state };
      delete newState[contactId];
      return newState;
    }
    case Contacts.BulkAddContact: {
      const { emailsToInvite } = payload;

      const newContacts = {};
      emailsToInvite.forEach((item) => {
        const newId = uuidv4();
        newContacts[newId] = {
          id: newId,
          name: '',
          email: item,
          status: Statuses.Created,
          role: 'Viewer',
        };
      });
      return { ...state, ...newContacts };
    }
    case ServiceUser.Logout:
      return { ...defaultState };
    default:
      return state;
  }
};
