import React from 'react';
import AvatarPlaceholder from '../../../../components/Avatar';
import { theLimit } from './AvatarGroup';

export const calculateUsername = (user = {}) => {
  if (user.showUsername && user.username) {
    return user.username;
  }
  if (!user.showUsername && (user.first_name || user.last_name)) {
    return (`${user.first_name || ''} ${user.last_name || ''} `).trim();
  }
  if (user.email) {
    return user.email;
  }

  return 'User does not exist anymore';
};

export const calculateTextForLastAvaPlaceholder = (users) => {
  return users.reduce((acc, value) => {
    if (!acc) {
      return calculateUsername(value);
    }
    return `${acc}, ${calculateUsername(value)}`;
  }, '');
};
export const calculateLeftMarginForAfterAvaGroup = (coEditorsLength) => {
  if (!coEditorsLength) {
    return false;
  }

  if (coEditorsLength > theLimit) {
    return -83;
  }
  if (coEditorsLength === 1) {
    return 7;
  }
  if (coEditorsLength === 2) {
    return -3;
  }
  return -3 - (10 * (coEditorsLength - 2));
};

export const calculateSingleUserAvatar = (user = {}, width = 28) => {
  if (typeof user === 'string') {
    return (
      <AvatarPlaceholder
        width={width}
        name={user}
        abbreviation={user}
        bgColor="rgb(255, 199, 44);"
        color="rgb(72, 67, 67);"
      />
    );
  }
  if (user.displayImage === 'AvatarImage' && user.avatarUrlVerySmall) {
    return (
      <img
        src={user?.avatarUrlVerySmall}
        alt="ava"
      />
    );
  }
  if (user.first_name || user.last_name) {
    return (
      <AvatarPlaceholder
        width={width}
        name={user?.first_name}
        abbreviation={`${user?.first_name[0] || ''}${user?.last_name[0] || ''}`}
      />
    );
  }
  if (user.email) {
    return (
      <AvatarPlaceholder
        width={width}
        name={user.email}
        abbreviation={`${user.email[0]}`}
      />
    );
  }

  return (
    <AvatarPlaceholder
      width={width}
      name="User doesnt exist anymore"
      abbreviation="∞"
    />
  );
};
