import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import {
  calcDisplayName,
  DATA_PARENTS,
  empty,
  TagsParams,
} from '../../../utils/constants';
import { ReactComponent as TableOfContentSvg }
  from '../../../images/2023/svg/small/component-description/table_content_14_14.svg';
import { ReactComponent as TableCheckSvg }
  from '../../../images/2023/svg/small/component-description/table_check_14_14.svg';
import SmallRectangleBtnWithIcon from '../../SharedComponents/SmallRectangleBtnWithIcon';
import {
  timeSince,
} from '../../../utils/dateConvert';
import TableOfContent from './TableOfContent';

import AvatarPlaceholder from '../../Avatar';
import { Avatar } from '../../MainHeader/User';
import CloseRoundedBtnWithCross from '../../SharedComponents/CloseRoundedBtnWithCross';
import {
  ChannelListWrapper,
  ContentTextWrapper,
  DataBlockColumns,
  DataBlockWrapper,
  DescriptionWrapper,
  DetailsWrapper,
  Divider,
  GlobalOneLineTextWrapped,
  GreyTitleWrapper,
  ImageWrapper,
  TitleBlockWrapper,
  UserWrapper,
} from './styled';
import { actionUpdateLibraryComponent } from '../../../redux/library/actions';
import { actionUpdatePlaylist } from '../../../redux/playlists/action';
import UsedIn from './UsedIn';
import mimeTypes from '../../../utils/mimeTypes';
import HashtagsInput from '../../../pages/Playlist/HashtagsInput';
import {
  actionSaveComponentDescriptionData,
  actionToggleComponentDescription,
} from '../../../redux/support/action';
import ExtensibleInput from '../../ExtensibleInput/ExtensibleInput';
import { getAvailability, getPlaylistType } from '../../../utils/helpers';
import { capitalizeFirstLetter } from '../../../utils/helperText';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import PreviewMinorCard from '../../CustomLightGallery/PrviewMinorCard';
import FolderImage from '../../../pages/Maker/FolderImage';
import FolderIconLayout from '../../../pages/Maker/FolderIconLayout';
import { actionCreator } from '../../../shared/redux/actionHelper';
import Playlist from '../../../redux/playlists/types';

export const Trim = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ComponentDescription2023 = ({ ...props }) => {
  const { t } = useTranslation();
  const { id, folderId, itemType } = useSelector(
    (state) => state.support.componentDescriptionData || empty,
  );
  const isDescriptionOpened = useSelector(
    (state) => state.support.componentDescription,
  );

  const linkPages = useSelector((state) => state.currentPage?.linkPages);
  const item = useSelector((state) => {
    if (!id) return empty;
    return state.content.contentData[id] || empty;
  });
  const {
    id: ownerID,
  } = useSelector((state) => state.user);
  // const { linesCount, isTextOverflown } = useDescriptionHeightCalculator('wallace', 16);
  const [refMenu, showTableOfContent, setShowTableOfContent] = useComponentVisible(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [description, setDescription] = useState('');
  const { selectorType } = useSelector((state) => state.content);
  const isSharedToMe = item.type === 'sharedPage';

  const isSharedPages = selectorType === 'shared' || isSharedToMe;
  const isSharedSmartFile = item.status === 'shared';

  const history = useHistory();
  const dispatch = useDispatch();
  const isLibComponent = itemType === 'libComponent';
  const isVideo = mimeTypes.video[item.type];

  let coverImageSrc = null;
  if (isVideo) {
    coverImageSrc = `https://image.mux.com/${item.playbackID}/thumbnail.jpg?width=211&height=211&fit_mode=smartcrop`;
  } else if (item.urlFile || item.img) {
    coverImageSrc = item.urlFile || item.img;
  }
  const isOwner = ownerID === item?.owner?.id;
  const isChannel = history.location.pathname.startsWith('/channel/');
  const isPlaylistMeta = itemType === 'playlist';

  const showContent = (e) => {
    e.stopPropagation();
    setShowTableOfContent(!showTableOfContent);
  };

  const save = (field) => (value) => {
    if (value !== item[field] && !isPlaylistMeta) {
      dispatch(
        actionUpdateLibraryComponent({ id: item.id, field, value }, folderId),
      );
    } else if (isPlaylistMeta) {
      dispatch(actionUpdatePlaylist({ id: item.id, field, value }, folderId));
    }
  };

  const handleClick = () => {
    setShowTableOfContent(!showTableOfContent);
  };

  const close = () => {
    props.setIsShowRightSideBarLib(false);
    dispatch(actionToggleComponentDescription(false));
    dispatch(actionSaveComponentDescriptionData());
  };

  useEffect(() => {
    if (!isDescriptionOpened) {
      return;
    }
    props.setIsShowRightSideBarLib(!!id);
    setDescription(item.description || '');
    if (isLibComponent) {
      // dispatch(actionSaveComponentDescriptionData(id, 'item.folderId'));
    } else {
      // let type = '';
      // if (history.location.pathname.includes('shared')) {
      //   type = 'shared';
      // } else if (history.location.pathname.includes('publish')) {
      //   type = 'publish';
      // } else {
      //   type = 'edit';
      // }
      if (id) {
        dispatch(
          actionCreator(Playlist.GetUniversalViewData, {
            id,
            type: itemType,
            setIsLoaded,
          }),
        );
      }
    }
  }, [id]);

  const abbreviation = (item.owner?.first_name?.[0] ?? '') + (item.owner?.last_name?.[0] ?? '');


  const calcSmartFileSize = `${(item.totalSizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
  if (!props.isShowRightSideBarLib) {
    return <></>;
  }

  if (isLibComponent) {
    return (
      <DescriptionWrapper
        ref={props.wrapperRef}
        // onClick={onMouseDownCloseMenu}
        // onClick={() => props.setIsShowRightSideBarLib(false)}
        data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary}`}
        onClick={handleClick}
      >
        <DetailsWrapper>{t('detailsT')}</DetailsWrapper>
        <CloseRoundedBtnWithCross top={27} right={30} onClick={close} />
        <PreviewMinorCard item={item} isLibDescription />
        <GreyTitleWrapper first>{t('fileTitleT')}</GreyTitleWrapper>
        <TitleBlockWrapper>
          <Trim>
            <p title={item.title}>{item.title}</p>
          </Trim>
        </TitleBlockWrapper>
        <DataBlockWrapper>
          <DataBlockColumns>
            <GreyTitleWrapper stepBot>{t('authorT')}</GreyTitleWrapper>
            <ContentTextWrapper>{calcDisplayName(item)}</ContentTextWrapper>
            <GreyTitleWrapper stepBot>{t('createdT')}</GreyTitleWrapper>
            <ContentTextWrapper>
              {timeSince(item.createDate * 1000)}
            </ContentTextWrapper>
            <GreyTitleWrapper stepBot>{t('sizeT')}</GreyTitleWrapper>
            <ContentTextWrapper>
              {item.size ? (item.size / 1000000).toFixed(1) : '--'}
            </ContentTextWrapper>
          </DataBlockColumns>
          <DataBlockColumns>
            <GreyTitleWrapper stepBot>{t('typeT')}</GreyTitleWrapper>
            <ContentTextWrapper>
              <Trim>
                <p title={item.type}>{item.type}</p>
              </Trim>
            </ContentTextWrapper>
            <GreyTitleWrapper stepBot>{t('modifiedT')}</GreyTitleWrapper>
            <ContentTextWrapper>
              {timeSince(item.lastModifiedDate * 1000)}
            </ContentTextWrapper>
            <GreyTitleWrapper stepBot>{t('resolutionT')}</GreyTitleWrapper>
            <ContentTextWrapper>{t('underDevelopmentT')}</ContentTextWrapper>
          </DataBlockColumns>
        </DataBlockWrapper>

        {!isSharedPages && (
          <>
            {item.usedIn?.length > 0 && (
              <>
                <Divider />
                <GreyTitleWrapper stepBot>{t('usedInT')}</GreyTitleWrapper>
                <ContentTextWrapper>
                  <UsedIn
                    data={item.usedIn}
                    setIsShowRightSideBarLib={props.setIsShowRightSideBarLib}
                    isShowRightSideBarLib={props.isShowRightSideBarLib}
                  />
                </ContentTextWrapper>
              </>
            )}

            <Divider />

            <GreyTitleWrapper stepBot>{t('tagsT')}</GreyTitleWrapper>
            <HashtagsInput
              isPage={false}
              noDescription
              isEditable
              isComponentDescription
              place="Library Component"
              type="LibraryComponent"
              isTemplate
              isLibraryComponentSettings
              state={TagsParams.tags}
            />
          </>
        )}
      </DescriptionWrapper>
    );
  }

  return (
    <DescriptionWrapper
      data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary}`}
      ref={props.wrapperRef}
    >
      <DetailsWrapper>{t('detailsT')}</DetailsWrapper>
      <CloseRoundedBtnWithCross top={27} right={30} onClick={close} />
      <ImageWrapper>
        {coverImageSrc ? (
          <>
            <img src={coverImageSrc} alt="sf-preview" />
            <FolderIconLayout id="templateSvg" isOverCoverImage />
          </>
        ) : (
          <FolderImage itemId={item.id} currentColor={item.defaultPreviewColor ?? 'yellow'} />
        )}
      </ImageWrapper>
      <GreyTitleWrapper>{t('smartFileTitleT')}</GreyTitleWrapper>
      <TitleBlockWrapper>
        <GlobalOneLineTextWrapped style={{ width: 250 }}>
          {item.title ? item.title : t('untitledSmartfileT')}
        </GlobalOneLineTextWrapped>
        <SmallRectangleBtnWithIcon
          click={showContent}
          position="absolute"
          bottom={-7}
          right={-4}
        >
          <TableOfContentSvg />
        </SmallRectangleBtnWithIcon>
        {showTableOfContent && (
          <TableOfContent
            isSharedSmartFile={isSharedSmartFile}
            setIsShowRightSideBarLib={props.setIsShowRightSideBarLib}
            linkPages={linkPages}
            id={id}
            refMenu={refMenu}
          />
        )}
      </TitleBlockWrapper>
      <DataBlockWrapper>
        <DataBlockColumns>
          <GreyTitleWrapper stepBot>{t('senderT')}</GreyTitleWrapper>
          <UserWrapper>
            {item?.owner?.avatarUrlVerySmall ? (
              <Avatar
                className="avatar_border_hover"
                width={20}
                noMargins
                src={item.owner.avatarUrlVerySmall}
                alt="avatar"
              />
            ) : (
              <AvatarPlaceholder
                width={20}
                name={item?.owner?.first_name}
                abbreviation={abbreviation}
              />
            )}
            <ContentTextWrapper isUserName>{calcDisplayName(item)}</ContentTextWrapper>
          </UserWrapper>
          <GreyTitleWrapper stepBot>{t('createdT')}</GreyTitleWrapper>
          <ContentTextWrapper>
            {timeSince(item.createDate * 1000)}
          </ContentTextWrapper>
          <GreyTitleWrapper stepBot>{t('availabilityT')}</GreyTitleWrapper>
          <ContentTextWrapper>
            {getAvailability(
              getPlaylistType({
                path: history.location.pathname,
                forMeta: true,
              }),
              item,
            )}
          </ContentTextWrapper>
        </DataBlockColumns>
        <DataBlockColumns>
          <GreyTitleWrapper stepBot>{t('sizeT')}</GreyTitleWrapper>
          <ContentTextWrapper>
            {calcSmartFileSize}
          </ContentTextWrapper>
          <GreyTitleWrapper stepBot>{t('modifiedT')}</GreyTitleWrapper>
          <ContentTextWrapper>
            {timeSince(item.lastModifiedDate * 1000)}
          </ContentTextWrapper>
          <GreyTitleWrapper stepBot>{t('statusT')}</GreyTitleWrapper>
          {capitalizeFirstLetter(item.statusForMeta)}
          {isChannel && item.isPublish && (
            <ContentTextWrapper>
              {t('sharedT')}
            </ContentTextWrapper>
          )}
        </DataBlockColumns>
      </DataBlockWrapper>
      <GreyTitleWrapper stepBot>{t('channelsT')}</GreyTitleWrapper>
      <ChannelListWrapper>
        {!!item.usedInChannels
          && Object.keys(item.usedInChannels).length
          && Object.keys(item.usedInChannels).map((key) => {
            return (
              <ContentTextWrapper forChannelList>
                {item.usedInChannels[key].name || t('unnamedChannelT')}
              </ContentTextWrapper>
            );
          })}

        <SmallRectangleBtnWithIcon position="absolute" bottom={1} right={-4}>
          <TableCheckSvg />
        </SmallRectangleBtnWithIcon>
      </ChannelListWrapper>
      <Divider />

      {((item.description && !isOwner) || isOwner) && (
        <>
          <GreyTitleWrapper>{t('descriptionT')}</GreyTitleWrapper>
          <ExtensibleInput
            id="wallace"
            isDescriptionMargin
            noDescription={!item.description}
            value={description}
            setValue={setDescription}
            save={() => save('description')(description)}
            readOnly={!isOwner || isChannel}
          />
        </>
      )}
      <GreyTitleWrapper stepBot>{t('imageCoverT')}</GreyTitleWrapper>
      <ContentTextWrapper>
        <a href={coverImageSrc}>{coverImageSrc}</a>{' '}
      </ContentTextWrapper>
      <DataBlockWrapper>
        <DataBlockColumns>
          <GreyTitleWrapper stepBot>{t('totalItemsT')}</GreyTitleWrapper>
          <ContentTextWrapper>{linkPages?.length || 0}</ContentTextWrapper>
          <GreyTitleWrapper stepBot>{t('totalViewsT')}</GreyTitleWrapper>
          <ContentTextWrapper>0</ContentTextWrapper>
        </DataBlockColumns>
        <DataBlockColumns>
          <GreyTitleWrapper stepBot>{t('totalCommentsT')}</GreyTitleWrapper>
          <ContentTextWrapper>0</ContentTextWrapper>
        </DataBlockColumns>
      </DataBlockWrapper>
    </DescriptionWrapper>
  );
};

export default ComponentDescription2023;
