import React, { useMemo } from 'react';
import { ReactComponent as CompletedSVG } from '../../images/2023/svg/small/complete/checked_20_20.svg';
import {
  PBCardViewInnerContainer,
  PBCounterContainer,
  PBEmptyStripe,
  PBFilledStripe,
  PBSVGWrapper,
  PBWrapper,
} from './styled';


const CardView = ({ viewersProgress, isFinished }) => {
  const { viewed, total } = viewersProgress;
  const isCompleted = total === viewed || isFinished;
  const filledWidth = useMemo(() => (isCompleted
    ? 130
    : Math.round(viewed * 152 / total)), [viewed, total]);

  if (total === 0) {
    return <></>;
  }

  return (
    <PBWrapper isCardView>
      <PBCardViewInnerContainer isCardView>
        <PBCounterContainer isCardView>{`${isCompleted ? total : viewed}/${total}`}</PBCounterContainer>
        <PBEmptyStripe isCardView>
          <PBFilledStripe width={filledWidth} isCardView />
          {isCompleted && <PBSVGWrapper> <CompletedSVG /></PBSVGWrapper>}
        </PBEmptyStripe>
      </PBCardViewInnerContainer>

    </PBWrapper>
  );
};

export default CardView;
