import { BlockTypes } from '../../utils/constants';

export const getLayersForPage = (block) => {
  const a = [];
  block?.reduce(
    (acc, item, index) => {
      if (index === 0) {
        if (item.type === BlockTypes.component) {
          a.push(getLayersForPage(item.components));
          return getLayersForPage(item.components);
        }
        if (item.type === BlockTypes.ordered_list_item0) {
          a.push([1]);
          return [1];
        }
        if (item.type === BlockTypes.unordered_list_item1) {
          a.push([1, 1]);
          return [1];
        }
        if (item.type === BlockTypes.unordered_list_item2) {
          a.push([1, 1, 1]);
          return [1];
        }
        a.push([1]);
        return [1];
      }

      if (item.type === BlockTypes.component) {
        a.push(getLayersForPage(item.components));
        return getLayersForPage(item.components);
      }

      if (!block[index - 1].type?.startsWith(BlockTypes.ordered_list_item)) {
        if (item.type === BlockTypes.ordered_list_item0) {
          acc = [1];
          a.push([...acc]);
          return acc;
        }
        if (item.type === BlockTypes.ordered_list_item1) {
          acc = [1, 1];
          a.push([...acc]);
          return acc;
        }
        if (item.type === BlockTypes.ordered_list_item2) {
          acc = [1, 1, 1];
          a.push([...acc]);
          return acc;
        }
      }

      if (item.type === BlockTypes.ordered_list_item0) {
        acc[0] = acc[0] ? acc[0] + 1 : 1;
        a.push([acc[0]]);
        return [acc[0]];
      }

      if (item.type === BlockTypes.ordered_list_item1) {
        if (block[index - 1].type === item.type) {
          acc[1] += 1;
          a.push([acc[0], acc[1]]);
          return [acc[0], acc[1]];
        }
        acc[1] = acc[1] ? acc[1] + 1 : 1;
        a.push([acc[0], acc[1]]);
        return [acc[0], acc[1]];
      }

      if (item.type === BlockTypes.ordered_list_item2) {
        if (block[index - 1].type === item.type) {
          acc[2] += 1;
          a.push([...acc]);
          return acc;
        }
        acc[2] = 1;
        acc[1] = acc[1] ? acc[1] : 1;
        acc[0] = acc[0] ? acc[0] : 1;
        a.push([...acc]);
        return acc;
      }

      a.push([1]);
      return [1];
    },
    [1],
  );

  return block?.map((item, index) => ({
    counter: a[index],
    type: item.type,
  }));
};
