import React from 'react';
import { calculateSingleUserAvatar, calculateUsername } from './helpers';
import { AvatarPopOver, AvatarWrapper } from './styled';


const SingleAvatarComponent = ({ user, index }) => {
  return (
    <AvatarWrapper key={user?.email || index} left={index * 10}>
      <AvatarPopOver
        imageIsFirst={index === 0}
        imageIsImage={user.displayImage === 'AvatarImage' && user.avatarUrlVerySmall}
        className="ava_popover"
      >{calculateUsername(user)}
      </AvatarPopOver>
      {calculateSingleUserAvatar(user)}

    </AvatarWrapper>
  );
};

export default SingleAvatarComponent;
