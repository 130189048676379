import React, { useMemo } from 'react';
import { ReactComponent as CompletedSVG } from '../../images/2023/svg/small/complete/checked_20_20.svg';
import { PBCounterContainer, PBEmptyStripe, PBFilledStripe, PBSVGWrapper, PBWrapper } from './styled';


const ListView = ({ viewersProgress, isFinished }) => {
  const { viewed, total } = viewersProgress;
  const isCompleted = total === viewed || isFinished;
  const filledWidth = useMemo(() => (isCompleted
    ? 90
    : Math.round(viewed * 90 / total)), [viewed, total]);

  if (total === 0) {
    return <></>;
  }

  return (
    <PBWrapper isListView>
      <PBEmptyStripe isListView>
        <PBFilledStripe width={filledWidth} />
        {isCompleted && <PBSVGWrapper> <CompletedSVG /></PBSVGWrapper>}
      </PBEmptyStripe>
      <PBCounterContainer isListView>{`${isCompleted ? total : viewed}/${total}`}</PBCounterContainer>
    </PBWrapper>
  );
};

export default ListView;
