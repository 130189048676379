import EditPlaylist from './types';
import { MiniPlaylists } from '../miniPlaylists/types';
import { CurrentPage } from '../currentPage/types';
import { ServiceUser } from '../user/types';
import { ViewsActions } from '../views/types';
import { Tags } from '../tags/types';
import SupportAction from '../support/types';

const defaultState = {};

export default (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case EditPlaylist.Get: {
      return payload;
    }
    case EditPlaylist.SaveRequestedUserPlaylists: {
      return { ...state, ...payload.content };
    }
    case EditPlaylist.EditPublishStateR: {
      return {
        ...state,
        [payload.playlistId]: {
          ...state[payload.playlistId],
          ...payload.inputs,
          isPublish: payload.isPublish,
        },
      };
    }
    case EditPlaylist.Add: {
      return { ...payload, ...state };
    }
    case MiniPlaylists.togglePublishMiniPlaylist: {
      if (state[payload.id]) {
        state[payload.id].isPublish = payload.isPublish;
        return { ...state };
      }
      return state;
    }

    case EditPlaylist.ToggleIsShowDescriptionPlaylist: {
      if (state[payload.id]) {
        const newState = { ...state, [payload.id]: { ...state[payload.id] } };
        newState[payload.id].isShowDescription = payload.isShowDescription;
        return newState;
      }
      return state;
    }

    case EditPlaylist.Delete: {
      const newState = { ...state };
      delete newState[payload.id];

      return { ...newState };
    }
    /*
    case EditPlaylist.updateMoveToTrash: {
      if (state[payload.id]) {
        const newState = { ...state, [payload.id]: { ...state[payload.id] } };
        newState[payload.id].movedToTrash = payload.state;
        return newState;
      }
      return state;
    } */

    case CurrentPage.UpdatePlaylistLastModifiedDate: {
      const { id, lastModifiedDate, isPage } = payload;
      if (isPage) return state;

      if (state[id]) {
        const newState = { ...state };
        newState[id].lastModifiedDate = lastModifiedDate;
        return { ...newState };
      }
      return state;
    }
    case ServiceUser.Logout: {
      return { ...defaultState };
    }
    case EditPlaylist.AddPageToPlaylistR: {
      return { ...state, [payload.id]: { ...payload } };
    }
    case Tags.AddTagToPlaylist: {
      const { tag, playlist } = payload;
      return {
        ...state,
        [playlist.id]: {
          ...state[playlist.id],
          tags: [...state[playlist.id].tags, tag],
        },
      };
    }
    case Tags.DeleteTag: {
      const { tag, type: localType, parent } = payload;
      if (localType !== 'Playlist' || !state[parent.id]) return state;
      const newState = JSON.parse(JSON.stringify(state));
      newState[parent.id].tags = newState[parent.id]?.tags.filter(
        (item) => item.id !== tag.id,
      );
      newState[parent.id].lastModifiedDate = parent.lastModifiedDate;
      return newState;
    }
    case SupportAction.updateTag.DeleteLocal: {
      const { tag, type: localType, parent } = payload;
      if (localType !== 'Playlist' || !state[parent.id]) return state;
      const newState = JSON.parse(JSON.stringify(state));
      newState[parent.id].tags = newState[parent.id]?.tags.filter(
        (item) => item.id !== tag.id,
      );
      newState[parent.id].lastModifiedDate = parent.lastModifiedDate;
      return newState;
    }
    case EditPlaylist.MarkViewedR: {
      const { isFinished, playlistId } = payload;
      return { ...state, [playlistId]: { ...state[playlistId], isFinished } };
    }
    case CurrentPage.InsertGoogleUploadBlockR: {
      const { playlistId, lastModifiedDate } = payload;
      return {
        ...state,
        [playlistId]: { ...state[playlistId], lastModifiedDate },
      };
    }
    case CurrentPage.DeleteLinkPageR: {
      const { playlistId, lastModifiedDate } = payload;
      return {
        ...state,
        [playlistId]: { ...state[playlistId], lastModifiedDate },
      };
    }
    case CurrentPage.UpdateGoogleUploadBlockR: {
      const { playlistId, lastModifiedDate } = payload;
      return {
        ...state,
        [playlistId]: { ...state[playlistId], lastModifiedDate },
      };
    }
    default:
      return state;
  }
};
