import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { empty } from '../../utils/constants';
import { actionAddNewMuxContent } from '../../redux/muxContentStatus/actions';
import mimeTypes from '../../utils/mimeTypes';

import CardView from './CardView';
import CardViewUploading from './CardViewUploading';
import styles from './index.module.css';

const CardViewWrap = React.memo(function CardViewWrap({
  onCompositeClickHandler,
  item,
  ...props
}) {
  const [localStatus, setLocalStatus] = useState({ init: true });
  const [itemUploadData, setItemUploadData] = useState({});

  const uploadData = useSelector(state => state.uploads.components);
  const dispatch = useDispatch();

  const bigSizeCondition = item.type === 'page'
    || item.type === 'sharedPage'
    || item.type === 'application/pdf';

  useEffect(() => {
    const data = { ...uploadData[item.id] };
    setItemUploadData(data || empty);
    if (Object.values(data || {}).length) {
      if (data.status === 'ERROR') setLocalStatus({ error: true });
      else if (data.progress === 1 && data.status === 'READY') setLocalStatus({ ready: true });
      else if (data.progress === 0 && data.status === 'READY') setLocalStatus({ ready: true });
      else if (
        data.progress !== 1
        && data.progress !== 0
        && data.status === 'UPLOADING'
      ) {
        setLocalStatus({ uploading: true });
      } else {
        setLocalStatus({ processing: true });
      }
    } else {
      setLocalStatus({ ready: true });
    }
    const needToBePooled = item.assetID
      && (!item.videoProcessingStatus
        || item.videoProcessingStatus === 'processing')
      && (mimeTypes.audio[item?.type] || mimeTypes.video[item?.type]);
    if (needToBePooled) {
      dispatch(
        actionAddNewMuxContent({
          id: item.id,
          videoProcessingStatus: 'processing',
          assetID: item.assetID,
        }),
      );
    }
  }, [item, uploadData, dispatch]);

  const handleClick = useCallback((e) => {
    onCompositeClickHandler(e);
  }, [onCompositeClickHandler]);

  return (
    <div
      data-parent={props.dataP}
      onClick={handleClick}
      ref={props.refPage}
      className={`${styles.page}
        ${bigSizeCondition ? styles.page_big : ''}
        ${props.isUPV ? styles.page_isUPV : ''}
      `}
    >
      {
        !localStatus?.ready
          ? (
            <CardViewUploading
              localStatus={localStatus}
              itemUploadData={itemUploadData}
              item={item}
            />
          )
          : (
            <CardView
              {...props}
              item={item}
              itemUploadData={itemUploadData}
              bigSizeCondition={bigSizeCondition}
              onCompositeClickHandler={onCompositeClickHandler}
            />
          )
      }
    </div>
  );
});

export default CardViewWrap;
