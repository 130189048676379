import styled from 'styled-components/macro';
import { keyframes } from 'styled-components';

export const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 30%;
  font-size: 14px;
  color: #596080;
  margin-bottom: 10px;
  height: 32px;
  cursor: pointer;

  & img {
    width: 21px;
    height: 21px;
    justify-content: center;
  }

  ${({ center }) => (center
    ? `
    justify-content: center;
  `
    : '')}

  ${({ isShortcuts, name }) => (isShortcuts && name
    ? `
    width: 62%;
    font-weight: 500;
    margin-bottom: 0;
    color: #343434;
  `
    : '')}

  ${({ isShortcuts, type }) => (isShortcuts && type
    ? `
    width: 25%;
    margin-bottom: 0;
    justify-content: flex-start;
    color: #585858;
    
  `
    : '')}
  ${({ isShortcuts, last }) => (isShortcuts && last
    ? `
      font-weight: 500;
      justify-content: flex-end;
      margin-bottom: 0;
      color: #585858;
    `
    : '')}
    
  ${({ isShortcuts, isHeader }) => (isShortcuts && isHeader
    ? `
    color: #585858;
    font-weight: 500;
  `
    : '')}
    
  ${({ sequence }) => (sequence
    ? `
    margin-left: 35px;
    width: 50%;
  `
    : '')}
  
  ${({ marginLeft }) => (marginLeft
    ? `
    margin-left: 40px;
  `
    : '')}
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${({ isEmojis }) => (isEmojis ? '' : 'padding-left: 8px;')}
  ${({ isShortcuts }) => (isShortcuts ? `
    padding-left: 14px;
    padding-right: 20px;
    border-bottom: 1px solid rgba(29, 29, 29, 0.08);
    margin-bottom: 10px;
  ` : '')}
`;

const fadeIn = keyframes`
  0% {
    height: 0;
   }
  100% {
    height: 32px;
  }
`;

export const Row = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  border-radius: 4px;
  padding-left: 14px;
  padding-bottom: 5px;

  animation: ${fadeIn} ease-in-out 0.3s;

  cursor: pointer;
  &:hover {
    background: #e8ebfa;
  }
  ${({ isShortcuts }) => (isShortcuts ? `
    padding-right: 20px;
    &:hover {
      background: #FFEEBF;
      border-radius: 8px;
    } ;` : '')}
  `;

export const TableShell = styled.div`
  display: flex;
  flex-direction: column;
  width: 412px;
 

  ${({ isShortcuts }) => (isShortcuts ? ' width: 100%;' : '')}
`;

export const EmptyMessage = styled.div`
  font-size: 14px;
  color: #596080;
`;

export const Text = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  cursor: pointer;
`;
