import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import FlagsApi from 'country-flag-icons/react/3x2';
import { hasFlag } from 'country-flag-icons';
import rawStyles from './index.module.scss';
import { ReactComponent as Search16Svg } from '../../../../images/icons/search.svg';
import { ReactComponent as UnknownFlagSvg } from '../../../../images/icons/UNKNOWN_FLAG.svg';
import { countriesPhonePrefixes } from '../../../../utils/countriesPhonePrefixUtils';

const cn = classNames.bind(rawStyles);

const MAX_SCROLL_HEIGHT = 100;
const SCROLL_ITEM_HEIGHT = 32;

const CountryPickerDropDown = ({
  parentRef,
  defaultPosition = { top: 31, left: 0 },
  selectedCountry,
  onChangeCountry,
  setIsDropDownActive,
}) => {
  const [nameFilter, setNameFilter] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const filterRef = useRef(null);

  useEffect(() => {
    if (isFilterActive && filterRef?.current) filterRef.current.focus();
  }, [isFilterActive]);

  useEffect(() => {
    if (nameFilter) {
      setSuggestions(
        Object.values(countriesPhonePrefixes).filter((country) => {
          const name = country.name.toLowerCase();
          const prefix = country.prefix.toLowerCase();
          const code = country.code.toLowerCase();
          return (
            !!name.startsWith(nameFilter.toLowerCase())
            || !!prefix.startsWith(nameFilter.toLowerCase())
            || !!code.startsWith(nameFilter.toLowerCase())
          );
        }),
      );
    } else {
      setSuggestions(Object.values(countriesPhonePrefixes));
    }
  }, [nameFilter]);

  const calculateVerticalPosition = () => {
    if (parentRef && parentRef?.current) {
      return parentRef?.current.getBoundingClientRect().y + defaultPosition.top;
    }
    return defaultPosition.top;
  };

  const calculateHorizontalPosition = () => {
    if (parentRef && parentRef?.current) {
      return parentRef?.current.getBoundingClientRect().x;
    }
    return defaultPosition.left;
  };

  const calculateHeight = () => {
    if (suggestions.length >= 3) {
      return MAX_SCROLL_HEIGHT;
    }
    if (!suggestions.length) {
      return 0;
    }
    return SCROLL_ITEM_HEIGHT * suggestions.length;
  };

  const divStyle = {
    top: calculateVerticalPosition(),
    left: calculateHorizontalPosition(),
  };
  const scrollStyle = {
    height: calculateHeight(),
  };

  const resetFilterInput = () => {
    setIsFilterActive(false);
    setNameFilter('');
  };
  const onFilterInputChange = (e) => setNameFilter(e.target.value);
  const onSearchHintClick = () => setIsFilterActive(true);

  return (
    <div className={cn('drop_list')} style={divStyle}>
      <div className={cn('filter_section')}>
        <Search16Svg className={cn('search_icon')} />
        <div
          className={cn('search_hint', { hide: isFilterActive })}
          onClick={onSearchHintClick}
        >
          Search for countries
        </div>
        <div className={cn('input_wrapper', { show: isFilterActive })}>
          <input
            className={cn('input')}
            ref={filterRef}
            type="text"
            onChange={onFilterInputChange}
            value={nameFilter}
          />
        </div>
      </div>
      <div className={cn('scroll_list')} style={scrollStyle}>
        {suggestions.map((item) => (
          <div
            key={item?.code}
            className={cn('country_item', {
              active: selectedCountry?.code === item?.code,
            })}
            onClick={() => {
              onChangeCountry(item);
              resetFilterInput();
              setIsDropDownActive(false);
            }}
          >
            <div className={cn('flag')}>
              {hasFlag(item?.code) && (
                <div className={cn('flag_container')}>
                  <div className={cn('flag_icon_border')} />
                  {React.createElement(FlagsApi[item?.code], {
                    className: cn('flag_icon'),
                  })}
                </div>
              )}
              {!hasFlag(item?.code) && (
                <div className={cn('flag_container')}>
                  <div className={cn('flag_icon_border')} />
                  <div className={cn('unknown_flag_icon')}>
                    <UnknownFlagSvg />
                    <div className={cn('question')}>?</div>
                  </div>
                </div>
              )}
            </div>
            <div className={cn('name')}>{item?.name}</div>
            <div className={cn('prefix in_dropdown')}>
              (
              {Array.isArray(item?.prefix)
                ? `${item?.prefix.join(',')}`
                : item?.prefix}
              )
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CountryPickerDropDown;
