import React, { useEffect } from 'react';
import styles from './MomentBlock.module.css';
import { ReactComponent as DeleteSvg } from '../../images/icons/delete_16.svg';
import { ReactComponent as EditSvg } from '../../images/icons/edit.svg';
import { ReactComponent as WebSvg } from '../../images/icons/web.svg';

const ShowLink = ({ refShowLinkInfo, url, removeLink, select, edit }) => {
  useEffect(() => {
    select();
  }, []);

  const openInNewTab = () => {
    if (!url.startsWith('https://') && !url.startsWith('http://')) url = `http://${url}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        ref={refShowLinkInfo}
        onKeyDown={(e) => e.stopPropagation()}
        className={`${styles.showLink} ${styles.showToolBar} show-link`}
      >
        <WebSvg />

        <a
          href={url}
          onClick={() => openInNewTab()}
          onKeyDown={(e) => e.preventDefault()}
          className={styles.showLink_text}
        >
          {url}
        </a>

        <EditSvg
          className={styles.link_svg}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            edit();
          }}
        />
        <DeleteSvg
          className={styles.link_svg}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            removeLink();
          }}
        />
      </div>
    </>
  );
};

export default ShowLink;
