import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../../components/LibraryMiniPage/index.module.css';
import { convertDate } from '../dateConvert';
import { ReactComponent as FavoriteSvg } from '../../images/icons/favorites20.svg';
import { capitalizeFirstLetter } from '../helperText';
import { DEFAULT_TITLE } from '../constants';
import i18n from '../../i18n';

const UserStatusStyle = styled.div`
  display: flex;
  align-items: center;
  color: ${({ message }) => {
    switch (message) {
      case 'active':
        return '#117E0F';
      case 'invited':
        return '#FF8A00';
      case 'banned':
        return '#000000';
      case 'blocked':
        return '#C21C44';
      case 'deleted':
        return '#000000';
      case 'admin':
        return '#0026E5';
      default:
        return '#596080';
    }
  }};
  font-size: 12px;
`;

const Circle = styled.div`
  background: ${({ message }) => {
    switch (message) {
      case 'active':
        return '#117E0F';
      case 'invited':
        return '#FF8A00';
      case 'banned':
        return '#000000';
      case 'blocked':
        return '#C21C44';
      case 'deleted':
        return '#000000';
      case 'admin':
        return '#0026E5';
      default:
        return '#596080';
    }
  }};
  position: relative;
  width: 8px;
  margin-right: 2px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid transparent;
`;

const FlexContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div``;

const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  padding-right: 5%;
`;

const Tag = ({ label, color }) => {
  let style;
  switch (color) {
    case 'red':
      style = styles.tagRed;
      break;
    case 'green':
      style = styles.tagGreen;
      break;
    case 'pink':
      style = styles.tagPink;
      break;
    default:
      style = styles.tagYellow;
  }

  return (
    <div className={`${styles.tag} ${style}`}>
      <div className={`${styles.tagText}`}>{label}</div>
    </div>
  );
};

const TagPlaceholder = styled.div`
  height: 24px;
  max-width: 36px;
  font-family: "RobotoFlex", sans-serif;
  font-size: 14px;
  line-height: 21px;
  padding: 2px 8px;
  background: #e8ebfa;
  border-radius: 15px;
`;

export const TABLE_DECORS = {
  UserRoleStatus: ({ message }) => {
    return (
      <UserStatusStyle message={message ? message.toLocaleLowerCase() : ''}>
        <Circle message={message ? message.toLocaleLowerCase() : ''} />
        {capitalizeFirstLetter(message)}
      </UserStatusStyle>
    );
  },
  Date: ({ date }) => <Container>{convertDate(date) || ''}</Container>,
  Favorite: () => (
    <FlexContainer>
      <FavoriteSvg />
    </FlexContainer>
  ),
  Tags: () => {
    const [dummyTags] = useState([
      { id: 0, label: 'Vincent', color: 'green' },
      { id: 1, label: 'share', color: 'pink' },
      { id: 2, label: 'A', color: 'pink' },
      { id: 3, label: 'C', color: 'yellow' },
      { id: 4, label: 'B', color: 'red' },
      { id: 5, label: 'quidzi-newbie', color: 'pink' },
      // { label: 'Boston', color: 'pink' },
      // { label: "abc's", color: 'green' },
      // { label: 'Vincent', color: 'green' },
      // { label: 'Vincent Van', color: 'green' },
    ]);
    const [tagsToShow, setTagsToShow] = useState(dummyTags);
    const [tagsCounter, setTagsCounter] = useState(0);
    const itemRef = useRef(null);

    useEffect(() => {
      const WIDTH_LIMIT = itemRef.current.clientWidth - 50;
      const resArray = [];
      let filledSpace = 0;
      const firstDiv = document.createElement('div');
      let width2 = null;
      firstDiv.className = styles.tag;
      firstDiv.id = 'firstDiv';
      document.body.appendChild(firstDiv);

      dummyTags.some((tag, index) => {
        firstDiv.textContent = `${tag.label || i18n.t(DEFAULT_TITLE.Page)}`;
        const width1 = document.getElementById('firstDiv').clientWidth;
        if (dummyTags[index + 1]) {
          firstDiv.textContent = `${
            dummyTags[index + 1].label || i18n.t(DEFAULT_TITLE.Page)
          }`;
          width2 = document.getElementById('firstDiv').clientWidth;
        }
        if (filledSpace + width1 < WIDTH_LIMIT) {
          filledSpace += width1;
          resArray.push(tag);
          return false;
        }
        if (!!width2 && filledSpace + width2 < WIDTH_LIMIT) {
          filledSpace += width2;
          resArray.push(dummyTags[index + 1]);
          return false;
        }
        return true;
      });

      document.body.removeChild(firstDiv);
      setTagsToShow(resArray);
      setTagsCounter(resArray.length);
    }, [dummyTags]);

    return (
      <TagsContainer ref={itemRef}>
        {tagsToShow.map((tag) => (
          <Tag
            data-parent="Table"
            key={tag.id}
            label={tag.label}
            color={tag.color}
          />
        ))}

        {!!dummyTags.length && !!(dummyTags.length - tagsCounter) && (
          <TagPlaceholder data-parent="Table">
            +{dummyTags.length - tagsCounter}
          </TagPlaceholder>
        )}
      </TagsContainer>
    );
  },
};
