import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { LineBreakNode, ParagraphNode } from 'lexical';
import { $createHeadingNode } from '@lexical/rich-text';

export function OnlyHeadingPlugin(props: { level: 1 | 2 | 3 }) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    editor.registerNodeTransform(ParagraphNode, (node) => {
      node.replace($createHeadingNode(`h${props.level}`));
    });

    editor.registerNodeTransform(LineBreakNode, (node) => {
      node.remove();
    });
  }, [editor, props.level]);
  return <></>;
}
