import React from 'react';
import classnames from 'classnames/bind';
import rawStyles from '../index.module.scss';
import { ReactComponent as BlueChevronLeftSvg } from '../../../images/icons/blue_chevron_left_24.svg';
import { ReactComponent as BlueChevronRightSvg } from '../../../images/icons/blue_chevron_right_24.svg';

const styles = classnames.bind(rawStyles);

export const LeftButton = ({ dataParent }) => {
  return (
    <div data-parent={dataParent} className={styles('datepicker_button')}>
      <BlueChevronLeftSvg />
    </div>
  );
};
export const RightButton = ({ dataParent }) => {
  return (
    <div data-parent={dataParent} className={styles('datepicker_button')}>
      <BlueChevronRightSvg />
    </div>
  );
};
