import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  BoardColumnWrapper,
  TimeAndAvatar,
} from '../Inbox/styled';
import {
  AvailableBlockOutbox,
  CardInfoChannelOutbox,
  CardInfoHeaderInOutbox,
  CardInfoSizeOutbox,
  HeaderWrapperOutbox,
  InfoBlockOutbox,
} from './styled';

const OutboxHeader = () => {
  const { t } = useTranslation();
  return (
    <HeaderWrapperOutbox>
      <CardInfoHeaderInOutbox>
        {t('titleT')}
      </CardInfoHeaderInOutbox>

      <CardInfoChannelOutbox>
        {t('recipientsT')}
      </CardInfoChannelOutbox>

      <InfoBlockOutbox>
        {t('openedT')}
      </InfoBlockOutbox>

      <CardInfoSizeOutbox isRight>
        {t('sizeT')}
      </CardInfoSizeOutbox>

      <AvailableBlockOutbox isRight>
        {t('availabilityT')}
      </AvailableBlockOutbox>

      <BoardColumnWrapper>
        {t('boardT')}
      </BoardColumnWrapper>

      <TimeAndAvatar>
        {t('modifiedSlashSentT')}
      </TimeAndAvatar>

    </HeaderWrapperOutbox>
  );
};

export default OutboxHeader;
