import Draggable from './types';
import { dragType } from '../../utils/constants';

const ActionDraggable = {
  BlocksOnPage: (blocksId, playlistField) => ({
    type: Draggable.PagesBlock,
    payload: { blocksId, playlistField },
  }),
  SetHasDraggable: (isDraggable) => ({
    type: Draggable.HasDraggable,
    payload: isDraggable,
  }),
  DragSmartView: (id) => ({
    type: Draggable.DragItem,
    payload: { type: dragType.smartView, id },
  }),
  DragChannel: ({ id, isForeign = false }) => ({
    type: Draggable.DragItem,
    payload: {
      type: isForeign ? dragType.foreignChannel : dragType.channel,
      id,
    },
  }),
  DragContact: ({ id }) => ({
    type: Draggable.DragItem,
    payload: {
      type: dragType.contact,
      id,
    },
  }),
  DragTag: (id) => ({
    type: Draggable.DragItem,
    payload: { type: dragType.tag, id },
  }),
  Clear: () => ({
    type: Draggable.Clear,
  }),
  DragBoardCard: (id) => ({
    type: Draggable.DragItem,
    payload: { type: dragType.board, id },
  }),
};

export default ActionDraggable;
