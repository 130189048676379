import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import BlockCreator from '../../../components/LibraryMiniPage/BlockCreator';
import rawStyles from '../index.module.scss';
import { getLayersForPage } from '../../../shared/page/utils';

const styles = classNames.bind(rawStyles);

export const ContentWithBlocks = ({
  item,
  imageRef,
  isPlayer,
  isPlayerPreview,
  isPlayerTOCPreview,
  isCardView,
}) => {
  const [newLayers, setNewLayers] = useState(
    getLayersForPage(item?.components.sort((a, b) => a.position - b.position)),
  );

  useEffect(() => {
    if (item?.components) {
      setNewLayers(
        getLayersForPage(item?.components.sort((a, b) => a.position - b.position)),
      );
    }
  }, [item?.components]);

  return (
    <div
      className={styles('library_component_with_blocks', {
        isPlayer,
        isPlayerPreview,
        isPlayerTOCPreview,
        row: !isCardView,
      })}
      ref={imageRef}
    >
      <div className={styles('with_blocks_title', { isPlayer })}>
        {item?.title || ''}
      </div>
      {!!item?.description && (
        <div className={styles('with_blocks_description')}>
          {item?.description || ''}
        </div>
      )}

      {!!item?.components
        && item?.components
          .sort((a, b) => a.position - b.position)
          .map((i, index) => (
            <div
              key={item.id + i.id}
              className={styles({
                // no_pointer_events: true,
                // no_pointer_events: !isPlayerPreview,
              })}
            >
              <BlockCreator
                newLayers={newLayers}
                item={i}
                index={index}
                isPlayer={isPlayer}
                isPlayerTOCPreview={isPlayerTOCPreview}
                isPlayerPreview={isPlayerPreview}
              />
            </div>
          ))}
    </div>
  );
};
