import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CloseCross } from '../../images/2023/svg/small/player/captions_close_cross.svg';
import { CaptionsVisibleContainer, CaptionsWrapper } from './styled';

const CaptionsForPlayer = ({ text, areControlsHidden }) => {
  const [isVisible, setIsVisible] = useState(true);
  const { playerSettings: { isFullScreenMode } } = useSelector((state) => state.user);

  useEffect(() => { setIsVisible(true); }, [text]);

  if (!isVisible || !text) {
    return <></>;
  }
  return (
    <CaptionsWrapper areControlsHidden={areControlsHidden}>
      <CaptionsVisibleContainer isFullScreenMode={isFullScreenMode}>
        {text}
        <CloseCross onClick={() => setIsVisible(false)} />
      </CaptionsVisibleContainer>
    </CaptionsWrapper>
  );
};

export default CaptionsForPlayer;
