import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import UI from '../../../UIComponents/index';
import rawStyles from './index.module.scss';
import { ValidationMessages } from '../../../utils/constants';

const cn = classNames.bind(rawStyles);

const SimpleTextInput = ({
  value,
  setValue,
  validateError,
  setValidateError,
  fieldName,
  isRequired,
  forSmartModal,
  forCreateBlock,
  fooKeyDown,
  fooBlur,
  isSubFieldError,
  isReworkedValidationStyle = false,
}) => {
  const [isTouched, setIsTouched] = useState(false);

  const validate = (inputValue) => {
    if (isRequired) {
      if (!inputValue) setValidateError(ValidationMessages.required);
      else if (inputValue?.length < 2) setValidateError(ValidationMessages.minLength(fieldName));
      else if (inputValue?.length > 55) setValidateError(ValidationMessages.maxLength(fieldName));
    } else if (inputValue?.length < 2 && !!inputValue) setValidateError(ValidationMessages.minLength(fieldName));
    else if (inputValue?.length > 55 && !!inputValue) setValidateError(ValidationMessages.maxLength(fieldName));
  };

  const onInputFirstNameChange = (e) => {
    setValue(e.target.value);
    if (isTouched) setIsTouched(false);
    if (e.target.value.length > 55) {
      setValidateError(ValidationMessages.maxLength(fieldName));
    } else {
      setValidateError('');
    }
  };

  return (
    <SimpleTextInputUI
      value={value}
      forSmartModal={forSmartModal}
      setValue={onInputFirstNameChange}
      validateError={validateError}
      fieldName={fieldName}
      isRequired={isRequired}
      isTouched={isTouched}
      setIsTouched={setIsTouched}
      validate={(e) => validate(e.target.value)}
      fooKeyDown={fooKeyDown}
      fooBlur={fooBlur}
      forCreateBlock={forCreateBlock}
      isSubFieldError={isSubFieldError}
      isReworkedValidationStyle={isReworkedValidationStyle}
    />
  );
};

export default SimpleTextInput;

const SimpleTextInputUI = ({
  value,
  setValue,
  validateError,
  fieldName,
  isRequired,
  isTouched,
  forSmartModal,
  autoFocus,
  fooKeyDown,
  fooBlur,
  forCreateBlock,
  isSubFieldError,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={cn('wrapper', { for_create_block: forCreateBlock })}>
        <div className={cn('title_wrapper')}>
          <div className={cn('title', { required: isRequired, hasSubFieldError: isSubFieldError })}>
            {fieldName} {!isRequired && <span>- {t('optionalT')}</span>}
          </div>
          {isSubFieldError && <UI.ErrorSubField error={validateError} />}
        </div>
        <div
          className={cn('input_area', {
            error: validateError || (isTouched && !value && isRequired),
            for_smart_modal: forSmartModal,
            for_create_block: forCreateBlock,
          })}
        >
          <input
            data-cy={fieldName}
            value={value}
            onChange={setValue}
            autoFocus={autoFocus}
            onKeyDown={fooKeyDown}
            className={cn('input')}
            maxLength={forSmartModal ? '20' : '55'}
            onBlur={(e) => {
              if (fooBlur) {
                fooBlur();
              } else {
                props.setIsTouched(true);
                props.validate(e);
              }
            }}
            onFocus={() => !value && props.setIsTouched(false)}
          />
        </div>
      </div>
      {!isSubFieldError && <UI.Error error={validateError} isLogin={props.isReworkedValidationStyle} />}
    </>
  );
};
