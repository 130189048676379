import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { UPGRADE_PLANS } from '../../../../utils/SettingsHelpers/constants';
import PlanItem from './PlanItem';

const AvailablePlansShell = styled.div`
  display: flex;
  width: fit-content;
  box-shadow: 1px 1px 6px 0px rgba(122, 105, 105, 0.10), -1px 0px 6px 0px rgba(122, 105, 105, 0.10) inset;
`;

const AvailablePlans = () => {
  const { currentPlan } = useSelector((state) => state.user);
  return (
    <AvailablePlansShell>
      {Object.keys(UPGRADE_PLANS).map((key, index) => (
        <PlanItem
          key={key}
          plan={key}
          isCardFirst={!index}
          isCardLast={index === Object.keys(UPGRADE_PLANS).length - 1}
          isActive={currentPlan === key}
        />
      ))}
    </AvailablePlansShell>
  );
};

export default AvailablePlans;
