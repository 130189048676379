import { call, put, takeEvery } from 'redux-saga/effects';
import { select, takeLatest } from '@redux-saga/core/effects';
import { v4 } from 'uuid';
import Contacts from '../redux/contacts/types';
import { getSupport, getUser, showErrorMessage, currentPage, getSidebarContacts } from './sagasUtils';
import {
  CREATE_CONTACT,
  MessageType,
  UPDATE_CONTACT_DATA,
  UPDATE_CONTACT_STATUS,
  METHOD, TOGGLE_CONTACT_PIN,
} from '../utils/constants';
import { actionShowMessage } from '../redux/support/action';
import {
  requestContactDeletion,
  requestGetUserMap,
  requestSingleContact,
  request,
  requestContactRearrange,
  requestGetSomeContactContent,
  requestGetSomeUserContent, requestCreateContactContact,
} from '../utils/request';
import {
  actionAddContactR,
  actionChangeContactR,
  actionInitialContactsLoadR,
} from '../redux/contacts/action';
import { actionDragPage } from '../redux/currentPage/action';
import { sanitizeToLoad, calculateIndex } from '../utils/helpers';
import { Statuses } from '../utils/permissions';
import { actionCreator } from '../shared/redux/actionHelper';
import {
  createUpdateContactBody,
  queryCreateContact,
} from '../utils/query/helper';

function* BulkAddContact(action) {
  try {
    yield put(actionShowMessage({ type: MessageType.AddContact }));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* ChangeContactStatus(action) {
  try {
    const { id, status } = action.payload;
    yield call(request(UPDATE_CONTACT_STATUS, METHOD.PUT), { id, status });
    yield put(
      actionShowMessage({ type: MessageType.ChangeContactStatus, status }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* LoadUserContacts(action) {
  try {
    // TODO
    const {
      data: { contactNicknameMap },
    } = yield call(requestGetUserMap);
    yield put(actionInitialContactsLoadR(contactNicknameMap));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* RemoveContact(action) {
  try {
    const { contactEmail, contactId, contactName } = action.payload;
    yield call(requestContactDeletion, { contactEmail, contactId });
    yield put(actionShowMessage({ type: MessageType.RemoveContact, contactName }));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* EditContact(action) {
  try {
    const { item } = action.payload;
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const { id, fields } = createUpdateContactBody({
      ...item,
      lastModifiedDate,
    });
    yield call(request(UPDATE_CONTACT_DATA(id), METHOD.PUT), { id, fields });
    yield put(
      actionChangeContactR({
        ...item,
        lastModifiedDate,
        name: `${item.firstName} ${item.lastName}`,
      }),
    );
    yield put(actionShowMessage({ type: MessageType.UpdateContact }));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* UpdatePinState(action) {
  try {
    const item = action.payload;
    yield call(request(TOGGLE_CONTACT_PIN(item.id), METHOD.PUT), { state: !item.isUnpin });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* CheckHasPin(action) {
  try {
    const { modalWarningCallback, confirmCallback } = action.payload;
    const { contactNicknameMap } = yield select(getSupport);
    const isHasSomeUpPinContact = !!Object.values(contactNicknameMap).find(i => i.isUnpin);
    if (!isHasSomeUpPinContact) modalWarningCallback();
    else confirmCallback();
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* CreateContact(action) {
  try {
    const { item } = action.payload;
    const clearUserEmail = item.email.trim().toLowerCase();
    const createDate = Math.floor(Date.now() / 1000);
    const user = yield select(getUser);
    const contacts = yield select(getSidebarContacts);
    const newContactId = v4();
    const existentUserData = yield call(requestGetSomeContactContent, [
      clearUserEmail,
    ]);

    const position = Object.values(contacts || {}).sort(
      (a, b) => a.position - b.position,
    )[0]?.position;
    const newPosition = calculateIndex(null, position);

    const [userAlreadyExist] = existentUserData.data?.User || [];
    const [userInviteAlreadyExist] = existentUserData.data?.InvitedUser || [];
    const [contactAlreadyExist] = existentUserData.data?.Contact || [];
    let newItem = {};

    if (userAlreadyExist?.id) {
      newItem = {
        email: clearUserEmail,
        userForContact: {
          id: userAlreadyExist?.id,
          userType: 'User',
        },
      };
    }
    if (!userAlreadyExist?.id && userInviteAlreadyExist?.id) {
      newItem = {
        email: clearUserEmail,
        userForContact: {
          id: userInviteAlreadyExist?.id,
          userType: 'InviteUser',
        },
      };
    }
    if (!userAlreadyExist?.id && !userInviteAlreadyExist?.id) {
      newItem = {
        email: clearUserEmail,
        userForContact: {
          id: v4(),
          userType: 'InviteUser',
          actionType: 'create',
        },
      };
    }
    if (contactAlreadyExist) {
      newItem.oldContact = {
        id: contactAlreadyExist.id,
      };
    }

    const creatingContactData = {
      ...item,
      id: newContactId,
      createDate,
      email: clearUserEmail,
      lastModifiedDate: createDate,
      userId: user.id,
      position: newPosition,
      counter: 0,
      counterFavorite: 0,
      ...newItem,
    };

    if (creatingContactData.oldContact) {
      yield put(
        actionShowMessage({
          type: MessageType.ContactAlreadyExists,
          email: creatingContactData.email,
        }),
      );
    } else {
      const data = queryCreateContact(creatingContactData);
      yield call(request(CREATE_CONTACT), data);
      const updatedContactData = yield call(requestGetSomeContactContent, [
        'membership',
        newContactId,
      ]);
      const mutualChannels = {};
      const [contact] = updatedContactData.data.Contact;
      contact.mutualSubscriptions.forEach((subscription) => {
        mutualChannels[subscription.id] = { ...subscription };
      });

      const contactToLoad = {
        id: newContactId,
        status: Statuses.Created,
        createDate,
        lastModifiedDate: createDate,
        position: newPosition,
        userFunction: sanitizeToLoad(contact.function),
        organization: sanitizeToLoad(contact.organization),
        first_name: sanitizeToLoad(contact.last_name),
        last_name: sanitizeToLoad(contact.first_name),
        name: `${item.firstName} ${item.lastName}`,
        phone: sanitizeToLoad(contact.phone),
        username: sanitizeToLoad(contact.username),
        email: creatingContactData.email,
        mutualChannels,
        counterFavorite: 0,
        counter: 0,
        isUnpin: null,
      };

      yield put(actionAddContactR(contactToLoad));

      yield put(
        actionShowMessage({
          type: MessageType.CreateContact,
          isUserExists: !!userAlreadyExist,
        }),
      );
      yield put(
        actionCreator(Contacts.AddSingleToContactMap, {
          creatingContactData: contactToLoad,
        }),
      );

      if (userAlreadyExist) {
        yield put(
          actionShowMessage({ type: MessageType.UserAlreadyRegistered }),
        );
      }
      yield put(
        actionCreator(Contacts.GetContactS, {
          id: newContactId,
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* GetContact(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(requestSingleContact(id));
    if (Object.values(data).length) yield put(actionCreator(Contacts.AddContactMap, data));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* CreateAndAddSingleToContactMap(action) {
  try {
    const { email } = action.payload;
    const contacts = yield select(getSidebarContacts);
    const owner = yield select(getUser);
    const {
      data: { UserExist, UserInvite, ContactUser },
    } = yield requestGetSomeUserContent(['email', email]);
    const isExistInContact = !!ContactUser?.length || email === owner.email;
    if (isExistInContact) return;
    const newContactUuid = v4();

    const position = Object.values(contacts || {}).sort(
      (a, b) => a.position - b.position,
    )[0]?.position;
    const newPosition = calculateIndex(null, position);

    const creatingContactData = {
      id: newContactUuid,
      email,
      position: newPosition,
      createDate: Math.floor(Date.now() / 1000),
      userId: owner.id,
      userForContact: {
        id: UserExist[0]?.id || UserInvite.id || v4(),
        userType: UserExist[0] ? '' : 'InviteUser',
      },
    };

    const response = yield requestCreateContactContact(queryCreateContact(creatingContactData));
    const databaseId = response.data.invitedUserId;
    if (databaseId) {
      creatingContactData.id = databaseId.replaceAll('"', '');
    }
    yield put(
      actionCreator(Contacts.AddSingleToContactMap, { creatingContactData }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* RearrangeContactItem(action) {
  try {
    const { nextItem, currentItem } = action.payload;
    const { idDragPage } = yield select(currentPage);

    const position = calculateIndex(currentItem?.position, nextItem?.position);
    const contacts = yield select(getSidebarContacts);
    const contactWrapperId = Object.values(contacts).filter(e => e.contactId === idDragPage)?.[0].id;

    yield put(
      actionCreator(Contacts.ChangeContactItemPositionR, {
        id: contactWrapperId ?? idDragPage,
        newPosition: position,
      }),
    );

    yield call(requestContactRearrange, { position, idDragPage });
    yield put(actionDragPage(null, null));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

export default function* dynamicCollectionSaga() {
  yield takeEvery(Contacts.GetContactS, GetContact);
  yield takeEvery(Contacts.BulkAddContact, BulkAddContact);
  yield takeEvery(Contacts.RemoveContact, RemoveContact);
  yield takeLatest(Contacts.LoadUserContactsS, LoadUserContacts);
  yield takeEvery(Contacts.ChangeContactStatus, ChangeContactStatus);
  yield takeEvery(Contacts.EditContact, EditContact);

  yield takeEvery(Contacts.RearrangeContactItemS, RearrangeContactItem);
  yield takeEvery(Contacts.UpdatePinState, UpdatePinState);
  yield takeEvery(Contacts.CheckHasPin, CheckHasPin);
  yield takeEvery(Contacts.CreateContact, CreateContact);
  yield takeEvery(
    Contacts.CreateAndAddSingleToContactMap,
    CreateAndAddSingleToContactMap,
  );
}
