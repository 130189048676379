export const Channels = {
  GetUserChannels: '[Channels] Get User Channels',
  UploadUserChannelsR: '[Channels] Upload User Channels',
  ChangeChannelUserStatus: '[Channels] Change Channel User Status',
  SetNewFilterValue: '[Channels] Set New Filter Value',
  CreateChannel: '[Channels] Create user channels',
  EditChannel: '[Channels] Create user channels',
  AddChannelR: '[Channels] Add channel REDUX',
  SaveChannelsR: '[Channels] Save Many channel REDUX',
  SaveChannelR: '[Channels] Save channel REDUX',
  ConfirmSubscribe: '[Channels] Confirm Subscribe to channel',
  AddSubscribers: '[Channels] Add Subscriber to channel',
  AddSubscribersR: '[Channels] Add Subscriber to channel to Redux',
  SetCurrentChannel: '[Channels] Set Current Channel',
  UpdateChannelR: '[Channels] Update channel REDUX',
  UpdatePinState: '[Channels] Update Pin State',
  UpdateChannelChangeUserR: '[Channels] Update channel change user REDUX',
  RenameChannel: '[Channels] Rename channel',
  DeleteChannel: '[Channels] Delete Channel',
  DeleteChannelR: '[Channels] Delete Channel Redux Only',
  TogglePlaylistPublishInChannelR:
    '[Channels] Toggle Playlist Publish In Channel REDUX',
  getChannelData: '[Channels] Get Channel Data',
  clearChannelData: '[Channels] clear Channel Data',
  UserLeaveChannel: '[Channels] User Leaves Channel',
  ChangeMemberRole: '[Channels] Change Members Role',
  FetchChannelObserverRole: '[Channels] Fetch User Role in Channel',
  RemoveContentInSharedChannel: '[Channels] Remove Content From Shared Channel',
  UpdatePlaylistsCountersS:
    '[Channels] Update Playlists Counters in Channels SAGAS',
  UpdatePlaylistsCountersR:
    '[Channels] Update Playlists Counters in Channels REDUX',
  GetPlaylistMatrixDataS: '[Channels] Get Playlist Matrix Data SAGAS',
  GetPlaylistButtonsStatesS: '[Channels] Get Playlist ButtonsStates SAGAS',
  SetPlaylistMatrixDataR: '[Channels] Set Playlist Matrix Data REDUX',
  SetPlaylistButtonsStatesR: '[Channels] Set Playlist ButtonsStates REDUX',
  RearrangeChannelItemS: '[Channels] Re-arrange Channel Item SAGAS',
  ChangeChannelItemPositionR: '[Channels] Change Channel Item Position REDUX',
  GetHelpChannel: '[Channels] Get Help Channel SAGAS',
  UpdateHelpChannel: '[Channels] Get Help Channel REDUX',
  CheckHasPin: '[Channels] Check Pin State REDUX',
};
