import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  actionJumpToPageTitle,
  actionShowMessage,
} from '../../redux/support/action';
import {
  DEFAULT_PAGE_PATH, DEFAULT_TITLE,
  MessageType,
  openModalType,
  SPINNER_USAGE,
} from '../../utils/constants';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { ReactComponent as MoreSvg } from '../../images/icons/more_20.svg';

import styles from './index.module.css';
import {
  actionDuplicatePage,
  actionToggleComponentFavorite,
} from '../../redux/library/actions';
import { actionCreator } from '../../shared/redux/actionHelper';
import SupportAction from '../../redux/support/types';
import PlaylistOptionWithX from './MenuOptions/PlaylistOptionWithX';
import MENU_OPTIONS from './MenuOptions/PlaylistMenuOptions';
import EditPlaylist from '../../redux/playlists/types';
import { actionOpenModal } from '../../redux/user/action';

const HeadingPageOption = ({ isFavorite }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentPage = useSelector((state) => state.currentPage);
  const { requestSpinner } = useSelector((state) => state.support);
  const [refMenu, isMenuVisible, setIsMenuVisible] = useComponentVisible(false);
  const [addPlaylistRef, isPlaylistOpened, setIsPlaylistOpened] = useComponentVisible(false);
  const history = useHistory();

  const toggleFavorite = (e) => {
    e.stopPropagation();
    setIsMenuVisible(false);
    if (!isFavorite) {
      dispatch(
        actionShowMessage({
          type: MessageType.PageToFavorite,
          itemName: currentPage?.title || t('defaultSmartPageTitleT'),
        }),
      );
    }
    dispatch(
      actionToggleComponentFavorite(
        currentPage.id,
        currentPage.inCollection?.id,
        !isFavorite,
        true,
      ),
    );
  };

  const duplicatePage = (e) => {
    e.stopPropagation();
    setIsMenuVisible(false);
    dispatch(
      actionShowMessage({
        type: MessageType.ItemToCopy,
        itemName: currentPage?.title || t('defaultSmartPageTitleT'),
      }),
    );
    dispatch(actionDuplicatePage(currentPage.inCollection?.id, currentPage.id));
  };
  const rename = (e) => {
    e.stopPropagation();
    setIsMenuVisible(false);
    dispatch(actionJumpToPageTitle());
  };

  const onDeleteItem = (e) => {
    e.stopPropagation();
    setIsMenuVisible(false);
    dispatch(
      actionCreator(SupportAction.CheckIsInUse, {
        itemTitle: currentPage?.title,
        itemId: currentPage.id,
      }),
    );
    history.push(DEFAULT_PAGE_PATH);
  };

  const unPublishPage = () => {
    // dispatch(actionSaveShareStateLibraryPage());
  };

  const openEmbedModal = () => {
    dispatch(actionOpenModal(openModalType.embedPageModal, { id: currentPage.id }));
  };

  const openPlaylistsMenu = () => {
    setIsPlaylistOpened((prev) => !prev);
  };
  const createPlaylistAddPage = () => {
    dispatch(
      actionCreator(EditPlaylist.CreatePlaylistAndAddPageToIt, {
        history,
        pageId: currentPage.id,
      }),
    );
  };

  const onAddTagClickHandler = () => {
    dispatch(actionOpenModal(openModalType.AddTagModal, { id: currentPage.id }));
    setTimeout(() => {
      dispatch(
        actionCreator(SupportAction.JumpTo, { anchorId: 'meta_tags_input' }),
      );
    }, 100);
    setIsMenuVisible(false);
  };

  return (
    <div data-cy="menu-library" ref={refMenu} className={styles.item_wrapper}>
      <div
        ref={addPlaylistRef}
        onClick={() => setIsMenuVisible(!isMenuVisible)}
        className={`${styles.item} ${isMenuVisible ? styles.active : ''}`}
      >
        <MoreSvg />
      </div>
      {isMenuVisible && (
        <>
          <PlaylistOptionWithX
            parentRef={refMenu}
            menuOptions={[
              MENU_OPTIONS.unPublish(
                unPublishPage,
                currentPage.shareState.isPublish,
              ),
              MENU_OPTIONS.renamePage(rename),
              MENU_OPTIONS.duplicatePage(duplicatePage),
              MENU_OPTIONS.embedPage(
                openEmbedModal,
                currentPage.shareState.isPublish,
              ),
              MENU_OPTIONS.addTagLibraryComponent(onAddTagClickHandler),
              MENU_OPTIONS.createPlaylistHeader(
                createPlaylistAddPage,
                false,
                requestSpinner === SPINNER_USAGE.PageCreateNewPlaylist,
              ),
              MENU_OPTIONS.addToPlaylistHeader(
                openPlaylistsMenu,
                isPlaylistOpened,
                addPlaylistRef,
                true,
                requestSpinner === SPINNER_USAGE.AddPageToPlaylist,
              ),
              MENU_OPTIONS.favorite(toggleFavorite, currentPage.isFavorite),
              MENU_OPTIONS.delete(onDeleteItem),
            ]}
            isPageEditor
          />
        </>
      )}
    </div>
  );
};
export default HeadingPageOption;
