import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import rawStyles from '../index.module.scss';
import { MAX_ZOOM_VALUE, MIN_ZOOM_VALUE } from '../helpers';

const styles = classNames.bind(rawStyles);

export const ImageContent = ({ ...props }) => {
  const imageRef = useRef(null);
  const calcStyle = { zoom: `${props.scale}%` };
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (props.setSiblingComponentIsLoading) {
      props.setSiblingComponentIsLoading(loaded);
    }
  }, [loaded]);

  useEffect(() => {
    if (props?.imageRef) return;
    if (imageRef.current) {
      const { innerWidth: width, innerHeight: height } = window;
      const topHeight = 64;
      const scrollSize = 20;
      const resiz = ((height - topHeight)
          / imageRef.current?.getBoundingClientRect().height)
        * 100;
      const resiz2 = ((width - scrollSize)
          / imageRef.current?.getBoundingClientRect().width)
        * 100;
      const isOverSize = Math.min(resiz, resiz2) < 100;
      if (isOverSize) {
        const maxsize = Math.min(resiz, resiz2);
        const maxResize = Math.max(maxsize, MIN_ZOOM_VALUE);
        props.setScalePercentage(maxResize);
      } else {
        const maxResize = Math.min(resiz, resiz2, MAX_ZOOM_VALUE);
        props.setScalePercentage(maxResize);
      }
    }
  }, [imageRef.current]);
  useEffect(() => {
    setLoaded(false);
  }, [props.item.urlFile]);

  return (
    <div
      className={styles('content_wrapper', {
        isPlayer: props.isPlayer,
        isImagePlayer: props.isPlayer,
        isPlayerPreview: props.isPlayerPreview,
        isWithBackground: props.isPlayer && props.item.type === 'page',
      })}
    >
      <div
        className={styles('content_image_section', {
          isPlayer: props.isPlayer,
        })}
      >
        <div
          className={styles('fullScreenImage', {
            isPlayer: props.isPlayer,
            isPlayerTOCPreview: props.isPlayerTOCPreview,
          })}
        >
          {props.item.type === 'image/svg+xml' && (
            <img
              ref={props?.imageRef ?? imageRef}
              className={styles('svg_pic', {
                isPlayerPreview: props.isPlayerPreview,
                isPlayerTOCPreview: props.isPlayerTOCPreview,
              })}
              style={props.isPlayer ? calcStyle : {}}
              src={props.item.urlFile}
              alt="preview_image"
              onLoad={() => setLoaded(true)}
            />
          )}
          {props.item.type !== 'image/svg+xml' && (
            <img
              ref={props?.imageRef ?? imageRef}
              className={styles({
                preview_pic_Player: props.isPlayerPreview,
                preview_pic_TOCPlayer: props.isPlayerTOCPreview,
                row: !props.isCardView && props.isPlayerTOCPreview,
              })}
              style={props.isPlayer ? calcStyle : {}}
              src={props.item.urlFile}
              alt="preview_image"
              onLoad={() => setLoaded(true)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
