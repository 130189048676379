import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moduleStyles from '../../pages/Library/Library.module.scss';
import googleDrive16 from '../../images/icons/google_drive_16.svg';
import dropbox16 from '../../images/icons/dropbox_16.svg';
import upload32 from '../../images/icons/upload_32.svg';
import ProgressBar from './ProgressBar';
import { DATA_PARENTS } from '../../utils/constants';

const styles = classNames.bind(moduleStyles);
const DnDZone = styled.div``;

const AddFilePopUpClickOutsideWithUnderlay = ({
  parentRef,
  setIsAddFileActive,
  defaultPosition = { top: 36, left: 0 },
  ...props
}) => {
  const [activeOption, setActiveOption] = useState('computer');
  const { t } = useTranslation();

  const calculateVerticalPosition = () => {
    if (parentRef && parentRef.current) {
      return parentRef.current.getBoundingClientRect().y + defaultPosition.top;
    }
    return defaultPosition.top;
  };

  const calculateHorizontalPosition = () => {
    if (parentRef && parentRef.current) {
      return parentRef.current.getBoundingClientRect().x + defaultPosition.left;
    }
    return defaultPosition.left;
  };

  const divStyle = {
    top: calculateVerticalPosition(),
    left: calculateHorizontalPosition(),
  };

  const computeActiveOption = (option) => {
    return option === activeOption ? 'active' : '';
  };

  const onUnderlayClick = () => {
    setIsAddFileActive(false);
  };

  return (
    <>
      <div className={styles('underlay')} onClick={onUnderlayClick} />
      <div
        style={divStyle}
        className={styles('add_file_popUp', 'fixed', {
          addFile_wrapper_emptyScreen: props.place === 'content',
          PlaylistBuilderAddFile:
            props.place === DATA_PARENTS.PlaylistBuilderAddFileTab,
        })}
      >
        <div className={styles('title')}>{t('uploadFileT')}</div>

        <div className={styles('options_container')}>
          <div className={styles('options')}>
            <div
              className={styles('text_container', 'opt')}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setActiveOption('computer');
              }}
            >
              <div
                className={styles('text', `${computeActiveOption('computer')}`)}
              >
                {t('computerT')}
              </div>
            </div>
            <div
              className={styles('text_container', 'opt')}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setActiveOption('embed');
              }}
            >
              <div
                className={styles('text', `${computeActiveOption('embed')}`)}
              >
                {' '}
                {t('embedLinkT')}
              </div>
            </div>
            <div
              className={styles('text_container', 'opt')}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setActiveOption('google');
              }}
            >
              <img
                className={styles('icon')}
                src={googleDrive16}
                alt="google_drive"
              />
              <div
                className={styles('text', `${computeActiveOption('google')}`)}
              >
                {' '}
                {t('googleDriveT')}{' '}
              </div>
            </div>
            <div
              className={styles('text_container', 'opt')}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setActiveOption('dropbox');
              }}
            >
              <img className={styles('icon')} src={dropbox16} alt="dropbox" />
              <div
                className={styles('text', `${computeActiveOption('dropbox')}`)}
              >
                {' '}
                {t('dropboxT')}{' '}
              </div>
            </div>
          </div>
          <div className={styles('options_slider')}>
            <div className={styles('long_line')} />
            <div className={styles(`under_${activeOption}`)} />
          </div>
        </div>

        {activeOption === 'computer' && (
          <div className={styles('drop_section')}>
            <DnDZone className={styles('add_file_drop_zone')}>
              <div className={styles('dnd_placeholder')}>
                <img
                  className={styles('icon')}
                  src={upload32}
                  alt="upload_file"
                />
                <div className={styles('dnd_text')}>
                  {t('dragNDropT')}<span className={styles('or')}> {t('orClickOnT')}</span>
                </div>
              </div>
            </DnDZone>
            <div
              className={styles('file_picker')}
              onClick={props.onButtonClick}
            >
              {t('chooseFileT')}
            </div>
          </div>
        )}
        {activeOption === 'embed' && (
          <div className={styles('embed_section')}>
            <div className={styles('embed_input_wrapper')}>
              <input
                type="text"
                className={styles('embed_input')}
                placeholder={t('pasteImageLinkT')}
              />
            </div>
            <div className={styles('embed_submit')}>{t('embedT')}</div>
          </div>
        )}
        <ProgressBar setIsAddFileMenuVisible={props.setIsAddFileMenuVisible} />
      </div>
    </>
  );
};
export default AddFilePopUpClickOutsideWithUnderlay;
