import styled from 'styled-components/macro';

export const MiddleDiv = styled.div`
  width: max-content;
  margin: 49% auto auto;
`;

export const MakerSettingsWrapper = styled.div`
  width: 360px;
  height: 100vh;
  background: #ffffff;
  filter: drop-shadow(-4px 0px 12px rgba(236, 226, 226, 0.5));
  padding-top: 31px;
  transition: all ease-in-out 0.4s;
`;

export const MakerOptionsWrapper = styled.div`
  padding-left: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 89px);

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.13);
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
`;

export const SettingsControlContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const SvgWrapper = styled.div`
  width: 20px;
  cursor: pointer;
  ${({ isRole }) => (isRole
    ? `
    padding: 2px 0 0 2px;
    margin-right: 6px;
  `
    : '')}
  ${({ isEdit }) => (isEdit
    ? `
    padding-top: 2px;
    margin-left: 10px;
    svg path {
      stroke: #878184;
    }
  `
    : '')}
`;

export const AvailabilityDateWrapper = styled.div`
  font-size: 13px;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: #534b4f;
  opacity: 0.7;
`;

export const AvailabilityMainWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1px;
  ${({ isChannel }) => (isChannel ? 'margin-left: 4px;' : '')}
`;


export const OptionWrapper = styled.div`
  width: 300px;
  margin-bottom: 30px;
  padding: 10px 12px 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 12px;
`;

export const OptionTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #939393;
`;

export const OptionContent = styled.div`
  margin-top: 9px;
  ${({ row }) => (row
    ? `
    display: flex;
  `
    : '')}
`;

export const OptionDivider = styled.div`
  height: 1px;
  background-color: #1d1d1d;
  opacity: 0.08;
  margin-top: 8px;
`;

export const OptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OptionChannel = styled.div`
  display: flex;
  align-items: center;
`;
export const Text = styled.div`
  line-height: 16px;
  color: #484343;
  ${({ isOptionComments }) => (isOptionComments
    ? 'margin-right: 10px;'
    : `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 225px;
  `)}
`;

export const OptionItem = styled.div`
  margin-top: 12px;
  position: relative;
`;

export const ContainerWithPointer = styled.div`
 cursor: pointer;
`;
