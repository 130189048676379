import React from 'react';
import PlaylistsDatePickerChrome2023 from './childComponents/ChromeModule2023';
import PlaylistsDatePickerSafari2023 from './childComponents/SafariModule2023';

const PlaylistsDatePicker2023 = ({
  saveState,
  setSelectedDateTo,
  selectedDateTo,
  showHeader,
  selectFirstOption,
  setSelectedDateFrom,
  setSelectFirstOption,
  selectedDateFrom,
  selectSecondOption,
  setSelectSecondOption,
  textSecondOption,
  isForShared,
  isLocal,
  isModify,
  ...props
}) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (isSafari) {
    return (
      <PlaylistsDatePickerSafari2023
        saveState={saveState}
        selectedOption={props.selectedOption}
        setSelectedOption={props.setSelectedOption}
        setSelectedDateTo={setSelectedDateTo}
        selectedDateTo={selectedDateTo}
        showHeader={showHeader}
        selectFirstOption={selectFirstOption}
        selectSecondOption={selectSecondOption}
        setSelectedDateFrom={setSelectedDateFrom}
        setSelectFirstOption={setSelectFirstOption}
        setSelectSecondOption={setSelectSecondOption}
        selectedDateFrom={selectedDateFrom}
        textFirstOption={props.textFirstOption}
        textSecondOption={textSecondOption}
        dataParent={props.dataParent}
        isForShared={isForShared}
        isLocal={isLocal}
        isModify={isModify}
      />
    );
  }
  return (
    <PlaylistsDatePickerChrome2023
      saveState={saveState}
      selectedOption={props.selectedOption}
      setSelectedOption={props.setSelectedOption}
      setSelectedDateTo={setSelectedDateTo}
      selectedDateTo={selectedDateTo}
      showHeader={showHeader}
      selectFirstOption={selectFirstOption}
      selectSecondOption={selectSecondOption}
      setSelectedDateFrom={setSelectedDateFrom}
      setSelectFirstOption={setSelectFirstOption}
      setSelectSecondOption={setSelectSecondOption}
      selectedDateFrom={selectedDateFrom}
      textFirstOption={props.textFirstOption}
      textSecondOption={textSecondOption}
      dataParent={props.dataParent}
      isForShared={isForShared}
      isLocal={isLocal}
      isModify={isModify}
    />
  );
};

export default PlaylistsDatePicker2023;
