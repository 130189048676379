import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import rawStyles from './elements.module.scss';
import PlaylistElementDAL from '../MakerCommon/PlaylistElementDAL';
import SixDotsItem from './SixDotsItem';
import useComponentVisibleMouseDown from '../../../utils/hooks/useComponentVisibleMouseDown';
import { PlaylistUI } from '../../../utils/UIHelpers/PlaylistBuilderElements/componentPickers';
import { ElementTypeComponent } from '../../../utils/UIHelpers/ItemTypes/playlistElements';
import DeleteContentButton from './DeleteContentButton';
import {
  LibraryComponentTypes,
  UiComponentTypes,
} from '../../../utils/constants';
import { isRoleInPlaylist } from '../../../utils/permissions';
import CommonCardLayouts from './Shared/CommonCardLayouts';
import PlaylistPhotoFrame from '../MakerCardView/PlaylistElementCard/PlaylistPhotoFrame';
import PDFPreview from '../../../components/CustomLightGallery/preview-components/PDF';
import BlockCreatorComponent from '../../../components/LibraryMiniPage/BlockCreatorComponent';
import { CustomMuxPlayer } from '../../Player/childComponents/TestPlayer';
import NewImageFrame from './Shared/NewImageFrame';
import LinkPreview from '../../../components/CustomLightGallery/preview-components/Link';
import { actionRemoveLinkPageFromPlaylist } from '../../../redux/currentPage/action';
import TextElement from '../MakerCardView/TextElement';
import EditIcon from '../../../components/EditIcon';
import TitleElement from '../MakerCardView/TitleElement';
import { smartFileItemTypeCheck } from '../../../shared/smartFile/constant';
import LexTextElement from '../MakerCardView/prevTextElement';

const cx = classNames.bind(rawStyles);

const LibraryElementExpanded = ({
  item = {},
  isDragHover,
  playlistId,
  itemStylesObject,
  createApproveButton,
  ...props
}) => {
  const { t } = useTranslation();
  const [menuRef, isActive, setIsActive] = useComponentVisibleMouseDown(
    false,
    item.id,
  );
  const { id: userId } = useSelector((state) => state.user);
  const currentPage = useSelector((state) => state.currentPage);
  const ownerID = currentPage?.owner?.id;
  const { singleUserShareState } = useSelector((state) => state.currentPage);
  const isAdmin = userId === ownerID;
  const { isViewer } = isRoleInPlaylist;
  const dispatch = useDispatch();
  const [renameMode, setRenameMode] = useState(false);

  const otherOwner = userId !== item?.owner?.id && item.owner;

  const deleteHandler = () => {
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        playlistId,
        item.id,
        item.textComponent?.id || item.libraryComponent?.id,
      ),
    );
  };
  if (item.type === 'elementText') {
    return (
      <TextElement
        item={item}
        playlistId={playlistId}
        isDragHover={props.isDragHover}
        itemStylesObject={itemStylesObject}
        isExpandedView
        {...props}
      />
    );
  }

  if (smartFileItemTypeCheck.isTitle[item.type]) {
    return (
      <TitleElement
        item={item}
        playlistId={playlistId}
        isDragHover={props.isDragHover}
        itemStylesObject={itemStylesObject}
        isExpandedView
        {...props}
      />
    );
  }

  if (smartFileItemTypeCheck.isRichText[item.type]) {
    return (
      <LexTextElement
        item={item}
        playlistId={playlistId}
        isDragHover={props.isDragHover}
        itemStylesObject={itemStylesObject}
        isExpandedView
        {...props}
      />
    );
  }

  return (
    <div
      className={cx(
        'six_dot_balancer',
        { hoverable: !props.isViewBuilder, isCompressView: props.isCompressView },
        { active: isActive },
      )}
    >
      <div
        className={cx('library_element', {
          dragginOver: isDragHover,
          viewMode: isViewer[props.currentRole],
        })}
        data-parent={item.id}
        onDragEnd={props.onDragEndHandler}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onDragStart={props.dragStartHandler}
        draggable={!isViewer[props.currentRole] && !otherOwner}
      >
        <SixDotsItem
          clear={props.clear}
          userRole={props.userRole}
          createHandler={props.createHandler}
          onDragStartHandler={props.onDragStartHandler}
          onDragEndHandler={props.onDragEndHandler}
          title={item?.title || 'Untitled item '}
          playlistId={playlistId}
          menuRef={menuRef}
          dragStartHandler={props.dragStartHandler}
          isActive={isActive}
          setIsActive={setIsActive}
          item={item}
          singleUserShareState={singleUserShareState}
          ownerID={ownerID}
          itemStylesObject={itemStylesObject}
          isViewBuilder={props.isViewBuilder}
          otherOwner={otherOwner}
          setRenameMode={setRenameMode}
          duplicateHandler={props.duplicateHandler}
          itemIndex={props.itemIndex}
          createApproveButton={createApproveButton}
          addCaptionToLinkPage={props.addCaptionToLinkPage}
          removeCaptionFromLinkPage={props.removeCaptionFromLinkPage}
          deleteApproveButton={props.deleteApproveButton}
          isViewer={isViewer[props.currentRole]}
          currentRole={props.currentRole}
          handleShowPannel={props.handleShowPannel}
          isCompressView={props.isCompressView}
        />

        <PlaylistElementDAL
          item={item?.libraryComponent || item?.textComponent}
          linkPage={item}
          isSelected={props.builderSelectedIds.includes(item.id)}
          isBlink={props.blinkIdsArray?.includes(item.id)}
          isViewBuilder={props.isViewBuilder || userId !== item?.owner?.id}
          renderNewUiComponent={({
            error,
            blinkIdsArray,
            displayItem,
            isBlink,
            linkPage,
          }) => {
            const isPage = displayItem?.type === 'page'
              || displayItem?.type === 'sharedPage';
            const isText = item.type === 'elementText';
            const isLexical = smartFileItemTypeCheck.isTitle[item.type];
            const isAudio = UiComponentTypes.audio[displayItem?.type];
            const isVideo = UiComponentTypes.video[displayItem?.type];
            const isArchive = UiComponentTypes.archive[displayItem?.type];
            const isImage = UiComponentTypes.image[displayItem?.type];
            const isImageLike = UiComponentTypes.imageLike[displayItem?.type];
            const isLink = UiComponentTypes.link[displayItem?.type];
            const isPDF = UiComponentTypes.pdf[displayItem?.type]
              || (!UiComponentTypes.pdf[displayItem?.type]
                && displayItem?.pdfPreviewUrl);
            const allOtherFiles = !(
              isPage
              || isAudio
              || isVideo
              || isPDF
              || isText
              || isLexical
            );
            const unknownType = !(
              isText
              || isLexical
              || isArchive
              || isImage
              || isImageLike
              || isVideo
              || isAudio
              || UiComponentTypes.component[displayItem?.type]
              || displayItem?.type === LibraryComponentTypes.embed_component
              || isLink
              || isPDF
              || UiComponentTypes.media[displayItem?.type]
              || UiComponentTypes.presentation[displayItem?.type]
              || isPage
              || UiComponentTypes.ms[displayItem?.type]
              || UiComponentTypes.youtube[displayItem?.type]
            );
            return (
              <CommonCardLayouts
                linkPage={item}
                imageComponent={PlaylistUI.createPreviewComponent(
                  displayItem?.type,
                  displayItem || {},
                  linkPage?.id,
                  error,
                )}
                title={
                  linkPage.title
                  || displayItem?.title
                  || ((isText || isLexical) ? t('newTextBlockT') : t('defaultSmartPageTitleT'))
                }
                typeIcon={ElementTypeComponent(displayItem?.type)}
                isBlink={
                  isBlink
                  || blinkIdsArray === linkPage.id
                  || (blinkIdsArray?.length && blinkIdsArray.includes(linkPage.id))
                }
                goToFullScreenView={props.goToFullScreenView}
                renameRef={props.renameRef}
                linkPageId={linkPage.id}
                caption={linkPage.caption}
                duration={
                  linkPage?.libraryComponent?.duration || linkPage.duration
                }
                isShowCaption={linkPage.isShowCaption}
                isRemixLocked={linkPage.isRemixLocked}
                isPage={isPage}
                isVideoAudio={
                  UiComponentTypes.audio[displayItem?.type]
                  || UiComponentTypes.video[displayItem?.type]
                }
                setRenameMode={setRenameMode}
                renameMode={renameMode}
                itemIndex={props.itemIndex}
                currentRole={props.currentRole}
                noPreview={isArchive || unknownType}
                isText={isText}
                isLexical={isLexical}
              >
                {(isImage || isImageLike) && <NewImageFrame item={displayItem} isImageLike={isImageLike} />}
                {isLink && <LinkPreview item={displayItem} isExpandedView />}
                {!(isImage || isImageLike || isLink) && (
                  <PlaylistPhotoFrame upvExpand>
                    {isPage && (
                      <BlockCreatorComponent
                        isPreview
                        isPlaylist
                        isExpandedView
                        item={displayItem}
                      />
                    )}
                    {isPDF && <PDFPreview item={displayItem} isExpandView />}
                    {allOtherFiles
                      && PlaylistUI.createPreviewComponent(
                        displayItem?.type,
                        displayItem || {},
                        linkPage?.id,
                        error,
                        true,
                      )}
                    {(isAudio || isVideo) && (
                      <CustomMuxPlayer
                        isExpandView
                        item={displayItem}
                      />
                    )}
                  </PlaylistPhotoFrame>
                )}
              </CommonCardLayouts>
            );
          }}
        />
      </div>
      {!isViewer[props.currentRole]
        && ((item.owner && userId === item?.owner?.id) || isAdmin) && (
          <DeleteContentButton deleteHandler={deleteHandler}>
            {item.libraryComponent?.type === 'page' && (item.owner && userId === item?.owner?.id)
              && <EditIcon type="MakerLibraryElement" id={item?.libraryComponent?.id} />
            }
          </DeleteContentButton>
      )}
    </div>
  );
};

export default LibraryElementExpanded;
