import styled from 'styled-components/macro';
import { css, keyframes } from 'styled-components';

export const borderRadius = '8px';
const buttonsBorderRadius = '4px';
const defaultColor = 'transparent';
const selectedColor = '#0026E5';
const buttonHoverAnimation = '0.15s';

const fadeInCard = keyframes`
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }

  60% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
`;

export const Counter = styled.div`
  position: absolute;
  display: none;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  left: -1px;
  top: -17px;
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
  color: #596080;
`;

export const PlayerButton = styled.div`
  position: relative;
  background: #59608077;
  border-radius: 4px;
  height: 22px;
  min-width: 22px;
  margin-right: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color ease-out ${buttonHoverAnimation};

  &:hover {
    background: #596080;
  }

  ${({ isSelected }) => (isSelected
    ? `
  background: #596080;
  `
    : '')}

  ${({ noVisualEffects }) => (noVisualEffects
    ? `
  background: transparent;
  &:hover {
    background: initial;
  }
  `
    : '')}


  ${({ leftMargin }) => (leftMargin
    ? `
    justify-self: flex-end;
    margin-right: 0;
  `
    : '')}
`;

export const PlayerButtonWithCounter = styled(PlayerButton)`
  &:hover ${Counter} {
    display: flex;
  }
`;

export const ButtonsPlank = styled.div`
  padding-left: 9px;
  margin-top: 11px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;
export const TOCButton = styled.div`
  left: 2px;
  top: 2px;

  width: 18px;
  height: 18px;
  cursor: pointer;

  ${({ active }) => (active
    ? `
    ${Counter} {
      display: flex;
    }
  `
    : '')}
`;

export const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ size }) => (size ? `${size}px` : '19px')};
  height: ${({ size }) => (size ? `${size}px` : '19px')};

  ${TOCButton} {
    & svg {
      width: 100%;
      height: 100%;
    }

    & path {
      stroke: #ffffff;
    }
  }
`;

export const ImageCover = styled.img`
  position: relative;
  display: block;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
  background-image: linear-gradient(0deg, white, white 40%, transparent);
`;

export const ImageCoverMetaData = styled.img`
  margin: 16px 20px;
  position: relative;
  display: block;
  z-index: 0;
  width: 445px;
  height: 236px;
  object-fit: cover;
  border-radius: 12px;
`;

export const SectionWrapper = styled.div`
  grid-area: ${({ place }) => place || 'title'};
  display: flex;
  z-index: ${({ zIndex }) => zIndex || 'auto'};
  align-items: center;

  ${({ spaceBetween }) => (spaceBetween
    ? ` 
    justify-content: space-between;
  `
    : '')}
`;

export const Dot = styled.div`
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: #596080;
  line-height: 25px;
  margin-bottom: 3px;
  margin-left: 6px;
  margin-right: 6px;
`;

export const InfoPanel = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  height: 117px;
  background: #ffffffee;
  margin-top: 0;
  display: grid;
  grid-template-areas:
    "controls"
    "channel"
    "availability"
    "updated";

  grid-template-rows: 38px 25px 25px 27px;
  grid-template-columns: 100%;
  border-bottom-left-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
  transition: bottom ease-in-out 0.2s;
`;

export const LineText = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #596080;
  //text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 230px;
`;
export const ChannelsText = styled(LineText)`
  text-overflow: initial;
  white-space: initial;
  overflow: initial;
`;

export const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100px;
`;

export const FullLineText = styled(LineText)`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #596080;
  padding-left: 14px;
  width: 100%;
  padding-right: 9px;
`;

export const DurationText = styled(LineText)`
  width: auto;
  margin-right: 3px;
`;

export const TitleText = styled(FullLineText)`
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #242633;
  padding-top: 7px;
  width: 270px;
  text-overflow: ellipsis;
`;

export const AvailabilityText = styled(LineText)`
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #596080;
  padding-left: 9px;
  width: 192px;
`;

export const UpdateText = styled(AvailabilityText)`
  text-transform: capitalize;
  width: 166px;
`;

export const Separator = styled.div`
  align-self: center;
  height: 1px;
  background: #e8ebfa;
  margin: 0 auto;
  width: 93%;
`;

export const Icon = styled.div`
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  & svg {
    width: 100%;
    height: 100%;
  }
`;

export const ClockIcon = styled(Icon)`
  margin-right: 10px;
  height: 15px;
  width: 15px;

  & svg path {
    stroke: #596080;
  }
`;

export const Sharp = styled.div`
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  color: #596080;
  margin-right: 13px;
  padding-left: 9px;
`;

export const GeneralHoverOverlay = styled.div`
  position: absolute;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 1px solid ${defaultColor};
  border-radius: ${borderRadius};

  // &:hover {
  //   border: 1px solid ${selectedColor};
  // }
`;

export const DurationContainer = styled.div`
  position: relative;
  width: 100px;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const TitleInput = styled.input`
  width: 260px;
  height: 26px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #242633;
  min-height: 25px;
  z-index: 118;
  background: #ffffff;
  border: 1px solid #8697f0;
  box-sizing: border-box;
  border-radius: 8px;
  line-height: 25px;
  position: relative;
  margin-left: 9px;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 3px;
  padding-left: 4px;
`;

export const ItemControlsShell = styled.div`
  position: absolute;
  display: none;
  top: 0;
  //background: #0da6f233;
  width: 278px;
  height: 125px;
`;

export const ControlButton = styled.div`
  position: absolute;
  z-index: 3;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: ${buttonsBorderRadius};
  cursor: pointer;
  top: initial;
  left: initial;
  bottom: initial;
  transition: background-color ease-out ${buttonHoverAnimation};

  &:hover {
    background: #e8ebfa33;
  }

  & svg path {
    stroke: #ffffff;
  }

  ${({ active }) => (active
    ? `
    background: #E8EBFA33;
   
    & svg path {
      stroke: #ffffff
    }
  `
    : '')}

  ${({ topRight }) => (topRight
    ? `
    right: 9px;
    top: 9px;
  `
    : '')}
  ${({ topLeft }) => (topLeft
    ? `
    left: 9px;
    top: 9px;
  `
    : '')}
  ${({ centerLeft }) => (centerLeft
    ? `
    left: 9px;
    top: 50px;
  `
    : '')}
  ${({ centerRight }) => (centerRight
    ? `
    right: 9px;
    top: 50px;
  `
    : '')}
  ${({ center }) => (center
    ? `
    left: 128px;
    top: 50px;
  `
    : '')}
  ${({ bottomRight }) => (bottomRight
    ? `
    right: 8px;
    bottom: 8px;
  `
    : '')}
`;

export const MoreButtonShell = styled.div`
  position: absolute;
  z-index: 3;
  width: 24px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color ease-out ${buttonHoverAnimation};

  ${({ active }) => (active
    ? `
    background: #E8EBFA;
   
    & svg path {
      stroke: #596080;
    }
  `
    : '')}
  &:hover {
    background: #e8ebfa;
  }

  & svg path {
    stroke: #596080;
  }

  ${({ isHidden }) => (isHidden
    ? `
    cursor: default;
  `
    : '')}
`;

export const UnHideIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #404d8c44;
  border-radius: 4px;

  & svg path {
    stroke: white;
  }

  cursor: pointer;

  &:hover {
    background: #404d8c;
  }

  transition: all ease-out ${buttonHoverAnimation};
`;

export const ControlButtonsZIndexAffected = styled(ControlButton)`
  cursor: pointer;

  &:hover ${Counter} {
    display: flex;
  }

  ${({ active }) => (active
    ? `
    ${Counter} {
      display: flex;
    }
  `
    : '')}
`;

export const BottomControlsShell = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 2px;
  position: relative;
  z-index: ${({ isActive }) => (isActive ? 1 : 0)};

  ${({ isHidden }) => (isHidden
    ? `
    opacity: 0.2;
  `
    : '')}
`;

const hideIt = keyframes`
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const MoreButton = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
  width: 22px;
  position: relative;
  -webkit-animation-direction: normal;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-name: ${hideIt};
  -webkit-animation-timing-function: ease;
`;

export const BoardLayerShell = styled.div`
  width: 100%;
  height: 100%;
`;

export const ImageHoverEffect = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  display: block;
  z-index: 4;
  width: 278px;
  height: 125px;
  background: transparent;
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};

  &:hover {
    z-index: 8;
    background: #24263399;
  }

  &:hover ${ItemControlsShell} {
    display: block;
  }

  &:hover ${ControlButtonsZIndexAffected}, &:hover ${BoardLayerShell} {
    z-index: 8;
  }

  ${({ active }) => (active
    ? `
    z-index: 8;
    background: #24263399;
  `
    : '')}
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${buttonsBorderRadius};
  margin-right: 10px;
  position: relative;
  height: 22px;
  width: auto;
  padding: 0 5px;
  max-width: 100px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  cursor: default;
  background: ${({ background }) => `${background ?? '#242633'};`};
  color: ${({ text }) => `${text ?? '#FCFDFF'};`};
`;

export const Shared = styled.div`
  position: relative;
  height: 22px;
  width: 62px;
  padding: 0 5px;
  max-width: 100px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  cursor: default;
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${buttonsBorderRadius};
  background: #ffffffb2;
  color: #596080;
  left: 15px;
  bottom: 28px;
`;

export const ColumnButton = styled(ControlButton)`
  height: 22px;
  width: 70px;
  border-radius: 4px;
  position: relative;
  padding: 0 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
  ${({ isRead, isInboxCard }) => (isRead && isInboxCard
    ? 'opacity: 0.7;' : '')}
  ${({ tableView }) => (tableView
    ? ' width: auto;'
    : `
    left: 78px;
    top: 34px;
    width: max-content;
  `)}
 
  opacity: 0.8;
  ${({ isInboxCard }) => (isInboxCard
    ? `
    position: inherit;
    padding: 0 3px;
    height: 20px;
    max-width: 110px;
    `
    : '')}

  & p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
  }

  background: ${({ background }) => `${background ?? 'rgba(52, 52, 52, 1)'};`};
  color: ${({ text }) => `${text ?? '#FFFFFF'};`};

  &:hover {
    background: ${({ background }) => `${background ?? '#596080'};`};
  }

  ${({ active }) => (active
    ? `
    background: ${({ background }) => `${background ?? '#596080'};`};
  `
    : '')}
    
  ${({ sameColors }) => (sameColors
    ? `
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  ` : '')}

  & svg {
    margin-left: 3px;
  }
  ${({ isCard }) => (isCard
    ? `
      width: 55px;
      top: 110px;
      left: 41px;

      & svg {
        margin-left: 0;
      }
    `
    : '')}
`;

export const SelectedOverlay = styled.div`
  background: #ffffff66;
  position: absolute;
  top: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 5px;
`;

export const LabelsLayerShell = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 125px;
`;

export const ColumnsDropDownShell = styled.div`
  overflow: auto;
  position: absolute;
  width: 140px;
  max-height: 183px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 3px 0;
  z-index: 4;

  &::-webkit-scrollbar {
    width: 4px;
    position: absolute;
    right: -4px;
  }

  &::-webkit-scrollbar-thumb {
    position: absolute;
    display: block;
    border-radius: 4px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.13);
  }

  &::-webkit-scrollbar-track-piece {
    display: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
`;

export const IconButtonsGroup = styled.div`
  position: relative;
  width: 60px;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 4px;
`;

export const SmallIcon = styled.div`
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    height: 18px;
  }

  & svg path {
    stroke: #1b2336;
  }

  ${({ active }) => (active
    ? `
    & svg path {
      fill: #1B2336;
    }
  `
    : '')}
  ${({ favorite }) => (favorite
    ? `
   
    cursor: pointer;
   
    &:hover svg path {
      stroke: #0026E5;
      fill: #C5CEFF;
    }
  `
    : '')}
`;

export const ColumnDropDownItemShell = styled.div`
  padding: 3px 15px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #e8ebfa;
  }

  color: ${({ active }) => `${active ? '#D7DDF7' : ''};`};

  p {
     margin: 0;
  }
`;

export const Column = styled.div`
  height: 22px;
  background: ${({ background }) => `${background ?? '#FFDE80'};`};
  color: ${({ text }) => `${text ?? '#596080'};`};
  border-radius: 4px;
  width: 100%;

  display: inline-block;
  text-align: center;
  font-family: "RobotoFlex", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 4px;
  padding-right: 4px;

  & p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    //width: 90%;
  }
  ${({ sameColors }) => (sameColors
    ? `
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  ` : '')}
`;

export const ChevronPlayerIcon = styled.div`
  opacity: 0;
  margin-left: 6px;
  line-height: 1px;
  transition: opacity ease-out ${buttonHoverAnimation};
  ${({ isActive }) => (isActive
    ? `
    opacity: 1;
  `
    : '')}
`;

export const CircleForPlayerButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #59608077;
  transition: all ease-out ${buttonHoverAnimation};

  & svg {
    width: 20px;
    height: 20px;
    margin-left: 3px;
  }

  &:hover {
    background-color: #c5ceff;

    & svg path {
      stroke: #0026e5;
    }
  }

  ${({ isActive }) => (isActive
    ? `
   background-color: #C5CEFF;
    & svg path {
      stroke: #0026E5;
    }
  `
    : '')}
`;

export const PlayerModesSelectorShell = styled.div`
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: -20px;
  right: 16px;
  //transition: top ease-out ${buttonHoverAnimation};
  cursor: pointer;

  &:hover ${ChevronPlayerIcon} {
    opacity: 1;
  }
`;

export const PlaylistCardShell = styled.div`
  position: relative;
  margin: 0 22px 14px 0;
`;

const glow2 = keyframes`
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
`;

const glowingMixin = css`
  transform: scale(1.05);

  &:before {
    content: "hello";
    background: linear-gradient(45deg, #0026e555, #0026e5aa);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 200%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: ${glow2} 35s linear infinite;
    transition: opactiy 0.3s ease-in-out;
    border-radius: 10px;
  }

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  overflow: visible;
`;

export const PlaylistCardItem = styled.div`
  width: 271px;
  height: 180px;
  animation: ${fadeInCard} 0.6s;
  position: relative;
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
  border-bottom-left-radius: ${borderRadius};
  overflow: hidden;
  box-shadow: 0 5px 10px 1px rgba(89, 96, 128, 0.15);
  //transition: all ease-in-out 0.25s;

  &:hover {
    box-shadow: 0 5px 10px 1px rgba(89, 96, 128, 0.25);
    transform: translateY(-2px);
  }

  & ${PlayerModesSelectorShell} {
    top: -32px;
  }

  &:hover ${PlayerModesSelectorShell} {
    top: 14px;
  }

  &:hover ${InfoPanel} {
    bottom: 117px;
  }

  & ${InfoPanel} {
    bottom: 0;
  }

  ${({ selected }) => (selected
    ? `
    box-shadow: 0 5px 10px 1px rgba(89, 96, 128, 0.25);
    transform: translateY(-2px);
  
    & ${InfoPanel} {
      bottom: 117px;
    }
    
    & ${PlayerModesSelectorShell} {
      top: 14px;
    }
  `
    : '')}

  ${({ isActionWithItem }) => (isActionWithItem
    ? `
    box-shadow: 0 5px 10px 1px rgba(89, 96, 128, 0.25);
    transform: translateY(-2px);
    overflow: visible;
    & ${InfoPanel} {
      bottom: 117px;
    }
    
    & ${PlayerModesSelectorShell} {
      top: 14px;
    }
  `
    : '')}

  ${({ selected }) => (selected
    ? `
    box-shadow: 0 5px 10px 1px rgba(89, 96, 128, 0.25);
    transform: translateY(-2px);
    z-index: 1;
    & ${GeneralHoverOverlay} {
      border: 1px solid ${selectedColor};
    }
  
    & ${ImageHoverEffect}, & ${ItemControlsShell} {
      display: block;
    }
    
    & ${BoardLayerShell}, & ${ControlButtonsZIndexAffected} {
      z-index: 7;
    }
  `
    : `
    &:hover ${InfoPanel} {
      z-index: 7;
    }
  `)}

  ${({ isGrow }) => (isGrow
    ? glowingMixin
    : 'filter: drop-shadow(1px 4px 10px rgba(0, 0, 0, 0.15));')}


  ${({ isGrowHover }) => (isGrowHover
    ? `
    &:hover {
      filter: drop-shadow(0 0 6px #0D26E5AA);
    }
  `
    : '')}
`;

export const ChannelsModal = styled.div`
  position: absolute;

  bottom: 25px;

  width: 276px;
  min-height: 56px;

  z-index: 1;
  padding: 5px;

  background: #fcfdff;
  border: 1px solid #e4e7f7;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  word-break: break-all;
  display: inline-block;
  white-space: break-spaces;
  transition: visibility ease-in 0.3s;
  visibility: hidden;
`;
export const TrianglePointer = styled.div`
  position: absolute;
  background: inherit;
  border: inherit;
  width: 10px;
  height: 10px;
  border-bottom-left-radius: 2px;
  left: 121px;
  bottom: -5px;

  ${({ pointingDirection }) => (pointingDirection === 'down'
    ? `
    -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
    `
    : '')}
`;

export const ChannelsPlank = styled.div`
  position: relative;
  width: ${({ specifiedWidth }) => (specifiedWidth ? specifiedWidth - 30 : 229)}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;

  ${({ showModal }) => (showModal
    ? `
    cursor: pointer;
  `
    : '')}
`;

export const MultilineText = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 24px;
  color: #596080;
  display: block;
  height: max-content;
`;

export const ChannelsSectionShell = styled.div`
  position: relative;
  display: flex;
  font-size: 14px;
  line-height: 24px;
  color: #596080;
  word-break: normal;
  align-items: center;
  width: ${({ specifiedWidth }) => specifiedWidth || 260}px;

  ${({ showModal }) => (showModal
    ? `
    cursor: pointer;
    
    &:hover ${ChannelsModal} {
      visibility: visible;
    }
  `
    : '')}
`;

export const Spinner = styled.div`
  z-index: 100;
  position: absolute;
  top: 18%;
  left: 43%;
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%);
  display: ${({ cursorLoader }) => (cursorLoader ? 'initial' : 'none')};
`;

const greyBlinking = keyframes`
  0% {
    background: rgba(225, 222, 222, 1);
  }
  25% {
    background: #c4c3c3;
  }
  50% {
    background: rgba(225, 222, 222, 1);
  }
  75% {
    background: #c4c3c3;

  }
  100% {
    background: rgba(225, 222, 222, 1);
  }
`;

export const GreyBlinkingPlaceholder = styled.div`
  //position: absolute;
  //top: 0px;
  //left:0px;
  opacity: 0.5;

  width: 140px;
  height: 140px;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  z-index: 1;
  //animation-direction: normal;
  //-webkit-animation-duration: in;
  //-webkit-animation-iteration-count: 1 ;
  //-webkit-animation-timing-function: ease;
  background: rgba(225, 222, 222, 0.5);
  margin: 0 22px 14px 0;
  ${({ type }) => (type === 'row'
    ? `
          height: 32px;
          width: 100%;
          margin-top: 4px;
          margin-bottom: 5px;
          `
    : '')}
  ${({ type }) => (type === 'notification'
    ? `
    max-width: 1176px;
    margin: 0 60px 2px 1px;
    position: relative;
    height: 35px;
    width: calc(100% - 19px);
    min-width: calc(100% - 19px);
    border-radius: 15px;
          `
    : '')}
  ${({ type }) => (type === 'pages'
    ? `
        width: 169px;
    height: 248px;`
    : '')}
  ${({ type }) => (type === 'components'
    ? `
   width: 168px;
    height: 168px;`
    : '')}
  ${({ type }) => (type === 'pdf'
    ? `
    width: 169px;
    height: 248px;`
    : '')}
  ${({ type }) => (type === 'links'
    ? `
    width: 168px;
    height: 168px;`
    : '')}
  ${({ type }) => (type === 'files'
    ? `
    width: 168px;
    height: 168px;`
    : '')}
  ${({ type }) => (type === 'media'
    ? `
       width: 168px;
    height: 168px;`
    : '')}

  ${({ type }) => (type === 'library'
    ? `
    width: 169px;
    height: 248px;`
    : '')}
  ${({ type }) => (type === 'preview'
    ? `
    width: 90px;
  min-width: 90px;
  height: 90px;
  border: 2px solid #FFFFFF;
  border-radius: 4px;
  overflow: hidden;
  margin-left: 18px;
  ${({ active }) => (active ? 'border: 2px solid #FFC72C;' : '')}
  `
    : '')}
`;
