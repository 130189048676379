import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import styled from 'styled-components/macro';
import styles from './EditColumn.module.scss';
import { COLUMN_DEFAULT_COLOR, empty, DATA_PARENTS, COLUMN_CUSTOM_TYPE } from '../../../utils/constants';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import { ReactComponent as More16Svg } from '../../../images/icons/more_16.svg';
import DropDownMenu from './DropdownMenu';

const stylesTag = classnames.bind(styles);

const Icon = styled.div`
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  display: flex;

  &:hover {
    background: rgba(255, 255, 255, 0.30);;
  }
`;

const TitleCount = styled.div`
  font-size: 14px;
  font-weight: 500;
  ${({ sameColors }) => (sameColors ? `
    text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000;
  ` : '')}
`;

const TitleText = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  ${({ sameColors }) => (sameColors ? `
    text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000;
  ` : '')}
`;

const EditColumn = ({ column, isStatic, ...props }) => {
  const threeDotsRef = useRef(null);
  const [localTitle, setLocalTitle] = useState(column.name || '');
  const [localColor, setLocalColor] = useState(column.color ?? '#484343');
  const [localBGColor, setLocalBGColor] = useState(column.backgroundColor ?? '#FFDE80');
  const notSeenPlaylist = useSelector(
    (state) => state.dashboardColumns.notSeenPlaylist || empty,
  );
  const [isActive, setIsActive] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState({});
  const [dropDDPos, setDropDDPos] = useState({ x: 220, y: 0 });
  const [exOptionRef, isExOpened, setIsExOpened] = useComponentVisible(
    false,
    DATA_PARENTS.tagList,
  );
  useEffect(() => {
    setLocalTitle(column.name);
  }, [column.name]);

  useEffect(() => {
    setLocalColor(column.color ?? '#484343');
    setLocalBGColor(column.backgroundColor ?? '#FFDE80');
  }, [column.color, column.backgroundColor]);


  const setPosition = useCallback((rect, columnName) => {
    const { y } = rect;
    setIsExOpened(true);
    setDropDDPos((oldState) => ({
      ...oldState,
      y: y - 92,
      x: column.customType === COLUMN_CUSTOM_TYPE.CLOSE ? 100 : props.isLastColumn ? 100 :oldState.x,
    }));
    setSelectedColumn(columnName);
  }, [props.isLastColumn, column.customType, setIsExOpened]);

  const setPositionEx = (e) => {
    e.stopPropagation();
    setIsActive(!isActive);
    if (threeDotsRef?.current) {
      setPosition(threeDotsRef.current.getBoundingClientRect(), column.name);
    }
  };

  return (
    <div
      style={{
        backgroundColor:
          localBGColor || COLUMN_DEFAULT_COLOR.backgroundColor,
        color: localColor || COLUMN_DEFAULT_COLOR.color,
      }}
      className={stylesTag(
        'column_header',
        {
          stroke:
            !!column.color
            && !!column.backgroundColor
            && column.color === column.backgroundColor,
        },
        {
          isNotStatic: !isStatic,
          notSeen: props.isInbox && Object.values(notSeenPlaylist || {}).length,
        },
      )}
    >
      <div className={styles.counter}>
        {' '}
        <TitleCount sameColors={localBGColor === localColor}>{Object.values(column?.items || {}).length}</TitleCount>
      </div>

      <div>
        <div
          className={stylesTag('textHeader', {
            stroke:
              !!column.color
              && !!column.backgroundColor
              && column.color === column.backgroundColor,
          })}
          style={{
            color: localColor || '#484343',
          }}
        >
          <TitleText sameColors={localBGColor === localColor}>{localTitle}</TitleText>
        </div>
      </div>
      <Icon
        data-cy="column-dropDownMenu"
        ref={threeDotsRef}
        data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
        onClick={setPositionEx}
        active={isActive}
        className="show_on_hover"
      >
        <More16Svg data-parent={DATA_PARENTS.PlaylistBuilderLibrary} />
      </Icon>
      {isExOpened && (
        <DropDownMenu
          refMenu={exOptionRef}
          clickedTag={selectedColumn}
          top={dropDDPos.y}
          left={dropDDPos.x}
          clickedColumn={column}
        />
      )}
    </div>
  );
};

export default EditColumn;
