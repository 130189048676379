import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import {
  actionGoToViewPage,
  actionPageWillDownload,
} from '../../redux/currentPage/action';
import BottomNav from '../../components/BottomNav';
import { MessageType } from '../../utils/constants';
import { actionShowMessage } from '../../redux/support/action';
import SharedComponent from '../../components/SharedComponent';

const ViewPage = ({ isInBuilder, isViewMode }) => {
  const { id, num } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const title = useSelector((state) => state.currentPage.title);
  const isAnyCanEdit = useSelector(
    (state) => state.currentPage.inputs.isAnyCanEdit,
  );
  const playlistId = useSelector((state) => state.currentPage.playlistId);
  const description = useSelector((state) => state.currentPage.description);
  const isDownload = useSelector((state) => state.currentPage.isDownload);
  const indexViewLesson = useSelector(
    (state) => state.currentPage.indexViewLesson,
  );
  const editableBlocks = useSelector((state) => state.currentPage.blocks) || [];
  const isPreview = history.location.pathname.includes('/preview_pages');

  const dispatch = useDispatch();

  useEffect(() => {
    if (isInBuilder) return;
    const intNum = parseInt(num);
    if (id === playlistId && intNum === indexViewLesson) return;
    if (id === playlistId) dispatch(actionGoToViewPage('', history, intNum));
    else {
      dispatch(actionPageWillDownload());

      history.push(
        `/${isPreview ? 'preview_playlist' : 'browse_playlists'}/${id}`,
      );
    }
  }, [num, id]);

  const showNotEditableMessage = () => dispatch(actionShowMessage({ type: MessageType.CantEdit }));

  if (isDownload) {
    return (
      <div className="loader-wrapper-page">
        <div className="loader">{t('loadingT')}</div>
      </div>
    );
  }
  return (
    <div className={styles.wrapper2}>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <div className={styles.input_title}>{title} </div>
          <div className={styles.input_description}>{description} </div>
        </div>

        <div className={styles.space_separator} />
        <div className={`${styles.content}`}>
          {editableBlocks.map((item, index) => {
            if (item.isHidden) return <div key={item.id} />;
            if (!item.components) {
              return (
                <SharedComponent
                  key={item.id}
                  type1={item.type}
                  state={item.state}
                  componentId={item.id}
                  index={index}
                  width={item.width}
                  page={item}
                  innerHtml={item.innerHtml}
                  isSharePage
                  isAnyCanEdit={isAnyCanEdit}
                  showNotEditableMessage={showNotEditableMessage}
                  isViewMode={isViewMode}
                />
              );
            }
            return item.components.map((component, nestedIndex) => {
              if (component.isHidden) return <div key={component.id} />;
              return (
                <>
                  <SharedComponent
                    key={component.id}
                    type1={component.type}
                    state={component.state}
                    componentId={component.id}
                    index={nestedIndex}
                    width={component.width}
                    page={component}
                    innerHtml={component.innerHtml}
                    isSharePage
                    isAnyCanEdit={isAnyCanEdit}
                    showNotEditableMessage={showNotEditableMessage}
                    isViewMode={isViewMode}
                  />
                </>
              );
            });
          })}
        </div>
      </div>
      {!isInBuilder && <BottomNav />}
    </div>
  );
};

export default ViewPage;
