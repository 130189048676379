import React from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  DATA_PARENTS,
  LibCompSieveTypesTest,
  mapTitles,
} from '../../utils/constants';
import { ReactComponent as ChevronDown12Svg } from '../../images/icons/chevron_down_12.svg';
import DynamicCollectionDropListOption from './DynamicCollectionDropListOption';
import DynamicCollectionDatePicker from './DynamicCollectionDatePicker';
import styles from './index.module.scss';
import DynamicCollectionDropListOptionTAGS from './DynamicCollectionDropListOptionTAGS';
import { convertDateLibFilter } from '../../utils/dateConvert';
import SearchInSmartView from '../../UI/SearchInSmartView/SearchInSmartView';

const cn = classNames.bind(styles);

const calcOption = (sieveTypes) => {
  const option = {};
  Object.values(sieveTypes || {}).forEach((i) => (option[i] = mapTitles(i)));
  return option;
};

// TODO here is filters
const BottomPart = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isPagesOrPlaylists = history.location.pathname === '/pages'
    || history.location.pathname === '/playlists';

  const hasFileTypeFilter = props.filter.fileType && Object.values(props.filter.fileType || {}).length;
  const hasDataFilter = props.filter.createDate
    && Object.values(props.filter.createDate || {}).length;
  const hasTagFilter = props.filter.tags && Object.values(props.filter.tags || {}).length;

  return (
    <div
      className={cn('tags', {
        active: props.isShowFilters,
        isFullScreen: props.isFullScreen,
      })}
    >
      {props.isSearch && (
        <SearchInSmartView
          isFullScreen={props.isFullScreen}
          libFilterSearch={props.libFilterSearch}
          setLibFilterSearch={props.setLibFilterSearch}
          activeSieveOption={props.activeSieveOption}
        />
      )}

      <div className={cn('filters')}>
        <div
          data-cy="tags"
          data-parent={DATA_PARENTS.SideBar.tags}
          className={cn('filters_option', 'hoverable', {
            active: props.isTagsOpened,
            selected: hasTagFilter,
          })}
          onMouseDown={(e) => {
            e.stopPropagation();
            props.toggleIsTagsOpened();
          }}
        >
          {!hasTagFilter && (
            <div className={cn('filters_option_text')}>{t('tagsT')}</div>
          )}
          {!!hasTagFilter && (
            <div
              className={cn(
                'filters_option_text',
                'filters_option_text_active',
              )}
            >
              {Object.values(props.filter.tags || {})
                .map((i) => i.title)
                .join(', ')}
            </div>
          )}
          <ChevronDown12Svg className={cn('icon')} />

          <DynamicCollectionDropListOptionTAGS
            type="Tags"
            option={props.tags}
            setFilter={props.setFilter}
            active={props.isTagsOpened}
            filter={props.filter.tags}
            data={DATA_PARENTS.SideBar.tags}
            refParent={props.refTags}
          />
        </div>
        <div
          data-cy="date-range"
          data-parent={DATA_PARENTS.SideBar.date}
          className={cn('filters_option', 'hoverable', 'date_range', {
            active: props.isDateOpened,
            selected: hasDataFilter,
          })}
          onMouseDown={(e) => {
            e.stopPropagation();
            props.toggleIsDateOpened();
          }}
        >
          {!hasDataFilter && (
            <div className={cn('filters_option_text')}>{t('dateRangeT')}</div>
          )}
          {!!hasDataFilter && (
            <div
              className={cn(
                'filters_option_text',
                'filters_option_text_active',
              )}
            >
              {`${convertDateLibFilter(
                props.filter.createDate.Start,
              )} - ${convertDateLibFilter(props.filter.createDate.End)}`}
            </div>
          )}

          <ChevronDown12Svg className={cn('icon')} />

          <DynamicCollectionDatePicker
            setFilter={props.setFilter}
            active={props.isDateOpened}
            data={DATA_PARENTS.SideBar.date}
            refParent={props.refDate}
            toggleIsDateOpened={props.toggleIsDateOpened}
          />
        </div>
        {!isPagesOrPlaylists && (
          <div
            data-cy="file-types"
            data-parent={DATA_PARENTS.SideBar.fileType}
            className={cn('filters_option', 'hoverable', 'file_type', {
              active: props.isFileTypeOpened,
              selected: hasFileTypeFilter,
            })}
            onMouseDown={(e) => {
              e.stopPropagation();
              props.toggleIsFileTypeOpened();
            }}
          >
            {!hasFileTypeFilter && (
              <div className={cn('filters_option_text')}>{t('fileTypeT')}</div>
            )}
            {!!hasFileTypeFilter && (
              <div
                className={cn(
                  'filters_option_text',
                  'filters_option_text_active',
                )}
              >
                {Object.values(props.filter.fileType).join(', ')}
              </div>
            )}
            <ChevronDown12Svg className={cn('icon')} />

            <DynamicCollectionDropListOption
              type="File Types"
              option={calcOption(LibCompSieveTypesTest)}
              filter={props.filter.fileType}
              setFilter={props.setFilter}
              active={props.isFileTypeOpened}
              data={DATA_PARENTS.SideBar.fileType}
              refParent={props.refFileType}
            />
          </div>
        )}

        <div
          data-cy="clear-all"
          onClick={props.clearAllFilters}
          className={cn('filters_option', {
            filters_option_text: props.isHasSomeFilter,
          })}
        >
          {t('clearAllT')}
        </div>
      </div>
    </div>
  );
};

export default BottomPart;
