export const LibraryComponents = {
  UnsubscribeLibraryEntity: "[LibraryComponents] Unsubscribe Library entity",
  UnsubscribeManyLibraryEntity: "[LibraryComponents] Unsubscribe Library entities(many)",
  AddComponent: "[LibraryComponents] Add new component to Library",
  UpdateGoogleEmbedModifiedTime:
    "[LibraryComponents] Update Google Embed ModifiedTime SAGA",
  actionAddLibraryComponentREDUX:
    "[LibraryComponents] Add Library Component REDUX",
  AddComponentToCollectionREDUX:
    "[LibraryComponents] Add new component to Collection Redux Only",
  LoadFileToLibrary: "[LibraryComponents] Load file to Library",
  BulkAddComponent: "[LibraryComponents] Load all users libraryComponents",
  ToggleFavorite: "[LibraryComponents] Toggle component favorite",
  DuplicateComponent: "[LibraryComponents] Duplicate component",
  DuplicateSharedComponent: "[LibraryComponents] Duplicate Shared component",
  MoveComponentToTrash: "[LibraryComponents] Move component to Trash",
  RestoreComponentFromTrash: "[LibraryComponents] Restore Component From Trash",
  UpdateLibraryComponent:
    "[LibraryComponents] Update any field of LibraryComponent",
  UpdateLibraryComponentInRedux:
    "[LibraryComponents] Update any field of LibraryComponent REDUX ONLY",
  AddBlockToLibraryComponent:
    "[LibraryComponents] Add Block To LibraryComponent REDUX ONLY",
  Player: {
    DuplicateSharedComponent:
      "[LibraryComponents] Player::Duplicate Shared component",
  },
  Shared: {
    UpdateSharedLibraryComponentInRedux:
      "[LibraryComponents] Update any field of Shared LibraryComponent REDUX ONLY",
  },
  UpdateLibraryComponentInCollectionRedux:
    "[LibraryComponents] Update component in collection REDUX ONLY",
  DownloadComponent: "[LibraryComponents] Download Library Component Data",
  DeleteComponent: "[LibraryComponents] Delete Library Component Data",
  DeleteComponentREDUX:
    "[LibraryComponents] Delete Library Component Data Redux only",
  DeleteComponentInCollectionREDUX:
    "[LibraryComponents] Delete Library Component in collection Data Redux only",
  CreateLibraryComponentFromBlocks:
    "[LibraryComponents] Create Library Component From Selected Blocks",
  GetSharedLibraryComponentInPlaylist:
    "[LibraryComponents] Get Shared LibraryComponent in Playlist to currentPage",
  GetSharedLibraryComponent:
    "[LibraryComponents] Get Shared LibraryComponent to currentPage",
  CreateLibraryComponentInLibraryFromBlocks:
    "[LibraryComponents] Create Library Component In library From Selected Blocks",
  ChangeLibraryComponentInLibraryFromBlocks:
    "[LibraryComponents] Change Library Component In library From Selected Blocks",
  RevertLibraryComponentInLibraryFromBlocks:
    "[LibraryComponents] Revert Library Component In library From Selected Blocks",
  CopyBlocksToPage: "[LibraryComponents] Copy Blocks To Page",
  CopyBlockImageToPage: "[LibraryComponents] Copy Image to Block In Page",
  CopyUploadToPage: "[LibraryComponents] Copy Upload to Block In Page",
  AddComponentToDragState: "[LibraryComponents] Add Component To Drag State",
  AddLinkPagesToDragState: "[LibraryComponents] Add LinkPages To Drag State",
  ClearDragState: "[LibraryComponents] Clear Drag State",
  BulkAddComponentsToDragState:
    "[LibraryComponents] Bulk Add Components To Drag State",
  CreateLinkLibraryComponent:
    "[LibraryComponents] Create Link Library Component",
  CreateEmbedLibraryComponent:
    "[LibraryComponents] Create Embed Library Component",
  OpenMenuCreateEmbedLibraryComponent:
    "[LibraryComponents] Open menu for Create Embed Library Component",
  CheckLinkForLibraryComponent:
    "[LibraryComponents] Check Link For LibraryComponent",
  DetachComponent: "[LibraryComponents] Detach Library Component in page",
  SavePdfBlock: "[LibraryComponents] Save Pdf Block",
  UpdatePdf: "[LibraryComponents] Update Pdf field value",
  UpdatePdfWidth: "[LibraryComponents] Update Pdf width",
  UpdatePdfPreview: "[LibraryComponents] Update Pdf Preview",
  GetComponents: "[LibraryComponents] Get Components",
  GetComponent: "[LibraryComponents] Get Component",
  SaveComponent: "[LibraryComponents] save Component",
  GetComponentsClear: "Get Components Clear",
  UpdateState: "[Library] Update Library State",
  ToggleUploadingFilesState: "[Library] Toggle Uploading Files State",
  Pages: {
    AddPageInLibraryRS: "[LibraryPages] Add Page Into Library R",
    UpdatePageInLibraryField: "[LibraryPages] Update Page In Library R",
    AddLibraryPageIntoLibraryPageR:
      "[LibraryPages] Add LibraryPage Into LibraryPage REDUX",
    AddLibraryPageToChannelR:
      "[LibraryPages] Library Add Library Page To Channel REDUX",
    Duplicate: "[LibraryPages] Duplicate page",
    RemoveLibraryPageFromChannelR:
      "[LibraryPages] Library Remove Library Page From Channel REDUX",
    MoveToTrashLibraryPageRS:
      "[LibraryPages] Move To Trash LibraryPage REDUX SAGAS",
    MoveFromTrashLibraryPageRS:
      "[LibraryPages] Move From Trash LibraryPage REDUX SAGAS",
    MoveLibraryPageToFolder: "[LibraryPages] Move LibraryPage To Folder",
    CreateNewLibraryPageInCollectionS:
      "[LibraryPages] Create New LibraryPage In Collection SAGAS",
    SaveShareStateLibraryPage: "[LibraryPages] Save ShareState LibraryPage",
    PublishPage: "[LibraryPages] Publish Page",
    UnPublishPage: "[LibraryPages] cancel the publication of the page",
    updateLibraryPageDescription:
      "[LibraryPages] Update LibraryPage Description state",
    ReplaceLibraryPageComponentsR:
      "[LibraryPages] Replace LibraryPage Component blocks REDUX ",
    AddLibraryPageComponentR: "[LibraryPages] Add LibraryPage Component REDUX",
    AddLibraryPageManyComponentR:
      "[LibraryPages] Add LibraryPage Manny Component REDUX",
    DeleteLibraryPageComponentR:
      "[LibraryPages] Delete LibraryPage Component REDUX",
    DeleteManyLibraryPageComponentR:
      "[LibraryPages] Delete Many LibraryPage Component REDUX",
    ChangeManyLibraryPageComponentPositionR:
      "[LibraryPages] Change Many LibraryPage Component position REDUX",
    GetSharedPagesS: "[LibraryPages] get shared pages SAGAS",
    UpdateStateSharedPagesR: "[LibraryPages] update state shared pages REDUX",
  },
};

export const LibraryCollections = {
  AddComponentToCollection:
    "[LibraryCollections] Add libraryComponent in collection",
  ChangeCollectionIsNewReduxOnly:
    "[LibraryCollections] Change Collection IsNew ReduxOnly",
  BulkAddCollections: "[LibraryCollections] Load all users libraryCollections",
  RenameLibraryCollectionRedux:
    "[LibraryCollections] Rename LibraryCollection Redux",
  RenameLibraryCollection: "[LibraryCollections] Rename LibraryCollection",
  RestoreCollectionFromTrash:
    "[LibraryCollections] Restore Collection From Trash",
  RemoveComponentREDUX:
    "[LibraryCollections] Remove Component from collection REDUX ONLY",
  UpdateCollectionsREDUX: "[LibraryCollections] Update Collections REDUX ONLY",
  MoveComponentInNewCollection:
    "[LibraryCollections] Move Component In New Collection",
  DeleteCollectionREDUX:
    "[LibraryCollections] Delete Collection For Components Redux only",
  DuplicateCollection:
    "[LibraryCollections] Duplicate Collection with components",
  AddCollectionREDUX: "[LibraryCollections] Add Collection Redux Only",
};

export const Library = {
  ChangeSideBarSelector: "[Library] Change Library Side Bar Selector",
  ChangeSieveValue: "[Library] Change sieve value",
  toggleOpenCreateComponent: "[switchFolder] Toggle open create component",
  OpenGooglePickerModal: "[Library] Open Google Picker Modal",
  CreateLinkedAccountS: "[Library] Create LinkedAccount SAGA",
  AddLinkedAccountR: "[Library] Add LinkedAccount REDUX",
};
