import React from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DATA_PARENTS, DEFAULT_PLAYER_PATH, DEFAULT_SHARED_PLAYER_PATH } from '../../../utils/constants';
import { Icons } from '../../../utils/UIHelpers/TableOfContent/icons';
import styles from '../../../utils/UIHelpers/TableOfContent/helpers.module.scss';
import { actionToggleComponentDescription } from '../../../redux/support/action';
import { TableOfContentRowWrapper, TableOfContentRowText } from './styled';
import { getItemName } from '../../../utils/helpers';

const cn = classNames.bind(styles);

const TableOfContentRow = ({ ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const click = () => {
    dispatch(actionToggleComponentDescription(false));
    if (props.isSharedSmartFile) {
      history.push(`${DEFAULT_SHARED_PLAYER_PATH + props.id}/${props.number}`);
    } else {
      history.push(`${DEFAULT_PLAYER_PATH + props.id}/${props.number}`);
    }
    props.setIsShowRightSideBarLib(false);
  };

  return (
    <TableOfContentRowWrapper onClick={click}>
      <TableOfContentRowText>
        {`${props.number}.`} {getItemName(props.item)}
      </TableOfContentRowText>
      <div
        data-parent={DATA_PARENTS.TableOfContents}
        className={cn('icon_container')}
      >
        {Icons(props.item)}
      </div>
    </TableOfContentRowWrapper>
  );
};

export default TableOfContentRow;
