import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AvatarGroupComponent } from './AvatarGroup';
import {
  FatDot,
  ItemWrapperUPV,
  TextWrapperUPV,
} from './styled';
import { timeSince } from '../../../../utils/dateConvert';
import { universalPlaylistSize } from '../../../../utils/helpers';
import {
  AvatarInfoRowShell,
  MakerFlexable,
  MakerInfoRowShell,
  MakerInfoUserUIWrapper,
  ShareWrapper,
  DownloadButton,
} from '../../styled';
import UniversalPlaylistUserUI from '../../MakerCommon/UiComponents/UniversalPlaylistUserUI';
import { getUserAvatarComponent } from '../../MakerCommon/LogicComponents/getUserAvatarComponent';
import { actionGetUsersToSharingPl } from '../../../../redux/playlists/action';

import Tooltip from '../../../../shared/Tooltips/Tooltip';
import { SHARED_PLAYLIST_URL } from '../../../../utils/constants';
import { isRoleInPlaylist } from '../../../../utils/permissions';

import ShareButtons from './ShareButtons';
import EndDate from './EndDate';

const MakerInfoRow = ({
  linkPages,
  lastModifiedDate,
  userName,
  owner,
  createDate,
  handleDownload,
  isCanManyDownload,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isDownload,
    singleUserShareState,
    currentRole,
  } = useSelector(state => state.currentPage);
  const history = useHistory();

  const { isOwner } = isRoleInPlaylist;
  const coEditorsToDisplay = useMemo(() => {
    return Object.values(singleUserShareState).filter(it => it.isCoEdit);
  }, [singleUserShareState]);

  useEffect(() => {
    if (history.location.pathname.includes(`/${SHARED_PLAYLIST_URL}`)) return;

    dispatch(actionGetUsersToSharingPl({ type: 'playlist' }));
  }, [dispatch, history.location.pathname]);

  const elemsCount = linkPages?.filter(elem => elem.type !== 'elementText').length;

  const calcPlaylistSize = useMemo(() => {
    return universalPlaylistSize(linkPages, true);
  }, [linkPages]);

  return (
    <MakerInfoRowShell>
      <MakerFlexable>
        <MakerInfoUserUIWrapper>
          <UniversalPlaylistUserUI
            AvatarComponent={getUserAvatarComponent(owner, 28)}
            name={userName.trim() ? userName : t('userDoesNotExistAnymoreT')}
            radius={28}
          />
        </MakerInfoUserUIWrapper>
        {!isDownload && (
          <>
            {!!coEditorsToDisplay.length && (
              <AvatarInfoRowShell>
                <FatDot> · </FatDot>
                <AvatarGroupComponent users={coEditorsToDisplay} />
                <TextWrapperUPV>
                  {coEditorsToDisplay.length === 1 ? t('coEditorT') : `${coEditorsToDisplay.length} ${t('coEditorsT')}`}
                </TextWrapperUPV>
              </AvatarInfoRowShell>
            )}
            <ItemWrapperUPV>
              <FatDot> · </FatDot>
              <TextWrapperUPV>{elemsCount || 0} {t('itemsLowT')}</TextWrapperUPV>
            </ItemWrapperUPV>

            <ItemWrapperUPV>
              <FatDot> · </FatDot>
              <TextWrapperUPV>{calcPlaylistSize}</TextWrapperUPV>
            </ItemWrapperUPV>

            <ItemWrapperUPV>
              <FatDot> · </FatDot>
              <TextWrapperUPV>{t('createdT')} {timeSince(createDate * 1000)}</TextWrapperUPV>
            </ItemWrapperUPV>

            <ItemWrapperUPV>
              <FatDot> · </FatDot>
              <TextWrapperUPV>{t('updatedT')} {timeSince(lastModifiedDate * 1000)}</TextWrapperUPV>
            </ItemWrapperUPV>

            {!isOwner[currentRole] && (
              <ItemWrapperUPV>
                <FatDot> · </FatDot>
                <EndDate />
              </ItemWrapperUPV>
            )}
          </>
        )}
      </MakerFlexable>
      {isOwner[currentRole] && (
        <ShareWrapper>
          <ShareButtons />
        </ShareWrapper>
      )}
      {!isOwner[currentRole] && (
        <Tooltip
          text={t('downloadThisSmartFileT')}
          direction="down"
        >
          <DownloadButton
            onClick={handleDownload}
            isDisabled={!linkPages?.length || !isCanManyDownload}
          >
            {t('downloadUpT')}
          </DownloadButton>
        </Tooltip>
      )}

    </MakerInfoRowShell>
  );
};

export default MakerInfoRow;
