import { Tags } from './types';

export const actionCreateTag = (tag) => ({
  type: Tags.CreateTag,
  payload: { tag },
});
export const actionAttachTagParentRedux = (
  tag,
  parent,
  type,
  lastModifiedDate,
) => ({
  type: Tags.actionAttachTagParentRedux,
  payload: { tag, parent, type, lastModifiedDate },
});
export const actionAttachTagParentAndCurrentPageRedux = (
  tag,
  parent,
  type,
  lastModifiedDate,
  isSelected,
) => ({
  type: Tags.attachTagParentAndCurrentPageRedux,
  payload: { tag, parent, type, lastModifiedDate, isSelected },
});
export const actionCheckTag = (
  tag,
  parent,
  type,
  isPage,
  isTemplate,
  isSelected,
) => ({
  type: Tags.CheckTag,
  payload: { tag, parent, type, isPage, isTemplate, isSelected },
});

export const actionCreateEmptyTag = (tagId) => ({
  type: Tags.createEmptyTag,
  payload: { tagId },
});

export const actionDeleteTag = (
  tag,
  index,
  type,
  parent,
  lastModifiedDate,
  isPage,
) => ({
  type: Tags.DeleteTag,
  payload: { tag, index, type, parent, lastModifiedDate, isPage },
});
export const actionDeleteTagFromSystem = (tagId, history) => ({
  type: Tags.DeleteTagFromSystem,
  payload: { tagId, history },
});
export const actionChangeTagTitle = ({
  id,
  newTitle,
  type,
  parent,
  libraryComponentId,
}) => ({
  type: Tags.ChangeTagTitle,
  payload: { id, newTitle, type, parent, libraryComponentId },
});
export const actionChangeTagColor = ({
  id,
  newColor,
  type,
  parent,
  newBackgroundColor,
  libraryComponentId,
}) => ({
  type: Tags.ChangeTagColor,
  payload: {
    id,
    newColor,
    type,
    parent,
    newBackgroundColor,
    libraryComponentId,
  },
});
export const actionMoveTag = (tag, newIndex, type, componentId, folderId) => ({
  type: Tags.MoveTag,
  payload: { tag, newIndex, type, componentId, folderId },
});
export const actionAddTagToCurrentPage = (tag, playlist) => ({
  type: Tags.AddTagToPlaylist,
  payload: { tag, playlist },
});

export const actionAddTagEmptyR = (tag) => ({
  type: Tags.AddTagEmptyR,
  payload: { tag },
});

export const _actionRefreshUserTags = (tags) => ({
  type: Tags._RefreshUserTags,
  payload: { tags },
});
export const actionRefreshUserTags = () => ({
  type: Tags.RefreshUserTags,
});
export const actionAttachMultipleTagsToLibraryComponent = (
  tags,
  libraryComponent,
  lastModifiedDate,
) => ({
  type: Tags.AttachMultipleTagsToLibraryComponent,
  payload: { tags, libraryComponent, lastModifiedDate },
});
export const actionReplaceTagsInLibraryComponent = (
  tags,
  libraryComponent,
  lastModifiedDate,
) => ({
  type: Tags.ReplaceTagsInLibraryComponent,
  payload: { tags, libraryComponent, lastModifiedDate },
});

export const actionUpdateTag = ({ id, color, backgroundColor, title }) => ({
  type: Tags.UpdateTag,
  payload: { id, color, backgroundColor, title },
});
export const actionUpdateTagRedux = ({
  id,
  color,
  backgroundColor,
  title,
}) => ({
  type: Tags.UpdateTagRedux,
  payload: { id, color, backgroundColor, title },
});
