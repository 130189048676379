import { UnifiedHistory } from './types';

export const actionAddNewPathToUnifiedHistory = (newPath) => ({
  type: UnifiedHistory.NativeHistoryAdd,
  payload: newPath,
});

export const actionSyntheticGoBack = () => ({
  type: UnifiedHistory.SyntheticGoBack,
});

export const actionSyntheticGoForward = () => ({
  type: UnifiedHistory.SyntheticGoForward,
});
export const actionClearHistory = () => ({
  type: UnifiedHistory.ClearHistory,
});

export const actionAddPayloadUnifyHistory = (payload) => ({
  type: UnifiedHistory.AddPayload,
  payload,
});
export const actionClearPayloadUnifyHistory = () => ({
  type: UnifiedHistory.ClearPayload,
});
