import styled from 'styled-components/macro';

export const DropdownSearch = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  height: 32px;
  margin: 12px 20px 2px;

  & svg {
    margin-right: 9px;
    margin-top: 1px;
    path {
      stroke: #484343;
    }
  }
  &:has(input:focus) {
    .cross_wrapper {
      visibility: visible;
    }
  }
`;

export const ClearIcon = styled.img`
  padding: 2px;
  cursor: pointer;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  position: absolute;
  right: 20px;

  &:hover {
    background: #ffeebf;
  }
`;

export const Input = styled.input`
  top: 2px;
  position: relative;
  background: none;
  border: 0;
  width: 100%;
  color: #484343;
  font-family: RobotoFlex;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  text-align: left;
  border-bottom: 2px solid transparent;
  &:focus {
    border-bottom: 2px solid #ffc72c;
  }
  &::placeholder {
    color: #484343;
    font-size: 16px;
    letter-spacing: -0.006em;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background: #1d1d1d;
  opacity: 0.08;
  margin: 8px 20px;
`;
