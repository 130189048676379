import { Hashtag } from './types';

export const actionDeleteHashtag = (hashtag, parent, isPage) => ({
  type: Hashtag.DeleteHashtag,
  payload: { hashtag, parent, isPage },
});
export const actionAddHashtagToPlaylist = (hashtag, parent, isPage) => ({
  type: Hashtag.AddHashtagPlaylist,
  payload: { hashtag, parent, isPage },
});
export const actionRemoveHashtagFromPlaylist = (hashtag, playlist) => ({
  type: Hashtag.RemoveHashtagPlaylist,
  payload: { hashtag, playlist },
});

export const actionRemoveHashtagFromPage = (hashtag, page) => ({
  type: Hashtag.RemoveHashtagPage,
  payload: { hashtag, page },
});
