import styled from 'styled-components/macro';

export const DescriptionS = styled.textarea`
  display: block;
  width: 100%;

  overflow: hidden;
  resize: none;
  border: none;
  background: none;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #585858;
  position: relative;
  top: 5px;
  left: 18px;

  text-overflow: ellipsis;

  margin-left: 2px;
  &::placeholder {
    color: rgba(122, 105, 105, 0.5);
  }
  ${({ withScroll }) => (withScroll
    ? `
    overflow-y:scroll;
    scrollbar-width: none;
    -ms-overflow-style: none; 
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
     `
    : '')}
    
    @media(max-width: 1050px) {
      height: 40px;
    }
`;
