import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { actionConfirmSubscribe } from '../../redux/channels/action';

const Confirm = () => {
  const { key } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { subscriptionStatus } = useSelector(
    (reduxState) => reduxState.channels,
  );
  const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
  const history = useHistory();
  useEffect(() => {
    const confirmUrl = history.location.pathname;
    dispatch(actionConfirmSubscribe(key, token, history, confirmUrl));
  }, []);

  if (!subscriptionStatus) {
    return (
      <div className="loader-wrapper-page">
        <div className="loader">{t('loadingT')}</div>
      </div>
    );
  }
  return (
    <>
      <div>
        <div
          style={{ color: 'blue', textDecoration: 'underline' }}
          onClick={() => history.push('/channels')}
        >
          {t('goToChannelsLowT')}
        </div>
      </div>
    </>
  );
};

export default Confirm;
