import React from 'react';
import styles from './MediaAdd.module.css';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import MediaAddPopup from './MediaAddPopup';
import { BlockTypes } from '../../utils/constants';

const MediaAdd = ({ type, ...props }) => {
  const [ref, isComponentVisible, setIsComponentVisible] = useComponentVisible(false);
  const addImage = (url, dur) => {
    const { editorState, onChange, setLocalState } = props;
    if (type === BlockTypes.image) {
      onChange(props.modifier(editorState, url));
    } else {
      setLocalState(props.modifier(editorState, { src: url }));
      onChange(props.modifier(editorState, { src: url }), null, dur);
    }
  };

  return (
    <div className={styles.addImage}>
      <div
        className={
          isComponentVisible
            ? styles.addImagePressedButton
            : styles.addImageButton
        }
        onClick={() => setIsComponentVisible(true)}
      >
        Add a video
      </div>
      <MediaAddPopup
        refPopup={ref}
        type="image"
        addImage={(url, dur) => {
          addImage(url, dur);
          setIsComponentVisible(false);
        }}
        isComponentVisible={isComponentVisible}
      />
    </div>
  );
};

export default MediaAdd;
