import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { ReactComponent as DropDownSvg } from '../../images/icons/drop-down.svg';
import searchIcon from '../../images/icons/search_folder_16.svg';
import styles from './index.module.scss';
import { ReactComponent as AlertSvg } from '../../images/icons/alert.svg';
import clearSearch, {
  ReactComponent as CloseSvg,
} from '../../images/icons/clear_search.svg';

import { ReactComponent as PlusSvg } from '../../images/icons/icon_20/plus_20.svg';
import { ReactComponent as SearchSvg } from '../../images/2023/svg/Search Icon.svg';
import { Inputs } from './PlaylistInput';
import { ReactComponent as CloseSmallSvg } from '../../images/icons/close_7.svg';
import { actionCreateChannel } from '../../redux/channels/action';
import ChannelInChannelsSelector from '../../components/ChannelInChannelSelector/ChannelInChannelSelector';
import { ClearIcon, DropdownSearch, Divider, Input } from './styled';

const cx = classNames.bind(styles);

const DropDown = ({
  type,
  isSettings,
  isNeedToWarning,
  placeholder,
  used,
  usedOld,
  changeLocalUsedValue,
  isPublishPlaylist,
}) => {
  const { t } = useTranslation();
  const dataParent = 'dropDown';
  const [refMenu, isMenuVisible, setIsMenuVisible] = useComponentVisible(
    false,
    dataParent,
  );
  const { myChannels } = useSelector((state) => state.channels);
  const dispatch = useDispatch();

  const selectListData = (localType, filter) => {
    return Object.values(myChannels)
      .filter(
        (item) => (item?.permissions?.canPostContent || item.isNew)
          && item.name.toLowerCase().includes(filter.toLowerCase()),
      )
      .sort((a, b) => a.position - b.position);
  };

  const [filter, setFilter] = useState('');
  const [dropDownList, setDropDownList] = useState(
    selectListData(type, filter),
  );

  useEffect(() => {
    setDropDownList(selectListData(type, filter));
  }, [filter, myChannels]);

  const createNewChannel = () => {
    dispatch(actionCreateChannel());
  };

  const deleteTagItem = (e, item) => {
    e.stopPropagation();
    changeLocalUsedValue(item);
  };

  if (isPublishPlaylist) {
    return (
      <div className={cx('dropdown_field', 'channel')} ref={refMenu}>
        <div
          onClick={() => {
            if (isMenuVisible === true) setIsMenuVisible(false);
            if (isMenuVisible === false || isMenuVisible === 'init') setIsMenuVisible(true);
          }}
          className={cx('input', 'no_edit', 'channelInput', {
            settings: isSettings,
            isNeedToWarning,
            placeholder: !Object.values(used || {}).length,
            channel: type === 'channel',
          })}
        >
          {!Object.values(used || {}).length && placeholder}

          {used
            && !!Object.values(used || {}).length
            && Object.values(used || {}).map((item) => (
              <div key={item.id} className={cx('hashtag')}>
                <div className={cx('text')}>
                  {item.name || t('unnamedChannelT')}
                </div>
                <div
                  className={cx('icon')}
                  onClick={(e) => deleteTagItem(e, item)}
                >
                  <CloseSmallSvg />
                </div>
              </div>
            ))}

          <DropDownSvg className={cx('no_edit_svg')} />
        </div>
        {isMenuVisible && isMenuVisible !== 'init' && (
          <div className={cx('dropdown_wrapper')}>
            <div className={cx('dropdown', 'publishPlaylist')}>
              {!!Inputs[type].search && (
                <DropdownSearch>
                  <SearchSvg />
                  <Input
                    value={filter}
                    placeholder={t('searchDotsPlaceholderT')}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  {!!filter && (
                    <ClearIcon
                      src={clearSearch}
                      onClick={() => setFilter('')}
                      alt="clear"
                    />
                  )}
                </DropdownSearch>
              )}
              {!Inputs[type].search && (
                <input autoFocus className={cx('invisible')} />
              )}
              {!filter && (
                <div
                  className={cx('dropdown_row_wrapper')}
                  onClick={createNewChannel}
                >
                  <div className={cx('dropdown_icon')}>
                    <PlusSvg />
                  </div>
                  <div
                    data-cy="create-channel"
                    className={cx('dropdown_row', 'channel_row')}
                    data-parent={dataParent}
                  >
                    {t('createNewChannelT')}
                  </div>
                </div>
              )}
              <Divider />
              <div className={cx('dropdown_result', 'channel_result')}>
                {!!dropDownList?.length
                  && dropDownList?.map((item) => (
                    <ChannelInChannelsSelector
                      key={item.id}
                      used={used}
                      usedOld={usedOld}
                      changeLocalUsedValue={changeLocalUsedValue}
                      item={item}
                      isPublishPlaylist={isPublishPlaylist}
                    />
                  ))}
              </div>
              {!dropDownList?.length && (
                <div
                  className={cx('dropdown_row', 'channel_row', 'no_results')}
                  data-parent={dataParent}
                >
                  {t('noSearchResultsT')}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={cx('dropdown_field', 'channel')} ref={refMenu}>
      <div
        onClick={() => {
          if (isMenuVisible === true) setIsMenuVisible(false);
          if (isMenuVisible === false || isMenuVisible === 'init') setIsMenuVisible(true);
        }}
        className={cx('input', 'no_edit', 'channelInput', {
          settings: isSettings,
          isNeedToWarning,
          placeholder: !Object.values(used || {}).length,
          channel: type === 'channel',
        })}
      >
        {!Object.values(used || {}).length && placeholder}

        {used
          && !!Object.values(used || {}).length
          && Object.values(used || {}).map((item) => (
            <div key={item.id} className={cx('hashtag')}>
              <div className={cx('text')}>{item.name || t('unnamedChannelT')}</div>
              <div
                className={cx('icon')}
                onClick={(e) => deleteTagItem(e, item)}
              >
                <CloseSmallSvg />
              </div>
            </div>
          ))}

        <DropDownSvg className={cx('no_edit_svg')} />
      </div>
      {isMenuVisible && isMenuVisible !== 'init' && (
        <div className={cx('dropdown_wrapper')}>
          <div className={cx('dropdown')}>
            {!!Inputs[type].search && (
              <div className={cx('dropdown_search')}>
                <div className={cx('search_icon_input')}>
                  <img
                    src={searchIcon}
                    alt="search"
                    className={cx('dropdown_search_icon')}
                  />
                  <input
                    autoFocus
                    value={filter}
                    placeholder={t('search')}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </div>

                <div
                  className={cx('dropdown_close_icon')}
                  onClick={() => {
                    setFilter('');
                  }}
                >
                  {filter && <CloseSvg />}
                </div>
              </div>
            )}
            {!Inputs[type].search && (
              <input autoFocus className={cx('invisible')} />
            )}
            {!filter && (
              <div
                className={cx('dropdown_row_wrapper')}
                onClick={createNewChannel}
              >
                <PlusSvg />
                <div
                  data-cy="create-channel"
                  className={cx('dropdown_row')}
                  data-parent={dataParent}
                >
                  {t('createNewChannelT')}
                </div>
              </div>
            )}
            <div className={cx('dropdown_result')}>
              {!!dropDownList?.length
                && dropDownList?.map((item) => (
                  <ChannelInChannelsSelector
                    used={used}
                    changeLocalUsedValue={changeLocalUsedValue}
                    item={item}
                  />
                ))}
            </div>
            {!dropDownList?.length && (
              <div className={cx('dropdown_row')} data-parent={dataParent}>
                {t('noSearchResultsT')}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const NewChannelsInput = ({
  state,
  isPage,
  setUsed,
  usedOld,
  used,
  isNeedToWarning,
  setIsNeedToWarning,
  isPublishPlaylist,
}) => {
  const { t } = useTranslation();
  const { anchorId } = useSelector((reduxState) => reduxState.support);
  const { id } = useSelector((reduxState) => reduxState.currentPage);

  const tryPublish = useSelector(
    (reduxState) => reduxState.currentPage.tryPublish,
  );
  const [rawValue, setRawValue] = useState('');

  const changeLocalUsedValue = (newItem) => {
    if (usedOld[newItem.id]) return;

    if (used[newItem.id]) {
      const tmp = { ...used };
      delete tmp[newItem.id];
      setUsed(tmp);
    } else {
      setUsed({ ...used, [newItem.id]: newItem });
    }
  };

  useEffect(() => {
    setIsNeedToWarning(
      tryPublish && !Object.values(used || {}).length && state.required,
    );
  }, [used]);

  useEffect(() => {
    if (
      anchorId === 'playlistChannelInput'
      && !Object.values(used || {}).length
    ) {
      setIsNeedToWarning(anchorId === 'playlistChannelInput');
    }
  }, [anchorId]);

  return (
    <div className={cx('input_wrapper', { sideBarWidth: state.settingsBar })}>
      <div
        className={cx('input_group_description', {
          required: state.required,
          settings: state.settingsBar,
          publishPlaylistDescription: isPublishPlaylist,
        })}
      >
        {state.description}
      </div>
      <div className={cx('note')}>{state.note}</div>
      <div>
        {!!state.list && (
          <DropDown
            isPage={isPage}
            used={used}
            usedOld={usedOld}
            // isAddChannel={isAddChannel}
            type={state.type}
            isNeedToWarning={isNeedToWarning}
            isSettings={state.settingsBar}
            value={rawValue}
            setValue={setRawValue}
            placeholder={state.placeholder}
            playlistId={id}
            changeLocalUsedValue={changeLocalUsedValue}
            isPublishPlaylist={isPublishPlaylist}
          />
        )}
        {isNeedToWarning && (
          <div className={cx('warning_wrapper')}>
            <div className={cx('warning')}>
              {' '}
              <AlertSvg />
              {state.required} {t('isaRequiredFieldT')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewChannelsInput;
