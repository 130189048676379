import { DynamicCollection } from './types';

export const actionRenameDynamicCollection = (dynamicCollectionId, name) => ({
  type: DynamicCollection.renameDC,
  payload: { dynamicCollectionId, name },
});

export const actionAddDynamicCollection = (dynamicCollection, callback) => {
  return {
    type: DynamicCollection.addDC,
    payload: { dynamicCollection, callback },
  };
};

export const actionChangeDynamicCollection = (dynamicCollection) => {
  return {
    type: DynamicCollection.changeDC,
    payload: dynamicCollection,
  };
};
export const actionMoveDynamicCollection = (dynamicCollection, newIndex) => {
  return {
    type: DynamicCollection.moveDC,
    payload: { dynamicCollection, newIndex },
  };
};
export const actionMoveDynamicCollectionR = (dynamicCollection) => {
  return {
    type: DynamicCollection.moveDC_R,
    payload: dynamicCollection,
  };
};

export const actionDeleteDynamicCollection = (
  dynamicCollectionId,
  pathname,
  isLibraryCallback,
) => {
  return {
    type: DynamicCollection.deleteDC,
    payload: { dynamicCollectionId, pathname, isLibraryCallback },
  };
};

export const actionDuplicateDynamicCollection = (dynamicCollectionId) => {
  return {
    type: DynamicCollection.duplicateDC,
    payload: { dynamicCollectionId },
  };
};

export const actionEditDynamicCollection = (dynamicCollection) => {
  return {
    type: DynamicCollection.editDC,
    payload: { ...dynamicCollection },
  };
};
