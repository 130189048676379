import styled from 'styled-components/macro';

export const PlayerBottomWrapper = styled.div`
  height: 100px;
  width: 100vw;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  transition:  ease-in-out 0.5s;

  ${({ isMouseMoveShowControls }) => (
    isMouseMoveShowControls
      ? 'opacity: 1;' : `   
      bottom:-12px;
      opacity: 0`)}
`;
export const BotShadow = styled.div`
  width: 100%;
  ::before {
    pointer-events: none;
    z-index: -1;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vW;
    height: 390px;
    background: linear-gradient(to bottom, rgba(72, 67, 67, 0.00) 0%, rgba(255, 255, 255, 0.0) 0.01%, rgba(0 0 0 / 37%) 140%);
  }
`;

export const TopShadow = styled.div`
  width: 100%;
  position: absolute;

  ::before {
    pointer-events: none;
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vW;
    height: 200px;
    background: linear-gradient(to top,rgba(72,67,67,0.00) 0%,rgba(255,255,255,0.0) 0.01%,rgba(0 0 0 / 37%) 140%)
  }
`;

export const PBControlsWrapper = styled.div`
  height: 77px;
  padding-top: 4px;
`;

export const PBProgressStripeWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 0 35px;
`;

export const PBProgressStripe = styled.div`
  ${({ width }) => `width:${width}px;`}
  height: 4px;
  border-radius: 2px;
  background: ${({ filled }) => (filled ? '#FFC72C' : '#FFF')};;
  cursor: pointer;
  position: relative;

  &:hover {
    .player_see_preview {
      display: block;
    }
  }
`;

export const PBRightConnerContainer = styled.div`
  position: absolute;
  right: 34px;
  bottom: 48px;
  width: 90px;
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;

    path {
      stroke: white;
    }

    &:hover {
      path {
        stroke: #FFEEBF;
      }
    }
  }
`;

export const PlayerHeaderWrapper = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  transition:  ease-in-out 0.5s;

  width: 100vw;
  z-index: 10;
  ${({ isMouseMoveShowControls }) => (isMouseMoveShowControls
    ? 'opacity: 1;' : `   
      top:-12px;
      opacity: 0`)}
`;

export const PHTitle = styled.div`
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  letter-spacing: 1px;
  margin-top: 8px;
`;

export const PHActions = styled.div`
  position: absolute;
  width: 153px;
  display: flex;
  justify-content: space-between;
  right: 37px;
  top: 15px;

  .locked_svg {
    top: -3px;
    cursor: not-allowed;
  }

  .download_svg {
    top: -1px;
    left: 2px;
  }
`;

export const PHActionsSVGWrapper = styled.div`
  svg {
    position: relative;
  }

  ${({ isBlocked }) => (isBlocked ? `
    svg{
    cursor: not-allowed;
    opacity: 0.6;
  }` : `
    svg{
    cursor: pointer;
    &:hover{
      path{
        stroke:#FFEEBF;
      }
    }
  }
  `)}
`;

export const PHLeftSide = styled.div`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.8px;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;

  top: 9px;
  ${({ isFirst }) => (isFirst ? '  left:35px;' : 'left:136px;')}
`;

export const MainPlayerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ${({ isFullScreenMode }) => (isFullScreenMode ? `
      background-color: #242633;
  ` : '')}
  &::-webkit-scrollbar {
    display: none;
  }

 
  
  
  
  
`;

export const PlayerZoomWrapper = styled.div`
  width: 193px;
  position: absolute;
  left: 47px;
  top: 33px;
  display: flex;
  justify-content: space-between;

  svg {
    &:hover {
      cursor: pointer;

      rect {
        stroke: #FFEEBF;
      }

      path {
        stroke: #FFEEBF;
        fill: #FFEEBF;

      }
    }
  }
`;

export const InnerZoomContainer = styled.div`
  width: 114px;
  display: flex;
  justify-content: space-between;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 14px; /* 100% */
  letter-spacing: 1.4px;
  text-transform: uppercase;

  div {
    cursor: default;
  }
`;

export const FitWrapper = styled.div`
  position: relative;
  bottom: 6px;
 
  ${({ isDefault }) => (isDefault
    ? `
     & svg {
    width: 35px;
    height: auto;
    margin-bottom: 2px;
  }
  `
    : '')}
`;

export const CPSVGWrapper = styled.div`
  position: absolute;
  cursor: pointer;

  top: 4px;
  left: 50px;

`;

export const PlayBTNWrapper = styled.div`
  width: 158px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  position: relative;

  svg {
    cursor: pointer;
  }

  .arrows {
    margin-top: 20px;
    width: 23px;
    height: 23px;

    path, rect {
      fill: #FFFFFF;
      stroke: #FFFFFF;
      fill-opacity: 1;
    }
    :hover{
        path, rect {
          fill: #FFEEBF;
          stroke: #FFEEBF;
      }
  }

`;

export const ImageAnchor = styled.div`
  position: absolute;
  bottom: 17px;
  display: none;
  flex-direction: column;
  align-items: center;
  width: 154px;
  height: 82px;
  border: 2px solid white;
  border-radius: 8px;
  justify-content: center;
  overflow: hidden;
  background-color: #ffffff;
`;

export const SettingsPopupWrapper = styled.div`
  width: 219px;
  height: 84px;
  padding: 12px 0;
  background-color: #FFFFFF;
  position: absolute;
  left: -127px;
  top: -98px;
  border-radius:12px;
  border: 0.6px solid #ECE2E2;
`;

export const SPItem = styled.div`
  width: 218px;
  position: relative;
  height: 30px;
  color: #484343;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  padding: 5px 12px 5px 16px;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: #FFEEBF;
  }
  svg{
    position: absolute;
    right: 13px;
    top: 9px;
   path{
     stroke:#484343;
   }
  }
`;

export const CaptionsWrapper = styled.div`
  position: absolute;
  width: 100vw;
  ${({ areControlsHidden }) => (areControlsHidden ? 'bottom:40px;' : 'bottom:130px;')}
`;

export const CaptionsVisibleContainer = styled.div`
  position: relative;
  max-width: 42vw;
  padding: 18px 20px;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: auto;
  z-index: 1;
  width: max-content;
  svg{
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
  }
  ${({ isFullScreenMode }) => (isFullScreenMode ? `
      background: rgba(255, 255, 255, 0.60);
      color: rgba(36, 38, 51);
      svg{
      path{
       stroke:rgba(36, 38, 51);
      }
     }
  ` : `
      background: rgba(36, 38, 51, 0.60);
      color: var(--uiui-9, #FFF);
  `)}
`;

export const ApprovalsWrapper = styled.div`
  position: absolute;
  width: max-content;
  top: 85px;
  right: 109px;
`;

export const SettingsSVGWrapper = styled.div`
  ${({ isActive }) => (isActive ? `
    svg {
      rect {
        stroke: #FFEEBF;
      }
      path {
        stroke: #FFEEBF;
  }
  ` : '')}

`;
