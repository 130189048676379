import React, { useEffect, useRef, useState } from 'react';
import { ABDividerStripe, ABExternalWrapper, ABPartitialWrapper } from './styled';
import { ReactComponent as CheckIcon } from '../../images/2023/svg/small/approval-buttons/check_ico_14_12.svg';
import AbPopup from './ABPopup';
import { NewUPVViewModes } from '../../utils/constants';
import { ApprovalBTNColorEnum } from './color-enums';

interface MakerApproveButtonProps {
  color: keyof typeof ApprovalBTNColorEnum; // Можно заменить на ключ из enum, если есть соответствующий enum
  isDisabled: boolean;
  defaultName: string;
  clickedName: string;
  toggleState: (e: React.MouseEvent<HTMLDivElement>, state: boolean) => void;
  isClicked: boolean;
  editCB: () => void;
  deleteCB: () => void;
  viewMode: keyof typeof NewUPVViewModes;
}

const MakerApproveButton: React.FC<MakerApproveButtonProps> = ({
  color,
  isDisabled,
  defaultName,
  clickedName,
  toggleState,
  isClicked,
  editCB,
  deleteCB,
  viewMode,
}) => {
  const [secondBlockWidth, setSecondBlockWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (viewMode !== NewUPVViewModes.list) {
      return;
    }
    if (ref.current && ref2.current) {
      if (ref2.current.clientWidth + ref.current.clientWidth > 250) {
        setSecondBlockWidth(250 - ref.current.clientWidth);
      }
    }
  }, [ref, ref2, viewMode]);

  return (
    <ABExternalWrapper>
      {(viewMode !== NewUPVViewModes.list && viewMode !== NewUPVViewModes.player) && <ABDividerStripe viewMode={viewMode} />}
      <ABPartitialWrapper
        ref={ref}
        onClick={(e) => toggleState(e, true)}
        isDisabled={isDisabled}
        color={color}
        theFirst
        isChosen={isClicked === true}
        viewMode={viewMode}
      >
        {isDisabled && viewMode !== NewUPVViewModes.player && viewMode !== NewUPVViewModes.list && (
          <AbPopup
            editCB={editCB}
            deleteCB={deleteCB}
          />
        )}
        {defaultName}{isClicked === true && <CheckIcon />}
      </ABPartitialWrapper>
      <ABPartitialWrapper
        ref={ref2}
        width={secondBlockWidth}
        onClick={(e) => toggleState(e, false)}
        isDisabled={isDisabled}
        color={color}
        isChosen={isClicked === false}
        viewMode={viewMode}
      >
        {clickedName} {isClicked === false && <CheckIcon />}
      </ABPartitialWrapper>
    </ABExternalWrapper>
  );
};

export default MakerApproveButton;
