import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import page from '../../images/icons/page.svg';
import { actionCreator } from '../../shared/redux/actionHelper';
import SupportAction from '../../redux/support/types';

const AddPageModal = ({ selectAddEditableBlock, refAddPage }) => {
  const components = useSelector((state) => state.support.tempContent);
  const [content, setContent] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actionCreator(SupportAction.GetPagesForLinkOption));
    return () => {
      dispatch(actionCreator(SupportAction.EraseTempComponents));
    };
  }, []);

  useEffect(() => {
    const mapItems = Object.values({ ...components }).reduce((acc, item) => {
      if (item.movedToTrash) return acc;
      acc.push(item);
      return acc;
    }, []);

    setContent(mapItems);
  }, [components]);

  return (
    <div ref={refAddPage} className={styles.dropdown}>
      <PerfectScrollbar>
        <div className={styles.dropdown_block}>
          {!!content.length
            && content.map((item) => (
              <div
                onClick={() => {
                  selectAddEditableBlock(item);
                }}
                className={styles.dropdown__item}
                key={item.id}
              >
                <img
                  className={styles.dropdown__item_image}
                  src={page}
                  alt="page"
                />
                <div> {item.title} </div>
              </div>
            ))}
          {!Object.keys(content).length && <div>{t('noPagesT')}</div>}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default AddPageModal;
