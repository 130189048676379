import React from 'react';
import { ReactComponent as UploadErrorSvg } from '../../../images/icons/upload_error_20.svg';
import { ReactComponent as CheckSvg } from '../../../images/icons/upload_check_20.svg';
import styles from '../../../components/LibraryMiniPage/index.module.css';

export const Circle = ({ color, progress, radius = 29, stroke = 1 }) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  return (
    <svg
      height={radius * 2}
      width={radius * 2}
      style={{ position: 'absolute' }}
    >
      <circle
        stroke={color}
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
};

export const progressBarStatus = (progress = 0, status = {}, text) => {
  if (status.error) return <UploadErrorSvg />;
  if (progress === 1) {
    if (status.ready) {
      return <CheckSvg />;
    }
    return (
      <div className={styles.circle_loader_wrapper}>
        <div className={styles.circle_loader} />
        {!!text && <div className={styles.circle_loader_text}>{text}</div>}
      </div>
    );
  }
  if (progress === 0) {
    return (
      <div className={styles.circle_loader_wrapper}>
        <div className={styles.circle_loader} />
      </div>
    );
  }
  return <Circle progress={(progress || 0) * 100} color="#0026E5" />;
};
