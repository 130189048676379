import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useHistory, useParams } from 'react-router-dom';
import rawStyles from './view.module.scss';
import { Page } from './Page';
import { actionGetCurrentPage } from '../../redux/currentPage/action';

import ViewPage from '../ViewPage';

const cn = classNames.bind(rawStyles);

const ViewPageFromBuilder = ({}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { owner } = useSelector((state) => state.currentPage);
  const userId = useSelector((state) => state.user.id);
  const history = useHistory();

  useEffect(() => {
    dispatch(actionGetCurrentPage(id, owner?.id !== userId, history));
  }, [id]);

  return (
    <div className={cn('view_page_wrapper')}>
      <div
        className={cn('view_page_content', { view_mode: owner?.id !== userId })}
      >
        {owner?.id === userId && (
          <Page isInBuilder isViewMode={owner?.id !== userId} />
        )}
        {owner?.id !== userId && <ViewPage isInBuilder isViewMode />}
      </div>
    </div>
  );
};

export default ViewPageFromBuilder;
