import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SignatureCardView } from './SignatureCardView';
import CardOverlay from './CardOverlay';
import { ReactComponent as Favorite2Svg } from '../../../images/2023/svg/small/favorite_16_16.svg';
import ThreeDotsDropDown from './ThreeDotsDropDown';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import {
  CardViewWrapper,
  FavouriteIconWrapper,
  ImageWrapper,
  TitleWrapper,
  SmartfileItemsCounter, Bubble, BubbleUpdate,
} from './styled';
import { empty, DATA_PARENTS } from '../../../utils/constants';
import useThrottle from '../../../utils/hooks/useThrottle';
import { Spinner } from '../NewPlaylist/CardView/StyledComponents';
import { Circle } from '../../../utils/UIHelpers/PlaylistBuilderElements/libraryComponent';
import { actionSaveComponentDescriptionData } from '../../../redux/support/action';
import ProgressBar from '../../ViewersProgressBars';
import { progressBarType } from '../../ViewersProgressBars/enum';

import FolderImage from '../../../pages/Maker/FolderImage';
import FolderIconLayout from '../../../pages/Maker/FolderIconLayout';

const ImageWrap = styled.img`
  ${({ isHide }) => (isHide
    ? `visibility: hidden;
      height: 0;
      width: 0;
    ` : `
      width: 140px;
      height: 140px;
      object-fit: cover;
      box-shadow: 0px 2px 2px rgba(120, 120, 120, 0.25);
    `)};

  ${({ absolute }) => (absolute
    ? `position: absolute;
    z-index:-1;`
    : '')};
`;


let interval;

const CardView = ({ item, refItem, isDescriptionOpen, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    componentDescription,
    unseenPlaylistMapInChannel = {},
    unseenPlaylistManagerMapInAll = {},
  } = useSelector((state) => state.support);
  const { id: metaSideBarId } = useSelector(
    (reduxState) => reduxState.support.componentDescriptionData || empty,
  );
  const isShown = useSelector((state) => state.support.componentDescription);
  const user = useSelector((state) => state.user);
  const selectedPage = useSelector((state) => state.selectedPage) || empty;
  const { folderId } = useSelector((state) => state.user) || empty;
  const [refMenu, isDropDownVisible, setIsDropDownVisible] = useComponentVisible(null);

  const [cursorLoader, setCursorLoader] = useState(0);
  const [throttleSaveValue] = useThrottle(1000);
  const isHelp = history.location.pathname.startsWith('/help');

  const isChannel = history.location.pathname.startsWith('/channel');
  const isOwner = item?.owner?.id === user?.id;

  const isUnseen = isChannel
    ? unseenPlaylistMapInChannel[item.playlistManagerId]
    : item.wrapperId && unseenPlaylistManagerMapInAll[item.wrapperId];

  const hasUpdate = item.hasUpdate;

  const [count, setCount] = useState(0);
  const loaderRef = useRef(null);
  const cardRef = useRef(null);

  const isMetaAffected = !!metaSideBarId && metaSideBarId === item.id;
  const coverImageSrc = item.img;
  const isPublished = item?.status === 'publish';

  const isSelected = folderId
    ? selectedPage[folderId] && selectedPage[folderId][item.id]
    : selectedPage[item.id];

  const showPlaylistInfo = () => {
    dispatch(actionSaveComponentDescriptionData(item.id, '', item.status));
  };

  const toggleShowThreeDots = (e) => {
    if (isHelp) return;
    e.stopPropagation();
    e.preventDefault();
    setIsDropDownVisible(!isDropDownVisible);
  };

  useEffect(() => {
    if (!cursorLoader) {
      if (count) setCount(0);
      return;
    }
    if (isShown) {
      interval = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 10);
    }

    return () => clearInterval(interval);
  }, [cursorLoader]);

  const onMouseEnterHandler = (event) => {
    if (!metaSideBarId || isMetaAffected || !isShown) return;
    setCursorLoader(true);
    throttleSaveValue(() => () => {
      showPlaylistInfo(event);
      setCursorLoader(false);
    });
  };

  const dragStartHandler = useCallback((e) => {
    props.dragStartHandler(e, item);
  }, [item]);

  const dragEndHandler = useCallback((e) => {
    props.dragEndHandler(e, item);
  }, [item]);

  const width = document.body.clientWidth;
  const height = document.body.clientHeight;
  const cardPosition = cardRef.current?.getBoundingClientRect();
  const couldBeCutRight = cardPosition?.right && ((width - cardPosition.right) < 161);
  const couldBeCutBottom = cardPosition?.top && ((height - cardPosition.top) < 430);
  const couldBeCutTop = cardPosition?.top && ((cardPosition.top) < 535);
  return (
    <>
      <CardViewWrapper
        draggable={item.type !== 'sharedPage'}
        onDragEnd={dragEndHandler}
        onDragStartCapture={dragStartHandler}
        data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
        isFrontLayer={isDropDownVisible}
        onContextMenu={toggleShowThreeDots}
        ref={refItem ?? cardRef}
        hoveredByMeta={cursorLoader || (isMetaAffected && componentDescription)}
        isHide={item.isHide}
        isDragging={props.libraryDragState?.[item?.id]}
        selected={isSelected}
        isDescriptionOpen={isDescriptionOpen}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={() => {
          if (!metaSideBarId) return;
          setCursorLoader(false);
          throttleSaveValue(() => () => {});
        }}
      >
        {!isMetaAffected && (
          <Spinner ref={loaderRef} cursorLoader={cursorLoader}>
            <Circle
              progress={count || 0}
              color="#FFC72C"
              radius={25}
              stroke={5}
            />
          </Spinner>
        )}
        {isDropDownVisible && (
          <ThreeDotsDropDown
            setIsDropDownVisible={setIsDropDownVisible}
            userRole={props.userRole}
            itemID={item.id}
            itemType={item.type}
            itemTitle={item.title}
            status={item.status}
            itemIsFavorite={item.isFavorite}
            itemWrapperID={item.wrapperId}
            refMenu={refMenu}
            show={isDropDownVisible}
            sharedID={item.sharedID}
            isPublished={isPublished}
            isOwner={isOwner}
            isCanBeOpen={!!item.contentCounter}
            inChannels={item.usedInChannels}
            couldBeCutRight={couldBeCutRight}
            couldBeCutBottom={couldBeCutBottom}
            couldBeCutTop={couldBeCutTop}
          />
        )}
        {item.isFavorite && (
          <FavouriteIconWrapper>
            <Favorite2Svg />
          </FavouriteIconWrapper>
        )}
        <CardOverlay
          index={props.index}
          item={item}
          isSimplifiedMultiselect={props.isSimplifiedMultiselect}
          setSimplifiedMultiselect={props.setSimplifiedMultiselect}
          toggleShowThreeDots={toggleShowThreeDots}
          showThreeDotsDropDown={isDropDownVisible}
        />
        <ImageWrapper>
          {isUnseen && <Bubble>{t('newUpT')}</Bubble>}
          {hasUpdate && !isUnseen && <BubbleUpdate>{t('updateUpT')}</BubbleUpdate>}
          {/* TODO. cannot get why we need 3 pictures (2of them are in html ethernally) */}
          {coverImageSrc ? (
            <>
              <ImageWrap src={coverImageSrc} absolute />
              <FolderIconLayout isSelected={isSelected} id="templateSvg" isOverCoverImage />
            </>
          ) : (
            <FolderImage itemId={item.id} currentColor={item.defaultPreviewColor ?? 'yellow'} />
          )}
          <SmartfileItemsCounter>
            {item.linkPagesCounter}
          </SmartfileItemsCounter>
          {/* <ImageWrap isHide={stopGif && newImageGifStopped} src={coverImageSrc} alt="" /> */}
          {/* {(stopGif && newImageGifStopped) && <ImageWrap src={newImageGifStopped} alt="" />} */}
          {/* <ImageWrap crossOrigin="anonymous" onLoad={onLoadHandler} ref={gifRef} src={coverImageSrc} alt="" /> */}
        </ImageWrapper>
      </CardViewWrapper>
      <TitleWrapper>{item.title || t('untitledSmartFileT')}</TitleWrapper>
      <SignatureCardView item={item} />
      {item.viewersProgress
        && (
          <ProgressBar
            type={progressBarType.card}
            viewersProgress={item.viewersProgress}
            isFinished={item.isFinished}
          />
        )
      }
    </>
  );
};

export default CardView;
