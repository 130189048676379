import React from 'react';
import { progressBarType } from './enum';
import CardView from './CardView';
import ListView from './ListView';
import Maker from './Maker';
import Player from './Player';


const ProgressBar = ({ type, isFinished, wrapperId, viewersProgress, forCutHeader }) => {
  return (
    <>
      {type === progressBarType.card && <CardView isFinished={isFinished} viewersProgress={viewersProgress} />}
      {type === progressBarType.list && <ListView isFinished={isFinished} viewersProgress={viewersProgress} />}
      {type === progressBarType.player && <Player isFinished={isFinished} wrapperId={wrapperId} />}
      {type === progressBarType.maker && (
        <Maker
          isFinished={isFinished}
          wrapperId={wrapperId}
          forCutHeader={forCutHeader}
        />
      )}
    </>
  );
};

export default ProgressBar;
