import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { CheckoutForm } from '../CheckoutForm';
import { STRIPE_PUBLISHABLE_KEY } from '../../environments/environmentsStripe';
import { actionCloseModal } from '../../redux/user/action';
import styles from './index.module.scss';

const cx = classNames.bind(styles);


const options = {
  appearance: {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      fontSizeSm: '12px',
    },
  },
};

const Checkout = () => {
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  const dispatch = useDispatch();
  const { t }= useTranslation();
  const {
    dataPayload: { plan },
  } = useSelector((redux) => redux.user);
  const { clientSecret } = useSelector((state) => state.user);

  const close = () => {
    dispatch(actionCloseModal());
  };

  return (
    <div onClick={close} className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'CheckoutModal')}
      >
        <div className={cx('title_wrapper')}>
          <div className={cx('title')}>{t('checkoutT')}</div>
        </div>
        <div className={cx('CheckoutModalElementWrapper')}>
          {clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret, ...options }}>
              <CheckoutForm plan={plan} />
            </Elements>
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkout;
