import React from 'react';
import SortAndView from '../SortAndView';
import { GroupIcon } from './StyledComponents';

const SecondaryPlaylist = ({ ...props }) => (
  <GroupIcon>
    <SortAndView {...props} />
  </GroupIcon>
);


export default SecondaryPlaylist;
