import React from 'react';
import classNames from 'classnames/bind';
import rawStyles from './index.module.scss';

const cn = classNames.bind(rawStyles);

export const GreyButton = ({
  onClick,
  text = 'Close',
  dataParent = 'CloseButton',
  isActive = false,
  customStyles = {},
  parentRef = null,
  primaryGrey = false,
  secondaryGrey = false,
  disabled = false,
}) => {
  return (
    <div
      ref={parentRef}
      className={cn('button', 'grey', { active: isActive, primaryGrey, secondaryGrey, disabled })}
      style={customStyles}
      onClick={!disabled ? onClick : null}
      data-parent={dataParent}
    >
      <div className={cn('text')} data-parent={dataParent}>
        {text}
      </div>
    </div>
  );
};

export const BlueButton = ({
  onClick,
  text = 'Save',
  dataParent = 'SaveButton',
  customStyles = {},
  parentRef = null,
  secondaryGrey,
}) => {
  return (
    <div
      ref={parentRef}
      className={cn('button', 'blue', { secondaryGrey })}
      style={customStyles}
      onClick={onClick}
      data-parent={dataParent}
    >
      <div className={cn('text')} data-parent={dataParent}>
        {text}
      </div>
    </div>
  );
};
