import { UPV } from './types';
import { NewUPVViewModes } from '../../utils/constants';

const defaultState = {
  viewMode: NewUPVViewModes.compressed,
};
export default (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case UPV.changeViewMode: {
      return {
        ...state,
        viewMode: payload,
      };
    }
    default: {
      return state;
    }
  }
};
