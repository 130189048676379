import React, { useEffect, useState } from 'react';
import { timeSinceLastUpdate } from './timeSinceLastUpdate';


export const LastUpdateDateDisplay = React.memo(function InboxCardBody({ date }) {
  const [currentTime, setCurrentTime] = useState(timeSinceLastUpdate((date * 1000)));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(timeSinceLastUpdate((date * 1000)));
    }, 20000);

    return () => {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    setCurrentTime(timeSinceLastUpdate((date * 1000)));
  }, [date]);

  return (
    <> {currentTime}</>
  );
});

export default LastUpdateDateDisplay;
