import styled from 'styled-components/macro';

export const PlaylistMainHeaderShell = styled.div`
  height: 67px;
  width: 100%;
  background: #f6f6f5;
  display: flex;
  box-shadow: 0 1px 4px rgba(122, 105, 105, 0.1);
  ${({ isPlayer }) => (isPlayer ? '    margin-bottom: 1vh;' : '')}
`;

export const PlaylistMainHeaderRoleSection = styled.div`
  width: 85%;
  display: flex;
  align-items: center;

  @media (min-width: 1601px) {
    margin: 0 auto;
  }
`;

export const PlaylistMainHeaderRole = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 10px 12px;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #534b4f;
  gap: 9px;
  transform: translateX(-43%);
`;

export const PlaylistMainHeaderUserWrap = styled.div`
  position: relative;
  width: 62px;
`;

export const PlaylistMainHeaderInChannelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 42.5%;
  padding-left: 7%;

  &:first-child {
    margin-right: 5px;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: rgba(83, 75, 79, 0.8);
  }

  @media (max-width: 1441px) {
    padding-left: 2%;
  }

  @media (max-width: 1375px) {
    width: 41%;
  }
`;
