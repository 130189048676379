import React from 'react';
import { useDispatch } from 'react-redux';
import SharedPlaylistUser from '../SharedPlaylistUser';
import { ReactComponent as CloseCrossSvg } from '../../../../../../images/2023/svg/small/component-description/close_16_16.svg';
import Toggler from '../../../../../../components/Togglers/Toggler';
import Tooltip from '../../../../../../shared/Tooltips/Tooltip';
import { getUserAvatarComponent } from '../../../LogicComponents/getUserAvatarComponent';
import Availability from '../Availability';
import {
  ContainerWithPointer,
  OptionContent,
  OptionDivider,
  OptionHeader,
  OptionItem,
  OptionTitle,
  OptionWrapper,
  Text,
} from '../styled';
import ShareSingleUser from '../../../../../../components/DropDownOption/Share/ShareSingleUser';
import { DATA_PARENTS } from '../../../../../../utils/constants';
import { actionCreator } from '../../../../../../shared/redux/actionHelper';
import { ActionTypeRemoveUserToSharing } from '../../../../../../components/DropDownOption/Share/helpers';
import ShareUserItem from './ShareUserItem';

const ShareOption = ({
  type, items,
  setIsSingleShareOpened,
  userEmail, setUserEmail,
  singleUserShareState, playlistId,
  itemType,
  ...props
}) => {
  return (
    <OptionWrapper data-parent={props.dataParent}>

      <OptionTitle>{type.title}</OptionTitle>
      <OptionContent>
        {items.map((user) => (
          <ShareUserItem
            dataParent={props.dataParent}
            singleUserShareState={singleUserShareState}
            playlistId={playlistId}
            itemType={itemType}
            user={user}
            coEditToggler={props.coEditToggler}
          />
        ))}

      </OptionContent>
      {props.children}
    </OptionWrapper>
  );
};

export default ShareOption;
