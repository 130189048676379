import styled from 'styled-components';
import { TAG_DEFAULT_COLOR } from '../../utils/constants';

export const Tag = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;

  background: ${({ backgroundColor }) => (backgroundColor
    ? `${backgroundColor}`
    : `${TAG_DEFAULT_COLOR.backgroundColor}`)};

  user-select: none;
  padding: 0 10px 0 10px;
  //margin: 1px 3px;
  max-width: 141px;
  width: fit-content;
  height: 22px;
  border-radius: 16px;
  justify-content: space-around;

  &:hover {
    background: ${({ backgroundColor }) => (backgroundColor
    ? `${backgroundColor}77`
    : `${TAG_DEFAULT_COLOR.backgroundColor}AA`)};
  }

  & .text {
    max-width: 110px;
    width: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: ${({ color }) => (color ? `${color}` : `${TAG_DEFAULT_COLOR.color}`)};
    padding: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.stroke {
      text-shadow:
        -0.5px -0.5px 0 #000,
        0.5px -0.5px 0 #000,
        -0.5px 0.5px 0 #000,
        0.5px 0.5px 0 #000;
    }
  }

  & .textHeader {
    max-width: 110px;
    width: auto;
    color: ${({ color }) => (color ? `${color}` : `${TAG_DEFAULT_COLOR.color}`)};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.stroke {
      text-shadow:
        -0.5px -0.5px 0 #000,
        0.5px -0.5px 0 #000,
        -0.5px 0.5px 0 #000,
        0.5px 0.5px 0 #000;
    }
  }

  & .icon.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 3px;
    padding: 3px;
  }
`;
