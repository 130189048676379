import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as BlueChevronLeftSvg } from '../../../images/icons/blue_chevron_left_24.svg';
import { ReactComponent as BlueChevronRightSvg } from '../../../images/icons/blue_chevron_right_24.svg';

const DatePickerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & svg path {
    stroke: #534b4f;
  }
`;

export const LeftButton2023 = ({ dataParent }) => {
  return (
    <DatePickerButton data-parent={dataParent}>
      <BlueChevronLeftSvg />
    </DatePickerButton>
  );
};
export const RightButton2023 = ({ dataParent }) => {
  return (
    <DatePickerButton data-parent={dataParent}>
      <BlueChevronRightSvg />
    </DatePickerButton>
  );
};
