import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgressBar } from './PlayBTN';
import { ReactComponent as SettingsSVG } from '../../images/2023/svg/small/player/settings.svg';
import { ReactComponent as CollapseSVG } from '../../images/2023/svg/small/player/collapse.svg';
import { ReactComponent as ExpandSvg } from '../../images/2023/svg/small/player/expand.svg';

import Zoom from './Zoom';
import {
  BotShadow,
  PBControlsWrapper,
  PBProgressStripeWrapper,
  PBRightConnerContainer,
  PlayerBottomWrapper, SettingsSVGWrapper,
} from './styled';
import DynamicProgressStripes from './DynamicProgressStripes';
import { exitFullScreen, openFullScreen } from '../../utils/fullscreen';
import { actionCreator } from '../../shared/redux/actionHelper';
import { ServiceUser } from '../../redux/user/types';
import SettingsPopup from './SettingsPopup';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { DATA_PARENTS } from '../../utils/constants';


const Bottom = ({ setIsEnd, scrollTo, currentItem, isMouseMoveShowControls }) => {
  const dispatch = useDispatch();
  const { playerSettings: { isFullScreenMode } } = useSelector((state) => state.user);
  const [popupRef, isPopupVisible, setIsPopUpVisible] = useComponentVisible(false, DATA_PARENTS.Player.settings);

  const toggleSettings = () => setIsPopUpVisible(!isPopupVisible);
  const toggleFullscreenHandler = () => {
    if (isFullScreenMode) {
      exitFullScreen();
    } else {
      openFullScreen();
    }
    dispatch(
      actionCreator(ServiceUser.Player.ChangeFullscreen, {
        value: !isFullScreenMode,
      }),
    );
  };

  return (
    <PlayerBottomWrapper isMouseMoveShowControls={isMouseMoveShowControls}>
      <PBControlsWrapper>
        <Zoom scrollTo={scrollTo} />
        <CircularProgressBar setIsEnd={setIsEnd} currentItem={currentItem} />
      </PBControlsWrapper>
      <PBProgressStripeWrapper>
        <DynamicProgressStripes />
      </PBProgressStripeWrapper>
      <PBRightConnerContainer>
        {isPopupVisible && <SettingsPopup close={() => setIsPopUpVisible(false)} popupRef={popupRef} dataParent={DATA_PARENTS.Player.settings} />}
        <SettingsSVGWrapper isActive={isPopupVisible}>
          <SettingsSVG onClick={toggleSettings} />
        </SettingsSVGWrapper>

        {isFullScreenMode && <CollapseSVG onClick={toggleFullscreenHandler} />}
        {!isFullScreenMode && <ExpandSvg onClick={toggleFullscreenHandler} />}
      </PBRightConnerContainer>
      <BotShadow />
    </PlayerBottomWrapper>
  );
};

export default Bottom;
