import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as LockClosedSvg } from '../../../images/icons/player/lock_closed_16.svg';
import { ReactComponent as LockOpenedSvg } from '../../../images/icons/player/lock_opened_16.svg';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { CurrentPage } from '../../../redux/currentPage/types';

const DisplayIcon = styled.div`
  position: absolute;
  margin: 0 auto;
  padding-top: 2px;
  opacity: 1;

  svg {
    width: 18px;
    height: 18px;
    ${({ isUnlocked }) => (isUnlocked ? 'opacity: 0.15;' : '')}
    path {
      stroke: #484343;
    }
  }
`;
const HoverIcon = styled.div`
  position: absolute;
  margin: 0 auto;
  padding-top: 2px;
  opacity: 0;

  svg {
    path {
      stroke: #484343;
    }
  }
`;

const Button = styled.div`
  border-radius: 6px;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: background-color, color;
  transition: ease-out 0.25s;
  color: #596080;

  ${({ isActive }) => (isActive ? 'background: #d7ddf7;' : '')}
  ${({ isDisabled, isTextPopup }) => (isDisabled
    ? `
      cursor: not-allowed;
      opacity: ${isTextPopup ? 0.15 : 1};
    `
    : `  &:hover {
    background: ${isTextPopup ? '#FFEEBF' : '#FFDF85'};
  }

  &:hover ${DisplayIcon} {
    opacity: 0;
  }

  &:hover ${HoverIcon} {
    opacity: 1;
  }`)}
  ${({ upvExpand }) => (upvExpand
    ? ` &:hover {
    background: transparent;
  }`
    : '')}
`;

export const LockButtonShell = styled.div`
  transition: opacity ease-out 0.15s;
  opacity: 1;

  ${({ upvCard }) => (upvCard
    ? `
    top: 87px;
    right: 18px;
  `
    : `
    margin-right: 8px;
    margin-top: 7px;
  `)}
  ${({ upvExpand }) => (upvExpand
    ? `  
    margin-right: 0px;
    margin-top: 0px;`
    : '')}
  ${({ isTextPopup }) => (isTextPopup
    ? `  
    margin: 0;
    margin-left: 10px;`
    : '')}
`;

const LockButton = (props) => {
  const { id: playlistId } = useSelector((state) => state.currentPage);

  const dispatch = useDispatch();

  const onLockButtonClickHandler = (e) => {
    e.stopPropagation();
    if (props.isDisabled) {
      return;
    }

    dispatch(
      actionCreator(CurrentPage.UpdateRemixState, {
        linkPageId: props.linkPageId,
        playlistId,
        value: !props.isRemixLocked,
      }),
    );
  };

  return (
    <LockButtonShell upvCard={props.upvCard} upvExpand={props.upvExpand} isTextPopup={props.isTextPopup}>
      <Button
        onClick={onLockButtonClickHandler}
        isDisabled={props.isDisabled}
        upvExpand={props.upvExpand}
        isTextPopup={props.isTextPopup}
      >
        {props.isRemixLocked && (
          <>
            <DisplayIcon>
              <LockClosedSvg />
            </DisplayIcon>
            <HoverIcon>
              <LockOpenedSvg />
            </HoverIcon>
          </>
        )}
        {!props.isRemixLocked && (
          <>
            <DisplayIcon isUnlocked>
              <LockOpenedSvg />
            </DisplayIcon>
            <HoverIcon>
              <LockClosedSvg />
            </HoverIcon>
          </>
        )}
      </Button>
    </LockButtonShell>
  );
};

export default LockButton;
