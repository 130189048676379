import styled from 'styled-components/macro';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { filterDC } from '../../../utils/helpers';
import useThrottle from '../../../utils/hooks/useThrottle';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { ContentActionType } from '../../../redux/content/contentTypes';
import { actionChangeSieveValue } from '../../../redux/library/actions';
import { DATA_PARENTS } from '../../../utils/constants';
import DropDownItem from './DropDownItem';
import { BtnWrapper, DDInputWrapper, DDTitleWrapper } from './styled';
import { HiddenScrollBarArea } from '../../Tag/TagsDropDown/styled';

const MainWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 250px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #484343;
  z-index: 1;
`;

const EmptyMsg = styled.div`
  font-size: 14px;
  color: #a3aacc;
  padding-left: 18px;
  margin-top: 8px;
  cursor: default;
`;

const DividerWrap = styled.div`
  width: 218px;
  height: 1px;
  margin: 8px auto 5px;
  background: rgba(29, 29, 29, 0.08);
`;
const DropDownSmartView = ({
  smartViewRef,
  parentRef,
  smartViewModal,
  setIsShowFilter,
  isLibrary,
  setPosition,
  isExOpened,
  hideDD,
  newUPVMode,
  defaultPosition = { top: 24, left: 0 },
  ...props
}) => {
  const dynamicCollection = useSelector((state) => state.dynamicCollection);
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { place } = useParams();
  const [search, setSearch] = useState('');
  const [localDynamicCollections, setLocalDynamicCollections] = useState([]);

  const { sortType } = useSelector((state) => state.content);
  const filterWithDebounce = () => setLocalDynamicCollections(
    Object.values(dynamicCollection)
      .filter((dynamicCollection) => filterDC(dynamicCollection, search))
      .sort((a, b) => b.createDate - a.createDate),
  );

  const [throttle] = useThrottle(300);
  const calculateHorizontalPosition = () => {
    if (isLibrary) return -253;
    if (parentRef && parentRef?.current) {
      const width = document.documentElement.clientWidth;
      const positionX = defaultPosition.left;
      const refAbsoluteX = parentRef?.current.getBoundingClientRect().x;
      const widthElement = 253 - 32;
      const isOpenInRight = width > refAbsoluteX + widthElement + positionX;
      if (isOpenInRight) return positionX;
      return positionX - widthElement;
    }
    return defaultPosition.left;
  };

  const onInputChange = (event) => {
    setSearch(event.target.value.trim());
  };
  const onInputKeyDown = (event) => {
    if (event.key === 'Backspace' && !search) {
      event.currentTarget.blur();
    }
  };

  const openModal = () => {
    props.resetSmartview();
    props.setShowNewSmartView(true);
    smartViewModal.setIsComponentVisible(false);
    history.push('/content/library/all');
  };
  const setPreviewContentQuery = ({
    newActiveNavSlider,
    newSelectorType,
    newSortType,
    isNeedUpdate = false,
  }) => {
    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        {
          activeNavSlider: newActiveNavSlider || 'pages',
          selectorType: newSelectorType || 'all',
          sortType: newSortType || sortType,
          isNeedUpdate,
        },
        'SmartView',
      ),
    );
  };

  const switchFolder = (e, destinationId = '') => {
    e.stopPropagation();
    e.preventDefault();

    if (newUPVMode) {
      if (props.setChosenSmartView) {
        props.setChosenSmartView(destinationId);
      }

      destinationId.initial = true;
      setPreviewContentQuery({
        newActiveNavSlider: 'dynamicCollectionNew',
        newSelectorType: destinationId,
        isNeedUpdate: true,
      });
      smartViewModal.setIsComponentVisible(false);
      return;
    }
    if (isLibrary) {
      history.push(`/content/library/dynamic_${destinationId.id}`);
      props.resetSmartview('switchingFolder');
      destinationId.initial = true;
      setPreviewContentQuery({
        newActiveNavSlider: 'dynamicCollectionNew',
        newSelectorType: destinationId,
      });
      dispatch(
        actionChangeSieveValue(
          { name: destinationId.name, id: destinationId.id },
          'library',
        ),
      );
      smartViewModal.setIsComponentVisible(false);
    } else {
      props.toggleSideBars(e, destinationId);
    }
  };
  const styleDiv = {
    top: isLibrary ? 38 : 0,
    left: isLibrary ? -49 : calculateHorizontalPosition(),
  };

  useEffect(() => {
    filterWithDebounce();
  }, []);

  useEffect(() => {
    throttle(() => filterWithDebounce);
  }, [dynamicCollection, search]);

  useEffect(() => {
    return hideDD();
  }, []);

  return (
    <MainWrapper
      ref={smartViewRef}
      data-parent={DATA_PARENTS.smartview + DATA_PARENTS.PlaylistBuilderLibrary}
      style={styleDiv}
      onScroll={hideDD}
    >
      <DDTitleWrapper>{t('manageSmartviewsUpT')}</DDTitleWrapper>
      <DDInputWrapper
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        value={search}
        onChange={onInputChange}
        onBlur={onInputKeyDown}
        placeholder={t('searchSmartViewPlaceholderT')}
      />
      {isLibrary && !newUPVMode && (
        <BtnWrapper onClick={openModal}>{t('addBewSmartViewT')}</BtnWrapper>
      )}
      <DividerWrap />
      {!!localDynamicCollections.length && (
        <HiddenScrollBarArea>
          {localDynamicCollections.map((dynamicCollection, index) => (
            <DropDownItem
              key={dynamicCollection.id}
              setPosition={setPosition}
              isExOpened={isExOpened}
              index={index}
              handleClick={switchFolder}
              smartCollection={dynamicCollection}
              isLibrary={isLibrary}
              newUPVMode={newUPVMode}
            />
          ))}
        </HiddenScrollBarArea>
      )}

      {!localDynamicCollections.length && !search && (
        <EmptyMsg>{t('NoSmartviewsT')}</EmptyMsg>
      )}
      {!localDynamicCollections.length && !!search && (
        <EmptyMsg>{t('NoSmartviewsFoundT')}</EmptyMsg>
      )}
    </MainWrapper>
  );
};

export default DropDownSmartView;
