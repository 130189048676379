import Draggable from './types';
import { ServiceUser } from '../user/types';

const defaultState = { draggableBlocks: [] };

const draggableReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case Draggable.PagesBlock: {
      return {
        ...defaultState,
        draggableBlocks: payload.blocksId,
        dragField: payload.playlistField,
      };
    }
    case Draggable.HasDraggable: {
      return { ...state, hasDraggable: payload };
    }
    case Draggable.DragItem: {
      return { ...defaultState, ...payload };
    }
    case Draggable.Clear: {
      return { ...defaultState };
    }
    case ServiceUser.Logout: {
      return { ...defaultState };
    }
    default: {
      return state;
    }
  }
};

export default draggableReducer;
