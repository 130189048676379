import { all, select } from '@redux-saga/core/effects';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  getCurrentPage,
  getLibraryComponent,
  getLibraryComponents,
  getLocalStateTags,
  getTags,
  getUser,
  getUserTags,
  showErrorMessage,
} from './sagasUtils';
import {
  requestCreateTag,
  requestDeleteTag,
  requestGetTagCounter,
  requestUpdateLibraryComponent,
  requestUpdatePlaylistManager,
  requestUpdateTag,
} from '../utils/request';

import { Tags } from '../redux/tags/types';
import {
  _actionRefreshUserTags,
  actionAddTagEmptyR,
  actionAddTagToCurrentPage,
  actionAttachTagParentAndCurrentPageRedux,
  actionAttachTagParentRedux,
  actionCheckTag,
  actionCreateTag,
  actionUpdateTagRedux,
} from '../redux/tags/action';
import {
  calculateIndex,
  createNewName,
  sanitizeToSave,
} from '../utils/helpers';
import {
  DEFAULT_POSITION_STEP,
  DEFAULT_TITLE,
  MessageType,
} from '../utils/constants';
import { createRandomColor } from '../pages/Playlist/utils';
import { actionShowMessage } from '../redux/support/action';
import { actionRemoveAllSelected } from '../redux/selectedPage/action';
import { actionCreator } from '../shared/redux/actionHelper';
import SupportAction from '../redux/support/types';
import { actionCloseModal } from '../redux/user/action';
import i18n from '../i18n';

function* checkTagExists(action) {
  try {
    const { tag, parent, type, isPage, isTemplate, isSelected } = action.payload;
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const currentPageTags = yield select(getTags);
    const userTags = yield select(getUserTags);

    const allUserTagsTitles = Object.values(userTags || {}).reduce(
      (acc, item) => {
        acc[item.title] = item.id;
        return acc;
      },
      {},
    );

    if (parent) {
      let currentTags;
      if (type === 'Playlist') {
        currentTags = parent.tags.reduce((acc, item) => {
          acc[item.title] = item.id;
          return acc;
        }, {});
      } else if (type !== 'LibraryComponent') {
        currentTags = currentPageTags.reduce((acc, item) => {
          acc[item.title] = item.id;
          return acc;
        }, {});
      } else {
        const components = yield select(getLibraryComponent);
        currentTags = parent.tags?.reduce((acc, item) => {
          acc[item.title] = item.id;
          return acc;
        }, {}) || {};
        if (!tag.nestedPosition) {
          tag.nestedPosition = Math.max(
            ...Object.values(components[parent.id].tags || {}).map(
              (tg) => tg.position || 0,
            ),
            0,
          ) + DEFAULT_POSITION_STEP;
        }
      }

      if (allUserTagsTitles[tag.title] && currentTags[tag.title]) {
        return;
      }
    }

    let savedTag;
    if (!allUserTagsTitles[tag.title]) {
      yield put(actionCreateTag(tag));
      savedTag = tag;
    } else {
      savedTag = {
        ...userTags[allUserTagsTitles[tag.title]],
        nestedPosition: tag.nestedPosition,
      };
    }

    if (parent) {
      if (!isTemplate && allUserTagsTitles[tag.title]) {
        if (type === 'Playlist') {
          yield call(requestUpdatePlaylistManager, {
            id: parent.id,
            connect: { contentTags: [tag.id] },
          });
        } else {
          yield call(requestUpdateLibraryComponent(), {
            id: parent.id,
            connect: { contentTags: [tag.id] },
          });
        }
      } else {
        yield put(
          actionCreator(SupportAction.updateTag.AddLocal, {
            tag: { id: savedTag.id },
          }),
        );
        // savedTag, parent, type, parent.lastModifiedDate));
      }
      if (type === 'LibraryComponent' && isPage) {
        yield put(
          actionAttachTagParentAndCurrentPageRedux(
            savedTag,
            parent,
            type,
            parent.lastModifiedDate,
            isSelected,
          ),
        );
      } else if (type === 'LibraryComponent') {
        yield put(
          actionAttachTagParentRedux(
            savedTag,
            parent,
            type,
            lastModifiedDate,
            isSelected,
          ),
        );
      } else if (type === 'Playlist' && isPage) {
        yield put(
          actionAttachTagParentAndCurrentPageRedux(
            savedTag,
            parent,
            type,
            parent.lastModifiedDate,
          ),
        );
      } else {
        yield put(actionAddTagToCurrentPage(savedTag, parent));
      }
    }
    if (parent) {
      yield put(
        actionCreator(SupportAction.TriggerCountersUpdate, { isActive: true }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updateTags(action) {
  try {
    const { id, isSelected, history } = action.payload;
    const currentPageTags = yield select(getLocalStateTags);
    if (
      Object.values(currentPageTags.add)
      || Object.values(currentPageTags.delete)
    ) {
      const calc = {};
      if (Object.keys(currentPageTags.add)) {
        calc.connect = { contentTags: Object.values(currentPageTags.add) };
      }
      if (Object.keys(currentPageTags.delete)) {
        calc.disconnect = {
          contentTags: Object.values(currentPageTags.delete),
        };
      }
      const {
        data: { updateLibraryComponents },
      } = yield call(requestUpdateLibraryComponent(), {
        place: 'queryAttachTagToParentCalc',
        id,
        ...calc,
        customAnswer: `
    contentTags {
      id
      title
      color
      position
      backgroundColor
    }`,
      });
      const { libraryComponents } = updateLibraryComponents;
      const [{ contentTags }] = libraryComponents;
      yield put(
        actionCreator(SupportAction.updateTag.Update, { contentTags, id }),
      );
      yield put(actionCreator(SupportAction.updateTag.Clear));
      const type = Object.values(currentPageTags.delete)
        ? MessageType.TagUpdated
        : MessageType.TagAdded;

      if (!isSelected) {
        yield put(actionShowMessage({ type }));
      }
      const idsToUpdate = [...Object.values(currentPageTags.add), ...Object.values(currentPageTags.delete)];
      yield put(
        actionCreator(Tags.updateTagCounterS, {
          idsToUpdate,
        }),
      );
      if (history?.location?.pathname.includes('tag_')) {
        const currentIdTagDisplay = history.location.pathname.split('_')[1];
        if (Object.values(currentPageTags.delete).includes(currentIdTagDisplay)) {
          yield put(actionCreator(SupportAction.RemoveItemContentInRedux, { id }));
        }
      }
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* createTag(action) {
  try {
    /* need for debug */
    // yield put(actionCloseModal());
    const { tag } = action.payload;
    const user = yield select(getUser);
    const fieldsUpdateObg = {
      id: `"${tag.id}"`,
      title: `"${tag.title}"`,
      position: `${tag.position}`,
    };
    if (tag.backgroundColor) {
      fieldsUpdateObg.backgroundColor = `"${tag.backgroundColor}"`;
    }
    if (tag.color) {
      fieldsUpdateObg.color = `"${tag.color}"`;
    }

    yield call(requestCreateTag, {
      fieldsUpdateObg,
      ownerId: user.id,
    });
    yield put(
      actionShowMessage({
        type: MessageType.TagHasBeenCreated,
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* createEmptyTag(action) {
  try {
    const { tagId } = action.payload;

    const userTags = yield select(getUserTags);
    const user = yield select(getUser);
    const maxPosition = Math.max(
      ...Object.values(userTags || {}).map((tag) => tag.position || 0),
      0,
    ) + DEFAULT_POSITION_STEP;
    const findName = 'untitled_tag';
    const newColor = createRandomColor();

    const newTag = {
      id: tagId,
      title: createNewName(userTags, findName),
      color: newColor,
      position: maxPosition,
      type: 'tags',
      itemsCounter: 0,
      libraryComponentsCounter: 0,
      playlistsCounter: 0,
    };

    yield put(actionAddTagEmptyR(newTag));

    const fieldsUpdateObg = {
      id: `"${newTag.id}"`,
      title: `"${newTag.title}"`,
      position: `${newTag.position}`,
    };
    if (newTag.backgroundColor) {
      fieldsUpdateObg.backgroundColor = `"${newTag.backgroundColor}"`;
    }
    if (newTag.color) {
      fieldsUpdateObg.color = `"${newTag.color}"`;
    }

    yield call(requestCreateTag, {
      fieldsUpdateObg,
      ownerId: user.id,
    });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* detachTagFromParent(action) {
  try {
    const { tag, parent, type } = action.payload;
    const { id } = yield select(getCurrentPage);
    if (type === 'Playlist') {
      yield call(requestUpdatePlaylistManager, {
        id: parent.id || id,
        disconnect: { contentTags: [tag.id] },
      });
    } else {
      yield call(requestUpdateLibraryComponent(), {
        id: parent.id || id,
        disconnect: { contentTags: [tag.id] },
      });
    }
    yield put(
      actionCreator(SupportAction.TriggerCountersUpdate, { isActive: true }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* moveTagPosition(action) {
  try {
    const { id } = yield select(getCurrentPage);
    const userTags = yield select(getUserTags);
    const { tag, newIndex, type, componentId } = action.payload;
    const preparedUserTags = Object.values(userTags || {}).sort(
      (a, b) => b.position - a.position,
    );
    let prevTag;
    let nextTag;
    let parentId;
    const currentTags = preparedUserTags;

    if (type !== 'LibraryComponent') {
      parentId = id;
    } else {
      parentId = componentId;
    }
    if (newIndex === 'last') {
      prevTag = currentTags[currentTags.length - 1];
    } else if (newIndex || newIndex === 0) {
      prevTag = currentTags[newIndex];
      nextTag = currentTags[newIndex - 1];
    }
    const newTagPosition = calculateIndex(prevTag?.position, nextTag?.position);

    yield call(requestUpdateTag, {
      fieldsUpdateObg: {
        position: newTagPosition,
      },
      id: tag.id,
    });
    const newArr = currentTags.map((item, index) => {
      if (index === tag.index) return { ...item, position: newTagPosition };
      return item;
    });
    yield put(
      _actionRefreshUserTags(newArr.sort((a, b) => a.position - b.position)),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* changeTagColor(action) {
  try {
    const { id, newColor, newBackgroundColor } = action.payload;
    const fieldsUpdateObg = {};
    if (newColor) fieldsUpdateObg.color = `"${newColor}"`;
    if (newBackgroundColor) fieldsUpdateObg.color = `"${newBackgroundColor}"`;
    yield call(requestUpdateTag, {
      fieldsUpdateObg,
      id,
    });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* changeTagTitle(action) {
  try {
    const { id, newTitle } = action.payload;
    const fieldsUpdateObg = {
      title: `"${newTitle.trim()}"`,
    };
    yield call(requestUpdateTag, {
      fieldsUpdateObg,
      id,
    });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updateTag(action) {
  try {
    yield put(actionCloseModal());
    const { id, color, backgroundColor, title } = action.payload;
    const fieldsUpdateObg = {};
    if (color) fieldsUpdateObg.color = `"${color}"`;
    if (backgroundColor) fieldsUpdateObg.color = `"${backgroundColor}"`;
    if (title) fieldsUpdateObg.title = `"${sanitizeToSave(title.trim())}"`;

    yield call(requestUpdateTag, {
      fieldsUpdateObg,
      id,
    });
    yield put(
      actionShowMessage({
        type: MessageType.ChangesSavedOK,
      }),
    );
    yield put(actionUpdateTagRedux({ id, color, backgroundColor, title }));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* deleteTagFromSystem(action) {
  try {
    const { tagId, history } = action.payload;
    if (history.location.pathname.includes('tag_')) {
      const id = history.location.pathname.split('_')[1];
      if (id === tagId) history.push('/content/library/all');
    }
    yield call(requestDeleteTag, { id: tagId });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* addContentTagToSeveralItems(action) {
  try {
    const { tagIds, selectedPage, history } = action.payload;
    const components = yield select(getLibraryComponents);
    const { tags: userTags } = yield select(getUser);
    const selectedItems = Object.keys(selectedPage).filter((item) => !!item);
    const getEmptyName = (type) => (
      type === 'page' ? i18n.t(DEFAULT_TITLE.Page) : i18n.t(DEFAULT_TITLE.LibraryComponent)
    );

    const targetTags = tagIds.map((tagId) => userTags[tagId]);

    yield all(
      targetTags
        .map((targetTag) => {
          return selectedItems.map((itemId) => {
            return put(
              actionCheckTag(targetTag, components[itemId], 'LibraryComponent'),
            );
          });
        })
        .flat(),
    );

    yield all(
      selectedItems.map((itemId) => {
        return put(
          actionCreator(SupportAction.updateTag.Save, {
            id: itemId,
            isSelected: true,
            history,
          }),
        );
      }),
    );

    yield put(
      actionCreator(SupportAction.TriggerCountersUpdate, { isActive: true }),
    );

    const title = components[selectedItems[0]]?.title
      ? components[selectedItems[0]]?.title
      : getEmptyName(components[selectedItems[0]]?.type);

    if (targetTags.length === 1) {
      const notificationData = {
        type: MessageType.TagAddedToItem,
        tagTitle: targetTags[0].title,
        itemsCounter: selectedItems.length,
        itemName: title,
      };
      yield put(actionShowMessage(notificationData));
    } else {
      const notificationData = {
        type: MessageType.TagsAddedToItem,
        itemsCounter: selectedItems.length,
        itemName: title,
      };
      yield put(actionShowMessage(notificationData));
    }
    yield put(actionRemoveAllSelected());
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updateCounterInTag(action) {
  try {
    const { idsToUpdate = [] } = action.payload;
    if (!idsToUpdate.length) return;
    const { data } = yield requestGetTagCounter({ ids: idsToUpdate });
    yield put(
      actionCreator(Tags.updateTagCounterR, {
        updateTagsData: data?.contentTags,
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

export default function* hashtagsSaga() {
  // yield takeLatest(Hashtag.CreateHashtag, createHashtag);
  // yield takeLatest(Hashtag.DeleteHashtag, deleteHashtag);
  // yield takeLatest(Hashtag.AddHashtagPlaylist, addHashTag);
  // yield takeLatest(Hashtag.GetPlaylistHashtags, getPlaylistHashtags);
  // yield takeLatest(Hashtag.RemoveHashtagPlaylist, removeHashTagFromPlaylist);
  // yield takeLatest(Hashtag.RemoveHashtagPage, removeHashTagFromPage);
  // yield takeLatest(Hashtag.CheckHashtag, checkHashtag);
  yield takeEvery(SupportAction.updateTag.Save, updateTags);
  yield takeEvery(Tags.CheckTag, checkTagExists);
  yield takeEvery(Tags.createEmptyTag, createEmptyTag);
  yield takeLatest(Tags.CreateTag, createTag);
  yield takeLatest(Tags.DeleteTag, detachTagFromParent);
  yield takeLatest(Tags.MoveTag, moveTagPosition);
  yield takeLatest(Tags.ChangeTagColor, changeTagColor);
  yield takeLatest(Tags.ChangeTagTitle, changeTagTitle);
  yield takeLatest(Tags.DeleteTagFromSystem, deleteTagFromSystem);
  yield takeLatest(
    Tags.AddContentTagToSeveralItems,
    addContentTagToSeveralItems,
  );
  yield takeLatest(Tags.UpdateTag, updateTag);
  yield takeEvery(Tags.updateTagCounterS, updateCounterInTag);
}
