import i18n from '../../i18n';

export function timeSinceLastUpdate(date) {
  const seconds = Math.abs(Math.floor((new Date() - date) / 1000));
  const text = 'ago';
  let interval = seconds / 31536000;
  if (interval > 1) {
    return i18n.t(`years${text}T`, {
      number: Math.floor(interval),
      isMultiple: Math.floor(interval) > 1 ? 's' : '',
    });
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return i18n.t(`months${text}T`, {
      number: Math.floor(interval),
      isMultiple: Math.floor(interval) > 1 ? 's' : '',
    });
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return i18n.t(`days${text}T`, {
      number: Math.floor(interval),
      isMultiple: Math.floor(interval) > 1 ? 's' : '',
    });
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return i18n.t(`hours${text}T`, {
      number: Math.floor(interval),
      isMultiple: Math.floor(interval) > 1 ? 's' : '',
    });
  }
  interval = seconds / 60;
  if (interval > 1) {
    return i18n.t(`minutes${text}T`, {
      number: Math.floor(interval),
      isMultiple: Math.floor(interval) > 1 ? 's' : '',
    });
  }

  return i18n.t('justNowT');
}
