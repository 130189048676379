import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './index.module.scss';
import { ReactComponent as ChevronDown20Svg } from '../../../../images/icons/chevron_down_20.svg';
import { ReactComponent as Check20Svg } from '../../../../images/icons/sortCheck.svg';
import { ReactComponent as SpinnerSvg } from '../../../../images/icons/spinner.svg';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Channels } from '../../../../redux/channels/types';
import {
  ADMIN_CHANGE_OPTIONS,
  ROLE_CHANGER_OPTION,
  UserRole,
} from '../../../../utils/permissions';
import { Spinner } from './Spinner';

const cn = classNames.bind(styles);

const RolesCell = ({ item, itemRef, isDropListVisible, setIsDropVisible }) => {
  const dispatch = useDispatch();
  const { requestSpinner, isHasErrorServer } = useSelector(
    (state) => state.support,
  );
  const { id: folderId } = useParams();
  const { myChannels } = useSelector((state) => state.channels);

  const clickHandler = (event) => {
    event.stopPropagation();
    if (requestSpinner && requestSpinner !== 'init') return;
    setIsDropVisible(true);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const listItemClick = (newRole) => {
    setIsDropVisible(false);
    dispatch(
      actionCreator(Channels.ChangeMemberRole, {
        itemId: item.id,
        newRole,
        channelId: folderId,
      }),
    );
  };

  return (
    <div onClick={stopPropagation}>
      <>
        {(!myChannels[folderId]?.permissions?.canChangeRolesTo[item.role]
          || item.isTemporaryUser
          || item.role === 'admin') && (
          <div className={cn('drop_list_button', 'viewOnly')}>
            {item.isTemporaryUser && (
              <div className={cn('drop_list_invitedUser_hint')}>
                Not a Quidzi user
              </div>
            )}
            {UserRole[item.role]}
          </div>
        )}
        {myChannels[folderId]?.permissions?.canChangeRolesTo[item.role]
          && !item.isTemporaryUser && (
          <>
            <div
              className={cn('drop_list_button', {
                active: isDropListVisible,
                blocked: requestSpinner === item.id,
                error: isHasErrorServer,
              })}
              onClick={clickHandler}
            >
              {UserRole[item.role]}
              {requestSpinner !== item.id && <ChevronDown20Svg />}
              {requestSpinner === item.id && (
              <Spinner isRoleCell>
                <SpinnerSvg />
              </Spinner>
              )}
            </div>

              {isDropListVisible && (
                <div ref={itemRef} className={cn('drop_list_wrapper')}>
                  <div className={cn('drop_list_container')}>
                    <div className={cn('drop_scroll')}>
                      {(myChannels[folderId].role === 'admin'
                        ? ADMIN_CHANGE_OPTIONS
                        : ROLE_CHANGER_OPTION[item.role]
                      ).map((innerItem) => (
                        <div
                          key={innerItem.key}
                          className={cn('list_item', {
                            active: innerItem.apiRole === item.role,
                          })}
                          onClick={() => listItemClick(innerItem.apiRole)}
                        >
                          <div className={cn('title')}>
                            {innerItem.title}
                            <Check20Svg className={cn('check_icon')} />
                          </div>

                          <div className={cn('description')}>
                            {innerItem.description}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
          </>
        )}
      </>
    </div>
  );
};

export default RolesCell;
