export const smartFileItemType: { [key: string]: string} = {
  title: 'elementTitle',
  text: 'elementText',
  richText: 'richText',
};

export const smartFileItemTypeCheck = {
  isTitle: { [smartFileItemType.title]: true },
  isText: { [smartFileItemType.text]: true },
  isRichText: { [smartFileItemType.richText]: true },
  isLexicalText: { [smartFileItemType.richText]: true, [smartFileItemType.title]: true },
} as const;
