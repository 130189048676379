export const getExtention = (element) => {
  const title = element?.title || element?.libraryComponent?.title;
  if (title) {
    const titleArr = title.split('.');
    if (titleArr.length > 1) {
      return titleArr[titleArr.length - 1];
    }
  }
  return null;
};
