import { call, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { all, select } from '@redux-saga/core/effects';
import { request } from '../utils/request';
import { GENERATE_NEUROTEXT, METHOD, UNSUBSCRIBE_MANY_URL, UPDATE_CONTACT_STATUS } from '../utils/constants';
import { actionCreator } from '../shared/redux/actionHelper';
import { ContentActionType } from '../redux/content/contentTypes';
import { showErrorMessage } from './sagasUtils';
import { CurrentPage } from '../redux/currentPage/types';
import { NeuralAction } from '../redux/neural/types';


function* neuralTextGenerate(action) {
  try {
    const { userText } = action.payload;
    const answer = yield call(request(GENERATE_NEUROTEXT), { userText });
    yield put(actionCreator(NeuralAction.SetText, { neuralText: answer.data }));
  } catch (err) {
    yield showErrorMessage(err);
  }
}

export default function* neuralSaga() {
  yield takeEvery(NeuralAction.GenerateText, neuralTextGenerate);
}
