import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MS43Svg } from '../../images/icons/elements_word_no_preview_43-52.svg';
import styles from './index.module.scss';
import ButtonComponent from '../../components/Buttons/ButtonComponent';
import { ReactComponent as Download20Svg } from '../../images/icons/download_20.svg';

const cn = classNames.bind(styles);

const MsContent = ({ item, goToLink }) => {
  const { t } = useTranslation();
  if (!item) return <div>{t('loadingT')}</div>;
  return (
    <div className={cn('msWord')}>
      <div className={cn('icon_wrapper')}>
        <MS43Svg />
      </div>
      <div className={cn('title_wrapper')}>{item.title}</div>
      <div className={cn('description_wrapper')}>
        {t('clickBelowToDownloadFileT')}
      </div>
      <div className={cn('actions_wrapper')} onClick={goToLink}>
        <ButtonComponent text={t('downloadT')}>
          <Download20Svg />
        </ButtonComponent>
      </div>
    </div>
  );
};

export default MsContent;
