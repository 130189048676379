import styled from 'styled-components/macro';

export const Width100 = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    position: absolute;
    right: -4px;
  }

  &::-webkit-scrollbar-thumb {
    position: absolute;
    display: block;
    border-radius: 4px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.13);
  }

  &::-webkit-scrollbar-track-piece {
    display: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
`;
