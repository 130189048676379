import { call, put, takeEvery } from 'redux-saga/effects';
import { select } from '@redux-saga/core/effects';
import { v4 as uuidv4 } from 'uuid';
import { getDynamicCollections, getUser, showErrorMessage } from './sagasUtils';
import {
  requestCounterSmartView,
  requestCreateSmartView,
  requestDeleteSmartView,
  requestUpdateSmartView,
} from '../utils/request';
import { DynamicCollection } from '../redux/dynamicCollection/types';
import {
  actionAddDynamicCollection,
  actionMoveDynamicCollectionR,
} from '../redux/dynamicCollection/action';
import { actionChangeSieveValue } from '../redux/library/actions';
import {
  LibCompSieveTypes,
  LibCompSieveTypesTest,
  MessageType,
  PLACE,
} from '../utils/constants';
import { calculateIndex, sanitizeToSave } from '../utils/helpers';
import { actionCreator } from '../shared/redux/actionHelper';
import SupportAction from '../redux/support/types';
import { actionShowMessage } from '../redux/support/action';
import { actionUpdateCounters } from '../redux/content/actions';

function* createDynamicCollection(action) {
  try {
    const { dynamicCollection, callback } = action.payload;
    const user = yield select(getUser);
    const createDate = Math.floor(Date.now() / 1000);
    const fieldsUpdateObg = {
      id: `"${dynamicCollection.id}"`,
      createDate,
      color: `"${dynamicCollection.color}"`,
      position: dynamicCollection.position,
      name: `"${sanitizeToSave(dynamicCollection.name)}"`,
      filter: `"${sanitizeToSave(JSON.stringify(dynamicCollection.filter))}"`,
      iconKey: `"${dynamicCollection.iconKey}"`,
      search: `"${dynamicCollection.libFilterSearch}"`,
    };
    yield call(requestCreateSmartView, {
      fieldsUpdateObg,
      ownerId: user.id,
    });
    if (callback) callback();
    yield put(
      actionShowMessage({
        type: MessageType.NewSmartView,
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* changeDynamicCollection(action) {
  try {
    const dynamicCollection = action.payload;
    const modifiedDate = Math.floor(Date.now() / 1000);
    const fieldsUpdateObg = {
      lastModifiedDate: modifiedDate,
      color: `"${dynamicCollection.color}"`,
      name: `"${sanitizeToSave(dynamicCollection.name)}"`,
      filter: `"${sanitizeToSave(JSON.stringify(dynamicCollection.filter))}"`,
      iconKey: `"${dynamicCollection.iconKey}"`,
      search: `"${dynamicCollection.libFilterSearch}"`,
    };
    yield call(requestUpdateSmartView, {
      fieldsUpdateObg,
      id: dynamicCollection.id,
    });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* DuplicateDynamicCollection(action) {
  try {
    const { dynamicCollectionId } = action.payload;
    const dynamicCollections = yield select(getDynamicCollections);
    const dynamicCollection = dynamicCollections[dynamicCollectionId];
    const newId = uuidv4();
    const newDynamicCollection = { ...dynamicCollection, id: newId };
    yield put(actionAddDynamicCollection(newDynamicCollection));
    yield put(actionChangeSieveValue(newId, PLACE.Library));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* renameDynamicCollection(action) {
  try {
    const {
      payload: { dynamicCollectionId, name },
    } = action;
    const fieldsUpdateObg = {
      name: `"${sanitizeToSave(name)}"`,
    };
    yield call(requestUpdateSmartView, {
      fieldsUpdateObg,
      id: dynamicCollectionId,
    });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* editDynamicCollection(action) {
  try {
    const { payload: dynamicCollection } = action;
    const fieldsUpdateObg = {
      filter: `"${sanitizeToSave(JSON.stringify(dynamicCollection.filter))}"`,
      search: `"${dynamicCollection.libFilterSearch}"`,
      color: `"${dynamicCollection.color}"`,
      name: `"${sanitizeToSave(dynamicCollection.name)}"`,
      iconKey: `"${dynamicCollection.iconKey}"`,
    };
    yield call(requestUpdateSmartView, {
      fieldsUpdateObg,
      id: dynamicCollection.dynamicCollectionId,
    });
    yield put(
      actionCreator(SupportAction.TriggerCountersUpdate, { isActive: true }),
    );
    yield put(
      actionShowMessage({
        type: MessageType.ChangesSavedOK,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* deleteDynamicCollection(action) {
  try {
    const {
      payload: { dynamicCollectionId, pathname, isLibraryCallback },
    } = action;
    if (isLibraryCallback) {
      isLibraryCallback();
    } else {
      const isPage = !!pathname && pathname.includes('/libraryPage');
      yield put(
        actionChangeSieveValue(
          isPage ? LibCompSieveTypes.All : LibCompSieveTypesTest.Pages,
          PLACE.Library,
        ),
      );
    }
    yield call(requestDeleteSmartView, {
      id: dynamicCollectionId,
    });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

//

function* moveDynamicCollection(action) {
  try {
    const userSmartView = yield select(getDynamicCollections);
    const { dynamicCollection, newIndex } = action.payload;
    const orderSmartView = Object.values(userSmartView || {}).sort(
      (a, b) => b.position - a.position,
    );
    let prevTag;
    let nextTag;
    if (newIndex === 'last') {
      prevTag = orderSmartView[orderSmartView.length - 1];
    } else if (newIndex || newIndex === 0) {
      prevTag = orderSmartView[newIndex];
      nextTag = orderSmartView[newIndex - 1];
    }
    const newSmartViewPosition = calculateIndex(
      prevTag?.position,
      nextTag?.position,
    );
    yield put(
      actionMoveDynamicCollectionR({
        ...dynamicCollection,
        position: newSmartViewPosition,
      }),
    );
    const fieldsUpdateObg = {
      position: newSmartViewPosition,
    };
    yield call(requestUpdateSmartView, {
      fieldsUpdateObg,
      id: dynamicCollection.id,
    });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* getCounterSmartView(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(requestCounterSmartView(id));
    yield put(
      actionUpdateCounters({ dynamic: data?.counter || 0 }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

export default function* dynamicCollectionSaga() {
  yield takeEvery(DynamicCollection.renameDC, renameDynamicCollection);
  yield takeEvery(DynamicCollection.addDC, createDynamicCollection);
  yield takeEvery(DynamicCollection.GetCounterSmartView, getCounterSmartView);
  yield takeEvery(DynamicCollection.changeDC, changeDynamicCollection);
  yield takeEvery(DynamicCollection.duplicateDC, DuplicateDynamicCollection);
  yield takeEvery(DynamicCollection.deleteDC, deleteDynamicCollection);
  yield takeEvery(DynamicCollection.editDC, editDynamicCollection);
  yield takeEvery(DynamicCollection.moveDC, moveDynamicCollection);
}
