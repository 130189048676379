import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SpinnerSvg } from '../../images/icons/spinner.svg';
import { SpinnerGoogleEmbed } from '../../pages/Channels/MemberRow/RolesCell/Spinner';
import { ReactComponent as Dropbox16Svg } from '../../images/icons/dropbox_16.svg';
import useImageCheck from '../../utils/hooks/useImageCheck';

export const DropboxPlaceholder = styled.div`
  width: 100%;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DropboxEmbedPreviewBlock = ({ data, editorWR }) => {
  const [imageUrl, setImageUrl] = useState('');
  const [error, loading] = useImageCheck(imageUrl);
  const { t } = useTranslation();

  const openInNewTab = (e) => {
    e.stopPropagation();

    let url = data?.url;
    if (url) {
      if (!url.startsWith('https://') && !url.startsWith('http://')) url = `http://${url}`;
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  };

  useEffect(() => {
    if (data?.images && Array.isArray(data?.images)) {
      const test = data?.images.find((i) => i.endsWith('.jpg'));
      setImageUrl(test || data?.images[0]);
    } else if (typeof data?.images === 'string') setImageUrl(data?.images);
  }, [data]);

  if (!data.title) {
    return (
      <>
        <div onClick={openInNewTab} className="google_embed_website">
          <div className="google_preview_image_wrapper">
            <DropboxPlaceholder>
              <Dropbox16Svg style={{ width: 200, height: 200 }} />
            </DropboxPlaceholder>
          </div>
          <div className="google_embed_website_text">
            <div className="google_embed_website_loading">
              {t('loadingPreviewT')}
            </div>
            <div className="google_embed_website_modified minHeight">
              <SpinnerGoogleEmbed>
                <SpinnerSvg />
              </SpinnerGoogleEmbed>
            </div>
            <div className="google_embed_website_url">{data?.url || ''}</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        ref={editorWR}
        onClick={openInNewTab}
        className="google_embed_website"
      >
        <div className="google_preview_image_wrapper">
          {data?.images.length && (
            <>
              {loading && (
                <div className="cornerSpinner">
                  <SpinnerGoogleEmbed>
                    <SpinnerSvg />
                  </SpinnerGoogleEmbed>
                </div>
              )}
              {!error && !loading && (
                <img
                  className="google_preview_image"
                  src={imageUrl}
                  alt="preview"
                />
              )}
              {(!!error || loading) && (
                <DropboxPlaceholder>
                  <Dropbox16Svg style={{ width: 200, height: 200 }} />
                </DropboxPlaceholder>
              )}
            </>
          )}
        </div>
        <div className="google_embed_website_text">
          <div className="google_embed_website_h1">{data?.title}</div>
          {data?.description && (
            <div className="google_embed_website_description">
              {data?.description}
            </div>
          )}
          <div className="google_embed_website_url">{data?.url || ''}</div>
        </div>
      </div>
    </>
  );
};

export default DropboxEmbedPreviewBlock;
