import React from 'react';
import { Avatar } from '../../../../components/MainHeader/User';
import AvatarPlaceholder from '../../../../components/Avatar';
import WithSimpleTooltip from './WithSimpleTooltip';

export const getUserAvatarComponent = (user, radius, withTooltip, color) => {
  const isDisplayImage = !!user?.avatarUrlVerySmall
    && (user?.displayImage === 'AvatarImage' || !user?.displayImage);
  const isDisplayGeneratedAvatar = !user?.avatarUrlVerySmall || user?.displayImage === 'GeneratedAvatar';

  if (isDisplayImage && withTooltip) {
    return () => (
      <WithSimpleTooltip text={`${user?.first_name} ${user?.last_name}`}>
        <Avatar width={radius} src={user?.avatarUrlVerySmall} alt="avatar" />
      </WithSimpleTooltip>
    );
  }
  if (isDisplayImage) {
    return () => (
      <Avatar width={radius} src={user?.avatarUrlVerySmall} alt="avatar" borderColor={color} />
    );
  }
  if (
    isDisplayGeneratedAvatar
    && withTooltip
    && user?.first_name
    && user?.last_name
  ) {
    return () => (
      <WithSimpleTooltip text={`${user?.first_name} ${user?.last_name}`}>
        <AvatarPlaceholder
          width={radius}
          name={user?.first_name}
          abbreviation={`${user?.first_name[0]}${user?.last_name[0]}`}
        />
      </WithSimpleTooltip>
    );
  }
  if (isDisplayGeneratedAvatar) {
    return () => (
      <AvatarPlaceholder
        borderColor={color}
        width={radius}
        name={user?.first_name || ''}
        abbreviation={`${user?.first_name ? user?.first_name[0] : ''}${user?.last_name ? user?.last_name[0] : ''}`}
      />
    );
  }
  return () => <div />;
};
