import { COLUMN_CUSTOM_TYPE } from '../../../utils/constants';

const calcColor = (
  id,
  isStatic,
  dashboardColumns,
  inBoxColumn,
  closeColumn,
) => {
  if (!isStatic) return dashboardColumns[id]?.backgroundColor;
  if (id === COLUMN_CUSTOM_TYPE.INBOX) return inBoxColumn?.backgroundColor;
  return closeColumn?.backgroundColor;
};
export default calcColor;
