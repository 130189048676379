import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PLANS_DATA_FEATURES, plansMap } from '../../../../utils/SettingsHelpers/constants';
import {
  CURRENCY,
  CURRENCY_UI,
} from '../../../../utils/Translations';
import { ReactComponent as Check32Svg } from '../../../../images/icons/check_green_circle.svg';
import { GreyButton } from '../../CommonComponents/Buttons';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';
import Tooltip from '../../../../shared/Tooltips/Tooltip';
import { actionOpenModal } from '../../../../redux/user/action';
import { openModalType } from '../../../../utils/constants';
import { ServiceUser } from '../../../../redux/user/types';

const { values: { plans: T_plans, features: T_features } } = plansMap;

const PlanItemShell = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 406px;
  width: 200px;
  cursor: pointer;
`;

const PlanName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 17px;
  font-size: 12px;
  color: #596080;
`;
const PlanCard = styled.div`
  position: relative;
  background: white;
  padding-top: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-top: 1px solid #ECE2E2;
  border-bottom: 1px solid #ECE2E2;
  border-left: 1px solid #ECE2E2;

  ${({ active }) => (active
    ? `
    background: #F2F2F2;
  `
    : '')}
  ${({ first }) => (first
    ? `
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  `
    : '')}  
  ${({ last }) => (last
    ? `
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border-right: 1px solid #ECE2E2;
  `
    : '')}
`;

const PlanDescription = styled.div`
  display: flex;
  flex-direction: column;
`;
const CardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  color: #343434;
  margin-bottom: 13px;
`;
const CardFeatures = styled.div``;
const FeatureItem = styled.div`
  font-size: 12px;
  color: #585858;
  font-weight: 400;
  width: 100%;
  height: 35px;
  padding: 0 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const MoneyShell = styled.div`
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  color: #596080;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 25px;
  margin-right: 8px;
`;
const Price = styled.div`
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  color: #585858;
`;
const Period = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #585858;
`;
const ButtonSection = styled.div`
  margin-top: 22px;
  margin-bottom: 26px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FakeButton = styled.div`
  width: 77px;
  height: 32px;
  box-shadow: 0px 1px 4px 0px rgba(122, 105, 105, 0.10);
  opacity: 0.5;
  border: 1px solid #534B4F;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #534B4F;
`;

const PlanItem = ({ plan, isCardFirst, isCardLast, isActive }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { currentPlan } = useSelector((state) => state.user);

  const activateNewPlan = () => {
    if (plan === 'Free') {
      dispatch(
        actionCreator(ServiceUser.СancelSubscription),
      );
    } else if (currentPlan === 'Free') {
      dispatch(
        actionCreator(ServiceUser.RetrievePaymentIntent, {
          plan,
        }),
      );
      dispatch(actionOpenModal(openModalType.Checkout, { plan }));
    } else {
      dispatch(
        actionCreator(ServiceUser.ChangeSubscriptionTier, {
          plan,
        }),
      );
      dispatch(actionCreator(Settings.ChangePaymentPlan, { newPlan: plan }));
    }
  };

  return (
    <PlanItemShell>
      <PlanCard first={isCardFirst} last={isCardLast} active={isActive}>
        <PlanDescription>
          <CardTitle>{t(`plan${plan}TitleT`)}</CardTitle>
          <MoneyShell>
            <Price>
              {T_plans[plan].price[CURRENCY.eur]}
              {CURRENCY_UI.eur}
            </Price>
            <Period>/{t('monthT')}</Period>
          </MoneyShell>
          <CardFeatures>
            {PLANS_DATA_FEATURES[plan].map((feature, index) => (
              <Tooltip
                text={t(T_features[feature][plan].tooltipText)}
                direction="left"
                place="UpgradePlanFeatureHint"
              >
                <FeatureItem key={feature} first={!index} active={isActive}>
                  {t(T_features[feature][plan].name)}
                  <Check32Svg />
                </FeatureItem>
              </Tooltip>
            ))}
          </CardFeatures>
        </PlanDescription>

        <ButtonSection>
          {!isActive && (
            <GreyButton
              text={t('upgradeT')}
              customStyles={{
                marginRight: 5,
              }}
              primaryGrey
              onClick={activateNewPlan}
            />
          )}
          {!!isActive && <FakeButton>{t('currentUpT')}</FakeButton>}
        </ButtonSection>
      </PlanCard>
    </PlanItemShell>
  );
};

export default PlanItem;
