
import {
  $createCodeNode,
  $isCodeNode,
  CODE_LANGUAGE_MAP,
} from '@lexical/code';

import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
  $isListNode,
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
} from '@lexical/list';
// import { INSERT_EMBED_COMMAND } from '@lexical/react/LexicalAutoEmbedPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
  $isQuoteNode,
} from '@lexical/rich-text';
import {
  $getSelectionStyleValueForProperty,
  $isParentElementRTL,
  $patchStyleText,
  $setBlocksType,
} from '@lexical/selection';
import { $isTableNode, $isTableSelection } from '@lexical/table';
import {
  $findMatchingParent,
  $getNearestBlockElementAncestorOrThrow,
  $getNearestNodeOfType,
  mergeRegister,
} from '@lexical/utils';
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_NORMAL,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  KEY_MODIFIER_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FOCUS_COMMAND,
} from 'lexical';
import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';

import { useTranslation } from 'react-i18next';
import useModal from '../../../../shared/lexical/hooks/useModal';
import DropDown, { DropDownItem } from '../../../../shared/lexical/ui/DropDown';
import DropdownColorPicker from '../../../../shared/lexical/ui/DropdownColorPicker';
import { getSelectedNode } from '../../../../shared/lexical/utils/getSelectedNode';
import { sanitizeUrl } from '../../../../shared/lexical/utils/url';


import { DATA_PARENTS } from '../../../../utils/constants';
import { InsertTableDialog } from '../../../../pages/Lexical/plugin/TablePlugin';
import { FontDropDown } from '../../../../shared/lexical/DropDown/FontDropDown';
import i18n from '../../../../i18n';

const IS_APPLE = true;

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
};


const ELEMENT_FORMAT_OPTIONS = {
  center: {
    icon: 'center-align',
    iconRTL: 'center-align',
    name: i18n.t('centerAlignT'),
  },
  end: {
    icon: 'right-align',
    iconRTL: 'left-align',
    name: i18n.t('endAlignT'),
  },
  justify: {
    icon: 'justify-align',
    iconRTL: 'justify-align',
    name: i18n.t('justifyAlignT'),
  },
  left: {
    icon: 'left-align',
    iconRTL: 'left-align',
    name: i18n.t('leftAlignT'),
  },
  right: {
    icon: 'right-align',
    iconRTL: 'right-align',
    name: i18n.t('rightAlignT'),
  },
  start: {
    icon: 'left-align',
    iconRTL: 'right-align',
    name: i18n.t('startAlignT'),
  },
};

function dropDownActiveClass(active) {
  if (active) {
    return 'active dropdown-item-active';
  }
  return '';
}

function BlockFormatDropDown({
  editor,
  blockType,
  disabled = false,
}) {
  const { t } = useTranslation();

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatHeading = (headingSize) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  const formatCheckList = () => {
    if (blockType !== 'check') {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createQuoteNode());
      });
    }
  };

  const formatCode = () => {
    if (blockType !== 'code') {
      editor.update(() => {
        let selection = $getSelection();

        if (selection !== null) {
          if (selection.isCollapsed()) {
            $setBlocksType(selection, () => $createCodeNode());
          } else {
            const textContent = selection.getTextContent();
            const codeNode = $createCodeNode();
            selection.insertNodes([codeNode]);
            selection = $getSelection();
            if ($isRangeSelection(selection)) {
              selection.insertRawText(textContent);
            }
          }
        }
      });
    }
  };

  return (
    <DropDown
      disabled={disabled}
      data-parent={DATA_PARENTS.TextFormatToolbar}
      buttonClassName="toolbar-item block-controls"
      buttonIconClassName={`icon block-type ${blockType}`}
      editor={editor}
      // buttonLabel={blockTypeToBlockName[blockType]}
      buttonAriaLabel="Formatting options for text style"
    >
      <div className="annotation">{t('formatUpT')}</div>

      <DropDownItem
        className="item"
        onClick={formatParagraph}
      >
        <i className="icon paragraph" />
        <span className="text">{t('normalT')}</span>
      </DropDownItem>
      <DropDownItem
        className="item"
        onClick={() => formatHeading('h1')}
      >
        <i className="icon h1" />
        <span className="text">{t('title1T')}</span>
      </DropDownItem>
      <DropDownItem
        className="item"
        onClick={() => formatHeading('h2')}
      >
        <i className="icon h2" />
        <span className="text">{t('title2T')}</span>
      </DropDownItem>
      <DropDownItem
        className="item"
        onClick={() => formatHeading('h3')}
      >
        <i className="icon h3" />
        <span className="text">{t('title3T')}</span>
      </DropDownItem>
      <DropDownItem
        className="item"
        onClick={formatBulletList}
      >
        <i className="icon bullet-list" />
        <span className="text">{t('bulletedListT')}</span>
      </DropDownItem>
      <DropDownItem
        className="item"
        onClick={formatNumberedList}
      >
        <i className="icon numbered-list" />
        <span className="text">{t('numberedListT')}</span>
      </DropDownItem>
      <DropDownItem
        className="item"
        onClick={formatCheckList}
      >
        <i className="icon check-list" />
        <span className="text">{t('checkboxT')}</span>
      </DropDownItem>
      <DropDownItem
        className="item"
        onClick={formatQuote}
      >
        <i className="icon quote" />
        <span className="text">{t('quoteT')}</span>
      </DropDownItem>
      <DropDownItem
        className="item"
        onClick={formatCode}
      >
        <i className="icon code" />
        <span className="text">{t('codeBlockT')}</span>
      </DropDownItem>
    </DropDown>
  );
}

function InsertDropDown({
  editor,
  showModal,
  disabled = false,
}) {
  const { t } = useTranslation();
  const formatCode = () => {
    editor.dispatchCommand(
      INSERT_HORIZONTAL_RULE_COMMAND,
      undefined,
    );
  };

  return (
    <DropDown
      disabled={disabled}
      editor={editor}
      buttonLabel={t('insertT')}
      buttonClassName="toolbar-item block-controls"
      buttonIconClassName="icon plus"
      buttonAriaLabel="Formatting options for text style"
    >
      <div className="annotation">{t('insertUpT')}</div>
      <DropDownItem
        onClick={() => {
          showModal('Insert Table', (onClose) => (
            <InsertTableDialog
              activeEditor={editor}
              onClose={onClose}
            />
          ));
        }}
        className="item"
      >
        <i className="icon table" />
        <span className="text">{t('tableT')}</span>
      </DropDownItem>
      <DropDownItem
        className="item"
        onClick={formatCode}
      >
        <i className="icon horizontal-rule" />
        <span className="text">{t('dividerT')}</span>
      </DropDownItem>
    </DropDown>
  );
}

function Divider() {
  return <div className="divider" />;
}

function ElementFormatDropdown({
  editor,
  value,
  isRTL,
  disabled = false,
}) {
  const { t } = useTranslation();
  const formatOption = ELEMENT_FORMAT_OPTIONS[value || 'left'];

  return (
    <DropDown
      disabled={disabled}
      editor={editor}
      buttonLabel={formatOption.name}
      buttonIconClassName={`icon ${
        isRTL ? formatOption.iconRTL : formatOption.icon
      }`}
      buttonClassName="toolbar-item spaced alignment"
      buttonAriaLabel="Formatting options for text alignment"
    >
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
        }}
        className="item"
      >
        <i className="icon left-align" />
        <span className="text">{t('leftAlignT')}</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
        }}
        className="item"
      >
        <i className="icon center-align" />
        <span className="text">{t('centerAlignT')}</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
        }}
        className="item"
      >
        <i className="icon right-align" />
        <span className="text">{t('rightAlignT')}</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
        }}
        className="item"
      >
        <i className="icon justify-align" />
        <span className="text">{t('justifyAlignT')}</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'start');
        }}
        className="item"
      >
        <i
          className={`icon ${
            isRTL
              ? ELEMENT_FORMAT_OPTIONS.start.iconRTL
              : ELEMENT_FORMAT_OPTIONS.start.icon
          }`}
        />
        <span className="text">{t('startAlignT')}</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'end');
        }}
        className="item"
      >
        <i
          className={`icon ${
            isRTL
              ? ELEMENT_FORMAT_OPTIONS.end.iconRTL
              : ELEMENT_FORMAT_OPTIONS.end.icon
          }`}
        />
        <span className="text">{t('endAlignT')}</span>
      </DropDownItem>
      <Divider />
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
        }}
        className="item"
      >
        <i className={`icon ${isRTL ? 'indent' : 'outdent'}`} />
        <span className="text">{t('outdentT')}</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
        }}
        className="item"
      >
        <i className={`icon ${isRTL ? 'outdent' : 'indent'}`} />
        <span className="text">{t('indentT')}</span>
      </DropDownItem>
    </DropDown>
  );
}

export default function ToolbarPlugin({
  setIsLinkEditMode,
  setShowToolbar,
  isLinkEditMode,
  showTollbar,
}) {
  const { t } = useTranslation();
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] = useState('paragraph');
  const [rootType, setRootType] = useState('root');
  const [selectedElementKey, setSelectedElementKey] = useState(
    null,
  );
  const [fontSize, setFontSize] = useState('16px');
  const [fontColor, setFontColor] = useState('#000');
  const [bgColor, setBgColor] = useState('#fff');
  const [fontFamily, setFontFamily] = useState('Roboto');
  const [elementFormat, setElementFormat] = useState('left');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isSubscript, setIsSubscript] = useState(false);
  const [isSuperscript, setIsSuperscript] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [modal, showModal] = useModal();
  const [isRTL, setIsRTL] = useState(false);
  const [codeLanguage, setCodeLanguage] = useState('');
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());

  useEffect(() => {
    editor.registerCommand(
      FOCUS_COMMAND,
      () => setShowToolbar(true),
      COMMAND_PRIORITY_EDITOR,
    );
  }, []);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    // console.log({ selection });
    // if ($isRangeSelection(selection)) {
    //   setHidden(false);
    // } else { setHidden(true); }
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element = anchorNode.getKey() === 'root'
        ? anchorNode
        : $findMatchingParent(anchorNode, (e) => {
          const parent = e.getParent();
          return parent !== null && $isRootOrShadowRoot(parent);
        });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }
      //
      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsSubscript(selection.hasFormat('subscript'));
      setIsSuperscript(selection.hasFormat('superscript'));
      setIsCode(selection.hasFormat('code'));
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      const tableNode = $findMatchingParent(node, $isTableNode);
      if ($isTableNode(tableNode)) {
        setRootType('table');
      } else {
        setRootType('root');
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(
            anchorNode,
            ListNode,
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type);
          }
          if ($isCodeNode(element)) {
            const language = element.getLanguage();
            setCodeLanguage(
              language ? CODE_LANGUAGE_MAP[language] || language : '',
            );
            return;
          }
        }
      }
      //   // Handle buttons
      setFontColor(
        $getSelectionStyleValueForProperty(selection, 'color', '#000'),
      );
      setBgColor(
        $getSelectionStyleValueForProperty(
          selection,
          'background-color',
          '#fff',
        ),
      );
      setFontFamily(
        $getSelectionStyleValueForProperty(selection, 'font-family', 'Arial'),
      );
      let matchingParent;
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline(),
        );
      }

      // If matchingParent is a valid node, pass it's format type
      setElementFormat(
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
            ? node.getFormatType()
            : parent?.getFormatType() || 'left',
      );
    }
    if ($isRangeSelection(selection) || $isTableSelection(selection)) {
      setFontSize(
        $getSelectionStyleValueForProperty(selection, 'font-size', '16px'),
      );
    }
  }, [activeEditor]);


  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      activeEditor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      activeEditor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    );
  }, [$updateToolbar, activeEditor, editor]);
  //
  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (payload) => {
        const event = payload;
        const { code, ctrlKey, metaKey } = event;

        if (code === 'KeyK' && (ctrlKey || metaKey)) {
          event.preventDefault();
          let url;
          if (!isLink) {
            setIsLinkEditMode(true);
            url = sanitizeUrl('https://');
          } else {
            setIsLinkEditMode(false);
            url = null;
          }
          return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL,
    );
  }, [activeEditor, isLink, setIsLinkEditMode]);

  const applyStyleText = useCallback(
    (styles, skipHistoryStack) => {
      activeEditor.update(
        () => {
          const selection = $getSelection();
          if (selection !== null) {
            $patchStyleText(selection, styles);
          }
        },
        skipHistoryStack ? { tag: 'historic' } : {},
      );
    },
    [activeEditor],
  );

  const clearFormatting = useCallback(() => {
    activeEditor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) || $isTableSelection(selection)) {
        const anchor = selection.anchor;
        const focus = selection.focus;
        const nodes = selection.getNodes();
        const extractedNodes = selection.extract();

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return;
        }

        nodes.forEach((node, idx) => {
          // We split the first and last node by the selection
          // So that we don't format unselected text inside those nodes
          if ($isTextNode(node)) {
            // Use a separate variable to ensure TS does not lose the refinement
            let textNode = node;
            if (idx === 0 && anchor.offset !== 0) {
              textNode = textNode.splitText(anchor.offset)[1] || textNode;
            }
            if (idx === nodes.length - 1) {
              textNode = textNode.splitText(focus.offset)[0] || textNode;
            }
            /**
                         * If the selected text has one format applied
                         * selecting a portion of the text, could
                         * clear the format to the wrong portion of the text.
                         *
                         * The cleared text is based on the length of the selected text.
                         */
            // We need this in case the selected text only has one format
            const extractedTextNode = extractedNodes[0];
            if (nodes.length === 1 && $isTextNode(extractedTextNode)) {
              textNode = extractedTextNode;
            }

            if (textNode.__style !== '') {
              textNode.setStyle('');
            }
            if (textNode.__format !== 0) {
              textNode.setFormat(0);
              $getNearestBlockElementAncestorOrThrow(textNode).setFormat('');
            }
            node = textNode;
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true);
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat('');
          }
        });
      }
    });
  }, [activeEditor]);

  const onFontColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ color: value }, skipHistoryStack);
    },
    [applyStyleText],
  );

  const onBgColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ 'background-color': value }, skipHistoryStack);
    },
    [applyStyleText],
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true);
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'));
    } else {
      setIsLinkEditMode(false);
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink, setIsLinkEditMode]);

  const onCodeLanguageSelect = useCallback(
    (value) => {
      activeEditor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(value);
          }
        }
      });
    },
    [activeEditor, selectedElementKey],
  );

  return (
    <div
      data-parent={DATA_PARENTS.TextFormatToolbar}
      className={`toolbar ${!showTollbar && !isLinkEditMode ? 'toolbar-hidden' : ''}`}
    >

      {blockType in blockTypeToBlockName && activeEditor === editor && (
        <>
          <BlockFormatDropDown
            disabled={!isEditable}
            blockType={blockType}
            rootType={rootType}
            editor={editor}
          />
          <Divider />
        </>
      )}
      <>
        <FontDropDown
          disabled={!isEditable}
          styles="font-family"
          value={fontFamily}
          editor={editor}
          annotation="Sans serif"
        />
        <Divider />
        <FontDropDown
          disabled={!isEditable}
          value={fontSize}
          annotation={t('sizeFilterT')}
          styles="font-size"
          editor={editor}
        />
        <Divider />
        <button
          disabled={!isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
          }}
          className={`toolbar-item spaced ${isBold ? 'active' : ''}`}
          title={t('boldT') + (IS_APPLE ? ' (⌘B)' : ' (Ctrl+B)')}
          type="button"
          aria-label={`Format text as bold. Shortcut: ${
            IS_APPLE ? '⌘B' : 'Ctrl+B'
          }`}
        >
          <i className="format bold" />
        </button>
        <button
          disabled={!isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
          }}
          className={`toolbar-item spaced ${isItalic ? 'active' : ''}`}
          title={t('italicT') + (IS_APPLE ? ' (⌘I)' : ' (Ctrl+I)')}
          type="button"
          aria-label={`Format text as italics. Shortcut: ${
            IS_APPLE ? '⌘I' : 'Ctrl+I'
          }`}
        >
          <i className="format italic" />
        </button>
        <button
          disabled={!isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
          }}
          className={`toolbar-item spaced ${isUnderline ? 'active' : ''}`}
          title={t('underlineT') + (IS_APPLE ? ' (⌘U)' : ' (Ctrl+U)')}
          type="button"
          aria-label={`Format text to underlined. Shortcut: ${
            IS_APPLE ? '⌘U' : 'Ctrl+U'
          }`}
        >
          <i className="format underline" />
        </button>
        <button
          disabled={!isEditable}
          onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code');
          }}
          className={`toolbar-item spaced ${isCode ? 'active' : ''}`}
          title={t('insertCodeBlockT')}
          type="button"
          aria-label="Insert code block"
        >
          <i className="format code" />
        </button>
        <button
          disabled={!isEditable}
          onClick={insertLink}
          data-parent={DATA_PARENTS.TextFormatToolbar}
          className={`toolbar-item spaced ${isLink ? 'active' : ''}`}
          aria-label="Insert link"
          title={t('insertLinkT')}
          type="button"
        >
          <i className="format link" />
        </button>
        <DropdownColorPicker
          disabled={!isEditable}
          buttonClassName="toolbar-item color-picker hasChevron"
          dropdownClassName="full_size"
          buttonAriaLabel="Formatting text color"
          buttonIconClassName="icon font-color"
          color={fontColor}
          onChange={onFontColorSelect}
          title={t('textColorLowT')}
        />
        <DropdownColorPicker
          disabled={!isEditable}
          buttonClassName="toolbar-item color-picker  hasChevron"
          dropdownClassName="full_size"
          buttonAriaLabel="Formatting background color"
          buttonIconClassName="icon bg-color"
          color={bgColor}
          onChange={onBgColorSelect}
          title={t('bgColorLowT')}
        />
        <DropDown
          disabled={!isEditable}
          editor={editor}
          buttonClassName="toolbar-item spaced"
          buttonLabel=""
          buttonAriaLabel="Formatting options for additional text styles"
          buttonIconClassName="icon dropdown-more"
        >
          <DropDownItem
            onClick={() => {
              activeEditor.dispatchCommand(
                FORMAT_TEXT_COMMAND,
                'strikethrough',
              );
            }}
            className={`item ${dropDownActiveClass(isStrikethrough)}`}
            title={t('strikethroughT')}
            aria-label="Format text with a strikethrough"
          >
            <i className="icon strikethrough" />
            <span className="text">{t('strikethroughT')}</span>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript');
            }}
            className={`item ${dropDownActiveClass(isSubscript)}`}
            title={t('subscriptT')}
            aria-label="Format text with a subscript"
          >
            <i className="icon subscript" />
            <span className="text">{t('subscriptT')}</span>
          </DropDownItem>
          <DropDownItem
            onClick={() => {
              activeEditor.dispatchCommand(
                FORMAT_TEXT_COMMAND,
                'superscript',
              );
            }}
            className={`item ${dropDownActiveClass(isSuperscript)}`}
            title={t('superscriptT')}
            aria-label="Format text with a superscript"
          >
            <i className="icon superscript" />
            <span className="text">{t('superscriptT')}</span>
          </DropDownItem>
          <DropDownItem
            onClick={clearFormatting}
            className="item"
            title={t('clearTextFormattingT')}
            aria-label="Clear all text formatting"
          >
            <i className="icon clear" />
            <span className="text">{t('clearFormattingT')}</span>
          </DropDownItem>
        </DropDown>
        <Divider />
        <InsertDropDown
          showModal={showModal}
          disabled={!isEditable}
          blockType={blockType}
          rootType={rootType}
          editor={editor}
        />
      </>
      <Divider />
      <ElementFormatDropdown
        disabled={!isEditable}
        value={elementFormat}
        editor={editor}
        isRTL={isRTL}
      />

      {modal}
    </div>
  );
}
