import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { TitleTextAreaComponent } from './PlaylistTitleTextArea';
import { CurrentPage } from '../../../redux/currentPage/types';
import { SizeWrapper, FatDot } from '../MakerCardView/PlaylistElementCard/styled';

const TitleShell = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 55px);
`;

const PlaylistTitleInput = ({
  title = 'Title',
  type,
  renameRef,
  linkPageId,
  isCompressView,
  calculatedSizeMB,
  setRenameMode,
  renameMode,
  isExpandedView,
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const { id: playlistId } = useSelector((state) => state.currentPage);
  const [renameClickOutsideRef, isRenameActive, setIsRenameActive] = useComponentVisible('close');

  useEffect(() => {
    if (!renameRef?.current) return;
    if (renameRef?.current) setIsRenameActive(true);
    if (!isRenameActive) renameRef.current = false;
  }, [renameRef?.current, isRenameActive]);

  const onRenameItemHandler = (newTitle) => {
    if (isDisabled) return;
    dispatch(
      actionCreator(CurrentPage.RenameLinkedPage, {
        playlistId,
        linkPageId,
        newTitle,
      }),
    );
  };
  const prevent = (e) => {
    e.stopPropagation();
  };

  const setIsRenameActiveHandler = (value) => {
    if (isDisabled) return;
    setIsRenameActive(value);
  };

  return (
    <TitleShell
      onMouseDown={prevent}
      ref={renameClickOutsideRef}
    >
      <TitleTextAreaComponent
        isExpandedView={isExpandedView}
        itemType={type}
        title={title}
        isCompressView={isCompressView}
        renameCB={onRenameItemHandler}
        show={isRenameActive}
        setShow={setIsRenameActiveHandler}
        setRenameMode={setRenameMode}
        renameMode={renameMode}
        isDisabled={isDisabled}
      />
      {isCompressView && (
        <>
          <FatDot> · </FatDot>
          <SizeWrapper>{calculatedSizeMB}</SizeWrapper>
        </>
      )}
    </TitleShell>
  );
};

export default PlaylistTitleInput;
