import React from 'react';
import classNames from 'classnames/bind';
import NavSlider from '../NavigationSlider/NavSlider';
import moduleStyles from '../../pages/Library/LibraryHeader.module.scss';
import { ChannelsSieveTypes } from '../../utils/constants';
import SecondaryMenu from './childComponents/SecondaryMenu';

const styles = classNames.bind(moduleStyles);

const ChannelHeader = (props) => {
  return (
    <div className={styles('header_container')}>
      <div
        className={styles('slider_and_sort_container', 'orders', 'channels', {
          forChannels: true,
        })}
      >
        <div className={styles('slider_parent', 'isChannels')}>
          <NavSlider
            setSieve={props.setSieve}
            setActiveNavSlider={props.setActiveNavSlider}
            activeNavSlider={props.activeNavSlider}
            usage="channels"
            permissions={props.permissions}
            isCardView={props.isCardsView}
            items={props.sliderNumbersObject || props.items}
            totalSubscribers={props.totalSubscribers}
            sieveOptions={props.sieveOptions}
            isBottomMargin
            isNoLeftMargin
            isDisableBottomMargin
            isChannels
            isHasVerticalLine
          >
            {(ChannelsSieveTypes.ChannelsAll === props.activeNavSlider
              || ChannelsSieveTypes.ChannelsFavorite === props.activeNavSlider
              || ChannelsSieveTypes.ChannelsMembers === props.activeNavSlider) && (
              <SecondaryMenu
                place={props.activeNavSlider}
                setSort={props.setSort}
              />
            )}
          </NavSlider>
        </div>
      </div>
    </div>
  );
};

export default ChannelHeader;
