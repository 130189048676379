import React from 'react';
import styled from 'styled-components/macro';

const UserShell = styled.div`
  display: flex;
  height: 24px;

  ${({ forUPV }) => (forUPV
    ? ''
    : `    
     position: relative;
    left: 7px;
`)}
`;

const AvatarImage = styled.div`
  width: ${({ radius }) => `${radius}px` || '20px'};
  height: 20px;
  margin-right: 10px;
  position: relative;
  top: -4px;
}
`;

const UserName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #484343;
`;

const UniversalPlaylistUserUI = ({ AvatarComponent, name, radius, forUPV }) => {
  return (
    <UserShell forUPV={forUPV}>
      <AvatarImage radius={radius}>
        <AvatarComponent />
      </AvatarImage>

      <UserName>{name}</UserName>
    </UserShell>
  );
};

export default UniversalPlaylistUserUI;
