import React from 'react';
import classnames from 'classnames/bind';
import styled from 'styled-components';
import rawStyles from './index.module.scss';

const cn = classnames.bind(rawStyles);

const ButtonComponent = ({ text, ...props }) => {
  return (
    <div
      style={{
        ...(props.height ? { height: props.height } : {}),
        ...(props.borderColor ? { borderColor: props.borderColor } : {}),
      }}
      className={cn('button_container', {
        delete: props.isDelete,
        fullParentWidth: props.fullWidth,
        widthFit: props.widthFit,
        isBlue: props.isBlue,
        isDark: props.isDark,
        isNoPointer: props.isNoPointer,
        isComputerTab: props.isComputerTab,
        isSignIn: props.isSignIn,
        isContinueWith: props.isContinueWith,
      })}
      onClick={props.clickCb}
    >
      <div
        className={cn('icon_container', {
          delete: props.isDelete,
          forceSvgStyles: !!text,
        })}
      >
        {props.children}
      </div>
      <div
        className={cn('button_text', {
          delete: props.isDelete,
          no_line_break: props.noLineBreak,
          isSignIn: props.isSignIn,
        })}
      >
        {text}
      </div>
    </div>
  );
};

const FullWidthWrap = styled.div`
  width: 100%;
  height: auto;
`;

const Title = styled.h3`
  display: block;
  width: 100%;
  margin: 16px 0 8px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #939393;
`;
const Description = styled.div`
  height: 36px;
  left: 0;
  right: 64px;
  top: 24px;
  font-family: "RobotoFlex", sans-serif;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #343434;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const ChannelButton = ({
  description,
  buttonText,
  title,
  clickCb = () => {},
}) => {
  return (
    <FullWidthWrap>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <ButtonWrapper>
        <ButtonComponent clickCb={clickCb} text={buttonText} isDelete />
      </ButtonWrapper>
    </FullWidthWrap>
  );
};

export const PlaylistButton = ({
  buttonText,
  clickCb = () => {},
  isBlue,
  ...props
}) => {
  return (
    <FullWidthWrap>
      <div
        data-cy={buttonText}
        className={cn('playlist_button_container', {
          blue: isBlue,
          isDisabled: props.isDisabled,
        })}
        onClick={clickCb}
      >
        <div className={cn('playlist_button_text', { blue: isBlue })}>
          {props.children}
          {buttonText}
        </div>
      </div>
    </FullWidthWrap>
  );
};

export const PlaylistButtonHoverSwitch = ({
  button1Text,
  button2Text,
  clickHandler,
  isBlue,
  ...props
}) => {
  return (
    <div className={cn('playlist_button_wrapper')}>
      <div
        className={cn('playlist_button_container', 'buttonInit', {
          blue: isBlue,
          isDisabled: props.isDisabled,
        })}
      >
        <div className={cn('playlist_button_text', { blue: isBlue })}>
          {props.children}
          {button1Text}
        </div>
      </div>
      <div
        className={cn('playlist_button_container', 'buttonShow', {
          blue: isBlue,
          isDisabled: props.isDisabled,
        })}
        onClick={clickHandler}
      >
        <div className={cn('playlist_button_text', { blue: isBlue })}>
          {props.children}
          {button2Text}
        </div>
      </div>
    </div>
  );
};

export default ButtonComponent;
