import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import rawStyles from './index.module.scss';
import { actionCreator } from '../../shared/redux/actionHelper';
import { HelpActions } from '../../redux/help/types';

const LEFT = 'left';
const RIGHT = 'right';
const TOP = 'top';
const BOTTOM = 'bottom';

const cn = classNames.bind(rawStyles);

const HelpDragHandler = ({ previousItemId, nextItemId, currentItemId }) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(true);
  const [place, setPlace] = useState(false);
  const [dragDepth, setDragDepth] = useState(0);
  const { idDragPage, isCardView } = useSelector((state) => state.currentPage);

  useEffect(() => {
    if (dragDepth !== 0) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [dragDepth]);

  const onDragOver = (e, dropSide) => {
    e.stopPropagation();
    e.preventDefault();
    setPlace(dropSide);
  };

  const onDragEnter = (e, dropSide) => {
    e.stopPropagation();
    e.preventDefault();
    setPlace(dropSide);
    setDragDepth((state) => state + 1);
  };
  const onDragLeave = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setPlace(false);
    if (dragDepth <= 0) setDragDepth(0);
    setDragDepth((state) => state - 1);
  };

  const placeSelector = (first, second) => (isCardView ? first : second);

  const dropIntoNewPosition = (dropSide) => {
    if (idDragPage === currentItemId) return;

    const payload = dropSide === LEFT || dropSide === TOP
      ? {
        leftItem: previousItemId,
        rightItem: currentItemId,
      }
      : {
        leftItem: currentItemId,
        rightItem: nextItemId,
      };

    dispatch(actionCreator(HelpActions.RearrangeHelpPlaylistS, payload));
  };

  const onDrop = (e, dropSide) => {
    e.stopPropagation();
    e.preventDefault();
    setIsActive(false);
    setPlace(false);
    setDragDepth(0);
    dropIntoNewPosition(dropSide);
  };

  const stopEverything = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  return (
    <div className={cn('wrapper', { row: !isCardView })}>
      <div
        onDragOver={stopEverything}
        className={cn('drop_line', `${placeSelector(LEFT, TOP)}`, {
          active: isActive && (place === LEFT || place === TOP),
          row_view: !isCardView,
        })}
      />
      <div
        onDragEnter={(e) => onDragEnter(e, placeSelector(LEFT, TOP))}
        onDragOver={(e) => onDragOver(e, placeSelector(LEFT, TOP))}
        onDrop={(e) => onDrop(e, placeSelector(LEFT, TOP))}
        onDragLeave={(e) => onDragLeave(e, placeSelector(LEFT, TOP))}
        className={cn('drop_zone', `${placeSelector(LEFT, TOP)}`, {
          active: !!idDragPage,
          row_view: !isCardView,
        })}
      />
      <div
        onDragEnter={(e) => onDragEnter(e, placeSelector(RIGHT, BOTTOM))}
        onDragOver={(e) => onDragOver(e, placeSelector(RIGHT, BOTTOM))}
        onDrop={(e) => onDrop(e, placeSelector(RIGHT, BOTTOM))}
        onDragLeave={(e) => onDragLeave(e, placeSelector(RIGHT, BOTTOM))}
        className={cn('drop_zone', `${placeSelector(RIGHT, BOTTOM)}`, {
          active: !!idDragPage,
          row_view: !isCardView,
        })}
      />
      <div
        onDragOver={stopEverything}
        className={cn('drop_line', `${placeSelector(RIGHT, BOTTOM)}`, {
          active: isActive && (place === RIGHT || place === BOTTOM),
          row_view: !isCardView,
        })}
      />
    </div>
  );
};

export default HelpDragHandler;
