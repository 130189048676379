import React from 'react';
import styled from 'styled-components';
import { SHORTCUTS_UI_MAP } from '../../../../../utils/SettingsHelpers/constants';
import { Item, Row } from '../../../CommonComponents/Table';

const Sequence = styled.div`
  display: flex;
`;

const SequenceItem = styled.div`
  background: #FFDF85;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
   ${({ isLetter }) => (isLetter ? 'background: #FFC72C;' : '')}
`;

const Plus = styled.div`
  margin: 0 6px;
`;

const ShortcutsListItem = ({ shortcut }) => {
  return (
    <Row isShortcuts>
      <Item isShortcuts name>
        {shortcut.name}
      </Item>
      <Item isShortcuts type>
        {shortcut.type}
      </Item>
      <Item sequence last isShortcuts>
        {shortcut.sequences.map((item, index) => (
          <Sequence key={item}>
            {!!index && <Plus>+</Plus>}
            <SequenceItem isLetter={item.length === 1
              && shortcut.sequences.length - 1 === index}
            >
              {SHORTCUTS_UI_MAP[item] ?? item}
            </SequenceItem>
          </Sequence>
        ))}
      </Item>
    </Row>
  );
};

export default ShortcutsListItem;
