import styled from 'styled-components/macro';


const hiddenBySize = {
  firstStep: 1400,
  twoStep: 1200,
  lastStep: 900,
};

export const InboxCardButtonsWrapper = styled.div`
  display: none;
  width: 10%;
  flex: none;
  justify-content: end;
  @media (max-width: 1100px) {
    width: 19%;
  }
  height: 27px;
  @media (max-width: ${hiddenBySize.lastStep}px) {
    order: 2;
    margin: 0;
  }
`;

export const TimeAndAvatar = styled.div`
  white-space: nowrap;
  width: 10%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  height: 27px;
  @media (max-width: 1100px) {
    width: 19%;
  }

  @media (max-width: ${hiddenBySize.lastStep}px) {
    order: 2;
    margin: 0;
  }
`;

export const Time = styled.p`
  white-space: nowrap;
  margin: 0;
  color: #484343;
`;

export const CardReadMarker = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background: #F5B400;
  border-radius: 50%;
  display: block;
  ${({ isRead, isSelected }) => (isRead || isSelected ? 'display: none;' : '')}
`;

export const SelectCheckBoxWrapper = styled.div`
  position: absolute;
  left: -8px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;

  svg path {
    stroke: #484343;
  }
  ${({ isSelected }) => (isSelected
    ? ` 
      background: #FFC72C;
      visibility: visible;
  ` : ''
  )}
  :hover {
    background: #FFEEBF;
  }
`;

export const CardBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  //& > div {  border: red 1px solid;}
  @media (max-width: ${hiddenBySize.lastStep}px) {
    flex-wrap: wrap;
  }
  :hover {
    ${InboxCardButtonsWrapper} {
      display: flex;
    }
    ${TimeAndAvatar} {
      display: none;
    }
    ${CardReadMarker} {
      display: none;
    }
    ${SelectCheckBoxWrapper} {
      visibility: visible;
    }
  }

`;

export const ImageWrap = styled.img`
  object-fit: cover;
  border-radius: 3px;
  height: 30px;
  width: 30px;
`;

export const ImageWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.15);
  background: #ffffff;
  position: relative;
  svg {
    z-index: 10;
    position: absolute;

    ${({ isFolderDefault }) => (!isFolderDefault
    ? ''
    : `
    width: 30px;  
    height: 30px;`
  )}
  }

`;
export const HeaderWrapper = styled.div`
  padding-left: 37px;
  padding-right: 63px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  & > div {
    //border: red 1px solid;
    color: #585858;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
  }
  @media (max-width: ${hiddenBySize.lastStep}px) {
    display: none;
  }
`;

export const CardInfo = styled.div`
  display: flex;
  width: 19%;
  align-items: center;
  flex: none;

  @media (max-width: ${hiddenBySize.firstStep}px) {
    width: 27.8%;
  }
  @media (max-width: ${hiddenBySize.twoStep}px) {
    width: 35%;
  }
  @media (max-width: ${hiddenBySize.lastStep}px) {
    order: 1;
    width: 54.75%;
  }
`;


export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30px;
  width: 12%;
  flex: none;
  white-space: nowrap;
  justify-content: center;
  @media (max-width: ${hiddenBySize.twoStep}px) {
    flex-direction: row;
    width: 70%;
    display: none;
  }
  ${({ isRight }) => (isRight
    ? 'align-items: flex-end;'
    : 'align-items: flex-start;'
  )}

  ${({ isPage }) => (isPage
    ? '  align-items: end;'
    : ''
  )}
`;

export const CardInfoSize = styled(InfoBlock)`
  width: 8%;
  color: #484343;
  @media (max-width: ${hiddenBySize.firstStep}px) {
    display: none;
  }
`;

export const CardInfoChannel = styled(InfoBlock)`
  width: 15%;
  @media (max-width: ${hiddenBySize.firstStep}px) {
    display: none;
  }

`;

export const InfoBlockTime = styled.div`
  font-weight: 400;
  margin-left: 5px;
`;

export const InviteChannelName = styled.span`
  margin-left: 5px;
  color: #585858;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  text-decoration-line: underline;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const SenderShare = styled.span`
  color: #343434;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  width: 82%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 24px;
  display: flex;

  svg {
    margin-right: 6px;
  }

  @media (max-width: 1600px){
    width: 77%;
  }

  @media (max-width: 1400px){
    width: 70%;
  }

  @media (max-width: ${hiddenBySize.lastStep}px){
    width: 204px;
  }
`;

export const Title = styled.span`
  color: #484343;
  font-size: 14px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: ${hiddenBySize.lastStep}px) {
    padding: 0;
  }
`;

export const CardBodyInfo = styled.div`
    font-size: 14px;
    letter-spacing: 0.1px;
    margin-left: 12px;
    ${({ isPlaylist }) => (isPlaylist ? `
          flex-direction: column;
          display: flex;` : 'width: 100%;')}
    overflow: hidden;
    width: 22%;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    white-space: nowrap;
    ${Title} {
      padding-left: 14px;
    }
    flex: none;

    p {
      color: #585858;
      font-weight: 400;
      margin: 0 0 4px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

  @media (max-width: ${hiddenBySize.firstStep}px) {
    width: 28%;
  }
  @media (max-width: ${hiddenBySize.twoStep}px) {
    width: 40%;
  }
    @media (max-width: ${hiddenBySize.lastStep}px) {
      order: 3;
      margin-left: 23px;
      width: 95%;
    }
`;

export const ImgWrap = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background: #f6f6f5;
  object-fit: cover;
  overflow: hidden;
  flex: none;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.14);
`;

export const CardBadge = styled.div`
    border-radius: 12px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    padding: 3px 6px;
    font-size: 10px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    line-height: 12px; 
    background: ${({ isUpdate }) => (isUpdate ? '#FF8A00' : '#FB4633')};
`;

export const CardBadgeWrapper = styled.div`
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
 ${({ isPlaceHolder }) => (isPlaceHolder ? 'opacity: 0;' : '')};
 ${({ isAbsolute }) => (isAbsolute ? `
  position: absolute;
  right: 27px;
  top: 6px;
 ` : '')};
    
`;

export const BoardColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 9%;
  margin-right: 21px;
  flex: none;
  @media (max-width: 1700px) {
    width: 11%;
  }
  //
  //@media (max-width: 1700px) {
  //  width: 10%;
  //}

  @media (max-width: 1300px) {
    display: none;
  }

`;

export const Message = styled.div`
  color: ${({ isOpenToRead }) => (isOpenToRead ? '#159D00' : '#E51B06')};
  font-size: 14px;
  text-align: right;
  width: 100%;
`;
