import React from 'react';
import styled from 'styled-components/macro';
import { axiosAbortarium } from '../../utils/axiosAbortarium';

const UndoWrapper = styled.div`
  position: absolute;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  left: 152px;
  top: 4px;
  cursor: pointer;
`;
const Undo = ({ dataID }) => {
  const abort = axiosAbortarium.getAbort(dataID);
  return <UndoWrapper onClick={() => abort('canceled')}>UNDO</UndoWrapper>;
};

export default Undo;
