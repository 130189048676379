import { InboxAction } from './types';
import downloadStatus from '../../utils/dataUtil';
import SupportAction from '../support/types';


const defaultState = {
  status: downloadStatus.init,
  cards: [],
  pages: {},
  inBoxCounter: 0,
  awaitedNotifications: {},
};

const inboxReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case InboxAction.UploadInboxCardSaga: {
      return { ...state, status: downloadStatus.pending };
    }

    case InboxAction.Clear: {
      return { ...defaultState, inBoxCounter: state.inBoxCounter };
    }

    case InboxAction.UploadInboxCardRedux: {
      const { maxDateToShow, newCards, nextStep, hasItem } = payload;
      return { ...state, cards: newCards, hasItem, maxDateToShow, nextStep, status: downloadStatus.success };
    }

    case InboxAction.UpdatePageInInboxCardRedux: {
      const page = payload;
      const pages = { ...state.pages, ...page };
      return { ...state, pages };
    }
    case InboxAction.AddInBoxCardRedux: {
      const { items } = payload;
      const newArray = state.cards.filter(element => !items
        .some(otherElement => otherElement.id === element.id && otherElement.type === element.type));

      const cards = [...items, ...newArray];
      return { ...state, cards };
    }
    case InboxAction.UpdateCounter: {
      const { changeCounterValue } = payload;
      return { ...state, inBoxCounter: state.inBoxCounter + changeCounterValue };
    }

    case SupportAction.InitialDataFetch: {
      return { ...state, inBoxCounter: payload.inBoxCounter };
    }


    case InboxAction.AddSingleWithoutUpdateInBoxCardRedux: {
      const { item } = payload;
      const index = state.cards.findIndex(i => i.id === item.id);
      if (index !== -1) {
        // no update
        return state;
      }
      const cards = [item, ...state.cards];
      return { ...state, cards };
    }

    case InboxAction.CardInBoxMarkUsReed: {
      return { ...state,
        cards: state.cards.map(i => {
          if (payload.item.id === i.id && i.type === payload.item.type) return { ...i, isRead: !i.isRead };
          return i;
        }),
      };
    }

    case InboxAction.CardsInboxMarkAsReadOrUnread: {
      const { items, type } = payload;
      const itemIds = items.reduce((acc, cur) => {
        return { ...acc, [cur.id]: type };
      }, {});
      return {
        ...state,
        cards: state.cards.map(i => {
          if (itemIds[i.id]) {
            return { ...i, isRead: itemIds[i.id] !== 'unread' };
          }
          return i;
        }),
      };
    }

    case InboxAction.DeleteInboxCard: {
      const cards = [...state.cards].filter(
        (i) => (i.id !== payload.item.id) || (i.type !== payload.item.type),
      );
      return { ...state, cards, inBoxCounter: --state.inBoxCounter };
    }

    case InboxAction.DeleteInboxCardR: {
      const cards = [...state.cards].filter(
        (i) => (i.id !== payload.item.id) || (i.type !== payload.item.type),
      );
      return { ...state, cards, inBoxCounter: --state.inBoxCounter };
    }

    case InboxAction.AddNewAwaitedNotification: {
      const { notification } = payload;
      const playlistId = notification.itemId;
      const awaitedNotifications = { ...state.awaitedNotifications, [playlistId]: notification };
      return { ...state, awaitedNotifications };
    }

    case InboxAction.ClearAwaitedNotification: {
      return { ...state, awaitedNotifications: defaultState.awaitedNotifications };
    }

    case InboxAction.DeleteInboxCards: {
      const { items } = payload;
      const cards = state.cards.filter((card) => !items.find(({ id }) => id === card.id));

      return { ...state, cards, inBoxCounter: state.inBoxCounter - items.length };
    }
    case InboxAction.UpdateNotificationNotificationAfterUpdate: {
      const { idsForUpdateNotification } = payload;
      const now = Math.round(new Date().getTime() / 1000);

      const updatedCard = state.cards.map(card => {
        if (idsForUpdateNotification[card.id]) {
          return { ...card,
            date: now,
            isRead: false,
            isHasUpdate: card.isHasUpdate || card.isRead,
          };
        }
        return card;
      }).sort((a, b) => b.date - a.date);

      return { ...state, cards: updatedCard };
    }

    default:
      return state;
  }
};

export default inboxReducer;
