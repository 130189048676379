import { Settings } from './types';
import { Channels } from '../channels/types';
import {
  AVATAR_CHANGE_OPTIONS,
  DB_USER_PREFERENCES_GENERAL_FIELDS,
  DB_USER_PREFERENCES_LANGUAGE_FIELDS,
  SUPPORTED_FORMATTING,
  TIME_FORMATS,
} from '../../utils/SettingsHelpers/constants';
import { LANG } from '../../utils/Translations';
import { calcCurrentLocale } from '../../utils/SettingsHelpers/helpers';

const defaultState = {
  isLoaded: false,
  inputs: {
    avatar: {
      value: '',
      dataBaseValue: '',
      errors: [],
      tmpImageBlob: null,
      tmpImageType: null,
      isLoading: false,
    },
    emojiName: {
      value: '',
      errors: [],
    },
    currentEmojiRename: {
      value: '',
      errors: [],
    },
    emojiFile: {
      value: '',
      errors: [],
    },
    username: {
      value: '',
      dataBaseValue: '',
      errors: [],
    },
    firstName: {
      value: '',
      dataBaseValue: '',
      errors: [],
    },
    lastName: {
      value: '',
      dataBaseValue: '',
      errors: [],
    },
    email: {
      value: '',
      dataBaseValue: '',
      errors: [],
    },
    phone: {
      value: '',
      dataBaseValue: '',
      errors: [],
    },
    company: {
      value: '',
      dataBaseValue: '',
      errors: [],
    },
    infoRole: {
      value: '',
      dataBaseValue: '',
      errors: [],
    },
    bio: {
      value: '',
      dataBaseValue: '',
      errors: [],
    },
    keepInformed: {
      value: false,
      errors: [],
    },
    newsletterSubscription: {
      value: false,
      errors: [],
    },
    emailNotifications: {
      value: false,
      errors: [],
    },
    showUsername: {
      value: false,
      errors: [],
    },
    displayImage: {
      value: AVATAR_CHANGE_OPTIONS.AvatarImage,
      errors: [],
    },
    preferredLanguage: {
      value: LANG.en,
      errors: [],
    },
    timeZone: {
      value: calcCurrentLocale(),
      errors: [],
    },
    dateFormat: {
      value: SUPPORTED_FORMATTING.MMDDYYYY,
      errors: [],
    },
    timeFormat: {
      value: TIME_FORMATS.H24,
      errors: [],
    },
  },
  forms: {
    infos: {
      errors: [],
      success: [],
      notifications: [],
    },
    emojiFileUpload: {
      errors: [],
      success: [],
      notifications: [],
    },
  },
  activity: {
    channels: {},
  },
  tables: {
    emojis: {},
    emojisErrors: {},
  },
  sortOptions: {
    playlistsPublished: 'recent',
    playlistsDrafts: 'recent',
    playlistsFavorites: 'recent',
    playlistsShared: 'recent',
    playlistsSharedByMe: 'recent',

    libraryAll: 'recent',
    libraryFavorites: 'recent',

    pagesAll: 'recent',
    pagesDrafts: 'recent',
    pagesFavorites: 'recent',
    pagesShared: 'recent',
    pagesSharedByMe: 'recent',

    componentsAll: 'recent',
    componentsFavorites: 'recent',

    pdfAll: 'recent',
    pdfFavorites: 'recent',

    mediaAll: 'recent',
    mediaFavorites: 'recent',

    mediaImages: 'recent',
    mediaVideo: 'recent',
    mediaAudio: 'recent',

    linksAll: 'recent',
    linksFavorites: 'recent',
    linksUrl: 'recent',
    linksEmbed: 'recent',

    filesAll: 'recent',
    filesFavorites: 'recent',

    channelPlaylists: 'recent',
    channelFavoritePlaylists: 'recent',
    channelUsers: 'recent',
  },
};

export const settingsReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case Settings.Profile.ChangeValue: {
      const { inputType, inputValue } = payload;
      if (!inputType) return state;
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [inputType]: { ...state.inputs[inputType], value: inputValue },
        },
      };
    }
    case Settings.ShowFormErrors: {
      const { formName, errors } = payload;
      if (!formName) return state;
      return {
        ...state,
        forms: {
          ...state.forms,
          [formName]: { ...state.forms[formName], errors },
        },
      };
    }
    case Settings.ShowFormSuccess: {
      const { formName, success } = payload;
      if (!formName) return state;
      return {
        ...state,
        forms: {
          ...state.forms,
          [formName]: { ...state.forms[formName], success },
        },
      };
    }
    case Settings.HideFormMessages: {
      const { formName } = payload;
      if (!formName) return state;
      return {
        ...state,
        forms: {
          ...state.forms,
          [formName]: {
            errors: [],
            success: [],
            notifications: [],
          },
        },
      };
    }
    case Settings.HideMessage: {
      const { type: localType, id, formName } = payload;
      if (!localType || !id || !formName) return state;
      const newItems = [...state.forms[formName][localType]].filter(
        (item) => item.id !== id,
      );
      return {
        ...state,
        forms: {
          ...state.forms,
          [formName]: {
            ...state.forms[formName],
            [localType]: {
              ...state.forms[formName][localType],
              [localType]: newItems,
            },
          },
        },
      };
    }
    case Settings.ShowFormNotifications: {
      const { formName, notifications } = payload;
      if (!formName) return state;
      return {
        ...state,
        forms: {
          ...state.forms,
          [formName]: { ...state.forms[formName], notifications },
        },
      };
    }
    case Settings.UpdateInputErrors: {
      const { inputType, errors } = payload;
      if (!inputType) return state;
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [inputType]: { ...state.inputs[inputType], errors },
        },
      };
    }
    case Settings.SaveTmpImageData: {
      const { data, type: tmpImageType, inputType, file } = payload;
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [inputType]: {
            ...state.inputs[inputType],
            tmpImageBlob: data,
            tmpImageType,
            file,
          },
        },
      };
    }
    case Settings.Profile.DropTmpImageData: {
      const { inputType } = payload;

      return {
        ...state,
        inputs: {
          ...state.inputs,
          [inputType]: {
            ...state.inputs[inputType],
            tmpImageBlob: null,
            tmpImageType: null,
            file: null,
          },
        },
      };
    }
    case Settings.Profile.UpdateAvatarInput: {
      const { avatarUrlSmall } = payload;
      return {
        ...state,
        inputs: {
          ...state.inputs,
          avatar: {
            ...state.inputs.avatar,
            value: avatarUrlSmall,
            dataBaseValue: avatarUrlSmall,
            isLoading: false,
          },
        },
      };
    }
    case Settings.Profile.DeleteAvatar: {
      return {
        ...state,
        inputs: {
          ...state.inputs,
          avatar: { ...state.inputs.avatar, value: '' },
        },
      };
    }
    case Settings.DataIsLoaded: {
      return { ...state, isLoaded: true };
    }
    case Settings.Profile.SetAvatarIsLoading: {
      const { isLoading } = payload;
      return {
        ...state,
        inputs: {
          ...state.inputs,
          avatar: { ...state.inputs.avatar, isLoading },
        },
      };
    }
    case Settings.Activity.GetChannels: {
      return { ...state, isLoaded: false };
    }
    case Settings.Activity.Initial: {
      return {
        ...state,
        activity: { ...state.activity, channels: payload.channels },
        isLoaded: true,
      };
    }
    case Channels.DeleteChannel: {
      const { channelId } = payload;
      const newChannels = { ...state.activity.channels };
      delete newChannels[channelId];
      return {
        ...state,
        activity: { ...state.activity, channels: newChannels },
        isLoaded: true,
      };
    }
    case Channels.DeleteChannelR: {
      const { channelId } = payload;
      const newChannels = { ...state.activity.channels };
      delete newChannels[channelId];
      return {
        ...state,
        activity: { ...state.activity, channels: newChannels },
        isLoaded: true,
      };
    }
    case Settings.Profile.SetInitialValuesR: {
      const inputs = JSON.parse(JSON.stringify(state.inputs));
      const updatedInputs = {};

      Object.entries(inputs).forEach(([key, values]) => {
        updatedInputs[key] = { ...values, dataBaseValue: values.value };
      });

      return { ...state, inputs: updatedInputs };
    }
    case Settings.Profile.InitialDataLoading: {
      const { user } = payload;
      const newInputs = {};

      Object.keys(user).forEach((key) => {
        newInputs[key] = {
          value: user[key] || '',
          dataBaseValue: user[key] || '',
          errors: [],
        };
      });

      return { ...state, inputs: { ...state.inputs, ...newInputs } };
    }
    case Settings.Preferences.ToggleInputValue: {
      const { inputType, newValue } = payload;
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [inputType]: { ...state.inputs[inputType], value: newValue },
        },
      };
    }
    case Settings.Preferences.UpdateAvatarSelector: {
      const { newValue } = payload;
      return {
        ...state,
        inputs: {
          ...state.inputs,
          displayImage: { ...state.inputs.displayImage, value: newValue },
        },
      };
    }
    case Settings.Preferences.LoadInitialData: {
      const newInputs = {};

      Object.keys(payload).forEach((key) => {
        if (key === DB_USER_PREFERENCES_GENERAL_FIELDS.displayImage) {
          newInputs[key] = {
            value: payload[key] || AVATAR_CHANGE_OPTIONS.AvatarImage,
            errors: [],
          };
        } else if (DB_USER_PREFERENCES_LANGUAGE_FIELDS[key]) {
          if (payload[key]) newInputs[key] = { value: payload[key], errors: [] };
        } else if (DB_USER_PREFERENCES_GENERAL_FIELDS[key]) {
          newInputs[key] = { value: payload[key] || false, errors: [] };
        }
      });

      return {
        ...state,
        inputs: { ...state.inputs, ...newInputs },
        isLoaded: true,
      };
    }

    case Settings.Preferences.ChangeLanguage: {
      const { newValue } = payload;
      const newInput = { ...state.inputs.preferredLanguage, value: newValue };
      return {
        ...state,
        inputs: { ...state.inputs, preferredLanguage: newInput },
      };
    }
    case Settings.Preferences.ChangeTimeFormat: {
      const { newValue } = payload;
      const newInput = { ...state.inputs.timeFormat, value: newValue };
      return { ...state, inputs: { ...state.inputs, timeFormat: newInput } };
    }
    case Settings.Preferences.ChangeDateFormat: {
      const { newValue } = payload;
      const newInput = { ...state.inputs.dateFormat, value: newValue };
      return { ...state, inputs: { ...state.inputs, dateFormat: newInput } };
    }
    case Settings.Preferences.ChangeTimeZoneFormat: {
      const { newValue } = payload;
      const newInput = { ...state.inputs.timeZone, value: newValue };
      return { ...state, inputs: { ...state.inputs, timeZone: newInput } };
    }
    case Settings.LoadingData: {
      return { ...state, isLoaded: false };
    }
    case Settings.Preferences.AddListItemToEmojis: {
      const { newEmoji } = payload;
      const oldEmojis = { ...state.tables.emojis };
      return {
        ...state,
        tables: {
          ...state.tables,
          emojis: { ...oldEmojis, [newEmoji.id]: newEmoji },
        },
        inputs: {
          ...state.inputs,
          emojiName: {
            value: '',
            errors: [],
          },
          emojiFile: {
            value: '',
            errors: [],
          },
        },
      };
    }
    case Settings.Preferences.UpdateEmojiTableErrors: {
      const { emojiId, errors } = payload;
      return {
        ...state,
        tables: { ...state.tables, emojisErrors: { [emojiId]: errors } },
      };
    }
    case Settings.Preferences.LoadEmojisR: {
      const { updatedEmojis } = payload;
      const oldEmojis = { ...state.tables.emojis };
      return {
        ...state,
        tables: { ...state.tables, emojis: { ...oldEmojis, ...updatedEmojis } },
      };
    }
    case Settings.Preferences.UpdateEmojiInTable: {
      const { updatedEmoji } = payload;
      const oldEmojis = { ...state.tables.emojis };
      return {
        ...state,
        tables: { ...state.tables, emojis: { ...oldEmojis, ...updatedEmoji } },
        inputs: {
          ...state.inputs,
          currentEmojiRename: {
            ...state.inputs.currentEmojiRename,
            value: updatedEmoji.value,
          },
        },
      };
    }
    case Settings.Preferences.SetRenameEmojiNameR: {
      const { inputType, inputValue } = payload;
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [inputType]: { ...state.inputs[inputType], value: inputValue },
        },
      };
    }
    case Settings.Application.LoadUsersShortcuts: {
      const { shortcuts } = payload;
      return { ...state, shortcuts };
    }
    case Settings.Preferences.RemoveUserEmoji: {
      const { emojiId } = payload;
      const updatedEmojis = { ...state.tables.emojis };
      delete updatedEmojis[emojiId];
      return {
        ...state,
        tables: {
          emojis: updatedEmojis,
          emojisErrors: {},
        },
      };
    }
    case Settings.Preferences.ResetUploadEmojiDataR: {
      return {
        ...state,
        inputs: {
          ...state.inputs,
          emojiName: {
            value: '',
            errors: [],
          },
          emojiFile: {
            value: '',
            errors: [],
          },
        },
      };
    }

    case Settings.Sort.changeSortRedux: {
      return {
        ...state,
        sortOptions: {
          ...state.sortOptions,
          ...payload,
        },
      };
    }

    case Settings.Sort.changeOneSortKeyInRedux: {
      const { key, value } = action.payload;
      return {
        ...state,
        sortOptions: {
          ...state.sortOptions,
          [key]: value,
        },
      };
    }
    case Channels.UpdatePinState: {
      const { id } = payload;
      if (!state.activity.channels[id]) return state;
      return {
        ...state,
        activity: {
          ...state.activity,
          channels: { ...state.activity.channels,
            [id]: {
              ...state.activity.channels[id],
              isUnpin: !state.activity.channels[id].isUnpin,
            },
          },
        },
      };
    }
    default:
      return state;
  }
};
