import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  calculateHorizontalPosition,
  calculateVerticalPosition,
  ChannelsUserStatusOptions,
} from '../../../utils/tableHelpers/tableUi';

const UserOptionsContainer = styled.div`
  z-index: 2;
  top: ${({ parentRef, elementHeight, menuHeight }) => `${calculateVerticalPosition(parentRef, menuHeight, elementHeight)}px`};
  left: ${({ parentRef, menuWidth, elementWidth }) => `${calculateHorizontalPosition(parentRef, menuWidth, elementWidth)}px`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 240px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #ffffff;
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.25),
    0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  color: ${({ color }) => color};

  &:hover {
    background-color: ${({ isDelete }) => (!isDelete ? '#E8EBFA' : '#FFE1E8')};
  }
  & svg {
    width: 16px;
    height: 16px;
    margin-right: 9px;
    & path {
      stroke: ${({ color }) => color};
      fill: transparent;
    }
  }

  ${({ isDisabled }) => (isDisabled
    ? `
    &:hover {
      background-color: initial;
    }
    
    color: grey;
    svg path {
      stroke: grey;
    }
  `
    : '')}
`;

const MemberOptionsList = ({ parentRef, optionsRef, item, ...props }) => {
  const [activeOptions, setActiveOptions] = useState(
    ChannelsUserStatusOptions[item.status],
  );
  const { myChannels } = useSelector((state) => state.channels);
  const { id: folderId } = useParams();

  useEffect(() => {
    setActiveOptions(ChannelsUserStatusOptions[item.status]);
  }, [item]);

  return (
    <UserOptionsContainer
      data-parent={`usersTable${item.id}`}
      ref={optionsRef}
      parentRef={parentRef}
      elementHeight={32}
      menuHeight={112}
      elementWidth={32}
      menuWidth={240}
    >
      {activeOptions.map((option) => (
        <Option
          isDisabled={
            !myChannels[folderId]?.permissions.canChangeRolesTo[item.role]
          }
          key={option.status}
          isDelete={option.msg === 'Delete'}
          color={option.color}
          onClick={() => {
            if (props.isDisabled) return;
            props.changeMemberStatus(item.id, item.name, option.status);
          }}
        >
          {option.svg}
          {option.msg}
        </Option>
      ))}
    </UserOptionsContainer>
  );
};

export default MemberOptionsList;
