import React, { FC, ReactNode } from 'react';
import {
  Item,
  TooltipContainer,
  TooltipShell,
  TooltipText,
  TrianglePointer,
} from './styled';

interface TooltipProps {
  text: string;
  direction: string;
  place: string;
  isShow?: boolean;
  forChannels?: boolean;
  forUPV?: boolean;
  moveLeft?: number;
  children: ReactNode;
}

const Tooltip: FC<TooltipProps> = ({
  text,
  direction,
  place,
  isShow = true,
  forChannels,
  children,
  ...props
}) => {
  return (
    <TooltipShell
      data-cy={text}
      forChannels={!!forChannels}
      forUPV={!!props.forUPV}
      moveLeft={props.moveLeft}
    >
      <Item>
        {children}
        {isShow && (
          <TooltipContainer
            data-tooltip="tooltip"
            place={place}
            direction={direction}
          >
            <TooltipText place={place}>{text}</TooltipText>
            <TrianglePointer pointingDirection={direction} />
          </TooltipContainer>
        )}
      </Item>
    </TooltipShell>
  );
};

export default Tooltip;
