import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import Button2023 from '../Buttons/Button2023';
import { Channels } from '../../redux/channels/types';
import { actionCreator } from '../../shared/redux/actionHelper';

const cx = classNames.bind(styles);

const LeaveChannel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dataPayload } = useSelector((redux) => redux.user);
  const history = useHistory();
  const close = () => {
    dispatch(actionCloseModal());
  };
  const leaveChannel = () => {
    dispatch(
      actionCreator(Channels.UserLeaveChannel, { ...dataPayload, history }),
    );
    dispatch(actionCloseModal());
    if (dataPayload.nextItem) {
      const newPath = dataPayload.nextItem.id
        ? `${dataPayload.nextItem.path}${dataPayload.nextItem.id}` : dataPayload.nextItem.path;
      history.push(newPath);
    }
  };

  return (
    <div onClick={close} className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'LeaveChannel')}
      >
        <div className={cx('title')}>{t('leaveChannelT')}</div>
        <div className={cx('text')}>{t('leaveChannelQuestionT')}</div>
        <div className={cx('group_buttons')}>
          <div onClick={close} className={cx('group_buttons_cancel')}>
            {t('cancelT')}
          </div>
          <div>
            <Button2023
              variant="secondary"
              width={128}
              height={32}
              text={t('leaveChannelT')}
              noMargins
              handleButtonClick={leaveChannel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveChannel;
