import { call, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { all, select } from '@redux-saga/core/effects';
import { v4 as uuid, v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { eventChannel } from 'redux-saga';
import { Library, LibraryComponents } from '../redux/library/types';
import {
  request,
  requestCreateComponent,
  requestCreateGoogleLinkedAccount,
  requestCreateLibraryComponent,
  requestCreateLibraryComponentPlacement,
  requestCreateLinkComponent,
  requestDeleteComponent,
  requestDeleteLinkPage,
  requestFileModifiedDate,
  requestGetLibComponent,
  requestGetSomePlaylistContent,
  requestGoogleTokenValid,
  requestPageMoveToTrash,
  requestParser,
  requestSharedComponentMetaData,
  requestUpdateComponent,
  requestUpdateLibraryComponent,
  requestUpdatePlaylist,
} from '../utils/request';

import {
  getBlocks,
  getCurrentPage,
  getLibrary,
  getLibraryCollections,
  getLibraryComponent as getContent,
  getLibraryComponentByIDsArr,
  getLibraryComponents,
  getSelectedPage,
  getSupportBlocks,
  getUploads,
  getUser,
  showErrorMessage,
} from './sagasUtils';
import {
  actionAddComponentToDragState,
  actionAddLibraryComponent,
  actionAddLibraryComponentReduxOnly,
  actionClearDragState,
  actionCloseCreateComponent,
  actionCreateLinkLibraryComponent,
  actionMoveComponentToTrash,
  actionToggleComponentFavorite,
  actionUpdateLibraryComponentInRedux,
  actionUpdatePdfRelation,
} from '../redux/library/actions';
import {
  calculateIndex,
  collectLines,
  combineComponentsAndLibraryComponents,
  contentImageTemplate,
  getNewTitle,
  innerHtmlDropboxEmbedTemplate,
  innerHtmlEmbed,
  innerHtmlGoogleEmbedTemplate,
  innerHtmlImageTemplate,
  innerHtmlPageLink,
  innerHtmlPdfTemplate,
  innerHtmlWebSiteTemplate,
  mutateImageBlockState,
  parseContentForUsage,
  removeStringifiedSymbols,
  sanitizeToLoad,
  sanitizeToSave,
  stringifyInnerHtml,
  validateURL,
} from '../utils/helpers';
import {
  actionSaveComponentDescriptionData,
  actionShowMessage,
  actionToggleComponentDescription,
  actionUpdateSupportBlocks,
  actionUpdateSupportLayers,
} from '../redux/support/action';
import {
  APIGetPageSharing,
  BlockTypes,
  DEFAULT_POSITION_STEP,
  isTypeAllowedForPage,
  LibCompSieveTypesSideBar,
  LibraryComponentTypes,
  MessageType,
  METHOD,
  PLAYLIST_ELEMENTS,
  TYPE,
  UiComponentTypes,
  UNSUBSCRIBE_URL,
  UNSUBSCRIBE_MANY_URL,
  UPLOAD_URL,
  openModalType,
} from '../utils/constants';
import {
  actionDragManyPage,
  actionSwitchPage,
  actionToggleLibraryDrag,
  actionUpdateBlock,
  actionUpdateShareState,
} from '../redux/currentPage/action';
import { actionRemoveAllSelected } from '../redux/selectedPage/action';
import { delay } from './uploadSagas';
import { actionOpenModal, actionUnblockUnloading } from '../redux/user/action';
import { actionDeleteEditableBlockRedux } from '../redux/pages/action';
import draftDataConvertToLoad from '../utils/draftDataConvert';
import SupportAction from '../redux/support/types';
import ActionDraggable from '../redux/dragable/action';
import { actionPlaylistMoveToTrash } from '../redux/playlists/action';
import { actionCreator } from '../shared/redux/actionHelper';
import { sanitizeStringFields } from './helpers';
import {
  actionAttachMultipleTagsToLibraryComponent,
  actionReplaceTagsInLibraryComponent,
} from '../redux/tags/action';
import downloadStatus from '../utils/dataUtil';
import { historyEvents, historyStep } from '../undo';
import { GOOGLE_API_KEY, GOOGLE_CLIENT_ID } from '../environments/environments';
import { CurrentPage } from '../redux/currentPage/types';
import { ServiceUser } from '../redux/user/types';
import {
  actionCreateUpload,
  actionUpdateUpload,
} from '../redux/filesUpload/action';
import { ContentActionType } from '../redux/content/contentTypes';
import { placementEnum } from '../utils/query/queryEnum';
import {
  convertStateWithDraftJs,
  createBodyAddComponentsToCurrentLibraryComponent,
  createDetachComponentBody,
  parseFieldsToObj,
} from '../utils/query/helper';
import { Tags } from '../redux/tags/types';
import { dateGroupEnum } from '../utils/libraryContentSelector/filterNames';
import { getLayersForPage } from '../shared/page/utils';
import i18n from '../i18n';

function* toggleFavorite(action) {
  try {
    const {
      payload: { componentId, isFavorite, withCurrentPage, libraryComponentId },
    } = action;

    const user = yield select(getUser);
    if (withCurrentPage) {
      yield put(actionSwitchPage({ isFavorite }));
    }
    if (isFavorite) {
      yield call(requestUpdateLibraryComponent(), {
        place: 'queryAddComponentFavorite',
        id: libraryComponentId || componentId,
        connect: {
          favorites: [user.id],
        },
      });
    } else {
      yield call(requestUpdateLibraryComponent(), {
        place: 'queryRemoveComponentFavorite',
        id: libraryComponentId || componentId,
        disconnect: {
          favorites: [user.id],
        },
      });
    }
    yield put(actionCreator(ContentActionType.updateCounterS, {}));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* calcNewItemPosition() {
  try {
    const components = yield select(getLibraryComponents);
    return (
      Math.max(
        ...Object.values(components || {})
          .filter((i) => i.position)
          .map((i) => i.position),
      ) + DEFAULT_POSITION_STEP
    );
  } catch (err) {
    return DEFAULT_POSITION_STEP;
  }
}

function* duplicateComponent(action) {
  try {
    const {
      payload: { componentId },
    } = action;
    const components = yield select(getLibraryComponents);
    const user = yield select(getUser);
    const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
    const sourceComponent = components[componentId];
    const isFile = !UiComponentTypes.link[sourceComponent.type]
      && !UiComponentTypes.component[sourceComponent.type]
      && !UiComponentTypes.page[sourceComponent.type];
    const maxPosition = yield call(calcNewItemPosition);
    const newTitle = getNewTitle(sourceComponent.title, isFile);
    if (UiComponentTypes.link[sourceComponent.type]) {
      const newId = uuidv4();
      const newComponents = {};
      newComponents[newId] = {
        ...sourceComponent,
        id: newId,
        position: maxPosition,
        itemType: 'component',
        title: newTitle,
        parentComponents: [],
        linkPage: [],
        libraryComponents: [],
        usedTimesCounter: null,
      };
      yield put(actionAddLibraryComponent(newComponents[newId]));
      const fieldsUpdateObg = {
        isShareToWeb: false,
        title: `"${sanitizeToSave(newComponents[newId].title)}"`,
        id: `"${newComponents[newId].id}"`,
        createDate: newComponents[newId].lastModifiedDate,
        lastModifiedDate: newComponents[newId].lastModifiedDate,
        position: newComponents[newId].position,
        type: `"${newComponents[newId].type}"`,
        urlFile: `"${newComponents[newId].urlFile}"`,
        linkUrl: `"${sanitizeToSave(newComponents[newId].linkUrl)}"`,
        description: `"${sanitizeToSave(newComponents[newId].description)}"`,
      };
      yield call(requestCreateLibraryComponent, {
        fieldsUpdateObg,
        ownerId: user.id,
      });
      yield put(
        actionShowMessage({
          type: MessageType.AddToMyStudio,
          itemName: newTitle || i18n.t('UnnamedComponentT'),
        }),
      );
    } else {
      const response = yield call(axios, {
        method: 'post',
        url: `${UPLOAD_URL}/duplicate`,
        headers: {
          token: token ? `${token}` : '',
        },
        data: {
          title: sanitizeToSave(newTitle),
          url: sourceComponent.urlFile,
          type: sourceComponent.type,
          position: maxPosition,
          componentId: sourceComponent.id,
          fileSize: sourceComponent.size,
        },
      });
      yield put(
        actionAddLibraryComponent({
          ...response.data.component,
          components: response.data.component.components?.map((item) => ({
            ...item,
            innerHtml: sanitizeToLoad(item.innerHtml),
          })),
          title: newTitle,
          itemType: 'component',
        }),
      );
      const { activeNavSlider, selectorType, sortType } = yield select(
        (state) => state.content,
      );
      yield put(
        actionCreator(ContentActionType.startUpload, {
          activeNavSlider,
          selectorType,
          sortType,
          isNeedUpdate: true,
        }),
      );

      yield put(actionCreator(ContentActionType.updateCounterS, {}));
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* duplicateSharedComponent(action) {
  try {
    const {
      payload: { componentId, type },
    } = action;
    const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');

    const user = yield select(getUser);
    const components = yield select(getLibraryComponents);
    const { data } = yield requestGetLibComponent(['any', componentId]);
    const [sourceComponent] = data.LibraryComponent;
    const maxPosition = Math.min(
      ...Object.values(components || {})
        .filter((i) => i.position)
        .map((i) => i.position),
    ) / 2;
    if (UiComponentTypes.link[sourceComponent.type]) {
      const lastModifiedDate = Math.floor(Date.now() / 1000);
      const newId = uuidv4();
      const newComponents = {};
      newComponents[newId] = {
        ...sourceComponent,
        lastModifiedDate,
        createDate: lastModifiedDate,
        id: newId,
        position: maxPosition,
        itemType: 'component',
      };

      yield put(actionAddLibraryComponent(newComponents[newId]));
      const fieldsUpdateObg = {
        isShareToWeb: false,
        title: `"${sanitizeToSave(newComponents[newId].title)}"`,
        id: `"${newComponents[newId].id}"`,
        createDate: newComponents[newId].lastModifiedDate,
        lastModifiedDate: newComponents[newId].lastModifiedDate,
        position: newComponents[newId].position,
        type: `"${newComponents[newId].type}"`,
        urlFile: `"${newComponents[newId].urlFile}"`,
        linkUrl: `"${sanitizeToSave(newComponents[newId].linkUrl)}"`,
        description: `"${sanitizeToSave(newComponents[newId].description)}"`,
      };
      yield call(requestCreateLibraryComponent, {
        fieldsUpdateObg,
        ownerId: user.id,
      });
      yield put(
        actionShowMessage({
          type: MessageType.AddToMyStudio,
          itemName: sourceComponent.title || i18n.t('UnnamedComponentT'),
          itemType: type,
        }),
      );
    } else {
      const response = yield call(axios, {
        method: 'post',
        url: `${UPLOAD_URL}/duplicate`,
        headers: {
          token: token ? `${token}` : '',
        },
        data: {
          ownerId: sourceComponent.users[0]?.id,
          title: sourceComponent.title,
          url: sourceComponent.urlFile,
          type: sourceComponent.type,
          position: maxPosition,
          componentId: sourceComponent.id,
          fileSize: sourceComponent.size,
        },
      });

      yield put(
        actionAddLibraryComponent({
          ...response.data.component,
          title: sourceComponent.title,
          itemType: 'component',
        }),
      );
      yield put(
        actionShowMessage({
          type: MessageType.AddToMyStudio,
          itemName: sourceComponent.title || i18n.t('UnnamedComponentT'),
          itemType: type,
        }),
      );
    }
    yield put(actionCreator(ContentActionType.updateCounterS, {}));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

export function* playerDuplicateSharedComponent(action) {
  try {
    const {
      payload: {
        componentId,
        type,
        isNoNotifications,
        newId: newIdToCreate,
        linkPageId,
      },
    } = action;
    const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');

    const user = yield select(getUser);
    const currentPage = yield select(getCurrentPage);
    const { data } = yield requestGetLibComponent(['any', componentId]);
    const [sourceComponent] = data.LibraryComponent;
    // const [userData] = data.User;
    // const newPosition = userData.minComponentPosition / 2;
    if (UiComponentTypes.link[sourceComponent.type]) {
      const lastModifiedDate = Math.floor(Date.now() / 1000);
      const newId = newIdToCreate || uuidv4();
      const newComponents = {};
      newComponents[newId] = {
        ...sourceComponent,
        lastModifiedDate,
        createDate: lastModifiedDate,
        id: newId,
        position: 0,
        itemType: 'component',
      };

      yield put(actionAddLibraryComponent(newComponents[newId]));
      const fieldsUpdateObg = {
        isShareToWeb: false,
        title: `"${sanitizeToSave(newComponents[newId].title)}"`,
        id: `"${newComponents[newId].id}"`,
        createDate: newComponents[newId].lastModifiedDate,
        lastModifiedDate: newComponents[newId].lastModifiedDate,
        position: newComponents[newId].position,
        type: `"${newComponents[newId].type}"`,
        urlFile: `"${newComponents[newId].urlFile}"`,
        linkUrl: `"${sanitizeToSave(newComponents[newId].linkUrl)}"`,
        description: `"${sanitizeToSave(newComponents[newId].description)}"`,
      };
      yield call(requestCreateLibraryComponent, {
        fieldsUpdateObg,
        ownerId: user.id,
      });
      if (!isNoNotifications) {
        yield put(
          actionShowMessage({
            type: MessageType.AddToMyStudio,
            itemName: sourceComponent.title || i18n.t('UnnamedComponentT'),
            itemType: type,
          }),
        );
      }
    } else {
      const response = yield call(axios, {
        method: 'post',
        url: `${UPLOAD_URL}/duplicate`,
        headers: {
          token: token ? `${token}` : '',
        },
        data: {
          ownerId: sourceComponent.users[0]?.id,
          title: sourceComponent.title,
          url: sourceComponent.urlFile,
          type: sourceComponent.type,
          // position: newPosition,
          componentId: sourceComponent.id,
          fileSize: sourceComponent.size,
          newIdToCreate,
          linkPageId,
          playlistId: currentPage.id,
        },
      });

      yield put(
        actionAddLibraryComponent({
          ...response.data.component,
          title: sourceComponent.title,
          itemType: 'component',
        }),
      );
      if (!isNoNotifications) {
        yield put(
          actionShowMessage({
            type: MessageType.AddToMyStudio,
            itemName: sourceComponent.title || i18n.t('UnnamedComponentT'),
            itemType: type,
          }),
        );
      }
    }
    yield put(actionCreator(ContentActionType.updateCounterS, {}));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* MoveComponentToTrash(action) {
  try {
    const { componentId, useCases } = action.payload;
    const { blocks } = yield select(getCurrentPage);
    const componentInPage = blocks
      ?.filter((i) => i.type === 'component')
      ?.filter((i) => i.nestedItemId === componentId);
    if (componentInPage) {
      yield all(
        componentInPage.map((block) => {
          return put(actionDeleteEditableBlockRedux(block.id, 'libComponent'));
        }),
      );
    }
    const needToRemoveAllUsage = useCases?.nestedTo
      && (useCases?.nestedTo.linkPage.length
        || useCases?.nestedTo.libraryComponents.length
        || useCases?.nestedTo.parentBLocks.length);
    if (needToRemoveAllUsage) {
      yield call(requestDeleteLinkPage(placementEnum.queryRemoveAllUsage), {
        componentId,
        useCases: useCases.nestedTo,
      });
    }
    const update = {};
    update.movedToTrash = true;
    yield call(requestUpdateLibraryComponent(), {
      place: 'queryUpdateLibraryComponent',
      id: componentId,
      fieldsUpdateObg: update,
    });
    yield call(requestPageMoveToTrash(componentId));

    yield put(actionCreator(ContentActionType.updateCounterS, {}));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* restoreComponentFromTrash(action) {
  try {
    const { componentId, position } = action.payload;
    yield call(
      requestUpdateLibraryComponent(
        placementEnum.queryRestoreLibraryComponentFromTrash,
      ),
      {
        componentId,
        position,
      },
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* updateLibraryComponent(action) {
  try {
    const { id, value, field } = action.payload;
    const { selectorType, activeNavSlider } = yield select(
      (state) => state.content,
    );

    const isSmartViewActive = activeNavSlider === 'dynamicCollection';
    const isRename = field === 'title';
    // check case
    if (isSmartViewActive && isRename) {
      const dynamicCollection = yield select(
        (state) => state.dynamicCollection,
      );

      const currentSmartViewTextFilter = dynamicCollection[selectorType]?.libFilterSearch;
      if (currentSmartViewTextFilter) {
        const itemNoMoreInSmartView = !value.includes(
          currentSmartViewTextFilter,
        );
        if (itemNoMoreInSmartView) {
          yield put(actionCreator(SupportAction.RemoveItemInRedux, { id }));
        }
      }
    }

    const update = {};
    if (field === 'urlFile' || field === 'type') update[field] = `"${value}"`;
    else if (
      field === 'title'
      || field === 'credits'
      || field === 'description'
    ) {
      update[field] = `"${sanitizeToSave(value)}"`;
    } else update[field] = value;
    yield call(requestUpdateLibraryComponent(), {
      place: 'queryUpdateLibraryComponent',
      id,
      fieldsUpdateObg: update,
    });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* checkExistItemInFilter(action) {
  try {
    const { id } = action.payload;
    const {
      selectorType: idSelector,
      activeNavSlider,
      contentData,
      contentIds,
    } = yield select((state) => state.content);

    if (!contentData[id]) return;
    const isSmartViewActive = activeNavSlider === 'dynamicCollection';
    const isTagActive = activeNavSlider === 'tag';
    // check case
    if (isSmartViewActive) {
      const dynamicCollection = yield select(
        (state) => state.dynamicCollection,
      );
      const currentSmartViewTags = dynamicCollection[idSelector].filter.tags;
      const isItemNoMoreInTagFilter = !contentData[id]?.tags?.filter(
        (tag) => currentSmartViewTags[tag.id],
      ).length;
      if (isItemNoMoreInTagFilter) yield put(actionCreator(SupportAction.RemoveItemInRedux, { id }));
      else if (!contentIds.includes(id)) yield put(actionCreator(SupportAction.AddItemInRedux, { id }));
    }

    if (isTagActive) {
      const isItemNoMoreInTag = !contentData[id]?.tags?.filter(
        (tag) => tag.id === idSelector,
      ).length;
      if (isItemNoMoreInTag) yield put(actionCreator(SupportAction.RemoveItemInRedux, { id }));
      else if (!contentIds.includes(id)) yield put(actionCreator(SupportAction.AddItemInRedux, { id }));
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* attachTagsToLibraryComponent(tags, newLibraryComponent, isEdit) {
  try {
    const lastModifiedDate = Math.floor(Date.now() / 1000);

    if (isEdit) {
      yield put(
        actionReplaceTagsInLibraryComponent(
          tags,
          newLibraryComponent,
          lastModifiedDate,
        ),
      );
    } else {
      yield put(
        actionAttachMultipleTagsToLibraryComponent(
          tags,
          newLibraryComponent,
          lastModifiedDate,
        ),
      );
    }

    if (!isEdit) {
      yield all(
        tags.map((tag) => call(requestUpdateLibraryComponent(), {
          place: 'queryAttachTagToParentLibraryComponent',
          id: newLibraryComponent.id,
          connect: { contentTags: [tag.id] },
        }),
        ),
      );
    }

    yield put(
      actionCreator(SupportAction.TriggerCountersUpdate, { isActive: false }),
    );
  } catch (err) {
    yield showErrorMessage(err, { type: 'attachTagsToLibraryComponent' });
  }
}

function* createLibraryComponentFromBlocks(action) {
  try {
    const {
      selectedBlocks,
      name,
      newLibraryComponentId,
      tags,
      blockId,
      currentPageId,
    } = action.payload;
    const user = yield select(getUser);

    const components = yield select(getLibraryComponents);
    const currentPageData = yield select(getCurrentPage);
    const compForLibrary = currentPageData.blocks.filter((i) => selectedBlocks.includes(i.id),
    );
    const oldComp = currentPageData.blocks.filter(
      (i) => !selectedBlocks.includes(i.id),
    );
    // const copiedBlocksArray = [];

    const positionInPage = currentPageData.blocks
      .filter((i) => selectedBlocks.includes(i.id))
      .sort((i, b) => b.position - i.position)[0]?.position
      || DEFAULT_POSITION_STEP;

    const maxPositionItem = Object.values(components || {}).sort(
      (i, b) => i.position - b.position,
    )[0] || { position: 0 };
    const positionInLibrary = (maxPositionItem.position || 0) + DEFAULT_POSITION_STEP;
    // selectedBlocks.forEach((toBeCopiedId) => {
    //   const newBlockId = uuid();
    //   copiedBlocksArray.push({ toBeCopiedId, newBlockId });
    // });

    const preparedBlocks = compForLibrary.map((block) => {
      const item = block;
      if (
        item.type === BlockTypes.lineSeparator
        || item.type === BlockTypes.approveButton
        || item.type === BlockTypes.actionButton
        || item.type === BlockTypes.shortText
      ) {
        return {
          ...item,
        };
      }
      if (item.type === BlockTypes.image) {
        return {
          ...item,
        };
      }
      if (item.type === BlockTypes.page) {
        return {
          ...item,
          state: { nestedItemId: item.nestedItemId, title: item.title },
          innerHtml: innerHtmlPageLink(item.title),
        };
      }
      if (item.type === BlockTypes.pdf) {
        return {
          ...item,
          state: {
            data: {
              ...item.state.data,
              isNew: false,
            },
          },
        };
      }
      if (block.type === BlockTypes.webSite) {
        return {
          ...item,
          innerHtml: innerHtmlWebSiteTemplate(item.state.data),
        };
      }
      return {
        ...item,
      };
    });
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const editableBlocks = [
      ...oldComp,
      {
        position: positionInPage,
        type: 'component',
        nestedItemId: newLibraryComponentId,
        title: name,
        id: blockId,
        components: preparedBlocks,
      },
    ].sort((i, b) => i.position - b.position);

    yield put(
      actionAddLibraryComponent({
        id: newLibraryComponentId,
        title: name,
        urlFile: '',
        isFavorite: false,
        type: 'component',
        movedToTrash: null,
        progress: 0.0,
        isUpload: true,
        position: maxPositionItem.position,
        createDate: lastModifiedDate,
        lastModifiedDate,
        components: preparedBlocks,
        itemType: 'component',
        tags: [],
      }),
    );
    yield put(actionSwitchPage({ blocks: editableBlocks }));
    yield call(
      requestCreateLibraryComponentPlacement(
        placementEnum.queryCreateLibraryComponentInPage,
      ),
      {
        title: name,
        nestedItemId: newLibraryComponentId,
        positionInLibrary,
        blocks: selectedBlocks,
        pageId: currentPageId,
        userId: user.id,
        blockId,
        positionInPage,
      },
    );

    if (tags?.length) yield attachTagsToLibraryComponent(tags, { id: newLibraryComponentId });
    yield put(actionDragManyPage(false));
    yield put(ActionDraggable.SetHasDraggable(false));
    yield put(actionCreator(ContentActionType.updateCounterS, {}));

    // yield put(actionUpdateDraggableBlocks([]));
    yield put(actionToggleLibraryDrag(false));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* createLibraryComponentInLibraryFromBlocks(action) {
  try {
    const { name, tags } = action.payload;
    const components = yield select(getLibraryComponents);
    const uploads = yield select(getUploads);
    const isSomeUnfinishedUploads = !!Object.values(uploads).filter(
      (upload) => upload.status !== 'READY',
    ).length;
    if (isSomeUnfinishedUploads) {
      yield put(
        actionShowMessage({
          type: MessageType.WarnUser,
          warningText: 'You have to wait for all uploads to finish',
        }),
      );
      return;
    }
    yield put(actionCloseCreateComponent());

    const blocks = yield select(getSupportBlocks);
    const user = yield select(getUser);
    const maxPositionItem = Object.values(components || {}).sort(
      (i, b) => i.position - b.position,
    )[0] || { position: 0 };
    const newPosition = (maxPositionItem.position || 0) + DEFAULT_POSITION_STEP;
    const newLibraryComponentId = uuid();
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const preparedImages = yield select(
      getLibraryComponentByIDsArr(
        blocks.filter((it) => it.nestedItemId).map((it) => it.nestedItemId),
      ),
    );
    const preparedBlocks = blocks
      .map((block) => {
        if (
          block.type === BlockTypes.lineSeparator
          || block.type === BlockTypes.approveButton
          || block.type === BlockTypes.actionButton
          || block.type === BlockTypes.shortText
        ) {
          return {
            ...block,
            innerHtml: stringifyInnerHtml(block?.innerHtml),
            state: block.state,
          };
        }
        // if (block.type === BlockTypes.embed) {
        //   console.log({ block });
        //   return {
        //     ...block,
        //     innerHtml: sanitizeToSave(block?.innerHtml),
        //     state: sanitizeToSave(block.state),
        //   };
        // }
        if (block.type === BlockTypes.image && !block?.content) {
          return null;
        }
        if (block.type === BlockTypes.image) {
          const toReturn = {
            ...block,
            state: EditorState.createWithContent(
              convertFromRaw(
                JSON.parse(
                  block.content
                    .replaceAll("'", '"')
                    .replace(/(\r\n|\n|\r)/gm, '\\n'),
                ),
              ),
            ),
            innerHtml: stringifyInnerHtml(block?.innerHtml),
          };
          if (block.nestedItemId) {
            const libComp = preparedImages.find(
              (it) => it.id === block.nestedItemId,
            );
            if (libComp && libComp.imagePath) {
              toReturn.urlFile = libComp.imagePath;
            }
          }
          return toReturn;
        }
        if (block.type === BlockTypes.page) {
          return {
            ...block,
            state: { pageId: block.pageId, title: block.title },
            innerHtml: innerHtmlPageLink(block.title),
          };
        }
        if (block.type === BlockTypes.webSite) {
          return {
            ...block,
            innerHtml: innerHtmlWebSiteTemplate(block.state.data),
            state: block.state,
          };
        }
        return {
          ...block,
          innerHtml: stringifyInnerHtml(block?.innerHtml),
          state: block.state,
        };
      })
      .filter((i) => !!i);
    yield call(requestCreateLibraryComponent, {
      fieldsUpdateObg: {
        id: `"${newLibraryComponentId}"`,
        title: `"${name}"`,
        type: '"component"',
        position: newPosition,
        createDate: lastModifiedDate,
        lastModifiedDate,
      },
      ownerId: user.id,
      contentTags: tags,
      components: preparedBlocks,
      stateContent: convertStateWithDraftJs(preparedBlocks),
    });

    yield put(actionCreator(ContentActionType.updateCounterS, {}));
    yield put(
      actionAddLibraryComponent({
        id: newLibraryComponentId,
        title: name,
        type: 'component',
        position: newPosition,
        createDate: lastModifiedDate,
        components: preparedBlocks,
        lastModifiedDate,
        playlist: { belongsToGroup: dateGroupEnum.TodayPeriod },
      }),
    );
    // if (tags?.length) yield attachTagsToLibraryComponent(tags, { id: newLibraryComponentId });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* getLibraryComponent(pageId) {
  try {
    const { data } = yield requestGetLibComponent(['extended', pageId]);
    const components = {};

    const pageData = data.LibraryComponent && data.LibraryComponent[0];

    const parsedTags = pageData?.contentTags?.reduce((acc, item) => {
      acc[item.ContentTag?.id] = { ...item, type: 'tags' };
      return acc;
    }, {});

    components[pageId] = {
      ...pageData,
      id: pageData.id,
      pdfPreviewUrl: pageData.pdfPreviewUrl,
      isNotDownload: true,
      title: sanitizeToLoad(pageData.title),
      movedToTrash: pageData.movedToTrash,
      isFavorite: !!pageData.favorites?.length,
      position: pageData.position,
      isPublish: pageData.isPublish,
      type: pageData.type,
      inPage: !!pageData?.inPage?.length,
      urlFile: pageData.urlFile,
      itemType: 'component',
      tags: Object.values(parsedTags || {}) ?? [],
      lastModifiedDate: pageData.lastModifiedDate,
      createDate: pageData.createDate,
      components: combineComponentsAndLibraryComponents({
        components: pageData.components,
        libraryComponents: pageData.libraryComponents,
        libraryComponentId: pageData.id,
      }),
      size: pageData.size,
      subtitle: pageData.subtitle,
      description: sanitizeToLoad(pageData.description),
      credits: sanitizeToLoad(pageData.credits),
      linkUrl: sanitizeToLoad(pageData.linkUrl).replaceAll('\\"', "'"),
    };

    yield put(actionUpdateLibraryComponentInRedux(components[pageId]));
  } catch (err) {
    yield showErrorMessage(err, { type: 'getLibraryComponent' });
  }
}

function* changeLibraryComponentInLibraryFromBlocks(action) {
  try {
    const { name, componentId, isPage, tags, pageId, history } = action.payload;
    const { id: userId } = yield select(getUser);
    const { editComponentId, isOpenCreateComponent } = yield select(getLibrary);
    const { currentPageId } = yield select(getCurrentPage);
    const { data } = yield requestGetLibComponent(['any', componentId]);

    const uploads = yield select(getUploads);
    const isSomeUnfinishedUploads = !!Object.values(uploads).filter(
      (upload) => upload.status !== 'READY',
    ).length;

    if (isSomeUnfinishedUploads) {
      yield put(
        actionShowMessage({
          type: MessageType.WarnUser,
          warningText: 'You have to wait for all uploads to finish',
        }),
      );
      return;
    }
    yield put(actionCloseCreateComponent());

    const blocks = yield select(getSupportBlocks);
    const lastModifiedDate = Math.floor(Date.now() / 1000);

    const componentData = data.LibraryComponent[0];
    const initialBlocksIds = data.LibraryComponent[0].components.map(
      (item) => item.id,
    );
    const resultBlocksIds = blocks.map((item) => item.id);
    const blocksToCreate = blocks.filter(
      (item) => !initialBlocksIds.includes(item.id),
    );
    const blocksToDelete = data.LibraryComponent[0].components.filter(
      (item) => !resultBlocksIds.includes(item.id),
    );
    const blocksToUpdate = blocks.filter((item) => initialBlocksIds.includes(item.id),
    );

    const preparedBlocks = blocks
      .map((block) => {
        if (
          block.type === BlockTypes.lineSeparator
          || block.type === BlockTypes.approveButton
          || block.type === BlockTypes.actionButton
          || block.type === BlockTypes.shortText
        ) {
          return {
            ...block,
            innerHtml: stringifyInnerHtml(block?.innerHtml),
            state: block.state,
          };
        }
        if (block.type === BlockTypes.image && !block?.content) {
          return null;
        }
        if (block.type === BlockTypes.image) {
          return {
            ...block,
            state: draftDataConvertToLoad(
              contentImageTemplate(block.urlSmallImage || block.urlFile),
            ),
          };
        }
        if (block.type === BlockTypes.page) {
          return {
            ...block,
            state: { pageId: block.pageId, title: block.title },
            innerHtml: innerHtmlPageLink(block.title),
          };
        }
        if (block.type === BlockTypes.webSite) {
          return {
            ...block,
            innerHtml: innerHtmlWebSiteTemplate(block.state.data),
            state: block.state,
          };
        }
        if (block.type === BlockTypes.googleEmbed) {
          return {
            ...block,
            innerHtml: innerHtmlGoogleEmbedTemplate(block.state.data),
            state: block.state,
          };
        }
        if (block.type === BlockTypes.dropboxEmbed) {
          return {
            ...block,
            innerHtml: innerHtmlDropboxEmbedTemplate(block.state.data),
            state: block.state,
          };
        }
        return {
          ...block,
          innerHtml: stringifyInnerHtml(block?.innerHtml),
          state: block.state,
        };
      })
      .filter((i) => !!i);

    yield put(
      actionUpdateLibraryComponentInRedux({
        ...componentData,
        components: preparedBlocks,
        lastModifiedDate,
        title: name,
      }),
    );

    if (isPage) {
      const currentPageBlocks = yield select(getBlocks);

      const mergedBlocks = currentPageBlocks.map((item) => {
        if (
          item?.libraryComponentId === componentId
          || item?.nestedItemId === componentId
        ) {
          item.components = preparedBlocks;
          item.title = name;
        }
        return item;
      });

      const layers = getLayersForPage(mergedBlocks);
      yield put(actionSwitchPage({ blocks: mergedBlocks, layers }));
    }

    yield call(
      requestUpdateLibraryComponent(
        placementEnum.queryAddComponentsToCurrentLibraryComponent,
      ),
      createBodyAddComponentsToCurrentLibraryComponent(
        blocksToCreate,
        blocksToDelete,
        blocksToUpdate,
        userId,
        componentId,
        name,
      ),
    );
    if (pageId && isPage) {
      yield getLibraryComponent(pageId);
    }
    if (isOpenCreateComponent && !pageId) {
      yield getLibraryComponent(componentId);
    }
    if (currentPageId && editComponentId && history) {
      history.push(history.location.pathname);
    }
    if (tags?.length) yield attachTagsToLibraryComponent(tags, { id: componentId }, true);
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* revertLibraryComponentInLibraryFromBlocks(action) {
  try {
    const { componentId, oldState, isPage, pageId } = action.payload;
    const { id: userId } = yield select(getUser);
    const components = yield select(getLibraryComponents);
    const tags = oldState.tags;
    const blocks = oldState.components;
    const name = oldState.title;
    const lastModifiedDate = Math.floor(Date.now() / 1000);

    const componentData = components[componentId];
    const initialBlocksIds = componentData.components.map((item) => item.id);
    const resultBlocksIds = blocks.map((item) => item.id);
    const blocksToCreate = blocks.filter(
      (item) => !initialBlocksIds.includes(item.id),
    );
    const blocksToDelete = componentData.components.filter(
      (item) => !resultBlocksIds.includes(item.id),
    );
    const blocksToUpdate = blocks.filter((item) => initialBlocksIds.includes(item.id),
    );

    const preparedBlocks = blocks
      .map((block) => {
        if (
          block.type === BlockTypes.lineSeparator
          || block.type === BlockTypes.approveButton
          || block.type === BlockTypes.actionButton
          || block.type === BlockTypes.shortText
        ) {
          return {
            ...block,
            innerHtml: stringifyInnerHtml(block?.innerHtml),
            state: block.state,
          };
        }
        if (block.type === BlockTypes.image && !block?.content) {
          return null;
        }
        if (block.type === BlockTypes.image) {
          return {
            ...block,
            state: EditorState.createWithContent(
              convertFromRaw(
                JSON.parse(
                  block.content
                    .replaceAll("'", '"')
                    .replace(/(\r\n|\n|\r)/gm, '\\n'),
                ),
              ),
            ),
            innerHtml: stringifyInnerHtml(block?.innerHtml),
          };
        }
        if (block.type === BlockTypes.page) {
          return {
            ...block,
            state: { pageId: block.pageId, title: block.title },
            innerHtml: innerHtmlPageLink(block.title),
          };
        }
        if (block.type === BlockTypes.webSite) {
          return {
            ...block,
            innerHtml: innerHtmlWebSiteTemplate(block.state.data),
            state: block.state,
          };
        }
        return {
          ...block,
          innerHtml: stringifyInnerHtml(block?.innerHtml),
          state: block.state,
        };
      })
      .filter((i) => !!i);

    if (isPage) {
      const currentPageBlocks = yield select(getBlocks);

      const mergedBlocks = currentPageBlocks.map((item) => {
        if (
          item?.libraryComponentId === componentId
          || item?.nestedItemId === componentId
        ) {
          item.components = preparedBlocks;
          item.title = name;
        }
        return item;
      });

      const layers = getLayersForPage(mergedBlocks);
      yield put(actionSwitchPage({ blocks: mergedBlocks, layers }));
    }

    yield put(
      actionUpdateLibraryComponentInRedux({
        ...componentData,
        components: preparedBlocks,
        lastModifiedDate,
        title: name,
      }),
    );
    yield call(
      requestUpdateLibraryComponent(
        placementEnum.queryAddComponentsToCurrentLibraryComponent,
      ),
      createBodyAddComponentsToCurrentLibraryComponent(
        blocksToCreate,
        blocksToDelete,
        blocksToUpdate,
        userId,
        componentId,
        name,
      ),
    );
    if (pageId && isPage) {
      yield getLibraryComponent(pageId);
    }

    if (tags.length) yield attachTagsToLibraryComponent(tags, { id: componentId }, true);
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* detachComponent(action) {
  try {
    const { detachComponentId, createComponentAfterDetach } = action.payload;

    const currentPageData = yield select(getCurrentPage);
    const pageId = currentPageData.id;
    const components = {};
    currentPageData.blocks.forEach((item) => {
      components[item.nestedItemId] = item;
    });
    const test = currentPageData.blocks
      .filter((i) => detachComponentId.includes(i.id))
      .map((i) => i.nestedItemId)
      .filter((i) => i);
    const index = currentPageData.blocks.findIndex(
      (i) => i.nestedItemId && detachComponentId.includes(i.id),
    );
    const sourceLibraryComponentsBlocks = [];

    const pageNestedBlocksData = currentPageData.blocks.reduce((acc, block) => {
      block?.components?.forEach((innerBlock) => {
        acc[innerBlock.id] = innerBlock.nestedItemId;
      });
      return acc;
    }, {});

    test.forEach((id) => {
      if (
        components[id]
        && components[id].type.split('/')[0] === BlockTypes.image
      ) {
        sourceLibraryComponentsBlocks.push({
          type: BlockTypes.image,
          ...components[id],
          id: components[id].id,
        });
      } else if (
        components[id]
        && (components[id].type.split('/')[0] === 'video'
          || components[id].type.split('/')[0] === 'application')
      ) {
        // sourceLibraryComponentsBlocks.push({ type: 'image-file', urlFile: components[id].urlFile });
      } else if (
        components[id]?.type === 'text/html'
        || components[id]?.type === 'link'
      ) {
        const newId = uuidv4();
        const content = {};
        content.state = EditorState.createEmpty();
        sourceLibraryComponentsBlocks.push({
          id: newId,
          ...content,
          type: BlockTypes.webSite,
          data: {
            url: components[id].linkUrl,
            title: components[id].title,
            description: sanitizeToLoad(components[id].description),
            images: [components[id].urlFile],
          },
        });
      } else if (
        components[id]?.components
        && components[id]?.components.length
      ) {
        if (components[id].components[0].type === LibraryComponentTypes.page) {
          sourceLibraryComponentsBlocks.push({
            ...components[id].components[0],
            title: components[id].title,
            pageId: components[id].id,
          });
          return;
        }
        if (components[id].components[0].type === BlockTypes.pdf) {
          sourceLibraryComponentsBlocks.push({
            ...components[id].components[0],
            title: components[id].title,
            pageId: components[id].components[0].nestedItemId,
          });
          return;
        }
        if (components[id].components[0].type === BlockTypes.embed) {
          const text = sanitizeToLoad(
            components[id].components[0]?.state?.data?.text,
          );
          const state = { data: { text, isPreview: true } };

          sourceLibraryComponentsBlocks.push({
            ...components[id].components[0],
            state,
          });

          return;
        }

        sourceLibraryComponentsBlocks.push(
          components[id].components.map((item) => {
            const readyValue = { ...item };
            if (pageNestedBlocksData[item.id]) readyValue.nestedItemId = pageNestedBlocksData[item.id];
            return readyValue;
          }),
        );
      }
    });
    const currentPageBlocks = currentPageData.blocks;
    const targetBlockPosition = currentPageBlocks[index - 1]?.position || 0;
    const nextBlockPosition = currentPageBlocks[index]?.position
      || targetBlockPosition + DEFAULT_POSITION_STEP;
    const positionRange = nextBlockPosition - targetBlockPosition;
    const wholeNewBlocksArray = sourceLibraryComponentsBlocks.flat();

    const newPositionStep = positionRange / (wholeNewBlocksArray.length + 1);

    const newBlocks = wholeNewBlocksArray.map((item, idx) => {
      const newBlockId = uuidv4();
      if (
        (item.type && item.type === BlockTypes.image_file)
        || item.type === BlockTypes.image
      ) {
        return {
          ...item,
          id: newBlockId,
          libraryComponentToAttachId: item.id,
          type: BlockTypes.image,
          innerHtml: innerHtmlImageTemplate(item.urlSmallImage || item.urlFile),
          position: newPositionStep
            ? targetBlockPosition + (idx + 1) * newPositionStep
            : DEFAULT_POSITION_STEP,
        };
      }

      if (item.type && item.type === BlockTypes.webSite) {
        if (item?.data) {
          return {
            ...item,
            id: item.id,
            type: item.type,
            innerHtml: innerHtmlWebSiteTemplate(item.data),
            position: newPositionStep
              ? targetBlockPosition + (idx + 1) * newPositionStep
              : DEFAULT_POSITION_STEP,
            state: {
              data: {
                url: item?.data?.url,
                download: true,
                title: item?.data?.title,
                description: item?.data?.description,
                images: item?.data?.images,
              },
            },
          };
        }
        if (item?.state?.data) {
          return {
            id: item.id,
            type: item.type,
            innerHtml: innerHtmlWebSiteTemplate(item?.state?.data),
            position: newPositionStep
              ? targetBlockPosition + (idx + 1) * newPositionStep
              : DEFAULT_POSITION_STEP,
            state: {
              data: {
                url: item?.state?.data?.url,
                download: true,
                title: item?.state?.data?.title,
                description: item?.state?.data?.description,
                images: item?.state?.data?.images,
              },
            },
          };
        }
        return {
          id: newBlockId,
          type: item.type,
          innerHtml: item.innerHtml,
          position: newPositionStep
            ? targetBlockPosition + (idx + 1) * newPositionStep
            : DEFAULT_POSITION_STEP,
          content: item.content,
        };
      }
      if (
        item.type === BlockTypes.lineSeparator
        || item.type === BlockTypes.approveButton
        || item.type === BlockTypes.actionButton
        || item.type === BlockTypes.shortText
      ) {
        return {
          id: newBlockId,
          type: item.type,
          innerHtml: item.innerHtml,
          position: newPositionStep
            ? targetBlockPosition + (idx + 1) * newPositionStep
            : DEFAULT_POSITION_STEP,
          content: item.content,
          state: item.state,
        };
      }
      if (item.type === BlockTypes.googleEmbed) {
        const data = item.state.data;
        return {
          ...item,
          id: newBlockId,
          type: BlockTypes.googleEmbed,
          nestedItemId: item.nestedItemId,
          innerHtml: innerHtmlGoogleEmbedTemplate({
            images: data.images,
            title: data.title,
            description: data.description,
            url: data.url,
            foreignLastModifiedDate: data.foreignLastModifiedDate,
            foreignLastModifiedUserName: data.foreignLastModifiedUserName,
          }),
          state: {
            data: {
              url: data.url,
              title: data.title,
              description: sanitizeToLoad(data.description),
              images: data.images,
              foreignFileId: data.foreignFileId,
              libraryComponentId: item.nestedItemId,
              linkedAccountId: data.linkedAccountId,
              foreignLastModifiedDate: data.foreignLastModifiedDate,
              foreignLastModifiedUserName: data.foreignLastModifiedUserName,
            },
          },
          position: newPositionStep
            ? targetBlockPosition + (idx + 1) * newPositionStep
            : DEFAULT_POSITION_STEP,
        };
      }
      if (item.type === BlockTypes.dropboxEmbed) {
        const data = item.state.data;
        return {
          ...item,
          id: newBlockId,
          type: BlockTypes.dropboxEmbed,
          nestedItemId: item.nestedItemId,
          innerHtml: innerHtmlDropboxEmbedTemplate({
            images: data.images,
            title: data.title,
            description: data.description,
          }),
          state: {
            data: {
              url: data.url,
              title: data.title,
              description: sanitizeToLoad(data.description),
              images: data.images,
              foreignFileId: data.foreignFileId,
              libraryComponentId: item.nestedItemId,
            },
          },
          position: newPositionStep
            ? targetBlockPosition + (idx + 1) * newPositionStep
            : DEFAULT_POSITION_STEP,
        };
      }
      return {
        ...item,
        id: newBlockId,
        type: item.type,
        innerHtml: item.innerHtml,
        position: newPositionStep
          ? targetBlockPosition + (idx + 1) * newPositionStep
          : DEFAULT_POSITION_STEP,
        content: item.content,
      };
    });
    const sourceBlocks = newBlocks;
    const blockState = [
      ...currentPageData.blocks.slice(0, index),
      ...sourceBlocks,
      ...currentPageData.blocks.slice(index + test.length),
    ];

    if (action.metod === 'UNDO') {
      historyEvents[historyStep].prevData = {
        idsBlock: sourceBlocks.map((i) => i.id),
        index,
        idsLibComp: test,
      };
    } else {
      historyEvents[historyStep - 1].prevData = {
        idsBlock: sourceBlocks.map((i) => i.id),
        index,
        idsLibComp: test,
      };
    }
    const layers = getLayersForPage(blockState);
    if (newBlocks.length) {
      yield put(actionSwitchPage({ blocks: blockState, layers }));
      yield call(requestDeleteComponent(placementEnum.queryDetachComponent), {
        pageId,
        blocksArray: newBlocks,
        detachComponents: detachComponentId,
        contentArray: createDetachComponentBody(newBlocks),
      });
    }
    yield put(actionRemoveAllSelected());
    yield put(actionUnblockUnloading());
    yield put(actionClearDragState());
    if (createComponentAfterDetach) {
      createComponentAfterDetach(
        [...sourceBlocks.map((i) => i.id), ...detachComponentId],
        blockState,
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function mutateObject(obj) {
  return ({
    components,
    outerId: id,
    newBlockId,
    newPositionStep,
    targetBlockPosition,
    blockIndex,
  }) => {
    obj[id] = { id: newBlockId };
    const position = targetBlockPosition + (blockIndex + 1) * newPositionStep;
    const sourceComponent = components[id];
    const isComponentImage = sourceComponent && sourceComponent?.type?.split('/')[0] === 'image';
    const isComponentLink = (sourceComponent && sourceComponent?.type === 'text/html')
      || sourceComponent?.type === 'link';
    const isComponentPage = sourceComponent && sourceComponent?.type === 'page';
    const isComponentVideo = sourceComponent && sourceComponent?.type?.split('/')[0] === 'video';
    const isComponentEmbed = sourceComponent
      && sourceComponent?.type === LibraryComponentTypes.embed_component;
    const isComponentGoogleEmbed = sourceComponent
      && sourceComponent?.type === LibraryComponentTypes.google_embed_component;
    const isComponentDropboxEmbed = sourceComponent
      && sourceComponent?.type === LibraryComponentTypes.dropbox_embed_component;
    const isComponentPdf = sourceComponent && sourceComponent?.type === 'application/pdf';
    const isMs = UiComponentTypes.ms[sourceComponent?.type]
      || UiComponentTypes.xls[sourceComponent?.type]
      || UiComponentTypes.pp[sourceComponent?.type]
      || UiComponentTypes.presentation[sourceComponent?.type];
    const isCustomComponent = sourceComponent
      && (sourceComponent?.type === BlockTypes.shortText
        || sourceComponent?.type === BlockTypes.approveButton
        || sourceComponent?.type === BlockTypes.actionButton
        || sourceComponent?.type === BlockTypes.lineSeparator);

    const isComponentHasComponents = sourceComponent && !!sourceComponent?.components?.length;
    if (isComponentImage || isComponentVideo) {
      const innerContent = {};
      const content = contentImageTemplate(
        sourceComponent.urlVerySmallImage
          || sourceComponent.urlSmallImage
          || sourceComponent.urlFile,
      );
      const innerHtml = innerHtmlImageTemplate(
        sourceComponent.urlVerySmallImage
          || sourceComponent.urlSmallImage
          || sourceComponent.urlFile,
      );
      try {
        innerContent.state = EditorState.createWithContent(
          convertFromRaw(
            JSON.parse(
              content
                .replaceAll('"', '\\"')
                .replaceAll("'", '"')
                .replaceAll(/(\r\n|\n|\r)/gm, '\\n')
                .replaceAll('$@quote', "'"),
            ),
          ),
        );
      } catch (e) {
        // eslint-disable-next-line max-len
        innerContent.state = EditorState.createWithContent(
          convertFromRaw(
            JSON.parse(
              "{'blocks':[{'key':'daj6c','text':'','type':'text','depth':0,'inlineStyleRanges':[],'entityRanges':[],'data':{}}],'entityMap':{}}"
                .replaceAll("'", '"')
                .replace(/(\r\n|\n|\r)/gm, '\\n'),
            ),
          ),
        );
      }

      obj[id] = {
        ...obj[id],
        type: BlockTypes.image,
        urlFile: sourceComponent.urlFile,
        nestedItemId: id,
        urlSmallImage: sourceComponent.urlSmallImage,
        urlVerySmallImage: sourceComponent.urlVerySmallImage,
        innerHtml,
        ...innerContent,
        content,
        position,
      };
    } else if (isComponentLink) {
      obj[id] = {
        ...obj[id],
        type: BlockTypes.webSite,
        innerHtml: innerHtmlWebSiteTemplate({
          images: [sourceComponent.urlFile],
          title: sourceComponent.title,
          description: sanitizeToLoad(sourceComponent.description),
          url: sourceComponent.linkUrl,
        }),
        state: {
          data: {
            url: sourceComponent.linkUrl,
            download: true,
            title: sourceComponent.title,
            description: sanitizeToLoad(sourceComponent.description),
            images: [sourceComponent.urlFile],
          },
        },
        position,
        nestedItemId: id,
      };
    } else if (isComponentPage) {
      obj[id] = {
        ...obj[id],
        nestedItemId: id,
        innerHtml: innerHtmlPageLink(sourceComponent.title),
        type: BlockTypes.page,
        id: newBlockId,
        title: sourceComponent.title,
        position,
      };
    } else if (isComponentEmbed) {
      obj[id] = {
        ...obj[id],
        type: BlockTypes.embed,
        innerHtml: innerHtmlEmbed(sourceComponent.linkUrl),
        state: { data: { text: sourceComponent.linkUrl, isPreview: true } },
        nestedItemId: id,
        position,
      };
    } else if (isComponentGoogleEmbed) {
      obj[id] = {
        ...obj[id],
        type: BlockTypes.googleEmbed,
        nestedItemId: sourceComponent.id,
        innerHtml: innerHtmlGoogleEmbedTemplate({
          images: [sourceComponent.urlFile],
          title: sourceComponent.title,
          description: sourceComponent.description,
          url: sourceComponent.linkUrl,
          foreignLastModifiedDate: sourceComponent.foreignLastModifiedDate,
          foreignLastModifiedUserName:
            sourceComponent.foreignLastModifiedUserName,
        }),
        state: {
          data: {
            url: sourceComponent.linkUrl,
            title: sourceComponent.title,
            description: sanitizeToLoad(sourceComponent.description),
            images: [sourceComponent.urlFile],
            foreignFileId: sourceComponent.foreignFileId,
            libraryComponentId: sourceComponent.id,
            linkedAccountId: sourceComponent.linkedAccountId,
            foreignLastModifiedDate: sourceComponent.foreignLastModifiedDate,
            foreignLastModifiedUserName:
              sourceComponent.foreignLastModifiedUserName,
          },
        },
        position,
      };
    } else if (isComponentDropboxEmbed) {
      obj[id] = {
        ...obj[id],
        type: BlockTypes.dropboxEmbed,
        nestedItemId: sourceComponent.id,
        innerHtml: innerHtmlDropboxEmbedTemplate({
          images: [sourceComponent.urlFile],
          title: sourceComponent.title,
          description: sourceComponent.description,
        }),
        state: {
          data: {
            url: sourceComponent.linkUrl,
            title: sourceComponent.title,
            description: sanitizeToLoad(sourceComponent.description),
            images: [sourceComponent.urlFile],
          },
        },
        position,
      };
    } else if (isComponentPdf) {
      obj[id] = {
        ...obj[id],
        type: BlockTypes.pdf,
        contentType: 'application/pdf',
        innerHtml: innerHtmlPdfTemplate(
          sourceComponent.title,
          sourceComponent.size,
        ),
        position,
        nestedItemId: id,

        state: {
          data: {
            isNew: true,
            title: sourceComponent.title,
            size: sourceComponent.size,
            nestedItemId: id,
            representationState: 'attachment',
            width: 700,
            urlFile: sourceComponent.urlFile,
          },
        },
      };
    } else if (isMs) {
      // TODO ('HERE WRONG type');
      obj[id] = {
        ...obj[id],
        type: sourceComponent.type,
        innerHtml: innerHtmlPdfTemplate(
          sourceComponent.title,
          sourceComponent.size,
        ),
        position,
        contentType: sourceComponent.type,
        nestedItemId: id,

        state: {
          data: {
            nestedItemId: id,
            width: 700,
            representationState: 'attachment',
            title: sourceComponent.title,
            size: sourceComponent.size,
            pdfPreviewUrl: sourceComponent.pdfPreviewUrl,
            urlFile: sourceComponent.urlFile,
            isNew: !!sourceComponent.pdfPreviewUrl,
          },
        },
      };
    } else if (isCustomComponent) {
      obj[id] = {
        ...obj[id],
        position,
        type: BlockTypes.text,
        state: EditorState.createWithText(''),
      };
    } else if (isComponentHasComponents) {
      obj[id].type = 'component';
      obj[id].position = position;
      obj[id].title = sourceComponent.title;
      obj[id].nestedItemId = id;
      obj[id].width = sourceComponent.type === BlockTypes.pdf ? 700 : null;
      obj[id].components = sourceComponent.components.map((item) => {
        const isItemPage = item.type === BlockTypes.page;
        const isItemImage = item.type === BlockTypes.image_file || item.type === BlockTypes.image;
        const isItemVideo = item.type === BlockTypes.video;
        const isEmbed = item.type === BlockTypes.embed;
        const isGoogleEmbed = item.type === BlockTypes.googleEmbed;
        const isDropboxEmbed = item.type === BlockTypes.dropboxEmbed;
        const isItemCustomBlock = item.type === BlockTypes.lineSeparator
          || item.type === BlockTypes.approveButton
          || item.type === BlockTypes.shortText
          || item.type === BlockTypes.actionButton;
        const isItemPdf = item.type === BlockTypes.pdf;
        const isMsItem = UiComponentTypes.ms[item?.type] || UiComponentTypes.xls[item?.type];
        const isWebSite = item.type === BlockTypes.webSite;
        if (isItemCustomBlock) {
          const tmpContent = parseContentForUsage(item.content);
          return {
            id: item.id,
            position: item.position,
            type: item.type,
            innerHtml: item.innerHtml,
            content: item.content,
            state: item.state || tmpContent || '',
          };
        }
        if (isItemPage) {
          return {
            id: item.id,
            position: item.position,
            type: BlockTypes.page,
            title: item.title,
            innerHtml: item.innerHtml,
            state: item.state,
            nestedItemId: item.nestedItemId,
          };
        }
        if (isItemImage || isItemVideo) {
          const intermediateItem = {
            id: item.id,
            position: item.position,
            type: item.type,
            innerHtml: item.innerHtml,
            width: item.width || 700,
            state: draftDataConvertToLoad(contentImageTemplate(item.urlFile)),
            urlFile: item.urlFile,
          };
          mutateImageBlockState(item.content, intermediateItem);
          return intermediateItem;
        }
        if (isWebSite) {
          let tmpState;
          if (item?.state) {
            tmpState = {
              data: {
                ...item.state.data,
                images: item.state.data.images,
                mediaType: BlockTypes.webSite,
                contentType: 'text/html',
              },
            };
          } else {
            try {
              tmpState = JSON.parse(
                item.content
                  .replaceAll('"', '\\"')
                  .replaceAll("'", '"')
                  .replaceAll(/(\r\n|\n|\r)/gm, '\\n')
                  .replaceAll('$@quote', "'"),
              );
            } catch (e) {
              tmpState = { data: '' };
            }
          }

          return {
            id: item.id,
            type: item.type,
            innerHtml: item.innerHtml,
            state: tmpState,
          };
        }

        if (isEmbed) {
          return {
            id: item.id,
            position: item.position,
            type: BlockTypes.embed,
            innerHtml: item.innerHtml,
            state: JSON.parse(sanitizeToLoad(item.state)),
            width: item.width,
          };
        }

        if (isGoogleEmbed) {
          return {
            id: item.id,
            type: item.type,
            innerHtml: item.innerHtml,
            state: {
              data: {
                ...item.state.data,
                images: item.state.data.images,
                mediaType: BlockTypes.googleEmbed,
                contentType: 'text/html',
              },
            },
          };
        }
        if (isDropboxEmbed) {
          return {
            id: item.id,
            type: item.type,
            innerHtml: item.innerHtml,
            state: {
              data: {
                ...item.state.data,
                images: item.state.data.images,
                mediaType: BlockTypes.dropboxEmbed,
                contentType: 'text/html',
              },
            },
          };
        }

        if (isItemPdf || isMsItem) {
          return {
            id: item.id,
            type: item.type,
            innerHtml: item.innerHtml,
            state: {
              data: {
                ...item.state.data,
              },
            },
          };
        }

        return {
          id: item.id,
          position: item.position,
          innerHtml: item.innerHtml,
          content: item?.content,
          type: item.type,
          nestedItemId: id,
          state:
            draftDataConvertToLoad(item.state)
            || draftDataConvertToLoad(item.content),
        };
      });
    }
    return obj;
  };
}

function* copyBlocksToPage(action) {
  try {
    const { componentsIds, index, isReplaceFirst, isLibraryCreate } = action.payload;
    const { id: userId } = yield select(getUser);
    const currentPageData = yield select(getCurrentPage);
    const { id: pageId } = currentPageData;
    const components = yield select(getContent);
    const supportBlocks = yield select(getSupportBlocks);

    let isAllContentAllowed = true;
    Object.keys(componentsIds).forEach((key) => {
      const type = components[key]?.type;
      if (!type || !isTypeAllowedForPage[type]) {
        isAllContentAllowed = false;
      }
    });
    if (!isAllContentAllowed) {
      yield put(actionShowMessage({ type: MessageType.fileNotSupported }));
      return;
    }
    let blocksData = {};
    let nedToDelete = '';
    const currentPageBlocks = [
      ...(isLibraryCreate ? supportBlocks : currentPageData.blocks),
    ];

    if (isReplaceFirst) {
      nedToDelete = currentPageBlocks[index]?.id;
      if (currentPageBlocks[index]) delete currentPageBlocks[index];
    }

    const targetBlockPosition = currentPageBlocks[index - 1]?.position || 0;
    const nextBlockPosition = currentPageBlocks[index]?.position
      || targetBlockPosition + DEFAULT_POSITION_STEP;
    const positionRange = nextBlockPosition - targetBlockPosition;

    const newPositionStep = positionRange / (Object.keys(componentsIds).length + 2);
    Object.entries(componentsIds).forEach((a, blockIndex) => {
      const [outerId, newBlockId] = a;
      if (components[outerId]) {
        blocksData = {
          ...blocksData,
          ...mutateObject(blocksData)({
            components,
            outerId,
            newBlockId,
            newPositionStep,
            targetBlockPosition,
            blockIndex,
          }),
        };
      }
    });
    const filtered = Object.values(blocksData).filter(
      (item) => item.type !== BlockTypes.video,
    );
    const blockState = [
      ...currentPageBlocks.slice(0, index),
      ...filtered,
      ...currentPageBlocks.slice(index),
    ].filter((i) => i);
    const layers = getLayersForPage(blockState);
    if (isLibraryCreate) {
      yield put(actionUpdateSupportLayers(layers));
      yield put(actionUpdateSupportBlocks({ blocks: blockState }));
    } else {
      yield put(actionSwitchPage({ blocks: blockState, layers }));
    }
    if (filtered.length && !isLibraryCreate) {
      yield call(requestUpdateLibraryComponent(), {
        place: 'queryCopyComponentsToPage',
        id: pageId,
        create: {
          components: filtered.map((block) => ({
            id: block.id,
            position: block.position,
            addOwner: userId,
            type: block.type,
            innerHtml: sanitizeToSave(block.innerHtml),
            libraryComponentId: block.nestedItemId,
          })),
        },
      });
    }
    const reduxUpdateObj = {
      ...components[pageId],
      components: blockState,
    };
    yield put(actionUpdateLibraryComponentInRedux(reduxUpdateObj));

    yield put(actionRemoveAllSelected());
    yield put(actionUnblockUnloading());
    yield put(actionClearDragState());
    if (isReplaceFirst && !isLibraryCreate) {
      yield call(requestDeleteComponent(placementEnum.queryDeleteComp), {
        compId: nedToDelete,
      });
    }
    yield all(
      filtered.map((item) => {
        if (item.type === 'component' && item.nestedItemId) {
          return put(actionSaveComponentDescriptionData(item.nestedItemId));
        }
        return () => {};
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* copyBlockImageToPage(action) {
  try {
    const { index, libCompId } = action.payload;

    const currentPageData = yield select(getCurrentPage);
    const { isOpenCreateComponent } = yield select(getLibrary);
    const { data } = yield requestGetLibComponent(['any', libCompId]);
    const newBlocks = [...currentPageData.blocks];
    let state;
    const { urlFile, urlSmallImage, urlVerySmallImage } = data?.LibraryComponent[0] ?? {};
    const content = contentImageTemplate(
      urlVerySmallImage ?? urlSmallImage ?? urlFile,
    );
    try {
      state = EditorState.createWithContent(
        convertFromRaw(
          JSON.parse(
            contentImageTemplate(urlFile)
              .replaceAll('"', '\\"')
              .replaceAll("'", '"')
              .replaceAll(/(\r\n|\n|\r)/gm, '\\n')
              .replaceAll('$@quote', "'"),
          ),
        ),
      );
    } catch (e) {
      state = EditorState.createWithContent(
        convertFromRaw(
          JSON.parse(
            `{'blocks':[{'key':'daj6c','text':'','type':'text','depth':0,'inlineStyleRanges'${":[],'entityRanges':[],'data':{}}],'entityMap':{}}"
              .replaceAll("'", '"')
              .replace(/(\r\n|\n|\r)/gm, '\\n')}`,
          ),
        ),
      );
    }

    const newBlock = {
      ...newBlocks[index],
      urlFile,
      urlSmallImage,
      urlVerySmallImage,
      state,
      innerHtml: innerHtmlImageTemplate(urlSmallImage ?? urlFile),
      content,
    };

    yield call(requestUpdateComponent(), {
      id: newBlock.id,
      connect: {
        libraryComponents: [libCompId],
      },
    });

    yield put(
      actionUpdateBlock(
        newBlock.id,
        state,
        newBlock.width,
        innerHtmlImageTemplate(urlVerySmallImage),
        null,
        {
          urlFile,
          urlSmallImage,
          urlVerySmallImage,
        },
        newBlock.type,
        content,
      ),
    );
    if (!isOpenCreateComponent) {
      const updComponentField = {
        content: `"${
          state.getCurrentContent
          && JSON.stringify(convertToRaw(state.getCurrentContent()))
            .replaceAll("'", '$@quote')
            .replaceAll('"', "'")
            .replaceAll("\\'", '\\"')
        }"`,
        innerHtml: `"${sanitizeToSave(
          innerHtmlImageTemplate(urlVerySmallImage ?? urlSmallImage ?? urlFile),
        )}"`,
      };
      yield call(requestUpdateLibraryComponent(), {
        place: 'queryUpdateComponentContent',
        id: currentPageData.id,
        updateNested: {
          components: [{ id: newBlock.id, field: updComponentField }],
        },
      });
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* CopyUploadToPage(action) {
  try {
    const { index, libCompId, blockId } = action.payload;
    const { id: userId } = yield select(getUser);

    const currentPageData = yield select(getCurrentPage);
    const { data } = yield requestGetLibComponent(['any', libCompId]);
    const newBlocks = [...currentPageData.blocks];
    let state;
    const { urlFile, urlSmallImage, urlVerySmallImage, type, pdfPreviewUrl, size, title } = data?.LibraryComponent[0] ?? {};

    const currentBlock = currentPageData.blocks.find(obj => obj.id === blockId);
    if (!currentBlock) return;
    if (UiComponentTypes.pdf[type] || pdfPreviewUrl) {
      const newBlock = {
        ...currentBlock,
        type,
        contentType: type,
        nestedItemId: libCompId,
        state: {
          data: {
            nestedItemId: libCompId,
            width: 700,
            representationState: 'attachment',
            title,
            size,
            pdfPreviewUrl,
            urlFile,
            isNew: true,
          },
        },
      };


      const blockState = [
        ...newBlocks.slice(0, index),
        newBlock,
        ...newBlocks.slice(index + 1),
      ].filter((i) => i);
      const layers = getLayersForPage(blockState);

      yield put(actionSwitchPage({ blocks: blockState, layers }));

      const { position, id } = newBlock;
      const queryWithBlockStateToAddToPage = { position, id, type, addOwner: userId };

      yield call(requestUpdateLibraryComponent(), {
        place: 'queryAddComponent',
        id: currentPageData.id,
        create: {
          components: [queryWithBlockStateToAddToPage],
        },
      });

      yield call(requestUpdateComponent(), {
        id: newBlock.id,
        connect: {
          libraryComponents: [libCompId],
        },
      });

      yield put(
        actionUpdateBlock(
          newBlock.id,
          state,
          newBlock.width,
          innerHtmlImageTemplate(urlVerySmallImage),
          null,
          {
            urlFile,
            urlSmallImage,
            urlVerySmallImage,
          },
          newBlock.type,
        ),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* bulkAddComponentsToDragState(action) {
  try {
    const components = yield select(getLibraryComponents);
    const collections = yield select(getLibraryCollections);
    const keyIds = Object.keys(action.payload);
    const valuesIds = Object.values(action.payload || {});
    const arrayOfComponents = keyIds.reduce((acc, item) => {
      if (components[item]) {
        acc.push({ componentId: item });
        return acc;
      }

      Object.values(collections[item].nestedPage || {}).forEach((item1) => {
        valuesIds.forEach((i) => {
          if (Object.keys(i)[0] === item1.id) acc.push({ collectionId: item, componentId: item1.id });
        });
      });
      return acc;
    }, []);
    yield all(
      arrayOfComponents.map((item) => {
        return put(
          actionAddComponentToDragState(item.componentId, item.collectionId),
        );
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* checkLinkForLibraryComponent(action) {
  const parserErrorHandler = (parserResponse) => {
    let resp;
    const {
      response: {
        data: { error },
      },
    } = parserResponse;
    if (error.code === 'ENOTFOUND' || error.code === 'ECONNREFUSED') {
      resp = { error: 'Failed to connect.' };
    } else if (error.code === 'ERR_TLS_CERT_ALTNAME_INVALID') {
      resp = { retryHttp: true };
    } else {
      resp = { error: 'Provide a correct URL link.' };
    }
    return resp;
  };

  const mutateValidationSuccess = (error) => !error;

  try {
    const { urlText, setIsCreateLinkVisible, setLocalError, addedTags } = action.payload;
    let successValidation;
    const urlHasProtocolPrefix = urlText.includes('http://')
      || urlText.includes('https://')
      || urlText.includes('ftp://');
    let testUrl = urlHasProtocolPrefix ? urlText : `https://${urlText}`;
    let parserResponse;
    let response;
    try {
      parserResponse = yield call(requestParser, { url: testUrl });
    } catch (err) {
      response = parserErrorHandler(err);
    }
    if (response?.retryHttp) {
      testUrl = `http://${urlText}`;
      try {
        parserResponse = yield call(requestParser, { url: testUrl });
      } catch (err) {
        response = parserErrorHandler(err);
      }
      successValidation = mutateValidationSuccess(response?.error);
    } else {
      successValidation = mutateValidationSuccess(response?.error);
    }

    if (successValidation) {
      yield put(
        actionCreateLinkLibraryComponent(
          testUrl,
          null,
          parserResponse,
          addedTags,
        ),
      );
      setIsCreateLinkVisible(false);
    } else {
      setLocalError(response?.error);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* createEmbedLibraryComponent(action) {
  try {
    const user = yield select(getUser);
    const components = yield select(getLibraryComponents);
    const { iframeText, newId, tagsToAttach = [] } = action.payload;
    const newNamePostfix = uuidv4().slice(0, 4);
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const newPosition = Object.values(components || {}).sort(
      (a, b) => b.position - a.position,
    )[0];

    const newLibraryComponent = {
      id: newId,
      title: `embed_${newNamePostfix}`,
      linkUrl: iframeText,
      type: LibraryComponentTypes.embed_component,
      lastModifiedDate,
      position: calculateIndex(newPosition?.position),
      itemType: 'component',
    };

    const fieldsUpdateObg = {
      isShareToWeb: false,
      title: `"${sanitizeToSave(newLibraryComponent.title)}"`,
      id: `"${newLibraryComponent.id}"`,
      createDate: newLibraryComponent.lastModifiedDate,
      lastModifiedDate: newLibraryComponent.lastModifiedDate,
      position: newLibraryComponent.position,
      type: `"${newLibraryComponent.type}"`,
      linkUrl: `"${sanitizeToSave(newLibraryComponent.linkUrl)}"`,
      description: `"${sanitizeToSave(newLibraryComponent.description)}"`,
    };
    if (newLibraryComponent.urlFile) {
      fieldsUpdateObg.urlFile = `"${newLibraryComponent.urlFile}"`;
    }
    const { data } = yield call(requestCreateLibraryComponent, {
      fieldsUpdateObg,
      ownerId: user.id,
      contentTags: tagsToAttach.map((i) => ({ id: i.id })),
    });
    const { activeNavSlider, selectorType, sortType } = yield select(
      (state) => state.content,
    );

    const isUserShowLink = (activeNavSlider === LibCompSieveTypesSideBar.Links
        && selectorType === 'all')
      || 'embed';
    const isUserShowLibrary = activeNavSlider === 'library' && selectorType === 'all';
    const isNeedUpdateContent = isUserShowLibrary || isUserShowLink;
    if (isNeedUpdateContent) {
      yield put(
        actionCreator(ContentActionType.startUpload, {
          activeNavSlider,
          selectorType,
          sortType,
          isNeedUpdate: true,
        }),
      );
    }
    yield put(actionCreator(ContentActionType.updateCounterS, {}));

    if (data?.errors) {
      yield showErrorMessage(data?.errors);
      return;
    }
    yield put(
      actionShowMessage({
        type: MessageType.SuccessfullyUploaded,
        itemName: 'link',
      }),
    );
    if (tagsToAttach.length) {
      yield put(
        actionCreator(Tags.updateTagCounterS, {
          idsToUpdate: tagsToAttach.map((item) => item.id),
        }),
      );
    }

    // yield put(actionCreator(ContentActionType.updateCounterS, {}));
    // yield put(actionCreator(ContentActionType.startUpload, { isNeedUpdate: true }));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* ReplaceBlocks(action) {
  try {
    const { block, pageEditorBlockId, parentLibraryComponent } = action.payload;
    const { id: userId } = yield select(getUser);
    yield call(
      requestCreateComponent(placementEnum.mutationReplaceBlockInParent),
      {
        block,
        parentLibraryComponent,
        componentToDeleteId: pageEditorBlockId,
      },
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

export function* insertNewLinkPageUpload({ newBlockData }) {
  const { id: playlistId } = yield select(getCurrentPage);
  const { id: userId } = yield select(getUser);
  const lastModifiedDate = Math.floor(Date.now() / 1000);

  yield put(
    actionCreator(CurrentPage.InsertGoogleUploadBlockSS, {
      playlistId,
      linkPage: newBlockData,
      lastModifiedDate,
    }),
  );

  yield requestUpdateLibraryComponent()({
    id: playlistId,
    create: {
      linkPages: [
        {
          id: newBlockData.id,
          position: newBlockData.position,
          createDate: newBlockData.createDate,
          duration: newBlockData.duration,
          addOwner: userId,
        },
      ],
    },
  });
}

export function* insertNewUploadBlock({
  firstBlockIndex,
  newBlockData,
  isOpenCreateComponent,
}) {
  yield put(
    actionCreator(CurrentPage.InsertSingleBlock, {
      firstIndex: firstBlockIndex,
      block: newBlockData,
      isOpenCreateComponent,
    }),
  );
}

export function* UpdateGoogleUploadBlock({ data, id, position }) {
  const { id: playlistId } = yield select(getCurrentPage);
  const sourceComponent = data;
  const lastModifiedDate = Math.floor(Date.now() / 1000);

  const newBlockData = {
    id,
    type: PLAYLIST_ELEMENTS.ElementComponent,
    innerHtml: innerHtmlGoogleEmbedTemplate({
      images: [sourceComponent.urlFile],
      title: sanitizeToLoad(sourceComponent.title),
      description: sanitizeToLoad(sourceComponent.description),
      url: sourceComponent.linkUrl,
      foreignLastModifiedDate: sourceComponent.foreignLastModifiedDate,
      foreignLastModifiedUserName: sourceComponent.foreignLastModifiedUserName,
    }),
    libraryComponent: {
      type: LibraryComponentTypes.google_embed_component,
      linkUrl: sourceComponent.linkUrl,
      title: sanitizeToLoad(sourceComponent.title),
      description: sanitizeToLoad(sourceComponent.description),
      urlFile: sourceComponent.urlFile,
      foreignFileId: sourceComponent.foreignFileId,
      id: sourceComponent.id,
      linkedAccountId: sourceComponent.linkedAccountId,
      foreignLastModifiedDate: sourceComponent.foreignLastModifiedDate,
      foreignLastModifiedUserName: sourceComponent.foreignLastModifiedUserName,
    },
    position,
  };

  yield put(
    actionCreator(CurrentPage.UpdateGoogleUploadBlockSS, {
      playlistId,
      linkPage: newBlockData,
      lastModifiedDate,
    }),
  );

  yield call(requestUpdatePlaylist(), {
    id: playlistId,
    updateNested: {
      linkPages: [
        { id: newBlockData.id, field: parseFieldsToObj(newBlockData) },
      ],
    },
  });
}

function* fillComponentBlocks(action) {
  try {
    const { componentId } = action.payload;
    const { data } = yield requestGetLibComponent(['any', componentId]);
    const components = yield select(getLibraryComponents);

    const reduxComponents = components[componentId]?.components ?? [];
    const uploadedContent = data.LibraryComponent && data.LibraryComponent[0];
    const innerComponents = uploadedContent?.components?.length
      ? uploadedContent.components
      : reduxComponents;
    const tags = [
      ...data.LibraryComponent[0].contentTags.map((item) => ({
        ...item,
        type: 'tags',
      })),
    ];
    const summaryComponent = {
      ...components[componentId],
      ...data.LibraryComponent[0],
      title: sanitizeToLoad(uploadedContent?.title),
      tags,
      contentTags: undefined,
    };

    const isDataFromServer = !!uploadedContent?.components?.length;

    if (innerComponents && !!innerComponents?.length) {
      const blocks = innerComponents.map((item) => {
        const content = {};
        if (item.type === BlockTypes.page) {
          const [componentData] = item.libraryComponents;
          content.title = (componentData && sanitizeToLoad(componentData.title))
            || 'The functional logic has been updated.';
          content.pageId = componentData && componentData.id;
        } else if (
          item.type === BlockTypes.approveButton
          || item.type === BlockTypes.actionButton
          || item.type === BlockTypes.shortText
          || item.type === BlockTypes.embed
        ) {
          content.state = JSON.parse(sanitizeToLoad(item?.content));
        } else if (item.type === BlockTypes.webSite) {
          if (item.content) {
            content.state = parseContentForUsage(item.content);
          } else {
            const sourceComponent = item.libraryComponents[0];
            content.state = {
              data: {
                url: sourceComponent.linkUrl,
                title: sourceComponent.title,
                description: sanitizeToLoad(sourceComponent.description),
                images: [sourceComponent.urlFile],
              },
            };
          }
        } else if (item.type === BlockTypes.lineSeparator) {
          content.state = JSON.parse(sanitizeToLoad(item.content));
        } else if (item.type === BlockTypes.image) {
          const parsedItem = {
            ...item,
            content: sanitizeToLoad(item.content),
            innerHtml: sanitizeToLoad(item.innerHtml),
          };
          mutateImageBlockState(content, parsedItem);
          if (parsedItem?.libraryComponents) {
            content.nestedItemId = parsedItem?.libraryComponents[0]?.id;
            content.urlSmallImage = parsedItem?.libraryComponents[0]?.urlSmallImage;
            content.urlVerySmallImage = parsedItem?.libraryComponents[0]?.urlVerySmallImage;
            content.urlFile = parsedItem?.libraryComponents[0]?.urlFile;
          }
        } else if (item.type === BlockTypes.pdf) {
          const [componentData] = item.libraryComponents;
          content.state = {
            data: {
              nestedItemId: item.id,
              width: item.width || 700,
              representationState: componentData.content,
              title: componentData.title,
              size: componentData.size || 700,
              urlFile: componentData.urlFile,
            },
          };
        } else if (
          item.type === BlockTypes.googleEmbed
          || item.type === BlockTypes.dropboxEmbed
        ) {
          if (item?.state) {
            content.state = { data: { ...item?.state?.data } };
          } else {
            const [componentData] = item?.libraryComponents;
            content.state = {
              data: {
                url: componentData?.linkUrl,
                images: componentData?.urlFile,
                title: componentData?.title,
                description: componentData?.description,
                foreignFileId: componentData?.foreignFileId,
                linkedAccountId: componentData?.linkedAccountId,
                libraryComponentId: componentData?.id,
                foreignLastModifiedDate: componentData?.foreignLastModifiedDate,
                foreignLastModifiedUserName:
                  componentData?.foreignLastModifiedUserName,
              },
            };
          }
        } else if (!isDataFromServer && item.state) {
          content.state = item.state;
        } else {
          content.state = draftDataConvertToLoad(item.content);
        }
        return {
          ...item,
          id: item.id,
          type: item.type,
          position: item.position,
          isHidden: item.isHidden,
          ...content,
        };
      });
      const layers = getLayersForPage(blocks);
      yield put(actionUpdateSupportLayers(getLayersForPage(layers)));

      const resArray = innerComponents.reduce((acc, item, index) => {
        acc.push({ ...item, ...blocks[index] });
        return acc;
      }, []);

      yield put(actionUpdateSupportLayers(getLayersForPage(layers)));
      yield put(actionUpdateSupportBlocks({ blocks: resArray }));
      yield put(
        actionCreator(SupportAction.UpdateComponentR, {
          component: summaryComponent,
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

// function* updateFieldsInLibComponent (action) {
//   try {
//     const { componentId, field, value } = action.payload;
//     yield call(requestNeo4j, { query: queryUpdateFieldsInLibComponent(componentId, field, value) });
//   } catch (err) {
//     console.error(err);
//     yield showErrorMessage(err, action);
//   }
// }

function* savePdfBlock(action) {
  try {
    const { pageId, relationId, innerHtml, width } = action.payload;

    yield call(requestUpdateLibraryComponent(), {
      place: 'queryUpdateLibraryComponentInPageRelation',
      id: pageId,
      updateNested: {
        components: [
          {
            id: relationId,
            field: { innerHtml: `"${sanitizeToSave(innerHtml)}"`, width },
          },
        ],
      },
    });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updatePdfPreview(action) {
  try {
    const { relationId, newState, currentPageId, isNew } = action.payload;
    yield call(
      requestUpdateComponent(placementEnum.queryUpdateLCinPageRelation),
      { relationId, fieldsArr: ['content'], valuesArr: [newState] },
    );
    yield put(
      actionUpdatePdfRelation(
        relationId,
        ['content'],
        [newState],
        null,
        null,
        currentPageId,
        isNew,
      ),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updatePdfWidth(action) {
  try {
    const { relationId, nestedItemId, width, newInnerHtml, currentPageId } = action.payload;

    yield put(
      actionCreator(LibraryComponents.UpdatePdf, {
        relationId,
        fieldsArr: ['width'],
        valuesArr: [width],
        nestedItemId,
        newInnerHtml,
        currentPageId,
      }),
    );
    yield call(
      requestUpdateComponent(placementEnum.queryUpdateLCinPageRelation),
      {
        relationId,
        fieldsArr: ['width'],
        valuesArr: [width],
        newInnerHtml,
      },
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* getSharedLibraryComponent(action) {
  try {
    const { playlistId, linkPageId, channelId, history } = action.payload;
    const user = yield select(getUser);

    const { data } = yield requestGetSomePlaylistContent([
      'shared',
      playlistId,
      linkPageId,
      channelId,
    ]);
    const [playlistData] = data.Playlist;
    const [linkPagesData] = playlistData.linkPages;
    const publishManagerData = playlistData.editManager;
    const [channelData] = publishManagerData.inChannel;
    const [channelOwner] = channelData.owner;
    const [libraryComponentData] = linkPagesData.libraryComponent;
    const channelSubscribersData = channelData.channelSubscription
      .filter((item) => item.status === 'Active')
      .map((item) => item.User.id);

    if (
      channelOwner.id !== user.id
      && !channelSubscribersData.includes(user.id)
    ) {
      yield put(
        actionSwitchPage({
          accessAvailable: false,
        }),
      );
      yield put(
        actionShowMessage({
          type: MessageType.AccessDenied,
        }),
      );
      yield delay(3000);
      history.push('/channels');
      return;
    }
    if (channelData) {
      yield put(
        actionSwitchPage({
          ...sanitizeStringFields(libraryComponentData),
          owner: channelOwner,
          accessAvailable: true,
          isAnyCanEdit: playlistData.isAnyCanEdit,
          channelId: channelData.id,
          isFavorite: !!libraryComponentData.favorites.length,
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* GetSharedLibraryComponentInPlaylist(action) {
  try {
    const { libraryItemId } = action.payload;
    const { id: userId } = yield select(getUser);

    const { data } = yield requestGetLibComponent(['component', libraryItemId]);
    const item = data.LibraryComponent[0];
    const isOwner = item.users[0]?.id === userId;

    yield put(
      actionSwitchPage({
        ...item,
        isDownload: false,
        isOwner,
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* saveLibraryPageIntoLibrary(action) {
  try {
    const { id: userId } = yield select(getUser);
    const [pageData] = Object.values(action.payload.page || {});
    const { isAddToFavorite } = action.payload;
    const fieldsUpdateObg = {
      isShareToWeb: false,
      id: `"${pageData.id}"`,
      position: pageData.position,
      type: '"page"',
      createDate: pageData.createDate,
      lastModifiedDate: pageData.createDate,
      title: '""',
    };
    const contentTags = pageData.tags.map((tag) => ({ id: tag.id }));
    yield call(requestCreateLibraryComponent, {
      fieldsUpdateObg,
      ownerId: userId,
      contentTags,
    });
    if (isAddToFavorite) {
      yield put(actionToggleComponentFavorite(pageData.id, null, true, true));
    }

    yield put(
      actionSwitchPage({
        id: pageData.id,
        title: '',
        type: TYPE.LIBRARY_COMPONENT,
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updateLibraryPageField(action) {
  try {
    const { value, field, id } = action.payload.page;
    const { id: currentId } = yield select(getCurrentPage);
    if (id === currentId) {
      yield put(actionSwitchPage({ [field]: value }));
    }
    let state;
    if (typeof value === 'string') state = `"${sanitizeToSave(value)}"`;
    else state = value;

    const fieldsUpdateObg = {};
    fieldsUpdateObg[field] = state;

    yield call(requestUpdateLibraryComponent(), {
      place: 'queryUpdateLibraryPageField',
      id,
      fieldsUpdateObg,
    });

    if (!value && field === 'isPublish') yield put(actionShowMessage({ type: MessageType.PageUnpublished }));
    else if (value && field === 'isPublish') yield put(actionShowMessage({ type: MessageType.PagePublished }));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* editShareStateLibraryPage(action) {
  try {
    const {
      payload: { state, field },
    } = action;
    const { availableFrom, availableTo, dateRangeMark } = state;
    const { id } = yield select(getCurrentPage);
    const sharedAvailableFrom = availableFrom ? Math.floor(
      availableFrom?.getTime() / 1000,
    ) : 0;
    const sharedAvailableTo = availableFrom ? Math.floor(
      availableTo?.getTime() / 1000,
    ) : 0;
    const newShareState = {
      availableFrom: sharedAvailableFrom,
      availableTo: sharedAvailableTo,
      dateRangeMark,
    };
    if (
      field === 'isCoEdit'
      || field === 'isShareToWeb'
      || field === 'isNeedAccess'
      || field === 'accessCode'
    ) {
      newShareState[field] = field === 'accessCode' ? `"${state}"` : state;
    }
    yield put(actionUpdateShareState(newShareState));

    yield call(requestUpdateLibraryComponent(), {
      place: 'queryShareStateLibraryPage',
      id,
      fieldsUpdateObg: newShareState,
    });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* updateLibraryPageDescription(action) {
  try {
    const {
      libraryPage: { id, value, field },
    } = action.payload;
    if (field === 'isShowDescription') {
      yield put(
        actionSwitchPage({
          notDescriptionAdded: false,
          isShowDescription: value,
        }),
      );
    } else {
      yield put(actionSwitchPage({ [field]: value }));
    }
    let state;
    if (field === 'title' || field === 'description') state = `"${sanitizeToSave(value)}"`;
    else state = value;
    const fieldsUpdateObg = {};
    fieldsUpdateObg[field] = state;
    yield call(requestUpdateLibraryComponent(), {
      place: 'queryUpdateLibraryPageInLibrary',
      id,
      fieldsUpdateObg,
    });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

const parserNested = (LibraryComponent) => {
  const [
    { id, title, parentComponents, linkPage, inPage, libraryComponents, type, users },
  ] = LibraryComponent;
  const nestedTo = {
    parentComponents: parentComponents?.map((i) => i.parentLibraryComponent),
    playlist: linkPage?.map((i) => i.playlist),
    linkPage,
    parentBLocks: parentComponents,
    inPage,
    libraryComponents,
  };
  nestedTo.counter = +nestedTo.parentComponents?.length
    + nestedTo.playlist.length
    + nestedTo.libraryComponents.length;
  return {
    id,
    users,
    title: sanitizeToLoad(title),
    nestedTo,
  };
};

const parserNestedPlaylist = (Playlist, playlistTitle, playlistId) => {
  const [{ id, title, inChannel }] = Playlist;
  const nestedTo = {
    inChannel,
  };
  nestedTo.counter = nestedTo.inChannel.length;
  return {
    id: playlistId ?? id,
    title: playlistTitle ?? title,
    nestedTo,
  };
};

function* getHelpInfo(action) {
  try {
    const { id, folderId, itemType } = action.payload;
    const isPlaylist = itemType === 'playlist' || itemType === 'shared' || itemType === 'channel'
      || itemType === 'draft' || itemType === 'publish';

    if (id && (isPlaylist)) {
      const { data } = yield requestGetSomePlaylistContent(['info', id]);
      const publishedItems = [];
      const notPublishedItems = [];

      Object.values(data.Playlist || {}).forEach((playlist) => {
        if (playlist?.inChannel?.length) {
          notPublishedItems.push(
            parserNestedPlaylist(
              [playlist],
              sanitizeToLoad(playlist?.title),
              playlist.id,
            ),
          );
        }
      });

      yield put(
        actionUpdateLibraryComponentInRedux(
          notPublishedItems.length ? notPublishedItems[0] : publishedItems[0],
        ),
      );
    } else if (id) {
      const {
        data: { LibraryComponent },
      } = yield requestGetLibComponent(['info', id]);
      if (!LibraryComponent[0]) return;
      yield put(
        actionUpdateLibraryComponentInRedux(
          parserNested(LibraryComponent, folderId), true,
        ),
      );
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

const prepareComponentData = (libraryPage) => {
  const channels = {};
  const playlists = {};
  const pages = {};
  const components = {};
  let usedTimesCounter = false;

  if (libraryPage) {
    libraryPage.linkPage.forEach((linkPage) => {
      linkPage.playlist.forEach((playlist) => {
        // if (playlist?.publishManager) {
        const editPlaylistId = playlist.editManager.editPlaylist.id;

        playlist.editManager.inChannel.forEach(({ id, name }) => {
          channels[id] = {
            id,
            name: sanitizeToLoad(name),
            type: 'channel',
            managerId: playlist.editManager.id,
          };
        });
        playlists[editPlaylistId] = {
          ...playlist,
          title: sanitizeToLoad(playlist.title),
          id: editPlaylistId,
          isPublished: !!playlist.editManager.inChannel,
          type: 'playlist',
        };
        usedTimesCounter = +usedTimesCounter + 1;
      });
    });

    libraryPage.parentComponents.forEach((parentComponent) => {
      if (!parentComponent.parentLibraryComponent[0]) return;
      const { type, id, title } = parentComponent.parentLibraryComponent[0];
      if (type === 'page') {
        pages[id] = { type, id, title: sanitizeToLoad(title) };
        usedTimesCounter = +usedTimesCounter + 1;
      } else if (type === 'component') {
        components[id] = { type, id, title: sanitizeToLoad(title) };
        usedTimesCounter = +usedTimesCounter + 1;
      }
    });
  }
  return {
    channels,
    playlists,
    pages,
    components,
    usedTimesCounter,
  };
};

function* getSharedHelpInfo(action) {
  try {
    const { jwtId } = action.payload;
    if (jwtId) {
      const { data } = yield call(requestSharedComponentMetaData, [jwtId]);

      const usedIn = prepareComponentData(data[0]);
      const resultArray = collectLines(usedIn);

      const component = {
        id: jwtId,
        type: 'sharedPage',
        usedIn: resultArray,
        usedTimesCounter: usedIn.usedTimesCounter,
        isNoChannels: Object.keys(usedIn.channels).length === 0,
      };

      yield put(
        actionCreator(
          LibraryComponents.Shared.UpdateSharedLibraryComponentInRedux,
          { component },
        ),
      );

      yield put(
        actionCreator(SupportAction.SaveSharedComponentDescriptionData, {
          id: jwtId,
        }),
      );
      yield put(actionToggleComponentDescription(true));
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* DuplicatePage(action) {
  try {
    const {
      payload: { folderId, pageId },
    } = action;
    const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
    const newId = uuidv4();

    yield call(axios, {
      method: 'post',
      url: `${UPLOAD_URL}/duplicatePage`,
      headers: {
        token: token ? `${token}` : '',
      },
      data: {
        pageId,
        newId,
        folderId,
      },
    });
    const { activeNavSlider, selectorType, sortType } = yield select(
      (state) => state.content,
    );
    yield put(
      actionCreator(ContentActionType.startUpload, {
        activeNavSlider,
        selectorType,
        sortType,
        isNeedUpdate: true,
      }),
    );

    yield put(actionCreator(ContentActionType.updateCounterS, {}));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* CheckIsInUse(action) {
  try {
    const { isPlaylist, itemTitle, counterSelectedPage, itemId } = action.payload;
    const selectedPage = yield select(getSelectedPage);
    let itemsWithParentRelations = 0;
    let option;

    const selectedIds = itemId ? [itemId] : Object.keys(selectedPage);

    if (!selectedIds.length) return;

    const { data } = isPlaylist
      ? yield requestGetSomePlaylistContent(['infos', selectedIds.join('_')])
      : yield requestGetLibComponent(['infos', selectedIds.join('_')]);
    const itemsWithUseCases = [];

    Object.values(data || {}).forEach((itemWrapper) => {
      if (itemWrapper.length) {
        itemsWithUseCases.push(
          isPlaylist
            ? parserNestedPlaylist(itemWrapper)
            : parserNested(itemWrapper),
        );
      }
    });

    let itemsType = isPlaylist ? 'Playlist' : 'LibraryComponent';
    const useCases = {};

    itemsWithUseCases.forEach((i) => {
      if (!i?.id) return;
      useCases[i.id] = { ...i, title: sanitizeToLoad(i.title) };
      if (i.type === 'page' && itemsType !== 'Page') itemsType = 'Page';
      i.nestedTo.counter && ++itemsWithParentRelations;
    });
    if (itemId) {
      option = { itemName: itemTitle || useCases[itemId].title, itemsType, useCases };
    } else {
      option = {
        counter: counterSelectedPage,
        itemsType,
        useCases,
        itemName: itemTitle,
      };
    }

    if (itemsWithParentRelations) {
      yield put(
        actionOpenModal(openModalType.WarningDeleteItem, {
          itemsToDelete: selectedIds,
          option,
        }),
      );
    } else {
      yield put(
        actionShowMessage({
          type: isPlaylist
            ? MessageType.PlaylistToTrash
            : MessageType.ComponentToTrash,
          ...option,
        }),
      );

      yield all(
        selectedIds.map((i) => put(
          isPlaylist
            ? actionPlaylistMoveToTrash(i)
            : actionMoveComponentToTrash(i),
        ),
        ),
      );

      yield put(actionRemoveAllSelected());
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* GetSharedPages(action) {
  try {
    const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');

    const { data } = yield call(axios, {
      method: 'get',
      url: APIGetPageSharing,
      headers: {
        token: token ? `${token}` : '',
      },
    });

    const downloadLibraryComponent = data;
    const sharedState = {
      status: downloadStatus.success,
      components: {},
      pagesToSharingCount: downloadLibraryComponent.length,
    };

    if (downloadLibraryComponent.length) {
      downloadLibraryComponent.forEach((component) => {
        sharedState.components[component.id] = {
          ...component,
          id: component.id,
          pdfPreviewUrl: component.pdfPreviewUrl,
          isNotDownload: true,
          title: sanitizeToLoad(component.title),
          movedToTrash: component.movedToTrash,
          isFavorite: !!component.favorites?.length,
          position: component.position,
          type: 'sharedPage',
          inPage: !!component?.inPage?.length,
          urlFile: component.urlFile,
          itemType: 'component',
          lastModifiedDate: component.lastModifiedDate,
          createDate: component.createDate,
          libraryComponents: component.libraryComponents,
          components: combineComponentsAndLibraryComponents({
            components: component.components,
            libraryComponents: component.libraryComponents,
            libraryComponentId: component.id,
          }),
          subtitle: component.subtitle,
          description: sanitizeToLoad(component.description),
          linkUrl: sanitizeToLoad(component.linkUrl).replaceAll('\\"', "'"),
        };
      });
    }
    yield put(
      actionCreator(LibraryComponents.Pages.UpdateStateSharedPagesR, {
        sharedState,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

const createEventProviderChannel = (
  response,
  openPicker,
  viewedTypes,
  isNotMultiselect,
) => {
  return eventChannel((emit) => {
    const handleEvent = (data) => {
      emit(data);
    };

    const pickerObject = {
      clientId: GOOGLE_CLIENT_ID,
      developerKey: GOOGLE_API_KEY,
      viewId: viewedTypes || 'DOCS',
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: isNotMultiselect || true,
      setIncludeFolders: true,
      customScopes: [
        'https://www.googleapis.com/auth/drive.file',
        'https://www.googleapis.com/auth/drive.readonly',
      ],
      callbackFunction: handleEvent,
    };

    if (response?.data?.result) pickerObject.token = response?.data?.accessToken;

    let picker = openPicker(pickerObject);

    return () => {
      picker = null;
    };
  });
};

function* openGooglePickerModal(action) {
  try {
    const {
      openPicker,
      viewedTypes,
      isNotMultiselect,
      linkedAccountId,
      setIsVisible,
      setStep,
      oldUploadBlockPosition,
      oldUploadBlockId,
      nextItemPosition,
      history,
      isWithUpload,
      firstBlockIndex,
    } = action.payload;

    const response = yield call(requestGoogleTokenValid, [linkedAccountId]);

    const eventProviderChannel = yield call(
      createEventProviderChannel,
      response,
      openPicker,
      viewedTypes,
      isNotMultiselect,
    );

    try {
      while (true) {
        const data = yield take(eventProviderChannel);
        if (data.action === 'cancel') return;
        // if (data.action === 'loaded') { }
        if (data.action === 'picked') {
          yield put(
            actionCreator(ServiceUser.HandleIntegrationPickedFilesS, {
              files: data.docs.map((file) => ({
                ...file,
                newChildId: uuidv4(),
                newLibraryComponentId: uuidv4(),
              })),
              startPosition: oldUploadBlockPosition,
              history,
              endPosition: nextItemPosition,
              firstBlockIndex,
              isWithUpload,
              oldUploadBlockId,
              oldUploadBlockPosition,
              componentType: LibraryComponentTypes.google_embed_component,
            }),
          );
          setIsVisible(false);
          return;
        }
        if (data.action === 'error') {
          if (setStep) setStep({ type: 'error', title: 'Google File Picker Error' });

          // eslint-disable-next-line no-console
          console.error(data);
          return;
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err.message);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* createLinkedAccount(action) {
  try {
    const { data, setIsVisible, viewedTypes, ...rest } = action.payload;

    const {
      data: { linkedAccount, isAlreadyLinked, accountEmail },
    } = yield requestCreateGoogleLinkedAccount({
      code: data.code,
    });

    if (isAlreadyLinked) {
      setIsVisible(false);

      yield put(
        actionShowMessage({
          type: MessageType.AccountIsAlreadyLinked,
          itemName: accountEmail,
          effects: 'shaking',
        }),
      );

      return;
    }

    yield put(actionCreator(Library.AddLinkedAccountR, { linkedAccount }));

    yield put(
      actionCreator(Library.OpenGooglePickerModal, {
        ...rest,
        setIsVisible,
        accessToken: linkedAccount.accessToken,
        linkedAccountId: linkedAccount.id,
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updateGoogleEmbedModifiedTime(action) {
  try {
    const { isOpenCreateComponent } = yield select(getLibrary);

    const { currentPageBlockIndex } = action.payload;

    const blocks = yield select(
      isOpenCreateComponent ? getSupportBlocks : getBlocks,
    );

    const {
      state: { data },
    } = { ...blocks[currentPageBlockIndex] };
    if (!data?.libraryComponentId) return;
    const { data: responseData } = yield call(requestFileModifiedDate, [
      data?.libraryComponentId,
    ]);

    yield put(
      actionCreator(CurrentPage.UpdateSingleBlock, {
        ...responseData,
        index: currentPageBlockIndex,
        id: data?.libraryComponentId,
        isOpenCreateComponent,
      }),
    );

    if (responseData?.isDeleteAccount) {
      yield put(
        actionShowMessage({
          type: MessageType.AccountHasBeenUnlinked,
          itemName: responseData.serviceEmail,
        }),
      );

      yield put(
        actionCreator(ServiceUser.DeleteLinkedAccount, {
          id: responseData.deletedAccountId,
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* createLinkLibraryComponent(action) {
  try {
    const {
      urlText,
      history,
      imageDataTitle,
      respData,
      addedTags = [],
    } = action.payload;

    const uploads = yield select(getUploads);
    const componentId = uuidv4();
    const { folderId, selectorType, tags } = yield select(getUser);
    const localTags = [];
    if (selectorType === 'tag' && folderId) {
      localTags.push({ ...tags[folderId] });
    } else if (addedTags.length) {
      localTags.push(...addedTags);
    }
    const { selector } = yield select(getLibrary);
    const sortedUploads = Object.values(uploads || {}).sort((a, b) => b.position - a.position)
      || [];
    const isImageData = urlText.startsWith('data:');
    let response;
    if (validateURL(urlText)) {
      let parsedUrl;
      if (respData) {
        parsedUrl = respData;
      } else {
        parsedUrl = yield call(requestParser, { url: urlText });
      }

      const {
        data: { data },
      } = parsedUrl;
      yield put(
        actionCreateUpload({
          newId: componentId,
          position: sortedUploads[0]?.position + 1,
          type: data.contentType,
          collectionId: folderId || undefined,
          history,
          isFavorite: selectorType === 'favorites' || selector === 'favorites',
          tags: localTags,
        }),
      );

      response = yield call(requestCreateLinkComponent, {
        urlText,
        componentId,
        data: {
          ...data,
          title: sanitizeToSave(removeStringifiedSymbols(data.title)),
          description: sanitizeToSave(
            removeStringifiedSymbols(data.description),
          ),
          subtitle: sanitizeToSave(removeStringifiedSymbols(data.subtitle)),
        },
        isFavorite: selectorType === 'favorites' || selector === 'favorites',
        tags: localTags,
      });
    } else if (isImageData) {
      const dataPrefix = urlText.split(',')[0];
      const indexStart = dataPrefix.indexOf(':') + 1;
      const indexEnd = dataPrefix.indexOf(';');
      const contentType = dataPrefix.slice(indexStart, indexEnd);
      yield put(
        actionCreateUpload({
          newId: componentId,
          position: sortedUploads[0]?.position + 1,
          type: contentType,
          collectionId: folderId || undefined,
          history,
          tags: localTags,
        }),
      );

      const tagsInString = yield call(JSON.stringify, tags);

      const objectToCreate = {
        urlText,
        componentId,
        data: {
          contentType,
          isImageData: true,
          title: imageDataTitle,
        },
        tags: tagsInString,
        isFavorite: selectorType === 'favorites' || selector === 'favorites',
      };
      if (tags) objectToCreate.tags = tagsInString;
      response = yield call(requestCreateLinkComponent, objectToCreate);
    }
    if (response.data.error) {
      yield put(
        actionUpdateUpload({
          status: 'ERROR',
          id: componentId,
          success: true,
          errorMsg: response.data.error,
        }),
      );
    } else {
      const {
        data: { data: componentData },
      } = response;
      const payload = { ...componentData, tags: localTags };

      yield put(
        actionUpdateUpload({ status: 'READY', id: componentId, success: true }),
      );
      yield put(actionAddLibraryComponentReduxOnly(payload));
      yield put(
        actionShowMessage({
          type: MessageType.LinkCreated,
        }),
      );

      yield call(delay, 1000);

      yield put(
        actionUpdateUpload({
          status: 'READY',
          id: componentId,
          success: false,
        }),
      );
      yield put(
        actionCreator(ContentActionType.updateCounterS, { isUpload: true }),
      );
      if (localTags.length) {
        yield put(
          actionCreator(Tags.updateTagCounterS, {
            idsToUpdate: localTags.map((item) => item.id),
          }),
        );
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err, 'Not valid url format');
  }
}

function* UnsubscribeLibraryEntity(action) {
  try {
    const { id, entityType, itemID } = action.payload;
    yield call(request(UNSUBSCRIBE_URL(id, entityType), METHOD.GET));
    yield put(
      actionCreator(ContentActionType.updateCounterS, {
        activeNavSliderStatePayload: 'shared',
      }),
    );
    yield put(
      actionCreator(ContentActionType.removeUnsubscribeLibraryEntity,
        { unsubscribeIdsObj: { [itemID]: { sharedID: id } } }),
    );
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* UnsubscribeManyLibraryEntity(action) {
  try {
    const { smartfileIdsObj, entityType } = action.payload;
    const ids = Object.values(smartfileIdsObj).map(elem => elem.sharedID);
    yield call(request(UNSUBSCRIBE_MANY_URL(), METHOD.PUT), { ids, type: entityType });
    yield put(
      actionCreator(ContentActionType.updateCounterS, {
        activeNavSliderStatePayload: 'shared',
      }),
    );
    yield put(
      actionCreator(ContentActionType.removeUnsubscribeLibraryEntity, { unsubscribeIdsObj: smartfileIdsObj }),
    );
  } catch (err) {
    yield showErrorMessage(err);
  }
}

export default function* librarySaga() {
  yield takeEvery(
    LibraryComponents.Pages.updateLibraryPageDescription,
    updateLibraryPageDescription,
  );
  yield takeEvery(LibraryComponents.ToggleFavorite, toggleFavorite);
  yield takeEvery(LibraryComponents.DuplicateComponent, duplicateComponent);
  yield takeEvery(
    LibraryComponents.DuplicateSharedComponent,
    duplicateSharedComponent,
  );
  yield takeEvery(
    LibraryComponents.Player.DuplicateSharedComponent,
    playerDuplicateSharedComponent,
  );
  yield takeEvery(LibraryComponents.MoveComponentToTrash, MoveComponentToTrash);
  yield takeEvery(
    LibraryComponents.RestoreComponentFromTrash,
    restoreComponentFromTrash,
  );
  yield takeEvery(
    LibraryComponents.UpdateLibraryComponent,
    updateLibraryComponent,
  );
  yield takeEvery(SupportAction.checkExistItemInFilter, checkExistItemInFilter);
  // yield takeEvery(LibraryComponents.DeleteComponent, deleteComponent);
  yield takeEvery(LibraryComponents.CopyBlocksToPage, copyBlocksToPage);
  yield takeEvery(LibraryComponents.CopyBlockImageToPage, copyBlockImageToPage);
  yield takeEvery(LibraryComponents.CopyUploadToPage, CopyUploadToPage);
  yield takeEvery(
    LibraryComponents.BulkAddComponentsToDragState,
    bulkAddComponentsToDragState,
  );
  // yield takeEvery(LibraryComponents.DeleteComponent, deleteComponent);
  yield takeLatest(
    SupportAction.FillComponentSupportBlocks,
    fillComponentBlocks,
  );
  yield takeEvery(
    LibraryComponents.CreateLibraryComponentFromBlocks,
    createLibraryComponentFromBlocks,
  );
  yield takeEvery(
    LibraryComponents.CreateLinkLibraryComponent,
    createLinkLibraryComponent,
  );
  yield takeEvery(
    LibraryComponents.CreateEmbedLibraryComponent,
    createEmbedLibraryComponent,
  );
  yield takeEvery(
    LibraryComponents.CheckLinkForLibraryComponent,
    checkLinkForLibraryComponent,
  );
  yield takeEvery(
    LibraryComponents.CreateLibraryComponentInLibraryFromBlocks,
    createLibraryComponentInLibraryFromBlocks,
  );
  yield takeEvery(
    LibraryComponents.ChangeLibraryComponentInLibraryFromBlocks,
    changeLibraryComponentInLibraryFromBlocks,
  );
  yield takeEvery(
    LibraryComponents.RevertLibraryComponentInLibraryFromBlocks,
    revertLibraryComponentInLibraryFromBlocks,
  );
  yield takeEvery(LibraryComponents.DetachComponent, detachComponent);
  yield takeEvery(LibraryComponents.SavePdfBlock, savePdfBlock);
  // yield takeEvery(LibraryComponents.UpdatePdf, updatePdfField);
  yield takeEvery(LibraryComponents.UpdatePdfPreview, updatePdfPreview);
  yield takeEvery(LibraryComponents.UpdatePdfWidth, updatePdfWidth);
  yield takeEvery(
    LibraryComponents.GetSharedLibraryComponentInPlaylist,
    GetSharedLibraryComponentInPlaylist,
  );
  yield takeEvery(
    LibraryComponents.GetSharedLibraryComponent,
    getSharedLibraryComponent,
  );
  yield takeEvery(
    LibraryComponents.Pages.AddPageInLibraryRS,
    saveLibraryPageIntoLibrary,
  );
  yield takeEvery(
    LibraryComponents.Pages.UpdatePageInLibraryField,
    updateLibraryPageField,
  );
  // yield takeEvery(LibraryComponents.Pages.PublishPage, PublishPage);
  yield takeEvery(
    LibraryComponents.Pages.SaveShareStateLibraryPage,
    editShareStateLibraryPage,
  );
  yield takeEvery(LibraryComponents.Pages.Duplicate, DuplicatePage);
  yield takeEvery(SupportAction.SaveComponentDescriptionData, getHelpInfo);
  yield takeEvery(
    SupportAction.GetSharedComponentDescriptionDataS,
    getSharedHelpInfo,
  );
  yield takeLatest(SupportAction.CheckIsInUse, CheckIsInUse);
  yield takeLatest(LibraryComponents.Pages.GetSharedPagesS, GetSharedPages);
  yield takeEvery(Library.OpenGooglePickerModal, openGooglePickerModal);
  yield takeEvery(Library.CreateLinkedAccountS, createLinkedAccount);
  yield takeEvery(
    LibraryComponents.UpdateGoogleEmbedModifiedTime,
    updateGoogleEmbedModifiedTime,
  );
  yield takeEvery(
    LibraryComponents.UnsubscribeLibraryEntity,
    UnsubscribeLibraryEntity,
  );
  yield takeEvery(
    LibraryComponents.UnsubscribeManyLibraryEntity,
    UnsubscribeManyLibraryEntity,
  );

  yield takeEvery(CurrentPage.ReplaceBlockS, ReplaceBlocks);
}
