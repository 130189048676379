import styled from 'styled-components/macro';
import {
  ApprovalBTNBackgroundColorEnum,
  ApprovalBTNClickedColorEnum,
  ApprovalBTNColorEnum,
  ApprovalBTNHoverColorEnum,
} from './color-enums';
import { NewUPVViewModes } from '../../utils/constants';


export const ABDividerStripe = styled.div<{viewMode: keyof typeof NewUPVViewModes}>`
  width: 40px;
  height: 2px;
  background: rgba(29, 29, 29, 0.08);
  position: relative;
  top: 15px;
  margin-right: 8px;
  ${({ viewMode }) => (viewMode === NewUPVViewModes.expanded
    ? '  margin-right: 12px;'
    : '')}
`;

export const ABPopUpDivider = styled.div`
  width: 18px;
  height: 0.6px;
  transform: rotate(-90deg);
  flex-shrink: 0;
  background: #ECE2E2;
`;

export const ABExternalWrapper = styled.div`
  display: flex;
  position: relative;
  top:4px;
  width: max-content;
`;

interface ABPartitialWrapperProps {
  color: keyof typeof ApprovalBTNColorEnum;
  theFirst?: boolean;
  isChosen?: boolean;
  isDisabled?: boolean;
  viewMode?: keyof typeof NewUPVViewModes;
  width?: number;
}

export const ABPartitialWrapper = styled.div<ABPartitialWrapperProps>`
  width: max-content;
  padding: 9px 12px;
  height: 32px;
  display: flex;
  border: 0.6px solid ${({ color }) => ApprovalBTNColorEnum[color]};
  color: ${({ color }) => ApprovalBTNColorEnum[color]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
  
  ${({ theFirst }) => (theFirst ? `
    border-right: none;
    border-radius: 6px 0px  0px 6px;
  ` : `
        border-radius: 0px 6px 6px 0px;
  `)}

  ${({ isChosen, color }) => (isChosen ? `
    background: ${ApprovalBTNBackgroundColorEnum[color]};
  ` : `
  background:#FEFEFE;
  `)}
  
  svg{
    margin-left:5px;
    path{
      stroke:  ${({ color }) => ApprovalBTNColorEnum[color]};
    }
  }
  ${({ isDisabled, color }) => (isDisabled ? 'cursor:not-allowed;' : ` cursor:pointer;
    &:hover{
    background: ${ApprovalBTNHoverColorEnum[color]};
  }
  &:active {
    background: ${ApprovalBTNClickedColorEnum[color]};
  }
  `)}
 &:hover{
    .ab_pop_up{
      opacity:1;
    }
  }
  ${({ viewMode, width, isChosen }) => (viewMode === NewUPVViewModes.list
    ? `height:24px;
       padding-top: 6px; 
      ${width ? `
      ${isChosen ? '  padding-top: 4px;' : ''}
       display: inline-block;
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
       max-width:${width}px;` : ''};

    `
    : '')}
`;


export const ABPopUpWrapper = styled.div<{ isListView?: boolean}>`
  position: absolute;
  top: -35px;
  left: 67px;
  opacity: 0;
  width: 65px;
  height: 28px;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 6px;
  border: 0.6px solid #ECE2E2;
  background: rgba(255, 255, 255, 0.80);
  ${({ isListView }) => (isListView ? 'left:19px;' : '')}
  svg{
    position: absolute;
    top: -9px;
    left: -16px;
    margin: unset;
    path {
      stroke: #343434;
    }
  }
  .delete_svg {
    top: -9px;
    left: -2px;
    path {
      stroke: rgba(211, 47, 47, 1);
    }
  }
`;
