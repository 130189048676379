import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import rawStyles from './elements.module.scss';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import useComponentVisibleMouseDown from '../../../utils/hooks/useComponentVisibleMouseDown';
import {
  actionRemoveLinkPageFromPlaylist,
} from '../../../redux/currentPage/action';
import { actionChangeTextElementBlockReduxMaker } from '../../../redux/playlists/action';
import SixDotsItem from './SixDotsItem';
import DeleteContentButton from './DeleteContentButton';
import { isRoleInPlaylist } from '../../../utils/permissions';
import TitleEditor from '../../../features/TitleTextEditor/TitleEditor';
import { smartFileItemType } from '../../../shared/smartFile/constant';
import { actionCreator } from '../../../shared/redux/actionHelper';
import EditPlaylist from '../../../redux/playlists/types';
import { TooltipWrapper, UsedByAIIndicator } from './PlaylistElementCard/styled';
import { calcColorForAIProcessingStatus } from '../../../utils/helpers';
import Tooltip from '../../../shared/Tooltips/Tooltip';
import { aiProcessingStatusEnum, aiProcessingStatusPreviewEnum } from '../../../utils/constants';

const cn = classNames.bind(rawStyles);


const TitleElement = ({
  item,
  playlistId,
  isDragHover,
  itemStylesObject,
  isExpandedView,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: userId } = useSelector((state) => state.user);
  const currentPage = useSelector((state) => state.currentPage);
  const ownerID = currentPage?.owner?.id;
  const isAdmin = userId === ownerID;
  const { isViewer } = isRoleInPlaylist;
  const [itemRef, , setIsEdit] = useComponentVisibleMouseDown(false, item.id);
  const [menuRef, isActive, setIsActive] = useComponentVisible(false);
  const canUpdForRedoUndo = useRef(null);

  const { newBlockId, selectedItems } = useSelector((reduxState) => (reduxState.currentPage));

  const cmdZ = useSelector(
    (reduxState) => reduxState.shortcut?.combination['mod+z'],
  );
  const cmdShZ = useSelector(
    (reduxState) => reduxState.shortcut?.combination['mod+shift+z'],
  );
  const socketUpd = useSelector(
    (reduxState) => reduxState.shortcut?.combination.socketUpd,
  );
  const timerId = useRef(null);
  const otherOwner = userId !== item?.owner?.id && item.owner;

  useEffect(() => {
    if (cmdZ || cmdShZ || socketUpd) {
      setCanUpdForRedo();
    }
  }, [cmdZ, cmdShZ, socketUpd]);

  const setCanUpdForRedo = () => {
    canUpdForRedoUndo.current = true;
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    timerId.current = setTimeout(() => {
      canUpdForRedoUndo.current = false;
    }, 1000);
  };

  const onCardClickHandler = () => {
    if (isViewer[props.currentRole]) {
      return;
    }
    setIsEdit(true);
  };

  const deleteHandler = () => {
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        playlistId,
        item.id,
        item.textComponent?.id || item.libraryComponent?.id,
      ),
    );
  };

  const onChange = useCallback((jsonEditorState) => {
    if (!jsonEditorState || otherOwner) return;
    dispatch(actionChangeTextElementBlockReduxMaker(
      item.textComponent.id,
      jsonEditorState,
      '',
      playlistId,
      item.id,
      smartFileItemType.title,
    ),
    );
  }, []);

  const onBlurHandler = useCallback((state) => {
    dispatch(actionCreator(EditPlaylist.removeNewInLinkPage, { state }));
  }, [dispatch]);

  return (
    <div
      className={cn('six_dot_balancer', {
        isCompressView: props.isCompressView,
        hoverable: !isDragHover && !isViewer[props.currentRole],
        active: isActive,
      })}
    >
      <div
        className={cn('empty_element_wrapper',
          { is_expanded_view: isExpandedView, not_viewer: !isViewer[props.currentRole] })}
      >
        <div
          onClick={onCardClickHandler}
          className={cn('text_element', 'title_element', {
            hoverable: !isDragHover,
            dragginOver: isDragHover,
            viewMode: isViewer[props.currentRole],
            notEdit:
              (userId !== item?.owner?.id)
              || isViewer[props.currentRole],
            blink_it: props.blinkId?.includes(item.id),
          })}
          ref={itemRef}
          onDragEnd={props.onDragEndHandler}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onDragStart={props.dragStartHandler}
          draggable={!isViewer[props.currentRole] && !otherOwner}
        >
          <SixDotsItem
            isExpandedView={isExpandedView}
            clear={props.clear}
            createHandler={props.createHandler}
            onDragStartHandler={props.onDragStartHandler}
            onDragEndHandler={props.onDragEndHandler}
            title={t('textBlockUpT')}
            playlistId={playlistId}
            menuRef={menuRef}
            dragStartHandler={props.dragStartHandler}
            isActive={isActive}
            setIsActive={setIsActive}
            isText
            isTitle
            ownerID={ownerID}
            item={item}
            itemStylesObject={itemStylesObject}
            otherOwner={otherOwner}
            userRole={props.userRole}
            duplicateHandler={props.duplicateHandler}
            isEmptyPlaceholder
            isViewer={isViewer[props.currentRole]}
            currentRole={props.currentRole}
            createApproveButton={props.createApproveButton}
            addCaptionToLinkPage={props.addCaptionToLinkPage}
            removeCaptionFromLinkPage={props.removeCaptionFromLinkPage}
            deleteApproveButton={props.deleteApproveButton}
            handleShowPannel={props.handleShowPannel}
            isCompressView={props.isCompressView}
          />
          {item.isTextSavedToVectorDb && (
            <TooltipWrapper>
              <Tooltip
                text={t(aiProcessingStatusPreviewEnum.DONE)}
                direction="down"
              >
                <UsedByAIIndicator
                  statusColor={calcColorForAIProcessingStatus(aiProcessingStatusEnum.ITEM_PROCESSED_IN_AI)}
                />
              </Tooltip>
            </TooltipWrapper>
          )}
          <div
            data-parent={item.id}
            className={`${cn('text_container', 'title_indent', {
              selectedTitle: selectedItems[item.id],
              notEdit:
                (userId !== item?.owner?.id)
                || isViewer[props.currentRole],
            })} highlightedPlace`}
            draggable
            onDragStart={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }
            }
            onMouseDown={(e) => {
              e.isText = true;
            }}
          >
            <TitleEditor
              isActive={newBlockId && (newBlockId === item.textComponent.id)}
              onChange={onChange}
              state={item.textComponent?.state || ''}
              onBlurHandler={onBlurHandler}
            />
          </div>
        </div>
      </div>
      {!isViewer[props.currentRole]
        && ((item.owner && userId === item?.owner?.id) || isAdmin) && (
        <DeleteContentButton deleteHandler={deleteHandler} isCompressView={props.isCompressView} />
      )}
    </div>
  );
};

export default TitleElement;
