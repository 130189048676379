import {
  TableCell,
  TableCellLayout,
  TableRow,
} from '@fluentui/react-components';
import React from 'react';
import './table.scss';
import { HOCActionSell } from '../MiniPlaylist/NewPlaylist2023/table/HOCActionSell';
import useComponentVisible from '../../utils/hooks/useComponentVisible';


export const LibraryTableRow = React.memo(function LibraryTableRow({
  item, singleClickHandler, index, columnSizing_unstable,
}) {
  const [refMenu, isDropDownVisible, setIsDropDownVisible] = useComponentVisible(null, 'miniPage');
  const toggleShowThreeDots = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDropDownVisible(!isDropDownVisible);
  };

  return (

    <TableRow
      onContextMenu={toggleShowThreeDots}
      onClick={(e) => singleClickHandler(e, item.elementProps, index)}
    >
      <TableCell
        {...columnSizing_unstable.getTableCellProps('checkbox')}
      >
        <TableCellLayout truncate media={item.checkbox.icon}>
          {item.checkbox.label}
        </TableCellLayout>
      </TableCell>
      <TableCell {...columnSizing_unstable.getTableCellProps('image')}>
        <TableCellLayout truncate className="cell-wrapper">{item.image.label}</TableCellLayout>
      </TableCell>
      <TableCell {...columnSizing_unstable.getTableCellProps('name')}>
        <TableCellLayout truncate>{item.name.label}</TableCellLayout>
      </TableCell>
      <TableCell {...columnSizing_unstable.getTableCellProps('tags')}>
        <TableCellLayout truncate media={item.tags.icon}>
          {item.tags.label}
        </TableCellLayout>
      </TableCell>
      <TableCell {...columnSizing_unstable.getTableCellProps('type')}>
        <TableCellLayout truncate media={item.type.icon}>
          {item.type.label}
        </TableCellLayout>
      </TableCell>
      <TableCell
        {...columnSizing_unstable.getTableCellProps('created')}
      >
        <TableCellLayout truncate media={item.created.icon}>
          {item.created.label}
        </TableCellLayout>
      </TableCell>
      <TableCell
        {...columnSizing_unstable.getTableCellProps('modified')}
      >
        <TableCellLayout truncate media={item.modified.icon}>
          {item.modified.label}
        </TableCellLayout>
      </TableCell>
      <TableCell {...columnSizing_unstable.getTableCellProps('size')}>
        <TableCellLayout truncate media={item.size.icon}>
          {item.size.label}
        </TableCellLayout>
      </TableCell>
      <TableCell
        {...columnSizing_unstable.getTableCellProps('actions1')}
      >
        <TableCellLayout truncate media={item.actions1.icon}>
          <HOCActionSell
            refMenu={refMenu}
            isDropDownVisible={isDropDownVisible}
            setIsDropDownVisible={setIsDropDownVisible}
            label={item.actions1.label}
          />
        </TableCellLayout>
      </TableCell>
    </TableRow>

  );
});
