const customBorderParams = {
  color: '%23dcd6d6',
  colorHovered: '%23FFC72C',
  strokeHeight: '2px',
  borderRadius: '12',
  spaceBorder: '7',
  widthBorder: '10',
} as const;

export const svgCustomBorder = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0%25' y='0%25' width='100%25' height='100%25' fill='transparent' stroke='${customBorderParams.color}' stroke-width='${customBorderParams.strokeHeight}' stroke-dasharray='${customBorderParams.widthBorder} ${customBorderParams.spaceBorder} ' rx='${customBorderParams.borderRadius}' ry='${customBorderParams.borderRadius}' /%3E%3C/svg%3E")` as const;
export const svgCustomBorderHovered = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0%25' y='0%25' width='100%25' height='100%25' fill='transparent' stroke='${customBorderParams.colorHovered}' stroke-width='${customBorderParams.strokeHeight}' stroke-dasharray='${customBorderParams.widthBorder} ${customBorderParams.spaceBorder} ' rx='${customBorderParams.borderRadius}' ry='${customBorderParams.borderRadius}' /%3E%3C/svg%3E")` as const;
