import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createPage } from '../../../../utils/helpers';
import Button2023, { IconTypesBtn2023 } from '../../../Buttons/Button2023';
import SortAndView from '../SortAndView';
import { Divider, GroupIcon, Wrapper } from './StyledComponents';
import { emptyCallback, openModalType } from '../../../../utils/constants';
import { actionOpenModal } from '../../../../redux/user/action';

const SecondaryPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <GroupIcon forContacts>
      <Wrapper>
        <Button2023
          text={t('plusContactUpT')}
          width={89}
          height={32}
          variant="secondary"
          handleButtonClick={() => dispatch(actionOpenModal(openModalType.EditContact))}
        />
        <Divider zeroMargins />
        <Button2023
          text={t('importCSVUpT')}
          handleButtonClick={emptyCallback}
          width={118}
          height={32}
          variant="primary"
          IcoType={IconTypesBtn2023.upload}
          paddingLeft={29}
        />
      </Wrapper>

    </GroupIcon>
  );
};

export default SecondaryPage;
