import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DATA_PARENTS, SHARED_PLAYLIST_URL } from '../../../../../utils/constants';
import Header from './SettingsHeader';
import Options from './options/Options';
import { MakerSettingsWrapper } from './styled';
import { actionGetUsersToSharingPl } from '../../../../../redux/playlists/action';
import { actionCreator } from '../../../../../shared/redux/actionHelper';
import EditPlaylist from '../../../../../redux/playlists/types';


const SettingsRightSideBar = ({ wrapperRef, currentPage, close, type }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname.includes(`/${SHARED_PLAYLIST_URL}`)) return;
    dispatch(actionGetUsersToSharingPl({ type }));
    if (type === 'playlist') {
      dispatch(actionCreator(EditPlaylist.getChannelsToPublishPl));
    }
  }, []);
  return (
    <MakerSettingsWrapper
      wrapperRef={wrapperRef}
      data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
    >
      <Header close={close} dataParent={DATA_PARENTS.PlaylistBuilderLibrary} />
      <Options
        type={type}
        currentPage={currentPage}
        dataParent={DATA_PARENTS.PlaylistBuilderLibrary}
      />
    </MakerSettingsWrapper>
  );
};

export default SettingsRightSideBar;
