import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import styles from './MediaAdd.module.css';
import { BASEURL } from '../../environments/environments';

const MediaAddPopup = ({ type, refPopup, isComponentVisible, addImage }) => {
  const [url, setUrl] = useState('');
  const [isFirstTab, setIsFirstTab] = useState(true);
  const [loader, setShowLoader] = useState(false);
  const { t } = useTranslation();

  const save = () => {
    setShowLoader(true);
    let a = url.split('v=')[1];
    a = a != undefined ? a : url.split('youtu.be/')[1];
    const b = a && a.split('&')[0];
    axios
      .get(`${BASEURL}/v1/duration/?youtubeId=${b}`)
      .then((e) => {
        addImage(url, e.data);
        setUrl('');
      })
      .catch(() => {
        addImage(url);
        setUrl('');
      });
  };
  const changeUrl = (evt) => {
    setUrl(evt.target.value);
  };

  return (
    <div
      ref={refPopup}
      className={
        isComponentVisible
          ? styles.addImagePopover
          : styles.addImageClosedPopover
      }
    >
      {loader && (
        <div className="loader-wrapper-page">
          <div className="loader_mini">{t('loadingT')}</div>
        </div>
      )}
      {!loader && (
        <>
          <div className={styles.tabs_block}>
            <div
              className={isFirstTab ? styles.tabs_active : styles.tab}
              onClick={() => setIsFirstTab(true)}
            >
              {t('embedLinkT')}
            </div>
            <div
              className={isFirstTab ? styles.tab : styles.tabs_active}
              onClick={() => setIsFirstTab(false)}
            >
              {t('uploadT')}
            </div>
          </div>
          {isFirstTab && (
            <>
              <input
                type="text"
                placeholder={`Paste the ${type} url …`}
                className={styles.addImageInput}
                onChange={changeUrl}
                value={url}
              />
              <button
                className={styles.addImageConfirmButton}
                type="button"
                onClick={save}
              >
                {t('addT')}
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MediaAddPopup;
