import { RequestProceed } from './types';

const defaultState = { deleteQueue: [] };
export default (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case RequestProceed.addNew: {
      const { id, key } = payload;
      return {
        ...state,
        [id]: key,
      };
    }
    case RequestProceed.remove: {
      const { id } = payload;
      const key = state[id];
      const newState = Object.assign({}, state);
      newState.deleteQueue.push(key);
      delete newState[id];
      return newState;
    }

    case RequestProceed.removeFromQueue: {
      const { key } = payload;
      return {
        ...state,
        deleteQueue: state.deleteQueue.filter((it) => it !== key),
      };
    }

    default: {
      return state;
    }
  }
};
