import { call, takeEvery } from 'redux-saga/effects';
import { all, fork, select } from '@redux-saga/core/effects';
import { ServiceUser } from '../redux/user/types';

import { getCurrentPage, getLibrary, getUser } from './sagasUtils';
import { validateURL } from '../utils/helpers';
import {
  createLibraryComponentUpload,
  createLinkTypeLibraryComponent,
  deleteOldUploadBlock,
  finishLibraryComponentUpload,
  getPositionsForAllFiles,
  insertNewChild,
  parseUrlViaQuidziAPI,
  updateNewChild,
} from '../utils/sagaFunctions';

function* addLinkTypeLibraryComponent({
  url,
  history,
  isWithUpload = false,
  firstBlockIndex,
  isParentExists = false,
  isPlaylist,
  newPosition,
  newChildId,
  newLibraryComponentId,
  oldUploadBlockId,
  componentType,
}) {
  if (!validateURL(url)) return;
  const { isOpenCreateComponent, editComponentId } = yield select(getLibrary);
  const { id: currentPageId } = yield select(getCurrentPage);

  const parentId = isOpenCreateComponent ? editComponentId : currentPageId;

  const {
    folderId,
    selectorType,
    tags,
    id: userId,
    firstName,
    lastName,
  } = yield select(getUser);

  const localTags = [];
  if (selectorType === 'tag' && folderId) {
    localTags.push({ ...tags[folderId] });
  }

  const { selector } = yield select(getLibrary);

  const newLibraryComponentForeignFileId = '';
  const newLibraryComponentLinkedAccountId = '';
  const foreignLastModifiedDate = '';

  const parsedLinkData = yield call(parseUrlViaQuidziAPI, url);

  if (isWithUpload) {
    yield call(createLibraryComponentUpload, {
      history,
      newLibraryComponentId,
      selectorType,
      selector,
      localTags,
      componentType,
    });
  }

  if (isParentExists) {
    yield call(deleteOldUploadBlock, {
      blockIdToDelete: oldUploadBlockId,
      parentIdToDeleteBlockFrom: parentId,
      isOpenCreateComponent,
      isPlaylist,
    });

    yield call(insertNewChild, {
      isPlaylist,
      newChildId,
      newPosition,
      user: {
        id: userId,
        firstName,
        lastName,
      },
      parsedLinkData,
      parentId,
      isOpenCreateComponent,
      firstBlockIndex,
    });
  }

  const result = yield call(createLinkTypeLibraryComponent, {
    urlText: url,
    newLibraryComponentId,
    newLibraryComponentForceType: componentType,
    newLibraryComponentForeignFileId,
    newLibraryComponentLinkedAccountId,
    foreignLastModifiedDate,
    parsedLinkData: { ...parsedLinkData },
    selectorType,
    selector,
    localTags,
  });

  if (isParentExists && !!result) {
    yield call(updateNewChild, {
      isPlaylist,
      newChildId,
      parentId,
      newPosition,
      sourceComponent: result,
      isOpenCreateComponent,
      firstBlockIndex,
      componentType,
    });
  }

  if (isWithUpload && result !== false) {
    yield call(finishLibraryComponentUpload, { newLibraryComponentId });
  }
}

function* addLibraryComponentToParent({
  file,
  newPosition,
  selectorType,
  localTags,
  selector,
  isPlaylist,
  firstBlockIndex,
  isParentExists,
  isWithUpload = false,
  history,
  componentType,
}) {
  const { isOpenCreateComponent, editComponentId } = yield select(getLibrary);
  const { id: userId, firstName, lastName } = yield select(getUser);
  const { id: currentPageId } = yield select(getCurrentPage);
  const parentId = isOpenCreateComponent ? editComponentId : currentPageId;
  const newChildId = file.newChildId;
  const newLibraryComponentId = file.newLibraryComponentId;
  const title = file.name;
  const url = file.link ?? file.url;
  const newLibraryComponentForeignFileId = file.id;

  const parsedLinkData = yield call(parseUrlViaQuidziAPI, url);

  if (isWithUpload) {
    yield call(createLibraryComponentUpload, {
      history,
      newLibraryComponentId,
      componentType,
      selectorType,
      selector,
      localTags,
    });
  }

  if (isParentExists) {
    yield call(insertNewChild, {
      isPlaylist,
      newChildId,
      newPosition,
      user: {
        id: userId,
        firstName,
        lastName,
      },
      parsedLinkData,
      parentId,
      isOpenCreateComponent,
      firstBlockIndex,
    });
  }

  const result = yield call(createLinkTypeLibraryComponent, {
    urlText: url,
    newLibraryComponentId,
    newLibraryComponentForceType: componentType,
    newLibraryComponentForeignFileId,
    parsedLinkData: { ...parsedLinkData, title, url },
    selectorType,
    selector,
    localTags,
  });

  if (isParentExists && !!result) {
    yield call(updateNewChild, {
      isPlaylist,
      newChildId,
      parentId,
      newPosition,
      sourceComponent: result,
      isOpenCreateComponent,
      firstBlockIndex,
      componentType,
    });
  }

  if (isWithUpload && result !== false) {
    yield call(finishLibraryComponentUpload, { newLibraryComponentId });
  }
}

function* handleIntegrationPickedFiles(action) {
  const {
    files,
    history,
    startPosition,
    endPosition,
    firstBlockIndex,
    isWithUpload,
    oldUploadBlockId,
    componentType,
  } = action.payload;

  const { folderId, selectorType, tags } = yield select(getUser);
  const { type, id: currentPageId } = yield select(getCurrentPage);
  const { selector, isOpenCreateComponent, editComponentId } = yield select(getLibrary);

  const parentId = isOpenCreateComponent ? editComponentId : currentPageId;
  const isParentExists = (!!type && (type === 'PLAYLIST' || type === 'LIBRARY_COMPONENT'))
    || isOpenCreateComponent;
  const isPlaylist = !!type && type === 'PLAYLIST';

  const localTags = [];
  if (selectorType === 'tag' && folderId) {
    localTags.push({ ...tags[folderId] });
  }

  const positions = getPositionsForAllFiles(startPosition, endPosition, files);

  if (isParentExists) {
    yield call(deleteOldUploadBlock, {
      blockIdToDelete: oldUploadBlockId,
      parentIdToDeleteBlockFrom: parentId,
      isOpenCreateComponent,
      isPlaylist,
    });
  }

  yield all(
    files.map((file, index) => {
      return fork(addLibraryComponentToParent, {
        file,
        history,
        newPosition: positions[index] ?? positions.defaultStep,
        selectorType,
        localTags,
        selector,
        isPlaylist: type === 'PLAYLIST',
        firstBlockIndex,
        isParentExists,
        isWithUpload,
        componentType,
      });
    }),
  );
}

function* handleIntegrationFileLink(action) {
  const {
    url,
    history,
    firstBlockIndex,
    isWithUpload,
    oldUploadBlockId,
    newChildId,
    newLibraryComponentId,
    oldUploadBlockPosition,
    componentType,
  } = action.payload;
  const { type } = yield select(getCurrentPage);
  const { isOpenCreateComponent } = yield select(getLibrary);
  const isParentExists = (!!type && (type === 'PLAYLIST' || type === 'LIBRARY_COMPONENT'))
    || isOpenCreateComponent;
  const isPlaylist = !!type && type === 'PLAYLIST';

  yield call(addLinkTypeLibraryComponent, {
    url,
    history,
    firstBlockIndex,
    isParentExists,
    isWithUpload,
    oldUploadBlockId,
    newChildId,
    newLibraryComponentId,
    newPosition: oldUploadBlockPosition,
    isPlaylist,
    componentType,
  });
}

export default function* integrationsSagas() {
  yield takeEvery(
    ServiceUser.HandleIntegrationPickedFilesS,
    handleIntegrationPickedFiles,
  );
  yield takeEvery(
    ServiceUser.HandleIntegrationFileLinkS,
    handleIntegrationFileLink,
  );
}
