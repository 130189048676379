import React from 'react';
import { useSelector } from 'react-redux';
import { ColumnsDropDownShell } from './StyledComponents';
import ColumnDropDownItem from './ColumnDropDownItem';

const ColumnsDropDown = ({
  item,
  isChannel,
  defaultPosition = { top: 33, right: 5 },
  selectedColumn,
  ...props
}) => {
  const { columns, closeColumn } = useSelector(
    (state) => state.dashboardColumns,
  );
  const displayedColumns = [...Object.values(columns), closeColumn];

  const divStyle = {
    top: defaultPosition?.top,
    right: defaultPosition?.right,
  };

  return (
    <ColumnsDropDownShell
      ref={props.refMenu}
      style={divStyle}
      data-parent={props.dataParent}
    >
      {displayedColumns.map((col) => (
        <ColumnDropDownItem
          key={col.id}
          item={col}
          setSelectedColumn={props.setSelectedColumn}
          dataParent={props.dataParent}
          setIsMenuVisible={props.setIsMenuVisible}
        />
      ))}
    </ColumnsDropDownShell>
  );
};

export default ColumnsDropDown;
