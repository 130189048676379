import { socketEmit } from '../SocketClusterHelper';

export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const sendMessage = (message, sender) => socketEmit({
  type: RECEIVE_MESSAGE,
  payload: {
    message,
    sender,
  },
});
