import { centerCrop, makeAspectCrop } from 'react-image-crop';

export const defaultCrop = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  unit: 'px',
};

export function convertToPixelCrop(crop, containerWidth, containerHeight) {
  if (!crop.unit) {
    return { ...defaultCrop, ...crop, unit: 'px' };
  }

  if (crop.unit === 'px') {
    return { ...defaultCrop, ...crop, unit: 'px' };
  }

  return {
    unit: 'px',
    x: crop.x ? (crop.x * containerWidth) / 100 : 0,
    y: crop.y ? (crop.y * containerHeight) / 100 : 0,
    width: crop.width ? (crop.width * containerWidth) / 100 : 0,
    height: crop.height ? (crop.height * containerHeight) / 100 : 0,
  };
}

export const calcNewCropStyles = ({ width, height }, sliderPosition) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: sliderPosition,
        height: sliderPosition,
      },
      1,
      width,
      height,
    ),
    width,
    height,
  );
};
export const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 50,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
};
