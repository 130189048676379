import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import LibraryHeaderMenu from '../../components/LibraryHeaderMenu';
import { sortedFunctions } from '../../utils/dateConvert';
import {
  actionToggleIsCardView,
} from '../../redux/currentPage/action';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { actionChangeSieveValue } from '../../redux/library/actions';
import {
  ChannelsSieveTypes,
  ChannelsSieveTypesWithOutMembers,
  empty,
  PLACE,
} from '../../utils/constants';
import { Channels as ChannelsTypes } from '../../redux/channels/types';
import { ChannelPagesSchema } from '../../utils/tableHelpers/tableUtils';
import ChannelsMembersContent from './ChannelMembersContent';
import {
  actionGetChannelData,
  actionSetCurrentChannel,
  actionSetNewFilterValue,
} from '../../redux/channels/action';
import AboutContent from './AboutContent';
import downloadStatus from '../../utils/dataUtil';
import { compositeUserFilter } from '../../utils/helpers';
import { actionCreator } from '../../shared/redux/actionHelper';
import { RawUserRoleMap } from '../../utils/permissions';
import { actionSwitchFolder } from '../../redux/user/action';
import PlaylistContentSelector from '../../components/PlaylistContentSelector';
import { ContentActionType } from '../../redux/content/contentTypes';
import { actionClearPayloadUnifyHistory } from '../../redux/history/actions';
import {
  sortOptionChooser,
  sortOptionChooserForChannels,
} from '../../utils/sort/sortTypeChooser';
import { actionSwitchContentSortType } from '../../redux/content/actions';

const Wrapper = styled.div`
  z-index: 102;
  position: relative;
  background: #f6f6f5;
  ${({ minimize }) => (minimize
    ? `    width: calc(100% - 350px);
`
    : ' width: 100%;')}
`;
const WrapperLoad = styled.div`
  z-index: 102;
  position: relative;
  background: #f0f0f0;
  height: calc(100vh - 71px);
  width: 100%;
`;
// FOR VIEW MODE DEBUGGING
// const _TmpToggler = styled.div`
//   position: absolute;
//   top: 30px;
//   border-radius: 4px;
//   padding: 0 10px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   min-width: 32px;
//   height: 32px;
//   background-color: #531313;
//   color: ${({ active }) => (active ? '#0bea0f' : 'red')};
//   font-size: 14px;
//   line-height: 21px;
//   text-transform: uppercase;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   cursor: pointer;
//
//
//   &:active {
//     background-color: #cf6565;
//   }
// `;
const convert = {
  [ChannelsSieveTypes.ChannelsMembers]: 'channelUsers',
  [ChannelsSieveTypes.ChannelsFavorite]: 'channelFavoritePlaylists',
  [ChannelsSieveTypes.ChannelsAll]: 'channelPlaylists',
};

const Channels = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { id: folderId } = useParams();
  const currentPage = useSelector((state) => state.currentPage);
  const [tableFilter] = useState({ field: 'name', value: '' });
  const { selectorType: selectorType2, isAdmin } = useSelector((state) => state.user);
  const { contentIds, existMore, contentData } = useSelector((state) => state.content);
  const previousSortType = useSelector((state) => state?.content?.sortType) || 'recent';

  const { myChannels, activeSieveOption, userRoleFilter, userStatusFilter } = useSelector(
    (redux) => redux.channels) || empty;

  const isDescriptionOpened = useSelector(
    (state) => state.support.componentDescription,
  );

  const sortOptions = useSelector((state) => state.settings.sortOptions);

  const [filter, setFilter] = useState('');
  const [title, setTitle] = useState(currentPage.name);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortType, setSort] = useState(previousSortType);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [showFilterBar] = useState(true);
  const [refShowMenuSort, isShowMenuSort, setShowMenuSort] = useComponentVisible(false);
  const [localSieveActive, setLocalSieveActive] = useState(ChannelsSieveTypes.ChannelsAll);
  const [sliderNumbersObject, setSliderNumbersObject] = useState({});
  const [showOnlyFavorite, setShowOnlyFavorite] = useState(false);
  const [showHidden, setShowHidden] = useState(false);
  const [totalFavorites] = useState(0);

  const scroller = useRef(null);
  const refSelectedBlocks = { current: [] };
  const [hasMore, setHasMore] = useState(true);

  const activeNavSlider = convert[localSieveActive];
  const selectorType = myChannels[folderId]?.id;
  const lastStep = useSelector((state) => state.historyTrace.somePayload);

  useEffect(() => {
    setHasMore(existMore);
  }, [contentIds]);

  useEffect(() => {
    if (!selectorType || !activeNavSlider) return;
    setHasMore(false);
    const sortName = sortOptionChooser(activeNavSlider, selectorType);
    const exSortType = sortOptions[sortName] || previousSortType;
    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        { activeNavSlider, selectorType, sortType: exSortType, isNeedUpdate: true,
        },
        'Channels',
      ),
    );
  }, [folderId, localSieveActive, selectorType, sortType]);

  useEffect(() => {
    dispatch(actionSwitchFolder('folder', folderId));
  }, [folderId]);

  useEffect(() => {
    return () => {
      dispatch(
        actionCreator(ContentActionType.startUpload, {}, 'Channels clear'),
      );
    };
  }, []);

  const changeCardView = () => {
    dispatch(actionToggleIsCardView());
  };

  useEffect(() => {
    setLocalSieveActive(activeSieveOption);
  }, [activeSieveOption]);

  useEffect(() => {
    setTitle(currentPage.inputs.name);
  }, [currentPage.inputs.name]);

  useEffect(() => {
    dispatch(actionCreator(ChannelsTypes.UpdatePlaylistsCountersS));

    let firstAvailableId;
    if (lastStep) {
      const activeChannel = lastStep?.step?.activeChannel;
      const activeManagerId = lastStep?.step?.activeManagerId;

      if (activeChannel && activeManagerId) {
        dispatch(actionSwitchFolder('folder', activeChannel));
        history.push(`/channel/${activeChannel}`);
        dispatch(actionClearPayloadUnifyHistory());
        dispatch(
          actionSetCurrentChannel({
            id: activeChannel,
          }),
        );
      }
    } else {
      firstAvailableId = folderId
        ? myChannels[folderId]?.id
        : Object.values(myChannels || {})[0]?.id;
      if (!firstAvailableId) return;
      dispatch(
        actionSetCurrentChannel({
          id: firstAvailableId,
        }),
      );
    }
  }, [history]);

  useEffect(() => {
    if (
      folderId
      && myChannels[folderId]?.readyStatus !== downloadStatus.success
    ) return;
    let tmpDisplayedItems = [];
    if (selectorType !== 'folder') {
      setTitle(t('quidziDefaultChannelT'));
    } else if (localSieveActive === ChannelsSieveTypes.ChannelsAll) {
      tmpDisplayedItems = Object.values(
        myChannels[folderId]?.playlists || {},
      ).filter(
        (playlist) => playlist.isPublish
          && !playlist.movedToTrash
          && (!showOnlyFavorite || playlist.isFavorite)
          && (!showHidden || !playlist.isHide),
      );
      setTitle(myChannels[folderId]?.name || t('unnamedChannelT'));
    } else if (localSieveActive === ChannelsSieveTypes.ChannelsMembers) {
      tmpDisplayedItems = Object.values(myChannels[folderId]?.users || {})
        .map((user) => {
          return {
            ...user,
            isNested: true,
            channelId: myChannels[folderId]?.id,
            channelTitle: myChannels[folderId]?.title,
          };
        })
        .filter((user) => user.status !== 'Deleted');
      setTitle(myChannels[folderId]?.name || t('unnamedChannelT'));
    } else if (localSieveActive === ChannelsSieveTypes.Info) {
      setTitle(myChannels[folderId]?.name || t('unnamedChannelT'));
    }
    const timeout = setTimeout(() => setLoading(false), 200);
    setDisplayedItems(tmpDisplayedItems);
    return () => {
      clearTimeout(timeout);
    };
  }, [
    folderId,
    myChannels,
    showHidden,
    showOnlyFavorite,
    selectorType,
    localSieveActive,
    currentPage,
    myChannels[folderId],
  ]);

  useEffect(() => {
    if (!folderId) return;
    setSliderNumbersObject({
      [ChannelsSieveTypes.ChannelsAll]: Array(
        myChannels[folderId]?.playlistsCounter,
      ),
      [ChannelsSieveTypes.ChannelsMembers]: Array(
        myChannels[folderId]?.totalSubs,
      ),
      [ChannelsSieveTypes.ChannelsFavorite]: Array(
        myChannels[folderId]?.playlistsFavoriteCounter,
      ),
    });
  }, [myChannels]);

  useEffect(() => {
    if (!folderId) return;
    dispatch(actionGetChannelData(folderId));
  }, [dispatch, folderId]);

  const setSieve = (value) => {
    const sortName = sortOptionChooserForChannels(value);
    const sortOption = sortOptions[sortName];
    if (sortName && sortOption) {
      dispatch(actionSwitchContentSortType(sortOption));
    }

    dispatch(actionChangeSieveValue(value, PLACE.Channels));
  };

  useEffect(() => {
    if (filter || userRoleFilter || userStatusFilter) {
      setFilteredItems(
        displayedItems.filter((item) => compositeUserFilter(
          item,
          RawUserRoleMap[userRoleFilter],
          userStatusFilter,
          filter,
        ),
        ),
      );
    } else setFilteredItems(displayedItems || []);
  }, [displayedItems, filter, userRoleFilter, userStatusFilter]);

  const setIsCardView = () => {
    dispatch(actionToggleIsCardView());
  };

  const clearAllFilters = () => {
    setFilter('');
    setIsShowFilter(false);
    dispatch(actionSetNewFilterValue('Role', ''));
    dispatch(actionSetNewFilterValue('Status', ''));
    setSort(Object.keys(sortedFunctions)[2]);
  };

  const next = () => {
    setHasMore(false);
    const sortName = sortOptionChooser(activeNavSlider, selectorType);
    const exSortType = sortOptions[sortName] || previousSortType;
    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        { activeNavSlider, selectorType, sortType: exSortType },
        'Channels next',
      ),
    );
  };
  // =====
  if (folderId
    && myChannels[folderId]?.readyStatus !== downloadStatus.success) {
    return (
      <WrapperLoad>
        <div className="flex_loader-wrapper">
          <div className="flex_loader">{t('loadingT')}</div>
        </div>
      </WrapperLoad>
    );
  }

  return (
    <Wrapper minimize={isDescriptionOpened}>
      <LibraryHeaderMenu
        isChannels
        filter={filter}
        isShowInput={!!displayedItems.length}
        clearAllFilters={clearAllFilters}
        refShowMenuSort={refShowMenuSort}
        setShowMenuSort={setShowMenuSort}
        isShowMenuSort={isShowMenuSort}
        sortType={sortType}
        setLoading={setLoading}
        setSort={setSort}
        setIsCardsView={setIsCardView}
        selectorType={selectorType}
        setFilter={setFilter}
        setIsShowFilter={setIsShowFilter}
        title={title}
        isShowFilter={isShowFilter}
        folderId={folderId}
        isCardsView={
          currentPage.isCardView
          && localSieveActive !== ChannelsSieveTypes.ChannelsMembers
        }
        pagesNumber={filteredItems.length}
        displayedPagesTotal={displayedItems.length}
        totalFavorites={totalFavorites}
        setSieve={setSieve}
        activeSieveOption={localSieveActive}
        isNoItems={!filteredItems.length}
        showFilterBar={showFilterBar}
        permissions={myChannels[folderId]?.permissions}
        totalSubscribers={myChannels[folderId]?.totalSubs}
        sieveOptions={
          isAdmin ? ChannelsSieveTypesWithOutMembers : ChannelsSieveTypes
        }
        sliderNumbersObject={sliderNumbersObject}
        showOnlyFavorite={showOnlyFavorite}
        setShowOnlyFavorite={setShowOnlyFavorite}
        changeCardView={changeCardView}
        setShowHidden={setShowHidden}
        showHidden={showHidden}
      />
      <InfiniteScroll
        pageStart={0}
        loadMore={next}
        useWindow={false}
        hasMore={hasMore}
        loader={<div key={0}>{t('loadingT')}</div>}
        getScrollParent={() => scroller.current}
      >
        <ChannelsContentSelector
          activeSieveOption={localSieveActive}
          sortedPages={Object.values(contentData)}
          isCardsView={
            currentPage.isCardView
            && localSieveActive !== ChannelsSieveTypes.ChannelsMembers
          }
          isSimplifiedMultiselect={false}
          setSimplifiedMultiselect={() => {}}
          counterSelectedPage={0}
          itemsCounter={displayedItems.length}
          needToDelete={() => {}}
          selectorType={selectorType2}
          setSort={setSort}
          sortType={previousSortType}
          isNoItems={!filteredItems.length}
          isFilter={!!filter || !!userRoleFilter || !!userStatusFilter}
          // isFavoriteEmpty={isFavoriteEmpty}
          filter={filter}
          totalFavorites={totalFavorites}
          displayedPages={displayedItems}
          refSelectedBlocks={refSelectedBlocks}
          filteredPages={Object.values(contentData)}
          folderId={folderId}
          loading={loading}
          isChannel
          channelId={folderId}
          dropSelection={() => {}}
          tableFilter={tableFilter}
          scroller={scroller}
          channel={myChannels[folderId]}
          permissions={myChannels[folderId]?.permissions}
        />
      </InfiniteScroll>
    </Wrapper>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  height: calc(100vh - 124px);
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 12px;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f6ff;
  }

  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 8px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track-piece {
    display: none;
    background-color: #f5f6ff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ${({ isCardView }) => (isCardView ? `
  padding: 0px 24px 20px 26px;

  @media (min-width: 800px) {
    padding: 0px 22px 20px 28px;
  }

  @media (min-width: 1000px) {
    padding: 0px 24px 20px 34px;
  }

  @media (min-width: 1566.1px) {
    padding: 0px 24px 20px 34px;
  }
  ` : 'padding: 0px 17px 20px 16px;')}

  ${({ isSideBarOpened }) => (isSideBarOpened
    ? `
  @media (max-width: 1730px) {
      padding: 0px 31px 20px 3px;
  }
  `
    : '')}

  ${({ isMembers }) => (isMembers ? 'padding-left: 32px;' : '')}
`;

const ChannelsContentSelector = (props) => {
  const { t } = useTranslation();
  switch (props.activeSieveOption) {
    case ChannelsSieveTypes.ChannelsAll: {
      return (
        <ContentWrapper
          isCardView={props.isCardsView}
          ref={props.scroller}
          activeOpt={props.activeSieveOption}
          isHeaderBig={props.isNoItems}
        >
          <PlaylistContentSelector
            {...props}
            isCardView={props.isCardsView}
            tableSchema={ChannelPagesSchema}
            isChannelMiniPlaylist
            isChannel="playlists"
          />
        </ContentWrapper>
      );
    }
    case ChannelsSieveTypes.ChannelsFavorite: {
      return (
        <ContentWrapper
          isCardView={props.isCardsView}
          ref={props.scroller}
          activeOpt={props.activeSieveOption}
          isHeaderBig={props.isNoItems}
        >
          <PlaylistContentSelector
            {...props}
            isCardView={props.isCardsView}
            tableSchema={ChannelPagesSchema}
            isChannelMiniPlaylist
            isChannel="playlists"
          />
        </ContentWrapper>
      );
    }
    case ChannelsSieveTypes.ChannelsMembers: {
      return (
        <ContentWrapper
          isCardView={props.isCardsView}
          ref={props.scroller}
          onScroll={props.getMany}
          activeOpt={props.activeSieveOption}
          isHeaderBig={props.isNoItems}
          isMembers
        >
          <ChannelsMembersContent
            {...props}
            isCardView={props.isCardsView}
            isChannel="users"
          />
        </ContentWrapper>
      );
    }
    case ChannelsSieveTypes.Info: {
      return (
        <ContentWrapper
          isCardView={props.isCardsView}
          ref={props.scroller}
          onScroll={props.getMany}
          activeOpt={props.activeSieveOption}
          isHeaderBig={!props.isNoItems}
        >
          <AboutContent
            channel={props.channel}
            permissions={props.permissions}
          />
        </ContentWrapper>
      );
    }
    default: {
      return <div>{t('defaultT')}</div>;
    }
  }
};

export default Channels;
