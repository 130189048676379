import React from 'react';
import { MessageType } from '../../utils/constants';
import { CHANNEL_PERMISSIONS, UserRole } from '../../utils/permissions';
import InformerSpinner from './InformerSpinner';
import i18n from '../../i18n';

const trimLongName = (name) => (name.length > 25 ? `${name.slice(0, 25)}...` : name);

export const calculateMessage = (messageData) => {
  const itemName = (!!messageData.itemName && trimLongName(messageData.itemName)) || '';
  const placeName = (!!messageData.placeName && trimLongName(messageData.placeName)) || '';
  switch (messageData.type) {
    case MessageType.ErrorRegular:
    case MessageType.Refresh:
    case MessageType.NeutralRegular:
    case MessageType.Regular: {
      return <span>{messageData.text}</span>;
    }

    case MessageType.FolderToTrash: {
      return (
        <span>{messageData.counter
          ? i18n.t('foldersMovedToTrashT', { counter: messageData.counter })
          : i18n.t('itemMovedToTrashT', { itemName })}
        </span>
      );
    }
    case MessageType.PageToTrash: {
      return (
        <span>{messageData.counter
          ? i18n.t('pagesMovedToTrashT', { counter: messageData.counter })
          : i18n.t('itemMovedToTrashT', { itemName })}
        </span>
      );
    }
    case MessageType.PlaylistNothingToDownload: {
      return (
        <span>{i18n.t('playlistNothingToDownloadT')}
        </span>
      );
    }
    case MessageType.ItemToCopy: {
      return (
        <span>{i18n.t('itemToCopyT', { itemName })}</span>
      );
    }
    case MessageType.WarnUser: {
      return (
        <span>{i18n.t('warnUserT', { warningText: messageData.warningText })}
        </span>
      );
    }
    case MessageType.DuplicateComponent: {
      return (
        <span>{i18n.t('duplicateComponentT', { itemName })}</span>
      );
    }
    case MessageType.PlaylistErrorUpload: {
      return <span>{messageData.msg} </span>;
    }
    case MessageType.LinkPageRearrangeIsLocked: {
      return (
        <span>
          {i18n.t('linkPageRearrangeIsLockedT')}
        </span>
      );
    }
    case MessageType.PageToMovedInFolder: {
      return (
        <span>
          {messageData.counter
            ? i18n.t('pagesMovedToPlaceT', { counter: messageData.counter, placeName })
            : i18n.t('itemMovedToPlaceT', { itemName, placeName })
          }
        </span>
      );
    }
    case MessageType.ComponentMovedInCollection: {
      return (
        <span>
          {messageData.counter
            ? i18n.t('pagesMovedToPlaceT', { counter: messageData.counter, placeName })
            : i18n.t('itemMovedToPlaceT', { itemName: messageData.msg, placeName })
          }
        </span>
      );
    }
    case MessageType.PageToPlaylist: {
      return (
        <span>
          {messageData.counter
            ? i18n.t('pagesMovedToPlaceT', { counter: messageData.counter, placeName })
            : i18n.t('itemMovedToPlaceT', { itemName, placeName })
          }
        </span>
      );
    }
    case MessageType.PageToFavorite: {
      return (
        <span>{messageData.counter
          ? i18n.t('numberOfPagesAddedToFavoritesT', { counter: messageData.counter })
          : i18n.t('itemAddedToFavoritesT', { itemName })
          }
        </span>
      );
    }
    case MessageType.PlaylistToFavorite: {
      return (
        <span>{messageData.counter
          ? i18n.t('numberOfSmartFileAddedToFavoritesT', { counter: messageData.counter })
          : i18n.t('itemAddedToFavoritesT', { itemName })
          }
        </span>
      );
    }
    case MessageType.LibraryComponentToFavorite: {
      return (
        <span>{messageData.counter
          ? i18n.t('numberOfComponentsAddedToFavoritesT', { counter: messageData.counter })
          : i18n.t('itemAddedToFavoritesT', { itemName })
          }
        </span>
      );
    }
    case MessageType.CollectionToTrash: {
      return (
        <span>{messageData.counter
          ? i18n.t('numberOfCollectionsMovedToTrashT', { counter: messageData.counter })
          : i18n.t('itemMovedToTrashT', { itemName })
          }
        </span>
      );
    }
    case MessageType.ComponentToTrash: {
      return (
        <span>
          {messageData.counter && messageData.counter != 1
            ? i18n.t('numberOfItemsMovedToTrashT', { counter: messageData.counter, itemType: messageData.itemType })
            : i18n.t('singleItemMovedToTrashT', { nameOfItem: itemName || messageData || i18n.t('itemLowT') })
          }
        </span>
      );
    }
    case MessageType.PlaylistToTrash: {
      return (
        <span>{messageData.counter && messageData.counter > 1
          ? i18n.t('smartFilesMovedToTrashT', { counter: messageData.counter })
          : i18n.t('smartFileMovedToTrashT', { itemName })
          }
        </span>
      );
    }
    case MessageType.CantEdit: {
      return <span>{i18n.t('cantEditT')}</span>;
    }
    case MessageType.PageAddedToPlaylist: {
      return (
        <span>{i18n.t('pageAddedToPlaylistT', { playlistTitle: messageData.playlistTitle })}
        </span>
      );
    }
    case MessageType.AddToMyStudio: {
      return (
        <span>
          {itemName.length > 20
            ? i18n.t('longItemAddedToLibraryT', { itemName: messageData.itemName })
            : i18n.t('shortItemAddedToLibraryT', { itemName: messageData.itemName })
          }
        </span>
      );
    }
    case MessageType.FillInPlaylistTitle: {
      const { count, categoryEmpty, titleEmpty, channelsEmpty } = messageData;
      if (count >= 2) {
        return (
          <span>
            {' '}
            {i18n.t('fillInTitleOption1T')}
          </span>
        );
      }
      if (titleEmpty && !categoryEmpty && !channelsEmpty) {
        return (
          <span>
            {' '}
            {i18n.t('fillInTitleOption2T')}
          </span>
        );
      }
      if (!titleEmpty && categoryEmpty && !channelsEmpty) {
        return (
          <span> {i18n.t('fillInTitleOption3T')} </span>
        );
      }
      if (!titleEmpty && !categoryEmpty && channelsEmpty) {
        return <span> {i18n.t('fillInTitleOption4T')} </span>;
      }
      return (
        <span>
          {' '}
          {i18n.t('fillInTitleOption5T')}
        </span>
      );
    }
    case MessageType.MultipleShareWarning: {
      const itemType = messageData.itemType;
      const message = messageData.message;
      return (
        <span>{i18n.t('multipleShareWarningT', { itemType, message })}
        </span>
      );
    }
    case MessageType.MultipleShareError: {
      const itemType = messageData.itemType;
      const message = messageData.message;
      return (
        <span>{i18n.t('multipleShareErrorT', { itemType, message })}
        </span>
      );
    }
    case MessageType.MultipleShareSuccess: {
      const itemType = messageData.itemType;
      const message = messageData.message;
      const isSingle = messageData.isSingle;
      return (
        <span>{isSingle
          ? message.replace('Pages', 'SmartPage').replace('SmartFiles', 'SmartFile')
          : i18n.t('multipleSharingSuccessT', { itemType, message })
          }
        </span>
      );
    }
    case MessageType.CannotBeUploaded: {
      return (
        <span>{i18n.t('cannotBeUploadedT')}</span>
      );
    }
    case MessageType.NoAIFiles: {
      return (
        <span>{i18n.t('noAIFiles')}</span>
      );
    }
    case MessageType.PromptInProgress: {
      return (
        <span>{i18n.t('Custom prompt is in progress')}</span>
      );
    }
    case MessageType.PlaylistIsEmpty: {
      return (
        <span>{i18n.t('playlistIsEmptyT')}</span>
      );
    }
    case MessageType.MediaIsNotReady: {
      return (
        <span>{i18n.t('mediaIsNotReadyT')}</span>
      );
    }
    case MessageType.UploadCanceled: {
      if (messageData.counter) {
        return (
          <span>{messageData.counter > 1
            ? i18n.t('multipleUploadsWereCancelledT', { counter: messageData.counter })
            : i18n.t('singleUploadWasCancelledT', { counter: messageData.counter })}
          </span>
        );
      }
      return (
        <span>{i18n.t('itemWasCancelledT', { itemName: messageData.itemName })}</span>
      );
    }
    case MessageType.SuccessfullyUploaded: {
      return (
        <span>{i18n.t('addedToLibraryT', { beginning: messageData.counter || itemName })}</span>
      );
    }
    case MessageType.FolderSuccessfullyAdded: {
      return (
        <span>{i18n.t('folderAddedT', { itemName })}</span>
      );
    }
    case MessageType.PageAddedToChapter: {
      return (
        <span>{i18n.t('pageAddedToChapterT', {
          counter: messageData.counter,
          placeName,
        })}
        </span>
      );
    }
    case MessageType.PageRemovedFromChapter: {
      return (
        <span>
          {i18n.t('pageRemovedFromChapterT', {
            counter: messageData.counter,
            placeName,
          })}
        </span>
      );
    }
    case MessageType.NotEditableNotification: {
      return (
        <span>{i18n.t('notEditableNotificationT', { place: messageData.place })}</span>
      );
    }
    case MessageType.PlaylistUnpublished: {
      return (
        <span>{i18n.t('playlistUnpublishedT')}</span>
      );
    }
    case MessageType.PlaylistPublished: {
      return (
        <span>{messageData.itemName
          ? i18n.t('itemSmartFileSharedT')
          : i18n.t('smartFileSharedT')
          }
        </span>
      );
    }
    case MessageType.RemoveAccess: {
      return (
        <span>{i18n.t('removeAccessT')}</span>
      );
    }
    case MessageType.PageUnpublished: {
      return (
        <span>{i18n.t('pageUnpublishedT')}</span>
      );
    }
    case MessageType.PlaylistCreated: {
      return (
        <span>{i18n.t('playlistCreatedT')}</span>
      );
    }
    case MessageType.PageIsNotPublished: {
      return (
        <span>{i18n.t('pageIsNotPublishedT')}</span>
      );
    }
    case MessageType.NoLinkPagesForPlayer: {
      return (
        <span>{i18n.t('noLinkPagesForPlayerT')}
        </span>
      );
    }
    case MessageType.AccessDenied: {
      return <span>{i18n.t('accessDeniedT')}</span>;
    }
    case MessageType.InvitationIsExpired: {
      return (
        <span>{i18n.t('invitationIsExpiredT')}</span>
      );
    }
    case MessageType.PagePublished: {
      return <span>{i18n.t('pagePublishedT')}</span>;
    }
    case MessageType.CopyUrl: {
      return (
        <span>
          {' '}
          {messageData.isUrl
            ? i18n.t('publicLinkCopiedT')
            : i18n.t('embedLinkCopiedT')
          }
        </span>
      );
    }
    case MessageType.DeleteTag: {
      return <span>{i18n.t('deleteTagMessageT', { tagName: messageData.tagName })}</span>;
    }
    case MessageType.DeleteChannel: {
      return (
        <span>
          {i18n.t('deleteChannelMessageT', { title: messageData.title || i18n.t('defaultChannelTitleT') })}
        </span>
      );
    }
    case MessageType.ChannelSetPrivate: {
      return (
        <span>
          {i18n.t('channelSetPrivateT', { title: messageData.title || i18n.t('defaultChannelTitleT') })}
        </span>
      );
    }
    case MessageType.ChannelSetPublic: {
      return (
        <span>
          {i18n.t('channelSetPublicT', { title: messageData.title || i18n.t('defaultChannelTitleT') })}
        </span>
      );
    }
    case MessageType.InviteUserToChannel: {
      return (
        <span>{i18n.t('inviteUserToChannelT')}</span>
      );
    }
    case MessageType.InviteUserToSharedPage: {
      return (
        <span>{i18n.t('inviteUserToSharedPageT')}</span>
      );
    }
    case MessageType.ChangeChannelSubscriberStatus: {
      return (
        <span>{i18n.t('changeChannelSubscriberStatusT', messageData)}
        </span>
      );
    }
    case MessageType.DeleteSubscriber: {
      return (
        <span>{i18n.t('deleteSubscriberT')}</span>
      );
    }
    case MessageType.CancelInvSubscriber: {
      return (
        <span>{i18n.t('cancelInvSubscriberT')}</span>
      );
    }
    case MessageType.Invite_NeedToLogin: {
      return (
        <span>{i18n.t('inviteNeedToLoginT')}</span>
      );
    }
    case MessageType.ChangeEmail_NeedToLogin: {
      return (
        <span>{i18n.t('changeEmailNeedToLoginT')}</span>
      );
    }
    case MessageType.ChangeEmail_TokenNotValid: {
      return (
        <span>{i18n.t('changeEmailTokenNotValidT')}</span>
      );
    }
    case MessageType.UserChangedPlan: {
      return (
        <span>
          {i18n.t('userChangedPlanT', { newPlan: messageData.newPlan })}
        </span>
      );
    }

    case MessageType.Invite_NeedToRegister: {
      return (
        <span>{i18n.t('inviteNeedToRegisterT')}</span>
      );
    }

    case MessageType.ForgotPassword_Success: {
      return (
        <span>{i18n.t('forgotPasswordSuccessT', { email: messageData.email })}</span>
      );
    }
    case MessageType.ForgotPassword_Error: {
      return <span>{messageData.notice}</span>;
    }

    case MessageType.ResetPassword_Success: {
      return (
        <span>{i18n.t('resetPasswordSuccessT')}</span>
      );
    }
    case MessageType.ResetPassword_Error: {
      return <span>{messageData.notice}</span>;
    }
    case MessageType.EditorPastedTextError: {
      return (
        <span>{i18n.t('editorPastedTextErrorT', { lengthLimit: messageData.lengthLimit })}</span>
      );
    }
    case MessageType.AddContact: {
      return <span>{i18n.t('addContactT')}</span>;
    }
    case MessageType.RemoveContact: {
      return <span>{i18n.t('removeContactT', { contactName: messageData.contactName })}</span>;
    }
    case MessageType.PlaylistNotAvailableAnyMore: {
      return (
        <span>{i18n.t('playlistNotAvailableAnyMoreT')}</span>
      );
    }
    case MessageType.PageNotAvailableAnyMore: {
      return (
        <span>{i18n.t('pageNotAvailableAnyMoreT')}
        </span>
      );
    }
    case MessageType.ColorDuplication: {
      return (
        <span>{i18n.t('colorDuplicationT', { color: messageData.color })}</span>
      );
    }
    case MessageType.ChangeContactStatus: {
      return (
        <span>{i18n.t('changeContactStatusT', { status: messageData.status })}</span>
      );
    }
    case MessageType.UpdateContact: {
      return <span>{i18n.t('updateContactT')}</span>;
    }
    case MessageType.CreateContact: {
      return <span>{i18n.t('createContactT')}</span>;
    }
    case MessageType.ContactAlreadyExists: {
      return (
        <span>{i18n.t('contactAlreadyExistsT', { email: messageData.email })}</span>
      );
    }
    case MessageType.RoleNotChanged: {
      return (
        <span>{messageData.customMessage ?? i18n.t('roleNotChangedT')}
        </span>
      );
    }
    case MessageType.PlaylistCreationError: {
      return (
        <span>{i18n.t('playlistCreationErrorT')}</span>
      );
    }
    case MessageType.SettingsError: {
      return (
        <span>
          {messageData.message}: {messageData.errors.join(',')}
        </span>
      );
    }
    case MessageType.EmailConfirmed: {
      return (
        <span>{i18n.t('emailConfirmedT')}</span>
      );
    }
    case MessageType.NothingToSave: {
      return <span>{i18n.t('nothingToSaveT')}</span>;
    }
    case MessageType.EmojiRemoved: {
      return (
        <span>{i18n.t('emojiRemovedT', { itemName: messageData.itemName })}</span>
      );
    }
    case MessageType.AvatarDeleted: {
      return <span>{i18n.t('avatarDeletedT')}</span>;
    }
    case MessageType.LeaveChannel: {
      return (
        <span>{i18n.t('leaveChannelMessageT', { name: messageData.channelName })}
        </span>
      );
    }
    case MessageType.AccountIsAlreadyLinked: {
      return (
        <span>{i18n.t('accountIsAlreadyLinkedT', { itemName: messageData.itemName })}</span>
      );
    }
    case MessageType.AccountHasBeenUnlinked: {
      return (
        <span>{i18n.t('accountHasBeenUnlinkedT', { itemName: messageData.itemName })}</span>
      );
    }
    case MessageType.EnterComponentEditMode: {
      return (
        <span>{i18n.t('enterComponentEditModeT')}</span>
      );
    }
    case MessageType.TagAddedToItem: {
      return (
        <span>
          {messageData.itemsCounter > 1
            ? i18n.t('tagAddedToMultipleItemsT', {
              tagTitle: messageData.tagTitle, itemsCounter: messageData.itemsCounter,
            })
            : i18n.t('tagAddedToSingleItemT', {
              tagTitle: messageData.tagTitle, itemName: messageData.itemName,
            })
          }
        </span>
      );
    }
    case MessageType.TagsAddedToItem: {
      return (
        <span>
          {messageData.itemsCounter > 1
            ? i18n.t('tagsUpdatedForItemsT', { itemsCounter: messageData.itemsCounter })
            : i18n.t('tagsUpdatedForSingleItemT', { itemName })
          }
        </span>
      );
    }
    case MessageType.ForbiddenStatusChange: {
      const userRole = UserRole[messageData.userRole];
      const rolesList = Object.keys(
        CHANNEL_PERMISSIONS[messageData.userRole].canChangeRolesTo,
      )
        .map((item) => `"${UserRole[item]}"`)
        .join(', ');
      return (
        <span>{i18n.t('ForbiddenStatusChange', { userRole, rolesList })}</span>
      );
    }
    case MessageType.PlaylistRenamed:
      return (
        <span>{i18n.t('playlistRenamedT')}</span>
      );
    case MessageType.ItemRenamed:
      return <span>{i18n.t('itemRenamedT')}</span>;

    case MessageType.TagAdded:
      return <span>{i18n.t('tagAddedT')}</span>;

    case MessageType.TagUpdated:
      return <span>{i18n.t('tagUpdatedT')}</span>;
    case MessageType.MarkAsRead:
      return <span>{i18n.t('markAsReadMessageT')}</span>;
    case MessageType.DownloadWholePlaylist:
      return (
        <>
          <InformerSpinner />
          <span>
            {i18n.t('downloadWholePlaylistT')}
          </span>
        </>
      );
    case MessageType.DuplicatePlaylist:
      return (
        <>
          <InformerSpinner />
          <span>
            {i18n.t('duplicatePlaylistT')}
          </span>
        </>
      );
    case MessageType.DownloadOneItem:
      return (
        <>
          <InformerSpinner />
          <span>{i18n.t('downloadOneItemT')}</span>
        </>
      );
    case MessageType.UserAlreadyRegistered:
      return (
        <span>{i18n.t('userAlreadyRegisteredT')}</span>
      );
    case MessageType.NewSmartView:
      return <span>{i18n.t('newSmartViewT')}</span>;
    case MessageType.ChangesSavedOK:
      return (
        <span>{i18n.t('changesSavedOKT')}</span>
      );
    case MessageType.TagHasBeenCreated:
      return (
        <span>{i18n.t('tagHasBeenCreatedT')}</span>
      );
    case MessageType.DeleteSmartview:
      return (
        <span>{i18n.t('deleteSmartviewT', { itemName: messageData.itemName })}</span>
      );
    case MessageType.AddContentFirst:
      return (
        <span>{i18n.t('addContentFirstT')}</span>
      );
    case MessageType.InvalidLink: {
      return <span>{i18n.t('invalidLinkT')}</span>;
    }
    case MessageType.LinkCreated: {
      return <span>{i18n.t('linkCreatedT')}</span>;
    }
    case MessageType.HotkeyPressed: {
      return <span>{i18n.t('hotkeyPressedT')}</span>;
    }
    case MessageType.userIsBlocked: {
      return <span>{i18n.t('userIsBlockedT')}</span>;
    }
    case MessageType.recentlyBlocked: {
      return (
        <span>{i18n.t('recentlyBlockedT')}</span>
      );
    }
    case MessageType.parseError: {
      return (
        <span>{i18n.t('parseErrorT')}</span>
      );
    }
    case MessageType.fileNotSupported: {
      return (
        <span>{i18n.t('fileNotSupportedT')}</span>
      );
    }
    case MessageType.SuccessfullyUpdated: {
      return (
        <span>{i18n.t('successfullyUpdatedT')}
        </span>
      );
    }
    case MessageType.viewersProgressReset: {
      return (
        <span>{i18n.t('viewersProgressResetT')}
        </span>
      );
    }
    case MessageType.sharedEmailSent: {
      return (
        <span>{i18n.t('sharedEmailSentT')}
        </span>
      );
    }
    case MessageType.sharedToContact: {
      return (
        <span>{messageData.sfCount > 1
          ? i18n.t('smartFilesSharedToT', { sfCount: messageData.sfCount, sharedTo: messageData.sharedTo })
          : i18n.t('smartFileSharedToT', { sfCount: messageData.sfCount, sharedTo: messageData.sharedTo })
          }
        </span>
      );
    }
    case MessageType.sharedToContactError: {
      return (
        <span>{i18n.t('sharedToContactErrorT')}
        </span>
      );
    }
    case MessageType.alreadySharedToContact: {
      return (
        <span>{messageData.isMultiple
          ? i18n.t('alreadySharedToContactMultipleT')
          : i18n.t('alreadySharedToContactSingleT')
          }
        </span>
      );
    }
    case MessageType.SuccessfullyUpdatedBoardColumnHeader: {
      return (
        <span>{i18n.t('successfullyUpdatedBoardColumnHeaderT')}
        </span>
      );
    }
    case MessageType.SuccessfullyDeletedBoardColumn: {
      return (
        <span>{i18n.t('successfullyDeletedBoardColumnT')}
        </span>
      );
    }

    case MessageType.SuccessfullyDeletedInboxCard: {
      return (
        <span>{i18n.t('successfullyDeletedInboxCardT')}
        </span>
      );
    }
    case MessageType.SuccessfullyDeletedInboxCards: {
      return (
        <span>{i18n.t('successfullyDeletedInboxCardsT')}
        </span>
      );
    }
    case MessageType.ReachedSmartFilesLimitForPlan: {
      return (
        <span>{i18n.t('reachedSmartFilesLimitForPlanT')}
        </span>
      );
    }
    case MessageType.ReachedUploadsLimitForPlan: {
      return (
        <span>{i18n.t('reachedUploadsLimitForPlanT')}
        </span>
      );
    }
    default:
      return <span>{i18n.t('unknownErrorT')}</span>;
  }
};
