import { UnifiedHistory } from './types';

const defaultState = {
  trace: [],
  activeIndex: -1,
  somePayload: {},
};

export default (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case UnifiedHistory.NativeHistoryAdd: {
      // case  after back
      const newTrace = state.trace.slice(0, state.activeIndex + 1);
      if (newTrace >= 50) {
        return {
          ...state,
          trace: [...newTrace, payload],
        };
      }

      return {
        ...state,
        trace: [...newTrace, payload],
        activeIndex: newTrace.length,
      };
    }
    case UnifiedHistory.ClearHistory: {
      return {
        ...state,
        trace: [...state.trace.slice(-1)],
        activeIndex: 0,
      };
    }
    case UnifiedHistory.SyntheticGoBack: {
      if (state.activeIndex > 0) {
        return {
          ...state,
          activeIndex: state.activeIndex - 1,
        };
      }
      return state;
    }

    case UnifiedHistory.SyntheticGoForward: {
      if (state.activeIndex < state.trace.length) {
        return {
          ...state,
          activeIndex: state.activeIndex + 1,
        };
      }
      return state;
    }

    case UnifiedHistory.AddPayload: {
      return {
        ...state,
        somePayload: { ...state.somePayload, ...payload },
      };
    }

    case UnifiedHistory.ClearPayload: {
      return {
        ...state,
        somePayload: {},
      };
    }
    default: {
      return state;
    }
  }
};
