import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { svgDynamicCollection } from '../../utils/constants';
import moduleStyles from '../../pages/Library/LibraryHeader.module.scss';
import dynamicCollectionStyles from './index.module.scss';
import { actionChangeDynamicCollection } from '../../redux/dynamicCollection/action';
import ColorPalette from '../ColorPicker/ColorPalette';

const styles = classNames.bind(moduleStyles);
const dynColCN = classNames.bind(dynamicCollectionStyles); // dynamic collection class names

const EditDynamicCollectionIcon = (props) => {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.dynamicCollection[props.itemId]);
  const [showColors, setShowColors] = useState(false);
  const [showIcon, setShowIcon] = useState(true);
  const [selectedColor, setSelectedColor] = useState(item?.color);
  const [selectedIcon, setSelectedIcon] = useState(item?.iconKey);
  const [activeOption, setActiveOption] = useState('icon');
  const refColorPickerParent = useRef(null);

  useEffect(() => {
    if (activeOption === 'color') {
      setShowIcon(false);
      setShowColors(true);
    }
    if (activeOption === 'icon') {
      setShowIcon(true);
      setShowColors(false);
    }
  }, [activeOption]);

  useEffect(() => {
    let isChanged = false;
    const tmpItem = { ...item };
    if (selectedIcon !== item?.iconKey) {
      isChanged = true;
      tmpItem.iconKey = selectedIcon;
    }
    if (selectedColor !== item?.color) {
      isChanged = true;
      tmpItem.color = selectedColor;
    }
    if (isChanged) dispatch(actionChangeDynamicCollection(tmpItem));
  }, [selectedColor, selectedIcon]);

  const changeItemColor = (event, color) => {
    setSelectedColor(color.slice(1));
  };

  return (
    <div className={styles('header_edit_icon')} ref={props.editRef}>
      <div className={dynColCN('slide_option_wrapper')}>
        <div
          onClick={() => {
            setActiveOption('icon');
          }}
          className={dynColCN('slide_option', { active: showIcon })}
        >
          icon
        </div>
        <div
          ref={refColorPickerParent}
          onClick={() => {
            setActiveOption('color');
          }}
          className={dynColCN('slide_option', { active: showColors })}
        >
          color
        </div>
      </div>

      <div className={dynColCN('slide_separator')}>
        <div className={dynColCN('slider')} data-option={activeOption} />
      </div>

      {showColors && (
        <ColorPalette
          isPositionRelative
          selectedColor={item.color}
          selectedBackgroundColor={item.backgroundColor}
          refColorPickerParent={refColorPickerParent}
          onChangeColor={changeItemColor}
          isPaletteActive
          defaultPosition={{ top: 0, left: 0 }}
          buttonWidth={0}
        />
      )}
      {showIcon && (
        <div className={dynColCN('edit_collection_options')}>
          {Object.keys(svgDynamicCollection).map((svgKey) => (
            <div
              key={svgKey}
              onClick={() => setSelectedIcon(svgKey)}
              className={dynColCN('edit_collection_item', {
                active: svgKey === selectedIcon,
              })}
            >
              {svgDynamicCollection[svgKey]()}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EditDynamicCollectionIcon;
