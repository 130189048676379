import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import { actionCreator } from '../../shared/redux/actionHelper';
import { Settings } from '../../redux/settings/types';
import UserAvatarCropper from '../ImageCroppers/UserAvatarCropper';

const cx = classNames.bind(styles);

const CropUserAvatar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    inputs: { avatar },
  } = useSelector((state) => state.settings);
  const close = () => {
    dispatch(actionCloseModal());
  };
  const tmpImageBlob = avatar.tmpImageBlob;
  const save = async (crop) => {
    const response = await fetch(tmpImageBlob);
    const imageBlob = await response.blob();
    dispatch(actionCreator(Settings.Profile.SaveAvatarS, { imageBlob, crop }));
    dispatch(actionCloseModal());
  };

  useEffect(() => {
    return () => {
      dispatch(
        actionCreator(Settings.Profile.DropTmpImageData, {
          inputType: 'avatar',
        }),
      );
    };
  }, []);

  return (
    <div onMouseDown={close} className={cx('modal_wrapper')}>
      <div
        onMouseDown={(e) => e.stopPropagation()}
        className={cx('modal', 'CropUserAvatar')}
      >
        <div className={cx('title')}>{t('cropAndUploadPictureT')}</div>

        <div className={cx('cropper_container')}>
          <UserAvatarCropper save={save} tmpImageBlob={tmpImageBlob} />
        </div>
      </div>
    </div>
  );
};

export default CropUserAvatar;
