import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import CloseRoundedBtnWithCross from '../../../../../components/SharedComponents/CloseRoundedBtnWithCross';

const UPVSettingsHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #343434;
  padding-left: 30px;
  margin-bottom: 35px;
`;

const Title = styled.div`
  font-family: "RobotoFlex", sans-serif;
`;

const Header = ({ close, dataParent }) => {
  const { t } = useTranslation();
  return (
    <UPVSettingsHeaderWrapper>
      <Title data-parent={dataParent}>{t('sharingOptionsT')}</Title>
      <CloseRoundedBtnWithCross top={-7} right={30} onClick={close} />
    </UPVSettingsHeaderWrapper>
  );
};

export default Header;
