import React, { useState, useEffect } from 'react';
import classnames from 'classnames/bind';
import rawStyles from './toggler.module.scss';

const styles = classnames.bind(rawStyles);

const Toggler = ({
  isDisable,
  text,
  callback,
  isActive,
  togglerWidth = 70,
  togglerHeight = 33,
  top = 'initial',
  left,
  isDarkMode = false,
  isAppSettings = false,
  ...props
}) => {
  const [knobStyle, setKnobStyle] = useState({
    width: togglerHeight + 2,
    height: togglerHeight + 2,
  });
  useEffect(() => {
    if (!isActive) setKnobStyle({ ...knobStyle, left: -1 });
    else setKnobStyle({ ...knobStyle, left: togglerWidth - togglerHeight - 1 });
  }, [isActive]);
  return (
    <div
      style={{ top, left }}
      onClick={() => callback(!isActive)}
      className={styles('top_wrapper', { relative: props.relative })}
      data-parent={props.dataParent}
    >
      <div
        className={styles(
          'container',
          { isDisable },
          { active: isActive },
          { darkMode: isDarkMode },
          { isAppSettings },
        )}
        style={{
          width: togglerWidth + 2,
          height: togglerHeight + 2,
          borderRadius: togglerHeight + 2,
        }}
        data-parent={props.dataParent}
      >
        <div
          className={styles('knob', {
            knob_left: !isActive,
            knob_right: isActive,
          })}
          style={knobStyle}
        />
      </div>
      {!!text && !props.children && (
        <div className={styles('label')} data-parent={props.dataParent}>
          <div className={styles('text')}>{text}</div>
        </div>
      )}
      {!text && props.children && <>{props.children}</>}
    </div>
  );
};

export default Toggler;
