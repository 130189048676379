const helpWithTitle = (title) => {
  const exTitle = title.split(']')[1];
  const lastSymbol = exTitle.charAt(exTitle.length - 1);
  if (lastSymbol === 's') {
    return exTitle.slice(0, exTitle.length - 1);
  }
  return exTitle;
};

export const getNameForTagsBTN = (tags) => {
  if (!tags) {
    return 'TAGS';
  }
  const tagsArr = [];
  Object.keys(tags).forEach((key) => tagsArr.push(tags[key].title));
  return tagsArr.join(', ') || 'TAGS';
};

export const getNameForTypeBTN = (types) => {
  if (!types) {
    return 'FILE TYPE';
  }
  const typeArr = [];
  Object.keys(types).forEach((key) => typeArr.push(types[key]));
  return typeArr.join(', ') || 'FILE TYPE';
};
export const calcOption = (sieveTypes) => {
  const option = {};
  Object.values(sieveTypes || {}).forEach(
    (i) => (option[i] = helpWithTitle(i)),
  );
  return option;
};
