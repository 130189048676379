import styled from 'styled-components/macro';

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 36px;
`;

export const MainNavSliderShell = styled.div`
  display: flex;
  padding: 19px 0 4px 4px;
  position: relative;
  z-index: 113;
  max-width: 1482px;
  margin: 0 auto;

  @media (max-width: 899.9px) {
    padding-right: 16px;
  }

  @media (min-width: 900px) {
    padding-left: 0;
    max-width: 508px;
  }
  ${({ isSmartFiles }) => (isSmartFiles ? `
    @media (min-width: 1024px) {
      max-width: 690px;
    }
    @media (min-width: 1300px) {
      max-width: 871px;
    }
    @media (min-width: 1430px) {
      max-width: 1054px;
    }
    @media (min-width: 1600px) {
      max-width: 1240px;
    }
    @media (min-width: 1850px) {
      max-width: 1417px;
    }
    @media (min-width: 2050px) {
      max-width: 1600px;
    }
    ` : `
    @media (min-width: 991.8px) {
      max-width: 598px;
      padding-right: 4px;
    }
    @media (min-width: 1199.8px) {
      max-width: 810px;
    }

    @media (min-width: 1430px) {
      max-width: 1019px;
    }

    @media (min-width: 1600px) {
      max-width: 1230px;
      padding-right: 4px;
    }

    @media (min-width: 1920px) {
      max-width: 1438px;
    }
    `)}
`;

export const New = styled.div`
  background: #f5b400;
  border-radius: 12px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -5px;
  top: -9px;
  line-height: 20px;
  z-index: 100;
  font-family: RobotoFlex, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: rgb(255, 255, 255);
  padding: 4px 6px;
`;

export const Text = styled.div`
  position: relative;
  display: flex;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: ${(props) => (props.active ? '#484343' : '#585858')};
  background-color: ${(props) => (props.active ? '#FFC72C' : '')};
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  white-space: nowrap;
  font-family: RobotoFlex;
  font-size: 14px;
  letter-spacing: 0.05em;
  position: relative;

  &:hover {
    background-color: #ffeebf;
  }

  ${({ disabled }) => (disabled
    ? `
    color: lightgrey;
    &:hover {
      background-color: initial;
    }
  `
    : '')}
  ${({ isPassive }) => (isPassive
    ? `
  background:#FFEEBF`
    : '')}
`;

export const DynamicTextWrapper = styled.div`
  max-width: 107px;
  min-width: 36px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 50px;
`;

export const CloseSVGWrapper = styled.div`
  position: absolute;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  padding-top: 3px;
  padding-left: 3px;

  &:hover {
    background: #ffdf85;
  }

  svg {
    width: 18px;
    height: 18px;
  }
  ${({ top }) => (top
    ? `
    top:${top}px;`
    : `
    top: 6px;
  `)}
`;

export const ThreeDotsSVGWrapper = styled.div`
  position: absolute;
  right: 34px;
  width: 24px;
  height: 24px;
  top: 6px;
  border-radius: 4px;
  padding-top: 2px;
  border-radius: 4px;
  padding-left: 3px;

  svg {
    width: 17px;
  }

  &:hover {
    background: #ffdf85;
  }

  ${({ isActive }) => (isActive ? 'background:#FFC72C' : '')}
`;

export const DynamicCounterWrapper = styled.div`
  position: absolute;
  right: 34px;
  top: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  text-transform: uppercase;

  ${({ isActive }) => (isActive ? 'color: #484343;' : 'color: #939393;')}
`;
