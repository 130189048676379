import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import socketCluster from 'socketcluster-client';
import { SnackbarProvider } from 'notistack';
import { actionSaveUser } from './redux/user/action';
import { DEFAULT_PAGE_PATH, errorType, MessageType } from './utils/constants';
import ErrorBoundary from './components/ErrorBondary/ErrorBoundary';
import UseKeypressCallback from './utils/hooks/useKeypressCallback';
import Router from './components/Router';
import { requestErrorReport } from './utils/request';
import { actionCreator } from './shared/redux/actionHelper';
import { ServiceUser } from './redux/user/types';
import { BASEURL, COREAPPURL, SOCKET_PORT } from './environments/environments';
import { actionShowMessage } from './redux/support/action';
import SnackBar2023 from './components/Informer/SnackBar2023';
import { transformedDATA_PARENTS } from './utils/dataUtil';
import { setServiceWorkerData } from './utils/registerServiceWorker';
import { addTokenToIndexedDB } from './utils/indexedDB';


// cancel all Unloading blocks on history.back.forward actions
window.onpopstate = () => {
  window.onbeforeunload = null;
};
export const socket = socketCluster.create({
  hostname: COREAPPURL,
  port: SOCKET_PORT,
});

const App = () => {
  const dispatch = useDispatch();
  const {
    id: userId,
    goToLink,
    paramsGoToLink,
  } = useSelector((state) => state.user);
  const {
    id: playlistId,
    wrapperId,
    socketId,
  } = useSelector((state) => state.currentPage);
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const jwt = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
    const id = localStorage.getItem('userId') || sessionStorage.getItem('userId');
    localStorage.setItem('socketcluster.authToken', jwt);
    setServiceWorkerData(jwt);
    const token = jwt;
    addTokenToIndexedDB(token, BASEURL).then(() => {
      console.log('Token added to IndexedDB');
    });
    if (!userId) {
      dispatch(actionSaveUser({ id }));
      dispatch({ type: 'UPLOAD_DATA', payload: { userId: id, history } });
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      const faviconUpdate = async () => {
        const favicon = document.getElementById('favicon');
        favicon.href = 'icon_stage.svg';
      };
      faviconUpdate().then();
    }
  }, [userId]);

  useEffect(() => {
    if (goToLink) {
      if (paramsGoToLink?.isGoOut) {
        if (goToLink === '/playlists') {
          const { wrapperId: wrapperIdToOut, playlistId: playlistIdToOut } = paramsGoToLink;
          if (wrapperIdToOut === wrapperId || playlistId === playlistIdToOut) {
            dispatch(
              actionShowMessage({
                type: MessageType.RemoveAccess,
                itemName: 'playlist',
              }),
            );
            if (location.key) {
              history.push('/content/smartfiles/shared');
            } else history.push(DEFAULT_PAGE_PATH);
          }
        } else if (goToLink === '/pages') {
          const { pageId: closePageId } = paramsGoToLink;
          if (closePageId === socketId) {
            dispatch(
              actionShowMessage({
                type: MessageType.RemoveAccess,
                itemName: 'page',
              }),
            );
            if (location.key) {
              history.push('/content/pages/shared');
            } else history.push(DEFAULT_PAGE_PATH);
          }
        }
      } else if (goToLink === '/playlists') {
        if (location.key) {
          history.push('/content/smartfiles/shared');
        } else history.push(DEFAULT_PAGE_PATH);
      } else if (goToLink === '/pages') {
        if (location.key) {
          history.push('/content/pages/shared');
        } else history.push(DEFAULT_PAGE_PATH);
      } else history.push(goToLink);
      dispatch(
        actionCreator(ServiceUser.goToLink, {
          goToLink: '',
          paramsGoToLink: {},
        }),
      );
    }
  }, [goToLink]);

  return (
    <>
      <SnackBar2023 data-parent={transformedDATA_PARENTS} />
      <SnackbarProvider />
      <ErrorBoundary
        calback={(error, errorInfo) => {
          const pathname = history.location.pathname;

          requestErrorReport({
            'Error message': error,
            description: {
              type: errorType.UXError,
              ...error,
              path: window.location?.href,
              message: error.message,
              errorInfo,
              name: error.name,
              stack: error.stack,
              pathname,
            },
          });
          history.push(DEFAULT_PAGE_PATH);
        }}
      >
        <Router />
        <UseKeypressCallback />
      </ErrorBoundary>
    </>
  );
};

export default App;
