import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SmartViewMenuInLibrary from './SmartViewMenuInLibrary';
import TagMenuInLibrary from './TagMenuInLibrary';
import NewSmartViewOrEdit from './NewSmartView';
import CreateSmartViewPanel from '../SmartView/2023/CreateSmartViewPanel';

const SmartViewTagMenu = (props) => {
  const { place } = useParams();

  if (place === 'dynamicCollectionNew' || props.isEdit || props.exShowIt) {
    return <CreateSmartViewPanel {...props} />;
  }
  if (place === 'dynamicCollection') {
    return <SmartViewMenuInLibrary {...props} />;
  }
  if (place === 'tag') {
    return <TagMenuInLibrary />;
  }

  return <></>;
};

export default SmartViewTagMenu;
