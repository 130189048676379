import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CheckBoxOn } from '../../../../images/icons/checkbox_on.svg';
import { ReactComponent as CheckBoxOff } from '../../../../images/icons/checkbox_off.svg';
import {
  actionRemoveSelectedPage,
  actionSelectedPage,
} from '../../../../redux/selectedPage/action';
import { empty } from '../../../../utils/constants';
import { CheckBoxWrapper } from './styled';

const TableCheckBoxComponent = ({ itemID, ...props }) => {
  const selectedPage = useSelector((state) => state.selectedPage) || empty;
  const [isSelected, setIsSelected] = useState();
  const dispatch = useDispatch();
  const { folderId } = useSelector((state) => state.user) || empty;

  const toggleSelected = (e) => {
    e.stopPropagation();
    if (isSelected) dispatch(actionRemoveSelectedPage(itemID, folderId));
    else {
      if (!props.isSimplifiedMultiselect) {
        props.setSimplifiedMultiselect(true);
      }
      dispatch(actionSelectedPage(itemID, folderId));
    }
  };
  useEffect(() => {
    const isSelected = folderId
      ? selectedPage[folderId] && selectedPage[folderId][itemID]
      : selectedPage[itemID];
    setIsSelected(isSelected);
  }, [selectedPage, itemID]);

  return (
    <CheckBoxWrapper isSelected={isSelected}>
      {isSelected ? (
        <CheckBoxOn data-parent="miniPage" onClick={toggleSelected} />
      ) : (
        <CheckBoxOff data-parent="miniPage" onClick={toggleSelected} />
      )}
    </CheckBoxWrapper>
  );
};

export default TableCheckBoxComponent;
