import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { empty, TYPE } from '../../../utils/constants';
import Toggler from '../Toggler';
import UI from '../../../UIComponents';
import validateEmail from '../../../utils/validators/validateEmail';
import { actionCreator } from '../../../shared/redux/actionHelper';
import DatePicker from '../../DatePicker';
import EditPlaylist from '../../../redux/playlists/types';
import Button2023 from '../../Buttons/Button2023';
import { actionCloseModal } from '../../../redux/user/action';
import {
  ActionTypeSendUserToSharing, checkShareType,
  calcUserState, shareType,
} from './helpers';
import { EditPage } from '../../../redux/pages/types';
import Contacts from '../../../redux/contacts/types';
import ShareRadioButtonGroup from './ShareRadioButtonGroup';
import LinkShareToWeb from './LinkShareToWeb';
import NewChannelsInput from '../../../pages/Playlist/NewChannelsInput';
import { Inputs } from '../../../pages/Playlist/PlaylistInput';
import { actionToggleRequestSpinner } from '../../../redux/support/action';
import { actionPublishPlaylistS } from '../../../redux/playlists/action';
import InputWithSelectUser from '../../Inputs/InputUsersWithSelector/InputWithSelectUser';

const cx = classNames.bind(styles);

const Share = React.memo(function Share({
  refMenu,
  close,
  dataParent = '',
  isMakerStyles,
  isMultiple,
  isForSettings,
  dataPayload,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const {
    type,
    id,
    title,
    shareState: { link },
    usedInChannels = {},
    wrapperId,
  } = useSelector((state) => state.currentPage);
  const { tierLimits } = useSelector((state) => state.user);
  const itemID = dataPayload?.item?.itemID;
  const itemWrapperID = dataPayload?.item?.itemWrapperID;
  const itemTitle = dataPayload?.item?.itemTitle;
  const { selectedPage, user } = useSelector((state) => state);
  const contacts = useSelector((state) => state.contacts);
  const preOpenOption = user?.dataPayload?.preOpenOption || null;
  const senderEmail = user?.email;

  const [code, setCode] = useState('');
  const [selectedShareType, setSelectedShareType] = useState(preOpenOption || shareType.SHARE_TO_USER);
  const [addedNew, setAddedNew] = useState({});
  const [isNeedToWarning, setIsNeedToWarning] = useState(false);


  const [isNeedUpdateShareState, setIsNeedUpdateShareState] = useState(true);
  const [inputEmail, setInputEmail] = useState('');
  const [validateError, setValidateError] = useState('');
  const [emailsAndUsersToInvite, setEmailsAndUsersToInvite] = useState(
    calcUserState(contacts),
  );
  const [selectedOption, setSelectedOption] = useState(null);
  const [localShareState, setLocalShareState] = useState({});
  const [localState, setLocalState] = useState(empty);

  const isPage = location.pathname.startsWith('/libraryPage')
    || location.pathname.startsWith('/content/pages/')
    || location.pathname.startsWith('/content/library');

  const isActiveShareToWeb = checkShareType.isShareToWeb[selectedShareType];
  const isActiveShareToUser = checkShareType.isShareToUser[selectedShareType];
  const isActiveShareToChannel = checkShareType.isShareToChannel[selectedShareType];

  const isMultiplePageSharing = isMultiple && isPage;
  const isMultipleButSingle = Object.keys(selectedPage).length === 1;
  const itemTypeReadable = isPage ? 'Page' : 'SmartFile';
  const itemType = isPage
    ? 'page'
    : 'SmartFile';

  const editShareStateHandler = (state, field) => {
    const newState = { ...localShareState };
    newState[field] = state;
    setLocalShareState(newState);
  };

  const shareInChannelHandler = useCallback(() => {
    const newId = uuidv4();
    if (Object.values(addedNew).length) {
      if (isMultiple) {
        dispatch(actionCreator(EditPlaylist.multiplePublish, {
          newId,
          addedNewChannel: Object.values(addedNew).map(i => ({ id: i.id })),
          playlistsIds: Object.keys(selectedPage),
          dateState: localState,
        }));
      } else {
        const playlist = {
          id: id || itemID,
          title: title || itemTitle,
        };
        dispatch(actionToggleRequestSpinner(id || itemID));
        dispatch(actionPublishPlaylistS(addedNew, {}, playlist, localState));
      }
      close && close();
      dispatch(actionCloseModal());
    } else {
      setIsNeedToWarning(true);
    }
  }, [addedNew, close, id, isMultiple, localState, selectedPage, title]);

  const shareToUserHandler = useCallback(() => {
    let users;
    if (inputEmail.trim() && validateEmail(inputEmail)) {
      users = [...emailsAndUsersToInvite, inputEmail.trim()];
    } else {
      users = emailsAndUsersToInvite;
    }

    if (!users.length && !isActiveShareToWeb) {
      setValidateError(t('pleaseEnterValidEmailT'));
      return;
    }
    if (!isMultiple && !isPage) {
      // single playlist
      dispatch(actionToggleRequestSpinner(id || itemID));
      dispatch(actionCreator(EditPlaylist.updateShareToUserPlaylistSR,
        {
          update: { ...localState, isCoEdit: !!localShareState.isCoEdit },
          users,
          wrapperIdFromOptions: itemWrapperID,
          idFromOptions: itemID,
        }));
    } else if (!isPage) {
    // many playlist
      dispatch(actionCreator(EditPlaylist.ShareMultiplePlaylistsS, {
        update: { ...localState, isCoEdit: !!localShareState.isCoEdit },
        users,
      }));
    } else if (isMultiplePageSharing) {
      dispatch(actionCreator(EditPage.ShareMultiplePagesS, {
        update: { ...localState },
        users,
      }));
    } else {
      // single page
      dispatch(
        actionCreator(ActionTypeSendUserToSharing.page, {
          update: { ...localState },
          users,
          pageId: id || itemID,
        }),
      );
    }
    close && close();
    dispatch(actionCloseModal());
  }, [close, emailsAndUsersToInvite, id, inputEmail, isActiveShareToWeb, isMultiple,
    isMultiplePageSharing, isPage, localShareState.isCoEdit, localState]);

  const shareToWebHandler = useCallback(() => {
    if (isNeedUpdateShareState) {
      if (isMultiple) return;
      if (isPage) {
        dispatch(actionCreator(EditPage.editShareStateToWeb,
          { isShareToWeb: true, updateTime: { ...localState }, ...localShareState }));
      } else {
        dispatch(actionCreator(EditPlaylist.updateShareToWebStatePlaylistSR,
          { isShareToWeb: true, updateTime: { ...localState }, ...localShareState }));
      }
      setIsNeedUpdateShareState(false);
    } else {
      close && close();
      dispatch(actionCloseModal());
    }
  }, [close, isMultiple, isNeedUpdateShareState, isPage, localShareState, localState]);

  const shareHandler = useCallback(() => {
    if (isActiveShareToChannel) {
      shareInChannelHandler();
    } else if (isActiveShareToUser) {
      shareToUserHandler();
    } else if (isActiveShareToWeb) {
      shareToWebHandler();
    }
  }, [isActiveShareToChannel, isActiveShareToUser,
    isActiveShareToWeb, shareInChannelHandler,
    shareToUserHandler, shareToWebHandler]);

  const deleteEmail = (indexToDel) => {
    setEmailsAndUsersToInvite(
      emailsAndUsersToInvite.filter((_, index) => index !== indexToDel),
    );
  };

  const handleUserOrEmailState = useCallback((e, user, email) => {
    const isAddOwner = email && email.trim().toLowerCase() === senderEmail.trim().toLowerCase()
      || user?.email && user?.email.trim().toLowerCase() === senderEmail.trim().toLowerCase();

    if (isAddOwner) {
      setValidateError(t('enterAnotherRecipientMessageT'));
      setInputEmail('');
      return;
    }

    if (!validateEmail(email) && user) {
      setEmailsAndUsersToInvite([
        ...emailsAndUsersToInvite,
        { ...user, type: 'user' },
      ]);
    } else if (email && !validateEmail(email) && !user) {
      const name = email.slice(0, 40);
      setValidateError(
        `${name}${
          name.length > 39 ? '...' : ''
        } ${t('notFoundInContactsT')}`,
      );
    } else if (email && validateEmail(email)) {
      setEmailsAndUsersToInvite([
        ...emailsAndUsersToInvite,
        { email, name: email, type: 'user' },
      ]);
      dispatch(
        actionCreator(Contacts.CreateAndAddSingleToContactMap, { email }),
      );
    } else {
      setValidateError(t('enterEmailOrUsernameT'));
      e.preventDefault();
    }
    setInputEmail('');
  }, [emailsAndUsersToInvite]);

  useEffect(() => {
    setIsNeedUpdateShareState(true);
  }, [localShareState, localState]);

  const modalTitle = `${t('shareYourT')} ${itemType}${
    isMultiple && !isMultipleButSingle ? 's' : ''
  }`;

  const isCanBeCoEdit = !isPage && isActiveShareToUser;

  return (
    <>
      <div
        data-parent={dataParent}
        className={styles.share_menu__wrapper}
      />

      <div
        ref={refMenu}
        className={cx('share_menu', isMakerStyles && 'share_maker_option', {
          isPlaylist: type !== TYPE.LIBRARY_COMPONENT && !isMultiplePageSharing,
          isForSettings,
        })}
        data-cy="share-playlist-modal"
      >
        <div data-parent={dataParent} className={styles.share_menu__heading}>

          <div data-parent={dataParent} className={styles.share_menu__heading_block}>
            {modalTitle}
          </div>

          <ShareRadioButtonGroup
            isMultiple={isMultiple}
            isPage={isPage}
            setSelectedShareType={setSelectedShareType}
            selectedShareType={selectedShareType}
            dataParent={dataParent}
          />

          <div
            data-parent={dataParent}
            className={styles.separator}
          />
          {isActiveShareToUser && (
            <>
              <InputWithSelectUser
                inputEmail={inputEmail}
                deleteEmail={deleteEmail}
                handleUserOrEmailState={handleUserOrEmailState}
                setInputEmail={setInputEmail}
                emailsAndUsersToInvite={emailsAndUsersToInvite}
                setEmailsAndUsersToInvite={setEmailsAndUsersToInvite}
                setValidateError={setValidateError}
                validateError={validateError}
                isSharePage
                dataParent={dataParent}
                labelText={t('shareWithT')}
                placeholder={t('usernameOrEmailT')}
                isDarkMode
              />
              <UI.Error isShare error={validateError} />
            </>
          )}
          {isActiveShareToChannel && (
            <div className={styles.channelsInput}>
              <NewChannelsInput
                state={Inputs.channel2023}
                setIsNeedToWarning={setIsNeedToWarning}
                isNeedToWarning={isNeedToWarning}
                used={addedNew}
                usedOld={usedInChannels}
                setUsed={setAddedNew}
                itemId={id}
                isPublishPlaylist
              />
            </div>
          )}

          {isActiveShareToWeb && !isNeedUpdateShareState && (
            <LinkShareToWeb wrapperId={wrapperId} link={link} dataParent={dataParent} isPage={isPage} />
          )}
          {isActiveShareToWeb && isNeedUpdateShareState && (
            <div
              data-parent={dataParent}
              className={styles.placeholder}
            />
          )}

          <div
            className={`${
              !isActiveShareToWeb && styles.availability_m_top
            }`}
          >
            <div
              data-parent={dataParent}
              className={styles.share_menu__access_code}
            >{t('availabilityT')}
            </div>
            <DatePicker
              type={`Share${itemTypeReadable}`}
              isMultiple={isMultiple}
              isModify
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              dataParent={dataParent}
              setLocalState={setLocalState}
              localState={localState}
            />
          </div>
          {isActiveShareToWeb && (
            <>
              <div
                data-parent={dataParent}
                className={styles.share_menu__access}
              >
                <div
                  data-parent={dataParent}
                  className={styles.share_menu__access_code}
                >
                  {t('accessCodeT')}
                </div>
                <div
                  data-parent={dataParent}
                  data-cy="access-code"
                  className={styles.toggler_container}
                >
                  <Toggler
                    dataParent={dataParent}
                    togglerHeight={11}
                    togglerWidth={23}
                    isActive={localShareState.isNeedAccess}
                    callback={() => {
                      editShareStateHandler(
                        !localShareState.isNeedAccess,
                        'isNeedAccess',
                      );
                    }}
                    isDarkMode
                  />
                </div>
              </div>

              {isActiveShareToWeb && localShareState.isNeedAccess && (
                <input
                  data-cy="input-accessCode"
                  className={`${styles.shareInput} ${styles.input_user_name}`}
                  onChange={(event) => setCode(event.target.value)}
                  value={code}
                  onBlur={() => editShareStateHandler(code, 'accessCode')}
                  placeholder={t('typeCodePlaceholderT')}
                />
              )}
            </>
          )}
          {isCanBeCoEdit && (
            <>
              <div
                data-parent={dataParent}
                className={styles.share_menu__access}
              >
                <div
                  data-parent={dataParent}
                  className={styles.share_menu__access_code}
                >
                  {t('allowChangesToSmartfileT')}
                </div>
                <div
                  data-parent={dataParent}
                  data-cy="co-edit"
                  className={styles.toggler_container}
                >
                  <Toggler
                    dataParent={dataParent}
                    togglerHeight={11}
                    togglerWidth={23}
                    isActive={localShareState.isCoEdit}
                    callback={() => {
                      editShareStateHandler(!localShareState.isCoEdit, 'isCoEdit');
                    }}
                    isDarkMode
                  />
                </div>
              </div>

                {isActiveShareToWeb && localShareState.isNeedAccess && (
                  <input
                    data-cy="input-accessCode"
                    className={styles.shareInput}
                    onChange={(event) => setCode(event.target.value)}
                    value={code}
                    onBlur={() => editShareStateHandler(code, 'accessCode')}
                    placeholder={t('typeCodePlaceholderT')}
                  />
                )}
            </>
          )}
        </div>
        <div
          data-parent={dataParent}
          className={styles.button_group}
        >
          <Button2023
            variant="primary"
            height={32}
            text={t('closeUpT')}
            handleButtonClick={close}
            noMargins
            derivedParentData={dataParent}
          />
          {!isActiveShareToWeb && (
          <Button2023
            variant="secondary"
            height={32}
            text={t('shareUpT')}
            handleButtonClick={shareHandler}
            isDisabled={!tierLimits.sharingSmartFiles}
            disabled
            noMargins
            derivedParentData={dataParent}
          />
          )}
          {isActiveShareToWeb && isNeedUpdateShareState && (
          <Button2023
            variant="secondary"
            height={32}
            text={t('applyUpT')}
            isDisabled={!tierLimits.sharingSmartFiles}
            handleButtonClick={shareHandler}
            noMargins
            derivedParentData={dataParent}
          />
          )}
        </div>
      </div>
    </>
  );
});

export default Share;
