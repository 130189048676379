import React from 'react';
import { UiComponentTypes } from '../../../utils/constants';
import LibraryComponentTypeIcon from '../../../components/LibraryComponentTypeIcon/LibraryComponentTypeIcon';
import { ReactComponent as PdfSymbolSvg } from '../../../images/icons/pdf_symbol_12.svg';
import { ReactComponent as PageSymbolSvg } from '../../../images/icons/page_16.svg';
import { ReactComponent as ImageSvg } from '../../../images/icons/image_symbol.svg';
import { ReactComponent as Link12Svg } from '../../../images/icons/web_symbol_12.svg';
import { ReactComponent as Google16Svg } from '../../../images/icons/google_drive_16.svg';
import { ReactComponent as Ms12Svg } from '../../../images/icons/doc_symbol_12.svg';
import { ReactComponent as Component12Svg } from '../../../images/icons/component_symbol_12.svg';
import { ReactComponent as OtherType12Svg } from '../../../images/icons/lib_component_other_type_12.svg';
import { otherType } from '../helpers';

export const iconTypeSelector = (type) => {
  return (
    <>
      {UiComponentTypes.pdf[type] && (
        <LibraryComponentTypeIcon type={type}>
          <PdfSymbolSvg />
        </LibraryComponentTypeIcon>
      )}
      {UiComponentTypes.page[type] && (
        <LibraryComponentTypeIcon type={type}>
          <PageSymbolSvg />
        </LibraryComponentTypeIcon>
      )}
      {UiComponentTypes.image[type] && (
        <LibraryComponentTypeIcon type={type}>
          <ImageSvg />
        </LibraryComponentTypeIcon>
      )}
      {UiComponentTypes.link[type]
        && !UiComponentTypes.google_embed_component[type] && (
          <LibraryComponentTypeIcon type={type}>
            <Link12Svg />
          </LibraryComponentTypeIcon>
      )}
      {UiComponentTypes.google_embed_component[type] && (
        <LibraryComponentTypeIcon type={type}>
          <Google16Svg />
        </LibraryComponentTypeIcon>
      )}
      {UiComponentTypes.ms[type] && (
        <LibraryComponentTypeIcon type={type}>
          <Ms12Svg />
        </LibraryComponentTypeIcon>
      )}
      {UiComponentTypes.component[type] && (
        <LibraryComponentTypeIcon type={type}>
          <Component12Svg />
        </LibraryComponentTypeIcon>
      )}
      {otherType(type)
        && !UiComponentTypes.ms[type]
        && !UiComponentTypes.link[type] && (
          <LibraryComponentTypeIcon type={type}>
            <OtherType12Svg />
          </LibraryComponentTypeIcon>
      )}
    </>
  );
};
