import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moduleStyles from '../../pages/Library/LibraryHeader.module.scss';
import collection20 from '../../images/icons/collection_20.svg';
import { ReactComponent as FavoriteHeaderSvg } from '../../images/icons/favorites20.svg';
import recentHeader from '../../images/icons/recent_20.svg';
import { actionRenameLibraryCollection } from '../../redux/library/actions';
import search from '../../images/icons/search.svg';
import { empty } from '../../utils/constants';
import Tooltip from '../../shared/Tooltips/Tooltip';
import DynamicCollectionCreator from '../DynamicCollectionCreator/DynamicCollectionCreator';
import { ReactComponent as Plus20Svg } from '../../images/icons/plus_20.svg';
import { ReactComponent as RoundedCross20Svg } from '../../images/icons/circle_cross_20.svg';
import SortAndView from './childComponents/SortAndView';
import { DynamicCollectionSave } from '../DynamicCollectionCreator/TopPart';
import ButtonComponent from '../Buttons/ButtonComponent';
import { ReactComponent as ShareSvg } from '../../images/icons/paperclip_link_20.svg';
import EditTagWrapperColorPalette from './EditTagWrapperColorPalette';

const styles = classNames.bind(moduleStyles);

const PagesHeader = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const isCustom = LibCompSieveTypesTest === props.sieveOptions;
  const { selectorType, folderId, tags } = useSelector((state) => state.user);
  const { isShowLibraryWidget } = useSelector((state) => state.currentPage);
  const { id: metaId } = useSelector(
    (reduxState) => reduxState.support.componentDescriptionData || empty,
  );

  return (
    <div
      className={styles('header_container', {
        isSideBarOpened: isShowLibraryWidget || metaId,
      })}
    >
      <div
        className={styles('header', 'topBallancer', {
          rows: !props.isCardsView,
        })}
      >
        <div className={styles('title_or_search')}>
          {!!folderId && selectorType !== 'tag' && (
            <img
              className={styles('folder_icon')}
              src={collection20}
              alt="is collection"
            />
          )}

          {selectorType === 'favorites' && (
            <FavoriteHeaderSvg
              className={styles('folder_icon', {
                favorite: selectorType === 'favorites',
              })}
            />
          )}
          {selectorType === 'recent' && (
            <img
              className={styles('folder_icon')}
              src={recentHeader}
              alt="is recent"
            />
          )}
          {selectorType === 'shared' && (
            <ShareSvg className={styles('folder_icon')} />
          )}

          {selectorType !== 'tag' && (
            <div className={styles('title_input_wrapper')}>
              <Tooltip
                text={props.collections.title}
                direction="up"
                place="manyPagesHeader"
                isShow={
                  !(
                    props.isComponentRename
                    && props.isComponentRename !== 'close'
                  )
                  && selectorType === 'folder'
                  && props.titleRef?.current?.getBoundingClientRect()?.width > 455
                }
              >
                <div
                  ref={props.titleRef}
                  className={styles({
                    title: selectorType !== 'folder',
                    hide:
                      props.isComponentRename
                      && props.isComponentRename !== 'close',
                  })}
                  onClick={props.onTitleClickHandler}
                >
                  {props.title || t('unnamedSmartViewT')}
                </div>
              </Tooltip>

              {props.isComponentRename
                && props.isComponentRename !== 'close' && (
                  <div
                    className={styles('folderTitleInputWrapper')}
                    style={{ width: props.width }}
                  >
                    <input
                      style={{ width: props.width }}
                      placeholder={t('smartViewTitlePlaceholderT')}
                      maxLength={55}
                      value={props.tmpTitle}
                      autoFocus
                      onChange={props.onChangeFolderTitle}
                      onKeyDown={props.onKeyPressFolderTitle}
                      className={styles('input2')}
                      onBlur={() => {
                        dispatch(
                          actionRenameLibraryCollection({
                            id: folderId,
                            name: props.tmpTitle,
                          }),
                        );
                        props.setIsComponentRename(false);
                      }}
                    />
                  </div>
              )}
            </div>
          )}

          {selectorType === 'tag' && (
            <div className={styles('title_input_wrapper')}>
              <Tooltip
                text={props.collections.title}
                direction="up"
                place="manyPagesHeader"
                isShow={
                  !(
                    props.isComponentRename
                    && props.isComponentRename !== 'close'
                  )
                  && selectorType === 'folder'
                  && props.titleRef?.current?.getBoundingClientRect()?.width > 455
                }
              >
                <EditTagWrapperColorPalette
                  titleRef={props.titleRef}
                  tag={tags[folderId] || {}}
                />
              </Tooltip>
            </div>
          )}

          <Tooltip
            text={t('searchInPagesTooltipT')}
            direction="left"
            place="libraryHeaderFullscreen"
          >
            <div
              onClick={() => {
                props.setFilter('');
                props.setIsShowFilter(!props.isShowFilter);
              }}
              className={styles('search_icon_wrapper')}
            >
              <img
                className={styles('search_icon')}
                src={search}
                alt="search"
              />
            </div>
          </Tooltip>
        </div>

        <div className={styles('top_sorting_and_add_file')}>
          <SortAndView {...props} />
          <div className={styles('btn_wrapper')}>
            <ButtonComponent text={t('pageUpT')} clickCb={props.createNewPage}>
              <Plus20Svg />
            </ButtonComponent>
          </div>
        </div>
      </div>
      {props.isShowFilter && props.showHeaderDetails && (
        <div
          className={styles('resize_wrapper', {
            isTag: selectorType === 'tag',
            show_description: isShowLibraryWidget || metaId,
          })}
        >
          <div className={styles('search', 'search_max_size')}>
            <div
              className={styles(
                'search_input_wrapper',
                'isPlaylists',
                'allLibraryHeader',
              )}
            >
              <input
                value={props.libFilterSearch}
                onChange={(e) => props.setLibFilterSearch(e.target.value)}
                placeholder={`${t('searchInT')} ${props.title}`}
                onMouseDown={(e) => e.stopPropagation()}
                autoFocus
                className={styles('search_input', 'isPlaylists')}
              />
              {!!props.libFilterSearch && (
                <RoundedCross20Svg
                  className={styles('clear_search', 'isPlaylists')}
                  onClick={() => {
                    props.setLibFilterSearch('');
                  }}
                />
              )}
            </div>
          </div>
          {selectorType === 'all' && (
            <div className={styles('save_search_wrapper')}>
              <DynamicCollectionSave {...props} isLibrary />
            </div>
          )}
        </div>
      )}
      {/* {props.showHeaderDetails && !props.isShowFilter && ( */}
      {/*  <div className={styles('slider_and_sort_container', 'orders')}> */}
      {/*    <div className={styles('slider_parent')}> */}

      {/*      <NavSlider */}
      {/*        setSieve={props.setSieve} */}
      {/*        setActiveNavSlider={props.setActiveNavSlider} */}
      {/*        activeNavSlider={props.activeNavSlider} */}
      {/*        usage={props.isChannels ? 'channels' : 'library'} */}
      {/*        viewMode={props.viewMode} */}
      {/*        isCardView={props.isCardsView} */}
      {/*        isCustom={isCustom} */}
      {/*        items={props.items} */}
      {/*        totalSubscribers={props.totalSubscribers} */}
      {/*        sieveOptions={props.sieveOptions} */}
      {/*        isBottomMargin */}
      {/*        isNoLeftMargin */}
      {/*      /> */}

      {/*    </div> */}
      {/*    <div className={styles('sorting_parent', { listView: !props.isCardsView })} /> */}

      {/*  </div> */}
      {/* )} */}

      {props.showHeaderDetails && props.isShowFilter && (
        <div
          className={styles('wrapper_dynamicCollection', {
            show_description: isShowLibraryWidget || metaId,
          })}
        >
          <DynamicCollectionCreator
            isAllLibraryItems={selectorType === 'all'}
            libFilterSearch={props.libFilterSearch}
            isFullScreen
            isShowFilter
            filter={props.filter}
            isHasSomeFilter={
              (props.filter.tags
                && Object.values(props.filter.tags || {}).length)
              || (props.filter.fileType
                && Object.values(props.filter.fileType || {}).length)
              || (props.filter.createDate
                && Object.values(props.filter.createDate || {}).length)
            }
            setFilter={props.setFilter}
            setIsShowFilter={props.setIsShowFilter}
          />
        </div>
      )}
    </div>
  );
};

export default PagesHeader;
