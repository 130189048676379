import { ContentActionType } from './contentTypes';
import SupportAction from '../support/types';

export const changePrevSortType = (sortType) => ({
  type: ContentActionType.previousSortType,
  payload: sortType,
});

export const actionUpdateContentTagInRedux = (contentID, tag) => ({
  type: SupportAction.updateTag.updateContentTags,
  payload: { contentID, tag },
});

export const actionSwitchContentSortType = (sorting) => ({
  type: ContentActionType.SwitchContentSortType,
  payload: sorting,
});

export const actionUpdateCounters = (payload) => ({
  type: ContentActionType.updateCounter,
  payload,
});
