import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import { APPURL } from '../../environments/environments';
import { copyInBuffer } from '../../utils/helpers';

const cx = classNames.bind(styles);

const iframeTemplate = (id) => `<iframe src='${APPURL}/embed_page/${id}' height='100%' width='100%'></iframe>`;

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dataPayload } = useSelector((state) => state.user);
  const [iFrameText, setIFrameText] = useState(iframeTemplate(dataPayload.id));
  const copyRef = useRef(null);

  const close = () => {
    dispatch(actionCloseModal());
  };
  const save = () => {
    close();
  };
  const copyLink = () => {
    copyInBuffer(copyRef, dispatch);
  };
  const onInputChange = (e) => setIFrameText(e.target.value);

  const focusHandler = (e) => {
    e.preventDefault();
    const textArea = copyRef.current;
    const range = document.createRange();
    range.selectNodeContents(textArea);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('selectAll');
    copyInBuffer(copyRef, dispatch);
  };

  return (
    <div onClick={close} className={cx('modal_wrapper', 'EmbedPageModal')}>
      <div onClick={(e) => e.stopPropagation()} className={cx('modal')}>
        <div className={cx('title')}>{t('embedLinkT')}</div>
        <div className={cx('description')}>{t('clickToCopyEmbedLinkT')}</div>

        <div
          ref={copyRef}
          className={cx('iframe_input')}
          contentEditable
          onChange={onInputChange}
          autoFocus
          onFocus={focusHandler}
        >
          {iFrameText}
        </div>

        <div className={cx('group_buttons', 'addTagModal')}>
          <div onClick={copyLink} className={cx('group_buttons_cancel')}>
            {t('copyLinkT')}
          </div>

          <div onClick={save} className={cx('group_buttons_apply')}>
            {t('closeT')}
          </div>
        </div>
      </div>
    </div>
  );
};
