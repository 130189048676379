import React, { useState, useEffect, useCallback, useRef, RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { keyframes } from 'styled-components';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SearchSvg } from '../../images/2023/svg/ai_search.svg';
import { ReactComponent as SummarizeSvg } from '../../images/2023/svg/ai_summarize.svg';
import { ReactComponent as ExpandedSvg } from '../../images/icons/icon_18/expand.svg';
import { ReactComponent as MinimizeSvg } from '../../images/icons/icon_18/minimize.svg';
import { ReactComponent as SendSvg } from '../../images/2023/svg/send-2.svg';
import { ReactComponent as StopSvg } from '../../images/2023/svg/stop_ai.svg';
import { actionCreator } from '../../shared/redux/actionHelper';
import { CurrentPage } from '../../redux/currentPage/types';
import { IRootState } from '../../redux/reducers';
import { openModalType, empty, aiProcessingStatusEnum, MessageType, DATA_PARENTS } from '../../utils/constants';
import useComponentVisibleMouseDown from '../../utils/hooks/useComponentVisibleMouseDown';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { actionOpenModal } from '../../redux/user/action';
import { calcItemName } from '../../utils/ai/helper';
import {
  generateSimpleRTBState,
  getLinkPagePosition,
  sanitizeToLoad,
  universalPlaylistSize,
} from '../../utils/helpers';
import { actionChangeLinkPageType, actionEmptyAddLinkPageToPlaylist, actionSaveSummarize } from '../../redux/currentPage/action';
import { actionChangeTextElementBlockReduxMaker } from '../../redux/playlists/action';
import { smartFileItemType } from '../../shared/smartFile/constant';
import { actionShowMessage } from '../../redux/support/action';
import { PlaylistUI } from '../../utils/UIHelpers/PlaylistBuilderElements/componentPickers';

const PlaylistAIInput = styled.div<{isExpanded: string | boolean, isExpandedForPreview: boolean}>`
  z-index: 8;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 40px;
  bottom:0;
  width: 702px;
  flex-direction: column;
  border-radius: 16px 16px 0 0;
  background: #fff;
  display: flex;
  padding: 16px;
  justify-content: space-between;
  transition: height, 0.2s;
  box-shadow: 0px 24px 48px -8px rgba(66, 66, 66, 0.16), 0px 4px 12px 4px rgba(66, 66, 66, 0.12);
   ${({ isExpanded }) => (isExpanded
    ? `height: fit-content;
`
    : '')}
  ${({ isExpandedForPreview }) => (isExpandedForPreview
    ? 'width: 935px; height: 552px;'
    : 'width: 702px; height: fit-content;')}
`;
const ExpandButton = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #E8E9EA;
  background: #FFF;
  box-shadow: 0px 24px 48px -8px rgba(66, 66, 66, 0.12), 0px 2px 8px 0px rgba(66, 66, 66, 0.04);
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  display: flex; 
  align-items: center;
  gap: 10px;
`;

const ProcessingType = styled.div`
  color: #2B2C2E;
  font-family: RobotoFlex, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
`;
const EstimatedTime = styled.div`
color: #4B5563;
  font-family: RobotoFlex, sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: -0.05px;
`;

const ScrollableItemWrapper = styled.div`
  overflow-y: scroll;
  max-height: 150px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    background-color: #dbdbdb;
    -webkit-box-shadow: inset 0 0 6px #dbdbdb;
    margin-right: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background: #dbdbdb;
  }
`;

const SendButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  width: 44px;
  min-width: 44px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  height: 44px;
  justify-content: center;
  align-items: center;
  background: ${({ disabled }) => (disabled ? '#A2A5AA' : '#000')};
  border-radius: 50%;
  border: none;
  outline: none;
  margin-left: 15px;
  cursor: pointer;
`;

const ExpandedFunctionalityWrapper = styled.div`
  padding: 12px;
`;

const SummaryExample = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 22px;
  margin-bottom: 10px;
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  border-radius: 32px;
  padding: 12px 4px 12px 10px;
  borer-radius: 50%;
  
  &:focus-within {
    background: #F5F5F5;
  }
`;

const SectionTitle = styled.p`
  color: #A2A5AA;
  font-family: RobotoFlex, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.05px;
  text-transform: uppercase;
`;
const ButtonsWrapper = styled.div`
    display: flex;
    gap: 15px;
`;
const FeatureTag = styled.div<{ height?: number }>`
  padding-bottom: 2px;
  ${({ height }) => (height ? `
    height: ${height}px;
    display: flex;
    align-items: flex-end;
    
    ` : '')}

  p {
    color: #A2A5AA;
    margin: 0;
    font-weight: 700;
    font-family: "RobotoFlex", sans-serif;
    font-size: 14px;
    line-height: 24px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const HeaderButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const AIButton = styled.button<{isSearch?:boolean, isReset?: boolean, isSave?: boolean}>`
  border-radius: 8px;
  height: 40px;
  padding: 2px 24px;
  align-items: center;
  align-content: center;
  display: flex;
  gap: 8px;
  outline: none;
  border: none;
  color: #FAFAFA;
  cursor: pointer;
  ${({ isSearch }) => (isSearch
    ? `
      background: radial-gradient(42.37% 709.09% at 50% -404.55%, rgba(255, 255, 255, 0.12) 0%, 
        rgba(255, 255, 255, 0.12) 50%, rgba(170, 89, 223, 0.12) 100%), 
        linear-gradient(87deg, #AA59DF 0%, #7B59DF 66.81%, #AA59DF 133.63%);

      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%),
          radial-gradient(42.37% 709.09% at 50% -404.55%, rgba(255, 255, 255, 0.12) 0%,
          rgba(255, 255, 255, 0.12) 50%, rgba(170, 89, 223, 0.12) 100%),
          linear-gradient(87deg, #AA59DF 0%, #7B59DF 66.81%, #AA59DF 133.63%);
      }
      ` : `
      background: radial-gradient(42.37% 709.09% at 50% -404.55%, rgba(255, 255, 255, 0.12) 0%,
        rgba(255, 255, 255, 0.12) 50%, rgba(170, 89, 223, 0.12) 100%),
        linear-gradient(89deg, #59DFC0 0%, #356CE4 74.49%, #59DFC0 148.99%);

      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%),
         radial-gradient(42.37% 709.09% at 50% -404.55%, rgba(255, 255, 255, 0.12) 0%,
         rgba(255, 255, 255, 0.12) 50%, rgba(170, 89, 223, 0.12) 100%),
         linear-gradient(89deg, #59DFC0 0%, #356CE4 74.49%, #59DFC0 148.99%), #A2A5AA;
      }
    `)}
  ${({ isReset }) => (isReset
    ? `
      background:  transparent;
      color:#000;
      border: 1px solid #E8E9EA;
       &:hover {
            color:#484343;
            border: 0.6px solid #FFC72C;
            background: #FFEEBF;
          }
    `
    : '')}
    ${({ isSave }) => (isSave
    ? `
      background: linear-gradient(317deg, rgb(31, 174, 9) -2.91%, rgb(91, 218, 72) 138.5%);
      &:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%,
           rgba(255, 255, 255, 0.15) 100%),
           linear-gradient(317deg, #1FAE09 -2.91%, #5BDA48 138.5%);
        }
    `
    : '')}
`;

const Textarea = styled.textarea`
  width: 100%;
  margin-right: 50px;
  height: 28px;
  max-height: 76px;
  resize: none;
  border: none;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  &::placeholder {
    padding-top: 2px;
    color: #C5C5C5;
  }

  &:focus {
    background: #F5F5F5;
  }

   &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    background-color: #dbdbdb;
    -webkit-box-shadow: inset 0 0 6px #dbdbdb;
    margin-right: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background: #dbdbdb;
  }
`;
const keyFrames = keyframes`
   to {
      transform: rotate(360deg);
   }
}`;

const SpinnerWrapper = styled.div`
  width: 40px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ImageShell = styled.div`
  position: relative;
  transition: ease-out 0.25s;
  transition-property: width, height;
  overflow: hidden;
  border-radius: 6px;
  display: flex;
  width: 38px;
  height: 38px;
`;
const Spinner = styled.div<{ isSmall?: boolean }>`
   ${({ isSmall }) => (isSmall
    ? `
      width: 4.8px;
      height: 4.8px;
      border-radius: 4.8px;
      box-shadow: 12px 0px 0 0 rgba(252,255,0,0.2), 9.7px 7.1px 0 0 rgba(252,255,0,0.4), 
        3.7199999999999998px 11.4px 0 0 rgba(252,255,0,0.6), 
        -3.7199999999999998px 11.4px 0 0 rgba(252,255,0,0.8), -9.7px 7.1px 0 0 #fcff00;
    `
    : `
      width: 11.2px;
      height: 11.2px;
      border-radius: 11.2px;
      box-shadow: 28px 0px 0 0 rgba(240,240,244,0.2), 22.7px 16.5px 0 0 rgba(240,240,244,0.4),
        8.68px 26.6px 0 0 rgba(240,240,244,0.6), -8.68px 26.6px 0 0 rgba(240,240,244,0.8), -22.7px 16.5px 0 0 #f0f0f4;
    `)}
  animation: ${keyFrames} 1s infinite linear;
`;
const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const Result = styled.div`
  display: flex;
  padding: 6px 8px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #E8E9EA;
  background: #FFF;
  cursor: pointer;
`;


interface ILinkPage {
  id: string,
  name: string,
  title: string,
  isTextSavedToVectorDb: boolean | null,
  libraryComponent?: {
    title: string,
    size: string,
    parsedStatusByAi: string | null,
    summaryAI: string | null,
    type: string,
  }
}

interface ICurrentProcessingItems {
  processing: number,
  pending: number,
  all: number,
  completed: number,
}
interface ISingleSearhResult {
  singleClickHandler: (e: React.MouseEvent, index: number) => void
  index: number,
  linkPage: ILinkPage,
}

const SingleSearhResult: React.FC<ISingleSearhResult> = ({ singleClickHandler, index, linkPage }) => {
  const { t } = useTranslation();

  const calculatedSizeMB = useMemo(() => universalPlaylistSize(linkPage, false), [linkPage]);
  return (
    <Result onClick={(e) => singleClickHandler(e, index)}>
      <ImageShell>
        {PlaylistUI.createPreviewComponent(
          linkPage.libraryComponent?.type,
          linkPage.libraryComponent || {},
          linkPage?.id,
          '',
        )}
      </ImageShell>
      {calcItemName(linkPage, t)}
      •
      {calculatedSizeMB}
    </Result>
  );
};

const AIInput: React.FC<{ currentProcessingItems: ICurrentProcessingItems }> = ({ currentProcessingItems }) => {
  const { t } = useTranslation();
  const isProcessing = useMemo(() => (
    currentProcessingItems?.pending > 0 || currentProcessingItems?.processing > 0
  ), [currentProcessingItems]);
  const itemsRef: RefObject<HTMLDivElement> | null = useRef(null);
  const inputRef: RefObject<HTMLTextAreaElement> | null = useRef(null);

  const [isExpandedForPreview, setExpandedForPreview] = useState(false);
  const [componentRef, isExpanded, setIsExpanded] = useComponentVisibleMouseDown(
    false, '', () => setExpandedForPreview(false),
  );
  const {
    linkPages = [],
    manager,
    currentSummarize,
    selectedItems,
    aiProcessing,
    id,
    wrapperId,
    title,
    currentProcessingTasks,
  } = useSelector((state: IRootState) => state.currentPage);
  const user = useSelector((state: IRootState) => state.user || empty);
  const dispatch = useDispatch();

  const sources: ILinkPage[] = useMemo(() => {
    return linkPages.filter((elem: ILinkPage) => elem.isTextSavedToVectorDb
      || elem.libraryComponent?.parsedStatusByAi === aiProcessingStatusEnum.ITEM_PROCESSED_IN_AI);
  }, [linkPages]);

  const [selectedAIFeatureForSend, setSelectedAIFeatureForSend] = useState('');
  const [userTask, setUserTask] = useState<string>('');
  const [dataIds, setDataIds] = useState<string[]>([]);
  const [data, setData] = useState<ILinkPage[]>([]);

  const handleReset = () => {
    dispatch(actionSaveSummarize(''));
    setData([]);
    setUserTask('');
    setSelectedAIFeatureForSend('');
  };

  const handleSelectAgent = (agent: string) => {
    handleReset();
    setSelectedAIFeatureForSend(agent);
    inputRef.current?.focus();
  };

  const placeholder = useMemo(() => {
    switch (selectedAIFeatureForSend) {
      case 'Summarize':
        return 'Expand your prompt here...';
      case 'Search':
        return 'Refine your search here...';
      default:
        return 'Enter your prompt here...';
    }
  }, [selectedAIFeatureForSend])

  const createLinkPage = (destinationIndex: number, targetIndex: number) => {
    const newPos = getLinkPagePosition(destinationIndex, linkPages, targetIndex);
    const linkPageId = uuid();
    const pageData = {
      id: linkPageId,
      position: newPos,
      owner: {
        id: user.id,
        last_name: user.last_name,
        avatarUrlVerySmall: user.avatarUrlVerySmall,
        first_name: user.first_name,
      },
      mainUserInterface: {
        id: uuid(),
      },
    };
    dispatch(actionEmptyAddLinkPageToPlaylist(id, pageData));
    return pageData;
  };

  const setSmartText = () => {
    setDataIds([]);
    const createdItem = createLinkPage(linkPages.length ?? 0, linkPages.length ?? 0);
    const newState = generateSimpleRTBState(currentSummarize);
    const updateCBaction = (blockId: string) => {
      return (
        actionChangeTextElementBlockReduxMaker(
          blockId,
          newState,
          '',
          id,
          createdItem.id,
          smartFileItemType.title,
        ));
    };
    dispatch(actionChangeLinkPageType(createdItem.id, smartFileItemType.richText, id, null, updateCBaction));
    handleReset();
    setExpandedForPreview(false);
    setIsExpanded(false);
  };

  const exampleSummarize = useMemo(() => {
    return sources.reduce((acc: string, cur: ILinkPage) => {
      if (acc) return acc;
      return cur?.libraryComponent?.summaryAI ?? '';
    }, '');
  }, [sources]);

  const singleClickHandler = useCallback((event: React.MouseEvent, index: number) => {
    const elemPosition = itemsRef?.current?.getBoundingClientRect();
    dispatch(
      actionOpenModal(openModalType.UPVPreview,
        { id, index, mode: 'UPV', elemPosition, linkPages: data }),
    );
  }, [id, dispatch, data]);

  const handleSend = () => {
    if (!sources.length) {
      return dispatch(
        actionShowMessage({
          type: MessageType.NoAIFiles,
          itemName: t('No AI-processed files are available'),
        }),
      );
    }
    if (aiProcessing.isLoading) {
      return dispatch(
        actionCreator(CurrentPage.StopAIProcessingInSmartfile, {
          taskIds: currentProcessingTasks,
        }),
      );
    }
    if (selectedAIFeatureForSend === 'Summarize') {
      const linkPagesForSend = (linkPages as ILinkPage[]).map(elem => elem.id);
      return dispatch(
        actionCreator(CurrentPage.Summarize, {
          linkPages: linkPagesForSend,
        }),
      );
    }
    if (selectedAIFeatureForSend === 'Search') {
      return dispatch(
        actionCreator(CurrentPage.requestAISearch, {
          userTask,
          playlistManagerId: manager?.id ?? wrapperId,
          selectedIds: Object.keys(selectedItems),
          setDataIds,
        }),
      );
    }
    return dispatch(
      actionShowMessage({
        type: MessageType.PromptInProgress,
        itemName: t('Custom prompt is in progress'),
      }),
    );
  };

  useEffect(() => {
    if (data.length !== dataIds.length) {
      const linkPagesMap = linkPages?.reduce((acc: {[key:string]:ILinkPage}, cur: ILinkPage) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
      const responseLinkPages = dataIds.map(mapId => linkPagesMap[mapId]);
      setData(responseLinkPages);
    }
  }, [dataIds, linkPages]);

  useEffect(() => {
    if (aiProcessing.isLoading) {
      setIsExpanded(true);
    }
  }, [aiProcessing.isLoading, setIsExpanded]);
  const adjustHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = '5px';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };
  return (
    <PlaylistAIInput ref={componentRef} isExpanded={isExpanded} isExpandedForPreview={isExpandedForPreview}>
      {isExpanded && (
        <ExpandedFunctionalityWrapper data-parent={DATA_PARENTS.checkItem}>
          <Header data-parent={DATA_PARENTS.checkItem}>
            <Title>
              {currentSummarize && (
                <ExpandButton onClick={() => setExpandedForPreview(!isExpandedForPreview)}>
                  {isExpandedForPreview ? <MinimizeSvg /> : <ExpandedSvg /> }
                </ExpandButton>
              )}
              <h3 data-parent={DATA_PARENTS.checkItem}>Alan AI</h3>
            </Title>
            <HeaderButtonsWrapper>
              {(!!data.length || currentSummarize) && (
                <AIButton onClick={handleReset} isReset data-parent={DATA_PARENTS.checkItem}>Reset</AIButton>
              )}
              {currentSummarize && (
                <AIButton onClick={setSmartText} isSave data-parent={DATA_PARENTS.checkItem}>Save as text</AIButton>
              )}
            </HeaderButtonsWrapper>
          </Header>
          {!aiProcessing.isLoading && !isProcessing && (
            <>
              {(!data.length || aiProcessing.isLoading) && !currentSummarize
                && <SectionTitle data-parent={DATA_PARENTS.checkItem}>What can alan ai do for you</SectionTitle>}
              {currentSummarize && <SectionTitle data-parent={DATA_PARENTS.checkItem}>Summary</SectionTitle>}
              {currentSummarize && <b data-parent={DATA_PARENTS.checkItem}>Overview<br /></b>}
              {(!data.length || aiProcessing.isLoading) && !currentSummarize && (
                <>
                  <ButtonsWrapper data-parent={DATA_PARENTS.checkItem}>
                    <Tooltip text="Allows  to  search via AI-processed files" direction="down" place="ai_input">
                      <AIButton
                        isSearch
                        onClick={() => handleSelectAgent('Search')}
                        data-parent={DATA_PARENTS.checkItem}
                      >
                        <SearchSvg data-parent={DATA_PARENTS.checkItem} />
                        Search
                      </AIButton>
                    </Tooltip>
                    <Tooltip text="Allows  to  summarize  AI-processed files" direction="down" place="ai_input">
                      <AIButton
                        onClick={() => handleSelectAgent('Summarize')}
                        data-parent={DATA_PARENTS.checkItem}
                      >
                        <SummarizeSvg data-parent={DATA_PARENTS.checkItem} />
                        Summarize
                      </AIButton>
                    </Tooltip>
                  </ButtonsWrapper>
                  {exampleSummarize && (
                    <SummaryExample data-parent={DATA_PARENTS.checkItem}>
                      <SectionTitle data-parent={DATA_PARENTS.checkItem}>{`${title} Summary`}</SectionTitle>
                      <ScrollableItemWrapper>
                        <p data-parent={DATA_PARENTS.checkItem}>
                          <b> In the first source: </b>
                          {sanitizeToLoad(exampleSummarize.replaceAll('*', ''))}
                        </p>
                      </ScrollableItemWrapper>
                    </SummaryExample>
                  )}
                </>
              )}
              <ScrollableItemWrapper>
                {currentSummarize}
              </ScrollableItemWrapper>
              {data.length > 0 && (
                <ResultsWrapper data-parent={DATA_PARENTS.checkItem} >
                  <SectionTitle data-parent={DATA_PARENTS.checkItem}>Top Result</SectionTitle>
                  <ScrollableItemWrapper>
                    <SingleSearhResult
                      data-parent={DATA_PARENTS.checkItem}
                      singleClickHandler={singleClickHandler}
                      linkPage={data[0]}
                      index={0}
                    />
                    {data.length > 1 && <SectionTitle>Other Results</SectionTitle>}
                    {data.slice(1).map((linkPage, index) => (
                      <SingleSearhResult
                        data-parent={DATA_PARENTS.checkItem}
                        singleClickHandler={singleClickHandler}
                        linkPage={linkPage}
                        index={index + 1}
                      />
                    ))}
                  </ScrollableItemWrapper>
                </ResultsWrapper>
              )}
            </>
          )}
          {(aiProcessing.isLoading || isProcessing) && (
            <LoadingWrapper data-parent={DATA_PARENTS.checkItem}>
              <SpinnerWrapper>
                <Spinner data-parent={DATA_PARENTS.checkItem} />
              </SpinnerWrapper>
              <ProcessingType data-parent={DATA_PARENTS.checkItem}>
                {aiProcessing.type === 'summarize' && 'Summarizing'}
                {aiProcessing.type === 'ai_search' && 'Searching'}
                {(aiProcessing.type === 'ai_send' || isProcessing) && 'Processing'}
              </ProcessingType>
              <EstimatedTime data-parent={DATA_PARENTS.checkItem}>
                {(aiProcessing.type === 'ai_send' || isProcessing)
                  ? `Completed ${currentProcessingItems?.completed} of ${currentProcessingItems?.all}`
                  : 'Estimated time: less than a minute'}
              </EstimatedTime>
            </LoadingWrapper>
          )}
        </ExpandedFunctionalityWrapper>
      )}
      <InputWrapper
        onClick={() => setIsExpanded(true)}
        data-parent={DATA_PARENTS.checkItem}
      >
        {selectedAIFeatureForSend && (
          <FeatureTag height={inputRef.current?.clientHeight}><p>{selectedAIFeatureForSend}:</p> </FeatureTag>
        )}
        <Textarea
          ref={inputRef}
          data-parent={DATA_PARENTS.checkItem}
          value={userTask}
          onInput={adjustHeight}
          onChange={(e) => setUserTask(e.target.value)}
          placeholder={placeholder}
        />
      </InputWrapper>
      <SendButton
        onClick={handleSend}
        data-parent={DATA_PARENTS.checkItem}
        disabled={userTask.length > 300}
      >
        {aiProcessing.isLoading
          ? !isExpanded ? <Spinner isSmall data-parent={DATA_PARENTS.checkItem} />
            : <StopSvg data-parent={DATA_PARENTS.checkItem} />
          : <SendSvg data-parent={DATA_PARENTS.checkItem} />}
      </SendButton>
    </PlaylistAIInput>
  );
};

export default AIInput;
