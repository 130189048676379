import React from 'react';
import styled from 'styled-components';
import { AVATAR_GENERATOR_COLORS } from '../../utils/SettingsHelpers/constants';

const Label = styled.div<{ width?: number }>`
  top: 1px;
  left: 5px;
  font-weight: 400;
  font-size: 10px;
  text-transform: capitalize;
  white-space: nowrap;
  line-height: 14px;

  ${({ width }) => (width
    ? `
    font-size: ${width / 2}px;
  `
    : '')}
`;

const Background = styled.div<{
  big: any;
  width?: number;
  isAbsolute?: boolean;
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
}>`
  width: ${({ width }) => width ?? 18}px;
  height: ${({ width }) => width ?? 18}px;
  ${({ isAbsolute }) => (isAbsolute
    ? 'position:absolute;'
    : `
  position:relative;
  `)}
  flex: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor || '#FFE589'};
  user-select: none;

  ${({ borderColor }) => (borderColor ? `
    border: 1px solid ${borderColor};
    outline: 1px solid ${borderColor};
    ` : '')}
  ${({ big }) => (big
    ? `
    transform: scale(2.1);
  `
    : '')}
  ${Label} {
    color: ${({ color }) => (color || 'white')};
  }
`;

interface AvatarPlaceholderProps {
    name: string;
    big?: boolean;
    abbreviation?: string;
    width: number;
    isAbsolute: boolean;
    bgColor?: string;
    color: string;
    borderColor?: string;
}

const AvatarPlaceholder: React.FC<AvatarPlaceholderProps> = ({
  name = 'Batman',
  big,
  abbreviation,
  width,
  isAbsolute = false,
  bgColor,
  color,
  borderColor,
}) => {
  return (
    <Background
      className="avatar_border_hover"
      big={big}
      width={width}
      isAbsolute={isAbsolute}
      color={color}
      backgroundColor={
          bgColor || AVATAR_GENERATOR_COLORS[name.length % AVATAR_GENERATOR_COLORS.length]
      }
      borderColor={borderColor}
    >
      <Label width={width}>
        {abbreviation?.trim() ? abbreviation : '∞'}
      </Label>
    </Background>
  );
};

export default AvatarPlaceholder;
