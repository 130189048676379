export default {
  AddMessage: '[SupportAction] Add a new notification in the tooltip',
  RemoveMessage: '[SupportAction]  Remove notification in the tooltip',
  ToggleSettingsSideBar:
    '[SupportAction] Toggle Show Playlist Settings SideBar',
  ToggleComponentDescription:
    '[SupportAction] Toggle Show Component Description',
  SaveComponentDescriptionData:
    '[SupportAction] Save Component Description Data',
  GetSharedComponentDescriptionDataS:
    '[SupportAction] Get Shared Component Description Data SAGAS',
  SaveSharedComponentDescriptionData:
    '[SupportAction] Save Shared Component Description Data',
  SettingsSideBarOff: '[SupportAction] Set Settings Side Bar Off',
  SettingsSideBarOn: '[SupportAction] Set Settings Side Bar On',
  JumpTo: '[SupportAction] Jump To Item',
  JumpToCard: '[SupportAction] Jump To Card',
  JumpToPlaylist: '[SupportAction] Jump To Playlist with effects',
  JumpToPageTitle: '[SupportAction] Jump To Page Title with effects',
  LoadingItem: '[SupportAction] Loading Item Title',
  FindHashTags: '[SupportAction] Find HashTags',
  UpdateHashTags: '[SupportAction] Update HashTags',
  SaveRedirectLink: '[SupportAction] Save Redirect Link',
  SendForgotPasswordRequest: '[SupportAction] Send Forgot Password Request',
  SendResetPasswordRequest: '[SupportAction] Send Reset Password Request',
  ToggleRequestSpinner: '[SupportAction] Toggle Request Spinner',
  ToggleSideBar: '[SupportAction] Toggle SideBar [type]',
  InitialDataFetch: '[SupportAction] Initial Data Fetch',
  ClearSupportBlocks: '[SupportAction] Clear Support Blocks',
  AddSupportEditableBlockRedux:
    '[SupportAction] Add Support Editable Block Redux',
  ChangeSupportEditableBlockRedux:
    '[SupportAction] Change Support Editable Block Redux',
  DeleteSupportEditableBlockRedux:
    '[SupportAction] Delete Support Editable Block Redux',
  DeleteManyBlocksSupportRedux:
    '[SupportAction] Delete Many Blocks Support Redux',
  UpdateSupportBlocks: '[SupportAction] Update Support Blocks',
  UpdateSupportLayers: '[SupportAction] Update Support Layers',
  UpdateSupportBlock: '[SupportAction] Update Single Support Block',
  UpdateSupportDraggableBlocks:
    '[SupportAction] Update Support Draggable Blocks',
  DragManySupportBlocksRedux: '[SupportAction] Drag Many Support Blocks Redux',
  CreateSupportEditableBlockWithState:
    '[SupportAction] Create Support Editable BlockWith State',
  ChangeSupportStateComponentButton:
    '[SupportAction] Change Support State Component Button',
  AddManySupportEditableBlock:
    '[SupportAction] Add Many Support Editable Block',
  FillComponentSupportBlocks: '[SupportAction] Fill Component Support Blocks',
  CheckIsInUse: '[SupportAction] Check if item is in-use',
  WarningDelete: '[SupportAction] Warning delete item',
  LoadPageSettings: '[SupportAction] Load Page Settings',
  SetActiveMenuItemId: '[SupportAction] Set Active Menu Item Id',
  TriggerCountersUpdate: '[SupportAction] Trigger Counters Update',
  ReadyToScroll: '[SupportAction] Component Ready To Scroll',
  SetComponentIsReady: '[SupportAction] Set Component Is Ready',
  JumpToWithConfirmation: '[SupportAction] Jump To With Confirmation',
  ClearReadyConfirmation: '[SupportAction] Clear Ready Confirmation',
  SetBlinkItemId: '[SupportAction] Set Blink ItemId',
  CleanBlinkItemIds: '[SupportAction] Clean Blink ItemId',
  SetColumnDropDownIsOpened: '[SupportAction] Set Column DropDown Is Opened',
  ShowContentLoader: '[SupportAction] Show Content Loader',
  SetZActiveItemIdR: '[SupportAction] Set ZActive Item Id REDUX',
  UpdateComponentR: '[SupportAction] Update Component REDUX',
  GetPagesForLinkOption: '[SupportAction] Get Pages For Link Option SAGAS',
  SaveTempComponents: '[SupportAction] Save Temp Components REDUX',
  EraseTempComponents: '[SupportAction] Erase Temp Components REDUX',
  MultiSelectActiveR: '[SupportAction] Multi Select Active REDUX',
  SetActiveTableItemId: '[SupportAction] Set Active Table ItemId',
  UploadUnseenS: '[SupportAction] upload Unseen info S',
  ShowUnread: '[SupportAction] show awaited Unread',
  AddUnseenNotificationPlaylist:
    '[SupportAction] add unseen notification playlist',
  AddUnseenNotificationPage: '[SupportAction] add unseen notification page',
  DeleteItemFromContent: '[EditPlaylist] delete from content',
  RemoveItemInRedux: '[SupportAction] remove item in redux',
  RemoveItemContentInRedux: '[SupportAction] remove item in Content/data redux ',
  AddItemInRedux: '[SupportAction]  add  item in redux in display',
  checkExistItemInFilter: '[SupportAction] check Exist Item In Filter',

  UploadUnseenR: '[SupportAction] save Unseen info R',
  UploadUnseenOutboxR: '[SupportAction] save Unseen for Outbox info R',
  AddBacklightItem: '[SupportAction] Add backlight Item ',
  ClearBacklightItem: '[SupportAction] Clear backlight Item ',
  ReadUnseenPlaylist: '[SupportAction] read unseen playlist',
  ReadOneUnseenPlaylist: '[SupportAction] read one unseen playlist',
  ReadUnseenPages: '[SupportAction] read unseen pages', // outdated
  ReadOneUnseenPage: '[SupportAction] read One unseen page',
  ReadUnseenChannel: '[SupportAction] read unseen channel',
  DeleteUnseenPlaylistR: '[SupportAction] delete unseen playlist in redux',
  UpdateSharedModalAnchor: '[Support]  Update sharedModal anchor',
  DeleteAllUnseenPlaylistR: '[SupportAction] delete all unseen playlist in redux', // outdated
  DeleteUnseenPagesR: '[SupportAction] delete unseen pages in redux',
  DeleteAllUnseenPagesR: '[SupportAction] delete all unseen pages in redux', // outdated
  DeleteUnseenPlaylistInChannel:
    '[SupportAction] delete unseen playlist in  channel in redux and saga',
  DeleteAllUnseenPlaylistInChannelR:
    '[SupportAction] delete all unseen playlist in  channel in redux', // outdated
  ColorPalette: {
    DeleteCustomColorS: '[Support-ColorPalette] Delete Custom Color SAGAS',
    DeleteCustomColorR: '[Support-ColorPalette] Delete Custom Color REDUX',
    AddCustomColorS: '[Support-ColorPalette] Add Custom Color SAGAS',
    AddCustomColorR: '[Support-ColorPalette] Add Custom Color REDUX',
    UpdateCustomColorS: '[Support-ColorPalette] Update Custom Color SAGAS',
    UpdateCustomColorR: '[Support-ColorPalette] Update Custom Color REDUX',
    ResetAllCustomColorsS:
      '[Support-ColorPalette] Reset All Custom Colors SAGAS',
    ResetAllCustomColorsR:
      '[Support-ColorPalette] Reset All Custom Colors REDUX',
  },
  updateTag: {
    updateContentTags: '[Support-updateTag] update contents tag in REDUX',
    DeleteLocal: '[Support-updateTag] Delete tag in REDUX',
    AddLocal: '[Support-updateTag] Add tag in REDUX',
    AddTagsLocal: '[Support-updateTag] Add tags in REDUX',
    Update: '[Support-updateTag] Update tag in component in REDUX',
    Save: '[Support-updateTag] Save new tags state SAGAS',
    Clear: '[Support-updateTag] clear tags state REDUX',
  },


};
