import React from 'react';
import styled from 'styled-components/macro';
import { convertToRaw } from 'draft-js';
import { useHistory } from 'react-router-dom';

import PdfAsAttachment from '../MomentBlock/CustomBlocks/pdfBlock/PdfBlockAsAttachment';
import PdfAsPreview from '../MomentBlock/CustomBlocks/pdfBlock/PdfAsPreview';
import { BlockTypes, UiComponentTypes } from '../../utils/constants';
import {
  createMarkup,
  innerHtmlImageTemplateShort,
  pipeAddIsHasTextMeta,
} from '../../utils/helpers';
import { APPURL } from '../../environments/environments';
import EmbedBlock from '../MomentBlock/CustomBlocks/EmbedBlock';
import PageLink from '../MomentBlock/CustomBlocks/PageLink';
import LinkPreviewBlock from '../MomentBlock/LinkPreviewBlock';
import SharedComponent from '../SharedComponent';

import styles from './index.module.css';

function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}

const parseId = (data) => {
  if (!data) return '';
  if (typeof data === 'string') {
    try {
      const dataObj = JSON.parse(data.replaceAll("'", '"'));
      const url = dataObj.entityMap[0]?.data.src;

      return getId(url);
    } catch (e) {
      return '';
    }
  } else {
    try {
      const contentRaw = convertToRaw(data.getCurrentContent());
      const url = contentRaw.entityMap[0]?.data.src;

      return getId(url);
    } catch (e) {
      return '';
    }
  }
};

const Video = styled.div`
  width: 640px;
  position: relative;
  height: 360px;
`;

const VideoWrapper = styled.div`
  width: 640px;
  height: 360px;
`;

const BlockCreator = React.memo(function BlockCreator({
  newLayers,
  isPlaylist,
  isMaker,
  isPlayer,
  item = {},
  index = 0,
  isPreview,
  isWhiteGallery,
  isPlayerPreview,
  isPlayerTOCPreview,
}) {
  const history = useHistory();

  const isPage = item.type === BlockTypes.page;
  const isWebSite = item.type === BlockTypes.webSite;
  const goToViewAttachmentPdf = () => {
    history.push(`/sharedPlaylistItem/${item.nestedItemId}`);
  };

  const calcKey = (innerItem) => {
    return `${item.id}:${item.position}:${innerItem.id}:${innerItem.position}`;
  };

  if (item.type === BlockTypes.image) {
    if (!item.urlSmallImage) return <></>;
    return (
      <div
        className={`wrapper_component_${item.type}_preview wrapper_component_${item.type} `}
      >
        {/* eslint-disable-next-line react/no-danger */}
        <div
          dangerouslySetInnerHTML={createMarkup(
            innerHtmlImageTemplateShort(item.urlSmallImage, item.width),
          )}
        />
      </div>
    );
  }

  if (
    item.type === BlockTypes.googleEmbed
    || item.type === BlockTypes.dropboxEmbed
  ) {
    return (
      <div
        className={`wrapper_component_${item.type}_preview wrapper_component_${item.type} `}
      >
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={createMarkup(item.innerHtml)} />
      </div>
    );
  }

  if (item?.nestedItemId && item?.contentType === BlockTypes.pdf) {
    const hasPreview = item?.state?.data?.representationState === 'preview'
      || item?.state?.data?.content === 'preview';

    return (
      <div
        className={`${styles.libraryComponent}
      ${styles.pdf}
      ${!hasPreview ? styles.attachment : ''}`}
      >
        {hasPreview && (
          <PdfAsPreview
            state={item?.state}
            width={item?.state?.data?.width}
            isNoShowImage={isWhiteGallery || (isPlayer && !isPlayerPreview && !isPlayerTOCPreview)}
          />
        )}
        {!hasPreview && (
          <PdfAsAttachment
            title={item?.state?.data?.title}
            size={item?.state?.data?.size}
            downloadPdf={goToViewAttachmentPdf}
            refOption={null}
            isPreview
          />
        )}
      </div>
    );
  }

  if (
    item?.type === BlockTypes.embed
    && item?.state?.data?.text?.includes(APPURL)
  ) {
    return <EmbedBlock state={item?.state} isView />;
  }

  if (item?.type === BlockTypes.pdf) {
    const hasPreview = item?.state?.data?.representationState === 'preview'
      || item?.state?.data?.content === 'preview';
    return (
      <div
        className={`${styles.libraryComponent}
      ${styles.pdf}
      ${!hasPreview ? styles.attachment : ''}`}
      >
        {hasPreview && (
          <PdfAsPreview
            state={item?.state}
            width={item?.state?.data?.width}
            isNoShowImage={isWhiteGallery || (isPlayer && !isPlayerPreview && !isPlayerTOCPreview)}
          />
        )}
        {!hasPreview && (
          <PdfAsAttachment
            title={item?.state?.data?.title}
            size={item?.state?.data?.size}
            downloadPdf={() => {}}
            refOption={null}
            isPreview
          />
        )}
      </div>
    );
  }
  if (
    item?.nestedItemId
    && !UiComponentTypes.image[item.type]
    && !UiComponentTypes.page[item.type]
  ) {
    return (
      <div className={styles.libraryComponent}>
        {(item.components || [])
          .sort((a, b) => a.position - b.position)
          .map(pipeAddIsHasTextMeta)
          .map((innerItem) => {
            if (innerItem.type === 'image') {
              return (
                <div
                  key={calcKey(innerItem)}
                  className={`wrapper_component_${innerItem.type}_preview wrapper_component_${innerItem.type}`}
                >
                  {/* eslint-disable-next-line react/no-danger */}
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      innerHtmlImageTemplateShort(
                        innerItem.urlFile,
                        innerItem.width,
                      ),
                    )}
                  />
                </div>
              );
            }
            // if (!innerItem.isHasText) {
            //   return <NoTextWrapper key={calcKey(innerItem)} />;
            // }
            return (
              <div
                key={calcKey(innerItem)}
                className={`wrapper_component_${innerItem.type}_preview wrapper_component_${innerItem.type}`}
              >
                {(isPlaylist || isPlayer || isMaker) && (
                <SharedComponent
                  type1={innerItem.type}
                  state={innerItem.state || {}}
                  componentId="nestedComponentId"
                  index="nestedIndex"
                  isSharePage
                  isViewMode
                />
                )}
                {/* eslint-disable-next-line react/no-danger */}
                {!(isPlaylist || isPlayer || isMaker) && (
                  <div
                    dangerouslySetInnerHTML={createMarkup(innerItem.innerHtml)}
                  />
                )}
              </div>
            );
          })}
      </div>
    );
  }

  if (isPage) {
    return (
      <PageLink page={item} header={item.title} isViewMode="props.isViewMode" />
    );
  }

  if (isWebSite) {
    return (
      <div className={`wrapper_component_${item.type} `}>
        <LinkPreviewBlock data={item?.state?.data} editorWR={null} />
      </div>
    );
  }

  if (item.type === 'video' && parseId(item.state)) {
    return (
      <VideoWrapper>
        <Video>
          <iframe
            width="160"
            height="115"
            src={`//www.youtube.com/embed/${parseId(item.state)}`}
            frameBorder="0"
          />
        </Video>
      </VideoWrapper>
    );
  }
  // TODO Vladimir => isHasText exists? check pls
  if (!item.isHasText && !Object.values(BlockTypes).includes(item.type)) {
    return <div style={{ height: 16 }} />;
  }

  return (
    <div
      className={`wrapper_component_${item.type}_preview wrapper_component_${item.type} `}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <SharedComponent
        type1={item.type}
        state={item.state}
        componentId={item.id}
        newLayers={newLayers}
        index={index}
        innerHtml={item.innerHtml}
        isSharePage
      />
      {/* {!isPreview && isEmptyBlockHtml(item.innerHtml) && <EmptyBlock />} */}
    </div>
  );
});

export default BlockCreator;
