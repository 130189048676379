import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SettingsSideBar from './SettingsSideBar';
import { ReactComponent as Close20Svg } from '../../images/icons/close_10_black.svg';
import styles from '../../components/GlobalModals/index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import SettingsContentSelector from './SettingsContentSelector';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

const cx = classNames.bind(styles);

const ApplicationSettingsShell = styled.div`
  display: flex;
  border-radius: 8px;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #f5f6ff;
`;

const SECTION = {
  profile: 'profile',
  preferences: 'preferences',
};

const CloseButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  border: 1px solid #ECE2E2;
  border-radius: 50%;

  &:hover {
    background: #ffeebf;
    cursor: pointer;
    border: 0.6px solid #ffc72c;
  }
`;

const RIGHT_OFFSET = 38;
const TOP_OFFSET = 14;

const ApplicationSettings = () => {
  const { dataPayload } = useSelector((redux) => redux.user);
  const [activeSection, setActiveSection] = useState(dataPayload?.defaultPage ?? SECTION.profile);
  const refItem = useRef(null);
  const [divStyles, setDivStyles] = useState({});
  const dispatch = useDispatch();
  const { width, height } = useWindowDimensions();

  const close = () => {
    dispatch(actionCloseModal());
  };

  const calcHorizontal = (ref) => {
    if (!ref?.current) return 0;
    const menuWidth = ref.current.clientWidth;
    const refAbsoluteX = ref.current.getBoundingClientRect().x;
    return refAbsoluteX + menuWidth - RIGHT_OFFSET;
  };
  const calcVertical = (ref) => {
    if (!ref?.current) return 0;
    return ref.current.getBoundingClientRect().y + TOP_OFFSET;
  };

  useEffect(() => {
    if (!refItem.current) return;
    setDivStyles({
      left: calcHorizontal(refItem) - 10,
      top: calcVertical(refItem) + 2,
    });
  }, [refItem, height, width]);

  return (
    <div onClick={close} className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'ApplicationSettings')}
      >
        <ApplicationSettingsShell ref={refItem}>
          <SettingsSideBar
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />

          <PerfectScrollbar style={{ background: '#F6F6F5', width: '100%', maxWidth: '864px' }}>
            <SettingsContentSelector activeSection={activeSection} />
          </PerfectScrollbar>
        </ApplicationSettingsShell>
        <CloseButton style={divStyles} onClick={close}>
          <Close20Svg />
        </CloseButton>
      </div>
    </div>
  );
};

export default ApplicationSettings;
