import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import useDrivePicker from 'react-google-drive-picker';
import { useTranslation } from 'react-i18next';
import { emptyArr } from '../../../utils/constants';
import { GOOGLE_CLIENT_ID } from '../../../environments/environments';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { Library } from '../../../redux/library/types';
import { Text } from '../../../pages/SettingsSection/CommonComponents/Layout';
import Button2023 from '../../Buttons/Button2023';


export const AddGoogleFileShell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-top: 8px;
  padding-right: ${({ isNoRightPadding }) => (isNoRightPadding ? 0 : 22)}px;
`;

export const HintText = styled(Text)`
  margin-top: 9px;
`;

const ScrollContainer = styled.div`
  max-height: 160px;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    scroll-padding-bottom: 0;
    width: 8px;
    background: initial;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 8px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track-piece {
    background: initial;
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: initial;
    border-radius: 10px;
  }
`;

export const AccountItem = styled.div`
  height: 32px;
  cursor: pointer;
  margin-bottom: initial;
  width: 100%;
  color: #585858;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: RobotoFlex;
  font-size: 15px;

  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  ${({ theSecond }) => (theSecond
    ? `
    font-weight: 500;
  `
    : `
    font-weight: 400;
  `)}
`;
export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${({ theSecond }) => (theSecond
    ? `
  margin-top:16px;
  `
    : '')}
`;
const AddGoogleFile = ({
  setIsVisible = () => {},
  viewedTypes,
  oldUploadBlockId,
  oldUploadBlockPosition,
  nextItemPosition,
  setStep,
  isPlaylist,
  isMediaAddBlock,
  firstBlockIndex,
}) => {
  const { linkedAccounts } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [accounts, setAccounts] = useState(emptyArr);
  const [openPicker] = useDrivePicker();

  const pickerPayloadCreator = ({ linkedAccountId, data }) => ({
    data,
    openPicker,
    linkedAccountId,
    setIsVisible,
    viewedTypes,
    setStep,
    isPlaylist,
    oldUploadBlockId,
    oldUploadBlockPosition,
    nextItemPosition,
    isWithUpload: !isPlaylist && !isMediaAddBlock,
    firstBlockIndex,
  });

  const handleOpenPicker = (linkedAccountId) => {
    dispatch(
      actionCreator(
        Library.OpenGooglePickerModal,
        pickerPayloadCreator({ linkedAccountId }),
      ),
    );
  };

  const googleResponseHandler = (data) => {
    dispatch(
      actionCreator(
        Library.CreateLinkedAccountS,
        pickerPayloadCreator({ data }),
      ),
    );
  };

  const googleResponseErrorHandler = () => {};

  const googleLogin = useGoogleLogin({
    onSuccess: googleResponseHandler,
    onError: googleResponseErrorHandler,
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly',
  });

  useEffect(() => {
    if (linkedAccounts) {
      setAccounts(
        linkedAccounts.filter((account) => account?.serviceName === 'GOOGLE'),
      );
    }
  }, [linkedAccounts]);

  return (
    <AddGoogleFileShell isNoRightPadding>
      <ScrollContainer>
        {!!accounts.length
          && accounts.map((account) => (
            <RowWrapper>
              <AccountItem key={account.id}>
                {`${t('chooseFromT')} ${account.accountEmail}`}
              </AccountItem>
              <Button2023
                text={t('importUpT')}
                variant="secondary"
                width={68}
                height={32}
                noMargins
                handleButtonClick={() => handleOpenPicker(account.id)}
              />
            </RowWrapper>
          ))}
      </ScrollContainer>
      <RowWrapper theSecond>
        <AccountItem theSecond>{t('linkAccountT')}</AccountItem>
        <Button2023
          text={t('connectUpT')}
          variant="secondary"
          height={32}
          handleButtonClick={googleLogin}
          noMargins
        />
      </RowWrapper>

      {/* <HintText>{t('addFilesHintT')}</HintText> */}
    </AddGoogleFileShell>
  );
};

export default (props) => (
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <AddGoogleFile {...props} />
  </GoogleOAuthProvider>
);
