import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { select } from '@redux-saga/core/effects';
import { getChannels, getCurrentPage, showErrorMessage } from './sagasUtils';
import { actionCreator } from '../shared/redux/actionHelper';
import {
  requestPagePreview,
  requestUpdateInBoxCardUnification,
  requestUpdateInBoxCardsMany,
  requestUploadInboxCard,
} from '../utils/request';
import { InboxAction } from '../redux/inbox/types';
import { INBOX_CARD_TYPE, MessageType } from '../utils/constants';
import { actionShowMessage } from '../redux/support/action';
import downloadStatus from '../utils/dataUtil';
import { sanitizeToLoad } from '../utils/helpers';
import draftDataConvertToLoad from '../utils/draftDataConvert';

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function calcState(state, type) {
  try {
    if (!state) return state;
    if (typeof state === 'object') return state;
    if (type === 'embed') return JSON.parse(sanitizeToLoad(state));
    return draftDataConvertToLoad(state);
  } catch (e) {
    return state;
  }
}
function* updateInboxCardReq({ field, value, type, id }) {
  yield call(requestUpdateInBoxCardUnification, { itemId: id,
    update: {
      itemType: type,
      field,
      value,
    } });
}

function* updateInboxCardsReq(items, type) {
  yield call(requestUpdateInBoxCardsMany(type), { items });
}

function* UploadPageInInboxCard(action) {
  try {
    const { pageIds } = action.payload;
    const { data } = yield call(
      requestPagePreview('sharedpage'),
      [pageIds.join('_')],
    );
    if (!data.errors) {
      Object.values(data.content).forEach(({ components }) => {
        components?.forEach((component) => {
          component.state = calcState(component.state, component.type);
        });
      });
      yield put(
        actionCreator(InboxAction.UpdatePageInInboxCardRedux, data.content),
      );
    } else {
      yield showErrorMessage(data.errors, action);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* UploadInboxCard(action) {
  try {
    const { filter, isNext } = action.payload;
    const cards = yield select((state) => state.inbox.cards);
    const nextStep = yield select((state) => state.inbox.nextStep);
    const params = {};
    if (isNext) {
      params.nextStep = nextStep;
    }
    const { data } = yield call(requestUploadInboxCard(filter), [], params,
    );
    if (!data.errors) {
      const uniqueElements = [...cards, ...data.card].filter((el, index, self) => index === self.findIndex((t) => (
        t.id === el.id && t.type === el.type
      ))).sort((a, b) => b.date - a.date);

      const pageIds = [];
      data.card.forEach(item => {
        if (item.type === INBOX_CARD_TYPE.page) {
          pageIds.push(item.id);
        }
      });
      if (pageIds.length) {
        yield put(actionCreator(InboxAction.UploadPage,
          { pageIds }),
        );
      }
      yield put(actionCreator(InboxAction.UploadInboxCardRedux, {
        newCards: uniqueElements,
        maxDateToShow: data.nextStep.maxDateToShow,
        nextStep: data.nextStep,
        hasItem: !!cards.length,
      }));
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* CardInBoxMarkUsReed(action) {
  try {
    const { item: { type, isRead, id } } = action.payload;
    if (type === INBOX_CARD_TYPE.page || type === INBOX_CARD_TYPE.sharedPlaylist
        || type === INBOX_CARD_TYPE.invite || type === INBOX_CARD_TYPE.channelPlaylist) {
      yield updateInboxCardReq({ type, field: 'read', value: !isRead, id });
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* CardsInboxMarkAsRead(action) {
  try {
    const { items, type } = action.payload;
    yield updateInboxCardsReq(items, type);
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* DeleteInboxCard(action) {
  try {
    const { item: { type, id } } = action.payload;
    if (type === INBOX_CARD_TYPE.page || type === INBOX_CARD_TYPE.invite
      || type === INBOX_CARD_TYPE.sharedPlaylist || type === INBOX_CARD_TYPE.channelPlaylist) {
      yield updateInboxCardReq({ type, field: 'deleted', value: true, id });
      yield put(
        actionShowMessage({
          type: MessageType.SuccessfullyDeletedInboxCard,
        }),
      );
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* DeleteInboxCards(action) {
  try {
    const { items } = action.payload;
    yield updateInboxCardsReq(items, 'deleted');
    yield put(
      actionShowMessage({
        type: MessageType.SuccessfullyDeletedInboxCards,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* AddInBoxCardSaga(action) {
  try {
    const status = yield select((state) => state.inbox.status);
    if (status !== downloadStatus.success) return;

    const { type, data } = action.payload;
    if (type === INBOX_CARD_TYPE.page) {
      const pageItem = [];
      if (data.isMany) {
        data.dataShared.forEach((dataInPage) => {
          const { libraryComponentId, availableTo, date, userWhoSent, title } = dataInPage;
          pageItem.push({
            id: libraryComponentId,
            type: INBOX_CARD_TYPE.page,
            title,
            isRead: false,
            isNew: true,
            date,
            userWhoSent,
            availableTo,
          });
        });
      } else {
        const { libraryComponentId, availableTo, date, userWhoSent, title } = data;
        pageItem.push({
          id: libraryComponentId,
          type: INBOX_CARD_TYPE.page,
          title,
          isRead: false,
          isNew: true,
          date,
          userWhoSent,
          availableTo,
        });
      }
      yield put(
        actionCreator(InboxAction.AddInBoxCardRedux, { items: pageItem }),
      );
      yield put(actionCreator(InboxAction.UploadPage, { pageIds: pageItem.map(i => i.id) }));
    }
    if (type === INBOX_CARD_TYPE.sharedPlaylist) {
      const playlistItem = [];
      if (data.isMany) {
        data.dataShared.forEach((dataInPage) => {
          const { wrapperId, availableTo, date, userWhoSent, playlist, itemsCount } = dataInPage;
          playlistItem.push({
            id: wrapperId,
            type: INBOX_CARD_TYPE.sharedPlaylist,
            playlist,
            isRead: false,
            isNew: true,
            date,
            readItemsCount: 0,
            itemsCount,
            userWhoSent,
            availableTo,
          });
        });
      } else {
        const { wrapperId, availableTo, date, userWhoSent, playlist, itemsCount } = data;
        playlistItem.push({
          id: wrapperId,
          type: INBOX_CARD_TYPE.sharedPlaylist,
          playlist,
          isRead: false,
          isNew: true,
          date,
          readItemsCount: 0,
          userWhoSent,
          availableTo,
          itemsCount,
        });
      }
      yield put(
        actionCreator(InboxAction.AddInBoxCardRedux, { items: playlistItem }),
      );
    }
    if (type === INBOX_CARD_TYPE.channelPlaylist) {
      const { wrapperId, availableTo, date, userWhoSent, playlist, channelsId, itemsCount } = data;
      const { myChannels } = yield select(getChannels);
      const playlistItem = {
        id: wrapperId,
        type: INBOX_CARD_TYPE.channelPlaylist,
        playlist,
        isRead: false,
        isNew: true,
        date,
        readItemsCount: 0,
        userWhoSent,
        availableTo,
        itemsCount,
        inChannel: channelsId.filter(id => !!myChannels[id]).map(id => ({ id, name: myChannels[id].name })),
      };
      yield put(
        actionCreator(InboxAction.AddSingleWithoutUpdateInBoxCardRedux, { item: playlistItem }),
      );
    }
    if (type === INBOX_CARD_TYPE.invite) {
      const { id, date, userWhoSent, linkToConfirm, channelName } = data;
      const playlistItem = {
        id,
        type: INBOX_CARD_TYPE.invite,
        isRead: false,
        isNew: true,
        date,
        channelName,
        userWhoSent,
        linkToConfirm,
      };
      yield put(
        actionCreator(InboxAction.AddSingleWithoutUpdateInBoxCardRedux, { item: playlistItem }),
      );
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}
function* UpdateInboxCounter(action) {
  try {
    let changeCounterValue = 1;
    const { data } = action.payload;
    if (data.isMany) {
      changeCounterValue = data.dataShared.length;
    }
    yield put(
      actionCreator(InboxAction.UpdateCounter, { changeCounterValue }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* CheckCurrentPageAddNewAwaitedNotification(action) {
  try {
    const { notification } = action.payload;
    const { id: idCurrentPage } = yield select(getCurrentPage);
    if (idCurrentPage && (idCurrentPage === notification.itemId)) return;

    yield put(actionCreator(InboxAction.AddNewAwaitedNotification, { notification }));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

export default function* inboxSaga() {
  yield takeLatest(InboxAction.UploadInboxCardSaga, UploadInboxCard);
  yield takeEvery(InboxAction.CardInBoxMarkUsReed, CardInBoxMarkUsReed);
  yield takeEvery(InboxAction.CardsInboxMarkAsReadOrUnread, CardsInboxMarkAsRead);
  yield takeEvery(InboxAction.AddInBoxCardSaga, AddInBoxCardSaga);
  yield takeEvery(InboxAction.AddInBoxCardSaga, UpdateInboxCounter);
  yield takeEvery(InboxAction.DeleteInboxCard, DeleteInboxCard);
  yield takeEvery(InboxAction.DeleteInboxCards, DeleteInboxCards);
  yield takeEvery(InboxAction.UploadPage, UploadPageInInboxCard);
  yield takeEvery(InboxAction.CheckCurrentPageAddNewAwaitedNotification, CheckCurrentPageAddNewAwaitedNotification);
}
