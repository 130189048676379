import React from 'react';
import styled from 'styled-components/macro';

const BtnWrapper = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  padding-top: 6px;
  border: 0.6px solid #ece2e2;
  box-shadow: 0px 1px 4px 0px #7a69691a;

  ${({ position }) => (position ? `position:${position};` : '')}
  ${({ right }) => (right ? `right:${right}px;` : '')}
  ${({ bottom }) => (bottom ? `bottom:${bottom}px;` : '')}
  &:hover {
    border: 0.6px solid #ffc72c;
    background-color: #ffeebf;
    cursor: pointer;
  }
`;

const SmallRectangleBtnWithIcon = ({
  children,
  position,
  right,
  bottom,
  click,
}) => {
  return (
    <BtnWrapper
      onClick={click}
      position={position}
      right={right}
      bottom={bottom}
    >
      {children}
    </BtnWrapper>
  );
};

export default SmallRectangleBtnWithIcon;
