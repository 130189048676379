import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { actionGetSharedLibraryComponent } from '../../redux/library/actions';
import { empty } from '../../utils/constants';
import { ViewLibraryComponent } from './childComponents/ViewLibComponent';

const SharedLibraryComponent = ({}) => {
  const { accessAvailable, isAnyCanEdit } = useSelector((state) => state.currentPage) || empty;
  const currentPage = useSelector((state) => state.currentPage) || empty;
  const user = useSelector((state) => state.user) || empty;
  const [isAccessAvailable, setIsAccessAvailable] = useState('init');
  const [isOwner, setIsOwner] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    const { playlistId, linkPageId, channelId } = params;
    dispatch(
      actionGetSharedLibraryComponent({
        playlistId,
        linkPageId,
        channelId,
        history,
      }),
    );
  }, [accessAvailable]);

  useEffect(() => {
    if (accessAvailable) setIsAccessAvailable(true);
  }, [accessAvailable]);

  useEffect(() => {
    setIsOwner(currentPage?.owner?.id === user.id);
  }, [isAccessAvailable]);
  return (
    <>
      {isAccessAvailable && isAccessAvailable !== 'init' && (
        <ViewLibraryComponent
          channelId={params.channelId}
          item={currentPage}
          isOwner={isOwner}
          isAnyCanEdit={isAnyCanEdit || isOwner}
        />
      )}
    </>
  );
};

export default SharedLibraryComponent;
