import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  BoardColumnWrapper,
  CardBodyInfo,
  CardInfo,
  CardInfoChannel,
  CardInfoSize,
  HeaderWrapper,
  InfoBlock,
  TimeAndAvatar,
} from './styled';

const InboxHeader = () => {
  const { t } = useTranslation();
  return (
    <HeaderWrapper>
      <CardInfo>
        {t('senderT')}
      </CardInfo>

      <CardBodyInfo>
        {t('titleT')}
      </CardBodyInfo>

      <CardInfoChannel>
        {t('recipientsT')}
      </CardInfoChannel>

      <CardInfoSize isRight>
        {t('sizeT')}
      </CardInfoSize>

      <InfoBlock isRight>
        {t('availabilityT')}
      </InfoBlock>

      <BoardColumnWrapper>
        {t('boardT')}
      </BoardColumnWrapper>

      <TimeAndAvatar>
        {t('receivedT')}
      </TimeAndAvatar>

    </HeaderWrapper>
  );
};

export default InboxHeader;
