import React from 'react';
import classNames from 'classnames/bind';
import styles from './UsedMenu.module.scss';

const cn = classNames.bind(styles);

const UsedCounter = ({ counter, callback, isShowOnly, isActive, dataParent }) => {
  return (
    <div
      data-parent={dataParent}
      className={cn(styles.button, { isShowOnly, isActive })}
      onClick={callback}
    >
      <div className={cn(styles.icon)}>{counter}</div>
    </div>
  );
};

export default UsedCounter;
