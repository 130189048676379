import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoSvg } from '../../images/2023/svg/Logo.svg';
import styles from '../loginLogout/Login.module.scss';
import { LoginBackGround } from '../loginLogout/Login';
import {
  Container,
  ForgotWrapper,
  Logo,
  SignInReturn,
  Title,
} from '../loginLogout/ForgotPassword/ForgotView';
import { DEFAULT_PAGE_PATH } from '../../utils/constants';

const UnauthorizedOperation = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const goToSignIn = () => {
    history.push('/login');
  };
  const { id } = useSelector((state) => state.user);
  const goToLibrary = () => {
    history.push(DEFAULT_PAGE_PATH);
  };

  return (
    <>
      <LoginBackGround />
      <div className={styles.scroll_wrapper}>
        <div className={styles.scroll_balancer}>
          <ForgotWrapper>
            <Container>
              <Logo onClick={goToLibrary}>
                <LogoSvg />
              </Logo>
              <Title>{t('unauthorizedOperationT')}</Title>
              {!id && (
                <SignInReturn onClick={goToSignIn}>
                  {t('returnToSignInT')}
                </SignInReturn>
              )}
              {id && (
                <SignInReturn onClick={goToLibrary}>
                  {t('returnToLibraryT')}
                </SignInReturn>
              )}
            </Container>
          </ForgotWrapper>
        </div>
      </div>
    </>
  );
};

export default UnauthorizedOperation;
