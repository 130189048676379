import Immutable from 'immutable';
import React from 'react';
import {
  CharacterMetadata,
  ContentBlock,
  convertFromRaw,
  EditorState,
  genKey,
  RichUtils,
  SelectionState,
} from 'draft-js';
import { BlockTypes } from './constants';

const { List, Repeat } = Immutable;

export const myBlockStyleFn = (contentBlock) => {
  const type = contentBlock.getType();
  if (type === 'alignLeft') {
    return 'align-left';
  }
  if (type === 'indent_text_1') {
    return 'indent_text_1';
  }
  if (type === 'indent_text_2') {
    return 'indent_text_2';
  }
  if (type === 'alignRight') {
    return 'align-right';
  }
  if (type === 'alignCenter') {
    return 'align-center';
  }
  if (type === 'alignJustify') {
    return 'align-justify';
  }
  if (type === 'unordered-list-item') {
    return 'unordered-list-item';
  }
  if (type === 'text') {
    return 'text';
  }
  if (type === 'title') {
    return 'title';
  }
  if (type === 'title1') {
    return 'title1';
  }
  if (type === 'title2') {
    return 'title2';
  }
  if (type === 'title3') {
    return 'title3';
  }
  if (type === BlockTypes.video) {
    return 'media';
  }
  if (type === BlockTypes.image) {
    return 'media';
  }
  if (type === BlockTypes.callout) {
    return 'callout';
  }
  if (type === 'blockquote') {
    return 'superFancyBlockquote';
  }
  return 'text';
};

export const typeFormatterPlaceholder = {
  text: 'Type “/” to select block',
  [BlockTypes.ordered_list_item]: '     Numbered List',
  [BlockTypes.unordered_list_item]: '     Bulleted List',
  // text: 'Type “/” to select block',
  [BlockTypes.ordered_list_item0]: 'Numbered List',
  [BlockTypes.ordered_list_item1]: 'Numbered List',
  [BlockTypes.ordered_list_item2]: 'Numbered List',
  [BlockTypes.unordered_list_item0]: 'Bulleted List',
  [BlockTypes.unordered_list_item1]: 'Bulleted List',
  [BlockTypes.unordered_list_item2]: 'Bulleted List',
  title: 'Title 1',
  title2: 'Title 2',
  title3: 'Title 3',
  callout: 'Callout',
};

export const isTextType = {
  text: true,
  [BlockTypes.ordered_list_item]: true,
  [BlockTypes.unordered_list_item]: true,
  [BlockTypes.ordered_list_item0]: true,
  [BlockTypes.ordered_list_item1]: true,
  [BlockTypes.ordered_list_item2]: true,
  [BlockTypes.unordered_list_item0]: true,
  [BlockTypes.unordered_list_item1]: true,
  [BlockTypes.unordered_list_item2]: true,
  title: true,
  title2: true,
  title3: true,
  callout: true,
};

export const typeFormatterLayers = {
  text: 'Text',
  page: 'Page',
  [BlockTypes.ordered_list_item]: 'Numbered List',
  [BlockTypes.unordered_list_item]: 'Bulleted List',
  title: 'Title 1',
  title2: 'Title 2',
  title3: 'Title 3',
  image: 'Image',
  video: 'Video',
  callout: 'Callout',
};

export const blockRenderMap = Immutable.Map({
  title: {
    element: 'h1',
  },
  title2: {
    element: 'h2',
  },
  title3: {
    element: 'h3',
  },
  'unordered-list-item': {
    wrapper: <ul />,

    element: 'li',
  },
  'unordered-list-item0': {
    wrapper: <ul />,

    element: 'li',
  },
  'unordered-list-item1': {
    wrapper: <ul />,

    element: 'li',
  },
  'unordered-list-item2': {
    wrapper: <ul />,

    element: 'li',
  },
  'ordered-list-item0': {
    wrapper: <ol />,
    element: 'li',
  },
  'ordered-list-item1': {
    wrapper: <ol />,
    element: 'li',
  },
  'ordered-list-item2': {
    wrapper: <ol />,
    element: 'li',
  },
  text: {
    element: 'div',
  },
  callout: {
    element: 'div',
  },
});

export const rawContent = (type, pageId) => {
  let text = '';
  if (type === BlockTypes.page) text = pageId;
  return {
    blocks: [
      {
        text,
        type,
        entityRanges: [{ offset: 0, length: 0, key: 'first' }],
      },
    ],
    entityMap: {
      first: {
        type,
        mutability: 'MUTABLE',
      },
    },
  };
};

export const blocks = (type = BlockTypes.text, pageId) => convertFromRaw(rawContent(type, pageId));

// export const createFirstBlock = () => ({
//   id: uuid(),
//   state: EditorState.createWithContent(blocks()),
//   type: BlockTypes.text,
//   position: DEFAULT_POSITION_STEP,
// });

export const createLinkContent = (text) => {
  const linkText = text.trim();
  const editorState = EditorState.createEmpty();

  const newBlockKey = genKey();
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const currentBlock = contentState.getBlockForKey(
    selectionState.getStartKey(),
  );
  const blockMap = contentState.getBlockMap();
  const blocksBefore = blockMap.toSeq().takeUntil((v) => v === currentBlock);
  const newBlock = new ContentBlock({
    key: newBlockKey,
    type: 'unstyled',
    text: linkText,
    characterList: new List(
      Repeat(CharacterMetadata.create(), linkText.length),
    ),
  });

  const newBlockMap = blocksBefore
    .concat([[newBlockKey, newBlock]])
    .toOrderedMap();

  const selection = editorState.getSelection();

  const newContent = contentState.merge({
    blockMap: newBlockMap,
    selectionBefore: selection,
    selectionAfter: selection.merge({
      anchorKey: newBlockKey,
      anchorOffset: 0,
      focusKey: newBlockKey,
      focusOffset: 0,
      isBackward: false,
    }),
  });

  let newEditorState = EditorState.push(editorState, newContent, 'split-block');

  let newSelection = new SelectionState({
    anchorKey: newBlockKey,
    anchorOffset: 0,
    focusKey: newBlockKey,
    focusOffset: linkText.length,
  });

  newEditorState = EditorState.forceSelection(newEditorState, newSelection);
  const newContentState = newEditorState.getCurrentContent();
  const contentStateWithEntity = newContentState.createEntity(
    'LINK',
    'IMMUTABLE',
    { url: linkText },
  );

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  newEditorState = EditorState.set(newEditorState, {
    currentContent: contentStateWithEntity,
  });
  newEditorState = RichUtils.toggleLink(
    newEditorState,
    newEditorState.getSelection(),
    entityKey,
  );
  newSelection = new SelectionState({
    anchorKey: newBlockKey,
    anchorOffset: linkText.length,
    focusKey: newBlockKey,
    focusOffset: linkText.length,
  });

  return EditorState.forceSelection(newEditorState, newSelection);
};

export function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null
      && contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

// const REGEX = /\#[\w\u0590-\u05ff]+/g;
//
// function findWithRegex(regex, contentBlock, callback) {
//   const text = contentBlock.getText();
//   let matchArr;
//   let start;
//   while ((matchArr = regex.exec(text)) !== null) {
//     start = matchArr.index;
//     callback(start, start + matchArr[0].length);
//   }
// }
//
// function hashtagStrategy(contentBlock, callback, contentState) {
//   findWithRegex(REGEX, contentBlock, callback);
// }
//
// const HashtagSpan = props => {
//   return (
//     <span {...props} style={{ color: 'red' }}>
//       {props.children}
//     </span>
//   );
// };
//
// export const hashtagsDecorator = new CompositeDecorator([
//   {
//     strategy: hashtagStrategy,
//     component: HashtagSpan,
//   },
// ]);

// const HANDLE_REGEX = /\@[\w]+/g;
// const HASHTAG_REGEX = /\#[\w\u0590-\u05ff]+/g;

// export function handleStrategy(contentBlock, callback, contentState) {
//   findWithRegex(HANDLE_REGEX, contentBlock, callback);
// }
//
// export function hashtagStrategy(contentBlock, callback, contentState) {
//   findWithRegex(HASHTAG_REGEX, contentBlock, callback);
// }
//
// function findWithRegex(regex, contentBlock, callback) {
//   const text = contentBlock.getText();
//   let matchArr; let
//     start;
//   while ((matchArr = regex.exec(text)) !== null) {
//     start = matchArr.index;
//     callback(start, start + matchArr[0].length);
//   }
// }
//
// export const HandleSpan = (props) => {
//   return (
//     <span
//       style={styles.handle}
//       data-offset-key={props.offsetKey}
//     >
//       {props.children}
//     </span>
//   );
// };

// export const HashtagSpan = (props) => {
//   return (
//     <span
//       style={styles.hashtag}
//       data-offset-key={props.offsetKey}
//     >
//       {props.children}
//     </span>
//   );
// };

// export const hashtagsDecorator = new CompositeDecorator([
//   {
//     strategy: handleStrategy,
//     component: HandleSpan,
//   },
//   {
//     strategy: hashtagStrategy,
//     component: HashtagSpan,
//   },
// ]);
//
// const styles = {
//   root: {
//     fontFamily: '\'Helvetica\', sans-serif',
//     padding: 20,
//     width: 600,
//   },
//   editor: {
//     border: '1px solid #ddd',
//     cursor: 'text',
//     fontSize: 16,
//     minHeight: 40,
//     padding: 10,
//   },
//   button: {
//     marginTop: 10,
//     textAlign: 'center',
//   },
//   handle: {
//     color: 'rgba(98, 177, 254, 1.0)',
//     direction: 'ltr',
//     unicodeBidi: 'bidi-override',
//   },
//   hashtag: {
//     color: 'rgba(95, 184, 138, 1.0)',
//   },
// };

export const isEmptyBlockHtml = (html) => html && html.includes('<div class="public-DraftEditorPlaceholder-inner"');
