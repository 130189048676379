import React from 'react';
import classNames from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { ReactComponent as RandomSvg } from '../../../images/icons/unpublish.svg';
import styles from './helpers.module.scss';
import { DATA_PARENTS, DEFAULT_TITLE } from '../../constants';
import { ReactComponent as PageBadge20Svg } from '../../../images/icons/page_element_TOC_20.svg';
import { ReactComponent as LockSvg } from '../../../images/icons/player/lock_16.svg';
import { ReactComponent as DownloadSvg } from '../../../images/icons/player/download_16.svg';
import { ReactComponent as Check7Svg } from '../../../images/icons/player/check_green.svg';
import { ReactComponent as CommentSvg } from '../../../images/icons/player/comment_16.svg';
import { playerTimeHelper } from '../DisplayValuesConverters/time';

import { Icons } from './icons';
import { DrawTime } from '../../../pages/Player/TimerComponent';
import { DisplayPreview } from '../../../pages/Player/DisplayPreview';
import { getNameFromState } from '../../draftJsHelpers';
import i18n from '../../../i18n';

const cn = classNames.bind(styles);

const Digit = (props) => <div className={cn('digit')}>{props.children}</div>;
const Dots = (props) => <div className={cn('dots')}>{props.children}</div>;

export const Icon = () => (
  <div>
    <RandomSvg />
  </div>
);

const itemNamePicker = (item) => {
  if (item?.type === 'elementText') {
    return getNameFromState(item) || 'Text';
  }
  return (
    item?.title
    || item?.libraryComponent?.title
    || i18n.t(DEFAULT_TITLE.PlaylistElement)
  );
};

export const TOCItemButtonUI = ({ onClickHandler, item = {} }) => {
  return (
    <div
      data-parent={DATA_PARENTS.TableOfContents}
      className={cn('button_wrapper')}
      onClick={(event) => {
        event.stopPropagation();
        onClickHandler(event);
      }}
    >
      <div className={cn('button')}>
        <div data-parent={DATA_PARENTS.TableOfContents} className={cn('title')}>
          {item.isFinished ? 'RESUME' : 'CLOSED'}
        </div>
      </div>
    </div>
  );
};

export const TOCItemUI = ({
  item,
  isSelected,
  onClickHandler,
  isHidden,
  itemStylesObject,
}) => {
  const title = itemNamePicker(item);

  return (
    <div
      data-parent={DATA_PARENTS.TableOfContents}
      className={cn('item', {
        selected: isSelected,
        greyElement:
          !item?.type
          || item?.type === 'upload'
          || (item?.type === 'elementText' && !title),
        leftShift: item?.type === 'elementText',
        hidden: isHidden,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onClickHandler(item);
      }}
    >
      <div data-parent={DATA_PARENTS.TableOfContents} className={cn('title')}>
        {`${itemStylesObject?.number ? `${itemStylesObject?.number}.` : ''} ${
          title || 'Text element'
        }`}
      </div>
      <div
        data-parent={DATA_PARENTS.TableOfContents}
        className={cn('icon_container')}
      >
        {Icons(item)}
        <div className={cn('circle', { read: item.isRead })} />
      </div>
    </div>
  );
};
export const TOCPlayerItemUI = ({
  item,
  isSelected,
  onClickHandler,
  isHidden,
  itemStylesObject,
  index,
  isCardView,
  ...props
}) => {
  const isLocked = item?.type === 'elementText'
    || item?.libraryComponent?.type === 'page'
    || item?.libraryComponent?.type === 'link'
    || item?.isRemixLocked;
  const { itemNumber } = useParams();
  // const { isCardView } = useSelector(state => state.currentPage);
  const title = itemNamePicker(item);
  const duration = playerTimeHelper(item.duration);

  const downloadItem = (event) => {
    event.stopPropagation();
    if (!isLocked) {
      props.downloadFile(event, item);
    }
  };
  const shouldHaveIndex = itemStylesObject?.customIndex;

  return (
    <div
      className={cn('TOCPlayerPlaylistCardView', { row_view: !isCardView })}
      // {
      // selected: isSelected,
      // greyElement: (!item?.type || item?.type === 'upload' || (item?.type === 'elementText' && !title)),
      // leftShift: item?.type === 'elementText',
      // hidden: isHidden }
      // )}
    >
      {shouldHaveIndex && (
        <div className={cn('number', { row_view: !isCardView })}>
          <div>{itemStylesObject?.customIndex}</div>
        </div>
      )}
      <div className={cn('six_dot_permanent_line')}>
        <div className={cn('line', { row_view: !isCardView }, `${itemStylesObject?.style}`)} />
      </div>
      {!shouldHaveIndex && (
        <div className={cn('number_placeholder', { row_view: !isCardView })} />
      )}

      <div
        className={cn('preview_container', { row_view: !isCardView })}
        onClick={() => onClickHandler(index)}
      >
        <div
          className={cn('preview', {
            isRead: item.isViewed,
            active: +itemNumber === index + 1,
            row_view: !isCardView,
          })}
        >
          <DisplayPreview
            libraryComponent={item.libraryComponent}
            linkPage={item}
            isPlayerTOCPreview
            isCardView={isCardView}
          />
          {item.isViewed && (
            <div className={cn('grey', { row_view: !isCardView })} />
          )}
        </div>


        {item.isViewed && (
          <div className={cn('isRead_circle', { row_view: !isCardView })}>
            <Check7Svg />
          </div>
        )}
        <div className={cn('duration_label', { row_view: !isCardView })}>
          <div className={cn('duration_text')}>
            <DrawTime
              displayTime={duration}
              DigitComponent={Digit}
              DotsComponent={Dots}
            />
          </div>
        </div>
        <div className={cn('row_info_container', { row_view: !isCardView })}>
          <div className={cn('duration_text')}>
            <DrawTime
              displayTime={duration}
              DigitComponent={Digit}
              DotsComponent={Dots}
            />
          </div>

          <div className={cn('button', 'types')}>{Icons(item)}</div>

          <div className={cn('button', 'comments')}>
            <CommentSvg />
          </div>

          <div
            className={cn('button', 'action', { isLocked })}
            onClick={(event) => downloadItem(event)}
          >
            {isLocked && <LockSvg />}
            {!isLocked && <DownloadSvg />}
          </div>
        </div>
        <div className={cn('title', { row_view: !isCardView })}>{title}</div>
      </div>

      <div className={cn('icons', { row_view: !isCardView })}>
        <div className={cn('button', 'types')}>{Icons(item)}</div>
        <div className={cn('button', 'comments')}>
          <CommentSvg />
        </div>
        <div
          className={cn('button', 'action', { isLocked })}
          onClick={(event) => downloadItem(event)}
        >
          {isLocked && <LockSvg />}
          {!isLocked && <DownloadSvg />}
        </div>
      </div>
    </div>
  );
};

export const UsedInPageUI = ({ item, currentPageId, onClickHandler }) => {
  return (
    <div
      data-parent={DATA_PARENTS.TableOfContents}
      className={cn('item', {
        selected: item.id === currentPageId,
      })}
      onClick={(e) => {
        e.stopPropagation();
        onClickHandler(item.id);
      }}
    >
      <div data-parent={DATA_PARENTS.TableOfContents} className={cn('title')}>
        {item.title || i18n.t(DEFAULT_TITLE.Page)}
      </div>
      <div
        data-parent={DATA_PARENTS.TableOfContents}
        className={cn('icon_container')}
      >
        <PageBadge20Svg />
      </div>
    </div>
  );
};
