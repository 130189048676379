import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const GroupIcon = styled.div`
  display: flex;
  margin-right: 2px;

  position: relative;
  ${({ forContacts }) => (forContacts ? '' : ' padding-top: 4px;')}
  ${({ lowMode }) => (lowMode
    ? `
    margin-top: -3px;
    margin-right:24px;
  `
    : '')}
`;

export const Divider = styled.div`
  ${({ isHidden }) => (isHidden
    ? `
     visibility: hidden;
  `
    : '')}
  width: 1px;
  height: 22px;
  background: rgba(29, 29, 29, 0.08);
  margin-right: 15px;
  ${({ ms }) => (ms
    ? `
     margin-left:12px;
  `
    : '')}
  ${({ zeroMargins }) => (zeroMargins
    ? `
     margin: 0;
  `
    : '')}
  margin-top: 5px;
  ${({ lowMode }) => (lowMode
    ? `
    margin-right: 11px;
    margin-left: 14px;
    margin-top: 3px;
  `
    : '')}
  ${({ ms, lowMode }) => (ms && lowMode
    ? `
    margin-right: 12px;
    margin-left: 12px;
  `
    : '')}
`;
