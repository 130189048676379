import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';


import { ReactComponent as ChevronSvg } from '../../images/icons/chevron_down_16.svg';
import { FILTER_CARD, FILTER_CARD_READABLE } from './utils';

const StatusDropdownShell = styled.div`
  position: relative;
`;
const StatusDropdown = styled.div`
  height: 30px;
  width: max-content;
  padding: 7px 8px 7px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  position: relative;
  display: flex;
  cursor: pointer;

  ${({ isActive }) => (isActive
    ? ` 
      background: #FFEEBF;
  ` : ''
  )}

  ${({ isEmptyState }) => (isEmptyState
    ? ` 
      position: absolute;
      right: 30px;
  ` : ''
  )}
  
    :hover {
      background: #FFEEBF;
    }

    p {
      color: #484343;
      font-size: 14px;
      font-weight: 400;
      line-height: 12px;
      width: 100%;
      text-transform: uppercase;
    }
`;

const DropdownWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: -9px;
  height: 60px;
`;

const Menu = styled.div`
  position: absolute;
  width: 148px;
  height: 114px;
  right: 8px;
  top: 51px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 108;
  padding: 12px 0 ;

`;

const Section = styled.div`
  padding: 5px 16px;
  cursor: pointer;
  height: 30px;
  display: flex;
  align-items: center;
    
  :hover {
    background: #FFEEBF;
  }
`;


const InboxDropdown = ({ pickStatus }) => {
  return (
    <Menu>
      <Section isTop onClick={() => pickStatus(FILTER_CARD.ALL)}>
        {FILTER_CARD_READABLE[FILTER_CARD.ALL]}
      </Section>
      <Section onClick={() => pickStatus(FILTER_CARD.READ)}>
        {FILTER_CARD_READABLE[FILTER_CARD.READ]}
      </Section>
      <Section isBottom onClick={() => pickStatus(FILTER_CARD.UNREAD)}>
        {FILTER_CARD_READABLE[FILTER_CARD.UNREAD]}
      </Section>
    </Menu>
  );
};

const Dropdown = ({ setCurrentFilter, currentFilter }) => {
  const dropdownRef = useRef();

  const [isDropdownVisible, setDropdownVisible] = useState(false);


  useEffect(() => {
    const listener = (event) => {
      if (!dropdownRef.current || dropdownRef.current.contains(event.target)) {
        return;
      }
      setDropdownVisible(false);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [dropdownRef.current, isDropdownVisible]);

  const pickStatus = (filter) => {
    setCurrentFilter(filter);
    setDropdownVisible(false);
  };
  return (
    <StatusDropdownShell>
      <StatusDropdown
        onClick={() => setDropdownVisible(!isDropdownVisible)}
        isActive={isDropdownVisible}
      >
        <p>{FILTER_CARD_READABLE[currentFilter]}</p>
        <ChevronSvg />
      </StatusDropdown>
      {isDropdownVisible && (
      <DropdownWrapper ref={dropdownRef}>
        <InboxDropdown pickStatus={pickStatus} />
      </DropdownWrapper>
      )}
    </StatusDropdownShell>
  );
};

export default Dropdown;
