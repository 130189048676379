import { dateGroupEnum } from './filterNames';


interface GroupedPlaylists {
  todayPlayLists: any;
  yesterdayPlayLists: any;
  currentWeekPlayLists: any;
  previousWeekPlayLists: any;
  currentMonthPlayLists: any;
  previousMonthPlaylists: any;
  currentYearPlaylists: any;
  previousYearPlaylists: any;
  aLongTimeAgoPlaylists: any;
}

export const groupPlaylistByDayModify = (playlistObj: any): GroupedPlaylists | undefined => {
  if (!playlistObj) {
    return undefined;
  }

  const isArray = Array.isArray(playlistObj);
  const keys = Object.keys(playlistObj);
  const playlistArr = keys.map(key => ({ key, playlist: playlistObj[key] }));

  if (!playlistArr.length) {
    return undefined;
  }

  const todayPlayLists: any = isArray ? [] : {};
  const yesterdayPlayLists: any = isArray ? [] : {};
  const currentWeekPlayLists: any = isArray ? [] : {};
  const previousWeekPlayLists: any = isArray ? [] : {};
  const currentMonthPlayLists: any = isArray ? [] : {};
  const previousMonthPlaylists: any = isArray ? [] : {};
  const currentYearPlaylists: any = isArray ? [] : {};
  const previousYearPlaylists: any = isArray ? [] : {};
  const aLongTimeAgoPlaylists: any = isArray ? [] : {};

  playlistArr.forEach((playlist) => {
    switch (playlist.playlist.belongsToGroup) {
      case dateGroupEnum.TodayPeriod:
        if (isArray) {
          todayPlayLists.push(playlist.playlist);
        } else todayPlayLists[playlist.key] = playlist.playlist;
        break;
      case dateGroupEnum.YesterdayPeriod:
        if (isArray) {
          yesterdayPlayLists.push(playlist.playlist);
        } else yesterdayPlayLists[playlist.key] = playlist.playlist;
        break;
      case dateGroupEnum.CurrentWeekPeriod:
        if (isArray) {
          currentWeekPlayLists.push(playlist.playlist);
        } else currentWeekPlayLists[playlist.key] = playlist.playlist;
        break;
      case dateGroupEnum.PreviousWeekPeriod:
        if (isArray) {
          previousWeekPlayLists.push(playlist.playlist);
        } else previousWeekPlayLists[playlist.key] = playlist.playlist;
        break;
      case dateGroupEnum.CurrentMonthPeriod:
        if (isArray) {
          currentMonthPlayLists.push(playlist.playlist);
        } else currentMonthPlayLists[playlist.key] = playlist.playlist;
        break;
      case dateGroupEnum.PreviousMonthPeriod:
        if (isArray) {
          previousMonthPlaylists.push(playlist.playlist);
        } else previousMonthPlaylists[playlist.key] = playlist.playlist;
        break;
      case dateGroupEnum.CurrentYearPeriod:
        if (isArray) {
          currentYearPlaylists.push(playlist.playlist);
        } else currentYearPlaylists[playlist.key] = playlist.playlist;
        break;
      case dateGroupEnum.PreviousYearPeriod:
        if (isArray) {
          previousYearPlaylists.push(playlist.playlist);
        } else previousYearPlaylists[playlist.key] = playlist.playlist;
        break;
      default:
        if (isArray) {
          aLongTimeAgoPlaylists.push(playlist.playlist);
        } else aLongTimeAgoPlaylists[playlist.key] = playlist.playlist;
        break;
    }
  });

  return {
    todayPlayLists,
    yesterdayPlayLists,
    currentWeekPlayLists,
    previousWeekPlayLists,
    currentMonthPlayLists,
    previousMonthPlaylists,
    currentYearPlaylists,
    previousYearPlaylists,
    aLongTimeAgoPlaylists,
  };
};
