import styled from 'styled-components/macro';

export const MainHeaderShell = styled.div`
  display: flex;
  width: calc(100vw - 260px);
  background-color: #f6f6f5;
  height: 66px;
  position: fixed;
  left: 260px;
  z-index: 103;
  padding-left: 24px;
  position: relative;
  align-items: center;
`;

export const MainHeaderDivider = styled.div`
  height: 1px;
  width: calc(100vw - 360px);
  position: absolute;
  bottom: 0;
  left: 30px;
  background: rgba(29, 29, 29, 0.08);
`;
