import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExpandIcon } from '../../../../../images/2023/svg/upv/expand_18_18.svg';
import { ReactComponent as CollapseIcon } from '../../../../../images/2023/svg/upv/collapse_18_18.svg';
import CloseRoundedBtnWithCross from '../../../../../components/SharedComponents/CloseRoundedBtnWithCross';
import { actionToggleSideBarExpand } from '../../../../../redux/currentPage/action';
import { ExpandWrapper, MakerLibHeaderWrapper, TitleWrapper } from './styled';


const Header = ({ close, dataP, isExpanded }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const toggleExpand = () => {
    dispatch(actionToggleSideBarExpand(!isExpanded));
  };

  return (
    <MakerLibHeaderWrapper data-parent={dataP}>
      <ExpandWrapper data-parent={dataP} onClick={toggleExpand}>
        {' '}
        {isExpanded ? <CollapseIcon data-parent={dataP} /> : <ExpandIcon data-parent={dataP} /> }
      </ExpandWrapper>
      <TitleWrapper>{t('libraryT')}</TitleWrapper>
      <CloseRoundedBtnWithCross
        top={0}
        right={31}
        moveTop={8}
        onClick={close}
      />
    </MakerLibHeaderWrapper>
  );
};

export default Header;
