import SupportAction from './types';
import { CurrentPage } from '../currentPage/types';
import { ServiceUser } from '../user/types';
import Contacts from '../contacts/types';
import { EditPage } from '../pages/types';
import { ContentActionType } from '../content/contentTypes';
import { LibraryComponents } from '../library/types';
import { InboxAction } from '../inbox/types';
import { deleteInboxNotification, updateUnreadState } from './reduxSupportUtils';
import downloadStatus from '../../utils/dataUtil';
import { getLayersForPage } from '../../shared/page/utils';
import EditPlaylist from '../playlists/types';

const defaultState = {
  isShowSettings: false,
  hashtags: {},
  anchorId: null,
  foundHashTags: [],
  isLoadedComponent: false,
  blocks: [],
  layers: [],
  users: [],
  draggableBlocks: [],
  sideBars: {
    isPages: false,
    isLibrary: false,
  },
  backlightItem: '',
  warningDelete: {},
  sharedModalAnchor: 0,
  contactNicknameMap: {},
  requestSpinner: 'init',
  isUnseenUpload: false,
  isDatabaseSavingSequenceEmpty: true,
  tempContent: {},
  isRequestSentForReadChannel: {},
  unseenInChannel: {},
  unseenInbox: {},
  unseenOutbox: {},
  unseenInvite: {},
  unseenPlaylistMapInChannel: {},
  unseenPlaylistManagerMapInAll: {},
  unseenPagesManagerMapNotification: {},
  unseenPlaylistManagerMapInAllNotification: {},
  unseenPlaylistMapInChannelNotification: {},
  unseenPagesManagerMap: {},
  tagsToUpdate: {
    add: {},
    delete: {},
  },
  toBeScrolledTo: null,
  loadStatusContact: downloadStatus.pending,

};

export const supportReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SupportAction.AddMessage: {
      return { ...state, ...payload };
    }
    case SupportAction.MultiSelectActiveR: {
      return { ...state, ...payload };
    }
    case SupportAction.UpdateSharedModalAnchor: {
      if (payload.sharedModalAnchor !== state.sharedModalAnchor) {
        return { ...state, sharedModalAnchor: payload.sharedModalAnchor };
      } return state;
    }
    case SupportAction.UpdateComponentR: {
      return { ...state, component: payload.component };
    }
    case Contacts.AddContactMap: {
      // const contactNicknameMap = { ...state.contactNicknameMap };
      // const [id] = Object.keys(payload);
      // const item = payload[id];
      // contactNicknameMap[id] = item;
      return { ...state, contactNicknameMap: payload.contactNicknameMap };
    }
    case Contacts.AddSingleToContactMap: {
      const { creatingContactData } = payload;
      return {
        ...state,
        contactNicknameMap: {
          ...state.contactNicknameMap,
          [creatingContactData.id]: {
            ...creatingContactData,
            username: creatingContactData.email,
          },
        },
      };
    }
    case Contacts.UpdatePinState: {
      const { userId } = payload;
      const id = state.contactNicknameMap[userId] ? userId
        : Object.values(state.contactNicknameMap).find(elem => elem.contactId === userId)?.id;
      return {
        ...state,
        contactNicknameMap: {
          ...state.contactNicknameMap,
          [id]: {
            ...state.contactNicknameMap[id],
            isUnpin: !state.contactNicknameMap[id].isUnpin,
          },
        },
      };
    }
    case Contacts.ChangeContactItemPositionR: {
      const { id, newPosition } = payload;
      return {
        ...state,
        contactNicknameMap: {
          ...state.contactNicknameMap,
          [id]: {
            ...state.contactNicknameMap[id],
            position: newPosition,
          },
        },
      };
    }
    case EditPlaylist.OpenSmartFile: {
      const { wrapperId } = payload;
      const newUnseenOutbox = { ...state.unseenOutbox };
      delete newUnseenOutbox[wrapperId];
      return {
        ...state,
        unseenOutbox: newUnseenOutbox,
      };
    }
    case SupportAction.SetComponentIsReady: {
      return { ...state, readyToScroll: payload?.value };
    }
    case Contacts.InitialContactsLoadR: {
      const { contactNicknameMap } = payload;
      return { ...state, loadStatusContact: downloadStatus.success, contactNicknameMap };
    }
    case SupportAction.UploadUnseenOutboxR: {
      return { ...state, unseenOutbox: payload.unseenOutbox };
    }
    case SupportAction.UploadUnseenR: {
      const { data } = payload;

      return {
        ...state,
        isUnseenUpload: true,
        ...data,
      };
    }
    case SupportAction.AddUnseenNotificationPlaylist: {
      const { channelId, channelsIds = [], wrapperId, publishId } = payload;
      const unseenInChannel = { ...state.unseenInChannel };
      if (channelId) {
        unseenInChannel[channelId] = { ...state.unseenInChannel[channelId] };
        unseenInChannel[channelId][wrapperId] = { playlistId: publishId };
      }
      if (channelsIds.length) {
        channelsIds.forEach(nestedChannelId => {
          unseenInChannel[nestedChannelId] = { ...state.unseenInChannel[channelId] };
          unseenInChannel[nestedChannelId][wrapperId] = { playlistId: publishId };
        });
      }
      const unseenPlaylistManagerMapInAll = {
        ...state.unseenPlaylistManagerMapInAll,
      };
      unseenPlaylistManagerMapInAll[wrapperId] = {
        type: 'sharing',
        playlistId: wrapperId,
      };
      const unseenPlaylistMapInChannel = {
        ...state.unseenPlaylistMapInChannel,
      };
      const unseenPlaylistMapInChannelNotification = {
        ...state.unseenPlaylistMapInChannelNotification,
      };
      const unseenPlaylistManagerMapInAllNotification = {
        ...state.unseenPlaylistManagerMapInAllNotification,
      };
      unseenPlaylistManagerMapInAllNotification[wrapperId] = {
        type: 'sharing',
        playlistId: wrapperId,
      };

      if (channelId || channelsIds.length) {
        unseenPlaylistMapInChannel[wrapperId] = true;
        unseenPlaylistMapInChannelNotification[wrapperId] = true;
      }
      return {
        ...state,
        unseenInChannel,
        unseenPlaylistMapInChannel,
        unseenPlaylistManagerMapInAll,
        unseenPlaylistManagerMapInAllNotification,
        unseenPlaylistMapInChannelNotification,
      };
    }
    case SupportAction.AddUnseenNotificationPage: {
      const { libraryComponentId } = payload;
      const unseenPagesManagerMap = { ...state.unseenPagesManagerMap };
      const unseenPagesManagerMapNotification = { ...state.unseenPagesManagerMapNotification };
      unseenPagesManagerMap[libraryComponentId] = true;
      unseenPagesManagerMapNotification[libraryComponentId] = true;

      return {
        ...state,
        unseenPagesManagerMap,
        unseenPagesManagerMapNotification,
        isRequestSentForReadPages: false,
      };
    }
    case ContentActionType.removePlaylistAfterSageR: {
      const { channelId, wrapperId, type } = payload;
      if (!channelId || !state.unseenInChannel[channelId] || type !== 'channel') return state;

      const unseenInChannel = {
        ...state.unseenInChannel,
        [channelId]: { ...state.unseenInChannel[channelId] },
      };
      if (unseenInChannel[channelId][wrapperId]) delete unseenInChannel[channelId][wrapperId];

      const unseenPlaylistManagerMapInAll = {
        ...state.unseenPlaylistManagerMapInAll,
      };
      if (unseenPlaylistManagerMapInAll[wrapperId]) delete unseenPlaylistManagerMapInAll[wrapperId];

      const unseenPlaylistManagerMapInAllNotification = {
        ...state.unseenPlaylistManagerMapInAllNotification,
      };
      delete unseenPlaylistManagerMapInAllNotification[wrapperId];
      const unseenPlaylistMapInChannel = { ...state.unseenPlaylistMapInChannel };
      if (unseenPlaylistMapInChannel[wrapperId]) delete unseenPlaylistMapInChannel[wrapperId];

      const unseenPlaylistMapInChannelNotification = { ...state.unseenPlaylistMapInChannel };
      delete unseenPlaylistMapInChannelNotification[wrapperId];

      return {
        ...state,
        unseenInChannel,
        unseenPlaylistMapInChannel,
        unseenPlaylistManagerMapInAll,
        unseenPlaylistManagerMapInAllNotification,
      };
    }
    case ContentActionType.removeSharedPage: {
      const { pageId } = payload;
      if (!state.unseenPagesManagerMap[pageId]) return state;

      const unseenPagesManagerMap = { ...state.unseenPagesManagerMap };
      const unseenPagesManagerMapNotification = { ...state.unseenPagesManagerMapNotification };
      delete unseenPagesManagerMapNotification[pageId];
      delete unseenPagesManagerMap[pageId];

      return { ...state, unseenPagesManagerMap, unseenPagesManagerMapNotification };
    }
    case SupportAction.ReadUnseenPlaylist: {
      return { ...state, isRequestSentForReadPlaylist: true };
    }
    case SupportAction.ReadOneUnseenPlaylist: {
      const { id } = payload;

      if (!state.unseenPlaylistManagerMapInAll[id]) return state;

      const unseenPlaylistManagerMapInAll = {
        ...state.unseenPlaylistManagerMapInAll,
      };
      delete unseenPlaylistManagerMapInAll[id];
      const unseenPlaylistManagerMapInAllNotification = {
        ...state.unseenPlaylistManagerMapInAllNotification,
      };
      delete unseenPlaylistManagerMapInAllNotification[id];


      return { ...state, unseenPlaylistManagerMapInAll, unseenPlaylistManagerMapInAllNotification };
    }

    case InboxAction.CardInBoxMarkUsReed: {
      const { item } = action.payload;
      return updateUnreadState(state, item, item.isRead);
    }
    case InboxAction.CardsInboxMarkAsReadOrUnread: {
      const { items, type } = payload;
      const updatedState = items.reduce((newState, item) => {
        return updateUnreadState(newState, item, type === 'unread');
      }, state);
      return updatedState;
    }
    case InboxAction.DeleteInboxCard: {
      const { item } = action.payload;
      return deleteInboxNotification(state, item);
    }
    case InboxAction.DeleteInboxCardR: {
      const { item } = action.payload;
      return deleteInboxNotification(state, item);
    }
    case InboxAction.DeleteInboxCards: {
      const { items } = action.payload;
      const updatedState = items.reduce((newState, item) => {
        return updateUnreadState(newState, item, type === 'unread');
      }, state);
      return updatedState;
    }
    case SupportAction.ReadOneUnseenPage: {
      const { id } = payload;

      if (!state.unseenPagesManagerMap[id]) return state;

      const unseenPagesManagerMap = { ...state.unseenPagesManagerMap };
      delete unseenPagesManagerMap[id];
      const unseenPagesManagerMapNotification = { ...state.unseenPagesManagerMapNotification };
      delete unseenPagesManagerMapNotification[id];

      return { ...state, unseenPagesManagerMap, unseenPagesManagerMapNotification };
    }
    case SupportAction.ReadUnseenChannel: {
      const { channelId } = payload;
      const isRequestSentForReadChannelOld = state.isRequestSentForReadChannel || {};
      const isRequestSentForReadChannel = {
        ...isRequestSentForReadChannelOld,
        [channelId]: true,
      };
      return { ...state, isRequestSentForReadChannel };
    }
    case SupportAction.DeleteUnseenPlaylistR: {
      const { playlistManagerId } = payload;
      const unseenPlaylistManagerMapInAll = {
        ...state.unseenPlaylistManagerMapInAll,
      };
      delete unseenPlaylistManagerMapInAll[playlistManagerId];
      const unseenPlaylistManagerMapInAllNotification = {
        ...state.unseenPlaylistManagerMapInAllNotification,
      };
      delete unseenPlaylistManagerMapInAllNotification[playlistManagerId];
      return { ...state, unseenPlaylistManagerMapInAll, unseenPlaylistManagerMapInAllNotification };
    }
    case SupportAction.DeleteUnseenPagesR: {
      const { pageId } = payload;
      const unseenPagesManagerMap = { ...state.unseenPagesManagerMap };
      delete unseenPagesManagerMap[pageId];
      const unseenPagesManagerMapNotification = { ...state.unseenPagesManagerMapNotification };
      delete unseenPagesManagerMapNotification[pageId];
      return { ...state, unseenPagesManagerMap, unseenPagesManagerMapNotification };
    }
    case SupportAction.DeleteUnseenPlaylistInChannel: {
      const { channelId, playlistManagerId } = payload;

      const unseenInCurrentChannel = { ...state.unseenInChannel[channelId] };
      delete unseenInCurrentChannel[playlistManagerId];

      const unseenPlaylistMapInChannel = {
        ...state.unseenPlaylistMapInChannel,
      };
      delete unseenPlaylistMapInChannel[playlistManagerId];
      const unseenPlaylistMapInChannelNotification = { ...state.unseenPlaylistMapInChannelNotification };
      delete unseenPlaylistMapInChannelNotification[playlistManagerId];
      return {
        ...state,
        unseenPlaylistMapInChannel,
        unseenPlaylistMapInChannelNotification,
        unseenInChannel: {
          ...state.unseenInChannel,
          [channelId]: { ...unseenInCurrentChannel },
        },
      };
    }
    case SupportAction.TriggerCountersUpdate: {
      return { ...state, countersNeedUpdate: payload.isActive };
    }
    case SupportAction.RemoveMessage: {
      return { ...state, ...payload };
    }
    case SupportAction.ToggleSettingsSideBar: {
      return { ...state, isShowSettings: !state.isShowSettings };
    }
    case SupportAction.ToggleComponentDescription: {
      const { fieldState } = payload;
      return { ...state, componentDescription: fieldState };
    }
    case SupportAction.SaveTempComponents: {
      const { content } = payload;
      return { ...state, tempContent: content };
    }
    case SupportAction.EraseTempComponents: {
      return { ...state, tempContent: {} };
    }
    case SupportAction.SaveComponentDescriptionData: {
      const { id, folderId, itemType } = payload;

      if (id) {
        return {
          ...state,
          componentDescriptionData: {
            id,
            folderId,
            itemType,
          },
        };
      }
      return { ...state, componentDescriptionData: null };
    }
    case SupportAction.SaveSharedComponentDescriptionData: {
      const { id } = payload;
      if (id) {
        return {
          ...state,
          componentDescriptionData: {
            id,
          },
        };
      }
      return { ...state, componentDescriptionData: null };
    }
    case SupportAction.SettingsSideBarOff: {
      return { ...state, isShowSettings: false };
    }
    case SupportAction.SettingsSideBarOn: {
      return { ...state, isShowSettings: true };
    }
    case SupportAction.JumpTo: {
      return { ...state, anchorId: payload.anchorId };
    }
    case SupportAction.JumpToCard: {
      return { ...state, columnAnchorId: payload.columnAnchorId };
    }
    case SupportAction.LoadingItem: {
      return { ...state, loadingItemId: payload };
    }
    case CurrentPage.ShowLibraryWidget: {
      return { ...state, isShowSettings: false, isLargeRightBar: false };
    }
    case CurrentPage.ShowPagesWidget: {
      return { ...state, isShowSettings: false, isLargeRightBar: false };
    }
    case SupportAction.UpdateHashTags: {
      return { ...state, foundHashTags: payload };
    }
    case SupportAction.SaveRedirectLink: {
      return { ...state, redirectLink: payload };
    }
    case SupportAction.ToggleRequestSpinner: {
      return { ...state, requestSpinner: payload };
    }
    case SupportAction.SetBlinkItemId: {
      return { ...state, blinkIdsArray: payload };
    }
    case CurrentPage.CreateLinkPageR: {
      const { pageData } = payload;
      if (!pageData?.id) {
        return { ...state };
      }
      const exIds = state.blinkIdsArray
        ? state.blinkIdsArray.concat([pageData?.id])
        : [pageData.id];
      return { ...state, blinkIdsArray: exIds };
    }
    case SupportAction.CleanBlinkItemIds: {
      return { ...state, blinkIdsArray: [] };
    }
    case ServiceUser.Logout: {
      return { ...defaultState };
    }
    case SupportAction.ClearSupportBlocks: {
      return { ...state, blocks: [], component: {}, isLoadedComponent: false };
    }
    case SupportAction.UpdateSupportBlocks: {
      const newState = {
        ...state,
        blocks: payload.blocks,
        isLoadedComponent: true,
      };
      if (payload.newBlockId) newState.newBlockId = payload.newBlockId;
      return newState;
    }
    case SupportAction.UpdateSupportLayers: {
      return { ...state, layers: payload };
    }
    case SupportAction.DeleteSupportEditableBlockRedux: {
      const index = state.blocks.findIndex((block) => block.id === payload.id);
      const blockState = [
        ...state.blocks.slice(0, index),
        ...state.blocks.slice(index + 1),
      ];
      return { ...state, blocks: blockState };
    }
    case SupportAction.ChangeSupportEditableBlockRedux: {
      const {
        payload: { id, newState, innerHtml },
      } = action;
      const block = state.blocks.find((item) => item.id === id);
      if (!block) return state;
      return {
        ...state,
        blocks: [
          ...state.blocks.filter((item) => item.id !== id),
          { ...block, state: newState, innerHtml },
        ],
      };
    }
    case SupportAction.UpdateSupportBlock: {
      const oldState = [...state.blocks];
      const index = state.blocks.findIndex((i) => i.id === payload.id);
      let block = { ...oldState[index] };

      if (block?.state) {
        if (payload?.width) {
          block.width = payload.width;
        }
        if (payload?.type) {
          block.type = payload.type;
        }
        if (payload?.libraryComponentToAttachId) {
          block.libraryComponentToAttachId = payload.libraryComponentToAttachId;
        }
        if (payload?.innerHtml) {
          block.innerHtml = payload.innerHtml;
        }
        if (payload?.state) {
          block.state = payload.state;
        }
        if (payload?.content) {
          block.content = payload.content;
        }
        if (payload?.imageUrl) {
          block = { ...block, ...payload.imageUrl };
        }
        const blockState = [
          ...state.blocks.slice(0, index),
          block,
          ...state.blocks.slice(index + 1),
        ];
        return { ...state, blocks: blockState };
      }
      return state;
    }
    case SupportAction.UpdateSupportDraggableBlocks: {
      return { ...state, draggableBlocks: payload };
    }
    case SupportAction.ChangeSupportStateComponentButton: {
      const {
        payload: { buttonState },
      } = action;
      const block = state.blocks.find((item) => item.id === buttonState.id);
      if (!block) return state;
      const newBlock = { ...block, innerHtml: buttonState.innerHtml };
      if (newBlock.state) newBlock.state = buttonState.state;
      return {
        ...state,
        blocks: [
          ...state.blocks.filter((item) => item.id !== buttonState.id),
          newBlock,
        ],
      };
    }
    case SupportAction.DeleteManyBlocksSupportRedux: {
      const newBlocks = state.blocks.filter(
        (item) => !action.payload.includes(item.id),
      );
      const newLayers = getLayersForPage(newBlocks);
      return { ...state, blocks: newBlocks, layers: newLayers };
    }
    case SupportAction.ToggleSideBar: {
      let place = '';
      if (payload === 'pages') place = 'isPages';
      if (payload === 'library') place = 'isLibrary';
      return {
        ...state,
        sideBars: { ...defaultState.sideBars, [place]: !state.sideBars[place] },
      };
    }
    case SupportAction.WarningDelete: {
      return { ...state, warningDelete: { ...payload } };
    }
    case SupportAction.SetActiveMenuItemId: {
      return { ...state, activeMenuItemId: payload?.id };
    }
    case SupportAction.SetColumnDropDownIsOpened: {
      return { ...state, columnDropDownId: payload.itemId };
    }
    case SupportAction.ShowContentLoader: {
      return { ...state, contentLoader: payload.state };
    }
    case SupportAction.SetZActiveItemIdR: {
      return { ...state, zActiveItemId: payload?.id };
    }
    case SupportAction.InitialDataFetch: {
      return { ...state, ...payload.support };
    }
    case SupportAction.ColorPalette.UpdateCustomColorR: {
      const { id, value, position } = payload;
      if (!value || !id) return state;
      const colors = {
        ...state.colors,
        [id]: { ...state.colors[id], color: value },
      };
      const newUsed = { ...state.usedColors };
      if (value) {
        delete newUsed[state.colors[id].color];
        newUsed[value] = true;
      }
      if (position) colors[id].position = position;
      return {
        ...state,
        colors,
        usedColors: newUsed,
      };
    }
    case SupportAction.ColorPalette.AddCustomColorR: {
      const { id, value, position } = payload;
      const color = {};
      color[id] = { id, color: value };
      if (position) color[id].position = position;
      return {
        ...state,
        colors: {
          ...state.colors,
          ...color,
        },
        colorsIds: [...state.colorsIds, id],
        usedColors: {
          ...state.usedColors,
          [value]: true,
        },
      };
    }
    case SupportAction.ColorPalette.DeleteCustomColorR: {
      const { id } = payload;
      const newColors = { ...state.colors };
      const newUsed = { ...state.usedColors };

      delete newColors[id];
      delete newUsed[id];

      return {
        ...state,
        colors: newColors,
        colorsIds: Object.keys(newColors),
        usedColors: newUsed,
      };
    }
    case SupportAction.ColorPalette.ResetAllCustomColorsR: {
      return {
        ...state,
        colors: {},
        colorsIds: [],
      };
    }
    case CurrentPage.UpdateSingleBlock: {
      const {
        index,
        foreignLastModifiedDate,
        foreignLastModifiedUserName,
        isOpenCreateComponent,
      } = payload;
      if (!isOpenCreateComponent) return state;
      const block = state.blocks[index];

      if (!block) return state;

      const newBlock = {
        ...block,
        state: {
          ...block.state,
          data: {
            ...block.state.data,
            foreignLastModifiedDate,
            foreignLastModifiedUserName,
            isUpdated: true,
          },
        },
      };

      return {
        ...state,
        blocks: state.blocks.map((blockItem, blockIndex) => (index === blockIndex ? newBlock : blockItem),
        ),
      };
    }
    case CurrentPage.RemoveBlock: {
      const { blockIdToDelete, isOpenCreateComponent } = payload;
      if (!isOpenCreateComponent) return state;
      const newBlocks = [
        ...state.blocks.filter((block) => block.id !== blockIdToDelete),
      ];
      return { ...state, blocks: newBlocks };
    }
    case CurrentPage.ReplaceSingleBlock: {
      const { block, isOpenCreateComponent } = payload;

      if (!isOpenCreateComponent) return state;

      return {
        ...state,
        blocks: [
          ...state.blocks.map((pageBlock) => (pageBlock.id === block.id ? block : pageBlock),
          ),
        ],
      };
    }
    case CurrentPage.InsertSingleBlock: {
      const { firstIndex, block, isOpenCreateComponent } = payload;

      if (!isOpenCreateComponent) return state;

      return {
        ...state,
        blocks: [
          ...state.blocks.slice(0, firstIndex),
          block,
          ...state.blocks.slice(firstIndex),
        ],
      };
    }

    case EditPage.ConvertBlockIntoAddMedia: {
      const { blockId, isOpenCreateComponent } = payload;
      if (!isOpenCreateComponent) return state;

      const newBlock = {
        ...state.blocks.find((block) => block.id === blockId),
        type: 'image',
        state: null,
      };
      const newBlocks = [
        ...state.blocks.filter((block) => block.id !== blockId),
        newBlock,
      ];
      const newLayers = getLayersForPage(newBlocks);

      return { ...state, blocks: newBlocks, layers: newLayers };
    }
    case EditPage.ConvertBlockIntoEmbed: {
      const { isOpenCreateComponent, block } = payload;
      if (!isOpenCreateComponent) return state;

      const newBlock = {
        ...state.blocks.find((item) => item.id === block.id),
        ...block,
      };
      const newBlocks = [
        ...state.blocks.filter((item) => item.id !== block.id),
        newBlock,
      ];
      const newLayers = getLayersForPage(newBlocks);

      return { ...state, blocks: newBlocks, layers: newLayers };
    }

    case EditPage.AddManyBlocksRedux: {
      const { isOpenCreateComponent, blocks } = payload;
      if (!isOpenCreateComponent) return state;

      const newBlocks = [...state.blocks, ...blocks];
      const newLayers = getLayersForPage(newBlocks);

      return { ...state, blocks: newBlocks, layers: newLayers };
    }
    case EditPage.DeleteManyBlocksRedux: {
      const { isOpenCreateComponent, blocks } = payload;
      if (!isOpenCreateComponent) return state;

      const ids = blocks.reduce((acc, item) => {
        acc[item.id] = true;
        return acc;
      }, {});

      const newBlocks = [...state.blocks.filter((item) => !ids[item.id])];
      const newLayers = getLayersForPage(newBlocks);

      return { ...state, blocks: newBlocks, layers: newLayers };
    }

    case EditPage.AddBlock: {
      const { block, isOpenCreateComponent } = payload;
      if (!isOpenCreateComponent) return state;

      const newBlock = {
        ...state.blocks.find((item) => item.id === block.id),
        ...block,
        type: 'image',
        state: null,
      };
      const newBlocks = [
        ...state.blocks.filter((item) => item.id !== block.id),
        newBlock,
      ];
      const newLayers = getLayersForPage(newBlocks);

      return { ...state, blocks: newBlocks, layers: newLayers };
    }
    case EditPage.RemoveBlock: {
      const { id, isOpenCreateComponent } = payload;
      if (!isOpenCreateComponent) return state;

      const newBlocks = [...state.blocks.filter((block) => block.id !== id)];
      const newLayers = getLayersForPage(newBlocks);

      return { ...state, blocks: newBlocks, layers: newLayers };
    }
    case ServiceUser._setDatabaseSequenceIsEmpty: {
      const { value } = payload;
      return { ...state, isDatabaseSavingSequenceEmpty: value };
    }
    case SupportAction.SetActiveTableItemId: {
      const { id, activeTableItemIdWithStatus } = payload;
      return { ...state, activeTableItemId: id, activeTableItemIdWithStatus };
    }
    case LibraryComponents.OpenMenuCreateEmbedLibraryComponent: {
      const open = payload?.open;
      if (open) return { ...state, openMenuCreateEmbedLibraryComponent: Math.random() };
      return { ...state, openMenuCreateEmbedLibraryComponent: 0 };
    }
    case SupportAction.updateTag.DeleteLocal: {
      const tagId = payload.tag.id;
      const isSelected = payload.isSelected;
      const newAdd = { ...state.tagsToUpdate.add };
      if (state.tagsToUpdate.add[tagId]) {
        delete newAdd[tagId];
        if (!isSelected) {
          return {
            ...state,
            tagsToUpdate: { ...state.tagsToUpdate, add: { ...newAdd } },
          };
        }
      }
      const newDelete = { ...state.tagsToUpdate.delete };
      newDelete[tagId] = tagId;
      return {
        ...state,
        tagsToUpdate: {
          ...state.tagsToUpdate,
          add: { ...newAdd },
          delete: { ...newDelete },
        },
      };
    }
    case SupportAction.updateTag.AddLocal: {
      const tagId = payload.tag.id;

      if (state.tagsToUpdate.delete[tagId]) {
        const newDelete = { ...state.tagsToUpdate.delete };
        delete newDelete[tagId];
        return {
          ...state,
          tagsToUpdate: { ...state.tagsToUpdate, delete: { ...newDelete } },
        };
      }
      const newAdd = { ...state.tagsToUpdate.add };
      newAdd[tagId] = tagId;
      return {
        ...state,
        tagsToUpdate: { ...state.tagsToUpdate, add: { ...newAdd } },
      };
    }
    case SupportAction.updateTag.AddTagsLocal: {
      const tagIds = payload.tagIds;
      const newAdd = { ...state.tagsToUpdate.add };
      tagIds.forEach((tagId) => {
        newAdd[tagId] = tagId;
      });
      return {
        ...state,
        tagsToUpdate: { ...state.tagsToUpdate, add: { ...newAdd } },
      };
    }
    case SupportAction.updateTag.Clear: {
      return { ...state, tagsToUpdate: { add: {}, delete: {} } };
    }
    case SupportAction.AddBacklightItem: {
      // payload
      const { backlightItemId } = payload;

      return { ...state, backlightItemId };
    }
    case SupportAction.ClearBacklightItem: {
      return { ...state, backlightItemId: '' };
    }
    case CurrentPage.AddElementToBeScrollTo: {
      return { ...state, toBeScrolledTo: payload };
    }
    case CurrentPage.ClearToBeScrollTo: {
      return { ...state, toBeScrolledTo: null };
    }
    case Contacts.ChangeContactR: {
      const { item } = payload;
      const newState = { ...state.contactNicknameMap };
      if (!newState[item.id]) return state;
      newState[item.id] = { ...newState[item.id], ...item };
      return { ...state, contactNicknameMap: newState };
    }
    case Contacts.RemoveContact: {
      const { id } = payload;
      const newContactNicknameMap = { ...state.contactNicknameMap };
      delete newContactNicknameMap[id];

      return {
        ...state,
        contactNicknameMap: newContactNicknameMap,
      };
    }
    case ContentActionType.updateCounterContacts: {
      const { contactsIds, smartFilesCount } = payload;
      const newContactsNocknameMap = { ...state.contactNicknameMap };
      contactsIds.forEach(id => {
        newContactsNocknameMap[id] = {
          ...newContactsNocknameMap[id],
          counter: (newContactsNocknameMap[id]?.counter ?? 0) + smartFilesCount,
        };
      });
      return { ...state, contactNicknameMap: newContactsNocknameMap };
    }
    default:
      return state;
  }
};
