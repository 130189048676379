import React, { useEffect } from 'react';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { useTranslation } from 'react-i18next';
import { titleConfigNoEditable, editorConfigNoEditable } from '../../shared/lexical/configs';
import LexicalClickableLinkPlugin from './plugin/LexicalClickableLinkPlugin';


function Placeholder() {
  const { t } = useTranslation();
  return <div className="editor-placeholder">{t('emptyT')}</div>;
}
const UpdateOutsidePlugin = ({ state }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!state) return;
    if (!Object.values(state).length) return;
    const oldState = JSON.stringify(editor.getEditorState().toJSON());
    const newState = JSON.stringify(state);
    if (newState === oldState) return;
    const editorState = editor.parseEditorState(
      state,
    );
    editor.setEditorState(editorState);
  }, [state]);
  return <></>;
};

const LexicalViewer = React.memo(function LexicalViewer({
  state,
}) {
  return (

    <LexicalComposer initialConfig={editorConfigNoEditable}>
      <RichTextPlugin
        contentEditable={<ContentEditable readOnly className="editor-input" />}
        placeholder={<Placeholder />}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <LexicalClickableLinkPlugin />

      <UpdateOutsidePlugin state={state} />
    </LexicalComposer>

  );
});

export default LexicalViewer;
