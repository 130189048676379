import React from 'react';
import { AvatarPopOver, AvatarWrapper } from './styled';
import { calculateSingleUserAvatar, calculateTextForLastAvaPlaceholder } from './helpers';

const LastAvaPlaceholder = ({ index, users }) => {
  return (
    <AvatarWrapper left={index * 10}>
      <AvatarPopOver
        imageIsFirst={false}
        imageIsImage={false}
        className="ava_popover"
      >{calculateTextForLastAvaPlaceholder(users)}
      </AvatarPopOver>
      {calculateSingleUserAvatar(`+${users.length}`)}

    </AvatarWrapper>
  );
};

export default LastAvaPlaceholder;
