import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIconCircle } from '../../../images/icons/icon_20/plus.svg';

import { ReactComponent as SectionChevronIcon } from '../../../images/icons/tiny_chevron_down_filled.svg';
import { MainNavigate } from '../../../navigate/navigate';
import {
  Counter,
  IconPlace,
  Info,
  SectionChevronIconWrapper,
  MajorSelector,
  SelectorWrapper,
  MajorText,
  Selector,
  CollapsingSectionWrapper, PlusWrapperCircle,
} from '../styled';
import { DATA_PARENTS } from '../../../utils/constants';
import { convertToSmartNumbers, calcSelectorForSmartfiles } from '../../../utils/helpers';
import { sortOptionChooser } from '../../../utils/sort/sortTypeChooser';
import { actionSwitchContentSortType } from '../../../redux/content/actions';


/* The function has no use after implementing LanguageContext
const calculateDisplayedSelector = (itemType) => {
  if (itemType === 'shared') return 'Shared To Me';
  if (itemType === 'sharedByMe') return sharedByMe;
  if (itemType === 'recently_viewed') return recently_viewed;
  return itemType;
};
*/

const sieveOptions = MainNavigate.smartfiles?.selectorsOrder;
const itemHeight = 33;
const smartItemsHeight = `${(sieveOptions.length + 1) * itemHeight}px`;

const SmartfilesSection = ({
  createNewPlaylist,
  sortOptions,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const [dragOverItem, setDragOverItem] = useState(null);
  const [isSmartfilesCollapsed, setSmartfilesCollapsed] = useState(false);
  const isContent = history.location.pathname.startsWith('/content');

  const {
    activeNavSlider: place = 'smartfiles',
    selectorType,
    counters,
  } = useSelector((state) => state.content);
  const isSmartViewOrTag = isContent
    && (place === 'tag'
      || place === 'dynamicCollectionNew'
      || place === 'dynamicCollection');

  const convert = (itemType, customPlace) => {
    if (counters[`${customPlace ?? place}/${itemType}`]) {
      if (itemType === 'sharedByMe') {
        return counters[`${customPlace ?? place}/${itemType}`] + counters[`${customPlace ?? place}/shared`];
      }
      return counters[`${customPlace ?? place}/${itemType}`];
    }
    return 0;
  };


  const setSieve = (value) => {
    const sortName = sortOptionChooser('smartfiles', value);
    if (sortName && sortOptions[sortName]) {
      dispatch(actionSwitchContentSortType(sortOptions[sortName]));
    }
    history.push(`/content/smartfiles/${value}`);
  };

  const activeSFOption = sieveOptions.find(type => history
    .location.pathname.includes(`smartfiles/${calcSelectorForSmartfiles(type)}`));

  const isShowPlaylistField = isSmartfilesCollapsed && isContent && activeSFOption;

  const onDragOver = (e, currentHover) => {
    e.stopPropagation();
    e.preventDefault();
    if (!dragOverItem) setDragOverItem(currentHover);
    e.dataTransfer.dropEffect = 'none';
  };

  const onDragEnd = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDragOverItem(null);
  };
  return (
    <>
      <MajorSelector isSecond>
        <SelectorWrapper>
          <SectionChevronIconWrapper
            isCollapsed={isSmartfilesCollapsed}
            onClick={() => setSmartfilesCollapsed(!isSmartfilesCollapsed)}
          >
            <SectionChevronIcon />
          </SectionChevronIconWrapper>
          <MajorText>{t('smartfilesT')}</MajorText>
        </SelectorWrapper>
        <PlusWrapperCircle onClick={createNewPlaylist}>
          <PlusIconCircle />
        </PlusWrapperCircle>
      </MajorSelector>
      <CollapsingSectionWrapper
        isCollapsed={isSmartfilesCollapsed}
        height={smartItemsHeight}
        time="0.3"
      >
        {sieveOptions.map((itemType) => (
          <Selector
            data-cy={itemType}
            data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} ${DATA_PARENTS.HeaderInput}`}
            key={`${itemType}`}
            active={history.location.pathname.includes(`smartfiles/${calcSelectorForSmartfiles(itemType)}`)}
            onClick={() => {
              setSieve(itemType);
            }}
            isPlusDisabled
            isDragOver={dragOverItem === itemType}
            onDragOver={(e) => onDragOver(e, itemType)}
            onDragLeave={onDragEnd}
          >
            <Info active={history.location.pathname.includes(`smartfiles/${calcSelectorForSmartfiles(itemType)}`)}>
              <IconPlace className="fill-icon-hover">
                {MainNavigate.smartfiles.sidebarSelectors[itemType].icon}
                {t(MainNavigate.smartfiles.sidebarSelectors[itemType].textID)}
              </IconPlace>
            </Info>
            <Counter
              active={
                (isContent && itemType === selectorType)
              }
            >
              {convertToSmartNumbers(convert(itemType, 'smartfiles')) || '??'}
            </Counter>
          </Selector>
        ))}
      </CollapsingSectionWrapper>
      {isShowPlaylistField && (
        <Selector
          data-cy={activeSFOption}
          data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} ${DATA_PARENTS.HeaderInput}`}
          active
          onClick={() => {
            setSieve(activeSFOption);
          }}
          isPlusDisabled
          isDragOver={dragOverItem === activeSFOption}
          onDragOver={(e) => onDragOver(e, activeSFOption)}
          onDragLeave={onDragEnd}
        >
          <Info active>
            <IconPlace className="fill-icon-hover">
              {MainNavigate.smartfiles.sidebarSelectors[activeSFOption].icon}
              {t(MainNavigate.smartfiles.sidebarSelectors[activeSFOption].textID)}
            </IconPlace>
          </Info>
          <Counter
            active
          >
            {convertToSmartNumbers(convert(activeSFOption, 'smartfiles')) || '??'}
          </Counter>
        </Selector>
      )}
    </>
  );
};

export default SmartfilesSection;
