import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import Toggler from '../../../../components/DropDownOption/Toggler';
import { DB_USER_PREFERENCES_GENERAL_FIELDS } from '../../../../utils/SettingsHelpers/constants';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';

import { Column, Line, SectionShell, HeaderTitle } from '../../CommonComponents/Layout';

const cn = classNames;

const PreferencesGeneral = () => {
  const dispatch = useDispatch();
  const { inputs } = useSelector((state) => state.settings);
  const { t } = useTranslation();

  const toggleInput = (e, inputType) => {
    let newValue;
    if (inputType === DB_USER_PREFERENCES_GENERAL_FIELDS.displayImage) {
      newValue = inputs[inputType].value === 'AvatarImage' ? 'GeneratedAvatar' : 'AvatarImage';
      dispatch(actionCreator(Settings.Profile.UpdateDisplayImageR, { newValue }));
    }
    dispatch(
      actionCreator(Settings.Preferences.ToggleInputValue, {
        inputType,
        newValue: newValue ?? !inputs[inputType].value,
      }),
    );
  };
  return (
    <SectionShell paddingForSetting>
      <HeaderTitle marginBottom32>{t('generalT')}</HeaderTitle>
      <Line>
        <div className={cn(styles.label)}>{t('displayT')}</div>
      </Line>
      <Line General>
        <Column General>
          <div className={cn(styles.text)}>
            <p>{t('showUsernameT')}</p>
          </div>
        </Column>
        <Column General>
          <Toggler
            togglerHeight={13}
            togglerWidth={28}
            isActive={inputs.showUsername.value}
            callback={(e) => toggleInput(e, DB_USER_PREFERENCES_GENERAL_FIELDS.showUsername)
            }
            left="150px"
            isAppSettings
            top={2}
          />
        </Column>
      </Line>
      <Line General>
        <Column General>
          <div className={cn(styles.text)}>
            <p>{t('displayImageT')}</p>
          </div>
        </Column>
        <Column General>
          <Toggler
            togglerHeight={13}
            togglerWidth={28}
            isActive={inputs.displayImage.value === 'GeneratedAvatar'}
            callback={(e) => toggleInput(e, DB_USER_PREFERENCES_GENERAL_FIELDS.displayImage)}
            left="150px"
            isAppSettings
            top={2}
          />
        </Column>
      </Line>
      <Line isDisable General>
        <div className={cn(styles.label, styles.marginTop12)}>
          {t('emailT')}
        </div>
      </Line>
      <Line isDisable General>
        <Column General>
          <div className={cn(styles.text)}>
            <p>{t('emailNotificationsT')}</p>
          </div>
        </Column>
        <Column General>
          <Toggler
            isDisable
            togglerHeight={13}
            togglerWidth={28}
            // isActive={inputs.emailNotifications.value}
            // callback={(e) => toggleInput(
            //   e,  DB_USER_PREFERENCES_GENERAL_FIELDS.emailNotifications,
            // ) }
            left="150px"
            isAppSettings
            top={2}
          />
        </Column>
      </Line>
      <Line isDisable General>
        <Column General>
          <div className={cn(styles.text)}>
            <p>{t('keepInformedT')}</p>
          </div>
        </Column>
        <Column General>
          <Toggler
            isDisable
            togglerHeight={13}
            togglerWidth={28}
            // isActive={inputs.keepInformed.value}
            // callback={(e) => toggleInput(e, DB_USER_PREFERENCES_GENERAL_FIELDS.keepInformed)
            // }
            left="150px"
            isAppSettings
            top={2}
          />
        </Column>
      </Line>
    </SectionShell>
  );
};

export default PreferencesGeneral;
