import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import PlaylistCoverCropper from '../ImageCroppers/PlaylistCoverCropper';
import { actionAddImagePlaylist } from '../../redux/playlists/action';

const cx = classNames.bind(styles);

const CropPlaylistCover = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const close = () => {
    dispatch(actionCloseModal());
  };

  const {
    dataPayload: { componentId, imagePath },
  } = useSelector((redux) => redux.user);

  const save = (crop) => {
    dispatch(actionAddImagePlaylist(null, componentId, imagePath, crop));
    dispatch(actionCloseModal());
  };

  return (
    <div className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        className={cx('modal', 'CropUserAvatar')}
      >
        <div className={cx('title')}>{t('editCoverT')}</div>

        <div className={cx('cropper_container')}>
          <PlaylistCoverCropper save={save} src={imagePath} />
        </div>
      </div>
    </div>
  );
};

export default CropPlaylistCover;
