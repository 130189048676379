import fscreen from './fscreen';

export function openFullScreen() {
  fscreen.requestFullscreen
    && fscreen.requestFullscreen(document.documentElement);
}

export function exitFullScreen() {
  if (fscreen.fullscreenElement && fscreen.exitFullscreen) {
    fscreen.exitFullscreen();
  }
}
