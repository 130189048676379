import classNames from 'classnames/bind';
import React from 'react';
import rawStyles from '../index.module.scss';
import i18n from '../../../i18n';

const cn = classNames.bind(rawStyles);

export const NOTIFICATIONS = {
  ConfirmEmail: (email) => () => {
    return (
      <>
        <div className={cn('notification_title')}>
          {i18n.t('confirmEmailTitleTextT')}
        </div>
        <div className={cn('inline')}>
          {i18n.t('confirmEmailDescriptionPartOneT')}
          <div className={cn('bold', 'inline')}>{email}</div>
          {i18n.t('confirmEmailDescriptionPartTwoT')}
        </div>
      </>
    );
  },
};
