import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { v4 } from 'uuid';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import rawStyles from './index.module.scss';
import { ReactComponent as Plus8Svg } from '../../images/icons/plus_black.svg';
import { ReactComponent as RestoreSvg } from '../../images/icons/restore.svg';
import LibColorPicker from './index';
import useThrottle from '../../utils/hooks/useThrottle';
import { actionOpenOverlayModal } from '../../redux/user/action';
import { actionCreator } from '../../shared/redux/actionHelper';
import SupportAction from '../../redux/support/types';
import {
  generateRandomHexColor,
  getLastItemPosition,
} from '../../utils/helpers';
import { actionShowMessage } from '../../redux/support/action';
import {
  DATA_PARENTS,
  DEFAULT_COLORS,
  DEFAULT_POSITION_STEP,
  MessageType,
  openModalType,
} from '../../utils/constants';

const cn = classNames.bind(rawStyles);

const Title = styled.div`
  font-family: "RobotoFlex", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: #d3c8c8;
  margin-bottom: 10px;
`;

const MAX_CUSTOM_COLORS = 12;

const DefaultTile = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  //border:  solid #ECE2E2;
  ${({ color, isSelected }) => `
    border: ${isSelected ? '2.5px' : '0.6px'} solid ${
  isSelected ? '#FFC72C' : '#ECE2E2'
};
    background: ${color};
  `}
  &:hover {
    border: 2.5px #ffeebf solid;
  }
`;

const CustomTile = styled.div`
  height: 24px;
  border-radius: 4px;
  cursor: pointer;

  ${({ isActive }) => `${
    isActive
      ? `
    width: 24px;
  `
      : ''
  }`}

  ${({ color, isSelected }) => `
     border: ${isSelected ? '2.5px' : '0.6px'} solid ${
  isSelected ? '#FFC72C' : '#ECE2E2'
};
    background: ${color};
  `}

  ${({ isDelete }) => `${
    isDelete
      ? `
    transition: all linear 0.3s;
    width: 0;
  `
      : ''
  }`}

  ${({ isEdit }) => `${
    isEdit
      ? `
    transition: width linear 0.3s;
    border: 0;
   
  `
      : ''
  }`}
  &:hover {
    border: 2.5px #ffeebf solid;
  }
`;

const AddColor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  background: white;
  border: 0.6px solid #ece2e2;
  box-shadow: 0 1px 4px rgba(122, 105, 105, 0.1);
  &:hover {
    background: #ffeebf;
  }

  svg {
    width: 13px;
    height: 13px;
  }

  svg path {
    stroke: #484343;
  }
`;

const PICKER_MODE = {
  Text: 'Text',
  Background: 'Background',
};

let timer;

const ColorPalette = ({
  selectedColor,
  selectedBackgroundColor,
  onEnablePicker,
  onChangeColor,
  refColorPickerParent,
  isPaletteActive,
  setIsPaletteActive,
  defaultPosition = { top: 24, left: 0 },
  buttonWidth = 24,
  isPositionFixed,
  isPositionRelative,
  type,
  dataParent,
  forSmartModal,
  calcIsSelected,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { colors, colorsIds, usedColors } = useSelector(
    (state) => state.support,
  );
  const [selectedMode, setSelectedMode] = useState(PICKER_MODE.Text);
  const [editingColorId, setEditingColorId] = useState(null);
  const [editingColorValue, setEditingColorValue] = useState(null);
  const [editingColorPosition, setEditingColorPosition] = useState(null);
  const [startAnimation, setStartAnimation] = useState(null);
  const [draggableItemId, setDraggableItemId] = useState(null);
  const [isColorDragActive, setIsColorDragActive] = useState(false);
  const [throttledAnimateIn] = useThrottle(10);
  const dropDepthRef = useRef(0);
  const refPickerHueParent = useRef(null);
  const markedForDelete = useRef({});

  const isShowAddColorButton = colorsIds.length < MAX_CUSTOM_COLORS;

  const calculateVerticalPosition = () => {
    if (!isPaletteActive) return defaultPosition.top;
    if (refColorPickerParent && refColorPickerParent?.current) {
      const height = document.documentElement.clientHeight;
      const positionY = defaultPosition.top;
      const refAbsoluteY = refColorPickerParent.current.getBoundingClientRect().y;
      const heightElement = 296;
      const isOpenInDown = height > refAbsoluteY + heightElement + positionY;
      if (isOpenInDown && isPositionFixed) return refAbsoluteY + buttonWidth;
      if (isOpenInDown) return positionY;
      if (isPositionFixed) return refAbsoluteY - heightElement;
      return positionY - heightElement;
    }
    return defaultPosition.top;
  };

  const calculateHorizontalPosition = () => {
    if (!isPaletteActive) return defaultPosition.top;
    if (refColorPickerParent && refColorPickerParent?.current) {
      const width = document.documentElement.clientWidth;
      const positionX = defaultPosition.left;
      const refAbsoluteX = refColorPickerParent?.current.getBoundingClientRect().x;
      const widthElement = 184 - buttonWidth;
      const isOpenInRight = width > refAbsoluteX + widthElement + positionX;
      if (isOpenInRight && isPositionFixed) return refAbsoluteX + buttonWidth;
      if (isOpenInRight) return positionX;
      if (isPositionFixed) return refAbsoluteX - widthElement;
      return positionX - widthElement;
    }
    return defaultPosition.left;
  };

  const dropHueColorData = () => {
    setEditingColorId(null);
    setEditingColorValue('');
    setEditingColorPosition(null);
  };

  const divStyle = forSmartModal
    ? {
      width: '100%',
    }
    : {
      top: calculateVerticalPosition(),
      left: calculateHorizontalPosition(),
    };

  const getRandomHexColor = () => {
    let randomHexColor = generateRandomHexColor();
    while (usedColors[randomHexColor]) {
      randomHexColor = generateRandomHexColor();
    }
    return randomHexColor;
  };

  const handleColorChange = (color) => {
    const hex = color.hex.toUpperCase();

    if (usedColors[hex]) {
      dispatch(
        actionShowMessage({
          type: MessageType.ColorDuplication,
          color: hex,
          effects: 'shaking',
        }),
      );
      return;
    }
    setEditingColorValue(color.hex);
  };

  const togglePaletteActive = () => {
    if (onEnablePicker) {
      onEnablePicker(!isPaletteActive);
    }
    if (setIsPaletteActive) setIsPaletteActive((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (event?.target?.dataset?.parent?.includes('CustomTile')) return;
    if (editingColorId && event.currentTarget.contains(event.target)) {
      dropHueColorData();
    }
    if (editingColorId) {
      dropHueColorData();
      event.stopPropagation();
      return;
    }
    if (isPaletteActive) {
      if (setIsPaletteActive) setIsPaletteActive(false);
      if (onEnablePicker) {
        onEnablePicker(!isPaletteActive);
      }
    }
    event.stopPropagation();
  };

  const onCancelClickHandler = () => {
    setEditingColorId(null);
    setEditingColorPosition(null);
    setEditingColorValue('');
  };

  const onSaveClickHandler = () => {
    if (!editingColorValue) return;
    if (colors[editingColorId]) {
      dispatch(
        actionCreator(SupportAction.ColorPalette.UpdateCustomColorS, {
          id: editingColorId,
          value: editingColorValue,
        }),
      );
    } else {
      dispatch(
        actionCreator(SupportAction.ColorPalette.AddCustomColorS, {
          id: editingColorId,
          value: editingColorValue,
          position: editingColorPosition,
        }),
      );
    }
    setEditingColorId(null);
    setEditingColorValue('');
    setEditingColorPosition(null);
  };

  const paletteClickHandler = (event) => {
    event.stopPropagation();
  };

  const pickerClickHandler = (event) => {
    if (editingColorId) event.stopPropagation();
  };

  const onEditCustomColorClick = (event, color, id, isDefaultColor) => {
    event.stopPropagation();
    if (markedForDelete.current[id]) return;
    clearTimeout(timer);

    if (event.detail === 1) {
      timer = setTimeout(() => {
        if (onChangeColor) onChangeColor(event, color, selectedMode);
      }, 400);
    } else if (event.detail === 2 && !isDefaultColor) {
      setEditingColorId(id);
      setEditingColorValue(colors[id].color);
    }
  };

  const addCustomColor = () => {
    if (startAnimation) return;
    if (colorsIds.length > 12) return;
    const newId = v4();
    const randomHexColor = getRandomHexColor();
    const lastPosition = getLastItemPosition(colors) || DEFAULT_POSITION_STEP;
    const position = lastPosition + DEFAULT_POSITION_STEP;

    setEditingColorId(newId);
    setEditingColorValue(randomHexColor);
    setEditingColorPosition(position);

    setStartAnimation(newId);
    throttledAnimateIn(() => () => {
      setStartAnimation(null);
    });
  };

  const dragStartHandler = (event, color, id) => {
    event.stopPropagation();
    const div = document.createElement('div');
    div.id = 'drag_color';
    div.style.width = '18px';
    div.style.height = '18px';
    div.style.borderRadius = '4px';
    div.style.position = 'absolute';
    div.style.cursor = 'grabbing';
    div.style.backgroundColor = `${color}`;

    document.body.appendChild(div);
    event.dataTransfer.setDragImage(div, 20, 20);

    setDraggableItemId(id);
  };

  const dragEndHandler = (event) => {
    event.stopPropagation();
    const div = document.querySelector('#drag_color');
    document.body.removeChild(div);
    setDraggableItemId(null);
    dropDepthRef.current = 0;
    setIsColorDragActive(false);
  };

  const changeMode = (event, mode) => {
    setSelectedMode(mode);
  };

  const changeToText = (event) => changeMode(event, PICKER_MODE.Text);
  const changeToBackground = (event) => changeMode(event, PICKER_MODE.Background);

  const onResetClickHandler = (event) => {
    event.stopPropagation();
    const close = () => {
      dropHueColorData();
      dispatch(actionCreator(SupportAction.ColorPalette.ResetAllCustomColorsS));
    };
    dispatch(actionOpenOverlayModal(openModalType.ResetColorOverlayModal, { foo: close }));
  };

  let exCalcIsSelected = (color) => {
    return (
      color.includes(selectedColor)
      || (selectedBackgroundColor
        && selectedBackgroundColor.toUpperCase() === color.toUpperCase())
      || (selectedColor && selectedColor.toUpperCase() === color.toUpperCase())
    );
  };
  if (calcIsSelected) {
    exCalcIsSelected = calcIsSelected;
  }

  return (
    <>
      <div className={cn('palette_wrapper')}>
        <div className={cn('palette_button')} onClick={togglePaletteActive}>
          {props.children}
        </div>
        {isPaletteActive && (
          <>
            {!isPositionRelative && (
              <div
                className={cn('underlay', { display: isPaletteActive })}
                onClick={handleClickOutside}
              />
            )}
            <div
              className={cn('palette_container', {
                display: isPaletteActive,
                usedWithScroll: isPositionFixed,
                usedEmbed: isPositionRelative,
              })}
              data-cy="color-palette"
              onClick={paletteClickHandler}
              data-parent={`${DATA_PARENTS.createLibrary} PaletteContainer ${dataParent}`}
              style={divStyle}
              ref={refPickerHueParent}
            >
              {!!props.element && props.element}
              <div
                className={cn('colors_section', {
                  is_element_exist: !!props.element,
                  usedEmbed: isPositionRelative,
                  for_smart_modal: forSmartModal,
                })}
              >
                {!forSmartModal && (
                  <Title>{t('changeTypeColorsT', { type: type ? ` ${type}` : '' })}</Title>
                )}

                {!forSmartModal && (
                  <div
                    className={cn('colors_selector')}
                    data-parent={`${DATA_PARENTS.createLibrary}  ${dataParent}`}
                  >
                    <div
                      data-cy="tag-backgroundColor"
                      className={cn('background', {
                        active: selectedMode === PICKER_MODE.Background,
                      })}
                      onClick={changeToBackground}
                      data-parent={`${DATA_PARENTS.createLibrary}  ${dataParent}`}
                    >
                      {t('backgroundT')}
                    </div>
                    <div
                      data-cy="tag-textColor"
                      className={cn('text', {
                        active: selectedMode === PICKER_MODE.Text,
                      })}
                      onClick={changeToText}
                      data-parent={`${DATA_PARENTS.createLibrary}  ${dataParent}`}
                    >
                      {t('textTypeT')}
                    </div>
                  </div>
                )}
                <div
                  className={cn('default_colors')}
                  data-parent={`${DATA_PARENTS.createLibrary}  ${dataParent}`}
                >
                  <div
                    className={cn('label', { for_smart_modal: forSmartModal })}
                    data-parent={`${DATA_PARENTS.createLibrary}  ${dataParent}`}
                  >
                    {t('basicT')} :
                  </div>
                  <div
                    data-cy="tag-colors"
                    className={cn('grid', { for_smart_modal: forSmartModal })}
                    data-parent={`${DATA_PARENTS.createLibrary}  ${dataParent}`}
                  >
                    {DEFAULT_COLORS.map((item) => (
                      <DefaultTile
                        key={item.id}
                        color={item.color}
                        isSelected={exCalcIsSelected(item.color)}
                        onClick={(event) => onEditCustomColorClick(
                          event,
                          item.color,
                          item.id,
                          true,
                        )
                        }
                      />
                    ))}
                  </div>
                </div>
                <div
                  className={cn('custom_colors')}
                  data-parent={`${DATA_PARENTS.createLibrary}  ${dataParent}`}
                >
                  <div
                    className={cn('label', { for_smart_modal: forSmartModal })}
                  >
                    {t('customT')} :
                  </div>
                  <div
                    className={cn('grid', {
                      for_smart_modal_icon: forSmartModal,
                    })}
                    data-parent={`${DATA_PARENTS.createLibrary}  ${dataParent} PaletteContainer`}
                  >
                    {colorsIds.map((itemId) => (
                      <CustomTile
                        key={itemId}
                        data-parent={`${DATA_PARENTS.createLibrary}  ${dataParent} CustomTile`}
                        color={
                          editingColorId === itemId
                            ? editingColorValue
                            : colors[itemId].color
                        }
                        isSelected={exCalcIsSelected(colors[itemId].color)}
                        onClick={(event) => onEditCustomColorClick(
                          event,
                          colors[itemId].color,
                          itemId,
                        )
                        }
                        draggable
                        onDragStart={(event) => dragStartHandler(event, colors[itemId].color, itemId)
                        }
                        onDragEnd={dragEndHandler}
                        isDelete={markedForDelete.current[itemId]}
                        isActive={itemId !== startAnimation}
                        isEdit={itemId === editingColorId}
                      />
                    ))}
                    {editingColorId && !colors[editingColorId] && (
                      <CustomTile
                        key={editingColorId}
                        data-parent={`${DATA_PARENTS.createLibrary}  ${dataParent} CustomTile`}
                        color={editingColorValue}
                        isSelected={exCalcIsSelected(editingColorValue)}
                        onClick={(event) => onEditCustomColorClick(
                          event,
                          editingColorValue,
                          editingColorId,
                        )
                        }
                        isActive={editingColorId !== startAnimation}
                        isEdit
                        forSmartModal={forSmartModal}
                      />
                    )}
                    {isShowAddColorButton && (
                      <AddColor
                        data-cy="tag-addCustomColor"
                        onClick={addCustomColor}
                        data-parent={`${DATA_PARENTS.createLibrary}  ${dataParent}`}
                      >
                        <Plus8Svg
                          data-parent={`${DATA_PARENTS.createLibrary}  ${dataParent}`}
                        />
                      </AddColor>
                    )}
                  </div>
                </div>
                <div
                  className={cn('controls_section', {
                    for_smart_modal: forSmartModal,
                  })}
                  data-parent={`${DATA_PARENTS.createLibrary}  ${dataParent}`}
                >
                  <div
                    onClick={onResetClickHandler}
                    className={cn('reset_button')}
                  >
                    <RestoreSvg />
                  </div>
                </div>
              </div>
              {!!editingColorId && (
                <LibColorPicker
                  color={editingColorValue}
                  onChange={handleColorChange}
                  pickerClickHandler={pickerClickHandler}
                  onSaveClickHandler={onSaveClickHandler}
                  onCancelClickHandler={onCancelClickHandler}
                  refPickerHueParent={refPickerHueParent}
                  forSmartModal={forSmartModal}
                />
              )}
              {!!props.elementEnd && props.elementEnd}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ColorPalette;
