import { shortcut } from './types';
import { ServiceUser } from '../user/types';

const initialState = {
  combination: {},
  mods: {},
};

export const shortcutReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case shortcut.WriteCombination: {
      return {
        ...state,
        combination: { ...state.combination, [payload]: true },
      };
    }
    case shortcut.ClearCombinations: {
      return { ...state, combination: {} };
    }
    case shortcut.PressModKey: {
      return { ...state, mods: { ...state.mods, [payload]: true } };
    }
    case shortcut.ReleaseModKey: {
      return { ...state, mods: { ...state.mods, [payload]: false } };
    }
    case ServiceUser.Logout: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
