import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import moduleStyles from './HeadingMenu.module.scss';
import { ReactComponent as FavoriteSvg } from '../../images/icons/favorites20.svg';
import { ReactComponent as ShareSvg } from '../../images/icons/paperclip_link_20.svg';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import Share from '../DropDownOption/Share';
import Navigation from './Navigation';

import { actionShowMessage } from '../../redux/support/action';
import { DEFAULT_TITLE, MessageType } from '../../utils/constants';
import HeadingPageOption from '../DropDownOption/HeadingPageOption';
import { actionToggleComponentFavorite } from '../../redux/library/actions';
import {
  convertDatePage,
  convertDatePlaylist,
  MILLISECONDS_IN_MIN,
  SECONDS_IN_DAY,
} from '../../utils/dateConvert';
import { actionCreator } from '../../shared/redux/actionHelper';
import SupportAction from '../../redux/support/types';
import useThrottle from '../../utils/hooks/useThrottle';
import { EditPage } from '../../redux/pages/types';
import i18n from '../../i18n';

const styles = classNames.bind(moduleStyles);

const calcTimeHint = (time) => {
  const todayBeginning = time - (time % SECONDS_IN_DAY);
  const yesterdayBeginning = todayBeginning - SECONDS_IN_DAY;
  const isToday = time > todayBeginning;
  const isYesterday = time > yesterdayBeginning && time < todayBeginning;
  if (isYesterday) return i18n.t('yesterdayT');
  if (isToday) return i18n.t('TodayT');
  return '';
};

const calcDate = (dateLastUpdate) => {
  if (!dateLastUpdate) return '';
  const now = new Date(Date.now()) / 1000;
  const dateLastUpdateSanitize = dateLastUpdate || now;
  if (now - dateLastUpdateSanitize < SECONDS_IN_DAY) {
    return `${i18n.t('lastUpdatedT')}: ${calcTimeHint(
      dateLastUpdateSanitize,
    )} ${convertDatePage(dateLastUpdateSanitize)}`;
  }
  return `${i18n.t('lastUpdatedT')}: ${convertDatePlaylist(dateLastUpdateSanitize)}`;
};
const Time = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #242633;
`;

const LibPageHeader = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const currentPage = useSelector((state) => state.currentPage);
  const { sharedModalAnchor } = useSelector((state) => state.support);
  const { isShowLibrary } = useSelector((state) => state.library);
  const [refShare, isShareActive, setIsShareActive] = useComponentVisible(false);
  const [time, setTime] = useState(calcDate(currentPage.lastModifiedDate));
  const timerId = useRef(null);
  const [throttle] = useThrottle(6000);


  const { isLargeRightBar } = useSelector((state) => state.currentPage);

  const isFavorite = currentPage?.isFavorite;

  const dispatch = useDispatch();

  useEffect(() => {
    setIsShareActive(false);
  }, [history.location.pathname]);

  useEffect(() => {
    setIsShareActive(!!sharedModalAnchor);
  }, [sharedModalAnchor]);

  useEffect(() => {
    return () => {
      dispatch(actionCreator(SupportAction.UpdateSharedModalAnchor, { sharedModalAnchor: 0 }));
    };
  }, []);

  useEffect(() => {
    setTime(calcDate(currentPage.lastModifiedDate));

    timerId.current = setInterval(() => {
      setTime(calcDate(currentPage.lastModifiedDate));
    }, MILLISECONDS_IN_MIN);

    if (currentPage.lastModifiedDate && time && currentPage.singleUserShareState && Object.values(currentPage.singleUserShareState).length) {
      const users = Object.values(currentPage.singleUserShareState).map(i => i.id);
      const callback = () => dispatch(actionCreator(EditPage.sendEventUpdatePage, { users }));
      throttle(() => callback);
    }
    return () => {
      clearInterval(timerId.current);
    };
  }, [currentPage.lastModifiedDate]);

  const toggleFavorite = () => {
    if (!isFavorite) {
      dispatch(
        actionShowMessage({
          type: MessageType.PageToFavorite,
          itemName: currentPage?.title || t('defaultSmartPageTitleT'),
        }),
      );
    }
    dispatch(
      actionToggleComponentFavorite(
        currentPage.id,
        currentPage.inFolder?.id,
        !isFavorite,
        true,
      ),
    );
  };
  const enableShare = () => {
    setIsShareActive(!isShareActive);
  };

  return (
    <div
      className={styles(
        'headingMenu',
        { headingMenu_small: isLargeRightBar, forEditPage: true },
        'isPage',
        { isShowLibrary },
      )}
    >
      <Navigation />
      <Time>{time}</Time>
      {!isLargeRightBar && (
        <div
          className={styles('headingMenu__container', { forEditPage: true })}
        >
          {isShareActive && (
            <div className={styles('headingMenu__share_container')}>
              <Share close={() => setIsShareActive(false)} refMenu={refShare} />
            </div>
          )}
          <div className={styles('headingMenu__options')}>
            <div
              className={styles(
                'item',
                'favorite',
                `${isFavorite ? 'active' : ''}`,
              )}
              onClick={toggleFavorite}
            >
              <FavoriteSvg />
            </div>

            <div
              className={styles(
                'item',
                'share',
                `${isShareActive ? 'active' : ''}`,
              )}
              onClick={enableShare}
            >
              <ShareSvg />
            </div>

            <div
              className={styles('publish_button_container', 'placeholder')}
            />

            <HeadingPageOption isFavorite={isFavorite} />
          </div>
        </div>
      )}
    </div>
  );
};

export default LibPageHeader;
