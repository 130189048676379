import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.css';
import { actionGetAllPublishPlaylist } from '../../redux/playlists/action';
import PreviewPlaylistCard from './PreviewPlaylistCard';

const BrowsePlaylists = () => {
  const { isDownload, playlistsCard, complete } = useSelector(
    (state) => state.currentPage,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actionGetAllPublishPlaylist());
  }, []);
  const scroller = useRef(null);
  const needToLoad = useRef(null);
  const [step, setStep] = useState(1);

  const getMany = (e) => {
    if (complete) return;
    if (e?.target) {
      const bottom = e.target.scrollHeight - e.target.scrollTop
        <= e.target.clientHeight + 230;
      if (bottom && !needToLoad.current) {
        needToLoad.current = true;
        setStep(step + 1);
        dispatch(actionGetAllPublishPlaylist(step + 1));
      }
    } else {
      setStep(step + 1);
      dispatch(actionGetAllPublishPlaylist(step + 1));
    }
  };

  useEffect(() => {
    if (complete) return;
    if (!isDownload) {
      if (
        scroller?.current?.scrollHeight
        <= scroller?.current?.clientHeight + scroller?.current?.scrollTop + 200
      ) {
        getMany();
      } else {
        needToLoad.current = false;
      }
    }
  }, [isDownload]);

  return (
    <>
      <div
        ref={scroller}
        onScroll={getMany}
        className={styles.block_playlist_card}
      >
        <div className={styles.block_playlist_card2}>
          {playlistsCard
            && playlistsCard.map((playlist) => (
              <PreviewPlaylistCard key={playlist.id} playlist={playlist} />
            ))}
          {isDownload
            && !complete
            && Array(10)
              .fill(null)
              .map((u, i) => i)
              .map((playlist) => (
                <PreviewPlaylistCard key={playlist} playlist={{}} isDownload />
              ))}
        </div>
      </div>
    </>
  );
};

export default BrowsePlaylists;
