import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import rawStyles from './index.module.scss';
import { actionCreator } from '../../shared/redux/actionHelper';
import Support from '../../redux/support/types';
import { UiComponentTypes } from '../../utils/constants';

const cn = classNames.bind(rawStyles);

const ELEMENT_CONTAINER_WIDTH = 40;
const ELEMENT_CONTAINER_HEIGHT = 27;
const TIME_TO_SHOW = 300;

const getElementHeight = (type: string, isLibrary = false, isKanban = false) => {
  if (isLibrary) {
    if (UiComponentTypes.image[type] || UiComponentTypes.link[type]) return 111;
    if (UiComponentTypes.page[type] || UiComponentTypes.component[type]) return 240;
    if (UiComponentTypes.pdf[type]) return 253;
    if (UiComponentTypes.ms[type] || UiComponentTypes.xls[type]) return 260;
  }
  if (UiComponentTypes.pdf[type]) return 500;
  if (UiComponentTypes.page[type]) return 247;
  if (UiComponentTypes.component[type]) return 185;
  if (UiComponentTypes.image[type] || UiComponentTypes.link[type]) return 236;
  if (isKanban) return 120;
  return 126;
};

type HoverTooltipWrapperProps = {
  element: React.JSX.Element;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  item: any; // TODO typify this
  isKanbanCardWithoutPreview: boolean;
  isLibrary?: boolean;
  isKanban?: boolean;
}

type PositionType = {
  top?: number;
  left?: number;
}

const HoverTooltipWrapper = ({
  element,
  setIsActive,
  isActive,
  item,
  isKanbanCardWithoutPreview = false,
  isLibrary,
  isKanban,
  children,
}: PropsWithChildren<HoverTooltipWrapperProps>) => {
  const dispatch = useDispatch(); // TODO typify this
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [elementPosition, setElementPosition] = useState<PositionType>({});
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const calcPositionProps = () => {
    if (!wrapperRef.current) return {};

    const newX = wrapperRef.current.getBoundingClientRect().x;
    const newY = wrapperRef.current.getBoundingClientRect().y;
    const height = window.innerHeight;
    const elementMaxHeight = getElementHeight(item?.type, isLibrary, isKanban);
    if (isKanban) {
      const position = {
        left: newX + ELEMENT_CONTAINER_WIDTH - 450,
        top:
          elementMaxHeight + newY < height
            ? newY + ELEMENT_CONTAINER_HEIGHT - 111
            : newY - elementMaxHeight - 111,
      };
      return position;
    }
    const top = elementMaxHeight + newY < height
      ? 35
      : -elementMaxHeight;
    return { top, left: 35 };
  };

  const handleMouseEnter = () => {
    if (!wrapperRef.current) return;
    if (isKanbanCardWithoutPreview) return;

    setElementPosition(calcPositionProps());

    timeoutRef.current = setTimeout(() => {
      dispatch(actionCreator(Support.SetColumnDropDownIsOpened, {}));
      setIsActive(true);
    }, TIME_TO_SHOW);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    setIsActive(false);
  };

  const stopEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  if (!item) return <></>;

  return (
    <div
      ref={wrapperRef}
      className={cn('preview_wrapper')}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!isKanbanCardWithoutPreview && (
        <div
          className={cn('display_element_container', {
            active: isActive,
            page_type:
              UiComponentTypes.page[item?.type] || item?.type === 'sharedPage',
            component_type: UiComponentTypes.component[item?.type],
            pdf_type:
              UiComponentTypes.pdf[item?.type]
              || UiComponentTypes.ms[item?.type]
              || UiComponentTypes.xls[item?.type]
              || (item?.type === 'text/plain' && !!item.pdfPreviewUrl)
              || !!item.pdfPreviewUrl,
            link_type: UiComponentTypes.link[item?.type],
            img_type:
              UiComponentTypes.image[item?.type]
              || UiComponentTypes.video[item?.type],
            audio_type: UiComponentTypes.audio[item?.type],
            video_type_svg:
              UiComponentTypes.video[item?.type] && !item.playbackID,
            presentation_type: UiComponentTypes.presentation[item?.type],
            broken_file:
              item?.type === 'text/plain' && !item.pdfPreviewUrl && !item.urlFile,
            another_type:
              !UiComponentTypes.page[item?.type]
              && !UiComponentTypes.component[item?.type]
              && !UiComponentTypes.pdf[item?.type]
              && !UiComponentTypes.ms[item?.type]
              && !UiComponentTypes.xls[item?.type]
              && !UiComponentTypes.image[item?.type]
              && !UiComponentTypes.video[item?.type]
              && !UiComponentTypes.audio[item?.type]
              && !UiComponentTypes.presentation[item?.type]
              && !UiComponentTypes.link[item?.type]
              && !UiComponentTypes.text[item?.type]
              && !item.pdfPreviewUrl,
            isKanban,
          })}
          style={elementPosition}
          onClick={stopEvent}
        >
          <div
            className={cn('image', {
              page_type: UiComponentTypes.page[item?.type],
              component_type: UiComponentTypes.component[item?.type],
              pdf_type:
                UiComponentTypes.pdf[item?.type]
                || UiComponentTypes.ms[item?.type]
                || UiComponentTypes.xls[item?.type]
                || item?.type === 'text/plain'
                || !!item.pdfPreviewUrl,
              img_type:
                UiComponentTypes.image[item?.type]
                || UiComponentTypes.video[item?.type],
              presentation_type: UiComponentTypes.presentation[item?.type],
              link_type: UiComponentTypes.link[item?.type],
            })}
          >
            {isActive && element}
          </div>
        </div>
      )}

      {children}
    </div>
  );
};

export default HoverTooltipWrapper;
