import React from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { isRoleInPlaylist } from '../../../utils/permissions';
import OptionsLinkPage from '../../../entities/OptionsLinkPage';

const BottomInfoPartWrapper = styled.div`
  z-index: 200;
  padding: 0 16px;
  display: flex;
  align-items: center;
  position: absolute;
  height: 36px;
  right: -1px;
  top: -26px;
  border-radius: 8px;
  border: 0.6px solid #ECE2E2;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(122, 105, 105, 0.10);
`;
const SvgWrapper = styled.div`
  width: 27px;
  height:27px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  
  :hover {
    background: #FFDF85;
  }

  ${({ isMore }) => (isMore
    ? `     
    :hover {
      background: #FFEEBF;
    }
    `
    : '')} 
  ${({ isDisabled }) => (isDisabled
    ? `     
    cursor: not-allowed;
    `
    : '')}
  ${({ isInactive }) => (isInactive ? `
  svg {
    opacity: 0.15;
  }
  ` : '')}
  
  svg {
    path {
      stroke: #484343;
    }
  }
`;

const SizeWrapper = styled.div`
  margin-right: 16px;
  cursor: not-allowed;
`;

const VerticalDivider = styled.div`
    width: 1px;
    height: 20px;
    background: rgba(29, 29, 29, 0.08);
    margin: 0 13px;
`;

export const TextElementPopup = (props) => {
  const currentPage = useSelector((state) => state.currentPage);

  const { currentRole } = currentPage;

  const isDurationMoreThanHour = props.linkPage.duration > 3599;
  const { isOwner: isOwnerPlaylist } = isRoleInPlaylist;
  const playlistOwner = isOwnerPlaylist[currentRole];

  return (
    <div
      onMouseEnter={() => props.setMenuVisible(true)}
      onMouseLeave={() => props.setMenuVisible(false)}
    >
      <BottomInfoPartWrapper
        isDurationMoreThanHour={isDurationMoreThanHour}
        isExpandedView={props.isExpandedView}
        isWide={!playlistOwner}
      >
        <OptionsLinkPage
          linkPage={props.linkPage}
          setIsThreeDotsActive={props.setIsThreeDotsActive}
        />
      </BottomInfoPartWrapper>
    </div>
  );
};
