import { useEffect, useState } from 'react';

const GET_IMAGE_RETRIES = 3;

const useImageCheck = (imageUrl: string): [boolean, boolean, number] => {
  const [retries, setRetries] = useState<number>(GET_IMAGE_RETRIES);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const onImageError = (): void => {
    if (retries <= 0) return;
    setError(true);
    setLoading(false);
    setRetries(retries - 1);
  };

  const removeCheckImage = (): void => {
    const eraseBody = document.body;
    const eraseNode = eraseBody.querySelector('#checkImage') as HTMLImageElement | null;
    if (!eraseNode) return;
    eraseNode.removeEventListener('error', onImageError);
    eraseNode.removeEventListener('load', onImageLoaded);
    eraseBody.removeChild(eraseNode);
  };

  const onImageLoaded = (): void => {
    setError(false);
    setLoading(false);
    setRetries(GET_IMAGE_RETRIES);
    removeCheckImage();
  };

  useEffect(() => {
    if (retries <= 0) removeCheckImage();
  }, [retries]);

  useEffect(() => {
    const body = document.body;
    let node = body.querySelector('#checkImage') as HTMLImageElement | null;
    if (!node) {
      node = document.createElement('img');
      node.id = 'checkImage';
      node.setAttribute('class', 'hiddenImage');
      node.setAttribute('alt', 'hiddenPreview');
    } else {
      node.removeEventListener('error', onImageError);
      node.removeEventListener('load', onImageLoaded);
      body.removeChild(node);
    }

    node.setAttribute('src', imageUrl ?? '');
    node.addEventListener('error', onImageError);
    node.addEventListener('load', onImageLoaded);
    body.appendChild(node);

    return () => {
      removeCheckImage();
    };
  }, [imageUrl, retries]);

  return [error, loading, retries];
};

export default useImageCheck;
