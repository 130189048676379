import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  convertDateListView,
  getReadableTimeLeft,
} from '../../utils/dateConvert';
import { actionShowMessage } from '../../redux/support/action';
import { MessageType } from '../../utils/constants';
import { actionAddPayloadUnifyHistory } from '../../redux/history/actions';

const DURATION_UPDATE_INTERVAL = 1000;

const TimeDiv = styled.div`
  color: #356590;
  font-size: 16px;
  font-weight: 500;
`;

const ReadableTimeLeft = ({ location = '/channels', availableTo, availableFrom, type }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentPage = useSelector((state) => state.currentPage);
  const openToReed = availableTo === 0 && availableFrom === 0;

  const [timer, setTimer] = useState(1);
  const timerId = useRef(null);
  const [readableTimeLeft, setReadableTimeLeft] = useState(
    getReadableTimeLeft(availableTo),
  );

  const clearTimer = () => {
    clearInterval(timerId.current);
  };

  useEffect(() => {
    if (availableTo && getReadableTimeLeft(availableTo) !== readableTimeLeft) {
      setReadableTimeLeft(getReadableTimeLeft(availableTo));
    }
  }, [availableTo, timer]);

  useEffect(() => {
    timerId.current = setInterval(() => {
      setTimer((time) => time + 1);
    }, DURATION_UPDATE_INTERVAL);
    return () => {
      clearTimer();
    };
  }, []);

  useEffect(() => {
    const timeNow = Math.floor(Date.now() / 1000);
    const isValidTime = openToReed || (availableTo && availableTo >= timeNow) && availableFrom && availableFrom <= timeNow;

    if (!currentPage.isOwnContent && !isValidTime) {
      if (type === 'sharedPage') {
        history.push('/content/pages/shared');
        dispatch(
          actionShowMessage({ type: MessageType.PageNotAvailableAnyMore }),
        );
      } else {
        history.push('/content/smartfiles/shared');
        dispatch(actionShowMessage({ type: MessageType.PlaylistNotAvailableAnyMore }));
      }
    }
  }, [timer]);
  const messageToDisplay = openToReed ? t('openToReadT') : ` ${t('endsT')} ${convertDateListView(availableTo)} - ${readableTimeLeft}`;
  return (
    <TimeDiv>
      {messageToDisplay}
    </TimeDiv>
  );
};

export default ReadableTimeLeft;
