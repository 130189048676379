import React from 'react';
import SecondaryLib from './SecondaryMenu/SecondaryLib';
import SecondaryPage from './SecondaryMenu/SecondaryPage';
import SecondarySlide from './SecondaryMenu/SecondarySlide';
import SecondaryComponent from './SecondaryMenu/SecondaryComponent';
import SecondaryPdf from './SecondaryMenu/SecondaryPdf';
import SecondaryMedia from './SecondaryMenu/SecondaryMedia';
import SecondaryLink from './SecondaryMenu/SecondaryLink';
import SecondaryFile from './SecondaryMenu/SecondaryFile';
import SecondaryPlaylist from './SecondaryMenu/SecondaryPlaylist';
import SecondaryChannel from './SecondaryMenu/SecondaryChannel';
import SecondaryContacts from './SecondaryMenu/SecondaryContacts';
import SecondaryUserContact from './SecondaryMenu/SecondaryUserContact';

const SecondaryMenu = ({ ...props }) => {
  if (props.place === 'library') {
    return <SecondaryLib {...props} />;
  }
  if (props.place === 'pages') {
    return <SecondaryPage {...props} />;
  }
  if (props.place === 'components') {
    return <SecondaryComponent {...props} />;
  }
  if (props.place === 'pdf') {
    return <SecondaryPdf {...props} />;
  }
  if (props.place === 'media') {
    return <SecondaryMedia {...props} />;
  }
  if (props.place === 'links') {
    return <SecondaryLink {...props} />;
  }
  if (props.place === 'files') {
    return <SecondaryFile {...props} />;
  }

  if (props.place === 'smartfiles') {
    return <SecondaryPlaylist {...props} />;
  }

  if (props.place === 'contacts') {
    return <SecondaryContacts {...props} />;
  }
  if (props.place === 'users_smartfiles') {
    return <SecondaryUserContact {...props} />;
  }
  return <SecondaryChannel {...props} />;
};

export default SecondaryMenu;
