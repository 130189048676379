import { combineReducers, Reducer, AnyAction } from 'redux';
import { pagesReducer } from './pages/reducers';
import { userReducer } from './user/reducers';
import currentPageReducer from './currentPage/reducers';
import playlistsReducer from './playlists/reducers';
import { miniPagesReducer } from './miniPages/reducers';
import { selectedPageReducer } from './selectedPage/reducers';
import { supportReducer } from './support/reducers';
import { libraryReducer } from './library/reducers';
import { uploadReducer } from './filesUpload/reducers';
import { shortcutReducer } from './shortcuts/reducers';
import { downloadsReducer } from './downloads/reducers';
import draggableReducer from './dragable/reducers';
import miniPlaylistsReducer from './miniPlaylists/reducers';
import channelsReducer from './channels/reducers';
import { dynamicCollectionReducer } from './dynamicCollection/reducers';
import customEventReducer from './customEvent/reducers';
import contactsReducer from './contacts/reducers';
import dashboardColumnsReducer from './dashboardColumns/reducers';
import helpReducer from './help/reducers';
import inboxReducer from './inbox/reducers';
import { myActionHandler } from './reduxUtils';
import { sharedPlaylistsReducer } from './sharedPlaylists/reducers';
import { settingsReducer } from './settings/reducers';
import contentReducer from './content/contentReducers';
import deletedReducers from './deleted/deletedReducers';
import historyReducer from './history/reducers';
import muxContentReducer from './muxContentStatus/reducers';
import upvReducer from './upv/reducers';
import requestProceedReducer from './requestProceed/reducers';
import outboxReducer from './outbox/reducers';
import neuralReducer from './neural/reducers';

export interface IRootState {
  contacts: ReturnType<typeof contactsReducer>;
  currentPage: ReturnType<typeof currentPageReducer>;
  draggable: ReturnType<typeof draggableReducer>;
  pages: ReturnType<typeof pagesReducer>;
  user: ReturnType<typeof userReducer>;
  playlists: ReturnType<typeof playlistsReducer>;
  miniPages: ReturnType<typeof miniPagesReducer>;
  miniPlaylist: ReturnType<typeof miniPlaylistsReducer>;
  selectedPage: ReturnType<typeof selectedPageReducer>;
  support: ReturnType<typeof supportReducer>;
  library: ReturnType<typeof libraryReducer>;
  uploads: ReturnType<typeof uploadReducer>;
  downloads: ReturnType<typeof downloadsReducer>;
  shortcut: ReturnType<typeof shortcutReducer>;
  channels: ReturnType<typeof channelsReducer>;
  dynamicCollection: ReturnType<typeof dynamicCollectionReducer>;
  customEvent: ReturnType<typeof customEventReducer>;
  dashboardColumns: ReturnType<typeof dashboardColumnsReducer>;
  help: ReturnType<typeof helpReducer>;
  sharedPlaylists: ReturnType<typeof sharedPlaylistsReducer>;
  settings: ReturnType<typeof settingsReducer>;
  content: ReturnType<typeof contentReducer>;
  deleted: ReturnType<typeof deletedReducers>;
  historyTrace: ReturnType<typeof historyReducer>;
  mux: ReturnType<typeof muxContentReducer>;
  upv: ReturnType<typeof upvReducer>;
  requestProceed: ReturnType<typeof requestProceedReducer>;
  inbox: ReturnType<typeof inboxReducer>;
  outbox: ReturnType<typeof outboxReducer>;
  neural: ReturnType<typeof neuralReducer>;
}

const appReducer = combineReducers({
  contacts: contactsReducer,
  currentPage: currentPageReducer,
  draggable: draggableReducer,
  pages: pagesReducer,
  user: userReducer,
  playlists: playlistsReducer,
  miniPages: miniPagesReducer,
  miniPlaylist: miniPlaylistsReducer,
  selectedPage: selectedPageReducer,
  support: supportReducer,
  library: libraryReducer,
  uploads: uploadReducer,
  downloads: downloadsReducer,
  shortcut: shortcutReducer,
  channels: channelsReducer,
  dynamicCollection: dynamicCollectionReducer,
  customEvent: customEventReducer,
  dashboardColumns: dashboardColumnsReducer,
  help: helpReducer,
  sharedPlaylists: sharedPlaylistsReducer,
  settings: settingsReducer,
  content: contentReducer,
  deleted: deletedReducers,
  historyTrace: historyReducer,
  mux: muxContentReducer,
  upv: upvReducer,
  requestProceed: requestProceedReducer,
  inbox: inboxReducer,
  outbox: outboxReducer,
  neural: neuralReducer,
});

// Dynamic way better, than hardcoded interface 'RootState', but firstly it require typify all reducers. Return of some reducers(like historyReducer) can be "never" type(because some parts of returned state is "never" type).
// export type RootState = ReturnType<typeof appReducer>;

const rootReducer: Reducer = (state, action:AnyAction) => {
  myActionHandler(action, state);
  return appReducer(state, action);
};

export default rootReducer;
