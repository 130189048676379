import React, { useEffect, useRef } from 'react';
import styles from './index.module.scss';
import { DATA_PARENTS } from '../../utils/constants';

const ExtensibleInput = ({
  value,
  setValue,
  readOnly,
  save,
  isExpanded,
  isInItalic,
  id,
  isDescriptionMargin,
  noDescription,
}) => {
  const inputDescription = useRef(null);

  const onChangeHandler = (event) => {
    event.target.rows = 1;
    event.target.rows = ~~(event.target.scrollHeight / 21) || 1;
    setValue(event.target.value);
  };
  useEffect(() => {
    if (inputDescription.current) {
      inputDescription.current.rows = 1;
      inputDescription.current.rows = ~~(inputDescription.current.scrollHeight / 21) || 1;
    }
  }, [value]);

  return (
    <div
      id={id}
      className={`
        ${styles.wrapper} 
        ${styles.areaWrapper} 
        ${isExpanded ? styles.isExpanded : ''}
        ${isDescriptionMargin ? styles.isDescriptionMargin : ''}
        ${readOnly ? styles.readOnly : ''}
        ${noDescription ? styles.noScroll : ''}
      `}
      data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
    >
      <textarea
        className={`${isInItalic ? styles.in_italic : ''} ${
          readOnly ? styles.inactiveText : ''
        }`}
        onBlur={save}
        value={value}
        ref={inputDescription}
        readOnly={readOnly}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default ExtensibleInput;
