import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DropSvg } from '../../../images/icons/drop_file.svg';
import { svgCustomBorder, svgCustomBorderHovered } from '../../../utils/UIHelpers/customBorder';

const EmptyShell = styled.div`
  padding: 0 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top:20px;
`;

const Drop = styled.div`
  display: flex;
  width: calc(100vw - 580px);
  height: 290px;
  border-radius: 12px;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  background: #FFFBF0;
  background-image: ${svgCustomBorder};
  width: calc(100vw - 580px);
  max-width: 1100px;
  min-width: 800px;
  
  @media (max-width: 1400px) {
    width: calc(100vw - 347px);
  }

  @media (max-width: 1050px) {
    min-width: 827px;
  }
  ${({ hasDrag }) => (hasDrag
    ? `
      background: #FFEEBF;
      background-image: ${svgCustomBorderHovered};
  `
    : '')}

  &:hover {
    background: #ffeebf;
    background-image: ${svgCustomBorderHovered};
    border-color: #FFC72C;
  }
`;

const Icon = styled.div`
`;

const TextHeader = styled.div`
  margin-top: 16px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #585858;
`;
const Text = styled.div`
  margin-top: 4px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.8px;
  line-height: 16px;
  text-align: center;
  color: #FFC72C;
  width: 248px;
`;

const Empty = ({
  indexHover,
  onDragEnter,
  onDragLeave,
  onEditorAreaMouseDrop,
  submitForm,
  upvCard,
}) => {
  const inputFile = useRef(null);
  const { t } = useTranslation();
  const onClick = () => {
    inputFile.current.click();
  };
  return (
    <EmptyShell
      upvCard={upvCard}
      onDragEnter={() => onDragEnter('empty')}
      onDragLeave={onDragLeave}
      onDrop={(e) => {
        onEditorAreaMouseDrop(e, null, 'last');
      }}
    >
      <Drop onClick={onClick} hasDrag={indexHover === 'empty'}>
        <Icon>
          <DropSvg />
        </Icon>
        <TextHeader>{t('dropFilesHereOrT')}</TextHeader>
        <Text>{t('browseFilesT')}</Text>
      </Drop>
      <input
        // className={cn('invisible_input')}
        onChange={submitForm}
        type="file"
        name="file"
        webkitdirectory
        // eslint-disable-next-line react/no-unknown-property
        mozdirectory
        // eslint-disable-next-line react/no-unknown-property
        directory
        multiple
        style={{ display: 'none' }}
        // accept={`image/png,
        //     image/jpeg,
        //     image/gif,
        //     image/svg+xml,
        //     image/bmp,
        // `}
        ref={inputFile}
      />
    </EmptyShell>
  );
};

export default Empty;
