import { MiniPages } from './types';

export const actionGetMiniPage = (id, folderId) => {
  return {
    type: MiniPages.getMiniPage,
    payload: { id, folderId },
  };
};

export const actionSaveMiniPage = (miniPage) => {
  return {
    type: MiniPages.saveMiniPage,
    payload: miniPage,
  };
};
