import styled from 'styled-components/macro';

export const CheckBoxWrapper = styled.div`
  width: 22px;
  height: 22px;
  ${({ isSelected }) => (isSelected
    ? `
    background-color: #FFC72C;
  `
    : '')}

  overflow: hidden;
  border-radius: 4px;

  &:hover {
    background-color: #ffdf85;
  }

  svg {
    position: relative;
    width: 18px;
    height: 18px;
    top: 2px;
    left: 2px;
  }

  svg path {
    stroke: #343434;
  }
`;
