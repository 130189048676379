import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import raStyles from './index.module.scss';
import DatePicker from '../DatePicker';
import NewChannelsInput from '../../pages/Playlist/NewChannelsInput';
import {
  actionLoadingItem,
  actionToggleRequestSpinner,
  actionToggleSettingsSideBar,
} from '../../redux/support/action';
import {
  actionPublishPlaylistS,
} from '../../redux/playlists/action';
import { Inputs } from '../../pages/Playlist/PlaylistInput';
import { actionCloseModal } from '../../redux/user/action';
import Button2023 from '../Buttons/Button2023';
import useThrottle from '../../utils/hooks/useThrottle';
import { DATA_PARENTS } from '../../utils/constants';

const cx = classNames.bind(raStyles);

const PublishPlaylistModal = ({ isAddChannel }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    id,
    usedInChannels = [],
    inputs,
    title,
  } = useSelector((state) => state.currentPage);
  const { isShowSettings, loadingItemId } = useSelector(
    (state) => state.support,
  );
  const [selectedOption, setSelectedOption] = useState(null);
  const [localState, setLocalState] = useState({});
  const { folderId } = useSelector((state) => state.user);
  const { myChannels } = useSelector((state) => state.channels);
  const [used, setUsed] = useState(usedInChannels);
  const [addedNew, setAddedNew] = useState({});
  const [isNeedToWarning, setIsNeedToWarning] = useState(false);
  const [loading, setLoading] = useState(
    history.location.pathname === '/maker',
  );

  const [throttleSave] = useThrottle();
  useEffect(() => {
    if (id === loadingItemId) {
      setLoading(false);
    }
  }, [id]);

  const updateLinks = (usedChannels) => {
    // dispatch(actionPlaylistChannelPublishManagement(usedChannels, { id }));
  };

  useEffect(() => {
    return () => {
      dispatch(actionLoadingItem(''));
    };
  }, []);

  useEffect(() => {
    if (folderId) {
      setUsed({ ...used, [folderId]: myChannels[folderId] });
    }
  }, [folderId]);

  useEffect(() => {
    if (used !== usedInChannels) {
      throttleSave(() => updateLinks(used));
    }
  }, [used]);

  const closeModal = () => {
    dispatch(actionCloseModal(''));
  };
  const tryPublish = () => {
    if (isShowSettings) dispatch(actionToggleSettingsSideBar());
    if (Object.values(addedNew || {}).length) {
      const playlist = { id, inputs, title };
      dispatch(actionToggleRequestSpinner(id));
      dispatch(actionPublishPlaylistS(addedNew, {}, playlist, localState));
      closeModal();
    } else {
      setIsNeedToWarning(true);
    }
  };

  return (
    <div className={`${cx('modal_wrapper')} global_modal`} onClick={closeModal}>
      <div className={cx('modal_offset')}>
        <div
          onClick={(e) => e.stopPropagation()}
          className={cx('modal', 'PublishPlaylistModal')}
        >
          <div className={cx('content_wrapper')}>
            <div className={cx('header')}>
              <div className={cx('main_label', 'channels_input')}>
                {t('shareYourSmartFileT')}
              </div>
            </div>
            <div className={cx('scrollable_parent')}>
              <div className={cx('item_wrapper', 'channel2023')}>
                <NewChannelsInput
                  state={Inputs.channel2023}
                  setIsNeedToWarning={setIsNeedToWarning}
                  isNeedToWarning={isNeedToWarning}
                  used={addedNew}
                  usedOld={isAddChannel ? used : {}}
                  // setUsedNew={setUsedNew}
                  setUsed={setAddedNew}
                  itemId={id}
                  // isAddChannel={isAddChannel}
                  isPublishPlaylist
                />
              </div>
              <div className={cx('item_wrapper', 'isPlaylist', 'availability')}>
                <div className={cx('item_title', 'availability')}>
                  {t('availabilityT')}
                </div>
                <DatePicker
                  localState={localState}
                  setLocalState={setLocalState}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  type="PublishPlaylistModal"
                />
              </div>
            </div>
            <div className={cx('controls_wrapper')}>
              <Button2023
                variant="primary"
                derivedParentData={DATA_PARENTS.PlaylistBuilderLibrary}
                height={32}
                text={t('cancelUpT')}
                handleButtonClick={closeModal}
                noMargins
              />
              <Button2023
                variant="secondary"
                derivedParentData={DATA_PARENTS.PlaylistBuilderLibrary}
                height={32}
                text={t('shareUpT')}
                handleButtonClick={tryPublish}
                noMargins
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublishPlaylistModal;
