import { Route, Switch } from 'react-router-dom';
import React, { lazy } from 'react';
import Login from '../../pages/loginLogout/Login';
import Registration from '../../pages/loginLogout/Registration';
import LogOut from '../../pages/loginLogout/Logout';
import PrivateRoute from '../PrivateRoute';
import PageDataLayer from '../../pages/EditPage/Page';
import ViewPageFromBuilder from '../../pages/EditPage/ViewPageFromBuilder';
import BrowsePlaylists from '../../pages/BrowsePlaylists';
import ViewPage from '../../pages/ViewPage';
import { SharedByWebPage, SharedPage, ViewPublishPage } from '../../pages/SharedPage';
import ViewLibraryComponentDataLayer from '../../pages/LibraryComponent/ViewLibraryComponent';
import Channels from '../../pages/Channels/Channels';
import Confirm from '../../pages/Confirm';
import ForgotPassword from '../../pages/loginLogout/ForgotPassword/ForgotPassword';
import ChannelForbidden from '../../pages/Channels/ChannelForbidden';
import ResetPassword from '../../pages/loginLogout/ForgotPassword/ResetPassword';
import SharedLibraryComponent from '../../pages/LibraryComponent/SharedLibraryComponent';
import Contacts from '../../pages/Contacts';
import EmbedPage from '../../pages/SharedPage/EmbedPage';
import Views from '../../pages/Dashboard';
import Help from '../../pages/Help';
import UnauthorizedOperation from '../../pages/AccessPage/UnauthorizedOperation';
import { ViewLibraryComponentSharedPlaylistItem } from '../../pages/LibraryComponent';
import ConfirmEmailChange from '../../pages/Confirm/ConfirmEmailChange';
import ProtectedConfirmEmailChange from '../../pages/Confirm/ProtectedConfirmEmailChange';
import MainLayout from '../MainLayout';
import LibraryNew from '../../pages/Library/LibraryNew';
import Home from '../../pages/Home';
import EmailLinkRedirect from '../EmailRedirects/EmailLinkRedirect';
import PlaylistMain from '../../pages/Maker';
import PlaylistShared from '../../pages/Maker/PlaylistShared';
import { SharedByWebPlaylist } from '../../pages/Maker/PlaylistSharedByWeb';
import Inbox from '../../pages/Inbox';
import NewPlayer from '../../pages/NewPlayer/NewPlayer';
import { SHARED_PLAYLIST_URL } from '../../utils/constants';
import OutBox from '../../pages/OutBox';

const Editor = lazy(() => import('../../pages/Lexical'));

const Router = () => {
  return (
    <Switch>
      <Route component={Login} path="/login" exact />
      <Route component={ForgotPassword} path="/forgot_password" exact />
      <Route component={ResetPassword} path="/reset_password/:key" exact />
      <Route component={Registration} path="/signup" exact />
      <Route component={LogOut} path="/logout" exact />
      <Route component={ViewPublishPage} path="/publish_page/:id" exact />
      <Route component={SharedPage} path="/shared_page/:id" exact />
      <Route component={SharedByWebPage} path="/sharedwebpage/:id" exact />
      <Route component={SharedByWebPlaylist} path={`/${SHARED_PLAYLIST_URL}/:id`} exact />
      <Route component={PlaylistShared} path="/shared_playlist/:id" />
      <Route component={EmbedPage} path="/embed_page/:id" exact />
      <Route component={Confirm} path="/confirm_invite/:key" />
      <Route component={Editor} path="/playground" />
      <Route component={ChannelForbidden} path="/channel_forbidden" />
      <Route component={UnauthorizedOperation} path="/unauthorized_operation" />
      <Route component={ConfirmEmailChange} path="/confirm_new_email/:jwt" />
      <Route
        component={NewPlayer}
        path="/shared_player/:channelId/:id/:itemNumber?"
      />
      <MainLayout>
        <PrivateRoute
          component={LibraryNew}
          path="/content/:place/:selector"
          exact
        />
        <PrivateRoute component={Channels} path="/channel/:id" exact />
        <PrivateRoute
          component={ProtectedConfirmEmailChange}
          path="/protected_confirm_new_email"
        />

        <PrivateRoute component={ViewPageFromBuilder} path="/edit_page/:id" />
        <PrivateRoute component={PageDataLayer} path="/page/:id" />
        <PrivateRoute component={Contacts} path="/contacts" />
        <PrivateRoute
          component={PlaylistMain}
          path="/maker/:id/:type/:channelId?"
        />
        <PrivateRoute component={PageDataLayer} path="/libraryPage/:id" />
        <PrivateRoute
          component={BrowsePlaylists}
          path="/browse_playlists"
          exact
        />
        <PrivateRoute component={ViewPage} path="/read_pages/:id/:num" />
        <PrivateRoute component={ViewPage} path="/preview_pages/:id/:num" />
        <PrivateRoute
          component={ViewLibraryComponentDataLayer}
          path="/libraryComponent/:id"
        />
        <PrivateRoute
          component={ViewLibraryComponentSharedPlaylistItem}
          path="/sharedPlaylistItem/:id"
        />
        <PrivateRoute
          component={SharedLibraryComponent}
          path="/shared_libraryComponent/:playlistId/:linkPageId/:channelId"
          exact
        />
        <PrivateRoute
          component={NewPlayer}
          path="/player/:channelId/:id/:itemNumber?"
        />
        <PrivateRoute component={Views} path="/views" />
        <PrivateRoute component={Help} path="/help" />
        <PrivateRoute component={EmailLinkRedirect} path="/mail_redirect" />
        <PrivateRoute component={Inbox} path="/inbox/:type?" />
        <PrivateRoute component={OutBox} path="/outbox/:type?" />
        <PrivateRoute component={Views} path="/board" />
        <PrivateRoute component={Home} path="/" />
      </MainLayout>
    </Switch>
  );
};

export default Router;
