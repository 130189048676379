import React from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import TopPart from './TopPart';
import BottomPartView from './BottomPartView';
import BottomPart from './BottomPart';

const cn = classNames.bind(styles);

const DynamicCollectionCreatorUI = (props) => {
  if (props.isLibrary) {
    return (
      <>
        <TopPart {...props} />
        <BottomPart {...props} />
      </>
    );
  }
  return (
    <div className={cn('wrapper', { leftBallancer: props.isAllLibraryItems })}>
      <div className={cn('container')}>
        {!props.isDynamicCollection && (
          <>
            {!props.isFullScreen && (
              <TopPart
                isShowFilters={props.isShowFilters}
                toggleEnableFilters={props.toggleEnableFilters}
                filter={props.filter}
                libFilterSearch={props.libFilterSearch}
              />
            )}
            <BottomPart
              libFilterSearch={props.libFilterSearch}
              filter={props.filter}
              setFilter={props.setFilter}
              isShowFilters={props.isShowFilters || props.isFullScreen}
              isFullScreen={props.isFullScreen}
              isTagsOpened={props.isTagsOpened}
              isDateOpened={props.isDateOpened}
              isFileTypeOpened={props.isFileTypeOpened}
              refTags={props.refTags}
              refDate={props.refDate}
              tags={props.tags}
              refFileType={props.refFileType}
              toggleIsTagsOpened={props.toggleIsTagsOpened}
              toggleIsDateOpened={props.toggleIsDateOpened}
              toggleIsFileTypeOpened={props.toggleIsFileTypeOpened}
              clearAllFilters={props.clearAllFilters}
              isHasSomeFilter={props.isHasSomeFilter}
              isShowParentFilter={props.isShowParentFilter}
            />
          </>
        )}

        {props.isDynamicCollection && !props.isEdit && (
          <BottomPartView
            isEdit={props.isEdit}
            libFilterSearch={props.libFilterSearch}
            filter={props.filter}
          />
        )}
        {props.isDynamicCollection && props.isEdit && (
          <BottomPart
            libFilterSearch={props.libFilterSearch}
            filter={props.filter}
            isSearch={!props.isFullScreen}
            setFilter={props.setFilter}
            setLibFilterSearch={props.setLibFilterSearch}
            isShowFilters
            isTagsOpened={props.isTagsOpened}
            isDateOpened={props.isDateOpened}
            isFileTypeOpened={props.isFileTypeOpened}
            refTags={props.refTags}
            refDate={props.refDate}
            tags={props.tags}
            refFileType={props.refFileType}
            toggleIsTagsOpened={props.toggleIsTagsOpened}
            toggleIsDateOpened={props.toggleIsDateOpened}
            toggleIsFileTypeOpened={props.toggleIsFileTypeOpened}
            clearAllFilters={props.clearAllFilters}
            isHasSomeFilter={props.isHasSomeFilter}
            isShowParentFilter={props.isShowParentFilter}
          />
        )}
      </div>
    </div>
  );
};

export default DynamicCollectionCreatorUI;
