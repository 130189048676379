import {
  TableCell,
  TableCellLayout,
  TableRow,
} from '@fluentui/react-components';
import React from 'react';
import '../table.scss';

import { useOpenPlaylist } from '../helpers/openPlaylistHook';
import { HOCActionSell } from './HOCActionSell';
import useComponentVisible from '../../../../utils/hooks/useComponentVisible';


export const FluentTableViewRow = ({ item, columnSizing_unstable }) => {
  const handlerOpenPlaylist = useOpenPlaylist();
  const [refMenu, isDropDownVisible, setIsDropDownVisible] = useComponentVisible(null, 'miniPage');

  const toggleShowThreeDots = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDropDownVisible(!isDropDownVisible);
  };


  const openPlaylist = (itemId, isShared, event) => {
    handlerOpenPlaylist({ playlistId: itemId, isShared, event });
  };

  return (

    <TableRow
      onContextMenu={toggleShowThreeDots}
      onClick={(e) => openPlaylist(item.id, item.isShared, e, item)}
    >
      <TableCell
        {...columnSizing_unstable.getTableCellProps('checkbox')}
      >
        <TableCellLayout truncate media={item.checkbox.icon}>
          {item.checkbox.label}
        </TableCellLayout>
      </TableCell>
      <TableCell {...columnSizing_unstable.getTableCellProps('image')}>
        <TableCellLayout truncate className="cell-wrapper">{item.image.label}</TableCellLayout>
      </TableCell>
      <TableCell {...columnSizing_unstable.getTableCellProps('name')}>
        <TableCellLayout truncate>{item.name.label}</TableCellLayout>
      </TableCell>
      <TableCell {...columnSizing_unstable.getTableCellProps('sender')}>
        <TableCellLayout truncate>{item.sender.label}</TableCellLayout>
      </TableCell>
      <TableCell {...columnSizing_unstable.getTableCellProps('board')}>
        <TableCellLayout truncate media={item.board.icon}>
          {item.board.label}
        </TableCellLayout>
      </TableCell>
      <TableCell
        {...columnSizing_unstable.getTableCellProps('availability')}
      >
        <TableCellLayout truncate media={item.availability.icon}>
          {item.availability.label}
        </TableCellLayout>
      </TableCell>
      <TableCell
        {...columnSizing_unstable.getTableCellProps('created')}
      >
        <TableCellLayout truncate media={item.created.icon}>
          {item.created.label}
        </TableCellLayout>
      </TableCell>
      <TableCell
        {...columnSizing_unstable.getTableCellProps('modified')}
      >
        <TableCellLayout truncate media={item.modified.icon}>
          {item.modified.label}
        </TableCellLayout>
      </TableCell>
      <TableCell {...columnSizing_unstable.getTableCellProps('size')}>
        <TableCellLayout truncate>{item.size.label}</TableCellLayout>
      </TableCell>
      <TableCell
        {...columnSizing_unstable.getTableCellProps('progress')}
      >
        <TableCellLayout truncate media={item.progress.icon}>
          {item.progress.label}
        </TableCellLayout>
      </TableCell>
      <TableCell
        {...columnSizing_unstable.getTableCellProps('actions')}
      >
        <TableCellLayout truncate media={item.actions1.icon}>
          <HOCActionSell
            refMenu={refMenu}
            isDropDownVisible={isDropDownVisible}
            setIsDropDownVisible={setIsDropDownVisible}
            label={item.actions1.label}
          />
        </TableCellLayout>
      </TableCell>
    </TableRow>

  );
};
