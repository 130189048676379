import EditPlaylist from '../playlists/types';
import SupportAction from '../support/types';

const defaultState = { counter: 0, sharedPlaylist: {} };

export const sharedPlaylistsReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SupportAction.InitialDataFetch: {
      return { ...state, counter: payload.playlistToSharingCount };
    }
    case EditPlaylist.UpdateStateSharedPlaylistsR: {
      const { sharedStatePlaylist } = payload;
      return { ...sharedStatePlaylist };
    }
    default:
      return state;
  }
};
