import React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';

const ProgressBarBG = styled.div`
  ${({ fullLength }) => (fullLength
    ? 'width:100%;'
    : `
     top: 13px;
     width:178px;`)}

  height: 6px;

  position: relative;
  background: rgba(52, 52, 52, 0.1);
  border-radius: 4px;

  .show_me_whore_tooltip {
    visibility: hidden;
  }
  &:hover {
    .show_me_whore_tooltip {
      visibility: visible;
    }
  }
`;

const blinkingAnima = keyframes`
  0% {
    background: #379b20

  }
  50% {
    background: #8fc75b;

  }
  100% {
    background: #379b20
  }
`;
export const zeroAnima = keyframes`
  0% {


  }
  50% {


  }
  100% {

  }
`;

const ProgressBarFilled = styled.div`
  width: ${({ widthInPercent }) => `${widthInPercent}%`};
  height: 6px;
  position: absolute;
  left: 0;
  top: 0;

  border-radius: 4px;
  transition: all ease-out 0.25s;
  ${({ widthInPercent }) => {
    if (widthInPercent < 30) {
      return ' background: #FFC72C;';
    }
    if (widthInPercent < 50) {
      return '  background: #bdc462;';
    }
    if (widthInPercent < 70) {
      return '  background: #8fc75b;';
    }
    return '  background: #379b20;';
  }}
  animation: ${(props) => (props.widthInPercent === 100 && props.fullLength
    ? css`
          ${blinkingAnima} 2s ease-in-out infinite forwards;
        `
    : css`
          ${zeroAnima};
        `)};
`;
const UpVcompleteProgressBar = ({
  widthInPercent,
  children,
  fullLength = false,
}) => {
  return (
    <ProgressBarBG fullLength={fullLength}>
      <ProgressBarFilled
        widthInPercent={widthInPercent}
        fullLength={fullLength}
      />
      {children}
    </ProgressBarBG>
  );
};

export default UpVcompleteProgressBar;
