import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import rawStyles from './index.module.scss';
import { actionCreator } from '../../shared/redux/actionHelper';
import { CurrentPage } from '../../redux/currentPage/types';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { ReactComponent as GridSvg } from '../../images/icons/view-grid.svg';
import { ReactComponent as ListSvg } from '../../images/icons/view-list.svg';
import { DATA_PARENTS, emptyCallback } from '../../utils/constants';

const cn = classNames.bind(rawStyles);

export const ListGridButton = ({
  isActive = true,
  isCantToggleView,
  setIsLocalState,
  isChannelUsers,
  isDisable,
  isLocalState,
  newUPVMode,
  stickyAdjacent,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isCardView } = useSelector((state) => state.currentPage);
  const onViewToggleClick = () => {
    if (isDisable || isChannelUsers) return;
    if (!isCantToggleView && !setIsLocalState) dispatch(actionCreator(CurrentPage.ToggleIsCardView));
    if (!isCantToggleView && !!setIsLocalState) setIsLocalState(!isLocalState);
  };

  const calcButtonState = setIsLocalState ? isLocalState : isCardView;

  if (!isActive) return <></>;

  if (stickyAdjacent) {
    return (
      <div className={cn(rawStyles.view_shell)}>
        <Tooltip
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          text={t('cardViewT')}
          direction="up"
          moveTop
        >
          <div
            className={cn('view', 'sticky_mode_left', {
              disable: isDisable || isChannelUsers,
              low_mode: newUPVMode,
              active: calcButtonState,
            })}
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            onClick={isDisable ? emptyCallback : onViewToggleClick}
          >
            <GridSvg />
          </div>
        </Tooltip>
        <Tooltip
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          text={t('listViewT')}
          direction="up"
          moveTop
        >
          <div
            className={cn('view', 'sticky_mode_right', {
              disable: isDisable || isChannelUsers,
              low_mode: newUPVMode,
              active: !calcButtonState,
            })}
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            onClick={isDisable ? emptyCallback : onViewToggleClick}
          >
            <ListSvg />
          </div>
        </Tooltip>
      </div>
    );
  }

  return (
    <div
      className={cn('view', {
        disable: isDisable || isChannelUsers,
        low_mode: newUPVMode,
      })}
      data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
      onClick={isDisable ? emptyCallback : onViewToggleClick}
    >
      {calcButtonState ? <GridSvg /> : <ListSvg />}
    </div>
  );
};

export const ListGridButtonWithTooltip = ({
  isActive,
  isCantToggleView,
  direction = 'up',
  isLocalState,
  isDisable,
  isChannelUsers,
  setIsLocalState,
  newUPVMode,
  stickyAdjacent,
}) => {
  const { t } = useTranslation();
  if (isCantToggleView || stickyAdjacent) {
    return (
      <ListGridButton
        isDisable={isDisable}
        isChannelUsers={isChannelUsers}
        isCantToggleView={isCantToggleView}
        isActive={isActive}
        setIsLocalState={setIsLocalState}
        isLocalState={isLocalState}
        stickyAdjacent={stickyAdjacent}
      />
    );
  }
  return (
    <Tooltip
      data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
      text={isLocalState ? t('cardViewT') : t('listViewT')}
      direction={direction}
      moveTop
    >
      <ListGridButton
        isCantToggleView={isCantToggleView}
        isActive={isActive}
        isDisable={isDisable}
        newUPVMode={newUPVMode}
        isChannelUsers={isChannelUsers}
        setIsLocalState={setIsLocalState}
        isLocalState={isLocalState}
      />
    </Tooltip>
  );
};
