import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import styles from './index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import { ReactComponent as CloseSvg } from '../../images/icons/close_24_white.svg';
import { ReactComponent as CheckSvg } from '../../images/icons/check_24.svg';

const cx = classNames.bind(styles);

const Modal = styled.div`
  width: 300px;
  height: 168px;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin: auto;
  background-color: #242633;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.div`
  margin-top: 56px;
  width: 252px;
  height: 33px;
  font-family: "RobotoFlex", sans-serif;
  font-size: 14px;
  color: #ffffff;
`;

const CloseExit = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  //box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25), 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background-color: rgba(31, 40, 85, 0.1);
    border: 1px solid #ffffff;
  }
  //margin: auto;
  //background-color: #242633;
`;

const CloseOk = styled.div`
  //padding: 0 8px;

  font-size: 12px;
  width: 96px;
  height: 31px;
  background: #fcfdff;
  /* UI/UI 9 */

  border: 1px solid #ffffff;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 23px;
  justify-content: center;
  &:hover {
    background-color: rgba(255, 255, 255, 0.84);
    border: 1px solid rgba(183, 183, 183, 0.84);
  }
`;

const ConfirmModalExitPlaylist = () => {
  const dispatch = useDispatch();
  const {
    dataPayload: { confirm = () => {} },
  } = useSelector((redux) => redux.user);
  const close = () => {
    dispatch(actionCloseModal());
  };
  const okHandler = () => {
    confirm();
    close();
  };
  return (
    <div onClick={close} className={cx('modal_wrapper', 'confirm_close')}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <CloseExit onClick={close}>
          <CloseSvg />
        </CloseExit>
        <Text>
          Close the SmartFile to inform the author you’ve finished to read the
          playlist.
        </Text>
        <CloseOk onClick={okHandler}>
          <CheckSvg />
          CLOSED
        </CloseOk>
      </Modal>
    </div>
  );
};

export default ConfirmModalExitPlaylist;
