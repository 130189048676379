import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GearSvg } from '../../../images/icons/settings_20.svg';
import usersStyles from './MemberContent.module.scss';
import { showArrowType } from '../../../utils/tableHelpers/tableUi';
import { sortedFunctions } from '../../../utils/dateConvert';

const usersContentStyles = classNames.bind(usersStyles);

const MemberTableHeader = (props) => {
  const { t } = useTranslation();
  const arraySortedKey = Object.keys(sortedFunctions);

  const handlerClick = (type) => {
    if (arraySortedKey[type] === props.sortType) props.setSort(arraySortedKey[type + 1]);
    else props.setSort(arraySortedKey[type]);
  };

  if (props.isHide) return <></>;

  return (
    <div className={usersContentStyles('header_table_row')} draggable="false">
      <div className={usersContentStyles('row_justification')}>
        <div
          className={usersContentStyles('cell', 'name')}
          onClick={() => {
            handlerClick(0);
          }}
        >
          {t('nameT')}
          {showArrowType(0, props.sortType)}
        </div>
        <div className={usersContentStyles('cell', 'function')}>{t('functionT')}</div>
        <div className={usersContentStyles('cell', 'organization')}>
          <div className={usersContentStyles('header_text')}>{t('organizationT')}</div>
        </div>
        <div className={usersContentStyles('cell', 'invited')}>
          <div className={usersContentStyles('header_text')}>{t('invitedByT')}</div>
        </div>
        <div
          className={usersContentStyles('cell', 'role')}
          onClick={() => {
            handlerClick(12);
          }}
        >
          {t('roleT')}
          {showArrowType(12, props.sortType)}
        </div>
        <div
          className={usersContentStyles('cell', 'status')}
          onClick={() => {
            handlerClick(14);
          }}
        >
          {t('statusT')}
          {showArrowType(14, props.sortType)}
        </div>
        <div className={usersContentStyles('cell', 'gear')}>
          {t('actionsT')}
        </div>
      </div>
    </div>
  );
};

export default MemberTableHeader;
