import React from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import moduleStyles from '../../../pages/Library/LibraryHeader.module.scss';
import { ChannelsSieveTypes, DATA_PARENTS } from '../../../utils/constants';
// import { ReactComponent as SortSvg } from '../../../images/icons/sort.svg';
import { ReactComponent as SortSvg } from '../../../images/2023/svg/small/sort_18_18.svg';
import SortOption from '../../DropDownOption/SortOption';
import { ListGridButtonWithTooltip } from '../ListGridButton';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';

const styles = classNames.bind(moduleStyles);

const ButtonWrapper = styled.div`
  width: ${(props) => props.width};
  display: flex;
  justify-content: center;
  height: ${(props) => props.width};
  padding-top: 5px;
  background: white;
  border: 0.6px solid #ece2e2;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 #7a69691a;
  &:hover {
    color: #484343;
    border: 0.6px solid #ffc72c;
    background-color: #ffeebf;
  }
  & svg path {
    stroke: #484343;
  }
  ${({ isActive }) => (isActive
    ? `
    color:#484343;
    border: 0.6px solid #FFC72C;
    background-color:#FFEEBF;
  `
    : '')}
`;
const SortAndView = ({ ...props }) => {
  const itemsNumber = useSelector((state) => state.content.contentIds.length) || props.isHelp;
  const isCantToggleView = !itemsNumber || props.isCantToggleView;
  const [refShowMenuSort, isShowMenuSort, setShowMenuSort] = useComponentVisible(false);

  const { contentIds, activeNavSlider } = useSelector((state) => state.content);
  const isChannelUsers = activeNavSlider === 'channelUsers';
  const isDisable = (!contentIds.length && !props.isHelp)
    || (isChannelUsers && contentIds.length === 1)
    || props.isContacts;

  return (
    <div
      className={styles('top_sorting_parent', {
        noRightSpacing: props.isManyPages,
        playlistSpacing: props.isManyPlaylists,
      })}
      data-parent={`${
        props.newUPVMode ? DATA_PARENTS.PlaylistBuilderLibrary : ''
      } `}
    >
      <div
        data-parent={`${
          props.newUPVMode ? DATA_PARENTS.PlaylistBuilderLibrary : ''
        } `}
        className={styles('sort_and_view', 'isFullScreen')}
      >
        <div
          data-cy="openSort"
          ref={refShowMenuSort}
          data-parent={`${
            props.newUPVMode ? DATA_PARENTS.PlaylistBuilderLibrary : ''
          } `}
          onClick={() => {
            if (isDisable) return;
            setShowMenuSort(!isShowMenuSort);
          }}
          className={styles('sort', {
            disabled: isDisable,
            sort_active: isShowMenuSort,
            low_mode: props.newUPVMode,
          })}
        >
          {!props.isHideSort && (
            <ButtonWrapper
              data-parent={`${props.newUPVMode ? DATA_PARENTS.PlaylistBuilderLibrary : ''} `}
              width={props.newUPVMode ? '28px' : '32px'}
              isActive={isShowMenuSort}
            >
              <SortSvg />
            </ButtonWrapper>
          )}
          {isShowMenuSort && (
            <SortOption
              newUPVMode={props.newUPVMode}
              setSort={props.setSort}
              place={props.place}
            />
          )}
        </div>
        <div className={styles('sort_wrapper')}>
          <ListGridButtonWithTooltip
            isDisable={isDisable || props.newUPVMode}
            newUPVMode={props.newUPVMode}
            isChannelUsers={isChannelUsers}
            isCantToggleView={isCantToggleView}
            stickyAdjacent
            isActive={props.activeNavSlider !== ChannelsSieveTypes.ChannelsMembers}
          />
        </div>
      </div>
    </div>
  );
};

export default SortAndView;
