import { useEffect, RefObject } from 'react';

const useTextareaAutosize = (textAreaRef: RefObject<HTMLTextAreaElement>, value: string) => {
  useEffect(() => {
    if (textAreaRef?.current) {
      textAreaRef.current.style.height = '0px';
      const scrollHeight = textAreaRef.current.scrollHeight;
      // Set the height directly, outside the render loop
      // Trying to set this with a state or ref will produce an incorrect value.
      // (!) to avoid resize logic height offsets set row={1} to controlled textarea element
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef, value]);
};

export default useTextareaAutosize;
