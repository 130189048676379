import * as React from 'react';
import styled from 'styled-components/macro';
import { UiComponentTypes, MUX_IMAGE_PATH } from '../../utils/constants';
import { ReactComponent as ArchiveSvg } from '../../images/icons/libComponentTypeArchivePlaceholder.svg';
import { ReactComponent as AudioSvg } from '../../images/2023/svg/fileTypes/audio_44_50.svg';
import { ReactComponent as AudioSvgMaker } from '../../images/2023/svg/fileTypes/audio_black.svg';
import { ReactComponent as TextSvgMaker } from '../../images/2023/svg/fileTypes/text.svg';
import { ReactComponent as VideoSvg } from '../../images/icons/playlist_item_types/video_17_24.svg';
import { ReactComponent as BrokenImageSvg } from '../../images/icons/broken_img_32.svg';
import BlockCreatorComponent from '../LibraryMiniPage/BlockCreatorComponent';
import FallBackImage from '../FallBackImage/FallBackImage';
import Loader from './Loader';

const Image = styled.img`
  object-fit: cover;
  position: relative;
  border-radius: 6px;
`;

const BrokenImageContainer = styled.div`
  width: 120px;
  display: flex;
  justify-content: center;
  svg {
    width: 44px;
    height: 100%;
  }
`;

const Preview = ({ item, alt, isMaker }) => {
  const [error, setError] = React.useState(false);

  const onError = (e) => {
    e.target.onError = null;
    setError(true);
  };

  if (UiComponentTypes.video[item?.type]) {
    if (!item.playbackID) return <VideoSvg />;
    return <Image src={MUX_IMAGE_PATH(item.playbackID, 360, 200)} alt={alt} />;
  }
  if (isMaker) {
    if (UiComponentTypes.image[item?.type]) return <Image src={item.urlFile} alt={alt} />;
    if (UiComponentTypes.audio[item?.type]) return <AudioSvgMaker />;
  }
  if (UiComponentTypes.image[item?.type]) return <Image src={item.urlFile} alt={alt} />;
  if (UiComponentTypes.audio[item?.type]) return <AudioSvg />;
  if (
    UiComponentTypes.page[item?.type]
    || UiComponentTypes.component[item?.type]
  ) {
    const place = UiComponentTypes.page[item?.type]
      ? 'PageViewListPreview'
      : 'ComponentViewListPreview';
    return <BlockCreatorComponent item={item} place={place} isPreview />;
  }
  if (UiComponentTypes.link[item?.type]) {
    if (error) {
      return (
        <BrokenImageContainer>
          <BrokenImageSvg />
        </BrokenImageContainer>
      );
    }
    return (
      <Image
        src={item.urlFile ? item.urlFile : ''}
        alt={alt}
        onError={onError}
        onLoad={() => setError(false)}
      />
    );
  }

  if (
    UiComponentTypes.pdf[item?.type]
    || UiComponentTypes.ms[item?.type]
    || UiComponentTypes.presentation[item?.type]
    || UiComponentTypes.xls[item?.type]
    || UiComponentTypes.text[item?.type]
    || !!item.pdfPreviewUrl
  ) {
    if (!item.pdfPreviewUrl && !item.urlFile) {
      return (
        <BrokenImageContainer>
          <BrokenImageSvg />
        </BrokenImageContainer>
      );
    }
    return (
      <FallBackImage
        src={item.pdfPreviewUrl || item.urlFile || ''}
        alt=""
        type={item.pdfPreviewUrl ? 'application/pdf' : item.type}
        item={item}
        isListViewPreview
        loader={Loader}
      />
    );
  }
  if (isMaker && item?.type === 'text') {
    return (
      <TextSvgMaker />
    );
  }
  return <ArchiveSvg />;
};

export default Preview;
