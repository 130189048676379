import React from 'react';
import { useSelector } from 'react-redux';
import HeaderWrapper from './HeaderWrapper';
import PreviewSelector from './PreviewSelector';
import {
  MakerInfoContent,
  MakerInfoDivider,
  MakerInfoImageAndMainInfo, MakerInfoShell,
  MakerInfoTextAreaBlock, MakerInfoTextPlaceholder,
} from './styled';
import MakerInfoRow from './components/Info/MakerInfoRow';


const PlaylistMainInfo = ({ isRightSideBarExpanded, currentRole, handleDownload, isCanManyDownload, refForScroll }) => {
  const currentPage = useSelector((it) => it.currentPage);

  const {
    title,
    description,
    owner,
    totalPlaylistDuration,
    co_autors,
    id,
    linkPages,
    lastModifiedDate,
    createDate,
    isShowLibraryWidget,
    content,
  } = currentPage;
  const userName = owner?.showUsername && owner?.username
    ? `${owner?.username || ''}`
    : `${owner?.first_name || ''} ${owner?.last_name || ''}`;

  return (
    <MakerInfoShell>
      <MakerInfoDivider theFirst />
      <MakerInfoContent isRightSideBarExpanded={isRightSideBarExpanded}>
        <MakerInfoImageAndMainInfo>
          <PreviewSelector refForScroll={refForScroll} />
          {owner && (
          <MakerInfoTextAreaBlock>
            <HeaderWrapper
              type="title"
              text={title}
              playlistID={id}
              currentRole={currentRole}
              isShowLibraryWidget={isShowLibraryWidget}
            />
            <HeaderWrapper
              currentRole={currentRole}
              playlistID={id}
              type="description"
              text={description || content.description}
            />
            <>
              <MakerInfoDivider />
              <MakerInfoRow
                users={co_autors}
                linkPages={linkPages}
                totalPlaylistDuration={totalPlaylistDuration || 0}
                lastModifiedDate={lastModifiedDate}
                userName={userName}
                owner={owner}
                createDate={createDate}
                handleDownload={handleDownload}
                isCanManyDownload={isCanManyDownload}
              />
            </>
          </MakerInfoTextAreaBlock>
          )}{!owner && (
          <MakerInfoTextPlaceholder />
          )}
        </MakerInfoImageAndMainInfo>
      </MakerInfoContent>
    </MakerInfoShell>
  );
};

export default PlaylistMainInfo;
