import React from 'react';


const ToScrollHelper = React.memo(
  function ToScrollHelper({ toBeScrolledTo, item, scrollRef }) {
    const isNeedAddRef = toBeScrolledTo === item?.libraryComponent?.id
      || toBeScrolledTo === item?.textComponent?.id || toBeScrolledTo === item?.id;

    if (!isNeedAddRef) return <></>;

    return (
      <div ref={scrollRef} />
    );
  });

export default ToScrollHelper;
