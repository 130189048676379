import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import ReadableTimeLeft from '../../components/ReadableTimeLeft';
import { ReactComponent as ArrowLeft16Svg } from '../../images/icons/arrow_left_16.svg';
import { ReactComponent as Collapse24Svg } from '../../images/icons/collapse_24.svg';
import { ReactComponent as Expand24Svg } from '../../images/icons/expand_24.svg';
import {
  actionSetResetScale,
  actionToggleIsCollapsedContent,
} from '../../redux/currentPage/action';
import AvatarPlaceholder from '../../components/Avatar';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { DEFAULT_PAGE_PATH } from '../../utils/constants';
import {
  generateHistoryFreezePath,
  ifGotFreeze,
} from '../../components/UnifiedNavigation/helpers/historyFreezePath';
import { actionAddPayloadUnifyHistory } from '../../redux/history/actions';

const SharedHeader = ({ type, owner }) => {
  const { id: userId } = useSelector((state) => state.user);
  const historyTrace = useSelector((state) => state.historyTrace);
  const trace = historyTrace.trace;
  const history = useHistory();
  const { t } = useTranslation();
  const isViewAsCoEditViewMode = trace[trace.length - 1]
    && trace[trace.length - 1].includes('shared_playlist');

  const goBack = () => {
    const index = historyTrace.activeIndex;
    const path = trace[index];
    if (trace.length === 1 || !path) {
      history.push(generateHistoryFreezePath(history.location.pathname));
      return;
    }
    if (ifGotFreeze(trace)) {
      const exPath = trace[index - 3] || generateHistoryFreezePath(trace[index - 2]);
      history.push(exPath, { mode: 'synthetic-', exPath });
      return;
    }
    history.push(path, { mode: 'synthetic-', path });
  };

  const { isCollapsedContent } = useSelector((state) => state.currentPage);
  const dispatch = useDispatch();

  const onExpandClickHandler = () => {
    dispatch(actionToggleIsCollapsedContent(!isCollapsedContent));
    dispatch(actionSetResetScale(true));
  };

  const SharedPageTitle = () => {
    if (!userId) return 'login';

    const lastStep = historyTrace.somePayload;
    if (lastStep.departureUrl?.startsWith('/content/pages/')) return 'pages';
    if (lastStep.departureUrl?.startsWith('/content/library/')) return 'Library';
    // case with return in default
    if (userId && type === 'sharedPage') return 'pages';

    if (type === 'page') return 'SmartFiles';

    const { step } = lastStep;

    if (!step) return 'SmartFiles';
    const { from } = step;
    if (from === '/views') return 'Views';
    if (userId && type === 'sharedPage') return 'pages';
    if (userId && isViewAsCoEditViewMode) return 'edit SmartFile';
    if (userId && type === 'sharedPlaylist') return 'SmartFiles';
    return 'TBD';
  };

  const SharedPageBackAction = () => {
    const step = historyTrace.somePayload;
    const departureUrl = trace[trace.length - 1] || '';

    let url;
    if (!userId) {
      url = '/login';
    } else if (departureUrl) {
      url = departureUrl;
    } else if (type === 'sharedPage') {
      goBack();
      return;
    } else {
      url = DEFAULT_PAGE_PATH;
    }
    const statePayload = {};
    if (step && step.localSelector) statePayload.localSelector = step?.localSelector;
    if (step && step.activeSliderOption) statePayload.activeSliderOption = step?.activeSliderOption;
    dispatch(actionAddPayloadUnifyHistory(statePayload));
    history.push(url);
  };
  const tooltipMessageToDisplay = `${owner?.first_name || ''} ${owner?.last_name || ''}`;
  return (
    <div className={styles.heading}>
      <div
        className={styles.back_to_playlists_btn}
        onClick={SharedPageBackAction}
      >
        <ArrowLeft16Svg />
        {SharedPageTitle()}
      </div>
      <div className={styles.heading_center}>
        <div className={styles.avatar_wrapper}>
          <Tooltip
            text={tooltipMessageToDisplay.trim() ? tooltipMessageToDisplay : t('userNotExistT')}
            direction="up"
            place="SharedHeader"
          >
            <AvatarPlaceholder
              name={owner?.first_name}
              abbreviation={`${owner?.first_name[0] || ''}${owner?.last_name[0] || ''}`}
            />
          </Tooltip>
        </div>

        <ReadableTimeLeft type={type} />
      </div>

      <div className={styles.view_expand_wrapper}>
        {type === 'sharedPlaylist' && (
          <div className={styles.simple_button} onClick={onExpandClickHandler}>
            {!isCollapsedContent && <Collapse24Svg />}
            {isCollapsedContent && <Expand24Svg />}
            <div className={styles.text}>
              {!isCollapsedContent && t('collapseUpT')}
              {isCollapsedContent && t('expandUpT')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SharedHeader;
