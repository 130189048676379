import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import Toggler from '../../components/DropDownOption/Toggler';
import rawStyles from './index.module.scss';
import { actionSaveInputPlaylist } from '../../redux/currentPage/action';
import { ReactComponent as CheckSvg } from '../../images/icons/green_check_24.svg';
import { ReactComponent as CrossSvg } from '../../images/icons/grey_cross_24.svg';

const styles = classnames.bind(rawStyles);

export const TogglerInputs = {
  comments: {
    parameter: 'Comments to Author',
    description: 'The users are able to send comments to the author',
    value: false,
    required: false,
    field: 'isAnyCanComment',
  },
  remix: {
    parameter: 'Allow "Use & remix"',
    description:
      'The author freely lets users to use, share, repurpose and remix his SmartFile content',
    value: false,
    required: false,
    field: 'isAnyCanEdit',
  },
  isPrivate: {
    parameter: 'Private Channel',
    description:
      'A channel set to private means only the members you add have access to it',
    value: false,
    required: false,
    field: 'isPrivate',
  },
};

const TogglerInput = ({
  state,
  isEditable,
  showMessageNotEditable,
  isModalWindow,
  isChannel,
  isPage,
  itemId,
}) => {
  const dispatch = useDispatch();
  const { inCollection } = useSelector((reduxState) => reduxState.currentPage);
  const downloadValue = useSelector((reduxState) => {
    return isChannel
      ? reduxState.channels.myChannels[itemId]
          && reduxState.channels.myChannels[itemId][state.field]
      : reduxState.currentPage?.inputs[state.field];
  });
  const [commentToAuthorTogglerState, setCommentToAuthorTogglerState] = useState(downloadValue || false);

  const save = () => {
    let place = '';
    if (isPage) place = 'page';
    if (isChannel) place = 'channels';
    dispatch(
      actionSaveInputPlaylist(
        state.field,
        !commentToAuthorTogglerState,
        place,
        inCollection?.id,
        itemId,
      ),
    );
  };

  useEffect(() => {
    setCommentToAuthorTogglerState(downloadValue);
  }, [downloadValue]);

  const onClickTogglerHandler = () => {
    save();
  };
  if (isModalWindow) {
    return (
      <div
        className={styles('item_title_container', 'toggler', {
          modal: isModalWindow,
        })}
      >
        {!!downloadValue && <CheckSvg />}
        {!downloadValue && <CrossSvg />}
        <div
          className={styles('item_title', 'toggler', { modal: isModalWindow })}
        >
          {state.parameter}
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className={styles('item_title_container', 'toggler')}>
        <div
          className={styles('item_title', 'toggler', { channels: isChannel })}
        >
          {state.parameter}{' '}
          {!!state.required && <span className={styles('hint')}>*</span>}
        </div>
        {!isChannel && (
          <div
            onClick={() => {
              if (!isEditable) showMessageNotEditable();
            }}
          >
            <div className={styles({ not_editable: !isEditable })}>
              <div
                className={styles('toggler_container')}
                onClick={onClickTogglerHandler}
              >
                <Toggler
                  callback={setCommentToAuthorTogglerState}
                  isActive={commentToAuthorTogglerState}
                  togglerHeight={13}
                  togglerWidth={28}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles('item_subtitle', { channel: isChannel })}>
        {state.description}
        {isChannel && (
          <div
            onClick={() => {
              if (!isEditable) showMessageNotEditable();
            }}
          >
            <div className={styles({ not_editable: !isEditable })}>
              <div
                className={styles('toggler_container', 'channel')}
                onClick={onClickTogglerHandler}
              >
                <Toggler
                  callback={setCommentToAuthorTogglerState}
                  isActive={commentToAuthorTogglerState}
                  togglerHeight={13}
                  togglerWidth={28}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TogglerInput;
