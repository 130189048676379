import React from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GearSvg } from '../../images/icons/settings_20.svg';
import { sortedFunctions } from '../../utils/dateConvert';
import newTableStyles from '../LibraryMiniPage/RowStyles.module.scss';
import { showArrowType } from '../../utils/tableHelpers/tableUi';

const cn = classNames.bind(newTableStyles);

const ManyPagesTableHeader = (props) => {
  const arraySortedKey = Object.keys(sortedFunctions);
  const history = useHistory();
  const { t } = useTranslation();
  const { componentDescription } = useSelector((state) => state.support);
  const { isAdmin } = useSelector((state) => state.user);
  const isChannels = history.location.pathname === '/channels';

  const handlerClick = (type) => {
    if (arraySortedKey[type] === props.sortType) props.setSort(arraySortedKey[type + 1]);
    else props.setSort(arraySortedKey[type]);
  };

  if (props.isPlaylists) {
    return (
      <div
        data-parent="miniPage"
        className={cn('row_wrapper', 'header', 'playlist_header')}
      >
        <div className={cn('checkbox_container', { no_authors: !isChannels })}>
          <div
            className={cn('item_container')}
            onClick={() => {
              handlerClick(18);
            }}
          >
            <div className={cn('user_cell')}>
              {isChannels && (
                <>
                  {' '}
                  {t('authorsT')}
                  <div>{showArrowType(18, props.sortType)}</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={cn('row_container')}>
          <div
            className={cn('first_container')}
            onClick={() => {
              handlerClick(16);
            }}
          >
            <div className={cn('first_item', 'icon_placeholder', 'header')}>
              <div className={cn('header_text')}>{t('smartFileT')}</div>
              {showArrowType(16, props.sortType)}
            </div>
          </div>

          {!props.isHelp && !isAdmin && (
            <div className={cn('item_container')}>
              <div className={cn('dashboard_cell', 'header_text')}>
                {t('inViewsT')}
              </div>
            </div>
          )}
          {!!isChannels && (
            <div className={cn('item_container')}>
              <div className={cn('progress_cell', 'header_text')}>{t('progressT')}</div>
            </div>
          )}
          <div className={cn('item_container')}>
            <div className={cn('availability_cell', 'header_text')}>
              {t('availabilityT')}
            </div>
          </div>
          <div
            className={cn('item_container')}
            onClick={() => {
              handlerClick(2);
            }}
          >
            <div className={cn('last_modified_cell', 'header_text')}>
              {t('modifiedT')}
              <div>{showArrowType(2, props.sortType)}</div>
            </div>
          </div>
          <div className={cn('item_container')}>
            <div className={cn('comments_cell', 'header_text')}>{t('commentsT')}</div>
          </div>

          <div
            className={cn('last_container', {
              collapsible: componentDescription,
            })}
          >
            <div className={cn('header_actions_cell')}>
              <GearSvg />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (props.isLibrary) {
    return (
      <div
        data-parent="miniPage"
        className={cn('row_wrapper', 'header', 'library_header')}
      >
        <div className={cn('row_container')}>
          <div
            className={cn('first_container', { no_authors: !isChannels })}
            onClick={() => {
              handlerClick(0);
            }}
          >
            <div className={cn('first_item', 'icon_placeholder', 'header')}>
              <div>{t('titleT')}</div>
              {showArrowType(0, props.sortType)}
            </div>
          </div>

          <div
            className={cn('item_container', {
              collapsible: componentDescription,
              hide: componentDescription,
            })}
          >
            <div
              className={cn('channel_cell', {
                collapsible: componentDescription,
              })}
            >
              {t('channelsT')}
              {/* {showArrowType(6)} */}
            </div>
          </div>

          <div
            className={cn('item_container', {
              collapsible: componentDescription,
              hide: componentDescription,
            })}
          >
            <div className={cn('tags_cell', 'header')}>{t('tagsT')}</div>
          </div>

          <div
            className={cn('item_container', {
              collapsible: componentDescription,
              hide: componentDescription,
            })}
            onClick={() => {
              handlerClick(4);
            }}
          >
            <div className={cn('created_cell')}>
              {t('createdT')}
              <div>{showArrowType(4, props.sortType)}</div>
            </div>
          </div>

          <div
            className={cn('item_container')}
            onClick={() => {
              handlerClick(2);
            }}
          >
            <div className={cn('modified_cell')}>
              {t('modifiedT')}
              <div>{showArrowType(2, props.sortType)}</div>
            </div>
          </div>

          <div
            className={cn('last_container', {
              collapsible: componentDescription,
            })}
          >
            <div
              className={cn('header_actions_cell', {
                collapsible: componentDescription,
              })}
            >
              <GearSvg />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default ManyPagesTableHeader;
