export const Tags = {
  CreateTag: '[Tags] Create Tag',
  DeleteTag: '[Tags] Delete Tags',
  MultipleDeleteTag: '[Tags] Multiple Delete Tags',
  CheckTag: '[Tags] Check Tag',
  ChangeTagTitle: '[Tags] Change Tag Title',
  ChangeTagColor: '[Tags] Change Tag Color',
  UpdateTag: '[Tags] Update Tag',
  UpdateTagRedux: '[Tags] Update Tag REDUX',
  MoveTag: '[Tags] Move Tag (DND)',
  AddTagToPlaylist: '[Tags] Add Tag To Playlist',
  AddTagEmptyR: '[Tags] Add Empty Tag R',
  updateTagCounterS: '[Content] update TAG counter Saga',
  updateTagCounterR: '[Content] update TAG counter Redux',
  DeleteTagFromSystem: '[Tags] Delete Tag From System',
  _TagsBulkUpdate: '[Tags] _Tags Bulk Update in CurrentPage',
  _RefreshUserTags: '[Tags] _Refresh User Tags',
  RefreshUserTags: '[Tags] Refresh User Tags',
  actionAttachTagParentRedux: '[Tags] Attach Tag Parent Redux',
  actionAttachTagToParentsRedux: '[Tags] Attach Tag to Parents Redux',
  actionAttachTagsToParentRedux: '[Tags] Attach Tags to Parent Redux',
  createEmptyTag: '[Tags] create Empty Tag ',
  attachTagParentAndCurrentPageRedux:
    '[Tags] Attach Tag Parent And CurrentPage Redux',
  AddContentTagToSeveralItems: '[Hashtag] Add ContentTag To Several Items',
  AttachMultipleTagsToLibraryComponent:
    '[Hashtag] Attach Multiple Tags To LibraryComponent',
  ReplaceTagsInLibraryComponent:
    '[Hashtag] Replace Multiple Tags In LibraryComponent',
};
