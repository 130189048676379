
export const convertLexicalToText = (editorStateJson) => {
  try {
    const editorStateTextString = [];
    const paragraphs = editorStateJson.root.children;
    paragraphs.forEach((paragraph) => {
      const children = paragraph.children;
      const paragraphText = [];
      children?.forEach((child) => {
        if (child.type === 'linebreak') {
          paragraphText.push(`
          `);
        } else if (child.text) {
          paragraphText.push(child.text);
        } else if (child.children) {
          child.children.forEach((sub_child) => { paragraphText.push(sub_child.text); });
        }
      });
      editorStateTextString.push(paragraphText.join(''));
    });
    return editorStateTextString.join(`
    `);
  } catch (e) {
    return '';
  }
};
