import { call, put, select } from '@redux-saga/core/effects';
import {
  requestCreateLinkComponent,
  requestDeleteComponent,
  requestDeleteLinkPage,
  requestParser,
  requestUpdatePlaylist,
} from './request';
import { getUploads, showErrorMessage } from '../sagas/sagasUtils';
import { CurrentPage } from '../redux/currentPage/types';
import {
  BlockTypes,
  DEFAULT_DURATION,
  LibraryComponentTypes,
  PLAYLIST_ELEMENTS,
} from './constants';
import {
  actionCreateUpload,
  actionDeleteUpload,
  actionUpdateUpload,
} from '../redux/filesUpload/action';
import {
  innerHtmlDropboxEmbedTemplate,
  innerHtmlGoogleEmbedTemplate,
  removeStringifiedSymbols,
  sanitizeToLoad,
  sanitizeToSave,
} from './helpers';
import { LibraryComponents } from '../redux/library/types';
import { actionAddLibraryComponentReduxOnly } from '../redux/library/actions';
import { ContentActionType } from '../redux/content/contentTypes';
import { placementEnum } from './query/queryEnum';
import { parseFieldsToObj } from './query/helper';

const actionCreator = (type, value, place) => ({ type, payload: value, place });

export const getPositionsForAllFiles = (startPosition, endPosition, files) => {
  // if no end position assume start element is the last one.
  // totalFiles multiplied by 2 in case we have one file to avoid n - n = 0 case
  const endLimit = endPosition ?? startPosition * (files.length * 2);
  const positionRange = endLimit - startPosition;
  const positionStep = positionRange / files.length;
  return files.reduce(
    (acc, item, index) => {
      if (!index) acc[index] = startPosition;
      else {
        acc[index] = startPosition + positionStep * index;
      }
      return acc;
    },
    { defaultStep: positionStep },
  );
};

function* getNewUploadPosition() {
  const uploads = yield select(getUploads);
  const sortedUploads = Object.values(uploads || {}).sort((a, b) => b.position - a.position) || [];
  return sortedUploads[0]?.position + 1;
}

function* insertPageUploadBlock({
  newBlockData,
  isOpenCreateComponent,
  firstBlockIndex,
}) {
  yield put(
    actionCreator(CurrentPage.InsertSingleBlock, {
      firstIndex: firstBlockIndex,
      block: newBlockData,
      isOpenCreateComponent,
    }),
  );
}

export function* insertPlaylistUploadElement({
  newBlockData,
  parentId,
  userId,
}) {
  const lastModifiedDate = Math.floor(Date.now() / 1000);

  yield put(
    actionCreator(CurrentPage.InsertGoogleUploadBlockSS, {
      playlistId: parentId,
      linkPage: newBlockData,
      lastModifiedDate,
    }),
  );
  yield requestUpdatePlaylist()({
    id: parentId,
    create: {
      linkPages: [
        {
          id: newBlockData.id,
          position: newBlockData.position,
          createDate: newBlockData.createDate,
          duration: newBlockData.duration,
          addOwner: userId,
        },
      ],
    },
  });
}

export function* updatePlaylistUploadElement({
  sourceComponent,
  newChildId,
  position,
  playlistId,
}) {
  const lastModifiedDate = Math.floor(Date.now() / 1000);

  const newBlockData = {
    id: newChildId,
    type: PLAYLIST_ELEMENTS.ElementComponent,
    innerHtml: innerHtmlDropboxEmbedTemplate({
      images: [sourceComponent.urlFile],
      title: sanitizeToLoad(sourceComponent.title),
      url: sourceComponent.linkUrl,
    }),
    libraryComponent: {
      type: sourceComponent.type,
      linkUrl: sourceComponent.linkUrl,
      title: sanitizeToLoad(sourceComponent.title),
      description: sanitizeToLoad(sourceComponent.description),
      urlFile: sourceComponent.urlFile,
      foreignFileId: sourceComponent.foreignFileId,
      id: sourceComponent.id,
    },
    position,
  };

  yield put(
    actionCreator(CurrentPage.UpdateGoogleUploadBlockSS, {
      playlistId,
      linkPage: newBlockData,
      lastModifiedDate,
    }),
  );

  yield call(requestUpdatePlaylist(), {
    id: playlistId,
    updateNested: {
      linkPages: [
        { id: newBlockData.id, field: parseFieldsToObj(newBlockData) },
      ],
    },
  });
}

function* updatePageUploadBlock({
  sourceComponent,
  newChildId,
  innerHtml,
  data,
  libraryComponentId,
  isOpenCreateComponent,
  type,
  newPosition,
  firstBlockIndex,
  pageEditorBlockId,
}) {
  const newBlockData = {
    id: newChildId,
    type,
    nestedItemId: sourceComponent.id,
    innerHtml,
    state: {
      data,
    },
    position: newPosition,
  };

  yield put(
    actionCreator(CurrentPage.ReplaceSingleBlock, {
      block: newBlockData,
      isOpenCreateComponent,
      parentLibraryComponent: libraryComponentId,
    }),
  );

  if (!isOpenCreateComponent) {
    yield put(
      actionCreator(CurrentPage.ReplaceBlockS, {
        firstIndex: firstBlockIndex,
        block: newBlockData,
        pageEditorBlockId,
        parentLibraryComponent: libraryComponentId,
      }),
    );
  }

  if (!isOpenCreateComponent) {
    yield put(
      actionCreator(LibraryComponents.AddBlockToLibraryComponent, {
        parentLibraryComponent: libraryComponentId,
        block: newBlockData,
      }),
    );
  }
}

export function* parseUrlViaQuidziAPI(urlText) {
  const parsedUrl = yield call(requestParser, { url: urlText });
  const {
    data: { data },
  } = parsedUrl;
  return data;
}

export function* insertNewChild({
  isPlaylist,
  newChildId,
  newPosition,
  user,
  parsedLinkData,
  parentId,
  isOpenCreateComponent,
  firstBlockIndex,
}) {
  if (isPlaylist) {
    yield call(insertPlaylistUploadElement, {
      newBlockData: {
        id: newChildId,
        position: newPosition,
        type: PLAYLIST_ELEMENTS.IntegrationsUpload,
        url: parsedLinkData.url,
        title: parsedLinkData.title,
        duration: DEFAULT_DURATION,
        owner: {
          id: user.id,
          first_name: user.firstName,
          last_name: user.lastName,
        },
      },
      parentId,
      userId: user.id,
    });
  } else {
    yield call(insertPageUploadBlock, {
      newBlockData: {
        id: newChildId,
        position: newPosition,
        type: BlockTypes.imageUploadPlaceHolder,
        data: {
          url: parsedLinkData.url,
          title: parsedLinkData.title,
        },
      },
      isOpenCreateComponent,
      firstBlockIndex,
    });
  }
}

export const getLibraryComponentData = ({ componentType, sourceComponent }) => {
  const blockType = componentType === LibraryComponentTypes.dropbox_embed_component
    ? BlockTypes.dropboxEmbed
    : BlockTypes.googleEmbed;

  const innerHtml = componentType === LibraryComponentTypes.dropbox_embed_component
    ? innerHtmlDropboxEmbedTemplate({
      images: [sourceComponent.urlFile],
      title: sanitizeToLoad(sourceComponent.title),
      url: sourceComponent.linkUrl,
    })
    : innerHtmlGoogleEmbedTemplate({
      images: [sourceComponent.urlFile],
      title: sanitizeToLoad(sourceComponent.title),
      description: sanitizeToLoad(sourceComponent.description),
      url: sourceComponent.linkUrl,
      foreignLastModifiedDate: sourceComponent.foreignLastModifiedDate,
    });

  const data = componentType === LibraryComponentTypes.dropbox_embed_component
    ? {
      url: sourceComponent.linkUrl,
      title: sourceComponent.title,
      description: sanitizeToLoad(sourceComponent.description),
      images: [sourceComponent.urlFile],
      libraryComponentId: sourceComponent.id,
    }
    : {
      url: sourceComponent.linkUrl,
      title: sourceComponent.title,
      description: sanitizeToLoad(sourceComponent.description),
      images: [sourceComponent.urlFile],
      foreignFileId: sourceComponent.foreignFileId,
      libraryComponentId: sourceComponent.id,
      linkedAccountId: sourceComponent.linkedAccountId,
      foreignLastModifiedDate: sourceComponent.foreignLastModifiedDate,
    };

  return { blockType, innerHtml, data };
};

export function* updateNewChild({
  isPlaylist,
  newChildId,
  parentId,
  newPosition,
  sourceComponent,
  isOpenCreateComponent,
  firstBlockIndex,
  componentType,
}) {
  if (isPlaylist) {
    yield call(updatePlaylistUploadElement, {
      sourceComponent,
      newChildId,
      position: newPosition,
      playlistId: parentId,
    });
  } else {
    const { blockType, innerHtml, data } = getLibraryComponentData({
      componentType,
      sourceComponent,
    });

    yield call(updatePageUploadBlock, {
      sourceComponent,
      innerHtml,
      data,
      newChildId,
      position: newPosition,
      libraryComponentId: parentId,
      isOpenCreateComponent,
      type: blockType,
      newPosition,
      firstBlockIndex,
    });
  }
}

export function* createLibraryComponentUpload({
  history,
  newLibraryComponentId,
  selectorType,
  selector,
  localTags,
  componentType,
}) {
  const newUploadPosition = yield call(getNewUploadPosition);

  yield put(
    actionCreateUpload({
      newId: newLibraryComponentId,
      position: newUploadPosition,
      type: componentType,
      history,
      isFavorite: selectorType === 'favorites' || selector === 'favorites',
      tags: localTags,
    }),
  );
}

export function* finishLibraryComponentUpload({ newLibraryComponentId }) {
  yield put(
    actionUpdateUpload({
      status: 'READY',
      id: newLibraryComponentId,
      success: true,
    }),
  );
  yield put(actionDeleteUpload(newLibraryComponentId));
  // yield delay(1000);
  // yield put(actionUpdateUpload({ status: 'READY', id: newLibraryComponentId, success: false }));
}

export function* deleteOldUploadBlock({
  blockIdToDelete,
  parentIdToDeleteBlockFrom,
  isOpenCreateComponent,
  isPlaylist,
}) {
  if (isPlaylist) {
    const lastModifiedDate = Math.floor(Date.now() / 1000);

    yield put(
      actionCreator(CurrentPage.DeleteLinkPageSS, {
        playlistId: parentIdToDeleteBlockFrom,
        linkPageId: blockIdToDelete,
        lastModifiedDate,
      }),
    );

    const { data } = yield call(requestDeleteLinkPage(), {
      id: blockIdToDelete,
    });

    if (data?.errors) {
      yield showErrorMessage(data.errors);
    }
  } else {
    yield put(
      actionCreator(CurrentPage.RemoveBlock, {
        blockIdToDelete,
        parentIdToDeleteBlockFrom,
        isOpenCreateComponent,
      }),
    );

    yield requestDeleteComponent(placementEnum.queryDeleteComponent)({
      componentId: blockIdToDelete,
    });
  }
}

export function* createLinkTypeLibraryComponent({
  urlText,
  newLibraryComponentId,
  newLibraryComponentForceType,
  newLibraryComponentForeignFileId,
  newLibraryComponentLinkedAccountId,
  foreignLastModifiedDate,
  parsedLinkData,
  selectorType,
  selector,
  localTags,
}) {
  try {
    const response = yield call(requestCreateLinkComponent, {
      urlText,
      componentId: newLibraryComponentId,
      data: {
        ...parsedLinkData,
        title: sanitizeToSave(removeStringifiedSymbols(parsedLinkData.title)),
        description: sanitizeToSave(
          removeStringifiedSymbols(parsedLinkData.description),
        ),
        subtitle: sanitizeToSave(
          removeStringifiedSymbols(parsedLinkData.subtitle),
        ),
      },
      isFavorite: selectorType === 'favorites' || selector === 'favorites',
      tags: localTags,
      type: newLibraryComponentForceType,
      foreignFileId: newLibraryComponentForeignFileId,
      linkedAccountId: newLibraryComponentLinkedAccountId,
      foreignLastModifiedDate,
    });

    if (response.data.error) {
      yield put(
        actionUpdateUpload({
          status: 'ERROR',
          id: newLibraryComponentId,
          success: true,
          errorMsg: response.data.error,
        }),
      );
      return false;
    }
    const {
      data: { data: componentData },
    } = response;

    const payload = { ...componentData, tags: localTags };

    yield put(actionAddLibraryComponentReduxOnly(payload));
    yield put(actionCreator(ContentActionType.updateCounterS, {}));

    return componentData;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err, 'Not valid url format');
    return false;
  }
}
