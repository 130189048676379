import { getPeriodStartInSeconds, sortedFunctions } from './dateConvert';

export default (pagesArray) => {
  const startTodaySeconds = getPeriodStartInSeconds('day');
  const startYesterdaySeconds = getPeriodStartInSeconds('yesterday');
  const startWeekSeconds = getPeriodStartInSeconds('thisWeek');
  const startMonthSeconds = getPeriodStartInSeconds('thisMonth');
  const startYearSeconds = getPeriodStartInSeconds('thisYear');

  // const startCurrentWeekSeconds = getPeriodStartInSeconds('thisWeek');
  // const startLastWeekSeconds = getPeriodStartInSeconds('lastWeek');
  const latestPages = sortedFunctions.lastModified(pagesArray);
  const todayPages = [];
  const yesterdayPages = [];
  const thisWeekPages = [];
  const thisMonthPages = [];
  const thisYearPages = [];
  const previousYearsPages = [];
  latestPages.forEach((page) => {
    if (page.lastModifiedDate >= startTodaySeconds) {
      todayPages.push(page);
    } else if (
      page.lastModifiedDate < startTodaySeconds
      && page.lastModifiedDate >= startYesterdaySeconds
    ) {
      yesterdayPages.push(page);
    } else if (
      page.lastModifiedDate < startYesterdaySeconds
      && page.lastModifiedDate >= startWeekSeconds
    ) {
      thisWeekPages.push(page);
    } else if (
      page.lastModifiedDate < startWeekSeconds
      && page.lastModifiedDate >= startMonthSeconds
    ) {
      thisMonthPages.push(page);
    } else if (
      page.lastModifiedDate < startMonthSeconds
      && page.lastModifiedDate >= startYearSeconds
    ) {
      thisYearPages.push(page);
    } else {
      previousYearsPages.push(page);
    }
  });
  return {
    todayPages,
    yesterdayPages,
    thisWeekPages,
    thisMonthPages,
    thisYearPages,
    previousYearsPages,
  };
};
