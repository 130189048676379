import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { empty } from '../../utils/constants';
import { actionCreator } from '../../shared/redux/actionHelper';
import { ContentActionType } from '../../redux/content/contentTypes';
import { ViewLibraryComponent } from './childComponents/ViewLibComponent';

const ViewLibraryComponentDataLayer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const item = useSelector((state) => {
    if (!id) return {};
    if (state.content.contentData[id]) return state.content.contentData[id] || empty;
    return empty;
  });
  useEffect(() => {
    if (item === empty) {
      dispatch(
        actionCreator(ContentActionType.updateSpecificElementS, {
          updatePageDataId: id,
        }),
      );
    }
  }, [item]);
  return <ViewLibraryComponent item={item} isOwner />;
};

export default ViewLibraryComponentDataLayer;
