import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import rawStyles from './index.module.scss';
import { ReactComponent as Check24Svg } from '../../images/icons/check_green_thin.svg';
import { ReactComponent as Cross24Svg } from '../../images/icons/cross_20.svg';
import { ReactComponent as Alert24Svg } from '../../images/icons/alert_circle_20.svg';
import { actionCreator } from '../../shared/redux/actionHelper';
import { Settings } from '../../redux/settings/types';

const cn = classNames.bind(rawStyles);

export const Error = (props) => {
  return (
    <div
      className={cn('form_item', 'error')}
      onClick={() => props.onClickHideMessage('errors', props.id)}
    >
      {props.children}
      <Cross24Svg className={cn('form_icon')} />
    </div>
  );
};
export const Success = (props) => {
  return (
    <div
      className={cn('form_item', 'success')}
      onClick={() => props.onClickHideMessage('success', props.id)}
    >
      {props.children}
      <Check24Svg className={cn('form_icon')} />
    </div>
  );
};
const Notification = ({ component, ...props }) => {
  return (
    <div
      className={cn('form_item', 'notification')}
      onClick={() => props.onClickHideMessage('notifications', props.id)}
    >
      {component()}
      <Alert24Svg className={cn('form_icon')} />
    </div>
  );
};

const FormInformer = ({ formName }) => {
  const { forms } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  const onClickHideMessage = (type, id) => {
    dispatch(actionCreator(Settings.HideMessage, { type, id, formName }));
  };

  return (
    <div className={cn('form_informer')}>
      {!!forms[formName].errors.length
        && forms[formName].errors.map((item) => (
          <Error
            id={item.id}
            key={item.id}
            onClickHideMessage={onClickHideMessage}
          >
            {item.message}
          </Error>
        ))}
      {!!forms[formName].success.length
        && forms[formName].success.map((item) => (
          <Success
            id={item.id}
            key={item.id}
            onClickHideMessage={onClickHideMessage}
          >
            {item.message}
          </Success>
        ))}
      {!!forms[formName].notifications.length
        && forms[formName].notifications.map((item) => (
          <Notification
            id={item.id}
            key={item.id}
            onClickHideMessage={onClickHideMessage}
            component={item.component(item.data)}
          />
        ))}
    </div>
  );
};

export default FormInformer;
