import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileInfo from './ProfileInfo';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { Settings as SettingsAction } from '../../../redux/settings/types';
import { MainShell } from '../CommonComponents/Layout';

const Profile = () => {
  const dispatch = useDispatch();
  const { isLoaded } = useSelector((state) => state.settings);


  useEffect(() => {
    dispatch(actionCreator(SettingsAction.GetProfileData));
  }, []);

  if (!isLoaded) {
    return (
      <>
        <div className="loader-wrapper-page">
          <div className="loader">Loading...</div>
        </div>
      </>
    );
  }

  return (
    <MainShell>
      <ProfileInfo />
    </MainShell>
  );
};

export default Profile;
