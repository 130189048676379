import {
  createTableColumn,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  useTableColumnSizing_unstable,
  useTableFeatures,
} from '@fluentui/react-components';
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { useEffect } from 'react';
import './table.scss';
import styled from 'styled-components/macro';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';
import { calculateSingleUserAvatar } from '../../Maker/components/Info/helpers';
import { generateColumns, generateColumnSizing } from './Columns';
import NameAndAva from './NameAndAva';
import ActionsForTableC from './ActionsForTable';
import ChannelsCell from './ChannelsCell';
import { ContactTableRow } from './ContactTableRow';

const ImageWrapper = styled.div`
    flex: none;
    img {
      border-radius: 50%;
      width: 28px;
      height: 28px;
    }
`;
const TableWrapper = styled.div`
  padding-right: 39px;
  padding-left: 30px;
  position: relative;
  top: -4px;

  tbody:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }
`;

const EmailField = styled.a`
  text-decoration: none;
  color: #2F71F4;
`;

export const FluentTableViewForContacts = ({ ...props }) => {
  const [data, setData] = React.useState([]);

  const columnsDef = generateColumns(createTableColumn, props.setSort);
  const [columns] = React.useState(columnsDef);
  const [columnSizingOptions, setHer] = React.useState(generateColumnSizing());
  const { width } = useWindowDimensions();

  useEffect(() => {
    setHer(generateColumnSizing());
  }, [width]);

  const { getRows, columnSizing_unstable, tableRef } = useTableFeatures(
    {
      columns,
      items: data,
    },
    [useTableColumnSizing_unstable({ columnSizingOptions })],
  );

  const rows = getRows();

  useEffect(() => {
    if (props.data) {
      const keys = Object.keys(props.data);
      const dataArr = [];
      keys.forEach((key) => {
        const item = props.data[key];
        let channels;
        if (item.mutualChannels) {
          channels = Object.keys(item.mutualChannels).map(
            (key) => item?.mutualChannels[key],
          );
        } else if (
          item.mutualSubscriptions
          && item.mutualSubscriptions.length > 0
        ) {
          channels = item.mutualSubscriptions;
        } else {
          channels = [];
        }
        const objToBeInserted = {
          checkbox: {
            label: (
              <ImageWrapper>
                {calculateSingleUserAvatar(item, 28)}
              </ImageWrapper>
            ),
          },
          nameAndAva: {
            label: (
              <NameAndAva
                item={item}
                src={item.avatarUrlVerySmall}
                text={`${item.first_name || ''} ${item.last_name || ''}`}
              />
            ),
          },
          mail: { label: <EmailField href={`mailto:${item.email}`}>{item.email}</EmailField> },
          phone: { label: item.phone },
          function: { label: item.userFunction },
          organization: { label: item.organization },
          channels: { label: <ChannelsCell channels={channels} /> },
          actions1: { label: <ActionsForTableC item={item} /> },
        };
        dataArr.push(objToBeInserted);
      });
      setData(dataArr);
    }
  }, [props.data]);
  return (
    <TableWrapper
      className={`
    table_for_contacts
        ${props.noHeader ? 'disable_headers' : ''}
        ${!props.noHeader && props.isRecent ? 'make_before_great_again' : ''}
     `}
    >
      <Table sortable aria-label="Table with sort" ref={tableRef}>
        {/* {!props.disableHeader && ( */}
        <TableHeader>
          <TableRow>
            {columns.map((column) => (
              <TableHeaderCell
                key={column.columnId}
                {...columnSizing_unstable.getTableHeaderCellProps(
                  column.columnId,
                )}
              >
                {column.renderHeaderCell()}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        {/* )} */}
        <TableBody>
          {rows.map(({ item }) => (
            <ContactTableRow
              key={item.rowId ?? uuid()}
              item={item}
              columnSizing_unstable={columnSizing_unstable}
            />
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};
