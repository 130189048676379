export const Language = {
  eng: 'English',
  fra: 'Français',
  ned: 'Nederlands',
  esp: 'Español',
  por: 'Português',
  ita: 'Italiano',
  rus: 'Русский',
  pol: 'Polski',
  gre: 'Eλληνικά',
} as const;

export const Category = {
  des: 'Art & Design',
  books: 'Books',
  bus: 'Business',
  career: 'Career Path',
  communication: 'Communication',
  creativity: 'Creativity ',
  doIt: 'Do-it-yourself',
  education: 'Education',
  finance: 'Finance',
  health: 'Health',
  history: 'History',
  howToHelp: 'How To & Help',
  humanResources: 'Human Resources',
  languages: 'Languages',
  lifestyle: 'Lifestyle',
  leadership: 'Leadership',
  management: 'Management',
  mar: 'Marketing & Sales',
  mus: 'Music',
  money: 'Money & Investments',
  news: 'News',
  newsletters: 'Newsletters',
  dev: 'Personal Development',
  product: 'Product Review',
  programming: 'Programming',
  safety: 'Safety',
  sci: 'Science',
  skills: 'Skills',
  technologies: 'Technologies',
  video: 'Video',
  other: 'Other',
} as const;

export const Channel = {
  pub: 'Public',
  ch1: 'Channel1',
  ch2: 'Channel2',
  ch3: 'Channel3',
  ch4: 'Channel4',
} as const;
