import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import {
  TIME_FORMATS,
  TIME_REPRESENTATION,
} from '../../../../utils/SettingsHelpers/constants';
import { ReactComponent as ChevronDownSvg } from '../../../../images/icons/chevron_down_12.svg';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';
import useComponentVisible from '../../../../utils/hooks/useComponentVisible';
import { DATA_PARENTS } from '../../../../utils/constants';
import ChevronContainer from './chevronContainer';

const SelectorShell = styled.div`
  position: relative;
  ${({ isActive }) => (isActive ? 'z-index: 5;' : '')}
  width:95px;
  padding: 3px 3px 3px 10px;
  border-radius: 6px;
  border: 0.6px solid #ECE2E2;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(122, 105, 105, 0.10);
  display: flex;
  align-items: center;
  :hover {
    ${({ isDisable }) => (isDisable
    ? 'cursor: no-drop;'
    : 'background: #FFEEBF;    '
            + '  box-shadow: 0px 1px 4px 0px rgba(122, 105, 105, 0.10);'
            + '   border: 0.6px solid #FFC72C;  cursor: pointer;')}

    border-radius: 6px;

  }
`;

const Selector = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #596080;
  z-index: 4;
`;

const DropDown = styled.div`
  top: 39px;
  left: -10px;
  position: absolute;
  overflow: auto;
  width: 94px;
  height: 80px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
`;

const DropListItem = styled.div`
  height: 32px;
  line-height: 24px;
  padding: 5px 16px;
  &:hover {
    background: #FFEEBF;
  }
  ${({ isActive }) => (isActive ? 'background: #FFC72C;' : '')}
`;

const TimeFormatSelector = () => {
  const [menuRef, isDropMenuActive, setIsDropMenuActive] = useComponentVisible(
    false,
    DATA_PARENTS.TimeFormatSelector,
  );
  const parentRef = useRef(null);
  const dispatch = useDispatch();
  const {
    inputs: { timeFormat },
  } = useSelector((state) => state.settings);

  const pickNewTimeFormat = (event, newValue) => {
    dispatch(
      actionCreator(Settings.Preferences.ChangeTimeFormat, { newValue }),
    );
    setIsDropMenuActive(false);
    event.stopPropagation();
  };
  //
  // const openDropMenu = () => {
  //   // e.stopPropagation();
  //   setIsDropMenuActive(!isDropMenuActive);
  // };

  return (
    <SelectorShell isDisable>
      <Selector ref={parentRef}>
        {TIME_REPRESENTATION[timeFormat.value]}

        <ChevronContainer
          data-parent={DATA_PARENTS.TimeFormatSelector}
          isActive={isDropMenuActive}
          onMouseDown={(e) => e.preventDefault()}
          isLanguageTab
        >
          <ChevronDownSvg data-parent={DATA_PARENTS.TimeFormatSelector} />
        </ChevronContainer>

        {isDropMenuActive && (
          <DropDown ref={menuRef}>
            <PerfectScrollbar>
              {Object.values(TIME_FORMATS).map((time) => (
                <DropListItem
                  key={time}
                  isActive={time === timeFormat.value}
                  onClick={(event) => pickNewTimeFormat(event, time)}
                >
                  {TIME_REPRESENTATION[time]}
                </DropListItem>
              ))}
            </PerfectScrollbar>
          </DropDown>
        )}
      </Selector>
    </SelectorShell>
  );
};

export default TimeFormatSelector;
