import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actionSetNewTotalPlaylistDuration } from '../../redux/currentPage/action';


const useDurationChangerInMaker = (linkPages: any[], totalPlaylistDuration: number): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!linkPages || !linkPages.length) {
      return;
    }
    const newTotalDuration = linkPages.reduce((acc, value) => {
      if (!value.duration) {
        if (acc) {
          return acc;
        }
        return 0;
      }
      return acc + value.duration;
    }, 0);

    if (newTotalDuration === totalPlaylistDuration) {
      return;
    }
    dispatch(actionSetNewTotalPlaylistDuration(newTotalDuration));
  }, [linkPages, totalPlaylistDuration, dispatch]);
};

export default useDurationChangerInMaker;
