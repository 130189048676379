import SupportAction from './types';

export const actionShowMessage = (message) => ({
  type: SupportAction.AddMessage,
  payload: { message },
});
export const actionJumpTo = (message) => ({
  type: SupportAction.JumpTo,
  payload: { anchorId: message },
});
export const actionJumpToWithConfirmation = (message) => ({
  type: SupportAction.JumpToWithConfirmation,
  payload: { anchorId: message },
});

export const actionRemoveMessage = () => ({
  type: SupportAction.RemoveMessage,
  payload: { message: {} },
});

export const actionToggleSettingsSideBar = () => ({
  type: SupportAction.ToggleSettingsSideBar,
});

export const actionSettingsSideBarOff = () => ({
  type: SupportAction.SettingsSideBarOff,
});
export const actionSettingsSideBarOn = () => ({
  type: SupportAction.SettingsSideBarOn,
});
export const actionLoadingItem = (itemId) => ({
  type: SupportAction.LoadingItem,
  payload: itemId,
});

export const actionJumpToPageTitle = () => ({
  type: SupportAction.JumpToPageTitle,
});
export const actionToggleSideBar = (place) => ({
  type: SupportAction.ToggleSideBar,
  payload: place,
});

export const actionFindHashTags = (inputValue) => ({
  type: SupportAction.FindHashTags,
  payload: { inputValue },
});

export const actionUpdateHashTags = (tags) => ({
  type: SupportAction.UpdateHashTags,
  payload: tags,
});

export const actionSendForgotPasswordRequest = (email, history, cb, errorCb) => ({
  type: SupportAction.SendForgotPasswordRequest,
  payload: { email, history, cb, errorCb },
});

export const actionSendResetPasswordRequest = (password, history, params) => ({
  type: SupportAction.SendResetPasswordRequest,
  payload: { password, history, params },
});

export const actionToggleRequestSpinner = (value) => ({
  type: SupportAction.ToggleRequestSpinner,
  payload: value,
});

export const actionSetBlinkId = (ids) => ({
  type: SupportAction.SetBlinkItemId,
  payload: ids,
});
export const actionCleanBlinkId = () => ({
  type: SupportAction.CleanBlinkItemIds,
  payload: {},
});
export const actionToggleComponentDescription = (fieldState) => ({
  type: SupportAction.ToggleComponentDescription,
  payload: { fieldState },
});

export const actionSaveComponentDescriptionData = (id, folderId, itemType) => ({
  type: SupportAction.SaveComponentDescriptionData,
  payload: { id, folderId, itemType },
});

export const actionSaveRedirect = (link) => ({
  type: SupportAction.SaveRedirectLink,
  payload: link,
});
export const actionClearSupportBlocks = () => ({
  type: SupportAction.ClearSupportBlocks,
});

export const actionUpdateSupportBlocks = (payload) => ({
  type: SupportAction.UpdateSupportBlocks,
  payload,
});
export const actionUpdateSupportLayers = (payload) => ({
  type: SupportAction.UpdateSupportLayers,
  payload,
});

export const actionAddSupportEditableBlockRedux = (
  id,
  type,
  pageId,
  isClone,
  contentText,
  eraseContent,
) => {
  return {
    type: SupportAction.AddSupportEditableBlockRedux,
    payload: { id, type, pageId, isClone, contentText, eraseContent },
  };
};

export const actionDeleteSupportEditableBlockRedux = (id, type, pageId) => {
  return {
    type: SupportAction.DeleteSupportEditableBlockRedux,
    payload: { id, type, pageId },
  };
};

export const actionChangeSupportEditableBlockRedux = (
  id,
  newState,
  innerHtml,
) => {
  return {
    type: SupportAction.ChangeSupportEditableBlockRedux,
    payload: { id, newState, innerHtml },
  };
};

export const actionDeleteManyBlocksSupportRedux = (ids) => {
  return {
    type: SupportAction.DeleteManyBlocksSupportRedux,
    payload: ids,
  };
};

export const actionUpdateSupportBlock = (
  id,
  state,
  width,
  innerHtml,
  playlistField,
  imageUrl,
  type,
  libraryComponentToAttachId,
  content,
) => ({
  type: SupportAction.UpdateSupportBlock,
  payload: {
    id,
    state,
    width,
    innerHtml,
    playlistField,
    imageUrl,
    type,
    libraryComponentToAttachId,
    content,
  },
});

export const actionUpdateSupportDraggableBlocks = (isDraggable) => ({
  type: SupportAction.UpdateSupportDraggableBlocks,
  payload: isDraggable,
});

export const actionDragManySupportBlockRedux = (dragHandlerId) => {
  return {
    type: SupportAction.DragManySupportBlocksRedux,
    payload: { dragHandlerId },
  };
};

export const actionCreateSupportEditableBlockWithState = (
  id,
  newState,
  pageId,
  isWidget,
  playlistField,
) => ({
  type: SupportAction.CreateSupportEditableBlockWithState,
  payload: { id, newState, pageId, isWidget, playlistField },
});

export const actionChangeSupportStateButtonRedux = (buttonState) => {
  return {
    type: SupportAction.ChangeSupportStateComponentButton,
    payload: { buttonState },
  };
};

export const actionFillComponentSupportBlocks = (componentId) => {
  return {
    type: SupportAction.FillComponentSupportBlocks,
    payload: { componentId },
  };
};

export const actionWarningDelete = (itemToDelete, option) => {
  return {
    type: SupportAction.WarningDelete,
    payload: { itemToDelete, option },
  };
};
