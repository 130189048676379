import React from 'react';
import { ReactComponent as PlusSvg } from '../../../images/icons/close.svg';
import styles from './index.module.scss';

const Close = ({ onClick }) => (
  <>
    <div className={styles.close_button} onClick={onClick}>
      <PlusSvg />
    </div>
  </>
);

export default Close;
