import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import styles from './HeadingMenu.module.scss';
import stylesPlaylistBuilder from '../../pages/Maker/MakerCommon/index.module.scss';
import { actionPageWillDownload } from '../../redux/currentPage/action';
import { ReactComponent as FolderSvg } from '../../images/icons/folder_20.svg';
import { ReactComponent as TrashSvg } from '../../images/icons/trash_20.svg';
import { ReactComponent as PageSvg } from '../../images/icons/replace_icons/Page.svg';
import { ReactComponent as FavoriteSvg } from '../../images/icons/favorites16.svg';
import { ReactComponent as RecentSvg } from '../../images/icons/recent.svg';
import { ReactComponent as PublishSvg } from '../../images/icons/playlist-published.svg';
import { ReactComponent as DraftSvg } from '../../images/icons/playlist-draft.svg';
import { ReactComponent as PlaylistSvg } from '../../images/icons/replace_icons/Playlist.svg';
import { DEFAULT_PAGE_PATH, DEFAULT_TITLE } from '../../utils/constants';
import ReadableTimeLeft from '../ReadableTimeLeft/ReadableTimeLeft';
import Logo from '../MainHeader/Logo';
import rawStyles from '../../pages/Playlist/Header/HeaderPlaylist.module.scss';
import UnifiedChevronNavigation from '../UnifiedNavigation/UnifiedChevronNavigation';

const cx = classNames.bind(styles);
const rbStyles = classNames.bind(stylesPlaylistBuilder);
const cn = classNames.bind(rawStyles);
const Navigation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const currentPage = useSelector((state) => state.currentPage);
  const pages = useSelector((state) => state.pages);
  const { selectorType, folderId } = useSelector((state) => state.user);
  const customHistory = useSelector((state) => state.historyTrace);
  const departureUrl = customHistory.somePayload?.departureUrl || '';
  const isReadPage = history.location.pathname.includes('/read_pages');
  const isPage = history.location.pathname.includes('/libraryPage');
  const isPlaylistViewMode = history.location.pathname.includes('/view_playlist');
  const isPageEdit = history.location.pathname.includes('/edit_page');
  const isLibraryComponentPreview = history.location.pathname.includes('/libraryComponent')
    || history.location.pathname.includes('/sharedPlaylistItem');
  const isPlaylist = history.location.pathname.includes('/preview_playlist')
    || history.location.pathname.includes('/playlist/')
    || history.location.pathname.includes('/maker/');
  const isPlaylistPreview = history.location.pathname.includes('/preview_playlist/')
    && selectorType === 'all';
  const isPreviewPage = history.location.pathname.includes('/preview_pages')
    && selectorType === 'all';
  const isBrowsePublishedPlaylists = history.location.pathname === '/browse_playlists' && selectorType === 'all';
  const isBrowsePlaylist = history.location.pathname.includes('/browse_playlists')
    && !isBrowsePublishedPlaylists;
  const isParentPlaylist = false;

  const isFolder = !isParentPlaylist
    && !isReadPage
    && !isBrowsePlaylist
    && selectorType === 'folder';
  const isFavorites = !isParentPlaylist
    && !isReadPage
    && !isBrowsePlaylist
    && selectorType === 'favorites';
  const isRecent = !isParentPlaylist
    && !isReadPage
    && !isBrowsePlaylist
    && !isParentPlaylist
    && selectorType === 'recent';
  const isPublished = !isParentPlaylist
    && !isReadPage
    && !isBrowsePlaylist
    && !isParentPlaylist
    && selectorType === 'published';
  const isDrafts = !isParentPlaylist
    && !isReadPage
    && !isBrowsePlaylist
    && !isParentPlaylist
    && !isRecent
    && selectorType === 'drafts';
  const isDefaultTitle = !isPlaylist
    && !isFolder
    && !isParentPlaylist
    && !isBrowsePlaylist
    && !isFavorites
    && !isRecent
    && !isPublished
    && !isDrafts;

  const folderTitle = isFolder && folderId && (pages[folderId]?.title || t('unnamedFolderT'));
  const isHasParent = history.location.pathname !== '/browse_playlists';
  const isPlaylistBuilder = history.location.pathname.includes('/playlist');

  const playlistTitle = (currentPage && currentPage?.title)
    || (isReadPage && currentPage.parentTitle)
    || (isBrowsePlaylist && currentPage.title)
    || t('unnamedSmartFileT');
  const defaultTitle = isPlaylist ? t('unnamedSmartFileT') : t('defaultSmartPageTitleT');
  const isLargeRightBar = useSelector(
    (state) => state.currentPage.isLargeRightBar,
  );

  if (isLibraryComponentPreview) return <></>;

  if (isPageEdit || isPage) {
    return (
      <div
        className={cn('container', { zIndex_top: false, forEditPage: true })}
      >
        <div className={cn('left_navigation')}>
          <Logo />
          <UnifiedChevronNavigation />
        </div>
      </div>
    );
  }

  if (isPlaylistViewMode && !currentPage.isDownload) {
    return (
      <>
        <div className={cn('left_navigation', { isViewLegacy: true })}>
          <Logo />
          <UnifiedChevronNavigation />
        </div>
        <div className={cx('view_playlist_title')}>
          {!currentPage?.inputs?.availableTo && (
            <PlaylistSvg className={cx('icon')} />
          )}
          <div
            className={rbStyles('text_wrapper', {
              dates: !!currentPage?.inputs?.availableTo,
            })}
          >
            {currentPage?.inputs?.availableTo ? (
              <ReadableTimeLeft
                availableTo={currentPage?.inputs?.availableTo}
                location={departureUrl || '/channels'}
              />
            ) : (
              playlistTitle
            )}
          </div>
        </div>
      </>
    );
  }

  if (currentPage.isDownload && !isBrowsePublishedPlaylists) return <div />;

  if (currentPage.movedToTrash) {
    return (
      <div className={cx('title', { title_small: isLargeRightBar })}>
        <div
          onClick={() => history.push(DEFAULT_PAGE_PATH)}
          className={cx('breadcrumbs', 'breadcrumbs_parent')}
        >
          <TrashSvg className={cx('icon')} />
          <div className={cx('text')}>{t('trashT')}</div>
        </div>
        <div className={cx('separator')}> /</div>
        <div className={cx('breadcrumbs')}>
          <div className={rbStyles('text_wrapper')}>
            {currentPage.title || defaultTitle}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cx('title', { title_small: isLargeRightBar })}>
      {isPlaylistBuilder && (
        <>
          <div
            onClick={() => history.push('/playlists')}
            className={cx('breadcrumbs', 'breadcrumbs_parent')}
          >
            <div className={rbStyles('text')}>{t('allSmartFilesT')}</div>
          </div>
          <div className={cx('separator')}> /</div>
          <div className={cx('breadcrumbs')}>
            <div className={cx('text')}>
              {isPlaylist && (
                <PlaylistSvg className={cx('icon', 'icon_title')} />
              )}
              <div className={rbStyles('text_wrapper')}>
                {currentPage.title || defaultTitle}
              </div>
            </div>
          </div>
        </>
      )}

      {(isBrowsePlaylist || isPlaylistPreview) && (
        <>
          <div
            onClick={() => history.push(
              isPlaylistPreview ? '/playlists' : '/browse_playlists',
            )
            }
            className={cx('breadcrumbs', 'breadcrumbs_parent')}
          >
            <div className={rbStyles('text')}>
              {isPlaylistPreview ? 'All SmartFiles' : 'Browse SmartFiles'}
            </div>
          </div>

          <div className={cx('separator')}> /</div>

          <div className={cx('breadcrumbs', 'breadcrumbs_parent')}>
            <PlaylistSvg className={cx('icon')} />
            <div className={rbStyles('text_wrapper')}>{playlistTitle}</div>
          </div>
        </>
      )}

      {(isReadPage || isPreviewPage) && (
        <>
          <div
            className={cx('breadcrumbs', 'breadcrumbs_parent')}
            onClick={() => {
              // dispatch(actionPageWillDownload());
              history.push(
                isPreviewPage
                  ? `/maker/${currentPage.playlistId}/edit`
                  : '/browse_playlists',
              );
            }}
          >
            <div className={rbStyles('text')}>{t('allSmartFilesT')}</div>
          </div>

          <div className={cx('separator')}> /</div>

          <div
            onClick={() => {
              // dispatch(actionPageWillDownload());
              history.push(
                `/${isPreviewPage ? 'playlist' : 'browse_playlists'}/${
                  currentPage.playlistId
                }`,
              );
            }}
            className={cx('breadcrumbs', 'breadcrumbs_parent')}
          >
            <PlaylistSvg className={cx('icon')} />
            <div className={rbStyles('text_wrapper')}>{playlistTitle}</div>
          </div>

          <div className={cx('separator')}> /</div>

          <div className={cx('breadcrumbs')}>
            <PageSvg className={cx('icon')} />
            <div className={rbStyles('text_wrapper')}>
              {currentPage.title || defaultTitle}
            </div>
          </div>
        </>
      )}

      {isBrowsePublishedPlaylists && (
        <>
          <div className={cx('breadcrumbs', 'breadcrumbs_parent')}>
            <PlaylistSvg className={cx('icon')} />
            <div className={rbStyles('text')}>{t('browseSmartFilesT')}</div>
          </div>
        </>
      )}

      {!isPlaylist && isHasParent && (
        <>
          {isDefaultTitle
            && !isReadPage
            && !isPlaylistPreview
            && !isPreviewPage && (
            <>
              <div
                onClick={() => history.push(
                  isPlaylist ? DEFAULT_PAGE_PATH : 'content/pages/all',
                )
                  }
                className={cx('breadcrumbs', 'breadcrumbs_parent')}
              >
                <div className={cx('text')}>
                  {isPlaylist ? 'All SmartFiles' : 'All pages'}
                </div>
              </div>
              <div className={cx('separator_parent')}> /</div>
            </>
          )}

          {isFolder && !isReadPage && (
            <>
              <div
                onClick={() => history.push('content/pages/all')}
                className={cx('breadcrumbs', 'breadcrumbs_parent')}
              >
                <FolderSvg className={cx('icon')} />
                <div className={cx('text_wrapper')}>{folderTitle}</div>
              </div>
              <div className={cx('separator_parent')}> /</div>
            </>
          )}

          {isRecent && (
            <>
              <div
                onClick={() => history.push(
                  isPlaylist ? DEFAULT_PAGE_PATH : 'content/pages/all',
                )
                }
                className={cx('breadcrumbs', 'breadcrumbs_parent')}
              >
                <RecentSvg className={cx('icon')} />
                {t('recentT')}
              </div>
              <div className={cx('separator_parent')}> /</div>
            </>
          )}

          {isFavorites && (
            <>
              <div
                onClick={() => history.push(
                  isPlaylist ? DEFAULT_PAGE_PATH : 'content/pages/all',
                )
                }
                className={cx('breadcrumbs', 'breadcrumbs_parent')}
              >
                <FavoriteSvg className={cx('icon', 'favorite_icon')} />
                {t('favoritesT')}
              </div>
              <div className={cx('separator_parent')}> /</div>
            </>
          )}

          {isPublished && (
            <>
              <div
                onClick={() => history.push(
                  isPlaylist ? DEFAULT_PAGE_PATH : 'content/pages/all',
                )
                }
                className={cx('breadcrumbs', 'breadcrumbs_parent')}
              >
                <PublishSvg className={cx('icon')} />
                {t('publishedT')}
              </div>
              <div className={cx('separator_parent')}> /</div>
            </>
          )}

          {isDrafts && !isPlaylistPreview && (
            <>
              <div
                onClick={() => history.push(
                  isPlaylist ? DEFAULT_PAGE_PATH : 'content/pages/all',
                )
                }
                className={cx('breadcrumbs', 'breadcrumbs_parent')}
              >
                <DraftSvg className={cx('icon')} />
                {t('draftsT')}
              </div>
              <div className={cx('separator_parent')}> /</div>
            </>
          )}

          {!isPlaylist
            && isParentPlaylist
            && !isReadPage
            && !isPlaylistPreview
            && !isPreviewPage && (
              <div
                onClick={() => {
                  dispatch(actionPageWillDownload());

                  history.push(`/maker/${currentPage.playlistId}/edit`);
                }}
                className={cx('breadcrumbs', 'breadcrumbs_parent')}
              >
                <PlaylistSvg className={cx('icon')} />
                <div className={cx('text_wrapper')}>{playlistTitle}</div>
              </div>
          )}
        </>
      )}

      {!isPlaylist
        && !isBrowsePublishedPlaylists
        && !isBrowsePlaylist
        && !isReadPage
        && !isPlaylistPreview
        && !isPreviewPage && (
          <div className={cx('breadcrumbs')}>
            <div className={cx('text')}>
              {isPlaylist && (
                <PlaylistSvg className={cx('icon', 'icon_title')} />
              )}
              <div className={rbStyles('text_wrapper')}>
                {currentPage.title || defaultTitle}
              </div>
            </div>
          </div>
      )}
    </div>
  );
};

export default Navigation;
