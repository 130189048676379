import React, { useEffect } from 'react';
import { ReactComponent as ArrowGallerySVG } from '../../images/2023/svg/preview/arrow-gallery.svg';
import mimeTypes from '../../utils/mimeTypes';
import ImagePreview from './preview-components/Image';
import VideoPreview from './preview-components/Video';
import CannotDisplayPreview from './preview-components/CannotDisplayPreview';
import TextPreview from './preview-components/Text';
import { stopPropagation, UiComponentTypes } from '../../utils/constants';
import PDFPreview from './preview-components/PDF';
import MSdocPreview from './preview-components/MSdoc';
import BlockCreatorComponent from '../LibraryMiniPage/BlockCreatorComponent';
import LinkPreview from './preview-components/Link';
import {
  ModalClickoutsideHandler,
  PreviewComponentWrapper,
  PreviewContentWrapper,
  PreviewLeftArrowWrapper,
  PreviewMainPreviewContentWrapper,
  PreviewRightArrowWrapper,
} from './styled';
import LexicalPreview from './preview-components/TextLexical';
import { smartFileItemTypeCheck } from '../../shared/smartFile/constant';

const PreviewContentSelector = React.memo(function PreviewContentSelector({ children, item, isMaker, ...props }) {
  const lc = item?.libraryComponent || item;
  const isImage = mimeTypes.image[lc?.type];
  const isLikeImage = mimeTypes.imageLike[lc?.type];
  const isVideo = mimeTypes.video[lc?.type];
  const isAudio = mimeTypes.audio[lc?.type];
  const isPDF = UiComponentTypes.pdf[lc?.type];
  const isLink = lc.type === 'text/html' || lc.type === 'embed_component';
  const isLexicalText = item?.textComponent?.id && smartFileItemTypeCheck.isLexicalText[item?.type];
  const isText = item?.textComponent?.id && !isLexicalText;
  const isPage = lc?.type === 'page' || lc?.type === 'sharedPage';
  const isDoc = !!(
    (UiComponentTypes.ms[lc.type]
      || UiComponentTypes.pp[lc.type]
      || UiComponentTypes.xls[lc.type])
    && !lc.pdfPreviewUrl
  );
  const isDocPDFLike = !!(!isPDF && lc.pdfPreviewUrl);
  const isJustAFile = !isVideo
    && !isImage
    && !isAudio
    && !isText
    && !isLexicalText
    && !isPage
    && !isLikeImage
    && !isPDF
    && !isDoc
    && !isDocPDFLike
    && !isLink;
  const isComponent = lc.type === 'component';

  useEffect(() => {
    let timeoutId;
    if (props.setGalleryOpen) {
      timeoutId = setTimeout(() => props.setGalleryOpen(true), (isVideo || isAudio) ? 1010 : 10);
    }
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, []);

  return (
    <PreviewMainPreviewContentWrapper>
      <PreviewLeftArrowWrapper
        className="fade_menu"
        onClick={props.goPrev}
        isDisabled={!props.canGoPrev}
      >
        <ArrowGallerySVG />
      </PreviewLeftArrowWrapper>
      <PreviewRightArrowWrapper
        className="fade_menu"
        onClick={props.goNext}
        isDisabled={!props.canGoNext}
      >
        <ArrowGallerySVG />
      </PreviewRightArrowWrapper>
      <PreviewContentWrapper onClick={stopPropagation} isVideo={isVideo} isPDF={isPDF} isImage={isImage}>
        {item && (
        <div className="expanded_content">
          <ModalClickoutsideHandler onClick={props.close} />
          {isComponent && (
          <PreviewComponentWrapper>
            {' '}
            <BlockCreatorComponent isPreview item={lc} />
          </PreviewComponentWrapper>
          )}
          {isImage && (
          <ImagePreview
            src={lc.urlFile}
            isGalleryOpen={props.isGalleryOpen}
            setGalleryOpen={props.setGalleryOpen}
          />
          )}
          {isLikeImage && (
          <ImagePreview
            src={`${lc.urlSmallImage}`}
            isGalleryOpen={props.isGalleryOpen}
            setGalleryOpen={props.setGalleryOpen}
          />
          )}
          {(isVideo || isAudio)
              && (
                <VideoPreview
                  item={lc}
                  isGalleryOpen={props.isGalleryOpen}
                  setGalleryOpen={props.setGalleryOpen}
                  isAudio={isAudio}
                />
              )}
          {isLink
              && (
                <LinkPreview
                  item={lc}
                  isGalleryOpen={props.isGalleryOpen}
                  setGalleryOpen={props.setGalleryOpen}
                />
              )}
          {isJustAFile && <CannotDisplayPreview item={lc} />}
          {isText
              && (
              <TextPreview
                item={item}
                isGalleryOpen={props.isGalleryOpen}
                setGalleryOpen={props.setGalleryOpen}
              />
              )}
          {isLexicalText
              && (
              <LexicalPreview
                item={item}
                isGalleryOpen={props.isGalleryOpen}
                setGalleryOpen={props.setGalleryOpen}
              />
              )}
          {isPage && (
          <PreviewComponentWrapper
            isPage
          >
            {' '}
            <BlockCreatorComponent
              isMaker={isMaker}
              isPreview
              isWhiteGallery
              item={lc}
            />
          </PreviewComponentWrapper>
          )}
          {/* PreviewContentSelector */}
          {(isPDF || isDocPDFLike)
              && (
                <PDFPreview
                  zoomWrapRef={props.zoomWrapRef}
                  zoomRef={props.zoomRef}
                  item={lc}
                  updateAfterLoadPdf={props.updateAfterLoadPdf}
                  isGalleryOpen={props.isGalleryOpen}
                  setGalleryOpen={props.setGalleryOpen}
                  isWhiteGallery
                />
              )}
          {isDoc
              && (
              <MSdocPreview
                item={lc}
                isGalleryOpen={props.isGalleryOpen}
                setGalleryOpen={props.setGalleryOpen}
              />
              )}
        </div>
        )}
      </PreviewContentWrapper>
    </PreviewMainPreviewContentWrapper>
  );
});

export default PreviewContentSelector;
