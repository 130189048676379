import React from 'react';
import styled from 'styled-components/macro';

const UserShell = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarImage = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 6px;
  border-radius: 50%;

`;

const UserName = styled.div`
  line-height: 16px;
  color: #484343;
  margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 187px;
`;

const SharedPlaylistUser = ({ AvatarComponent, name, ...props }) => {
  return (
    <UserShell data-parent={props.dataParent}>
      <AvatarImage>
        <AvatarComponent />
      </AvatarImage>

      <UserName>{name}</UserName>
      {props.children}
    </UserShell>
  );
};

export default SharedPlaylistUser;
