import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ParagraphSvg } from '../../images/icons/paragraph.svg';
import styles from './index.module.css';

import { ReactComponent as Title2Svg } from '../../images/icons/title_2.svg';
import { ReactComponent as Title3Svg } from '../../images/icons/title_3.svg';
import { ReactComponent as CalloutSvg } from '../../images/icons/icon_20/callout.svg';
import { ReactComponent as ImageSvg } from '../../images/icons/icon_20/image.svg';
import { ReactComponent as VideoSvg } from '../../images/icons/icon_20/play.svg';
import { ReactComponent as Title1Svg } from '../../images/icons/icon_20/title_1.svg';
import { ReactComponent as NumListSvg } from '../../images/icons/icon_20/list-number.svg';
import { ReactComponent as BullListSvg } from '../../images/icons/icon_20/list-bullet.svg';
import { ReactComponent as TableSvg } from '../../images/icons/icon_20/table.svg';
import { ReactComponent as LineSvg } from '../../images/icons/icon_20/line.svg';
import { ReactComponent as LinkPageSvg } from '../../images/icons/icon_20/link-ext.svg';
import { BlockTypes, emptyCallback } from '../../utils/constants';

const CreateBlockOption = ({
  selectAddEditableBlock,
  setIsComponentVisible,
  parentRef,
  setIsAddPage,
}) => {
  // tdisables "link to page option"
  const { t } = useTranslation();
  const isDisabled = true;
  const calculatePosition = () => {
    if (parentRef.current) {
      const height = document.documentElement.clientHeight;
      const positionY = parentRef.current.getBoundingClientRect().y;
      const heightElement = 176;
      const isOpenInUp = height > heightElement + positionY;
      if (isOpenInUp) return 24;
      return -180;
    }
    return 0;
  };
  const divStyle = {
    top: calculatePosition(),
  };

  const onCreateClickHandler = (newType) => {
    setIsComponentVisible(false);
    if (BlockTypes.page === newType) setIsAddPage(true);
    else if (newType) selectAddEditableBlock(newType, undefined, undefined, true);
  };

  return (
    <div style={divStyle} className={styles.dropdown_create}>
      <div className={styles.text}> {t('basicUpT')} </div>

      <div className={styles.dropdown_order}>
        <div className={styles.stolb}>
          <div
            className={styles.dropdown_create__item}
            onClick={() => onCreateClickHandler('')}
          >
            <ParagraphSvg />
            <div>{t('paragraphT')}</div>
          </div>

          <div
            className={styles.dropdown_create__item}
            onClick={() => onCreateClickHandler('title')}
          >
            <Title1Svg />
            <div>{t('title1T')}</div>
          </div>

          <div
            className={styles.dropdown_create__item}
            onClick={() => onCreateClickHandler('title2')}
          >
            <Title2Svg />
            <div>{t('title2T')}</div>
          </div>

          <div
            className={styles.dropdown_create__item}
            onClick={() => onCreateClickHandler('title3')}
          >
            <Title3Svg />
            <div>{t('title3T')}</div>
          </div>
        </div>

        <div className={styles.stolb}>
          <div
            className={styles.dropdown_create__item}
            onClick={() => onCreateClickHandler(BlockTypes.ordered_list_item0)}
          >
            <NumListSvg />
            <div>{t('numberedListT')}</div>
          </div>

          <div
            className={styles.dropdown_create__item}
            onClick={() => onCreateClickHandler(BlockTypes.unordered_list_item0)
            }
          >
            <BullListSvg />
            <div>{t('bulletedListT')}</div>
          </div>

          <div
            className={styles.dropdown_create__item}
            onClick={() => onCreateClickHandler(BlockTypes.callout)}
          >
            <CalloutSvg />
            <div>{t('calloutT')}</div>
          </div>

          <div
            className={`${styles.dropdown_create__item}`}
            onClick={() => onCreateClickHandler(BlockTypes.lineSeparator)}
          >
            <LineSvg />
            <div>{t('lineT')}</div>
          </div>
        </div>
        <div className={styles.stolb}>
          <div
            className={`${styles.dropdown_create__item_blocked} ${styles.dropdown_create__item}`}
          >
            <TableSvg />
            <div>{t('tableT')}</div>
          </div>

          <div
            className={styles.dropdown_create__item}
            onClick={() => onCreateClickHandler(BlockTypes.image)}
          >
            <ImageSvg />
            <div>{t('imageT')}</div>
          </div>

          <div
            className={styles.dropdown_create__item}
            onClick={() => onCreateClickHandler(BlockTypes.video)}
          >
            <VideoSvg />
            <div>{t('videoT')}</div>
          </div>

          <div
            className={`${styles.dropdown_create__item} ${
              isDisabled ? styles.icon_wrapper_disabled : ''
            }`}
            onClick={
              isDisabled
                ? emptyCallback
                : () => onCreateClickHandler(BlockTypes.page)
            }
          >
            <LinkPageSvg />
            <div>{t('linkTopageT')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBlockOption;
