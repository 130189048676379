import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../../components/MainHeader/Logo';
import UnifiedChevronNavigation from '../../components/UnifiedNavigation/UnifiedChevronNavigation';
import {
  ActiveContent,
  AvatarPlaceHolder,
  Controls,
  LeftNav,
  RoleDISPLAYCoedit,
  RoleDISPLAYOwner,
  RoleDISPLAYView,
  SmallVerticalSeparatorInCuteHeader,
} from './sharedStyled';
import { isRoleInPlaylist } from '../../utils/permissions';
import User from '../../components/MainHeader/User';
import UserMenu from '../../components/UserMenu';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { DATA_PARENTS, emptyArr, MessageType, openModalType } from '../../utils/constants';
import { actionPageWillDownload } from '../../redux/currentPage/action';
import SwitchModeAndPublishBlock from './SwitchModeAndPublishBlock';
import ShareButtons from './components/Info/ShareButtons';
import { actionOpenModal } from '../../redux/user/action';
import {
  actionShowMessage,
} from '../../redux/support/action';
import ImageAndName from './ImageAndName';
import {
  AllContentWrapper,
  AvaModuleWrapper,
  Divider,
  DownloadButton,
  LeftPlaceHoldersWrapper,
  MakerCutHeaderWrapper,
} from './styled';
import { actionAddPayloadUnifyHistory } from '../../redux/history/actions';
import { actionUnPublishSPlaylistS } from '../../redux/playlists/action';

const MakerCutHeader = ({
  smExpand,
  lExpand,
  moveLeft,
  setViewMode,
  viewMode,
  cutMode,
  isForSharedToWeb,
  isFinished,
  wrapperId,
  isLargeRightBar,
  isShowLibraryWidget,
  linkPages,
  handleDownloadAll,
  isCanManyDownload,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [buttonText, setButtonText] = useState('');

  const { isCoEdit, isOwner, isViewer } = isRoleInPlaylist;
  const { requestSpinner } = useSelector((state) => state.support);
  const {
    currentRole,
    id,
    title,
    usedInChannels,
    isPublish,
    shareState: { isShareToWeb, usersToSharing = emptyArr },
    img,
    cropUrl,
    singleUserShareState,
    defaultPreviewColor,
  } = useSelector((state) => state.currentPage);
  const { type, id: sharedToWebPlaylistID } = useParams();
  const [refUserMenu, isComponentVisible, setIsComponentVisible] = useComponentVisible(false, DATA_PARENTS.UserMenu);
  const isButtonsBlocked = !!requestSpinner && requestSpinner !== 'init' && requestSpinner === id;
  const isHasShare = !!Object.values(singleUserShareState).length || !!usersToSharing?.length || isShareToWeb || isPublish;
  const toggleShowUserMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const enableShare = (e) => {
    e.stopPropagation();
    dispatch(
      actionOpenModal(openModalType.Share, { itemType: 'playlist', isMakerStyles: true }),
    );
  };

  const goToPlayer = () => {
    dispatch(actionPageWillDownload());
    if (isForSharedToWeb) {
      dispatch(
        actionAddPayloadUnifyHistory({ sharedToWebID: sharedToWebPlaylistID }),
      );
      history.push(`/shared_player/shared/${sharedToWebPlaylistID}/1`);
      return;
    }
    const attributes = {};
    const defaultChannel = Object.values(usedInChannels)[0]?.id || 'preview';
    const firstChannelId = type === 'publish' ? defaultChannel : 'preview';
    attributes.from = history.location.pathname;
    // attributes.publishPlaylistId = id;
    attributes.channelId = firstChannelId;
    history.push(`/player/${firstChannelId}/${id}/1`);
  };

  const unshare = useCallback(() => {
    dispatch(
      actionOpenModal(openModalType.ConfirmModalUniversal, {
        title: t('unshareUpT'),
        subject: t('unshareThisSmartFileQuestionT'),
        description: t('unshareDescriptionT'),
        confirm: () => {
          dispatch(actionUnPublishSPlaylistS(id, wrapperId));
          dispatch(
            actionShowMessage({
              type: MessageType.Regular,
              text: t('smartFileUnsharedT'),
            }),
          );
        },
        cancelText: t('cancelUpT'),
        okText: t('unshareUpT'),
        type: 'unshare',
      }),
    );
  }, [dispatch, id, wrapperId]);

  useEffect(() => {
    let text = isHasShare ? t('unshareUpT') : t('shareUpT');
    if (
      !!requestSpinner
      && requestSpinner !== 'init'
      && requestSpinner === id
    ) {
      text = t('sharingUpT');
    }
    setButtonText(text);
  }, [requestSpinner, isHasShare]);

  return (
    <MakerCutHeaderWrapper
      isLargeRightBar={isLargeRightBar}
      smExpand={smExpand}
      lExpand={lExpand}
      moveLeft={moveLeft}
      expandVertical={cutMode}
    >
      <LeftPlaceHoldersWrapper moveLeft={moveLeft} smExpand={smExpand} />
      <AllContentWrapper>
        <LeftNav>
          <Logo isMaker isForSharedToWeb={isForSharedToWeb} />
          <UnifiedChevronNavigation isForSharedToWeb={isForSharedToWeb} />
        </LeftNav>
        {process.env.REACT_APP_ENVIRONMENT === 'development' && (
          <>
            {isCoEdit[currentRole] && (
              <RoleDISPLAYCoedit>{t('coeditLowT')}</RoleDISPLAYCoedit>
            )}
            {isOwner[currentRole] && <RoleDISPLAYOwner>{t('ownerLowT')}</RoleDISPLAYOwner>}
            {isViewer[currentRole] && <RoleDISPLAYView>{t('viewerLowT')}</RoleDISPLAYView>}
          </>
        )}


        <ActiveContent>
          {!isLargeRightBar
          && (
          <ImageAndName
            itemId={id}
            small={lExpand || smExpand}
            src={cropUrl || img?.src}
            title={title}
            isWithProgressBar={!isOwner[currentRole] && !isForSharedToWeb}
            color={defaultPreviewColor}
            moveLeft={moveLeft}
            isShowLibraryWidget={isShowLibraryWidget}
          />
          )
        }
          <Controls>
            {cutMode && (
            <SwitchModeAndPublishBlock
              isLargeRightBar={isLargeRightBar}
              isOwner={isOwner[currentRole]}
              isButtonsBlocked={isButtonsBlocked}
              buttonText={buttonText}
              isHasShare={isHasShare}
              viewMode={viewMode}
              setViewMode={setViewMode}
              isFinished={isFinished}
              wrapperId={wrapperId}
              goToPlayer={goToPlayer}
              enableShare={enableShare}
              unshare={unshare}
              moveLeft={moveLeft}
              isShowLibraryWidget={isShowLibraryWidget}
              forCutHeader
            />
            )}
            {cutMode && isOwner[currentRole] && (<SmallVerticalSeparatorInCuteHeader />)}
            {cutMode && !isOwner[currentRole] && (
              <DownloadButton
                onClick={handleDownloadAll}
                isDisabled={!linkPages?.length || !isCanManyDownload}
                isCutHeader
              >
                {t('downloadUpT')}
              </DownloadButton>
            )}
            {cutMode && isOwner[currentRole] && (
              <ShareButtons
                forCutHeader
                moveLeft={moveLeft}
              />
            )}
          </Controls>
        </ActiveContent>
        {!isForSharedToWeb && !isLargeRightBar && (
          <AvaModuleWrapper>
            <User
              isNew
              toggleUserMenu={toggleShowUserMenu}
              isActive={isComponentVisible}
              isMaker
            />
            {isComponentVisible && (
              <UserMenu
                refUserMenu={refUserMenu}
                setIsComponentVisible={setIsComponentVisible}
              />
            )}
          </AvaModuleWrapper>
        )}
        <AvatarPlaceHolder />
      </AllContentWrapper>
      <Divider />
    </MakerCutHeaderWrapper>
  );
};

export default MakerCutHeader;
