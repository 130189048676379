import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContactsHeaderUI from './ContactsHeaderUI';
import { actionSetNewFilterValue } from '../../../redux/channels/action';
import { sortedFunctions } from '../../../utils/dateConvert';
import { actionChangeSieveValue } from '../../../redux/library/actions';
import { PLACE } from '../../../utils/constants';

const ContactsHeader = (props) => {
  const dispatch = useDispatch();
  const { isCardsView } = useSelector((state) => state.user);
  const [showFilterBar] = useState(true);
  const clearAllFilters = () => {
    props.setFilter('');
    props.setIsShowFilter(false);
    dispatch(actionSetNewFilterValue('Role', ''));
    dispatch(actionSetNewFilterValue('Status', ''));
    props.setSort(Object.keys(sortedFunctions)[2]);
  };
  const setSieve = (value) => {
    dispatch(actionChangeSieveValue(value, PLACE.Users));
  };
  const isShowSorting = () => true;
  return (
    <ContactsHeaderUI
      setFilter={props.setFilter}
      setIsShowFilter={props.setIsShowFilter}
      isShowFilter={props.isShowFilter}
      filter={props.filter}
      isCardsView={isCardsView}
      setSieve={setSieve}
      setActiveNavSlider={props.setActiveNavSlider}
      activeNavSlider={props.activeNavSlider}
      items={props.items}
      sieveOptions={props.sieveOptions}
      isShowSorting={isShowSorting}
      clearAllFilters={clearAllFilters}
      showFilterBar={showFilterBar}
      refShowMenuSort={props.refShowMenuSort}
      isShowMenuSort={props.isShowMenuSort}
      setShowMenuSort={props.setShowMenuSort}
      sortType={props.sortType}
      setSort={props.setSort}
      sievedItems={props.sievedItems}
    />
  );
};

export default ContactsHeader;
