import React from 'react';
import classNames from 'classnames/bind';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import { ReactComponent as Download20Svg } from '../../../images/icons/download_20.svg';
import rawStyles from '../index.module.scss';
import { UiComponentTypes } from '../../../utils/constants';
import { ReactComponent as PdfSymbolSvg } from '../../../images/icons/contentIconSmall/pdfIcon.svg';
import { ReactComponent as Audio24Svg } from '../../../images/icons/playlist_item_types/audio_20_24.svg';
import { ReactComponent as VideoSvg } from '../../../images/icons/playlist_item_types/video_17_24.svg';
import { ReactComponent as ImageSvg } from '../../../images/icons/playlist_item_types/image_22_22.svg';
import { ReactComponent as Excel24Svg } from '../../../images/icons/playlist_item_types/excel_24_24.svg';
import { ReactComponent as Link12Svg } from '../../../images/icons/playlist_item_types/link_24_24.svg';
import { ReactComponent as Google16Svg } from '../../../images/icons/google_drive_16.svg';
import { ReactComponent as Ms12Svg } from '../../../images/icons/doc_symbol_12.svg';
import { ReactComponent as Component12Svg } from '../../../images/icons/playlist_item_types/component_26_26.svg';
import { ReactComponent as Archive24 } from '../../../images/icons/playlist_item_types/archive_20_24.svg';

const styles = classNames.bind(rawStyles);

const ElementTypeComponent = ({ type }) => {
  if (UiComponentTypes.pdf[type]) {
    return <PdfSymbolSvg />;
  }
  if (UiComponentTypes.archive[type]) {
    return <Archive24 />;
  }
  if (UiComponentTypes.audio[type]) {
    return <Audio24Svg />;
  }
  if (UiComponentTypes.media[type]) {
    return <VideoSvg />;
  }
  if (UiComponentTypes.image[type]) {
    return <ImageSvg />;
  }
  if (UiComponentTypes.xls[type]) {
    return <Excel24Svg />;
  }
  if (
    UiComponentTypes.link[type]
    && !UiComponentTypes.google_embed_component[type]
  ) {
    return <Link12Svg />;
  }
  if (UiComponentTypes.google_embed_component[type]) {
    return <Google16Svg />;
  }
  if (UiComponentTypes.ms[type]) {
    return <Ms12Svg />;
  }
  if (UiComponentTypes.component[type]) {
    return <Component12Svg />;
  }
  return <Archive24 />;
};

const IconWrapper = styled.div`
  width: 104px;
  height: 122px;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    width: 56px;
    height: 100%;
  }
`;
export const ArchiveContent = ({
  item,
  downloadFile,
  isPlayer,
  isForSharedToWeb,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={styles('archive', {
        isPlayer,
        isPlayerPreview: props.isPlayerPreview,
        isPlayerTOCPreviewCard: props.isPlayerTOCPreview && props.isCardView,
        isPlayerTOCPreviewRow: props.isPlayerTOCPreview && !props.isCardView,
      })}
      ref={props.imageRef}
    >
      <div className={styles('icon_wrapper')}>
        <IconWrapper>
          <ElementTypeComponent type={item.type} />
        </IconWrapper>
      </div>
      <div className={styles('title_wrapper')}>{item.title}</div>
      <div className={styles('description_wrapper')}>
        {`${t('cantPreview1T')} ${
          isForSharedToWeb
            ? ''
            : t('cantPreview2T')
        }`}
      </div>
      {!isForSharedToWeb && (
        <div className={styles('actions_wrapper')} onClick={downloadFile}>
          <ButtonComponent text={t('downloadFileT')}>
            <Download20Svg />
          </ButtonComponent>
        </div>
      )}
    </div>
  );
};
