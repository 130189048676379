import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronBotSvg }
  from '../../../images/2023/svg/small/component-description/chevron_8_5_bot.svg';
import { ReactComponent as ChevronRightSvg }
  from '../../../images/2023/svg/small/component-description/chevron_4_8_right.svg';
import { ReactComponent as PlaylistsIcon } from '../../../images/2023/svg/small/left-main-menu/playlist_icon_16_16.svg';
import { ReactComponent as PageIcon } from '../../../images/2023/svg/small/left-main-menu/page_icon_16_16.svg';
import { ReactComponent as ArrowRight } from '../../../images/2023/svg/small/usedIn/arrow_right_16_16.svg';
import { IconRole } from '../../../utils/permissions';
import { GlobalOneLineTextWrapped } from './styled';
import { sanitizeToLoad } from '../../../utils/helpers';
import { DEFAULT_TITLE } from '../../../utils/constants';

const types = {
  playlist: 'playlist',
  page: 'page',
};

const GlobalOneLineTextWrappedHover = styled(GlobalOneLineTextWrapped)`
  ${({ forCard }) => !forCard && '&:hover{ text-decoration: underline; }'}
  ${({ forCard, isOpen }) => !forCard && isOpen && 'margin-left:6px;'}
`;
const ToggleItWrapper = styled.div`
  ${({ isOpen }) => (isOpen
    ? `
      margin-right: 35px;`
    : 'margin: 0 13px 0 10px;')}

  ${({ forCard }) => forCard && 'margin: 0 5px 0 0;'}
`;

const UsedRowWrapper = styled.div`
  ${({ forCard }) => !forCard && 'margin-bottom: 10px;'}

  display:flex;
  flex-direction: row;
  cursor: pointer;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  &:hover {
    text-decoration: underline;
  }

  svg:nth-child(2) {
    margin-left: 11px;
    margin-right: 8px;
    position: absolute;
    left: 0;
  }
  ${({ isOpen }) => (isOpen
    ? `
       svg:nth-child(2){
    left: 9px;
  }
     `
    : '')}
  ${({ forCard }) => (forCard
    ? ` 
    height:30px;
    padding-top: 8px;
    line-height:16px;
    &:hover{
    text-decoration: none;
        background-color:#FFEEBF;
  }
    svg:nth-child(1){
    margin-top:0px;
     position: absolute;
    left: 15px;
  }
  padding-left:36px;
  padding-right:16px;
  font-size:14px;`
    : '')}
`;
const MainWrapper = styled.div`
  margin-top: 4px;
`;
const ChannelListWrapper = styled.div`
  padding-left: 42px;
  margin-bottom: 10px;

  ${({ forCard }) => (forCard
    ? ` 
    padding-left:0px;
    margin-bottom:0;
`
    : '')}
`;

const InChannelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  cursor: pointer;
  line-height: 19px;
  &:hover {
    text-decoration: underline;
  }
  ${({ forCard }) => (forCard
    ? ` 
    height:30px;
    line-height:16px;
        padding-top: 6px;
    &:hover{
    text-decoration: none;
    background-color:#FFEEBF;
  }
  padding-left:42px;
  font-size:14px;`
    : '')}

  svg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    rect {
      fill: transparent;
    }
  }
`;
const InChannelWrapperText = styled.div`
  width: 168px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const UsedInRow = ({
  item,
  role,
  forCard,
  type: currentType,
  setIsShowRightSideBarLib,
  isShowRightSideBarLib,
  dataParent,
}) => {
  const icon = currentType === types.playlist ? <PlaylistsIcon /> : <PageIcon />;
  const userId = useSelector((state) => state.user.id);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const toggleIt = () => {
    setIsOpen(!isOpen);
  };

  const open = (e) => {
    e.stopPropagation();
    if (currentType === types.playlist) {
      const isOwner = item.editManager?.users[0].id === userId;
      const type = isOwner ? 'edit' : 'shared';
      const id = isOwner ? item.id : item.editManager.id;
      history.push(`/maker/${id}/${type}`);
    }

    if (currentType === types.page) {
      history.push(`/libraryPage/${item.id}`);
    }
    if (isShowRightSideBarLib) setIsShowRightSideBarLib(false);
  };
  const default_name = currentType === types.playlist ? t('defaultSmartFileTitleT') : t('defaultSmartPageTitleT');
  const name = currentType === types.playlist ? item.editManager?.editPlaylist?.title : item.title;
  return (
    <>
      <UsedRowWrapper
        data-parent={dataParent}
        forCard={forCard}
        isOpen={isOpen}
      >
        <div onClick={toggleIt}>
          {currentType === types.playlist ? (
            <ToggleItWrapper
              data-parent={dataParent}
              forCard={forCard}
              isOpen
            >
              {!forCard && (isOpen ? <ChevronBotSvg /> : <ChevronRightSvg />)}
              {icon}
            </ToggleItWrapper>
          ) : (
            <ToggleItWrapper>{icon}</ToggleItWrapper>
          )}
        </div>
        <GlobalOneLineTextWrappedHover
          data-parent={dataParent}
          forCard={forCard}
          isOpen={isOpen}
          onMouseDown={open}
        >
          {name || default_name}
        </GlobalOneLineTextWrappedHover>
      </UsedRowWrapper>

      {(isOpen || forCard) && !!item?.editManager?.inChannel?.length && (
        <ChannelListWrapper
          data-parent={dataParent}
          forCard={forCard}
        >
          {item.editManager.inChannel.map((ch) => {
            return (
              <InChannelWrapper
                forCard={forCard}
                key={ch.id}
                onClick={() => {
                  history.push(`/channel/${ch.id}`);
                }}
                data-parent={dataParent}
              >
                {forCard && <ArrowRight />}
                {IconRole[role]}
                <InChannelWrapperText data-parent={dataParent}>
                  {ch.name ? sanitizeToLoad(ch.name) : t('unnamedChannelT')}{' '}
                </InChannelWrapperText>
              </InChannelWrapper>
            );
          })}
        </ChannelListWrapper>
      )}
    </>
  );
};
const UsedIn = ({
  data,
  forCard,
  setIsShowRightSideBarLib,
  isShowRightSideBarLib,
  dataParent,
}) => {
  const { myChannels } = useSelector((redux) => redux.channels);
  return (
    <MainWrapper data-parent={dataParent}>
      {data.map((it) => {
        return (
          it.item && (
            <UsedInRow
              dataParent={dataParent}
              type={it.item?.type}
              forCard={forCard}
              item={it.item}
              key={it.item?.id}
              role={myChannels[it.channel?.id]?.role}
              setIsShowRightSideBarLib={setIsShowRightSideBarLib}
              isShowRightSideBarLib={isShowRightSideBarLib}
            />
          )
        );
      })}
    </MainWrapper>
  );
};

export default UsedIn;
