import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { actionCloseModal } from '../../redux/user/action';
import {
  DATA_PARENTS,
  DEFAULT_POSITION_STEP,
  empty,
  svgDynamicCollection,
} from '../../utils/constants';
import Button2023 from '../Buttons/Button2023';
import { GlobalFlexRowWrapper } from '../../pages/Maker/MakerCommon/UiComponents/styled';
import SimpleTextInput from '../SimpleComponents/Inputs/SimpleTextInput';
import SelectIconGroup from '../SmartView/2023/SelectIcon';
import ColorPalette from '../ColorPicker/ColorPalette';
import {
  actionAddDynamicCollection,
  actionEditDynamicCollection,
} from '../../redux/dynamicCollection/action';

const ModalWrapper = styled.div`
  position: fixed;
  background: rgba(147, 147, 147, 0.6);
  z-index: 104;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;
  font-style: normal;
  font-weight: 400;
`;

const GreyTitleWrapper = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #939393;
  letter-spacing: 0.05em;
  margin-bottom: 12px;
  text-transform: uppercase;
  font-family: "RobotoFlex", sans-serif;
  ${({ isSecond }) => (isSecond
    ? `
    margin-top: 4px;
    margin-bottom: 13px;
  `
    : '')}
  ${({ isThird }) => (isThird
    ? `
    margin-top: 15px;
    margin-bottom: -2px;
  `
    : '')}
`;

const SmartModalWrapper = styled.div`
  position: relative;
  width: 342px;
  height: 584px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  padding: 16px;
`;

const ButtonBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 169px;
  padding-top: 6px;
  ${({ isForCreate }) => (isForCreate
    ? `
    padding-left: 160px;
  `
    : `
    padding-left: 169px;
  `)}
`;

const ItemTitleWrapper = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #484343;
`;
const SVGWrapper = styled.div`
  position: relative;
  left: -4px;

  svg {
    width: 22px;
    height: 22px;
  }

  svg path {
    ${({ color }) => (color ? `stroke:#${color};` : '')}
  }
`;

const Divider = styled.div`
  width: 310px;
  height: 1px;
  margin: 13px auto 3px;
  background: #1d1d1d;
  opacity: 0.08;
  ${({ theSecond }) => (theSecond
    ? `
    margin-top: 15px;
    margin-bottom: 10px;
  `
    : '')}
`;
const SmartModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dataPayload } = useSelector((state) => state.user);
  const superColorPickerRef = useRef(null);
  const item = useSelector((state) => state.content.contentData)[dataPayload?.contactId]
    || empty;
  const [iconKey, setIconKey] = useState(dataPayload?.iconKey || 'actions');
  const [selectedColor, setColorKey] = useState(dataPayload.color || 'A3AACC');
  const [inputName, setInputName] = useState(
    dataPayload.name || 'Untitled smartview',
  );
  const [firstNameValidationError, setFirstNameValidationError] = useState('');
  const isCreateMode = !dataPayload.name;

  const close = () => dispatch(actionCloseModal());

  const changeItemColor = (event, color) => {
    setColorKey(color.slice(1));
  };

  const onSave = () => {
    if (isCreateMode) {
      const id = uuidv4();
      const position = Math.max(
        ...Object.values(dataPayload || {}).map(
          (d) => d.position || DEFAULT_POSITION_STEP,
        ),
        0,
      ) + DEFAULT_POSITION_STEP;
      const createDate = Math.floor(Date.now() / 1000);
      dispatch(
        actionAddDynamicCollection(
          {
            name: inputName,
            id,
            position,
            iconKey,
            createDate,
            color: selectedColor,
            filter: dataPayload.libFilter,
            libFilterSearch: dataPayload.libFilterSearch,
          },
          null,
        ),
      );
      dispatch(actionCloseModal());
    } else {
      dispatch(
        actionEditDynamicCollection({
          dynamicCollectionId: dataPayload.id,
          name: inputName,
          color: selectedColor,
          filter: dataPayload.filter,
          libFilterSearch: dataPayload.libFilterSearch,
          iconKey,
        }),
      );
      dispatch(actionCloseModal());
    }
  };
  return (
    <ModalWrapper>
      <SmartModalWrapper>
        <GreyTitleWrapper>
          {!isCreateMode ? t('editSmartviewT') : t('createSmartViewT')}
        </GreyTitleWrapper>
        <GlobalFlexRowWrapper>
          <SVGWrapper data-place={item.id} color={selectedColor}>
            {svgDynamicCollection[iconKey](DATA_PARENTS.smartview)}
          </SVGWrapper>
          <ItemTitleWrapper>{inputName}</ItemTitleWrapper>
        </GlobalFlexRowWrapper>
        <Divider />
        <SimpleTextInput
          fieldName=""
          autoFocus
          value={inputName}
          setValue={setInputName}
          validateError={firstNameValidationError}
          setValidateError={setFirstNameValidationError}
          isRequired
          forSmartModal
        />
        <GreyTitleWrapper isSecond>{t('selectIconT')}</GreyTitleWrapper>
        <SelectIconGroup selectedIcon={iconKey} setIconKey={setIconKey} />
        <GreyTitleWrapper isThird>{t('selectIconColorT')}</GreyTitleWrapper>
        <ColorPalette
          isPositionRelative
          selectedColor={selectedColor}
          selectedBackgroundColor={selectedColor}
          refColorPickerParent={superColorPickerRef}
          onChangeColor={changeItemColor}
          isPaletteActive
          defaultPosition={{ top: 0, left: 0 }}
          buttonWidth={0}
          forSmartModal
        />
        <Divider theSecond />

        <ButtonBlockWrapper isForCreate={isCreateMode}>
          <Button2023
            variant="primary"
            width={69}
            height={32}
            text={t('cancelUpT')}
            noMargins
            handleButtonClick={close}
          />
          <Button2023
            variant="secondary"
            width={!isCreateMode ? 52 : 67}
            height={32}
            text={!isCreateMode ? t('saveUpT') : t('createUpT')}
            noMargins
            handleButtonClick={onSave}
          />
        </ButtonBlockWrapper>
      </SmartModalWrapper>
    </ModalWrapper>
  );
};

export default SmartModal;
