import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import Button2023 from '../Buttons/Button2023';

const cx = classNames.bind(styles);

const ConfirmDialog = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    dataPayload: {
      subject = t('areYouSureT'),
      description = t('permanentActionQuestionT'),
      confirm = () => {},
      cancelText = t('cancelT'),
      okText = t('deleteT'),
      isSignOut,
    },
  } = useSelector((redux) => redux.user);
  const close = () => {
    dispatch(actionCloseModal());
  };
  const deleteItem = () => {
    confirm();
    close();
  };
  return (
    <div onClick={close} className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'DeleteTag')}
      >
        <div className={cx('title')}>{subject}</div>

        {!!description && <div className={cx('sub_title')}>{description}</div>}

        <div className={cx('group_buttons')}>
          <Button2023
            variant="primary"
            width={70}
            height={32}
            text={cancelText}
            noMargins
            handleButtonClick={close}
          />
          <Button2023
            variant="secondary"
            height={32}
            text={okText}
            noMargins
            withMinWidth
            handleButtonClick={deleteItem}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
