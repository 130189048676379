export const shortcut = {
  StartRecording: '[shortcut] Start recording ',
  StopRecording: '[shortcut] Stop recording ',
  WriteAKey: '[shortcut] Write a key',
  ClearTheKey: '[shortcut] Clear the key',
  UpdateSequence: '[shortcut] Update sequence',
  ClearSequence: '[shortcut] Clear sequence',
  WriteCombination: '[shortcut] Write Combination',
  ClearCombinations: '[shortcut] Clear Combinations',
  ReleaseModKey: '[shortcut] Release Mod Key',
  PressModKey: '[shortcut] Press Mod Key',
};
