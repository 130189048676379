import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import moduleStyles from '../../pages/Library/LibraryHeader.module.scss';
import {
  DATA_PARENTS,
  empty,
  svgDynamicCollection,
} from '../../utils/constants';
import { ReactComponent as EditSvg } from '../../images/icons/icon_20/edit_20.svg';
import { ReactComponent as MoreSvg } from '../../images/icons/icon_20/more_20.svg';
import { ReactComponent as CloseSvg } from '../../images/icons/close_28.svg';
import UnifiedDropDownMenu, {
  options,
} from '../DropDownOption/UnifiedDropDownMenu';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import {
  actionDeleteDynamicCollection,
  actionDuplicateDynamicCollection,
  actionRenameDynamicCollection,
} from '../../redux/dynamicCollection/action';
import EditDynamicCollectionIcon from '../DynamicCollectionCreator/EditDynamicCollectionIcon';
import IconColored from '../../UI/IconColored';

const styles = classNames.bind(moduleStyles);

const SmartViewMenuInLibraryShell = styled.div`
  //width: 120px;
  margin-left: 30px;
  position: relative;
  display: flex;
  align-items: center;
`;

const Border = styled.div`
  border: 1px solid #e8ebfa;
  border-radius: 8px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 7px;
`;
const CloseWrapper = styled.div`
  //border: 1px solid #E8EBFA;
  //border-radius: 8px;
  margin-left: 20px;
  cursor: pointer;
`;

const SmartViewMenuInLibrary = (props) => {
  // const { activeSieveOption: dynamicCollectionId } = useSelector(state => state.library);
  const { selector: dynamicCollectionId } = useParams();

  const history = useHistory();
  const { t } = useTranslation();
  const dynamicCollection = useSelector((state) => state.dynamicCollection);

  const currentCollection = dynamicCollection[dynamicCollectionId] || empty;
  const [isComponentRename, setIsComponentRename] = useState(false);
  const [editRef, isEditIcon, setIsEditIcon] = useComponentVisible(false);
  const [tmpTitle, setTmpTitle] = useState('');
  const [refMenu, isComponentVisible, setIsComponentVisible] = useComponentVisible(false);
  const dispatch = useDispatch();
  const [width, setWidth] = useState(156);
  const inputRef = useRef(null);

  useEffect(() => {
    const x = inputRef.current.getBoundingClientRect().width;
    let newWidth;
    if (x < 156) {
      newWidth = 156 - 1;
    } else if (x >= 330) {
      newWidth = 330;
    } else newWidth = x;

    setWidth(newWidth + 12);
  }, [tmpTitle, currentCollection]);

  const onTitleClickHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setTmpTitle(currentCollection.name);
    isComponentVisible && setIsComponentVisible(false);
    setIsComponentRename(true);
  };

  const renameHandler = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    setIsComponentRename(false);
    setTmpTitle('');
    dispatch(actionRenameDynamicCollection(dynamicCollectionId, tmpTitle));
  };

  const onKeyPressFolderTitle = (e) => {
    if (e.key === 'Enter') {
      renameHandler(e);
    }
    if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();
      setIsComponentRename(false);
      setTmpTitle('');
    }
  };

  const deleteHandler = () => {
    const isLibraryCallback = () => history.push('/content/library/all');
    dispatch(
      actionDeleteDynamicCollection(
        dynamicCollectionId,
        history.location.pathname,
        isLibraryCallback,
      ),
    );
  };
  const duplicateHandler = () => {
    setIsComponentVisible(false);
    dispatch(actionDuplicateDynamicCollection(dynamicCollectionId));
  };

  const onClickEditIconHandler = () => {
    setIsEditIcon((prev) => !prev);
  };

  const closeHandler = () => history.push('/content/library/all');
  return (
    <SmartViewMenuInLibraryShell>
      <div className={styles('sideBarLib__header_option')}>
        <Border>
          <div
            className={styles('sideBarLib__header_title', 'dynamic_collection')}
          >
            <div
              className={styles('title_group')}
              data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            >
              <div
                data-cy="smartView-icons"
                className={styles('header_edit_icon_wrapper')}
              >
                <IconColored
                  color={currentCollection.derivedColors}
                  onClick={onClickEditIconHandler}
                  isActive={isEditIcon}
                >
                  {svgDynamicCollection[currentCollection.iconKey]
                    && svgDynamicCollection[currentCollection.iconKey]()}
                </IconColored>

                {isEditIcon && (
                  <EditDynamicCollectionIcon
                    itemId={dynamicCollectionId}
                    editRef={editRef}
                  />
                )}
              </div>

              <div
                className={styles('')}
                data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
              >
                <div
                  className={styles('')}
                  data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
                >
                  <div
                    ref={inputRef}
                    // className={styles('editableTitle', { hide: isComponentRename })}
                    className={styles('editableTitle', {
                      hide: isComponentRename,
                    })}
                    onClick={onTitleClickHandler}
                    data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
                  >
                    {tmpTitle || currentCollection.name || t('unnamedSmartViewT')}
                  </div>

                  {isComponentRename && isComponentRename !== 'close' && (
                    <div data-parent={DATA_PARENTS.PlaylistBuilderLibrary}>
                      <input
                        placeholder={t('nameDynamicCollectionT')}
                        maxLength={55}
                        value={tmpTitle}
                        style={{ width }}
                        autoFocus
                        onChange={(e) => setTmpTitle(e.target.value)}
                        onKeyDown={onKeyPressFolderTitle}
                        className={styles('inputField')}
                        onBlur={renameHandler}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles('options_wrapper')}>
              <div
                data-cy="edit-SmartView"
                onClick={() => props.setIsEdit((prev) => !prev)}
                className={styles('icon_wrapper')}
                data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
              >
                <EditSvg />
              </div>

              <div
                data-cy="SmartView-DropDownMenu"
                data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
                onClick={setIsComponentVisible}
                ref={refMenu}
                className={styles('icon_wrapper', {
                  active: isComponentVisible,
                })}
              >
                <MoreSvg />
                {isComponentVisible && (
                  <UnifiedDropDownMenu
                    place="DynamicCollectionHeader"
                    clickHandler={() => {}}
                    state={{
                      rename: options.rename(onTitleClickHandler),
                      duplicate: options.duplicate(duplicateHandler),
                      delete: options.delete_no_separator(deleteHandler, true),
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </Border>
      </div>

      <CloseWrapper onClick={closeHandler}>
        <CloseSvg />
      </CloseWrapper>
      {/* <DynamicCollectionCreator */}
      {/*  isDynamicCollection */}
      {/*  isEdit={props.isEdit} */}
      {/*  filter={props.filter} */}
      {/*  libFilterSearch={props.libFilterSearch} */}
      {/*  clearAllFilters={props.clearAllFilters} */}
      {/*  setFilter={props.setFilter} */}
      {/*  setLibFilterSearch={props.setLibFilterSearch} */}
      {/* /> */}
    </SmartViewMenuInLibraryShell>
  );
};

export default SmartViewMenuInLibrary;
