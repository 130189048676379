import { NeuralAction } from './types';
// import downloadStatus from '../../utils/dataUtil';


const defaultState = {
  status: 'downloadStatus.init',
  neuralText: '',
};

const neuralReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case NeuralAction.GenerateText: {
      return { ...state, neuralText: '', status: 'downloadStatus.pending' };
    }

    case NeuralAction.SetText: {
      return { ...state, neuralText: payload.neuralText, status: 'downloadStatus.success' };
    }

    case NeuralAction.ClearText: {
      return defaultState;
    }


    default:
      return state;
  }
};

export default neuralReducer;
