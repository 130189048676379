import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import moduleStyles from './HeadingMenu.module.scss';
import { ReactComponent as Eye16Svg } from '../../images/icons/eye_16.svg';
import Navigation from './Navigation';
import { DATA_PARENTS, empty } from '../../utils/constants';
import { ReactComponent as Collapse24Svg } from '../../images/icons/collapse_24.svg';
import { ReactComponent as Expand24Svg } from '../../images/icons/expand_24.svg';
import {
  actionSetResetScale,
  actionToggleIsCollapsedContent,
} from '../../redux/currentPage/action';
import Tooltip from '../../shared/Tooltips/Tooltip';

const styles = classNames.bind(moduleStyles);

const PlaylistViewMode = () => {
  const { t } = useTranslation();
  const { isCollapsedContent } = useSelector((state) => state.currentPage);
  const userId = useSelector((state) => state?.user?.id) || empty;
  const ownerId = useSelector((state) => state?.currentPage?.owner?.id) || empty;
  const isOwner = userId === ownerId;

  const dispatch = useDispatch();

  const onExpandClickHandler = () => {
    dispatch(actionToggleIsCollapsedContent(!isCollapsedContent));
    dispatch(actionSetResetScale(true));
  };

  return (
    <div className={styles('headingMenu', 'view_playlist')}>
      <Navigation />
      {isOwner && (
        <Tooltip
          text={t('youAreAnOwnerT')}
          direction="left"
          place="PlaylistHeader"
        >
          <div className={styles('preview_info_icon')}>
            <Eye16Svg />
            <p className={styles('text')}>{t('previewT')}</p>
          </div>
        </Tooltip>
      )}
      <div className={styles('view_expand_wrapper')}>
        <div
          className={styles('simple_button')}
          onClick={onExpandClickHandler}
          data-parent={DATA_PARENTS.TableOfContents}
        >
          {!isCollapsedContent && <Collapse24Svg />}
          {isCollapsedContent && <Expand24Svg />}
          <div
            className={styles('text')}
            data-parent={DATA_PARENTS.TableOfContents}
          >
            {!isCollapsedContent && t('collapseUpT')}
            {isCollapsedContent && t('expandUpT')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaylistViewMode;
