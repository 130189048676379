import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DynamicCollectionCreatorUI from './DynamicCollectionCreatorUI';
import useComponentVisibleMouseDown from '../../utils/hooks/useComponentVisibleMouseDown';
import { DATA_PARENTS } from '../../utils/constants';

const DynamicCollectionCreator = (props) => {
  const [isShowFilters, setIsShowFilters] = useState(false);
  const [refTags, isTagsOpened, setIsTagsOpened] = useComponentVisibleMouseDown(
    false,
    DATA_PARENTS.SideBar.tags,
  );
  const [refDate, isDateOpened, setIsDateOpened] = useComponentVisibleMouseDown(
    false,
    DATA_PARENTS.SideBar.date,
  );
  const [refFileType, isFileTypeOpened, setIsFileTypeOpened] = useComponentVisibleMouseDown(false, DATA_PARENTS.SideBar.fileType);
  const tags = useSelector((state) => state.user.tags);

  const clearAllFilters = () => {
    props.setFilter({});
  };
  const toggleEnableFilters = () => {
    if (!props.noEdit) setIsShowFilters((prev) => !prev);
  };
  const toggleIsTagsOpened = () => {
    if (!props.noEdit) setIsTagsOpened((prev) => !prev);
  };
  const toggleIsDateOpened = () => {
    if (!props.noEdit) setIsDateOpened((prev) => !prev);
  };
  const toggleIsFileTypeOpened = () => {
    if (!props.noEdit) setIsFileTypeOpened((prev) => !prev);
  };

  return (
    <DynamicCollectionCreatorUI
      {...props}
      clearAllFilters={clearAllFilters}
      isShowFilters={isShowFilters}
      toggleEnableFilters={toggleEnableFilters}
      refTags={refTags}
      tags={tags}
      isTagsOpened={isTagsOpened}
      toggleIsTagsOpened={toggleIsTagsOpened}
      refDate={refDate}
      isDateOpened={isDateOpened}
      toggleIsDateOpened={toggleIsDateOpened}
      refFileType={refFileType}
      isFileTypeOpened={isFileTypeOpened}
      toggleIsFileTypeOpened={toggleIsFileTypeOpened}
    />
  );
};

export default DynamicCollectionCreator;
