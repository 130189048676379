import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import styled from 'styled-components/macro';
import rawElemStyles from './elements.module.scss';
import { ReactComponent as SixDotsSvg } from '../../../images/icons/drag_black_lines.svg';
import { ReactComponent as None } from '../../../images/icons/icon-colour-none.svg';
import { ReactComponent as Add16Svg } from '../../../images/icons/plus_16.svg';
import SixDotsDropDownUPV from '../MakerCommon/Table/SixDotsDropDown';
import { isRoleInPlaylist } from '../../../utils/permissions';
import { NameAndImageAva } from '../MakerCommon/Table/NameAndImage';
import { calcCoEditorColor, calcCoEditorItemDate } from '../../../utils/helpers';
import { AvaWrapper, UserAvatarWrapper } from '../styled';
import CheckBoxLinkPage from '../../../entities/CheckBoxLinkPage';

const cx = classNames.bind(rawElemStyles);

const SixDotsAnalogWrap = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
  position: absolute;
  background: #ffc72c;
  left: -15px;
  top: 50%;
  z-index: 31;
  transform: translate(0%, -50%);
`;

const SixDotsItem = ({
  menuRef,
  isActive,
  setIsActive,
  itemStylesObject,
  otherOwner,
  setRenameMode,
  duplicateHandler,
  createApproveButton,
  currentRole,
  isExpandedView,
  ...props
}) => {
  const { singleUserShareState, selectedItems } = useSelector(state => state.currentPage);
  const { isViewer: isViewerEx, isCoEdit: isCoEditEx } = isRoleInPlaylist;
  const isViewer = isViewerEx[currentRole];
  const isCoEdit = isCoEditEx[currentRole];
  const isTitle = itemStylesObject?.isTitle;
  const isDisabled = (isViewer || isCoEdit && otherOwner) && props.item.isRemixLocked;
  const { manager } = useSelector((state) => state.currentPage);
  const clickHandler = (e) => {
    e && e.stopPropagation();
    if (isViewer) return;
    props.clear();
    setIsActive(!isActive);
  };
  const buttonRef = useRef(null);
  const calcDate = useMemo(() => {
    return calcCoEditorItemDate(props.item.lastModifiedDate ?? props.item.createDate);
  }, [props.item.createDate, props.item.lastModifiedDate]);

  const itemColor = useMemo(() => {
    return calcCoEditorColor(singleUserShareState, props.ownerID, props.item.owner?.id);
  }, [singleUserShareState, props.item.owner.id]);

  const sixDotsPosition = buttonRef.current?.getBoundingClientRect();
  const isOnlyAvatarsDisplay = window.innerWidth < 1550;
  return (
    <div
      className={cx('six_dot_wrap', {
        isCompressView: props.isCompressView,
        just_a_viewer: isViewer,
        on: isActive,
        isCardView: !isExpandedView,
      })}
    >
      {!props.isPlaceHolder && (
        <div className={cx('checkbox_balancer', { selected_checkbox: selectedItems[props.item.id] })}>
          <CheckBoxLinkPage
            itemId={props.item.id}
            isSelected={selectedItems[props.item.id]}
            isDisabled={isDisabled}
            handleShowPannel={props.handleShowPannel}
          />
        </div>
      )}
      <div className={cx('six_dot_positioning')}>
        {!isViewer && (
          <div
            data-cy="playlist-element-hover"
            ref={buttonRef}
            className={cx('six_dot_dropMenu_wrapper')}
          >
            {isActive && (
              <SixDotsDropDownUPV
                isText={!!props.item?.textComponent?.id}
                isPlaceHolder={props.isPlaceHolder}
                item={props.item}
                isTitle={isTitle}
                playlistId={props.playlistId}
                refMenu={menuRef}
                show={isActive}
                setIsActive={setIsActive}
                setRenameMode={setRenameMode}
                duplicateHandler={duplicateHandler}
                itemIndex={props.itemIndex}
                createApproveButton={createApproveButton}
                addCaptionToLinkPage={props.addCaptionToLinkPage}
                removeCaptionFromLinkPage={props.removeCaptionFromLinkPage}
                deleteApproveButton={props.deleteApproveButton}
                currentRole={currentRole}
                upvCard
                sixDotsPosition={sixDotsPosition}
              />
            )}
            <UserAvatarWrapper
              color={itemColor}
              draggable={!otherOwner}
              onDragStart={props.dragStartHandler}
              onDragEnd={props.onDragEndHandler}
              className={cx(
                { six_dot_icon: !otherOwner, otherOwner: !!otherOwner, on: isActive },
              )}
              onClick={clickHandler}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
            >
              {!otherOwner && <SixDotsSvg />}
            </UserAvatarWrapper>
          </div>
        )}
        {!manager?.userHideDateAvatar && (
          <AvaWrapper calcColor={itemColor} isOnlyAvatarsDisplay={isOnlyAvatarsDisplay}>
            {!isOnlyAvatarsDisplay && calcDate}
            <NameAndImageAva
              noStick
              avaURL={props.item.owner?.avatarUrlVerySmall}
              first_name={props.item.owner?.first_name}
              last_name={props.item.owner?.last_name}
              displayImage={props.item.owner.displayImage}
              upvCard
              itemColor={itemColor}
              isOnlyAvatarsDisplay={isOnlyAvatarsDisplay}
              calcDate={calcDate}
            />
          </AvaWrapper>
        )}
        {otherOwner && (
          <SixDotsAnalogWrap
            onClick={clickHandler}
            className={cx('show_analog_wrap_on_hover')}
          >
            <None />
          </SixDotsAnalogWrap>
        )}
        {!isTitle && (
        <div className={cx('six_dot_permanent_line')}>
          <div className={cx('line', `${itemStylesObject.style}`)} />
        </div>
        )}
        {!isViewer && (
          <div className={cx('six_dot_stripe')}>
            <div
              onClick={() => props.createHandler(true)}
              className={cx('plus', { plus_viewer: isViewer })}
            >
              <Add16Svg />
            </div>

            <div className={cx('line')} />
            <div
              onClick={() => props.createHandler(false)}
              className={cx('plus', 'bottom', { plus_viewer: isViewer })}
            >
              <Add16Svg />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SixDotsItem;
