import { convertFromRaw, EditorState } from 'draft-js';
import { sanitizeToLoad } from './helpers';

const draftDataConvertToLoad = (content) => {
  let state;

  try {
    const parsedContent = JSON.parse(
      content
        .replaceAll('"', '\\"')
        .replaceAll("'", '"')
        .replaceAll(/(\r\n|\n|\r)/gm, '\\n')
        .replaceAll('$@quote', "'"),
    );
    parsedContent.blocks.forEach((it) => { it.text = sanitizeToLoad(it.text); });
    state = EditorState.createWithContent(convertFromRaw(parsedContent));
  } catch (e) {
    state = EditorState.createWithContent(
      convertFromRaw(
        // eslint-disable-next-line max-len
        JSON.parse(
          "{'blocks':[{'key':'daj6c','text':'','type':'text','depth':0,'inlineStyleRanges':[],'entityRanges':[],'data':{}}],'entityMap':{}}"
            .replaceAll("'", '"')
            .replace(/(\r\n|\n|\r)/gm, '\\n'),
        ),
      ),
    );
  }

  return state;
};

export default draftDataConvertToLoad;
