import React, { memo, useMemo } from 'react';
import { isTextType } from '../../utils/helpersDragt';
import MomentBlockText from './MomentBlockText';
import MomentBlockOther from './MomentBlockOther';

const MomentBlock = memo((props) => {
  const propsMemo = useMemo(
    () => props,
    [
      props.blocksToCreateLComponent,
      props.blocksIds,
      props.blocksIndices,
      props.idOpenMenuBlock,
      props.type1,
      props.state,
      props.newFocus,
      props.componentId,
      props.isBlockHidden,
      props.innerHtml,
      props.position,
      props.index,
      props.width,
      props.page,
      props.canUpdForRedoUndo,
      props.isNew,
    ],
  );
  if (isTextType[props.type1] || props.type1 === 'video') return <MomentBlockText props={propsMemo} />;
  return <MomentBlockOther {...props} />;
});

// const MemoizedComponent = memo(SomeComponent, ?)
export default MomentBlock;
