import { UiComponentTypes } from '../../../utils/constants';

export const MAX_ZOOM_VALUE = 300;
export const MIN_ZOOM_VALUE = 25;

export const scaleControls = {
  default: 100,
  increase: (n, step) => {
    return Math.min(n + step, MAX_ZOOM_VALUE);
  },
  decrease: (n, step) => {
    return Math.max(n - step, MIN_ZOOM_VALUE);
  },
  reset() {
    return this.default;
  },
};

export const pageControls = {
  increase: (n, max) => {
    if (n + 1 > max) return n;
    return n + 1;
  },
  decrease: (n) => {
    if (n - 1 <= 0) return n;
    return n - 1;
  },
};

export const otherType = (type) => !UiComponentTypes.pdf[type]
  && !UiComponentTypes.image[type]
  && !UiComponentTypes.component[type]
  && !UiComponentTypes.page[type]
  && !UiComponentTypes.ms[type]
  && !UiComponentTypes.xls[type]
  // && !UiComponentTypes.audio[type]
  && !UiComponentTypes.pp[type]
  && !UiComponentTypes.link[type]
  && !UiComponentTypes.google_embed_component[type]
  && !UiComponentTypes.dropbox_embed_component[type];

export const otherTypeForDisplayPreview = (type) => !UiComponentTypes.pdf[type]
  && !UiComponentTypes.image[type]
  && !UiComponentTypes.component[type]
  && !UiComponentTypes.page[type]
  && !UiComponentTypes.ms[type]
  && !UiComponentTypes.xls[type]
  && !UiComponentTypes.audio[type]
  && !UiComponentTypes.video[type]
  && !UiComponentTypes.pp[type]
  && !UiComponentTypes.link[type]
  && !UiComponentTypes.google_embed_component[type]
  && !UiComponentTypes.dropbox_embed_component[type];
