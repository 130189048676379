import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { actionClearCombinations } from '../redux/shortcuts/action';
import { showErrorMessage } from './sagasUtils';
import { DownloadAction } from '../redux/downloads/types';
import { DOWNLOAD_URL, UiComponentTypes } from '../utils/constants';
import { APPURL } from '../environments/environments';
import { sanitizeToLoad } from '../utils/helpers';
import { actionToggleRequestSpinner } from '../redux/support/action';
import { requestGetLibComponent } from '../utils/request';

const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');

function* downloadFile(action) {
  try {
    const { id, url } = action.payload;
    const { data } = yield requestGetLibComponent(['any', id]);
    const [component] = data.LibraryComponent;
    const [owner] = component.users;
    let blobUrl;
    yield put(actionToggleRequestSpinner(true));

    // case video or audio
    if (component.assetID) {
      const {
        data: { downloadUrl },
      } = yield call(axios, {
        method: 'get',
        url: `${DOWNLOAD_URL}/mux/${component.assetID}`,
        headers: {
          token: token ? `${token}` : '',
        },
      });
      blobUrl = downloadUrl;
    } else {
      // case any other type
      if (
        UiComponentTypes.link[component?.type]
        || UiComponentTypes.component[component?.type]
        || UiComponentTypes.page[component?.type]
      ) return;
      let response;
      if (url) {
        response = yield call(axios, {
          method: 'get',
          url: `${url}`,
          responseType: 'blob',
          headers: {
            'Access-Control-Allow-Origin': `${APPURL}`,
            'Access-Control-Request-Headers': `${APPURL}`,
            'Cache-Control': 'no-cache',
          },
        });
      } else {
        response = yield call(axios, {
          method: 'get',
          url: `${DOWNLOAD_URL}/${id}/${owner ? owner?.id : ''}`,
          responseType: 'blob',
          headers: {
            token: token ? `${token}` : '',
          },
        });
      }
      blobUrl = URL.createObjectURL(response.data);
    }
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute(
      'download',
      `${sanitizeToLoad(component?.title) || 'quidzi_download'}`,
    );
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
    link.parentNode.removeChild(link);
    yield put(actionClearCombinations());
    yield put(actionToggleRequestSpinner(false));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

export default function* downloadSaga() {
  yield takeEvery(DownloadAction.DownloadFile, downloadFile);
}
