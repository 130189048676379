import React from 'react';
import classNames from 'classnames/bind';
import { sanitizeHtml } from '../../../utils/helpers';
import rawStyles from '../index.module.scss';

const styles = classNames.bind(rawStyles);

export const EmbedContent = ({ item, ...props }) => {
  return (
    <div
      ref={props.imageRef}
      className={styles('embed_preview', {
        isPlayerPreview: props.isPlayerPreview,
        isPlayer: props.isPlayer,
        isPlayerTOCPreviewRow: props.isPlayerTOCPreview && !props.isCardView,
        isPlayerTOCPreviewCard: props.isPlayerTOCPreview && !!props.isCardView,
      })}
      dangerouslySetInnerHTML={{ __html: sanitizeHtml(item?.linkUrl) }}
    />
  );
};
