import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import ContactsHeader from './Header/ContactsHeader';
import { sortedFunctions } from '../../utils/dateConvert';
import { UsersSieveTypes } from '../../utils/constants';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { actionLoadUserContactsS } from '../../redux/contacts/action';
import { actionCreator } from '../../shared/redux/actionHelper';
import { ContentActionType } from '../../redux/content/contentTypes';
import { FluentTableViewForContacts } from './EditContactTable';

const ContactsShell = styled.div`
  width: 100%;
  background-color: #f6f6f5;
  height: calc(100vh - 67px);
`;

const Contacts = () => {
  const { sortType: sortTypeEx } = useSelector((state) => state.content);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const scroller = useRef();
  const [filter, setFilter] = useState('');
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [refShowMenuSort, isShowMenuSort, setShowMenuSort] = useComponentVisible(false);
  const [activeNavSlider, setActiveNavSlider] = useState(UsersSieveTypes.All);
  const [sortType, setSort] = useState(
    sortTypeEx || Object.keys(sortedFunctions)[2],
  );
  const { contentData, contentIds, existMore, counters } = useSelector(
    (state) => state.content,
  );

  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setHasMore(existMore);
  }, [contentIds.length]);

  useEffect(() => {
    dispatch(actionLoadUserContactsS());
  }, []);

  useEffect(() => {
    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        { activeNavSlider: 'contact', selectorType: activeNavSlider, sortType },
        'Contact',
      ),
    );
    dispatch(actionCreator(ContentActionType.updateCounterS, {}));
  }, [sortType]);

  const next = () => {
    setHasMore(false);
    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        { activeNavSlider: 'contact', selectorType: activeNavSlider, sortType },
        'Contact next',
      ),
    );
  };

  return (
    <ContactsShell>
      <ContactsHeader
        setFilter={setFilter}
        setIsShowFilter={setIsShowFilter}
        isShowFilter={isShowFilter}
        filter={filter}
        setActiveNavSlider={setActiveNavSlider}
        activeNavSlider={activeNavSlider}
        // items={sorterPages}
        refShowMenuSort={refShowMenuSort}
        isShowMenuSort={isShowMenuSort}
        setShowMenuSort={setShowMenuSort}
        sortType={sortType}
        setSort={setSort}
        sievedItems={{
          '[Users]Contacts': Array(counters.contact),
        }}
        sieveOptions={UsersSieveTypes}
      />
      <InfiniteScroll
        pageStart={0}
        loadMore={next}
        useWindow={false}
        hasMore={hasMore}
        loader={<div key={0}>{t('loadingT')}</div>}
        getScrollParent={() => scroller.current}
      >
        <FluentTableViewForContacts data={contentData} setSort={setSort} />
      </InfiniteScroll>
    </ContactsShell>
  );
};

export default Contacts;
