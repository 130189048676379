import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import BlockCreatorComponent from '../../components/LibraryMiniPage/BlockCreatorComponent';

const PageBodySharing = styled.div`
  width: 30px;
  min-width: 30px;
  height: 30px;
  overflow: hidden;
  position: relative;
`;
const PageBodySharingTransform = styled.div`
  transform: scale(0.12) translate(-482%,-368%);
`;

const InboxPagePreview = ({ id, pageRef }) => {
  const { t } = useTranslation();
  const it = useSelector((state) => state.inbox.pages);
  if (!it[id]) {
    return (
      <>
        <PageBodySharing ref={pageRef}>
          <div className="loader-wrapper-page">
            <div className="loader_tini">{t('loadingT')}</div>
          </div>
        </PageBodySharing>
      </>
    );
  }

  return (
    <PageBodySharing ref={pageRef}>
      <PageBodySharingTransform>
        <BlockCreatorComponent
          isPreview
          item={it[id]}
        />
      </PageBodySharingTransform>
    </PageBodySharing>
  );
};

export default InboxPagePreview;
