import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { ReactComponent as EyeOffSvg } from '../../../images/icons/eye_off.svg';
import { ReactComponent as EyeOnSvg } from '../../../images/icons/eye_on.svg';
import { actionCreator } from '../../../shared/redux/actionHelper';
import DashboardColumns from '../../../redux/dashboardColumns/types';
import calcColor from '../EditColumn/utils';
import styles from '../EditColumn/EditColumn.module.scss';
import { empty } from '../../../utils/constants';

const Row = styled.div`
  position: relative;
  width: 229px;
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #242633;
  padding-right: 10px;
  &:hover {
    background: #FFEEBF;
    & .itemMenu {
      background: red;
    }
  }
  border-radius: 6px;
  cursor: pointer;
  margin-left: 24px;
  background: ${({ dragHover }) => (dragHover ? '#FFEEBF' : '')};
`;

const Text = styled.div`
  width: 153px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    background: #FFEEBF;
  }
`;

const Rectangle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background: ${({ color }) => color ?? '#FFDE80'};
  margin-right: 8px;
  margin-left: 8px;
`;
const InputRenameRow = styled.input`
  border: none;
  background-color: inherit;
  margin-right: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
`;
const EyeWrap = styled.div`
  position: absolute;
  right: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;

  & svg path {
    stroke: #484343;
  }

  &:hover {
    background-color: #fff;
  }
`;
const DashboardLeftMenuRow = ({
  column,
  dragColumnOverId,
  highlightedColumn,
  onDragEnter,
  onDropCard,
  isStatic,
  moveHandler,
}) => {
  const dispatch = useDispatch();

  const { columns: dashboardColumns, closeColumn } = useSelector(
    (state) => state.dashboardColumns || empty,
  );

  const [localTitle, setLocalTitle] = useState(
    dashboardColumns ? dashboardColumns[column?.id]?.name : '',
  );
  const [isComponentRename, setIsComponentRename] = useState(false);

  const refTitleInput = useRef(null);

  useEffect(() => {
    setLocalTitle(dashboardColumns && dashboardColumns[column.id]?.name);
  }, [dashboardColumns && dashboardColumns[column.id]?.name]);

  useEffect(() => {
    if (isComponentRename) refTitleInput?.current?.focus();
  }, [isComponentRename]);

  const saveNewTitle = (e) => {
    e.currentTarget.blur();
    setIsComponentRename(false);
    if (localTitle === column.name) return;
    dispatch(
      actionCreator(DashboardColumns.column.rename, {
        columnId: column.id,
        newName: localTitle,
      }),
    );
  };
  const onNewTitleKeyDown = (e) => {
    if (e.key === 'Enter') {
      saveNewTitle(e);
    }
  };
  const onShowHideHandler = (e) => {
    e.stopPropagation();
    dispatch(
      actionCreator(DashboardColumns.column.toggleShowHide, {
        columnType: column.id,
        isHide: !column.isHide,
      }),
    );
  };
  return (
    <div
      className={`${styles.hoverHandler} ${
        highlightedColumn === column.id ? styles.highlightedColumn : ''
      }`}
    >
      <Row
        dragHover={dragColumnOverId === column.id}
        onDragEnter={onDragEnter}
        onDrop={(e) => onDropCard(e, column.id)}
        onClick={moveHandler}
      >
        <Rectangle
          color={calcColor(
            column.id,
            isStatic,
            dashboardColumns,
            {},
            closeColumn,
          )}
        />
        {!isComponentRename && (
          <Text>{dashboardColumns[column.id]?.name || column.name}</Text>
        )}
        {isComponentRename && (
          <div>
            <InputRenameRow
              ref={refTitleInput}
              onBlur={(e) => saveNewTitle(e)}
              onKeyPress={onNewTitleKeyDown}
              value={localTitle}
              onChange={(e) => setLocalTitle(e.target.value)}
            />
          </div>
        )}
        <EyeWrap onClick={onShowHideHandler}>
          {column.isHide && <EyeOnSvg />}
          {!column.isHide && <EyeOffSvg />}
        </EyeWrap>
        <div className={styles.counterInRow}>
          {Object.keys(column.items || {}).length}
        </div>
        {/* <EditColumnButton */}
        {/*  column={column} */}
        {/*  isHidden */}
        {/*  isStatic={isStatic} */}
        {/*  setIsComponentRename={setIsComponentRename} */}
        {/* /> */}
      </Row>
    </div>
  );
};

export default DashboardLeftMenuRow;
