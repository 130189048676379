import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { empty } from '../../utils/constants';
import { actionSetAvailabilityDate } from '../../redux/currentPage/action';
import PlaylistsDatePicker2023 from './PlaylistsDatePicker2023';
import { calcNewState, calcSaveState, calcSaveStateForShared } from './helpers';

export const PlaylistDatePickerDataLayer = ({ dataParent = 'miniPage', setLocalState,
  localState, selectedOption, setSelectedOption }) => {
  const { t } = useTranslation();
  // const { availableFrom, availableTo, isAvailableRange } = useSelector((redux) => redux.currentPage.inputs) || empty;
  const [selectedDateFrom, setSelectedDateFrom] = useState(calcNewState(null));
  const [selectedDateTo, setSelectedDateTo] = useState(calcNewState(null));
  const [selectFirstOption, setSelectFirstOption] = useState(true);
  const [selectSecondOption, setSelectSecondOption] = useState(false);
  useEffect(() => {
    if (localState?.sharedAvailableTo != null && selectedDateTo !== calcNewState(localState.selectedDateTo)) {
      setSelectedDateTo(calcNewState(localState.sharedAvailableTo));
    }
    if (localState?.sharedAvailableFrom != null && selectedDateFrom !== calcNewState(localState.selectedDateFrom)) {
      setSelectedDateFrom(calcNewState(localState.sharedAvailableFrom));
    }
  }, [localState]);
  const saveState = () => {
    const state = calcSaveStateForShared(
      selectedDateFrom,
      selectedDateTo,
      selectedOption,
    );
    setLocalState(state);
  };

  return (
    <PlaylistsDatePicker2023
      dataParent={dataParent}
      saveState={saveState}
      selectFirstOption={selectFirstOption}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      setSelectFirstOption={setSelectFirstOption}
      selectSecondOption={selectSecondOption}
      setSelectSecondOption={setSelectSecondOption}
      selectedDateFrom={selectedDateFrom}
      setSelectedDateFrom={setSelectedDateFrom}
      selectedDateTo={selectedDateTo}
      setSelectedDateTo={setSelectedDateTo}
      showHeader
      isLocal
      textFirstOption={t('openToReadT')}
      textSecondOption={t('only24HoursT')}
    />
  );
};
