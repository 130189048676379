export const PAGE_IS_DOWNLOAD = 'PAGE_IS_DOWNLOAD';

export const ContentActionType = {
  removeUnsubscribeLibraryEntity: '[Content] remove unsubscribe library entity',
  startUpload: '[Content] start upload content',
  finishUpload: '[Content] finish upload content',
  finishUploadCount: '[Content] finish upload content count',
  updateCounter: '[Content] update counter',
  resetDynamicCounter: '[Content] reset dynamic counter',
  updateCounterChannel: '[Content] update counter in channel',
  updateCounterSFChannel: '[Content] update counter SF in channel',
  updateCounterContacts: '[Content] update counter in contacts',
  incrementCounters: '[Content] incrementCounters',
  updateCounterS: '[Content] update counter Saga',
  updateCounterInChannelS: '[Content] update counter in Channel Saga',
  clearContentDataR: '[Content] clear Content Data REDUX',
  updateSpecificElementS: '[Content] update Specific Element SAGAS',
  updateSpecificElementR: '[Content] update Specific Element REDUX',
  updatePlaylistR: '[Content] update playlist REDUX',
  updatePageR: '[Content] update page REDUX',
  removePlaylistAfterSageR:
    '[Content] remove playlist after saga message REDUX',
  removeSharedPage: '[Content] remove shared page REDUX',
  previousSortType: '[Content] add the previous Sort Type',
  ShareMultipleR: '[EditPlaylist] Share Multiple Playlists Redux',
  SwitchContentSortType: '[Content] actionSwitchContentSortType',
  MovePlaylistToShare: '[Content] move playlist to publish',
  updateCounterInOutbox: '[Content] move playlist to publish',
};
