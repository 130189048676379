import React, { useState, useEffect, DragEvent, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.css';
import { ReactComponent as DropLineSvg } from '../../images/icons/drop_area_short.svg';
import { actionCreator } from '../../shared/redux/actionHelper';
import { Channels } from '../../redux/channels/types';
import Contacts from '../../redux/contacts/types';
import { IRootState } from '../../redux/reducers';

interface ListDragHandlerProps {
  nextItem: any;
  currentItem: any;
  isLast: boolean;
  isFirst: boolean;
  isContacts: boolean;
}


const ListDragHandler: React.FC<ListDragHandlerProps> = ({
  nextItem,
  currentItem,
  isLast,
  isFirst,
  isContacts,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { idDragPage, isDragFolder } = useSelector((state: IRootState) => state.currentPage);
  const isNearbyOnDrag = nextItem === idDragPage || currentItem === idDragPage;
  const dispatch = useDispatch();
  const dropDepthRef = useRef(0);

  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    dropDepthRef.current += 1;
    setIsActive(true);
  };

  const onDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    dropDepthRef.current -= 1;

    if (dropDepthRef.current <= 0) {
      dropDepthRef.current = 0;
      setIsActive(false);
    }
  };

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsActive(false);
    dropDepthRef.current = 0;
    const conditionalAction = isContacts ? Contacts.RearrangeContactItemS : Channels.RearrangeChannelItemS;
    dispatch(actionCreator(conditionalAction, { nextItem, currentItem }));
  };

  return (
    <>
      {isDragFolder && !isNearbyOnDrag && (
        <div className={styles.wrapper}>
          {idDragPage && (
            <div
              onDragEnter={onDragEnter}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onDragLeave={onDragLeave}
              className={`${styles.handler} ${isLast && styles.is_last} ${isFirst && styles.is_first}`}
            >
              {isActive && (
                <div className={styles.place}>
                  <DropLineSvg />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ListDragHandler;
