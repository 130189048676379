import { call, put } from 'redux-saga/effects';
import { delay, select, takeLatest } from '@redux-saga/core/effects';
import { ContentActionType } from '../redux/content/contentTypes';
import {
  requestPagePreview,
  requestSingleItem,
  requestUserPlaylists,
  requestUserPlaylistsPreCheck,
} from '../utils/request';
import { actionCreator } from '../shared/redux/actionHelper';
import EditPlaylist from '../redux/playlists/types';
import { sortedFunctions } from '../utils/dateConvert';
import SupportAction from '../redux/support/types';
import {
  getAllSortOptions,
  getDynamicCollections,
  getSupportPlaylists,
  showErrorMessage,
} from './sagasUtils';
import { DynamicCollection } from '../redux/dynamicCollection/types';
import { sortOptionChooser } from '../utils/sort/sortTypeChooser';
import { LIMIT_ITEMS_PAGINATION } from '../utils/constants';
import { actionUpdateCounters } from '../redux/content/actions';

const hsaPagination = {
  playlists: true,
  smartfiles: true,
  smartpages: true,
  pages: true,
  media: true,
  components: true,
  pdf: true,
  dynamicCollection: true,
  links: true,
  files: true,
  tag: true,
  image: true,
  channelPlaylists: true,
  users_smartfiles: true,
  users_smartfiles_favorite: true,
  channelFavoritePlaylists: true,
  channelUsers: true,
  contact: true,
  library: true,
};

const hasMergeItem = (activeNavSlider, selectorType) => {
  if (activeNavSlider === 'smartfiles') {
    if (selectorType === 'favorites') return true;
  }
  if (activeNavSlider === 'library') return true;
  if (
    (activeNavSlider === 'smartpages' || activeNavSlider === 'pages')
    && (selectorType === 'all' || selectorType === 'favorites')
  ) return true;
  return activeNavSlider === 'channelUsers';
};

function* startUpload(action) {
  try {
    const { activeNavSlider, selectorType, sortType, filter } = action.payload;
    if (selectorType === 'board') return;
    const existItem = yield select((state) => state.content.contentIds);
    const calcMap = yield select((state) => state.content.calcMap);
    const user = yield select((state) => state.user);
    if (!user.id) return;
    const selector = selectorType === 'shared to me' ? 'shared' : selectorType;
    if (!activeNavSlider) {
      yield put(
        actionCreator(ContentActionType.finishUpload, {
          content: {},
          isCleaning: true,
        }),
      );
      return;
    }
    if (typeof selectorType === 'string' && selectorType.includes('dynamic_')) {
      yield delay(300);
      const dynamicCollections = yield select(getDynamicCollections);
      const dynamicColID = selectorType.replace('dynamic_', '');
      const dynamicCollection = dynamicCollections[dynamicColID];
      const exFilter = dynamicCollection.libFilter || dynamicCollection.filter;
      const params = {
        search: dynamicCollection.libFilterSearch,
        tags: exFilter.tags,
        createDate: exFilter.createDate,
        fileType: exFilter.fileType,
        offset: existItem.length,
        limit: LIMIT_ITEMS_PAGINATION,
      };
      yield put(
        actionCreator(DynamicCollection.GetCounterSmartView, {
          id: dynamicColID,
        }),
      );
      const { data } = yield call(
        requestPagePreview('dynamicCollectionNewPagination'),
        [sortType],
        params,
      );
      yield put(
        actionCreator(ContentActionType.finishUploadCount, { content: data }),
      );
      return;
    }
    if (hsaPagination[activeNavSlider]) {
      const sortOption = sortOptionChooser(activeNavSlider, selectorType);
      const sortOptions = yield select(getAllSortOptions);
      const exSortType = sortOptions[sortOption] || sortType || Object.keys(sortedFunctions)[2];
      if (hasMergeItem(activeNavSlider, selectorType)) {
        const params = {
          offset: existItem.length,
          limit: LIMIT_ITEMS_PAGINATION,
          filter,
          calcMap,
        };
        const { data } = yield call(
          requestPagePreview(`${activeNavSlider}Pagination`),
          [selector, exSortType],
          params,
        );

        yield put(
          actionCreator(ContentActionType.finishUploadCount, {
            content: data.content,
            calcMap: data.calcMap,
          }),
        );
        return;
      }

      const params = {
        offset: existItem.length,
        limit: LIMIT_ITEMS_PAGINATION,
        filter,
      };

      const { data } = yield call(
        requestPagePreview(`${activeNavSlider}Pagination`),
        [selector, exSortType],
        params,
      );
      yield put(
        actionCreator(ContentActionType.finishUploadCount, { content: data }),
      );
      return;
    }
    if (activeNavSlider === 'dynamicCollectionNew') {
      const sortOption = sortOptionChooser(activeNavSlider, selectorType);
      const sortOptions = yield select(getAllSortOptions);
      const exSortType = sortOptions[sortOption] || sortType || Object.keys(sortedFunctions)[2];
      yield put(actionUpdateCounters({ dynamic: 0 }));
      yield delay(300);
      const exFilter = selector.libFilter || selector.filter;
      const params = {
        search: selector.libFilterSearch,
        tags: exFilter.tags,
        createDate: exFilter.createDate,
        fileType: exFilter.fileType,
        offset: selector.initial ? 0 : existItem.length,
        limit: LIMIT_ITEMS_PAGINATION,
      };
      const { data } = yield call(
        requestPagePreview(`${activeNavSlider}Pagination`),
        [exSortType],
        params,
      );
      yield put(
        actionCreator(ContentActionType.finishUploadCount, { content: data }),
      );
      return;
    }
    const { data } = yield call(requestPagePreview(activeNavSlider), [
      selector,
      sortType,
    ]);
    yield put(actionCreator(ContentActionType.finishUpload, { content: data }));
  } catch (err) {
    yield put(
      actionCreator(ContentActionType.finishUpload, {
        content: {},
        isCleaning: true,
      }),
    );
    yield showErrorMessage(err, action);
  }
}

function* playlistsUpload(action) {
  try {
    const { data } = yield call(requestUserPlaylists, [
      Object.keys(sortedFunctions)[2],
      'edit',
    ]);
    yield put(
      actionCreator(EditPlaylist.SaveRequestedUserPlaylists, { content: data }),
    );
  } catch (err) {
    yield put(
      actionCreator(ContentActionType.finishUpload, {
        content: {},
        isCleaning: true,
      }),
    );
    yield showErrorMessage(err, action);
  }
}

function* getPagesForLinkOption() {
  const { data } = yield call(requestPagePreview('pages'), [
    'all',
    'lastModified',
  ]);
  yield put(actionCreator(SupportAction.SaveTempComponents, { content: data }));
}

function* updateSpecificElement(action) {
  const { payload } = action;
  const { data } = yield call(requestSingleItem(payload.updatePageDataId));
  yield put(
    actionCreator(ContentActionType.updateSpecificElementR, {
      content: data,
      id: payload.updatePageDataId,
    }),
  );
}

export default function* contentSagas() {
  yield takeLatest(ContentActionType.startUpload, startUpload);
  yield takeLatest(
    ContentActionType.updateSpecificElementS,
    updateSpecificElement,
  );
  yield takeLatest(EditPlaylist.RequestUserPlaylists, playlistsUpload);
  yield takeLatest(SupportAction.GetPagesForLinkOption, getPagesForLinkOption);
}
