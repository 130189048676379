const mimeTypes = {
  imageLike: {
    'image/heic': true, // .bmp  Bitmap Image File
  },
  image: {
    'image/bmp': true, // .bmp  Bitmap Image File
    'image/cgm': true, // .cgm  Computer Graphics Metafile
    'image/g3fax': true, // .g3  G3 Fax Image
    'image/gif': true, // .gif  Graphics Interchange Format
    'image/ief': true, // .ief  Image Exchange Format
    'image/jpeg': true, // .jpeg, .jpg  JPEG Image
    'image/ktx': true, // .ktx  OpenGL Textures (KTX)
    'image/pjpeg': true, // .pjpeg  JPEG Image (Progressive)
    'image/png': true, // .png  Portable Network Graphics (PNG)
    'image/prs.btif': true, // .btif  BTIF
    'image/svg+xml': true, // .svg  Scalable Vector Graphics (SVG)
    'image/tiff': true, // .tiff  Tagged Image File Format
    'image/vnd.adobe.photoshop': true, // .psd  Photoshop Document
    'image/vnd.dece.graphic': true, // .uvi  DECE Graphic
    'image/vnd.djvu': true, // .djvu  DjVu
    'image/vnd.dvb.subtitle': true, // .sub  Close Captioning - Subtitle
    'image/vnd.dwg': true, // .dwg  DWG Drawing
    'image/vnd.dxf': true, // .dxf  AutoCAD DXF
    'image/vnd.fastbidsheet': true, //  .fbs  FastBid Sheet
    'image/vnd.fpx': true, // .fpx  FlashPix
    'image/vnd.fst': true, // .fst  FAST Search & Transfer ASA
    'image/vnd.fujixerox.edmics-mmr': true, // .mmr  EDMICS 2000
    'image/vnd.fujixerox.edmics-rlc': true, // .rlc  EDMICS 2000
    'image/vnd.ms-modi': true, // .mdi  Microsoft Document Imaging Format
    'image/vnd.net-fpx': true, // .npx  FlashPix
    'image/vnd.wap.wbmp': true, // .wbmp  WAP Bitamp (WBMP)
    'image/vnd.xiff': true, // .xif  eXtended Image File Format (XIFF)
    'image/webp': true, // .webp  WebP Image
    'image/x-citrix-jpeg': true, // .jpeg, .jpg  JPEG Image (Citrix client)
    'image/x-citrix-png': true, // .png  Portable Network Graphics (PNG) (Citrix client)
    'image/x-cmu-raster': true, // .ras  CMU Image
    'image/x-cmx': true, // .cmx  Corel Metafile Exchange (CMX)
    'image/x-freehand': true, // .fh  FreeHand MX
    'image/x-icon': true, // .ico  Icon Image
    'image/x-pcx': true, // .pcx  PCX Image
    'image/x-pict': true, // .pic  PICT Image
    'image/x-png': true, // .png  Portable Network Graphics (PNG) (x-token)
    'image/x-portable-anymap': true, // .pnm  Portable Anymap Image
    'image/x-portable-bitmap': true, // .pbm  Portable Bitmap Format
    'image/x-portable-graymap': true, // .pgm  Portable Graymap Format
    'image/x-portable-pixmap': true, // .ppm  Portable Pixmap Format
    'image/x-rgb': true, // .rgb  Silicon Graphics RGB Bitmap
    'image/x-xbitmap': true, // .xbm  X BitMap
    'image/x-xpixmap': true, // .xpm  X PixMap
    'image/x-xwindowdump': true, // .xwd  X Window Dump
    'image/vnd.microsoft.icon': true, // .ico Icon format
  },
  audio: {
    'audio/adpcm': true, // .adp  Adaptive differential pulse-code modulation
    'audio/basic': true, // .au  Sun Audio - Au file format
    'audio/midi': true, //  .mid  MIDI - Musical Instrument Digital Interface
    'audio/mp4': true, // .mp4a  MPEG-4 Audio
    'audio/wav': true, // .wav audio
    'audio/mpeg': true, // .mpga  MPEG Audio
    'audio/ogg': true, // .oga  Ogg Audio
    'audio/vnd.dece.audio': true, // .uva  DECE Audio
    'audio/vnd.digital-winds': true, // .eol  Digital Winds Music
    'audio/vnd.dra': true, // .dra  DRA Audio
    'audio/vnd.dts': true, // .dts  DTS Audio
    'audio/vnd.dts.hd': true, // .dtshd  DTS High Definition Audio
    'audio/vnd.lucent.voice': true, // .lvp  Lucent Voice
    'audio/vnd.ms-playready.media.pya': true, // .pya  Microsoft PlayReady Ecosystem
    'audio/vnd.nuera.ecelp4800': true, // .ecelp4800  Nuera ECELP 4800
    'audio/vnd.nuera.ecelp7470': true, // .ecelp7470  Nuera ECELP 7470
    'audio/vnd.nuera.ecelp9600': true, // .ecelp9600  Nuera ECELP 9600
    'audio/vnd.rip': true, // .rip  Hit'n'Mix
    'audio/webm': true, // .weba  Open Web Media Project - Audio
    'audio/x-aac': true, // .aac  Advanced Audio Coding (AAC)
    'audio/x-aiff': true, // .aif  Audio Interchange File Format
    'audio/x-mpegurl': true, // .m3u  M3U (Multimedia Playlist)
    'audio/x-ms-wax': true, // .wax  Microsoft Windows Media Audio Redirector
    'audio/x-ms-wma': true, // .wma  Microsoft Windows Media Audio
    'audio/x-pn-realaudio': true, // .ram  Real Audio Sound
    'audio/x-pn-realaudio-plugin': true, // .rmp  Real Audio Sound
    'audio/x-wav': true, // .wav  Waveform Audio File Format (WAV)
  },
  video: {
    'video/3gpp': true, // .3gp  3GP
    'video/3gpp2': true, // .3g2  3GP2
    'video/h261': true, // .h261  H.261
    'video/h263': true, // .h263  H.263
    'video/h264': true, // .h264  H.264
    'video/jpeg': true, // .jpgv  JPGVideo
    'video/jpm': true, // .jpm  JPEG 2000 Compound Image File Format
    'video/mj2': true, // .mj2  Motion JPEG 2000
    'video/mp4': true, // .mp4  MPEG-4 Video
    'video/mpeg': true, // .mpeg  MPEG Video
    'video/ogg': true, // .ogv  Ogg Video
    'video/quicktime': true, // .qt  Quicktime Video
    'video/vnd.dece.hd': true, // .uvh  DECE High Definition Video
    'video/vnd.dece.mobile': true, // .uvm  DECE Mobile Video
    'video/vnd.dece.pd': true, // .uvp  DECE PD Video
    'video/vnd.dece.sd': true, // .uvs  DECE SD Video
    'video/vnd.dece.video': true, // .uvv  DECE Video
    'video/vnd.fvt': true, // .fvt  FAST Search & Transfer ASA
    'video/vnd.mpegurl': true, // .mxu  MPEG Url
    'video/vnd.ms-playready.media.pyv': true, // .pyv  Microsoft PlayReady Ecosystem Video
    'video/vnd.uvvu.mp4': true, // .uvu  DECE MP4
    'video/vnd.vivo': true, // .viv  Vivo
    'video/webm': true, // .webm  Open Web Media Project - Video
    'video/x-f4v': true, // .f4v  Flash Video
    'video/x-fli': true, // .fli  FLI/FLC Animation Format
    'video/x-flv': true, // .flv  Flash Video
    'video/x-m4v': true, // .m4v  M4v
    'video/x-ms-asf': true, // .asf  Microsoft Advanced Systems Format (ASF)
    'video/x-ms-wm': true, // .wm  Microsoft Windows Media
    'video/x-ms-wmv': true, // .wmv  Microsoft Windows Media Video
    'video/x-ms-wmx': true, // .wmx  Microsoft Windows Media Audio/Video Playlist
    'video/x-ms-wvx': true, // .wvx  Microsoft Windows Media Video Playlist
    'video/x-msvideo': true, // .avi  Audio Video Interleave (AVI)
    'video/x-sgi-movie': true, // .movie  SGI Movie
  },
  text: {
    'text/calendar': true, // .ics  iCalendar
    'text/css': true, // .css  Cascading Style Sheets (CSS)
    'text/csv': true, // .csv  Comma-Seperated Values
    'text/html': true, // .html  HyperText Markup Language (HTML)
    'text/n3': true, // .n3  Notation3
    'text/plain': true, // .txt  Text File
    'text/plain-bas': true, // .par  BAS Partitur Format
    'text/prs.lines.tag': true, // .dsc  PRS Lines Tag
    'text/richtext': true, // .rtx  Rich Text Format (RTF)
    'text/sgml': true, // .sgml  Standard Generalized Markup Language (SGML)
    'text/tab-separated-values': true, // .tsv  Tab Seperated Values
    'text/troff': true, // .t  troff
    'text/turtle': true, // .ttl  Turtle (Terse RDF Triple Language)
    'text/uri-list': true, // .uri  URI Resolution Services
    'text/vnd.curl': true, // .curl  Curl - Applet
    'text/vnd.curl.dcurl': true, // .dcurl  Curl - Detached Applet
    'text/vnd.curl.mcurl': true, // .mcurl  Curl - Manifest File
    'text/vnd.curl.scurl': true, // .scurl  Curl - Source Code
    'text/vnd.fly': true, // .fly  mod_fly / fly.cgi
    'text/vnd.fmi.flexstor': true, // .flx  FLEXSTOR
    'text/vnd.graphviz': true, // .gv  Graphviz
    'text/vnd.in3d.3dml': true, // .3dml  In3D - 3DML
    'text/vnd.in3d.spot': true, // .spot  In3D - 3DML
    'text/vnd.sun.j2me.app-descriptor': true, // .jad  J2ME App Descriptor
    'text/vnd.wap.wml': true, // .wml  Wireless Markup Language (WML)
    'text/vnd.wap.wmlscript': true, // .wmls  Wireless Markup Language Script (WMLScript)
    'text/x-asm': true, // .s  Assembler Source File
    'text/x-c': true, // .c  C Source File
    'text/x-fortran': true, // .f  Fortran Source File
    'text/x-java-source': true, // .java  Java Source File
    'text/java': true, // .java  Java Source File
    'text/x-pascal': true, // .p  Pascal Source File
    'text/x-setext': true, // .etx  Setext
    'text/x-uuencode': true, // .uu  UUEncode
    'text/x-vcalendar': true, // .vcs  vCalendar
    'text/x-vcard': true, // .vcf  vCard
    'text/yaml': true, // .yaml  YAML Ain't Markup Language / Yet Another Markup Language
    'text/javascript': true, // .js  javascript Source File
    'text/markdown': true, // .md  Markdown format
  },
  application: {
    'application/andrew-inset': true, //  N/A  Andrew Toolkit
    'application/applixware': true, // .aw  Applixware
    'application/atom+xml': true, // .atom, .xml  Atom Syndication Format
    'application/atomcat+xml': true, // .atomcat  Atom Publishing Protocol
    'application/atomsvc+xml': true, // .atomsvc  Atom Publishing Protocol Service Document
    'application/ccxml+xml': true, // .ccxml  Voice Browser Call Control
    'application/cdmi-capability': true, // .cdmia  Cloud Data Management Interface (CDMI) - Capability
    'application/cdmi-container': true, //  .cdmic  Cloud Data Management Interface (CDMI) - Contaimer
    'application/cdmi-domain': true, // .cdmid  Cloud Data Management Interface (CDMI) - Domain
    'application/cdmi-object': true, // .cdmio  Cloud Data Management Interface (CDMI) - Object
    'application/cdmi-queue': true, //  .cdmiq  Cloud Data Management Interface (CDMI) - Queue
    'application/cu-seeme': true, //  .cu  CU-SeeMe
    'application/davmount+xml': true, // .davmount  Web Distributed Authoring and Versioning
    'application/dssc+der': true, // .dssc  Data Structure for the Security Suitability of Cryptographic Algorithms
    'application/dssc+xml': true, // .xdssc  Data Structure for the Security Suitability of Cryptographic Algorithms
    'application/ecmascript': true, // .es  ECMAScript
    'application/emma+xml': true, // .emma  Extensible MultiModal Annotation
    'application/epub+zip': true, // .epub  Electronic Publication
    'application/exi': true, // .exi  Efficient XML Interchange
    'application/font-tdpfr': true, // .pfr  Portable Font Resource
    'application/hyperstudio': true, // .stk  Hyperstudio
    'application/ipfix': true, // .ipfix  Internet Protocol Flow Information Export
    'application/java-archive': true, // .jar  Java Archive
    'application/java-serialized-object': true, //  .ser  Java Serialized Object
    'application/java-vm': true, // .class  Java Bytecode File
    'application/javascript': true, // .js  JavaScript
    'application/json': true, //  .json  JavaScript Object Notation (JSON)
    'application/mac-binhex40': true, // .hqx  Macintosh BinHex 4.0
    'application/mac-compactpro': true, // .cpt  Compact Pro
    'application/mads+xml': true, //  .mads  Metadata Authority Description Schema
    'application/marc': true, // .mrc  MARC Formats
    'application/marcxml+xml': true, // .mrcx  MARC21 XML Schema
    'application/mathematica': true, // .ma  Mathematica Notebooks
    'application/mathml+xml': true, // .mathml  Mathematical Markup Language
    'application/mbox': true, // .mbox  Mbox database files
    'application/mediaservercontrol+xml': true, // .mscml  Media Server Control Markup Language
    'application/metalink4+xml': true, // .meta4  Metalink
    'application/mets+xml': true, // .mets  Metadata Encoding and Transmission Standard
    'application/mods+xml': true, // .mods  Metadata Object Description Schema
    'application/mp21': true, // .m21  MPEG-21
    'application/mp4': true, // .mp4  MPEG4
    'application/msword': true, // .doc  Microsoft Word
    'application/mxf': true, // .mxf  Material Exchange Format
    'application/octet-stream': true, // .bin  Binary Data
    'application/oda': true, // .oda  Office Document Architecture
    'application/oebps-package+xml': true, //  .opf  Open eBook Publication Structure
    'application/ogg': true, // .ogx  Ogg
    'application/onenote': true, // .onetoc  Microsoft OneNote
    'application/patch-ops-error+xml': true, // .xer  XML Patch Framework
    'application/pdf': true, // .pdf  Adobe Portable Document Format
    'application/pgp-encrypted': true, // .pgp  Pretty Good Privacy
    'application/pgp-signature': true, // .pgp  Pretty Good Privacy - Signature
    'application/pics-rules': true, // .prf  PICSRules
    'application/pkcs10': true, // .p10  PKCS #10 - Certification Request Standard
    'application/pkcs7-mime': true, // .p7m  PKCS #7 - Cryptographic Message Syntax Standard
    'application/pkcs7-signature': true, // .p7s  PKCS #7 - Cryptographic Message Syntax Standard
    'application/pkcs8': true, // .p8  PKCS #8 - Private-Key Information Syntax Standard
    'application/pkix-attr-cert': true, // .ac  Attribute Certificate
    'application/pkix-cert': true, // .cer  Internet Public Key Infrastructure - Certificate
    'application/pkix-crl': true, // .crl  Internet Public Key Infrastructure - Certificate Revocation Lists
    'application/pkix-pkipath': true, // .pkipath  Internet Public Key Infrastructure - Certification Path
    'application/pkixcmp': true, // .pki  Internet Public Key Infrastructure - Certificate Management Protocole
    'application/pls+xml': true, // .pls  Pronunciation Lexicon Specification
    'application/postscript': true, // .ai  PostScript
    'application/prs.cww': true, // .cww  CU-Writer
    'application/pskc+xml': true, // .pskcxml  Portable Symmetric Key Container
    'application/rdf+xml': true, // .rdf  Resource Description Framework
    'application/reginfo+xml': true, // .rif  IMS Networks
    'application/relax-ng-compact-syntax': true, // .rnc  Relax NG Compact Syntax
    'application/resource-lists-diff+xml': true, // .rld  XML Resource Lists Diff
    'application/resource-lists+xml': true, // .rl  XML Resource Lists
    'application/rls-services+xml': true, // .rs  XML Resource Lists
    'application/rsd+xml': true, // .rsd  Really Simple Discovery
    'application/rss+xml': true, // .rss, .xml  RSS - Really Simple Syndication
    'application/rtf': true, // .rtf  Rich Text Format
    'application/sbml+xml': true, // .sbml  Systems Biology Markup Language
    'application/scvp-cv-request': true, // .scq
    // Server-Based Certificate Validation Protocol - Validation Request
    'application/scvp-cv-response': true, // .scs
    // Server-Based Certificate Validation Protocol - Validation Response
    'application/scvp-vp-request': true, // .spq
    // Server-Based Certificate Validation Protocol - Validation Policies - Request
    'application/scvp-vp-response': true, // .spp
    // Server-Based Certificate Validation Protocol - Validation Policies - Response
    'application/sdp': true, // .sdp  Session Description Protocol
    'application/set-payment-initiation': true, // .setpay  Secure Electronic Transaction - Payment
    'application/set-registration-initiation': true, // .setreg  Secure Electronic Transaction - Registration
    'application/shf+xml': true, // .shf  S Hexdump Format
    'application/smil+xml': true, // .smi  Synchronized Multimedia Integration Language
    'application/sparql-query': true, // .rq  SPARQL - Query
    'application/sparql-results+xml': true, // .srx  SPARQL - Results
    'application/srgs': true, // .gram  Speech Recognition Grammar Specification
    'application/srgs+xml': true, // .grxml  Speech Recognition Grammar Specification - XML
    'application/sru+xml': true, // .sru  Search/Retrieve via URL Response Format
    'application/ssml+xml': true, // .ssml  Speech Synthesis Markup Language
    'application/tei+xml': true, // .tei  Text Encoding and Interchange
    'application/thraud+xml': true, // .tfi  Sharing Transaction Fraud Data
    'application/timestamped-data': true, // .tsd  Time Stamped Data Envelope
    'application/vnd.3gpp.pic-bw-large': true, // .plb  3rd Generation Partnership Project - Pic Large
    'application/vnd.3gpp.pic-bw-small': true, // .psb  3rd Generation Partnership Project - Pic Small
    'application/vnd.3gpp.pic-bw-var': true, // .pvb  3rd Generation Partnership Project - Pic Var
    'application/vnd.3gpp2.tcap': true, // .tcap
    // 3rd Generation Partnership Project - Transaction Capabilities Application Part
    'application/vnd.3m.post-it-notes': true, // .pwn  3M Post It Notes
    'application/vnd.accpac.simply.aso': true, // .aso  Simply Accounting
    'application/vnd.accpac.simply.imp': true, // .imp  Simply Accounting - Data Import
    'application/vnd.acucobol': true, // .acu  ACU Cobol
    'application/vnd.acucorp': true, // .atc  ACU Cobol
    'application/vnd.adobe.air-application-installer-package+zip': true, // .air  Adobe AIR Application
    'application/vnd.adobe.fxp': true, // .fxp  Adobe Flex Project
    'application/vnd.adobe.xdp+xml': true, // .xdp  Adobe XML Data Package
    'application/vnd.adobe.xfdf': true, // .xfdf  Adobe XML Forms Data Format
    'application/vnd.ahead.space': true, // .ahead  Ahead AIR Application
    'application/vnd.airzip.filesecure.azf': true, // .azf  AirZip FileSECURE
    'application/vnd.airzip.filesecure.azs': true, // .azs  AirZip FileSECURE
    'application/vnd.amazon.ebook': true, // .azw  Amazon Kindle eBook format
    'application/vnd.americandynamics.acc': true, // .acc  Active Content Compression
    'application/vnd.amiga.ami': true, // .ami  AmigaDE
    'application/vnd.android.package-archive': true, // .apk  Android Package Archive
    'application/vnd.anser-web-certificate-issue-initiation': true, // .cii
    // ANSER-WEB Terminal Client - Certificate Issue
    'application/vnd.anser-web-funds-transfer-initiation': true, // .fti  ANSER-WEB Terminal Client - Web Funds Transfer
    'application/vnd.antix.game-component': true, // .atx  Antix Game Player
    'application/vnd.apple.installer+xml': true, // .mpkg  Apple Installer Package
    'application/vnd.apple.mpegurl': true, // .m3u8  Multimedia Playlist Unicode
    'application/vnd.aristanetworks.swi': true, // .swi  Arista Networks Software Image
    'application/vnd.audiograph': true, // .aep  Audiograph
    'application/vnd.blueice.multipass': true, // .mpm  Blueice Research Multipass
    'application/vnd.bmi': true, // .bmi  BMI Drawing Data Interchange
    'application/vnd.businessobjects': true, // .rep  BusinessObjects
    'application/vnd.chemdraw+xml': true, // .cdxml  CambridgeSoft Chem Draw
    'application/vnd.chipnuts.karaoke-mmd': true, // .mmd  Karaoke on Chipnuts Chipsets
    'application/vnd.cinderella': true, //  .cdy  Interactive Geometry Software Cinderella
    'application/vnd.claymore': true, // .cla  Claymore Data Files
    'application/vnd.cloanto.rp9': true, // .rp9  RetroPlatform Player
    'application/vnd.clonk.c4group': true, // .c4g  Clonk Game
    'application/vnd.cluetrust.cartomobile-config': true, // .c11amc  ClueTrust CartoMobile - Config
    'application/vnd.cluetrust.cartomobile-config-pkg': true, // .c11amz  ClueTrust CartoMobile - Config Package
    'application/vnd.commonspace': true, // .csp  Sixth Floor Media - CommonSpace
    'application/vnd.contact.cmsg': true, // .cdbcmsg  CIM Database
    'application/vnd.cosmocaller': true, // .cmc  CosmoCaller
    'application/vnd.crick.clicker': true, // .clkx  CrickSoftware - Clicker
    'application/vnd.crick.clicker.keyboard': true, // .clkk  CrickSoftware - Clicker - Keyboard
    'application/vnd.crick.clicker.palette': true, // .clkp  CrickSoftware - Clicker - Palette
    'application/vnd.crick.clicker.template': true, // .clkt  CrickSoftware - Clicker - Template
    'application/vnd.crick.clicker.wordbank': true, // .clkw  CrickSoftware - Clicker - Wordbank
    'application/vnd.criticaltools.wbs+xml': true, // .wbs  Critical Tools - PERT Chart EXPERT
    'application/vnd.ctc-posml': true, // .pml  PosML
    'application/vnd.cups-ppd': true, //  .ppd  Adobe PostScript Printer Description File Format
    'application/vnd.curl.car': true, // .car  CURL Applet
    'application/vnd.curl.pcurl': true, // .pcurl  CURL Applet
    'application/vnd.data-vision.rdz': true, // .rdz  RemoteDocs R-Viewer
    'application/vnd.denovo.fcselayout-link': true, // .fe_launch  FCS Express Layout Link
    'application/vnd.dna': true, // .dna  New Moon Liftoff/DNA
    'application/vnd.dolby.mlp': true, // .mlp  Dolby Meridian Lossless Packing
    'application/vnd.dpgraph': true, // .dpg  DPGraph
    'application/vnd.dreamfactory': true, // .dfac  DreamFactory
    'application/vnd.dvb.ait': true, // .ait  Digital Video Broadcasting
    'application/vnd.dvb.service': true, // .svc  Digital Video Broadcasting
    'application/vnd.dynageo': true, // .geo  DynaGeo
    'application/vnd.ecowin.chart': true, // .mag  EcoWin Chart
    'application/vnd.enliven': true, // .nml  Enliven Viewer
    'application/vnd.epson.esf': true, // .esf  QUASS Stream Player
    'application/vnd.epson.msf': true, // .msf  QUASS Stream Player
    'application/vnd.epson.quickanime': true, //  .qam  QuickAnime Player
    'application/vnd.epson.salt': true, // .slt  SimpleAnimeLite Player
    'application/vnd.epson.ssf': true, // .ssf  QUASS Stream Player
    'application/vnd.eszigno3+xml': true, // .es3  MICROSEC e-Szign¢
    'application/vnd.ezpix-album': true, // .ez2  EZPix Secure Photo Album
    'application/vnd.ezpix-package': true, // .ez3  EZPix Secure Photo Album
    'application/vnd.fdf': true, // .fdf  Forms Data Format
    'application/vnd.fdsn.seed': true, //  .seed  Digital Siesmograph Networks - SEED Datafiles
    'application/vnd.flographit': true, // .gph  NpGraphIt
    'application/vnd.fluxtime.clip': true, // .ftc  FluxTime Clip
    'application/vnd.framemaker': true, // .fm  FrameMaker Normal Format
    'application/vnd.frogans.fnc': true, // .fnc  Frogans Player
    'application/vnd.frogans.ltf': true, // .ltf  Frogans Player
    'application/vnd.fsc.weblaunch': true, // .fsc  Friendly Software Corporation
    'application/vnd.fujitsu.oasys': true, // .oas  Fujitsu Oasys
    'application/vnd.fujitsu.oasys2': true, //  .oa2  Fujitsu Oasys
    'application/vnd.fujitsu.oasys3': true, // .oa3  Fujitsu Oasys
    'application/vnd.fujitsu.oasysgp': true, // .fg5  Fujitsu Oasys
    'application/vnd.fujitsu.oasysprs': true, // .bh2  Fujitsu Oasys
    'application/vnd.fujixerox.ddd': true, // .ddd  Fujitsu - Xerox 2D CAD Data
    'application/vnd.fujixerox.docuworks': true, // .xdw  Fujitsu - Xerox DocuWorks
    'application/vnd.fujixerox.docuworks.binder': true, // .xbd  Fujitsu - Xerox DocuWorks Binder
    'application/vnd.fuzzysheet': true, // .fzs  FuzzySheet
    'application/vnd.genomatix.tuxedo': true, //  .txd  Genomatix Tuxedo Framework
    'application/vnd.geogebra.file': true, // .ggb  GeoGebra
    'application/vnd.geogebra.tool': true, // .ggt  GeoGebra
    'application/vnd.geometry-explorer': true, // .gex  GeoMetry Explorer
    'application/vnd.geonext': true, // .gxt  GEONExT and JSXGraph
    'application/vnd.geoplan': true, // .g2w  GeoplanW
    'application/vnd.geospace': true, // .g3w  GeospacW
    'application/vnd.gmx': true, // .gmx  GameMaker ActiveX
    'application/vnd.google-earth.kml+xml': true, //  .kml  Google Earth - KML
    'application/vnd.google-earth.kmz': true, // .kmz  Google Earth - Zipped KML
    'application/vnd.grafeq': true, // .gqf  GrafEq
    'application/vnd.groove-account': true, // .gac  Groove - Account
    'application/vnd.groove-help': true, // .ghf  Groove - Help
    'application/vnd.groove-identity-message': true, // .gim  Groove - Identity Message
    'application/vnd.groove-injector': true, // .grv  Groove - Injector
    'application/vnd.groove-tool-message': true, // .gtm  Groove - Tool Message
    'application/vnd.groove-tool-template': true, // .tpl  Groove - Tool Template
    'application/vnd.groove-vcard': true, // .vcg  Groove - Vcard
    'application/vnd.hal+xml': true, // .hal  Hypertext Application Language
    'application/vnd.handheld-entertainment+xml': true, // .zmm  ZVUE Media Manager
    'application/vnd.hbci': true, // .hbci  Homebanking Computer Interface (HBCI)
    'application/vnd.hhe.lesson-player': true, // .les  Archipelago Lesson Player
    'application/vnd.hp-hpgl': true, // .hpgl  HP-GL/2 and HP RTL
    'application/vnd.hp-hpid': true, // .hpid  Hewlett Packard Instant Delivery
    'application/vnd.hp-hps': true, // .hps  Hewlett-Packard's WebPrintSmart
    'application/vnd.hp-jlyt': true, // .jlt  HP Indigo Digital Press - Job Layout Languate
    'application/vnd.hp-pcl': true, // .pcl  HP Printer Command Language
    'application/vnd.hp-pclxl': true, // .pclxl  PCL 6 Enhanced (Formely PCL XL)
    'application/vnd.hydrostatix.sof-data': true, //  .sfd-hdstx  Hydrostatix Master Suite
    'application/vnd.hzn-3d-crossword': true, //  .x3d  3D Crossword Plugin
    'application/vnd.ibm.minipay': true, // .mpy  MiniPay
    'application/vnd.ibm.modcap': true, //  .afp  MO:DCA-P
    'application/vnd.ibm.rights-management': true, // .irm  IBM DB2 Rights Manager
    'application/vnd.ibm.secure-container': true, //  .sc  IBM Electronic Media Management System - Secure Container
    'application/vnd.iccprofile': true, //  .icc  ICC profile
    'application/vnd.igloader': true, //  .igl  igLoader
    'application/vnd.immervision-ivp': true, // .ivp  ImmerVision PURE Players
    'application/vnd.immervision-ivu': true, // .ivu  ImmerVision PURE Players
    'application/vnd.insors.igm': true, // .igm  IOCOM Visimeet
    'application/vnd.intercon.formnet': true, // .xpw  Intercon FormNet
    'application/vnd.intergeo': true, //  .i2g  Interactive Geometry Software
    'application/vnd.intu.qbo': true, //  .qbo  Open Financial Exchange
    'application/vnd.intu.qfx': true, // .qfx  Quicken
    'application/vnd.ipunplugged.rcprofile': true, // .rcprofile  IP Unplugged Roaming Client
    'application/vnd.irepository.package+xml': true, // .irp  iRepository / Lucidoc Editor
    'application/vnd.is-xpr': true, // .xpr  Express by Infoseek
    'application/vnd.isac.fcs': true, // .fcs  International Society for Advancement of Cytometry
    'application/vnd.jam': true, // .jam  Lightspeed Audio Lab
    'application/vnd.jcp.javame.midlet-rms': true, // .rms  Mobile Information Device Profile
    'application/vnd.jisp': true, //  .jisp  RhymBox
    'application/vnd.joost.joda-archive': true, // .joda  Joda Archive
    'application/vnd.kahootz': true, // .ktz  Kahootz
    'application/vnd.kde.karbon': true, // .karbon  KDE KOffice Office Suite - Karbon
    'application/vnd.kde.kchart': true, // .chrt  KDE KOffice Office Suite - KChart
    'application/vnd.kde.kformula': true, // .kfo  KDE KOffice Office Suite - Kformula
    'application/vnd.kde.kivio': true, // .flw  KDE KOffice Office Suite - Kivio
    'application/vnd.kde.kontour': true, // .kon  KDE KOffice Office Suite - Kontour
    'application/vnd.kde.kpresenter': true, // .kpr  KDE KOffice Office Suite - Kpresenter
    'application/vnd.kde.kspread': true, // .ksp  KDE KOffice Office Suite - Kspread
    'application/vnd.kde.kword': true, //  .kwd  KDE KOffice Office Suite - Kword
    'application/vnd.kenameaapp': true, // .htke  Kenamea App
    'application/vnd.kidspiration': true, // .kia  Kidspiration
    'application/vnd.kinar': true, // .kne  Kinar Applications
    'application/vnd.koan': true, // .skp  SSEYO Koan Play File
    'application/vnd.kodak-descriptor': true, // .sse  Kodak Storyshare
    'application/vnd.las.las+xml': true, // .lasxml  Laser App Enterprise
    'application/vnd.llamagraphics.life-balance.desktop': true, // .lbd  Life Balance - Desktop Edition
    'application/vnd.llamagraphics.life-balance.exchange+xml': true, // .lbe  Life Balance - Exchange Format
    'application/vnd.lotus-1-2-3': true, // 0,123  Lotus 1-2-3
    'application/vnd.lotus-approach': true, // .apr  Lotus Approach
    'application/vnd.lotus-freelance': true, // .pre  Lotus Freelance
    'application/vnd.lotus-notes': true, // .nsf  Lotus Notes
    'application/vnd.lotus-organizer': true, // .org  Lotus Organizer
    'application/vnd.lotus-screencam': true, //  .scm  Lotus Screencam
    'application/vnd.lotus-wordpro': true, //  .lwp  Lotus Wordpro
    'application/vnd.macports.portpkg': true, // .portpkg': true, // MacPorts Port System
    'application/vnd.mcd': true, // .mcd': true, // Micro CADAM Helix D&D
    'application/vnd.medcalcdata': true, // .mc1': true, // MedCalc
    'application/vnd.mediastation.cdkey': true, // .cdkey': true, // MediaRemote
    'application/vnd.mfer': true, // .mwf': true, // Medical Waveform Encoding Format
    'application/vnd.mfmp': true, // .mfm': true, // Melody Format for Mobile Platform
    'application/vnd.micrografx.flo': true, // .flo': true, // Micrografx
    'application/vnd.micrografx.igx': true, // .igx': true, // Micrografx iGrafx Professional
    'application/vnd.mif': true, // .mif': true, // FrameMaker Interchange Format
    'application/vnd.mobius.daf': true, // .daf': true, // Mobius Management Systems - UniversalArchive
    'application/vnd.mobius.dis': true, // .dis': true, // Mobius Management Systems - Distribution Database
    'application/vnd.mobius.mbk': true, // .mbk': true, // Mobius Management Systems - Basket file
    'application/vnd.mobius.mqy': true, // .mqy': true, // Mobius Management Systems - Query File
    'application/vnd.mobius.msl': true, // .msl': true, // Mobius Management Systems - Script Language
    'application/vnd.mobius.plc': true, // .plc': true, // Mobius Management Systems - Policy Definition Language File
    'application/vnd.mobius.txf': true, // .txf': true, // Mobius Management Systems - Topic Index File
    'application/vnd.mophun.application': true, // .mpn': true, // Mophun VM
    'application/vnd.mophun.certificate': true, // .mpc': true, // Mophun Certificate
    'application/vnd.mozilla.xul+xml': true, // .xul': true, // XUL - XML User Interface Language
    'application/vnd.ms-artgalry': true, // .cil': true, // Microsoft Artgalry
    'application/vnd.ms-cab-compressed': true, // .cab': true, // Microsoft Cabinet File
    'application/vnd.ms-excel': true, // .xls': true, // Microsoft Excel
    'application/vnd.ms-excel.addin.macroenabled.12': true, // .xlam': true, // Microsoft Excel - Add-In File
    'application/vnd.ms-excel.sheet.binary.macroenabled.12': true, // .xlsb': true, // Microsoft Excel - Binary Workbook
    'application/vnd.ms-excel.sheet.macroenabled.12': true, // .xlsm': true, // Microsoft Excel - Macro-Enabled Workbook
    'application/vnd.ms-excel.template.macroenabled.12': true, // .xltm': true,
    // Microsoft Excel - Macro-Enabled Template File
    'application/vnd.ms-fontobject': true, // .eot': true, // Microsoft Embedded OpenType
    'application/vnd.ms-htmlhelp': true, // .chm': true, // Microsoft Html Help File
    'application/vnd.ms-ims': true, // .ims': true, // Microsoft Class Server
    'application/vnd.ms-lrm': true, // .lrm': true, // Microsoft Learning Resource Module
    'application/vnd.ms-officetheme': true, // .thmx': true, // Microsoft Office System Release Theme
    'application/vnd.ms-pki.seccat': true, // .cat': true, // Microsoft Trust UI Provider - Security Catalog
    'application/vnd.ms-pki.stl': true, // .stl': true, // Microsoft Trust UI Provider - Certificate Trust Link
    'application/vnd.ms-powerpoint': true, // .ppt': true, // Microsoft PowerPoint
    'application/vnd.ms-powerpoint.addin.macroenabled.12': true, // .ppam': true,
    // Microsoft PowerPoint - Add-in file
    'application/vnd.ms-powerpoint.presentation.macroenabled.12': true, // .pptm': true,
    // Microsoft PowerPoint - Macro-Enabled Presentation File
    'application/vnd.ms-powerpoint.slide.macroenabled.12': true, // .sldm': true,
    // Microsoft PowerPoint - Macro-Enabled Open XML Slide
    'application/vnd.ms-powerpoint.slideshow.macroenabled.12': true, // .ppsm': true,
    // Microsoft PowerPoint - Macro-Enabled Slide Show File
    'application/vnd.ms-powerpoint.template.macroenabled.12': true, // .potm': true,
    // Microsoft PowerPoint - Macro-Enabled Template File
    'application/vnd.ms-project': true, // .mpp': true, // Microsoft Project
    'application/vnd.ms-word.document.macroenabled.12': true, // .docm': true,
    // Microsoft Word - Macro-Enabled Document
    'application/vnd.ms-word.template.macroenabled.12': true, // .dotm': true,
    // Microsoft Word - Macro-Enabled Template
    'application/vnd.ms-works': true, // .wps': true, // Microsoft Works
    'application/vnd.ms-wpl': true, // .wpl': true, // Microsoft Windows Media Player Playlist
    'application/vnd.ms-xpsdocument': true, // .xps': true, // Microsoft XML Paper Specification
    'application/vnd.mseqv: true, //.mseq': true, // 3GPP MSEQ File
    'application/vnd.musician': true, // .mus': true,
    // MUsical Score Interpreted Code Invented for the ASCII designation of Notation
    'application/vnd.muvee.style': true, // .msty': true, // Muvee Automatic Video Editing
    'application/vnd.neurolanguage.nlu': true, // .nlu': true, // neuroLanguage
    'application/vnd.noblenet-directory': true, // .nnd': true, // NobleNet Directory
    'application/vnd.noblenet-sealer': true, // .nns': true, // NobleNet Sealer
    'application/vnd.noblenet-web': true, // .nnw': true, // NobleNet Web
    'application/vnd.nokia.n-gage.data': true, // .ngdat': true, // N-Gage Game Data
    'application/vnd.nokia.n-gage.symbian.install': true, // .n-gage': true, // N-Gage Game Installer
    'application/vnd.nokia.radio-preset': true, // .rpst': true, // Nokia Radio Application - Preset
    'application/vnd.nokia.radio-presets': true, // .rpss': true, // Nokia Radio Application - Preset
    'application/vnd.novadigm.edm': true, // .edm': true, // Novadigm's RADIA and EDM products
    'application/vnd.novadigm.edx': true, // .edx': true, // Novadigm's RADIA and EDM products
    'application/vnd.novadigm.ext': true, // .ext': true, // Novadigm's RADIA and EDM products
    'application/vnd.oasis.opendocument.chart': true, // .odc': true, // OpenDocument Chart
    'application/vnd.oasis.opendocument.chart-template': true, // .otc': true, // OpenDocument Chart Template
    'application/vnd.oasis.opendocument.database': true, // .odb': true, // OpenDocument Database
    'application/vnd.oasis.opendocument.formula': true, // .odf': true, // OpenDocument Formula
    'application/vnd.oasis.opendocument.formula-template': true, // .odft': true, // OpenDocument Formula Template
    'application/vnd.oasis.opendocument.graphics': true, // .odg': true, // OpenDocument Graphics
    'application/vnd.oasis.opendocument.graphics-template': true, // .otg': true, // OpenDocument Graphics Template
    'application/vnd.oasis.opendocument.image': true, // .odi': true, // OpenDocument Image
    'application/vnd.oasis.opendocument.image-template': true, // .oti': true, // OpenDocument Image Template
    'application/vnd.oasis.opendocument.presentation': true, // .odp': true, // OpenDocument Presentation
    'application/vnd.oasis.opendocument.presentation-template': true, // .otp': true,
    // OpenDocument Presentation Template
    'application/vnd.oasis.opendocument.spreadsheet': true, // .ods': true, // OpenDocument Spreadsheet
    'application/vnd.oasis.opendocument.spreadsheet-template': true, // .ots': true,
    // OpenDocument Spreadsheet Template
    'application/vnd.oasis.opendocument.text': true, // .odt': true, // OpenDocument Text
    'application/vnd.oasis.opendocument.text-master': true, // .odm': true, // OpenDocument Text Master
    'application/vnd.oasis.opendocument.text-template': true, // .ott': true, // OpenDocument Text Template
    'application/vnd.oasis.opendocument.text-web': true, // .oth': true, // Open Document Text Web
    'application/vnd.olpc-sugar': true, // .xo': true, // Sugar Linux Application Bundle
    'application/vnd.oma.dd2+xml': true, // .dd2': true, // OMA Download Agents
    'application/vnd.openofficeorg.extension': true, // .oxt': true, // Open Office Extension
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': true, // .pptx': true,
    // Microsoft Office - OOXML - Presentation
    'application/vnd.openxmlformats-officedocument.presentationml.slide': true, // .sldx': true,
    // Microsoft Office - OOXML - Presentation (Slide)
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': true, // .ppsx': true,
    // Microsoft Office - OOXML - Presentation (Slideshow)
    'application/vnd.openxmlformats-officedocument.presentationml.template': true, // .potx': true,
    // Microsoft Office - OOXML - Presentation Template
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': true, // .xlsx': true,
    // Microsoft Office - OOXML - Spreadsheet
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template': true, // .xltx': true,
    // Microsoft Office - OOXML - Spreadsheet Template
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': true, // .docx': true,
    // Microsoft Office - OOXML - Word Document
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': true, // .dotx': true,
    // Microsoft Office - OOXML - Word Document Template
    'application/vnd.osgeo.mapguide.package': true, // .mgp': true, // MapGuide DBXML
    'application/vnd.osgi.dp': true, // .dp': true, // OSGi Deployment Package
    'application/vnd.palm': true, // .pdb': true, // PalmOS Data
    'application/vnd.pawaafile': true, // .paw': true, // PawaaFILE
    'application/vnd.pg.format': true, // .str': true, // Proprietary P&G Standard Reporting System
    'application/vnd.pg.osasli': true, // .ei6': true, // Proprietary P&G Standard Reporting System
    'application/vnd.picsel': true, // .efif': true, // Pcsel eFIF File
    'application/vnd.pmi.widget': true, // .wg': true, // Qualcomm's Plaza Mobile Internet
    'application/vnd.pocketlearn': true, // .plf': true, // PocketLearn Viewers
    'application/vnd.powerbuilder6': true, // .pbd': true, // PowerBuilder
    'application/vnd.previewsystems.box': true, // .box': true, // Preview Systems ZipLock/VBox
    'application/vnd.proteus.magazine': true, // .mgz': true, // EFI Proteus
    'application/vnd.publishare-delta-tree': true, // .qps': true, // PubliShare Objects
    'application/vnd.pvi.ptid1': true, // .ptid': true, // Princeton Video Image
    'application/vnd.quark.quarkxpress': true, // .qxd': true, // QuarkXpress
    'application/vnd.realvnc.bed': true, // .bed': true, // RealVNC
    'application/vnd.recordare.musicxml': true, // .mxl': true, // Recordare Applications
    'application/vnd.recordare.musicxml+xml': true, // .musicxml': true, // Recordare Applications
    'application/vnd.rig.cryptonote': true, // .cryptonote': true, // CryptoNote
    'application/vnd.rim.cod': true, // .cod': true, // Blackberry COD File
    'application/vnd.rn-realmedia': true, // .rm': true, // RealMedia
    'application/vnd.route66.link66+xml': true, // .link66': true, // ROUTE 66 Location Based Services
    'application/vnd.sailingtracker.track': true, // .st': true, // SailingTracker
    'application/vnd.seemail': true, // .see': true, // SeeMail
    'application/vnd.sema': true, // .sema': true, // Secured eMail
    'application/vnd.semd': true, // .semd': true, // Secured eMail
    'application/vnd.semf': true, // .semf': true, // Secured eMail
    'application/vnd.shana.informed.formdata': true, // .ifm': true, // Shana Informed Filler
    'application/vnd.shana.informed.formtemplate': true, // .itp': true, // Shana Informed Filler
    'application/vnd.shana.informed.interchange': true, // .iif': true, // Shana Informed Filler
    'application/vnd.shana.informed.package': true, // Shana Informed Filler
    'application/vnd.simtech-mindmapper': true, // SimTech MindMapper
    'application/vnd.smaf': true, // SMAF File
    'application/vnd.smart.teacher': true, // SMART Technologies Apps
    'application/vnd.solent.sdkm+xml': true, // SudokuMagic
    'application/vnd.spotfire.dxp': true, // TIBCO Spotfire
    'application/vnd.spotfire.sfs': true, // TIBCO Spotfire
    'application/vnd.stardivision.calc': true, // StarOffice - Calc
    'application/vnd.stardivision.draw': true, // StarOffice - Draw
    'application/vnd.stardivision.impress': true, // StarOffice - Impress
    'application/vnd.stardivision.math': true, // StarOffice - Math
    'application/vnd.stardivision.writer': true, // StarOffice - Writer
    'application/vnd.stardivision.writer-global': true, // StarOffice - Writer (Global)
    'application/vnd.stepmania.stepchart': true, // StepMania
    'application/vnd.sun.xml.calc': true, // OpenOffice - Calc (Spreadsheet)
    'application/vnd.sun.xml.calc.template': true, // OpenOffice - Calc Template (Spreadsheet)
    'application/vnd.sun.xml.draw: true': true, // OpenOffice - Draw (Graphics)
    'application/vnd.sun.xml.draw.template': true, // OpenOffice - Draw Template (Graphics)
    'application/vnd.sun.xml.impress: true': true, // OpenOffice - Impress (Presentation)
    'application/vnd.sun.xml.impress.template': true, // OpenOffice - Impress Template (Presentation)
    'application/vnd.sun.xml.math': true, // OpenOffice - Math (Formula)
    'application/vnd.sun.xml.writer': true, // OpenOffice - Writer (Text - HTML)
    'application/vnd.sun.xml.writer.global': true, // OpenOffice - Writer (Text - HTML)
    'application/vnd.sun.xml.writer.template': true, // OpenOffice - Writer Template (Text - HTML)
    'application/vnd.sus-calendar: true, //.sus': true, // ScheduleUs
    'application/vnd.svd: true, //.svd': true, // SourceView Document
    'application/vnd.symbian.install': true, // .sis': true, //Symbian Install Package
    'application/vnd.syncml.dm+wbxml': true, // .bdm': true, //SyncML - Device Management
    'application/vnd.syncml.dm+xml': true, // .xdm': true, //SyncML - Device Management
    'application/vnd.syncml+xml': true, // .xsm': true, //SyncML
    'application/vnd.tao.intent-module-archive': true, // .tao': true, //Tao Intent
    'application/vnd.tmobile-livetv': true, // .tmo': true, //MobileTV
    'application/vnd.trid.tpt': true, // .tpt': true, //TRI Systems Config
    'application/vnd.triscape.mxs': true, // .mxs': true, //Triscape Map Explorer
    'application/vnd.trueapp': true, // .tra': true, //True BASIC
    'application/vnd.ufdl': true, // .ufd': true, //Universal Forms Description Language
    'application/vnd.uiq.theme': true, // .utz': true, //User Interface Quartz - Theme (Symbian)
    'application/vnd.umajin': true, // .umj': true, //UMAJIN
    'application/vnd.unity': true, // .unityweb': true, //Unity 3d
    'application/vnd.uoml+xml': true, // .uoml': true, //Unique Object Markup Language
    'application/vnd.vcx': true, // .vcx': true, //VirtualCatalog
    'application/vnd.visio': true, // .vsd': true, //Microsoft Visio
    'application/vnd.visio2013': true, // .vsdx': true, //Microsoft Visio 2013
    'application/vnd.visionary': true, // .vis': true, //Visionary
    'application/vnd.vsf': true, // .vsf': true, //Viewport+
    'application/vnd.wap.wbxml': true, // .wbxml': true, //WAP Binary XML (WBXML)
    'application/vnd.wap.wmlc': true, // .wmlc': true, //Compiled Wireless Markup Language (WMLC)
    'application/vnd.wap.wmlscriptc': true, // .wmlsc': true, //WMLScript
    'application/vnd.webturbo': true, // .wtb': true, //WebTurbo
    'application/vnd.wolfram.player': true, // .nbp': true, //Mathematica Notebook Player
    'application/vnd.wordperfect': true, // .wpd': true, //Wordperfect
    'application/vnd.wqd': true, // .wqd': true, //SundaHus WQ
    'application/vnd.wt.stf': true, // .stf': true, //Worldtalk
    'application/vnd.xara': true, // .xar': true, //CorelXARA
    'application/vnd.xfdl': true, // .xfdl': true, //Extensible Forms Description Language
    'application/vnd.yamaha.hv-dic': true, // .hvd': true, //HV Voice Dictionary
    'application/vnd.yamaha.hv-script': true, // .hvs': true, //HV Script
    'application/vnd.yamaha.hv-voice': true, // .hvp': true, //HV Voice Parameter
    'application/vnd.yamaha.openscoreformat': true, // .osf': true, //Open Score Format
    'application/vnd.yamaha.openscoreformat.osfpvg+xml': true, // .osfpvg': true, //OSFPVG
    'application/vnd.yamaha.smaf-audio': true, // .saf': true, //SMAF Audio
    'application/vnd.yamaha.smaf-phrase': true, // .spf': true, //SMAF Phrase
    'application/vnd.yellowriver-custom-menu': true, // .cmp': true, //CustomMenu
    'application/vnd.zul': true, // .zir': true, //Z.U.L. Geometry
    'application/vnd.zzazz.deck+xml': true, // .zaz': true, //Zzazz Deck
    'application/voicexml+xml': true, // .vxml': true, //VoiceXML
    'application/widget': true, // .wgt': true, //Widget Packaging and XML Configuration
    'application/winhlp': true, // .hlp': true, //WinHelp
    'application/wsdl+xml': true, // .wsdl': true, //WSDL - Web Services Description Language
    'application/wspolicy+xml': true, // .wspolicy': true, //Web Services Policy
    'application/x-7z-compressed': true, // .7z': true, //7-Zip
    'application/x-abiword': true, // .abw': true, //AbiWord
    'application/x-ace-compressed': true, // .ace': true, //Ace Archive
    'application/x-apple-diskimage': true, // .dmg': true, //Apple Disk Image
    'application/x-authorware-bin': true, // .aab': true, //Adobe (Macropedia) Authorware - Binary File
    'application/x-authorware-map': true, // .aam': true, //Adobe (Macropedia) Authorware - Map
    'application/x-authorware-seg': true, // .aas': true, //Adobe (Macropedia) Authorware - Segment File
    'application/x-bcpio': true, // .bcpio': true, //Binary CPIO Archive
    'application/x-bittorrent': true, // .torrent': true, //BitTorrent
    'application/x-bzip': true, // .bz': true, //Bzip Archive
    'application/x-bzip2': true, // .bz2': true, //Bzip2 Archive
    'application/x-cdlink': true, // .vcd': true, //Video CD
    'application/x-chat': true, // .chat': true, //pIRCh
    'application/x-chess-pgn': true, // .pgn': true, //Portable Game Notation (Chess Games)
    'application/x-cpio': true, // .cpio': true, //CPIO Archive
    'application/x-csh': true, // .csh': true, //C Shell Script
    'application/x-debian-package': true, // .deb': true, //Debian Package
    'application/x-director': true, // .dir': true, //Adobe Shockwave Player
    'application/x-doom': true, // .wad': true, //Doom Video Game
    'application/x-dtbncx+xml': true, // .ncx': true, //Navigation Control file for XML (for ePub)
    'application/x-dtbook+xml': true, // .dtb': true, //Digital Talking Book
    'application/x-dtbresource+xml': true, // .res': true, //Digital Talking Book - Resource File
    'application/x-dvi': true, // .dvi': true, //Device Independent File Format (DVI)
    'application/x-font-bdf': true, // .bdf': true, //Glyph Bitmap Distribution Format
    'application/x-font-ghostscript': true, // .gsf': true, //Ghostscript Font
    'application/x-font-linux-psf': true, // .psf': true, //PSF Fonts
    'application/x-font-otf': true, // .otf': true, //OpenType Font File
    'application/x-font-pcf': true, // .pcf': true, //Portable Compiled Format
    'application/x-font-snf': true, // .snf': true, //Server Normal Format
    'application/x-font-ttf': true, // .ttf': true, //TrueType Font
    'application/x-font-type1': true, // .pfa': true, //PostScript Fonts
    'application/x-font-woff': true, // .woff': true, //Web Open Font Format
    'application/x-futuresplash': true, // .spl': true, //FutureSplash Animator
    'application/x-gnumeric': true, // .gnumeric': true, //Gnumeric
    'application/x-gtar': true, // .gtar': true, //GNU Tar Files
    'application/x-hdf': true, // .hdf': true, //Hierarchical Data Format
    'application/x-java-jnlp-file': true, // .jnlp': true, //Java Network Launching Protocol
    'application/x-latex': true, // .latex': true, //LaTeX
    'application/x-mobipocket-ebook': true, // .prc': true, //Mobipocket
    'application/x-ms-application': true, // .application': true, //Microsoft ClickOnce
    'application/x-ms-wmd': true, // .wmd': true, //Microsoft Windows Media Player Download Package
    'application/x-ms-wmz': true, // .wmz': true, //Microsoft Windows Media Player Skin Package
    'application/x-ms-xbap': true, // .xbap': true, //Microsoft XAML Browser Application
    'application/x-msaccess': true, // .mdb': true, //Microsoft Access
    'application/x-msbinder': true, // .obd': true, //Microsoft Office Binder
    'application/x-mscardfile': true, // .crd': true, //Microsoft Information Card
    'application/x-msclip': true, // .clp': true, //Microsoft Clipboard Clip
    'application/x-msdownload': true, // .exe': true, //Microsoft Application
    'application/x-msmediaview': true, // .mvb': true, //Microsoft MediaView
    'application/x-msmetafile': true, // .wmf': true, //Microsoft Windows Metafile
    'application/x-msmoney': true, // .mny': true, //Microsoft Money
    'application/x-mspublisher': true, // .pub': true, //Microsoft Publisher
    'application/x-msschedule': true, // .scd': true, //Microsoft Schedule+
    'application/x-msterminal': true, // .trm': true, //Microsoft Windows Terminal Services
    'application/x-mswrite': true, // .wri': true, //Microsoft Wordpad
    'application/x-netcdf': true, // .nc': true, //Network Common Data Form (NetCDF)
    'application/x-pkcs12': true, // .p12': true, //PKCS #12 - Personal Information Exchange Syntax Standard
    'application/x-pkcs7-certificates': true, // .p7b': true,
    // PKCS #7 - Cryptographic Message Syntax Standard (Certificates)
    'application/x-pkcs7-certreqresp': true, // .p7r': true,
    // PKCS #7 - Cryptographic Message Syntax Standard (Certificate Request Response)
    'application/x-rar-compressed': true, // .rar': true, //RAR Archive
    'application/x-sh': true, // .sh': true, //Bourne Shell Script
    'application/x-shar': true, // .shar': true, //Shell Archive
    'application/x-shockwave-flash': true, // .swf': true, //Adobe Flash
    'application/x-silverlight-app': true, // .xap': true, //Microsoft Silverlight
    'application/x-stuffit': true, // .sit': true, //Stuffit Archive
    'application/x-stuffitx': true, // .sitx': true, //Stuffit Archive
    'application/x-sv4cpio': true, // .sv4cpio': true, //System V Release 4 CPIO Archive
    'application/x-sv4crc': true, // .sv4crc': true, //System V Release 4 CPIO Checksum Data
    'application/x-tar': true, // .tar': true, //Tar File (Tape Archive)
    'application/x-tcl': true, // .tcl': true, //Tcl Script
    'application/x-tex': true, // .tex': true, //TeX
    'application/x-tex-tfm': true, // .tfm': true, //TeX Font Metric
    'application/x-texinfo': true, // .texinfo': true, //GNU Texinfo Document
    'application/x-ustar': true, // .ustar': true, //Ustar (Uniform Standard Tape Archive)
    'application/x-wais-source': true, // .src': true, //WAIS Source
    'application/x-x509-ca-cert': true, // .der': true, //X.509 Certificate
    'application/x-xfig': true, // .fig': true, //Xfig
    'application/x-xpinstall': true, // .xpi': true, //XPInstall - Mozilla
    'application/xcap-diff+xml': true, // .xdf': true, //XML Configuration Access Protocol - XCAP Diff
    'application/xenc+xml': true, // .xenc': true, //XML Encryption Syntax and Processing
    'application/xhtml+xml': true, // .xhtml': true, //XHTML - The Extensible HyperText Markup Language
    'application/xml': true, // .xml: true, //XML - Extensible Markup Language
    'application/xml-dtd': true, // .dtd: true, //Document Type Definition
    'application/xop+xml': true, // .xop: true, //XML-Binary Optimized Packaging
    'application/xslt+xml': true, // .xslt: true, //XML Transformations
    'application/xspf+xml': true, // .xspf: true, //XSPF - XML Shareable Playlist Format
    'application/xv+xml': true, // .mxml: true, //MXML
    'application/yang': true, // .yang: true, //YANG Data Modeling Language
    'application/yin+xml': true, // .yin: true, //YIN (YANG - XML)
    'application/zip': true, // .zip: true, //Zip Archive
  },
  extra: {
    'chemical/x-cdx': true, // .cdx  ChemDraw eXchange file
    'chemical/x-cif': true, // .cif Crystallographic Interchange Format
    'chemical/x-cmdf': true, // .cmdf  CrystalMaker Data Format
    'chemical/x-cml': true, //  .cml  Chemical Markup Language
    'chemical/x-csml': true, // .csml  Chemical Style Markup Language
    'chemical/x-xyz': true, // .xyz  XYZ File Format
    'message/rfc822': true, // .eml  Email Message
    'model/iges': true, // .igs  Initial Graphics Exchange Specification (IGES)
    'model/mesh': true, // .msh  Mesh Data Type
    'model/vnd.collada+xml': true, // .dae  COLLADA
    'model/vnd.dwf': true, // .dwf  Autodesk Design Web Format (DWF)
    'model/vnd.gdl': true, // .gdl  Geometric Description Language (GDL)
    'model/vnd.gtw': true, // .gtw  Gen-Trix Studio
    'model/vnd.mts': true, // .mts  Virtue MTS
    'model/vnd.vtu': true, // .vtu  Virtue VTU
    'model/vrml': true, // .wrl  Virtual Reality Modeling Language
    'x-conference/x-cooltalk': true, // .ice  CoolTalk
  },
  xls: {
    'application/vnd.ms-excel': true, // .xls': true, // Microsoft Excel
    'application/vnd.ms-excel.addin.macroenabled.12': true, // .xlam': true, // Microsoft Excel - Add-In File
    'application/vnd.ms-excel.sheet.binary.macroenabled.12': true, // .xlsb': true, // Microsoft Excel - Binary Workbook
    'application/vnd.ms-excel.sheet.macroenabled.12': true, // .xlsm': true, // Microsoft Excel - Macro-Enabled Workbook
    'application/vnd.ms-excel.template.macroenabled.12': true,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': true,
  },
  presentation: {
    'application/vnd.ms-powerpoint': true, // .ppt': true,
    // Microsoft PowerPoint
    'application/vnd.ms-powerpoint.addin.macroenabled.12': true, // .ppam': true,
    // Microsoft PowerPoint - Add-in file
    'application/vnd.ms-powerpoint.presentation.macroenabled.12': true, // .pptm': true,
    // Microsoft PowerPoint - Macro-Enabled Presentation File
    'application/vnd.ms-powerpoint.slide.macroenabled.12': true, // .sldm': true,
    // Microsoft PowerPoint - Macro-Enabled Open XML Slide
    'application/vnd.ms-powerpoint.slideshow.macroenabled.12': true, // .ppsm': true,
    // Microsoft PowerPoint - Macro-Enabled Slide Show File
    'application/vnd.ms-powerpoint.template.macroenabled.12': true, // .potm': true,
    // Microsoft PowerPoint - Macro-Enabled Template File
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': true,
  },
  ms: {
    'application/msword': true,
    'application/vnd.ms-fontobject': true, // .eot': true, // Microsoft Embedded OpenType
    'application/vnd.ms-htmlhelp': true, // .chm': true, // Microsoft Html Help File
    'application/vnd.ms-ims': true, // .ims': true, // Microsoft Class Server
    'application/vnd.ms-lrm': true, // .lrm': true, // Microsoft Learning Resource Module
    'application/vnd.ms-officetheme': true, // .thmx': true, // Microsoft Office System Release Theme
    'application/vnd.ms-pki.seccat': true, // .cat': true, // Microsoft Trust UI Provider - Security Catalog
    'application/vnd.ms-pki.stl': true, // .stl': true, // Microsoft Trust UI Provider - Certificate Trust Link
    'application/vnd.ms-project': true, // .mpp': true, // Microsoft Project
    'application/vnd.ms-word.document.macroenabled.12': true, // .docm': true,
    // Microsoft Word - Macro-Enabled Document

    'application/vnd.ms-word.template.macroenabled.12': true, // .dotm': true,
    // Microsoft Word - Macro-Enabled Template
    'application/x-mswrite': true, // .wri': true, //Microsoft Wordpad
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': true,
  },
};

export default mimeTypes;
