import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import AddFile from '../LibraryHeaderMenu/childComponents/AddFilePopUp';
import { actionCloseModal } from '../../redux/user/action';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { DATA_PARENTS } from '../../utils/constants';
import { maxFileSize } from '../../GLOBAL_CONST';
import { actionCreateUpload } from '../../redux/filesUpload/action';


const Wrap = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 750px;
  z-index: 10000;
  ${({ clientY }) => `top: ${clientY - 100}px;`}
    
`;


const AddFileModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [wrapperRef, isOptionVisible, setIsComponentVisible] = useComponentVisible(true, DATA_PARENTS.PlaylistBuilderLibrary);
  const inputFile = useRef(null);

  const { dataPayload: { clientY } } = useSelector((redux) => redux.user);
  const uploads = useSelector((state) => state.uploads.components);


  const close = useCallback(() => {
    dispatch(actionCloseModal());
  }, [dispatch]);

  useEffect(() => {
    if (!isOptionVisible) close();
  }, [close, isOptionVisible]);

  useEffect(() => {
    setIsComponentVisible(true);
  }, [clientY]);

  const onButtonClick = useCallback((e) => {
    e.stopPropagation();
    inputFile?.current.click();
  }, []);

  const submitForm = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const sortedUploads = Object.values(uploads || {}).sort((a, b) => {
      if (a.errorMsg) return -1;
      if (b.errorMsg) return 1;
      return b.position - a.position;
    });
    [...e.target.files].forEach((item, index) => {
      const newId = uuidv4();
      if (item.size > maxFileSize.bytes) {
        dispatch(
          actionCreateUpload({
            newId,
            file: item,
            title: item.name,
            position: sortedUploads.length
              ? sortedUploads[0]?.position + 1
              : index + 1,
            type: item.type || 'unknown_type',
            errorMsg: `${t('fileSizeMoreThanT')} ${maxFileSize.text}`,
            history,
          }),
        );
      } else {
        dispatch(
          actionCreateUpload({
            newId,
            file: item,
            title: item.name,
            position: sortedUploads[0]?.position + 1,
            type: item.type || 'unknown_type',
            history,
          }),
        );
      }
    });
    inputFile.current.value = '';
  }, [dispatch, history, uploads]);

  return (
    <Wrap clientY={clientY}>
      {isOptionVisible && (
      <AddFile
        place="GlobalModal"
        onButtonClick={onButtonClick}
        wrapperRef={wrapperRef}
        setIsAddFileMenuVisible={close}
      />
      )}
      <input
        type="file"
        name="file"
        multiple
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={submitForm}
      />
    </Wrap>
  );
};
export default AddFileModal;
