import { keyframes } from 'styled-components';
import styled from 'styled-components/macro';

export const dotTypingAnima = keyframes`
  0% {
    box-shadow: 9984px 0 0 0 #484343, 9999px 0 0 0 #484343, 10014px 0 0 0 #484343;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #484343, 9999px 0 0 0 #484343, 10014px 0 0 0 #484343;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #484343, 9999px 0 0 0 #484343, 10014px 0 0 0 #484343;
  }
  50% {
    box-shadow: 9984px 0 0 0 #484343, 9999px -10px 0 0 #484343, 10014px 0 0 0 #484343;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #484343, 9999px 0 0 0 #484343, 10014px 0 0 0 #484343;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #484343, 9999px 0 0 0 #484343, 10014px -10px 0 0 #484343;
  }
  100% {
    box-shadow: 9984px 0 0 0 #484343, 9999px 0 0 0 #484343, 10014px 0 0 0 #484343;
  }
`;
export const DotTyping = styled.div`
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background-color: #484343;
  color: #484343;
  box-shadow:
    9984px 0 0 0 #484343,
    9999px 0 0 0 #484343,
    10014px 0 0 0 #484343;
  animation: ${dotTypingAnima} 1.5s infinite linear;
  left: -9969px;
  top: 11px;
`;
export const UnavailableMuxWrapper = styled.div`
  font-family: "RobotoFlex";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #484343;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;
export const InnerWrapper = styled.div`
  width: max-content;
  margin: auto;
  display: flex;
  padding-right: 45px;
`;
