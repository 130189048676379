import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actionCreator } from '../../shared/redux/actionHelper';
import { LibraryComponents } from '../../redux/library/types';
import { actionClearCurrentPage } from '../../redux/currentPage/action';
import { ViewLibraryComponent } from './childComponents/ViewLibComponent';

export const ViewLibraryComponentSharedPlaylistItem = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const item = useSelector((state) => state.currentPage);

  useEffect(() => {
    dispatch(
      actionCreator(LibraryComponents.GetSharedLibraryComponentInPlaylist, {
        libraryItemId: id,
      }),
    );
    return () => dispatch(actionClearCurrentPage());
  }, [id]);

  if (item.isDownload || !item?.id) {
    return (
      <div className="loader-wrapper-page">
        <div className="loader">{t('loadingT')}</div>
      </div>
    );
  }
  return <ViewLibraryComponent item={item} />;
};
