import React from 'react';
import styled from 'styled-components/macro';
import SearchInHeader from '../../../../../components/MainHeader/SearchInHeader';
import useThrottle from '../../../../../utils/hooks/useThrottle';

const UPVLibSearchWrapper = styled.div`
  margin-top: 19px;
  margin-left: 31px;
`;

const Search = ({ setSearchString, searchString, dataP }) => {
  const [throttle] = useThrottle(300);
  const searchWithDelay = (e) => {
    throttle(() => setSearchString(e));
  };
  return (
    <UPVLibSearchWrapper data-parent={dataP}>
      <SearchInHeader
        libFilterSearch={searchString}
        setLibFilterSearch={searchWithDelay}
        forPlace="smart"
      />
    </UPVLibSearchWrapper>
  );
};

export default Search;
