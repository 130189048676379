import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { DATA_PARENTS, empty, openModalType } from '../../utils/constants';
import {
  actionSaveComponentDescriptionData,
  actionToggleComponentDescription,
} from '../../redux/support/action';
import { actionShowLibraryWidget } from '../../redux/currentPage/action';
import styles from './sideBarsWrapper.module.scss';
import SideBarTabs from '../../pages/Playlist/PlaylistTabs/SideBarTabs';
import ComponentDescription2023 from '../ComponentDescription/2023/ComponentDescriptions';
import UPVLib from '../../pages/Maker/MakerCommon/UiComponents/LibraryRightSideBar';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import SettingsRightSideBar from '../../pages/Maker/MakerCommon/UiComponents/SettingsRightSideBar';

const cn = classNames.bind(styles);
let transitionTimer;
let transitionTimer2;

const SideBarsWrapper = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isShowSettings } = useSelector((state) => state.support);
  const { id: userId, openModal } = useSelector((state) => state.user);
  const { componentDescription } = useSelector((state) => state.support);
  const currentPage = useSelector((state) => state.currentPage);
  const { isShowLibraryWidget, owner, isLargeRightBar, sideBarType } = currentPage;
  const isSettings = sideBarType === 'settings';
  // const uploads = useSelector(state => state.uploads.components);
  const { id: metaId } = useSelector(
    (reduxState) => reduxState.support.componentDescriptionData || empty,
  );
  const isPrevent = useRef(null);

  const isPages = history.location.pathname === '/pages';
  const isChannels = history.location.pathname === '/channels';
  const isChannel = history.location.pathname.startsWith('/channel');
  const isUPV = history.location.pathname.startsWith('/universal');
  const isLibrary = history.location.pathname.startsWith('/content/');
  const isManyPlaylists = history.location.pathname === '/playlists';
  const isViewPlaylists = history.location.pathname.includes('/view_playlist');
  const isPlaylist = history.location.pathname.startsWith('/playlist/');
  const isEditPage = history.location.pathname.startsWith('/edit_page');
  const isViewMode = isEditPage && owner?.id !== userId;
  const [isShowFilter, setIsShowFilter] = useState(false);

  // const isPage = history.location.pathname.startsWith('/libraryPage')
  //   || history.location.pathname.startsWith('/edit_page');
  const isShowLibraryTabs = (isPlaylist
      || isEditPage
      || history.location.pathname.includes('libraryPage')
      || (history.location.pathname.includes('shared_playlist_co_edit')
        && !isViewMode))
    && !isManyPlaylists
    && !isViewPlaylists;

  const TRANSITION_DURATION = 350;
  const [transitionStart, setTransitionStart] = useState('init');
  const [lastActiveSideBars, setLastActiveSideBars] = useState({});
  const [clickOutSideRefLib, isShowRightSideBarLib, setIsShowRightSideBarLib] = useComponentVisible(
    isShowLibraryWidget,
    DATA_PARENTS.PlaylistBuilderLibrary,
    null,
    isPrevent,
  );

  const [isNoAnimationNeeded, setIsNoAnimationNeeded] = useState(false);

  useEffect(() => {
    transitionTimer = setTimeout(() => {
      setLastActiveSideBars({
        settings: isShowSettings,
        library: isShowRightSideBarLib,
      });
    }, TRANSITION_DURATION);
    return () => {
      clearTimeout(transitionTimer);
    };
  }, [isShowRightSideBarLib, isShowSettings]);

  useEffect(() => {
    if (!isShowSettings && isShowRightSideBarLib && lastActiveSideBars.settings) setIsNoAnimationNeeded(false);
    else if (
      isShowSettings
      && !isShowRightSideBarLib
      && lastActiveSideBars.settings
    ) setIsNoAnimationNeeded(false);
    else if (
      isShowSettings
      && !isShowRightSideBarLib
      && lastActiveSideBars.library
    ) setIsNoAnimationNeeded(false);
    else if (
      !isShowSettings
      && isShowRightSideBarLib
      && lastActiveSideBars.library
    ) setIsNoAnimationNeeded(false);
    else if (!isShowSettings && isShowRightSideBarLib) setIsNoAnimationNeeded(true);
    else if (isShowSettings && !isShowRightSideBarLib) setIsNoAnimationNeeded(true);
    else if (!isShowSettings && !isShowRightSideBarLib) setIsNoAnimationNeeded(true);
  }, [lastActiveSideBars]);

  useEffect(() => {
    if (transitionStart === 'init' && !isShowRightSideBarLib && !isShowSettings) return;
    setTransitionStart(true);
    transitionTimer2 = setTimeout(() => {
      setTransitionStart(false);
    }, TRANSITION_DURATION);
    return () => {
      clearTimeout(transitionTimer2);
    };
  }, [isShowRightSideBarLib, isShowSettings]);

  useEffect(() => {
    isPrevent.current = openModal === openModalType.UPVPreview;
  }, [openModal]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(actionToggleComponentDescription(false));
      dispatch(actionSaveComponentDescriptionData());
      dispatch(actionShowLibraryWidget(false));
    });
  }, [history.location.pathname]);

  useEffect(() => {
    setIsShowRightSideBarLib(isShowLibraryWidget);
  }, [isShowLibraryWidget]);

  useEffect(() => {
    if (!isShowRightSideBarLib && isShowLibraryWidget) {
      dispatch(actionShowLibraryWidget(isShowRightSideBarLib));
    }
  }, [isShowRightSideBarLib]);

  useEffect(() => {
    if (!isShowRightSideBarLib) {
      dispatch(actionToggleComponentDescription(false));
    }
  }, [isShowRightSideBarLib]);
  const setShowLibrary = (show) => {
    setIsShowRightSideBarLib(show);
  };
  return (
    <div className={cn('sideBars')}>
      {!isLibrary
        && !isChannels
        && !isChannel
        && !isUPV
        && !isPages
        && !isManyPlaylists
        && !isViewPlaylists && (
          <div
            data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
            className={cn('library_sideBar', {
              expanded: isShowRightSideBarLib && isLargeRightBar,
              settings: isShowRightSideBarLib && isSettings,
              open:
                (transitionStart !== 'init'
                  && !transitionStart
                  && isShowRightSideBarLib)
                || (isNoAnimationNeeded && isShowRightSideBarLib),
              close:
                transitionStart !== 'init'
                && transitionStart
                && !isShowRightSideBarLib
                && isNoAnimationNeeded,
            })}
          >
            {isShowLibraryTabs && (
              <SideBarTabs
                setIsShowFilter={setIsShowFilter}
                isShowFilter={isShowFilter}
                wrapperRef={clickOutSideRefLib}
                showLibrary={isShowRightSideBarLib}
                isEditPage={
                  history.location.pathname.includes('edit_page')
                  || history.location.pathname.includes('libraryPage')
                }
              />
            )}
            <div
              className={cn('block', {
                block2: isShowRightSideBarLib,
                expanded: isShowRightSideBarLib && isLargeRightBar,
                none: isShowSettings,
              })}
            >{!isSettings && isShowRightSideBarLib
              && (
              <UPVLib
                wrapperRef={clickOutSideRefLib}
                close={() => setShowLibrary(false)}
                currentPage={currentPage}
                isPage
                dataP={DATA_PARENTS.PlaylistBuilderLibrary}
              />
              )}
              {isSettings && (
                <SettingsRightSideBar
                  dataParent={DATA_PARENTS.PlaylistBuilderLibrary}
                  wrapperRef={clickOutSideRefLib}
                  currentPage={currentPage}
                  type="page"
                />
              )}
            </div>
          </div>
      )}

      {(isLibrary || isPages || isChannel) && componentDescription && (
        <div
          ref={clickOutSideRefLib}
          className={cn('componentMetaData_sideBar', {
            open: !!metaId && isShowRightSideBarLib && componentDescription,
          })}
        >
          <ComponentDescription2023
            setIsShowRightSideBarLib={setIsShowRightSideBarLib}
            isShowRightSideBarLib={isShowRightSideBarLib}
            wrapperRef={clickOutSideRefLib}
          />
        </div>
      )}
    </div>
  );
};

export default SideBarsWrapper;
