import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './MomentBlock.module.css';
import ShowLink from './ShowLink';
import useComponentVisible from '../../utils/hooks/useComponentVisible';

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  const ref = useRef(null);
  const [refShowLinkInfo, isShowLinkInfo, setShowLinkInfo] = useComponentVisible(false);
  const history = useHistory();

  const isViewPage = history.location.pathname.startsWith('/edit_page')
    || history.location.pathname.startsWith('/view_playlist')
    || history.location.pathname.startsWith('/shared_playlist');
  const openInNewTab = (e) => {
    e.stopPropagation();
    let goToUrl = url;
    if (!url.startsWith('https://') && !url.startsWith('http://')) goToUrl = `http://${url}`;
    const newWindow = window.open(goToUrl, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  return (
    <span
      className={styles.test}
      onClick={(e) => (!isViewPage ? setShowLinkInfo(true) : openInNewTab(e))}
    >
      {isShowLinkInfo && (
        <ShowLink
          removeLink={() => {
            setShowLinkInfo(false);
            props.option.setLinkOption({ ref, props, remove: true });
          }}
          select={() => props.option.setLinkOption({ ref, props, update: false })
          }
          edit={() => {
            setShowLinkInfo(false);
            props.option.setLinkOption({ ref, props, edit: true });
          }}
          setShowLinkInfo={setShowLinkInfo}
          refShowLinkInfo={refShowLinkInfo}
          url={url}
        />
      )}

      <a ref={ref} href={url} style={styles.link}>
        {props.children}
      </a>
    </span>
  );
};

const WrapperLink = (props) => {
  return (prop) => <Link option={props} {...prop} />;
};
export default WrapperLink;
