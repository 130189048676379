export class Point {
  constructor(x, y) {
    this._x = x;
    this._y = y;
  }

  get x() {
    return this._x;
  }

  get y() {
    return this._y;
  }

  equals(point) {
    return this.x === point.x && this.y === point.y;
  }

  calcDeltaXTo(point) {
    return this.x - point.x;
  }

  calcDeltaYTo(point) {
    return this.y - point.y;
  }

  calcHorizontalDistanceTo(point) {
    return Math.abs(this.calcDeltaXTo(point));
  }

  calcVerticalDistance(point) {
    return Math.abs(this.calcDeltaYTo(point));
  }

  calcDistanceTo(point) {
    return Math.sqrt(
      (this.calcDeltaXTo(point) ** 2)
      + (this.calcDeltaYTo(point) ** 2),
    );
  }
}
export function isPoint(x) {
  return x instanceof Point;
}
