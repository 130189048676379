import styled, { keyframes } from 'styled-components';

export const spinTheSpinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const ConfirmLoadingSpinner = styled.div`
  position: absolute;
  right: -26px;
  top: 4px;

  & svg {
    width: 20px;
    height: 20px;
    animation: linear ${spinTheSpinner} 1.3s infinite;
  }
`;

export const AvatarLoadingSpinner = styled.div`
  position: absolute;
  left: 95px;
  top: 0;

  & svg {
    width: 20px;
    height: 20px;
    animation: linear ${spinTheSpinner} 1.3s infinite;
  }
`;

export const Spinner = styled.div`
  position: absolute;
  right: 11px;
  top: 6px;

  & svg {
    width: 20px;
    height: 20px;
    animation: linear ${spinTheSpinner} 1.3s infinite;
  }

  ${({ isRoleCell }) => (isRoleCell
    ? `
    position: relative;
    top: 2px;
    right: initial;
  `
    : '')}
`;
export const SpinnerGoogleEmbed = styled.div`
  position: absolute;
  left: 0;
  top: 8px;
  height: 20px;
  width: 20px;

  & svg {
    width: 16px;
    height: 16px;
    animation: linear ${spinTheSpinner} 1.3s infinite;
    & path {
      stroke: black;
    }
  }
`;
export const TOCSpinner = styled.div`
  position: relative;
  margin-top: 10px;

  & svg {
    width: 24px;
    height: 24px;
    animation: linear ${spinTheSpinner} 1.3s infinite;
  }
`;

export const MatrixSpinner = styled.div`
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  margin: 0 auto;
  width: fit-content;

  & svg {
    width: 32px;
    height: 32px;
    animation: linear ${spinTheSpinner} 1.3s infinite;
  }
`;
