import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-date-picker';
import classnames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseSmallSvg } from '../../../images/icons/close_7.svg';
import '../datepicker.css';
import rawStyles from '../index.module.scss';

import { ReactComponent as RadioButton2023 } from '../../../images/2023/svg/Select_16_16.svg';
import { calcGreater } from '../helpers';
import { LeftButton2023, RightButton2023 } from './Buttons2023';
import { DateRangeMark4SharedPlaylist, DateRangeMark4SharedPlaylistText } from '../helpers/enum';
import { setButtonsParentAttribute } from '../../../utils/DOM';

const styles = classnames.bind(rawStyles);

const PlaylistsDatePickerChrome2023 = ({
  saveState,
  setSelectedDateTo,
  selectedDateTo,
  selectFirstOption,
  setSelectedDateFrom,
  setSelectFirstOption,
  selectedDateFrom,
  textFirstOption,
  selectSecondOption,
  setSelectSecondOption,
  textSecondOption,
  selectedOption,
  setSelectedOption,
  dataParent,
  isForShared,
  isModify,
  isLocal,
}) => {
  const [openedDateTo, setIsOpenedDateTo] = useState(false);
  const [openedDateFrom, setIsOpenedDateFrom] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const refDateFrom = useRef(null);
  const refDateTo = useRef(null);
  const { t } = useTranslation();

  const checkSelect = (textOption) => {
    setIsTouched(true);
    if ((isForShared || isLocal)) {
      setSelectedOption(textOption);
    } else {
      if (selectFirstOption !== (textFirstOption === textOption)) {
        setSelectFirstOption(!selectFirstOption);
      }
      if (!!selectSecondOption !== (textSecondOption === textOption)) {
        setSelectSecondOption(!selectSecondOption);
      }
    }
  };

  const setOpenedDateFrom = (val) => {
    setIsOpenedDateFrom(val);
    setButtonsParentAttribute('cursed_date_picker_from', dataParent, 'tileClass');
  };
  const setOpenedDateTo = (val) => {
    setIsOpenedDateTo(val);
    setButtonsParentAttribute('cursed_date_picker_to', dataParent, 'tileClass');
  };
  const nowDate = new Date();

  const setSelectedDateToHandler = (newSelectedDateTo) => {
    newSelectedDateTo.setHours(23, 59, 59, 999);
    setSelectedDateTo(newSelectedDateTo);
  };

  const setSelectedDateFromHandler = (newSelectedDateFrom) => {
    const newDate = new Date(newSelectedDateFrom.setHours(0, 0, 0, 0));
    if (calcGreater(newSelectedDateFrom, selectedDateTo)) {
      setSelectedDateToHandler(new Date(newDate));
    }
    setSelectedDateFrom(newDate);
  };

  useEffect(() => {
    if (isModify) {
      isTouched && saveState();
      return;
    }
    isTouched && saveState();
  }, [selectedDateFrom, selectedDateTo, selectedOption, selectFirstOption, selectSecondOption]);
  return (
    <div className={styles('radio_selector')} data-parent={dataParent}>
      <div
        data-cy="onlyRead"
        className={styles('option')}
        data-parent={dataParent}
        onClick={
          (isForShared || isLocal)
            ? () => checkSelect(DateRangeMark4SharedPlaylist.OPEN_TO_READ)
            : () => checkSelect(textFirstOption)
        }
      >
        <RadioButton2023
          className={styles('radioButton2023', {
            selected2023: (isForShared || isLocal)
              ? selectedOption === DateRangeMark4SharedPlaylist.OPEN_TO_READ || !selectedOption
              : selectFirstOption
            ,
          })}
          data-parent={dataParent}
        />
        <div className={styles('timePublish', 'no_date')} data-parent={dataParent}>
          {' '}
          {(isForShared || isLocal) ? t(DateRangeMark4SharedPlaylistText.OPEN_TO_READ) : textFirstOption}
        </div>
      </div>
      <div
        data-cy="24h"
        className={styles('option')}
        data-parent={dataParent}
        onClick={(isForShared || isLocal)
          ? () => checkSelect(DateRangeMark4SharedPlaylist.DAILY)
          : () => checkSelect(textSecondOption)
        }
      >
        <RadioButton2023
          className={styles('radioButton2023', {
            selected2023: (isForShared || isLocal)
              ? selectedOption === DateRangeMark4SharedPlaylist.DAILY
              : selectSecondOption
            ,
          })}
          data-parent={dataParent}
        />
        <div className={styles('timePublish', 'no_date')} data-parent={dataParent}>
          {' '}
          {(isForShared || isLocal) ? t(DateRangeMark4SharedPlaylistText.DAILY) : textSecondOption}
        </div>
      </div>
      {(isForShared) && (
      <div
        data-cy="24h"
        className={styles('option')}
        data-parent={dataParent}
        onClick={() => checkSelect(DateRangeMark4SharedPlaylist.WEEKLY)}
      >
        <RadioButton2023
          className={styles('radioButton2023', {
            selected2023: selectedOption === DateRangeMark4SharedPlaylist.WEEKLY,
          })}
          data-parent={dataParent}
        />
        <div className={styles('timePublish', 'no_date')} data-parent={dataParent}>
          {' '}
          {t(DateRangeMark4SharedPlaylistText.WEEKLY)}
        </div>
      </div>
      )}
      <div
        data-cy="fromTo"
        className={styles('option')}
        data-parent={dataParent}
        onClick={(isForShared || isLocal)
          ? () => checkSelect(DateRangeMark4SharedPlaylist.CUSTOM_TIME_RANGE)
          : () => checkSelect()
        }
      >
        <RadioButton2023
          className={styles('radioButton2023', {
            selected2023: (isForShared || isLocal)
              ? selectedOption === DateRangeMark4SharedPlaylist.CUSTOM_TIME_RANGE
              : !selectFirstOption && !selectSecondOption
            ,
          })}
          data-parent={dataParent}
        />
        <div className={styles('wrapper_date_picker')} data-parent={dataParent}>
          <div className={styles('timePublish')} data-parent={dataParent}>{(isForShared || isLocal)
            ? t(DateRangeMark4SharedPlaylistText.CUSTOM_TIME_RANGE)
            : t('selectADateRangeT')
          }
          </div>
          {((isForShared || isLocal)
            ? selectedOption === DateRangeMark4SharedPlaylist.CUSTOM_TIME_RANGE
            : !selectFirstOption && !selectSecondOption
          ) && (
            <div
              className={styles('picker_group', { isModify })}
            >
              <div className={styles('row')} data-parent={dataParent}>
                <div
                  tabIndex="0"
                  ref={refDateFrom}
                  onClick={() => setOpenedDateFrom(true)}
                  className={styles('datePicker', { isModify })}
                  data-parent={dataParent}
                  id="cursed_date_picker_from"
                >
                  <div className={styles('name')}>{t('fromDateT')}</div>
                  <DatePicker
                    onChange={(e) => {
                      setSelectedDateFromHandler(e);
                    }}
                    value={selectedDateFrom}
                    clearIcon={<CloseSmallSvg />}
                    tileClassName="tileClass"
                    className={`playlistsDatePicker2023 ${isModify ? 'isModifyUnset' : ''}`}
                    showLeadingZeros
                    isOpen={openedDateFrom}
                    onCalendarClose={(a) => setOpenedDateFrom(false, 'close', a)
                    }
                    minDate={openedDateFrom && nowDate}
                    prevLabel={<LeftButton2023 dataParent={dataParent} />}
                    nextLabel={<RightButton2023 dataParent={dataParent} />}
                    disabled={!isLocal && selectFirstOption}
                  />
                </div>
              </div>
              <div className={styles('row')} data-parent={dataParent}>
                <div
                  ref={refDateTo}
                  tabIndex="0"
                  onClick={() => setOpenedDateTo(true)}
                  className={styles('datePicker', { isModify })}
                  data-parent={dataParent}
                  id="cursed_date_picker_to"
                >
                  <div className={styles('name')}>{t('toDateT')}</div>
                  <DatePicker
                    onChange={(e) => setSelectedDateToHandler(e)}
                    minDate={selectedDateFrom || nowDate}
                    value={selectedDateTo}
                    tileClassName="tileClass"
                    className={`playlistsDatePicker2023 ${isModify ? 'modifyZeroLeft' : ''}`}
                    showLeadingZeros
                    isOpen={openedDateTo}
                    onCalendarClose={(a) => setOpenedDateTo(false, 'close', a)}
                    prevLabel={<LeftButton2023 dataParent={dataParent} />}
                    nextLabel={<RightButton2023 dataParent={dataParent} />}
                    disabled={!isLocal && selectFirstOption}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlaylistsDatePickerChrome2023;
