import React, { useState } from 'react';
import classNames from 'classnames/bind';
import HoverTooltipWrapper from '../PagePreview/HoverTooltipWrapper';
import styles from '../../pages/Dashboard/WelcomeView/RowView.module.scss';
import Image from './Image';
import Preview from './Preview';
import FolderImage from '../../pages/Maker/FolderImage';
import FolderIconLayout from '../../pages/Maker/FolderIconLayout';

const cn = classNames.bind(styles);

const ImageWithPreview = ({ item, src, isPlaylists, isLibrary, isMaker }) => {
  const [isHoverPreviewActive, setIsHoverPreviewActive] = useState(false);
  return (
    <HoverTooltipWrapper
      isActive={isHoverPreviewActive}
      setIsActive={setIsHoverPreviewActive}
      setIsHoverPreviewActive
      playlist={item}
      item={item}
      isLibrary={isLibrary}
      element={(
        <>
          {src && (
            <div className={cn('folder_wrap')}>
              <img
                style={{ top: -item.img?.position }}
                src={src}
                alt="type_image"
              />
              {isPlaylists && <FolderIconLayout isOverCoverImage />}
            </div>
          )}
          {!src && isPlaylists && (
            <FolderImage itemId={item.id} currentColor={item.defaultPreviewColor} />
          )}
          {isLibrary && <Preview item={item} alt="type_image" isMaker={isMaker} />}
        </>
      )}
    >
      {isPlaylists && (
        <div className={cn('image_wrapper')}>
          {src && (
            <div className={cn('folder_wrap_small')}>
              <img
                className={cn('image')}
                style={{ top: -(item?.img?.position / 1.9842) }}
                src={src}
                alt="type_image"
              />
              <FolderIconLayout isOverCoverImage />
            </div>
          )}
          {!src && (
            <FolderImage isTable itemId={item.id} currentColor={item.defaultPreviewColor} />
          )}
        </div>
      )}
      {isLibrary && <Image item={item} isMaker={isMaker} />}
    </HoverTooltipWrapper>
  );
};

export default ImageWithPreview;
