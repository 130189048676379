import styled from 'styled-components/macro';
import { keyframes } from 'styled-components';

const blinking = keyframes`
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
`;

export const NotReadyWrapper = styled.div`
  background: white;
  position: absolute;
  width: 170px;
  height: 170px;
  border-radius: 8px;
  z-index: 21;
  cursor: not-allowed;
`;

export const BadgeWrapper = styled.div`
  margin: auto;
  text-align: center;
  padding-left: 5px;
  margin-top: 44%;
  background: #1eff1e;
  border-radius: 8px;
  width: max-content;
  padding-right: 5px;
  color: #995a5a;
  -webkit-animation-direction: normal;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: ${blinking};
  -webkit-animation-timing-function: ease;
`;

export const UsedBadgeWrapper = styled.div`
  z-index: 10;
  width: 43px;
  height: 22px;
  background: #d32f2f;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #ffffff;
  ${({ forPages }) => (forPages ? 'top: 118px;' : 'top: 75px;')}

  left: 65px;
  padding-left: 7px;
  position: absolute;
  opacity: 0.8;
`;

export const DisruptOpacityForUsedItemsWrapper = styled.div`
  ${({ activated }) => (activated ? '  opacity: 0.3;' : '  opacity: 1;')}
`;
