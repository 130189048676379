import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { plainArrowType } from '../../../../../utils/tableHelpers/tableUi';
import { sortedFunctions } from '../../../../../utils/dateConvert';
import { emptyArr } from '../../../../../utils/constants';
import ShortcutsListItem from './ShortcutsListItem';
import {
  Header,
  Item,
  TableShell,
  Text,
} from '../../../CommonComponents/Table';
import { createShortcutItem } from '../../../../../utils/SettingsHelpers/constants';

const IS_ABSOLUTE_POSITIONED = true;

const ShortcutsTable = ({ shortcuts }) => {
  const [sortType, setSort] = useState(Object.keys(sortedFunctions)[2]);
  const [displayItems, setDisplayItems] = useState(emptyArr);
  const [raw, setRaw] = useState(emptyArr);
  const arraySortedKey = Object.keys(sortedFunctions);
  const { t } = useTranslation();

  useEffect(() => {
    if (!shortcuts) return;
    setRaw(
      Object.entries(shortcuts).map(([key, value]) => createShortcutItem(key, value),
      ),
    );
  }, [shortcuts]);

  useEffect(() => {
    setDisplayItems(sortedFunctions[sortType](Object.values(raw)));
  }, [sortType, raw]);

  const handlerClick = (type) => {
    if (arraySortedKey[type] === sortType) setSort(arraySortedKey[type + 1]);
    else setSort(arraySortedKey[type]);
  };

  return (
    <TableShell isShortcuts>
      <Header isShortcuts>
        <Item onClick={() => handlerClick(0)} isShortcuts name isHeader>
          <Text>
            {t('nameT')}
            {plainArrowType(0, sortType, IS_ABSOLUTE_POSITIONED)}
          </Text>
        </Item>
        <Item center onClick={() => handlerClick(6)} isShortcuts type isHeader>
          <Text>
            {t('typeT')}
            {plainArrowType(6, sortType, IS_ABSOLUTE_POSITIONED)}
          </Text>
        </Item>
        <Item sequence last isShortcuts onClick={() => handlerClick(22)} isHeader>
          <Text>
            {t('shortcutT')}
            {plainArrowType(22, sortType, IS_ABSOLUTE_POSITIONED)}
          </Text>
        </Item>
      </Header>
      {!!displayItems.length
        && displayItems.map((shortcut) => (
          <ShortcutsListItem key={shortcut.key} shortcut={shortcut} />
        ))}
    </TableShell>
  );
};

export default ShortcutsTable;
