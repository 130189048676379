import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button2023 from '../../../Buttons/Button2023';
import SortAndView from '../SortAndView';
import { Divider, GroupIcon, Wrapper } from './StyledComponents';
import { createNewPlaylistAndJumpIntoIt } from '../../../../utils/helpers';

const SecondaryUserContact = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const userId = useSelector((state) => state.content.selectorType);

  const createNewPlaylist = useCallback(() => {
    createNewPlaylistAndJumpIntoIt({ history, dispatch, addToUser: userId });
  }, [userId]);

  return (
    <GroupIcon>
      <Wrapper>
        <Button2023
          text={t('plusCreateUpT')}
          width={74}
          height={32}
          variant="secondary"
          handleButtonClick={createNewPlaylist}
        />
        <Divider />
      </Wrapper>
      <SortAndView {...props} />
    </GroupIcon>
  );
};

export default SecondaryUserContact;
