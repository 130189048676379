import {
  actionRemoveSelectedPage,
  actionSelectedPage,
} from '../../redux/selectedPage/action';

export const toggleSelectedWrapper = ({
  event: e,
  props,
  item,
  dispatch,
  isSelected,
}) => {
  e.stopPropagation();
  if (isSelected) {
    dispatch(actionRemoveSelectedPage(item.id, item.folderId));
  } else {
    if (!props.isSimplifiedMultiselect) props.setSimplifiedMultiselect(true);
    dispatch(actionSelectedPage(item.id, item.folderId));
  }
};
