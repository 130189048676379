import React, { useState } from 'react';
import classNames from 'classnames/bind';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import UI from '../../../UIComponents/index';
import rawStyles from './index.module.scss';
import { ValidationMessages } from '../../../utils/constants';
import 'react-phone-input-2/lib/style.css';

const cn = classNames.bind(rawStyles);

const SimpleNumberInput = ({
  isPhone,
  value,
  setValue,
  validateError,
  setValidateError,
  fieldName,
  isRequired,
}) => {
  const [isTouched, setIsTouched] = useState(false);

  const validate = (inputValue) => {
    if (isRequired) {
      if (!inputValue) setValidateError(ValidationMessages.required);
      else if (inputValue?.length < 3) setValidateError(ValidationMessages.minLength(fieldName, 3));
      else if (inputValue?.length > 20) setValidateError(ValidationMessages.maxLength(fieldName, 20));
    } else if (inputValue?.length < 3 && !!inputValue) setValidateError(ValidationMessages.minLength(fieldName, 3));
    else if (inputValue?.length >= 20 && !!inputValue) setValidateError(ValidationMessages.maxLength(fieldName, 20));
  };

  const onInputFirstNameChange = (e) => {
    const value = isPhone ? e : e.target.value;
    setValue(value);
    if (isTouched) setIsTouched(false);
    if (value.length > 55) {
      setValidateError(ValidationMessages.maxLength(fieldName));
    } else {
      setValidateError('');
    }
  };

  return (
    <SimpleNumberInputUI
      isPhone={isPhone}
      value={value}
      setValue={onInputFirstNameChange}
      validateError={validateError}
      fieldName={fieldName}
      isRequired={isRequired}
      isTouched={isTouched}
      setIsTouched={setIsTouched}
      validate={(e) => validate(e.target.value)}
    />
  );
};

export default SimpleNumberInput;

const SimpleNumberInputUI = ({
  isPhone,
  value,
  setValue,
  validateError,
  fieldName,
  isRequired,
  isTouched,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={cn('wrapper')}>
        <div className={cn('title_wrapper')}>
          <div className={cn('title', 'isSubFieldError', { required: isRequired })}>
            {fieldName} {!isRequired && <span>- {t('optionalT')}</span>}
          </div>
          <UI.ErrorSubField error={validateError} />
        </div>
        <div
          className={cn('input_area', {
            error: validateError || (isTouched && !value && isRequired),
          })}
        >
          {isPhone && (
            <PhoneInput
              country="us"
              data-cy={fieldName}
              value={value}
              onChange={setValue}
              className={cn('input', 'number')}
              // onBlur={(e) => {
              //   props.setIsTouched(true);
              //   props.validate(e);
              // }}
              onFocus={() => !value && props.setIsTouched(false)}
            />
          )}
          {!isPhone && (
            <input
              data-cy={fieldName}
              value={value}
              type="number"
              onChange={setValue}
              className={cn('input', 'number')}
              onBlur={(e) => {
                props.setIsTouched(true);
                props.validate(e);
              }}
              onFocus={() => !value && props.setIsTouched(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};
