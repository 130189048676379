import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Alert20Svg } from '../../../images/icons/alert_circle_20.svg';

const Message = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: #c21c44;
`;

const ValidationMessages = styled.div`
  position: absolute;
  width: 250px;
  left: 0;
  display: flex;
  align-items: center;
  transition: height ease-in-out 0.3s;
  overflow: hidden;

  height: ${({ isShowError }) => (isShowError ? '22' : '0')}px;

  & svg {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  ${({ isEmojiUploadInput, isEmojiNameInput }) => (isEmojiUploadInput || isEmojiNameInput
    ? `
     top: 33px;
     left: initial;
  `
    : '')}
`;

const ValidationResultsPrinter = ({
  isShowError,
  errorMessage,
  isEmojiUploadInput,
  isEmojiNameInput,
}) => {
  return (
    <ValidationMessages
      isShowError={isShowError}
      isEmojiUploadInput={isEmojiUploadInput}
      isEmojiNameInput={isEmojiNameInput}
    >
      <Alert20Svg />
      <Message>{isShowError && errorMessage}</Message>
    </ValidationMessages>
  );
};

export default ValidationResultsPrinter;
