import React, { useMemo, useState, memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from '../../../images/icons/icon_12/plusBlue.svg';
import { ReactComponent as PlusIconCircle } from '../../../images/icons/icon_20/plus.svg';

import { ReactComponent as SectionChevronIcon } from '../../../images/icons/tiny_chevron_down_filled.svg';
import { MainNavigate } from '../../../navigate/navigate';
import {
  Counter,
  IconPlace,
  Info,
  SectionChevronIconWrapper,
  MajorSelector,
  SelectorWrapper,
  MajorText,
  New,
  Selector,
  SelectorPlus,
  CollapsingSectionWrapper, PlusWrapperCircle,
} from '../styled';
import { actionOpenModal } from '../../../redux/user/action';
import { openModalType } from '../../../utils/constants';

const NewToolTip = ({ count }) => <New>{count}</New>;


const libraryItems = Object.entries(MainNavigate).filter(
  ([key]) => !!MainNavigate[key]?.isLibraryItem && !key.startsWith('['),
);
const itemHeight = 33;
const libraryItemsHeight = `${(libraryItems.length + 1) * itemHeight}px`;

const smartViewsAndTag = {
  tag: true,
  dynamicCollectionNew: true,
  dynamicCollection: true,
};

const LibrarySection = ({
  onSelectorClick,
  addFileInputRef,
  tags,
  folderId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { unseenPagesManagerMap } = useSelector((state) => state.support);
  const { t } = useTranslation();

  const [isLibraryCollapsed, setLibraryCollapsed] = useState(false);
  const isContent = history.location.pathname.startsWith('/content');

  const {
    activeNavSlider: place = 'pages',
    counters,
    selectorType,
  } = useSelector((state) => state.content);

  const isSmartViewOrTag = isContent && smartViewsAndTag[place];

  const createNew = (actionCreateNew) => {
    if (actionCreateNew) {
      const newId = uuidv4();
      const tag = tags[folderId];
      actionCreateNew({ newId, history, tag, dispatch, selectorType });
    }
  };

  const onClickPlus = useCallback((e, value) => {
    e.preventDefault();
    e.stopPropagation();
    if (value.actionCreateNew) {
      createNew(value.actionCreateNew);
    } else {
      dispatch(actionOpenModal(openModalType.AddFileModal, { clientY: e.clientY }));
    }
  }, [addFileInputRef]);

  const isLibrarySectionItem = useMemo(() => {
    return libraryItems.some(([i]) => (i === place));
  }, [place]);

  const isShowLibrarySubField = isLibraryCollapsed
    && isContent
    && (isLibrarySectionItem || place === 'pages');

  return (
    <>
      <MajorSelector isSecond>
        <SelectorWrapper>
          <SectionChevronIconWrapper
            isCollapsed={isLibraryCollapsed}
            onClick={() => setLibraryCollapsed(!isLibraryCollapsed)}
          >
            <SectionChevronIcon />
          </SectionChevronIconWrapper>
          <MajorText>{t('libraryT')}</MajorText>
        </SelectorWrapper>
        <PlusWrapperCircle onClick={(event) => onClickPlus(event, MainNavigate.library)}>
          <PlusIconCircle />
        </PlusWrapperCircle>
      </MajorSelector>
      <CollapsingSectionWrapper
        isCollapsed={isLibraryCollapsed}
        height={libraryItemsHeight}
        time="0.3"
      >
        <SelectorItem
          onSelectorClick={onSelectorClick}
          value={MainNavigate.pages}
          unseenPagesManagerMap={unseenPagesManagerMap}
          keyItem="pages"
          place={place}
          onClickPlus={onClickPlus}
          isSmartViewOrTag={isSmartViewOrTag}
          isContent={isContent}
          counters={counters}
        />
        {libraryItems.map(([key, value]) => (
          <SelectorItem
            onSelectorClick={onSelectorClick}
            value={value}
            unseenPagesManagerMap={unseenPagesManagerMap}
            key={key}
            keyItem={key}
            place={place}
            onClickPlus={onClickPlus}
            isSmartViewOrTag={isSmartViewOrTag}
            isContent={isContent}
            counters={counters}
          />
        ))}
      </CollapsingSectionWrapper>
      {isShowLibrarySubField && (
        <SelectorItem
          onSelectorClick={onSelectorClick}
          value={MainNavigate[place]}
          unseenPagesManagerMap={unseenPagesManagerMap}
          keyItem={place}
          place={place}
          isCanHide={MainNavigate[place].isCanHide}
          onClickPlus={onClickPlus}
          isSmartViewOrTag={isSmartViewOrTag}
          isContent={isContent}
          counters={counters}
        />
      )}
    </>
  );
};


const SelectorItem = memo(({ onSelectorClick, value, keyItem, place, onClickPlus,
  isSmartViewOrTag, isContent, unseenPagesManagerMap,
  counters }) => {
  const [dragOverItem, setDragOverItem] = useState(null);
  const isDragOver = dragOverItem === keyItem;
  const { t } = useTranslation();

  const onDragOver = (e, currentHover) => {
    e.stopPropagation();
    e.preventDefault();
    if (!dragOverItem) setDragOverItem(currentHover);
    e.dataTransfer.dropEffect = 'none';
  };

  const onDragEnd = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDragOverItem(null);
  };

  return (
    <Selector
      data-cy={keyItem}
      onClick={() => onSelectorClick(keyItem)}
      isDragOver={isDragOver}
      onDragOver={(e) => onDragOver(e, keyItem)}
      onDragLeave={onDragEnd}
      active={
        (isContent && keyItem === place) || isSmartViewOrTag
      }
    >
      <Info
        active={isContent && keyItem === place}
      >
        <IconPlace className="fill-icon-hover" isPdf={keyItem === 'pdf'}>
          {value.icon}
        </IconPlace>
        {t(value.textID)}
      </Info>
      {!!Object.values(unseenPagesManagerMap).length > 0 && keyItem === 'pages' ? (
        <NewToolTip
          count={Object.values(unseenPagesManagerMap).length}
        />
      ) : (
        <Counter
          active={
            (isContent && keyItem === place) || isSmartViewOrTag
          }
        >
          {counters[`${keyItem}/${MainNavigate[keyItem]?.selectorsOrder[0]}`]}
        </Counter>
      )
      }
      <SelectorPlus
        hidden
        onClick={(event) => {
          onClickPlus(event, value);
        }}
      >
        <PlusIcon />
      </SelectorPlus>
    </Selector>
  );
});

export default LibrarySection;
