import { getItemName } from './helpers';

const font = (size) => `${size}px "RobotoFlex", sans-serif`;

export const calculateTitleForDuplicateLinkPage = (array, title) => {
  let counter = 0;

  const regex = /\s\(\d+\)$/;
  const exTitle = title.replace(regex, '');
  array.forEach((item) => {
    const nameToCheck = getItemName(item);
    if (nameToCheck === exTitle || nameToCheck.startsWith(`${exTitle} `)) {
      const match = nameToCheck.match(/\((\d+)\)$/);
      if (match) {
        const existingCounter = parseInt(match[1]);
        if (existingCounter >= counter) {
          counter = existingCounter + 1;
        }
      }
    }
  });
  const incrementedTitle = counter === 0 ? `${exTitle} (1)` : `${exTitle} (${counter})`;
  return incrementedTitle;
};
export function isFirsRw(text, width = 698, size = 16) {
  const testDiv = document.createElement('span');
  testDiv.textContent = text || '_';
  testDiv.style.width = `${width}px`;
  testDiv.style.font = font(size);
  testDiv.style['line-height'] = '20px';
  testDiv.style.position = 'absolute';
  testDiv.style['word-break'] = 'break-all';

  document.body.appendChild(testDiv);
  const isFirstRow = testDiv.getBoundingClientRect().height === 20;
  document.body.removeChild(testDiv);

  return isFirstRow;
}

export function capitalizeFirstLetter(string) {
  if (!string || typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}
export function capitalizeOnlyFirstLetter(string) {
  if (!string || typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

//
// export function getTextWidthTest(text, allTexy, count, target) {
//   const testDiv = document.createElement('div');
//   const testDiv2 = document.createElement('div');
//
//   testDiv.textContent = `${text || '_'}.`;
//   testDiv.style.width = '698px';
//   testDiv.style.top = '20px';
//   testDiv.style.left = '40px';
//   testDiv.style.font = font;
//   testDiv.style.position = 'absolute';
//   testDiv.style['word-break'] = 'break-all';
//
//   testDiv2.textContent = allTexy || '_';
//   testDiv2.style.width = '698px';
//   testDiv2.style.font = font;
//   testDiv2.style.top = '520px';
//   testDiv2.style.left = '40px';
//   testDiv2.style['word-break'] = 'break-all';
//   testDiv2.setAttribute('editable', 'true');
//
//
//   document.body.appendChild(testDiv);
//   document.body.appendChild(testDiv2);
//
//   testDiv2.style.position = 'absolute';
//
//   const isLastRow = testDiv.getBoundingClientRect().height === testDiv2.getBoundingClientRect().height;

//   return isLastRow;
// }
export function getTextLastROwStartSimvol(allTexy) {
  const testDiv = document.createElement('span');
  const testDiv2 = document.createElement('span');
  testDiv.textContent = allTexy || '_';
  testDiv.style.width = '698.64px';
  testDiv.style.font = '16px "RobotoFlex", sans-serif';
  testDiv.style.position = 'absolute';
  testDiv.style['word-break'] = 'break-all';

  testDiv2.textContent = allTexy || '_';
  testDiv2.style.width = '698.64px';
  testDiv2.style.font = '16px "RobotoFlex", sans-serif';
  testDiv2.style.position = 'absolute';
  testDiv2.style['word-break'] = 'break-all';

  document.body.appendChild(testDiv);
  document.body.appendChild(testDiv2);
  let trimTitle = allTexy;
  let counter = allTexy.length;

  while (
    testDiv.getBoundingClientRect().height
      === testDiv2.getBoundingClientRect().height
    && counter
  ) {
    trimTitle = allTexy.slice(0, counter);
    testDiv.textContent = trimTitle;
    counter--;
  }
  document.body.removeChild(testDiv);
  document.body.removeChild(testDiv2);

  return counter;
}

export const checkIsLastRow = (element) => {
  const window2 = element.ownerDocument.defaultView;
  const selection = window2.getSelection();
  if (selection.rangeCount === 0) return false;
  const originalCaretRange = selection.getRangeAt(0);
  if (originalCaretRange.toString().length > 0) return false;
  const originalCaretRect = originalCaretRange.getBoundingClientRect();
  // Create a range at the end of the last text node
  const endOfElementRange = document.createRange();
  endOfElementRange.selectNodeContents(element);
  // The endContainer might not be an actual text node,
  // try to find the last text node inside
  let endContainer = endOfElementRange.endContainer;
  let endOffset = 0;
  while (endContainer.hasChildNodes() && !(endContainer instanceof Text)) {
    endContainer = endContainer.lastChild;
    endOffset = endContainer.length ?? 0;
  }
  endOfElementRange.setEnd(endContainer, endOffset);
  endOfElementRange.setStart(endContainer, endOffset);
  const endOfElementRect = endOfElementRange.getBoundingClientRect();
  return originalCaretRect.bottom === endOfElementRect.bottom;
};
function getTextNodeFrom(element) {
  // replace with more sophisticated method
  return element.childNodes[0];
}
export const checkIsLastRowForTextArea = (
  element,
  text,
  size,
  fontWeight = '',
) => {
  const { selectionEnd } = element;
  const div = document.createElement('DIV');

  document.body.appendChild(div);

  div.textContent = text || '_';
  div.style.width = '876px';
  div.style.font = `${fontWeight} ${size}px "RobotoFlex", sans-serif`;

  div.contentEditable = 'true';
  div.focus();

  const textNode = getTextNodeFrom(div);
  // const textArea = copyRef.current;
  const range = document.createRange();
  range.selectNodeContents(div);
  range.setStart(textNode, selectionEnd);
  range.setEnd(textNode, selectionEnd);
  const selection2 = window.getSelection();
  selection2.removeAllRanges();
  selection2.addRange(range);
  // selection2
  const window2 = div.ownerDocument.defaultView;
  const selection = window2.getSelection();
  if (selection.rangeCount === 0) return false;
  const originalCaretRange = selection.getRangeAt(0);
  if (originalCaretRange.toString().length > 0) return false;
  const originalCaretRect = originalCaretRange.getBoundingClientRect();
  // Create a range at the end of the last text node
  const endOfElementRange = document.createRange();
  endOfElementRange.selectNodeContents(div);
  // The endContainer might not be an actual text node,
  // try to find the last text node inside
  let endContainer = endOfElementRange.endContainer;
  let endOffset = 0;
  while (endContainer.hasChildNodes() && !(endContainer instanceof Text)) {
    endContainer = endContainer.lastChild;
    endOffset = endContainer.length ?? 0;
  }

  endOfElementRange.setEnd(endContainer, endOffset);
  endOfElementRange.setStart(endContainer, endOffset);
  const endOfElementRect = endOfElementRange.getBoundingClientRect();
  document.body.removeChild(div);

  element.focus();
  element.selectionEnd = selectionEnd;

  return originalCaretRect.bottom === endOfElementRect.bottom;
};
