import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPlaylistType } from '../../../../utils/helpers';
import { useQuery } from '../../../../utils/hooks/useQuery';

export const useOpenPlaylist = (status = '') => {
  const history = useHistory();
  const { channelId } = useParams();
  const { id: channelIdFromCurrentPage } = useSelector(
    (state) => state.currentPage,
  );
  const query = useQuery();

  return ({ playlistId, isShared = false, event }) => {
    const queryFromPageType = query?.get('fromPageType');

    let fromPageType = getPlaylistType({
      path: history.location.pathname,
      forMeta: true,
      queryFromPageType,
    });
    const isChannels = history.location.pathname.startsWith('/channel/')
      || queryFromPageType === 'channel';
    // if we have channelId in current history params we shall take it.
    // In another way we shall take it as id of current page
    const isHelp = history.location.pathname.startsWith('/help');
    let _channelId = '';
    if (isHelp) {
      _channelId = 'help';
    }
    if (isChannels || status === 'channel') {
      _channelId = (channelId || channelIdFromCurrentPage);
      fromPageType = 'channel';
    }


    let type = 'edit';
    if (isShared || status === 'shared') {
      type = 'shared';
      fromPageType = 'shared to me';
    }
    if (event?.metaKey || event?.ctrlKey) {
      window.open(
        `${document.location.origin}/maker/${playlistId}/${type}/${_channelId}?fromPageType=${fromPageType}`,
        '_blank', 'noreferrer',
      );
      return;
    }
    history.push({
      pathname: `/maker/${playlistId}/${type}/${_channelId}`,
      search: `?fromPageType=${fromPageType}`,
    });
  };
};
