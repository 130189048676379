import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { actionCreateUpload } from '../../../redux/filesUpload/action';
import { allowedFormatsImage } from '../../../utils/constants';
import { actionEditImagePlaylistREDUX } from '../../../redux/playlists/action';
import { maxFileSize } from '../../../GLOBAL_CONST';
import { CoverDDOptionWrapper } from './styled';

const UploadImage = ({
  startUpload,
  text = 'Upload Photo',
  dataParent,
  setImageUploading,
}) => {
  const history = useHistory();

  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const onButtonClick = () => {
    inputFile.current.click();
    setImageUploading(true);
  };

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const firstFile = e.target.files[0];
    const newId = uuidv4();

    if (firstFile.size > maxFileSize.bytes) {
      // setStep({ type: 'error', info: 'isMore', title: firstFile.name });
    } else if (allowedFormatsImage[firstFile.type]) {
      dispatch(
        actionCreateUpload({
          newId,
          file: firstFile,
          title: firstFile.name,
          type: firstFile.type,
          callback: (url) => {
            actionEditImagePlaylistREDUX(newId, null, url);
            setImageUploading(false);
          },
          history,
        }),
      );
      startUpload(newId);
    }
  };

  return (

    <CoverDDOptionWrapper
      onClick={onButtonClick}
      data-parent={dataParent}
    >
      {text}
      <input
        type="file"
        name="file"
        accept={`image/png,
                    image/jpeg,
                    image/gif,
                    image/svg+xml,
                    image/bmp,
                `}
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={(e) => {
          submitForm(e);
        }}
      />
    </CoverDDOptionWrapper>

  );
};

export default UploadImage;
