import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { empty } from '../../../utils/constants';
import { actionCreator } from '../../../shared/redux/actionHelper';
import SupportAction from '../../../redux/support/types';
import KanbanLeftMenu from './KanbanLeftMenu';
import DashboardColumns from '../../../redux/dashboardColumns/types';
import { ReactComponent as PlusIcon } from '../../../images/icons/icon_12/plusBlack.svg';

const PlusWrapper = styled.div`
  width: ${({ hidden }) => (hidden ? '23px' : '29px')};
  height: ${({ hidden }) => (hidden ? '23px' : '29px')};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: ${({ hidden }) => (hidden ? '#F5F6FF' : '#FFEEBF')};
  }
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'inherit')};
  cursor: pointer;
`;
const Title = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
  margin-left: 30px;
  align-items: center;
  width: 229px;
  margin-bottom: 14px;
`;
const DashboardLeftMenu = ({
  onDragEnter,
  highlightedColumn,
  dragColumnOverId,
  onDropCard,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { structure, closeColumn } = useSelector(
    (state) => state.dashboardColumns || empty,
  );

  const moveHandler = (id) => () => {
    dispatch(actionCreator(SupportAction.JumpToCard, { columnAnchorId: id }));
  };

  const createNew = () => {
    dispatch(actionCreator(DashboardColumns.column.create, {}));
  };

  const onDragEnterRow = (id) => () => {
    onDragEnter(id)();
    moveHandler(id)();
  };
  return (
    <div className={styles.leftMenu}>
      <Title>
        <div className={styles.views_title}>
          <div className={styles.views_text}>{t('columsUpT')}</div>
        </div>
        <PlusWrapper onClick={createNew}>
          <PlusIcon />
        </PlusWrapper>
      </Title>

      <KanbanLeftMenu
        highlightedColumn={highlightedColumn}
        dragColumnOverId={dragColumnOverId}
        onDropCard={onDropCard}
        closeColumn={closeColumn}
        filteredItem={structure}
        onDragEnterRow={onDragEnterRow}
        moveHandler={moveHandler}
      />
    </div>
  );
};

export default DashboardLeftMenu;
