import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DATA_PARENTS, DEFAULT_TITLE, empty } from '../../../utils/constants';
import { Tag } from '../index';
import { ReactComponent as More16Svg } from '../../../images/icons/more_16.svg';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { Tags } from '../../../redux/tags/types';
import { actionChangeTagColor } from '../../../redux/tags/action';
import { checkTagExists } from '../../../utils/helpers';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';

const TagsDropDownItemShell = styled.div`
  width: 100%;
  position: relative;
`;

const Icon = styled.div`
  width: 22px;
  height: 22px;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &:hover {
    background: #ffdf85;
  }

  ${({ active }) => (active ? 'background: #FFC72C;' + '    display: flex;' : '')}
`;

const Number = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  color: #a3aacc;
  ${({ active }) => (active ? 'display: none;' : '')}
`;

const Item = styled.div`
  min-height: 32px;
  padding-right: 12px;
  padding-left: 17px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  color: #242633;

  &:hover {
    background: #ffeebf;
  }

  &:hover ${Number} {
    display: none;
  }

  &:hover ${Icon} {
    display: flex;
  }

  ${({ active }) => (active ? 'background: #FFEEBF;' : '')}
`;

const TagsDropDownItem = ({
  dragPageStart,
  endDrop,
  index,
  tag,
  iconsToShow,
  toggleSideBars,
  isPlaylists = false,
  setPosition,
  selectedTagID,
}) => {
  const { t } = useTranslation();
  const threeDotsRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const setPositionEx = (e) => {
    e.stopPropagation();
    setIsActive(!isActive);
    if (threeDotsRef?.current) {
      setPosition(threeDotsRef.current.getBoundingClientRect(), tag);
    }
  };

  useEffect(() => {
    setIsActive(selectedTagID === tag.id);
  }, [selectedTagID]);
  return (
    <TagsDropDownItemShell
      data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
      onDragStart={(e) => {
        e.stopPropagation();
      }}
      onDragEnd={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <Item
        active={isActive}
        onDragStart={(e) => {
          e.stopPropagation();
          dragPageStart(tag, index + iconsToShow.length);
        }}
        draggable
        onDragEnd={endDrop}
        onClick={(e) => {
          e.stopPropagation();
          toggleSideBars(e, 'tag', tag);
        }}
        data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
      >
        <Tag
          color={tag.color}
          backgroundColor={tag.backgroundColor}
          id={tag.id}
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
        >
          <div
            className={`text ${
              tag.color === tag.backgroundColor ? 'stroke' : ''
            }`}
          >
            {tag.title || t('defaultTagNameT')}
          </div>
        </Tag>
        <Icon
          data-cy="tag-dropDownMenu"
          ref={threeDotsRef}
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          onClick={setPositionEx}
          active={isActive}
          className="show_on_hover"
        >
          <More16Svg data-parent={DATA_PARENTS.PlaylistBuilderLibrary} />
        </Icon>
        <Number active={isActive} className="hide_on_hover">
          {tag[isPlaylists ? 'playlistsCounter' : 'libraryComponentsCounter']
            || 0}
        </Number>
      </Item>
    </TagsDropDownItemShell>
  );
};

export default TagsDropDownItem;
