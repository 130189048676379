import React from 'react';
import './styles.scss';
import { InformerSpinnerDiv } from './styled';

const InformerSpinner = ({ forTable, isAvatar = null, isMaker = null }) => {
  return (
    <InformerSpinnerDiv
      className="informer_loader"
      forTable={forTable}
      isAvatar={isAvatar}
      isMaker={isMaker}
    />
  );
};

export default InformerSpinner;
