import React from 'react';
import styles from './index.module.css';
import check from '../../images/icons/check.svg';
import add from '../../images/icons/add.svg';
import settings from '../../images/icons/settings.svg';
import logout from '../../images/icons/logout.svg';
import avatar from '../../images/icons/avatar.svg';
import duplicate from '../../images/icons/duplicate.svg';
import turnInto from '../../images/icons/turninto.svg';
import moveTo from '../../images/icons/move_to.svg';
import link from '../../images/icons/link.svg';
import color from '../../images/icons/color.svg';
import comment from '../../images/icons/comment.svg';
import createComponent from '../../images/icons/create_component.svg';
import eyeOff from '../../images/icons/eye_off.svg';
import deleteRed from '../../images/icons/delete_red.svg';
import node from '../../images/icons/node.svg';
import folderAdd from '../../images/icons/folder_add.svg';
import pageAdd from '../../images/icons/page_add.svg';
import tag from '../../images/icons/tag.svg';
import chevronRightSmall from '../../images/icons/chevron_right_small.svg';
import editText from '../../images/icons/edit_text.svg';
import cursorDragPage from '../../images/icons/cursor_drag_page.svg';
import closedChanges from '../../images/icons/closed_changes_20.svg';
import lock20 from '../../images/icons/lock_20.svg';
import web20 from '../../images/icons/web_20.svg';
import update from '../../images/icons/Update.svg';

const PreloadIcon = () => (
  <div className={styles.hidden}>
    <img src={lock20} alt="" />
    <img src={web20} alt="" />
    <img src={check} alt="" />
    <img src={add} alt="" />
    <img src={settings} alt="" />
    <img src={logout} alt="" />
    <img src={avatar} alt="" />
    <img src={duplicate} alt="" />
    <img src={tag} alt="" />
    <img src={chevronRightSmall} alt="" />
    <img src={turnInto} alt="" />
    <img src={moveTo} alt="" />
    <img src={editText} alt="" />
    <img src={link} alt="" />
    <img src={color} alt="" />
    <img src={comment} alt="" />
    <img src={createComponent} alt="" />
    <img src={eyeOff} alt="" />
    <img src={deleteRed} alt="" />
    <img src={node} alt="" />
    <img src={folderAdd} alt="" />
    <img src={pageAdd} alt="" />
    <img src={cursorDragPage} alt="" />
    <img src={closedChanges} alt="" />
    <img src={update} alt="" />
  </div>
);

export default PreloadIcon;
