import React from 'react';
import classnames from 'classnames/bind';
import { ReactComponent as PaperClipSvg } from '../../../../images/icons/paperclip_32.svg';
import { readableSizeConverter } from '../../../../utils/helpers';
import rawStyles from './pdfBlock.module.scss';

const styles = classnames.bind(rawStyles);

const PdfAsAttachment = ({
  title,
  size,
  refOption,
  downloadPdf,
  isPreview,
}) => {
  return (
    <div
      className={styles('attachment', 'wrapper', { preview: isPreview })}
      ref={refOption}
    >
      <div className={styles('container')} onClick={downloadPdf}>
        <div className={styles('icon')}>
          <PaperClipSvg />
        </div>
        <div className={styles('text')}>{title}</div>
        <div className={styles('size_text')}>{readableSizeConverter(size)}</div>
      </div>
    </div>
  );
};

export default PdfAsAttachment;
