import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import classnames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevLeftSvg } from '../../../../images/icons/chevron_left_16.svg';
import { ReactComponent as ChevRightSvg } from '../../../../images/icons/chevron_right_16x16.svg';
import rawStyles from './pdfBlock.module.scss';
import { APPURL } from '../../../../environments/environments';

const styles = classnames.bind(rawStyles);

export const loader = (msg, isReadOnly) => {
  if (!isReadOnly) {
    return (
      <div className={styles('loader_container')}>
        <div className={styles('message')}>{msg}</div>
      </div>
    );
  }
};

const PdfAsPreview = ({ height, isReadOnly, ...props }) => {
  const urlSmallImage = props.item?.urlSmallImage || props.state?.urlSmallImage;

  if (urlSmallImage && !props.isNoShowImage) {
    return (
      <div className="preview">
        <div
          className={styles('preview', 'wrapper', { noYMargins: isReadOnly })}
          style={{ minHeight: height }}
        >
          <img
            className={styles('pdfImagePreview')}
            src={urlSmallImage}
            alt={props.alt}
          />
        </div>
      </div>
    );
  }
  return <PdfPreview height={height} isReadOnly={isReadOnly} {...props} />;
};
const PdfPreview = ({
  previewRef,
  height,
  setHeight,
  resizeWidth,
  state,
  save,
  width,
  isReadOnly,
  ...props
}) => {
  const [numPages, setNumPages] = useState(null);
  const { t } = useTranslation();

  const [file, setFile] = useState({
    httpHeaders: {
      'Access-Control-Allow-Origin': `${APPURL}`,
      'Access-Control-Request-Headers': `${APPURL}`,
      'Cache-Control': 'no-cache',
    },
    url: state?.data?.pdfPreviewUrl || state?.data?.urlFile,
  });

  useEffect(() => {
    setFile({
      httpHeaders: {
        'Access-Control-Allow-Origin': `${APPURL}`,
        'Access-Control-Request-Headers': `${APPURL}`,
        'Cache-Control': 'no-cache',
      },
      url: state?.data?.pdfPreviewUrl || state?.data?.urlFile,
    });
  }, [state?.data?.pdfPreviewUrl, state?.data?.urlFile]);

  useEffect(() => {
    if (previewRef) {
      setHeight(previewRef.current.clientHeight);
    }
  }, [previewRef?.current?.clientHeight, file]);

  const [pageNumber, setPageNumber] = useState(state?.data?.startPage || 1);

  async function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf.numPages);
    if (!isReadOnly) {
      setTimeout(() => {
        const innerHtml = previewRef?.current?.innerHTML;
        const newHeight = previewRef?.current?.clientHeight;
        if (newHeight) setHeight(newHeight);
        if (innerHtml) save(state?.data?.nestedItemId, innerHtml, resizeWidth, newHeight);
      }, 100);
    }
  }

  const goPreviousPage = (e) => {
    e.stopPropagation();
    if (pageNumber > 1) {
      setPageNumber((prev) => prev - 1);
    }
  };
  const goNextPage = (e) => {
    e.stopPropagation();
    if (pageNumber < numPages) {
      setPageNumber((prev) => prev + 1);
    }
  };
  return (
    <div className="preview">
      <div
        ref={props.pdfRef}
        className={styles('preview', 'wrapper', { noYMargins: isReadOnly })}
        style={{ minHeight: height }}
      >
        <Document
          className={props.isLibDescription ? styles('is_lib_description') : ''}
          error={t('FailedToLoadAFileT')}
          file={file}
          onLoadSuccess={(data) => onDocumentLoadSuccess(data)}
          inputRef={previewRef}
          loading={() => (props.isListViewPreview && props.loader
            ? props.loader()
            : loader(t('downloadingFilePleaseWaitT'), isReadOnly))
          }
          imageResourcesPath="../../../images/icons/"
        >
          <Page
            pageNumber={pageNumber}
            width={width || resizeWidth}
            loading={() => <></>}
            imageResourcesPath=""
            renderAnnotationLayer={false}
          />
        </Document>
        {!!file && !props.isListView && !props.isLibDescription && !props.isWhiteGallery && (
          <div className={styles('controls_container')}>
            <div className={styles('icon')} onMouseDown={goPreviousPage}>
              <ChevLeftSvg />
            </div>
            <div className={styles('info')}>
              {pageNumber}/{numPages}
            </div>
            <div className={styles('icon')} onMouseDown={goNextPage}>
              <ChevRightSvg />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PdfAsPreview;
