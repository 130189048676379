import axios, { CancelTokenSource, CancelToken } from 'axios';

class AxiosAbortarium {
  private store: { [key: string]: CancelTokenSource } = {};

  generateNew(id: string): void {
    this.store[id] = axios.CancelToken.source();
  }

  getToken(id: string): CancelToken | undefined {
    return this.store[id]?.token;
  }

  getAbort(id: string): (() => void) | undefined {
    return this.store[id]?.cancel;
  }

  checkIfExist(id: string): boolean {
    return !!this.store[id];
  }

  clearUp(id: string): void {
    delete this.store[id];
  }
}

const axiosAbortarium = new AxiosAbortarium();
export { axiosAbortarium };
