import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { empty, openModalType } from '../../utils/constants';
import rawStyles from '../../pages/Playlist/index.module.scss';
import { checkString, checkTagExists } from '../../utils/helpers';
import { ReactComponent as Cross16Svg } from '../../images/icons/close_cross_16.svg';
import {
  actionChangeTagColor,
  actionChangeTagTitle,
} from '../../redux/tags/action';
import { actionOpenModal } from '../../redux/user/action';
import ColorPalette from '../ColorPicker/ColorPalette';
import TagListDropOptionsColorPalette from '../../pages/Playlist/TagListDropOptionsColorPalette';
import { Tag } from '../Tag';

const stylesTag = classnames.bind(rawStyles);

const EditTagWrapperColorPalette = ({ tag, titleRef }) => {
  const dispatch = useDispatch();
  const [localTitle, setLocalTitle] = useState(tag.title);
  const [hasError, setHasError] = useState('');
  const { tags: userTags } = useSelector((redux) => redux.user) || empty;
  const [isPaletteActive, setIsPaletteActive] = useState(false);
  const refColorPickerParent = useRef(null);

  useEffect(() => {
    setLocalTitle(tag.title);
  }, [tag.title]);

  const saveNewTitle = (e) => {
    if (localTitle === tag.title || !isPaletteActive) return;

    if (!localTitle) {
      setHasError('Please type a value');
    } else if (checkTagExists(userTags, localTitle.trim())) {
      setHasError('This tag already exists');
    } else {
      dispatch(
        actionChangeTagTitle({ id: tag.id, newTitle: localTitle.trim() }),
      );
      e.currentTarget.blur();
      setIsPaletteActive(false);
    }
  };
  const onNewTitleKeyDown = (e) => {
    if (e.key === 'Enter') {
      saveNewTitle(e, tag.id);
    }
  };

  const changeItemColor = (e, newColor, mode) => {
    if (mode === 'Text') {
      dispatch(actionChangeTagColor({ id: tag.id, newColor }));
    } else {
      dispatch(
        actionChangeTagColor({ id: tag.id, newBackgroundColor: newColor }),
      );
    }
    e.stopPropagation();
  };

  const handleInputChange = (value) => {
    if (checkString(value)) {
      setLocalTitle(value.replace(/\s+/g, ' '));
    }
  };
  const deleteTagHandler = (event) => {
    event.stopPropagation();
    dispatch(actionOpenModal(openModalType.DeleteTag, { id: tag.id, tagName: tag.title }));
  };

  const handlerOpenMenu = (e) => {
    e.stopPropagation();
    setIsPaletteActive(true);
  };

  return (
    <div className={stylesTag('flex_wrapper')}>
      <ColorPalette
        selectedColor={tag.color}
        selectedBackgroundColor={tag.backgroundColor}
        refColorPickerParent={refColorPickerParent}
        onChangeColor={changeItemColor}
        isPaletteActive={isPaletteActive}
        setIsPaletteActive={setIsPaletteActive}
        element={(
          <TagListDropOptionsColorPalette
            setIsOptionVisible={setIsPaletteActive}
            title={localTitle}
            saveTitle={saveNewTitle}
            onTitleKeyDown={onNewTitleKeyDown}
            onTitleChange={handleInputChange}
            hasError={hasError && tag.title !== localTitle}
            errorText={hasError}
            setHasError={setHasError}
            isLeftSideBar
            itemId={tag.id}
          />
        )}
      >
        <Tag
          color={tag.color}
          backgroundColor={tag.backgroundColor}
          ref={refColorPickerParent}
          className={stylesTag('more_button')}
          onClick={handlerOpenMenu}
        >
          <div
            className={`textHeader ${
              tag.color === tag.backgroundColor ? 'stroke' : ''
            }`}
            ref={titleRef}
          >
            {localTitle}
          </div>

          <div
            className={stylesTag('cross_wrapper')}
            onClick={deleteTagHandler}
          >
            <Cross16Svg />
          </div>
        </Tag>
      </ColorPalette>
    </div>
  );
};

export default EditTagWrapperColorPalette;
