import React from 'react';
import {
  MediaControlBar,
  MediaController,
  MediaPlayButton,
  MediaTimeRange,
  MediaFullscreenButton,
  MediaTimeDisplay,
  MediaVolumeRange,
} from 'media-chrome/dist/react';
import MuxPlayer from '@mux/mux-player-react';
import './index.scss';
import { useSelector } from 'react-redux';
import { ReactComponent as PlaySmallSVG } from '../../../images/2023/svg/mux/play_48_48.svg';
import { ReactComponent as PauseSmallSVG } from '../../../images/icons/player/pause_10_18.svg';
import { DotTyping, InnerWrapper, UnavailableMuxWrapper } from './styled';
import { UiComponentTypes } from '../../../utils/constants';

export const CustomMuxPlayer = ({ item }) => {
  const isAudio = UiComponentTypes.audio[item?.type];
  const preparing = useSelector((state) => state.mux.preparing);
  const theTarget = preparing.find((it) => it.playbackID === item.playbackID);
  if (theTarget) {
    return (
      <UnavailableMuxWrapper>
        <InnerWrapper>
          File successfully uploaded and is processing. Please wait{' '}
          <DotTyping />
        </InnerWrapper>
      </UnavailableMuxWrapper>
    );
  }
  return (
    <MediaController
      autohide={isAudio ? -1 : 2}
      draggable={false}
      className={`
      ${isAudio ? 'custom_audio_controls' : 'video_custom_controls'}
      `}
    >
      <MuxPlayer
        className="turn_off_all"
        slot="media"
        playbackId={item.playbackID}
        preload="auto"
        audio={isAudio}
        minResolution="720p"
        title={item.title}
        crossOrigin=""
      />
      {!isAudio && (
        <MediaPlayButton className="custom_media_btn">
          <PlaySmallSVG slot="play" />
          <PauseSmallSVG slot="pause" className="svg_to_be_colored" />
        </MediaPlayButton>
      )}
      <MediaControlBar>
        {isAudio && (
          <MediaPlayButton className="custom_media_btn">
            <PlaySmallSVG slot="play" />
            <PauseSmallSVG slot="pause" className="svg_to_be_colored" />
          </MediaPlayButton>
        )}
        {/* <MediaSeekBackwardButton /> */}
        {/* <MediaSeekForwardButton /> */}
        <MediaTimeRange draggable={false} />
        {!isAudio && (
          <>
            <MediaTimeDisplay showDuration style={{ backgroundColor: 'transparent' }} />
            <MediaVolumeRange style={{ backgroundColor: 'transparent' }} />
            <MediaFullscreenButton />
          </>
        )}
      </MediaControlBar>
    </MediaController>
  );
};
