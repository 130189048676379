import styled from 'styled-components';

export const SideBarSeparator = styled.div`
  background: #d7ddf7;
  height: 1px;
  width: 16px;
  margin: 4px auto;
`;

export const SideBarTab = styled.div`
  position: ${({ isPage }) => (isPage ? 'absolute' : 'relative')};
  width: 32px;
  height: 32px;
  border: 1px solid transparent;
  border-radius: 8px;
  margin: 0 auto;
  ${({ lowMode }) => (lowMode
    ? `
  height:28px;
  width:28px;
  `
    : '')}
  ${({ isLast, isLibrary }) => (isLast || isLibrary ? 'margin-bottom: 0;' : 'margin-bottom: 4px;')}
  ${({ isLibrary }) => (isLibrary ? 'margin-right: 8px;' : 'margin-right: 0;')}

  ${({ isHiddenItem }) => (isHiddenItem ? 'margin: 0;' : '')}

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive, color, isHiddenItem }) => {
    if (isHiddenItem) return isActive ? '#FFEEBF' : 'transparent';
    return isActive ? color.background : 'transparent';
  }};
  & svg path {
    stroke: ${({ isActive, color, isDynamicCollection }) => {
    if (!isDynamicCollection) return isActive ? color.svgActive : 'rgb(89, 96, 128)';
    return color?.svgActive;
  }};
    fill: transparent;
  }
  ${({ forSecondaryMenu }) => (forSecondaryMenu
    ? `
    display: flex;
    justify-content: center;
    border: 0.6px solid #ECE2E2;
    border-radius: 6px;
       svg path {
    stroke:#484343;
    }

  `
    : '')}

  ${({ isActive }) => (isActive ? '    border: 0.6px solid #FFC72C;' : 'background:white;')}
  &:hover {
    background-color: ${({ color, isHiddenItem }) => {
    if (isHiddenItem) return '#FFEEBF';
    return color.background;
  }};

    & svg path {
      stroke: ${({ color }) => {
    return color?.svgActive || '#242633';
  }};
    }

    border: 0.6px solid #ffc72c;
  }
`;
