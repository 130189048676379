import axios from 'axios';
import { BASEURL } from '../../../environments/environments';

export const checkMediaStatus = async (assetID, id) => {
  try {
    const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
    const { data } = await axios.get(
      `${BASEURL}/v1/library/media_status/${assetID}/${id}`,
      {
        headers: {
          token: token ? `${token}` : '',
        },
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};
