import React from 'react';
import classNames from 'classnames/bind';
import { UiComponentTypes } from '../../constants';
import styles from './helpers.module.scss';
import { ReactComponent as GoogleDrive16Svg } from '../../../images/icons/google_drive_16.svg';
import { ReactComponent as Dropbox16Svg } from '../../../images/icons/dropbox_16.svg';
import { ReactComponent as LinkBadge20Svg } from '../../../images/2023/svg/small/player/type_icons/link_icon.svg';
import { ReactComponent as PdfBadge20Svg } from '../../../images/2023/svg/small/player/type_icons/pdf_icon.svg';
import { ReactComponent as VideoBadge20Svg } from '../../../images/2023/svg/small/player/type_icons/video_icon.svg';
import { ReactComponent as PptBadge20Svg } from '../../../images/icons/presentation_element_TOC_20.svg';
import { ReactComponent as PageBadge20Svg } from '../../../images/2023/svg/small/player/type_icons/page_icon.svg';
import { ReactComponent as ImageBadge20Svg } from '../../../images/2023/svg/small/player/type_icons/image_icon.svg';
import { ReactComponent as MsBadge20Svg } from '../../../images/icons/word_element_TOC_20.svg';
import { ReactComponent as YoutubeBadge20Svg } from '../../../images/icons/youtube_element_TOC_20.svg';
import { ReactComponent as Placeholder20Svg } from '../../../images/icons/placeholder_element_TOC_20.svg';
import { ReactComponent as TextSvg } from '../../../images/2023/svg/small/player/type_icons/text_icon.svg';
import { ReactComponent as EmbedSvg } from '../../../images/2023/svg/small/player/type_icons/embed_icon.svg';
import { ReactComponent as Upload20Svg } from '../../../images/icons/upload_20.svg';

const cn = classNames.bind(styles);

export const Icons = ({ type, libraryComponent }) => {
  if (type === 'elementText') {
    return <TextSvg />;
  }
  const libraryComponentType = libraryComponent?.type;
  // if (UiComponentTypes.component[libraryComponentType]) return <></>;
  if (UiComponentTypes.google_embed_component[libraryComponentType]) {
    return <GoogleDrive16Svg style={{ width: 20, height: 20 }} />;
  }
  if (UiComponentTypes.dropbox_embed_component[libraryComponentType]) {
    return <Dropbox16Svg style={{ width: 20, height: 20 }} />;
  }
  if (UiComponentTypes.link[libraryComponentType]) return <LinkBadge20Svg />;
  if (UiComponentTypes.pdf[libraryComponentType]) return <PdfBadge20Svg />;
  if (UiComponentTypes.media[libraryComponentType]) return <VideoBadge20Svg />;
  if (UiComponentTypes.presentation[libraryComponentType]) return <PptBadge20Svg />;
  if (UiComponentTypes.page[libraryComponentType]) return <PageBadge20Svg />;
  if (UiComponentTypes.image[libraryComponentType]) return <ImageBadge20Svg />;
  if (UiComponentTypes.ms[libraryComponentType]) return <MsBadge20Svg />;
  if (UiComponentTypes.youtube[libraryComponentType]) return <YoutubeBadge20Svg />;
  if (!type) return <Placeholder20Svg />;
  if (type === 'upload') return <Upload20Svg className={cn('upload_20')} />;
  return <div className={cn('no_icon_placeholder')} />;
};
