import React from 'react';

import { DATA_PARENTS } from '../../../utils/constants';

import { ReactComponent as FavoriteSvg } from '../../../images/2023/svg/small/favorite_16_16.svg';

import styles from '../index.module.css';

const FavoriteIcon = ({ isFavorite, handleToggleFavorite, bigSizeCondition, isHoveredAction }) => (
  <div
    data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
    className={`
                    ${styles.itemMenu}
                    ${isFavorite ? styles.favorite : styles.unfavorite}
                    ${styles.favoriteIcon}
                    ${isHoveredAction ? '' : styles.notHovered}
                    ${bigSizeCondition ? styles.favoriteIcon_page : ''}
                  `}
    onClick={handleToggleFavorite}
  >
    <FavoriteSvg
      className={`${styles.star_icon}`}
      data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
    />
  </div>
);

export default FavoriteIcon;
