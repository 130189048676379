import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moduleStyles from '../../pages/Library/LibraryHeader.module.scss';
import { ReactComponent as FavoriteHeaderSvg } from '../../images/icons/favorites20.svg';
import { ReactComponent as Upload16Svg } from '../../images/icons/upload_16.svg';
import recentHeader from '../../images/icons/recent_20.svg';
import { actionRenameLibraryCollection } from '../../redux/library/actions';
import search from '../../images/icons/search.svg';
import AddFilePopUp from './childComponents/AddFilePopUp';
import { empty } from '../../utils/constants';
import Tooltip from '../../shared/Tooltips/Tooltip';
import NavSlider from '../NavigationSlider/NavSlider';
import { PlaylistButton } from '../Buttons/ButtonComponent';
import DynamicCollectionCreator from '../DynamicCollectionCreator/DynamicCollectionCreator';
import { ReactComponent as RoundedCross20Svg } from '../../images/icons/circle_cross_20.svg';
import SortAndView from './childComponents/SortAndView';
import { DynamicCollectionSave } from '../DynamicCollectionCreator/TopPart';
import EditTagWrapperColorPalette from './EditTagWrapperColorPalette';

const styles = classNames.bind(moduleStyles);

const LibraryHeader = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectorType, folderId, tags } = useSelector((state) => state.user);
  const { isShowLibraryWidget } = useSelector((state) => state.currentPage);
  const { componentDescription } = useSelector((state) => state.support);
  const { id: metaId } = useSelector(
    (reduxState) => reduxState.support.componentDescriptionData || empty,
  );

  return (
    <div
      className={styles('header_container', {
        isSideBarOpened: isShowLibraryWidget || metaId,
      })}
    >
      <div
        className={styles('header', 'topBallancer', {
          rows: !props.isCardsView,
        })}
      >
        <div className={styles('title_or_search')}>
          {selectorType === 'favorites' && (
            <FavoriteHeaderSvg
              className={styles('folder_icon', {
                favorite: selectorType === 'favorites',
              })}
            />
          )}
          {selectorType === 'recent' && (
            <img
              className={styles('folder_icon')}
              src={recentHeader}
              alt="is recent"
            />
          )}

          {selectorType !== 'tag' && (
            <div className={styles('title_input_wrapper')}>
              <Tooltip
                text={props.collections.title}
                direction="up"
                place="manyPagesHeader"
                isShow={
                  !(
                    props.isComponentRename
                    && props.isComponentRename !== 'close'
                  )
                  && selectorType === 'folder'
                  && props.titleRef?.current?.getBoundingClientRect()?.width > 455
                }
              >
                <div
                  ref={props.titleRef}
                  className={styles({
                    title: selectorType !== 'folder',
                    hide:
                      props.isComponentRename
                      && props.isComponentRename !== 'close',
                  })}
                  onClick={props.onTitleClickHandler}
                >
                  {props.title || t('unnamedFolderT')}
                </div>
              </Tooltip>

              {props.isComponentRename
                && props.isComponentRename !== 'close' && (
                  <div
                    className={styles('folderTitleInputWrapper')}
                    style={{ width: props.width }}
                  >
                    <input
                      style={{ width: props.width }}
                      placeholder={t('folderTitleT')}
                      maxLength={55}
                      value={props.tmpTitle}
                      autoFocus
                      onChange={props.onChangeFolderTitle}
                      onKeyDown={props.onKeyPressFolderTitle}
                      className={styles('input2')}
                      onBlur={() => {
                        dispatch(
                          actionRenameLibraryCollection({
                            id: folderId,
                            name: props.tmpTitle,
                          }),
                        );
                        props.setIsComponentRename(false);
                      }}
                    />
                  </div>
              )}
            </div>
          )}

          {selectorType === 'tag' && (
            <div className={styles('title_input_wrapper')}>
              <Tooltip
                text={props.collections.title}
                direction="up"
                place="manyPagesHeader"
                isShow={
                  !(
                    props.isComponentRename
                    && props.isComponentRename !== 'close'
                  )
                  && selectorType === 'folder'
                  && props.titleRef?.current?.getBoundingClientRect()?.width > 455
                }
              >
                <EditTagWrapperColorPalette
                  titleRef={props.titleRef}
                  tag={tags[folderId] || {}}
                  isLibrary
                />
              </Tooltip>
            </div>
          )}

          {props.showHeaderDetails && (
            <Tooltip
              text={t('searchInLibraryTooltipT')}
              direction="left"
              place="libraryHeaderFullscreen"
            >
              <div
                onClick={() => {
                  props.setFilter('');
                  props.setIsShowFilter(!props.isShowFilter);
                }}
                className={styles('search_icon_wrapper')}
              >
                <img
                  className={styles('search_icon')}
                  src={search}
                  alt="search"
                />
              </div>
            </Tooltip>
          )}
        </div>
        <input
          type="file"
          name="file"
          multiple
          ref={props.inputFile}
          style={{ display: 'none' }}
          onChange={(e) => {
            props.submitForm(e);
          }}
        />
        <div className={styles('top_sorting_and_add_file')}>
          <SortAndView {...props} />

          <div
            className={styles('detailsContainer')}
            ref={props.addFileParentRef}
          >
            <div className={styles('options')}>
              <div className={styles('button_parent_width')}>
                <PlaylistButton
                  data-parent={props.dataParent}
                  clickCb={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.setIsAddFileMenuVisibleHeader(
                      !props.isAddFileMenuVisibleHeader,
                    );
                  }}
                >
                  <Upload16Svg
                    data-parent={props.dataParent}
                    className={styles('upload_button')}
                  />
                  <div data-parent={props.dataParent}>
                    {!props.hasActiveUploads && t('uploadFileUpT')}
                    {props.hasActiveUploads && t('uploadingUpT')}
                    {props.hasActiveUploads && (
                      <div
                        style={{ width: Math.ceil(props.progress * 125) }}
                        className={styles('upload_line')}
                      />
                    )}
                  </div>
                </PlaylistButton>
              </div>
            </div>
          </div>
        </div>

        {props.isAddFileMenuVisibleHeader && (
          <AddFilePopUp
            parentRef={props.addFileParentRef}
            wrapperRef={props.addFileRefHeader}
            onButtonClick={props.onButtonClick}
            setIsAddFileMenuVisible={props.setIsAddFileMenuVisibleHeader}
          />
        )}
      </div>
      {props.isShowFilter && props.showHeaderDetails && (
        <div
          className={styles('resize_wrapper', {
            isTag: selectorType === 'tag',
            show_description: isShowLibraryWidget || metaId,
          })}
        >
          <div className={styles('search', 'search_max_size')}>
            <div
              className={styles(
                'search_input_wrapper',
                'isPlaylists',
                'allLibraryHeader',
              )}
            >
              <input
                value={props.libFilterSearch}
                onChange={(e) => props.setLibFilterSearch(e.target.value)}
                placeholder={`Search in ${props.title}`}
                onMouseDown={(e) => e.stopPropagation()}
                autoFocus
                className={styles('search_input', 'isPlaylists')}
              />
              {!!props.libFilterSearch && (
                <RoundedCross20Svg
                  className={styles('clear_search', 'isPlaylists')}
                  onClick={() => {
                    props.setLibFilterSearch('');
                  }}
                />
              )}
            </div>
          </div>
          {selectorType === 'all' && (
            <div className={styles('save_search_wrapper')}>
              <DynamicCollectionSave {...props} isLibrary />
            </div>
          )}
        </div>
      )}
      {props.showHeaderDetails && !props.isShowFilter && (
        <div
          className={styles('slider_and_sort_container', 'orders', {
            collapsible: componentDescription,
          })}
        >
          <div
            className={styles('slider_parent', {
              collapsible: componentDescription,
            })}
          >
            <NavSlider
              setSieve={props.setSieve}
              setActiveNavSlider={props.setActiveNavSlider}
              activeNavSlider={props.activeNavSlider}
              usage={props.isChannels ? 'channels' : 'library'}
              isCardView={props.isCardsView}
              items={props.items}
              totalSubscribers={props.totalSubscribers}
              sieveOptions={props.sieveOptions}
              isBottomMargin
              isNoLeftMargin
            />
          </div>
          <div
            className={styles('sorting_parent', {
              listView: !props.isCardsView,
            })}
          />
        </div>
      )}

      {props.showHeaderDetails
        && props.isShowFilter
        && selectorType === 'all' && (
          <div
            className={styles('wrapper_dynamicCollection', {
              show_description: isShowLibraryWidget || metaId,
            })}
          >
            <DynamicCollectionCreator
              isAllLibraryItems={selectorType === 'all'}
              libFilterSearch={props.libFilterSearch}
              isFullScreen
              isShowFilter
              filter={props.filter}
              isHasSomeFilter={
                (props.filter.tags
                  && Object.values(props.filter.tags || {}).length)
                || (props.filter.fileType
                  && Object.values(props.filter.fileType || {}).length)
                || (props.filter.createDate
                  && Object.values(props.filter.createDate || {}).length)
              }
              setFilter={props.setFilter}
              setIsShowFilter={props.setIsShowFilter}
            />
          </div>
      )}

      {/* {(isShowSorting() && showHeaderDetails) && ( */}
      {/*  <div className={styles('orders', { rows: !isCardsView })}> */}
      {/*    <Filters */}
      {/*      isChannels={isChannels} */}
      {/*      clearAllFilters={props.clearAllFilters} */}
      {/*      showFilterBar={props.showFilterBar} */}
      {/*    /> */}

      {/*    /!* <div className={styles('filters')}> *!/ */}
      {/*    /!*  <FilterSvg /> *!/ */}
      {/*    /!*  Filters *!/ */}
      {/*    /!* </div> *!/ */}

      {/*    <div className={styles('sort_and_view')}> */}
      {/*      {isCardsView && ( */}
      {/*        <div className={styles('sort_wrapper')}> */}

      {/*          <div */}
      {/*            ref={refShowMenuSort} */}
      {/*            onClick={() => setShowMenuSort(!isShowMenuSort)} */}
      {/*            className={`${styles('sort')} ${isShowMenuSort ? styles('sort_active') : ''} `} */}
      {/*          > */}
      {/*            <img src={sort} alt="sort" /> */}
      {/*            <div> {sortTitleMapping[sortType]} </div> */}

      {/*            {isShowMenuSort && ( */}

      {/*              <SortOption sortType={sortType} setSort={setSort} /> */}

      {/*            )} */}

      {/*          </div> */}
      {/*        </div> */}
      {/*      )} */}

      {/*      <Tooltip */}
      {/*        text={`${isCardsView ? 'Grid' : 'List'} view`} */}
      {/*        direction="up" */}
      {/*        place="libraryHeaderFullscreenCardList" */}
      {/*      > */}
      {/*        <div className={styles('view')} onClick={showCardView}> */}
      {/*          {isCardsView && activeNavSlider !== ChannelsSieveTypes.ChannelsUsers */}
      {/*            ? <img src={grid} alt="view" /> */}
      {/*            : <img src={list} alt="list" />} */}
      {/*        </div> */}
      {/*      </Tooltip> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* )} */}
    </div>
  );
};

export default LibraryHeader;
