import { useSelector } from 'react-redux';
import React from 'react';
import styled from 'styled-components/macro';
import { empty } from '../../../utils/constants';
import SharedComponent from '../../../components/SharedComponent';

const ElementTextContentShell = styled.div`
  background: ${({ isFullScreenMode }) => (isFullScreenMode ? '#242633' : '')};
  max-width: 1000px;
  //height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isPlayerTOCPreview, isCardView }) => {
    if (isPlayerTOCPreview && isCardView) {
      return `
        width: 100%;
        height: 100%;
      `;
    }
    if (isPlayerTOCPreview && !isCardView) {
      return `
        width: 100%;
        height: 100%;
      `;
    }
    return '';
  }}
`;
export const ElementTextContent = ({
  item,
  imageRef,
  isPlayerTOCPreview,
  isPlayerPreview,
  isCardView,
}) => {
  const {
    playerSettings: { isFullScreenMode },
  } = useSelector(state => state.user || empty);

  const textComponent = Array.isArray(item.textComponent)
    ? item.textComponent[0]
    : item.textComponent;

  return (
    <ElementTextContentShell
      ref={imageRef}
      isPlayerTOCPreview={isPlayerTOCPreview}
      isPlayerPreview={isPlayerPreview}
      isFullScreenMode={isFullScreenMode}
    >
      <SharedComponent
        isPlayerTextElement
        isFullScreenMode={isFullScreenMode}
        state={textComponent.state}
        isCardView={isCardView}
        isPlayerTOCPreview={isPlayerTOCPreview}
        page={null}
      />
    </ElementTextContentShell>
  );
};
