import { useState, useEffect } from 'react';

type CallbackFunction = any;

export default function useThrottle(time: number = 400): [(callback: CallbackFunction) => void] {
  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined);
  const [callback, setCallback] = useState<CallbackFunction>(() => () => {});

  useEffect(() => {
    if (callback) {
      if (timeoutId) clearTimeout(timeoutId);
      // @ts-ignore
      setTimeoutId(setTimeout(() => { callback(); }, time));
    }
  }, [callback]);

  return [setCallback];
}
