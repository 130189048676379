import React from 'react';
import styled from 'styled-components/macro';
import { sanitizeHtml } from '../../../../utils/helpers';

const Tooltip = styled.div`
  position: absolute;
  bottom: -12px;
  opacity: 0;
  transition: opacity ease-out 0.15s;
  height: 24px;
  padding: 0px 8px;
  width: auto;
  white-space: nowrap;
  background-color: #ffffff;
  border: 1px solid #d7ddf7;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #596080;
  //padding: 1px;
`;

const WithSimpleTooltipShell = styled.div`
  transition: opacity ease-out 0.15s;
  cursor: default;
  position: relative;
  &:hover ${Tooltip} {
    opacity: 1;
  }
`;

const WithSimpleTooltip = ({ children, text }) => {
  return (
    <WithSimpleTooltipShell>
      {children}
      <Tooltip>{sanitizeHtml(text)}</Tooltip>
    </WithSimpleTooltipShell>
  );
};

export default WithSimpleTooltip;
