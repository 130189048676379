import EditPlaylist from './types';

export const actionSavePlaylists = (playlists) => ({
  type: EditPlaylist.Get,
  payload: playlists,
});
export const actionAddPlaylists = (playlist) => ({
  type: EditPlaylist.Add,
  payload: playlist,
});
export const actionUpdatePlaylistTitle = (playlist) => ({
  type: EditPlaylist.Update,
  payload: playlist,
});

export const actionGetAllPublishPlaylist = (step) => ({
  type: EditPlaylist.GetPublish,
  payload: { step },
});

export const AddAllDownloadableItemsIntoLibrary = (items) => ({
  type: EditPlaylist.AddAllDownloadableItemsIntoLibrary,
  payload: { items },
});

export const actionDeletePlaylist = (id) => ({
  type: EditPlaylist.Delete,
  payload: { id },
});
export const actionUpdatePlaylist = (component) => {
  return {
    type: EditPlaylist.UpdatePlaylist,
    payload: { ...component },
  };
};

export const actionPlaylistMoveToTrash = (id, useCases, wrapperId) => ({
  type: EditPlaylist.updateMoveToTrash,
  payload: { id, state: true, useCases, wrapperId },
});
export const actionCreatePageAndAddToBuilder = (
  playlistId,
  history,
  replaceElement,
  isFavorite,
  playlistTitle,
) => ({
  type: EditPlaylist.CreatePageAndAddToBuilder,
  payload: { playlistId, history, replaceElement, isFavorite, playlistTitle },
});
export const actionChangeTextElementBlockRedux = (
  blockId,
  newState,
  innerHtml,
  playlistId,
  linkPageId,
  type,
) => ({
  type: EditPlaylist.ChangeTextElementBlockRedux,
  payload: { blockId, newState, innerHtml, playlistId, linkPageId, type },
});
export const actionChangeTextElementBlockReduxMaker = (
  blockId,
  newState,
  innerHtml,
  playlistId,
  linkPageId,
  type,
) => ({
  type: EditPlaylist.ChangeTextElementBlockReduxMaker,
  payload: { blockId, newState, innerHtml, playlistId, linkPageId, type },
});

export const actionPublishPlaylistS = (
  addedNew,
  selectedColumn,
  playlist,
  dateState,
  isSharedOnDrop,
) => ({
  type: EditPlaylist.Publish,
  payload: { addedNew, selectedColumn, playlist, dateState, isSharedOnDrop },
});

export const actionUnPublishSPlaylistS = (playlistId, wrapperId) => ({
  type: EditPlaylist.UnPublishS,
  payload: { playlistId, wrapperId },
});

export const actionEditPublishStateR = (playlistId, isPublish, inputs) => ({
  type: EditPlaylist.EditPublishStateR,
  payload: { playlistId, isPublish, inputs },
});

export const actionPlaylistReMoveToTrash = (id, block, index) => ({
  type: EditPlaylist.updateMoveToTrash,
  payload: { id, state: false, block, index },
});

export const actionPlaylistToggleFavorite = (
  id,
  isFavorite,
  channelId,
  block,
  index,
) => ({
  type: EditPlaylist.ToggleFavorite,
  payload: { id, isFavorite, channelId, block, index },
});
export const actionToggleIsShowDescriptionPlaylist = (
  id,
  isShowDescription,
) => ({
  type: EditPlaylist.ToggleIsShowDescriptionPlaylist,
  payload: { id, isShowDescription },
});

export const actionEditTitlePlaylist = (id, title, isCurrentPage) => ({
  type: EditPlaylist.EditTitle,
  payload: { id, title, isCurrentPage },
});

export const actionEditDescriptionPlaylistS = (id, description) => ({
  type: EditPlaylist.EditDescriptionS,
  payload: { id, description },
});
export const actionEditDescriptionPlaylistR = (id, description) => ({
  type: EditPlaylist.EditDescriptionR,
  payload: { id, description },
});
// ?? is it working
// export const actionEditImagePlaylist = (id, imgUrl) => ({
//   type: EditPlaylist.EditImage,
//   payload: { id, imgUrl },
// });

export const actionSaveShareStatePlaylist = (id, field, state) => {
  return {
    type: EditPlaylist.EditShareState,
    payload: { id, field, state },
  };
};

export const actionEditComplementaryPlaylist = (
  parentId,
  addIdsPlaylist,
  removeIdsPlaylist,
) => {
  return {
    type: EditPlaylist.EditComplementaryPlaylists,
    payload: { parentId, addIdsPlaylist, removeIdsPlaylist },
  };
};

// export const actionAddEditableBlockPlaylist = (id, type, pageId, isClone, isWidget, eraseContent) => {
//   return {
//     type: EditPlaylist.AddEditableBlock,
//     payload: { id, type, pageId, isClone, isWidget, eraseContent },
//   };
// };
//
// export const actionDeleteEditableBlockPlaylist = (id, type, pageId) => {
//   return {
//     type: EditPlaylist.DeleteEditableBlock,
//     payload: { id, type, pageId },
//   };
// };

export const actionAddImagePlaylist = (position, componentId, img, crop) => {
  return {
    type: EditPlaylist.AddImage,
    payload: { position, componentId, img, crop },
  };
};

export const actionChangeDefaultColorPlaylist = (defaultPreviewColor, componentId) => {
  return {
    type: EditPlaylist.ChangeDefaultColor,
    payload: { defaultPreviewColor, componentId },
  };
};
export const actionEditImagePlaylistREDUX = (id, folderId, src) => {
  return {
    type: EditPlaylist.EditImageREDUX,
    payload: { id, folderId, src },
  };
};

export const actionRemoveImage = () => {
  return {
    type: EditPlaylist.DeleteImage,
  };
};

export const actionRemoveImageRedux = () => {
  return {
    type: EditPlaylist.DeleteImageRedux,
  };
};
export const actionDuplicatePlaylist = (
  playlistId,
  newId,
  itemsSelectedId,
) => ({
  type: EditPlaylist.DuplicatePlaylist,
  payload: { playlistId, newId, itemsSelectedId },
});
export const actionDuplicateAndSwitchPlaylist = (
  playlistId,
  newId,
  history,
  itemsSelectedId,
) => ({
  type: EditPlaylist.DuplicateAndSwitchPlaylist,
  payload: { playlistId, newId, history, itemsSelectedId },
});

export const actionPlaylistChannelPublishManagement = (channels, playlist) => ({
  type: EditPlaylist.PlaylistChannelPublishManagement,
  payload: { channels, playlist },
});

export const actionAddPlaylistToChannelR = (channel, playlist, channels) => ({
  type: EditPlaylist.AddPlaylistToChannelR,
  payload: { channel, playlist, channels },
});
export const actionRemovePlaylistFromChannelR = (channel, playlist) => ({
  type: EditPlaylist.RemovePlaylistFromChannelR,
  payload: { channel, playlist },
});

export const actionCreatePlaylistWithLibItems = (
  history,
  libComponentsIds,
  playlistId,
) => ({
  type: EditPlaylist.CreatePlaylistFromLib,
  payload: { history, libComponentsIds, playlistId },
});

export const actionShareMultiplePlaylists = () => ({
  type: EditPlaylist.ShareMultiplePlaylistsS,
});

export const actionEditPlaylistSingleStateUser = (payload) => ({
  type: EditPlaylist.editShareStatePlaylistSingleUser,
  payload,
});

export const actionGetUsersToSharingPl = ({ type }) => ({
  type: EditPlaylist.getUsersToSharingPl,
  payload: { type },
});

export const actionUpdateReadState = (id, isViewed, linkPageId) => ({
  type: EditPlaylist.UpdateReadStateLinkPage,
  payload: { isViewed, id, linkPageId },
});

export const actionUpdatePlaylistReadStatus = (value, wrapperId, playlistId, channelId) => ({
  type: EditPlaylist.MarkViewedS,
  payload: { value, playlistId, channelId, wrapperId },
});
