import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  CoverDDDivider,
  CoverDDOptionWrapper,
  CoverDDTitle,
  CoverDropDownWrapper,
} from './styled';
import { actionOpenModal } from '../../../redux/user/action';
import UploadImage from './UploadImage';
import { openModalType, stopPropagation } from '../../../utils/constants';

const DropDownCover = ({ nestedRef,
  setUploadId,
  position,
  setIsComponentVisible,
  setImageUploading,
  dataParentDropDown,
  setCurrentColor,
}) => {
  const { img, cropUrl } = useSelector((state) => state.currentPage);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const startUpload = (id) => {
    setIsComponentVisible(false);
    setUploadId(id);
  };

  const openModal = () => {
    dispatch(actionOpenModal(openModalType.AddImageToPlaylist));
    setIsComponentVisible(false);
  };

  const editHandler = () => {
    dispatch(
      actionOpenModal(openModalType.CropPlaylistCover, {
        componentId: img.id,
        imagePath: img.urlFile,
      }),
    );

    setIsComponentVisible(false);
  };

  const deleteImage = () => {
    dispatch(actionOpenModal(openModalType.DeleteImageToPlaylist));
    setCurrentColor('yellow');
    setIsComponentVisible(false);
  };
  return (
    <CoverDropDownWrapper
      position={position}
      data-parent={dataParentDropDown}
      ref={nestedRef}
      onClick={stopPropagation}
    >
      <CoverDDTitle>{t('uploadCoverLowT')}</CoverDDTitle>
      <CoverDDOptionWrapper onClick={openModal}>{t('fromYourLibraryLowT')}</CoverDDOptionWrapper>
      <UploadImage
        dataParent={dataParentDropDown}
        text={t('fromYourComputerMobileLowT')}
        startUpload={startUpload}
        setImageUploading={setImageUploading}
      />

      {cropUrl && (
        <>
          <CoverDDOptionWrapper onClick={editHandler}>{t('resizeT')}</CoverDDOptionWrapper>
          <CoverDDDivider />
          <CoverDDOptionWrapper isRed onClick={deleteImage}>{t('removeT')}</CoverDDOptionWrapper>
        </>
      )}
    </CoverDropDownWrapper>
  );
};

export default DropDownCover;
