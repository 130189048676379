import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import { DATA_PARENTS } from '../../utils/constants';
import Button2023 from '../Buttons/Button2023';

const cx = classNames.bind(styles);

const DeleteDialog = ({
  title,
  message,
  okText = 'Delete',
  cancelText = 'Cancel',
  deleteCb,
}) => {
  const dispatch = useDispatch();

  const { dataPayload } = useSelector((redux) => redux.user);
  const close = () => {
    dispatch(actionCloseModal());
  };

  const deleteItem = () => {
    close();
    if (dataPayload.deleteCb) {
      dataPayload.deleteCb();
    } else if (dataPayload.id && !dataPayload.nextItem?.id) {
      deleteCb(dataPayload.id, dataPayload.tagName);
    } else if (dataPayload.tagName) {
      deleteCb(dataPayload, dataPayload.tagName);
    } else if (dataPayload.nextItem) {
      deleteCb(dataPayload.id, dataPayload.nextItem.id);
    }
    if (dataPayload.closeDynamicTab) {
      dataPayload.closeDynamicTab();
    }
    if (dataPayload.nextItem && dataPayload.history) {
      const newPath = dataPayload.nextItem.id
        ? `${dataPayload.nextItem.path}${dataPayload.nextItem.id}` : dataPayload.nextItem.path;
      dataPayload.history.push(newPath);
    }
  };
  return (
    <div onClick={close} className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'DeleteTag')}
      >
        <div className={cx('title')}>{title}</div>

        {!!message && <div className={cx('sub_title')}>{message}</div>}

        <div className={cx('group_buttons')}>
          <Button2023
            variant="primary"
            derivedParentData={DATA_PARENTS.PlaylistBuilderLibrary}
            height={32}
            noMargins
            text={cancelText}
            handleButtonClick={close}
          />
          <Button2023
            variant="secondary"
            derivedParentData={DATA_PARENTS.PlaylistBuilderLibrary}
            height={32}
            text={okText}
            noMargins
            handleButtonClick={deleteItem}
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteDialog;
