import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CheckBoxOffSvg } from '../../../../images/icons/checkbox_off.svg';
import { ReactComponent as CheckBoxOnSvg } from '../../../../images/icons/checkbox_on.svg';
import { DATA_PARENTS, stopPropagation } from '../../../../utils/constants';

const CheckboxWrapper = styled.div`
  overflow: hidden;
  .svg_wrapper {
    top: 7px;
    left: 10px;
    width: 28px;
    height: 28px;
    position: absolute;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg path {
    stroke: rgb(52, 52, 52);
  }
  &:hover {
    .svg_wrapper {
      background-color: #ffdf85;
    }
  }

  .index_wrapper {
    min-width: 15px;
    display: flex;
    justify-content: flex-end;
  }
`;

const HandlerWrapper = styled.div`
   ${({ isDisabled }) => (isDisabled ? 'opacity: 0.15; cursor: not-allowed; ' : '')}
`;

const CheckboxSection = React.memo(function CheckboxSection({
  selectItem,
  isSelected,
  itemId,
  isDisabled,
  handleShowPannel,
}) {
  const handleSelectItem = (e) => {
    e.stopPropagation();
    selectItem(itemId);
    handleShowPannel(e);
  };
  return (
    <CheckboxWrapper>
      <HandlerWrapper
        data-parent={DATA_PARENTS.checkItem}
        className="svg_wrapper"
        onClick={isDisabled ? stopPropagation : handleSelectItem}
        isDisabled={isDisabled}
      >
        {isSelected ? (
          <CheckBoxOnSvg className="checkbox_svg" />
        ) : (
          <CheckBoxOffSvg className="checkbox_svg" />
        )}
      </HandlerWrapper>
    </CheckboxWrapper>
  );
});

export default CheckboxSection;
