import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import TimeZoneSelector from './TimeZoneSelector';
import DateFormattingSelector from './DateFormatingSelector';
import LanguageSelector from './LanguageSelector';
import TimeFormatSelector from './TimeFormatSelector';
import { Column, Line, SectionShell, HeaderTitle } from '../../CommonComponents/Layout';

const cn = classNames;

const PreferencesLanguage = () => {
  const { t } = useTranslation();

  return (
    <SectionShell paddingForSetting>
      <HeaderTitle marginBottom32>{t('languageT')}</HeaderTitle>
      <Line Language>
        <div className={cn(styles.label)}>
          {t('languageAndRegionT')} :
        </div>
      </Line>

      <Line Language>
        <Column Language>
          <div className={cn(styles.text)}>{t('languageT')}</div>
        </Column>
        <Column Language>
          <LanguageSelector />
        </Column>
      </Line>

      <Line Language>
        <Column Language>
          <div className={cn(styles.text)}>{t('timeZoneT')}</div>
        </Column>
        <Column Language>
          <TimeZoneSelector />
        </Column>
      </Line>

      <Line isDisable Language secondSection>
        <div className={cn(styles.label, styles.marginTop)}>
          {t('formatT')} :
        </div>
      </Line>

      <Line isDisable Language>
        <Column Language>
          <div className={cn(styles.text)}>{t('dateT')}</div>
        </Column>
        <Column Language>
          <DateFormattingSelector />
        </Column>
      </Line>

      <Line isDisable Language>
        <Column Language>
          <div className={cn(styles.text)}>{t('hourT')}</div>
        </Column>
        <Column Language>
          <TimeFormatSelector />
        </Column>
      </Line>
    </SectionShell>
  );
};

export default PreferencesLanguage;
