import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import styles from './MediaAdd.module.scss';
import { actionCancelUpload } from '../../redux/filesUpload/action';
import BlockImageAddStart from './MediaAddPopup';
import BlockImageError from './BlockImageError';
import BlockImageUpload from './BlockImageUpload';

const cx = classNames.bind(styles);
const hasError = { error: true };
const BlockImageAdd = ({
  index,
  modifier,
  id,
  playlistField,
  isLibraryCreate,
  libCompId,
  isSharedComponent,
  position,
  nextItemPosition,
}) => {
  const [step, setStep] = useState({ type: 'start' });
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.uploads.components);
  const uploadData = upload[libCompId || id] || hasError;

  const cancel = () => {
    if (step.type === 'loading') dispatch(actionCancelUpload(step.newId));
    setStep({ type: 'start' });
  };
  useEffect(() => {
    if (uploadData.error) setStep({ type: 'start' });
    else if (step.type === 'start' && uploadData.type) {
      setStep({
        type: 'loading',
        newId: uploadData.newId,
        title: uploadData.title,
        size: uploadData.file?.size,
      });
    }
  }, [libCompId, id]);

  if (step.type === 'hidden') {
    return <></>;
  }

  return (
    <div className={cx('addImage')}>
      {step.type === 'start' && (
        <BlockImageAddStart
          playlistField={playlistField}
          modifier={modifier}
          setStep={setStep}
          index={index}
          id={id}
          isLibraryCreate={isLibraryCreate}
          isSharedComponent={isSharedComponent}
          position={position}
          nextItemPosition={nextItemPosition}
        />
      )}
      {step.type === 'error' && (
        <BlockImageError setStep={setStep} step={step} cancel={cancel} />
      )}
      {step.type === 'loading' && (
        <BlockImageUpload
          setStep={setStep}
          index={index}
          step={step}
          cancel={cancel}
        />
      )}
    </div>
  );
};

export default BlockImageAdd;
