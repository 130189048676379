export const checkIsUserSuits = (inputEmail, emailsAndUsersToInvite, channelsMembers, noEmailFilter = false) => (user,) => {
  const additionalCondition = noEmailFilter ? true : !emailsAndUsersToInvite.filter((item) => item.id === user.id).length
  return (
    (!!user.name?.toLowerCase().includes(inputEmail.toLowerCase())
      || !!user.email?.toLowerCase().includes(inputEmail.toLowerCase())
      || !!user.username?.toLowerCase().includes(inputEmail.toLowerCase())
      || !!user.nameInContact
        ?.toLowerCase()
        .includes(inputEmail.toLowerCase())
      || !!user.organization
        ?.toLowerCase()
        .includes(inputEmail.toLowerCase()))
    && additionalCondition
    && !Object.values(channelsMembers.users || {})
      .map((item) => item.email)
      .includes(user.email)
  );
};


export const checkAndFilterUserPreSelective = (inputEmail, emailsAndUsersToInvite, channelsMembers, userContacts) => {
  const filteredContacts = userContacts.filter(checkIsUserSuits(inputEmail, emailsAndUsersToInvite, channelsMembers, true));

  const uniqueEmailsSet = new Set(emailsAndUsersToInvite.map(item => item.email));
  const uniqueFilteredContacts = filteredContacts.filter(contact => !uniqueEmailsSet.has(contact.email));

  return uniqueFilteredContacts;
};
