import React from 'react';
import styled from 'styled-components/macro';
import classNames from 'classnames/bind';
import { GreyBlinkingPlaceholder } from './StyledComponents';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const DisplayFlex = styled.div`
  display: flex;
`;
const isSmall = {
  notification: true,
  row: true,
};
export const CardItemPlaceHolder = ({ type }) => (
  <GreyBlinkingPlaceholder type={type}>
    <div className={cx('loading')}>
      <span className={cx('loading__dot', { small: isSmall[type] })} />
      <span className={cx('loading__dot', { small: isSmall[type] })} />
      <span className={cx('loading__dot', { small: isSmall[type] })} />
    </div>
  </GreyBlinkingPlaceholder>
);

export const ItemPlaceHolder = ({ type, isFlex }) => (
  <>
    {!isFlex && (
      <>
        <CardItemPlaceHolder type={type} />
      </>
    )}
    {isFlex && (
      <DisplayFlex>
        <CardItemPlaceHolder type={type} />
      </DisplayFlex>
    )}
  </>
);
