import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PreviewMinorCard from './PrviewMinorCard';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { actionCreator } from '../../shared/redux/actionHelper';
import { ContentActionType } from '../../redux/content/contentTypes';
import { GalleryBottomWrapper, GalleryWrapper } from './styled';

const GalleryBottomList = ({
  items,
  previousSortType,
  activeNavSlider,
  selectorType,
  ...props
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [itemsToBeDisplayed, setItemsToBeDisplayed] = useState([]);
  const [prevItemsL, setPrevItemsL] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const { width } = useWindowDimensions();
  const galleryRef = useRef(null);

  const isForSharedToWeb = history.location.pathname.includes('shared_playlist');

  useEffect(() => {
    const onScreenItemQTY = Math.floor(width / 120);

    let leftSlice = Math.floor(props.activeN - onScreenItemQTY / 2);
    let rightSlice = leftSlice + onScreenItemQTY - 1;
    if (leftSlice < 0) {
      rightSlice = onScreenItemQTY;
      leftSlice = 0;
    } else if (rightSlice > items.length) {
      rightSlice = items.length;
      leftSlice = items.length - onScreenItemQTY;
    }
    setItemsToBeDisplayed(
      items
        .map((item, index) => ({ ...item, indexEx: index }))
        .slice(leftSlice, rightSlice),
    );
  }, [width, props.activeN]);

  useEffect(() => {
    if (
      !activeNavSlider
      || !selectorType
      || !previousSortType
      || isForSharedToWeb
    ) return;
    if (items.length - props.activeN < 3 && hasMore) {
      dispatch(
        actionCreator(
          ContentActionType.startUpload,
          { activeNavSlider, selectorType, sortType: previousSortType },
          'LibraryNew next',
        ),
      );
    }
  }, [props.activeN]);

  useEffect(() => {
    if (items.length > prevItemsL) {
      setPrevItemsL(items.length);
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [items.length]);
  return (
    <GalleryBottomWrapper className="fade_menu">
      <GalleryWrapper ref={galleryRef}>
        {!!itemsToBeDisplayed.length
          && itemsToBeDisplayed.map((item) => (
            <PreviewMinorCard
              loading={props.loading}
              key={item.id}
              item={item}
              itemN={item.indexEx}
              goToItem={props.goToItem}
              active={props.activeN === item.indexEx}
              isWhiteGallery
            />
          ))}
      </GalleryWrapper>
    </GalleryBottomWrapper>
  );
};

export default GalleryBottomList;
