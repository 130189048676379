import React, { useRef, useState, useCallback } from 'react';
import mimeTypes from '../../utils/mimeTypes';
import { emptyCallback, UiComponentTypes } from '../../utils/constants';
import { getNameFromState } from '../../utils/draftJsHelpers';
import BlockCreatorComponent from '../LibraryMiniPage/BlockCreatorComponent';
import FallbackImage from '../FallBackImage/FallBackImage';
import { GreyBlinkingPlaceholder } from '../MiniPlaylist/NewPlaylist/CardView/StyledComponents';
import {
  MinorCardComponentWrapper,
  MinorCardFallbackContainer,
  MinorCardPageWrapper,
  MinorCardTextWrapper,
  MinorCardWrapper,
} from './styled';
import LinkPreview from './preview-components/LinkPreview';
import VideoMinorPreview from './preview-components/VideoMinorPreview';
import { getItemName } from '../../utils/helpers';

const PreviewMinorCard = React.memo(function PreviewMinorCard({
  item, isLibDescription, ...props
}) {
  const [imageError, setImageError] = useState(false);
  const lc = item.libraryComponent || item;
  const isImage = mimeTypes.image[lc?.type] || mimeTypes.imageLike[lc?.type];
  const isVideo = mimeTypes.video[lc?.type];
  const isText = item?.textComponent?.id;
  const isPage = lc.type === 'page' || lc.type === 'sharedPage';
  const isPdfPreview = lc?.pdfPreviewUrl;
  const isPDF = UiComponentTypes.pdf[lc?.type];
  const isComponent = lc.type === 'component';
  const isLink = lc.type === 'text/html' || lc.type === 'embed_component';
  const isJustAFile = !isVideo && !isImage && !isText && !isPage && !isLink;
  const cardRef = useRef(null);

  const goToItemCb = useCallback(() => {
    props.goToItem(props.itemN, item.customIndex);
  }, [props.itemN, item.customIndex]);

  if (props?.loading) {
    return <GreyBlinkingPlaceholder type="preview" />;
  }
  const onImageError = () => setImageError(true);

  return (
    <MinorCardWrapper
      isPage={isPage}
      ref={cardRef}
      active={props?.active}
      onClick={
        !props.goToItem ? emptyCallback : goToItemCb
      }
      forFallback={isJustAFile}
      isLibDescription={isLibDescription}
    >
      {isImage && imageError && !isLibDescription && (
        <FallbackImage
          src={lc.urlFile}
          alt=""
          type={lc.type}
          item={lc}
          isPlaylistBuilder={props?.isPlaylistSideBar}
          isChannelMiniPage={props?.isChannelMiniPage}
          isPagePdf={isPDF}
          isPdfPreview={isPdfPreview}
        />
      )}
      {(isImage || isVideo) && (
        <VideoMinorPreview
          isLibDescription={isLibDescription}
          isImage={isImage}
          item={lc}
          onImageError={onImageError}
          isError={imageError}
          isVideo={isVideo}
        />
      )}
      {isComponent && (
        <MinorCardComponentWrapper isLibDescription={isLibDescription}>
          {' '}
          <BlockCreatorComponent isPreview item={lc} />
        </MinorCardComponentWrapper>
      )}
      {isLink && <LinkPreview item={lc} isLibDescription={isLibDescription} />}
      {isJustAFile && (
        <MinorCardFallbackContainer isLibDescription={isLibDescription}>
          <FallbackImage
            src={lc.pdfPreviewUrl || lc.urlFile}
            alt=""
            type={lc.type}
            item={lc}
            isPlaylistBuilder={props?.isPlaylistSideBar}
            isChannelMiniPage={props?.isChannelMiniPage}
            isPagePdf={isPDF}
            isPdfPreview={isPdfPreview}
            isLibDescription={isLibDescription}
            isWhiteGallery={props.isWhiteGallery}
          />
        </MinorCardFallbackContainer>
      )}
      {isText && item.textComponent?.state && (
        <MinorCardTextWrapper>{getItemName(item)}</MinorCardTextWrapper>
      )}
      {isPage && (
        <MinorCardPageWrapper isLibDescription={isLibDescription}>
          <BlockCreatorComponent
            forUPVPreview
            item={lc}
            isPreview
            forDescription
            isLibDescription={isLibDescription}
          />
        </MinorCardPageWrapper>
      )}
    </MinorCardWrapper>
  );
});

export default PreviewMinorCard;
