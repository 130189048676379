import { RequestProceed } from './types';

export const ActionRequestProceedAddNew = ({ id, key }) => ({
  type: RequestProceed.addNew,
  payload: { id, key },
});

export const ActionRemoveFromProceedQueue = (key) => ({
  type: RequestProceed.removeFromQueue,
  payload: { key },
});
export const ActionRequestProceedRemove = (id) => ({
  type: RequestProceed.remove,
  payload: { id },
});
