import { SelectedPage } from './types';
import { ServiceUser } from '../user/types';

const defaultState = {};

export const selectedPageReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SelectedPage.AddSelectedPage: {
      const newState = { ...state };
      if (payload.folderId) {
        if (!newState[payload.folderId]) newState[payload.folderId] = {};
        newState[payload.folderId][payload.pageId] = true;
        // newState[payload.folderId][payload.idStatus] = true;
      } else {
        newState[payload.pageId] = true;
        // newState[payload.idStatus] = true;
      }

      return newState;
    }
    case SelectedPage.RemoveSelectedPage: {
      const newState = { ...state };

      if (payload.folderId) {
        delete newState[payload.folderId][payload.pageId];
        if (
          newState[payload.folderId]
          && Object.keys(newState[payload.folderId]).length === 0
        ) {
          delete newState[payload.folderId];
        }
      } else {
        delete newState[payload.pageId];
        delete newState[payload.idStatus];
      }
      return newState;
    }
    case SelectedPage.RemoveAllSelected: {
      return { ...defaultState };
    }
    case ServiceUser.Logout: {
      return { ...defaultState };
    }
    default:
      return state;
  }
};
