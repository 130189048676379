import styled from 'styled-components/macro';

export const LibRightSideBarWrapper = styled.div`
  transition: ease-in-out all 0.35s;
  height: 100%;
  background: #ffffff;
  filter: drop-shadow(-4px 0px 12px rgba(236, 226, 226, 0.5));
  padding-top: 26px;
  ${({ isExpanded }) => (isExpanded ? 'width: 75vw;' : ' width: 660px;')}
`;

export const MakerLibHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #343434;
  padding-left: 29px;
`;

export const ExpandWrapper = styled.div`
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 0px 1px 4px rgba(122, 105, 105, 0.1);
  border-radius: 6px;
  padding-top: 6px;
  padding-left: 6px;
  &:hover {
    background-color: #ffeebf;
    cursor: pointer;
  }
`;
export const TitleWrapper = styled.div`
  position: absolute;
  left: 74px;
  top: 6px;
  font-family: "RobotoFlex", sans-serif;
`;

export const MakerLibWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: calc(100% + 18px);
  margin: 0 0 40px;
  /* z-index: 100; */
  scroll-snap-type: inline;
  scroll-behavior: smooth;
  padding-left: 30px;
`;
export const ContentWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 206px);
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  margin-top: 26px;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
