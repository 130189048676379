import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { ReactComponent as SixDotsSvg } from '../../images/icons/drag_black.svg';
import { ReactComponent as MoreSvg } from '../../images/icons/more_20.svg';
import TagItem from './TagItem';
import {
  actionChangeTagColor,
  actionChangeTagTitle,
} from '../../redux/tags/action';
import { checkString, checkTagExists } from '../../utils/helpers';
import rawStyles from './taglist.module.scss';
import { DATA_PARENTS, empty } from '../../utils/constants';
import TagListDropOptionsColorPalette from './TagListDropOptionsColorPalette';
import ColorPalette from '../../components/ColorPicker/ColorPalette';
import TagListDropOptionsColorPaletteEnd from './TagListDropOptionsColorPaletteEnd';

const styles = classnames.bind(rawStyles);

const TagListItem = ({
  item,
  onDragEnd,
  saveCb,
  onDragStart,
  dragOverId,
  onDrop,
  isDrag,
  isView,
  isLast,
  onDragEnter,
  onDragLeave,
  deleteCb,
  parent,
  type,
  closeAllMenus,
  outerCloseAllMenus,
  dataParent,
  // isCreateLibrary,
}) => {
  const { tags, id: currentPageId } = useSelector((redux) => redux.currentPage) || empty;
  const { tags: userTags } = useSelector((redux) => redux.user) || empty;
  const initTitle = tags.filter((initItem) => initItem.id === item.id)[0]
    ?.title;
  const [isPaletteActive, setIsPaletteActive] = useState(false);
  const positionRef = useRef(null);
  const refColorPickerParent = useRef(null);
  const [localTitle, setLocalTitle] = useState(item.title);
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if ((closeAllMenus || outerCloseAllMenus) && isPaletteActive) setIsPaletteActive(false);
  }, [closeAllMenus, outerCloseAllMenus]);

  useEffect(() => {
    setLocalTitle(item.title);
  }, [isPaletteActive, item.title]);

  const saveNewTitle = (e) => {
    if (localTitle === item.title || !isPaletteActive) return;

    if (!localTitle) {
      setHasError('Please type a value');
    } else if (checkTagExists(userTags, localTitle.trim())) {
      setHasError('This tag already exists');
    } else {
      const payload = {
        id: item.id,
        newTitle: localTitle.trim(),
        type,
        parent,
      };
      if (currentPageId) payload.libraryComponentId = currentPageId;
      dispatch(actionChangeTagTitle(payload));
      e.currentTarget.blur();
      setIsPaletteActive(false);
    }
  };
  const onNewTitleKeyDown = (e) => {
    if (e.key === 'Enter') {
      saveNewTitle(e, item.id);
    }
  };
  const changeItemColor = (e, newColor, mode) => {
    const payload = {};
    if (mode === 'Text') {
      payload.id = item.id;
      payload.newColor = newColor;
      dispatch(actionChangeTagColor(payload));
    } else {
      payload.id = item.id;
      payload.newBackgroundColor = newColor;
      dispatch(actionChangeTagColor(payload));
    }
    e.stopPropagation();
  };
  const handleInputChange = (value) => {
    if (checkString(value)) {
      setLocalTitle(value.replace(/\s+/g, ' '));
    }
  };

  return (
    <>
      <div
        data-parent={
          dataParent || dataParent || DATA_PARENTS.PlaylistBuilderLibrary
        }
        className={styles('listItem', { active: isPaletteActive })}
        onDragEnd={(e) => {
          onDragEnd(e);
        }}
        draggable={!isPaletteActive}
        onDragStart={(e) => {
          onDragStart(e, item);
        }}
        onDragEnter={(e) => onDragEnter(e, item.id)}
        onDragLeave={onDragLeave}
      >
        {dragOverId === item.id && <div className={styles('dropLine')} />}
        {isDrag && (
          <div
            data-parent={dataParent || DATA_PARENTS.PlaylistBuilderLibrary}
            ref={positionRef}
            className={styles('dropZone', { first: item.index === 0 })}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={(e) => {
              onDrop(e, item.index);
            }}
          />
        )}
        <div
          data-parent={dataParent || DATA_PARENTS.PlaylistBuilderLibrary}
          className={styles('clickableSave')}
          onClick={(e) => {
            if (!isDrag) saveCb(e, item);
          }}
          onDragEnter={(e) => onDragEnter(e, item.id)}
          onDragLeave={onDragLeave}
        >
          <div
            className={styles('icon_container')}
            data-parent={dataParent || DATA_PARENTS.PlaylistBuilderLibrary}
          >
            <SixDotsSvg />
          </div>
          <div
            className={styles('item_tag_container')}
            data-parent={dataParent || DATA_PARENTS.PlaylistBuilderLibrary}
          >
            <TagItem
              isView={isView}
              {...userTags[item.id]}
              type="tags"
              title={localTitle}
              deleteCb={deleteCb}
              dataParent={dataParent}
            />
          </div>
        </div>
        <ColorPalette
          isPositionFixed
          selectedColor={item.color}
          selectedBackgroundColor={item.backgroundColor}
          refColorPickerParent={refColorPickerParent}
          onChangeColor={changeItemColor}
          isPaletteActive={isPaletteActive}
          type="tag"
          setIsPaletteActive={setIsPaletteActive}
          element={(
            <TagListDropOptionsColorPalette
              setIsOptionVisible={setIsPaletteActive}
              title={localTitle}
              saveTitle={saveNewTitle}
              onTitleKeyDown={onNewTitleKeyDown}
              onTitleChange={handleInputChange}
              hasError={hasError && initTitle !== localTitle}
              errorText={hasError}
              setHasError={setHasError}
              isLeftSideBar
              itemId={item.id}
            />
          )}
          elementEnd={(
            <TagListDropOptionsColorPaletteEnd
              setIsOptionVisible={setIsPaletteActive}
              hasError={hasError && initTitle !== localTitle}
              isLeftSideBar
              itemId={item.id}
              itemName={item.title}
            />
          )}
        >
          <div
            data-parent={dataParent || DATA_PARENTS.PlaylistBuilderLibrary}
            ref={refColorPickerParent}
            className={`${styles('item_actions')}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsPaletteActive(true);
            }}
          >
            <MoreSvg
              className={styles('more')}
              data-parent={dataParent || DATA_PARENTS.PlaylistBuilderLibrary}
            />
          </div>
        </ColorPalette>
      </div>
      {isLast && (
        <div
          className={styles('last_drag_wrapper')}
          data-parent={dataParent || DATA_PARENTS.PlaylistBuilderLibrary}
        >
          {dragOverId === 'last' && <div className={styles('dropLine')} />}
          <div
            data-parent={dataParent || DATA_PARENTS.PlaylistBuilderLibrary}
            className={styles('dropZone', { last: isLast })}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              onDrop(e, 'last');
            }}
            onDragEnter={(e) => onDragEnter(e, 'last')}
            onDragLeave={onDragLeave}
          />
        </div>
      )}
    </>
  );
};

export default TagListItem;
