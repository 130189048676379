import React from 'react';
import styled from 'styled-components';
import styles from '../../PlaylistContentSelector/index.module.css';
import HelpDragHandler from '../../../pages/Help/HelpDragHandler';
import NewPlaylist2023 from '../../MiniPlaylist/NewPlaylist2023/NewPlaylist2023';
import { FluentTableView } from '../../MiniPlaylist/NewPlaylist2023/table/FluentTableView';
import { PlaylistGap } from '../../MiniPlaylist/NewPlaylist2023/styled';

const PlayListCard = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 29.5px;
`;
const PlaylistTable = styled.div``;
const TitleStyle = styled.div`
  width: max-content;
  height: 26px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  margin-bottom: 30px;
  margin-left: 8px;
  margin-top: 15px;
  color: #484343;
  font-size: 20px;
  font-weight: 500;
  line-height: 19px;
`;
const TitleStyleCard = styled.div`
  width: max-content;
  height: 26px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  margin-bottom: 10px;
  margin-left: 8px;
  margin-top: 15px;
  color: #484343;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
`;
const RecentFilterComponentInHelp = ({
  sortedPages,
  filterTitle,
  refSelectedBlocks,
  isAdmin,
  isSimplifiedMultiselect,
  setSimplifiedMultiselect,
  counterSelectedPage,
  needToDelete,
  channelId,
  isChannelMiniPlaylist,
  permissions,
  isHelp,
  isCardsView,
  cannotBeDeleted,
  isDescriptionOpen,
  ...props
}) => {
  const PlayListRow = isCardsView ? PlayListCard : PlaylistTable;

  const TitleRow = isCardsView ? TitleStyle : TitleStyleCard;
  if (sortedPages.length) {
    return (
      <>
        <TitleRow>{filterTitle.toUpperCase()}</TitleRow>{' '}
        <PlayListRow>
          {!isCardsView && (
            <FluentTableView
              isRecent
              isSimplifiedMultiselect={isSimplifiedMultiselect}
              setSimplifiedMultiselect={setSimplifiedMultiselect}
              isDescriptionOpen={isDescriptionOpen}
              data={sortedPages}
            />
          )}
          {isCardsView
            && sortedPages.map((page, index, arr) => (
              <PlaylistGap
                key={page.id}
                className={`${
                  !isCardsView ? styles.fullParentWidth : styles.relative
                }`}
                ref={(ref) => refSelectedBlocks.current.push({
                  ref,
                  pageId: page.id,
                  folderId: page.folderId,
                })
                }
              >
                {isAdmin && (
                  <HelpDragHandler
                    previousItemId={arr[index - 1]?.id}
                    currentItemId={page?.id}
                    nextItemId={arr[index + 1]?.id}
                  />
                )}
                <NewPlaylist2023
                  item={page}
                  isSimplifiedMultiselect={isSimplifiedMultiselect}
                  setSimplifiedMultiselect={setSimplifiedMultiselect}
                  isDescriptionOpen={isDescriptionOpen}
                />
              </PlaylistGap>
            ))}
        </PlayListRow>
      </>
    );
  }
  return <></>;
};

export default RecentFilterComponentInHelp;
