import { put, select, takeEvery } from '@redux-saga/core/effects';
import { showErrorMessage } from './sagasUtils';
import { counterMap } from '../utils/counters';
import { ContentActionType } from '../redux/content/contentTypes';
import { actionCreator } from '../shared/redux/actionHelper';
import {
  requestGetSomeChannelContent,
  requestGetSomeUserContent,
} from '../utils/request';
import { actionUpdateCounters } from '../redux/content/actions';

const inChannelSlider = {
  channelUsers: true,
  channelPlaylists: true,
  channelFavoritePlaylists: true,
};

export function* updateCounterInMainTab(action) {
  try {
    const { activeNavSlider: activeNavSliderStatePayload, isUpload, affectedCounters } = action.payload;
    const { activeNavSlider: activeNavSliderState, selectorType: channelId } = yield select((state) => state.content);
    const activeNavSlider = activeNavSliderStatePayload || activeNavSliderState;
    if (inChannelSlider[activeNavSlider]) {
      const {
        data: {
          channels: [counters],
        },
      } = yield requestGetSomeChannelContent(['counter', channelId]);
      yield put(
        actionCreator(ContentActionType.updateCounterChannel, {
          channelId,
          counters,
        }),
      );
      return;
    }
    const queryCounter = Object.entries(counterMap).reduce(
      (prev, [key, query]) => {
        if (query && key.startsWith(activeNavSlider)) return `${prev + query}_`;
        if (query && affectedCounters && key.startsWith(affectedCounters)) return `${prev + query}_`;
        if (isUpload && key.includes('/all') && !!query) return `${prev + query}_`;
        return prev;
      },
      '',
    );

    if (!queryCounter) return;
    const data = yield requestGetSomeUserContent(['counter', queryCounter]);
    const user = data.data.User[0];
    const calcCounters = {};

    Object.entries(counterMap).forEach(([counterKey, counterPath]) => {
      if (user[counterPath] !== undefined) {
        calcCounters[counterKey] = user[counterPath];
      }
    });
    yield put(actionUpdateCounters(calcCounters));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updateCounterInChannel(action) {
  try {
    const { channelId } = action.payload;
    const {
      data: {
        channels: [counters],
      },
    } = yield requestGetSomeChannelContent(['counter', channelId]);
    yield put(
      actionCreator(ContentActionType.updateCounterChannel, {
        channelId,
        counters,
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

export default function* updateCounter() {
  // yield takeEvery(ContentActionType.startUpload, updateCounterInMainTab);
  yield takeEvery(ContentActionType.updateCounterS, updateCounterInMainTab);
  yield takeEvery(
    ContentActionType.updateCounterInChannelS,
    updateCounterInChannel,
  );
}
