import { DeletedActionType } from './deletedTypes';
import downloadStatus from '../../utils/dataUtil';

const defaultState = {
  loadStatus: downloadStatus.pending,
  deletedItems: [],
};

const deletedReducers = (state = defaultState, action) => {
  const { payload, type } = action;
  switch (type) {
    case DeletedActionType.getDeleted: {
      return { ...defaultState, loadStatus: downloadStatus.pending };
    }
    case DeletedActionType.finishUpload: {
      return {
        ...state,
        deletedItems: payload.data,
        loadStatus: downloadStatus.success,
      };
    }
    case DeletedActionType.deletePlaylist: {
      const newState = [...state.deletedItems].filter(
        (i) => i.id !== payload.id,
      );
      return {
        ...state,
        deletedItems: newState,
      };
    }
    case DeletedActionType.deleteLibItem: {
      const newState = [...state.deletedItems].filter(
        (i) => i.id !== payload.id,
      );
      return {
        ...state,
        deletedItems: newState,
      };
    }
    case DeletedActionType.restorePlaylistSR: {
      const newState = [...state.deletedItems].filter(
        (i) => i.id !== payload.id,
      );
      return {
        ...state,
        deletedItems: newState,
      };
    }
    case DeletedActionType.restoreLibItemSR: {
      const newState = [...state.deletedItems].filter(
        (i) => i.id !== payload.id,
      );
      return {
        ...state,
        deletedItems: newState,
      };
    }
    default:
      return state;
  }
};

export default deletedReducers;
