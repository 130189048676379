import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moduleStyles from '../../pages/Library/LibraryHeader.module.scss';
import { ReactComponent as ExpandSvg } from '../../images/icons/expand_20.svg';
import { ReactComponent as CollapseSvg } from '../../images/icons/collapse.svg';
import { ReactComponent as CloseCircle20Svg } from '../../images/icons/close_circle_20.svg';
import search from '../../images/icons/search.svg';
import clearSearch from '../../images/icons/clear_search.svg';
import { actionChangeSieveValue } from '../../redux/library/actions';
import { resolveActiveSieveOption } from '../../utils/helpers';
import SortAndView from './childComponents/SortAndView';
import { Tag } from '../Tag';
import { empty } from '../../utils/constants';

const styles = classNames.bind(moduleStyles);

const TagHeader = ({
  isShowFilter,
  setIsShowFilter,
  libFilterSearch,
  setLibFilterSearch,
  setFilter,
  filter,
  isCardsView,
  setIsCardsView,
  isShowMenuSort,
  setActiveNavSlider,
  computeNavBarStyles,
  setSieve,
  refShowMenuSort,
  setShowMenuSort,
  setSort,
  isExpanded,
  goToLibrarySideBar,
  changeLibraryDisplayComponents,
  setShowLibrary,
  isShowInput,
  navigate,
  ...props
}) => {
  const dispatch = useDispatch();
  const { selectorType = 'all' } = useSelector((state) => state.content);
  const { tags } = useSelector((state) => state.user);
  const tag = tags[selectorType] || empty;
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className={styles('sideBarLib__header_container')}>
      <div className={styles('sideBarLib__header_option')}>
        <div className={styles('sideBarLib__header_title')}>
          <div className={styles('expand')} onClick={goToLibrarySideBar}>
            {isExpanded && <CollapseSvg />}

            {!isExpanded && <ExpandSvg />}
          </div>
          <div className={styles('title')}>{t('libraryT')}</div>

          <div className={styles('search_wrapper')}>
            {!isShowFilter && (
              <div
                onClick={props.openFilterHandler}
                className={styles('search_icon_wrapper')}
              >
                <img
                  className={styles('search_icon')}
                  src={search}
                  alt="search"
                />
              </div>
            )}

            {isShowFilter && (
              <div className={styles('search')}>
                <div
                  className={styles('search_input_wrapper')}
                >
                  <img
                    onClick={() => setIsShowFilter(!isShowFilter)}
                    className={styles('search_icon2')}
                    src={search}
                    alt="search"
                  />
                  {isShowFilter && (
                    <input
                      autoFocus
                      value={libFilterSearch}
                      onChange={(e) => {
                        setLibFilterSearch(e.target.value);
                      }}
                      data-cy="search-in-lib"
                      placeholder={t('searchInLibraryTooltipT')}
                      className={styles('search_input')}
                      onBlur={() => {
                        if (!filter) setIsShowFilter(!isShowFilter);
                      }}
                    />
                  )}
                  {!!filter && (
                    <img
                      className={styles('clear_search')}
                      src={clearSearch}
                      onClick={() => {
                        setLibFilterSearch('');
                        setIsShowFilter(false);
                        dispatch(
                          actionChangeSieveValue(
                            resolveActiveSieveOption(
                              history.location.pathname,
                            ),
                            'library',
                          ),
                        );
                      }}
                      alt=""
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className={styles('close_button')}
          onClick={() => {
            setTimeout(() => props.closeFilterHandler(), 10);
            setShowLibrary(false);
          }}
        >
          <CloseCircle20Svg />
        </div>
      </div>

      <div className={styles('sideBarLib__header_tabs_container')}>
        <div className={styles('item_tag')}>
          <Tag
            color={tag.color}
            backgroundColor={tag.backgroundColor}
            id={tag.id}
          >
            <div
              className={`text ${
                tag.color === tag.backgroundColor ? 'stroke' : ''
              }`}
            >
              {tag.title}
            </div>
          </Tag>
        </div>

        <SortAndView
          setSort={(key) => {
            navigate({
              newActiveNavSlider: 'tag',
              newSelectorType: tag.id,
              newSortType: key,
            });
          }}
          setShowMenuSort={setShowMenuSort}
          refShowMenuSort={refShowMenuSort}
          isShowMenuSort={isShowMenuSort}
          isCantToggleView={!isExpanded}
        />
      </div>
    </div>
  );
};

export default TagHeader;
