import { UiComponentTypes } from '../../../../../utils/constants';

export const parseItemsForRightSideBar = (
  linkPages,
  selector,
  setItems,
  isPage,
  contentData,
) => {
  const exItems = isPage
    ? linkPages
      .filter((block) => block.nestedItemId)
      .map((block) => contentData[block.nestedItemId]).filter(i => i)
    : linkPages.map((it) => it.libraryComponent);

  if (exItems && exItems.length) {
    switch (selector) {
      case 'library':
        setItems(exItems);
        break;
      case 'pages':
        setItems(exItems.filter((i) => UiComponentTypes.page[i.type]));
        break;
      case 'pdf':
        setItems(exItems.filter((i) => UiComponentTypes.pdf[i.type]));
        break;
      case 'media':
        setItems(
          exItems.filter(
            (i) => UiComponentTypes.media[i.type] || UiComponentTypes.image[i.type],
          ),
        );
        break;
      case 'links':
        setItems(exItems.filter((i) => UiComponentTypes.link[i.type]));
        break;
      default:
        setItems(
          exItems.filter(
            (i) => i?.type
              && !(
                UiComponentTypes.page[i.type]
                || UiComponentTypes.component[i.type]
                || UiComponentTypes.pdf[i.type]
                || UiComponentTypes.media[i.type]
                || UiComponentTypes.image[i.type]
                || UiComponentTypes.link[i.type]
              ),
          ),
        );
    }
  } else { setItems([]); }
};
