export const Settings = {
  ValidateForm: '[Settings-Form] Validate Form',
  ResetForm: '[Settings-Form] Reset Form',
  SendForm: '[Settings-Form] Send Form',
  ShowFormErrors: '[Settings-Form] Show Form Errors',
  ShowFormSuccess: '[Settings-Form] Show Form Success',
  ShowFormNotifications: '[Settings-Form] Show Form Notifications',
  HideFormMessages:
    '[Settings-Form] Hide Form Errors, Success and Notifications',
  HideMessage: '[Settings-Form] Hide form Message',
  GetProfileData: '[Settings-Form] Get Profile Data',
  DataIsLoaded: '[Settings] Data Is Loaded',
  LoadingData: '[Settings] Loading Data',
  UpdateInputErrors: '[Settings] Update Input Error Redux',
  SaveTmpImageData: '[Settings] Save Tmp Image Data Object REDUX',
  ValidateInputS: '[Settings] Validate Input Value SAGAS',
  HandleUploadFileS: '[Settings] Handle File Upload SAGAS',
  ChangePaymentPlan: '[Settings] Change Payment Plan for User',
  AttachPaymentMethod: '[Settings] Attach New Payment Method for User & Subscription',
  ChangePaymentMethod: '[Settings] Change Payment Method for User & Subscription',
  Preferences: {
    GetGeneralDataS:
      '[Settings-Preferences] Get Preferences General Data SAGAS',
    LoadInitialData: '[Settings-Preferences] Load Initial Data',
    ToggleInputValue: '[Settings-Preferences] Toggle TogglerInput Value',
    UpdateAvatarSelector: '[Settings-Preferences] Update Avatar Selector value',
    ChangeLanguage: '[Settings-Preferences] Change Language Selector value',
    ChangeTimeFormat:
      '[Settings-Preferences] Change Time Format Selector value',
    ChangeDateFormat:
      '[Settings-Preferences] Change Date Format Selector value',
    ChangeTimeZoneFormat:
      '[Settings-Preferences] Change TimeZone Selector value',
    InitialGetEmojisS: '[Settings-Preferences] Initial Get Emojis SAGA',
    SaveEmojiFileS: '[Settings-Preferences] Save Emoji File SAGA',
    LoadEmojisR: '[Settings-Preferences] Load Emojis REDUX',
    UpdateEmojiInTable: '[Settings-Preferences] Update Emoji In Table',
    AddListItemToEmojis: '[Settings-Preferences] Add List Item To Emojis',
    UpdateEmojiTableErrors: '[Settings-Preferences] Update Emoji Table Errors',
    EmojiNameRenameCheckAndSave:
      '[Settings-Preferences] EmojiName Rename Check And Save',
    SetRenameEmojiNameR: '[Settings-Preferences] Set Rename EmojiName R',
    RemoveUserEmoji: '[Settings-Preferences] Remove User Emoji',
    ResetUploadEmojiDataR:
      '[Settings-Preferences] Reset Upload Emoji Data REDUX',
  },
  Activity: {
    GetChannels: '[Settings-Activity] Get Channels',
    Initial: '[Settings-Activity] Initial data upload',
  },
  Profile: {
    InitialDataLoading: '[Settings-Profile] Initial Data Loading REDUX',
    ChangeValue: '[Settings-Profile] Change Input Value Redux',
    DropTmpImageData: '[Settings-Profile] Drop Tmp Image Data REDUX',
    SaveAvatarS: '[Settings-Profile] Save Avatar SAGAS',
    DeleteAvatar: '[Settings-Profile] Delete Avatar',
    UpdateAvatarInput: '[Settings-Profile] Update Avatar Input REDUX',
    SetInitialValuesR: '[Settings-Profile] Set InitialValues REDUX',
    SetAvatarIsLoading: '[Settings-Profile] Set Avatar IsLoading REDUX',
    ChangeEmailCheckJwt: '[Settings-Profile] Change Email Check Jwt',
    ConfirmEmailChange: '[Settings-Profile] Confirm Email Change',
    UpdateDisplayImageR: '[Settings-Profile] Update Display Image REDUX',
    UpdateUserInfoAfterSettingsChangeR:
      '[Settings-Profile] Update User Info After Settings Change REDUX',
  },
  Application: {
    LoadUsersShortcuts: '[Settings-Application] Load Users Shortcuts',
  },
  Sort: {
    changeOneSortKeyInRedux: '[Settings-Sort] changeOneSortKeyInRedux',
    getSortFromDB: '[Settings-Sort] getSortFromDB',
    changeSortRedux: '[Settings-Sort] Change Sort in REDUX',
    changeSortSagas: '[Settings-Sort] Change Sort SAGAS',
  },
};
