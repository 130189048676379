import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { select } from '@redux-saga/core/effects';
import { getCurrentPage, showErrorMessage } from './sagasUtils';
import { actionCreator } from '../shared/redux/actionHelper';
import {
  requestDeleteOutBoxCard,
  requestDeleteOutBoxCardsMany,
  requestGetSomeUserContent,
  requestPagePreview,
  requestUploadOutboxCard,
} from '../utils/request';
import { OutboxAction } from '../redux/outbox/types';
import { OUTBOX_CARD_TYPE, MessageType } from '../utils/constants';
import { sanitizeToLoad } from '../utils/helpers';
import draftDataConvertToLoad from '../utils/draftDataConvert';
import { InboxAction } from '../redux/inbox/types';
import { actionShowMessage } from '../redux/support/action';
import { counterMap } from '../utils/counters';

function calcState(state, type) {
  try {
    if (!state) return state;
    if (typeof state === 'object') return state;
    if (type === 'embed') return JSON.parse(sanitizeToLoad(state));
    return draftDataConvertToLoad(state);
  } catch (e) {
    return state;
  }
}
const roleOrder = {
  admin: 1,
  co_admin: 2,
  contributor: 3,
  viewer: 4,
};
function* UploadPageInOutboxCard(action) {
  try {
    const { pageIds } = action.payload;
    const { data } = yield call(
      requestPagePreview('sharedpage'),
      [pageIds.join('_')],
    );
    if (!data.errors) {
      Object.values(data.content).forEach(({ components }) => {
        components?.forEach((component) => {
          component.state = calcState(component.state, component.type);
        });
      });
      yield put(
        actionCreator(InboxAction.UpdatePageInInboxCardRedux, data.content),
      );
    } else {
      yield showErrorMessage(data.errors, action);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* UploadOutboxCard(action) {
  try {
    const { filter, isNext } = action.payload;
    const cards = yield select((state) => state.outbox.cards);
    const nextStep = yield select((state) => state.outbox.nextStep);
    const params = {};
    if (isNext) {
      params.nextStep = nextStep;
    }
    const { myChannels } = yield select((redux) => redux.channels);

    const { data } = yield call(requestUploadOutboxCard(filter), [], params,
    );
    if (!data.errors) {
      const sortedByChannelRole = data.card.map(i => {
        if (i.type === OUTBOX_CARD_TYPE.sharedPlaylist) {
          i.recipients.sort((a, b) => {
            const roleA = myChannels[a.channel?.id]?.role;
            const roleB = myChannels[b.channel?.id]?.role;
            return (roleOrder[roleA] || 0) - (roleOrder[roleB] || 0);
          });
        }
        return i;
      });


      const uniqueElements = [...cards, ...sortedByChannelRole]
        .filter((el, index, self) => index === self.findIndex((t) => (
          t.id === el.id && t.type === el.type
        ))).sort((a, b) => b.date - a.date);

      const pageIds = [];
      data.card.forEach(item => {
        if (item.type === OUTBOX_CARD_TYPE.page) {
          pageIds.push(item.id);
        }
      });
      if (pageIds.length) {
        yield put(actionCreator(OutboxAction.UploadPage,
          { pageIds }),
        );
      }
      yield put(actionCreator(OutboxAction.UploadOutboxCardRedux, {
        newCards: uniqueElements,
        maxDateToShow: data.nextStep.maxDateToShow,
        nextStep: data.nextStep,
        hasItem: !!cards.length,
      }));
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* DeleteOutboxCard(action) {
  try {
    const { item: { id, type, recipients } } = action.payload;
    if (type === OUTBOX_CARD_TYPE.invite) {
      yield call(requestDeleteOutBoxCard, { itemId: id, type, recipients: recipients.map(i => ({ id: i.user.id })) });
    } else {
      yield call(requestDeleteOutBoxCard, { itemId: id, type });
    }
    yield put(
      actionShowMessage({
        type: MessageType.SuccessfullyDeletedInboxCard,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* DeleteOutboxCards(action) {
  try {
    const { items } = action.payload;
    yield call(requestDeleteOutBoxCardsMany, { items });
    yield put(
      actionShowMessage({
        type: MessageType.SuccessfullyDeletedInboxCards,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* CheckCurrentPageAddNewAwaitedNotification(action) {
  try {
    const { notification } = action.payload;
    const { id: idCurrentPage } = yield select(getCurrentPage);
    if (idCurrentPage && (idCurrentPage === notification.itemId)) return;

    yield put(actionCreator(OutboxAction.AddNewAwaitedNotification, { notification }));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* updateCounterInOutbox(action) {
  try {
    const { data: { User } } = yield requestGetSomeUserContent(['counter', `${counterMap.playlistOutNotificationCount}_${counterMap.pagesOutNotificationCount}_${counterMap.channelHasMyInviteCounter}_`]);
    const { channelHasMyInviteCounter, pagesOutNotificationCount, playlistOutNotificationCount } = User[0];
    const outBoxCounter = channelHasMyInviteCounter + pagesOutNotificationCount + playlistOutNotificationCount;
    yield put(actionCreator(OutboxAction.UpdateCounter, { outBoxCounter }));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}


export default function* OutboxSaga() {
  yield takeLatest(OutboxAction.UploadOutboxCardSaga, UploadOutboxCard);
  yield takeLatest(OutboxAction.UpdateCounterS, updateCounterInOutbox);
  yield takeEvery(OutboxAction.DeleteOutboxCard, DeleteOutboxCard);
  yield takeEvery(OutboxAction.DeleteOutboxCards, DeleteOutboxCards);
  yield takeEvery(OutboxAction.UploadPage, UploadPageInOutboxCard);
  yield takeEvery(OutboxAction.CheckCurrentPageAddNewAwaitedNotification, CheckCurrentPageAddNewAwaitedNotification);
}
