import styled from 'styled-components/macro';

export const SvgWrapper = styled.div`
  ${({ isListView }) => (isListView
    ? `
  svg {
    height: 30px;
    width: 30px;
  }`
    : `
  svg {
    height: 100%;
    width: 44px;
  }`)}
`;
