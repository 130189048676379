import React from 'react';
import { useSelector } from 'react-redux';
import CaptionsForPlayer from './CaptionsForPlayer';
import ApprovalsForPlayer from './ApprovalsForPlayer';


const ApprovalAndCaptionsModule = ({ areControlsHidden, currentItem }) => {
  const { playerSettings: { isCaptionsHidden, isApproveButtonsHidden } } = useSelector((state) => state.user);
  const ABState = currentItem?.approveButtonState || currentItem?.interactiveItemData;
  return (
    <>
      {!isCaptionsHidden && <CaptionsForPlayer text={currentItem?.caption} areControlsHidden={areControlsHidden} />}
      {!isApproveButtonsHidden && ABState && <ApprovalsForPlayer currentItem={currentItem} />}
    </>
  );
};

export default ApprovalAndCaptionsModule;
