import React from 'react';
import classNames from 'classnames/bind';
import rawStyles from './UsedMenu.module.scss';
import UsedCounter from './UsedCounter';
import UsedContainer from './UsedContainer';

const cn = classNames.bind(rawStyles);

const MiniPageUsedMenu = ({
  usedRef,
  isUsedActive,
  setIsUsedActive,
  defaultPosition = { bottom: 0, left: 0 },
  libraryComponentId,
  usedIn,
  usedTimesCounter,
  isNoChannels,
  isComponent,
  dataParent,
}) => {
  const calculateHorizontalPosition = () => {
    if (usedRef && usedRef?.current) {
      const width = document.documentElement.clientWidth;
      const positionX = defaultPosition.left;
      const refAbsoluteX = usedRef?.current.getBoundingClientRect().x;
      const widthElement = 306;
      const isOpenInRight = width > refAbsoluteX + widthElement + positionX;
      if (isOpenInRight) return positionX;
      return positionX - widthElement + 56;
    }
    return defaultPosition.left;
  };

  const divStyle = {
    // bottom: defaultPosition.bottom,
    left: calculateHorizontalPosition(),
  };

  const toggleMenu = (event) => {
    event.stopPropagation();
    setIsUsedActive((prev) => !prev);
  };

  const preventPropagation = (event) => {
    event.stopPropagation();
  };

  if (!usedTimesCounter) return <></>;

  return (
    <div
      className={cn('wrapper', { isComponent })}
      ref={usedRef}
      onClick={preventPropagation}
      data-parent={dataParent}
    >
      {!!usedTimesCounter && (
        <UsedCounter
          dataParent={dataParent}
          isActive={isUsedActive}
          counter={usedTimesCounter}
          callback={toggleMenu}
        />
      )}
      {isUsedActive && (
        <UsedContainer
          usedRef={usedRef}
          forCard
          dataParent={dataParent}
          divStyle={divStyle}
          usedIn={usedIn}
          setIsUsedActive={setIsUsedActive}
          isNoChannels={isNoChannels}
          isUsedActive={isUsedActive}
          libraryComponentId={libraryComponentId}
        />
      )}
    </div>
  );
};

export default MiniPageUsedMenu;
