import { PageSharedDatePickerDataLayer } from './PageSharedDatePickerDataLayer';
import { PlaylistDatePickerDataLayer } from './PlaylistDatePickerDataLayer';
import { PlaylistDatePickerDataLayerDeprecated } from './PlaylistDatePickerDataLayerDeprecated';
import { PlaylistSharedDatePickerDataLayer } from './PlaylistSharedDatePickerDataLayer';

const DatePicker = ({
  type,
  localUpdate,
  localAvailableFrom,
  localAvailableTo,
  localIsAvailableRange,
  isMultiple,
  selectedOption,
  setSelectedOption,
  isModify,
  dataParent,
  setLocalState,
  localState,
}) => {
  switch (type) {
    case 'PublishPlaylistModal': {
      return (
        <PlaylistDatePickerDataLayer
          localState={localState}
          setLocalState={setLocalState}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      );
    }
    case 'SharePage': {
      return (
        <PageSharedDatePickerDataLayer
          localUpdate={localUpdate}
          localAvailableFrom={localAvailableFrom}
          localAvailableTo={localAvailableTo}
          localIsAvailableRange={localIsAvailableRange}
          isMultiple={isMultiple}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          isModify={isModify}
          setLocalState={setLocalState}

        />
      );
    }
    // / also is used for multiple page sharing >.< sry
    case 'ShareSmartFile': {
      return (
        <PlaylistSharedDatePickerDataLayer
          localState={localState}
          setLocalState={setLocalState}
          isModify={isModify}
          localUpdate={localUpdate}
          localAvailableFrom={localAvailableFrom}
          localAvailableTo={localAvailableTo}
          localIsAvailableRange={localIsAvailableRange}
          isMultiple={isMultiple}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          dataParent={dataParent || ''}
        />
      );
    }
    default: {
      return <></>;
    }
  }
};

export default DatePicker;
