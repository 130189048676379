import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actionCreator } from '../../shared/redux/actionHelper';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { ReactComponent as TrashSvg } from '../../images/icons/trash_Icon.svg';
import { OutboxAction } from '../../redux/outbox/types';

const CardButtonsWrappers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${({ inHeader }) => (inHeader
    ? `
    gap: 10px;
    margin-left: 10px;
    ` : ''
  )};
`;

const CardButton = styled.div`
  ${({ isDelete }) => (isDelete
    ? 'top: 60px;' : 'top: 15px'
  )};
  border: 0.6px solid rgba(255, 199, 44, 0);
  width: 26px;
  height: 26px;
  ${({ inHeader }) => (inHeader
    ? `border: 0.6px solid #ECE2E2;
      width: 32px;
      border-radius: 32px;
      height: 32px;`
    : ''
  )};
  
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 18px;
    height: 18px;
  }

  :hover {
    background: ${({ inHeader }) => (inHeader
    ? '#FFEEBF;'
    : '#FFDF85'
  )};
    border: 0.6px solid #FFC72C;
  }
`;
const OutboxCardButtons = ({ item, inHeader, items, clearCards }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleDelete = (e) => {
    e.stopPropagation();
    if (inHeader) {
      dispatch(actionCreator(OutboxAction.DeleteOutboxCards, { items }));
      clearCards();
    } else {
      dispatch(actionCreator(OutboxAction.DeleteOutboxCard, { item }));
    }
  };

  const directionTooltip = inHeader ? 'up' : 'down';

  return (
    <CardButtonsWrappers inHeader>
      <Tooltip text={t('removeT')} direction={directionTooltip}>
        <CardButton isDelete onClick={handleDelete}>
          <TrashSvg />
        </CardButton>
      </Tooltip>
    </CardButtonsWrappers>
  );
};

export default OutboxCardButtons;
