import classNames from 'classnames/bind';
import React from 'react';
import NavSlider from '../../../components/NavigationSlider/NavSlider';
import rawStyles from '../../Library/LibraryHeader.module.scss';
import SecondaryMenu from '../../../components/LibraryHeaderMenu/childComponents/SecondaryMenu';

const cn = classNames.bind(rawStyles);

const ContactsHeaderUI = (props) => {
  return (
    <div className={cn('header_container')}>
      <div className={cn('slider_and_sort_container', 'orders', 'isUsers')}>
        <div className={cn('slider_parent', 'isUsers')}>
          <NavSlider
            setSieve={props.setSieve}
            setActiveNavSlider={props.setActiveNavSlider}
            activeNavSlider={props.activeNavSlider}
            viewMode={props.viewMode}
            isCardView={props.isCardsView}
            items={props.sievedItems}
            totalSubscribers={props.totalSubscribers}
            sieveOptions={props.sieveOptions}
            isBottomMargin
            isNoLeftMargin
            isChannels
            forContacts
          />
        </div>
        <SecondaryMenu
          sortType={props.sortType}
          setSort={props.setSort}
          place="contacts"
        />
      </div>
    </div>
  );
};

export default ContactsHeaderUI;
