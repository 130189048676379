import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import styles from '../Dashboard.module.scss';
import { COLUMN_CUSTOM_TYPE, dragType, empty } from '../../../utils/constants';
import EditColumn from '../EditColumn/EditColumn';
import CardInDashBoard from '../../../components/CardInDashBoard';
import CardWrapper from '../../../components/CardInDashBoard/CardWrapper';
import { ReactComponent as PlusSvg } from '../../../images/icons/plus_black.svg';
import { ReactComponent as SeparatorSvg } from '../../../images/2023/svg/Card_separator.svg';
import { actionCreator } from '../../../shared/redux/actionHelper';
import DashboardColumns from '../../../redux/dashboardColumns/types';
import ActionDraggable from '../../../redux/dragable/action';

const cn = classNames.bind(styles);

const KanbanViewContent = ({
  refScrollBlocks,
  onDragEnter,
  onDropCard,
  clearDragState,
  dragCard,
  dragColumnOverId,
  setDragColumn,
  setdragColumnOverId,
  setDragCard,
  columnDropDepthRef,
  highlighted,
  addNewColumnHandler,
  dragColumn,
  setDragCardOverId,
  cardDropDepthRef,
  dragCardOverId,
  ...props
}) => {
  const dispatch = useDispatch();
  const notSeenPlaylist = useSelector(
    (state) => state.dashboardColumns.notSeenPlaylist || empty,
  );
  const { zActiveItemId } = useSelector((state) => state.support || empty);
  const {
    columns: dashboardColumns,
    structure,
    cards,
    closeColumn,
  } = useSelector((state) => state.dashboardColumns || empty);

  // const [lastItems, setLastItems] = useState(LAST_ITEMS.ALL);

  const dropDepthRef = useRef(null);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     dispatch(actionCreator(DashboardColumns.column.updateInboxColumnSaga, {}));
  //   }, 30000);
  //   return () => clearInterval(interval);
  // }, []);

  // const now = Math.round(new Date().getTime() / 1000);

  const dragCardStart = (itemId, isInbox, item) => (e) => {
    dispatch(ActionDraggable.DragBoardCard(itemId));
    if (isInbox && notSeenPlaylist[item.wrapperId]) {
      dispatch(
        actionCreator(DashboardColumns.card.addViewedPlaylistManagerSR, {
          playlistWrapperId: item.wrapperId,
        }),
      );
    }
    setTimeout(() => {
      setDragCard(itemId);
    }, 10);
    e.stopPropagation();

    const id = document.getElementById(itemId);
    const div2 = id.cloneNode(true);
    const div = document.createElement('div');
    div.className = 'dragCard';
    div2.className = 'dragCardNested';
    div.appendChild(div2);
    div.id = dragType.board;
    document.body.appendChild(div);

    const rect = id.getBoundingClientRect();
    e.dataTransfer.setDragImage(
      div,
      e.clientX - rect.left,
      e.clientY - rect.top,
    );
  };

  const dragColumnStart = (item) => (e) => {
    setTimeout(() => {
      setDragColumn(item);
    }, 10);
    const id = document.getElementById(item);
    const div2 = id.cloneNode(true);
    const div = document.createElement('div');
    div.className = 'dragColumn';
    div.appendChild(div2);
    div.id = dragType.board;
    document.body.appendChild(div);
    const rect = id.getBoundingClientRect();
    e.dataTransfer.setDragImage(
      div,
      e.clientX - rect.left,
      e.clientY - rect.top,
    );
  };

  const onDragLeave = () => {
    columnDropDepthRef.current -= 1;
    if (columnDropDepthRef.current > 0) return;
    setdragColumnOverId(null);
  };

  const onDragCardLeave = () => {
    cardDropDepthRef.current -= 1;
    if (cardDropDepthRef.current > 0) return;
    setDragCardOverId(null);
  };

  const onDragCardEnter = (idHover) => () => {
    cardDropDepthRef.current += 1;
    setDragCardOverId(idHover);
  };

  const addBlockToScrollRefs = (ref, itemId) => {
    refScrollBlocks.current[itemId] = ref;
  };

  const visibleColumns = [...structure, closeColumn].filter(elem => !elem.isHide);
  return (
    <div className={styles.column_group}>
      {structure
        && structure.map((column, index) => (
          <div
            key={column.id}
            className={`${styles.hoverHandler}
           ${column.isHide ? styles.isHide : ''} 
           ${zActiveItemId === column.id ? styles.zActive : ''}`}
          >
            <div
              ref={(ref) => addBlockToScrollRefs(ref, column.id)}
              draggable={column.id !== zActiveItemId}
              onDragLeave={onDragLeave}
              onDragEnter={onDragEnter(column.id)}
              onDragStart={dragColumnStart(column.id)}
              onDrop={(e) => onDropCard(e, column.id)}
              onDragEnd={clearDragState}
              id={column.id}
              className={styles.wrap_column}
            >
              <div
                className={cn('column_separator', {
                  column_drag_hover:
                    dragColumn
                    && dragColumn !== column.id
                    && column.id === dragColumnOverId,
                })}
              />

              <div
                className={cn('column', {
                  column_hover: dragCard && column.id === dragColumnOverId,
                })}
              >
                <div ref={dropDepthRef}>
                  <EditColumn
                    column={dashboardColumns[column.id]}
                    isLastColumn={visibleColumns[visibleColumns.length - 1].id === column.id}
                  />
                </div>
                {/* <PerfectScrollbar> */}
                <div key={column.id} className={styles.column_item_group}>
                  {column.items.map((item) => (
                    <div
                      key={item}
                      onDragLeave={onDragCardLeave}
                      onDrop={(e) => onDropCard(e, column.id, item)}
                      onDragEnter={onDragCardEnter(item)}
                      className={styles.card}
                    >
                      <div
                        className={cn('separator')}
                      />
                      {dragCard
                        && item === dragCardOverId
                        && dragCard !== item && <SeparatorSvg />}
                      <CardWrapper item={cards[item]}>
                        <div
                          onDragStart={dragCardStart(item)}
                          id={item}
                          draggable
                          onDragEnd={clearDragState}
                          key={item}
                          className={cn({ draggable_item: dragCard === item })}
                          style={{ position: 'relative' }}
                        >
                          <CardInDashBoard
                            item={cards[item]}
                            highlighted={highlighted}
                            cardIsDrag={dragCard === item}
                            activeNavSlider={props.activeNavSlider}
                            localSelector={props.localSelector}
                          />
                        </div>
                      </CardWrapper>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              onClick={() => addNewColumnHandler(index)}
              className={styles.addColumn}
            >
              <PlusSvg />
            </div>
          </div>
        ))}
      <div
        key="close"
        className={cn('hoverHandler', {
          isHide: closeColumn?.isHide,
          zActive: zActiveItemId === 'close',
        })}
      >
        <div
          ref={(ref) => addBlockToScrollRefs(ref, COLUMN_CUSTOM_TYPE.CLOSE)}
          onDragLeave={onDragLeave}
          onDragEnter={onDragEnter(COLUMN_CUSTOM_TYPE.CLOSE)}
          onDragStart={dragColumnStart(COLUMN_CUSTOM_TYPE.CLOSE)}
          onDrop={(e) => onDropCard(e, COLUMN_CUSTOM_TYPE.CLOSE)}
          onDragEnd={clearDragState}
          key={COLUMN_CUSTOM_TYPE.CLOSE}
          id={COLUMN_CUSTOM_TYPE.CLOSE}
          className={styles.wrap_column}
        >
          <div
            className={cn('column_separator', {
              column_drag_hover:
                dragColumn
                && dragColumn !== COLUMN_CUSTOM_TYPE.CLOSE
                && dragColumnOverId === COLUMN_CUSTOM_TYPE.CLOSE,
            })}
          />

          <div
            className={cn('column', {
              column_hover:
                dragCard && dragColumnOverId === COLUMN_CUSTOM_TYPE.CLOSE,
            })}
          >
            <div ref={dropDepthRef}>
              <EditColumn
                column={{
                  ...closeColumn,
                  id: COLUMN_CUSTOM_TYPE.CLOSE,
                  name: 'Completed',
                }}
                titleRef={dropDepthRef}
                isStatic
              />
            </div>
            {/* <PerfectScrollbar> */}
            <div key="column.id2" className={styles.column_item_group}>
              {closeColumn?.items?.map((item) => (
                <div
                  key={item.id}
                  onDragLeave={onDragCardLeave}
                  onDrop={(e) => onDropCard(e, COLUMN_CUSTOM_TYPE.CLOSE, item.id)
                  }
                  onDragEnter={onDragCardEnter(item.id)}
                  className={styles.card}
                >
                  <div
                    className={cn('separator')}
                  />
                  {dragCard
                    && item.id === dragCardOverId
                    && dragCard !== item.id && <SeparatorSvg />}
                  <CardWrapper item={item}>
                    <div
                      onDragStart={dragCardStart(item.id)}
                      id={item.id}
                      draggable
                      onDragEnd={clearDragState}
                      key={item.id}
                      className={cn({ draggable_item: dragCard === item.id })}
                    >
                      <CardInDashBoard
                        item={item}
                        highlighted={highlighted}
                        cardIsDrag={dragCard === item.id}
                        activeNavSlider={props.activeNavSlider}
                        localSelector={props.localSelector}
                      />
                    </div>
                  </CardWrapper>
                </div>
              ))}
            </div>
            {/* </PerfectScrollbar> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KanbanViewContent;
