import React from 'react';
import {
  BoldButton,
  CenterButton,
  CodeBlockButton,
  colors,
  colorsBackGround,
  DynamicIcon,
  Indent,
  IndentDecrease,
  IndentDecreaseTextButton,
  IndentTextButton,
  ItalicButton,
  LeftButton,
  LinkIcon,
  RightButton,
  StrikethroughButton,
  TextColorButton,
  TextColorButtonBackGround,
  UnderlineButton,
} from '../../utils/richTextBar';
import styles from './MomentBlock.module.css';
import { ReactComponent as TextColorSvg } from '../../images/icons/color_text.svg';
import { ReactComponent as BackgroundColorSvg } from '../../images/icons/backgroundColor.svg';
import { ReactComponent as TagButtonSvg } from '../../images/icons/tag_butt.svg';
import { ReactComponent as Title1Svg } from '../../images/icons/title_1.svg';
import { ReactComponent as Title2Svg } from '../../images/icons/title_2.svg';
import { ReactComponent as Title3Svg } from '../../images/icons/title_3.svg';
import './MomentBlock.css';

const prevent = (e) => {
  e.preventDefault();
  e.stopPropagation();
};
export const toolBarCreator = (
  type,
  isTitle,
  isLinkVisible,
  isParagraph,
  isList,
  InlineToolbar,
  setIsLinkVisible,
  saveAndUpdate,
  updateType,
  indentCalc,
  toolbarRef,
  isCallout,
  setSavedExternalProps,
  linkCreateHandler,
) => {
  if (!isTitle) {
    return (
      <InlineToolbar isLinkVisible={isLinkVisible}>
        {(externalProps) => (
          <div ref={toolbarRef}>
            <BoldButton {...externalProps} />
            <ItalicButton {...externalProps} />
            <UnderlineButton {...externalProps} />
            <StrikethroughButton {...externalProps} />

            <div className={styles.separator} />

            <div className={styles.wa}>
              <div onMouseDown={prevent}>
                <DynamicIcon {...externalProps} />
              </div>
              <div className={styles.a}>
                <LeftButton {...externalProps} />
                <CenterButton {...externalProps} />
                <RightButton {...externalProps} />
              </div>
            </div>

            {!(isParagraph || isCallout) && !isList && (
              <>
                <IndentDecrease {...externalProps} />
                <Indent {...externalProps} />
              </>
            )}
            {(isParagraph || isCallout) && !isList && (
              <>
                <IndentDecreaseTextButton {...externalProps} />
                <IndentTextButton {...externalProps} />
              </>
            )}

            {isList && (
              <>
                <div className="bpsgbes">
                  <button
                    onMouseDown={(e) => {
                      prevent(e);
                      updateType(indentCalc('indent'));
                    }}
                    className="b181v2oy"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.66699 5.33333L0.666992 8L3.66699 10.6667M1.66699 2H15.0003H1.66699ZM7.00033 6H15.0003H7.00033ZM7.00033 10H15.0003H7.00033ZM1.66699 14H15.0003H1.66699Z"
                        stroke="#242633"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>

                <div className="bpsgbes">
                  <button
                    onMouseDown={(e) => {
                      prevent(e);
                      updateType(indentCalc('IndentDecrease'));
                    }}
                    className="b181v2oy"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.3333 10.6667L15.3333 8L12.3333 5.33333M14.3333 14H1H14.3333ZM9 10H1H9ZM9 6H1H9ZM14.3333 2H1H14.3333Z"
                        stroke="#242633"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </>
            )}
            <div className={styles.separator} />

            <div
              onMouseDown={prevent}
              onMouseUp={prevent}
              onClick={(e) => {
                prevent(e);
                linkCreateHandler(externalProps, e);
              }}
            >
              <LinkIcon
                setSavedExternalProps={setSavedExternalProps}
                {...externalProps}
              />
            </div>

            <CodeBlockButton {...externalProps} />

            <div onMouseDown={prevent} className={styles.wk}>
              <TextColorSvg />
              <div className={styles.k}>
                {colors.map((i) => TextColorButton(i, 'Text')(externalProps))}
              </div>
            </div>

            <div onMouseDown={prevent} className={styles.wz}>
              <BackgroundColorSvg />
              <div className={styles.z}>
                {colorsBackGround.map((i, index) => TextColorButtonBackGround(
                  i,
                  'BackGround',
                  index,
                )(externalProps),
                )}
              </div>
            </div>
            <div className={styles.separator} />

            <div onMouseDown={prevent} className={styles.bar_button_disable}>
              <TagButtonSvg />
            </div>
          </div>
        )}
      </InlineToolbar>
    );
  }

  return (
    <InlineToolbar isLinkVisible={isLinkVisible}>
      {(externalProps) => (
        <div>
          <div
            onMouseDown={prevent}
            onClick={() => saveAndUpdate('title', externalProps)}
            className={`${styles.bar_turn_into} ${
              type === 'title' ? styles.bar_turn_into_active : ''
            }`}
          >
            <Title1Svg />
          </div>
          <div
            onMouseDown={prevent}
            onClick={() => saveAndUpdate('title2', externalProps)}
            className={`${styles.bar_turn_into} ${
              type === 'title2' ? styles.bar_turn_into_active : ''
            }`}
          >
            <Title2Svg />
          </div>
          <div
            onMouseDown={prevent}
            onClick={() => saveAndUpdate('title3', externalProps)}
            className={`${styles.bar_turn_into} ${
              type === 'title3' ? styles.bar_turn_into_active : ''
            }`}
          >
            <Title3Svg />
          </div>
          <div className={styles.separator} />
          <div onMouseDown={prevent} className={styles.wk}>
            <TextColorSvg />
            <div className={styles.k}>
              {colors.map((i) => TextColorButton(i, 'Text')(externalProps))}
            </div>
          </div>
          <div onMouseDown={prevent} className={styles.bar_button_disable}>
            <TagButtonSvg />
          </div>
        </div>
      )}
    </InlineToolbar>
  );
};
