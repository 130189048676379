import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import Close from '../SimpleComponents/Close/Close';
import { ReactComponent as CloseSvg } from '../../images/icons/close_12.svg';
import { actionAddSubscribe } from '../../redux/channels/action';
import validateEmail from '../../utils/validators/validateEmail';
import UI from '../../UIComponents';
import { actionBulkAddContact } from '../../redux/contacts/action';

const cx = classNames.bind(styles);

const InviteUserModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [inputEmail, setInputEmail] = useState('');
  const [emailsToInvite, setEmailsToInvite] = useState([]);
  const [validateError, setValidateError] = useState('');
  const { folderId } = useSelector((state) => state.user);
  const refInput = useRef(null);
  const isUsers = history.location.pathname === '/users';
  const close = () => {
    dispatch(actionCloseModal());
  };
  const validate = (value) => {
    const emailIsValid = validateEmail(value);
    if (!emailIsValid) setValidateError(t('pleaseEnterValidEmailT'));
    return emailIsValid;
  };
  const send = () => {
    if (inputEmail.trim() && validate(inputEmail)) {
      isUsers
        ? dispatch(actionBulkAddContact([...emailsToInvite, inputEmail.trim()]))
        : dispatch(
          actionAddSubscribe(
            [...emailsToInvite, inputEmail.trim()],
            folderId,
          ),
        );
      dispatch(actionCloseModal());
    } else if (!inputEmail.trim() && emailsToInvite.length) {
      isUsers
        ? dispatch(actionBulkAddContact(emailsToInvite))
        : dispatch(actionAddSubscribe(emailsToInvite, folderId));
      dispatch(actionCloseModal());
    } else if (!inputEmail.trim()) validate(inputEmail);
  };

  const deleteEmail = (indexToDel) => {
    setEmailsToInvite(
      emailsToInvite.filter((_, index) => index !== indexToDel),
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
      if (inputEmail.trim() && validate(inputEmail.trim())) {
        setEmailsToInvite([...emailsToInvite, inputEmail.trim()]);
        setInputEmail('');
      } else {
        e.preventDefault();
      }
    } else if (validateError) setValidateError('');
  };

  return (
    <div onClick={close} className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'InviteUserModal')}
      >
        <div className={cx('title_wrapper')}>
          <div className={cx('title')}>{t('inviteUserT')}</div>
          <Close onClick={close} />
        </div>

        <div className={cx('inputEmail_wrapper')}>
          <div className={cx('inputEmail_description')}>{t('emailAddressT')}</div>
          <div
            onClick={() => refInput.current.focus()}
            className={cx('input_area')}
          >
            {emailsToInvite.map((i, index) => (
              <div key={`${i}${index}`} className={cx('emailToInvite_wrapper')}>
                <div className={cx('email')}>{i}</div>
                <div className={cx('close_wrapper')}>
                  <CloseSvg onClick={() => deleteEmail(index)} />
                </div>
              </div>
            ))}
            <input
              onKeyDown={handleKeyPress}
              autoFocus
              ref={refInput}
              onBlur={() => inputEmail.trim() && validate(inputEmail)}
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
              className={cx('title')}
            />
          </div>
        </div>

        <UI.Error error={validateError} />

        <div className={cx('group_buttons')}>
          <div onClick={close} className={cx('group_buttons_cancel')}>
            {t('cancelT')}
          </div>
          <div onClick={send} className={cx('group_buttons_send_invite')}>
            {t('sendInviteT')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteUserModal;
