import { MiniPlaylists } from './types';
import downloadStatus from '../../utils/dataUtil';
import EditPlaylist from '../playlists/types';
import { ServiceUser } from '../user/types';

const defaultState = {};

export default (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case MiniPlaylists.getMiniPlaylist: {
      state[payload.id] = { status: downloadStatus.pending };
      return { ...state };
    }
    case EditPlaylist.EditPublishStateR: {
      return {
        ...state,
        [payload.playlistId]: {
          ...state[payload.playlistId],
          ...payload.inputs,
          isPublish: payload.isPublish,
        },
      };
    }
    case MiniPlaylists.saveMiniPlaylist: {
      state[payload.id] = payload;
      state[payload.id].status = downloadStatus.success;
      return { ...state };
    }
    case EditPlaylist.Update: {
      const newState = {
        ...state,
        [payload.id]: { ...state[payload.id], title: payload.title },
      };
      return newState;
    }

    case MiniPlaylists.getMiniPlaylistsClear: {
      return {};
    }

    case MiniPlaylists.togglePublishMiniPlaylist: {
      if (state[payload.id]) {
        state[payload.id].isPublish = payload.isPublish;
        return { ...state };
      }
      return state;
    }

    case EditPlaylist.MoveToTrash: {
      state[payload.id].movedToTrash = true;
      return { ...state };
    }
    case EditPlaylist.Delete: {
      delete state[payload.id];
      return { ...state };
    }
    case ServiceUser.Logout: {
      return { ...defaultState };
    }
    default:
      return state;
  }
};
