import React from 'react';
import styled from 'styled-components/macro';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import rawStyles from './elements.module.scss';
import { ReactComponent as TrashIcon } from '../../../images/icons/trash_Icon.svg';

import Tooltip from '../../../shared/Tooltips/Tooltip';

const DeleteButtonContainer = styled.div`
  width: 32px;
  height: 32px;
  border: 0.6px solid #ece2e2;
  border-radius: 32px;
  opacity: 0.5;
  padding-left: 7px;
  padding-top: 5px;
  ${({ isPageAndCompress }) => (isPageAndCompress ? `
     position: absolute;
     left: 48px;
     top: 0px;` : '')}
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;
const DeleteButtonShell = styled.div`
  right: 0;
  position: absolute;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  display: none;
  gap: 14px;
`;

const DeleteButtonBalancer = styled.div`
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  right: 40px;
  position: absolute;
  z-index: 100;
`;

const cx = classNames.bind(rawStyles);

const DeleteContentButton = ({ deleteHandler, children, isCompressView, isPage }) => {
  const { t } = useTranslation();
  return (
    <DeleteButtonBalancer isCompressView={isCompressView}>
      <DeleteButtonShell
        className={cx('rising_button_flex', 'rising_button')}
      >
        {children}
        <DeleteButtonContainer
          isPageAndCompress={isPage && isCompressView}
          onClick={deleteHandler}
        >
          <Tooltip
            text={t('removeT')}
            direction="down"
            place="ActionsTrash"
          >
            <TrashIcon />
          </Tooltip>

        </DeleteButtonContainer>

      </DeleteButtonShell>
    </DeleteButtonBalancer>
  );
};

export default DeleteContentButton;
