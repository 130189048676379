import {
  combineComponentsAndLibraryComponents,
  parseContentForUsage,
  sanitizeToLoad,
} from "../utils/helpers";
import { BlockTypes, UiComponentTypes } from "../utils/constants";
import draftDataConvertToLoad from "../utils/draftDataConvert";

export const parseBlocks = (components) => {
  let blocks = [];
  if (components?.length) {
    blocks = components.map((item) => {
      const content = {};
      if (!item) return content;
      if (item.type === "page") {
        content.title =
          (item?.page &&
            item?.page[0] &&
            sanitizeToLoad(item?.page[0]?.title)) ||
          "The functional logic has been updated.";
        content.pageId = item?.page && item?.page[0] && item?.page[0]?.id;
      } else if (
        item.type === BlockTypes.approveButton ||
        item.type === BlockTypes.actionButton ||
        item.type === BlockTypes.shortText
      ) {
        try {
          content.state = JSON.parse(sanitizeToLoad(item.content));
        } catch (err) {
          console.error(err.message);
          content.state = "";
        }
      } else if (
        item.type === BlockTypes.webSite ||
        item.type === BlockTypes.lineSeparator
      ) {
        content.state = parseContentForUsage(item.content);
      } else {
        content.state = draftDataConvertToLoad(item.content);
      }
      return {
        id: item.id,
        type: item.type,
        innerHtml: item.innerHtml,
        position: item.position,
        isHidden: item.isHidden,
        width: item.width,
        ...content,
      };
    });
  }
  return blocks;
};

export const sanitizeStringFields = (obj) => {
  const result = {};

  Object.entries(obj).forEach((item) => {
    if (typeof item[1] === "string") {
      result[item[0]] = sanitizeToLoad(item[1]).replaceAll('\\"', "'");
    } else result[item[0]] = item[1];
  });

  return result;
};

export const parseLibraryComponent = (inputComponents) => {
  return inputComponents.map((item) => {
    // LibraryComponent: [{
    //  id: String,
    //  type: String,
    //  title: String,
    //  components: [...],
    //  type: String,
    //  urlFile: String,
    //  linkUrl: String,
    //  size: Integer,
    // }]
    const libraryComponent = item;
    let components;
    // components: [{
    //    id: String,
    //    position: Float,
    //    type: String,
    //    innerHtml: String,
    // }]
    if (libraryComponent.type === "application/pdf") {
      components = [
        {
          id: libraryComponent.id,
          type: BlockTypes.pdf,
          state: {
            data: {
              nestedItemId: libraryComponent.id,
              width: libraryComponent.width,
              representationState: libraryComponent.representationState,
              title: libraryComponent.title,
              size: libraryComponent.size,
              urlFile: libraryComponent.urlFile,
            },
          },
        },
      ];
    } else if (UiComponentTypes.link[libraryComponent.type]) {
      components = [
        {
          ...libraryComponent,
        },
      ];
    } else if (UiComponentTypes.archive[libraryComponent.type]) {
      components = [
        {
          ...libraryComponent,
        },
      ];
    } else if (UiComponentTypes.ms[libraryComponent.type]) {
      components = [
        {
          ...libraryComponent,
        },
      ];
    } else if (UiComponentTypes.image[libraryComponent.type]) {
      components = [
        {
          id: libraryComponent.id,
          title: libraryComponent.title,
          type: libraryComponent.type,
          urlFile: libraryComponent.urlFile,
          urlSmallImage: libraryComponent.urlSmallImage,
          urlVerySmallImage: libraryComponent.urlVerySmallImage,
          size: libraryComponent.size,
        },
      ];
    } else if (
      UiComponentTypes.media[libraryComponent.type] ||
      UiComponentTypes.text[libraryComponent.type]
    ) {
      components = [
        {
          id: libraryComponent.id,
          title: libraryComponent.title,
          type: libraryComponent.type,
          urlFile: libraryComponent.urlFile,
          size: libraryComponent.size,
        },
      ];
    } else if (!libraryComponent?.components?.length) {

      components = [
        // {
        //   id: libraryComponent.id,
        //   title: libraryComponent.title,
        //   type: libraryComponent.type,
        //   urlFile: libraryComponent.urlFile,
        //   size: libraryComponent.size,
        // },
      ];
    } else {
      components = combineComponentsAndLibraryComponents({
        components: libraryComponent.components.filter(
          (i) => i.type !== BlockTypes.component,
        ),
        libraryComponents: libraryComponent.components.filter(
          (i) => i.type === BlockTypes.component,
        ),
      });
    }
    return sanitizeStringFields({
      ...item,
      components,
    });
  })[0];
};

export const calcColorClassname = (item) =>
  `text ${item.color === item.backgroundColor ? "stroke" : ""}`;
