import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FitWrapper } from './styled';
import { ReactComponent as FitSVG } from '../../images/2023/svg/small/player/fit_to_width.svg';
import { PLAYER_SETTINGS } from '../../utils/constants';
import { actionCreator } from '../../shared/redux/actionHelper';
import { ServiceUser } from '../../redux/user/types';
import { ReactComponent as ContentHeightSvg } from '../../images/icons/player/content_height_button_24_19.svg';

const FitContent = () => {
  const dispatch = useDispatch();
  const {
    playerSettings: { contentWidthMode },
  } = useSelector((state) => state.user);
  const isDefault = contentWidthMode === PLAYER_SETTINGS.widthMode.fullheight;

  const isActive = contentWidthMode === PLAYER_SETTINGS.widthMode.fullheight
    || contentWidthMode === PLAYER_SETTINGS.widthMode.fullwidth;

  const toggleContentWidthMode = () => {
    if (!isActive) {
      dispatch(
        actionCreator(ServiceUser.Player.SetContentWidthMode, {
          value: PLAYER_SETTINGS.widthMode.fullwidth,
        }),
      );
      return;
    }
    if (contentWidthMode === PLAYER_SETTINGS.widthMode.fullwidth) {
      dispatch(
        actionCreator(ServiceUser.Player.SetContentWidthMode, {
          value: PLAYER_SETTINGS.widthMode.fullheight,
        }),
      );
      return;
    }
    if (contentWidthMode === PLAYER_SETTINGS.widthMode.fullheight) {
      dispatch(
        actionCreator(ServiceUser.Player.ChangeZoom, {
          value: 100,
        }),
      );
    }
  };

  return (
    <FitWrapper
      isActive={isActive}
      isDefault={isDefault}
      onClick={toggleContentWidthMode}
    >
      {isDefault && <ContentHeightSvg />}
      {!isDefault && <FitSVG />}
    </FitWrapper>
  );
};

export default FitContent;
