export default {
  BulkAddContact: '[Contacts] Add Contacts',
  AddSingleToContactMap: '[Contacts] Add single Contact Map REDUX',
  CreateAndAddSingleToContactMap: '[Contacts] Create Add single Contact Map REDUX',
  AddContactR: '[Contacts] Add Contact REDUX',
  AddContactMap: '[Contacts] Add Contact Map REDUX',
  GetContactS: '[Contacts] Get Contact',
  RemoveContact: '[Contacts] RemoveContact',
  LoadUserContactsS: '[Contacts] Load User Contacts SAGAS',
  InitialContactsLoadR: '[Contacts] Initial Contacts Load REDUX',
  ChangeContactStatus: '[Contacts] Change Contact Status',
  EditContact: '[Contacts] Edit Contact',
  UpdatePinState: '[Contacts] Update Pin State',
  CreateContact: '[Contacts] Create Contact',
  ChangeContactR: '[Contacts] Change Contact REDUX',
  RearrangeContactItemS: '[Contacts] Re-arrange Contact Item SAGAS',
  ChangeContactItemPositionR: '[Contacts] Change Contact Item Position REDUX',
  CheckHasPin: '[Contacts] Check has pin in Contact REDUX',
};
