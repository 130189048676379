import { select } from '@redux-saga/core/effects';
import { call } from 'redux-saga/effects';

import { maxAvatarSize, maxEmojiSize } from '../../GLOBAL_CONST';
import validateEmail from '../validators/validateEmail';
import { get } from '../request';
import { BASEURL } from '../../environments/environments';
import { sanitizeHtml, sanitizeToSave } from '../helpers';
import { getSettings } from '../../sagas/sagasUtils';
import {
  ERROR_PRIORITY, MAX_LENGTH,
  MAX_LENGTH_EMAIL,
  MAX_LENGTH_PHONE,
  MAX_LENGTH_TEXTAREA,
  MIN_LENGTH, SPECIAL_CHAR, STRING_HAS_DIGITS,
  VALIDATE,
} from './constants';

function calculateImageDimensions(url) {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    img.onload = () => resolve({
      width: img.width,
      height: img.height,
    });
    img.onerror = (error) => reject(error);
    img.src = url;
  });
}


export const VALIDATORS = {
  [VALIDATE.MaxFileSize]: (file) => (file?.size && file?.size <= maxAvatarSize.bytes
    ? false
    : ERROR_PRIORITY.Three),
  [VALIDATE.FileType]: (file) => (file?.type && maxAvatarSize.formats[file?.type]
    ? false
    : ERROR_PRIORITY.Two),
  [VALIDATE.MinLength]: (value) => (!value || value.length >= MIN_LENGTH ? false : ERROR_PRIORITY.Three),
  [VALIDATE.MaxLength]: (value) => (value.length > MAX_LENGTH ? ERROR_PRIORITY.Three : false),
  [VALIDATE.MaxLengthPhone]: (value) => {
    const intermediateValue = value.replace('+', '');
    return intermediateValue.length > MAX_LENGTH_PHONE
      ? ERROR_PRIORITY.Three
      : false;
  },
  [VALIDATE.MaxLengthEmail]: (value) => (value.length > MAX_LENGTH_EMAIL ? ERROR_PRIORITY.Three : false),
  [VALIDATE.MaxLengthTextarea]: (value) => (value.length > MAX_LENGTH_TEXTAREA ? ERROR_PRIORITY.Three : false),
  [VALIDATE.SpecialCharacters]: (value) => (SPECIAL_CHAR.test(value) ? ERROR_PRIORITY.Three : false),
  [VALIDATE.IsRequired]: (value) => (value ? null : ERROR_PRIORITY.Three),
  [VALIDATE.IsEmail]: (value) => (validateEmail(value) ? null : ERROR_PRIORITY.Two),
  * [VALIDATE.UsernameIsFree](value) {
    if (!value) return false;
    const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
    const { data } = yield get(`${BASEURL}/v1/users/username`)({
      key: sanitizeToSave(sanitizeHtml(value)),
      token,
    });
    return !data.result ? false : ERROR_PRIORITY.Three;
  },
  * [VALIDATE.EmailIsFree](value) {
    if (!value) return false;
    const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
    const { data } = yield get(`${BASEURL}/v1/users/email_check`)({
      key: sanitizeToSave(sanitizeHtml(value)),
      token,
    });
    return !data.result ? false : ERROR_PRIORITY.Three;
  },
  * [VALIDATE.EmojiMaxFileSize](file) {
    const {
      inputs: { emojiFile },
    } = yield select(getSettings);
    return emojiFile?.tmpImageBlob
    || (file?.size && file?.size <= maxEmojiSize.bytes)
      ? false
      : ERROR_PRIORITY.Three;
  },

  * [VALIDATE.EmojiFileType](file) {
    const {
      inputs: { emojiFile },
    } = yield select(getSettings);
    return emojiFile?.tmpImageBlob
    || (file?.type && maxEmojiSize.formats[file?.type])
      ? false
      : ERROR_PRIORITY.Two;
  },
  * [VALIDATE.EmojiDimensions](file) {
    try {
      const {
        inputs: { emojiFile },
      } = yield select(getSettings);
      if (emojiFile?.tmpImageBlob) return false;
      const blobUrl = URL.createObjectURL(file);
      const dimensions = yield call(calculateImageDimensions, blobUrl);

      return dimensions.width === maxEmojiSize.dimensionsPixels.width
      && dimensions.height === maxEmojiSize.dimensionsPixels.height
        ? false
        : ERROR_PRIORITY.Three;
    } catch (err) {
      return ERROR_PRIORITY.Three;
    }
  },
  * [VALIDATE.IsEmojiNameUnique](value, emojiId) {
    try {
      const {
        tables: { emojis },
      } = yield select(getSettings);

      if (!value || emojis[emojiId]?.name === value) return false;

      const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
      const { data } = yield get(`${BASEURL}/v1/users/emoji`)({
        key: sanitizeToSave(sanitizeHtml(value)),
        token,
      });

      return !data.result ? false : ERROR_PRIORITY.Two;
    } catch (err) {
      return ERROR_PRIORITY.Three;
    }
  },
  [VALIDATE.NoDigits]: (value) => (STRING_HAS_DIGITS.test(value) ? ERROR_PRIORITY.Three : false),
  * [VALIDATE.IsEmojiNameRequired](value) {
    const { inputs } = yield select(getSettings);
    if (!inputs.emojiFile.file) return false;
    return value ? null : ERROR_PRIORITY.Three;
  },
};
