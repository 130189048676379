import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import smile from '../../images/icons/smile.svg';
import info from '../../images/icons/info.svg';
import { actionCurrentEditTitle } from '../../redux/currentPage/action';
import { createDate } from '../../utils/dateConvert';
import {
  actionUpdateLibraryPageDescriptionShow,
  actionUpdateLibraryPageInLibraryField,
} from '../../redux/library/actions';
import {
  actionBlockUnloading,
  actionUnblockUnloading,
} from '../../redux/user/action';
import useThrottle from '../../utils/hooks/useThrottle';
import { actionWriteCombination } from '../../redux/shortcuts/action';
import { checkIsLastRowForTextArea, isFirsRw } from '../../utils/helperText';

const OSName = window.navigator.platform;

const PageCoreInfo = ({
  isInitialized,
  onDragLeave,
  onDragEnter,
  newFocus,
  isHasTitle,
  createNewBlock,
  setShowAdd,
  setIsHasTitle,
  goToOtherTextBlock,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputEl = useRef(null);
  const inputDescription = useRef(null);
  const { id } = useParams();
  const {
    isDownload,
    currentPageId,
    isLargeRightBar,
    isShowDescription,
    notDescriptionAdded,
    description: introDescription,
    title: introTitle,
  } = useSelector((state) => state.currentPage);
  const { anchorId } = useSelector((state) => state.support);

  const editableBlocks = useSelector((state) => state.currentPage.blocks) || [];
  const isUnloadingBlocked = useSelector(
    (state) => state.user.isUnloadingBlocked,
  );
  const [stateDescription, setStateDescription] = useState(introDescription);
  const [prevTitle, setPrevTitle] = useState(introTitle || '');
  const [throttleSaveTitle] = useThrottle(300);

  const handlerShowDescription = () => {
    if (!isLargeRightBar) {
      dispatch(
        actionUpdateLibraryPageDescriptionShow({
          id: currentPageId,
          value: !isShowDescription,
          field: 'isShowDescription',
        }),
      );
      setTimeout(() => {
        const end = introDescription?.length || 0;
        inputDescription.current.setSelectionRange(end, end);
        inputDescription.current.focus();
      }, 0);
    }
  };
  const handleUNDO = (event) => {
    if (
      event.keyCode === 90
      && event.shiftKey
      && (event.metaKey || (OSName === 'Win32' && event.ctrlKey))
    ) {
      event.preventDefault();
      dispatch(actionWriteCombination('mod+shift+z'));
      return;
    }
    if (
      event.keyCode === 90
      && (event.metaKey || (OSName === 'Win32' && event.ctrlKey))
    ) {
      event.preventDefault();
      dispatch(actionWriteCombination('mod+z'));
    }
  };
  useEffect(() => {
    if (anchorId === 'pageTitleInput') {
      inputEl.current.focus();
    }
  }, [anchorId]);

  useEffect(() => {
    if (newFocus.newFocusId === 'core') {
      if (isShowDescription) {
        inputDescription.current.focus();
      } else {
        inputEl.current.focus();
      }
    }
  }, [newFocus]);

  useEffect(() => {
    if (
      !isUnloadingBlocked
      && !!prevTitle
      && introTitle !== prevTitle
      && isInitialized
    ) {
      dispatch(actionBlockUnloading());
    }
  }, [introTitle, prevTitle]);

  useEffect(() => {
    setPrevTitle(introTitle || '');
  }, [introTitle]);

  useEffect(() => {
    const initEd = !!stateDescription;
    const isChanges = introDescription !== stateDescription;
    if (!isUnloadingBlocked && initEd && isChanges && isInitialized) {
      dispatch(actionBlockUnloading());
    }
  }, [introDescription, stateDescription]);

  useEffect(() => {
    setStateDescription(introDescription);
  }, [introDescription]);

  const onChangeTitle = (event) => {
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    event.target.rows = 1;
    event.target.rows = ~~(event.target.scrollHeight / 48) || 1;
    setPrevTitle(event.target.value || '');
    throttleSaveTitle(() => () => {
      if (!event.target.value) return;
      dispatch(
        actionUpdateLibraryPageInLibraryField({
          id,
          value: event.target.value || '',
          field: 'title',
        }),
      );
      dispatch(
        actionCurrentEditTitle(event.target.value, id, null, lastModifiedDate),
      );
    });
  };

  useEffect(() => {
    if (inputDescription.current) {
      inputDescription.current.rows = 1;
      inputDescription.current.rows = ~~(inputDescription.current.scrollHeight / 21) || 1;
    }
  }, [
    inputDescription.current,
    isShowDescription,
    introDescription,
    stateDescription,
    isDownload,
  ]);

  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.rows = 1;
      inputEl.current.rows = ~~(inputEl.current.scrollHeight / 48) || 1;
    }
  }, [inputEl.current, prevTitle, isDownload]);

  useEffect(() => {
    setIsHasTitle(!isDownload && !!introTitle);
    if (
      !isDownload
      && inputEl.current
      && (!introTitle || !editableBlocks.length)
    ) {
      inputEl.current.focus();
    }
  }, [isDownload]);

  useEffect(() => {
    if (inputEl.current && isHasTitle) {
      setShowAdd(true);
    } else setShowAdd(false);
  }, [!editableBlocks.length, inputEl.current, isHasTitle, isDownload]);

  const moveCaretAtEnd = (e) => {
    // setPrevTitle(introTitle);
    const tempValue = e.target.value;
    e.target.value = '';
    e.target.value = tempValue;
  };

  const saveTitle = (plase) => {
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const trimmedIntroTitle = prevTitle.trim();
    if (plase === 'Enter') createNewBlock();

    if (!trimmedIntroTitle) {
      const readableDate = createDate();
      dispatch(
        actionCurrentEditTitle(readableDate, id, null, lastModifiedDate),
      );
      setIsHasTitle(true);
      dispatch(
        actionUpdateLibraryPageInLibraryField({
          id,
          value: readableDate,
          field: 'title',
        }),
      );
      return;
    }
    if (trimmedIntroTitle) setIsHasTitle(true);

    if (trimmedIntroTitle === introTitle) return;
    inputEl.current?.blur();

    if (trimmedIntroTitle !== introTitle && trimmedIntroTitle) {
      dispatch(
        actionUpdateLibraryPageInLibraryField({
          id,
          value: prevTitle,
          field: 'title',
        }),
      );
    }

    dispatch(actionUnblockUnloading());
  };

  const saveDescription = (plase) => {
    if (plase === 'Enter') createNewBlock();
    if (stateDescription === introDescription) return;
    dispatch(
      actionUpdateLibraryPageDescriptionShow({
        id: currentPageId,
        value: stateDescription,
        field: 'description',
      }),
    );
    dispatch(actionUnblockUnloading());
  };

  const saveLongDescription = (event) => {
    if (event.target.value === introDescription) return;
    dispatch(
      actionUpdateLibraryPageDescriptionShow({
        id: currentPageId,
        value: event.target.value,
        field: 'description',
      }),
    );
    dispatch(actionUnblockUnloading());
  };

  const handleKeyPress = (event) => {
    if (event.key === 'ArrowDown') {
      if (checkIsLastRowForTextArea(event.target, prevTitle, 44)) {
        event.stopPropagation();
        event.preventDefault();
        if (isShowDescription) {
          inputDescription.current.focus();
        } else goToOtherTextBlock();
      }
    }
    handleUNDO(event);
    if (event.key === 'Backspace') {
      event.stopPropagation();
    }
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      saveTitle('Enter');
    }
  };
  const handleKeyPressDescription = (event) => {
    if (event.key === 'ArrowDown') {
      if (checkIsLastRowForTextArea(event.target, stateDescription)) {
        event.stopPropagation();
        event.preventDefault();
        goToOtherTextBlock();
      }
    }
    if (event.key === 'ArrowUp') {
      if (isFirsRw(stateDescription.slice(0, event.target.selectionEnd))) {
        event.stopPropagation();
        event.preventDefault();
        inputEl.current.focus();
      }
    }
    handleUNDO(event);
    if (event.key === 'Backspace') {
      event.stopPropagation();
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      saveDescription('Enter');
    }
  };

  const onChangeDescription = (event) => {
    setStateDescription(event.target.value);
    throttleSaveTitle(() => () => saveLongDescription(event));
  };

  return (
    <div
      className={`${styles.info_wrapper} ${
        isLargeRightBar ? styles.info_wrapper_small : ''
      }`}
    >
      <div
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        className={`${styles.info} ${
          isLargeRightBar ? styles.center_info : ''
        }`}
      >
        <div className={styles.info_block}>
          <div className={`${styles.info_block_add}`}>
            <img src={smile} alt="add icon" />
            <div>{t('addIconUpT')}</div>
          </div>
          <div>
            <div
              onClick={handlerShowDescription}
              className={`${styles.info_block_hide}`}
            >
              <img src={info} alt="hide/add description" />
              <div>
                {isShowDescription && t('hideDescriptionUpT')}
                {!isShowDescription
                  && (notDescriptionAdded
                    ? t('addDescriptionUpT')
                    : t('showDescriptionUpT'))}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.counter_shell}>
          <div
            className={`${styles.counter} ${
              document.activeElement.id === 'TitlePage'
                ? styles.counter_visible
                : ''
            }
              ${prevTitle.length < 50 && styles.counter_grey}
              ${
                !(prevTitle.length < 50) && prevTitle.length === 55
                  ? styles.counter_red
                  : styles.counter_yellow
              }`}
          >
            {prevTitle.length}/55
          </div>
          <textarea
            data-cy="titleEntity"
            placeholder={t('untitledSmartPageT')}
            onKeyDown={handleKeyPress}
            onBlur={saveTitle}
            ref={inputEl}
            id="TitlePage"
            maxLength="55"
            readOnly={isLargeRightBar}
            className={styles.input_title}
            onChange={onChangeTitle}
            onFocus={moveCaretAtEnd}
            value={prevTitle}
          />
        </div>
        <textarea
          placeholder={t('pageDescriptionT')}
          ref={inputDescription}
          className={`${styles.description}  ${
            !isShowDescription ? styles.display_none : ''
          }`}
          maxLength="1000"
          readOnly={isLargeRightBar}
          onKeyDown={handleKeyPressDescription}
          onBlur={saveDescription}
          onChange={onChangeDescription}
          value={stateDescription || ''}
        />
      </div>
    </div>
  );
};

export default PageCoreInfo;
