export const countries = {
  AD: 'Andorra',
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AO: 'Angola',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Aland Islands',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'Saint Barthelemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BQ: 'Bonaire, Saint Eustatius and Saba ',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos Islands',
  CD: 'Democratic Republic of the Congo',
  CF: 'Central African Republic',
  CG: 'Republic of the Congo',
  CH: 'Switzerland',
  CI: 'Ivory Coast',
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cape Verde',
  CW: 'Curacao',
  CX: 'Christmas Island',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands',
  FM: 'Micronesia',
  FO: 'Faroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'India',
  IO: 'British Indian Ocean Territory',
  IQ: 'Iraq',
  IR: 'Iran',
  IS: 'Iceland',
  IT: 'Italy',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts and Nevis',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: 'Laos',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MF: 'Saint Martin',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macao',
  MP: 'Northern Mariana Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PM: 'Saint Pierre and Miquelon',
  PR: 'Puerto Rico',
  PS: 'Palestinian Territory',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russia',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SH: 'Saint Helena',
  SI: 'Slovenia',
  SJ: 'Svalbard and Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'South Sudan',
  ST: 'Sao Tome and Principe',
  SV: 'El Salvador',
  SX: 'Sint Maarten',
  SY: 'Syria',
  SZ: 'Swaziland',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'East Timor',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkey',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ukraine',
  UG: 'Uganda',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatican',
  VC: 'Saint Vincent and the Grenadines',
  VE: 'Venezuela',
  VG: 'British Virgin Islands',
  VI: 'U.S. Virgin Islands',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis and Futuna',
  WS: 'Samoa',
  XK: 'Kosovo',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
} as const;

export const countriesPhonePrefixes = {
  Andorra: { name: 'Andorra', prefix: '376', code: 'AD' },
  'United Arab Emirates': {
    name: 'United Arab Emirates',
    prefix: '971',
    code: 'AE',
  },
  Afghanistan: { name: 'Afghanistan', prefix: '93', code: 'AF' },
  'Antigua and Barbuda': {
    name: 'Antigua and Barbuda',
    prefix: '1',
    code: 'AG',
  },
  Anguilla: { name: 'Anguilla', prefix: '1', code: 'AI' },
  Albania: { name: 'Albania', prefix: '355', code: 'AL' },
  Armenia: { name: 'Armenia', prefix: '374', code: 'AM' },
  Angola: { name: 'Angola', prefix: '244', code: 'AO' },
  Argentina: { name: 'Argentina', prefix: '54', code: 'AR' },
  'American Samoa': { name: 'American Samoa', prefix: '1', code: 'AS' },
  Austria: { name: 'Austria', prefix: '43', code: 'AT' },
  Australia: { name: 'Australia', prefix: '61', code: 'AU' },
  Aruba: { name: 'Aruba', prefix: '297', code: 'AW' },
  'Aland Islands': { name: 'Aland Islands', prefix: '358', code: 'AX' },
  Azerbaijan: { name: 'Azerbaijan', prefix: '994', code: 'AZ' },
  'Bosnia and Herzegovina': {
    name: 'Bosnia and Herzegovina',
    prefix: '387',
    code: 'BA',
  },
  Barbados: { name: 'Barbados', prefix: '1', code: 'BB' },
  Bangladesh: { name: 'Bangladesh', prefix: '880', code: 'BD' },
  Belgium: { name: 'Belgium', prefix: '32', code: 'BE' },
  'Burkina Faso': { name: 'Burkina Faso', prefix: '226', code: 'BF' },
  Bulgaria: { name: 'Bulgaria', prefix: '359', code: 'BG' },
  Bahrain: { name: 'Bahrain', prefix: '973', code: 'BH' },
  Burundi: { name: 'Burundi', prefix: '257', code: 'BI' },
  Benin: { name: 'Benin', prefix: '229', code: 'BJ' },
  'Saint Barthelemy': { name: 'Saint Barthelemy', prefix: '590', code: 'BL' },
  Bermuda: { name: 'Bermuda', prefix: '1', code: 'BM' },
  Brunei: { name: 'Brunei', prefix: '673', code: 'BN' },
  Bolivia: { name: 'Bolivia', prefix: '591', code: 'BO' },
  'Bonaire, Saint Eustatius and Saba ': {
    name: 'Bonaire, Saint Eustatius and Saba ',
    prefix: '599',
    code: 'BQ',
  },
  Brazil: { name: 'Brazil', prefix: '55', code: 'BR' },
  Bahamas: { name: 'Bahamas', prefix: '1', code: 'BS' },
  Bhutan: { name: 'Bhutan', prefix: '975', code: 'BT' },
  Botswana: { name: 'Botswana', prefix: '267', code: 'BW' },
  Belarus: { name: 'Belarus', prefix: '375', code: 'BY' },
  Belize: { name: 'Belize', prefix: '501', code: 'BZ' },
  Canada: { name: 'Canada', prefix: '1', code: 'CA' },
  'Cocos Islands': { name: 'Cocos Islands', prefix: '61', code: 'CC' },
  'Democratic Republic of the Congo': {
    name: 'Democratic Republic of the Congo',
    prefix: '243',
    code: 'CD',
  },
  'Central African Republic': {
    name: 'Central African Republic',
    prefix: '236',
    code: 'CF',
  },
  'Republic of the Congo': {
    name: 'Republic of the Congo',
    prefix: '242',
    code: 'CG',
  },
  Switzerland: { name: 'Switzerland', prefix: '41', code: 'CH' },
  'Ivory Coast': { name: 'Ivory Coast', prefix: '225', code: 'CI' },
  'Cook Islands': { name: 'Cook Islands', prefix: '682', code: 'CK' },
  Chile: { name: 'Chile', prefix: '56', code: 'CL' },
  Cameroon: { name: 'Cameroon', prefix: '237', code: 'CM' },
  China: { name: 'China', prefix: '86', code: 'CN' },
  Colombia: { name: 'Colombia', prefix: '57', code: 'CO' },
  'Costa Rica': { name: 'Costa Rica', prefix: '506', code: 'CR' },
  Cuba: { name: 'Cuba', prefix: '53', code: 'CU' },
  'Cape Verde': { name: 'Cape Verde', prefix: '238', code: 'CV' },
  Curacao: { name: 'Curacao', prefix: '599', code: 'CW' },
  'Christmas Island': { name: 'Christmas Island', prefix: '61', code: 'CX' },
  Cyprus: { name: 'Cyprus', prefix: '357', code: 'CY' },
  'Czech Republic': { name: 'Czech Republic', prefix: '420', code: 'CZ' },
  Germany: { name: 'Germany', prefix: '49', code: 'DE' },
  Djibouti: { name: 'Djibouti', prefix: '253', code: 'DJ' },
  Denmark: { name: 'Denmark', prefix: '45', code: 'DK' },
  Dominica: { name: 'Dominica', prefix: '1', code: 'DM' },
  'Dominican Republic': { name: 'Dominican Republic', prefix: '1', code: 'DO' },
  Algeria: { name: 'Algeria', prefix: '213', code: 'DZ' },
  Ecuador: { name: 'Ecuador', prefix: '593', code: 'EC' },
  Estonia: { name: 'Estonia', prefix: '372', code: 'EE' },
  Egypt: { name: 'Egypt', prefix: '20', code: 'EG' },
  'Western Sahara': { name: 'Western Sahara', prefix: '212', code: 'EH' },
  Eritrea: { name: 'Eritrea', prefix: '291', code: 'ER' },
  Spain: { name: 'Spain', prefix: '34', code: 'ES' },
  Ethiopia: { name: 'Ethiopia', prefix: '251', code: 'ET' },
  Finland: { name: 'Finland', prefix: '358', code: 'FI' },
  Fiji: { name: 'Fiji', prefix: '679', code: 'FJ' },
  'Falkland Islands': { name: 'Falkland Islands', prefix: '500', code: 'FK' },
  Micronesia: { name: 'Micronesia', prefix: '691', code: 'FM' },
  'Faroe Islands': { name: 'Faroe Islands', prefix: '298', code: 'FO' },
  France: { name: 'France', prefix: '33', code: 'FR' },
  Gabon: { name: 'Gabon', prefix: '241', code: 'GA' },
  'United Kingdom': { name: 'United Kingdom', prefix: '44', code: 'GB' },
  Grenada: { name: 'Grenada', prefix: '1', code: 'GD' },
  Georgia: { name: 'Georgia', prefix: '995', code: 'GE' },
  'French Guiana': { name: 'French Guiana', prefix: '594', code: 'GF' },
  Guernsey: { name: 'Guernsey', prefix: '44', code: 'GG' },
  Ghana: { name: 'Ghana', prefix: '233', code: 'GH' },
  Gibraltar: { name: 'Gibraltar', prefix: '350', code: 'GI' },
  Greenland: { name: 'Greenland', prefix: '299', code: 'GL' },
  Gambia: { name: 'Gambia', prefix: '220', code: 'GM' },
  Guinea: { name: 'Guinea', prefix: '224', code: 'GN' },
  Guadeloupe: { name: 'Guadeloupe', prefix: '590', code: 'GP' },
  'Equatorial Guinea': { name: 'Equatorial Guinea', prefix: '240', code: 'GQ' },
  Greece: { name: 'Greece', prefix: '30', code: 'GR' },
  Guatemala: { name: 'Guatemala', prefix: '502', code: 'GT' },
  Guam: { name: 'Guam', prefix: '1', code: 'GU' },
  'Guinea-Bissau': { name: 'Guinea-Bissau', prefix: '245', code: 'GW' },
  Guyana: { name: 'Guyana', prefix: '592', code: 'GY' },
  'Hong Kong': { name: 'Hong Kong', prefix: '852', code: 'HK' },
  Honduras: { name: 'Honduras', prefix: '504', code: 'HN' },
  Croatia: { name: 'Croatia', prefix: '385', code: 'HR' },
  Haiti: { name: 'Haiti', prefix: '509', code: 'HT' },
  Hungary: { name: 'Hungary', prefix: '36', code: 'HU' },
  Indonesia: { name: 'Indonesia', prefix: '62', code: 'ID' },
  Ireland: { name: 'Ireland', prefix: '353', code: 'IE' },
  Israel: { name: 'Israel', prefix: '972', code: 'IL' },
  'Isle of Man': { name: 'Isle of Man', prefix: '44', code: 'IM' },
  India: { name: 'India', prefix: '91', code: 'IN' },
  'British Indian Ocean Territory': {
    name: 'British Indian Ocean Territory',
    prefix: '246',
    code: 'IO',
  },
  Iraq: { name: 'Iraq', prefix: '964', code: 'IQ' },
  Iran: { name: 'Iran', prefix: '98', code: 'IR' },
  Iceland: { name: 'Iceland', prefix: '354', code: 'IS' },
  Italy: { name: 'Italy', prefix: '39', code: 'IT' },
  Jersey: { name: 'Jersey', prefix: '44', code: 'JE' },
  Jamaica: { name: 'Jamaica', prefix: '1', code: 'JM' },
  Jordan: { name: 'Jordan', prefix: '962', code: 'JO' },
  Japan: { name: 'Japan', prefix: '81', code: 'JP' },
  Kenya: { name: 'Kenya', prefix: '254', code: 'KE' },
  Kyrgyzstan: { name: 'Kyrgyzstan', prefix: '996', code: 'KG' },
  Cambodia: { name: 'Cambodia', prefix: '855', code: 'KH' },
  Kiribati: { name: 'Kiribati', prefix: '686', code: 'KI' },
  Comoros: { name: 'Comoros', prefix: '269', code: 'KM' },
  'Saint Kitts and Nevis': {
    name: 'Saint Kitts and Nevis',
    prefix: '1',
    code: 'KN',
  },
  'North Korea': { name: 'North Korea', prefix: '850', code: 'KP' },
  'South Korea': { name: 'South Korea', prefix: '82', code: 'KR' },
  Kuwait: { name: 'Kuwait', prefix: '965', code: 'KW' },
  'Cayman Islands': { name: 'Cayman Islands', prefix: '1', code: 'KY' },
  Kazakhstan: { name: 'Kazakhstan', prefix: '7', code: 'KZ' },
  Laos: { name: 'Laos', prefix: '856', code: 'LA' },
  Lebanon: { name: 'Lebanon', prefix: '961', code: 'LB' },
  'Saint Lucia': { name: 'Saint Lucia', prefix: '1', code: 'LC' },
  Liechtenstein: { name: 'Liechtenstein', prefix: '423', code: 'LI' },
  'Sri Lanka': { name: 'Sri Lanka', prefix: '94', code: 'LK' },
  Liberia: { name: 'Liberia', prefix: '231', code: 'LR' },
  Lesotho: { name: 'Lesotho', prefix: '266', code: 'LS' },
  Lithuania: { name: 'Lithuania', prefix: '370', code: 'LT' },
  Luxembourg: { name: 'Luxembourg', prefix: '352', code: 'LU' },
  Latvia: { name: 'Latvia', prefix: '371', code: 'LV' },
  Libya: { name: 'Libya', prefix: '218', code: 'LY' },
  Morocco: { name: 'Morocco', prefix: '212', code: 'MA' },
  Monaco: { name: 'Monaco', prefix: '377', code: 'MC' },
  Moldova: { name: 'Moldova', prefix: '373', code: 'MD' },
  Montenegro: { name: 'Montenegro', prefix: '382', code: 'ME' },
  'Saint Martin': { name: 'Saint Martin', prefix: '590', code: 'MF' },
  Madagascar: { name: 'Madagascar', prefix: '261', code: 'MG' },
  'Marshall Islands': { name: 'Marshall Islands', prefix: '692', code: 'MH' },
  Macedonia: { name: 'Macedonia', prefix: '389', code: 'MK' },
  Mali: { name: 'Mali', prefix: '223', code: 'ML' },
  Myanmar: { name: 'Myanmar', prefix: '95', code: 'MM' },
  Mongolia: { name: 'Mongolia', prefix: '976', code: 'MN' },
  Macao: { name: 'Macao', prefix: '853', code: 'MO' },
  'Northern Mariana Islands': {
    name: 'Northern Mariana Islands',
    prefix: '1',
    code: 'MP',
  },
  Martinique: { name: 'Martinique', prefix: '596', code: 'MQ' },
  Mauritania: { name: 'Mauritania', prefix: '222', code: 'MR' },
  Montserrat: { name: 'Montserrat', prefix: '1', code: 'MS' },
  Malta: { name: 'Malta', prefix: '356', code: 'MT' },
  Mauritius: { name: 'Mauritius', prefix: '230', code: 'MU' },
  Maldives: { name: 'Maldives', prefix: '960', code: 'MV' },
  Malawi: { name: 'Malawi', prefix: '265', code: 'MW' },
  Mexico: { name: 'Mexico', prefix: '52', code: 'MX' },
  Malaysia: { name: 'Malaysia', prefix: '60', code: 'MY' },
  Mozambique: { name: 'Mozambique', prefix: '258', code: 'MZ' },
  Namibia: { name: 'Namibia', prefix: '264', code: 'NA' },
  'New Caledonia': { name: 'New Caledonia', prefix: '687', code: 'NC' },
  Niger: { name: 'Niger', prefix: '227', code: 'NE' },
  'Norfolk Island': { name: 'Norfolk Island', prefix: '672', code: 'NF' },
  Nigeria: { name: 'Nigeria', prefix: '234', code: 'NG' },
  Nicaragua: { name: 'Nicaragua', prefix: '505', code: 'NI' },
  Netherlands: { name: 'Netherlands', prefix: '31', code: 'NL' },
  Norway: { name: 'Norway', prefix: '47', code: 'NO' },
  Nepal: { name: 'Nepal', prefix: '977', code: 'NP' },
  Nauru: { name: 'Nauru', prefix: '674', code: 'NR' },
  Niue: { name: 'Niue', prefix: '683', code: 'NU' },
  'New Zealand': { name: 'New Zealand', prefix: '64', code: 'NZ' },
  Oman: { name: 'Oman', prefix: '968', code: 'OM' },
  Panama: { name: 'Panama', prefix: '507', code: 'PA' },
  Peru: { name: 'Peru', prefix: '51', code: 'PE' },
  'French Polynesia': { name: 'French Polynesia', prefix: '689', code: 'PF' },
  'Papua New Guinea': { name: 'Papua New Guinea', prefix: '675', code: 'PG' },
  Philippines: { name: 'Philippines', prefix: '63', code: 'PH' },
  Pakistan: { name: 'Pakistan', prefix: '92', code: 'PK' },
  Poland: { name: 'Poland', prefix: '48', code: 'PL' },
  'Saint Pierre and Miquelon': {
    name: 'Saint Pierre and Miquelon',
    prefix: '508',
    code: 'PM',
  },
  'Puerto Rico': { name: 'Puerto Rico', prefix: '1', code: 'PR' },
  'Palestinian Territory': {
    name: 'Palestinian Territory',
    prefix: '970',
    code: 'PS',
  },
  Portugal: { name: 'Portugal', prefix: '351', code: 'PT' },
  Palau: { name: 'Palau', prefix: '680', code: 'PW' },
  Paraguay: { name: 'Paraguay', prefix: '595', code: 'PY' },
  Qatar: { name: 'Qatar', prefix: '974', code: 'QA' },
  Reunion: { name: 'Reunion', prefix: '262', code: 'RE' },
  Romania: { name: 'Romania', prefix: '40', code: 'RO' },
  Serbia: { name: 'Serbia', prefix: '381', code: 'RS' },
  Russia: { name: 'Russia', prefix: '7', code: 'RU' },
  Rwanda: { name: 'Rwanda', prefix: '250', code: 'RW' },
  'Saudi Arabia': { name: 'Saudi Arabia', prefix: '966', code: 'SA' },
  'Solomon Islands': { name: 'Solomon Islands', prefix: '677', code: 'SB' },
  Seychelles: { name: 'Seychelles', prefix: '248', code: 'SC' },
  Sudan: { name: 'Sudan', prefix: '249', code: 'SD' },
  Sweden: { name: 'Sweden', prefix: '46', code: 'SE' },
  Singapore: { name: 'Singapore', prefix: '65', code: 'SG' },
  'Saint Helena': { name: 'Saint Helena', prefix: '290', code: 'SH' },
  Slovenia: { name: 'Slovenia', prefix: '386', code: 'SI' },
  'Svalbard and Jan Mayen': {
    name: 'Svalbard and Jan Mayen',
    prefix: '47',
    code: 'SJ',
  },
  Slovakia: { name: 'Slovakia', prefix: '421', code: 'SK' },
  'Sierra Leone': { name: 'Sierra Leone', prefix: '232', code: 'SL' },
  'San Marino': { name: 'San Marino', prefix: '378', code: 'SM' },
  Senegal: { name: 'Senegal', prefix: '221', code: 'SN' },
  Somalia: { name: 'Somalia', prefix: '252', code: 'SO' },
  Suriname: { name: 'Suriname', prefix: '597', code: 'SR' },
  'South Sudan': { name: 'South Sudan', prefix: '211', code: 'SS' },
  'Sao Tome and Principe': {
    name: 'Sao Tome and Principe',
    prefix: '239',
    code: 'ST',
  },
  'El Salvador': { name: 'El Salvador', prefix: '503', code: 'SV' },
  'Sint Maarten': { name: 'Sint Maarten', prefix: '1', code: 'SX' },
  Syria: { name: 'Syria', prefix: '963', code: 'SY' },
  Swaziland: { name: 'Swaziland', prefix: '268', code: 'SZ' },
  'Turks and Caicos Islands': {
    name: 'Turks and Caicos Islands',
    prefix: '1',
    code: 'TC',
  },
  Chad: { name: 'Chad', prefix: '235', code: 'TD' },
  Togo: { name: 'Togo', prefix: '228', code: 'TG' },
  Thailand: { name: 'Thailand', prefix: '66', code: 'TH' },
  Tajikistan: { name: 'Tajikistan', prefix: '992', code: 'TJ' },
  Tokelau: { name: 'Tokelau', prefix: '690', code: 'TK' },
  'East Timor': { name: 'East Timor', prefix: '670', code: 'TL' },
  Turkmenistan: { name: 'Turkmenistan', prefix: '993', code: 'TM' },
  Tunisia: { name: 'Tunisia', prefix: '216', code: 'TN' },
  Tonga: { name: 'Tonga', prefix: '676', code: 'TO' },
  Turkey: { name: 'Turkey', prefix: '90', code: 'TR' },
  'Trinidad and Tobago': {
    name: 'Trinidad and Tobago',
    prefix: '1',
    code: 'TT',
  },
  Tuvalu: { name: 'Tuvalu', prefix: '688', code: 'TV' },
  Taiwan: { name: 'Taiwan', prefix: '886', code: 'TW' },
  Tanzania: { name: 'Tanzania', prefix: '255', code: 'TZ' },
  Ukraine: { name: 'Ukraine', prefix: '380', code: 'UA' },
  Uganda: { name: 'Uganda', prefix: '256', code: 'UG' },
  'United States': { name: 'United States', prefix: '1', code: 'US' },
  Uruguay: { name: 'Uruguay', prefix: '598', code: 'UY' },
  Uzbekistan: { name: 'Uzbekistan', prefix: '998', code: 'UZ' },
  Vatican: { name: 'Vatican', prefix: '39', code: 'VA' },
  'Saint Vincent and the Grenadines': {
    name: 'Saint Vincent and the Grenadines',
    prefix: '1',
    code: 'VC',
  },
  Venezuela: { name: 'Venezuela', prefix: '58', code: 'VE' },
  'British Virgin Islands': {
    name: 'British Virgin Islands',
    prefix: '1',
    code: 'VG',
  },
  'U.S. Virgin Islands': {
    name: 'U.S. Virgin Islands',
    prefix: '1',
    code: 'VI',
  },
  Vietnam: { name: 'Vietnam', prefix: '84', code: 'VN' },
  Vanuatu: { name: 'Vanuatu', prefix: '678', code: 'VU' },
  'Wallis and Futuna': { name: 'Wallis and Futuna', prefix: '681', code: 'WF' },
  Samoa: { name: 'Samoa', prefix: '685', code: 'WS' },
  Kosovo: { name: 'Kosovo', prefix: '383', code: 'XK' },
  Yemen: { name: 'Yemen', prefix: '967', code: 'YE' },
  Mayotte: { name: 'Mayotte', prefix: '262', code: 'YT' },
  'South Africa': { name: 'South Africa', prefix: '27', code: 'ZA' },
  Zambia: { name: 'Zambia', prefix: '260', code: 'ZM' },
  Zimbabwe: { name: 'Zimbabwe', prefix: '263', code: 'ZW' },
} as const;
