import styled from 'styled-components/macro';
import { css, keyframes } from 'styled-components';
import { zeroAnima } from '../../../../components/ViewersProgressBars/UPVcompleteProgressBar';

const blinking_touch = keyframes`
  0% {
    background-color: #FFFFFF;
    border: 0.6px solid  #ece2e2;
    box-shadow: 0 1px 4px rgba(122, 105, 105, 0.1);

  }
  50% {
    background-color: #FFE9AD;
    border: 0.6px solid  #ece2e2;
    box-shadow: 0 1px 4px rgba(122, 105, 105, 0.1);

  }
  100% {
    background-color: #FFFFFF;
    border: 0.6px solid  #ece2e2;
    box-shadow: 0 1px 4px rgba(122, 105, 105, 0.1);

  }
`;
export const ExpandedCardContentWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 3px 8px rgba(122, 105, 105, 0.15);
  width: 100%;
  height: 100%;
  max-height: 1200px;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  border-radius: 8px;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  ${({ isPage }) => (isPage ? `padding-left:15px;
  zoom: 0.84;
  ` : '')}
  ${({ withPaddings }) => (withPaddings
    ? `
  display: flex;
  padding-left: 28px;
  padding-top:22px;
  padding-bottom:22px;
  align-items:center;
  `
    : '')}

  ${({ isText }) => (isText
    ? `
      overflow-y: scroll;
      border-radius: 12px;
  `
    : '')}
`;
export const ExpandedCardTitleWrapper = styled.textarea`
  position: absolute;
  width: 374px;
  height: 40px;
  font-family: "RobotoFlex";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #343434;
`;

export const ExpandedCardControlsWrapper = styled.div`
  padding: 0 14px;
  display: flex;
  border-radius: 8px;

  align-items: center;
  background: white;
  height: 34px;
  margin-bottom: 8px; // TODO CHECK
  // ${({ isUpload }) => (isUpload ? 'width:125px;' : '')}
`;

export const ExpandedCardMainControlsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  visibility: hidden;
  opacity: 0;
`;

export const SvgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 5px;
  height: 32px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 0 1px 4px rgba(122, 105, 105, 0.1);
  border-radius: 6px;
  ${({ pL }) => (pL ? `padding-left:${pL}px;` : '')}
  ${({ pR }) => (pR ? `padding-right:${pR}px;` : '')}
  ${({ pT }) => (pT
    ? `
  padding-bottom:${pT}px;
  padding-top:${pT}px;`
    : '')}
  svg {
    path {
      stroke: #484343;
    }
  }
  ${({ isActive }) => (isActive ? '    background: #FFDF85;' : '')}
  ${({ isDisabled }) => (isDisabled
    ? `
opacity:0.5;
cursor:not-allowed;
  `
    : `
    &:hover {
    background: #FFDF85;
  }

  `)}
  ${({ width }) => (width ? `
  width:${width}px;
  border:none;
  shadow:none;
  ` : '')}
`;
export const NoPreviewAvailable = styled.div`
  font-family: "RobotoFlex";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #484343;
  margin-left: 140px;
`;

export const ExpandedCardMainWrapper = styled.div`
  position: relative;
  border: 0.6px solid rgba(122, 105, 105, 0);
  box-shadow: 0 1px 4px rgba(122, 105, 105, 0);
  border-radius: 12px;
  padding-bottom: 30px;
  max-width: 935px;
  &:hover {
    ${ExpandedCardMainControlsWrapper} {
      visibility: visible;
      opacity: 1;

    }
    //background-color: #ffffff;
    //border: 0.6px solid  #ece2e2;
    //box-shadow: 0 1px 4px rgba(122, 105, 105, 0.1);

  }
  ${({ withCaption }) => (withCaption ? '    margin-bottom: 14px;' : '')}
  animation: ${(props) => (props.blinkIt
    ? css`
          ${blinking_touch} 1s;
          animation-iteration-count: 2;
        `
    : css`
          ${zeroAnima};
        `)}
`;
