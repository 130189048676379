import * as React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const SpinnerWrap = styled.div`
  width: 200px;
  margin: auto;
  height: 100%;
`;

const Loader = () => {
  const { t } = useTranslation();
  return (
    <SpinnerWrap>
      <div className="loader_mini centered">{t('loadingT')}</div>
    </SpinnerWrap>
  );
};

export default Loader;
