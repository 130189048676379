import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowUpSvg } from '../../images/icons/arrow_up.svg';
import { ReactComponent as ChevronUpSvg } from '../../images/icons/chevron_up_12.svg';
import { ReactComponent as ChevronDownSvg } from '../../images/icons/chevron_down_12.svg';
import tableStyles from '../../components/LibraryMiniPage/index.module.css';
import { ReactComponent as ArrowDownSvg } from '../../images/icons/arrow-down_16.svg';
import { sortedFunctions } from '../dateConvert';
import { ReactComponent as OnHoldUser16Svg } from '../../images/icons/onhold_user_16.svg';
import { ReactComponent as BlockUser24Svg } from '../../images/icons/block_user_24.svg';
import { ReactComponent as ActivateUser16Svg } from '../../images/icons/activate_user_16.svg';
import { ReactComponent as ActivatePlay16Svg } from '../../images/icons/play_16.svg';
import { ReactComponent as Trash16Svg } from '../../images/icons/trash_20.svg';

const Arrow = styled.div`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  margin-left: 8px;

  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: #e8ebfa;
  }

  ${({ absolute }) => (absolute
    ? `
    position: absolute;
    left: 100%;
  `
    : '')}
`;

const PlainArrow = styled.div`
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

export const showArrowType = (type, sortType) => {
  if (Object.keys(sortedFunctions)[type] === sortType) {
    return (
      <Arrow>
        <ArrowUpSvg className={tableStyles.tableHeadArrowActive} />
      </Arrow>
    );
  }
  if (Object.keys(sortedFunctions)[type + 1] === sortType) {
    return (
      <Arrow>
        <ArrowDownSvg className={tableStyles.tableHeadArrowActive} />
      </Arrow>
    );
  }
  return (
    <Arrow isHidden>
      <ArrowDownSvg className={tableStyles.tableHeadArrow} />
    </Arrow>
  );
};

export const plainArrowType = (type, sortType) => {
  if (Object.keys(sortedFunctions)[type] === sortType) {
    return (
      <PlainArrow>
        <ArrowDownSvg className={tableStyles.tableHeadArrowActive} />
      </PlainArrow>
    );
  }
  if (Object.keys(sortedFunctions)[type + 1] === sortType) {
    return (
      <PlainArrow>
        <ArrowUpSvg className={tableStyles.tableHeadArrowActive} />
      </PlainArrow>
    );
  }
  return null;
};

export const chevronArrowType = (type, sortType, isAbsolute) => {
  if (Object.keys(sortedFunctions)[type] === sortType) {
    return (
      <Arrow absolute={isAbsolute}>
        <ChevronUpSvg className={tableStyles.tableHeadArrowActive} />
      </Arrow>
    );
  }
  if (Object.keys(sortedFunctions)[type + 1] === sortType) {
    return (
      <Arrow absolute={isAbsolute}>
        <ChevronDownSvg className={tableStyles.tableHeadArrowActive} />
      </Arrow>
    );
  }
  return (
    <Arrow isHidden absolute={isAbsolute}>
      <ChevronDownSvg className={tableStyles.tableHeadArrow} />
    </Arrow>
  );
};

export const ChannelsUserStatusOptions = {
  Active: [
    {
      status: 'On Hold',
      svg: <OnHoldUser16Svg />,
      msg: 'Put On Hold',
      color: '#596080',
    },
    // { status: 'Blocked', svg: <BlockUser24Svg />, msg: 'Block', color: '#C21C44' },
  ],
  'On Hold': [
    {
      status: 'Active',
      svg: <ActivateUser16Svg />,
      msg: 'Activate',
      color: '#596080',
    },
    // { status: 'Blocked', svg: <BlockUser24Svg />, msg: 'Block', color: '#596080' },
    { status: 'Deleted', svg: <Trash16Svg />, msg: 'Delete', color: '#C21C44' },
  ],
  // Blocked: [
  //   { status: 'Active', svg: <ActivateUser16Svg />, msg: 'Activate', color: '#596080' },
  //   { status: 'On Hold', svg: <OnHoldUser16Svg />, msg: 'Put On Hold', color: '#596080' },
  //   { status: 'Deleted', svg: <Trash16Svg />, msg: 'Delete', color: '#C21C44' },
  // ],
  Deleted: [
    {
      status: 'Active',
      svg: <ActivateUser16Svg />,
      msg: 'Activate',
      color: '#596080',
    },
    {
      status: 'On Hold',
      svg: <OnHoldUser16Svg />,
      msg: 'Put On Hold',
      color: '#596080',
    },
    // { status: 'Blocked', svg: <BlockUser24Svg />, msg: 'Block', color: '#596080' },
  ],
  Invited: [
    {
      status: 'Cancel',
      svg: <BlockUser24Svg />,
      msg: 'Cancel Invitation',
      color: '#C21C44',
    },
  ],
  Invite: [
    {
      status: 'Cancel',
      svg: <BlockUser24Svg />,
      msg: 'Cancel Invitation',
      color: '#C21C44',
    },
  ],
};

export const ContactsStatusOptions = {
  Blocked: [
    {
      status: 'Active',
      svg: <ActivateUser16Svg />,
      msg: 'Activate',
      color: '#117E0F',
    },
    {
      status: 'On Hold',
      svg: <OnHoldUser16Svg />,
      msg: 'Put On Hold',
      color: '#596080',
    },
    {
      status: 'Remove',
      svg: <Trash16Svg />,
      msg: 'Remove Contact',
      color: '#C21C44',
    },
  ],
  Active: [
    {
      status: 'On Hold',
      svg: <OnHoldUser16Svg />,
      msg: 'Put On Hold',
      color: '#596080',
    },
    // { status: 'Blocked', svg: <BlockUser24Svg />, msg: 'Block', color: '#596080' },
  ],
  'On Hold': [
    {
      status: 'Active',
      svg: <ActivatePlay16Svg />,
      msg: 'Activate',
      color: '#117E0F',
    },
    // { status: 'Blocked', svg: <BlockUser24Svg />, msg: 'Block', color: '#596080' },
    {
      status: 'Remove',
      svg: <Trash16Svg />,
      msg: 'Remove Contact',
      color: '#C21C44',
    },
  ],
  Created: [
    {
      status: 'Active',
      svg: <ActivatePlay16Svg />,
      msg: 'Activate',
      color: '#117E0F',
    },
    {
      status: 'On Hold',
      svg: <OnHoldUser16Svg />,
      msg: 'Put On Hold',
      color: '#596080',
    },
    {
      status: 'Remove',
      svg: <Trash16Svg />,
      msg: 'Remove Contact',
      color: '#C21C44',
    },
  ],
};

export const calculateVerticalPosition = (ref, menuHeight, elementHeight) => {
  if (ref && ref.current) {
    const height = document.documentElement.clientHeight;
    const positionY = elementHeight;
    const refAbsoluteY = ref.current.getBoundingClientRect().y;
    const heightElement = menuHeight;
    const isOpenInDown = height > refAbsoluteY + heightElement + positionY;
    if (isOpenInDown) return positionY;
    return positionY - heightElement;
  }
  return elementHeight;
};

export const calculateHorizontalPosition = (ref, menuWidth, elementWidth) => {
  if (ref && ref.current) {
    const width = document.documentElement.clientWidth;
    const positionX = elementWidth;
    const refAbsoluteX = ref.current.getBoundingClientRect().x;
    const widthElement = menuWidth;
    const isOpenInDown = width > refAbsoluteX + widthElement + positionX;
    if (isOpenInDown) return positionX;
    return positionX - widthElement;
  }
  return elementWidth;
};
