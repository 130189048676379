import React from 'react';
import styled from 'styled-components/macro';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TABLE_DECORS } from '../../../utils/tableHelpers/tableDecorators';
import { ReactComponent as MoreSvg } from '../../../images/icons/more_20.svg';
import { ReactComponent as InContactsSvg } from '../../../images/icons/icon_20/in_contacts.svg';
import MemberOptionsList from './MemberRowOptions';
import ContactAvatar from './CalculatedContactAvatar';
import ContactPopup from './ContactPopup';

import rawStyles from './MemberContent.module.scss';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import { DATA_PARENTS } from '../../../utils/constants';
import RolesCell from './RolesCell/RolesCell';

const InContactMarker = styled.div`
  position: absolute;
  z-index: 2;
  top: 12px;
  left: 14px;
`;


const cn = classNames.bind(rawStyles);

const MemberRowUi = ({ item, rowClickHandle, ...props }) => {
  const [itemRef, isDropListVisible, setIsDropVisible] = useComponentVisible(
    false,
    DATA_PARENTS.ChannelCellDropList + item.id,
  );
  const isRowActive = isDropListVisible || !!props.isOptionsOpened;
  const { id: folderId } = useParams();
  const channels = useSelector((state) => state.channels.myChannels);
  const myChannels = channels[folderId] || {};
  const permissions = myChannels.permissions || {};

  const inContacts = !!item.inContact;

  const name = item.inContact?.first_name
    ? `${item.inContact?.first_name} ${item.inContact?.last_name}` : item.name || item.email;

  const invitedBy = item.invitedBy === 'You'
    ? item.invitedBy
    : item.userWhoInvitedInChannel?.first_name
      ? `${item.userWhoInvitedInChannel?.first_name} ${item.userWhoInvitedInChannel?.last_name}`
      : item.userWhoInvitedInChannel?.name || item.userWhoInvitedInChannel?.email || '-';

  const showEditMenu = (e) => {
    if (permissions.canManageMembers) props.setIsOptionsOpened(true);
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div
      onContextMenu={showEditMenu}
      className={cn('table_row', { selected: isRowActive })}
      draggable="false"
    >
      <div className={cn('row_justification')}>
        <div
          className={cn('cell', 'name', 'first', 'in_body', { selected: isRowActive })}
          onClick={rowClickHandle}
        >
          <div className={cn('contact_hovered_place', { is_in_contacts: inContacts })}>
            <div className={cn('contact_popup-wrapper')}>
              <ContactPopup item={item} />
            </div>
            <div className={cn('avatar_container')}>
              <ContactAvatar item={item} />
              {inContacts && (
              <InContactMarker>
                <InContactsSvg />
              </InContactMarker>
              )}
            </div>

            <div className={cn('name_text', 'name_field', { in_contacts: inContacts })}>
              {name}
            </div>
          </div>
        </div>
        <div
          className={cn('cell', 'function', 'in_body', { selected: isRowActive })}
          onClick={rowClickHandle}
        >
          <div className={cn('name_text')}>{item.inContact?.function || '-'}</div>
        </div>
        <div
          className={cn('cell', 'organization', 'in_body', { selected: isRowActive })}
          onClick={rowClickHandle}
        >
          <div className={cn('name_text')}>{item.inContact?.organization || '-'}</div>
        </div>
        <div
          className={cn('cell', 'invited', 'in_body', { selected: isRowActive })}
          onClick={rowClickHandle}
        >
          <div className={cn('name_text')}>{invitedBy || '-'}</div>
        </div>
        <div
          className={cn('cell', 'role', 'in_body', { selected: isRowActive })}
          onClick={rowClickHandle}
        >
          <RolesCell
            itemRef={itemRef}
            isDropListVisible={isDropListVisible}
            setIsDropVisible={setIsDropVisible}
            item={item}
          />
        </div>

        <div
          className={cn('cell', 'status', 'in_body', { selected: isRowActive })}
          onClick={rowClickHandle}
        >
          <TABLE_DECORS.UserRoleStatus message={item.status} />
        </div>

        <div
          className={cn('cell', 'more', 'last', 'in_body', {
            selected: isRowActive,
            disabled: !permissions.canManageMembers,
          })}
          onClick={showEditMenu}
          ref={props.cellRef}
        >
          <div
            className={cn('more_cell', {
              selected: !!props.isOptionsOpened,
              disabled: !permissions.canManageMembers,
            })}
          >
            <MoreSvg className={cn('more_svg')} />
            {props.isOptionsOpened && (
              <MemberOptionsList
                changeMemberStatus={props.changeMemberStatus}
                parentRef={props.cellRef}
                optionsRef={props.optionsRef}
                item={item}
                isDisabled={props.isDisabled}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberRowUi;
