import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import Logo from '../../../components/MainHeader/Logo';
import { TitleInput } from '../../../components/ManyPagesHeaderMenu';
import {
  UiComponentTypes,
  LibraryComponentTypes,
} from '../../../utils/constants';
import { ReactComponent as Download16Svg } from '../../../images/icons/download_16.svg';
import { ReactComponent as FavoritesSvg } from '../../../images/icons/favorites16.svg';
import { ReactComponent as AddToStudio20Svg } from '../../../images/icons/add_to_studio_20.svg';
import Tooltip from '../../../shared/Tooltips/Tooltip';
import { ReactComponent as MoreSvg } from '../../../images/icons/more_20.svg';
import PlaylistOptionWithX from '../../../components/DropDownOption/MenuOptions/PlaylistOptionWithX';
import MENU_OPTIONS from '../../../components/DropDownOption/MenuOptions/PlaylistMenuOptions';
import { ReactComponent as CloseSvg } from '../../../images/icons/cross_20.svg';
import UnifiedChevronNavigation from '../../../components/UnifiedNavigation/UnifiedChevronNavigation';
import rawStylesHeader from '../../Playlist/Header/HeaderPlaylist.module.scss';
import rawStyles from '../index.module.scss';
import { iconTypeSelector } from './IconTypeSelector';
import { ReactComponent as SpinnerSvg } from '../../../images/icons/spinner.svg';
import { spinTheSpinner } from '../../Channels/MemberRow/RolesCell/Spinner';

const PlaylistStatusSpinner = styled.div`
  margin-top: 4px;
  & svg {
    width: 20px;
    height: 20px;
    animation: linear ${spinTheSpinner} 1.3s infinite;
  }
`;

const cn = classNames.bind(rawStylesHeader);
const styles = classNames.bind(rawStyles);

const Header = ({
  downloadFile,
  toggleFavorite,
  goBack,
  renameItem,
  duplicateItem,
  deleteItem,
  goToItem,
  isOwner,
  saveInStudio,
  isAnyCanEdit,
  ...props
}) => {
  const { t } = useTranslation();
  const [listRef, isListActive, setIsListActive] = useComponentVisible(
    false,
    'pdfList',
  );
  const [isComponentRename, setIsComponentRename] = useState(false);
  const isProcessing = useSelector((state) => state.support.requestSpinner);
  const handleMoreClick = () => {
    setIsListActive(!isListActive);
  };

  const openRename = () => {
    setIsListActive(false);
    setIsComponentRename(true);
  };

  const handleDuplicateClick = (e) => {
    e.stopPropagation();
    setIsListActive(false);
    duplicateItem();
  };

  const onDeleteItem = (e) => {
    e.stopPropagation();
    setIsListActive(false);
    deleteItem();
  };

  return (
    <div className={styles('header_wrapper', { pushTop: true })}>
      <div
        className={cn('container', {
          zIndex_top: false,
          forEditPage: true,
          forFiles: true,
        })}
      >
        <div className={cn('left_navigation', { pushTop: true })}>
          <Logo />
          <UnifiedChevronNavigation />
        </div>
      </div>
      {UiComponentTypes.link[props.item.type]
      && props.item.type !== LibraryComponentTypes.embed_component ? (
          ''
        ) : (
          <div className={styles('middle_wrapper')}>
            <div className={styles('side_sections', 'title')}>
              <div className={styles('icon_container')}>
                {iconTypeSelector(props.item.type)}
              </div>
              <div className={styles('text', { inputActive: isComponentRename })}>
                <TitleInput
                  itemType={props.item.type}
                  title={props.item.title}
                  renameCB={renameItem}
                  show={isComponentRename}
                  setShow={setIsComponentRename}
                  isDisabled={!isOwner}
                />
              </div>
            </div>
            <div className={styles('middle_section')}>
              {!(
                UiComponentTypes.link[props.item.type]
              || UiComponentTypes.component[props.item.type]
              ) && (
              <div
                className={styles('item', 'download', {
                  disabled:
                    !isOwner || (isProcessing && isProcessing !== 'init'),
                })}
                onClick={downloadFile}
              >
                {isProcessing == 'init' || !isProcessing ? (
                  <Download16Svg />
                ) : (
                  <PlaylistStatusSpinner>
                    <SpinnerSvg />
                  </PlaylistStatusSpinner>
                )}
                <div className={styles('text')}>{t('downloadUpT')}</div>
              </div>
              )}
              <div
                className={styles('item', {
                  active: props?.item?.isFavorite,
                  favorite: isOwner,
                  disabled: !isOwner,
                })}
                onClick={isOwner ? toggleFavorite : saveInStudio}
              >
                {isOwner ? <FavoritesSvg /> : <AddToStudio20Svg />}

                <div className={styles('text')}>
                  {isOwner ? t('addToFavoriteUpT') : t('saveInLibraryT')}
                </div>
              </div>
              <div
                data-cy="media-tooltip"
                data-parent="pdfList"
                ref={listRef}
                className={styles('item', 'more', 'tooltip', {
                  active: isListActive,
                  disabled: !isOwner,
                })}
                onClick={handleMoreClick}
              >
                <Tooltip text={t('optionsT')} direction="up">
                  <MoreSvg />
                </Tooltip>
                {isListActive && (
                  <>
                    <PlaylistOptionWithX
                      isPdfView
                      refMenu={props.refMenu}
                      menuOptions={[
                        MENU_OPTIONS.rename(openRename),
                        MENU_OPTIONS.duplicate(handleDuplicateClick),
                        MENU_OPTIONS.delete(onDeleteItem),
                      ]}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        )}

      <div className={styles('side_sections', 'right', { isAbsolute: true })}>
        <div className={styles('close')} onClick={goBack}>
          <CloseSvg />
        </div>
      </div>
    </div>
  );
};

export default Header;
