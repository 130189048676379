export const generateHistoryFreezePath = (path = '') => {
  // const pathArr = path.split('/');

  if (path.includes('/universal/')) {
    return '/content/smartfiles/drafts';
  }
  // TODO On Refresh page will affect usual behavior. can be solved?
  // if (path.includes('/player/')) {
  //   const id = pathArr[3];
  //   const step = pathArr[4];
  //   if (parseInt(step) > 1) {
  //     pathArr[4] = parseInt(step) - 1;
  //     return pathArr.join('/');
  //   }
  //   return `/universal/${id}/edit`;
  // }
  //
  // if (path.includes('playlist')) {
  //   const id = pathArr[2];
  //   return `/universal/${id}/edit`;
  // }

  if (
    path.includes('libraryPage')
    || path.includes('edit_page')
    || path.includes('shared_page')
  ) {
    return '/content/pages/all';
  }
  if (path.includes('libraryComponent')) {
    return '/content/library/all';
  }

  return '/content/smartfiles/drafts';
};

export const ifGotFreeze = (trace) => {
  return trace[trace.length - 1] === trace[trace.length - 2];
};
