import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import rawStyles from './unifiedDropDown.module.scss';
import { ReactComponent as DuplicateSvg } from '../../images/icons/duplicate.svg';
import { ReactComponent as UnpublishSvg } from '../../images/icons/unpublish.svg';
import { ReactComponent as DeleteRedSvg } from '../../images/icons/delete_red.svg';
import { ReactComponent as FavoriteSvg } from '../../images/icons/favorites16.svg';
import styles from './index.module.css';
import { ReactComponent as TurnIntoSvg } from '../../images/icons/turninto.svg';
import { ReactComponent as PlaylistSvg } from '../../images/icons/playlist_add_16.svg';
import { ReactComponent as CreateApproveSvg } from '../../images/icons/createApproveButton.svg';
import { ReactComponent as AddCaptionSvg } from '../../images/icons/editing/assCaption_16.svg';
import { ReactComponent as RenameSvg } from '../../images/icons/editing/rename_16.svg';
import { ReactComponent as EditSvg } from '../../images/icons/settings_20.svg';
import chevronRightSmall from '../../images/icons/chevron_right_small.svg';
import { ReactComponent as EditTextSvg } from '../../images/icons/edit_text.svg';

import TurnIntoBlock from './TurnIntoBlock';
import i18n from '../../i18n';

const cx = classNames.bind(rawStyles);

const Text = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: 8px;
  }

  ${({ isColoredText, isDelete }) => (isColoredText && isDelete ? 'color: #C21C44;' : '')}

  ${({ isDelete }) => (!isDelete
    ? `
    & svg path {
      stroke: #596080;
    }
  `
    : '')}
  
  ${({ isFavorite }) => (isFavorite
    ? `
    & svg {
      fill: #FF8A00;
    }
    
    & svg path {
      stroke: #FF8A00;
    }
  `
    : '')}
`;

const Option = styled.div`
  height: 32px;
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  ${({ isDisabled }) => (isDisabled
    ? `
    pointer-events: none;
    color: grey;
    & svg path {
      stroke: grey;
    }
  `
    : '')}
  &:hover {
    background: ${({ isDelete }) => (isDelete ? '#FFE1E8' : '#E8EBFA')};

    ${({ isDelete, isFavorite }) => (!isDelete && !isFavorite
    ? `
      & ${Text} svg path {
        stroke: #242633;
      }
    `
    : '')}
  }
`;

const SeparateLine = styled.div`
  background: #e8ebfa;
  height: 1px;
  width: 100%;
  margin: 8px 0;
`;

const ShortCut = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #a3aacc;
`;

export const options = {
  duplicate: (cb) => ({
    component: ({ action }) => (
      <Option
        data-cy="Duplicate"
        key="UnifiedDropDownListDuplicate"
        onMouseDown={(e) => {
          cb(e);
          action && action(e);
        }}
      >
        <Text>
          <DuplicateSvg />
          {i18n.t('duplicateT')}
        </Text>
        <ShortCut>⌘ D</ShortCut>
      </Option>
    ),
    cb,
  }),
  delete: (cb) => ({
    component: () => (
      <div key="UnifiedDropDownListDelete">
        <SeparateLine />
        <Option data-cy="Delete" onMouseDown={cb} isDelete>
          <Text isDelete>
            <DeleteRedSvg />
            {i18n.t('deleteT')}
          </Text>
        </Option>
      </div>
    ),
    cb,
  }),
  delete_no_separator: (cb, isColoredText) => ({
    component: () => (
      <div key="UnifiedDropDownListDelete">
        <Option data-cy="Delete" onMouseDown={cb} isDelete>
          <Text isDelete isColoredText={isColoredText}>
            <DeleteRedSvg />
            {i18n.t('deleteT')}
          </Text>
        </Option>
      </div>
    ),
    cb,
  }),
  rename: (cb) => ({
    component: () => (
      <div key="UnifiedRename">
        <Option data-cy="Rename" onMouseDown={cb}>
          <Text>
            <EditTextSvg />
            {i18n.t('renameT')}
          </Text>
        </Option>
      </div>
    ),
    cb,
  }),
  unPublish: (cb) => ({
    component: ({ action }) => (
      <Option
        data-cy="Unpublish"
        key="UnifiedDropDownListUnPublish"
        onMouseDown={(e) => {
          cb(e);
          action && action(e);
        }}
      >
        <Text>
          <UnpublishSvg />
          {i18n.t('unshareT')}
        </Text>
      </Option>
    ),
    cb,
  }),
  TurnInto: ({ cb, hidden }) => ({
    component: ({ action }) => {
      if (hidden) return <></>;
      return (
        <div
          key="UnifiedDropDownListTurnInto"
          className={`${styles.editMenu__item}  
        ${styles.editMenu__turn_info}
        `}
        >
          <TurnIntoSvg />
          <div className={`${styles.editMenu__text}`}>{i18n.t('turnIntoT')}</div>
          <img src={chevronRightSmall} alt="" className={`${styles.icon}`} />
          <div className={styles.editMenu__turn_block}>
            <TurnIntoBlock
              isPlaylist
              setIsOpenMenu={action}
              selectAddEditableBlock={(type) => {
                action();
                cb(type);
              }}
              parentRef={() => {}}
              clear={() => {}}
            />
          </div>
        </div>
      );
    },
    cb,
  }),
  removeCaption: ({ cb, hidden }) => ({
    component: ({ action }) => {
      if (hidden) return <></>;
      return (
        <div key="UnifiedDropDownListRemoveCaption">
          <Option
            data-cy="Remove Caption"
            onMouseDown={(e) => {
              cb(e);
              action && action(e);
            }}
          >
            <Text>
              <AddCaptionSvg />
              {i18n.t('removeCaptionT')}
            </Text>
          </Option>
        </div>
      );
    },
    cb,
  }),
  deleteApproveButton: ({ cb, hidden }) => ({
    component: ({ action }) => {
      if (hidden) return <></>;
      return (
        <div key="UnifiedDropDownListCreateApproveButton">
          <Option
            data-cy="Delete Approve Button"
            onMouseDown={(e) => {
              cb(e);
              action && action(e);
            }}
          >
            <Text>
              <DeleteRedSvg />
              {i18n.t('deleteApproveButtonT')}
            </Text>
          </Option>
        </div>
      );
    },
    cb,
  }),
};
const UnifiedDropDownMenu = ({
  state,
  duplicateCb,
  deleteCb,
  parentRef,
  clickHandler,
  itemId,
  refMenu,
  isHeader,
  ...props
}) => {
  const calculateVerticalPosition = () => {
    if (props.place === 'sixDots') return -16;
    if (props.isPdfView) return 33;
    if (parentRef && parentRef.current) {
      const height = document.documentElement.clientHeight;
      const positionY = 25;
      const refAbsoluteY = parentRef.current.getBoundingClientRect().y;
      const heightElement = 257;
      const isOpenInDown = height > refAbsoluteY + heightElement + positionY;
      if (isOpenInDown) return positionY;
      return positionY - heightElement;
    }
    return 32;
  };

  const calculateHorizontalPosition = () => {
    if (props.place === 'sixDots') return -255;
    if (props.place === 'DynamicCollectionHeader') return -206;
    if (props.place === 'SmartView') return 0;
    if (props.isPdfView) return -206;
    if (parentRef && parentRef.current) {
      const width = document.documentElement.clientWidth;
      const positionX = 1;
      const refAbsoluteX = parentRef.current.getBoundingClientRect().x;
      const widthElement = 230;
      const isOpenInRight = width > refAbsoluteX + widthElement + positionX;
      if (isOpenInRight) return positionX;
      return positionX - widthElement;
    }
    if (isHeader) return -208;
    return 0;
  };

  const divStyle = {
    top: calculateVerticalPosition(),
    left: calculateHorizontalPosition(),
  };

  return (
    <div
      data-cy="unified-DropDownMenu"
      style={divStyle}
      className={cx('drop_wrapper')}
    >
      {Object.keys(state).map((key) => {
        if (!state[key]) return <></>;
        const Component = state[key].component;
        return <Component key={`${key}`} action={clickHandler} />;
      })}
    </div>
  );
};

export default UnifiedDropDownMenu;
