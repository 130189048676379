import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ChevronLeft20Svg } from '../../images/icons/chevron_left_7_14.svg';
import { cantGoForward } from './helpers';
import {
  generateHistoryFreezePath,
  ifGotFreeze,
} from './helpers/historyFreezePath';
import { Arrow, ChevronButton, Main } from './styled';


const UnifiedChevronNavigation = ({
  customGoBack,
  // middlewareGoBack,
  isForSharedToWeb,
  isPlayer,
}) => {
  const history = useHistory();
  const historyTrace = useSelector((state) => state.historyTrace);

  const trace = historyTrace.trace;
  const index = historyTrace.activeIndex;
  const goBack = (customPath) => {
    const path = customPath || trace[index - 1];

    // case page was refreshed
    if (trace.length === 1 || !path) {
      const exPath = trace[index];
      history.push(generateHistoryFreezePath(exPath));
      return;
    }
    if (ifGotFreeze(trace)) {
      const exPath = trace[index - 3] || generateHistoryFreezePath(trace[index - 2]);
      history.push(exPath, { mode: 'synthetic-', exPath });
      return;
    }
    history.push(path, { mode: 'synthetic-', path });
  };

  const goBackHandler = () => {
    if (customGoBack) {
      customGoBack();
      return;
    }
    if (isForSharedToWeb && isPlayer) {
      const id = historyTrace.somePayload?.sharedToWebID;
      if (id) {
        history.push(`/shared_playlist/${id}`);
        return;
      }
      history.push('/login');
      return;
    }
    if (isForSharedToWeb && !isPlayer) {
      history.push('/login');
      return;
    }

    // if (middlewareGoBack) {
    //   middlewareGoBack(goBack);
    //   return;
    // }
    goBack();
  };

  const goForward = () => {
    const path = trace[index + 1];
    history.push(path, { mode: 'synthetic+', path });
  };
  return (
    <Main>
      <ChevronButton data-cy="back" onClick={goBackHandler}>
        <Arrow>
          <ChevronLeft20Svg />
        </Arrow>
      </ChevronButton>
      <ChevronButton
        isRight
        isDisabled={cantGoForward(historyTrace)}
        onClick={goForward}
      >
        {!isForSharedToWeb && (
          <Arrow isRight>
            <ChevronLeft20Svg />
          </Arrow>
        )}
      </ChevronButton>
    </Main>
  );
};
export default UnifiedChevronNavigation;
