import React from 'react';
import styled from 'styled-components';
import styles from '../../PlaylistContentSelector/index.module.css';
import NewPlaylist2023 from '../../MiniPlaylist/NewPlaylist2023/NewPlaylist2023';
import { FluentTableView } from '../../MiniPlaylist/NewPlaylist2023/table/FluentTableView';
import { PlaylistGap } from '../../MiniPlaylist/NewPlaylist2023/styled';

const PlayListCard = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 29.5px;
`;

const PlaylistTable = styled.div``;
const TitleStyle = styled.div`
  width: max-content;
  height: 26px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  margin-bottom: 30px;
  margin-left: 8px;
  margin-top: 15px;
  color: #484343;
  font-size: 20px;
  font-weight: 500;
  line-height: 19px;
`;
const RecentFilterComponentInChannel = ({
  playlists,
  filterTitle,
  refSelectedBlocks,
  isSimplifiedMultiselect,
  setSimplifiedMultiselect,
  counterSelectedPage,
  needToDelete,
  today,
  channelId,
  isChannelMiniPlaylist,
  permissions,
  ...props
}) => {
  const isCardView = props?.props?.props?.isCardView;
  const PlayListRow = isCardView ? PlayListCard : PlaylistTable;
  if (playlists.length) {
    return (
      <>
        <TitleStyle>{filterTitle.toUpperCase()}</TitleStyle>
        <PlayListRow>
          {!isCardView && (
            <FluentTableView
              isRecent
              isSimplifiedMultiselect={isSimplifiedMultiselect}
              setSimplifiedMultiselect={setSimplifiedMultiselect}
              isDescriptionOpen={props.isDescriptionOpen}
              data={playlists}
            />
          )}

          {isCardView && playlists.map((page) => (
            <PlaylistGap
              className={`${!isCardView ? styles.fullParentWidth : ''}`}
              key={page.id}
              ref={(ref) => refSelectedBlocks.current.push({
                ref,
                pageId: page.id,
                folderId: page.folderId,
              })
                }
            >
              <NewPlaylist2023
                item={page}
                isSimplifiedMultiselect={isSimplifiedMultiselect}
                setSimplifiedMultiselect={setSimplifiedMultiselect}
                isDescriptionOpen={props.isDescriptionOpen}
              />
            </PlaylistGap>
          ))}
        </PlayListRow>
      </>
    );
  }
  return <></>;
};

export default RecentFilterComponentInChannel;
