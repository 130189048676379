import { BASEURL } from '../environments/environments';

export const setServiceWorkerData = (token) => {
  if (!token) return;
  setTimeout(() => {
    if ('serviceWorker' in navigator) {
      const message = { type: 'data', token };
      navigator.serviceWorker.controller?.postMessage(message); // Send the message to the Service Worker
    }
  }, 100);
};

export const setServiceWorkerLocation = (location) => {
  if (!location) return;
  if ('serviceWorker' in navigator) {
    setTimeout(() => {
      const message = { type: 'setLocation', location };
      navigator.serviceWorker.controller?.postMessage(message); // Send the message to the Service Worker
    }, 100);
  }
};
export const setServiceWorkerBackendUrl = () => {
  setTimeout(() => {
    if ('serviceWorker' in navigator) {
      const message = { type: 'setBackendUrl', BASEURL };
      navigator.serviceWorker.controller?.postMessage(message); // Send the message to the Service Worker
    }
  }, 100);
};
export const setServiceWorkerSharedJwt = (sharedJwt) => {
  if ('serviceWorker' in navigator) {
    const message = { type: 'setSharedJwt', sharedJwt };
    navigator.serviceWorker.controller?.postMessage(message); // Send the message to the Service Worker
  }
};

export const handlerHardReload = () => {
  if (!navigator.serviceWorker.controller) {
    const url = `${window.location.protocol}//${window.location.host}/service-worker.js`;
    navigator.serviceWorker.getRegistration(url).then(function (sw) {
      if (sw) {
        sw.unregister().then(() => {
          navigator.serviceWorker.register('/service-worker.js').then(() => {
            const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt') || '';
            setServiceWorkerData(token);
          });
        });
      }
    });
  }
};

export const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      await navigator.serviceWorker.register('/service-worker.js', {
        scope: '/',
      });
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};
