import classNames from 'classnames/bind';
import React from 'react';
import styles from '../../GlobalModals/index.module.scss';
import { Avatar } from '../../MainHeader/User';
import AvatarPlaceholder from '../../Avatar';

const cx = classNames.bind(styles);

export const AvatarInInput = ({ item }) => {
  const abbreviation = item.abbreviation || item.email?.[0];
  return (
    <div className={cx('avatar')}>
      {item.avatarUrlVerySmall ? (
        <Avatar
          className="avatar_border_hover"
          width={20}
          src={item.avatarUrlVerySmall}
          alt="avatar"
          noMargins
        />
      ) : (
        <AvatarPlaceholder
          width={20}
          name={item.username}
          abbreviation={abbreviation}
        />
      )}
    </div>
  );
};
