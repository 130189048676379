import styled from 'styled-components/macro';

export const PlaylistMainShellW = styled.div`
  display: flex;
  width: 100%;
  ${({ isForSharedToWeb }) => (isForSharedToWeb ? ' height: 100vh;' : ' height: 100%;')}

`;
export const PlaylistAIProgressbar = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  width: 100%;
  max-width: 800px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px; 
  height: 100px;
  bottom: 40px;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  gap: 30px;
  
  .info {
    display: flex;
    height: 30px;
    align-items: center;
    gap: 14px;
  }
`;

export const SpinnerWrapper = styled.div`
  position: relative;
  top: 60px;

  div {
    width: 40px;
    height: 40px;
  }
`;

export const Place = styled.div`
  transition: cubic-bezier(0.09, 0.47, 0.58, 1) width 0.30s;
  width: 0;
  ${({ isShowRightSideBar }) => (isShowRightSideBar ? ' width: 360px; ' : '')}
  ${({ isShowRightSideBar, isLargeRightBar }) => ((isShowRightSideBar && isLargeRightBar) ? 'width: 100%;' : '')}
`;

export const PlaylistLastFrameShell = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #93939385;
  position: absolute;
`;

export const PlaylistMainShell = styled.div`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 800px;
  transition: ease-in-out width 0.35s;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    ${({ isFullScreenMode }) => (isFullScreenMode
    ? `
          background-color: #26293d;
    `
    : 'background-color: #FAFAFF;')}
  }

  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;

    ${({ isFullScreenMode }) => (isFullScreenMode
    ? `
          background: #242633;
    `
    : '    background-color: #e2e1e1;')}
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track-piece {
    display: none;
    background-color: #fafaff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  background: #f6f6f5;
`;

export const ToCAndExpandWrapper = styled.div``;

export const PlaylistMainControlsShell2 = styled.div`
  margin: 0 auto;
  position: relative;
  width: fit-content;
  z-index: 6;
`;

export const PlaylistMainControlsShell = styled.div`
  height: 66px;
  transition: all 0.3s ease-in-out;
  display: flex;
  margin: 12px auto auto -12px;
  width: 1035px;
  ${({ smExpand }) => (smExpand ? '  width: calc(100vw - 429px);' : '')}
`;

export const PlaylistControlsShellWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 29px;
  position: relative;
  justify-content: space-between;
  width: 100%;
`;

export const PlaylistControlsShell = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
`;
export const CheckboxWrapper = styled.div`
  top: -21px;
  position: relative;
  margin-right: 50px;
`;
export const CheckboxTextWrapper = styled.div`
  text-transform: uppercase;
  margin-right: 10px;
`;

export const ButtonContainer = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 17px;
  cursor: pointer;
  border-radius: 8px;

  ${({ pL }) => (pL ? `padding-left:${pL}px;` : 'padding-left: 3px;')}
  ${({ pB }) => (pB ? `padding-bottom:${pB}px;` : '  padding-bottom: 0;')}
  &:hover {
    svg path {
      stroke: #343434;
    }
  }

  ${({ isActive }) => (isActive
    ? `
    & svg path {
  
    }
  `
    : '')}

  ${({ isFavorite }) => (isFavorite
    ? `
    & svg path {
      stroke: #F6F6F5;
      fill: #FFC72C;
    }
      &:hover {
    svg path {
      stroke:transparent;
    }
  }
  `
    : '')}

  ${({ isDisabled }) => (isDisabled
    ? `
    & svg path {
      stroke: lightgrey;
    }
    &:hover svg path {
      stroke: lightgrey;
    }
    cursor: no-drop;
  `
    : '')}
`;

export const ThreeDotsDropDownUPVWrapper = styled.div`
  position: relative;
`;

export const TableCheckWrapper = styled.div`
  cursor: pointer;
  margin-right: 19px;

  svg path {
    stroke: #596080;
  }

  &:hover {
    svg path {
      stroke: #484343;
    }
  }

  ${({ isDisabled }) => (isDisabled
    ? `
    & svg path {
      stroke: lightgrey;
    }
    &:hover svg path {
      stroke: lightgrey;
    }
    cursor: no-drop;
  `
    : '')}
`;

export const MakerCutHeaderWrapper = styled.div`
  height: 67px;
  width: 100%;
  background: #f6f6f5;
  position: fixed;
  z-index: 10011;
  display: flex;
  ${({ expandVertical, smExpand, lExpand, moveLeft, isLargeRightBar }) => (expandVertical
    && (!smExpand && !lExpand && !moveLeft && !isLargeRightBar) ? 'top:0px;' : '  top: -67px;')}
  box-shadow: 0 1px 4px rgba(122, 105, 105, 0.1);
  ${({ smExpand }) => (smExpand
    ? `
  width:calc(100% - 360px);
  `
    : '')}

  ${({ lExpand }) => (lExpand
    ? `
  width:calc(100% - 630px);
  `
    : '')}

  ${({ moveLeft }) => (moveLeft
    ? `
  width:calc(100% - 360px);
  `
    : '')}
  ${({ isLargeRightBar }) => (isLargeRightBar
    ? `
  width:25vw;
  `
    : '')}
  transition: all ease-in-out 0.3s;
`;

export const Divider = styled.div`
  height: 1px;
  background: rgba(29, 29, 29, 0.08);
  width: calc(100% - 60px);
  position: absolute;
  top: 66px;
  margin-left: 30px;
`;

export const AllContentWrapper = styled.div`
  height: 66px;
  width: 100%;
  display: flex;
`;

export const AvaModuleWrapper = styled.div`
  position: absolute;
  right: 9px;
  top: 1px;
`;

export const LeftPlaceHoldersWrapper = styled.div`
  height: 67px;
  ${({ moveLeft, smExpand }) => (!moveLeft && smExpand
    ? `
  width:360px;
  `
    : 'width:0px;')}
  transition: all ease-in-out 0.3s;
`;

export const MakerContentMainSection = styled.div`
  height: 100%;
  position: relative;
  z-index: 6;
  ${({ scale }) => (`
  transform: scale(${scale || 1});
  webkit-transform: scale(${scale || 1});
  transform-origin: top;
  max-height: ${scale || 100}%;
  `)}
`;
export const MakerRowContentMainSection = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  min-width: 1140px;
  ${({ scale }) => (`
  transform: scale(${scale || 1});
  -webkit-transform:scale(${scale || 1}, ${scale || 1});
  webkit-transform: scale(${scale || 1}, ${scale || 1});
  max-height: ${scale || 1}%;
  z-index: 6;
  position: relative;
  `)}

`;
export const ContentWrapper = styled.div`
  width: fit-content;
`;

export const ThreeDotsDropWrapperMaker = styled.div`
  position: absolute;
  z-index: 9999;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  padding-bottom: 10px;
  height: fit-content;
  ${({ isWholePlaylistOperation }) => (isWholePlaylistOperation
    ? `
    top: 22px;
    left: 311px;
  `
    : `  
    bottom: -37px;
    right: -145px;
  `)}

  ${({ isShort, isWholePlaylistOperation }) => (isShort && isWholePlaylistOperation ? 'left:260px;' : '')}
  ${({ forPreview }) => (forPreview
    ? `
    top: 26px;
    right: 63px;
    padding-bottom: 8px;
  `
    : '')}
  ${({ isForLib }) => (isForLib
    ? `
  top:32px;
  `
    : '')}
  ${({ positionDown }) => (positionDown ? '    bottom: -171px;' : '')}
  ${({ upvCard }) => (upvCard
    ? `
    bottom: -2px;
    right: -218px;
  `
    : '')}

  ${({ isMaker }) => isMaker
    && `
    top: -5px;
    left: 36px;
  `}

  ${({ upvExpand }) => (upvExpand
    ? `
    top:0px;
    right: -146px;
  `
    : '')}
  ${({ isTextCardView }) => (isTextCardView
    ? `
    top: -71px;
    right: -123px;
  `
    : '')}
  ${({ isMultiActionPanel }) => (isMultiActionPanel
    ? `
    top: -179px;
    left: -29px;
    right: auto;
  `
    : '')}
  ${({ isTOC }) => (isTOC
    ? `
    width:121px;
    right: 5px;
  `
    : '')}
  ${({ isCardView }) => (isCardView
    ? `
    top: 6px;
    right: -130px;
  `
    : '')}
  ${({ top, left }) => (top && left
    ? `
    top: ${top}px;
    left: ${left}px;
  `
    : '')}
`;

export const MakerInfoShell = styled.div`
  min-height: 238px;
  width: 100%;
  background: #f1f1f0;
  border-bottom: 1px solid #ece2e2;
  box-shadow: 0 1px 4px rgba(122, 105, 105, 0.1);
`;

export const MakerInfoContent = styled.div`
  transition: all ease-in-out 0.3s;
  width: calc(100vw - 580px);
  max-width: 1020px;
  min-width: 800px;
  ${({ isRightSideBarExpanded }) => (isRightSideBarExpanded
    ? `
 
  `
    : '')}
  margin:auto;

  @media (max-width: 1400px) {
    width: calc(100vw - 347px);
  }

  @media (max-width: 1050px) {
    min-width: 827px;
  }
`;

export const MakerInfoImageAndMainInfo = styled.div`
  display: flex;
`;

export const MakerInfoDivider = styled.div`
  height: 1px;
  position: relative;
  background: rgba(29, 29, 29, 0.08);
  ${({ theFirst }) => (theFirst
    ? `
  width: calc(100% - 60px);
  left:30px;
  `
    : `
    bottom: -7px;
    left: 7px;
    width: calc(100% - 7px);
    margin-bottom: 9px;
  `)}
`;

export const MakerInfoUserUIWrapper = styled.div`
  margin-right: 8px;
  height: 20px;
`;
export const ShareWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`;

export const DownloadButton = styled.div`
  padding: 0 5px;
  height: 36px;
  border-radius: 8px;
  background: linear-gradient(313deg, #1FAE09 11.52%, #5BDA48 129.17%);
  box-shadow: 0 1px 4px 0 rgba(122, 105, 105, 0.15);  
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-family: RobotoFlex, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.8px;
  cursor: pointer;

  ${({ isDisabled }) => (isDisabled
    ? `
      cursor: not-allowed;
      opacity: 0.15;
    `
    : '')}
    ${({ isCutHeader }) => (isCutHeader
    ? `
      margin-left: 20px;
    `
    : '')}
`;

export const MakerInfoRowShell = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  align-items: center;
`;
export const AvatarInfoRowShell = styled.div`
  display: flex;
  position: relative;
  align-items: center;

`;

export const MakerFlexable = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const MakerInfoTextAreaBlock = styled.div`
  width: 86%;
  min-width: 0;
`;
export const MakerInfoTextPlaceholder = styled.div`
  width: 86%;
  min-width: 0;
  position: relative;
`;

export const ImgWrap = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 8px;
  background: #f6f6f5;
  object-fit: cover;
  overflow: hidden;
  margin-right: 16px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.14);
`;

export const Title = styled.p`
  min-width: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 761.2px);
  white-space: nowrap;
  ${({ moveLeft }) => (moveLeft
    ? `
      max-width: calc(100vw - 1225px);
    `
    : '')}
  ${({ isShowLibraryWidget }) => (isShowLibraryWidget
    ? `
      max-width: calc(100vw - 1385px);
    `
    : '')}
  ${({ isShowLibraryWidget, moveLeft, isWithProgressBar }) => ((isShowLibraryWidget || moveLeft) && isWithProgressBar
    ? `
      max-width: calc(100vw - 1540px);
    `
    : '')}
  
`;

export const CutModeImageAndNameWrapper = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 40px;
  color: #343434;
  text-align: center;
  align-items: center;
  gap: 16px;
  margin-left: 14px;
  position: relative;

  > svg {
    position: absolute;
    width: 44px;
    height: 44px;
    left: 0;
  }

  ${({ isWithProgressBar, small }) => (
    (isWithProgressBar || small)
      ? ` 
  @media (max-width: 1350px) {
    ${Title} {
      width: 320px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }
  @media (max-width: 1249px) {
    ${Title} {
      width: 220px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }
  @media (max-width: 1149px) {
    ${Title} {
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }
    `
      : ''
  )}
`;

export const FolderActiveOverlay = styled.div`
  opacity: 0.7;
  transform: scale(1.02, 1) translate(0%, -0.9%);
`;

export const AvaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -90px;
  top: 5px;
  white-space: nowrap;
  gap: 10px;
  border-radius: 32px;
  z-index: 2;
  ${({ calcColor }) => `background: ${calcColor}2C;`}
  color: #343434;
  font-family: RobotoFlex, sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding-left:  ${({ isOnlyAvatarsDisplay }) => (isOnlyAvatarsDisplay ? '0;' : '10px;')}
  ${({ isListView, calcColor }) => (isListView ? '' : `
    right: 90px;
    top: 50%;
    transform: translateY(-50%);
    ::before {
      content: " ";
      border: ${calcColor}4D;
      border-style: solid;
      width: 16px;
      right: -25px;
      top: 14px;
      position: absolute;
      border-width: 1px;
  }
  `)}

  p {
    display: none;
    padding-left: 12px;
    margin: 0;
  }

  &:hover {
    p {
      display: block;
    }
  }
`;

export const UserAvatarWrapper = styled.div`
  z-index: 10;
  position: relative;
  &::before {
  ${({ color }) => `border-color: ${color}33;`}
  }
`;

export const PanelButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  align - items: center;
`;

export const SelectedItemsPanel = styled.div`
  display: flex;
  width: 304px;
  height: 48px;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  border-radius: 8px;
  border: 1px #484343;
  background: #534B4F;
  box-shadow: 0px 1px 4px 0px rgba(52, 52, 52, 0.25);
  color: #fff;

  svg {
    cursor: pointer;
    width: 18px;
    heigjt: 18px;
    path {
      stroke: #fff;
    }
  }
`;

export const Processed = styled.div`
  color: #A2A5AA;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.05px;
`;

export const PannelTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const PannelWrapper = styled.div`
  position: absolute;
  bottom: 75px;
  right: 30px;
  z-index: 6;
`;

export const DisableWrap = styled.div`
  ${({ isDisable }) => (!isDisable ? '' : `
  svg {
    cursor: no-drop;
    path {
      stroke: #a2a7b2;
    }
  }
  `)}
`;
