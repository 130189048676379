import { MuxContentStatus } from './types';

// content = lib component with id, assetId
export const actionAddNewMuxContent = (content) => ({
  type: MuxContentStatus.addNew,
  payload: content,
});

export const actionRemoveMuxContent = (id) => ({
  type: MuxContentStatus.remove,
  payload: id,
});
