import React from 'react';
import { UiComponentTypes } from '../../constants';
import LibraryComponentTypeIcon from '../../../components/LibraryComponentTypeIcon/LibraryComponentTypeIcon';
import { ReactComponent as PdfSymbolSvg } from '../../../images/icons/contentIconSmall/pdfIcon.svg';

import { ReactComponent as PageSymbolSvg } from '../../../images/icons/playlist_item_types/page_19_23.svg';
import { ReactComponent as ImageSvg } from '../../../images/icons/playlist_item_types/image_22_22.svg';
import { ReactComponent as VideoSvg } from '../../../images/icons/playlist_item_types/video_17_24.svg';
import { ReactComponent as Link12Svg } from '../../../images/icons/playlist_item_types/link_24_24.svg';
import { ReactComponent as Google16Svg } from '../../../images/icons/google_drive_16.svg';
import { ReactComponent as Ms12Svg } from '../../../images/icons/doc_symbol_12.svg';
import { ReactComponent as Component12Svg } from '../../../images/icons/playlist_item_types/component_26_26.svg';
import { ReactComponent as OtherType12Svg } from '../../../images/icons/playlist_item_types/file_20_24.svg';
import { ReactComponent as Image24Svg } from '../../../images/icons/table_image_24.svg';
import { ReactComponent as Page24Svg } from '../../../images/icons/playlist_item_types/page_20_24.svg';
import { ReactComponent as Video24Svg } from '../../../images/icons/table_video_24.svg';
import { ReactComponent as Pdf24Svg } from '../../../images/icons/playlist_item_types/pdf_20_24.svg';
import { ReactComponent as Web24Svg } from '../../../images/icons/table_web_24.svg';
import { ReactComponent as Word24Svg } from '../../../images/icons/table_word_24.svg';
import { ReactComponent as Audio24Svg } from '../../../images/icons/playlist_item_types/audio_20_24.svg';
import { ReactComponent as Excel24Svg } from '../../../images/icons/playlist_item_types/excel_24_24.svg';
import { ReactComponent as Archive24 } from '../../../images/icons/playlist_item_types/archive_20_24.svg';
import { ReactComponent as NoRender24Svg } from '../../../images/icons/table_no_render_24.svg';
import { ReactComponent as Presentation24Svg } from '../../../images/icons/builder_element_presentation_24.svg';
import { ReactComponent as Component24Svg } from '../../../images/icons/component_tab_24.svg';

export const ElementTypeComponent = (type) => {
  if (UiComponentTypes.pdf[type]) {
    return (
      <LibraryComponentTypeIcon type={type}>
        <PdfSymbolSvg />
      </LibraryComponentTypeIcon>
    );
  }
  if (UiComponentTypes.page[type]) return <PageSymbolSvg />;
  if (UiComponentTypes.archive[type]) {
    return (
      <LibraryComponentTypeIcon type={type}>
        <Archive24 />
      </LibraryComponentTypeIcon>
    );
  }
  if (UiComponentTypes.audio[type]) {
    return (
      <LibraryComponentTypeIcon type={type}>
        <Audio24Svg />
      </LibraryComponentTypeIcon>
    );
  }
  if (UiComponentTypes.media[type]) {
    return (
      <LibraryComponentTypeIcon type={type}>
        <VideoSvg />
      </LibraryComponentTypeIcon>
    );
  }
  if (UiComponentTypes.image[type]) {
    return (
      <LibraryComponentTypeIcon type={type}>
        <ImageSvg />
      </LibraryComponentTypeIcon>
    );
  }
  if (UiComponentTypes.xls[type]) {
    return (
      <LibraryComponentTypeIcon type={type}>
        <Excel24Svg />
      </LibraryComponentTypeIcon>
    );
  }
  if (
    UiComponentTypes.link[type]
    && !UiComponentTypes.google_embed_component[type]
  ) {
    return (
      <LibraryComponentTypeIcon type={type}>
        <Link12Svg />
      </LibraryComponentTypeIcon>
    );
  }
  if (UiComponentTypes.google_embed_component[type]) {
    return (
      <LibraryComponentTypeIcon type={type}>
        <Google16Svg />
      </LibraryComponentTypeIcon>
    );
  }
  if (UiComponentTypes.ms[type]) {
    return (
      <LibraryComponentTypeIcon type={type}>
        <Ms12Svg />
      </LibraryComponentTypeIcon>
    );
  }
  if (UiComponentTypes.component[type]) {
    return (
      <LibraryComponentTypeIcon type={type}>
        <Component12Svg />
      </LibraryComponentTypeIcon>
    );
  }
  return <OtherType12Svg />;
};

export const mimeTypesResolver = (type) => {
  if (!type || typeof type !== 'string') return new Error('TypeError: Must be a string type');
  if (UiComponentTypes.image[type]) return 'image';
  if (UiComponentTypes.media[type]) return 'media';
  if (UiComponentTypes.pdf[type]) return 'pdf';
  if (UiComponentTypes.link[type]) return 'link';
  if (UiComponentTypes.ms[type]) return 'word';
  if (UiComponentTypes.xls[type]) return 'excel';
  if (UiComponentTypes.presentation[type]) return 'presentation';
  if (UiComponentTypes.archive[type]) return 'archive';
  if (UiComponentTypes.component[type]) return 'component';
  return '';
};
export const tableIconResolver = (type) => {
  if (!type || typeof type !== 'string') return new Error('TypeError: Must be a string type');
  if (UiComponentTypes.image[type]) return <Image24Svg data-parent="miniPage" />;
  if (UiComponentTypes.page[type]) {
    return (
      <Page24Svg data-parent="miniPage" style={{ width: 24, height: 24 }} />
    );
  }
  if (UiComponentTypes.media[type]) return <Video24Svg data-parent="miniPage" />;
  if (UiComponentTypes.pdf[type]) return <Pdf24Svg data-parent="miniPage" />;
  if (UiComponentTypes.link[type]) return <Web24Svg data-parent="miniPage" />;
  if (UiComponentTypes.ms[type]) return <Word24Svg data-parent="miniPage" />;
  if (UiComponentTypes.xls[type]) return <NoRender24Svg data-parent="miniPage" />;
  if (UiComponentTypes.xls[type]) return <Excel24Svg data-parent="miniPage" />;
  if (UiComponentTypes.presentation[type]) return <Presentation24Svg data-parent="miniPage" />;
  if (UiComponentTypes.archive[type]) return <NoRender24Svg data-parent="miniPage" />;
  if (UiComponentTypes.component[type]) return <Component24Svg data-parent="miniPage" />;
  return <NoRender24Svg data-parent="miniPage" />;
};
