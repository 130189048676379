import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsPopupWrapper, SPItem } from './styled';
import { actionCreator } from '../../shared/redux/actionHelper';
import { ServiceUser } from '../../redux/user/types';
import { ReactComponent as SortCheck2023 } from '../../images/2023/svg/small/player/settings_checked.svg';

const SettingsPopup = ({ popupRef, dataParent, close }) => {
  const dispatch = useDispatch();

  const { playerSettings: { isCaptionsHidden, isApproveButtonsHidden } } = useSelector((state) => state.user);

  const toggleCaptions = () => {
    dispatch(
      actionCreator(ServiceUser.Player.isCaptionsHidden, {
        value: !isCaptionsHidden,
      }),
    );
    close();
  };

  const toggleApprovalButtons = () => {
    dispatch(
      actionCreator(ServiceUser.Player.isApproveButtonsHidden, {
        value: !isApproveButtonsHidden,
      }),
    );
    close();
  };
  return (
    <SettingsPopupWrapper ref={popupRef} data-parent={dataParent}>
      <SPItem data-parent={dataParent} onClick={toggleApprovalButtons}>
        Show approval buttons
        {!isApproveButtonsHidden && <SortCheck2023 />}
      </SPItem>
      <SPItem data-parent={dataParent} onClick={toggleCaptions}>Show captions
        {!isCaptionsHidden && <SortCheck2023 />}
      </SPItem>
    </SettingsPopupWrapper>
  );
};

export default SettingsPopup;
