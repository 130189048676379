import { call, put, takeEvery } from 'redux-saga/effects';
import { select } from '@redux-saga/core/effects';
import { showErrorMessage } from './sagasUtils';
import {
  requestDeletedItems,
  requestDeleteLibItem,
  requestDeletePlaylist,
  requestRestoreLibItems,
  requestRestorePlaylist,
} from '../utils/request';
import { MessageType } from '../utils/constants';
import { actionShowMessage } from '../redux/support/action';
import { DeletedActionType } from '../redux/deleted/deletedTypes';
import { actionCreator } from '../shared/redux/actionHelper';
import { ContentActionType } from '../redux/content/contentTypes';

function* getDeleted(action) {
  try {
    const { data } = yield call(requestDeletedItems);
    yield put(actionCreator(DeletedActionType.finishUpload, { data }));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* deletePlaylist(action) {
  try {
    const { id } = action.payload;

    yield call(requestDeletePlaylist(id));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* deleteLibItem(action) {
  try {
    const { id } = action.payload;

    yield call(requestDeleteLibItem(id));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* restorePlaylist(action) {
  try {
    const { id } = action.payload;

    yield call(requestRestorePlaylist, [id]);
    const { activeNavSlider, selectorType, sortType } = yield select(
      (state) => state.content,
    );

    if (activeNavSlider === 'smartfiles') {
      if (
        selectorType === 'all'
        || selectorType === 'published'
        || selectorType === 'drafts'
        || selectorType === 'favorites'
      ) {
        yield put(
          actionCreator(ContentActionType.startUpload, {
            activeNavSlider,
            selectorType,
            sortType,
            isNeedUpdate: true,
          }),
        );
      }
    }
    yield put(actionCreator(ContentActionType.updateCounterS, {}));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* restoreLibItem(action) {
  try {
    const { id } = action.payload;

    yield call(requestRestoreLibItems, [id]);
    const { activeNavSlider, selectorType, sortType } = yield select(
      (state) => state.content,
    );
    if (activeNavSlider !== 'smartfiles') {
      yield put(
        actionCreator(ContentActionType.startUpload, {
          activeNavSlider,
          selectorType,
          sortType,
          isNeedUpdate: true,
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* deleteInboxCard(action) {
  try {
    // will be used further
    const { id } = action.payload;
    yield put(
      actionShowMessage({
        type: MessageType.SuccessfullyDeletedInboxCard,
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

export default function* deletedSagas() {
  yield takeEvery(DeletedActionType.getDeleted, getDeleted);
  yield takeEvery(DeletedActionType.deletePlaylist, deletePlaylist);
  yield takeEvery(DeletedActionType.deleteLibItem, deleteLibItem);
  yield takeEvery(DeletedActionType.deleteInboxCard, deleteInboxCard);
  yield takeEvery(DeletedActionType.restorePlaylistSR, restorePlaylist);
  yield takeEvery(DeletedActionType.restoreLibItemSR, restoreLibItem);
}
