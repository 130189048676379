import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import styles from './index.module.scss';
import { colorsApprovedButton, DATA_PARENTS, NewUPVViewMode, NewUPVViewModes } from '../../utils/constants';
import useComponentVisibleMouseDown from '../../utils/hooks/useComponentVisibleMouseDown';
import { actionCreator } from '../../shared/redux/actionHelper';
import SupportAction from '../../redux/support/types';
import { CurrentPage } from '../../redux/currentPage/types';
import CreateApprovePopUp from './CreateApprovePopUp';
import MakerApproveButton from './MakerApproveButton';

const cx = classNames.bind(styles);


type ColorsApprovedButton = typeof colorsApprovedButton[number];


interface ApproveButtonProps {
  state: {
    isNew: boolean;
    type: string;
    clickedName?: string;
    defaultName?: string;
    color?: ColorsApprovedButton;
    buttonId?: string;
  };
  saveState: (newState: Partial<ApproveButtonProps['state']>) => void;
  isView: boolean;
  close: () => void;
  linkPageId: string;
  place: string;
  upvCard: boolean;
  isExpandedView: boolean;
  isDisabled: boolean;
  viewMode: NewUPVViewMode;
  itemId: string;
  isMainInterface?: boolean;
  userInterface?: {
    userAnswerConnection?: {
      edges?: {
        answer: boolean;
      }[];
    };
  };
  children?: React.ReactNode;
}

const ApproveButton: React.FC<ApproveButtonProps> = ({
  state,
  saveState,
  isView,
  close,
  linkPageId,
  place,
  upvCard,
  isExpandedView,
  isDisabled,
  viewMode,
  itemId,
  isMainInterface,
  userInterface,
  children,
}) => {
  const [ref, isOpenMenu, setOpenMenu] = useComponentVisibleMouseDown(
    state.isNew,
    DATA_PARENTS.ApproveButton,
  );
  const [clickedName, setClickedName] = useState<string>(
    state.clickedName || 'Approve',
  );
  const [color, setColor] = useState<ColorsApprovedButton>(state.color || colorsApprovedButton[0]);
  const [defaultName, setDefaultName] = useState<string>(state.defaultName || 'Approved');
  const [buttonId, setButtonId] = useState<string>(state.buttonId || 'Approve Button');
  const [isClicked, setIsClicked] = useState<boolean | null>(null);
  const saveRef = useRef(null);
  const dispatch = useDispatch();

  const deleteApproveButton = () => {
    dispatch(
      actionCreator(CurrentPage.DeleteApproveButtonS, { linkPageId: itemId }),
    );
  };

  const toggleState = (e: React.MouseEvent<HTMLDivElement>, condition: boolean) => {
    e.stopPropagation();
    if (isDisabled) return;
    setIsClicked(condition);
    if (isView) {
      dispatch(
        actionCreator(CurrentPage.ClickToApproveButtonS, {
          linkPageId,
          isApprove: condition,
          isMainInterface,
        }),
      );
    }
    if (upvCard) {
      dispatch(
        actionCreator(CurrentPage.ClickToApproveButtonS, {
          linkPageId: itemId,
          isApprove: condition,
          isMainInterface,
        }),
      );
    }
  };

  useEffect(() => {
    if (userInterface?.userAnswerConnection?.edges?.length) {
      setIsClicked(userInterface?.userAnswerConnection?.edges[0].answer);
    }
  }, [userInterface]);

  useEffect(() => {
    if (isOpenMenu) {
      dispatch(actionCreator(SupportAction.SetZActiveItemIdR, { id: itemId }));
    } else {
      dispatch(actionCreator(SupportAction.SetZActiveItemIdR));
    }
  }, [isOpenMenu]);

  useEffect(() => {
    if (state.isNew && !isOpenMenu) setOpenMenu(state.isNew);
    if (
      clickedName !== state.clickedName
      || defaultName !== state.defaultName
      || color !== state.color
      || buttonId !== state.buttonId
    ) {
      setClickedName(state.clickedName || '');
      setColor(state.color as ColorsApprovedButton);
      setDefaultName(state.defaultName || '');
      setButtonId(state.buttonId || '');
      setOpenMenu(false);
    }
  }, [state]);

  useEffect(() => {
    if (
      !isOpenMenu
      && (clickedName !== state.clickedName
        || defaultName !== state.defaultName
        || color !== state.color
        || buttonId !== state.buttonId)
    ) {
      saveState({
        clickedName,
        type: state.type,
        defaultName,
        color,
        buttonId,
      });
    } else if (!isOpenMenu && state.isNew) {
      close();
    }
  }, [isOpenMenu]);

  return (
    <>
      <div
        className={viewMode === NewUPVViewModes.list
          ? ''
          : cx('button_wrapper', {
            PlayerContent: place === 'PlayerContent',
            isExpandedView,
          })}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className={cx('question_wrapper', {
            PlayerContent: place === 'PlayerContent',
            isPlayer: viewMode === NewUPVViewModes.player,
            upvCard,
            isListView: viewMode === NewUPVViewModes.list,
          })}
          ref={saveRef}
        >
          <MakerApproveButton
            viewMode={viewMode}
            toggleState={toggleState}
            color={color}
            isDisabled={isDisabled}
            defaultName={defaultName}
            clickedName={clickedName}
            isClicked={!!isClicked}
            editCB={() => setOpenMenu((prev) => !prev)}
            deleteCB={deleteApproveButton}
          />
        </div>

        {!isView && viewMode !== NewUPVViewModes.list && (
          <div className={cx('button_settings')}>
            <CreateApprovePopUp
              isOpenMenu={!!isOpenMenu}
              exRef={ref}
              buttonId={buttonId}
              setButtonId={setButtonId}
              defaultName={defaultName}
              setDefaultName={setDefaultName}
              clickedName={clickedName}
              setClickedName={setClickedName}
              setColor={setColor}
              color={color}
            />
          </div>
        )}
        {children}
      </div>
    </>
  );
};

export default ApproveButton;
