import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import Button2023, { IconTypesBtn2023 } from './Button2023';
import AddFilePopUp from '../LibraryHeaderMenu/childComponents/AddFilePopUp';
import { maxFileSize } from '../../GLOBAL_CONST';
import { actionCreateUpload } from '../../redux/filesUpload/action';
import { DATA_PARENTS, DEFAULT_TITLE, MessageType } from '../../utils/constants';
import filterFilesByMaxSize from '../../utils/validators/filterFilesByMaxSize';
import { actionShowMessage } from '../../redux/support/action';
import i18n from '../../i18n';

const ButtonUpload = ({ ...props }) => {
  const addFile = useComponentVisible(false, 'data', true);

  const inputFile = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const uploads = useSelector((state) => state.uploads.components);
  const { folderId, tags, selectorType } = useSelector((state) => state.user);

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = Array.from(e.target.files);
    const filteredFilesArr = filterFilesByMaxSize([...e.target.files]);
    if (files.length !== filteredFilesArr.length) {
      dispatch(
        actionShowMessage({
          type: MessageType.CannotBeUploaded,
          itemName: t('defaultSmartPageTitleT'),
        }),
      );
    }
    const sortedUploads = Object.values(uploads || {}).sort((a, b) => {
      if (a.errorMsg) return -1;
      if (b.errorMsg) return 1;
      return b.position - a.position;
    });

    filteredFilesArr.forEach((item, index) => {
      let uploadObject;
      const newId = uuidv4();
      if (item.size > maxFileSize.bytes) {
        uploadObject = {
          newId,
          file: item,
          title: item.name,
          position: sortedUploads.length
            ? sortedUploads[0]?.position + 1
            : index + 1,
          type: item.type || 'unknown_type',
          errorMsg: `${t('fileSizeMoreThanT')} ${maxFileSize.text}`,
          history,
          isFavorite: selectorType === 'favorites',
        };
      } else {
        uploadObject = {
          newId,
          file: item,
          title: item.name,
          position: sortedUploads[0]?.position + 1,
          type: item.type || 'unknown_type',
          history,
          isFavorite: selectorType === 'favorites',
        };
      }

      if (selectorType === 'tag') uploadObject.tags = [{ ...tags[folderId], type: 'tags' }];

      dispatch(actionCreateUpload(uploadObject));
      e.target.value = '';
    });
  };

  const onButtonClick = (e) => {
    e.stopPropagation();
    inputFile?.current?.click();
  };
  const upload = () => {
    addFile.setIsComponentVisible((prev) => !prev);
  };

  return (
    <>
      <Button2023
        text={t('importUpT')}
        handleButtonClick={upload}
        height={props.lowMode ? 28 : 32}
        variant="secondaryWhite"
        IcoType={IconTypesBtn2023.upload}
        paddingLeft={29}
        derivedParentData={props.dataParent || ''}
      />
      {!!addFile.isComponentVisible && (
        <AddFilePopUp
          parentRef={addFile.ref}
          wrapperRef={addFile.ref}
          onButtonClick={onButtonClick}
          setIsAddFileMenuVisible={addFile.setIsComponentVisible}
          place="upload_button"
        />
      )}

      <input
        type="file"
        name="file"
        multiple
        ref={inputFile}
        data-parent={`miniPage${DATA_PARENTS.PlaylistBuilderLibrary}`}
        style={{ display: 'none' }}
        onChange={(e) => {
          submitForm(e);
        }}
      />
    </>
  );
};

export default ButtonUpload;
