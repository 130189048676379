import styled from 'styled-components/macro';

export const TDWrapper = styled.td`
  text-align: center;
  ${({ failed }) => (failed
    ? `
  svg{
     margin-bottom:4px;  
  }
  `
    : '')}
`;
export const TBodyWrapper = styled.table`
  color: #585858;
  max-width: 636px;

  tr:nth-child(odd) {
    background-color: #ffffff;
  }

  tr:nth-child(even) {
    background-color: #fafafa;
  }

  tr {
    height: 36px;
  }

  tr:first-of-type {
    height: 19px;

    th:first-of-type {
      width: 238px;
      text-align: left;
      padding: 5px 5px 5px 10px;
    }
  }

  border-collapse: collapse;
  border: 1px solid black;

  th {
    border: 1px solid #dddfe1;
    font-weight: 500;
    font-size: 14px;
    width: 111px;
    position: relative;
    padding: 5px 0px;
    text-align: center;
  }

  td {
    border: 1px solid #dddfe1;
    padding: 5px 5px 5px 10px;
    font-weight: 400;
    font-size: 14px;
  }
`;

export const SortArrowWrapper = styled.div`
  position: absolute;
  left: 54px;
  top: 7px;
  cursor: pointer;
  ${({ rotate }) => (rotate
    ? `  
  top:4px;
  transform: rotate(180deg);`
    : '')}
`;
