import React from 'react';
import styled from 'styled-components/macro';
import { folderColors } from '../../utils/constants';
import FolderIconLayout from './FolderIconLayout';
import FolderSvgFrontPart from './FolderIcon';

const OverlayWrapper = styled.div`
  height: 140px;
  width: 140px;
  ${({ isSmall }) => (isSmall ? `
    height: 44px;
    width: 44px;
  ` : '')};
  ${({ isTable }) => (isTable ? `
    height: 30px;
    width: 30px;
    border-radius: 3px;
    overflow: hidden;
  ` : '')};
  ${({ isDownloadModal }) => (isDownloadModal ? `
   height: 100px;
   width: 100px;
  ` : '')};
  position: relative;
   > svg {
    height: 138px;
    width: 138px;
    left: 1px;
    bottom: 1px;
    ${({ isSmall }) => (isSmall ? `
      height: 42px;
      width: 42px;
    ` : '')};
    ${({ isTable }) => (isTable ? `
      height: 30px;
      width: 30px;
      left: 0px;
      bottom: -1px;
    ` : '')};
    ${({ isDownloadModal }) => (isDownloadModal ? `
      height: 98px;
      width: 98px;
    ` : '')};
    position: absolute;
  
    z-index: 2;
  }
`;
const FolderSvgBackPart = styled.div`
  height: 135px;
  width: 140px;
  border-radius: 6px;
  ${({ isSmall }) => (isSmall ? `
    height: 44px;
    width: 44px;
    border-radius: 4px;
  ` : '')};
  ${({ isTable }) => (isTable ? `
    height: 30px;
    width: 30px;
    border-radius: 3px;
  ` : '')};
  ${({ isDownloadModal }) => (isDownloadModal ? `
    height: 100px;
    width: 100px;
  ` : '')};
  position: relative;
  z-index: 1;
  background:  ${({ color }) => (color
    ? `linear-gradient(180deg, ${color.dark} 0%, rgba(255, 255, 255, 0) 100%);`
    : '')}
`;

const FolderSvgFrontPartWrapper = styled.div`
  width: 100%;
  height: 116px;
  ${({ isSmall }) => (isSmall ? `
    height: 35px;
  ` : '')};
  ${({ isMaker }) => (isMaker ? `
    height: 116px;
    transform: scale(1, 1.03);
  ` : '')};
  ${({ isTable }) => (isTable ? `
    height: 24px;
  ` : '')};
  ${({ isDownloadModal }) => (isDownloadModal ? `
    height: 93px;
    transform: scale(1.022, 1.04);
  ` : '')};
  background: transparent;
  border-radius: 10px;
  position: absolute;
  z-index: 3;
  bottom: 0;
  svg {
    margin: 0;
    ${({ isSmall }) => (isSmall ? `
      height: 35px;
      width: 44px;
    ` : '')};
    ${({ isTable }) => (isTable ? `
      height: 26px;
      width: 30px;
    ` : '')};
  }
`;

const FolderSvgCombined = React.memo(function FolderSvgCombined({
  currentColor,
  isSmall,
  isTable,
  isMaker,
  isDownloadModal,
  itemId,
}) {
  const color = folderColors.main?.[currentColor ?? 'yellow'] ?? folderColors.extra?.[currentColor];

  return (
    <OverlayWrapper isTable={isTable} isSmall={isSmall} isDownloadModal={isDownloadModal}>
      <FolderSvgBackPart
        color={color}
        isTable={isTable}
        isSmall={isSmall}
        isDownloadModal={isDownloadModal}
      />
      <FolderIconLayout id="templateSvg" isSmall={isSmall} />
      <FolderSvgFrontPartWrapper
        isTable={isTable}
        isMaker={isMaker}
        isSmall={isSmall}
        isDownloadModal={isDownloadModal}
      >
        <FolderSvgFrontPart
          color={color}
          id={itemId}
        />
      </FolderSvgFrontPartWrapper>
    </OverlayWrapper>
  );
});

export default FolderSvgCombined;
