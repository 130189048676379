import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { sortedFunctions } from '../../utils/dateConvert';
import { actionCreator } from '../../shared/redux/actionHelper';
import { InboxAction } from '../../redux/inbox/types';
import { INBOX_CARD_TYPE, UiComponentTypes } from '../../utils/constants';
import { ReactComponent as CloseSVG } from '../../images/2023/svg/preview/close_24_24.svg';

import Header from './Header';
import PreviewContentSelector from './PreviewContentSelector';
import GalleryBottomList from './GalleryBottomList';
import { HeaderCloseWrapper, HeaderControlsSVGWrapper, MainLightGalleryWrapper } from './styled';

import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

const ZOOM_STEP = 0.1;

const PreviewLightGallery = React.memo(function PreviewLightGallery({
  length,
  index,
  items,
  isForLib,
  isInbox,
  isMaker,
  close,
  setPreventResize,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeN, setActiveN] = useState(index);
  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [calcVisibleIndex, setCalcVisibleIndex] = useState(items[activeN]?.customIndex);
  const previousSortType = useSelector(state => state?.content?.sortType)
    || Object.keys(sortedFunctions)[2];
  const { unseenPagesManagerMap } = useSelector((state) => state.support);
  const windowDimensions = useWindowDimensions();

  const zoomRef = useRef(null);
  const zoomWrapRef = useRef(null);
  const zoomParam = useRef(1);

  const curryOperatePlus = (a, b) => a + b;

  const curryOperateMinus = (a, b) => a - b;

  const changeZoom = (operate) => {
    if (zoomParam.current && zoomWrapRef?.current) {
      zoomParam.current = Math.floor((operate(zoomParam.current * 10, 1) / 10) * 100) / 100;
      if (zoomWrapRef?.current?.style) zoomWrapRef.current.style = `zoom: ${zoomParam.current}`;
    }
  };

  const isContentWithinViewPort = (a) => {
    return (
      windowDimensions.width
      > zoomRef?.current?.clientWidth * ((a || zoomParam?.current) + ZOOM_STEP * 2)
    );
  };

  const updateAfterLoadPdf = useCallback(() => {
    setTimeout(() => {
      const resizeByHeight = windowDimensions.height / (zoomRef?.current?.clientHeight + 400);
      const resizeByWidth = windowDimensions.width / (zoomRef?.current?.clientWidth + 300);


      zoomParam.current = Math.min(resizeByWidth, resizeByHeight);
      if (zoomWrapRef?.current?.style) zoomWrapRef.current.style = `zoom: ${zoomParam.current}`;
    }, 100);
  }, []);

  const onZoomOutClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    zoomParam?.current > ZOOM_STEP * 2 && changeZoom(curryOperateMinus);
  };

  const onZoomInClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    isContentWithinViewPort() && changeZoom(curryOperatePlus);
  };

  const onZoomDefaultClick = (e) => {
    zoomParam.current = 1;
    e.stopPropagation();
    e.preventDefault();
    if (zoomWrapRef?.current?.style) zoomWrapRef.current.style = 'zoom: 1';
  };


  const zoomHandler = useCallback((event) => {
    if (event.ctrlKey && zoomWrapRef?.current) {
      event.preventDefault();
      const isZoomIn = !!(Math.sign(event.deltaY) - 1);
      if (isZoomIn) {
        zoomParam.current = isContentWithinViewPort(zoomParam.current)
          ? (zoomParam.current * 10 + event.deltaY * -0.02) / 10
          : zoomParam.current;

        if (zoomWrapRef?.current?.style) zoomWrapRef.current.style = `zoom: ${zoomParam.current}`;
      } else {
        zoomParam.current = (zoomParam.current * 10 + zoomParam.current * -0.03) / 10 > ZOOM_STEP * 2
          ? (zoomParam.current * 10 + event.deltaY * -0.03) / 10
          : ZOOM_STEP * 2;

        if (zoomWrapRef?.current?.style) zoomWrapRef.current.style = `zoom: ${zoomParam.current}`;
      }
    }
  }, []);

  useEffect(() => {
    zoomParam.current = 1;
    if (zoomWrapRef?.current?.style) {
      // zoomWrapRef.current.style = `zoom: ${zoomParam.current}`;
      // zoomParam.current = 1;
    }

    document.addEventListener('wheel', zoomHandler, { passive: false });
    return () => document.removeEventListener('wheel', zoomHandler);
  }, []);

  const {
    activeNavSlider = 'pages',
    selectorType = 'all',
    loadStatus,
    counters,
  } = useSelector(state => state.content);

  const maxNonTextElements = (isMaker || isInbox)
    ? items.filter((elem) => elem.type !== 'elementText').length
    : counters[`${activeNavSlider}/${selectorType}`];

  const goNext = useCallback((e) => {
    e.stopPropagation();
    setPreventResize(true);
    if (items[activeN + 1]?.type !== 'elementText' && items[activeN + 1]?.customIndex) {
      setCalcVisibleIndex(items[activeN + 1]?.customIndex);
    }
    setActiveN(prev => prev + 1);
  }, [items, activeN, setCalcVisibleIndex]);

  const goPrev = useCallback((e) => {
    setPreventResize(true);
    e.stopPropagation();
    if (items[activeN - 1]?.type !== 'elementText') {
      setCalcVisibleIndex(items[activeN - 1]?.customIndex);
    }
    setActiveN(prev => prev - 1);
  }, [items, activeN, setCalcVisibleIndex]);

  const goToItem = useCallback((n, i) => {
    setPreventResize(true);
    setActiveN(n);
    setCalcVisibleIndex(i);
  }, [setCalcVisibleIndex]);

  const ref = useRef(null);

  const handleKeyPress = useCallback((e) => {
    if (e.keyCode === 27) {
      close();
    }
    if (e.keyCode === 37 && activeN !== 0) {
      goPrev(e);
    } else if (e.keyCode === 39 && activeN < length - 1) {
      goNext(e);
    }
  }, [goPrev, goNext, dispatch, activeN, length]);


  useEffect(() => {
    setTimeout(() => {
      ref.current?.focus();
    }, 120);
  }, [ref.current]);

  useEffect(() => {
    setActiveN(index);
  }, [index]);

  useEffect(() => {
    setCalcVisibleIndex(items[index]?.customIndex);
  }, [index, items]);

  useEffect(() => {
    zoomParam.current = 1;
    const item = items[activeN];
    if (item) {
      if (unseenPagesManagerMap
        && unseenPagesManagerMap[item.id]) {
        dispatch(actionCreator(InboxAction.CardInBoxMarkUsReed, { item: {
          isRead: false,
          id: item.id,
          type: INBOX_CARD_TYPE.page,
        } }));
      }
    }
  }, [activeN, items.length]);

  const activeLinkPage = items[activeN];
  const isPDF = UiComponentTypes.pdf[activeLinkPage?.type || activeLinkPage?.libraryComponent?.type];

  if (!activeLinkPage?.id || !items.length) {
    return (
      <>
        {isInbox && (
        <MainLightGalleryWrapper ref={ref} tabIndex="1" onKeyDown={handleKeyPress}>
          <HeaderCloseWrapper>
            <HeaderControlsSVGWrapper onClick={close}>
              <CloseSVG />
            </HeaderControlsSVGWrapper>
          </HeaderCloseWrapper>
          <div className="loader-wrapper-page white below">
            <div className="loader white">{t('loadingT')}</div>
          </div>
        </MainLightGalleryWrapper>
        )}
        {!isInbox && (<div />)}
      </>
    );
  }

  return (
    <MainLightGalleryWrapper ref={ref} tabIndex="1" onKeyDown={handleKeyPress}>
      <Header
        isLocked={activeLinkPage?.isRemixLocked}
        title={activeLinkPage?.title || activeLinkPage?.libraryComponent?.title}
        counter={`${calcVisibleIndex}/${maxNonTextElements}`}
        item={activeLinkPage}
        activeN={activeN}
        isForLib={isForLib}
        goNext={goNext}
        beforeCloseHandler={close}
        isShowResizeControls={isPDF}
        onZoomOutClick={onZoomOutClick}
        onZoomInClick={onZoomInClick}
        onZoomDefaultClick={onZoomDefaultClick}
      />
      <PreviewContentSelector
        zoomWrapRef={zoomWrapRef}
        zoomRef={zoomRef}
        isMaker={isMaker}
        updateAfterLoadPdf={updateAfterLoadPdf}
        goNext={goNext}
        goPrev={goPrev}
        item={items[activeN]}
        canGoNext={activeN < length - 1}
        canGoPrev={activeN !== 0}
        isGalleryOpen={isGalleryOpen}
        setGalleryOpen={setGalleryOpen}
        close={close}
      />
      <GalleryBottomList
        loading={loadStatus === 'PENDING'}
        items={items}
        activeN={activeN}
        setActive={setActiveN}
        goToItem={goToItem}
        previousSortType={previousSortType}
        activeNavSlider={activeNavSlider}
        selectorType={selectorType}
      />
    </MainLightGalleryWrapper>
  );
});

export default PreviewLightGallery;
