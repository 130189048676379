import styled from 'styled-components/macro';

export const DropDownWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  color: #d3c8c8;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  top: 80px;
  padding: 16px 0px;
  width: 214px;
  z-index: 1;

  div:first-of-type {
    padding-left: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  border-radius: 6px;
  color: #585858;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  position: relative;
  height: 28px;
  padding-left: 8px;
  padding-right: 27px;
  line-height: 28px;
  width: max-content;
  position: relative;
  cursor: pointer;

  ${({ marginBot }) => (marginBot ? `margin-bottom:${marginBot}px;` : '')}
  svg {
    display: none;
  }

  ${({ isDropDown }) => (isDropDown
    ? `
    background: #FFC72C;
    color: #484343;
    svg {
    display: block;
    position: absolute;
    right: 7px;
    top: 13px;
  }
  `
    : `
    padding-right: 8px;
     &:hover{
        border: 1px solid #585858;
      }
  `)}

  ${({ isChosen }) => (isChosen
    ? `
   background: #FFC72C;
   color: #484343;
  `
    : '')}
`;
export const ChannelRowWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #484343;
  margin-top: 1px;
  height: 30px;
  line-height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 5px;

  &:hover {
    background-color: #ffeebf;
  }
  ${({ active }) => (active
    ? `
    background-color: #FFC72C;
  `
    : '')}//&.active {
  //  background-color: #FFC72C;
  //}
`;

export const ChannelListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1px;
`;

export const ButtonBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 251px;
`;
