import * as React from 'react';
import SortComponent from '../MiniPlaylist/NewPlaylist2023/childComponents/SortComponent';
import i18n from '../../i18n';

export const generateColumns = (createTableColumn, setSort) => {
  return [
    createTableColumn({
      columnId: 'checkbox',
      renderHeaderCell: () => <></>,
    }),
    createTableColumn({
      columnId: 'image',
      renderHeaderCell: () => <></>,
    }),
    createTableColumn({
      columnId: 'name',
      renderHeaderCell: () => <SortComponent isName setSort={setSort} />,
    }),
    createTableColumn({
      columnId: 'tags',
      renderHeaderCell: () => <div className="custom_header">{i18n.t('tagsT')}</div>,
    }),
    createTableColumn({
      columnId: 'type',
      renderHeaderCell: () => <SortComponent isType setSort={setSort} />,
    }),
    createTableColumn({
      columnId: 'created',
      renderHeaderCell: () => <SortComponent isCreated setSort={setSort} />,
    }),
    createTableColumn({
      columnId: 'modified',
      renderHeaderCell: () => <SortComponent isModified setSort={setSort} />,
    }),
    createTableColumn({
      columnId: 'size',
      renderHeaderCell: () => <div className="custom_header">{i18n.t('sizeT')}</div>,
    }),
    createTableColumn({
      columnId: 'actions1',
      renderHeaderCell: () => (
        <div style={{ textAlign: 'right', width: '100%', paddingRight: 19 }}>
          {i18n.t('actionsT')}
        </div>
      ),
    }),
  ];
};
const getWidth = (k) => ((window.innerWidth - 300) * k) / 100;
export const generateColumnSizing = () => {
  return {
    checkbox: {
      maxWidth: 40,
      idealWidth: 40,
      minWidth: 40,
    },
    image: {
      maxWidth: 40,
      idealWidth: 40,

      minWidth: 40,
    },
    name: {
      minWidth: 100,
      maxWidth: 300,
      defaultWidth: getWidth(20), // 17.6
    },
    tags: {
      minWidth: 200,
      defaultWidth: getWidth(28.8), // 15.46
    },
    type: {
      minWidth: 100,
      defaultWidth: getWidth(11.8), // 16.15
    },
    created: {
      minWidth: 120,
      defaultWidth: getWidth(14), // 10.75
    },
    modified: {
      minWidth: 115,
      defaultWidth: getWidth(14), // 10.6
    },
    size: {
      minWidth: 110,
      defaultWidth: getWidth(16), // 12.3
    },
    actions1: {
      minWidth: 115,
      defaultWidth: getWidth(8.5), // 3.07
    },
  };
};
