import React from 'react';


export const HOCActionSell = React.memo(function HOCActionSell({ label, ...props }) {
  const extendedActionSell = { ...label, props: { ...label.props, ...props } };
  return (
    <>
      {extendedActionSell}
    </>
  );
});
