import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import moduleStyles from './index.module.scss';
import { ReactComponent as ResizeIconSvg } from '../../images/icons/arrow_resize.svg';
import { createNodeFromHtml } from '../../utils/helpers';
import { ReactComponent as BrokenImageSvg } from '../../images/icons/broken_img_32.svg';
import FallbackImage from '../FallBackImage/FallBackImage';
import { BlockTypes } from '../../utils/constants';

const styles = classNames.bind(moduleStyles);

const ResizeImage = ({ startWith = 700, ...props }) => {
  const [width, setWidth] = useState(startWith || 700);
  const [stableWidth, setStableWidth] = useState(startWith || 700);
  const [direction, setDirection] = useState('');
  const mouseX = useRef(startWith || 700);
  const currentSize = useRef(startWith || 700);
  const [isMouseDown, setMouseDown] = useState(false);
  const isLargeRightBar = useSelector(
    (state) => state.currentPage.isLargeRightBar,
  );
  const [urlToCheck, setUrlToCheck] = useState(props.innerHtml || '');
  const [imageError] = useState(false);

  useEffect(() => {
    setWidth(startWith || 700);
    setStableWidth(startWith || 700);
    currentSize.current = startWith || 700;
  }, [startWith]);

  useEffect(() => {
    if (props.innerHtml && props.type === BlockTypes.image) {
      const node = createNodeFromHtml(props.innerHtml);
      if (node) {
        const images = node?.querySelectorAll('img') || [];
        if (images) {
          setUrlToCheck(images[1]?.src);
        }
      }
    }
  }, [props.localState]);

  const handlerMouseMove = (e) => {
    if (mouseX.current && direction && stableWidth) {
      let deviation;
      if (direction === 'right') deviation = stableWidth + (e.screenX - mouseX.current) * 2;
      else deviation = stableWidth - (e.screenX - mouseX.current) * 2;
      let calcWith;

      if (props.isPdf) {
        calcWith = deviation >= 700 ? 701 : deviation <= 383 ? 383 : deviation;
      } else {
        calcWith = deviation >= 700 ? 701 : deviation <= 150 ? 151 : deviation;
      }
      setWidth(calcWith);
      currentSize.current = calcWith;
    }
  };

  const handlerMouseUp = () => {
    mouseX.current = null;
    setDirection('');
    if (currentSize.current) setStableWidth(currentSize.current);
    setMouseDown(false);
    props.save(currentSize.current);
  };

  const handlerMouseDown = (e, dr) => {
    mouseX.current = e.screenX;
    setDirection(dr);

    e.stopPropagation();
    e.preventDefault();
    setMouseDown(true);
  };

  useEffect(() => {
    if (isMouseDown) {
      // document.addEventListener('mouseleave', handlerMouseDown, true);
      document.addEventListener('mouseup', handlerMouseUp, true);
      document.addEventListener('mousemove', handlerMouseMove, true);
    } else {
      // document.removeEventListener('mouseleave', handlerMouseDown, true);
      document.removeEventListener('mouseup', handlerMouseUp, true);
      document.removeEventListener('mousemove', handlerMouseMove, true);
    }
    return () => {
      // document.removeEventListener('mouseleave', handlerMouseDown, true);
      document.removeEventListener('mouseup', handlerMouseUp, true);
      document.removeEventListener('mousemove', handlerMouseMove, true);
    };
  }, [isMouseDown]);

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...child.props, resizeWidth: width });
    }
    return child;
  });

  if (props.type !== BlockTypes.image && props.type !== BlockTypes.pdf) return props.children;
  if (imageError) {
    return (
      <div className={styles('broken_image_container')}>
        <BrokenImageSvg />
      </div>
    );
  }

  return (
    <div className={styles('resize_wrapper')}>
      <div style={{ width }} className={styles('resize', { pdf: props.isPdf })}>
        {props.type !== BlockTypes.pdf && (
          <FallbackImage
            isModal
            src={urlToCheck}
            alt=""
            type="image"
            isResizeImage
          />
        )}
        <div
          onMouseDown={(e) => handlerMouseDown(e, 'left')}
          className={styles('resize_handler', 'left', {
            hidden: isLargeRightBar || props.isSharePage,
          })}
        >
          <div className={styles('svg_wrapper')}>
            <ResizeIconSvg />
          </div>
        </div>
        {childrenWithProps}
        <div
          onMouseDown={(e) => handlerMouseDown(e, 'right')}
          className={styles('resize_handler', 'right', {
            hidden: isLargeRightBar || props.isSharePage,
          })}
        >
          <div className={styles('svg_wrapper')}>
            <ResizeIconSvg />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResizeImage;
