import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Mousetrap from '../mousetrap';
import { actionWriteCombination } from '../../redux/shortcuts/action';
import { actionCreator } from '../../shared/redux/actionHelper';
import { CurrentPage } from '../../redux/currentPage/types';

const UseKeypressCallback: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const bindKeys = () => {
      Mousetrap.bind(['mod+a'], (e: KeyboardEvent, combo: string) => {
        e.preventDefault();
        dispatch(actionWriteCombination(combo));
        return false;
      });
      Mousetrap.bind(['mod+shift+z'], (e: KeyboardEvent, combo: string) => {
        e.preventDefault();
        dispatch(actionWriteCombination(combo));
        return false;
      });
      Mousetrap.bind(['mod+c'], (e: KeyboardEvent, combo: string) => {
        dispatch(actionWriteCombination(combo));
        return true;
      });
      Mousetrap.bind(['mod+z'], (e: KeyboardEvent, combo: string) => {
        dispatch(actionWriteCombination(combo));
        return false;
      });
      Mousetrap.bind(['mod+k'], () => {
        dispatch(actionCreator(CurrentPage.ToggleIsCardView));
        return true;
      });
      Mousetrap.bind(['mod+enter'], (e: KeyboardEvent, combo: string) => {
        dispatch(actionWriteCombination(combo));
        return true;
      });
    };

    bindKeys();

    return () => {
      // @ts-ignore
      Mousetrap.unbind(['mod+a']);
    };
  }, [dispatch]);

  return <></>;
};

export default UseKeypressCallback;
