import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import EmptyStateSelector from '../../components/EmptyStateSelector/EmptyStateSelector';
import { resolvePath } from '../../utils/helpers';
import MemberRow from './MemberRow';
import MemberTableHeader from './MemberRow/MemberTableHeader';

const ChannelsMembersContent = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  if (props.loading) {
    return (
      <div className="loader-wrapper-page white">
        <div className="loader white">{t('loadingT')}</div>
      </div>
    );
  }
  return (
    <>
      <EmptyStateSelector
        isChannel={props.isChannel}
        sortedItems={props.sortedPages}
        isFilter={props.isFilter}
        search={props.filter}
        filter={props.isFilter}
        isCardView={props.isCardView}
        loading={props.loading}
        path={resolvePath(history.location.pathname)}
      />
      {!!props.sortedPages.length && !props.isCardView && (
        <MemberTableHeader
          setSort={props.setSort}
          sortType={props.sortType}
          isHide={!props.sortedPages.length}
        />
      )}
      {props.sortedPages.map((item) => (
        <MemberRow
          key={`Users_row_${item.id}`}
          item={item}
          channel={props.channel}
        />
      ))}
    </>
  );
};

export default ChannelsMembersContent;
