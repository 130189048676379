import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';
import { universalPlaylistSize, getItemName, sanitizeToLoad } from '../../utils/helpers';
import { actionLoadingItem } from '../../redux/support/action';
import { actionCloseModal } from '../../redux/user/action';

import FolderImage from '../../pages/Maker/FolderImage';
import Tooltip from '../../shared/Tooltips/Tooltip';

import FolderIconLayout from '../../pages/Maker/FolderIconLayout';
import { ReactComponent as CheckSvg } from '../../images/icons/icon_20/checkbox_grey.svg';
import { ReactComponent as CheckEmptySvg } from '../../images/icons/icon_20/checkbox_grey_off.svg';
import { ReactComponent as WarningSvg } from '../../images/icons/icon_20/warning.svg';
import { ReactComponent as ChevronSVG } from '../../images/2023/svg/small/complete/chevron_10_6.svg';
import closeIcon from '../../images/icons/close_24.svg';

import rawStyles from './index.module.scss';

const cx = classNames.bind(rawStyles);

const DownloadPlaylistModalBody = ({
  id,
  img,
  cropUrl,
  linkPages = [],
  download,
  title,
  defaultPreviewColor,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const items = linkPages?.filter(elem => elem.type);
  const defaultEnabledLP = items?.filter(elem => !elem.isRemixLocked && elem.libraryComponent?.type !== 'page' && elem.type);
  const [filesForDownload, setFilesForDownload] = useState(defaultEnabledLP);
  const [isLinkAdded, setLinkAdded] = useState(true);
  const [isMenuExpanded, setMenuExpanded] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(actionLoadingItem(''));
    };
  }, []);

  const closeModal = () => {
    dispatch(actionCloseModal(''));
  };

  const calcPlaylistSize = useMemo(() => {
    return universalPlaylistSize(filesForDownload, true);
  }, [filesForDownload]);

  const calcFileName = (linkPage) => {
    if (linkPage?.textComponent) {
      const text = getItemName(linkPage);
      return sanitizeToLoad(text).replaceAll('\\n', ' ');
    }
    return linkPage?.title ? linkPage?.title : linkPage?.libraryComponent?.title;
  };

  const selectFile = (linkPage) => {
    if (!linkPage.isRemixLocked && linkPage.libraryComponent?.type !== 'page') {
      const filteredArrayById = filesForDownload.filter(file => file.id !== linkPage.id);
      filteredArrayById.length < filesForDownload.length
        ? setFilesForDownload(filteredArrayById) : setFilesForDownload([...filesForDownload, linkPage]);
    }
  };

  const selectAllFiles = () => {
    filesForDownload.length === defaultEnabledLP.length
      ? setFilesForDownload([]) : setFilesForDownload(defaultEnabledLP);
  };

  const downloadFiles = (e) => {
    const filesArray = filesForDownload.map(elem => elem.id);
    const smartfileLink = window.location.href;
    download(e, filesArray, isLinkAdded, isLinkAdded ? smartfileLink : null);
    closeModal();
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={cx('modal', 'downloadPlaylist')}
    >
      <div className={cx('content_wrapper')}>
        <div className={cx('header')}>
          <img
            onClick={closeModal}
            src={closeIcon}
            className={cx('close')}
            alt="close"
          />
        </div>
        <div className={cx('modal_body')}>
          <div className={cx('item_wrapper', 'channel2023')}>
            <div className={cx('image_wrapper')}>
              {img?.urlFile || cropUrl ? (
                <>
                  <img className={cx('image')} src={cropUrl || img?.urlFile} alt="preview" />
                  <FolderIconLayout isOverCoverImage />
                </>
              ) : (
                <FolderImage itemId={id} currentColor={defaultPreviewColor} isDownloadModal />
              )}
            </div>
          </div>
          <p className={cx('title')}>{title}</p>
          <div className={cx('modal_body_details')}>
            <div className={cx('modal_body_details_row')}>
              <p>{t('sizeT')}</p>
              <p>{calcPlaylistSize}</p>
            </div>
            <div className={cx('modal_body_details_row')}>
              <div className={cx('modal_body_details_files_wrapper')}>
                <p>{t('filesT')}</p>
                <div
                  className={cx('chevron_wrapper', { is_rotated: isMenuExpanded })}
                  onClick={() => setMenuExpanded(!isMenuExpanded)}
                >
                  <ChevronSVG />
                </div>
              </div>
              <div className={cx('files_count_wrapper')}>
                {items.length !== defaultEnabledLP?.length && (
                <Tooltip
                  text={t('someFilesAreLockedT')}
                  direction="down"
                  place="DownloadModal"
                >
                  <WarningSvg />
                </Tooltip>
                )}
                <p>{`${filesForDownload?.length}/${items?.length}`}</p>
              </div>
            </div>
            <div className={cx('modal_body_details_table', { expanded_menu: isMenuExpanded })}>
              <div className={cx('modal_body_details_table_header')}>
                <div className={cx('select_all_wrapper')} onClick={selectAllFiles}>
                  {defaultEnabledLP.length === filesForDownload.length ? (
                    <CheckSvg />
                  ) : (
                    <CheckEmptySvg />
                  )}
                </div>
                <span>№</span>
                <p className={cx('table_title')}>{t('fileNameT')}</p>
              </div>
              <div className={cx('divider', 'first')} />
              <div className={cx('modal_body_details_table_body')}>
                {items.map((linkPage = {}, index) => (
                  <div
                    className={cx('file_row',
                      { disabled: linkPage.isRemixLocked || linkPage.libraryComponent?.type === 'page' })}
                    key={linkPage.id}
                    onClick={() => selectFile(linkPage)}
                  >
                    {filesForDownload.some(elem => elem.id === linkPage.id) ? (
                      <CheckSvg />
                    ) : (
                      <CheckEmptySvg />
                    )}
                    <span>{index + 1}</span>
                    <p className={cx('file_title')}>{calcFileName(linkPage)}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={cx('add_link_checkbox')} onClick={() => setLinkAdded(!isLinkAdded)}>
            <p>{t('addLinkToSmartFileT')}</p>
            {isLinkAdded ? (
              <CheckSvg />
            ) : (
              <CheckEmptySvg />
            )}
          </div>
          <div className={cx('divider')} />
        </div>
        <div className={cx('controls_wrapper')}>
          <button
            className={cx('cancel_button')}
            type="button"
            onClick={closeModal}
          >
            {t('cancelUpT')}
          </button>
          <button
            className={cx('download_button', { disabled: !filesForDownload.length })}
            type="submit"
            onClick={downloadFiles}
          >
            {t('downloadUpT')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadPlaylistModalBody;
