import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import { actionGetCurrentPage } from '../../redux/currentPage/action';
import { ReactComponent as LogoSvg } from '../../images/icons/quidzi_logo_login_113-34.svg';
import SharedComponent from '../../components/SharedComponent';

const EmbedPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const titlePage = useSelector(state => state.currentPage.title);
  const description = useSelector(state => state.currentPage.description);
  const isDownload = useSelector(state => state.currentPage.isDownload);
  const editableBlocks = useSelector(state => state.currentPage.blocks) || [];

  useEffect(() => {
    dispatch(actionGetCurrentPage(id, true, history));
  }, [id]);

  if (isDownload) {
    return (
      <div className="loader-wrapper-page">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper2}>
      <div className={styles.wrapper}>
        <div className={styles.heading}>
          <div className={styles.heading_left}>
            <LogoSvg />
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.input_title}>{titlePage} </div>
          <div className={styles.input_description}>{description} </div>
        </div>

        <div className={styles.space_separator} />
        <div className={styles.content}>
          {editableBlocks.map((item, index) => {
            if (item.isHidden) return <div key={item.id} />;
            if (!item.components) {
              return (
                <SharedComponent
                  key={item.id}
                  type1={item.type}
                  state={item.state}
                  componentId={id}
                  index={index}
                  width={item.width}
                  page={item}
                  innerHtml={item.innerHtml}
                  isSharePage
                />
              );
            }
            return item.components.map((component, index) => {
              if (component.isHidden) return <div key={component.id} />;
              return (
                <SharedComponent
                  key={component.id}
                  type1={component.type}
                  state={component.state}
                  componentId={id}
                  index={index}
                  width={component.width}
                  page={component}
                  innerHtml={component.innerHtml}
                  isSharePage
                />
              );
            });
          })}
        </div>
      </div>
    </div>
  );
};

export default EmbedPage;
