import React from 'react';
import styled from 'styled-components/macro';
import { svgDynamicCollection } from '../../../utils/constants';

const MainFlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 9px;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  left: -2px;
`;

const SvgWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding-left: 5px;
  padding-top: 5px;
  cursor: pointer;

  &:hover {
    background-color: #ffeebf;
  }

  svg path {
    stroke: #484343;
  }

  ${({ isActive }) => (isActive
    ? `
   background: #FFC72C;
  `
    : '')}
`;
const SelectIconGroup = ({ selectedIcon, setIconKey }) => {
  return (
    <MainFlexWrapper>
      {Object.keys(svgDynamicCollection).map((svgKey, index) => (
        <SvgWrapper
          key={`${svgKey}_${index}`}
          onClick={() => setIconKey(svgKey)}
          isActive={selectedIcon === svgKey}
        >
          {' '}
          {svgDynamicCollection[svgKey]()}
        </SvgWrapper>
      ))}
    </MainFlexWrapper>
  );
};

export default SelectIconGroup;
