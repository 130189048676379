import styled from 'styled-components/macro';

export const NextAdd = styled.div`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  & svg {
    width: 20px;
    margin-right: 6px;
    & path {
      stroke-width: 1.7px;
    }
  }
`;
export const PlaylistNew = styled.div`
  margin-bottom: 16px;
  margin-right: 18px;
  border: 1px solid #a3aacc;
  color: #a3aacc;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  & svg {
    & path {
      stroke: #a3aacc;
      stroke-width: 2px;
    }
  }
  & div svg {
    width: 20px;
  }
  &:hover {
    background: #e8ebfa;
    border: 1px solid #0d26e5;
    color: #0d26e5;
    & path {
      fill: #e8ebfa;
      stroke: #0d26e5;
    }
  }
`;
