import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoSvg } from '../../images/2023/svg/Logo.svg';
import styles from '../loginLogout/Login.module.scss';
import { LoginBackGround } from '../loginLogout/Login';
import {
  ContainerFit,
  ForgotWrapper,
  Logo,
  SignInReturn,
  SubTitle,
  Title,
} from '../loginLogout/ForgotPassword/ForgotView';
import { DEFAULT_PAGE_PATH } from '../../utils/constants';
import { actionSaveRedirect } from '../../redux/support/action';
import { capitalizeFirstLetter } from '../../utils/helperText';

const WrongUser = ({ needLogin, type = 'page', path }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const goToSignIn = () => {
    dispatch(actionSaveRedirect(path));
    history.push('/login');
  };

  const goToLibrary = () => {
    if (type === 'page') {
      history.push('/content/pages/shared');
    } else {
      history.push('/content/smartfiles/shared');
    }
  };

  return (
    <>
      <LoginBackGround />
      <div className={styles.scroll_wrapper}>
        <div className={styles.scroll_balancer}>
          <ForgotWrapper>
            <ContainerFit>
              <Logo onClick={goToSignIn}>
                <LogoSvg />
              </Logo>
              <Title>
                {!needLogin
                  && t('typeSharedNotAvailableT', { type })}
                {needLogin && t('signInToAccessSharedT', { type })}
              </Title>
              {!needLogin && (
                <SubTitle>
                  {t('contactQuidziMateToShareT')}
                </SubTitle>
              )}
              {needLogin && (
                <SignInReturn onClick={goToSignIn}>
                  {t('returnToSignInT')}
                </SignInReturn>
              )}
              {!needLogin && (
                <SignInReturn onClick={goToLibrary}>
                  {t('returnToTypesT', { type: capitalizeFirstLetter(type) })}
                </SignInReturn>
              )}
            </ContainerFit>
          </ForgotWrapper>
        </div>
      </div>
    </>
  );
};

export default WrongUser;
