export const filterNamesRecently = [
  'filterNameTodayT',
  'filterNameYesterdayT',
  'filterNameEarlierThisWeekT',
  'filterNameLastweekT',
  'filterNameearlierThisMonthT',
  'filterNamelastMonthT',
  'filterNameearlierThisYearT',
  'filterNamelastYearT',
  'filterNameaLongTimeAgoT',
] as const;


export const dateGroupEnum = {
  TodayPeriod: 'TodayPeriod',
  YesterdayPeriod: 'YesterdayPeriod',
  CurrentWeekPeriod: 'CurrentWeekPeriod',
  PreviousWeekPeriod: 'PreviousWeekPeriod',
  CurrentMonthPeriod: 'CurrentMonthPeriod',
  PreviousMonthPeriod: 'PreviousMonthPeriod',
  CurrentYearPeriod: 'CurrentYearPeriod',
  PreviousYearPeriod: 'PreviousYearPeriod',
  ALongTimeAgoPeriod: 'ALongTimeAgoPeriod',
} as const;
