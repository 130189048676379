import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button2023 from '../Buttons/Button2023';
import { ReactComponent as CompletedSVG } from '../../images/2023/svg/small/complete/checked_20_20.svg';
import { PBCounterContainer, PBEmptyStripe, PBFilledStripe, PBSVGWrapper, PBWrapper } from './styled';
import ButtonResumeRestart from '../Buttons/ButtonResumeReset';
import { actionUpdatePlaylistReadStatus } from '../../redux/playlists/action';
import { actionMarkAllLinkPagesAsIncomplete } from '../../redux/currentPage/action';
import useViewersProgressMaker from '../../utils/hooks/useViewersProgressMaker';


const Maker = ({ isFinished, wrapperId, forCutHeader }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isShowLibraryWidget } = useSelector((state) => state.currentPage);

  const { viewed = 0, total = 0 } = useViewersProgressMaker();
  const filledWidth = useMemo(() => (isFinished ? 304 : Math.round(viewed * 304 / total)), [viewed, total, isFinished]);
  if (total === 0) {
    return <></>;
  }
  const isCompleted = total === viewed || isFinished;


  const toggleCompletion = () => {
    dispatch(actionUpdatePlaylistReadStatus(isFinished, wrapperId));
  };

  const restart = () => {
    dispatch(actionMarkAllLinkPagesAsIncomplete(wrapperId));
  };
  return (
    <PBWrapper isMaker>
      <PBCounterContainer forCutHeader={forCutHeader} isMaker>
        {`${isFinished ? total : viewed}/${total}`}
      </PBCounterContainer>

      <PBEmptyStripe forCutHeader={forCutHeader} isMaker isCut={isShowLibraryWidget && forCutHeader}>
        <PBFilledStripe width={filledWidth} />
        {isCompleted && (
          <PBSVGWrapper>
            <CompletedSVG />
          </PBSVGWrapper>
        )}
      </PBEmptyStripe>

      {isCompleted ? (
        <ButtonResumeRestart resume={toggleCompletion} restart={restart} />
      ) : (
        <Button2023
          variant="transparentGreen"
          text={t('markAsCompletedUpT')}
          handleButtonClick={toggleCompletion}
        />
      )}
    </PBWrapper>
  );
};

export default Maker;
