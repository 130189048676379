import { capitalizeFirstLetter, capitalizeOnlyFirstLetter } from '../helperText';

const channelTabsWithSort = {
  '[Channels]Favorite': 'channelFavoritePlaylists',
  '[Channels]All': 'channelPlaylists',
};
export const sortOptionChooser = (activeNavSlider, selectorType) => {
  if (activeNavSlider === 'users_smartfiles' || activeNavSlider === 'users_smartfiles_favorite') {
    return 'smartfilesUsers';
  }

  return activeNavSlider?.includes('channel')
    ? activeNavSlider
    : activeNavSlider + capitalizeOnlyFirstLetter(selectorType);
};

export const sortOptionChooserForChannels = (channelTabName) => {
  return channelTabsWithSort[channelTabName];
};

export const extractSortOptionFromHistory = (history) => {
  const path = history.location.pathname;
  if (path.includes('channel')) {
    return 'channelPlaylists';
  }
  const splittedPath = path.split('/');
  return splittedPath[2] + capitalizeFirstLetter(splittedPath[3]);
};
