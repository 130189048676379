const DashboardColumns = {
  uploadColumns: '[DashboardColumns] uploadColumns',
  uploadColumnsRedux: '[DashboardColumns] save state in redux',
  column: {
    create: '[DashboardColumns] create column',
    addToRedux: '[DashboardColumns] add to redux column',
    remove: '[DashboardColumns] remove column',
    removeInRedux: '[DashboardColumns] remove in redux column',
    updateInboxColumnSaga:
      '[DashboardColumns] update state from column Inbox Sage',
    updateInboxColumnRedux: '[DashboardColumns] update state from column Inbox',

    rename: '[DashboardColumns] [column]rename column',
    updateColorsOrTitle: '[DashboardColumns] [column] change color/title column',
    updateColorsOrTitleR: '[DashboardColumns] [column] change color/title column Redux',
    updatePosition: '[DashboardColumns] [column] update position',
    moveInRedux: '[DashboardColumns] [column] remove in redux column',

    toggleShowHide: '[DashboardColumns] [column] toggle Show/Hide',
    toggleShowHideRedux: '[DashboardColumns] [column] toggle Show/Hide Redux',
  },
  card: {
    addToColumn: '[DashboardColumns] [card] add cart to column',
    addToCloseColumn: '[DashboardColumns] [card] add cart to CLOSE column',
    remove: '[DashboardColumns] [card] remove column',
    moveToColumn: '[DashboardColumns] [card] card move to column',
    moveToCLoseColumn: '[DashboardColumns] [card] card move to Close column',
    // moveToINBoxColumn: '[DashboardColumns] [card] card move to INBOX column',
    updateCardsForPlaylist:
      '[DashboardColumns] [card] update cards for playlist',
    addViewedPlaylistManagerSR:
      '[DashboardColumns] [card] add viewed PlaylistManager SAGA+REDUX ',
  },
};

export default DashboardColumns;
