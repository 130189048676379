export const THREE_DOTS_DD_OPTIONS = {
  title: 'titleDropdownOptionT',
  play: 'playDropdownOptionT',
  open: 'openDropdownOptionT',
  edit: 'editDropdownOptionT',
  rename: 'renameDropdownOptionT',
  openMaker: 'openMakerDropdownOptionT',
  duplicate: 'duplicateDropdownOptionT',
  download: 'downloadDropdownOptionT',
  unfavorite: 'unfavoriteDropdownOptionT',
  favorite: 'favoriteDropdownOptionT',
  share: 'shareDropdownOptionT',
  unpublish: 'unpublishDropdownOptionT',
  publish: 'publishDropdownOptionT',
  viewerProgress: 'viewerProgressDropdownOptionT',
  viewDetails: 'viewDetailsDropdownOptionT',
  delete: 'deleteDropdownOptionT',
  remove: 'removeDropdownOptionT',
};
export const THREE_DOTS_DD_OPTIONS_MiniPage = {
  title: 'titleDropdownOptionT',
  open: 'openDropdownOptionT',
  edit: 'editDropdownOptionT',
  rename: 'renameDropdownOptionT',
  duplicate: 'duplicateDropdownOptionT',
  unfavorite: 'unfavoriteDropdownOptionT',
  favorite: 'favoriteDropdownOptionT',
  share: 'shareDropdownOptionT',
  addATag: 'addATagDropdownOptionT',
  addToPlaylist: 'addToPlaylistDropdownOptionT',
  viewDetails: 'viewDetailsDropdownOptionT',
  delete: 'deleteDropdownOptionT',
  summarize: 'summarizeOptionT',
};
export const THREE_DOTS_DD_OPTIONS_LIBRARY = {
  title: 'titleDropdownOptionT',
  open: 'openDropdownOptionT',
  edit: 'editDropdownOptionT',
  rename: 'renameDropdownOptionT',
  duplicate: 'duplicateDropdownOptionT',
  unfavorite: 'unfavoriteDropdownOptionT',
  favorite: 'favoriteDropdownOptionT',
  share: 'shareDropdownOptionT',
  addATag: 'addATagDropdownOptionT',
  addToPlaylist: 'addToPlaylistDropdownOptionT',
  viewDetails: 'viewDetailsDropdownOptionT',
  delete: 'deleteDropdownOptionT',
};
export const THREE_DOTS_DD_OPTIONS_UPV_TABLE = {
  title: 'titleDropdownOptionT',
  play: 'playDropdownOptionT',
  open: 'openDropdownOptionT',
  edit: 'editDropdownOptionT',
  download: 'downloadDropdownOptionT',
  addToLibrary: 'addToLibraryDropdownOptionT',
  addToPlaylist: 'addToAnotherSmartfileDropdownOptionT',
  remove: 'removeDropdownOptionT',
  delete: 'deleteDropdownOptionT',
  duplicate: 'duplicateDropdownOptionT',
  rename: 'renameDropdownOptionT',
  deletePlaylist: 'deletePlaylistDropdownOptionT',
  unsubscribe: 'unsubscribeDropdownOptionT',
  removeFromChannel: 'removeFromChannelDropdownOptionT',
  summarize: 'summarizeOptionT',
  ai_task: 'aiTaskT',
  ai_send: 'aiProcessingT',
  sendToAIGlobal: 'sendToAIT',
};
export const SIX_DOTS_DD_OPTIONS_UPV = {
  title: 'sixDotsDDTitleT',
  rename: 'editDropdownOptionT',
  duplicate: 'duplicateDropdownOptionT',
  addApprovalButton: 'addApprovalButtonT',
  deleteApproveButton: 'deleteApproveButtonT',
  addCaption: 'addCaptionT',
  deleteCaption: 'removeCaptionT',
  summarize: 'summarizeOptionT',
  aiTask: 'aiTaskT',
  ai_send: 'aiProcessingT',
  sendToAIGlobal: 'sendToAIT',
  delete: 'deleteDropdownOptionT',
};
export const THREE_DOTS_DD_OPTIONS_CONTACTS = {
  title: 'titleDropdownOptionT',
  edit: 'editDropdownOptionT',
  delete: 'deleteDropdownOptionT',
};
