import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { actionSetBlinkId } from '../../../redux/support/action';
import Contacts from '../../../redux/contacts/types';
import { ReactComponent as ThreeDotsSvg } from '../../../images/2023/svg/small/3dots_13_2.svg';
import { ReactComponent as PinSvg } from '../../../images/icons/pin_icon.svg';
import { ReactComponent as UnpinSvg } from '../../../images/icons/unpin_icon.svg';
import ThreeDotsDropDown from './ThreeDotsDropDown';
import Tooltip from '../../../shared/Tooltips/Tooltip';

const SvgIcoWrapper = styled.div`
  visibility: hidden;
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  svg {
    left: 2px;
    position: relative;
  }
  ${({ isFavourite }) => (isFavourite
    ? `
  svg{
   top:2px;
  }
  `
    : '')};

  ${({ isEye }) => (isEye
    ? `
  svg{
   top:3px;
  }
  `
    : '')};

  ${({ is3Dots }) => (is3Dots
    ? `
    svg{
      top: -1px;
    }
  `
    : '')}
  ${({ is3DotsForContacts }) => (is3DotsForContacts
    ? `
    margin-right: 0px;
    svg{
      top: -1px;
    }
  `
    : '')}

  ${({ active, isFavourite }) => (active && isFavourite
    ? `
   visibility: visible;
   background:none;
    & svg path{
        stroke:none;
        fill: rgba(255, 199, 44, 1);
    }
    &:hover {
         background: rgba(52, 52, 52, 1);
       & svg path{
        stroke:#FFC72C;
        fill:rgba(255, 199, 44, 0.6);
         }
    }
  `
    : '')}
  ${({ active, is3Dots, is3DotsForContacts }) => (active && (is3Dots || is3DotsForContacts)
    ? `
      border-radius: 4px;
      background:rgba(255, 199, 44, 1);
       & svg path{
        stroke:#343434;
       }
       &:hover {
         background:rgba(255, 199, 44, 1);
       }
  `
    : '')}
  &:hover {
    background: rgba(255, 199, 44, 0.4);
    border-radius: 4px;
  }
  ${({ isPinForContacts }) => (isPinForContacts ? `
    display: flex;
    align-items:center;
    visibility: visible;
    cursor: pointer;
  ` : `
    & svg path {
      stroke: #484343;
      fill: transparent;
    }
    `)}
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const ActionsForTableC = ({ item, isFavorite, isLib, refMenu, isDropDownVisible, setIsDropDownVisible, ...props }) => {
  const dispatch = useDispatch();
  const toggleShowThreeDots = () => {
    setIsDropDownVisible(!isDropDownVisible);
  };
  const setBlinkId = (id) => {
    if (item.isUnpin) {
      dispatch(actionSetBlinkId([id]));
    }
  };

  const contactId = item.inviteUser?.id || item.user?.id || item.id;
  const unpinHandler = (e) => {
    e.stopPropagation();
    dispatch(
      actionCreator(Contacts.UpdatePinState, {
        id: item.id,
        userId: contactId,
        isUnpin: item.isUnpin,
      }),
    );
    if (item.isUnpin) {
      setBlinkId(item.id);
    }
  };

  return (
    <ActionsWrapper className="actions_fluent">
      <Tooltip
        text={item.isUnpin ? 'Add to menu' : 'Remove from menu'}
        direction="down"
        place="PinIcon"
      >
        <SvgIcoWrapper className="actions_svg_wrapper" isPinForContacts onClick={unpinHandler}>
          {item.isUnpin ? <UnpinSvg /> : <PinSvg />}
        </SvgIcoWrapper>
      </Tooltip>
      <SvgIcoWrapper
        className="actions_svg_wrapper"
        is3DotsForContacts
        onClick={
          item.isSelected
            ? (e) => e.stopPropagation()
            : (e) => toggleShowThreeDots(e)
        }
        active={isDropDownVisible}
      >
        {' '}
        <ThreeDotsSvg />
      </SvgIcoWrapper>
      <ThreeDotsDropDown
        isTableView
        isLib={isLib}
        setIsDropDownVisible={setIsDropDownVisible}
        userRole={props.userRole}
        itemID={item.id}
        memberId={contactId}
        itemType={item.type}
        status={item.status}
        refMenu={refMenu}
        show={isDropDownVisible}
      />
    </ActionsWrapper>
  );
};

export default ActionsForTableC;
