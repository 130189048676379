import React from 'react';
import styled from 'styled-components/macro';
import { emptyCallback } from '../../../utils/constants';

const checkLength = (object) => {
  if (!Object.keys(object).length) return false;
  let state = false;
  Object.keys(object).forEach((key) => {
    if (!object[key].moveToTrash) {
      state = true;
    } else {
      emptyCallback();
    }
  });
  return state;
};

const PlayListCard = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 34px;
`;

const PlaylistTable = styled.div`
  margin-left: 5px;
`;

const TitleStyle = styled.div`
  width: max-content;
  height: 26px;
  margin-bottom: 30px;
  margin-left: 7px;
  font-family: RobotoFlex;
  font-size: 20px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #484343;
  ${({ isFirst }) => (isFirst ? 'top:41px;' : '')}
  ${({ isCardsView }) => (
    isCardsView
      ? ''
      : `
  margin-top:15px;
  margin-left:6px;
  position:absolute;
  `)}
`;

const RecentFilterComponent = ({
  filterTitle,
  playlist,
  isCardView,
  noHeader,
  children,
}) => {
  const PlayListRow = isCardView ? PlayListCard : PlaylistTable;

  if (!checkLength(playlist)) {
    return null;
  }

  return (
    <>
      <TitleStyle isCardsView={isCardView} isFirst={!noHeader}>
        {filterTitle.toUpperCase()}
      </TitleStyle>{' '}
      <PlayListRow>
        {children}
      </PlayListRow>
    </>
  );
};

export default RecentFilterComponent;
