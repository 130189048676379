import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useTranslation } from 'react-i18next';
import Button2023 from '../../Buttons/Button2023';
import { actionCloseOverlayModal } from '../../../redux/user/action';

const ModalOverlayModalWrapper = styled.div`
  position: fixed;
  background: rgba(147, 147, 147, 0.8);
  z-index: 106;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;
  font-style: normal;
  font-weight: 400;
`;

const GreyTitleWrapper = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #939393;
  letter-spacing: 0.05em;
  margin-bottom: 8px;
  text-transform: uppercase;
  ${({ isSecond }) => (isSecond
    ? `
    margin-top: 4px;
    margin-bottom: 13px;
  `
    : '')}
  ${({ isThird }) => (isThird
    ? `
    margin-top: 15px;
    margin-bottom: -2px;
  `
    : '')}
`;

const ResetColorOverlayModalWrapper = styled.div`
  position: relative;
  width: 325px;
  height: 161px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  padding: 12px 16px 16px;
`;

const ButtonBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px;
  padding-left: 140px;
`;

const ItemTitleWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #484343;
  border-radius: 16px;
  padding-right: 10px;
  margin-bottom: 8px;
`;

const ResetColorOverlayModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { overlayDataPayload } = useSelector((state) => state.user);
  const cancel = () => dispatch(actionCloseOverlayModal());
  const reset = () => {
    overlayDataPayload.foo();
    dispatch(actionCloseOverlayModal());
  };
  return (
    <ModalOverlayModalWrapper>
      <ResetColorOverlayModalWrapper>
        <GreyTitleWrapper>{t('deleteUpT')}</GreyTitleWrapper>

        <ItemTitleWrapper>{t('deleteCustomColorsQuestionT')}</ItemTitleWrapper>

        <div>
          {t('deleteCustomColorsMessageT')}
        </div>

        <ButtonBlockWrapper>
          <Button2023
            variant="primary"
            width={69}
            height={32}
            text={t('cancelUpT')}
            noMargins
            handleButtonClick={cancel}
          />
          <Button2023
            variant="secondary"
            width={66}
            height={32}
            text={t('deleteUpT')}
            noMargins
            handleButtonClick={reset}
          />
        </ButtonBlockWrapper>
      </ResetColorOverlayModalWrapper>
    </ModalOverlayModalWrapper>
  );
};

export default ResetColorOverlayModal;
