import { EditPage } from '../../../redux/pages/types';
import EditPlaylist from '../../../redux/playlists/types';

export const calcUserState = (
  contacts = {},
  usersEmail = [],
  inviteStatus = {},
) => {
  const contactToInvite = [];
  usersEmail.forEach((item) => {
    const userEmailData = Object.values(contacts || {}).find(
      (i) => i.email === item.email,
    );
    if (userEmailData) {
      contactToInvite.push({
        ...userEmailData,
        ...item,
        type: 'user',
        send: inviteStatus[item.email] ? inviteStatus[item.email] : item.send,
      });
    } else {
      contactToInvite.push({
        ...item,
        name: item.email,
        type: 'user',
        send: inviteStatus[item.email] ? inviteStatus[item.email] : item.send,
      });
    }
  });
  return contactToInvite;
};
export const updateState = (emailsAndUsersToInvite, inviteStatus = {}) => {
  return emailsAndUsersToInvite.map((i) => ({
    ...i,
    send: inviteStatus[i.email] ? inviteStatus[i.email] : i.send,
  }));
};

export const ActionTypeAddUserToSharing = {
  page: EditPage.AddUserToSharing,
  playlist: EditPlaylist.AddUserToSharing,
};
export const ActionTypeRemoveUserToSharing = {
  page: EditPage.RemoveUserToSharing,
  playlist: EditPlaylist.RemoveUserToSharing,
};
export const ActionTypeSendUserToSharing = {
  page: EditPage.SendEmailToSharingS,
  playlist: EditPlaylist.SendEmailToSharingS,
};


export const shareType = {
  SHARE_TO_WEB: 'SHARE_TO_WEB',
  SHARE_TO_USER: 'SHARE_TO_USER',
  SHARE_TO_CHANNEL: 'SHARE_TO_CHANNEL',
};

export const checkShareType = {
  isShareToWeb: {
    [shareType.SHARE_TO_WEB]: true,
  },
  isShareToUser: {
    [shareType.SHARE_TO_USER]: true,
  },
  isShareToChannel: {
    [shareType.SHARE_TO_CHANNEL]: true,
  },
};
