export const Hashtag = {
  CreateHashtag: '[Hashtag] Create Hashtag',
  DeleteHashtag: '[Hashtag] Delete Hashtag',
  AddHashtagPlaylist: '[Hashtag] Add Hashtag To Playlist',
  RemoveHashtagPlaylist: '[Hashtag] Remove Hashtag To Playlist',
  RemoveHashtagPage: '[Hashtag] Remove Hashtag To Page',
  // GetPlaylistHashtags: '[Hashtag] Get Playlist Hashtags',
  AddHashtagsToState: '[Hashtag] Add Hashtags To State',
  ClearPlaylistHashtags: '[Hashtag] Clear Playlist Hashtags',
  CheckHashtag: '[Hashtag] Check Hashtag',
};
