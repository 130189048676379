import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import Playlist from '../../../../redux/playlists/types';
import {
  CurrentPage,
  PAGE_IS_DOWNLOAD,
} from '../../../../redux/currentPage/types';

export const useLoadPlaylistData = (owerwrittenType) => {
  const { id, type = 'shared', channelId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const passedType = owerwrittenType ?? type;

  useEffect(() => {
    if (owerwrittenType === 'justCreatedEdit') {
      return () => {
        // isGoOutside - fixes the case when the dev mode is started again
        const isGoOutside = !history.location.pathname.startsWith('/maker/');
        if (isGoOutside) dispatch(actionCreator(CurrentPage.ClearCurrentPage));
      };
    }
    if (!id) throw new Error(t('needIDOfAPlaylistErrorT'));
    if (type !== 'edit' && type !== 'publish' && type !== 'shared') {
      throw new Error(t('typeOfAPlaylistErrorT'));
    }
    dispatch(actionCreator(PAGE_IS_DOWNLOAD, { isDownload: true }));
    dispatch(
      actionCreator(Playlist.GetUniversalViewData, {
        id,
        type: passedType,
        setIsLoaded,
        channelID: channelId,
        history,
      }),
    );
    return () => {
      dispatch(actionCreator(CurrentPage.ClearCurrentPage));
    };
  }, [history.location.pathname]);

  return isLoaded;
};
