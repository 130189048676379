import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import Button2023 from '../Buttons/Button2023';
import { actionAIToggleVectorizeLinkPageRS } from '../../redux/currentPage/action';

const cx = classNames.bind(styles);

const AICancelVectorizeConfirmModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { dataPayload } = useSelector((state) => state.user);
  const close = () => {
    dispatch(actionCloseModal());
  };
  const onOkClick = () => {
    dispatch(actionAIToggleVectorizeLinkPageRS([dataPayload.linkPageId], false));
    dataPayload.onOk();
    close();
  };
  return (
    <div onMouseDown={close} className={cx('modal_wrapper')}>
      <div
        onMouseDown={(e) => e.stopPropagation()}
        className={cx('modal', 'Summarize')}
      >
        <h3 className={cx('title')}>{t('actionConfirmationT')}</h3>
        {t('removeFromKnoledgeBaseT')}
        <div className={cx('buttons_group')}>
          <Button2023
            variant="gradientGreenSquare"
            width={90}
            height={32}
            text={t('cancelT')}
            noMargins
            handleButtonClick={close}
          />
          <Button2023
            variant="gradientGreenSquare"
            width={69}
            height={32}
            text="OK"
            noMargins
            handleButtonClick={onOkClick}
          />
        </div>
      </div>
    </div>
  );
};

export default AICancelVectorizeConfirmModal;
