import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionAddNewPathToUnifiedHistory,
  actionSyntheticGoBack,
  actionSyntheticGoForward,
} from '../../redux/history/actions';

const HighLevelHistoryListener = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const historyTrace = useSelector((state) => state.historyTrace);

  useEffect(() => {
    const path = history.location.pathname;
    if (
      history.location.state
      && history.location.state.mode
      && history.location.state.path
    ) {
      const mode = history.location.state.mode;
      // case synthetic goBack

      const matchPath = path === history.location.state
        || path === history.location.state.path.split('?')[0];

      if (mode === 'synthetic-' && matchPath) {
        dispatch(actionSyntheticGoBack());
        return;
      }

      // case synthetic go forward
      if (mode === 'synthetic+' && path === history.location.state.path) {
        dispatch(actionSyntheticGoForward());
        return;
      }
    }

    if (
      (history.location?.state?.isNewPlaylist && !history.location?.state?.isChannel)
      || (historyTrace.somePayload?.isNewPlaylist && !historyTrace.somePayload?.isChannel)
    ) {
      dispatch(actionAddNewPathToUnifiedHistory('/content/smartfiles/drafts'));
    }

    // case player
    // const historyTraceLastIndex = historyTrace.trace.length && historyTrace.trace.length - 1;
    const isPlayer = historyTrace.trace[historyTrace.activeIndex]?.startsWith('/player/');
    const currentPathIsPlayer = history.location.pathname?.startsWith('/player/');

    if (isPlayer && currentPathIsPlayer) return;
    // case native

    dispatch(actionAddNewPathToUnifiedHistory(history.location.pathname));
  }, [history.location.pathname]);

  return <div />;
};

export default HighLevelHistoryListener;
