import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { sanitizeHtml, sanitizeToLoad } from '../../../utils/helpers';
import { APPURL } from '../../../environments/environments';
import { ReactComponent as Page32Svg } from '../../../images/icons/page_32.svg';
import { DEFAULT_TITLE } from '../../../utils/constants';

const cn = classNames.bind(styles);

const HeightBalanser = styled.div`
  height: 400px;
  ${({ height }) => (height
    ? `height: ${height}px;
  `
    : '')};
`;
const getUrl = (text) => {
  if (!text) return null;
  const cleanText = sanitizeToLoad(text).replaceAll('\\"', "'");
  const url = cleanText?.split("'")[1];
  const id = url?.split('/')[url?.split('/').length - 1];
  return id || null;
};

const EmbedBlock = ({ state, innerHtml, saveItem, isView }) => {
  const { components } = useSelector((redux) => redux.library);
  const history = useHistory();
  const { t } = useTranslation();
  const [element, setElement] = useState(null);
  const [height, setHeight] = useState(null);
  const iframeRef = useRef(null);
  const pageId = getUrl(state?.data?.text);
  useEffect(() => {
    if (state?.data?.text) {
      const iframeString = state?.data?.text;
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = iframeString;
      let validHeight;
      if (tempDiv.firstChild?.getAttribute) {
        const heightValue = tempDiv.firstChild?.getAttribute('height') || 400;
        const heightNumber = parseInt(heightValue, 10);
        tempDiv.remove();

        validHeight = heightNumber > 400 ? 400 : heightNumber;
        setHeight(validHeight);
      }
      const node = (
        <HeightBalanser
          height={validHeight}
          className={cn('embedPreview')}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(state?.data?.text) }}
        />
      );
      setElement(node);
    }
  }, [state?.data]);
  useEffect(() => {
    if (iframeRef?.current && !isView && saveItem) {
      const html = iframeRef?.current?.innerHTML;
      if (innerHtml !== html) saveItem(state, html);
    }
  }, [element, height]);

  const goToPublish = () => {
    history.push(`/publish_page/${pageId}`);
  };

  if (state?.data?.text?.includes(APPURL)) {
    return (
      <div onClick={goToPublish} className={cn('page_link_wrapper')}>
        <div className={cn('page_link_container')}>
          <Page32Svg className={cn('icon')} />
          <div className={cn('page_name')}>
            {components[pageId]?.title || t('defaultSmartPageTitleT')}
          </div>
          <div className={cn('hint')}>{t('quidziAppEmbedT')}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      {!!state?.data?.isPreview && (
        <HeightBalanser height={height} ref={iframeRef} className={cn('EmbedBlock')}>
          {element}
        </HeightBalanser>
      )}
    </>
  );
};

export default EmbedBlock;
