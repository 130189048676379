import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { calcNewState, calcSaveStateForShared } from './helpers';
import { actionSaveShareStateLibraryPage } from '../../redux/library/actions';
import PlaylistsDatePicker2023 from './PlaylistsDatePicker2023';
import { DATA_PARENTS } from '../../utils/constants';

export const PageSharedDatePickerDataLayer = ({
  dataParent = `miniPage ${DATA_PARENTS.PlaylistBuilderLibrary}`,
  setSelectedOption,
  selectedOption,
  isModify,
  setLocalState,
}) => {
  const {
    shareState: {
      availableFrom,
      availableTo,
    },
  } = useSelector((state) => state.currentPage);

  const dispatch = useDispatch();

  const [selectedDateFrom, setSelectedDateFrom] = useState(
    calcNewState(availableFrom),
  );
  const [selectedDateTo, setSelectedDateTo] = useState(
    calcNewState(availableTo),
  );

  const saveState = () => {
    const state = calcSaveStateForShared(
      selectedDateFrom,
      selectedDateTo,
      selectedOption,
    );
    if (isModify) {
      setLocalState(state);
      return;
    }
    dispatch(
      actionSaveShareStateLibraryPage(state),
    );
  };

  return (
    <PlaylistsDatePicker2023
      dataParent={dataParent}
      saveState={saveState}
      selectedDateFrom={selectedDateFrom}
      setSelectedDateFrom={setSelectedDateFrom}
      selectedDateTo={selectedDateTo}
      setSelectedDateTo={setSelectedDateTo}
      showHeader
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      isForShared
    />
  );
};
