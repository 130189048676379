export const InboxAction = {
  DeleteInboxCard: '[InboxAction] Delete Inbox Card',
  DeleteInboxCardR: '[InboxAction] Delete Inbox Card Redux',
  DeleteInboxCards: '[InboxAction] Delete Inbox Cards',
  UploadInboxCardSaga: '[InboxAction] Upload Inbox Card Saga',
  Clear: '[InboxAction] Clear Inbox Card Saga',
  UploadInboxCardRedux: '[InboxAction] Upload Inbox Card Redux',
  UpdatePageInInboxCardRedux: '[InboxAction] Update page Inbox Card Redux',
  FilterInboxCards: '[InboxAction] Filter Inbox Cards',
  CardInBoxMarkUsReed: '[InboxAction] Mark card as reed',
  CardsInboxMarkAsReadOrUnread: '[InboxAction] Mark cards as read/unread',
  AddInBoxCardSaga: '[InboxAction] Add inbox card Saga',
  AddInBoxCardRedux: '[InboxAction] Add inbox card Redux',
  UpdateCounter: '[InboxAction] Update counter',
  UploadPage: '[InboxAction] Upload page',
  AddSingleWithoutUpdateInBoxCardRedux: '[InboxAction] Add single inbox card Redux without update',
  AddNewAwaitedNotification: '[InboxAction] Add new updated notification',
  CheckCurrentPageAddNewAwaitedNotification: '[InboxAction] Check Current Page and go to Add new updated notification',
  ClearAwaitedNotification: '[InboxAction] Clear notification',
  UpdateNotificationNotificationAfterUpdate: '[InboxAction] Update Notification Notification After Update',
};
