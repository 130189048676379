import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImageAnchor, PBProgressStripe } from './styled';
import { traceStackPush } from '../../utils/helpers';
import { DisplayPreview } from '../Player/DisplayPreview';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { chunk } from './helpers';

const DynamicProgressStripes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentChunk, setCurrentChunk] = useState({});
  const [itemNum, seItemNum] = useState({});

  const isForSharedToWeb = history.location.pathname.includes('shared_player');

  const { id: playlistId, itemNumber, channelId } = useParams();

  const { linkPages = [] } = useSelector((state) => state.currentPage);
  const itemsLength = linkPages.length;


  const goToItem = (index) => {
    traceStackPush(
      isForSharedToWeb
        ? `/shared_player/shared/${playlistId}/${index + 1}`
        : `/player/${channelId}/${playlistId}/${index + 1}`,
      history,
      { type: 'page' },
      dispatch,
    );
  };

  useEffect(() => {
    const currentItemNumber = parseInt(itemNumber);
    seItemNum(currentItemNumber);
    const flor = Math.floor(currentItemNumber / 44);
    const start = flor * 44;
    const end = (flor + 1) * 44 > itemsLength ? itemsLength : (flor + 1) * 44;

    setCurrentChunk({
      sliceStart: start,
      sliceEnd: end,
    });
  }, [itemNumber]);

  if (!Object.keys(currentChunk).length) {
    return null;
  }

  return (
    <>
      {linkPages.slice(currentChunk.sliceStart, currentChunk.sliceEnd).map((linkPage, index) => (
        <PBProgressStripe width={60} key={linkPage.id} filled={currentChunk.sliceStart + index < itemNum} onClick={() => goToItem(currentChunk.sliceStart + index)}>
          <ImageAnchor className="player_see_preview">
            <DisplayPreview libraryComponent={linkPages[currentChunk.sliceStart + index]?.libraryComponent} linkPage={linkPages[currentChunk.sliceStart + index]} isPlayerPreview />
          </ImageAnchor>
        </PBProgressStripe>
      ))}
    </>
  );
};

export default DynamicProgressStripes;
