import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import ActionCustomEvent from '../../redux/customEvent/action';

import rawStyles from '../../pages/Maker/MakerCardView/index.module.scss';
import rawStylesLibrary from '../../pages/Library/Library.module.scss';

interface IBoxParams {
  left: number,
  top: number,
  width: number,
  height: number,
}

interface IPoint {
  x: number,
  y: number,
}

export default function useSelectAreaItems(
  selectedItems: { [key: string]: boolean },
  actionRemoveAllSelected: () => void,
  areaRef: MutableRefObject<HTMLElement | null>,
  actionSelectItem: (itemId: string, extraParam?: boolean) => void,
  selectorType?: string,
  isSimplifiedMultiselect?: boolean,
  setSimplifiedMultiselect?: (key: boolean) => void,
  actionRemoveSelectedPage?: (key: string) => void,
): [(e: React.MouseEvent<HTMLDivElement>) => void, (e: React.MouseEvent | MouseEvent) => void] {
  const dispatch = useDispatch();

  const mouseDown = useRef<boolean>(false);
  const stateStartPoint = useRef<IPoint | null>(null);
  const boxEl = useRef<HTMLElement | null>(null);
  const [stateEndPoint, setEndPoint] = useState<IPoint>({ x: 0, y: 0 });
  const [selectionBox, setSelectionBox] = useState<IBoxParams | null>(null);

  const setMouseDown = (val: boolean) => {
    mouseDown.current = val;
  };
  const setStateStartPoint = (val: IPoint) => {
    stateStartPoint.current = val;
  };

  const intersect = (boxA: IBoxParams, boxB: IBoxParams) => {
    return (
      boxA.left <= boxB.left + boxB.width
      && boxA.left + boxA.width >= boxB.left
      && boxA.top <= boxB.top + boxB.height
      && boxA.top + boxA.height >= boxB.top
    );
  };
  const calculateSelectionBox = (startPoint: IPoint, endPoint: IPoint) => {
    if (!mouseDown?.current || !endPoint || !startPoint) {
      return null;
    }
    const left = Math.min(startPoint.x, endPoint.x);
    const top = Math.min(startPoint.y, endPoint.y);
    const width = Math.abs(startPoint.x - endPoint.x);
    const height = Math.abs(startPoint.y - endPoint.y);

    if (height < 3 || width < 3) {
      return null;
    }

    return {
      left,
      top,
      width,
      height,
    };
  };

  const onMouseMove = (e: React.MouseEvent | MouseEvent) => {
    if (selectorType === 'board') return;
    if (mouseDown?.current) {
      const selection = document.getSelection();
      selection?.empty();
      e.preventDefault();
      const endPoint = {
        x: e.pageX,
        y: e.pageY,
      };
      setEndPoint(endPoint);
      if (stateStartPoint.current) {
        const box = calculateSelectionBox(stateStartPoint.current, endPoint);
        setSelectionBox(box);
        if (!!box && !isSimplifiedMultiselect && setSimplifiedMultiselect) {
          setSimplifiedMultiselect(true);
        }
      }
    }
  };
  useEffect(() => {
    const el = document.createElement('div');
    el.classList.add(selectorType
      ? rawStylesLibrary.selection_border
      : rawStyles.selection_border,
    );
    el.onmousemove = onMouseMove;
    document.body.append(el);
    boxEl.current = el;
    return () => {
      document.body.removeChild(el);
    }
  }, []);

  useEffect(() => {
    if (boxEl.current) {
      if (selectionBox) {
        Object.assign(boxEl.current.style, {
          left: `${selectionBox.left}px`,
          top: `${selectionBox.top}px`,
          width: `${selectionBox.width}px`,
          height: `${selectionBox.height}px`,
        });
      } else {
        Object.assign(boxEl.current.style, {
          left: '0px',
          top: '0px',
          width: '0px',
          height: '0px',
        });
      }
    }
  }, [selectionBox]);

  const mouseup = (e: React.MouseEvent | MouseEvent) => {
    e.stopPropagation();

    document.removeEventListener('mouseup', mouseup, true);
    setMouseDown(false);
    setStateStartPoint({ x: 0, y: 0 });
    setSelectionBox(null);
  };

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const undefinedFoo = () => {
      try {
        if (
          (e.target as HTMLDivElement)?.className
          && (e.target as HTMLDivElement).className?.includes('TableResize')
        ) {
          return true;
        }
      } catch (e) {
        return false;
      }
    };

    if (undefinedFoo()) {
      return;
    }

    if (selectorType === 'board') return;
    if (
      !(e.button === 2 || e.nativeEvent.which === 2)
      && !(
        (e.target as HTMLDivElement)?.dataset?.parent?.includes('miniPage')
        || (e.target as HTMLDivElement)?.parentElement?.dataset?.parent?.includes('miniPage')
      )
    ) {
      if (selectorType) e.preventDefault();
      if (Object.keys(selectedItems).length) {
        dispatch(actionRemoveAllSelected());
      }
      document.addEventListener('mouseup', mouseup, true);
      setMouseDown(true);
      setStateStartPoint({
        x: e.pageX,
        y: e.pageY,
      });
      if (stateEndPoint.x) {
        setEndPoint({ x: 0, y: 0 });
      }
      if (selectionBox) {
        setSelectionBox(null);
      }
      if (isSimplifiedMultiselect && setSimplifiedMultiselect) {
        setSimplifiedMultiselect(false);
      }
    }
  };


  useEffect(() => {
    if (selectionBox && selectionBox.left && areaRef && areaRef.current) {
      const items = areaRef.current.querySelectorAll('[data-intersect]');

      items.forEach((item) => {
        if (item) {
          const itemId = item.getAttribute('data-intersect');
          if (intersect(selectionBox, item.getBoundingClientRect())) {
            if (itemId && !selectedItems[itemId]) {
              if (selectorType) {
                dispatch(actionSelectItem(itemId));
              } else {
                dispatch(actionSelectItem(itemId, true));
              }
            }
          } else if (itemId && selectedItems[itemId]) {
            if (actionRemoveSelectedPage) {
              dispatch(actionRemoveSelectedPage(itemId));
            } else {
              dispatch(actionSelectItem(itemId));
            }
          }
        }
      });
    }
  }, [selectionBox, dispatch, selectedItems]);

  useEffect(() => {
    return () => {
      dispatch(ActionCustomEvent.Clear());
      document.removeEventListener('mouseup', mouseup, true);
    };
  }, []);

  return [onMouseDown, onMouseMove];
}
