import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import searchIcon from '../../images/icons/search.svg';
import { empty } from '../../utils/constants';
import downloadStatus from '../../utils/dataUtil';
import ItemInTrash from './ItemInTrash';

const TrashOption = ({ parentRef }) => {
  const { loadStatus, deletedItems } = useSelector((state) => state.deleted) || empty;

  const [search, setSearch] = useState('');
  const [result, setResult] = useState(<></>);
  const [customStyle, setCustomStyle] = useState({
    top: 91,
  });

  const trashOptionRef = useRef(null);
  const { t } = useTranslation();

  const calculatePosition = () => {
    if (parentRef.current) {
      const positionY = parentRef.current.getBoundingClientRect().y;
      const heightElement = trashOptionRef?.current?.getBoundingClientRect().height || 91;
      return positionY - heightElement;
    }
    return 0;
  };

  useEffect(() => {
    setCustomStyle({
      top: calculatePosition(),
    });
  }, [result]);

  useEffect(() => {
    let deleted;
    if (search) {
      deleted = deletedItems.filter((item) => {
        const title = item.title || t('unnamedComponentT');
        return title.toLocaleLowerCase().includes(search.toLocaleLowerCase());
      });
    } else deleted = deletedItems;
    if (deletedItems.length === 0) {
      setResult(<div className={styles.no_found}>{t('trashIsemptyT')}</div>);
    } else if (deleted.length === 0) {
      setResult(<div className={styles.no_found}>{t('notFoundT')}</div>);
    } else if (deleted.length < 6) {
      setResult(
        <>
          {deleted.map((res) => (
            <ItemInTrash key={res.id} item={res} />
          ))}
          <div className={styles.end} />
        </>,
      );
    } else {
      setResult(
        <>
          <div className={styles.scroll_wrapper}>
            <PerfectScrollbar>
              {deleted.map((res) => (
                <ItemInTrash key={res.id} item={res} />
              ))}
              <div className={styles.end} />
            </PerfectScrollbar>
          </div>
        </>,
      );
    }
  }, [search, deletedItems]);

  return (
    <div
      data-cy="trashMenu"
      ref={trashOptionRef}
      style={customStyle}
      className={styles.trashMenu}
    >
      {loadStatus === downloadStatus.success && (
        <>
          <div
            className={`${styles.editMenu__input} ${styles.editMenu__item_first}`}
          >
            <div className={styles.search_wrapper_input}>
              <img
                src={searchIcon}
                alt={t('searchAltT')}
                className={styles.search_input_search_icon}
              />
              <input
                placeholder={t('searchInTrashT')}
                value={search}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => setSearch(e.target.value)}
                className={`${styles.search_input}`}
              />
            </div>
          </div>

          {result}
        </>
      )}
      {loadStatus === downloadStatus.pending && (
        <>
          <div className={styles.loader_wrapper_trash}>
            <div className="loader_trash">{t('loadingT')}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default TrashOption;
