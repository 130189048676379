import React, { useRef } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PageSvg } from '../../images/icons/page_72.svg';
import noPageFound, {
  ReactComponent as NotFoundPageSvg,
} from '../../images/icons/no_page_found.svg';
import { ReactComponent as NotFoundUserSvg } from '../../images/icons/user_search_70.svg';
import { ReactComponent as PlaylistIconSvg } from '../../images/icons/replace_icons/Playlist.svg';
import { ReactComponent as Slides72Svg } from '../../images/icons/slides_72.svg';
import { ReactComponent as NoPlaylist72Svg } from '../../images/icons/no_search_playlist_72.svg';
import { ReactComponent as Clocks18Svg } from '../../images/icons/clocks_18.svg';
import { ReactComponent as NoRecentPlaylist72Svg } from '../../images/icons/clock_24.svg';
import { ReactComponent as PlusSvg } from '../../images/icons/add_component.svg';
import { ReactComponent as StarSvg } from '../../images/icons/favorites20.svg';
import { ReactComponent as ShareSvg } from '../../images/icons/paperclip_link_20.svg';
import { ReactComponent as Team72Svg } from '../../images/2023/svg/contacts/empty_contacts_paceholder_80_80.svg';
import { ReactComponent as UploadSvg } from '../../images/icons/upload_20.svg';
import manyPagesStyles from '../../pages/ManyPages/index.module.css';
import libraryStyles from '../../pages/Library/Library.module.scss';
import playlistContentStyles from '../PlaylistContentSelector/index.module.css';

import styles from '../ContentSelector/index.module.css';
import recentEmpty from '../../images/icons/recent_empty.svg';
import publishEmpty from '../../images/icons/no_playlist_publish.svg';
import draftEmpty from '../../images/icons/no_playlist_draft.svg';
import foundEmpty from '../../images/icons/no_playlist_found.svg';
import noFilesFound from '../../images/icons/no-files-found_64.svg';
import noFiles from '../../images/icons/no_files_64.svg';
import AddFilePopUp from '../LibraryHeaderMenu/childComponents/AddFilePopUp';
import emptyFavoriteCollections from '../../images/icons/empty_favorite_collection_64.svg';
import { maxFileSize } from '../../GLOBAL_CONST';
import { actionCreateUpload } from '../../redux/filesUpload/action';
import {
  LibCompSieveTypes,
  LibCompSieveTypesSideBar,
} from '../../utils/constants';

const NoItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #242633;
  flex-direction: column;
  height: calc(100vh - 260px);
  ${({ isUser }) => (isUser ? 'margin-left: 65px;' : '')}

  ${({ isContacts, isMembers }) => (isContacts || isMembers ? 'padding-left: 40px;' : '')}
`;
const Text = styled.div`
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 8px;
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #484343;
`;
const TextSub = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #939393;
`;

const EmptyStateSelector = ({
  isChannel,
  isFilter,
  sortedItems,
  loading,
  ...props
}) => {
  const uploads = useSelector((state) => state.uploads.components);
  const isCardView = useSelector((state) => state.currentPage.isCardView);
  const { folderId, tags, selectorType } = useSelector((state) => state.user);
  const { activeSieveOption } = useSelector((state) => state.library);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const isPage = isChannel === 'pages';
  const isUser = isChannel === 'users';
  const isPlaylist = isChannel === 'smartfiles';
  const isRecent = props.selectorType === 'recent';
  const isFavorites = props.selectorType === 'favorites';
  const isDrafts = props.selectorType === 'drafts';
  const isPublished = props.selectorType === 'published';
  const isUsed = activeSieveOption === LibCompSieveTypesSideBar.Usage;
  const isShowNewItem = !(
    isRecent
    || isFavorites
    || isDrafts
    || isPublished
    || loading
    || !!props.filter
    || props.isChannel
  );
  const isNotEmptyFolder = isFilter && !sortedItems?.length;
  const isNoRecentItemsExist = props.selectorType === 'recent' && !sortedItems.length;
  const isNoSharedPages = props.selectorType === 'shared' && !sortedItems.length;
  const isNoFavoriteItemsExist = props.selectorType === 'favorites'
    && props.totalFavorites === 0
    && !isFilter;
  const isNoSearchResults = props.selectorType === 'favorites'
    ? !sortedItems?.length && !isNoFavoriteItemsExist
    : !sortedItems?.length;
  const isNotEmptyFilter = isFilter && !sortedItems?.length;
  const isNoPublishPlaylistsExist = props.selectorType === 'drafts' && !sortedItems.length;
  const isNoDraftPlaylistsExist = props.selectorType === 'published' && !sortedItems.length;

  const inputFile = useRef(null);

  const onButtonClick = (e) => {
    e.stopPropagation();
    inputFile?.current.click();
  };

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const sortedUploads = Object.values(uploads || {}).sort((a, b) => {
      if (a.errorMsg) return -1;
      if (b.errorMsg) return 1;
      return b.position - a.position;
    });

    [...e.target.files].forEach((item, index) => {
      let uploadObject;
      const newId = uuidv4();
      if (item.size > maxFileSize.bytes) {
        uploadObject = {
          newId,
          file: item,
          title: item.name,
          position: sortedUploads.length
            ? sortedUploads[0]?.position + 1
            : index + 1,
          type: item.type || 'unknown_type',
          errorMsg: `${t('fileSizeIsMoreThenT')} ${maxFileSize.text}`,
          history,
          isFavorite: props.selectorType === 'favorites',
        };
      } else {
        uploadObject = {
          newId,
          file: item,
          title: item.name,
          position: sortedUploads[0]?.position + 1,
          type: item.type || 'unknown_type',
          history,
          isFavorite: props.selectorType === 'favorites',
        };
      }

      if (props.selectorType === 'tag') uploadObject.tags = [{ ...tags[folderId], type: 'tags' }];

      dispatch(actionCreateUpload(uploadObject));
    });
  };
  // ====
  if (loading) return <div />;
  if (props.path === 'smartfiles') {
    if (!loading && isNoRecentItemsExist && !props.isChannel) {
      return (
        <div className={playlistContentStyles.empty}>
          <NoRecentPlaylist72Svg className={playlistContentStyles.clockIcon} />
          <div className={playlistContentStyles.emptyRecent_mainText}>
            {t('noRecentSmartFilesT')}
          </div>
          <div className={playlistContentStyles.emptyRecent_infoText}>
            {t('noRecentMsgT')}
          </div>
        </div>
      );
    }
    if (!loading && isNoDraftPlaylistsExist && !props.isChannel) {
      return (
        <div className={playlistContentStyles.empty}>
          <img src={publishEmpty} alt="" />
          <div className={playlistContentStyles.emptyRecent_mainText}>
            {t('noSharedSmartFilesT')}
          </div>
          <div className={playlistContentStyles.emptyRecent_infoText}>
            {t('noPublishMsgT')}
          </div>
        </div>
      );
    }
    if (!loading && isNoPublishPlaylistsExist && !props.isChannel) {
      return (
        <div className={playlistContentStyles.empty}>
          <img src={draftEmpty} alt="" />
          <div className={playlistContentStyles.emptyRecent_mainText}>
            {t('nodraftSmartFilesT')}
          </div>
          <div className={playlistContentStyles.emptyRecent_infoText}>
            {t('noDraftMsgT')}
          </div>
        </div>
      );
    }
    if (!loading && isNoFavoriteItemsExist && !props.isChannel) {
      return (
        <div className={playlistContentStyles.empty}>
          <StarSvg className={playlistContentStyles.clockIcon} />
          <div className={manyPagesStyles.emptyFavorite_mainText}>
            {t('noFavoriteSmartFilesT')}
          </div>
          <div className={manyPagesStyles.emptyFavorite_infoText}>
            {t('noFavorMsgT')}
          </div>
        </div>
      );
    }
    if (!loading && isNoSharedPages && !props.isChannel) {
      return (
        <div className={playlistContentStyles.empty}>
          <ShareSvg className={playlistContentStyles.clockIcon} />
          <div className={manyPagesStyles.emptyFavorite_mainText}>
            {t('noSharedSmartFilesT')}
          </div>
          <div className={manyPagesStyles.emptyFavorite_infoText}>
            {t('noSharedMsgT')}
          </div>
        </div>
      );
    }
    if (!loading && isNotEmptyFilter && isNoSearchResults) {
      return (
        <div className={playlistContentStyles.empty}>
          <img src={foundEmpty} alt="" />
          <div className={manyPagesStyles.emptyFavorite_mainText}>
            {t('noSmartFilesFoundT')}
          </div>
          <div className={manyPagesStyles.emptyFavorite_infoText}>
            {t('noFoundMsgT')}
          </div>
        </div>
      );
    }
    if (!sortedItems.length && selectorType === 'smartView') {
      return (
        <div className={playlistContentStyles.emptySmartView}>
          <img src={foundEmpty} alt="" />
          <div className={manyPagesStyles.emptyFavorite_mainText}>
            {t('noSmartFilesFoundT')}
          </div>
          <div className={manyPagesStyles.emptyFavorite_infoText}>
            {t('noSmartMsgT')}
          </div>
        </div>
      );
    }
    if (!sortedItems.length && selectorType !== 'smartView') {
      return (
        <div
          onClick={props.createPage}
          className={`${manyPagesStyles.create_new_playlist} 
                  ${!isCardView && manyPagesStyles.create_new_page_list}`}
        >
          <PlaylistIconSvg
            className={`${manyPagesStyles.icon_page_40} ${manyPagesStyles.new_playlist_icon}`}
          />
          <div className={manyPagesStyles.add_new_page}>
            <PlusSvg className={`${manyPagesStyles.icon_page_20}`} />
            <div className={manyPagesStyles.new_page_text}>{t('smartFileUpT')}</div>
          </div>
        </div>
      );
    }
    return <></>;
  }
  if (props.path === 'pages') {
    if (!loading && isNoRecentItemsExist) {
      return (
        <div className={styles.emptyRecent}>
          <img src={recentEmpty} alt="" />
          <div className={styles.emptyRecent_mainText}>{t('noRecentPagesT')}</div>
          <div className={styles.emptyRecent_infoText}>
            {t('recentlyOpenedOrEditedPagesMessageT')}
          </div>
        </div>
      );
    }
    if (!loading && isNoSharedPages) {
      return (
        <div className={styles.emptyRecent}>
          <PageSvg />
          <div className={styles.emptyRecent_mainText}>{t('noSharedPagesT')}</div>
          <div className={styles.emptyRecent_infoText}>
            {t('findSharedPagesHereMessageT')}
          </div>
        </div>
      );
    }
    if (!loading && isNoFavoriteItemsExist) {
      return (
        <div className={manyPagesStyles.emptyFavorite}>
          <div className={manyPagesStyles.emptyFavorite_icon_container}>
            <PageSvg />
            <StarSvg className={manyPagesStyles.star} />
          </div>
          <div className={manyPagesStyles.emptyFavorite_mainText}>
            {t('noFavoritePagesT')}
          </div>
          <div className={manyPagesStyles.emptyFavorite_infoText}>
            {t('addStarToPagesMessageT')}
          </div>
        </div>
      );
    }
    if (!loading && !sortedItems?.length && isNotEmptyFolder) {
      return (
        <div className={manyPagesStyles.noResults}>
          <img src={noPageFound} alt="" />
          <div className={manyPagesStyles.emptyFavorite_mainText}>
            {t('noPagesFoundT')}
          </div>
          <div className={manyPagesStyles.emptyFavorite_infoText}>
            {t('unselectFiltersMessageT')}
          </div>
        </div>
      );
    }
    if (
      !loading
      && !sortedItems?.length
      && props.selectorType === 'smartView'
    ) {
      return (
        <div
          className={`${libraryStyles.emptyNoResults} 
          ${props.isSideBar ? libraryStyles.isSideBar : ''} 
          ${libraryStyles.collections}
        `}
        >
          <PageSvg />
          <div
            className={`${libraryStyles.emptyNoResults_mainText} ${libraryStyles.collections}`}
          >
            {t('noPagesT')}
          </div>
          <div className={libraryStyles.emptyNoResults_infoText}>
            {t('noPagesInSmartViewT')}
          </div>
        </div>
      );
    }

    if (isNoSharedPages) {
      return (
        <div
          className={`${libraryStyles.emptyNoResults} 
          ${props.isSideBar ? libraryStyles.isSideBar : ''} 
          ${libraryStyles.collections}
        `}
        >
          <PageSvg />
          <div
            className={`${libraryStyles.emptyNoResults_mainText} ${libraryStyles.collections}`}
          >
            {t('noPagesT')}
          </div>
          <div className={libraryStyles.emptyNoResults_infoText}>
            {t('thereAreNoSharedPagesT')}
          </div>
        </div>
      );
    }

    return <></>;
  }
  if (props.path === 'channels') {
    if (loading && !isFilter) return <></>;
    if (isPage && !sortedItems.length && isFilter) {
      return (
        <NoItemsContainer>
          <NotFoundPageSvg />
          <Text>{t('noPagesFoundT')}</Text>
          <TextSub>{t('unselectFiltersMessageT')}</TextSub>
        </NoItemsContainer>
      );
    }
    if (isPage && !sortedItems.length && !isFilter) {
      return (
        <NoItemsContainer>
          <PageSvg />
          <Text>{t('youDontHavePagesInChannelMessageT')}</Text>
          <TextSub>
            {t('sharePageIntoThisChannelFirstT')}{' '}
          </TextSub>
        </NoItemsContainer>
      );
    }
    if (isUser && !sortedItems?.length && isFilter) {
      return (
        <NoItemsContainer isMembers>
          <NotFoundUserSvg />
          <Text>{t('noMembersFoundUpT')}</Text>
          <TextSub>
            {t('doubleCheckSearchOrDifferentTermT')}
          </TextSub>
        </NoItemsContainer>
      );
    }
    if (isUser && !sortedItems.length && !isFilter) {
      return (
        <NoItemsContainer isMembers>
          <Team72Svg />
          <Text>{t('noMembersHereT')}</Text>
          <TextSub>{t('inviteMembersToYourChannelT')}</TextSub>
        </NoItemsContainer>
      );
    }
    if (isPlaylist && !sortedItems?.length && isFilter) {
      return (
        <NoItemsContainer>
          <NoPlaylist72Svg />
          <Text>{t('noSmartFilesFoundT')}</Text>
          <TextSub>
            {t('doubleCheckSearchOrDifferentTermT')}
          </TextSub>
        </NoItemsContainer>
      );
    }
    if (isPlaylist && !sortedItems.length && !isFilter) {
      return (
        <NoItemsContainer>
          <PlaylistIconSvg
            className={manyPagesStyles.image_create_new_playlist}
          />
          <Text>{t('noSmartFilesInYourChannelT')}</Text>
          <TextSub>
            {t('needToShareSmartFileIntoChannelFirstT')}
          </TextSub>
        </NoItemsContainer>
      );
    }

    if (!sortedItems.length && !isFilter && isShowNewItem) {
      return (
        <div
          onClick={props.createPage}
          className={`${manyPagesStyles.create_new_playlist}`}
        >
          <PlaylistIconSvg
            className={`${manyPagesStyles.icon_page_40} ${manyPagesStyles.new_playlist_icon}`}
          />
          <div className={manyPagesStyles.add_new_page}>
            <PlusSvg className={manyPagesStyles.icon_new_recipe} />
            {t('smartFileUpT')}
          </div>
        </div>
      );
    }
  }
  if (
    props.path === 'library'
    || props.path === 'libraryPage'
    || props.path === 'edit_page'
  ) {
    if (!loading && isNoFavoriteItemsExist) {
      return (
        <div
          className={`${libraryStyles.emptyNoResults} ${
            props.isSideBar ? libraryStyles.isSideBar : ''
          }`}
        >
          <img src={emptyFavoriteCollections} alt="" />
          <div className={libraryStyles.emptyNoResults_mainText}>
            {t('noFavoriteLibraryFilesT')}
          </div>
          <div className={libraryStyles.emptyNoResults_infoText}>
            {t('addStarToFilesForQuickAccessT')}
          </div>
        </div>
      );
    }

    if (!loading && isNoRecentItemsExist) {
      return (
        <div
          className={`${libraryStyles.emptyNoResults} ${
            props.isSideBar ? libraryStyles.isSideBar : ''
          }`}
        >
          <div className={`${libraryStyles.emptyNoResults_icon_container}`}>
            <img src={noFiles} alt="" />
            <Clocks18Svg className={libraryStyles.clocks} />
          </div>
          <div className={libraryStyles.emptyNoResults_mainText}>
            {t('noRecentLibraryFilesT')}
          </div>
          <div className={libraryStyles.emptyNoResults_infoText}>
            {t('findRecentlyAddedLibraryComponentsT')}
          </div>
        </div>
      );
    }

    if (
      (!loading && isNotEmptyFilter && isNoSearchResults)
      || (!loading && !sortedItems.length && props.isSideBar)
    ) {
      return (
        <div
          className={`${libraryStyles.emptyNoResults} ${
            props.isSideBar ? libraryStyles.isSideBar : ''
          }`}
        >
          <img src={noFilesFound} alt="" />
          <div className={libraryStyles.emptyNoResults_mainText}>
            {t('noFilesFoundT')}
          </div>
          <div className={libraryStyles.emptyNoResults_infoText}>
            {t('unselectFiltersToFindFileT')}
          </div>
        </div>
      );
    }

    if (!loading && !sortedItems.length && selectorType === 'tag') {
      return <></>;
    }

    if (!loading && !sortedItems.length && !props.isSideBar) {
      return (
        <div
          className={`${libraryStyles.emptyNoResults} ${
            props.isSideBar ? libraryStyles.isSideBar : ''
          }`}
        >
          <img src={noFiles} alt="" />
          <div className={libraryStyles.emptyNoResults_mainText}>
            {`{${t('noFilesInT')}} ${
              folderId
                ? `"${props.title || t('unnamedSmartViewT')}"`
                : props.title
            }`}
          </div>
          <div className={libraryStyles.emptyNoResults_infoText}>
            {t('uploadFilesOrCreateComponentsT')}
          </div>
          <div ref={props.addFileRef}>
            <input
              type="file"
              name="file"
              multiple
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={(e) => {
                submitForm(e);
              }}
            />
            <div
              className={`${libraryStyles.upload_file} ${
                props.isAddFileMenuVisible ? libraryStyles.active : ''
              }`}
              onClick={(e) => {
                e.stopPropagation();
                props.setIsAddFileMenuVisible((a) => !a);
              }}
            >
              <UploadSvg />
              <div>{t('uploadFileUpT')}</div>
            </div>
            {props.isAddFileMenuVisible && (
              <div className={libraryStyles.addFile_wrapper}>
                <AddFilePopUp
                  parentRef={props.addFileRef}
                  wrapperRef={props.addFileRef}
                  onButtonClick={onButtonClick}
                  setIsAddFileMenuVisible={props.setIsAddFileMenuVisible}
                  place="content"
                />
              </div>
            )}
          </div>
        </div>
      );
    }

    return <></>;
  }
  if (props.path === 'playlist') {
    if (!loading && isNoFavoriteItemsExist) {
      return (
        <div
          className={`${libraryStyles.emptyNoResults} ${
            props.isSideBar ? libraryStyles.isSideBar : ''
          }`}
        >
          <img src={emptyFavoriteCollections} alt="" />
          <div className={libraryStyles.emptyNoResults_mainText}>
            {t('noFavoriteLibraryFilesT')}
          </div>
          <div className={libraryStyles.emptyNoResults_infoText}>
            {t('addStarToFilesForQuickAccessT')}
          </div>
        </div>
      );
    }

    if (!loading && isNoRecentItemsExist) {
      return (
        <div
          className={`${libraryStyles.emptyNoResults} ${
            props.isSideBar ? libraryStyles.isSideBar : ''
          }`}
        >
          <div className={`${libraryStyles.emptyNoResults_icon_container}`}>
            <img src={noFiles} alt="" />
            <Clocks18Svg className={libraryStyles.clocks} />
          </div>
          <div className={libraryStyles.emptyNoResults_mainText}>
            {t('noRecentLibraryFilesT')}
          </div>
          <div className={libraryStyles.emptyNoResults_infoText}>
            {t('findRecentlyAddedLibraryComponentsT')}
          </div>
        </div>
      );
    }
    if (isUsed && !sortedItems.length) {
      return (
        <div
          className={`${libraryStyles.emptyNoResults} ${
            props.isSideBar ? libraryStyles.isSideBar : ''
          }`}
        >
          <img src={noFiles} alt="" />
          <div className={libraryStyles.emptyNoResults_mainText}>
            {t('noUsedFilesUpT')}
          </div>
          <div className={libraryStyles.emptyNoResults_infoText}>
            {t('addContenttoPlaylistT')}
          </div>
        </div>
      );
    }
    if (
      (!loading && isNotEmptyFilter && isNoSearchResults)
      || (!loading
        && !sortedItems.length
        && activeSieveOption !== LibCompSieveTypes.Pages)
    ) {
      return (
        <div
          className={`${libraryStyles.emptyNoResults} ${
            props.isSideBar ? libraryStyles.isSideBar : ''
          }`}
        >
          <img src={noFilesFound} alt="" />
          <div className={libraryStyles.emptyNoResults_mainText}>
            {t('noFilesFoundT')}
          </div>
          <div className={libraryStyles.emptyNoResults_infoText}>
            {t('unselectFiltersToFindFileT')}
          </div>
        </div>
      );
    }
  }
  if (props.path === 'contacts') {
    if (!sortedItems?.length && isFilter) {
      return (
        <NoItemsContainer isContacts>
          <NotFoundUserSvg />
          <Text>{t('noContactsFoundUpT')}</Text>
          <TextSub>
            {t('doubleCheckSearchOrDifferentTermT')}
          </TextSub>
        </NoItemsContainer>
      );
    }
    if (!sortedItems.length && !isFilter) {
      return (
        <NoItemsContainer isContacts>
          <Team72Svg />
          <Text>{t('noContactsT')}</Text>
          <TextSub>{t('findListOfYourContactsT')}</TextSub>
        </NoItemsContainer>
      );
    }
  }
  return <></>;
};

export default EmptyStateSelector;
