import React from 'react';
import { useSelector } from 'react-redux';
import ChannelHeader from './ChannelHeader';
import SmartViewHeader from './childComponents/SmartViewHeader';
import LibraryHeader from './LibraryHeader';
import PagesHeader from './PagesHeader';
import ManyPlaylistsHeaderMenu from '../ManyPlaylistsHeaderMenu';

const FullscreenHeader = (props) => {
  const { selectorType } = useSelector((state) => state.user);

  if (props.isManyPages && selectorType !== 'smartView') {
    return <PagesHeader {...props} />;
  }

  if (props.isChannels) {
    return <ChannelHeader {...props} />;
  }

  if (selectorType === 'smartView') {
    return <SmartViewHeader {...props} />;
  }

  if (props.isManyPlaylists) {
    return <ManyPlaylistsHeaderMenu {...props} />;
  }
  return <LibraryHeader {...props} />;
};

export default FullscreenHeader;
