import DashboardColumns from './types';
import { COLUMN_CUSTOM_TYPE } from '../../utils/constants';

const defaultState = {
  structure: [],
  isLoad: true,
};
const dashboardColumnsReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case DashboardColumns.uploadColumnsRedux: {
      return { ...payload };
    }

    case DashboardColumns.column.toggleShowHideRedux: {
      const { columnType, isHide } = payload;
      if (columnType === COLUMN_CUSTOM_TYPE.CLOSE) {
        return { ...state, closeColumn: { ...state.closeColumn, isHide } };
      }
      const newStructure = state.structure.map((i) => (i.id === columnType ? { ...i, isHide } : i),
      );
      return { ...state, structure: newStructure };
    }

    case DashboardColumns.column.updateColorsOrTitleR: {
      const { columnId, newColor, newBackgroundColor, newName } = payload;
      const udpatedColumn = {};
      if (newColor) {
        udpatedColumn.name = newName;
      }
      if (newBackgroundColor) {
        udpatedColumn.backgroundColor = newBackgroundColor;
      }
      if (newName) {
        udpatedColumn.color = newColor;
      }
      if (columnId === COLUMN_CUSTOM_TYPE.CLOSE) {
        return {
          ...state,
          closeColumn: { ...state.closeColumn, ...udpatedColumn },
        };
      }
      if (columnId === COLUMN_CUSTOM_TYPE.INBOX) {
        return {
          ...state,
          inBoxColumn: { ...state.inBoxColumn, ...udpatedColumn },
        };
      }
      const newColumns = {
        ...state.columns,
        [columnId]: udpatedColumn,
      };
      return { ...state, columns: newColumns };
    }

    case DashboardColumns.card.addViewedPlaylistManagerSR: {
      const { playlistWrapperId } = payload;

      const newNotSeenPlaylist = { ...state.notSeenPlaylist };
      delete newNotSeenPlaylist[playlistWrapperId];
      return { ...state, notSeenPlaylist: newNotSeenPlaylist };
    }

    case DashboardColumns.column.updateInboxColumnRedux: {
      const { inBoxColumnItems, notSeenPlaylist } = payload;

      return {
        ...state,
        notSeenPlaylist,
        inBoxColumn: { ...state.inBoxColumn, items: inBoxColumnItems },
      };
    }

    default: {
      return state;
    }
  }
};

export default dashboardColumnsReducer;
