import React from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  MainWrapper,
  OptionsWrapper,
} from '../../../components/MiniPlaylist/NewPlaylist2023/styled';
import { THREE_DOTS_DD_OPTIONS_CONTACTS } from '../../../components/MiniPlaylist/NewPlaylist2023/helpers/enums';
import { actionOpenModal } from '../../../redux/user/action';
import { openModalType } from '../../../utils/constants';

const ThreeDotsDropDown = ({
  show,
  refMenu,
  userRole,
  setIsDropDownVisible,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const editContact = () => dispatch(actionOpenModal(openModalType.EditContact, { memberId: props.memberId }));
  const deleteContact = () => dispatch(actionOpenModal(openModalType.ConfirmDeleteContact, { id: props.itemID }));

  if (show) {
    return (
      <>
        <MainWrapper
          isTableView={props.isTableView}
          data-parent="miniPage"
          isContacts
          ref={refMenu}
        >
          <OptionsWrapper isFirst>
            {t(THREE_DOTS_DD_OPTIONS_CONTACTS.title)}
          </OptionsWrapper>
          <OptionsWrapper onClick={editContact}>
            {t(THREE_DOTS_DD_OPTIONS_CONTACTS.edit)}
          </OptionsWrapper>
          <Divider />
          <OptionsWrapper isLast onClick={deleteContact}>
            {t(THREE_DOTS_DD_OPTIONS_CONTACTS.delete)}
          </OptionsWrapper>
        </MainWrapper>
      </>
    );
  }
  return <div />;
};

export default ThreeDotsDropDown;
