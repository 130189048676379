export const placementEnum = {
  queryAddComponentsToCurrentLibraryComponent: 0,
  queryToggleLibComponentIsHidden: 1,
  queryRestoreLibraryComponentFromTrash: 2,
  queryDeleteLinkPageForLibraryComponent: 3,
  queryCreateTextLinkPage: 4,
  queryClearLinkPage: 5,
  queryAddManyLinkPages: 6,
  queryDeleteManyLinkPages: 7,
  queryReplaceLinkPageToPage: 8,
  queryDuplicateLinkPageText: 9,
  queryDuplicateLinkPageWithPage: 10,
  queryDuplicateLinkPageWithLibComponent: 11,
  queryAddUserAnswer: 12,
  queryTrashStateUpdatePlaylist: 13,
  queryCreatePlaylistWithPage: 14,
  queryDeletePlaylist: 15,
  queryReadLinkPages: 16,
  queryUpdateLCinPageRelation: 17,
  mutationBlockIntoAddMedia: 18,
  mutationCreateAddMediaBlockInLibraryComponent: 19,
  queryDeleteComponent: 20,
  queryRemoveAllUsage: 21,
  mutationDeleteNewBlocks: 22,
  mutationAddMediaIntoEmbedBlock: 23,
  deleteRelation: 24,
  queryDetachComponent: 25,
  queryDeleteComp: 26,
  mutationReplaceBlockInParent: 27,
  queryCreatePage: 28,
  queryDeletePagesAndComponents: 29,
  queryDeleteLibComponent: 30,
  queryAddManyComponents: 31,
  queryCreateLibraryComponentInPage: 32,
  queryDuplicateLibraryComponentInPage: 33,
  queryAddComponentPlaylist: 34,
  queryGetUserByEmail: 35,
  queryContactMemberships: 36,
  queryDeleteManyLibComponent: 31,

};
