import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import listStyles from './taglist.module.scss';
import { DATA_PARENTS } from '../../utils/constants';

const HashTagsList = ({ refList, inputValue, saveHashtag, isListActive }) => {
  const { t } = useTranslation();
  const foundHashTags = useSelector((state) => state.support.foundHashTags);
  const hashtags = useSelector((reduxState) => reduxState.currentPage.hashtags);
  const [filteredTags, setFilteredTags] = useState([]);

  useEffect(() => {
    const arrayTitle = (hashtags && hashtags.map((i) => i.hashtagTitle || i.title)) || [];
    setFilteredTags(
      foundHashTags.filter((i) => !arrayTitle.includes(i.title)).slice(0, 9),
    );
  }, [foundHashTags, hashtags]);

  if (!isListActive || !filteredTags.length || !inputValue) return <></>;

  return (
    <div
      className={listStyles.wrapper}
      ref={refList}
      data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
    >
      <div className={listStyles.container}>
        <div className={listStyles.hint}>{t('newNashtagT')}</div>
        <div className={listStyles.tags}>
          {!!inputValue
            && filteredTags.map((item) => (
              <div
                data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
                onMouseDown={(e) => saveHashtag(e, item.title)}
                className={listStyles.foundHashTags}
                key={item.title}
              >
                <div className={listStyles.text}>#{item.title}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HashTagsList;
