import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../redux/reducers';

interface ViewerProgress {
  viewed: number;
  total: number;
}

const useViewersProgressMaker = (): ViewerProgress => {
  const { linkPages } = useSelector((state: IRootState) => state.currentPage);

  const currentViewed = useMemo(() => (
    linkPages?.filter((lp: { isViewed: boolean; }) => lp.isViewed) || []).length,
  [linkPages]);

  return { viewed: currentViewed || 0, total: linkPages?.length || 0 };
};

export default useViewersProgressMaker;
