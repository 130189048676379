import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ApprovalsWrapper } from './styled';
import { NewUPVViewModes } from '../../utils/constants';
import { actionCreator } from '../../shared/redux/actionHelper';
import { CurrentPage } from '../../redux/currentPage/types';
import ApproveButton from '../../features/ApproveButtonPlaylist';


const ApprovalsForPlayer = ({ currentItem }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: playlistId } = useParams();


  const { id: userID } = useSelector((state) => state.user);
  const isSharedToMe = history.location.pathname.endsWith('shared/');
  const isPublishedInChannel = history.location.pathname.includes('/publish/');


  return (
    <ApprovalsWrapper>
      <ApproveButton
        viewMode={NewUPVViewModes.player}
        itemId={currentItem.id}
        upvCard
        saveState={(data) => {
          dispatch(
            actionCreator(CurrentPage.UpdateApproveButtonS, {
              linkPageId: currentItem.id,
              playlistId,
              interactiveItemData: data,
            }),
          );
        }}
        close={() => {
          dispatch(
            actionCreator(
              CurrentPage.UpdateApproveButtonRedux,
              {
                linkPageId: currentItem.id,
                playlistId,
                interactiveItemData: {
                  ...currentItem.interactiveItemData,
                  isNew: false,
                },
              },
            ),
          );
        }}
        userInterface={
          isSharedToMe
            ? currentItem.mainUserInterface
            : isPublishedInChannel
              ? currentItem.inPublishUserInterface
              : null
        }
        isDisabled={userID === currentItem?.owner?.id}
        isMainInterface={!!isSharedToMe}
        state={
          currentItem.approveButtonState || currentItem.interactiveItemData
        }
        // isView
        id="componentId"
        isEditable
        isExpandedView={false}
      />
    </ApprovalsWrapper>
  );
};

export default ApprovalsForPlayer;
