import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DATA_PARENTS, MessageType, openModalType } from '../../../utils/constants';
import { DDTitleWrapper } from './styled';
import { actionOpenModal } from '../../../redux/user/action';
import { actionDeleteDynamicCollection } from '../../../redux/dynamicCollection/action';
import { actionShowMessage } from '../../../redux/support/action';
import { transformedDATA_PARENTS } from '../../../utils/dataUtil';

const MainWrapper = styled.div`
  position: absolute;
  width: 150px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  padding-top: 16px;
  padding-bottom: 10px;
  z-index: 2;
  ${({ top }) => (top ? `top:${top}px;` : '')}
  ${({ left }) => (left ? `left:${left}px;` : '')}
  ${({ right }) => (right ? `right:${right}px;` : '')}
`;
const OptionWrapper = styled.div`
  padding-left: 16px;
  height: 30px;
  min-height: 30px;
  ${({ isDelete }) => (isDelete ? 'color: #D32F2F;' : '')}
  cursor: pointer;
  padding-top: 7px;

  &:hover {
    background: #ffeebf;
    border-color: #ece2e2;
  }
`;
const Divider = styled.div`
  width: 118px;
  height: 1px;
  background: #1d1d1d;
  opacity: 0.08;
  margin: 7px auto 5px;
`;
const DropDownDownItem = ({
  refMenu,
  clickedView,
  top,
  left,
  right,
  applyFilters,
  setShowNewSmartView,
  close,
  resetSmartview,
  isLibCallBack,
  forTab,
  isTag,
  closeDynamicTab,
  closeTagTab,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const clickDynamicTab = () => {
    history.push(`/content/library/dynamic_${clickedView.id}`);
  };

  const openModal = (e) => {
    e.stopPropagation();
    isTag
      ? dispatch(actionOpenModal(openModalType.TagModal2023, clickedView))
      : dispatch(actionOpenModal(openModalType.SmartViewModal, clickedView));
  };
  const deleteSmart = (e) => {
    e.stopPropagation();
    if (isTag) {
      dispatch(actionOpenModal(openModalType.DeleteTag, {
        id: clickedView.id,
        tagName: clickedView.title,
        closeDynamicTab: (...props) => {
          if (closeDynamicTab) closeDynamicTab(props);
          if (closeTagTab) closeTagTab(e);
        } }));
      return;
    }
    dispatch(
      actionOpenModal(openModalType.DeleteSmartview, {
        deleteCb: () => {
          if (isLibCallBack) {
            const isLibraryCallback = () => history.push('/content/library/all');
            dispatch(
              actionDeleteDynamicCollection(
                clickedView.id,
                history.location.pathname,
                isLibraryCallback,
              ),
            );
          } else {
            dispatch(
              actionDeleteDynamicCollection(
                clickedView.id,
                history.location.pathname,
              ),
            );
          }
          dispatch(
            actionShowMessage({
              type: MessageType.DeleteSmartview,
              itemName: clickedView.name,
            }),
          );
          close();
          resetSmartview();
        },
      }),
    );
  };
  return (
    <MainWrapper
      ref={refMenu}
      top={top}
      left={left}
      right={right}
      data-parent={transformedDATA_PARENTS}
      className="drop_down_down"
    >
      <DDTitleWrapper style={{ marginBottom: 8 }}>{t('optionsUpT')}</DDTitleWrapper>
      <OptionWrapper
        className="drop_dow_down_option"
        data-parent={DATA_PARENTS.smartview}
        onClick={openModal}
      >
        {t('editT')}
      </OptionWrapper>
      {!isTag && (
        <OptionWrapper
          onClick={(e) => {
            e.stopPropagation();
            if (resetSmartview && !forTab) {
              resetSmartview();
            }
            applyFilters({ ...clickedView, forEdit: true });
            setShowNewSmartView(true);
            clickDynamicTab();
            close();
          }}
        >
          {t('applyNewFiltersT')}
        </OptionWrapper>
      )}
      <Divider />
      <OptionWrapper isDelete onClick={deleteSmart}>
        {t('deleteT')}
      </OptionWrapper>
    </MainWrapper>
  );
};

export default DropDownDownItem;
