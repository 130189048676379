import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../Inbox/index.module.scss';
import { calculateUsername } from '../Maker/components/Info/helpers';
import { ReactComponent as CheckSvg } from '../../images/icons/icon_16/check_invite.svg';
import { ReactComponent as AwaitSvg } from '../../images/icons/icon_16/await_invite.svg';

const cn = classNames.bind(styles);


const OutboxRecipientsOpened = ({
  recipients,
  opened,
}) => {
  const refMenu = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const handleHover = () => {
    const windowHeight = window.innerHeight;
    const { bottom } = refMenu?.current?.getBoundingClientRect() || {};
    const componentHeight = 326;
    if (windowHeight <= bottom + componentHeight) {
      setIsAtBottom(true);
    } else {
      setIsAtBottom(false);
    }
  };


  return (
    <div onMouseEnter={handleHover} ref={refMenu} className={`${styles.opens_info} ${styles.tooltip_wrapper}`}>
      {recipients && recipients[0]?.isUser && (
        <div className={styles.avatar_and_status}>
          {opened}
        </div>
      )}
      {!!recipients.length
        && (
        <div className={cn(styles.tooltip, styles.tooltip_opacity, styles.isInvite, { isAtBottom })}>
          {recipients.map(ch => (
            <div key={ch.user?.id || ch.channel?.id} className={styles.channels_tooltip_item}>
              {ch.isUser && (
                <>
                  <div className={styles.recipients_opened_tooltip}>
                    <span>   {calculateUsername(ch.user)} </span>
                    {ch.user.status === 'Active' && <CheckSvg />}
                    {ch.user.status !== 'Active' && <AwaitSvg />}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        )}
    </div>
  );
};

export default OutboxRecipientsOpened;
