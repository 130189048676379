import React, { useEffect, useState } from 'react';
import CardView from './CardView';
import { Roles } from '../../../utils/permissions';


const NewPlaylist2023 = ({ ...props }) => {
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    let userRole = '';
    if (props.item.isOwnContent) {
      userRole = Roles.Admin;
    } else if (!props.item.isCoEdit) {
      userRole = Roles.Viewer;
    } else {
      userRole = Roles['Co-Admin'];
    }
    setUserRole(userRole);
  }, []);

  return (
    <CardView
      isDescriptionOpen={props.isDescriptionOpen}
      userRole={userRole}
      item={props.item}
      index={props.index}
      isSimplifiedMultiselect={props.isSimplifiedMultiselect}
      setSimplifiedMultiselect={props.setSimplifiedMultiselect}
      dragStartHandler={props.dragStartHandler}
      dragEndHandler={props.dragEndHandler}
      libraryDragState={props.libraryDragState}
    />
  );
};

export default NewPlaylist2023;
