import React from 'react';
import { MinorCardFallbackContainer, MinorCardImgWrapper } from '../styled';
import { extractIFrameSrc } from '../../../utils/DOM';
import { ReactComponent as LinkPlaceholderSVG } from '../../../images/2023/svg/preview/link_image_57_56.svg';

const LinkPreview = ({ item, isLibDescription }) => {
  if (item.urlFile && item.urlFile !== 'undefined') {
    return (
      <MinorCardImgWrapper
        src={item.urlFile}
        isLibDescription={isLibDescription}
      />
    );
  }

  const itemIFrameSrc = extractIFrameSrc(item.linkUrl);

  if (itemIFrameSrc) {
    return (
      <MinorCardFallbackContainer isLink isLibDescription={isLibDescription}>
        <iframe
          src={itemIFrameSrc}
          width={isLibDescription ? 1920 : 85}
          height={isLibDescription ? 1080 : 92}
          scrolling="no"
        />
      </MinorCardFallbackContainer>
    );
  }
  return (
    <MinorCardFallbackContainer isLink isLibDescription={isLibDescription}>
      <LinkPlaceholderSVG />
    </MinorCardFallbackContainer>
  );
};

export default LinkPreview;
