import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CloseRoundedBtnWithCross from '../SharedComponents/CloseRoundedBtnWithCross';
import { actionCloseModal } from '../../redux/user/action';
import ButtonDropDown from '../SharedComponents/ButtonDropDown/ButtonDropDown';
import SimpleTable from '../SharedComponents/SimpleTable/SimpleTable';
import { empty, emptyArr } from '../../utils/constants';
import { actionCreator } from '../../shared/redux/actionHelper';
import { Channels } from '../../redux/channels/types';
import { sortedFunctions } from '../../utils/dateConvert';
import { actionSaveComponentDescriptionData } from '../../redux/support/action';

const ViewProgressWrapper = styled.div`
  height: 400px;
  width: 800px;
  border-radius: 12px;
  border: 0.6px solid #ece2e2;
  background: #ffffff;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
`;
const ModalWrapper = styled.div`
  position: fixed;
  background: rgba(147, 147, 147, 0.6);
  z-index: 104;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;
`;
const GreyTitleWrapper = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #939393;
  letter-spacing: 0.05em;
  margin-bottom: 12px;
  font-family: "RobotoFlex", sans-serif;
`;
const arraySortedKey = Object.keys(sortedFunctions);

const ViewersProgress = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [channels, setChannels] = useState([]);
  const [dropDownMode, setDropDownMode] = useState(false);
  const [activeChannel, setActiveChannel] = useState(empty);
  const [isLoaded, setIsLoaded] = useState(false);
  const [buttons, setButtons] = useState(empty);
  const [sortType, setSort] = useState(arraySortedKey[0]);
  const [members, setMembers] = useState(emptyArr);

  const { id } = useSelector(
    (reduxState) => reduxState.support.componentDescriptionData || empty,
  );
  const currentPage = useSelector((state) => state.currentPage);

  const item = useSelector((state) => {
    if (!id) return currentPage;
    return state.content.contentData[id] || currentPage;
  });

  const close = () => {
    dispatch(actionCloseModal());
  };

  const handlerClick = (type) => {
    if (arraySortedKey[type] === sortType) setSort(arraySortedKey[type + 1]);
    else setSort(arraySortedKey[type]);
  };

  useEffect(() => {
    if (item?.usedInChannels) {
      const channelsArr = [];
      Object.keys(item.usedInChannels).forEach((key) => {
        channelsArr.push(item.usedInChannels[key]);
      });
      setChannels(channelsArr);
      if (channelsArr.length) setActiveChannel(channelsArr[0]);
    }
  }, [id]);

  useEffect(() => {
    if (channels.length > 2) {
      setDropDownMode(true);
    }
  }, [channels.length]);

  useEffect(() => {
    let timer;
    if (
      activeChannel.id
      && item?.usedInChannels?.[activeChannel.id]?.isLoaded
    ) {
      timer = setTimeout(() => setIsLoaded(true), 350);
    } else if (
      activeChannel.id
      && !item?.usedInChannels?.[activeChannel.id]?.isLoaded
    ) {
      setIsLoaded(false);
      dispatch(
        actionCreator(Channels.GetPlaylistButtonsStatesS, {
          playlistManagerId: item.wrapperId,
          channelId: activeChannel.id,
          playlistId: item.id,
          playlistIdStatus: `${item.id}_${item.status}`,
        }),
      );
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [activeChannel.id, item]);

  useEffect(() => {
    setButtons(
      (item?.usedInChannels
        && item?.usedInChannels[activeChannel.id]?.buttons)
        ?? empty,
    );
    const membersArray = [
      ...(item.usedInChannels[activeChannel?.id]?.members ?? emptyArr),
    ];
    sortedFunctions[sortType](membersArray);
    membersArray.sort((a) => {
      if (a.id === item.owner.id) return -1;
      return 1;
    });
    setMembers(membersArray);
  }, [item, activeChannel.id, sortType]);

  useEffect(() => {
    return () => {
      dispatch(actionSaveComponentDescriptionData(null, null));
    };
  }, []);

  return (
    <ModalWrapper onClick={close}>
      <ViewProgressWrapper onClick={(e) => e.stopPropagation()}>
        <GreyTitleWrapper>{t('viewersProgressUp')}</GreyTitleWrapper>
        <CloseRoundedBtnWithCross
          top={20}
          right={19}
          onClick={close}
          moveTop={8}
        />
        <ButtonDropDown
          channelsArr={channels}
          marginBot={22}
          dropDownMode={dropDownMode}
          setActiveChannel={setActiveChannel}
          activeChannel={activeChannel}
        />
        <SimpleTable
          members={members}
          sortByName={handlerClick}
          sortType={sortType}
          ownerID={item.owner?.id}
        />
      </ViewProgressWrapper>
    </ModalWrapper>
  );
};

export default ViewersProgress;
