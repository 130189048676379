export const DynamicCollection = {
  renameDC: '[DynamicCollection] rename',
  addDC: '[DynamicCollection] add ',
  deleteDC: '[DynamicCollection] delete',
  uploadDC: '[DynamicCollection] upload Redux',
  duplicateDC: '[DynamicCollection] duplicate',
  editDC: '[DynamicCollection] edit',
  changeDC: '[DynamicCollection] change fields',
  moveDC: '[DynamicCollection] move dc (DND)',
  moveDC_R: '[DynamicCollection] move dc (DND) REDUX',
  GetCounterSmartView: '[DynamicCollection] Get counter for smartView SAGAS',
  UpdateCounterSmartView:
    '[DynamicCollection] Update counter for smartView Redux',
};
