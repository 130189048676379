import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ForgotView, {
  ConfirmedContainer,
  EmailBoldSpan,
  ForgotWrapper,
  Logo,
  SignInReturn,
  SubTitle,
  Title,
} from './ForgotView';
import validateEmail from '../../../utils/validators/validateEmail';
import {
  actionSendForgotPasswordRequest,
  actionToggleRequestSpinner,
} from '../../../redux/support/action';
import { LoginBackGround } from '../Login';
import LoginIntro from '../Intro';
import { ReactComponent as LogoSvg } from '../../../images/2023/svg/Logo_black_text.svg';
import styles from '../Login.module.scss';
import { requestForgotPasswordLink } from '../../../utils/request';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { requestSpinner } = useSelector((state) => state.support);
  const [emailInputValue, setEmailInputValue] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isTouched, setIsTouched] = useState(false);
  const [welcomeModal, setWelcomeModal] = useState(true);

  useEffect(() => {
    dispatch(actionToggleRequestSpinner(false));
  }, []);

  const onEmailInputChange = (e) => {
    if (e.target.value) setErrorMsg('');
    setEmailInputValue(e.target.value);
  };

  const switchModal = () => {
    setWelcomeModal(false);
  };

  const goToSignInt = () => {
    history.push('/login');
  };

  const onInputBlur = () => {
    if (!emailInputValue) setErrorMsg(t('filedIsRequiredT'));
  };
  const onInputFocus = () => {
    if (!isTouched) setIsTouched(true);
  };

  const onSubmitButtonClick = async () => {
    if (!validateEmail(emailInputValue)) {
      setErrorMsg(t('invalidEmailErrorT'));
      return;
    }
    if (emailInputValue && validateEmail(emailInputValue)) {
      try {
        setErrorMsg('');
        dispatch(
          actionSendForgotPasswordRequest(
            emailInputValue,
            history,
            switchModal,
            setErrorMsg,
          ),
        );
      } catch (err) {
        setErrorMsg(err.response.data.message);
      }
    }
  };

  const onInputKeyPress = (e) => {
    if (e.code === 'Enter') onSubmitButtonClick();
  };
  const goToSignIn = () => {
    history.push('/login');
  };

  return (
    <>
      <LoginBackGround />
      {welcomeModal && (
        <div className={styles.scroll_wrapper}>
          <div className={styles.scroll_balancer}>
            <ForgotView
              value={emailInputValue}
              onSubmit={onSubmitButtonClick}
              onValueChange={onEmailInputChange}
              onInputBlur={onInputBlur}
              onInputFocus={onInputFocus}
              errorMsg={errorMsg}
              isTouched={isTouched}
              onInputKeyPress={onInputKeyPress}
              requestSpinner={requestSpinner}
              onSignIn={goToSignIn}
            />
            <LoginIntro />
          </div>
        </div>
      )}
      {!welcomeModal && (
        <div className={styles.scroll_wrapper}>
          <div className={styles.scroll_balancer}>
            <ForgotWrapper>
              <ConfirmedContainer>
                <Logo isNotClickable isForgotPassword>
                  <LogoSvg />
                </Logo>
                <Title>
                  {t('resetYourPasswordT')}
                </Title>
                <SubTitle isForgotPassword>
                  {t('passwordResetLine1T')} <EmailBoldSpan>{emailInputValue}</EmailBoldSpan>{' '}
                  {t('withT')} <br /> {t('passwordResetLine2T')}
                  <br />
                  <br />
                  {t('passwordResetLine3T')}
                </SubTitle>
                <SignInReturn onClick={goToSignInt} isForgotPassword>
                  {t('backToSignInT')}
                </SignInReturn>
              </ConfirmedContainer>
            </ForgotWrapper>
            <LoginIntro />
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
