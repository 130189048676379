import { getNameFromState } from '../../utils/draftJsHelpers';
import { DEFAULT_TITLE } from '../../utils/constants';
import { actionCreator } from '../../shared/redux/actionHelper';
import EditPlaylist from '../../redux/playlists/types';
import i18n from '../../i18n';

export const getItemTitle = (currentItem) => {
  if (currentItem?.type === 'elementText') {
    return currentItem?.title || getNameFromState(currentItem) || 'Text';
  }
  return (
    currentItem?.title
    || currentItem?.libraryComponent?.title
    || i18n.t(DEFAULT_TITLE.PlaylistElement)
  );
};

export const getSharedData = (accessCode, dispatch, id, history) => dispatch(
  actionCreator(EditPlaylist.GetSharedPlaylist, {
    id,
    accessCode,
    history,
    isViewAsCoEditViewMode: true,
  }),
);

export function chunk(array, size) {
  if (!array) return [];
  const firstChunk = array.slice(0, size); // create the first chunk of the given array
  if (!firstChunk.length) {
    return array; // this is the base case to terminal the recursive
  }
  return [firstChunk].concat(chunk(array.slice(size, array.length), size));
}
