import MuxPlayer from '@mux/mux-player-react';

import './index.scss';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { checkMediaStatus } from './helpers';
import { actionShowMessage } from '../../../redux/support/action';
import { DEFAULT_TITLE, MessageType } from '../../../utils/constants';

const MuxAudioVideoPlayer = ({ item, isExpandView }) => {
  const title = item.title;
  const id = item.id;

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const checkIfMediaIsReady = async (pathname) => {
    if (item.assetID) {
      // case item is mux content
      const data = await checkMediaStatus(item.assetID, item.id);
      if (data && data.status === 'preparing') {
        dispatch(
          actionShowMessage({
            type: MessageType.MediaIsNotReady,
            itemName: t('defaultSmartPageTitleT'),
          }),
        );
        if (pathname.includes('player/preview/')) {
          history.push('/content/smartfiles/drafts');
        } else {
          history.push('/content/library/all');
        }
      }
    }
  };
  useEffect(() => {
    checkIfMediaIsReady(history.location.pathname);
  }, []);

  return (
    <>
      <MuxPlayer
        id="custom-id"
        className={isExpandView ? 'mux_expand_view' : 'white_gallery_video'}
        streamType="on-demand"
        minResolution="720p"
        accent-color="#ffd56d"
        playbackId={item.playbackID}
        metadata={{
          video_id: { id },
          video_title: { title },
          viewer_user_id: 'user-id-007',
        }}
      />
    </>
  );
};

export { MuxAudioVideoPlayer };
