import { SECONDS_IN_DAY } from '../../../utils/dateConvert';
import { DateRangeMark4SharedPlaylist } from './enum';

export const calcDuration = (selectedDate) => {
  if (!selectedDate) return 0;
  const now = new Date();
  return Math.ceil((selectedDate.getTime() - now.getTime()) / (1000 * 86400));
};
export const calcDurationRead = (selectedDateFrom, selectedDateTo) => {
  if (!selectedDateFrom || !selectedDateTo) return 0;
  if (!selectedDateFrom) return calcDuration(selectedDateTo) && calcDuration(selectedDateTo) + 1;
  return Math.ceil(
    (selectedDateTo.getTime() - selectedDateFrom.getTime()) / (1000 * 86400),
  );
};

export const calcGreater = (selectedDateFrom, selectedDateTo) => {
  if (!selectedDateFrom || !selectedDateTo) return 0;
  return selectedDateFrom.getTime() > selectedDateTo.getTime();
};

export const getDateToStringForSafari = (date) => {
  const exDate = new Date(date);
  return `${(exDate.getDate() < 10 ? '0' : '') + exDate.getDate()}/${
    (exDate.toLocaleString('default', { month: 'numeric' }) < 10 ? '0' : '')
    + exDate.toLocaleString('default', { month: 'numeric' })
  }/${exDate.getFullYear()}`;
};

export const calcSaveState = (
  availableFrom,
  availableTo,
  selectNoDate,
  selectDay,
) => {
  if (selectNoDate) return {};
  if (selectDay) {
    const startDay = new Date();
    const endDay = new Date();
    endDay.setDate(endDay.getDate() + 1);
    return { availableFrom: startDay, availableTo: new Date(endDay) };
  }
  return { availableFrom, availableTo };
};

export const calcSaveStateForShared = (
  availableFrom,
  availableTo,
  selectedOption,
) => {
  let availableToEx = 0;
  let availableFromEx = 0;
  let dateRangeMark;
  switch (selectedOption) {
    case DateRangeMark4SharedPlaylist.OPEN_TO_READ: {
      dateRangeMark = DateRangeMark4SharedPlaylist.OPEN_TO_READ;
      break;
    }
    case DateRangeMark4SharedPlaylist.DAILY: {
      dateRangeMark = DateRangeMark4SharedPlaylist.DAILY;
      const startDay = new Date();
      const endDay = new Date();
      endDay.setDate(endDay.getDate() + 1);
      availableFromEx = startDay;
      availableToEx = new Date(endDay);
      break;
    }
    case DateRangeMark4SharedPlaylist.WEEKLY: {
      dateRangeMark = DateRangeMark4SharedPlaylist.WEEKLY;
      const startDay = new Date();
      const endDay = new Date();
      endDay.setDate(endDay.getDate() + 7);
      availableFromEx = startDay;
      availableToEx = new Date(endDay);
      break;
    }
    default:
      const endDay_date = new Date(availableTo);
      endDay_date.setHours(23);
      endDay_date.setMinutes(59);
      endDay_date.setSeconds(59);
      endDay_date.setMilliseconds(999);

      availableToEx = endDay_date;
      availableFromEx = availableFrom;
      dateRangeMark = DateRangeMark4SharedPlaylist.CUSTOM_TIME_RANGE;
  }
  return { availableFrom: availableFromEx, availableTo: availableToEx, dateRangeMark };
};

export const calcNewState = (date, isAvailableTo) => {
  if (!date) {
    if (!isAvailableTo) return new Date();
    const calcNewDate = new Date();
    calcNewDate.setTime(calcNewDate.getTime() + SECONDS_IN_DAY * 1000);
    return calcNewDate;
  }
  const date1 = new Date();
  date1.setTime(date * 1000);
  return date1;
};

export const updateCalcFields = (field) => `shared${field.charAt(0).toUpperCase()}${field.slice(1)}`;
