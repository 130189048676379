import React from 'react';
import styled from 'styled-components/macro';

const ImageWrapper = styled.img`
  width: 100%;
  height: inherit;
  max-height: calc(100vh - 200px);
  object-fit: contain;
  overflow: hidden;
  transition: transform 0.25s ease;
  cursor: zoom-in;
  ${({ zoomed }) => (zoomed ? `
    --x: 50%;
    --y: 50%;
    transform: scale(2);
    transform-origin: var(--x) var(--y);
    clip-path: inset(
      calc((1 - 1/2) * (var(--y)))
      calc((1 - 1/2) * (100% - var(--x)))
      calc((1 - 1/2) * (100% - var(--y)))
      calc((1 - 1/2) * (var(--x)))
    );
  cursor: zoom-out;
    ` : '')}
 
`;

const Container = styled.div`
${({ zoomed }) => (zoomed ? `
  max-width: 100%;
  ` : 'max-width: 1200px;')};
`;
const ImagePreview = ({ src }) => {
  const [zoomed, setZoomed] = React.useState(false);

  const mouseMove = (e) => {
    if (zoomed) {
      e.target.style.setProperty('--x', `${100 * e.nativeEvent.offsetX / e.target.offsetWidth}%`);
      e.target.style.setProperty('--y', `${100 * e.nativeEvent.offsetY / e.target.offsetHeight}%`);
    }
  }
  return (
    <Container onClick={() => setZoomed(!zoomed)} zoomed={zoomed}>
      <ImageWrapper src={src} onMouseMove={mouseMove} zoomed={zoomed} />
    </Container>
  );
};

export default ImagePreview;
