import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SmallChevyLeftSvg } from '../../../images/icons/chevron_left_16x16.svg';
import { ReactComponent as SmallChevyRightSvg } from '../../../images/icons/chevron_right_16x16.svg';
import Tooltip from '../../../shared/Tooltips/Tooltip';
import { ReactComponent as MinusSvg } from '../../../images/icons/minus_16.svg';
import { ReactComponent as PlusSvg } from '../../../images/icons/plus_16.svg';
import rawStyles from '../index.module.scss';

const styles = classNames.bind(rawStyles);
export const LowControls = ({ changeStep, changePage, pages, ...props }) => {
  const { t } = useTranslation();
  return (
    <div className={styles('content_bottom_controls')}>
      {props.isPdf && (
        <>
          <div className={styles('section')}>
            <div className={styles('wrap', 'first')}>
              <div
                onClick={changePage('decrease')}
                className={styles('item', { disabled: pages.current === 1 })}
              >
                <SmallChevyLeftSvg />
              </div>
            </div>
            <div className={styles('wrap')}>
              <div className={styles('item', 'grow', 'text', 'pages')}>
                {pages.current} <span className={styles('slash')}>/</span>
                {pages.max}
              </div>
            </div>
            <div className={styles('wrap')}>
              <div
                onClick={changePage('increase')}
                className={styles('item', {
                  disabled: pages.current === pages.max,
                })}
              >
                <SmallChevyRightSvg />
              </div>
            </div>
          </div>
          <div className={styles('separator')} />
        </>
      )}
      <div className={styles('section')}>
        <div className={styles('wrap', 'first')}>
          <div
            onClick={changeStep('decrease')}
            className={styles('item', 'tooltip', 'side')}
          >
            <Tooltip text={t('zoomOutT')} direction="down">
              <div className={styles('item')}>
                <MinusSvg />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={styles('wrap')}>
          <div
            onClick={changeStep('reset')}
            className={styles('item', 'tooltip')}
          >
            <Tooltip text={t('backToDefaultSizeT')} direction="down">
              <div
                className={styles('item', 'grow', 'text', 'bold', 'percent')}
              >
                {Math.round(props.scalePercentage * 10) / 10}%
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={styles('last', 'wrap')}>
          <div
            onClick={changeStep('increase')}
            className={styles('item', 'tooltip', 'side')}
          >
            <Tooltip text={t('zoomInT')} direction="down">
              <div className={styles('item')}>
                <PlusSvg />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
