import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ThreeDotsMenuShell } from '../styled';
import { ReactComponent as ThreeDotsSVG } from '../../../images/2023/svg/small/player/three_dots.svg';
import { DDTitleWrapper } from '../../SmartView/2023/styled';
import { actionOpenModal } from '../../../redux/user/action';
import { actionCreator } from '../../../shared/redux/actionHelper';
import Contacts from '../../../redux/contacts/types';
import { Channels } from '../../../redux/channels/types';
import { openModalType } from '../../../utils/constants';
import { calcNextItemToRedirect } from '../../../utils/helpers';

const MainWrapper = styled.div`
  position: absolute;
  width: 150px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  padding-top: 16px;
  padding-bottom: 12px;
  right: 20px;
  z-index: 100;
   ${({ isChannel }) => (isChannel ? 'top: -125px; width: 115px;' : 'width: 150px;')}
`;
const OptionWrapper = styled.div`
  padding-left: 16px;
  height: 30px;
  min-height: 30px;
  ${({ isDelete }) => (isDelete ? 'color: #D32F2F;' : '')}
  cursor:pointer;
  display: flex;
  align-items: center;
  &:hover {
    background: #ffeebf;
    border-color: #ece2e2;
  }
`;
const Divider = styled.div`
  width: 118px;
  height: 1px;
  background: #1d1d1d;
  opacity: 0.08;
  margin: 7px auto 5px;
`;
const Description = styled.div`
  color: #484343;
  font-family: RobotoFlex, sans-serif;
  font-size: 14px;
  font-weight: 400;
`;

const DescriptionLink = styled.a`
  color: #2F71F4;
  text-decoration: none;
`;

const StyledDescription = ({ subject, onLinkClick }) => {
  const { t } = useTranslation();
  const capitalizeSubject = subject[0].toUpperCase() + subject.slice(1);
  return (
    <Description>
      {t('styledDescription1T', { subject })}
      <DescriptionLink onClick={onLinkClick}>{t('styledDescription2T', { capitalizeSubject })}</DescriptionLink>.
    </Description>
  );
};

const DropDownDownItem = ({ refShowMenu, itemId, userId, isChannel, isChannelOwner, activeItemId, items }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { myChannels } = useSelector((state) => state.channels);
  const subject = isChannel ? 'channel' : 'contact';

  const nextItem = useMemo(() => calcNextItemToRedirect(items, activeItemId, isChannel, myChannels),
    [items, activeItemId, isChannel, myChannels],
  );

  const newPath = nextItem?.id ? `${nextItem.path}${nextItem.id}` : nextItem?.path ?? '';
  const history = useHistory();
  const onChannelsLinkClick = () => {
    dispatch(actionOpenModal(openModalType.ApplicationSettings, { defaultPage: 'activity' }));
  };

  const onLinkClick = () => {
    if (isChannel) {
      onChannelsLinkClick();
      return;
    }
    history.push('/contacts');
  };

  const editContact = (e) => {
    e.stopPropagation();
    dispatch(actionOpenModal(openModalType.EditContact, { memberId: userId, isCalledFromSidebar: true }));
  };

  const deleteContact = (e) => {
    e.stopPropagation();
    dispatch(actionOpenModal(openModalType.ConfirmDeleteContact, { id: userId, newPath, history }));
  };

  const onDeleteChannelClick = (e) => {
    e.stopPropagation();
    if (isChannelOwner) {
      dispatch(actionOpenModal(openModalType.DeleteChannel, { id: itemId, nextItem, history }));
      return;
    }
    dispatch(actionOpenModal(openModalType.LeaveChannel, { channelId: itemId, nextItem }));
  };

  const handleDeleteWrapper = (e) => {
    if (isChannel) {
      onDeleteChannelClick(e);
      return;
    }
    deleteContact(e);
  };

  const unpinHandler = (e) => {
    e.stopPropagation();
    const conditionalAction = isChannel ? Channels.UpdatePinState : Contacts.UpdatePinState;
    const conditionalModalAction = isChannel ? Channels.CheckHasPin : Contacts.CheckHasPin;
    const confirmCallback = () => {
      dispatch(
        actionCreator(conditionalAction, {
          id: itemId,
          userId,
        }),
      );
      if (nextItem) {
        history.push(newPath);
      }
    };

    const modalWarningCallback = () => {
      dispatch(
        actionOpenModal(openModalType.ConfirmModalUniversal, {
          title: `${t('unpinUpT')} ${subject.toUpperCase()}`,
          subject: `${t('unpinThisT')}  ${subject}?`,
          description: <StyledDescription subject={subject} onLinkClick={onLinkClick} />,
          confirm: confirmCallback,
          cancelText: t('cancelUpT'),
          okText: t('unpinUpT'),
          type: t('unpinLowT'),
        }),
      );
    };

    dispatch(actionCreator(conditionalModalAction, { confirmCallback, modalWarningCallback }));
  };

  return (
    <MainWrapper ref={refShowMenu} isChannel={isChannel}>
      <DDTitleWrapper>{t('upT')}</DDTitleWrapper>
      <OptionWrapper onClick={unpinHandler}>
        {t('unpinT')}
      </OptionWrapper>
      {!isChannel && (
        <OptionWrapper onClick={editContact}>
          {t('editT')}
        </OptionWrapper>
      )}
      <Divider />
      <OptionWrapper onClick={handleDeleteWrapper} isDelete>
        {isChannel && !isChannelOwner ? t('leaveT') : t('deleteT') }
      </OptionWrapper>
    </MainWrapper>
  );
};
const ThreeDotsMenu = React.memo(
  function ThreeDotsMenu({
    setIsOpenMenu,
    isOpenMenu,
    refShowMenu,
    itemId,
    userId,
    isChannel,
    isChannelOwner,
    activeItemId,
    items,
  }) {
    const openHandler = (e) => {
      e.stopPropagation();
      setIsOpenMenu(true);
    };
    return (
      <>
        <ThreeDotsMenuShell isOpenMenu={isOpenMenu} onClick={openHandler}>
          <ThreeDotsSVG />
        </ThreeDotsMenuShell>
        {isOpenMenu && (
          <DropDownDownItem
            refShowMenu={refShowMenu}
            itemId={itemId}
            userId={userId}
            isChannel={isChannel}
            isChannelOwner={isChannelOwner}
            items={items}
            activeItemId={activeItemId}
          />
        )}
      </>
    );
  },
);
export default ThreeDotsMenu;
