import { SelectedPage } from './types';

export const actionSelectedPage = (pageId, folderId, idStatus) => ({
  type: SelectedPage.AddSelectedPage,
  payload: { pageId, folderId, idStatus },
});

export const actionRemoveSelectedPage = (pageId, folderId, idStatus) => ({
  type: SelectedPage.RemoveSelectedPage,
  payload: { pageId, folderId, idStatus },
});

export const actionRemoveAllSelected = () => ({
  type: SelectedPage.RemoveAllSelected,
});
