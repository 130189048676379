import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import NewCardForm from './NewCardForm';
import { STRIPE_PUBLISHABLE_KEY } from '../../../../environments/environmentsStripe';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const PaymentMethods = ({ currentPlan }) => {
  const { paymentMethods, activePaymentMethod } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const setDefaultPaymentMethod = (id) => {
    dispatch(actionCreator(Settings.ChangePaymentMethod, { methodId: id }));
  };

  return (
    <div>
      {paymentMethods && paymentMethods.length > 0 && (
        <div>
          <h3 style={{ margin: '30px 0 15px' }}>{t('paymentMethodsHeaderT')}</h3>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {paymentMethods.map((item) => (
              <div style={{ display: 'flex', gap: '20px' }}>
                <div>{t('cardEndingInT')} {item.card.last4}</div>
                {activePaymentMethod && activePaymentMethod === item.id && (
                  <div>{t('defaultPaymentMethodT')}</div>
                )}
                {(currentPlan !== 'Free') && activePaymentMethod && activePaymentMethod !== item.id && (
                  <div
                    onClick={() => setDefaultPaymentMethod(item.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {t('setAsDefaultT')}
                  </div>
                )}
              </div>
            ))}
          </div>
          {(currentPlan !== 'Free') && (
            <div>
              <h4>{t('addNewPaymentMethodT')}</h4>
              <Elements stripe={stripePromise}>
                <NewCardForm />
              </Elements>
            </div>
          )}
        </div>
      )
    }
    </div>
  );
};

export default PaymentMethods;
