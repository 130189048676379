import React from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.scss';

import { calculateSingleUserAvatar, calculateUsername } from '../Maker/components/Info/helpers';

const cn = classNames.bind(styles);

const InboxSender = ({
  userWhoSent = {},
  noDetails,
  width = 22,
}) => {
  return (
    <div className={styles.sender_info}>
      <div className={cn(styles.tooltip_wrapper, styles.avatar_wrapper)}>
        {calculateSingleUserAvatar(userWhoSent, width)}
        {!noDetails && (
          <div className={cn(styles.tooltip, styles.tooltip_opacity)}>
            <div className={styles.channels_tooltip_item}>
              <div className={styles.avatar_wrapper_details}>
                {calculateSingleUserAvatar(userWhoSent)}
              </div>
              <div className={styles.sender_details}>
                <div> {calculateUsername(userWhoSent)} </div>
                <div className={styles.sender_details_email}>{userWhoSent.email}</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.bold_text}> {calculateUsername(userWhoSent)} </div>
    </div>
  );
};

export default InboxSender;
