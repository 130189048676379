import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './index.module.css';
// import default_image from '../../images/icons/default_image.svg';
import default_image from '../../images/png/defaultImagePlaylist.png';
import avatar from '../../images/icons/avatar.svg';
import { actionPageWillDownload } from '../../redux/currentPage/action';
import clock from '../../images/icons/clock.svg';

const PreviewPlaylistCard = ({ playlist, isDownload }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      <div
        onClick={() => {
          dispatch(actionPageWillDownload());
          history.push(`/browse_playlists/${playlist.id}`);
        }}
        className={`${styles.playlist_card} ${
          isDownload ? styles.pending : ''
        }`}
      >
        <div className={styles.wrap_image}>
          {!!playlist.img && (
            <img
              className={styles.image_crop}
              src={playlist.img}
              style={{ top: -(playlist.position / 2) + 20 }}
              alt="Image"
            />
          )}
          {!playlist.img && (
            <img className={styles.image} src={default_image} alt="Image" />
          )}
        </div>
        <div className={styles.title}>{playlist.title}</div>
        <div className={styles.info}>
          <div className={styles.author}>
            <img src={avatar} className={styles.avatar} alt="avatar" />
            {playlist.author}
          </div>
          <div className={styles.time}>
            <img src={clock} className={styles.clock} alt="tine" />
            {Math.ceil(playlist.time)} min
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewPlaylistCard;
