import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  ${({ disableHeightMargin }) => (disableHeightMargin
    ? `
    height: calc(100vh - 200px);
    width:auto;
    margin:unset;
    overflow: hidden;
  `
    : `
    background: red;
    position: absolute;
    width: calc(100vw - 100px);
    height: calc(100vh - 100px);
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 1%;
  `)}

  mux-player {
    height: 100%;
  }
`;

export const AudioVideoContent = ({ ...props }) => {
  return (
    <Wrapper disableHeightMargin={props.disableHeightMargin}>
      {props.children}
    </Wrapper>
  );
};
