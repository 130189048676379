import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { openModalType, TAG_DEFAULT_COLOR } from '../../utils/constants';
import { GlobalFlexRowWrapper } from '../../pages/Maker/MakerCommon/UiComponents/styled';
import { actionOpenModal } from '../../redux/user/action';

const SoloTagWrapper = styled.div`
  color: ${({ color }) => (color ? `${color};` : `${TAG_DEFAULT_COLOR.color};`)};
  background: ${({ backgroundColor }) => (backgroundColor
    ? `${backgroundColor};`
    : `${TAG_DEFAULT_COLOR.backgroundColor};`)};
  ${({ fhd }) => (fhd
    ? `
    max-width: 150px;
  `
    : `
    max-width: 100px;
  `)}
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  border-radius: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 22px;
  padding-top: 5px;
  margin-right: 8px;
  min-width: 17px;
  ${({ noLimit }) => (noLimit
    ? 'max-width:none;'
    : `
  
  `)}
  ${({ noPaddings }) => (noPaddings
    ? `
    margin-right: 0px;
  `
    : `
    padding-left: 10px;
  padding-right: 10px;
  `)}
`;
const TagsCell = ({ tags, limiter, fhd, noPaddings, itemID, type }) => {
  const dispatch = useDispatch();
  const toggleTags = (e) => {
    e.stopPropagation();
    if (type === 'sharedPage') return;
    dispatch(actionOpenModal(openModalType.AddTagModal, { id: itemID }),
    );
  };
  if (!tags || tags.length === 0 || type === 'sharedPage') {
    return (
      <GlobalFlexRowWrapper
        onClick={toggleTags}
        style={{ minWidth: 200, minHeight: 28 }}
      />
    );
  }

  return (
    <GlobalFlexRowWrapper onClick={toggleTags}>
      {tags.slice(0, limiter).map((tag) => (
        <SoloTagWrapper
          key={tag.id}
          noLimit={tags.length === 1}
          fhd={fhd}
          color={tag.color}
          backgroundColor={tag.backgroundColor}
        >
          {tag.title}
        </SoloTagWrapper>
      ))}
      {tags.length > limiter && (
        <SoloTagWrapper onClick={toggleTags} noPaddings={noPaddings}>
          {`+${tags.length - limiter}`}
        </SoloTagWrapper>
      )}
    </GlobalFlexRowWrapper>
  );
};

export default TagsCell;
