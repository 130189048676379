import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moduleStyles from '../../../pages/Library/LibraryHeader.module.scss';
import { ReactComponent as Upload16Svg } from '../../../images/icons/upload_16.svg';
import AddFilePopUp from './AddFilePopUp';
import {
  DATA_PARENTS,
  empty,
  svgDynamicCollection,
} from '../../../utils/constants';
import Tooltip from '../../../shared/Tooltips/Tooltip';
import { PlaylistButton } from '../../Buttons/ButtonComponent';
import DynamicCollectionCreator from '../../DynamicCollectionCreator/DynamicCollectionCreator';
import { ReactComponent as EditSvg } from '../../../images/icons/icon_20/edit_20.svg';
import { ReactComponent as MoreSvg } from '../../../images/icons/icon_20/more_20.svg';
import UnifiedDropDownMenu, {
  options,
} from '../../DropDownOption/UnifiedDropDownMenu';
import {
  actionDeleteDynamicCollection,
  actionDuplicateDynamicCollection,
  actionEditDynamicCollection,
  actionRenameDynamicCollection,
} from '../../../redux/dynamicCollection/action';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import sideBarStyles from '../../LibraryBar/LibrarySideBar.module.scss';
import SearchInSmartView from '../../../UI/SearchInSmartView/SearchInSmartView';
import IconColored from '../../../UI/IconColored';
import EditDynamicCollectionIcon from '../../DynamicCollectionCreator/EditDynamicCollectionIcon';
import SortAndView from './SortAndView';
import { ReactComponent as Plus } from '../../../images/icons/plus_blue_24.svg';

const styles = classNames.bind(moduleStyles);

const SmartViewHeader = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { folderId } = useSelector((state) => state.user);
  const { isShowLibraryWidget } = useSelector((state) => state.currentPage);
  const { id: metaId } = useSelector(
    (reduxState) => reduxState.support.componentDescriptionData || empty,
  );
  const dynamicCollection = useSelector((state) => state.dynamicCollection);
  const [refMenu, isComponentVisible, setIsComponentVisible] = useComponentVisible(false);
  const history = useHistory();
  const [editRef, isEditIcon, setIsEditIcon] = useComponentVisible(false);
  const [tmpTitle, setTmpTitle] = useState('');
  const [isComponentRename, setIsComponentRename] = useState(false);
  const [width, setWidth] = useState(156);
  const inputRef = useRef(null);
  const { componentDescription } = useSelector((state) => state.support);

  useEffect(() => {
    const x = inputRef.current?.getBoundingClientRect().width;
    let newWidth;
    if (x < 156) {
      newWidth = 156 - 1;
    } else if (x >= 330) {
      newWidth = 330;
    } else newWidth = x;
    setWidth(newWidth + 12);
  }, [tmpTitle, dynamicCollection[folderId]]);

  const onTitleClickHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setTmpTitle(dynamicCollection[folderId]?.name);
    isComponentVisible && setIsComponentVisible(false);
    setIsComponentRename(true);
  };

  const renameHandler = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    setIsComponentRename(false);
    setTmpTitle('');
    dispatch(actionRenameDynamicCollection(folderId, tmpTitle));
  };

  const onKeyPressFolderTitle = (e) => {
    if (e.key === 'Enter') {
      renameHandler(e);
    }
    if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();
      setIsComponentRename(false);
      setTmpTitle('');
    }
  };

  const deleteHandler = () => {
    dispatch(
      actionDeleteDynamicCollection(folderId, history.location.pathname),
    );
  };
  const cancelHandler = () => {
    props.setIsEdit(false);
    const { libFilterSearch, filter } = dynamicCollection[folderId];
    props.setFilter(filter);
    props.setLibFilterSearch(libFilterSearch);
  };
  const duplicateHandler = () => {
    setIsComponentVisible(false);
    dispatch(actionDuplicateDynamicCollection(folderId));
  };
  const updateDynamicCollectionHandler = () => {
    dispatch(
      actionEditDynamicCollection({
        dynamicCollectionId: folderId,
        filter: props.filter,
        libFilterSearch: props.libFilterSearch,
      }),
    );
    props.setIsEdit(false);
  };

  const onClickEditIconHandler = () => {
    setIsEditIcon((prev) => !prev);
  };

  return (
    <div
      className={styles('header_container', {
        isSideBarOpened: isShowLibraryWidget || metaId,
      })}
    >
      <div className={styles('header', { rows: !props.isCardsView })}>
        <div className={styles('title_or_search')}>
          {!!folderId && dynamicCollection[folderId] && (
            <div
              data-cy="smartView-icons"
              className={styles('header_edit_icon_wrapper')}
            >
              <IconColored
                color={dynamicCollection[folderId]?.derivedColors}
                onClick={onClickEditIconHandler}
                isActive={isEditIcon}
              >
                {svgDynamicCollection[dynamicCollection[folderId]?.iconKey]
                  && svgDynamicCollection[dynamicCollection[folderId]?.iconKey]()}
              </IconColored>

              {isEditIcon && (
                <EditDynamicCollectionIcon
                  itemId={folderId}
                  editRef={editRef}
                />
              )}
            </div>
          )}
          <div className={styles('title_input_wrapper')}>
            <Tooltip
              text={dynamicCollection[folderId].name}
              direction="up"
              place="manyPagesHeader"
              isShow={
                !isComponentRename
                && inputRef?.current?.getBoundingClientRect()?.width > 340
              }
            >
              <div
                className={styles('editableTitle', { hide: isComponentRename })}
                ref={inputRef}
                onClick={onTitleClickHandler}
              >
                {tmpTitle
                  || dynamicCollection[folderId].name
                  || t('unnamedSmartViewT')}
              </div>
            </Tooltip>

            {isComponentRename && (
              <div
                className={styles('folderTitleInputWrapper')}
                style={{ width }}
              >
                <input
                  style={{ width }}
                  placeholder={t('folderTitleT')}
                  maxLength={55}
                  onMouseDown={(e) => e.stopPropagation()}
                  value={tmpTitle}
                  autoFocus
                  onChange={(e) => setTmpTitle(e.target.value)}
                  onKeyDown={onKeyPressFolderTitle}
                  className={styles('input2')}
                  onBlur={renameHandler}
                />
              </div>
            )}
          </div>

          {!props.isEdit && (
            <div className={styles('options_wrapper')}>
              <div
                onClick={() => props.setIsEdit((prev) => !prev)}
                className={styles('icon_wrapper')}
                data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
              >
                <EditSvg />
              </div>

              <div
                data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
                onClick={setIsComponentVisible}
                ref={refMenu}
                className={styles('icon_wrapper', {
                  active: isComponentVisible,
                })}
              >
                <MoreSvg />
                {isComponentVisible && (
                  <UnifiedDropDownMenu
                    place="SmartView"
                    clickHandler={() => {}}
                    state={{
                      rename: options.rename(onTitleClickHandler),
                      duplicate: options.duplicate(duplicateHandler),
                      delete: options.delete_no_separator(deleteHandler, true),
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <input
          type="file"
          name="file"
          multiple
          ref={props.inputFile}
          style={{ display: 'none' }}
          onChange={(e) => {
            props.submitForm(e);
          }}
        />
        <div className={styles('top_sorting_and_add_file')}>
          <SortAndView {...props} />

          {!props.isEdit && !props.isManyPages && !props.isManyPlaylists && (
            <div
              className={styles('detailsContainer')}
              ref={props.addFileParentRef}
            >
              <div className={styles('options')}>
                <div className={styles('button_parent_width')}>
                  <PlaylistButton
                    data-parent={props.dataParent}
                    clickCb={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      props.setIsAddFileMenuVisibleHeader(
                        !props.isAddFileMenuVisibleHeader,
                      );
                    }}
                  >
                    <Upload16Svg
                      data-parent={props.dataParent}
                      className={styles('upload_button')}
                    />
                    <div data-parent={props.dataParent}>
                      {!props.hasActiveUploads && t('uploadFileUpT')}
                      {props.hasActiveUploads && t('uploadingUpT')}
                      {props.hasActiveUploads && (
                        <div
                          style={{ width: Math.ceil(props.progress * 125) }}
                          className={styles('upload_line')}
                        />
                      )}
                    </div>
                  </PlaylistButton>
                </div>
              </div>
            </div>
          )}

          {props.isManyPlaylists && (
            <PlaylistButton
              data-parent={props.dataParent}
              clickCb={(event) => {
                event.preventDefault();
                event.stopPropagation();
                props.createNewPage();
              }}
            >
              <Plus />
              <div className={styles('add_playlist_button')}>{t('smartFileT')}</div>
            </PlaylistButton>
          )}
        </div>
        {props.isAddFileMenuVisibleHeader && (
          <AddFilePopUp
            parentRef={props.addFileParentRef}
            wrapperRef={props.addFileRefHeader}
            onButtonClick={props.onButtonClick}
            setIsAddFileMenuVisible={props.setIsAddFileMenuVisibleHeader}
          />
        )}
      </div>
      {props.isEdit && (
        <div
          className={styles('SearchInSmartView_wrapper', {
            is_open_description: componentDescription || metaId,
          })}
        >
          <SearchInSmartView
            isFullScreen={props.isFullScreen}
            libFilterSearch={props.libFilterSearch}
            setLibFilterSearch={props.setLibFilterSearch}
          />
          {props.isEdit && (
            <div className={styles('confirm_button_Smart_view')}>
              <div
                onClick={cancelHandler}
                className={sideBarStyles.confirm_cancel}
              >
                {t('cancelT')}
              </div>
              <div className={sideBarStyles.confirm_create}>
                <PlaylistButton
                  buttonText={t('saveChangesUpT')}
                  clickCb={updateDynamicCollectionHandler}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={styles('wrapper_dynamicCollection', {
          show_description: componentDescription || metaId,
        })}
      >
        <DynamicCollectionCreator
          libFilterSearch={props.libFilterSearch}
          isFullScreen
          isShowFilter
          isEdit={props.isEdit}
          setLibFilterSearch={props.setLibFilterSearch}
          isDynamicCollection
          filter={props.filter}
          isHasSomeFilter={
            (props.filter.tags
              && Object.values(props.filter.tags || {}).length)
            || (props.filter.fileType
              && Object.values(props.filter.fileType || {}).length)
            || (props.filter.createDate
              && Object.values(props.filter.createDate || {}).length)
          }
          setFilter={props.setFilter}
          setIsShowFilter={props.setIsShowFilter}
        />
      </div>
    </div>
  );
};

export default SmartViewHeader;
