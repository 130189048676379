import { EditorState, Modifier, RichUtils } from 'draft-js';

export const markDownByKeyList = {
  '**': 'bold',
  // '*': 'bold',
  '*': 'italicize',
  '```': 'codeStyling',
  '~': 'strikethrough',
};

export const refExp = {
  '**': '^([*]){2}(.+)([*])|(.*)( )([*]){2}(.+)([*])',
  '*': '^([*])([^*]){1}|[ ]{1}([*])([^*]){1}',
  '~': '^([~])([^~]){1}|[ ]{1}([~])([^~]){1}',
  '```': '^([`]){3}(.+)([`]){2}|(.*)( )([`]){3}(.+)([`]){2}',
};

export const markTypeAndHandledKeys = Object.keys(markDownByKeyList).join('');

const markDownByKeyFunc = (arg, key) => {
  const { event, localState, update } = arg;
  const contentState = localState.getCurrentContent();
  const selection = localState.getSelection();
  const newSelectionBySymbol = [];
  const command = markDownByKeyList[key];

  const selectKey = selection.getStartKey();
  const selectOffset = selection.getStartOffset();

  let selectIndex;

  let target = selection;

  const arrayOBlocks = contentState.getBlocksAsArray();

  arrayOBlocks.forEach((block, index) => {
    if (selectKey === block.getKey()) selectIndex = index;
    const text = block.getText();
    if (text.indexOf(key) + 1) {
      if (index === 0 && text.indexOf(key) === 0) {
        newSelectionBySymbol.push({
          textKey: text.indexOf(key),
          blockKey: block.getKey(),
        });
      } else if (text[text.indexOf(key) - 1] === ' ') {
        newSelectionBySymbol.push({
          textKey: text.indexOf(key),
          blockKey: block.getKey(),
          index,
        });
      }
    }
  });

  if (!newSelectionBySymbol.length) return;

  const isEnterSymbolAfterKey = selectIndex > newSelectionBySymbol[0].index
    || selectOffset > newSelectionBySymbol[0].textKey;
  const noSpaceBetweenKey = selectKey === newSelectionBySymbol[0].blockKey
    && selectOffset
      === newSelectionBySymbol[0].textKey + (isEnterSymbolAfterKey ? 1 : 0);

  if (noSpaceBetweenKey || !isEnterSymbolAfterKey) return;
  const needToCheckTargetPlace = key.length > 1;
  if (needToCheckTargetPlace) {
    const textInFocusBlock = arrayOBlocks[selectIndex].getText();
    let check = textInFocusBlock[selectOffset - 1] === key[key.length - 2];
    if (!check) return;

    if (key[key.length - 3]) {
      check = textInFocusBlock[selectOffset - 2] === key[key.length - 3];
    }
    if (!check) return;
  }

  event.preventDefault();
  event.stopPropagation();

  const anchorKey = newSelectionBySymbol[0].blockKey;
  const anchorOffset = newSelectionBySymbol[0].textKey;
  const focusKey = selectKey;
  const focusOffset = selectOffset;

  target = target.merge({
    anchorKey,
    anchorOffset,
    focusKey,
    focusOffset,
    isBackward: false,
  });
  const newContent2 = contentState.merge({
    selectionBefore: selection,
    selectionAfter: target,
  });

  const newContent = EditorState.push(localState, newContent2, 'split-block');

  let updateState;

  if (command === 'bold') {
    updateState = RichUtils.toggleInlineStyle(newContent, 'BOLD');
  }
  if (command === 'italicize') {
    updateState = RichUtils.toggleInlineStyle(newContent, 'ITALIC');
  }
  if (command === 'underline') {
    updateState = RichUtils.toggleInlineStyle(newContent, 'UNDERLINE');
  }
  if (command === 'strikethrough') {
    updateState = RichUtils.toggleInlineStyle(newContent, 'STRIKETHROUGH');
  }
  if (command === 'codeStyling') {
    updateState = RichUtils.toggleInlineStyle(newContent, 'CODE');
  }
  let newContentState = Modifier.replaceText(
    updateState.getCurrentContent(),

    target.merge({
      anchorKey: newSelectionBySymbol[0].blockKey,
      anchorOffset: newSelectionBySymbol[0].textKey,
      focusKey: newSelectionBySymbol[0].blockKey,
      focusOffset: newSelectionBySymbol[0].textKey + key.length,
    }),
    '',
  );
  const needDeleteOtherSymbol = key.length > 1;

  if (needDeleteOtherSymbol) {
    target = target.merge({
      anchorKey: focusKey,
      anchorOffset: focusOffset - key.length - (key.length - 1),
      focusKey,
      focusOffset: focusOffset - key.length,
      isBackward: false,
    });
    newContentState = Modifier.replaceText(newContentState, target, '');
  }
  target = target.merge({
    anchorKey: focusKey,
    anchorOffset: selectOffset - (key.length + key.length - 1),
    focusKey,
    focusOffset: selectOffset - (key.length + key.length - 1),
    isBackward: false,
  });

  // const newSelect = selectionState.merge({
  //   focusKey: blockKey,
  //   anchorKey: anchorKey || blockKey,
  //   anchorOffset: anchorOffset || newOffset,
  //   focusOffset: newOffset,
  // });

  // const newEditorState = EditorState.forceSelection(localState, newSelect);
  const stateWithReturnSelection = EditorState.create({
    currentContent: newContentState,
    selection: target,
  });

  update(stateWithReturnSelection);
};

const markDownByKey = (arg) => {
  const { localState, key: eventKey } = arg;
  const text = localState.getCurrentContent().getPlainText();

  if (arg.localState) {
    Object.keys(markDownByKeyList).some((key) => {
      const re = new RegExp(refExp[key]);

      if (re.test(text) && eventKey === key[0]) {
        markDownByKeyFunc(arg, key);
        return true;
      }
      return false;
    });
  }
};

export default markDownByKey;
