import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { ReactComponent as ApproveButtonSettingsSvg } from '../../../images/icons/icon_20/ApproveButtoSettings.svg';
import { ReactComponent as CheckSvg } from '../../../images/icons/icon_20/check.svg';
import { colorsApprovedButton } from '../../../utils/constants';
import useComponentVisibleMouseDown from '../../../utils/hooks/useComponentVisibleMouseDown';

const cx = classNames.bind(styles);

const ApproveButton = ({ state, saveState, isView }) => {
  const { t } = useTranslation();
  const [ref, isOpenMenu, setOpenMenu] = useComponentVisibleMouseDown(
    false,
    'ApproveButton',
  );
  const [clickedName, setClickedName] = useState(
    state.clickedName || t('approveT'),
  );
  const [color, setColor] = useState(state.color || colorsApprovedButton[0]);
  const [defaultName, setDefaultName] = useState(
    state.defaultName || t('approvedT'),
  );
  const [buttonId, setButtonId] = useState(state.buttonId || t('approveButtonT'));
  const [isClicked, setIsClicked] = useState(false);
  const refQuestion = useRef(null);
  const [questionName, setQuestionName] = useState(
    state.questionName || t('questionNameT'),
  );
  const [prevQuestionName, setPrevQuestionName] = useState(
    state.questionName || '',
  );
  const [isRename, setIsRename] = useState(false);
  const [width, setWidth] = useState(50);
  const saveRef = useRef(null);

  useEffect(() => {
    if (state?.questionName !== questionName) setQuestionName(state?.questionName);
    if (state.clickedName !== clickedName) setClickedName(state.clickedName);
    if (state.color !== color) setColor(state.color);
    if (state.defaultName !== defaultName) setDefaultName(state.defaultName);
    if (state.buttonId !== buttonId) setButtonId(state.buttonId);
  }, [state]);

  const toggleState = (e) => {
    e.stopPropagation();
    setIsClicked(!isClicked);
  };
  const handlerOpenMenu = (e) => {
    e.stopPropagation();
    setOpenMenu((v) => !v);
  };

  useEffect(() => {
    if (isRename) {
      setPrevQuestionName(questionName);
    }
  }, [isRename]);

  useEffect(() => {
    if (refQuestion?.current) setWidth(refQuestion?.current?.getBoundingClientRect().width + 4);
  }, [questionName, isRename]);

  useEffect(() => {
    if (
      !isOpenMenu
      && (clickedName !== state.clickedName
        || defaultName !== state.defaultName
        || color !== state.color
        || questionName !== state.questionName
        || buttonId !== state.buttonId)
    ) {
      const outerHtml = saveRef?.current?.outerHTML;
      saveState(
        { clickedName, defaultName, color, questionName, buttonId },
        outerHtml,
      );
    }
    if (!defaultName) setDefaultName(t('approveT'));
    if (!buttonId) setButtonId(t('approveButtonT'));
    if (!clickedName) setClickedName(t('approvedT'));
  }, [isOpenMenu, isRename]);

  const keyHandler = (e) => {
    if (e.key === 'Escape') {
      setQuestionName(prevQuestionName);
      setIsRename(false);
    }
    if (e.key === 'Enter') {
      setIsRename(false);
    }
    if (e.key === 'Backspace') {
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (isRename) {
      setPrevQuestionName(questionName);
    }
  }, [isRename]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
    if (e.key === 'Backspace') {
      e.stopPropagation();
    }
  };

  return (
    <>
      <div className={cx('button_wrapper')}>
        <div className={cx('question_wrapper')} ref={saveRef}>
          <div
            onClick={() => !isView && setIsRename(true)}
            className={cx('question', { rename: isRename, hoverable: !isView })}
          >
            {isRename && (
              <input
                value={questionName}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (!questionName) setQuestionName(t('questionNameT'));
                  }
                  keyHandler(e);
                }}
                autoFocus
                onBlur={() => {
                  setIsRename(false);
                  if (!questionName) setQuestionName(t('questionNameT'));
                }}
                style={{ width }}
                onChange={(e) => setQuestionName(e.target.value)}
                maxLength={120}
              />
            )}
            {!isRename && (
              <div
                className={cx('question_text', { hidden: isRename })}
                ref={refQuestion}
              >
                {questionName || t('questionNameT')}
              </div>
            )}
          </div>

          <div
            onClick={toggleState}
            className={cx('approve_button', `${color}`, { clicked: isClicked })}
          >
            {isClicked && <CheckSvg />}

            {isClicked && (clickedName || t('approvedT'))}
            {!isClicked && (defaultName || t('approveT'))}
          </div>
        </div>

        {!isView && (
          <div className={cx('button_settings')}>
            {t('approveButtonT')}
            <div
              className={cx('svg_wrapper', { active_item: isOpenMenu })}
              onClick={handlerOpenMenu}
            >
              <ApproveButtonSettingsSvg data-parent="ApproveButton" />
            </div>
            {isOpenMenu && (
              <div ref={ref} className={cx('settings_menu')}>
                <div className={cx('item_wrapper')}>
                  <div className={cx('text', { active: !buttonId.length })}>
                    {t('buttonIDT')}
                  </div>

                  <div className={cx('input_wrapper')}>
                    <input
                      maxLength={55}
                      value={buttonId}
                      onChange={(event) => setButtonId(event.target.value)}
                      placeholder={t('approveButtonT')}
                      onKeyDown={handleKeyDown}
                      onBlur={() => {
                        if (!buttonId) setButtonId('Approve Button');
                      }}
                    />
                  </div>
                </div>
                <div className={cx('item_wrapper')}>
                  <div className={cx('text', { active: !defaultName.length })}>
                    {t('defaultNameT')}
                  </div>

                  <div className={cx('input_wrapper')}>
                    <input
                      maxLength={55}
                      value={defaultName}
                      onChange={(event) => setDefaultName(event.target.value)}
                      placeholder={t('approveT')}
                      onKeyDown={handleKeyDown}
                      onBlur={() => {
                        if (!defaultName) setDefaultName('Approve');
                      }}
                    />
                  </div>
                </div>
                <div className={cx('item_wrapper')}>
                  <div className={cx('text', { active: !clickedName.length })}>
                    {t('clickedNameT')}
                  </div>

                  <div className={cx('input_wrapper')}>
                    <input
                      maxLength={55}
                      value={clickedName}
                      onChange={(event) => setClickedName(event.target.value)}
                      placeholder={t('approvedT')}
                      onKeyDown={handleKeyDown}
                      onBlur={() => {
                        if (!clickedName) setClickedName('Approved');
                      }}
                    />
                  </div>
                </div>
                <div className={cx('item_wrapper')}>
                  <div className={cx('text')}>{t('buttonColorT')}</div>
                  <div className={cx('select_color')}>
                    {colorsApprovedButton.map((selectableColors) => (
                      <div
                        key={selectableColors}
                        onClick={() => setColor(selectableColors)}
                        className={cx('picker_wrapper', {
                          active: selectableColors === color,
                        })}
                      >
                        <div className={cx('picker', `${selectableColors}`)} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ApproveButton;
