export const SUPPORTED_TIME_ZONES = {
  'UTC-11:00': ['Pago Pago', 'Niue', 'Midway'],
  'UTC-10:00': ['Rarotonga', 'Tahiti', 'Honolulu'],
  'UTC-09:30': ['Marquesas'],
  'UTC-09:00': ['Gambier', 'Adak'],
  'UTC-08:00': [
    'Pitcairn',
    'Anchorage',
    'Juneau',
    'Metlakatla',
    'Nome',
    'Sitka',
    'Yakutat',
  ],
  'UTC-07:00': [
    'Creston',
    'Dawson',
    'Dawson Creek',
    'Fort Nelson',
    'Vancouver',
    'Whitehorse',
    'Hermosillo',
    'Tijuana',
    'Los Angeles',
    'Phoenix',
  ],
  'UTC-06:00': [
    'Belize',
    'Cambridge Bay',
    'Edmonton',
    'Inuvik',
    'Regina',
    'Swift Current',
    'Yellowknife',
    'Costa Rica',
    'Galapagos',
    'El Salvador',
    'Guatemala',
    'Tegucigalpa',
    'Chihuahua',
    'Mazatlan',
    'Ojinaga',
    'Managua',
    'Boise',
    'Denver',
  ],
  'UTC-05:00': [
    'Eirunepe',
    'Rio Branco',
    'Atikokan',
    'Rainy River',
    'Rankin Inlet',
    'Resolute',
    'Winnipeg',
    'Cayman',
    'Easter',
    'Bogota',
    'Guayaquil',
    'Jamaica',
    'Bahia Banderas',
    'Cancun',
    'Matamoros',
    'Merida',
    'Mexico City',
    'Monterrey',
    'Panama',
    'Lima',
    'Chicago',
    'Knox',
    'Tell City',
    'Menominee',
    'Beulah',
    'Center',
    'New Salem',
  ],
  'UTC-04:00': [
    'Anguilla',
    'Antigua',
    'Aruba',
    'Nassau',
    'Barbados',
    'La Paz',
    'Kralendijk',
    'Boa Vista',
    'Campo Grande',
    'Cuiaba',
    'Manaus',
    'Porto Velho',
    'Tortola',
    'Blanc-Sablon',
    'Iqaluit',
    'Nipigon',
    'Pangnirtung',
    'Thunder Bay',
    'Toronto',
    'Havana',
    'Curacao',
    'Dominica',
    'Santo Domingo',
    'Grenada',
    'Guadeloupe',
    'Guyana',
    'Port-au-Prince',
    'Martinique',
    'Montserrat',
    'Asuncion',
    'Puerto Rico',
    'St Barthelemy',
    'St Kitts',
    'St Lucia',
    'Marigot',
    'St Vincent',
    'Lower Princes',
    'Port of Spain',
    'Grand Turk',
    'St Thomas',
    'Detroit',
    'Indianapolis',
    'Marengo',
    'Petersburg',
    'Vevay',
    'Vincennes',
    'Winamac',
    'Louisville',
    'Monticello',
    'New York',
    'Caracas',
  ],
  'UTC-03:00': [
    'Palmer',
    'Rothera',
    'Buenos Aires',
    'Catamarca',
    'Cordoba',
    'Jujuy',
    'La Rioja',
    'Mendoza',
    'Rio Gallegos',
    'Salta',
    'San Juan',
    'San Luis',
    'Tucuman',
    'Ushuaia',
    'Bermuda',
    'Araguaina',
    'Bahia',
    'Belem',
    'Fortaleza',
    'Maceio',
    'Recife',
    'Santarem',
    'Sao Paulo',
    'Glace Bay',
    'Goose Bay',
    'Halifax',
    'Moncton',
    'Punta Arenas',
    'Santiago',
    'Stanley',
    'Cayenne',
    'Thule',
    'Paramaribo',
    'Montevideo',
  ],
  'UTC-02:30': ['St Johns'],
  'UTC-02:00': ['Noronha', 'Godthab', 'Miquelon', 'South Georgia'],
  'UTC-01:00': ['Cape Verde'],
  UTC: [
    'Ouagadougou',
    'Banjul',
    'Accra',
    'Danmarkshavn',
    'Scoresbysund',
    'Conakry',
    'Bissau',
    'Reykjavik',
    'Abidjan',
    'Monrovia',
    'Bamako',
    'Nouakchott',
    'Azores',
    'St Helena',
    'Dakar',
    'Freetown',
    'Lome',
  ],
  'UTC+01:00': [
    'Algiers',
    'Luanda',
    'Porto-Novo',
    'Douala',
    'Bangui',
    'Ndjamena',
    'Kinshasa',
    'Malabo',
    'Faroe',
    'Libreville',
    'Guernsey',
    'Dublin',
    'Isle of Man',
    'Jersey',
    'Casablanca',
    'Niamey',
    'Lagos',
    'Madeira',
    'Lisbon',
    'Brazzaville',
    'Sao Tome',
    'Canary',
    'Tunis',
    'London',
    'El Aaiun',
  ],
  'UTC+02:00': [
    'Tirane',
    'Andorra',
    'Troll',
    'Vienna',
    'Brussels',
    'Sarajevo',
    'Gaborone',
    'Bujumbura',
    'Zagreb',
    'Prague',
    'Lubumbashi',
    'Copenhagen',
    'Cairo',
    'Paris',
    'Berlin',
    'Busingen',
    'Gibraltar',
    'Budapest',
    'Rome',
    'Maseru',
    'Tripoli',
    'Vaduz',
    'Luxembourg',
    'Skopje',
    'Blantyre',
    'Malta',
    'Monaco',
    'Podgorica',
    'Maputo',
    'Windhoek',
    'Amsterdam',
    'Oslo',
    'Warsaw',
    'Kaliningrad',
    'Kigali',
    'San Marino',
    'Belgrade',
    'Bratislava',
    'Ljubljana',
    'Johannesburg',
    'Ceuta',
    'Madrid',
    'Khartoum',
    'Longyearbyen',
    'Mbabane',
    'Stockholm',
    'Zurich',
    'Vatican',
    'Lusaka',
    'Harare',
  ],
  'UTC+03:00': [
    'Mariehamn',
    'Syowa',
    'Bahrain',
    'Minsk',
    'Sofia',
    'Comoro',
    'Famagusta',
    'Nicosia',
    'Djibouti',
    'Asmara',
    'Tallinn',
    'Addis Ababa',
    'Helsinki',
    'Athens',
    'Baghdad',
    'Jerusalem',
    'Amman',
    'Nairobi',
    'Kuwait',
    'Riga',
    'Beirut',
    'Vilnius',
    'Antananarivo',
    'Mayotte',
    'Chisinau',
    'Qatar',
    'Bucharest',
    'Kirov',
    'Moscow',
    'Simferopol',
    'Volgograd',
    'Riyadh',
    'Mogadishu',
    'Juba',
    'Damascus',
    'Dar es Salaam',
    'Istanbul',
    'Kampala',
    'Kiev',
    'Uzhgorod',
    'Zaporozhye',
    'Aden',
  ],
  'UTC+04:00': [
    'Yerevan',
    'Baku',
    'Tbilisi',
    'Mauritius',
    'Muscat',
    'Reunion',
    'Astrakhan',
    'Samara',
    'Saratov',
    'Ulyanovsk',
    'Mahe',
    'Dubai',
  ],
  'UTC+04:30': ['Kabul', 'Tehran'],
  'UTC+05:00': [
    'Mawson',
    'Kerguelen',
    'Aqtau',
    'Aqtobe',
    'Atyrau',
    'Oral',
    'Maldives',
    'Karachi',
    'Yekaterinburg',
    'Dushanbe',
    'Ashgabat',
    'Samarkand',
    'Tashkent',
  ],
  'UTC+05:30': ['Kolkata', 'Colombo'],

  'UTC+05:45': ['Kathmandu'],
  'UTC+06:00': [
    'Vostok',
    'Dhaka',
    'Thimphu',
    'Chagos',
    'Urumqi',
    'Almaty',
    'Qyzylorda',
    'Bishkek',
    'Omsk',
  ],
  'UTC+06:30': ['Cocos', 'Yangon'],
  'UTC+07:00': [
    'Davis',
    'Phnom Penh',
    'Christmas',
    'Jakarta',
    'Pontianak',
    'Vientiane',
    'Hovd',
    'Barnaul',
    'Krasnoyarsk',
    'Novokuznetsk',
    'Novosibirsk',
    'Tomsk',
    'Bangkok',
    'Ho Chi Minh',
  ],
  'UTC+08:00': [
    'Casey',
    'Perth',
    'Brunei',
    'Shanghai',
    'Hong Kong',
    'Makassar',
    'Macau',
    'Kuala Lumpur',
    'Kuching',
    'Choibalsan',
    'Ulaanbaatar',
    'Manila',
    'Irkutsk',
    'Singapore',
    'Taipei',
  ],
  'UTC+08:30': ['Pyongyang'],
  'UTC+08:45': ['Eucla'],
  'UTC+09:00': [
    'Dili',
    'Jayapura',
    'Tokyo',
    'Palau',
    'Chita',
    'Khandyga',
    'Yakutsk',
    'Seoul',
  ],
  'UTC+09:30': ['Adelaide', 'Broken Hill', 'Darwin'],
  'UTC+10:00': [
    'DumontDUrville',
    'Brisbane',
    'Currie',
    'Hobart',
    'Lindeman',
    'Melbourne',
    'Sydney',
    'Guam',
    'Chuuk',
    'Saipan',
    'Port Moresby',
    'Ust-Nera',
    'Vladivostok',
  ],
  'UTC+10:30': ['Lord Howe'],
  'UTC+11:00': [
    'Macquarie',
    'Kosrae',
    'Pohnpei',
    'Noumea',
    'Norfolk',
    'Bougainville',
    'Magadan',
    'Sakhalin',
    'Srednekolymsk',
    'Efate',
  ],
  'UTC+12:00': [
    'McMurdo',
    'Fiji',
    'Tarawa',
    'Kwajalein',
    'Majuro',
    'Nauru',
    'Auckland',
    'Anadyr',
    'Kamchatka',
    'Funafuti',
    'Wake',
    'Wallis',
  ],
  'UTC+12:45': ['Chatham'],
  'UTC+13:00': ['Enderbury', 'Apia', 'Fakaofo', 'Tongatapu'],
  'UTC+14:00': ['Kiritimati'],
};

export const calcCurrentLocale = () => {
  const now = new Date();
  const offset = now.getTimezoneOffset();
  const localTimeZone = Math.floor(offset / 60);
  let localZoneStart = 'UTC';
  const absoluteNumber = Math.abs(localTimeZone.toString());
  if (localTimeZone < 0) {
    if (absoluteNumber.length === 2) localZoneStart += `+${Math.abs(localTimeZone.toString())}`;
    else localZoneStart += `+0${Math.abs(localTimeZone.toString())}`;
  } else if (localTimeZone > 0) {
    if (absoluteNumber.length === 2) localZoneStart += `-${Math.abs(localTimeZone.toString())}`;
    else localZoneStart += `-0${Math.abs(localTimeZone.toString())}`;
  } else {
    return localZoneStart;
  }
  return Object.keys(SUPPORTED_TIME_ZONES).filter((zone) => zone.startsWith(localZoneStart),
  )[0];
};
