import React from 'react';
import classNames from 'classnames/bind';
import { ReactComponent as Avatar24Svg } from '../../../images/icons/avatar_24.svg';
import AvatarPlaceholder from '../../../components/Avatar';
import { Avatar } from '../../../components/MainHeader/User';
import rawStyles from './MemberContent.module.scss';

const cn = classNames.bind(rawStyles);

const ContactAvatar = ({ item, isPopup }) => {
  const abbreviation = item.name?.split(' ').map(e => e[0]).join('') ?? '';
  return (
    <div className={cn('contact_avatar_wrapper')}>
      {item.status === 'Invited' ? (
        <div className={cn('contact_avatar_wrapper_placeholder', { is_big: isPopup })}>
          <Avatar24Svg />
        </div>
      ) : (
        item?.avatarUrlVerySmall ? (
          <Avatar
            className="avatar_border_hover"
            width={isPopup ? 38 : 26}
            src={item?.avatarUrlVerySmall}
            alt="avatar"
            noMargins
          />
        ) : (
          <AvatarPlaceholder
            width={isPopup ? 38 : 26}
            name={item?.name}
            abbreviation={abbreviation}
          />
        )
      )}
    </div>
  );
};

export default ContactAvatar;
