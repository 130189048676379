import React from 'react';
import styled from 'styled-components/macro';

const ErrorDiv = styled.div`
  color: #D32F2F;
  text-align: right;
  font-family: RobotoFlex, sans-serif;
  font-size: 12px;
  margin-top: 3px;
`;


const ErrorSubField = ({ error }) => {
  return (
    <ErrorDiv active={error}>
      {!!error && (<>{error}</>)}
    </ErrorDiv>
  );
};

export default ErrorSubField;
