import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { actionCloseModal, actionSwitchFolder } from '../../redux/user/action';
import Button2023 from '../Buttons/Button2023';
import { actionCreateChannel } from '../../redux/channels/action';

const ModalWrapper = styled.div`
  position: fixed;
  background: rgba(147, 147, 147, 0.6);
  z-index: 104;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ModalWindow = styled.div`
  width: 333px;
  height: 152px;
  border-radius: 12px;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px 0 #7a696940;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #ece2e2, #ece2e2);
  display: flex;
  flex-direction: column;
  padding: 19px 2px 3px 19px;
`;

const TitleWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #d3c8c8;
  margin-bottom: 14px;
  font-family: "RobotoFlex", sans-serif;
`;

const InputWrapper = styled.input`
  height: 32px;
  width: 293px;
  border-radius: 6px;
  border: 1px solid #7a696980;
  padding-left: 8px;
  &::placeholder {
    color: #7a696980;
    font-weight: 400;
    font-size: 15px;
  }
  &:focus {
    border: 1px solid #ffc72c;
  }
`;
const ButtonBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 135px;
  padding-right: 17px;
  padding-top: 19px;
`;

const CreateChannelModal = () => {
  const [newTitle, setNewTitle] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const close = () => {
    dispatch(actionCloseModal());
  };
  const listenForTitle = (e) => {
    setNewTitle(e.target.value);
  };
  const saveTitle = () => {
    const channelID = uuidv4();

    dispatch(
      actionCreateChannel(newTitle.trim(), channelID, () => {
        dispatch(actionSwitchFolder('folder', channelID));
        history.push(`/channel/${channelID}`);
      }),
    );
    close();
  };

  const enterFoo = (e) => {
    if (e.key === 'Enter') {
      saveTitle();
    }
  };
  return (
    <ModalWrapper onClick={close} onKeyPress={enterFoo}>
      <ModalWindow onClick={(e) => e.stopPropagation()}>
        <TitleWrapper>{t('createNewChannelUpT')}</TitleWrapper>
        <InputWrapper
          autoFocus
          placeholder={t('channelNamePlaceholderT')}
          value={newTitle}
          onChange={listenForTitle}
        />
        <ButtonBlockWrapper>
          <Button2023
            variant="primary"
            width={69}
            height={32}
            text={t('cancelUpT')}
            noMargins
            handleButtonClick={close}
          />
          <Button2023
            variant="secondary"
            height={32}
            text={t('createUpT')}
            noMargins
            handleButtonClick={saveTitle}
          />
        </ButtonBlockWrapper>
      </ModalWindow>
    </ModalWrapper>
  );
};

export default CreateChannelModal;
