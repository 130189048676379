// import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';


const value = (text) => `{"root":{"children":[{"children":[{"format":0, "version":1, "text":"${text}", "type":"text"}],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}`;

const convertDraftStateToLexicalState = (contentState) => {
  return contentState;

  // const text = contentState.getCurrentContent().getPlainText('');
  // return JSON.parse(value(text));
};

export default convertDraftStateToLexicalState;
