import React from 'react';
import { useTranslation } from 'react-i18next';
import { DescriptionS } from './styled';

export const Description = ({
  handleKeyPressTitle,
  handleUNDO,
  onBlur,
  descriptionRef,
  setStateDescription,
  value,
  setDescriptionFocused,
  isDescription,
  isDescriptionFocused,
  disableChanges,
}) => {
  const { t } = useTranslation();
  const goDown = (id) => {
    id.scrollTo({
      top: id.clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onChangeDescription = (event) => {
    setStateDescription(event.target.value);

    if (descriptionRef?.current) {
      goDown(descriptionRef?.current);
    }
  };

  return (
    <DescriptionS
      data-cy="descriptionEntity"
      placeholder={t('descriptionOptionalPlaceholderT')}
      onKeyPress={handleKeyPressTitle}
      onKeyDown={handleUNDO}
      onBlur={onBlur}
      ref={descriptionRef}
      maxLength="1000"
      onChange={onChangeDescription}
      value={value}
      isDescription={isDescription || false}
      withScroll={isDescriptionFocused}
      onClick={(e) => {
        if (!isDescriptionFocused) {
          setDescriptionFocused(true);
          goDown(descriptionRef?.current);
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length,
          );
        }
      }}
      disabled={disableChanges}
      rows={4}
    />
  );
};
