import React, { useState, useEffect, useCallback, useRef, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import { actionCloseAIModal, actionOpenModal } from '../../redux/user/action';
import Button2023 from '../Buttons/Button2023';
import { actionCreator } from '../../shared/redux/actionHelper';
import { CurrentPage } from '../../redux/currentPage/types';
import { openModalType } from '../../utils/constants';
import { calcItemName } from '../../utils/ai/helper';
import { IRootState } from '../../redux/reducers';

const cx = classNames.bind(styles);

interface ILinkPage {
  id: string,
}

const AIChatModal:React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const itemsRef: RefObject<HTMLDivElement> | null = useRef(null);
  const [userTask, setUserTask] = useState<string>('');
  const [dataIds, setDataIds] = useState<string[]>([]);
  const [data, setData] = useState<string[]>([]);
  const [selectedAIFeature, setSelectedAIFeature] = useState<string>('Summarize');

  const [selectedAIFeatureForSend, setSelectedAIFeatureForSend] = useState('Summarize');


  const close = () => {
    dispatch(actionCloseAIModal());
  };
  const {
    linkPages,
    manager,
    currentSummarize,
    id,
    wrapperId,
  } = useSelector((state: IRootState) => state.currentPage);

  const singleClickHandler = useCallback((event: React.MouseEvent, index: number) => {
    const elemPosition = itemsRef?.current?.getBoundingClientRect();
    dispatch(
      actionOpenModal(openModalType.UPVPreview,
        { id, index, mode: 'UPV', elemPosition, linkPages: data }),
    );
  }, [id, dispatch, data]);

  const handleSend = () => {
    if (selectedAIFeatureForSend === 'Summarize') {
      dispatch(
        actionCreator(CurrentPage.Summarize, {
          linkPages,
        }),
      );
    }
    if (selectedAIFeatureForSend === 'Find me something') {
      dispatch(
        actionCreator(CurrentPage.requestAISearch, {
          userTask,
          playlistManagerId: manager?.id ?? wrapperId,
          setDataIds,
        }),
      );
    }
  };

  useEffect(() => {
    const linkPagesMap = linkPages.reduce((acc: {[key:string]:ILinkPage}, cur: ILinkPage) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
    const responseLinkPages = dataIds.map(mapId => linkPagesMap[mapId]);
    setData(responseLinkPages);
  }, [dataIds, linkPages]);

  const calcedDescription = () => {
    if (selectedAIFeature === 'Summarize') {
      return `Put simply, AI summarization is the use of AI technologies to distill text,
      documents, or content into a short and easily digestible format.For example,
      AI summarization can use natural language processing or understanding to condense a
      long PDF and restate its most important takeaways in just a few sentences.`;
    }
    if (selectedAIFeature === 'Generate question') {
      return `This feature uses generative AI to easily generate customizable
       (length and other variables can be changed based on preferences) questions for documents. 
      And with Document users can get answers to natural language questions about their documents.`;
    }
    if (selectedAIFeature === 'Find me something') {
      return `This feature allows to find Smartfile Items to fit users request`;
    }
  };

  return (
    <div onMouseDown={close} className={cx('modal_wrapper')}>
      <div
        onMouseDown={(e) => e.stopPropagation()}
        className={cx('modal', 'Summarize', 'AI_Chat')}
      >
        <h3 className={cx('title')}>Quidzi guide</h3>
        <div className={cx('split_wrapper')}>
          <div className={cx('split_left')}>
            Help me create
            <div className={cx('buttons_wrapper')}>
              <button
                className={cx('premade_button')}
                type="button"
                onMouseEnter={() => setSelectedAIFeature('Summarize')}
                onClick={() => setSelectedAIFeatureForSend('Summarize')}
              >
                Summarize
              </button>
              <button
                className={cx('premade_button')}
                type="button"
                onMouseEnter={() => setSelectedAIFeature('Generate question')}
                onClick={() => setSelectedAIFeatureForSend('Generate question')}
              >
                Generate question
              </button>
              <button
                className={cx('premade_button')}
                type="button"
                onMouseEnter={() => setSelectedAIFeature('Find me something')}
                onClick={() => setSelectedAIFeatureForSend('Find me something')}
              >
                Find me something
              </button>
            </div>
            <b>
              {selectedAIFeature}
            </b>
            {calcedDescription()}
          </div>
          <div className={cx('split_right')}>
            Overview
            <div className={cx('chat_wrapper')}>
              {selectedAIFeatureForSend === 'Summarize' && currentSummarize}
              {selectedAIFeatureForSend === 'Find me something'
                && data.map((lp, index) => (
                  <div onClick={(e) => singleClickHandler(e, index)}>- {calcItemName(lp, t)}</div>))}
            </div>
            Suggested questions
            <div className={cx('chat_wrapper')}>
              -Please summarize SmartFile
              <br/>
              -Something else
            </div>
          </div>
        </div>
        <div className={cx('buttons_group')}>
          <div className={cx('selected_item_wrapper')}>
            <p className={cx('premade_button')}>{selectedAIFeatureForSend}</p>
          </div>
          <div className={cx('embed_input_wrapper')}>
            <input
              type="text"
              className={cx('embed_input')}
              value={userTask}
              onChange={(e) => setUserTask(e.target.value)}
              placeholder={t('askAnythingT')}
              disabled={selectedAIFeatureForSend === 'Summarize'}
            />
          </div>
          <button className={cx('submit_button')} onClick={handleSend}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default AIChatModal;
