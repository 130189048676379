import styled from 'styled-components/macro';

export const InformerSpinnerDiv = styled.div`
  ${({ isForMakerTable }) => (isForMakerTable ? `
     width: 18px  !important;
     height: 18px !important;
     top: -48px  !important;
     left: 7px  !important;
  ` : '')}
  ${({ isForMakerCard }) => (isForMakerCard ? `
  width: 18px !important;
  height: 18px !important;
  top: 10px !important;
  left: 136px !important;
  ` : '')}
${({ isForMakerExpand }) => (isForMakerExpand ? `
    width: 18px !important;
    height: 18px !important;
    top: -32px !important;
    left: 482px !important;
` : '')}
  ${({ forTable }) => forTable && 'top: - 49px !important;'}
  ${({ isAvatar }) => isAvatar
    && `
   width: 32px  !important;
   height: 32px !important;
   top: -58px !important;
   left: -41px !important;
  `}
  ${({ isMaker }) => isMaker
    && `
   top: -41px !important;
   left: -13px !important;
  `}
`;
