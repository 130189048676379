export const GET_PAGE = 'GET_PAGE';
export const ADD_PAGE = 'ADD_PAGE';

export const EditPage = {
  UpdatePages: '[EditPage] Download pages ',
  RenameFolder: '[EditPage] Rename Folder ',
  DownloadPage: '[EditPage] Download page ',
  ChangeEditableBlockRedux: '[EditPage] Change Editable Block Redux',
  ChangeEditableBlock: '[EditPage] Change Editable Block',
  AddEditableBlockRedux: '[EditPage] Add Editable Block Redux',
  AddEditableBlock: '[EditPage] Add Editable Block ',
  AddNewEmptyEditableBlock: '[EditPage] Add new empty Editable Block ',
  ChangeTypeEditableBlock: '[EditPage] Change type Editable Block',
  AddManyEditableBlock: '[EditPage] Add many Editable Block ',
  DeleteEditableBlock: '[EditPage] Delete Editable Block ',
  RestoreAfterDeleteEditableBlock:
    '[EditPage] Restore After Delete Editable Block ',
  RestoreAfterDeleteManyEditableBlock:
    '[EditPage] Restore After Delete MANY Editable Block ',
  DeleteEditableBlockRedux: '[EditPage] Delete Editable Block Redux',
  DeleteManyBlocks: '[EditPage] Delete Many Blocks ',
  DeleteManyBlocksRedux: '[EditPage] Delete Many Blocks Redux',
  AddManyBlocksRedux: '[EditPage] Add Many Blocks Redux',
  AddBlock: '[EditPage] Add Block',
  RemoveBlock: '[EditPage] Remove Block',
  DragManyBlock: '[EditPage] Drag Many Editable Block ',
  DragManyBlockRedux: '[EditPage] Drag Many Editable Block Redux',
  CreateFolderForPages: '[EditPage] Create new folder for pages ',
  DragPageInNewFolder: '[EditPage] Create new page and add draggable page',
  MovePageToFolder: '[EditPage] Move page to folder ',
  RenamePageToFolder: '[EditPage] Rename page to folder ',
  ClearIsNewFolder: '[EditPage] Clear IsNew Folder',
  DeletePage: '[EditPage] Delete page ',
  MovedToTrashPage: '[EditPage] Page moved to trash',
  MovedFromTrashPage: '[EditPage] Page moved from trash',
  DeleteManyPage: '[EditPage] Delete all page in folder ',
  DeleteFolder: '[EditPage] Delete folder ',
  MoveFolderToTrash: '[EditPage] Move folder to trash',
  MoveFolderFromTrash: '[EditPage] Move folder from trash',
  MovePage: '[EditPage] Move page in left side bar ',
  CreateNewPageInFolder: '[EditPage] Create New Page in folder ',
  DuplicatePage: '[EditPage] Duplicate Page ',
  DuplicateSharedPage: '[EditPage] Duplicate Shared Page ',
  ToggleFavorite: '[EditPage] Switching the status of being added to favorites',
  ToggleFavoritePageInChannel: '[EditPage] Toggle Favorite Page In Channel',
  TogglePublishPage: '[EditPage] Toggle Publish Page',
  AddPageToChannelR: '[EditPage] Add Page To Channel REDUX',
  RemovePageFromChannelR: '[EditPage] Remove Page From Channel REDUX',
  AddUserToSharing: '[EditPage] Add User To Sharing',
  RemoveUserToSharing: '[EditPage] Remove User To Sharing',
  SendEmailToSharingS: '[EditPage] Start Send email to User To Sharing SAGE',
  SendingOnEmail: '[EditPage] Sending email to User To Sharing REDUX',
  addDuplicateBlock: '[EditPage] add duplicate block',
  multipleDuplicateBlock: '[EditPage] multiple duplicate block',
  ResizeImageInBlock: '[EditPage] resize image in block',
  SeparateBlock: '[EditPage] separate block by enter',
  ConvertBlockIntoAddMedia: '[EditPage] Convert Block Into AddMedia',
  ConvertBlockIntoEmbed: '[EditPage] Convert Block Into Embed',
  ShareMultiplePagesS: '[EditPage] Share Multiple Pages SAGAS',
  editShareStatePageSingleUser: '[EditPage]Edit Share State Pages  for single page SAGAS',
  editShareStateToWeb: '[EditPage] Edit Share to web State Page  for single page SAGAS',
  sendEventUpdatePage: '[EditPage] send event about page is update SAGAS',
};
