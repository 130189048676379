import React from 'react';
import { withTranslation } from 'react-i18next';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    if (this.props.catchCallback) this.props.catchCallback();
  }

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      return <p>{t('errorBoundaryT')}</p>;
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
