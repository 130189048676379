import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import {
  actionShowMessage,
} from '../../redux/support/action';
import {
  DATA_PARENTS, DEFAULT_TITLE,
  empty,
  ITEM,
  MessageType,
  openModalType,
  UiComponentTypes,
} from '../../utils/constants';
import {
  actionBlockUnloading,
  actionOpenModal,
  actionUnblockUnloading,
} from '../../redux/user/action';
import CardViewWrap from './CardViewWrap';
import {
  actionDuplicateComponent,
  actionDuplicatePage,
  actionUpdateLibraryComponent,
} from '../../redux/library/actions';
import { getFileExt, getFileName } from '../../utils/helpers';
import SupportAction from '../../redux/support/types';
import { actionCreator } from '../../shared/redux/actionHelper';
import { Channels } from '../../redux/channels/types';
import { actionDownloadFile } from '../../redux/downloads/action';
import { ServiceUser } from '../../redux/user/types';
import { LibraryComponents } from '../../redux/library/types';

const MiniPage = React.memo(function MiniPage({
  item = {},
  scrollDelta,
  isUPV,
  dataP,
  index,
  isFavorite,
  isChannelMiniPage,
  isSelected,
  dragStartHandler,
  dragEndHandler,
  onCompositeClickHandler,
  toggleSelected,
  singleClickHandler,
  isMenuActive,
  ...props
}) {
  const [refMenu, isMenuVisible, setIsMenuVisible] = useComponentVisible(
    false,
    DATA_PARENTS.PlaylistBuilderLibrary + item.id,
  );
  const [refItem, isComponentRename, setIsComponentRename] = useComponentVisible(null);
  const currentPage = useSelector(state => state.currentPage);
  const history = useHistory();
  const { t } = useTranslation();

  const miniPage = useSelector(state => state.miniPages[item.id] || empty);
  const componentDescription = useSelector(state => state.support?.componentDescription);
  const { isUnloadingBlocked } = useSelector(state => state.user);
  const usedLibraryComponents = useSelector(
    state => state.currentPage.usedLibraryComponents || empty,
  );

  const [isBlurred, setIsBlurred] = useState(false);
  const [title, setTitle] = useState(item.title);
  const inputEl = useRef(null);
  const refPage = useRef(null);

  const isDownload = true;
  const isComponentExtensionNeeded = !UiComponentTypes.component[item.type]
    && !UiComponentTypes.page[item.type]
    && !UiComponentTypes.link[item.type];
  const isPageEditor = history.location.pathname.includes('libraryPage');
  const showDescription = miniPage.isShowDescription;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isMenuVisible && isMenuActive) {
      dispatch(actionCreator(SupportAction.SetActiveMenuItemId));
    }
  }, [isMenuVisible, isMenuActive]);

  useEffect(() => {
    if (
      (isSelected || currentPage.idDragPage === item.id)
      && !isBlurred
      && (currentPage.idDragPage || currentPage.isDragManyPage)
    ) {
      setIsBlurred(true);
    }
    if (isBlurred && !(currentPage.idDragPage || currentPage.isDragManyPage)) {
      setIsBlurred(false);
    }
    if (isBlurred && !isSelected) {
      setIsBlurred(false);
    }
  }, [isSelected, currentPage.idDragPage, currentPage.isDragManyPage]);

  const isDownloadDisabled = UiComponentTypes.component[item?.type]
    || UiComponentTypes.link[item?.type]
    || item?.type === 'unknown_type';

  useEffect(() => {
    setTitle(item.title);
  }, [item.title]);

  useEffect(() => {
    if (isComponentRename && item.title !== title && !isUnloadingBlocked) dispatch(actionBlockUnloading());
  }, [title]);

  const onClickShowEditMenu = useCallback((e) => {
    e.stopPropagation();
    setIsMenuVisible(!isMenuVisible);
    dispatch(actionCreator(SupportAction.SetActiveMenuItemId, { id: item?.id }));
  }, [isMenuVisible, item?.id, dispatch, setIsMenuVisible]);

  const saveTitle = useCallback(() => {
    if (title.trim()) {
      const newTitle = isComponentExtensionNeeded
        ? `${title || 'unnamed'}.${getFileExt(item.title)}`
        : title.trim();

      if (newTitle !== item.title) {
        dispatch(
          actionUpdateLibraryComponent(
            {
              id: item.id,
              value: newTitle,
              field: 'title',
            },
            item.folderId,
          ),
        );
      }
    } else setTitle(item.title);
    setIsComponentRename(false);
    dispatch(actionUnblockUnloading());
  }, [
    title,
    item.title,
    item.id,
    item.folderId,
    isComponentExtensionNeeded,
    setIsComponentRename,
    dispatch,
  ]);

  useEffect(() => {
    // prevent initial case
    if (isComponentRename === null) return;

    if (refItem && refItem.current) refItem.current.focus();
    if (!isComponentRename) {
      setTitle(item.title);
      if (!refItem.current) {
        const newTitle = isComponentExtensionNeeded
          ? `${title || 'unnamed'}`
          : title?.trim();
        if (newTitle !== item.title) {
          saveTitle();
        }
      }
    }
  }, [isComponentRename]);

  const toggleFavorite = useCallback((e) => {
    e.stopPropagation();
    if (isMenuVisible) setIsMenuVisible(false);
    if (!item.isFavorite) {
      dispatch(
        actionShowMessage({
          type: MessageType.PageToFavorite,
          itemName: item?.title || t('defaultSmartPageTitleT'),
        }),
      );
    }
    if (isFavorite && refPage.current) {
      refPage.current.className = `${refPage.current.className}${
        currentPage.isCardView ? 'to_delete' : 'to_delete_row'
      }`;
      setTimeout(() => {
        dispatch(
          actionCreator(LibraryComponents.ToggleFavorite, {
            componentId: item.id,
            isFavorite: !item.isFavorite,
            libraryComponentId: item.libraryComponentId,
          }),
        );
      }, 1000);
    } else {
      dispatch(
        actionCreator(LibraryComponents.ToggleFavorite, {
          componentId: item.id,
          isFavorite: !item.isFavorite,
          libraryComponentId: item.libraryComponentId,
        }),
      );
    }
  }, [
    isMenuVisible,
    item.isFavorite,
    isFavorite,
    refPage,
    currentPage.isCardView,
    item.id,
    item.libraryComponentId,
    item?.title,
    dispatch,
    setIsMenuVisible,
  ]);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter' && isComponentRename) {
      e.preventDefault();
      saveTitle();
      setIsComponentRename(false);
    }
    e.stopPropagation();
  }, [isComponentRename, setIsComponentRename, saveTitle]);

  const openRenameItem = useCallback((e) => {
    e.stopPropagation();
    if (isComponentExtensionNeeded) setTitle(getFileName(item.title));
    setIsMenuVisible(false);
    setIsComponentRename(true);
  }, [item.title, setIsMenuVisible, setIsComponentRename, isComponentExtensionNeeded]);

  const duplicatePage = useCallback((e) => {
    e.stopPropagation();
    setIsMenuVisible(false);
    dispatch(
      actionShowMessage({
        type: MessageType.ItemToCopy,
        itemName: item?.title || t('defaultSmartPageTitleT'),
      }),
    );
    if (item.type === 'page') {
      dispatch(actionDuplicatePage(item.folderId, item.id));
    } else {
      dispatch(actionDuplicateComponent(item.folderId, item.id));
    }
  }, [item.id, item.folderId, item.title, item.type, dispatch, setIsMenuVisible]);

  const onDownloadHandleClick = useCallback((e) => {
    if (!isDownloadDisabled) {
      dispatch(
        actionDownloadFile({
          id: item.id,
          folderId: item.folderId,
          url: item.urlFile,
        }),
      );
    }
    e.stopPropagation();
    e.preventDefault();
  }, [isDownloadDisabled, item.id, item.folderId, item.urlFile, dispatch]);

  const onAddPageTagClickHandler = useCallback(() => {
    dispatch(actionOpenModal(openModalType.AddTagModal, { id: item.id }));
    setTimeout(() => {
      dispatch(
        actionCreator(SupportAction.JumpTo, { anchorId: 'meta_tags_input' }),
      );
    }, 100);
  }, [item.id, dispatch]);

  const onDeleteItem = useCallback((e) => {
    e.stopPropagation();
    setIsMenuVisible(false);
    dispatch(
      actionCreator(SupportAction.CheckIsInUse, {
        itemTitle: item?.title || t('defaultSmartPageTitleT'),
        counterSelectedPage: 1,
        itemId: item.id,
      }),
    );
  }, [item.id, item.title, dispatch, setIsMenuVisible]);

  const removeContentFromChannel = useCallback(() => {
    if (!isChannelMiniPage) return;
    dispatch(
      actionCreator(Channels.RemoveContentInSharedChannel, {
        contentId: item.id,
        type: ITEM.LibraryPage,
      }),
    );
  }, [isChannelMiniPage, item.id, dispatch]);

  const conditionForExtendedComponent = (
    item.type === 'page'
    || item.type === 'sharedPage'
    || item.type === 'application/pdf'
  ) && (!props.isMixed || isChannelMiniPage);

  const dragStartCb = useCallback((e) => {
    if (isComponentRename) {
      saveTitle();
      setIsComponentRename(false);
    }
    dragStartHandler(e, item);
  }, [
    isComponentRename,
    saveTitle,
    setIsComponentRename,
    dragStartHandler,
    item,
  ]);

  const dragEndCb = useCallback(() => {
    dragEndHandler(item);
  }, [item, dragEndHandler]);

  const onCompositeClickHandlerCb = useCallback((event) => {
    onCompositeClickHandler(event, item, index, isSelected);
  }, [onCompositeClickHandler, item, index, isSelected]);

  const toggleSelectedCb = useCallback((event) => {
    toggleSelected(event, item, isSelected);
  }, [toggleSelected, item, isSelected]);

  const singleClickHandlerCb = useCallback((event) => {
    singleClickHandler(event, item, index);
  }, [singleClickHandler, item, index]);

  return (
    <CardViewWrap
      dataP={dataP}
      isExtended={conditionForExtendedComponent}
      isUPV={isUPV}
      singleClickHandler={singleClickHandlerCb}
      needToDelete={props.needToDelete}
      isDownload={isDownload}
      isSelected={isSelected}
      isBlurred={isBlurred}
      refPage={refPage}
      dragEndHandler={dragEndCb}
      dragStartHandler={dragStartCb}
      toggleSelected={toggleSelectedCb}
      item={item}
      title={title}
      permissions={props.permissions}
      handleKeyPress={handleKeyPress}
      setTitle={setTitle}
      refItem={refItem}
      isComponentRename={isComponentRename}
      setIsComponentRename={setIsComponentRename}
      toggleFavorite={toggleFavorite}
      isMenuVisible={isMenuVisible}
      setIsMenuVisible={setIsMenuVisible}
      inputEl={inputEl}
      onDeleteItem={onDeleteItem}
      openRenameItem={openRenameItem}
      refMenu={refMenu}
      duplicatePage={duplicatePage}
      showDescription={showDescription}
      saveTitle={saveTitle}
      onClickShowEditMenu={onClickShowEditMenu}
      index={index}
      scrollDelta={scrollDelta}
      parentType={props.parentType}
      builderSelectedIds={props.builderSelectedIds}
      isChannelMiniPage={isChannelMiniPage}
      isPageUsed={usedLibraryComponents[item?.id]}
      removeContentFromChannel={removeContentFromChannel}
      onDownloadHandleClick={onDownloadHandleClick}
      onAddTagClickHandler={onAddPageTagClickHandler}
      isDownloadDisabled={isDownloadDisabled}
      onCompositeClickHandler={onCompositeClickHandlerCb}
      isPdf={item.type === 'application/pdf'}
      isPageEditor={isPageEditor}
      isComponentUsed={usedLibraryComponents[item?.id]}
      isComponentDescriptionOpened={componentDescription}
      isMenuActive={isMenuActive}
    />
  );
});

export default MiniPage;
