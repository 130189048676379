import Contacts from './types';

export const actionAddContactR = (item) => ({
  type: Contacts.AddContactR,
  payload: { item },
});
export const actionChangeContactR = (item) => ({
  type: Contacts.ChangeContactR,
  payload: { item },
});
export const actionBulkAddContact = (emailsToInvite) => ({
  type: Contacts.BulkAddContact,
  payload: { emailsToInvite },
});
export const actionRemoveContact = (contactId) => ({
  type: Contacts.RemoveContact,
  payload: { contactId },
});
export const actionLoadUserContactsS = (id) => ({
  type: Contacts.LoadUserContactsS,
  payload: { id },
});
export const actionInitialContactsLoadR = (contactNicknameMap) => ({
  type: Contacts.InitialContactsLoadR,
  payload: { contactNicknameMap },
});
export const actionChangeContactStatus = (id, status) => ({
  type: Contacts.ChangeContactStatus,
  payload: { id, status },
});
export const actionEditContact = (item) => ({
  type: Contacts.EditContact,
  payload: { item },
});

export const actionCreateContact = (item) => ({
  type: Contacts.CreateContact,
  payload: { item },
});
