import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import { ReactComponent as SortCheck2023 } from '../../images/2023/svg/small/sort_check_24_24.svg';
import { LOCAL_SORT_MODE, LOCAL_SORT_TYPE } from './helpers/SortOptionENUM';
import { capitalizeFirstLetter } from '../../utils/helperText';
import {
  filterByPlacement,
  sortByMode,
  sortByType,
} from './helpers/newSorting';
import {
  actionSwitchContentSortType,
  changePrevSortType,
} from '../../redux/content/actions';
import { DATA_PARENTS } from '../../utils/constants';
import { actionChangeSortSagas } from '../../redux/settings/actions';

const Divider = styled.div`
  cursor: default;
  background: #1d1d1d14;
  height: 1px;
  width: 118px;
  margin-left: 16px;
  margin-top: ${({ isLast }) => (isLast ? '7px' : '5px')};
  margin-bottom: 5px;
`;
const SortOption = ({ ...props }) => {
  const [localSortType, setLocalSortType] = useState('');
  const [localSortMode, setLocalSortMode] = useState('');
  const globalSortType = useSelector((s) => s?.content?.sortType);
  const prevSortType = useSelector((s) => s?.content?.prevSortType) || LOCAL_SORT_TYPE.MODIFIED;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handlerClickType = (key) => () => {
    if (key === LOCAL_SORT_TYPE.RECENT) {
      if (localSortType !== LOCAL_SORT_TYPE.RECENT) {
        dispatch(changePrevSortType(localSortType));
      }
      if (localSortType === key) {
        setLocalSortType(prevSortType);
        const sortType = sortByType(prevSortType, localSortMode, props.setSort);
        dispatch(actionChangeSortSagas(sortType));
        dispatch(actionSwitchContentSortType(sortType));
        return;
      }
      setLocalSortType(key);
      dispatch(actionChangeSortSagas('recent'));
      dispatch(actionSwitchContentSortType('recent'));
      props.setSort('recent');
      return;
    }
    setLocalSortType(key);
    const sortType = sortByType(key, localSortMode, props.setSort);
    dispatch(actionSwitchContentSortType(sortType));
    dispatch(actionChangeSortSagas(sortType));
  };

  const handlerClickMode = (key) => () => {
    setLocalSortMode(key);
    const sortType = sortByMode(key, localSortType, props.setSort);
    dispatch(actionSwitchContentSortType(sortType));
    dispatch(actionChangeSortSagas(sortType));
  };

  const isActiveType = (key) => {
    return key === localSortType;
  };
  const isActiveMode = (index) => {
    return Object.keys(LOCAL_SORT_MODE)[index] === localSortMode;
  };

  useEffect(() => {
    switch (globalSortType) {
      case 'recent':
        setLocalSortType(LOCAL_SORT_TYPE.RECENT);
        break;
      case 'nameAtoZ':
        setLocalSortType(LOCAL_SORT_TYPE.NAME);
        setLocalSortMode(LOCAL_SORT_MODE.ASCENDING);
        break;
      case 'firstModified':
        setLocalSortType(LOCAL_SORT_TYPE.MODIFIED);
        setLocalSortMode(LOCAL_SORT_MODE.ASCENDING);
        break;
      case 'firstAdded':
        setLocalSortType(LOCAL_SORT_TYPE.CREATED);
        setLocalSortMode(LOCAL_SORT_MODE.ASCENDING);
        break;
      case 'nameZtoA':
        setLocalSortType(LOCAL_SORT_TYPE.NAME);
        setLocalSortMode(LOCAL_SORT_MODE.DESCENDING);
        break;
      case 'lastModified':
        setLocalSortType(LOCAL_SORT_TYPE.MODIFIED);
        setLocalSortMode(LOCAL_SORT_MODE.DESCENDING);
        break;
      case 'lastAdded':
        setLocalSortType(LOCAL_SORT_TYPE.CREATED);
        setLocalSortMode(LOCAL_SORT_MODE.DESCENDING);
        break;
      case 'typeAtoZ':
        setLocalSortType(LOCAL_SORT_TYPE.TYPE);
        setLocalSortMode(LOCAL_SORT_MODE.ASCENDING);
        break;
      case 'typeZtoA':
        setLocalSortType(LOCAL_SORT_TYPE.TYPE);
        setLocalSortMode(LOCAL_SORT_MODE.DESCENDING);
        break;
    }
  }, []);

  return (
    <div
      data-cy="sortByMenu"
      className={styles.sort_menu}
      data-parent={`${
        props.newUPVMode ? DATA_PARENTS.PlaylistBuilderLibrary : ''
      } `}
    >
      <div className={styles.sort_menu_title}>{t('sortByT')}</div>
      {filterByPlacement(props.place).map((key, index) => (
        <div key={LOCAL_SORT_TYPE[key]}>
          <div
            onClick={
              index !== 0 && localSortType === LOCAL_SORT_TYPE.RECENT
                ? (e) => {
                  e.stopPropagation();
                }
                : handlerClickType(key)
            }
            data-parent={`${
              props.newUPVMode ? DATA_PARENTS.PlaylistBuilderLibrary : ''
            } `}
            key={LOCAL_SORT_TYPE[key] + index}
            className={`${styles.editMenu__item} ${
              index !== 0 && localSortType === LOCAL_SORT_TYPE.RECENT
                ? styles.editMenu__item_freezed
                : ''
            }`}
          >
            <div
              data-parent={`${
                props.newUPVMode ? DATA_PARENTS.PlaylistBuilderLibrary : ''
              } `}
              className={`${styles.editMenu__text}  ${
                index !== 0 && localSortType === LOCAL_SORT_TYPE.RECENT
                  ? styles.editMenu__item_freezed
                  : ''
              }`}
            >
              {capitalizeFirstLetter(t(`${LOCAL_SORT_TYPE[key].toLowerCase()}FilterT`))}
            </div>
            {isActiveType(LOCAL_SORT_TYPE[key]) && <SortCheck2023 />}
          </div>
          {index === 0 && <Divider />}
        </div>
      ))}
      <Divider isLast />
      {Object.keys(LOCAL_SORT_MODE).map((key, index) => (
        <div
          data-parent={`${
            props.newUPVMode ? DATA_PARENTS.PlaylistBuilderLibrary : ''
          } `}
          onClick={
            index !== 0 && localSortType === LOCAL_SORT_TYPE.RECENT
              ? (e) => {
                e.stopPropagation();
              }
              : handlerClickMode(key)
          }
          key={LOCAL_SORT_MODE[key] + index}
          className={`${styles.editMenu__item} ${
            localSortType === LOCAL_SORT_TYPE.RECENT
              ? styles.editMenu__item_freezed
              : ''
          }`}
        >
          <div
            data-parent={`${
              props.newUPVMode ? DATA_PARENTS.PlaylistBuilderLibrary : ''
            } `}
            className={`${styles.editMenu__text} ${
              localSortType === LOCAL_SORT_TYPE.RECENT
                ? styles.editMenu__item_freezed
                : ''
            }`}
          >
            {capitalizeFirstLetter(t(`${LOCAL_SORT_MODE[key].toLowerCase()}T`))}
          </div>
          {isActiveMode(index) && <SortCheck2023 />}
        </div>
      ))}
    </div>
  );
};

export default SortOption;
