import React from 'react';
import Preferences from './Preferences/PreferencesGeneral';
import Language from './Preferences/PreferencesLanguage';
import Emojis from './Preferences/PreferencesEmojis';
import Profile from './Profile';
import Activity from './Profile/ProfileActivity';
import WorkspaceUpgrade from './Workspace/WorkspaceUpgrade';
import ShortcutsAll from './ApplicationShortcuts/ShortcutsAll';
import { Width100 } from './styled';


const SettingsContentSelector = ({ activeSection }) => {
  return (
    <Width100>
      {activeSection === 'profile' && <Profile />}
      {activeSection === 'preferences' && <Preferences />}
      {activeSection === 'upgrade' && <WorkspaceUpgrade />}
      {activeSection === 'shortcuts' && <ShortcutsAll />}
      {activeSection === 'activity' && <Activity />}
      {activeSection === 'language' && <Language />}
      {activeSection === 'emojis' && <Emojis />}
    </Width100>
  );
};

export default SettingsContentSelector;
