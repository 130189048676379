import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import { actionCloseModal } from '../../redux/user/action';

const cx = classNames.bind(styles);


// check usage
const AddPlaylistToPlaylistWindow = () => {
  const dispatch = useDispatch();
  const { dataPayload } = useSelector((redux) => redux.user);
  const close = () => {
    dispatch(actionCloseModal());
  };
  const deleteItem = (e) => {
    close();
    dataPayload.deletePlaylist(e);
  };
  const goToPlaylists = (e) => {
    close();
    dataPayload.goToPlaylists(e);
  };
  return (
    <div onClick={close} className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'addPlaylistToPlaylistWindow')}
      >
        <div className={cx('sub_title')}>
          Add this SmartFile to your SmartFile window
        </div>
        <div className={cx('group_buttons_three')}>
          <div onClick={close} className={cx('group_buttons_cancel')}>
            Cancel
          </div>

          <div className={cx('group_buttons')}>
            <div onClick={deleteItem} className={cx('group_buttons_delete')}>
              No
            </div>

            <div onClick={goToPlaylists} className={cx('group_buttons_add')}>
              Add
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPlaylistToPlaylistWindow;
