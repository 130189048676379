import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { DATA_PARENTS } from '../../utils/constants';
import styles from './index.module.scss';
import { convertDateLibFilter, sortedFunctions } from '../../utils/dateConvert';
import SortAndView from '../LibraryHeaderMenu/childComponents/SortAndView';
import { actionCreator } from '../../shared/redux/actionHelper';
import { ContentActionType } from '../../redux/content/contentTypes';
import useComponentVisible from '../../utils/hooks/useComponentVisible';

const cn = classNames.bind(styles);
const defaultSortType = Object.keys(sortedFunctions)[2];

const BottomPartView = (props) => {
  const hasFileTypeFilter = props.filter?.fileType
    && !!Object.values(props.filter.fileType || {}).length;
  const hasDataFilter = props.filter?.createDate
    && !!Object.values(props.filter.createDate || {}).length;
  const hasTagFilter = props.filter?.tags && !!Object.values(props.filter.tags || {}).length;
  const hasSearchFilter = !!props.libFilterSearch;
  const dispatch = useDispatch();
  const isLargeRightBar = useSelector(
    (state) => state.currentPage.isLargeRightBar,
  );

  const [refShowMenuSort, isShowMenuSort, setShowMenuSort] = useComponentVisible(false);

  const {
    activeNavSlider = 'pages',
    selectorType = 'all',
    sortType = defaultSortType,
  } = useSelector((state) => state.content);

  const setPreviewContentQuery = ({
    newActiveNavSlider,
    newSelectorType,
    newSortType,
  }) => {
    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        {
          activeNavSlider: activeNavSlider || newActiveNavSlider,
          selectorType: selectorType || newSelectorType,
          sortType: newSortType || sortType,
        },
        'BottomPartView ',
      ),
    );
  };
  return (
    <div className={cn('tags', 'active')}>
      <div className={cn('filters')}>
        {hasSearchFilter && (
          <div className={cn('filters_option', 'hoverable', 'active')}>
            <div
              className={cn(
                'filters_option_text',
                'filters_option_text_active',
              )}
            >
              {`Search: ${props.libFilterSearch}`}
            </div>
          </div>
        )}

        {hasTagFilter && (
          <div
            data-parent={DATA_PARENTS.SideBar.tags}
            className={cn('filters_option', 'hoverable', 'selected')}
          >
            <div
              className={cn(
                'filters_option_text',
                'filters_option_text_active',
              )}
            >
              {Object.values(props.filter.tags || {})
                .map((i) => i.title)
                .join(', ')}
            </div>
          </div>
        )}

        {hasDataFilter && (
          <div
            data-parent={DATA_PARENTS.SideBar.date}
            className={cn(
              'filters_option',
              'hoverable',
              'date_range',
              'selected',
            )}
          >
            <div
              className={cn(
                'filters_option_text',
                'filters_option_text_active',
              )}
            >
              {`${convertDateLibFilter(
                props.filter.createDate.Start,
              )} - ${convertDateLibFilter(props.filter.createDate.End)}`}
            </div>
          </div>
        )}
        {hasFileTypeFilter && (
          <div
            data-parent={DATA_PARENTS.SideBar.fileType}
            className={cn(
              'filters_option',
              'hoverable',
              'file_type',
              'selected',
            )}
          >
            <div
              className={cn(
                'filters_option_text',
                'filters_option_text_active',
              )}
            >
              {Object.values(props.filter.fileType || {}).join(', ')}
            </div>
          </div>
        )}
      </div>
      <div className={cn('sort_wrapper')}>
        <SortAndView
          setSort={(sort) => setPreviewContentQuery({ newSortType: sort })}
          setShowMenuSort={setShowMenuSort}
          refShowMenuSort={refShowMenuSort}
          isShowMenuSort={isShowMenuSort}
          isCantToggleView={!isLargeRightBar}
        />
      </div>
    </div>
  );
};

export default BottomPartView;
