import styled from 'styled-components/macro';

export const TagsDropDownShell = styled.div`
  position: relative;
  z-index: 1;
`;

export const TagsDropDownContent = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  scroll-snap-type: inline;
  scroll-behavior: smooth;
  height: 100%;

  &::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    background: #ece2e2;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px #dbdbdb;
    margin-right: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background: #dbdbdb;
  }
`;

export const TagsDropDownWrap = styled.div`
  width: 250px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #484343;
  position: absolute;
  left: -253px;
  max-height: 482px;
`;

export const TagsDropDownSeparator = styled.div`
  height: 1px;
  background: #f0f1fe;
  margin-left: 17px;
  margin-right: 17px;
  margin-top: 3px;
`;

export const TagsDropDownEmptyMsg = styled.div`
  font-size: 14px;
  color: #a3aacc;
  padding-left: 18px;
  margin-top: 8px;

  cursor: default;
`;

export const HiddenScrollBarArea = styled.div`
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  max-height: 290px;
`;
