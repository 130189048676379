import { call, cancel, put, take, takeEvery } from 'redux-saga/effects';
import { all, cancelled, fork, select } from '@redux-saga/core/effects';
import { convertFromRaw, EditorState } from 'draft-js';
import {
  actionAddLibraryComponent,
  actionDeleteComponentInCollectionReduxOnly,
  actionDeleteComponentReduxOnly,
  actionToggleUploadingFilesState,
  actionUpdateLibraryComponentInRedux,
} from '../redux/library/actions';
import { FileUpload } from '../redux/filesUpload/types';
import {
  actionCloseUploadsOnFolderSwitch,
  actionDeleteUpload,
  actionUpdateUpload,
} from '../redux/filesUpload/action';
import {
  DEFAULT_POSITION_STEP,
  FileTypeResolver, isTypeShowLikePdf,
  MessageType,
  PLAYLIST_ELEMENTS, UiComponentTypes,
  UNLINK_LIBRARY_COMPONENT,
  UPLOAD_URL,
} from '../utils/constants';
import { createUploadFileChannel } from './createFileUploadChannel';
import { ServiceUser } from '../redux/user/types';
import {
  actionShowMessage,
  actionUpdateSupportBlock,
} from '../redux/support/action';
import {
  getCurrentPage,
  getCurrentPageLinkPages,
  getLibrary,
  getLibraryComponents,
  getUploads,
  getUser,
  sendEventUpdate,
  showErrorMessage,
} from './sagasUtils';
import {
  requestUpdateLibraryComponent,
  requestUploadImageByUrl,
} from '../utils/request';
import {
  contentImageTemplate,
  innerHtmlImageTemplateShort,
  sanitizeToSave,
} from '../utils/helpers';
import {
  actionUpdateBlock,
  actionUpdateLinkPage,
} from '../redux/currentPage/action';
import { sendMessage } from './SocketCluster/action';
import { actionCreator } from '../shared/redux/actionHelper';
import { ContentActionType } from '../redux/content/contentTypes';
import { actionAddNewMuxContent } from '../redux/muxContentStatus/actions';
import { CurrentPage } from '../redux/currentPage/types';
import { createUpdateComponentBody } from '../utils/query/helper';
import i18n from '../i18n';

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function* createUpload(action) {
  try {
    const {
      newId,
      title,
      file,
      type,
      errorMsg,
      collectionId,
      history,
      hasLinkPage,
      playlistId,
      isNew,
      isFavorite,
      tags,
      replace,
      blockId = null,
    } = action.payload;
    yield delay(200);
    const components = yield select(getLibraryComponents);
    const linkPages = yield select(getCurrentPageLinkPages);
    const user = yield select(getUser);
    const now = Math.round(new Date().getTime() / 1000);
    const maxPositionItem = Object.values(components || {}).sort(
      (i, b) => i.position - b.position,
    )[0] || { position: 0 };
    const newPosition = (maxPositionItem.position || 0) + DEFAULT_POSITION_STEP;
    // if (!file || !title) throw new Error('File loading failed: no file provided');
    if (!errorMsg) {
      let abortUpload = () => {};

      const formData = new FormData();
      const tagsInString = yield call(JSON.stringify, tags);
      formData.append('file', file);
      formData.append('title', title);
      formData.append('id', newId);
      formData.append('type', type || 'component');
      formData.append('position', newPosition);
      if (collectionId) formData.append('collectionId', collectionId);
      formData.append('newId', newId);
      if (isFavorite) formData.append('isFavorite', isFavorite);
      if (hasLinkPage) formData.append('linkPageId', hasLinkPage);
      if (tags) formData.append('tags', tagsInString);

      yield put(
        actionAddLibraryComponent({
          id: newId,
          title,
          urlFile: '',
          isFavorite,
          itemType: 'component',
          movedToTrash: null,
          progress: 0.0,
          isUpload: true,
          position: newPosition,
          createDate: now,
          lastModifiedDate: now,
          tags,
          type,
          size: file?.size,
        }),
      );
      if (file) {
        try {
          const channel = yield call(
            createUploadFileChannel,
            UPLOAD_URL,
            formData,
          );
          let upload = true;
          while (upload) {
            yield delay(300);
            const {
              progress = 0,
              err,
              abort,
              success,
              res,
            } = yield take(channel);
            abortUpload = abort;
            if (err) {
              // eslint-disable-next-line no-console
              yield put(
                actionUpdateUpload({
                  id: newId,
                  errorMsg: i18n.t('serverIssuesErrorT'),
                  status: 'ERROR',
                  collectionId,
                }),
              );
              upload = false;
            }
            if (progress) {
              yield put(
                actionUpdateUpload({ id: newId, progress, collectionId }),
              );
            }
            if (success) {
              const {
                createDate,
                lastModifiedDate,
                type: responseType,
                pdfPreviewUrl,
                imagePath,
                urlVerySmallImage: responseUrlVerySmallImage,
                urlSmallImage: responseUrlSmallImage,
              } = res;
              if (res && res.playbackID && res.assetID) {
                yield put(
                  actionUpdateLibraryComponentInRedux({
                    id: newId,
                    playbackID: res.playbackID,
                    assetID: res.assetID,
                    videoProcessingStatus: res.videoProcessingStatus,
                  }),
                );
                yield put(
                  actionAddNewMuxContent({
                    id: newId,
                    playbackID: res.playbackID,
                    videoProcessingStatus: res.videoProcessingStatus,
                    assetID: res.assetID,
                  }),
                );
              } else if (res && res.imagePath && res.componentId && blockId) {
                yield put(
                  actionUpdateSupportBlock(
                    blockId,
                    null,
                    null,
                    null,
                    null,
                    { urlFile: res.imagePath },
                    null,
                    res.componentId,
                    null,
                  ),
                );
              }
              const src = URL.createObjectURL(file);
              const urlFile = src;
              let urlVerySmallImage = src;
              let urlSmallImage = src;
              if (UiComponentTypes.imageLike[responseType] || isTypeShowLikePdf[responseType]) {
                urlVerySmallImage = responseUrlVerySmallImage;
                urlSmallImage = responseUrlSmallImage;
              }

              const duration = res?.duration;
              yield put(actionUpdateUpload({ id: newId, imagePath }));
              yield put(
                actionUpdateLibraryComponentInRedux({
                  playlistId,
                  hasLinkPage,
                  id: newId,
                  title,
                  urlFile: urlFile || imagePath,
                  urlSmallImage,
                  imagePath,
                  urlVerySmallImage,
                  isFavorite,
                  itemType: 'component',
                  movedToTrash: null,
                  position: newPosition,
                  pdfPreviewUrl,
                  createDate,
                  lastModifiedDate,
                  isUpload: false,
                  successUpload: true,
                  type: responseType || type,
                  tags,
                  duration,
                }),
              );

              if (hasLinkPage) {
                yield put(
                  actionUpdateLinkPage(
                    playlistId,
                    {
                      id: hasLinkPage,
                      duration,
                      type: PLAYLIST_ELEMENTS.ElementComponent,
                      position: linkPages.find((lp) => lp.id === hasLinkPage)
                        .position,
                      owner: user,
                      libraryComponent: {
                        id: newId,
                        title,
                        urlFile: urlFile || imagePath,
                        isFavorite,
                        itemType: 'component',
                        movedToTrash: null,
                        position: newPosition,
                        createDate,
                        pdfPreviewUrl,
                        lastModifiedDate,
                        urlVerySmallImage,
                        urlSmallImage,
                        isUpload: false,
                        successUpload: true,
                        type,
                      },
                    },
                    isNew,
                    replace,
                  ),
                );
                yield put(
                  sendMessage({
                    dataSend: {
                      sendChannelPlaylist: 'sendChannel',
                      type: CurrentPage.CreateLinkPageR,
                      payload: sanitizeToSave(
                        JSON.stringify({
                          playlistId,
                          pageData: {
                            id: hasLinkPage,
                            type: PLAYLIST_ELEMENTS.ElementComponent,
                            position: linkPages.find(
                              (lp) => lp.id === hasLinkPage,
                            ).position,
                            owner: user,
                            libraryComponent: {
                              id: newId,
                              title,
                              urlFile: urlFile || imagePath,
                              isFavorite,
                              itemType: 'component',
                              movedToTrash: null,
                              position: newPosition,
                              isNotOwner: true,
                              imagePath,
                              createDate,
                              pdfPreviewUrl,
                              lastModifiedDate,
                              urlVerySmallImage,
                              urlSmallImage,
                              isUpload: false,
                              successUpload: true,
                              type,
                              isSocket: true,
                            },
                          },
                        }),
                      ),
                      playlistId,
                    },
                    pushToChannel: playlistId,
                  }),
                );
                yield call(sendEventUpdate, ({}));
              }
              if (action.payload.callback) {
                yield delay(300);
                yield put(action.payload.callback(urlFile || imagePath));
              }
            }
            yield delay(200);
          }

          // eslint-disable-next-line no-console
          if (yield cancelled()) console.log('canceled');
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        } finally {
          if (yield cancelled()) {
            if (abortUpload) abortUpload();
            yield put(actionDeleteUpload(newId));
            if (hasLinkPage) {
              yield put(
                actionUpdateLinkPage(playlistId, {
                  id: hasLinkPage,
                  position: linkPages.find((lp) => lp.id === hasLinkPage)
                    .position,
                  type: 'upload',
                  libraryComponent: UNLINK_LIBRARY_COMPONENT,
                }),
              );
            }
            yield put(actionDeleteComponentReduxOnly(newId));
            yield put(
              actionShowMessage({
                type: MessageType.UploadCanceled,
                itemName: title,
              }),
            );
          }
          const uploads = yield select(getUploads);

          if (uploads[newId] && uploads[newId].status !== 'ERROR') {
            yield put(
              actionUpdateUpload({
                status: uploads[newId].status === 'ERROR' ? 'ERROR' : 'READY',
                id: newId,
                success: true,
              }),
            );
            if (uploads[newId].status !== 'ERROR') {
              yield put(actionDeleteUpload(newId));
            }
            yield call(delay, 1000);
            // yield put(actionUpdateUpload({ id: newId, success: false }));
            if (
              history.location.pathname.startsWith('/page/')
              || history.location.pathname.startsWith('/playlist')
              || history.location.pathname !== '/playlists'
            ) {
              const fileName = FileTypeResolver[type]
                ? FileTypeResolver[type]
                : 'File';
              yield put(
                actionShowMessage({
                  type: MessageType.SuccessfullyUploaded,
                  itemName: fileName,
                }),
              );
              yield put(actionToggleUploadingFilesState(false));
              yield put(
                actionCreator(ContentActionType.updateCounterS, {
                  isUpload: true,
                }),
              );
            }
          } else {
            yield put(actionShowMessage({
              type: MessageType.ReachedUploadsLimitForPlan,
            }));
          }
        }
      }
      yield put(actionCreator(ContentActionType.updateCounterS, {}));
    } else {
      yield put(
        actionUpdateLibraryComponentInRedux({
          id: newId,
          title,
          urlFile: '',
          isFavorite,
          itemType: 'component',
          movedToTrash: null,
          isUpload: true,
          position: newPosition,
          successUpload: true,
          type,
          tags,
          size: file?.size,
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* closeUpload(action) {
  try {
    const { id, status } = action.payload;
    const uploads = yield select(getUploads);
    if (!uploads[id]) return;
    if (uploads[id].workerTask) {
      yield cancel(uploads[id].workerTask);
    }
    if (status !== 'READY') {
      if (uploads[id].collectionId) {
        yield put(
          actionDeleteComponentInCollectionReduxOnly(
            id,
            uploads[id].collectionId,
          ),
        );
      } else {
        yield put(actionDeleteComponentReduxOnly(id));
      }
    }
    yield put(actionDeleteUpload(id));
    window.onbeforeunload = null;
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* closeUploadsOnFolderSwitch(action) {
  try {
    // legacy ?
    const { folderId } = action.payload;
    const uploads = yield select(getUploads);
    yield all(
      Object.values(uploads || {}).map((i) => {
        const id = i.id;
        if (i.status !== 'UPLOADING') {
          return closeUpload({ payload: { id, folderId, status: i.status } });
        }
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* cancelAllUploads(action) {
  try {
    const { folderId, hideNotification } = action.payload;
    const uploads = yield select(getUploads);
    let uploadsNumber = 0;
    yield all(
      Object.values(uploads || {})
        .filter((item) => item.status !== 'READY')
        .map((i) => {
          const id = i.id;
          uploadsNumber++;
          return closeUpload({ payload: { id, folderId } });
        }),
    );
    if (!hideNotification && uploadsNumber) {
      yield put(
        actionShowMessage({
          type: MessageType.UploadCanceled,
          counter: uploadsNumber,
        }),
      );
    }
    window.onbeforeunload = null;
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* createUploadHandler(action) {
  try {
    const { newId, file, tags, blockId = null } = action.payload;
    const workerTask = yield fork(createUpload, action);
    yield put(
      actionUpdateUpload({
        id: newId,
        blockId,
        workerTask,
        size: file?.size,
        tags,
        file,
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* handleSwitchFolder(action) {
  try {
    const { folderId } = action.payload;
    yield put(actionCloseUploadsOnFolderSwitch(folderId, true));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* CreateImageUploadByLink(action) {
  try {
    const { url: rawUrl, blockId, newId, isNewBlock, position } = action.payload;
    const url = rawUrl.split('?')[0];
    const { id: userId } = yield select(getUser);

    const { editComponentId, isOpenCreateComponent } = yield select(getLibrary);

    const { id: currentPageId } = yield select(getCurrentPage);
    const isComponent = !currentPageId;
    const pageId = editComponentId ?? currentPageId;
    if (isNewBlock) {
      //
      requestUpdateLibraryComponent()({
        place: 'addNewEmptyEditableBlock',
        id: currentPageId,
        create: {
          components: [
            {
              id: blockId,
              content: '',
              type: 'image',
              position,
              owner: userId,
            },
          ],
        },
      });
    } else if (position) {
      requestUpdateLibraryComponent()({
        place: 'queryUpdatePositionManyComponents',
        id: currentPageId,
        updateNested: {
          components: [{
            id: blockId,
            field: { position },
          }],
        },
      });
    }
    yield put(
      actionUpdateUpload({
        id: blockId,
        type: 'loading',
        progress: 1,
        newId: blockId,
      }),
    );
    let response;
    try {
      response = yield call(requestUploadImageByUrl, {
        urlToDownload: url,
        blockId,
        newId,
        pageId,
        isComponent,
      });
    } catch (err) {
      yield put(
        actionUpdateUpload({
          errorMsg: err,
          status: 'ERROR',
          id: blockId,
          success: true,
        }),
      );
      return;
    }

    yield put(
      actionUpdateUpload({ status: 'READY', id: blockId, success: true }),
    );
    yield delay(100);
    yield put(actionDeleteUpload(blockId));
    const urlFile = response.data.imagePath;
    const libraryComponentToAttachId = response.data.componentId;
    let state;
    const content = contentImageTemplate(urlFile);
    try {
      state = EditorState.createWithContent(
        convertFromRaw(
          JSON.parse(
            contentImageTemplate(urlFile)
              .replaceAll('"', '\\"')
              .replaceAll("'", '"')
              .replaceAll(/(\r\n|\n|\r)/gm, '\\n')
              .replaceAll('$@quote', "'"),
          ),
        ),
      );
    } catch (e) {
      state = EditorState.createWithContent(
        convertFromRaw(
          JSON
            // eslint-disable-next-line max-len
            .parse(
              "{'blocks':[{'key':'daj6c','text':'','type':'text','depth':0,'inlineStyleRanges':[],'entityRanges':[],'data':{}}],'entityMap':{}}"
                .replaceAll("'", '"')
                .replace(/(\r\n|\n|\r)/gm, '\\n'),
            ),
        ),
      );
    }
    if (isOpenCreateComponent) {
      yield put(
        actionUpdateSupportBlock(
          blockId,
          state,
          null,
          innerHtmlImageTemplateShort(urlFile),
          null,
          { urlFile },
          'image',
          libraryComponentToAttachId,
          content,
        ),
      );
    } else {
      yield put(
        actionUpdateBlock(
          blockId,
          state,
          null,
          innerHtmlImageTemplateShort(urlFile),
          null,
          { urlFile },
          'image',
          content,
        ),
      );
    }
    if (!isOpenCreateComponent) {
      yield call(
        requestUpdateLibraryComponent(),
        createUpdateComponentBody(
          {
            id: blockId,
            state,
            innerHtml: innerHtmlImageTemplateShort(urlFile),
          },
          pageId,
          null,
          null,
          null,
        ),
      );
    }

    yield put(actionCreator(ContentActionType.updateCounterS, {}));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

export default function* uploadSaga() {
  yield takeEvery(FileUpload.CreateUpload, createUploadHandler);
  yield takeEvery(FileUpload.Cancel, closeUpload);
  yield takeEvery(FileUpload.CancelAll, cancelAllUploads);
  yield takeEvery(FileUpload.CloseUploads, closeUploadsOnFolderSwitch);
  yield takeEvery(ServiceUser.switchFolder, handleSwitchFolder);
  yield takeEvery(FileUpload.CreateImageUploadByLink, CreateImageUploadByLink);
}
