import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import { DEFAULT_TITLE } from '../../utils/constants';
import BlockCreator from './BlockCreator';
import { pipeAddIsHasTextMeta } from '../../utils/helpers';

const BlockCreatorComponent = React.memo(function BlockCreatorComponent({
  forUPVPreview,
  item = {},
  forComponentDescription,
  isLibDescription,
  isPreview,
  isStandAlone = false,
  place,
  isPlaylist,
  isExpandedView,
  hasMark,
  isWhiteGallery,
  isMaker,
}) {
  const { t } = useTranslation();
  const [orderedItem, setOrderedItem] = useState([]);

  useEffect(() => {
    if (item?.components) {
      const newOrderedList = item?.components
        .sort((i, b) => i.position - b.position)
        .map(pipeAddIsHasTextMeta);
      // if (forComponentDescription) {
      //   newOrderedList.pop();
      // }
      // if (isPreview) {
      //   newOrderedList.pop();
      // }
      setOrderedItem(newOrderedList);
    } else {
      setOrderedItem([]);
    }
  }, [item]);

  return (
    <div
      className={`
        ${isStandAlone ? styles.stand_alone_wrapper : styles.wrapper}
        ${place === 'ViewPageRow' ? styles.ViewPageRow : ''}
        ${place === 'ViewPageRowHover' ? styles.ViewPageRowHover : ''}
        ${hasMark ? styles.hasMark : ''}
        ${place === 'ViewList' ? styles.ViewList : ''}
        ${
          place === 'ComponentViewListPreview'
            ? styles.ComponentViewListPreview
            : ''
        }
        ${place === 'PageViewListPreview' ? styles.PageViewListPreview : ''}
        ${forUPVPreview ? styles.ForUPVPreview : ''}
            ${isExpandedView ? styles.isExpandedView : ''}
            ${isLibDescription ? styles.isLibDescription : ''}
      `}
    >
      <div className={styles.title_alt}>
        {' '}
        {item.title === '' ? t('defaultSmartPageTitleT') : item.title}
      </div>
      <div
        className={`${styles.description}
         ${
           !item.showDescription && !forComponentDescription
             ? styles.display_none
             : ''
         }`}
      >
        {' '}
        {item.description}
      </div>
      <div className={styles.space_separator2} />
      {orderedItem
        .map((i) => {
          return (
            <BlockCreator
              isMaker={isMaker}
              isPlaylist={isPlaylist}
              isPreview={isPreview}
              isWhiteGallery={isWhiteGallery}
              key={`
                ${i.nestedItemId}
                ${i.position || (i.components && i.components[0]?.position)}|
                ${i.id}
                ${item.id}
              `}
              item={i}
            />
          );
        })}
    </div>
  );
});

export default BlockCreatorComponent;
