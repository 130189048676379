import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InvoiceSingle from './InvoiceSingle';


const InvoicesList = () => {
  const { invoices } = useSelector((state) => state.user);
  const { t } = useTranslation();

  return (
    <div>
      {invoices && invoices.length > 0 && (
        <div>
          <h3 style={{ margin: '30px 0 15px' }}>{t('invoicesHeaderT')}</h3>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {invoices.map((item) => (
              <InvoiceSingle item={item} />
            ))}
          </div>
        </div>
      )
    }
    </div>
  );
};

export default InvoicesList;
