import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { ReactComponent as SingleSvg } from '../../images/icons/line_single_13.svg';
import { ReactComponent as BoldSvg } from '../../images/icons/line_bold_13.svg';
import { ReactComponent as DoubleSvg } from '../../images/icons/line_double_13.svg';
import { ReactComponent as DottedSvg } from '../../images/icons/line_dotted_13.svg';

import rawStyles from './CustomBlocks.module.scss';
import useComponentVisibleMouseDown from '../../utils/hooks/useComponentVisibleMouseDown';

const styles = classnames.bind(rawStyles);

export const separatorTemplates = {
  single: <div className={styles('line', 'single')} />,
  bold: <div className={styles('line', 'bold')} />,
  dotted: <div className={styles('line', 'dotted')} />,
  double: (
    <>
      <div className={styles('line', 'single')} />
      <div className={styles('line', 'single')} />
    </>
  ),
};

const svgBook = {
  single: <SingleSvg />,
  bold: <BoldSvg />,
  dotted: <DottedSvg />,
  double: <DoubleSvg />,
};

const SeparatorLineBlock = ({
  data = { lineType: 'single' },
  editorWR,
  componentId,
  isView,
  setLocalState,
  saveState,
}) => {
  const [refIs, isShowSettings, setIsShowSettings] = useComponentVisibleMouseDown(false, 'lineSettingsWrapper');
  const [activeTab, setActiveTab] = useState(data.lineType);
  useEffect(() => {
    setActiveTab(data.lineType);
  }, [data.lineType]);

  const onClickHandler = (e, newType) => {
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      setLocalState({ data: { lineType: newType } });
      const outerHTML = editorWR?.current?.outerHTML;
      saveState({ data: { lineType: newType } }, outerHTML);
    }, 10);
    setActiveTab(newType);
    setIsShowSettings(false);
  };

  return (
    <>
      <div>
        <div
          ref={editorWR}
          className={styles(
            'container',
            { hover: !isView },
            { heightDouble: activeTab === 'double' },
          )}
          onClick={() => !isView && setIsShowSettings(!isShowSettings)}
        >
          {separatorTemplates[activeTab]}
        </div>
      </div>
      {isShowSettings && (
        <div
          ref={refIs}
          className={styles('settingsWrapper')}
          data-parent="lineSettingsWrapper"
        >
          {Object.keys(separatorTemplates).map((key, index) => (
            <div
              key={componentId + index}
              onClick={(e) => onClickHandler(e, key)}
              className={styles('buttonWrapper', { active: activeTab === key })}
            >
              {svgBook[key]}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SeparatorLineBlock;
