import { EditorState, Modifier } from 'draft-js';
import {
  actionAddSupportEditableBlockRedux,
  actionChangeSupportEditableBlockRedux,
} from '../../../redux/support/action';
import { BlockTypes } from '../../../utils/constants';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { EditPage } from '../../../redux/pages/types';
import { revertObject } from '../../../utils/dataUtil';

const markDownBySpaseList = {
  '1.': BlockTypes.ordered_list_item0,
  '-': BlockTypes.unordered_list_item0,
  '*': BlockTypes.unordered_list_item0,
  '+': BlockTypes.unordered_list_item0,
  '"': BlockTypes.callout,
  '#': BlockTypes.title,
  '##': BlockTypes.title2,
  '###': BlockTypes.title3,
};

export const markTypeBySpaseAndHandledKey = revertObject(markDownBySpaseList);

const markDownBySpaseFunc = (arg, key) => {
  const {
    event,
    localState,
    isLibraryCreate,
    dispatch,
    componentId,
    playlistField,
    type,
  } = arg;
  const newType = markDownBySpaseList[key];
  if (type === newType) {
    return;
  }
  event.preventDefault();
  event.stopPropagation();
  const contentState = localState.getCurrentContent();
  const selectionState = localState.getSelection();
  const newContentState = Modifier.replaceText(
    contentState,
    selectionState.merge({
      anchorOffset: 0,
      focusOffset: key.length,
    }),
    '',
  );

  if (isLibraryCreate) {
    dispatch(
      actionChangeSupportEditableBlockRedux(
        componentId,
        EditorState.push(localState, newContentState, 'replace-text'),
        null,
      ),
    );
    dispatch(
      actionAddSupportEditableBlockRedux(
        componentId,
        type,
        null,
        null,
        null,
        null,
        playlistField,
      ),
    );
  } else {
    dispatch(
      actionCreator(EditPage.ChangeTypeEditableBlock, {
        id: componentId,
        type: newType,
        newContentState: EditorState.push(
          localState,
          newContentState,
          'replace-text',
        ),
      }),
    );
  }
};
export const markDownBySpase = (arg) => {
  const { localState } = arg;
  if (arg.localState) {
    Object.keys(markDownBySpaseList).some((key) => {
      if (
        localState.getCurrentContent().getPlainText().startsWith(key)
        && localState.getSelection().getEndOffset() === key.length
      ) {
        markDownBySpaseFunc(arg, key);
        return true;
      }
      return false;
    });
  }
};

export default markDownBySpase;
