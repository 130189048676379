export const LOCAL_SORT_TYPE = {
  RECENT: 'RECENT',
  NAME: 'NAME',
  MODIFIED: 'MODIFIED',
  CREATED: 'CREATED',
  AVAILABILITY: 'AVAILABILITY',
  BOARD: 'BOARD',
  PROGRESS: 'PROGRESS',
  TYPE: 'TYPE',
  SIZE: 'SIZE',
};

export const LOCAL_SORT_MODE = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
};
