import React from 'react';
import classNames from 'classnames/bind';
import { DATA_PARENTS } from '../../../utils/constants';
import styles from '../../GlobalModals/index.module.scss';
import { AvatarInInput } from './AvatarInInput';

const cx = classNames.bind(styles);


const SuggestedContact = React.memo(
  function SuggestedContact({ userClickHandler, dataParent, user, active }) {
    return (
      <div
        className={cx('item_wrapper', { active })}
        onClick={(event) => userClickHandler(event, user.id)}
        data-parent={`${DATA_PARENTS.AddMember} ${dataParent}`}
      >
        <div className={cx('item_title_container')}>
          <AvatarInInput dataParent={`${DATA_PARENTS.AddMember} ${dataParent}`} item={user} />
          <div className={cx('name')}> {user.username} ({user.email})</div>
        </div>
      </div>
    );
  });

export default SuggestedContact;
