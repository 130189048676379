import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import {
  CLEAR_HISTORY,
  clearHistory,
  decrementHistoryStep,
  historyEvents,
  historyStep,
  incrementHistoryStep,
} from '../undo';
import { undoActionHandler } from '../sagas/undoSagas';
import EditPlaylist from './playlists/types';

export const myActionHandler = (action, state) => {
  if (action.payload?.isSocket && !action.payload?.isSender) return;
  if (action.type === CLEAR_HISTORY) {
    clearHistory();
    return;
  }
  if (!action.metod && undoActionHandler[action.type]) {
    if (action.type === EditPlaylist.ChangeTextElementBlockReduxMaker) {
      const { linkPageId, isSocket, isSender } = action.payload;
      const linkPage = state.currentPage?.linkPages?.find(
        (linkPage) => linkPage.id === linkPageId,
      );
      const old = linkPage?.textComponent.state;
      let newState;
      if (isSocket) {
        newState = EditorState.createWithContent(
          convertFromRaw(action.payload.newState),
        );
      } else newState = action.payload.newState;
      if (isSender || !old) return;
      if (
        !!(old?.getCurrentContent && newState?.getCurrentContent)
        && JSON.stringify(convertToRaw(old?.getCurrentContent()))
          === JSON.stringify(convertToRaw(newState?.getCurrentContent()))
      ) return;
    }
    if (historyEvents.length > 49) {
      historyEvents.shift();
      decrementHistoryStep();
    }
    if (historyStep !== historyEvents.length) {
      historyEvents.splice(historyStep, historyEvents.length - historyStep);
    }
    const prevData = undoActionHandler[action.type].getPrevData(action, state);
    historyEvents.push({ ...action, prevData });
    incrementHistoryStep();
  }
};
