import React, { useState } from 'react';
import HoverTooltipWrapper from '../PagePreview/HoverTooltipWrapper';
import styles from './index.module.css';

const CardWrapper = (props) => {
  const [isHoverPreviewActive, setIsHoverPreviewActive] = useState(false);
  return (
    <HoverTooltipWrapper
      isActive={isHoverPreviewActive}
      setIsActive={setIsHoverPreviewActive}
      playlist={props.item}
      item={props.item}
      isKanban
      isKanbanCardWithoutPreview={!props.item.cropUrl}
      element={(
        <div className={styles.image_wrapper}>
          {props.item.cropUrl && (
            <img
              className={styles.image}
              style={{ top: 0 }}
              src={props.item.cropUrl}
              alt="type_image"
            />
          )}
        </div>
      )}
    >
      {props.children}
    </HoverTooltipWrapper>
  );
};

export default CardWrapper;
