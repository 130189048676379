import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import {
  builderDragItemTitle,
  getItemName,
  getManyNewLinkPagePositionByIndexForDuplicate,
  getNewLinkPagePositionByIndex,
  sanitizeToLoad,
  removeFromDom,
} from '../../../utils/helpers';
import MakerTOCItem from './MakerTocItem';
import {
  SvgWrap,
  ViewOptionShell,
} from '../sharedStyled';
import Tooltip from '../../../shared/Tooltips/Tooltip';

import { NewUPVViewModes } from '../../../utils/constants';
import { ReactComponent as CloseSvg } from '../../../images/icons/icon_16/close.svg';
import { ReactComponent as DragSvg } from '../../../images/icons/dragline.svg';
import { ReactComponent as ListSvg } from '../../../images/icons/icon_18/view_list.svg';
import { ReactComponent as GridSvg } from '../../../images/icons/icon_18/view_grid.svg';
import SixDotsSvg from '../../../images/icons/drag_black_lines.svg';
import {
  actionAddToBeScrollTo,
  actionDuplicateLinkPageSInCurrentPage,
  actionRemoveLinkPageFromPlaylist,
} from '../../../redux/currentPage/action';
import { isRoleInPlaylist } from '../../../utils/permissions';

import {
  CloseWrap,
  DNDElementWrap,
  DragLineWrap,
  Header,
  LastItem,
  TOCContent,
  TOCShell,
  EmptyStateWrapper,
  EmptyStateText,
} from './styled';
import dropInPlaylistHandler from '../../../utils/dnd/dropHandler';
import {
  actionCleanBlinkId,
  actionSetBlinkId,
} from '../../../redux/support/action';
import empty from '../MakerContent/Empty';
import { SeparatorLineHorizontal } from '../../Player/styledComponents';
import CloseSection from '../../Player/CloseSection';
import { actionCreator } from '../../../shared/redux/actionHelper';
import Playlists from '../../../redux/playlists/types';

const MakerTOC = ({
  refItem,
  open,
  setOpen,
  currentRole,
  scrollToItem,
  isForSharedToWeb = false,
  isPlayer = false,
}) => {
  const { t } = useTranslation();
  const { id, linkPages = [] } = useSelector((state) => state.currentPage);
  const { isViewer: exViewer } = isRoleInPlaylist;
  const currentPage = useSelector((state) => state.currentPage);
  const [indexHover, setIndexHover] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [draggablePage, setDraggablePage] = useState(false);
  const user = useSelector((state) => state.user || empty);
  const selectedPage = useSelector((state) => state.selectedPage) || empty;

  const params = useParams();

  const isOwner = user.id && (user.id === currentPage.owner?.id);

  const [filteredIndex, setFilteredIndex] = useState(0);
  const [lastTouchedItemId, setLastTouchedItemId] = useState(null);

  const partiallyIndexedArray = useMemo(() => {
    let defaultIndex = 0;
    const resArray = linkPages.map((elem) => {
      if (elem.type !== 'elementTitle') {
        defaultIndex++;
        return { ...elem, customIndex: defaultIndex };
      }
      return elem;
    });
    if (draggablePage) {
      const missedIndex = resArray.findIndex(item => item.id === draggablePage.id);
      setFilteredIndex(missedIndex);
      return resArray.filter(item => item.id !== draggablePage.id);
    }
    return resArray;
  }, [linkPages, draggablePage]);

  const [selectedIndex, setSelectedIndex] = useState([]);
  const [viewMode, setViewMode] = useState(NewUPVViewModes.list);
  const [selectedIds, setSelectedIds] = useState([]);

  const dropDepthRef = useRef(0);

  const onDragEnter = (index) => () => {
    if (exViewer[currentRole]) {
      return;
    }
    dropDepthRef.current += 1;
    setIndexHover(index);
  };
  const onDragLeave = () => {
    if (exViewer[currentRole]) {
      return;
    }
    dropDepthRef.current -= 1;
    if (dropDepthRef.current > 0) return;
    setIndexHover(false);
  };
  const { idDragPage, wrapperId, isFinished } = useSelector((state) => state.currentPage);
  const clear = (itemId) => {
    if (!selectedIds?.includes(itemId)) {
      setSelectedIds([]);
      setSelectedIndex([]);
      return true;
    }
    return false;
  };


  const deleteHandler = () => {
    const item = linkPages[draggablePage.itemIndex];
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        currentPage.id,
        item.id,
        item.textComponent?.id || item.libraryComponent?.id,
      ),
    );
  };
  const onClickClosePlaylist = (event) => {
    event.stopPropagation();

    dispatch(
      actionCreator(Playlists.MarkViewedS, {
        value: isFinished,
        playlistId: params.id,
        channelId: params.channelId,
        wrapperId,
      }),
    );
  };
  const setBlinkId = (id) => {
    if (!id) {
      dispatch(actionCleanBlinkId());
      return;
    }
    dispatch(actionSetBlinkId([id]));
  };
  const onEditorAreaMouseDrop = (e, linkPageId, index, replace) => {
    e.preventDefault();
    e.stopPropagation();
    removeFromDom('draggable_page');
    if (exViewer[currentRole]) {
      return;
    }
    const calculatedIndex = (filteredIndex >= index || index === 'last') ? index : index + 1;
    setDraggablePage(false);
    dropDepthRef.current = 0;
    setIndexHover(false);

    dropInPlaylistHandler(
      e,
      linkPageId,
      calculatedIndex,
      replace,
      linkPages,
      dispatch,
      history,
      draggablePage,
      selectedPage,
      idDragPage,
      selectedIndex,
      setBlinkId,
      selectedIds,
      id,
      [],
      user,
    );
  };

  const dragStartHandler = (props, item) => (e) => {
    e.stopPropagation();
    setLastTouchedItemId(item.id);
    if (exViewer[currentRole]) {
      return;
    }

    const div = document.createElement('div');
    const image = document.createElement('img');
    image.className = 'draggable_item_icon';
    image.src = SixDotsSvg;
    div.id = 'draggable_page';
    if (item.textComponent?.id || !item.libraryComponent?.type || viewMode === 'list') {
      const isDragNotSelected = clear(item.id);
      div.className = 'draggable_item in_toc';
      if (
        isDragNotSelected
        || !selectedIndex.length
        || selectedIndex.length === 1
      ) {
        const draggingItemTitle = builderDragItemTitle(item);
        const testDiv = document.createElement('div');
        testDiv.textContent = `${draggingItemTitle}`;
        testDiv.style.width = 'fit-content';
        testDiv.id = 'testDiv';
        document.body.appendChild(testDiv);
        let trimTitle = draggingItemTitle;
        let counter = trimTitle.length;

        while (testDiv.getBoundingClientRect().width > 200) {
          trimTitle = `${trimTitle.slice(0, counter)}...`;
          testDiv.textContent = trimTitle;
          counter--;
        }

        document.body.removeChild(testDiv);
        div.textContent = `${trimTitle}`;
      } else {
        div.textContent = `${selectedIndex.length} items`;
      }
      e.dataTransfer.setDragImage(div, 117, 20);
    } else {
      const id1 = document.getElementById(`image-${item.id}`);

      if (id1) {
        const div2 = id1.cloneNode(true);
        div.className = 'dragCard in_toc';
        div2.className = 'drag_toc_preview';
        div.appendChild(div2);
        const rect = id1.getBoundingClientRect();
        e.dataTransfer.setDragImage(
          div,
          e.clientX - rect.left,
          e.clientY - rect.top,
        );
      }
    }
    div.appendChild(image);
    document.body.appendChild(div);
    setTimeout(() => setDraggablePage(props), 0);
  };
  const onDragEndHandler = (e) => {
    const draggableItem = document.getElementById('draggable_page');
    document.body.removeChild(draggableItem);
    e.stopPropagation();
    e.preventDefault();
    if (exViewer[currentRole]) {
      return;
    }
    setDraggablePage(false);
  };

  const duplicateHandler = (item, newIndex) => {
    const newId = uuid();
    if (!selectedIds.length) {
      const newPos = getNewLinkPagePositionByIndex(newIndex, linkPages);
      dispatch(actionDuplicateLinkPageSInCurrentPage(item, newPos, id, newId));
    } else {
      const newPosAr = getManyNewLinkPagePositionByIndexForDuplicate(
        linkPages,
        selectedIndex,
      );
      newPosAr.map((newPos, index) => dispatch(
        actionDuplicateLinkPageSInCurrentPage(
          linkPages[selectedIndex[index]],
          newPos,
          id,
          newId,
        ),
      ),
      );
    }
    setLastTouchedItemId(newId);
  };

  const onClickHandler = (item, itemIndex) => () => {
    if (scrollToItem) scrollToItem(itemIndex);
    dispatch(actionAddToBeScrollTo(item?.id));
    dispatch(actionSetBlinkId([item?.id]));
  };
  const mouseLeaveHandler = () => {
    removeFromDom('draggable_page');
    setDraggablePage(false);
  }
  const shouldScroll = partiallyIndexedArray.length > 4;
  return (
    <TOCShell open={open} ref={refItem} onMouseLeave={mouseLeaveHandler} shouldScroll={shouldScroll}>
      <Header onDrop={mouseLeaveHandler}>
        <ViewOptionShell>
          <SvgWrap
            isActive={viewMode === NewUPVViewModes.list}
            isLeft
            onClick={() => setViewMode(NewUPVViewModes.list)}
          >
            {' '}
            <Tooltip
              text={t('listViewT')}
              direction="down"
            >
              <ListSvg />
            </Tooltip>
          </SvgWrap>

          <SvgWrap
            isActive={viewMode === NewUPVViewModes.card}
            onClick={() => setViewMode(NewUPVViewModes.card)}
            isRight
          >
            {' '}
            <Tooltip
              text={t('cardViewT')}
              direction="down"
            >
              <GridSvg />
            </Tooltip>
          </SvgWrap>
        </ViewOptionShell>
        <CloseWrap onClick={() => setOpen(false)}>
          {' '}
          <CloseSvg />
        </CloseWrap>
      </Header>
      <TOCContent>
        {partiallyIndexedArray.length > 0 ? (
          partiallyIndexedArray.map((item, itemIndex) => (
            <DNDElementWrap
              onClick={onClickHandler(item, itemIndex)}
              key={`${itemIndex}_${item.id}`}
              onDrop={(e) => {
                onEditorAreaMouseDrop(e, item.id, itemIndex);
              }}
              onDragEnter={onDragEnter(itemIndex)}
              onDragLeave={onDragLeave}
            >
              {!exViewer[currentRole] && (
                <DragLineWrap dragHover={indexHover === itemIndex}>
                  <DragSvg />
                </DragLineWrap>
              )}

              <MakerTOCItem
                onDragEnd={onDragEndHandler}
                onDragStart={dragStartHandler({ id: item.id, itemIndex }, item)}
                name={sanitizeToLoad(getItemName(item)).replaceAll('\\n', ' ')}
                index={itemIndex}
                item={item}
                dragHover={indexHover === itemIndex}
                dragHoverNext={indexHover === itemIndex + 1}
                draggablePage={draggablePage}
                isLast={itemIndex === linkPages.length - 1}
                currentRole={currentRole}
                duplicateHandler={duplicateHandler}
                viewMode={viewMode}
                items={partiallyIndexedArray}
                setLastTouchedItemId={setLastTouchedItemId}
                lastTouchedItemId={lastTouchedItemId}
                isPlayer={isPlayer}
                isFinished={isFinished}
              />
            </DNDElementWrap>
          ))
        ) : (
          <EmptyStateWrapper>
            <EmptyStateText>{t('tableOfContentIsEmptyT')}</EmptyStateText>
            <EmptyStateText>{t('useStuffToCreateTOContentT')}</EmptyStateText>
          </EmptyStateWrapper>
        )}
        {!exViewer[currentRole] && partiallyIndexedArray.length > 0 && (
          <DNDElementWrap
            onDragEnter={onDragEnter('last')}
            onDragLeave={onDragLeave}
            onDrop={(e) => {
              onEditorAreaMouseDrop(e, null, 'last');
            }}
          >
            <LastItem>
              <DragLineWrap dragHover={indexHover === 'last'}>
                <DragSvg />
              </DragLineWrap>
            </LastItem>
          </DNDElementWrap>
        )}
        {!isForSharedToWeb && !isOwner && isPlayer && (
          <>
            <SeparatorLineHorizontal />
            <CloseSection
              currentRole={currentRole}
              wrapperId={wrapperId}
              isFinished={isFinished}
              onClickClosePlaylist={onClickClosePlaylist}
            />
          </>
        )}
      </TOCContent>
    </TOCShell>
  );
};

export default MakerTOC;
