import { useEffect, useRef, useState, RefObject } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useWindowDimensions from './useWindowDimensions';
import { MainNavigate } from '../../navigate/navigate';
import { IRootState } from '../../redux/reducers';

interface UseTableInstallIndexProps {
  cardWidth: number;
  paddingOffset: number;
}

interface IndexCalculationProps {
  contentIds: any[];
  activeTableItemId: string | null;
  cardWidth: number;
  tableInsertRef: RefObject<HTMLDivElement>;
  paddingOffset: number;
  activeNavSlider: string;
  selectorType: string;
}

const getIndex = ({
  contentIds,
  activeTableItemId,
  cardWidth,
  tableInsertRef,
  paddingOffset,
  activeNavSlider,
  selectorType,
}: IndexCalculationProps): number | null => {
  if (!activeTableItemId) return null;
  const sizeOfOneLine = (tableInsertRef.current?.clientWidth || 0) - paddingOffset;
  const maxPlace = contentIds.length;
  // converting index into a place
  let placeOfCurrentActiveItem = contentIds.indexOf(activeTableItemId) + 1;

  // @ts-ignore
  if (MainNavigate[activeNavSlider]?.selectors[selectorType]?.new) {
    // create new Playlist element balancer
    placeOfCurrentActiveItem++;
  }
  if (placeOfCurrentActiveItem === 0) return null;
  const numberOfItemsInARow = Math.floor(sizeOfOneLine / cardWidth);
  let rowsOffset = 0;
  if (placeOfCurrentActiveItem > numberOfItemsInARow) {
    rowsOffset = Math.floor(placeOfCurrentActiveItem / numberOfItemsInARow);
  }
  const normalizedPlace = placeOfCurrentActiveItem - rowsOffset * numberOfItemsInARow;
  const itemsLeftInARow = Math.abs(normalizedPlace - numberOfItemsInARow);

  let resultPlace = normalizedPlace === 0
    ? normalizedPlace + rowsOffset * numberOfItemsInARow
    : normalizedPlace + itemsLeftInARow + rowsOffset * numberOfItemsInARow;

  while (resultPlace > maxPlace) {
    // handle last row items table opening
    resultPlace--;
  }

  // @ts-ignore
  if (MainNavigate[activeNavSlider]?.selectors[selectorType]?.new) {
    // create new Playlist element balancer
    resultPlace--;
  }

  return resultPlace - 1;
};

const useTableInstallIndex = ({ cardWidth, paddingOffset }: UseTableInstallIndexProps) => {
  const { place: activeNavSlider = 'smartfiles', selector: selectorType = 'recent' } = useParams<any>();
  const tableInsertRef = useRef<HTMLDivElement>(null);
  const { contentIds } = useSelector((state: IRootState) => state.content);
  const { activeTableItemId } = useSelector((state: IRootState) => state.support);
  const [installationIndex, setInstallationIndex] = useState<number | null>(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (
      !activeTableItemId
      || !contentIds.length
      || !tableInsertRef.current
      || activeNavSlider !== 'smartfiles'
    ) {
      setInstallationIndex(null);
    } else {
      setInstallationIndex(
        getIndex({
          contentIds,
          activeTableItemId,
          cardWidth,
          tableInsertRef,
          selectorType,
          activeNavSlider,
          paddingOffset,
        }),
      );
    }
  }, [
    contentIds,
    width,
    activeTableItemId,
    cardWidth,
    tableInsertRef,
    selectorType,
    activeNavSlider,
    paddingOffset,
  ]);

  return { installationIndex, tableInsertRef };
};

export default useTableInstallIndex;
