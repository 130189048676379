import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import { actionCreator } from '../../shared/redux/actionHelper';
import { DeletedActionType } from '../../redux/deleted/deletedTypes';
import { ReactComponent as PageInTrashSvg } from '../../images/icons/replace_icons/Page.svg';
import { ReactComponent as ComponentTrashSvg } from '../../images/icons/trash_component_icon_16.svg';
import { ReactComponent as UndoSvg } from '../../images/icons/replace_icons/update.svg';
import { ReactComponent as DeleteSvg } from '../../images/icons/replace_icons/Bin.svg';
import { ReactComponent as PlaylistSvg } from '../../images/icons/playlist_in_trash.svg';
import { DEFAULT_TITLE } from '../../utils/constants';

const ItemInTrash = ({ item }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const trashItemIcon = (i) => {
    let res;
    if (i.type === 'page') {
      res = <PageInTrashSvg />;
    } else if (i.type === 'component') {
      res = <ComponentTrashSvg />;
    } else if (i.type === 'playlist') {
      res = <PlaylistSvg />;
    }
    return res;
  };

  const calculateTitle = (i) => {
    if (i.itemType === 'collection') return t('unnamedCollectionT');
    if (i.itemType === 'component') return t('defaultComponentTitleT');
    if (i.type === 'playlist') return t('defaultSmartFileTitleT');
    if (i.type === 'page') return t('defaultSmartPageTitleT');
    return t('defaultItemNameT');
  };

  const moveFromTrash = (id, itemType) => () => {
    setIsDisabled(true);
    if (itemType === 'playlist') dispatch(actionCreator(DeletedActionType.restorePlaylistSR, { id }));
    else dispatch(actionCreator(DeletedActionType.restoreLibItemSR, { id }));
  };

  const deleteItem = (id, itemType) => () => {
    setIsDisabled(true);
    if (itemType === 'playlist') dispatch(actionCreator(DeletedActionType.deletePlaylist, { id }));
    else dispatch(actionCreator(DeletedActionType.deleteLibItem, { id }));
  };

  return (
    <div className={`${styles.in_trash__item}`}>
      <div className={styles.image_wrapper}>{trashItemIcon(item)}</div>
      <div className={`${styles.editMenu__text}`}>
        {item.title || calculateTitle(item)}
      </div>
      <div className={styles.option_in_trash}>
        <div
          onClick={isDisabled ? () => {} : moveFromTrash(item.id, item.type)}
          className={`${styles.undo_wrapper} ${
            isDisabled ? styles.icon_wrapper_disabled : ''
          }`}
        >
          <UndoSvg className={styles.menu_icon_16} />
        </div>
        <div
          onClick={isDisabled ? () => {} : deleteItem(item.id, item.type)}
          className={`${styles.delete_icon_wrapper} ${
            isDisabled ? styles.icon_wrapper_disabled : ''
          }`}
        >
          <DeleteSvg className={styles.menu_icon_16} />
        </div>
      </div>
    </div>
  );
};

export default ItemInTrash;
