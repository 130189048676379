import { call, put, select } from '@redux-saga/core/effects';
import { getCurrentPage, getLibraryComponents, getUser } from './sagasUtils';
import {
  decrementHistoryStep,
  historyEvents,
  historyStep,
  incrementHistoryStep,
} from '../undo';
import { EditPage } from '../redux/pages/types';
import {
  getLibraryComponentData,
  getPositionsForAllFiles,
  updateNewChild,
} from '../utils/sagaFunctions';
import { PLAYLIST_ELEMENTS } from '../utils/constants';
import { ServiceUser } from '../redux/user/types';
import { CurrentPage } from '../redux/currentPage/types';
import {
  requestCreateComponent,
  requestDeleteComponent,
  requestDeleteLibraryComponent,
  requestDeleteLinkPage,
  requestUpdateComponent,
  requestUpdateLibraryComponent,
  requestUpdatePlaylist,
} from '../utils/request';
import { sanitizeToSave } from '../utils/helpers';
import { placementEnum } from '../utils/query/queryEnum';

const SKIP = 'SKIP';

const actionCreator = (type, value, place) => ({ type, payload: value, place });

function* notUndoActionUpdate(action) {
  return yield put({ ...action, metod: 'UNDO' });
}

export function* HandleDropboxPickedFiles_UNDO() {
  const { id: currentPageId } = yield select(getCurrentPage);
  const { id: userId, firstName, lastName } = yield select(getUser);
  const { oldUploadBlockId, oldUploadBlockPosition, files } = historyEvents[historyStep - 1].payload;
  const { isPlaylist, isOpenCreateComponent } = historyEvents[historyStep - 1].prevData;

  const lastModifiedDate = Math.floor(Date.now() / 1000);
  const blocks = files.map((item) => ({ id: item.newChildId }));

  let newLinkPage;

  if (!isPlaylist) {
    yield notUndoActionUpdate(
      actionCreator(EditPage.AddBlock, {
        block: {
          id: oldUploadBlockId,
          position: oldUploadBlockPosition,
        },
        isOpenCreateComponent,
      }),
    );

    yield notUndoActionUpdate(
      actionCreator(EditPage.DeleteManyBlocksRedux, {
        blocks,
        isOpenCreateComponent,
      }),
    );
  } else {
    newLinkPage = {
      id: oldUploadBlockId,
      position: oldUploadBlockPosition,
      type: 'upload',
      createDate: lastModifiedDate,
      owner: {
        id: userId,
        first_name: firstName,
        last_name: lastName,
      },
    };

    yield put(
      actionCreator(CurrentPage.AddManyLinkPagesSS, {
        playlistId: currentPageId,
        blocks: [newLinkPage],
        lastModifiedDate,
      }),
    );

    yield put(
      actionCreator(CurrentPage.RemoveManyLinkPagesSS, {
        playlistId: currentPageId,
        blocks,
        lastModifiedDate,
      }),
    );
  }

  decrementHistoryStep();

  if (!isOpenCreateComponent && isPlaylist) {
    const linkPageIdsToDelete = files.map((item) => ({ id: item.newChildId }));
    yield put(
      actionCreator(ServiceUser.ADD_INTO_SEQUENCE, {
        logicToLaunch: function* UndoSeveralFilesPlaylist() {
          yield requestUpdatePlaylist()({
            id: currentPageId,
            create: {
              linkPages: [
                {
                  id: newLinkPage.id,
                  position: newLinkPage.position,
                  createDate: newLinkPage.createDate,
                  duration: newLinkPage.duration,
                  addOwner: userId,
                },
              ],
            },
          });
          yield call(
            requestDeleteLinkPage(placementEnum.queryDeleteManyLinkPages),
            {
              linkPages: linkPageIdsToDelete,
            },
          );
        },
      }),
    );
  }
  if (!isOpenCreateComponent && !isPlaylist) {
    yield put(
      actionCreator(ServiceUser.ADD_INTO_SEQUENCE, {
        logicToLaunch: function* UndoSeveralFilesPage() {
          yield requestDeleteComponent(placementEnum.mutationDeleteNewBlocks)({
            blocks: files,
          });
          yield requestCreateComponent(
            placementEnum.mutationCreateAddMediaBlockInLibraryComponent,
          )({
            component: {
              id: oldUploadBlockId,
              position: oldUploadBlockPosition,
            },
            currentPageId,
            userId,
          });
        },
      }),
    );
  }
}

const pageBlockCreator = ({ components, positions, componentType }) => (item, index) => {
  if (!components[item.newLibraryComponentId]) return null;

  const { blockType, data, innerHtml } = getLibraryComponentData({
    componentType,
    sourceComponent: components[item.newLibraryComponentId],
  });

  return {
    id: item.newChildId,
    state: {
      data,
    },
    type: blockType,
    position: positions[index] ?? positions.defaultStep,
    innerHtml,
  };
};

const playlistLinkPageCreator = ({ components, positions, userId, firstName, lastName, componentType }) => (item, index) => {
  if (!components[item.newLibraryComponentId]) return null;

  const { linkUrl, title, description, urlFile, id } = components[item.newLibraryComponentId];

  return {
    id: item.newChildId,
    position: positions[index] ?? positions.defaultStep,
    type: PLAYLIST_ELEMENTS.ElementComponent,
    owner: {
      id: userId,
      first_name: firstName,
      last_name: lastName,
    },
    libraryComponent: {
      type: componentType,
      linkUrl,
      title,
      description,
      urlFile,
      id,
    },
  };
};

export function* HandleDropboxPickedFiles_REDO() {
  const { id: userId, firstName, lastName } = yield select(getUser);
  const { id: currentPageId } = yield select(getCurrentPage);
  const components = yield select(getLibraryComponents);
  const { startPosition, endPosition, files, componentType, oldUploadBlockId } = historyEvents[historyStep].payload;
  const { isOpenCreateComponent, isPlaylist } = historyEvents[historyStep].prevData;
  const positions = getPositionsForAllFiles(startPosition, endPosition, files);
  const lastModifiedDate = Math.floor(Date.now() / 1000);

  const itemCreatorFunction = isPlaylist
    ? playlistLinkPageCreator({
      components,
      positions,
      componentType,
      userId,
      firstName,
      lastName,
    })
    : pageBlockCreator({
      components,
      positions,
      componentType,
    });

  const blocks = files.map(itemCreatorFunction).filter((item) => !!item);

  incrementHistoryStep();

  if (!isPlaylist) {
    if (!blocks.length) return;

    yield notUndoActionUpdate(
      actionCreator(EditPage.RemoveBlock, {
        isOpenCreateComponent,
        id: oldUploadBlockId,
      }),
    );

    yield notUndoActionUpdate(
      actionCreator(EditPage.AddManyBlocksRedux, {
        blocks,
        isOpenCreateComponent,
      }),
    );
  } else {
    yield put(
      actionCreator(CurrentPage.DeleteLinkPageSS, {
        playlistId: currentPageId,
        linkPageId: oldUploadBlockId,
      }),
    );

    yield put(
      actionCreator(CurrentPage.AddManyLinkPagesSS, {
        playlistId: currentPageId,
        blocks,
        lastModifiedDate,
      }),
    );
  }
  if (!isOpenCreateComponent && isPlaylist) {
    yield put(
      actionCreator(ServiceUser.ADD_INTO_SEQUENCE, {
        logicToLaunch: function* RedoSeveralFilesPlaylist() {
          yield call(requestDeleteLinkPage(), {
            id: oldUploadBlockId,
          });
          yield requestDeleteLibraryComponent(
            placementEnum.queryAddManyLinkPages,
          );
          yield call(
            requestUpdatePlaylist(placementEnum.queryAddManyLinkPages),
            {
              playlistId: currentPageId,
              linkPages: blocks,
              userId,
              lastModifiedDate,
            },
          );
        },
      }),
    );
  }
  if (!isOpenCreateComponent && !isPlaylist) {
    yield put(
      actionCreator(ServiceUser.ADD_INTO_SEQUENCE, {
        logicToLaunch: function* RedoSeveralFilesPage() {
          yield requestUpdateLibraryComponent()({
            place: 'mutationAddNewBlocks',
            id: currentPageId,
            create: {
              components: blocks.map((block) => ({
                id: block.id,
                position: block.position,
                addOwner: userId,
                type: block.type,
                innerHtml: sanitizeToSave(block.innerHtml),
                libraryComponentId: block.state.data.libraryComponentId,
              })),
            },
          });
          yield call(requestDeleteComponent(placementEnum.queryDeleteComp), {
            compId: oldUploadBlockId,
          });
        },
      }),
    );
  }
}

export function* HandleDropboxFileLinkS_UNDO() {
  const payload = historyEvents[historyStep - 1].payload;
  const { isPlaylist, isOpenCreateComponent } = historyEvents[historyStep - 1].prevData;
  const { id: playlistId } = yield select(getCurrentPage);
  const lastModifiedDate = Math.floor(Date.now() / 1000);

  if (!isPlaylist) {
    yield notUndoActionUpdate(
      actionCreator(EditPage.ConvertBlockIntoAddMedia, {
        blockId: payload.newChildId,
        isOpenCreateComponent,
      }),
    );
    yield requestUpdateComponent(placementEnum.mutationBlockIntoAddMedia)({
      componentId: payload.newChildId,
      nestedComponentId: payload.newLibraryComponentId,
    });
  } else {
    yield put(
      actionCreator(CurrentPage.UpdateGoogleUploadBlockSS, {
        playlistId,
        linkPage: {
          id: payload.newChildId,
          type: 'upload',
          position: payload.oldUploadBlockPosition,
        },
        lastModifiedDate,
      }),
    );

    yield call(requestUpdatePlaylist(), {
      id: playlistId,
      updateNested: {
        linkPages: [{ id: payload.newChildId, field: { type: '"upload"' } }],
      },
    });
  }

  decrementHistoryStep();
}

export function* HandleDropboxFileLinkS_REDO() {
  const payload = historyEvents[historyStep].payload;
  const prevData = historyEvents[historyStep].prevData;
  const components = yield select(getLibraryComponents);
  const { isOpenCreateComponent, isPlaylist } = prevData;
  const {
    newChildId,
    contentType,
    oldUploadBlockPosition,
    newLibraryComponentId,
  } = payload;
  const sourceComponent = components[newLibraryComponentId];

  if (!sourceComponent) return;
  if (!isPlaylist) {
    const { blockType, data, innerHtml } = getLibraryComponentData({
      contentType,
      sourceComponent,
    });
    yield notUndoActionUpdate(
      actionCreator(EditPage.ConvertBlockIntoEmbed, {
        isOpenCreateComponent,
        block: {
          id: newChildId,
          type: blockType,
          innerHtml,
          state: {
            data,
          },
        },
      }),
    );
  } else {
    const { id: playlistId } = yield select(getCurrentPage);

    yield call(updateNewChild, {
      isPlaylist,
      newChildId,
      parentId: playlistId,
      newPosition: oldUploadBlockPosition,
      sourceComponent,
      isOpenCreateComponent,
    });
  }

  incrementHistoryStep();
}

export const handleDropboxPrevData = (action, state) => {
  const type = state.currentPage.type;
  const isOpenCreateComponent = state.library.isOpenCreateComponent;

  const isParentExists = (!!type && (type === 'PLAYLIST' || type === 'LIBRARY_COMPONENT'))
    || isOpenCreateComponent;
  const isPlaylist = !!type && type === 'PLAYLIST';

  if (!isParentExists) return SKIP;

  return {
    isOpenCreateComponent,
    isPlaylist,
  };
};
