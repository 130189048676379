import {
  TableCell,
  TableCellLayout,
  TableRow,
} from '@fluentui/react-components';
import React from 'react';
import './table.scss';
import { HOCActionSell } from '../../../components/MiniPlaylist/NewPlaylist2023/table/HOCActionSell';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';

export const ContactTableRow = React.memo(function ContactTableRow({ item, columnSizing_unstable }) {
  const [refMenu, isDropDownVisible, setIsDropDownVisible] = useComponentVisible(null, 'miniPage');
  const toggleShowThreeDots = (e) => {
    setIsDropDownVisible(e);
    e.stopPropagation();
    e.preventDefault();
  };

  return (

    <TableRow
      onContextMenu={toggleShowThreeDots}
    >
      <TableCell
        {...columnSizing_unstable.getTableCellProps('checkbox')}
      >
        <TableCellLayout truncate media={item.checkbox.icon}>
          {item.checkbox.label}
        </TableCellLayout>
      </TableCell>
      <TableCell
        {...columnSizing_unstable.getTableCellProps('nameAndAva')}
      >
        <TableCellLayout truncate>
          {item.nameAndAva.label}
        </TableCellLayout>
      </TableCell>
      <TableCell {...columnSizing_unstable.getTableCellProps('mail')}>
        <TableCellLayout truncate>{item.mail.label}</TableCellLayout>
      </TableCell>
      <TableCell {...columnSizing_unstable.getTableCellProps('phone')}>
        <TableCellLayout truncate media={item.phone.icon}>
          {item.phone.label}
        </TableCellLayout>
      </TableCell>
      <TableCell
        {...columnSizing_unstable.getTableCellProps('function')}
      >
        <TableCellLayout truncate media={item.function.icon}>
          {item.function.label}
        </TableCellLayout>
      </TableCell>
      <TableCell
        {...columnSizing_unstable.getTableCellProps('organization')}
      >
        <TableCellLayout truncate media={item.organization.icon}>
          {item.organization.label}
        </TableCellLayout>
      </TableCell>
      <TableCell
        {...columnSizing_unstable.getTableCellProps('channels')}
      >
        <TableCellLayout truncate media={item.channels.icon}>
          {item.channels.label}
        </TableCellLayout>
      </TableCell>
      <TableCell
        {...columnSizing_unstable.getTableCellProps('actions1')}
      >
        <HOCActionSell
          refMenu={refMenu}
          isDropDownVisible={isDropDownVisible}
          setIsDropDownVisible={setIsDropDownVisible}
          label={item.actions1.label}
        />
      </TableCell>
    </TableRow>

  );
});
