import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './Dashboard.module.scss';
import {
  COLUMN_CUSTOM_TYPE,
  dragType,
  SELECTOR_NEW,
} from '../../utils/constants';
import { actionCreator } from '../../shared/redux/actionHelper';
import DashboardColumns from '../../redux/dashboardColumns/types';
import DashboardLeftMenu from './DashboardLeftMenu';
import SupportAction from '../../redux/support/types';
import KanbanViewContent from './WelcomeView/KanbanViewContent';
import { ReactComponent as KanbanIcon } from '../../images/2023/svg/small/left-main-menu/kanban_icon_16_16.svg';

const Views = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { columnAnchorId } = useSelector((state) => state.support);
  const { isLoad } = useSelector((state) => state.dashboardColumns);
  const { id: userId } = useSelector((state) => state.user);
  const [highlighted, setHighlighted] = useState('');
  const [highlightedColumn, setHighlightedColumn] = useState('');
  // const [activeSliderOption, setActiveSliderOption] = useState(ViewsSliderOptions.New);
  const [dragCard, setDragCard] = useState('');
  const [dragColumnOverId, setdragColumnOverId] = useState('');
  const [dragColumn, setDragColumn] = useState(null);
  const [dragCardOverId, setDragCardOverId] = useState('');
  const [localSelector, setLocalSelector] = useState(SELECTOR_NEW.New);

  const columnDropDepthRef = useRef(0);
  const cardDropDepthRef = useRef(0);
  const refScrollBlocks = useRef({});
  const moveToBlock = useRef(null);
  const timerId = useRef(null);
  const clearDragState = () => {
    setdragColumnOverId(null);
    setDragCardOverId(null);
    setDragCard(null);
    setDragColumn(null);
    cardDropDepthRef.current = 0;
    columnDropDepthRef.current = 0;

    const div = document.getElementById(dragType.board);
    if (div) document.body.removeChild(div);
  };

  useEffect(() => {
    if (columnAnchorId) {
      if (moveToBlock.current !== columnAnchorId) {
        moveToBlock.current = columnAnchorId;
        clearTimeout(timerId.current);
        timerId.current = setTimeout(() => {
          refScrollBlocks.current[columnAnchorId]?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'center',
          });
          dispatch(actionCreator(SupportAction.JumpToCard, {}));
        }, 200);
      } else {
        clearTimeout(timerId.current);
        timerId.current = setTimeout(
          () => dispatch(actionCreator(SupportAction.JumpToCard, {})),
          300,
        );
      }
    }
  }, [columnAnchorId]);

  useEffect(() => {
    if (
      history?.location?.state?.localSelector
      || history?.location?.state?.activeSliderOption
    ) {
      setLocalSelector(history?.location?.state?.localSelector);
      // setActiveSliderOption(history?.location?.state?.activeSliderOption);
    }
    if (history.location.pathname === '/content/smartfiles/board') {
      userId && dispatch(actionCreator(DashboardColumns.uploadColumns));
    }
  }, [history.location.pathname]);

  const onDragEnter = (idHover) => () => {
    columnDropDepthRef.current += 1;
    setdragColumnOverId(idHover);
  };

  const onDropCard = (e, columnId, moveOverCard) => {
    setHighlighted(dragCard);
    setHighlightedColumn(columnId);
    setTimeout(() => {
      setHighlightedColumn('');
      setHighlighted('');
    }, 1000);

    e.stopPropagation();
    if (dragCard) {
      if (moveOverCard === dragCard) return;
      if (COLUMN_CUSTOM_TYPE.CLOSE === columnId) {
        dispatch(
          actionCreator(DashboardColumns.card.moveToCLoseColumn, {
            moveOverCard,
            dragCard,
          }),
        );
      } else {
        dispatch(
          actionCreator(DashboardColumns.card.moveToColumn, {
            columnId,
            moveOverCard,
            dragCard,
          }),
        );
      }
    } else {
      if (columnId === dragColumn) return;
      dispatch(
        actionCreator(DashboardColumns.column.updatePosition, {
          columnId,
          moveOverCard,
          dragColumn,
        }),
      );
    }
    clearDragState();
  };
  const addNewColumnHandler = (putAfterItemIndex) => {
    if (putAfterItemIndex || putAfterItemIndex === 0) {
      dispatch(
        actionCreator(DashboardColumns.column.create, { putAfterItemIndex }),
      );
    } else dispatch(actionCreator(DashboardColumns.column.create));
  };
  if (isLoad) {
    return (
      <div className={styles.loader_wrapper}>
        <div className="loader">{t('loadingT')}</div>
      </div>
    );
  }

  return (
    <div className={styles.page_wrapper}>
      <div className={styles.page}>
        <DashboardLeftMenu
          onDragEnter={onDragEnter}
          highlightedColumn={highlightedColumn}
          dragColumnOverId={dragColumnOverId}
          onDropCard={onDropCard}
        />
        <KanbanViewContent
          refScrollBlocks={refScrollBlocks}
          onDragEnter={onDragEnter}
          onDropCard={onDropCard}
          clearDragState={clearDragState}
          dragCard={dragCard}
          dragColumnOverId={dragColumnOverId}
          setDragColumn={setDragColumn}
          setdragColumnOverId={setdragColumnOverId}
          setDragCard={setDragCard}
          columnDropDepthRef={columnDropDepthRef}
          highlighted={highlighted}
          addNewColumnHandler={addNewColumnHandler}
          dragColumn={dragColumn}
          setDragCardOverId={setDragCardOverId}
          cardDropDepthRef={cardDropDepthRef}
          dragCardOverId={dragCardOverId}
          localSelector={localSelector}
        // activeNavSlider={activeNavSlider}
        />
      </div>
    </div>
  );
};

export default Views;
