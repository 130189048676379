import React, { useState } from 'react';
import classNames from 'classnames/bind';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import './range.scss';
import { ReactComponent as CloseSmallSvg } from '../../images/icons/close_7.svg';
import { ReactComponent as BlueChevronLeftSvg } from '../../images/icons/blue_chevron_left_24.svg';
import { ReactComponent as BlueChevronRightSvg } from '../../images/icons/blue_chevron_right_24.svg';

const cn = classNames.bind(styles);

const LeftButton = () => {
  return (
    <div className={cn('datepicker_button')}>
      <BlueChevronLeftSvg />
    </div>
  );
};
const RightButton = () => {
  return (
    <div className={cn('datepicker_button', 'right')}>
      <BlueChevronRightSvg />
    </div>
  );
};

const calculateInitialSelectedDate = (availableFrom, availableTo) => {
  if (!availableFrom && availableTo) {
    const date = new Date();
    date.setTime(availableTo * 1000);
    return date;
  }
  if (availableFrom && !availableTo) {
    const date = new Date();
    date.setTime(availableFrom * 1000);
    return date;
  }

  if (!availableFrom && !availableTo) {
    return [new Date(), new Date()];
  }

  const date1 = new Date();
  const date2 = new Date();
  date1.setTime(availableFrom * 1000);
  date2.setTime(availableTo * 1000);
  return [date1, date2];
};

const DynamicCollectionDatePicker = (props) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(
    calculateInitialSelectedDate(),
  );
  const [init, setInit] = useState(false);
  const [pickerTouched, setPickerTouched] = useState(false);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const calendarCloseHandler = (values) => {
    setPickerTouched(true);
    // setSelectedDate(values);
    props.setFilter((prev) => ({
      ...prev,
      createDate: {
        Start: values[0].getTime() / 1000,
        End: values[1].getTime() / 1000,
      },
    }));
    props.toggleIsDateOpened();
  };

  return (
    <div
      ref={props.refParent}
      className={cn('date_range_wrapper', { active: props.active })}
    >
      <div className={cn('drop_list_title')}>{t('selectDateRangeUpT')}</div>
      <div className={cn('date_range_container')} onMouseDown={stopPropagation}>
        <div
          className={`${init ? 'not_touched' : ''} ${
            !selectedDate ? 'hide' : ''
          } ${pickerTouched ? 'smartview-date-picker_touched' : ''}`}
          id="smartview-date-picker"
        >
          <DateRangePicker
            className="playlist_date_range_calendar_wrapper"
            onClick={() => setPickerTouched(true)}
            onChange={calendarCloseHandler}
            value={selectedDate}
            selectRange
            clearIcon={<CloseSmallSvg />}
            tileClassName="tileClass"
            showLeadingZeros
            prevLabel={<LeftButton />}
            nextLabel={<RightButton />}
            isOpen={props.active}
          />
        </div>
      </div>
    </div>
  );
};

export default DynamicCollectionDatePicker;
