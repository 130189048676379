import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../ManyPlaylists/index.module.scss';
import LanguageSelectorComponent from '../../components/LanguageSelector/LanguageSelectorComponent';
import NavSlider from '../../components/NavigationSlider/NavSlider';
import SecondaryMenu from '../../components/LibraryHeaderMenu/childComponents/SecondaryMenu';

const HelpHeader = (props) => {
  const { isAdmin } = useSelector((state) => state.user);

  return (
    <div className={styles.is_help}>
      <div className={styles.header}>
        <NavSlider
          setSieve={() => {}}
          setActiveNavSlider={() => {}}
          activeNavSlider={props.activeNavSlider}
          usage="help"
          items={props.items}
          sieveOptions={props.sieveOptions}
        />

        <div className={styles.flex}>
          <div className={styles.orders}>
            <div className={styles.sort_and_view}>
              <SecondaryMenu
                sortType={props.sortType}
                setSort={props.setSort}
                place="help"
                isAdmin={isAdmin}
                isHelp
              />
            </div>
          </div>
          {/* {isAdmin && ( */}
          {/*  <div onClick={props.createPage} className={styles.new_page}> */}
          {/*    <Plus /> */}
          {/*    <div>PLAYLIST</div> */}
          {/*  </div> */}
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default HelpHeader;
