import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../Inbox/index.module.scss';
import InboxSender from '../Inbox/InboxSender';
import { IconRole } from '../../utils/permissions';
import { calculateSingleUserAvatar, calculateUsername } from '../Maker/components/Info/helpers';

import CopyButton from './CopyButton';
import { ReactComponent as CheckSvg } from '../../images/icons/icon_16/check_invite.svg';
import { ReactComponent as AwaitSvg } from '../../images/icons/icon_16/await_invite.svg';

const cn = classNames.bind(styles);


const OutboxRecipients = ({
  recipients,
  channels,
  isPage,
  id,
}) => {
  const excessChannels = recipients.length - 1;
  const refMenu = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const handleHover = () => {
    const windowHeight = window.innerHeight;
    const { bottom } = refMenu?.current?.getBoundingClientRect();
    const componentHeight = 326;
    if (windowHeight <= bottom + componentHeight) {
      setIsAtBottom(true);
    } else {
      setIsAtBottom(false);
    }
  };
  return (
    <div ref={refMenu} onMouseEnter={handleHover} className={`${styles.recipients_info} `}>

      {recipients && recipients[0]?.isUser && (
      <div className={styles.avatar_and_status}>
        <InboxSender userWhoSent={recipients[0]?.user} noDetails />
      </div>
      )}

      {recipients && recipients[0]?.isChannel && (
        <>
          <div className={cn(styles.channel_info, styles.channels_tooltip_item)}>
            {IconRole[channels[recipients[0].channel?.id]?.role]}
            <p> {recipients[0].channel.name}</p>
          </div>
        </>
      )}
      {recipients && recipients[0]?.isSharedToWeb && (
        <div className={`${styles.channel_info} ${styles.channels_tooltip_item}`}>
          <CopyButton isPage={isPage} id={id} />
        </div>
      )}
      {!!excessChannels && !!recipients.length && (
      <div className={`${styles.channels_bubble} ${styles.tooltip_wrapper}`}>
        {`+${excessChannels}`}
        <div className={cn(styles.tooltip, styles.tooltip_opacity, { isAtBottom })}>
          {recipients.map(ch => (
            <div key={ch.user?.id || ch.channel?.id} className={styles.channels_tooltip_item_wrap}>
            <div className={styles.channels_tooltip_item}>
              {ch.isUser && (
                <>
                  <div className={styles.outbox_avatar_wrapper}>
                    {calculateSingleUserAvatar(ch.user, 32)}
                  </div>
                  <div className={styles.recipients_info_tooltip}>
                    <span className={styles.outbox_popup_username}>{calculateUsername(ch.user)}  </span>
                    {((ch.user.showUsername && ch.user.username) || ch.user.first_name) && (
                      <span className={styles.outbox_popup_email}> { ch.user.email }</span>
                    )}
                  </div>
                </>
              )}
              {ch.isChannel && (
                <>
                  <div className={styles.role_wrapper}>{IconRole[channels[ch.channel?.id]?.role]}</div>
                  <span>{ch.channel.name}</span>
                </>
              )}
              {ch.isSharedToWeb && (
              <CopyButton isPage={isPage} id={id} />
              )}
            </div>
              <div>
                {ch.isUser && <>
                  {ch.isNotificationRead && <CheckSvg />}
                  {!ch.isNotificationRead && <AwaitSvg />}
                </>}
              </div>
            </div>
          ))}
        </div>
      </div>
      )}
    </div>
  );
};

export default OutboxRecipients;
