import styled from 'styled-components/macro';

export const TopWrapper = styled.div`
  ${({ relative }) => (relative
  ? `
    position: relative;
    background-color: inherit;`
  : 'position: absolute;')}
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TogglerContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #cbcbcb;
  background: #dedede;
  transition-property: background;
  transition-duration: 0.3s;
  ${({ isActive }) => (isActive
  ? `
    background: #534B4F;
    border: 1px solid #534B4F;`
  : '')}
  ${({disabled}) => (disabled?`cursor: no-drop; opacity: 0.6;`:'')}
`;

export const Knob = styled.div`
  position: absolute;
  top: -1px;
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 50%;
  transition-property: left;
  transition-duration: 0.3s;
  ${({ isActive }) => (isActive
  ? `
    background: #FFFFFF;
    border: 1px solid #534B4F;
  `
  : '')}
`;
