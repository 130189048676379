import { put, takeEvery } from 'redux-saga/effects';
import { shortcut } from '../redux/shortcuts/types';
import { showErrorMessage } from './sagasUtils';
import { actionClearCombinations } from '../redux/shortcuts/action';

function* writeCombination() {
  try {
    yield put(actionClearCombinations());
  } catch (err) {
    yield showErrorMessage(err, { type: 'writeCombination' });
  }
}

export default function* shortcutsSaga() {
  yield takeEvery(shortcut.WriteCombination, writeCombination);
}
