import React from 'react';
import classNames from 'classnames/bind';
import styled from 'styled-components/macro';
import { ReactComponent as BigLink132Svg } from '../../../images/icons/big_link_component_132.svg';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import { ReactComponent as WebSvg } from '../../../images/icons/web.svg';
import rawStyles from '../index.module.scss';
import LinkPreview from '../../../components/CustomLightGallery/preview-components/Link';

const MainWrapper = styled.div`
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LinkContent = ({ item }) => {
  return (
    <MainWrapper>
      <LinkPreview item={item} />
    </MainWrapper>
  );
};
