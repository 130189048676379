import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import useThrottle from '../../hooks/useThrottle';

const VideoPreviewWrapper = styled.div`
 ${({ isTOC }) => (isTOC
  ? `
    img {
      border-radius: 8px;
      margin-left: 12px;
    }
    
  `
    : ''
  )}`;
const VideoPreview = ({ playbackID, width, height, ...props }) => {
  const [isLoading, setLoading] = useState(false);
  const [throttle] = useThrottle(1000);
  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleError = () => {
    setLoading(true);
  };

  useEffect(() => {
    throttle(() => () => {
      if (isLoading) {
        setLoading(false);
      }
    });
  }, [isLoading]);

  return (
    <VideoPreviewWrapper isTOC={props.isTOC}>
      {isLoading ? (
        <div />
      ) : (
        <img
          src={`https://image.mux.com/${playbackID}/thumbnail.jpg?width=${width}&height=${height}&fit_mode=smartcrop`}
          alt="Card Image"
          onLoad={handleImageLoad}
          onError={handleError}
          draggable={false}
        />
      )}
    </VideoPreviewWrapper>
  );
};

export default VideoPreview;
