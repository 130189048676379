import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { INBOX_CARD_TYPE, openModalType } from '../../utils/constants';
import { actionOpenModal, actionSwitchFolder } from '../../redux/user/action';
import OutboxCardBody from './OutboxCardBody';

const Card = styled.div`
  width: 100%;
  min-width: 100%;
  padding: 0 17px 0 14px;
  height: 38px;
  border-radius: 8px;
  border: 0.6px solid transparent;
  background: #FFF;
  box-shadow: 1px 1px 6px 0 rgba(122, 105, 105, 0.10);
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 900px) {
    height: 76px;

  }

   ${({ isSelected }) => (isSelected
    ? `
      background: #FFEEBF;
    ` : '')}
`;


const CardShell = styled.div`
  margin-right: 20px;
  position: relative;
  margin-bottom: 1px;
  display: flex;
  cursor: pointer;
  z-index: 1;

  :hover {
    z-index: 2;

    ${Card} {
      background: #FFEEBF;
      box-shadow: 0 5px 5px -2px rgba(0,0,0,0.12);
    }
`;
export const OutboxCard = React.memo(function InboxCard({
  item,
  channels,
  pickCard,
  isCardSelectedByDefault,
}) {
  const [isSelected, setSelected] = useState(isCardSelectedByDefault);
  const pageRef = useRef();

  const history = useHistory();
  const { maxDateToShow } = useSelector(state => state.outbox);
  const user = useSelector(state => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    setSelected(isCardSelectedByDefault);
  }, [isCardSelectedByDefault]);

  const handleClick = (event) => {
    if (item.type === INBOX_CARD_TYPE.invite) {
      dispatch(actionSwitchFolder('folder', item.id));
      history.push(`/channel/${item.id}`);
    }
    if (item.type === INBOX_CARD_TYPE.sharedPlaylist) {
      if (event?.metaKey || event?.ctrlKey) {
        window.open(
          `${document.location.origin}/maker/${item.playlist.id}/edit/?fromPageType=shared%20by%20me`,
          '_blank', 'noreferrer',
        );
        return;
      }
      history.push(`/maker/${item.playlist.id}/edit/?fromPageType=shared%20by%20me`);
    }
    if (item.type === INBOX_CARD_TYPE.page) {
      const elemPosition = pageRef.current?.getBoundingClientRect() || {};

      dispatch(
        actionOpenModal(openModalType.UPVPreview, { id: item.id, mode: 'inbox', isNeedZoom: true, elemPosition }),
      );
    }
  };

  if (!!maxDateToShow && (maxDateToShow > item.date)) return <></>;
  return (
    <CardShell isRead={item.isRead}>
      <Card isRead={item.isRead} onClick={handleClick} isSelected={isSelected}>
        <OutboxCardBody
          pageRef={pageRef}
          item={item}
          channels={channels}
          pickCard={pickCard}
          isSelected={isSelected}
          setSelected={setSelected}
          user={user}
        />
      </Card>
    </CardShell>
  );
});

export default OutboxCard;
